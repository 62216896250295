// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: June 19, 2023
// Last: May 01, 2024

// /////////////////////////////////////////////////////////////////////////////

import { MUI_THEME } from "./Themes";
import pkgJson from "../../package.json";

// /////////////////////////////////////////////////////////////////////////////
// Application Settings ////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const AppSettings = {
  /**
   * Root ID of this web application.
   */
  ROOT_ID: "root",

  /**
   * "M3DAS Web App"
   * The title of this web application.
   */
  TITLE: pkgJson.title,

  /**
   * "#.#.#-###" The version of this web application.
   */
  VERSION: pkgJson.version,

  /**
   * "M3DAS Web App v#.#.#-###"
   * The title and version of this web application.
   */
  TITLE_VERSION: pkgJson.title + " v" + pkgJson.version,

  /**
   * ! Update before deployment.
   * Toggle to show or hide questions in survey forms.
   * Default: false
   */
  showAll: false,

  /**
   * ! Update before deployment.
   */
  SERVER: "https://" + "202.92.144.27" + ":4433", // Permanent
  // SERVER: "https://" + "127.0.0.1" + ":4433", // Local

  /**
   * Theme for the MUI components of this web application.
   */
  MUI_THEME: MUI_THEME,

  /**
   * The width breakpoint for the responsive design of this web application.
   */
  WIDTHBREAKPOINT: 768,

  /**
   * The minimum accuracy of the GPS location.
   */
  MIN_ACCURACY: 8,
};

// /////////////////////////////////////////////////////////////////////////////
// Constants ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const enum DATA_STATUS {
  local = "Saved Locally",
  online = "Uploaded to Database",
  returned = "Returned from Admin",
  verified = "Verified",
}

export const enum FORMS {
  profile = "profile",
  machine = "machine",
  implement = "implement",
  null = "null",
}

export const enum LOG {
  login = "Login",
  loginCode = "1",
  logout = "Logout",
  logoutCode = "0",
}

// /////////////////////////////////////////////////////////////////////////////
// Functions ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// TODO Move this to other file.
export function GeoLocationPane() {
  return (
    <div
      className={
        "d-md-flex " +
        "justify-content-md-center " +
        "align-items-md-center " +
        "avz-border-radius"
      }
      style={{
        width: "100%",
        maxWidth: "100%",
        minHeight: "250px",
        maxHeight: "250px",
        position: "relative",
      }}
      id="map"
    ></div>
  );
}

// /////////////////////////////////////////////////////////////////////////////
