/**
 * @param date
 * @returns the date in the format DDMMYYYYHHMM like 150520240800
 */
export function comDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  return `${day}${month}${year}${hour}${minute}`;
}

export function dateOnly(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${day}${month}${year}`;
}

export function timeOnly(date: Date): string {
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  return `${hour}${minute}`;
}

// A working but not used example of how to use JSZip
// import { saveAs } from "file-saver";
// import JSZip from "jszip";

// var zip = new JSZip();
// var json = {
//   val1: "One",
//   val2: "Two",
// };
// zip.file("Hello.json", JSON.stringify(json));
// zip.generateAsync({ type: "blob" }).then(function (content) {
//   saveAs(content, "example.zip");
// });
