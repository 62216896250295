const regions = [
  // { label: "REGION I (ILOCOS REGION)", reg_code: "01" },
  // { label: "REGION II (CAGAYAN VALLEY)", reg_code: "02" },
  // { label: "REGION III (CENTRAL LUZON)", reg_code: "03" },
  // { label: "REGION IV-A (CALABARZON)", reg_code: "04" },
  // { label: "REGION IV-B (MIMAROPA)", reg_code: "17" },
  // { label: "REGION V (BICOL REGION)", reg_code: "05" },
  { label: "REGION VI (WESTERN VISAYAS)", reg_code: "06" },
  { label: "REGION VII (CENTRAL VISAYAS)", reg_code: "07" },
  { label: "REGION VIII (EASTERN VISAYAS)", reg_code: "08" },
  // { label: "REGION IX (ZAMBOANGA PENINSULA)", reg_code: "09" },
  // { label: "REGION X (NORTHERN MINDANAO)", reg_code: "10" },
  // { label: "REGION XI (DAVAO REGION)", reg_code: "11" },
  // { label: "REGION XII (SOCCSKSARGEN)", reg_code: "12" },
  // { label: "NATIONAL CAPITAL REGION (NCR)", reg_code: "13" },
  // { label: "CORDILLERA ADMINISTRATIVE REGION (CAR)", reg_code: "14" },
  // { label: "AUTONOMOUS REGION IN MUSLIM MINDANAO (ARMM)", reg_code: "15" },
  // { label: "REGION XIII (Caraga)", reg_code: "16" },
];

const provinces = [
  // { label: "ILOCOS NORTE", reg_code: "01", prov_code: "0128" },
  // { label: "ILOCOS SUR", reg_code: "01", prov_code: "0129" },
  // { label: "LA UNION", reg_code: "01", prov_code: "0133" },
  // { label: "PANGASINAN", reg_code: "01", prov_code: "0155" },
  // { label: "BATANES", reg_code: "02", prov_code: "0209" },
  // { label: "CAGAYAN", reg_code: "02", prov_code: "0215" },
  // { label: "ISABELA", reg_code: "02", prov_code: "0231" },
  // { label: "NUEVA VIZCAYA", reg_code: "02", prov_code: "0250" },
  // { label: "QUIRINO", reg_code: "02", prov_code: "0257" },
  // { label: "BATAAN", reg_code: "03", prov_code: "0308" },
  // { label: "BULACAN", reg_code: "03", prov_code: "0314" },
  // { label: "NUEVA ECIJA", reg_code: "03", prov_code: "0349" },
  // { label: "PAMPANGA", reg_code: "03", prov_code: "0354" },
  // { label: "TARLAC", reg_code: "03", prov_code: "0369" },
  // { label: "ZAMBALES", reg_code: "03", prov_code: "0371" },
  // { label: "AURORA", reg_code: "03", prov_code: "0377" },
  // { label: "BATANGAS", reg_code: "04", prov_code: "0410" },
  // { label: "CAVITE", reg_code: "04", prov_code: "0421" },
  // { label: "LAGUNA", reg_code: "04", prov_code: "0434" },
  // { label: "QUEZON", reg_code: "04", prov_code: "0456" },
  // { label: "RIZAL", reg_code: "04", prov_code: "0458" },
  // { label: "MARINDUQUE", reg_code: "17", prov_code: "1740" },
  // { label: "OCCIDENTAL MINDORO", reg_code: "17", prov_code: "1751" },
  // { label: "ORIENTAL MINDORO", reg_code: "17", prov_code: "1752" },
  // { label: "PALAWAN", reg_code: "17", prov_code: "1753" },
  // { label: "ROMBLON", reg_code: "17", prov_code: "1759" },
  // { label: "ALBAY", reg_code: "05", prov_code: "0505" },
  // { label: "CAMARINES NORTE", reg_code: "05", prov_code: "0516" },
  // { label: "CAMARINES SUR", reg_code: "05", prov_code: "0517" },
  // { label: "CATANDUANES", reg_code: "05", prov_code: "0520" },
  // { label: "MASBATE", reg_code: "05", prov_code: "0541" },
  // { label: "SORSOGON", reg_code: "05", prov_code: "0562" },
  // { label: "AKLAN", reg_code: "06", prov_code: "0604" },
  // { label: "ANTIQUE", reg_code: "06", prov_code: "0606" },
  // { label: "CAPIZ", reg_code: "06", prov_code: "0619" },
  // { label: "ILOILO", reg_code: "06", prov_code: "0630" },
  { label: "NEGROS OCCIDENTAL", reg_code: "06", prov_code: "0645" },
  // { label: "GUIMARAS", reg_code: "06", prov_code: "0679" },
  // { label: "BOHOL", reg_code: "07", prov_code: "0712" },
  // { label: "CEBU", reg_code: "07", prov_code: "0722" },
  { label: "NEGROS ORIENTAL", reg_code: "07", prov_code: "0746" },
  // { label: "SIQUIJOR", reg_code: "07", prov_code: "0761" },
  // { label: "EASTERN SAMAR", reg_code: "08", prov_code: "0826" },
  { label: "LEYTE", reg_code: "08", prov_code: "0837" },
  // { label: "NORTHERN SAMAR", reg_code: "08", prov_code: "0848" },
  // { label: "SAMAR (WESTERN SAMAR)", reg_code: "08", prov_code: "0860" },
  // { label: "SOUTHERN LEYTE", reg_code: "08", prov_code: "0864" },
  // { label: "BILIRAN", reg_code: "08", prov_code: "0878" },
  // { label: "ZAMBOANGA DEL NORTE", reg_code: "09", prov_code: "0972" },
  // { label: "ZAMBOANGA DEL SUR", reg_code: "09", prov_code: "0973" },
  // { label: "ZAMBOANGA SIBUGAY", reg_code: "09", prov_code: "0983" },
  // { label: "CITY OF ISABELA", reg_code: "09", prov_code: "0997" },
  // { label: "BUKIDNON", reg_code: "10", prov_code: "1013" },
  // { label: "CAMIGUIN", reg_code: "10", prov_code: "1018" },
  // { label: "LANAO DEL NORTE", reg_code: "10", prov_code: "1035" },
  // { label: "MISAMIS OCCIDENTAL", reg_code: "10", prov_code: "1042" },
  // { label: "MISAMIS ORIENTAL", reg_code: "10", prov_code: "1043" },
  // { label: "DAVAO DEL NORTE", reg_code: "11", prov_code: "1123" },
  // { label: "DAVAO DEL SUR", reg_code: "11", prov_code: "1124" },
  // { label: "DAVAO ORIENTAL", reg_code: "11", prov_code: "1125" },
  // { label: "COMPOSTELA VALLEY", reg_code: "11", prov_code: "1182" },
  // { label: "DAVAO OCCIDENTAL", reg_code: "11", prov_code: "1186" },
  // {
  //   label: "COTABATO (NORTH COTABATO)",
  //   reg_code: "12",
  //   prov_code: "1247",
  // },
  // { label: "SOUTH COTABATO", reg_code: "12", prov_code: "1263" },
  // { label: "SULTAN KUDARAT", reg_code: "12", prov_code: "1265" },
  // { label: "SARANGANI", reg_code: "12", prov_code: "1280" },
  // { label: "COTABATO CITY", reg_code: "12", prov_code: "1298" },
  // {
  //   label: "NCR, CITY OF MANILA, FIRST DISTRICT",
  //   reg_code: "13",
  //   prov_code: "1339",
  // },
  // { label: "CITY OF MANILA", reg_code: "13", prov_code: "1339" },
  // { label: "NCR, SECOND DISTRICT", reg_code: "13", prov_code: "1374" },
  // { label: "NCR, THIRD DISTRICT", reg_code: "13", prov_code: "1375" },
  // { label: "NCR, FOURTH DISTRICT", reg_code: "13", prov_code: "1376" },
  // { label: "ABRA", reg_code: "14", prov_code: "1401" },
  // { label: "BENGUET", reg_code: "14", prov_code: "1411" },
  // { label: "IFUGAO", reg_code: "14", prov_code: "1427" },
  // { label: "KALINGA", reg_code: "14", prov_code: "1432" },
  // { label: "MOUNTAIN PROVINCE", reg_code: "14", prov_code: "1444" },
  // { label: "APAYAO", reg_code: "14", prov_code: "1481" },
  // { label: "BASILAN", reg_code: "15", prov_code: "1507" },
  // { label: "LANAO DEL SUR", reg_code: "15", prov_code: "1536" },
  // { label: "MAGUINDANAO", reg_code: "15", prov_code: "1538" },
  // { label: "SULU", reg_code: "15", prov_code: "1566" },
  // { label: "TAWI-TAWI", reg_code: "15", prov_code: "1570" },
  // { label: "AGUSAN DEL NORTE", reg_code: "16", prov_code: "1602" },
  // { label: "AGUSAN DEL SUR", reg_code: "16", prov_code: "1603" },
  // { label: "SURIGAO DEL NORTE", reg_code: "16", prov_code: "1667" },
  // { label: "SURIGAO DEL SUR", reg_code: "16", prov_code: "1668" },
  // { label: "DINAGAT ISLANDS", reg_code: "16", prov_code: "1685" },
];
const municipalities = [
  // { label: "ADAMS", prov_code: "0128", mun_code: "012801" },
  // { label: "BACARRA", prov_code: "0128", mun_code: "012802" },
  // { label: "BADOC", prov_code: "0128", mun_code: "012803" },
  // { label: "BANGUI", prov_code: "0128", mun_code: "012804" },
  // { label: "CITY OF BATAC", prov_code: "0128", mun_code: "012805" },
  // { label: "BURGOS", prov_code: "0128", mun_code: "012806" },
  // { label: "CARASI", prov_code: "0128", mun_code: "012807" },
  // { label: "CURRIMAO", prov_code: "0128", mun_code: "012808" },
  // { label: "DINGRAS", prov_code: "0128", mun_code: "012809" },
  // { label: "DUMALNEG", prov_code: "0128", mun_code: "012810" },
  // { label: "BANNA (ESPIRITU)", prov_code: "0128", mun_code: "012811" },
  // { label: "LAOAG CITY (Capital)", prov_code: "0128", mun_code: "012812" },
  // { label: "MARCOS", prov_code: "0128", mun_code: "012813" },
  // { label: "NUEVA ERA", prov_code: "0128", mun_code: "012814" },
  // { label: "PAGUDPUD", prov_code: "0128", mun_code: "012815" },
  // { label: "PAOAY", prov_code: "0128", mun_code: "012816" },
  // { label: "PASUQUIN", prov_code: "0128", mun_code: "012817" },
  // { label: "PIDDIG", prov_code: "0128", mun_code: "012818" },
  // { label: "PINILI", prov_code: "0128", mun_code: "012819" },
  // { label: "SAN NICOLAS", prov_code: "0128", mun_code: "012820" },
  // { label: "SARRAT", prov_code: "0128", mun_code: "012821" },
  // { label: "SOLSONA", prov_code: "0128", mun_code: "012822" },
  // { label: "VINTAR", prov_code: "0128", mun_code: "012823" },
  // { label: "ALILEM", prov_code: "0129", mun_code: "012901" },
  // { label: "BANAYOYO", prov_code: "0129", mun_code: "012902" },
  // { label: "BANTAY", prov_code: "0129", mun_code: "012903" },
  // { label: "BURGOS", prov_code: "0129", mun_code: "012904" },
  // { label: "CABUGAO", prov_code: "0129", mun_code: "012905" },
  // { label: "CITY OF CANDON", prov_code: "0129", mun_code: "012906" },
  // { label: "CAOAYAN", prov_code: "0129", mun_code: "012907" },
  // { label: "CERVANTES", prov_code: "0129", mun_code: "012908" },
  // { label: "GALIMUYOD", prov_code: "0129", mun_code: "012909" },
  // {
  //   label: "GREGORIO DEL PILAR (CONCEPCION)",
  //   prov_code: "0129",
  //   mun_code: "012910",
  // },
  // { label: "LIDLIDDA", prov_code: "0129", mun_code: "012911" },
  // { label: "MAGSINGAL", prov_code: "0129", mun_code: "012912" },
  // { label: "NAGBUKEL", prov_code: "0129", mun_code: "012913" },
  // { label: "NARVACAN", prov_code: "0129", mun_code: "012914" },
  // { label: "QUIRINO (ANGKAKI)", prov_code: "0129", mun_code: "012915" },
  // { label: "SALCEDO (BAUGEN)", prov_code: "0129", mun_code: "012916" },
  // { label: "SAN EMILIO", prov_code: "0129", mun_code: "012917" },
  // { label: "SAN ESTEBAN", prov_code: "0129", mun_code: "012918" },
  // { label: "SAN ILDEFONSO", prov_code: "0129", mun_code: "012919" },
  // { label: "SAN JUAN (LAPOG)", prov_code: "0129", mun_code: "012920" },
  // { label: "SAN VICENTE", prov_code: "0129", mun_code: "012921" },
  // { label: "SANTA", prov_code: "0129", mun_code: "012922" },
  // { label: "SANTA CATALINA", prov_code: "0129", mun_code: "012923" },
  // { label: "SANTA CRUZ", prov_code: "0129", mun_code: "012924" },
  // { label: "SANTA LUCIA", prov_code: "0129", mun_code: "012925" },
  // { label: "SANTA MARIA", prov_code: "0129", mun_code: "012926" },
  // { label: "SANTIAGO", prov_code: "0129", mun_code: "012927" },
  // { label: "SANTO DOMINGO", prov_code: "0129", mun_code: "012928" },
  // { label: "SIGAY", prov_code: "0129", mun_code: "012929" },
  // { label: "SINAIT", prov_code: "0129", mun_code: "012930" },
  // { label: "SUGPON", prov_code: "0129", mun_code: "012931" },
  // { label: "SUYO", prov_code: "0129", mun_code: "012932" },
  // { label: "TAGUDIN", prov_code: "0129", mun_code: "012933" },
  // {
  //   label: "CITY OF VIGAN (Capital)",
  //   prov_code: "0129",
  //   mun_code: "012934",
  // },
  // { label: "AGOO", prov_code: "0133", mun_code: "013301" },
  // { label: "ARINGAY", prov_code: "0133", mun_code: "013302" },
  // { label: "BACNOTAN", prov_code: "0133", mun_code: "013303" },
  // { label: "BAGULIN", prov_code: "0133", mun_code: "013304" },
  // { label: "BALAOAN", prov_code: "0133", mun_code: "013305" },
  // { label: "BANGAR", prov_code: "0133", mun_code: "013306" },
  // { label: "BAUANG", prov_code: "0133", mun_code: "013307" },
  // { label: "BURGOS", prov_code: "0133", mun_code: "013308" },
  // { label: "CABA", prov_code: "0133", mun_code: "013309" },
  // { label: "LUNA", prov_code: "0133", mun_code: "013310" },
  // { label: "NAGUILIAN", prov_code: "0133", mun_code: "013311" },
  // { label: "PUGO", prov_code: "0133", mun_code: "013312" },
  // { label: "ROSARIO", prov_code: "0133", mun_code: "013313" },
  // {
  //   label: "CITY OF SAN FERNANDO (Capital)",
  //   prov_code: "0133",
  //   mun_code: "013314",
  // },
  // { label: "SAN GABRIEL", prov_code: "0133", mun_code: "013315" },
  // { label: "SAN JUAN", prov_code: "0133", mun_code: "013316" },
  // { label: "SANTO TOMAS", prov_code: "0133", mun_code: "013317" },
  // { label: "SANTOL", prov_code: "0133", mun_code: "013318" },
  // { label: "SUDIPEN", prov_code: "0133", mun_code: "013319" },
  // { label: "TUBAO", prov_code: "0133", mun_code: "013320" },
  // { label: "AGNO", prov_code: "0155", mun_code: "015501" },
  // { label: "AGUILAR", prov_code: "0155", mun_code: "015502" },
  // { label: "CITY OF ALAMINOS", prov_code: "0155", mun_code: "015503" },
  // { label: "ALCALA", prov_code: "0155", mun_code: "015504" },
  // { label: "ANDA", prov_code: "0155", mun_code: "015505" },
  // { label: "ASINGAN", prov_code: "0155", mun_code: "015506" },
  // { label: "BALUNGAO", prov_code: "0155", mun_code: "015507" },
  // { label: "BANI", prov_code: "0155", mun_code: "015508" },
  // { label: "BASISTA", prov_code: "0155", mun_code: "015509" },
  // { label: "BAUTISTA", prov_code: "0155", mun_code: "015510" },
  // { label: "BAYAMBANG", prov_code: "0155", mun_code: "015511" },
  // { label: "BINALONAN", prov_code: "0155", mun_code: "015512" },
  // { label: "BINMALEY", prov_code: "0155", mun_code: "015513" },
  // { label: "BOLINAO", prov_code: "0155", mun_code: "015514" },
  // { label: "BUGALLON", prov_code: "0155", mun_code: "015515" },
  // { label: "BURGOS", prov_code: "0155", mun_code: "015516" },
  // { label: "CALASIAO", prov_code: "0155", mun_code: "015517" },
  // { label: "DAGUPAN CITY", prov_code: "0155", mun_code: "015518" },
  // { label: "DASOL", prov_code: "0155", mun_code: "015519" },
  // { label: "INFANTA", prov_code: "0155", mun_code: "015520" },
  // { label: "LABRADOR", prov_code: "0155", mun_code: "015521" },
  // { label: "LINGAYEN (Capital)", prov_code: "0155", mun_code: "015522" },
  // { label: "MABINI", prov_code: "0155", mun_code: "015523" },
  // { label: "MALASIQUI", prov_code: "0155", mun_code: "015524" },
  // { label: "MANAOAG", prov_code: "0155", mun_code: "015525" },
  // { label: "MANGALDAN", prov_code: "0155", mun_code: "015526" },
  // { label: "MANGATAREM", prov_code: "0155", mun_code: "015527" },
  // { label: "MAPANDAN", prov_code: "0155", mun_code: "015528" },
  // { label: "NATIVIDAD", prov_code: "0155", mun_code: "015529" },
  // { label: "POZORRUBIO", prov_code: "0155", mun_code: "015530" },
  // { label: "ROSALES", prov_code: "0155", mun_code: "015531" },
  // { label: "SAN CARLOS CITY", prov_code: "0155", mun_code: "015532" },
  // { label: "SAN FABIAN", prov_code: "0155", mun_code: "015533" },
  // { label: "SAN JACINTO", prov_code: "0155", mun_code: "015534" },
  // { label: "SAN MANUEL", prov_code: "0155", mun_code: "015535" },
  // { label: "SAN NICOLAS", prov_code: "0155", mun_code: "015536" },
  // { label: "SAN QUINTIN", prov_code: "0155", mun_code: "015537" },
  // { label: "SANTA BARBARA", prov_code: "0155", mun_code: "015538" },
  // { label: "SANTA MARIA", prov_code: "0155", mun_code: "015539" },
  // { label: "SANTO TOMAS", prov_code: "0155", mun_code: "015540" },
  // { label: "SISON", prov_code: "0155", mun_code: "015541" },
  // { label: "SUAL", prov_code: "0155", mun_code: "015542" },
  // { label: "TAYUG", prov_code: "0155", mun_code: "015543" },
  // { label: "UMINGAN", prov_code: "0155", mun_code: "015544" },
  // { label: "URBIZTONDO", prov_code: "0155", mun_code: "015545" },
  // { label: "CITY OF URDANETA", prov_code: "0155", mun_code: "015546" },
  // { label: "VILLASIS", prov_code: "0155", mun_code: "015547" },
  // { label: "LAOAC", prov_code: "0155", mun_code: "015548" },
  // { label: "BASCO (Capital)", prov_code: "0209", mun_code: "020901" },
  // { label: "ITBAYAT", prov_code: "0209", mun_code: "020902" },
  // { label: "IVANA", prov_code: "0209", mun_code: "020903" },
  // { label: "MAHATAO", prov_code: "0209", mun_code: "020904" },
  // { label: "SABTANG", prov_code: "0209", mun_code: "020905" },
  // { label: "UYUGAN", prov_code: "0209", mun_code: "020906" },
  // { label: "ABULUG", prov_code: "0215", mun_code: "021501" },
  // { label: "ALCALA", prov_code: "0215", mun_code: "021502" },
  // { label: "ALLACAPAN", prov_code: "0215", mun_code: "021503" },
  // { label: "AMULUNG", prov_code: "0215", mun_code: "021504" },
  // { label: "APARRI", prov_code: "0215", mun_code: "021505" },
  // { label: "BAGGAO", prov_code: "0215", mun_code: "021506" },
  // { label: "BALLESTEROS", prov_code: "0215", mun_code: "021507" },
  // { label: "BUGUEY", prov_code: "0215", mun_code: "021508" },
  // { label: "CALAYAN", prov_code: "0215", mun_code: "021509" },
  // { label: "CAMALANIUGAN", prov_code: "0215", mun_code: "021510" },
  // { label: "CLAVERIA", prov_code: "0215", mun_code: "021511" },
  // { label: "ENRILE", prov_code: "0215", mun_code: "021512" },
  // { label: "GATTARAN", prov_code: "0215", mun_code: "021513" },
  // { label: "GONZAGA", prov_code: "0215", mun_code: "021514" },
  // { label: "IGUIG", prov_code: "0215", mun_code: "021515" },
  // { label: "LAL-LO", prov_code: "0215", mun_code: "021516" },
  // { label: "LASAM", prov_code: "0215", mun_code: "021517" },
  // { label: "PAMPLONA", prov_code: "0215", mun_code: "021518" },
  // { label: "PIAT", prov_code: "0215", mun_code: "021520" },
  // { label: "RIZAL", prov_code: "0215", mun_code: "021521" },
  // { label: "SANCHEZ-MIRA", prov_code: "0215", mun_code: "021522" },
  // { label: "SANTA ANA", prov_code: "0215", mun_code: "021523" },
  // { label: "SANTA PRAXEDES", prov_code: "0215", mun_code: "021524" },
  // { label: "SANTA TERESITA", prov_code: "0215", mun_code: "021525" },
  // { label: "SOLANA", prov_code: "0215", mun_code: "021527" },
  // { label: "TUAO", prov_code: "0215", mun_code: "021528" },
  // {
  //   label: "TUGUEGARAO CITY (Capital)",
  //   prov_code: "0215",
  //   mun_code: "021529",
  // },
  // { label: "ALICIA", prov_code: "0231", mun_code: "023101" },
  // { label: "ANGADANAN", prov_code: "0231", mun_code: "023102" },
  // { label: "AURORA", prov_code: "0231", mun_code: "023103" },
  // { label: "BENITO SOLIVEN", prov_code: "0231", mun_code: "023104" },
  // { label: "BURGOS", prov_code: "0231", mun_code: "023105" },
  // { label: "CABAGAN", prov_code: "0231", mun_code: "023106" },
  // { label: "CABATUAN", prov_code: "0231", mun_code: "023107" },
  // { label: "CITY OF CAUAYAN", prov_code: "0231", mun_code: "023108" },
  // { label: "CORDON", prov_code: "0231", mun_code: "023109" },
  // { label: "DINAPIGUE", prov_code: "0231", mun_code: "023110" },
  // { label: "DIVILACAN", prov_code: "0231", mun_code: "023111" },
  // { label: "ECHAGUE", prov_code: "0231", mun_code: "023112" },
  // { label: "GAMU", prov_code: "0231", mun_code: "023113" },
  // {
  //   label: "ILAGAN CITY (Capital)",
  //   prov_code: "0231",
  //   mun_code: "023114",
  // },
  // { label: "JONES", prov_code: "0231", mun_code: "023115" },
  // { label: "LUNA", prov_code: "0231", mun_code: "023116" },
  // { label: "MACONACON", prov_code: "0231", mun_code: "023117" },
  // {
  //   label: "DELFIN ALBANO (MAGSAYSAY)",
  //   prov_code: "0231",
  //   mun_code: "023118",
  // },
  // { label: "MALLIG", prov_code: "0231", mun_code: "023119" },
  // { label: "NAGUILIAN", prov_code: "0231", mun_code: "023120" },
  // { label: "PALANAN", prov_code: "0231", mun_code: "023121" },
  // { label: "QUEZON", prov_code: "0231", mun_code: "023122" },
  // { label: "QUIRINO", prov_code: "0231", mun_code: "023123" },
  // { label: "RAMON", prov_code: "0231", mun_code: "023124" },
  // { label: "REINA MERCEDES", prov_code: "0231", mun_code: "023125" },
  // { label: "ROXAS", prov_code: "0231", mun_code: "023126" },
  // { label: "SAN AGUSTIN", prov_code: "0231", mun_code: "023127" },
  // { label: "SAN GUILLERMO", prov_code: "0231", mun_code: "023128" },
  // { label: "SAN ISIDRO", prov_code: "0231", mun_code: "023129" },
  // { label: "SAN MANUEL", prov_code: "0231", mun_code: "023130" },
  // { label: "SAN MARIANO", prov_code: "0231", mun_code: "023131" },
  // { label: "SAN MATEO", prov_code: "0231", mun_code: "023132" },
  // { label: "SAN PABLO", prov_code: "0231", mun_code: "023133" },
  // { label: "SANTA MARIA", prov_code: "0231", mun_code: "023134" },
  // { label: "CITY OF SANTIAGO", prov_code: "0231", mun_code: "023135" },
  // { label: "SANTO TOMAS", prov_code: "0231", mun_code: "023136" },
  // { label: "TUMAUINI", prov_code: "0231", mun_code: "023137" },
  // { label: "AMBAGUIO", prov_code: "0250", mun_code: "025001" },
  // { label: "ARITAO", prov_code: "0250", mun_code: "025002" },
  // { label: "BAGABAG", prov_code: "0250", mun_code: "025003" },
  // { label: "BAMBANG", prov_code: "0250", mun_code: "025004" },
  // { label: "BAYOMBONG (Capital)", prov_code: "0250", mun_code: "025005" },
  // { label: "DIADI", prov_code: "0250", mun_code: "025006" },
  // { label: "DUPAX DEL NORTE", prov_code: "0250", mun_code: "025007" },
  // { label: "DUPAX DEL SUR", prov_code: "0250", mun_code: "025008" },
  // { label: "KASIBU", prov_code: "0250", mun_code: "025009" },
  // { label: "KAYAPA", prov_code: "0250", mun_code: "025010" },
  // { label: "QUEZON", prov_code: "0250", mun_code: "025011" },
  // { label: "SANTA FE", prov_code: "0250", mun_code: "025012" },
  // { label: "SOLANO", prov_code: "0250", mun_code: "025013" },
  // { label: "VILLAVERDE", prov_code: "0250", mun_code: "025014" },
  // { label: "ALFONSO CASTANEDA", prov_code: "0250", mun_code: "025015" },
  // { label: "AGLIPAY", prov_code: "0257", mun_code: "025701" },
  // {
  //   label: "CABARROGUIS (Capital)",
  //   prov_code: "0257",
  //   mun_code: "025702",
  // },
  // { label: "DIFFUN", prov_code: "0257", mun_code: "025703" },
  // { label: "MADDELA", prov_code: "0257", mun_code: "025704" },
  // { label: "SAGUDAY", prov_code: "0257", mun_code: "025705" },
  // { label: "NAGTIPUNAN", prov_code: "0257", mun_code: "025706" },
  // { label: "ABUCAY", prov_code: "0308", mun_code: "030801" },
  // { label: "BAGAC", prov_code: "0308", mun_code: "030802" },
  // {
  //   label: "CITY OF BALANGA (Capital)",
  //   prov_code: "0308",
  //   mun_code: "030803",
  // },
  // { label: "DINALUPIHAN", prov_code: "0308", mun_code: "030804" },
  // { label: "HERMOSA", prov_code: "0308", mun_code: "030805" },
  // { label: "LIMAY", prov_code: "0308", mun_code: "030806" },
  // { label: "MARIVELES", prov_code: "0308", mun_code: "030807" },
  // { label: "MORONG", prov_code: "0308", mun_code: "030808" },
  // { label: "ORANI", prov_code: "0308", mun_code: "030809" },
  // { label: "ORION", prov_code: "0308", mun_code: "030810" },
  // { label: "PILAR", prov_code: "0308", mun_code: "030811" },
  // { label: "SAMAL", prov_code: "0308", mun_code: "030812" },
  // { label: "ANGAT", prov_code: "0314", mun_code: "031401" },
  // { label: "BALAGTAS (BIGAA)", prov_code: "0314", mun_code: "031402" },
  // { label: "BALIUAG", prov_code: "0314", mun_code: "031403" },
  // { label: "BOCAUE", prov_code: "0314", mun_code: "031404" },
  // { label: "BULACAN", prov_code: "0314", mun_code: "031405" },
  // { label: "BUSTOS", prov_code: "0314", mun_code: "031406" },
  // { label: "CALUMPIT", prov_code: "0314", mun_code: "031407" },
  // { label: "GUIGUINTO", prov_code: "0314", mun_code: "031408" },
  // { label: "HAGONOY", prov_code: "0314", mun_code: "031409" },
  // {
  //   label: "CITY OF MALOLOS (Capital)",
  //   prov_code: "0314",
  //   mun_code: "031410",
  // },
  // { label: "MARILAO", prov_code: "0314", mun_code: "031411" },
  // { label: "CITY OF MEYCAUAYAN", prov_code: "0314", mun_code: "031412" },
  // { label: "NORZAGARAY", prov_code: "0314", mun_code: "031413" },
  // { label: "OBANDO", prov_code: "0314", mun_code: "031414" },
  // { label: "PANDI", prov_code: "0314", mun_code: "031415" },
  // { label: "PAOMBONG", prov_code: "0314", mun_code: "031416" },
  // { label: "PLARIDEL", prov_code: "0314", mun_code: "031417" },
  // { label: "PULILAN", prov_code: "0314", mun_code: "031418" },
  // { label: "SAN ILDEFONSO", prov_code: "0314", mun_code: "031419" },
  // {
  //   label: "CITY OF SAN JOSE DEL MONTE",
  //   prov_code: "0314",
  //   mun_code: "031420",
  // },
  // { label: "SAN MIGUEL", prov_code: "0314", mun_code: "031421" },
  // { label: "SAN RAFAEL", prov_code: "0314", mun_code: "031422" },
  // { label: "SANTA MARIA", prov_code: "0314", mun_code: "031423" },
  // { label: "ALIAGA", prov_code: "0349", mun_code: "034901" },
  // { label: "BONGABON", prov_code: "0349", mun_code: "034902" },
  // { label: "CABANATUAN CITY", prov_code: "0349", mun_code: "034903" },
  // { label: "CABIAO", prov_code: "0349", mun_code: "034904" },
  // { label: "CARRANGLAN", prov_code: "0349", mun_code: "034905" },
  // { label: "CUYAPO", prov_code: "0349", mun_code: "034906" },
  // {
  //   label: "GABALDON (BITULOK & SABANI)",
  //   prov_code: "0349",
  //   mun_code: "034907",
  // },
  // { label: "CITY OF GAPAN", prov_code: "0349", mun_code: "034908" },
  // {
  //   label: "GENERAL MAMERTO NATIVIDAD",
  //   prov_code: "0349",
  //   mun_code: "034909",
  // },
  // {
  //   label: "GENERAL TINIO (PAPAYA)",
  //   prov_code: "0349",
  //   mun_code: "034910",
  // },
  // { label: "GUIMBA", prov_code: "0349", mun_code: "034911" },
  // { label: "JAEN", prov_code: "0349", mun_code: "034912" },
  // { label: "LAUR", prov_code: "0349", mun_code: "034913" },
  // { label: "LICAB", prov_code: "0349", mun_code: "034914" },
  // { label: "LLANERA", prov_code: "0349", mun_code: "034915" },
  // { label: "LUPAO", prov_code: "0349", mun_code: "034916" },
  // { label: "NAMPICUAN", prov_code: "0349", mun_code: "034918" },
  // {
  //   label: "PALAYAN CITY (Capital)",
  //   prov_code: "0349",
  //   mun_code: "034919",
  // },
  // { label: "PANTABANGAN", prov_code: "0349", mun_code: "034920" },
  // { label: "QUEZON", prov_code: "0349", mun_code: "034922" },
  // { label: "RIZAL", prov_code: "0349", mun_code: "034923" },
  // { label: "SAN ANTONIO", prov_code: "0349", mun_code: "034924" },
  // { label: "SAN ISIDRO", prov_code: "0349", mun_code: "034925" },
  // { label: "SAN JOSE CITY", prov_code: "0349", mun_code: "034926" },
  // { label: "SAN LEONARDO", prov_code: "0349", mun_code: "034927" },
  // { label: "SANTA ROSA", prov_code: "0349", mun_code: "034928" },
  // { label: "SANTO DOMINGO", prov_code: "0349", mun_code: "034929" },
  // { label: "TALAVERA", prov_code: "0349", mun_code: "034930" },
  // { label: "TALUGTUG", prov_code: "0349", mun_code: "034931" },
  // { label: "ZARAGOZA", prov_code: "0349", mun_code: "034932" },
  // { label: "ANGELES CITY", prov_code: "0354", mun_code: "035401" },
  // { label: "APALIT", prov_code: "0354", mun_code: "035402" },
  // { label: "ARAYAT", prov_code: "0354", mun_code: "035403" },
  // { label: "BACOLOR", prov_code: "0354", mun_code: "035404" },
  // { label: "CANDABA", prov_code: "0354", mun_code: "035405" },
  // { label: "FLORIDABLANCA", prov_code: "0354", mun_code: "035406" },
  // { label: "GUAGUA", prov_code: "0354", mun_code: "035407" },
  // { label: "LUBAO", prov_code: "0354", mun_code: "035408" },
  // { label: "MABALACAT CITY", prov_code: "0354", mun_code: "035409" },
  // { label: "MACABEBE", prov_code: "0354", mun_code: "035410" },
  // { label: "MAGALANG", prov_code: "0354", mun_code: "035411" },
  // { label: "MASANTOL", prov_code: "0354", mun_code: "035412" },
  // { label: "MEXICO", prov_code: "0354", mun_code: "035413" },
  // { label: "MINALIN", prov_code: "0354", mun_code: "035414" },
  // { label: "PORAC", prov_code: "0354", mun_code: "035415" },
  // {
  //   label: "CITY OF SAN FERNANDO (Capital)",
  //   prov_code: "0354",
  //   mun_code: "035416",
  // },
  // { label: "SAN LUIS", prov_code: "0354", mun_code: "035417" },
  // { label: "SAN SIMON", prov_code: "0354", mun_code: "035418" },
  // { label: "SANTA ANA", prov_code: "0354", mun_code: "035419" },
  // { label: "SANTA RITA", prov_code: "0354", mun_code: "035420" },
  // { label: "SANTO TOMAS", prov_code: "0354", mun_code: "035421" },
  // { label: "SASMUAN (Sexmoan)", prov_code: "0354", mun_code: "035422" },
  // { label: "ANAO", prov_code: "0369", mun_code: "036901" },
  // { label: "BAMBAN", prov_code: "0369", mun_code: "036902" },
  // { label: "CAMILING", prov_code: "0369", mun_code: "036903" },
  // { label: "CAPAS", prov_code: "0369", mun_code: "036904" },
  // { label: "CONCEPCION", prov_code: "0369", mun_code: "036905" },
  // { label: "GERONA", prov_code: "0369", mun_code: "036906" },
  // { label: "LA PAZ", prov_code: "0369", mun_code: "036907" },
  // { label: "MAYANTOC", prov_code: "0369", mun_code: "036908" },
  // { label: "MONCADA", prov_code: "0369", mun_code: "036909" },
  // { label: "PANIQUI", prov_code: "0369", mun_code: "036910" },
  // { label: "PURA", prov_code: "0369", mun_code: "036911" },
  // { label: "RAMOS", prov_code: "0369", mun_code: "036912" },
  // { label: "SAN CLEMENTE", prov_code: "0369", mun_code: "036913" },
  // { label: "SAN MANUEL", prov_code: "0369", mun_code: "036914" },
  // { label: "SANTA IGNACIA", prov_code: "0369", mun_code: "036915" },
  // {
  //   label: "CITY OF TARLAC (Capital)",
  //   prov_code: "0369",
  //   mun_code: "036916",
  // },
  // { label: "VICTORIA", prov_code: "0369", mun_code: "036917" },
  // { label: "SAN JOSE", prov_code: "0369", mun_code: "036918" },
  // { label: "BOTOLAN", prov_code: "0371", mun_code: "037101" },
  // { label: "CABANGAN", prov_code: "0371", mun_code: "037102" },
  // { label: "CANDELARIA", prov_code: "0371", mun_code: "037103" },
  // { label: "CASTILLEJOS", prov_code: "0371", mun_code: "037104" },
  // { label: "IBA (Capital)", prov_code: "0371", mun_code: "037105" },
  // { label: "MASINLOC", prov_code: "0371", mun_code: "037106" },
  // { label: "OLONGAPO CITY", prov_code: "0371", mun_code: "037107" },
  // { label: "PALAUIG", prov_code: "0371", mun_code: "037108" },
  // { label: "SAN ANTONIO", prov_code: "0371", mun_code: "037109" },
  // { label: "SAN FELIPE", prov_code: "0371", mun_code: "037110" },
  // { label: "SAN MARCELINO", prov_code: "0371", mun_code: "037111" },
  // { label: "SAN NARCISO", prov_code: "0371", mun_code: "037112" },
  // { label: "SANTA CRUZ", prov_code: "0371", mun_code: "037113" },
  // { label: "SUBIC", prov_code: "0371", mun_code: "037114" },
  // { label: "BALER (Capital)", prov_code: "0377", mun_code: "037701" },
  // { label: "CASIGURAN", prov_code: "0377", mun_code: "037702" },
  // { label: "DILASAG", prov_code: "0377", mun_code: "037703" },
  // { label: "DINALUNGAN", prov_code: "0377", mun_code: "037704" },
  // { label: "DINGALAN", prov_code: "0377", mun_code: "037705" },
  // { label: "DIPACULAO", prov_code: "0377", mun_code: "037706" },
  // { label: "MARIA AURORA", prov_code: "0377", mun_code: "037707" },
  // { label: "SAN LUIS", prov_code: "0377", mun_code: "037708" },
  // { label: "AGONCILLO", prov_code: "0410", mun_code: "041001" },
  // { label: "ALITAGTAG", prov_code: "0410", mun_code: "041002" },
  // { label: "BALAYAN", prov_code: "0410", mun_code: "041003" },
  // { label: "BALETE", prov_code: "0410", mun_code: "041004" },
  // {
  //   label: "BATANGAS CITY (Capital)",
  //   prov_code: "0410",
  //   mun_code: "041005",
  // },
  // { label: "BAUAN", prov_code: "0410", mun_code: "041006" },
  // { label: "CALACA", prov_code: "0410", mun_code: "041007" },
  // { label: "CALATAGAN", prov_code: "0410", mun_code: "041008" },
  // { label: "CUENCA", prov_code: "0410", mun_code: "041009" },
  // { label: "IBAAN", prov_code: "0410", mun_code: "041010" },
  // { label: "LAUREL", prov_code: "0410", mun_code: "041011" },
  // { label: "LEMERY", prov_code: "0410", mun_code: "041012" },
  // { label: "LIAN", prov_code: "0410", mun_code: "041013" },
  // { label: "LIPA CITY", prov_code: "0410", mun_code: "041014" },
  // { label: "LOBO", prov_code: "0410", mun_code: "041015" },
  // { label: "MABINI", prov_code: "0410", mun_code: "041016" },
  // { label: "MALVAR", prov_code: "0410", mun_code: "041017" },
  // { label: "MATAASNAKAHOY", prov_code: "0410", mun_code: "041018" },
  // { label: "NASUGBU", prov_code: "0410", mun_code: "041019" },
  // { label: "PADRE GARCIA", prov_code: "0410", mun_code: "041020" },
  // { label: "ROSARIO", prov_code: "0410", mun_code: "041021" },
  // { label: "SAN JOSE", prov_code: "0410", mun_code: "041022" },
  // { label: "SAN JUAN", prov_code: "0410", mun_code: "041023" },
  // { label: "SAN LUIS", prov_code: "0410", mun_code: "041024" },
  // { label: "SAN NICOLAS", prov_code: "0410", mun_code: "041025" },
  // { label: "SAN PASCUAL", prov_code: "0410", mun_code: "041026" },
  // { label: "SANTA TERESITA", prov_code: "0410", mun_code: "041027" },
  // { label: "SANTO TOMAS", prov_code: "0410", mun_code: "041028" },
  // { label: "TAAL", prov_code: "0410", mun_code: "041029" },
  // { label: "TALISAY", prov_code: "0410", mun_code: "041030" },
  // { label: "CITY OF TANAUAN", prov_code: "0410", mun_code: "041031" },
  // { label: "TAYSAN", prov_code: "0410", mun_code: "041032" },
  // { label: "TINGLOY", prov_code: "0410", mun_code: "041033" },
  // { label: "TUY", prov_code: "0410", mun_code: "041034" },
  // { label: "ALFONSO", prov_code: "0421", mun_code: "042101" },
  // { label: "AMADEO", prov_code: "0421", mun_code: "042102" },
  // { label: "BACOOR CITY", prov_code: "0421", mun_code: "042103" },
  // { label: "CARMONA", prov_code: "0421", mun_code: "042104" },
  // { label: "CAVITE CITY", prov_code: "0421", mun_code: "042105" },
  // {
  //   label: "GENERAL EMILIO AGUINALDO",
  //   prov_code: "0421",
  //   mun_code: "042107",
  // },
  // { label: "GENERAL TRIAS", prov_code: "0421", mun_code: "042108" },
  // { label: "IMUS CITY", prov_code: "0421", mun_code: "042109" },
  // { label: "INDANG", prov_code: "0421", mun_code: "042110" },
  // { label: "KAWIT", prov_code: "0421", mun_code: "042111" },
  // { label: "MAGALLANES", prov_code: "0421", mun_code: "042112" },
  // { label: "MARAGONDON", prov_code: "0421", mun_code: "042113" },
  // { label: "NAIC", prov_code: "0421", mun_code: "042115" },
  // { label: "NOVELETA", prov_code: "0421", mun_code: "042116" },
  // { label: "ROSARIO", prov_code: "0421", mun_code: "042117" },
  // { label: "SILANG", prov_code: "0421", mun_code: "042118" },
  // { label: "TAGAYTAY CITY", prov_code: "0421", mun_code: "042119" },
  // { label: "TANZA", prov_code: "0421", mun_code: "042120" },
  // { label: "TERNATE", prov_code: "0421", mun_code: "042121" },
  // {
  //   label: "TRECE MARTIRES CITY (Capital)",
  //   prov_code: "0421",
  //   mun_code: "042122",
  // },
  // { label: "GEN. MARIANO ALVAREZ", prov_code: "0421", mun_code: "042123" },
  // { label: "ALAMINOS", prov_code: "0434", mun_code: "043401" },
  // { label: "BAY", prov_code: "0434", mun_code: "043402" },
  // { label: "CABUYAO CITY", prov_code: "0434", mun_code: "043404" },
  // { label: "CITY OF CALAMBA", prov_code: "0434", mun_code: "043405" },
  // { label: "CALAUAN", prov_code: "0434", mun_code: "043406" },
  // { label: "CAVINTI", prov_code: "0434", mun_code: "043407" },
  // { label: "FAMY", prov_code: "0434", mun_code: "043408" },
  // { label: "KALAYAAN", prov_code: "0434", mun_code: "043409" },
  // { label: "LILIW", prov_code: "0434", mun_code: "043410" },
  // { label: "LUISIANA", prov_code: "0434", mun_code: "043412" },
  // { label: "LUMBAN", prov_code: "0434", mun_code: "043413" },
  // { label: "MABITAC", prov_code: "0434", mun_code: "043414" },
  // { label: "MAGDALENA", prov_code: "0434", mun_code: "043415" },
  // { label: "MAJAYJAY", prov_code: "0434", mun_code: "043416" },
  // { label: "NAGCARLAN", prov_code: "0434", mun_code: "043417" },
  // { label: "PAETE", prov_code: "0434", mun_code: "043418" },
  // { label: "PAGSANJAN", prov_code: "0434", mun_code: "043419" },
  // { label: "PAKIL", prov_code: "0434", mun_code: "043420" },
  // { label: "PANGIL", prov_code: "0434", mun_code: "043421" },
  // { label: "PILA", prov_code: "0434", mun_code: "043422" },
  // { label: "RIZAL", prov_code: "0434", mun_code: "043423" },
  // { label: "SAN PABLO CITY", prov_code: "0434", mun_code: "043424" },
  // { label: "CITY OF SAN PEDRO", prov_code: "0434", mun_code: "043425" },
  // { label: "SANTA CRUZ (Capital)", prov_code: "0434", mun_code: "043426" },
  // { label: "SANTA MARIA", prov_code: "0434", mun_code: "043427" },
  // { label: "CITY OF SANTA ROSA", prov_code: "0434", mun_code: "043428" },
  // { label: "SINILOAN", prov_code: "0434", mun_code: "043429" },
  // { label: "VICTORIA", prov_code: "0434", mun_code: "043430" },
  // { label: "AGDANGAN", prov_code: "0456", mun_code: "045601" },
  // { label: "ALABAT", prov_code: "0456", mun_code: "045602" },
  // { label: "ATIMONAN", prov_code: "0456", mun_code: "045603" },
  // { label: "BUENAVISTA", prov_code: "0456", mun_code: "045605" },
  // { label: "BURDEOS", prov_code: "0456", mun_code: "045606" },
  // { label: "CALAUAG", prov_code: "0456", mun_code: "045607" },
  // { label: "CANDELARIA", prov_code: "0456", mun_code: "045608" },
  // { label: "CATANAUAN", prov_code: "0456", mun_code: "045610" },
  // { label: "DOLORES", prov_code: "0456", mun_code: "045615" },
  // { label: "GENERAL LUNA", prov_code: "0456", mun_code: "045616" },
  // { label: "GENERAL NAKAR", prov_code: "0456", mun_code: "045617" },
  // { label: "GUINAYANGAN", prov_code: "0456", mun_code: "045618" },
  // { label: "GUMACA", prov_code: "0456", mun_code: "045619" },
  // { label: "INFANTA", prov_code: "0456", mun_code: "045620" },
  // { label: "JOMALIG", prov_code: "0456", mun_code: "045621" },
  // { label: "LOPEZ", prov_code: "0456", mun_code: "045622" },
  // { label: "LUCBAN", prov_code: "0456", mun_code: "045623" },
  // {
  //   label: "LUCENA CITY (Capital)",
  //   prov_code: "0456",
  //   mun_code: "045624",
  // },
  // { label: "MACALELON", prov_code: "0456", mun_code: "045625" },
  // { label: "MAUBAN", prov_code: "0456", mun_code: "045627" },
  // { label: "MULANAY", prov_code: "0456", mun_code: "045628" },
  // { label: "PADRE BURGOS", prov_code: "0456", mun_code: "045629" },
  // { label: "PAGBILAO", prov_code: "0456", mun_code: "045630" },
  // { label: "PANUKULAN", prov_code: "0456", mun_code: "045631" },
  // { label: "PATNANUNGAN", prov_code: "0456", mun_code: "045632" },
  // { label: "PEREZ", prov_code: "0456", mun_code: "045633" },
  // { label: "PITOGO", prov_code: "0456", mun_code: "045634" },
  // { label: "PLARIDEL", prov_code: "0456", mun_code: "045635" },
  // { label: "POLILLO", prov_code: "0456", mun_code: "045636" },
  // { label: "QUEZON", prov_code: "0456", mun_code: "045637" },
  // { label: "REAL", prov_code: "0456", mun_code: "045638" },
  // { label: "SAMPALOC", prov_code: "0456", mun_code: "045639" },
  // { label: "SAN ANDRES", prov_code: "0456", mun_code: "045640" },
  // { label: "SAN ANTONIO", prov_code: "0456", mun_code: "045641" },
  // {
  //   label: "SAN FRANCISCO (AURORA)",
  //   prov_code: "0456",
  //   mun_code: "045642",
  // },
  // { label: "SAN NARCISO", prov_code: "0456", mun_code: "045644" },
  // { label: "SARIAYA", prov_code: "0456", mun_code: "045645" },
  // { label: "TAGKAWAYAN", prov_code: "0456", mun_code: "045646" },
  // { label: "CITY OF TAYABAS", prov_code: "0456", mun_code: "045647" },
  // { label: "TIAONG", prov_code: "0456", mun_code: "045648" },
  // { label: "UNISAN", prov_code: "0456", mun_code: "045649" },
  // { label: "ANGONO", prov_code: "0458", mun_code: "045801" },
  // { label: "CITY OF ANTIPOLO", prov_code: "0458", mun_code: "045802" },
  // { label: "BARAS", prov_code: "0458", mun_code: "045803" },
  // { label: "BINANGONAN", prov_code: "0458", mun_code: "045804" },
  // { label: "CAINTA", prov_code: "0458", mun_code: "045805" },
  // { label: "CARDONA", prov_code: "0458", mun_code: "045806" },
  // { label: "JALA-JALA", prov_code: "0458", mun_code: "045807" },
  // {
  //   label: "RODRIGUEZ (MONTALBAN)",
  //   prov_code: "0458",
  //   mun_code: "045808",
  // },
  // { label: "MORONG", prov_code: "0458", mun_code: "045809" },
  // { label: "PILILLA", prov_code: "0458", mun_code: "045810" },
  // { label: "SAN MATEO", prov_code: "0458", mun_code: "045811" },
  // { label: "TANAY", prov_code: "0458", mun_code: "045812" },
  // { label: "TAYTAY", prov_code: "0458", mun_code: "045813" },
  // { label: "TERESA", prov_code: "0458", mun_code: "045814" },
  // { label: "BOAC (Capital)", prov_code: "1740", mun_code: "174001" },
  // { label: "BUENAVISTA", prov_code: "1740", mun_code: "174002" },
  // { label: "GASAN", prov_code: "1740", mun_code: "174003" },
  // { label: "MOGPOG", prov_code: "1740", mun_code: "174004" },
  // { label: "SANTA CRUZ", prov_code: "1740", mun_code: "174005" },
  // { label: "TORRIJOS", prov_code: "1740", mun_code: "174006" },
  // { label: "ABRA DE ILOG", prov_code: "1751", mun_code: "175101" },
  // { label: "CALINTAAN", prov_code: "1751", mun_code: "175102" },
  // { label: "LOOC", prov_code: "1751", mun_code: "175103" },
  // { label: "LUBANG", prov_code: "1751", mun_code: "175104" },
  // { label: "MAGSAYSAY", prov_code: "1751", mun_code: "175105" },
  // { label: "MAMBURAO (Capital)", prov_code: "1751", mun_code: "175106" },
  // { label: "PALUAN", prov_code: "1751", mun_code: "175107" },
  // { label: "RIZAL", prov_code: "1751", mun_code: "175108" },
  // { label: "SABLAYAN", prov_code: "1751", mun_code: "175109" },
  // { label: "SAN JOSE", prov_code: "1751", mun_code: "175110" },
  // { label: "SANTA CRUZ", prov_code: "1751", mun_code: "175111" },
  // { label: "BACO", prov_code: "1752", mun_code: "175201" },
  // { label: "BANSUD", prov_code: "1752", mun_code: "175202" },
  // { label: "BONGABONG", prov_code: "1752", mun_code: "175203" },
  // {
  //   label: "BULALACAO (SAN PEDRO)",
  //   prov_code: "1752",
  //   mun_code: "175204",
  // },
  // {
  //   label: "CITY OF CALAPAN (Capital)",
  //   prov_code: "1752",
  //   mun_code: "175205",
  // },
  // { label: "GLORIA", prov_code: "1752", mun_code: "175206" },
  // { label: "MANSALAY", prov_code: "1752", mun_code: "175207" },
  // { label: "NAUJAN", prov_code: "1752", mun_code: "175208" },
  // { label: "PINAMALAYAN", prov_code: "1752", mun_code: "175209" },
  // { label: "POLA", prov_code: "1752", mun_code: "175210" },
  // { label: "PUERTO GALERA", prov_code: "1752", mun_code: "175211" },
  // { label: "ROXAS", prov_code: "1752", mun_code: "175212" },
  // { label: "SAN TEODORO", prov_code: "1752", mun_code: "175213" },
  // { label: "SOCORRO", prov_code: "1752", mun_code: "175214" },
  // { label: "VICTORIA", prov_code: "1752", mun_code: "175215" },
  // { label: "ABORLAN", prov_code: "1753", mun_code: "175301" },
  // { label: "AGUTAYA", prov_code: "1753", mun_code: "175302" },
  // { label: "ARACELI", prov_code: "1753", mun_code: "175303" },
  // { label: "BALABAC", prov_code: "1753", mun_code: "175304" },
  // { label: "BATARAZA", prov_code: "1753", mun_code: "175305" },
  // { label: "BROOKE'S POINT", prov_code: "1753", mun_code: "175306" },
  // { label: "BUSUANGA", prov_code: "1753", mun_code: "175307" },
  // { label: "CAGAYANCILLO", prov_code: "1753", mun_code: "175308" },
  // { label: "CORON", prov_code: "1753", mun_code: "175309" },
  // { label: "CUYO", prov_code: "1753", mun_code: "175310" },
  // { label: "DUMARAN", prov_code: "1753", mun_code: "175311" },
  // { label: "EL NIDO (BACUIT)", prov_code: "1753", mun_code: "175312" },
  // { label: "LINAPACAN", prov_code: "1753", mun_code: "175313" },
  // { label: "MAGSAYSAY", prov_code: "1753", mun_code: "175314" },
  // { label: "NARRA", prov_code: "1753", mun_code: "175315" },
  // {
  //   label: "PUERTO PRINCESA CITY (Capital)",
  //   prov_code: "1753",
  //   mun_code: "175316",
  // },
  // { label: "QUEZON", prov_code: "1753", mun_code: "175317" },
  // { label: "ROXAS", prov_code: "1753", mun_code: "175318" },
  // { label: "SAN VICENTE", prov_code: "1753", mun_code: "175319" },
  // { label: "TAYTAY", prov_code: "1753", mun_code: "175320" },
  // { label: "KALAYAAN", prov_code: "1753", mun_code: "175321" },
  // { label: "CULION", prov_code: "1753", mun_code: "175322" },
  // { label: "RIZAL (MARCOS)", prov_code: "1753", mun_code: "175323" },
  // { label: "ALCANTARA", prov_code: "1759", mun_code: "175901" },
  // { label: "BANTON", prov_code: "1759", mun_code: "175902" },
  // { label: "CAJIDIOCAN", prov_code: "1759", mun_code: "175903" },
  // { label: "CALATRAVA", prov_code: "1759", mun_code: "175904" },
  // { label: "CONCEPCION", prov_code: "1759", mun_code: "175905" },
  // { label: "CORCUERA", prov_code: "1759", mun_code: "175906" },
  // { label: "LOOC", prov_code: "1759", mun_code: "175907" },
  // { label: "MAGDIWANG", prov_code: "1759", mun_code: "175908" },
  // { label: "ODIONGAN", prov_code: "1759", mun_code: "175909" },
  // { label: "ROMBLON (Capital)", prov_code: "1759", mun_code: "175910" },
  // { label: "SAN AGUSTIN", prov_code: "1759", mun_code: "175911" },
  // { label: "SAN ANDRES", prov_code: "1759", mun_code: "175912" },
  // { label: "SAN FERNANDO", prov_code: "1759", mun_code: "175913" },
  // { label: "SAN JOSE", prov_code: "1759", mun_code: "175914" },
  // { label: "SANTA FE", prov_code: "1759", mun_code: "175915" },
  // { label: "FERROL", prov_code: "1759", mun_code: "175916" },
  // { label: "SANTA MARIA (IMELDA)", prov_code: "1759", mun_code: "175917" },
  // { label: "BACACAY", prov_code: "0505", mun_code: "050501" },
  // { label: "CAMALIG", prov_code: "0505", mun_code: "050502" },
  // { label: "DARAGA (LOCSIN)", prov_code: "0505", mun_code: "050503" },
  // { label: "GUINOBATAN", prov_code: "0505", mun_code: "050504" },
  // { label: "JOVELLAR", prov_code: "0505", mun_code: "050505" },
  // {
  //   label: "LEGAZPI CITY (Capital)",
  //   prov_code: "0505",
  //   mun_code: "050506",
  // },
  // { label: "LIBON", prov_code: "0505", mun_code: "050507" },
  // { label: "CITY OF LIGAO", prov_code: "0505", mun_code: "050508" },
  // { label: "MALILIPOT", prov_code: "0505", mun_code: "050509" },
  // { label: "MALINAO", prov_code: "0505", mun_code: "050510" },
  // { label: "MANITO", prov_code: "0505", mun_code: "050511" },
  // { label: "OAS", prov_code: "0505", mun_code: "050512" },
  // { label: "PIO DURAN", prov_code: "0505", mun_code: "050513" },
  // { label: "POLANGUI", prov_code: "0505", mun_code: "050514" },
  // { label: "RAPU-RAPU", prov_code: "0505", mun_code: "050515" },
  // {
  //   label: "SANTO DOMINGO (LIBOG)",
  //   prov_code: "0505",
  //   mun_code: "050516",
  // },
  // { label: "CITY OF TABACO", prov_code: "0505", mun_code: "050517" },
  // { label: "TIWI", prov_code: "0505", mun_code: "050518" },
  // { label: "BASUD", prov_code: "0516", mun_code: "051601" },
  // { label: "CAPALONGA", prov_code: "0516", mun_code: "051602" },
  // { label: "DAET (Capital)", prov_code: "0516", mun_code: "051603" },
  // {
  //   label: "SAN LORENZO RUIZ (IMELDA)",
  //   prov_code: "0516",
  //   mun_code: "051604",
  // },
  // { label: "JOSE PANGANIBAN", prov_code: "0516", mun_code: "051605" },
  // { label: "LABO", prov_code: "0516", mun_code: "051606" },
  // { label: "MERCEDES", prov_code: "0516", mun_code: "051607" },
  // { label: "PARACALE", prov_code: "0516", mun_code: "051608" },
  // { label: "SAN VICENTE", prov_code: "0516", mun_code: "051609" },
  // { label: "SANTA ELENA", prov_code: "0516", mun_code: "051610" },
  // { label: "TALISAY", prov_code: "0516", mun_code: "051611" },
  // { label: "VINZONS", prov_code: "0516", mun_code: "051612" },
  // { label: "BAAO", prov_code: "0517", mun_code: "051701" },
  // { label: "BALATAN", prov_code: "0517", mun_code: "051702" },
  // { label: "BATO", prov_code: "0517", mun_code: "051703" },
  // { label: "BOMBON", prov_code: "0517", mun_code: "051704" },
  // { label: "BUHI", prov_code: "0517", mun_code: "051705" },
  // { label: "BULA", prov_code: "0517", mun_code: "051706" },
  // { label: "CABUSAO", prov_code: "0517", mun_code: "051707" },
  // { label: "CALABANGA", prov_code: "0517", mun_code: "051708" },
  // { label: "CAMALIGAN", prov_code: "0517", mun_code: "051709" },
  // { label: "CANAMAN", prov_code: "0517", mun_code: "051710" },
  // { label: "CARAMOAN", prov_code: "0517", mun_code: "051711" },
  // { label: "DEL GALLEGO", prov_code: "0517", mun_code: "051712" },
  // { label: "GAINZA", prov_code: "0517", mun_code: "051713" },
  // { label: "GARCHITORENA", prov_code: "0517", mun_code: "051714" },
  // { label: "GOA", prov_code: "0517", mun_code: "051715" },
  // { label: "IRIGA CITY", prov_code: "0517", mun_code: "051716" },
  // { label: "LAGONOY", prov_code: "0517", mun_code: "051717" },
  // { label: "LIBMANAN", prov_code: "0517", mun_code: "051718" },
  // { label: "LUPI", prov_code: "0517", mun_code: "051719" },
  // { label: "MAGARAO", prov_code: "0517", mun_code: "051720" },
  // { label: "MILAOR", prov_code: "0517", mun_code: "051721" },
  // { label: "MINALABAC", prov_code: "0517", mun_code: "051722" },
  // { label: "NABUA", prov_code: "0517", mun_code: "051723" },
  // { label: "NAGA CITY", prov_code: "0517", mun_code: "051724" },
  // { label: "OCAMPO", prov_code: "0517", mun_code: "051725" },
  // { label: "PAMPLONA", prov_code: "0517", mun_code: "051726" },
  // { label: "PASACAO", prov_code: "0517", mun_code: "051727" },
  // { label: "PILI (Capital)", prov_code: "0517", mun_code: "051728" },
  // {
  //   label: "PRESENTACION (PARUBCAN)",
  //   prov_code: "0517",
  //   mun_code: "051729",
  // },
  // { label: "RAGAY", prov_code: "0517", mun_code: "051730" },
  // { label: "SAN FERNANDO", prov_code: "0517", mun_code: "051732" },
  // { label: "SAN JOSE", prov_code: "0517", mun_code: "051733" },
  // { label: "SIPOCOT", prov_code: "0517", mun_code: "051734" },
  // { label: "SIRUMA", prov_code: "0517", mun_code: "051735" },
  // { label: "TIGAON", prov_code: "0517", mun_code: "051736" },
  // { label: "TINAMBAC", prov_code: "0517", mun_code: "051737" },
  // { label: "BAGAMANOC", prov_code: "0520", mun_code: "052001" },
  // { label: "BARAS", prov_code: "0520", mun_code: "052002" },
  // { label: "BATO", prov_code: "0520", mun_code: "052003" },
  // { label: "CARAMORAN", prov_code: "0520", mun_code: "052004" },
  // { label: "GIGMOTO", prov_code: "0520", mun_code: "052005" },
  // { label: "PANDAN", prov_code: "0520", mun_code: "052006" },
  // { label: "PANGANIBAN (PAYO)", prov_code: "0520", mun_code: "052007" },
  // {
  //   label: "SAN ANDRES (CALOLBON)",
  //   prov_code: "0520",
  //   mun_code: "052008",
  // },
  // { label: "SAN MIGUEL", prov_code: "0520", mun_code: "052009" },
  // { label: "VIGA", prov_code: "0520", mun_code: "052010" },
  // { label: "VIRAC (Capital)", prov_code: "0520", mun_code: "052011" },
  // { label: "AROROY", prov_code: "0541", mun_code: "054101" },
  // { label: "BALENO", prov_code: "0541", mun_code: "054102" },
  // { label: "BALUD", prov_code: "0541", mun_code: "054103" },
  // { label: "BATUAN", prov_code: "0541", mun_code: "054104" },
  // { label: "CATAINGAN", prov_code: "0541", mun_code: "054105" },
  // { label: "CAWAYAN", prov_code: "0541", mun_code: "054106" },
  // { label: "CLAVERIA", prov_code: "0541", mun_code: "054107" },
  // { label: "DIMASALANG", prov_code: "0541", mun_code: "054108" },
  // { label: "ESPERANZA", prov_code: "0541", mun_code: "054109" },
  // { label: "MANDAON", prov_code: "0541", mun_code: "054110" },
  // {
  //   label: "CITY OF MASBATE (Capital)",
  //   prov_code: "0541",
  //   mun_code: "054111",
  // },
  // { label: "MILAGROS", prov_code: "0541", mun_code: "054112" },
  // { label: "MOBO", prov_code: "0541", mun_code: "054113" },
  // { label: "MONREAL", prov_code: "0541", mun_code: "054114" },
  // { label: "PALANAS", prov_code: "0541", mun_code: "054115" },
  // {
  //   label: "PIO V. CORPUZ (LIMBUHAN)",
  //   prov_code: "0541",
  //   mun_code: "054116",
  // },
  // { label: "PLACER", prov_code: "0541", mun_code: "054117" },
  // { label: "SAN FERNANDO", prov_code: "0541", mun_code: "054118" },
  // { label: "SAN JACINTO", prov_code: "0541", mun_code: "054119" },
  // { label: "SAN PASCUAL", prov_code: "0541", mun_code: "054120" },
  // { label: "USON", prov_code: "0541", mun_code: "054121" },
  // { label: "BARCELONA", prov_code: "0562", mun_code: "056202" },
  // { label: "BULAN", prov_code: "0562", mun_code: "056203" },
  // { label: "BULUSAN", prov_code: "0562", mun_code: "056204" },
  // { label: "CASIGURAN", prov_code: "0562", mun_code: "056205" },
  // { label: "CASTILLA", prov_code: "0562", mun_code: "056206" },
  // { label: "DONSOL", prov_code: "0562", mun_code: "056207" },
  // { label: "GUBAT", prov_code: "0562", mun_code: "056208" },
  // { label: "IROSIN", prov_code: "0562", mun_code: "056209" },
  // { label: "JUBAN", prov_code: "0562", mun_code: "056210" },
  // { label: "MAGALLANES", prov_code: "0562", mun_code: "056211" },
  // { label: "MATNOG", prov_code: "0562", mun_code: "056212" },
  // { label: "PILAR", prov_code: "0562", mun_code: "056213" },
  // { label: "PRIETO DIAZ", prov_code: "0562", mun_code: "056214" },
  // { label: "SANTA MAGDALENA", prov_code: "0562", mun_code: "056215" },
  // {
  //   label: "CITY OF SORSOGON (Capital)",
  //   prov_code: "0562",
  //   mun_code: "056216",
  // },
  // { label: "ALTAVAS", prov_code: "0604", mun_code: "060401" },
  // { label: "BALETE", prov_code: "0604", mun_code: "060402" },
  // { label: "BANGA", prov_code: "0604", mun_code: "060403" },
  // { label: "BATAN", prov_code: "0604", mun_code: "060404" },
  // { label: "BURUANGA", prov_code: "0604", mun_code: "060405" },
  // { label: "IBAJAY", prov_code: "0604", mun_code: "060406" },
  // { label: "KALIBO (Capital)", prov_code: "0604", mun_code: "060407" },
  // { label: "LEZO", prov_code: "0604", mun_code: "060408" },
  // { label: "LIBACAO", prov_code: "0604", mun_code: "060409" },
  // { label: "MADALAG", prov_code: "0604", mun_code: "060410" },
  // { label: "MAKATO", prov_code: "0604", mun_code: "060411" },
  // { label: "MALAY", prov_code: "0604", mun_code: "060412" },
  // { label: "MALINAO", prov_code: "0604", mun_code: "060413" },
  // { label: "NABAS", prov_code: "0604", mun_code: "060414" },
  // { label: "NEW WASHINGTON", prov_code: "0604", mun_code: "060415" },
  // { label: "NUMANCIA", prov_code: "0604", mun_code: "060416" },
  // { label: "TANGALAN", prov_code: "0604", mun_code: "060417" },
  // { label: "ANINI-Y", prov_code: "0606", mun_code: "060601" },
  // { label: "BARBAZA", prov_code: "0606", mun_code: "060602" },
  // { label: "BELISON", prov_code: "0606", mun_code: "060603" },
  // { label: "BUGASONG", prov_code: "0606", mun_code: "060604" },
  // { label: "CALUYA", prov_code: "0606", mun_code: "060605" },
  // { label: "CULASI", prov_code: "0606", mun_code: "060606" },
  // { label: "TOBIAS FORNIER (DAO)", prov_code: "0606", mun_code: "060607" },
  // { label: "HAMTIC", prov_code: "0606", mun_code: "060608" },
  // { label: "LAUA-AN", prov_code: "0606", mun_code: "060609" },
  // { label: "LIBERTAD", prov_code: "0606", mun_code: "060610" },
  // { label: "PANDAN", prov_code: "0606", mun_code: "060611" },
  // { label: "PATNONGON", prov_code: "0606", mun_code: "060612" },
  // { label: "SAN JOSE (Capital)", prov_code: "0606", mun_code: "060613" },
  // { label: "SAN REMIGIO", prov_code: "0606", mun_code: "060614" },
  // { label: "SEBASTE", prov_code: "0606", mun_code: "060615" },
  // { label: "SIBALOM", prov_code: "0606", mun_code: "060616" },
  // { label: "TIBIAO", prov_code: "0606", mun_code: "060617" },
  // { label: "VALDERRAMA", prov_code: "0606", mun_code: "060618" },
  // { label: "CUARTERO", prov_code: "0619", mun_code: "061901" },
  // { label: "DAO", prov_code: "0619", mun_code: "061902" },
  // { label: "DUMALAG", prov_code: "0619", mun_code: "061903" },
  // { label: "DUMARAO", prov_code: "0619", mun_code: "061904" },
  // { label: "IVISAN", prov_code: "0619", mun_code: "061905" },
  // { label: "JAMINDAN", prov_code: "0619", mun_code: "061906" },
  // { label: "MA-AYON", prov_code: "0619", mun_code: "061907" },
  // { label: "MAMBUSAO", prov_code: "0619", mun_code: "061908" },
  // { label: "PANAY", prov_code: "0619", mun_code: "061909" },
  // { label: "PANITAN", prov_code: "0619", mun_code: "061910" },
  // { label: "PILAR", prov_code: "0619", mun_code: "061911" },
  // { label: "PONTEVEDRA", prov_code: "0619", mun_code: "061912" },
  // { label: "PRESIDENT ROXAS", prov_code: "0619", mun_code: "061913" },
  // { label: "ROXAS CITY (Capital)", prov_code: "0619", mun_code: "061914" },
  // { label: "SAPI-AN", prov_code: "0619", mun_code: "061915" },
  // { label: "SIGMA", prov_code: "0619", mun_code: "061916" },
  // { label: "TAPAZ", prov_code: "0619", mun_code: "061917" },
  // { label: "AJUY", prov_code: "0630", mun_code: "063001" },
  // { label: "ALIMODIAN", prov_code: "0630", mun_code: "063002" },
  // { label: "ANILAO", prov_code: "0630", mun_code: "063003" },
  // { label: "BADIANGAN", prov_code: "0630", mun_code: "063004" },
  // { label: "BALASAN", prov_code: "0630", mun_code: "063005" },
  // { label: "BANATE", prov_code: "0630", mun_code: "063006" },
  // { label: "BAROTAC NUEVO", prov_code: "0630", mun_code: "063007" },
  // { label: "BAROTAC VIEJO", prov_code: "0630", mun_code: "063008" },
  // { label: "BATAD", prov_code: "0630", mun_code: "063009" },
  // { label: "BINGAWAN", prov_code: "0630", mun_code: "063010" },
  // { label: "CABATUAN", prov_code: "0630", mun_code: "063012" },
  // { label: "CALINOG", prov_code: "0630", mun_code: "063013" },
  // { label: "CARLES", prov_code: "0630", mun_code: "063014" },
  // { label: "CONCEPCION", prov_code: "0630", mun_code: "063015" },
  // { label: "DINGLE", prov_code: "0630", mun_code: "063016" },
  // { label: "DUMANGAS", prov_code: "0630", mun_code: "063018" },
  // { label: "ESTANCIA", prov_code: "0630", mun_code: "063019" },
  // { label: "GUIMBAL", prov_code: "0630", mun_code: "063020" },
  // { label: "IGBARAS", prov_code: "0630", mun_code: "063021" },
  // {
  //   label: "ILOILO CITY (Capital)",
  //   prov_code: "0630",
  //   mun_code: "063022",
  // },
  // { label: "JANIUAY", prov_code: "0630", mun_code: "063023" },
  // { label: "LAMBUNAO", prov_code: "0630", mun_code: "063025" },
  // { label: "LEGANES", prov_code: "0630", mun_code: "063026" },
  // { label: "LEMERY", prov_code: "0630", mun_code: "063027" },
  // { label: "LEON", prov_code: "0630", mun_code: "063028" },
  // { label: "MAASIN", prov_code: "0630", mun_code: "063029" },
  // { label: "MIAGAO", prov_code: "0630", mun_code: "063030" },
  // { label: "MINA", prov_code: "0630", mun_code: "063031" },
  // { label: "NEW LUCENA", prov_code: "0630", mun_code: "063032" },
  // { label: "OTON", prov_code: "0630", mun_code: "063034" },
  // { label: "CITY OF PASSI", prov_code: "0630", mun_code: "063035" },
  // { label: "PAVIA", prov_code: "0630", mun_code: "063036" },
  // { label: "POTOTAN", prov_code: "0630", mun_code: "063037" },
  // { label: "SAN DIONISIO", prov_code: "0630", mun_code: "063038" },
  // { label: "SAN ENRIQUE", prov_code: "0630", mun_code: "063039" },
  // { label: "SAN JOAQUIN", prov_code: "0630", mun_code: "063040" },
  // { label: "SAN MIGUEL", prov_code: "0630", mun_code: "063041" },
  // { label: "SAN RAFAEL", prov_code: "0630", mun_code: "063042" },
  // { label: "SANTA BARBARA", prov_code: "0630", mun_code: "063043" },
  // { label: "SARA", prov_code: "0630", mun_code: "063044" },
  // { label: "TIGBAUAN", prov_code: "0630", mun_code: "063045" },
  // { label: "TUBUNGAN", prov_code: "0630", mun_code: "063046" },
  // { label: "ZARRAGA", prov_code: "0630", mun_code: "063047" },
  {
    label: "BACOLOD CITY (Capital)",
    prov_code: "0645",
    mun_code: "064501",
  },
  { label: "BAGO CITY", prov_code: "0645", mun_code: "064502" },
  { label: "BINALBAGAN", prov_code: "0645", mun_code: "064503" },
  { label: "CADIZ CITY", prov_code: "0645", mun_code: "064504" },
  { label: "CALATRAVA", prov_code: "0645", mun_code: "064505" },
  { label: "CANDONI", prov_code: "0645", mun_code: "064506" },
  { label: "CAUAYAN", prov_code: "0645", mun_code: "064507" },
  {
    label: "ENRIQUE B. MAGALONA (SARAVIA)",
    prov_code: "0645",
    mun_code: "064508",
  },
  { label: "CITY OF ESCALANTE", prov_code: "0645", mun_code: "064509" },
  { label: "CITY OF HIMAMAYLAN", prov_code: "0645", mun_code: "064510" },
  { label: "HINIGARAN", prov_code: "0645", mun_code: "064511" },
  { label: "HINOBA-AN (ASIA)", prov_code: "0645", mun_code: "064512" },
  { label: "ILOG", prov_code: "0645", mun_code: "064513" },
  { label: "ISABELA", prov_code: "0645", mun_code: "064514" },
  { label: "CITY OF KABANKALAN", prov_code: "0645", mun_code: "064515" },
  { label: "LA CARLOTA CITY", prov_code: "0645", mun_code: "064516" },
  { label: "LA CASTELLANA", prov_code: "0645", mun_code: "064517" },
  { label: "MANAPLA", prov_code: "0645", mun_code: "064518" },
  {
    label: "MOISES PADILLA (MAGALLON)",
    prov_code: "0645",
    mun_code: "064519",
  },
  { label: "MURCIA", prov_code: "0645", mun_code: "064520" },
  { label: "PONTEVEDRA", prov_code: "0645", mun_code: "064521" },
  { label: "PULUPANDAN", prov_code: "0645", mun_code: "064522" },
  { label: "SAGAY CITY", prov_code: "0645", mun_code: "064523" },
  { label: "SAN CARLOS CITY", prov_code: "0645", mun_code: "064524" },
  { label: "SAN ENRIQUE", prov_code: "0645", mun_code: "064525" },
  { label: "SILAY CITY", prov_code: "0645", mun_code: "064526" },
  { label: "CITY OF SIPALAY", prov_code: "0645", mun_code: "064527" },
  { label: "CITY OF TALISAY", prov_code: "0645", mun_code: "064528" },
  { label: "TOBOSO", prov_code: "0645", mun_code: "064529" },
  { label: "VALLADOLID", prov_code: "0645", mun_code: "064530" },
  { label: "CITY OF VICTORIAS", prov_code: "0645", mun_code: "064531" },
  { label: "SALVADOR BENEDICTO", prov_code: "0645", mun_code: "064532" },
  // { label: "BUENAVISTA", prov_code: "0679", mun_code: "067901" },
  // { label: "JORDAN (Capital)", prov_code: "0679", mun_code: "067902" },
  // { label: "NUEVA VALENCIA", prov_code: "0679", mun_code: "067903" },
  // { label: "SAN LORENZO", prov_code: "0679", mun_code: "067904" },
  // { label: "SIBUNAG", prov_code: "0679", mun_code: "067905" },
  // { label: "ALBURQUERQUE", prov_code: "0712", mun_code: "071201" },
  // { label: "ALICIA", prov_code: "0712", mun_code: "071202" },
  // { label: "ANDA", prov_code: "0712", mun_code: "071203" },
  // { label: "ANTEQUERA", prov_code: "0712", mun_code: "071204" },
  // { label: "BACLAYON", prov_code: "0712", mun_code: "071205" },
  // { label: "BALILIHAN", prov_code: "0712", mun_code: "071206" },
  // { label: "BATUAN", prov_code: "0712", mun_code: "071207" },
  // { label: "BILAR", prov_code: "0712", mun_code: "071208" },
  // { label: "BUENAVISTA", prov_code: "0712", mun_code: "071209" },
  // { label: "CALAPE", prov_code: "0712", mun_code: "071210" },
  // { label: "CANDIJAY", prov_code: "0712", mun_code: "071211" },
  // { label: "CARMEN", prov_code: "0712", mun_code: "071212" },
  // { label: "CATIGBIAN", prov_code: "0712", mun_code: "071213" },
  // { label: "CLARIN", prov_code: "0712", mun_code: "071214" },
  // { label: "CORELLA", prov_code: "0712", mun_code: "071215" },
  // { label: "CORTES", prov_code: "0712", mun_code: "071216" },
  // { label: "DAGOHOY", prov_code: "0712", mun_code: "071217" },
  // { label: "DANAO", prov_code: "0712", mun_code: "071218" },
  // { label: "DAUIS", prov_code: "0712", mun_code: "071219" },
  // { label: "DIMIAO", prov_code: "0712", mun_code: "071220" },
  // { label: "DUERO", prov_code: "0712", mun_code: "071221" },
  // { label: "GARCIA HERNANDEZ", prov_code: "0712", mun_code: "071222" },
  // { label: "GUINDULMAN", prov_code: "0712", mun_code: "071223" },
  // { label: "INABANGA", prov_code: "0712", mun_code: "071224" },
  // { label: "JAGNA", prov_code: "0712", mun_code: "071225" },
  // { label: "JETAFE", prov_code: "0712", mun_code: "071226" },
  // { label: "LILA", prov_code: "0712", mun_code: "071227" },
  // { label: "LOAY", prov_code: "0712", mun_code: "071228" },
  // { label: "LOBOC", prov_code: "0712", mun_code: "071229" },
  // { label: "LOON", prov_code: "0712", mun_code: "071230" },
  // { label: "MABINI", prov_code: "0712", mun_code: "071231" },
  // { label: "MARIBOJOC", prov_code: "0712", mun_code: "071232" },
  // { label: "PANGLAO", prov_code: "0712", mun_code: "071233" },
  // { label: "PILAR", prov_code: "0712", mun_code: "071234" },
  // {
  //   label: "PRES. CARLOS P. GARCIA (PITOGO)",
  //   prov_code: "0712",
  //   mun_code: "071235",
  // },
  // { label: "SAGBAYAN (BORJA)", prov_code: "0712", mun_code: "071236" },
  // { label: "SAN ISIDRO", prov_code: "0712", mun_code: "071237" },
  // { label: "SAN MIGUEL", prov_code: "0712", mun_code: "071238" },
  // { label: "SEVILLA", prov_code: "0712", mun_code: "071239" },
  // { label: "SIERRA BULLONES", prov_code: "0712", mun_code: "071240" },
  // { label: "SIKATUNA", prov_code: "0712", mun_code: "071241" },
  // {
  //   label: "TAGBILARAN CITY (Capital)",
  //   prov_code: "0712",
  //   mun_code: "071242",
  // },
  // { label: "TALIBON", prov_code: "0712", mun_code: "071243" },
  // { label: "TRINIDAD", prov_code: "0712", mun_code: "071244" },
  // { label: "TUBIGON", prov_code: "0712", mun_code: "071245" },
  // { label: "UBAY", prov_code: "0712", mun_code: "071246" },
  // { label: "VALENCIA", prov_code: "0712", mun_code: "071247" },
  // { label: "BIEN UNIDO", prov_code: "0712", mun_code: "071248" },
  // { label: "ALCANTARA", prov_code: "0722", mun_code: "072201" },
  // { label: "ALCOY", prov_code: "0722", mun_code: "072202" },
  // { label: "ALEGRIA", prov_code: "0722", mun_code: "072203" },
  // { label: "ALOGUINSAN", prov_code: "0722", mun_code: "072204" },
  // { label: "ARGAO", prov_code: "0722", mun_code: "072205" },
  // { label: "ASTURIAS", prov_code: "0722", mun_code: "072206" },
  // { label: "BADIAN", prov_code: "0722", mun_code: "072207" },
  // { label: "BALAMBAN", prov_code: "0722", mun_code: "072208" },
  // { label: "BANTAYAN", prov_code: "0722", mun_code: "072209" },
  // { label: "BARILI", prov_code: "0722", mun_code: "072210" },
  // { label: "CITY OF BOGO", prov_code: "0722", mun_code: "072211" },
  // { label: "BOLJOON", prov_code: "0722", mun_code: "072212" },
  // { label: "BORBON", prov_code: "0722", mun_code: "072213" },
  // { label: "CITY OF CARCAR", prov_code: "0722", mun_code: "072214" },
  // { label: "CARMEN", prov_code: "0722", mun_code: "072215" },
  // { label: "CATMON", prov_code: "0722", mun_code: "072216" },
  // { label: "CEBU CITY (Capital)", prov_code: "0722", mun_code: "072217" },
  // { label: "COMPOSTELA", prov_code: "0722", mun_code: "072218" },
  // { label: "CONSOLACION", prov_code: "0722", mun_code: "072219" },
  // { label: "CORDOVA", prov_code: "0722", mun_code: "072220" },
  // { label: "DAANBANTAYAN", prov_code: "0722", mun_code: "072221" },
  // { label: "DALAGUETE", prov_code: "0722", mun_code: "072222" },
  // { label: "DANAO CITY", prov_code: "0722", mun_code: "072223" },
  // { label: "DUMANJUG", prov_code: "0722", mun_code: "072224" },
  // { label: "GINATILAN", prov_code: "0722", mun_code: "072225" },
  // {
  //   label: "LAPU-LAPU CITY (OPON)",
  //   prov_code: "0722",
  //   mun_code: "072226",
  // },
  // { label: "LILOAN", prov_code: "0722", mun_code: "072227" },
  // { label: "MADRIDEJOS", prov_code: "0722", mun_code: "072228" },
  // { label: "MALABUYOC", prov_code: "0722", mun_code: "072229" },
  // { label: "MANDAUE CITY", prov_code: "0722", mun_code: "072230" },
  // { label: "MEDELLIN", prov_code: "0722", mun_code: "072231" },
  // { label: "MINGLANILLA", prov_code: "0722", mun_code: "072232" },
  // { label: "MOALBOAL", prov_code: "0722", mun_code: "072233" },
  // { label: "CITY OF NAGA", prov_code: "0722", mun_code: "072234" },
  // { label: "OSLOB", prov_code: "0722", mun_code: "072235" },
  // { label: "PILAR", prov_code: "0722", mun_code: "072236" },
  // { label: "PINAMUNGAHAN", prov_code: "0722", mun_code: "072237" },
  // { label: "PORO", prov_code: "0722", mun_code: "072238" },
  // { label: "RONDA", prov_code: "0722", mun_code: "072239" },
  // { label: "SAMBOAN", prov_code: "0722", mun_code: "072240" },
  // { label: "SAN FERNANDO", prov_code: "0722", mun_code: "072241" },
  // { label: "SAN FRANCISCO", prov_code: "0722", mun_code: "072242" },
  // { label: "SAN REMIGIO", prov_code: "0722", mun_code: "072243" },
  // { label: "SANTA FE", prov_code: "0722", mun_code: "072244" },
  // { label: "SANTANDER", prov_code: "0722", mun_code: "072245" },
  // { label: "SIBONGA", prov_code: "0722", mun_code: "072246" },
  // { label: "SOGOD", prov_code: "0722", mun_code: "072247" },
  // { label: "TABOGON", prov_code: "0722", mun_code: "072248" },
  // { label: "TABUELAN", prov_code: "0722", mun_code: "072249" },
  // { label: "CITY OF TALISAY", prov_code: "0722", mun_code: "072250" },
  // { label: "TOLEDO CITY", prov_code: "0722", mun_code: "072251" },
  // { label: "TUBURAN", prov_code: "0722", mun_code: "072252" },
  // { label: "TUDELA", prov_code: "0722", mun_code: "072253" },
  { label: "AMLAN (AYUQUITAN)", prov_code: "0746", mun_code: "074601" },
  { label: "AYUNGON", prov_code: "0746", mun_code: "074602" },
  { label: "BACONG", prov_code: "0746", mun_code: "074603" },
  { label: "BAIS CITY", prov_code: "0746", mun_code: "074604" },
  { label: "BASAY", prov_code: "0746", mun_code: "074605" },
  {
    label: "CITY OF BAYAWAN (TULONG)",
    prov_code: "0746",
    mun_code: "074606",
  },
  { label: "BINDOY (PAYABON)", prov_code: "0746", mun_code: "074607" },
  { label: "CANLAON CITY", prov_code: "0746", mun_code: "074608" },
  { label: "DAUIN", prov_code: "0746", mun_code: "074609" },
  {
    label: "DUMAGUETE CITY (Capital)",
    prov_code: "0746",
    mun_code: "074610",
  },
  { label: "CITY OF GUIHULNGAN", prov_code: "0746", mun_code: "074611" },
  { label: "JIMALALUD", prov_code: "0746", mun_code: "074612" },
  { label: "LA LIBERTAD", prov_code: "0746", mun_code: "074613" },
  { label: "MABINAY", prov_code: "0746", mun_code: "074614" },
  { label: "MANJUYOD", prov_code: "0746", mun_code: "074615" },
  { label: "PAMPLONA", prov_code: "0746", mun_code: "074616" },
  { label: "SAN JOSE", prov_code: "0746", mun_code: "074617" },
  { label: "SANTA CATALINA", prov_code: "0746", mun_code: "074618" },
  { label: "SIATON", prov_code: "0746", mun_code: "074619" },
  { label: "SIBULAN", prov_code: "0746", mun_code: "074620" },
  { label: "CITY OF TANJAY", prov_code: "0746", mun_code: "074621" },
  { label: "TAYASAN", prov_code: "0746", mun_code: "074622" },
  {
    label: "VALENCIA (LUZURRIAGA)",
    prov_code: "0746",
    mun_code: "074623",
  },
  { label: "VALLEHERMOSO", prov_code: "0746", mun_code: "074624" },
  { label: "ZAMBOANGUITA", prov_code: "0746", mun_code: "074625" },
  // { label: "ENRIQUE VILLANUEVA", prov_code: "0761", mun_code: "076101" },
  // { label: "LARENA", prov_code: "0761", mun_code: "076102" },
  // { label: "LAZI", prov_code: "0761", mun_code: "076103" },
  // { label: "MARIA", prov_code: "0761", mun_code: "076104" },
  // { label: "SAN JUAN", prov_code: "0761", mun_code: "076105" },
  // { label: "SIQUIJOR (Capital)", prov_code: "0761", mun_code: "076106" },
  // { label: "ARTECHE", prov_code: "0826", mun_code: "082601" },
  // { label: "BALANGIGA", prov_code: "0826", mun_code: "082602" },
  // { label: "BALANGKAYAN", prov_code: "0826", mun_code: "082603" },
  // {
  //   label: "CITY OF BORONGAN (Capital)",
  //   prov_code: "0826",
  //   mun_code: "082604",
  // },
  // { label: "CAN-AVID", prov_code: "0826", mun_code: "082605" },
  // { label: "DOLORES", prov_code: "0826", mun_code: "082606" },
  // { label: "GENERAL MACARTHUR", prov_code: "0826", mun_code: "082607" },
  // { label: "GIPORLOS", prov_code: "0826", mun_code: "082608" },
  // { label: "GUIUAN", prov_code: "0826", mun_code: "082609" },
  // { label: "HERNANI", prov_code: "0826", mun_code: "082610" },
  // { label: "JIPAPAD", prov_code: "0826", mun_code: "082611" },
  // { label: "LAWAAN", prov_code: "0826", mun_code: "082612" },
  // { label: "LLORENTE", prov_code: "0826", mun_code: "082613" },
  // { label: "MASLOG", prov_code: "0826", mun_code: "082614" },
  // { label: "MAYDOLONG", prov_code: "0826", mun_code: "082615" },
  // { label: "MERCEDES", prov_code: "0826", mun_code: "082616" },
  // { label: "ORAS", prov_code: "0826", mun_code: "082617" },
  // { label: "QUINAPONDAN", prov_code: "0826", mun_code: "082618" },
  // { label: "SALCEDO", prov_code: "0826", mun_code: "082619" },
  // { label: "SAN JULIAN", prov_code: "0826", mun_code: "082620" },
  // { label: "SAN POLICARPO", prov_code: "0826", mun_code: "082621" },
  // { label: "SULAT", prov_code: "0826", mun_code: "082622" },
  // { label: "TAFT", prov_code: "0826", mun_code: "082623" },
  { label: "ABUYOG", prov_code: "0837", mun_code: "083701" },
  { label: "ALANGALANG", prov_code: "0837", mun_code: "083702" },
  { label: "ALBUERA", prov_code: "0837", mun_code: "083703" },
  { label: "BABATNGON", prov_code: "0837", mun_code: "083705" },
  { label: "BARUGO", prov_code: "0837", mun_code: "083706" },
  { label: "BATO", prov_code: "0837", mun_code: "083707" },
  { label: "CITY OF BAYBAY", prov_code: "0837", mun_code: "083708" },
  { label: "BURAUEN", prov_code: "0837", mun_code: "083710" },
  { label: "CALUBIAN", prov_code: "0837", mun_code: "083713" },
  { label: "CAPOOCAN", prov_code: "0837", mun_code: "083714" },
  { label: "CARIGARA", prov_code: "0837", mun_code: "083715" },
  { label: "DAGAMI", prov_code: "0837", mun_code: "083717" },
  { label: "DULAG", prov_code: "0837", mun_code: "083718" },
  { label: "HILONGOS", prov_code: "0837", mun_code: "083719" },
  { label: "HINDANG", prov_code: "0837", mun_code: "083720" },
  { label: "INOPACAN", prov_code: "0837", mun_code: "083721" },
  { label: "ISABEL", prov_code: "0837", mun_code: "083722" },
  { label: "JARO", prov_code: "0837", mun_code: "083723" },
  { label: "JAVIER (BUGHO)", prov_code: "0837", mun_code: "083724" },
  { label: "JULITA", prov_code: "0837", mun_code: "083725" },
  { label: "KANANGA", prov_code: "0837", mun_code: "083726" },
  { label: "LA PAZ", prov_code: "0837", mun_code: "083728" },
  { label: "LEYTE", prov_code: "0837", mun_code: "083729" },
  { label: "MACARTHUR", prov_code: "0837", mun_code: "083730" },
  { label: "MAHAPLAG", prov_code: "0837", mun_code: "083731" },
  { label: "MATAG-OB", prov_code: "0837", mun_code: "083733" },
  { label: "MATALOM", prov_code: "0837", mun_code: "083734" },
  { label: "MAYORGA", prov_code: "0837", mun_code: "083735" },
  { label: "MERIDA", prov_code: "0837", mun_code: "083736" },
  { label: "ORMOC CITY", prov_code: "0837", mun_code: "083738" },
  { label: "PALO", prov_code: "0837", mun_code: "083739" },
  { label: "PALOMPON", prov_code: "0837", mun_code: "083740" },
  { label: "PASTRANA", prov_code: "0837", mun_code: "083741" },
  { label: "SAN ISIDRO", prov_code: "0837", mun_code: "083742" },
  { label: "SAN MIGUEL", prov_code: "0837", mun_code: "083743" },
  { label: "SANTA FE", prov_code: "0837", mun_code: "083744" },
  { label: "TABANGO", prov_code: "0837", mun_code: "083745" },
  { label: "TABONTABON", prov_code: "0837", mun_code: "083746" },
  {
    label: "TACLOBAN CITY (Capital)",
    prov_code: "0837",
    mun_code: "083747",
  },
  { label: "TANAUAN", prov_code: "0837", mun_code: "083748" },
  { label: "TOLOSA", prov_code: "0837", mun_code: "083749" },
  { label: "TUNGA", prov_code: "0837", mun_code: "083750" },
  { label: "VILLABA", prov_code: "0837", mun_code: "083751" },
  // { label: "ALLEN", prov_code: "0848", mun_code: "084801" },
  // { label: "BIRI", prov_code: "0848", mun_code: "084802" },
  // { label: "BOBON", prov_code: "0848", mun_code: "084803" },
  // { label: "CAPUL", prov_code: "0848", mun_code: "084804" },
  // { label: "CATARMAN (Capital)", prov_code: "0848", mun_code: "084805" },
  // { label: "CATUBIG", prov_code: "0848", mun_code: "084806" },
  // { label: "GAMAY", prov_code: "0848", mun_code: "084807" },
  // { label: "LAOANG", prov_code: "0848", mun_code: "084808" },
  // { label: "LAPINIG", prov_code: "0848", mun_code: "084809" },
  // { label: "LAS NAVAS", prov_code: "0848", mun_code: "084810" },
  // { label: "LAVEZARES", prov_code: "0848", mun_code: "084811" },
  // { label: "MAPANAS", prov_code: "0848", mun_code: "084812" },
  // { label: "MONDRAGON", prov_code: "0848", mun_code: "084813" },
  // { label: "PALAPAG", prov_code: "0848", mun_code: "084814" },
  // { label: "PAMBUJAN", prov_code: "0848", mun_code: "084815" },
  // { label: "ROSARIO", prov_code: "0848", mun_code: "084816" },
  // { label: "SAN ANTONIO", prov_code: "0848", mun_code: "084817" },
  // { label: "SAN ISIDRO", prov_code: "0848", mun_code: "084818" },
  // { label: "SAN JOSE", prov_code: "0848", mun_code: "084819" },
  // { label: "SAN ROQUE", prov_code: "0848", mun_code: "084820" },
  // { label: "SAN VICENTE", prov_code: "0848", mun_code: "084821" },
  // { label: "SILVINO LOBOS", prov_code: "0848", mun_code: "084822" },
  // { label: "VICTORIA", prov_code: "0848", mun_code: "084823" },
  // { label: "LOPE DE VEGA", prov_code: "0848", mun_code: "084824" },
  // { label: "ALMAGRO", prov_code: "0860", mun_code: "086001" },
  // { label: "BASEY", prov_code: "0860", mun_code: "086002" },
  // { label: "CALBAYOG CITY", prov_code: "0860", mun_code: "086003" },
  // { label: "CALBIGA", prov_code: "0860", mun_code: "086004" },
  // {
  //   label: "CITY OF CATBALOGAN (Capital)",
  //   prov_code: "0860",
  //   mun_code: "086005",
  // },
  // { label: "DARAM", prov_code: "0860", mun_code: "086006" },
  // { label: "GANDARA", prov_code: "0860", mun_code: "086007" },
  // { label: "HINABANGAN", prov_code: "0860", mun_code: "086008" },
  // { label: "JIABONG", prov_code: "0860", mun_code: "086009" },
  // { label: "MARABUT", prov_code: "0860", mun_code: "086010" },
  // { label: "MATUGUINAO", prov_code: "0860", mun_code: "086011" },
  // { label: "MOTIONG", prov_code: "0860", mun_code: "086012" },
  // { label: "PINABACDAO", prov_code: "0860", mun_code: "086013" },
  // { label: "SAN JOSE DE BUAN", prov_code: "0860", mun_code: "086014" },
  // { label: "SAN SEBASTIAN", prov_code: "0860", mun_code: "086015" },
  // { label: "SANTA MARGARITA", prov_code: "0860", mun_code: "086016" },
  // { label: "SANTA RITA", prov_code: "0860", mun_code: "086017" },
  // { label: "TALALORA", prov_code: "0860", mun_code: "086019" },
  // { label: "TARANGNAN", prov_code: "0860", mun_code: "086020" },
  // { label: "VILLAREAL", prov_code: "0860", mun_code: "086021" },
  // { label: "PARANAS (WRIGHT)", prov_code: "0860", mun_code: "086022" },
  // { label: "ZUMARRAGA", prov_code: "0860", mun_code: "086023" },
  // { label: "TAGAPUL-AN", prov_code: "0860", mun_code: "086024" },
  // { label: "SAN JORGE", prov_code: "0860", mun_code: "086025" },
  // { label: "PAGSANGHAN", prov_code: "0860", mun_code: "086026" },
  // { label: "ANAHAWAN", prov_code: "0864", mun_code: "086401" },
  // { label: "BONTOC", prov_code: "0864", mun_code: "086402" },
  // { label: "HINUNANGAN", prov_code: "0864", mun_code: "086403" },
  // { label: "HINUNDAYAN", prov_code: "0864", mun_code: "086404" },
  // { label: "LIBAGON", prov_code: "0864", mun_code: "086405" },
  // { label: "LILOAN", prov_code: "0864", mun_code: "086406" },
  // {
  //   label: "CITY OF MAASIN (Capital)",
  //   prov_code: "0864",
  //   mun_code: "086407",
  // },
  // { label: "MACROHON", prov_code: "0864", mun_code: "086408" },
  // { label: "MALITBOG", prov_code: "0864", mun_code: "086409" },
  // { label: "PADRE BURGOS", prov_code: "0864", mun_code: "086410" },
  // { label: "PINTUYAN", prov_code: "0864", mun_code: "086411" },
  // { label: "SAINT BERNARD", prov_code: "0864", mun_code: "086412" },
  // { label: "SAN FRANCISCO", prov_code: "0864", mun_code: "086413" },
  // { label: "SAN JUAN (CABALIAN)", prov_code: "0864", mun_code: "086414" },
  // { label: "SAN RICARDO", prov_code: "0864", mun_code: "086415" },
  // { label: "SILAGO", prov_code: "0864", mun_code: "086416" },
  // { label: "SOGOD", prov_code: "0864", mun_code: "086417" },
  // { label: "TOMAS OPPUS", prov_code: "0864", mun_code: "086418" },
  // { label: "LIMASAWA", prov_code: "0864", mun_code: "086419" },
  // { label: "ALMERIA", prov_code: "0878", mun_code: "087801" },
  // { label: "BILIRAN", prov_code: "0878", mun_code: "087802" },
  // { label: "CABUCGAYAN", prov_code: "0878", mun_code: "087803" },
  // { label: "CAIBIRAN", prov_code: "0878", mun_code: "087804" },
  // { label: "CULABA", prov_code: "0878", mun_code: "087805" },
  // { label: "KAWAYAN", prov_code: "0878", mun_code: "087806" },
  // { label: "MARIPIPI", prov_code: "0878", mun_code: "087807" },
  // { label: "NAVAL (Capital)", prov_code: "0878", mun_code: "087808" },
  // { label: "DAPITAN CITY", prov_code: "0972", mun_code: "097201" },
  // {
  //   label: "DIPOLOG CITY (Capital)",
  //   prov_code: "0972",
  //   mun_code: "097202",
  // },
  // { label: "KATIPUNAN", prov_code: "0972", mun_code: "097203" },
  // { label: "LA LIBERTAD", prov_code: "0972", mun_code: "097204" },
  // { label: "LABASON", prov_code: "0972", mun_code: "097205" },
  // { label: "LILOY", prov_code: "0972", mun_code: "097206" },
  // { label: "MANUKAN", prov_code: "0972", mun_code: "097207" },
  // { label: "MUTIA", prov_code: "0972", mun_code: "097208" },
  // { label: "POLANCO", prov_code: "0972", mun_code: "097210" },
  // {
  //   label: "PRES. MANUEL A. ROXAS",
  //   prov_code: "0972",
  //   mun_code: "097211",
  // },
  // { label: "RIZAL", prov_code: "0972", mun_code: "097212" },
  // { label: "SALUG", prov_code: "0972", mun_code: "097213" },
  // { label: "SIAYAN", prov_code: "0972", mun_code: "097215" },
  // { label: "SIBUCO", prov_code: "0972", mun_code: "097216" },
  // { label: "SIBUTAD", prov_code: "0972", mun_code: "097217" },
  // { label: "SINDANGAN", prov_code: "0972", mun_code: "097218" },
  // { label: "SIOCON", prov_code: "0972", mun_code: "097219" },
  // { label: "SIRAWAI", prov_code: "0972", mun_code: "097220" },
  // { label: "TAMPILISAN", prov_code: "0972", mun_code: "097221" },
  // { label: "JOSE DALMAN (PONOT)", prov_code: "0972", mun_code: "097222" },
  // { label: "GUTALAC", prov_code: "0972", mun_code: "097223" },
  // { label: "BALIGUIAN", prov_code: "0972", mun_code: "097224" },
  // { label: "GODOD", prov_code: "0972", mun_code: "097225" },
  // {
  //   label: "BACUNGAN (Leon T. Postigo)",
  //   prov_code: "0972",
  //   mun_code: "097226",
  // },
  // { label: "KALAWIT", prov_code: "0972", mun_code: "097227" },
  // { label: "AURORA", prov_code: "0973", mun_code: "097302" },
  // { label: "BAYOG", prov_code: "0973", mun_code: "097303" },
  // { label: "DIMATALING", prov_code: "0973", mun_code: "097305" },
  // { label: "DINAS", prov_code: "0973", mun_code: "097306" },
  // { label: "DUMALINAO", prov_code: "0973", mun_code: "097307" },
  // { label: "DUMINGAG", prov_code: "0973", mun_code: "097308" },
  // { label: "KUMALARANG", prov_code: "0973", mun_code: "097311" },
  // { label: "LABANGAN", prov_code: "0973", mun_code: "097312" },
  // { label: "LAPUYAN", prov_code: "0973", mun_code: "097313" },
  // { label: "MAHAYAG", prov_code: "0973", mun_code: "097315" },
  // { label: "MARGOSATUBIG", prov_code: "0973", mun_code: "097317" },
  // { label: "MIDSALIP", prov_code: "0973", mun_code: "097318" },
  // { label: "MOLAVE", prov_code: "0973", mun_code: "097319" },
  // {
  //   label: "PAGADIAN CITY (Capital)",
  //   prov_code: "0973",
  //   mun_code: "097322",
  // },
  // {
  //   label: "RAMON MAGSAYSAY (LIARGO)",
  //   prov_code: "0973",
  //   mun_code: "097323",
  // },
  // { label: "SAN MIGUEL", prov_code: "0973", mun_code: "097324" },
  // { label: "SAN PABLO", prov_code: "0973", mun_code: "097325" },
  // { label: "TABINA", prov_code: "0973", mun_code: "097327" },
  // { label: "TAMBULIG", prov_code: "0973", mun_code: "097328" },
  // { label: "TUKURAN", prov_code: "0973", mun_code: "097330" },
  // { label: "ZAMBOANGA CITY", prov_code: "0973", mun_code: "097332" },
  // { label: "LAKEWOOD", prov_code: "0973", mun_code: "097333" },
  // { label: "JOSEFINA", prov_code: "0973", mun_code: "097337" },
  // { label: "PITOGO", prov_code: "0973", mun_code: "097338" },
  // {
  //   label: "SOMINOT (DON MARIANO MARCOS)",
  //   prov_code: "0973",
  //   mun_code: "097340",
  // },
  // { label: "VINCENZO A. SAGUN", prov_code: "0973", mun_code: "097341" },
  // { label: "GUIPOS", prov_code: "0973", mun_code: "097343" },
  // { label: "TIGBAO", prov_code: "0973", mun_code: "097344" },
  // { label: "ALICIA", prov_code: "0983", mun_code: "098301" },
  // { label: "BUUG", prov_code: "0983", mun_code: "098302" },
  // { label: "DIPLAHAN", prov_code: "0983", mun_code: "098303" },
  // { label: "IMELDA", prov_code: "0983", mun_code: "098304" },
  // { label: "IPIL (Capital)", prov_code: "0983", mun_code: "098305" },
  // { label: "KABASALAN", prov_code: "0983", mun_code: "098306" },
  // { label: "MABUHAY", prov_code: "0983", mun_code: "098307" },
  // { label: "MALANGAS", prov_code: "0983", mun_code: "098308" },
  // { label: "NAGA", prov_code: "0983", mun_code: "098309" },
  // { label: "OLUTANGA", prov_code: "0983", mun_code: "098310" },
  // { label: "PAYAO", prov_code: "0983", mun_code: "098311" },
  // { label: "ROSELLER LIM", prov_code: "0983", mun_code: "098312" },
  // { label: "SIAY", prov_code: "0983", mun_code: "098313" },
  // { label: "TALUSAN", prov_code: "0983", mun_code: "098314" },
  // { label: "TITAY", prov_code: "0983", mun_code: "098315" },
  // { label: "TUNGAWAN", prov_code: "0983", mun_code: "098316" },
  // { label: "CITY OF ISABELA", prov_code: "0997", mun_code: "099701" },
  // { label: "BAUNGON", prov_code: "1013", mun_code: "101301" },
  // { label: "DAMULOG", prov_code: "1013", mun_code: "101302" },
  // { label: "DANGCAGAN", prov_code: "1013", mun_code: "101303" },
  // { label: "DON CARLOS", prov_code: "1013", mun_code: "101304" },
  // { label: "IMPASUG-ONG", prov_code: "1013", mun_code: "101305" },
  // { label: "KADINGILAN", prov_code: "1013", mun_code: "101306" },
  // { label: "KALILANGAN", prov_code: "1013", mun_code: "101307" },
  // { label: "KIBAWE", prov_code: "1013", mun_code: "101308" },
  // { label: "KITAOTAO", prov_code: "1013", mun_code: "101309" },
  // { label: "LANTAPAN", prov_code: "1013", mun_code: "101310" },
  // { label: "LIBONA", prov_code: "1013", mun_code: "101311" },
  // {
  //   label: "CITY OF MALAYBALAY (Capital)",
  //   prov_code: "1013",
  //   mun_code: "101312",
  // },
  // { label: "MALITBOG", prov_code: "1013", mun_code: "101313" },
  // { label: "MANOLO FORTICH", prov_code: "1013", mun_code: "101314" },
  // { label: "MARAMAG", prov_code: "1013", mun_code: "101315" },
  // { label: "PANGANTUCAN", prov_code: "1013", mun_code: "101316" },
  // { label: "QUEZON", prov_code: "1013", mun_code: "101317" },
  // { label: "SAN FERNANDO", prov_code: "1013", mun_code: "101318" },
  // { label: "SUMILAO", prov_code: "1013", mun_code: "101319" },
  // { label: "TALAKAG", prov_code: "1013", mun_code: "101320" },
  // { label: "CITY OF VALENCIA", prov_code: "1013", mun_code: "101321" },
  // { label: "CABANGLASAN", prov_code: "1013", mun_code: "101322" },
  // { label: "CATARMAN", prov_code: "1018", mun_code: "101801" },
  // { label: "GUINSILIBAN", prov_code: "1018", mun_code: "101802" },
  // { label: "MAHINOG", prov_code: "1018", mun_code: "101803" },
  // { label: "MAMBAJAO (Capital)", prov_code: "1018", mun_code: "101804" },
  // { label: "SAGAY", prov_code: "1018", mun_code: "101805" },
  // { label: "BACOLOD", prov_code: "1035", mun_code: "103501" },
  // { label: "BALOI", prov_code: "1035", mun_code: "103502" },
  // { label: "BAROY", prov_code: "1035", mun_code: "103503" },
  // { label: "ILIGAN CITY", prov_code: "1035", mun_code: "103504" },
  // { label: "KAPATAGAN", prov_code: "1035", mun_code: "103505" },
  // {
  //   label: "SULTAN NAGA DIMAPORO (KAROMATAN)",
  //   prov_code: "1035",
  //   mun_code: "103506",
  // },
  // { label: "KAUSWAGAN", prov_code: "1035", mun_code: "103507" },
  // { label: "KOLAMBUGAN", prov_code: "1035", mun_code: "103508" },
  // { label: "LALA", prov_code: "1035", mun_code: "103509" },
  // { label: "LINAMON", prov_code: "1035", mun_code: "103510" },
  // { label: "MAGSAYSAY", prov_code: "1035", mun_code: "103511" },
  // { label: "MAIGO", prov_code: "1035", mun_code: "103512" },
  // { label: "MATUNGAO", prov_code: "1035", mun_code: "103513" },
  // { label: "MUNAI", prov_code: "1035", mun_code: "103514" },
  // { label: "NUNUNGAN", prov_code: "1035", mun_code: "103515" },
  // { label: "PANTAO RAGAT", prov_code: "1035", mun_code: "103516" },
  // { label: "POONA PIAGAPO", prov_code: "1035", mun_code: "103517" },
  // { label: "SALVADOR", prov_code: "1035", mun_code: "103518" },
  // { label: "SAPAD", prov_code: "1035", mun_code: "103519" },
  // { label: "TAGOLOAN", prov_code: "1035", mun_code: "103520" },
  // { label: "TANGCAL", prov_code: "1035", mun_code: "103521" },
  // { label: "TUBOD (Capital)", prov_code: "1035", mun_code: "103522" },
  // { label: "PANTAR", prov_code: "1035", mun_code: "103523" },
  // { label: "ALORAN", prov_code: "1042", mun_code: "104201" },
  // { label: "BALIANGAO", prov_code: "1042", mun_code: "104202" },
  // { label: "BONIFACIO", prov_code: "1042", mun_code: "104203" },
  // { label: "CALAMBA", prov_code: "1042", mun_code: "104204" },
  // { label: "CLARIN", prov_code: "1042", mun_code: "104205" },
  // { label: "CONCEPCION", prov_code: "1042", mun_code: "104206" },
  // { label: "JIMENEZ", prov_code: "1042", mun_code: "104207" },
  // { label: "LOPEZ JAENA", prov_code: "1042", mun_code: "104208" },
  // {
  //   label: "OROQUIETA CITY (Capital)",
  //   prov_code: "1042",
  //   mun_code: "104209",
  // },
  // { label: "OZAMIS CITY", prov_code: "1042", mun_code: "104210" },
  // { label: "PANAON", prov_code: "1042", mun_code: "104211" },
  // { label: "PLARIDEL", prov_code: "1042", mun_code: "104212" },
  // { label: "SAPANG DALAGA", prov_code: "1042", mun_code: "104213" },
  // { label: "SINACABAN", prov_code: "1042", mun_code: "104214" },
  // { label: "TANGUB CITY", prov_code: "1042", mun_code: "104215" },
  // { label: "TUDELA", prov_code: "1042", mun_code: "104216" },
  // {
  //   label: "DON VICTORIANO CHIONGBIAN (DON MARIANO MARCOS)",
  //   prov_code: "1042",
  //   mun_code: "104217",
  // },
  // { label: "ALUBIJID", prov_code: "1043", mun_code: "104301" },
  // { label: "BALINGASAG", prov_code: "1043", mun_code: "104302" },
  // { label: "BALINGOAN", prov_code: "1043", mun_code: "104303" },
  // { label: "BINUANGAN", prov_code: "1043", mun_code: "104304" },
  // {
  //   label: "CAGAYAN DE ORO CITY (Capital)",
  //   prov_code: "1043",
  //   mun_code: "104305",
  // },
  // { label: "CLAVERIA", prov_code: "1043", mun_code: "104306" },
  // { label: "CITY OF EL SALVADOR", prov_code: "1043", mun_code: "104307" },
  // { label: "GINGOOG CITY", prov_code: "1043", mun_code: "104308" },
  // { label: "GITAGUM", prov_code: "1043", mun_code: "104309" },
  // { label: "INITAO", prov_code: "1043", mun_code: "104310" },
  // { label: "JASAAN", prov_code: "1043", mun_code: "104311" },
  // { label: "KINOGUITAN", prov_code: "1043", mun_code: "104312" },
  // { label: "LAGONGLONG", prov_code: "1043", mun_code: "104313" },
  // { label: "LAGUINDINGAN", prov_code: "1043", mun_code: "104314" },
  // { label: "LIBERTAD", prov_code: "1043", mun_code: "104315" },
  // { label: "LUGAIT", prov_code: "1043", mun_code: "104316" },
  // { label: "MAGSAYSAY (LINUGOS)", prov_code: "1043", mun_code: "104317" },
  // { label: "MANTICAO", prov_code: "1043", mun_code: "104318" },
  // { label: "MEDINA", prov_code: "1043", mun_code: "104319" },
  // { label: "NAAWAN", prov_code: "1043", mun_code: "104320" },
  // { label: "OPOL", prov_code: "1043", mun_code: "104321" },
  // { label: "SALAY", prov_code: "1043", mun_code: "104322" },
  // { label: "SUGBONGCOGON", prov_code: "1043", mun_code: "104323" },
  // { label: "TAGOLOAN", prov_code: "1043", mun_code: "104324" },
  // { label: "TALISAYAN", prov_code: "1043", mun_code: "104325" },
  // { label: "VILLANUEVA", prov_code: "1043", mun_code: "104326" },
  // { label: "ASUNCION (SAUG)", prov_code: "1123", mun_code: "112301" },
  // { label: "CARMEN", prov_code: "1123", mun_code: "112303" },
  // { label: "KAPALONG", prov_code: "1123", mun_code: "112305" },
  // { label: "NEW CORELLA", prov_code: "1123", mun_code: "112314" },
  // { label: "CITY OF PANABO", prov_code: "1123", mun_code: "112315" },
  // {
  //   label: "ISLAND GARDEN CITY OF SAMAL",
  //   prov_code: "1123",
  //   mun_code: "112317",
  // },
  // { label: "SANTO TOMAS", prov_code: "1123", mun_code: "112318" },
  // {
  //   label: "CITY OF TAGUM (Capital)",
  //   prov_code: "1123",
  //   mun_code: "112319",
  // },
  // { label: "TALAINGOD", prov_code: "1123", mun_code: "112322" },
  // { label: "BRAULIO E. DUJALI", prov_code: "1123", mun_code: "112323" },
  // { label: "SAN ISIDRO", prov_code: "1123", mun_code: "112324" },
  // { label: "BANSALAN", prov_code: "1124", mun_code: "112401" },
  // { label: "DAVAO CITY", prov_code: "1124", mun_code: "112402" },
  // {
  //   label: "CITY OF DIGOS (Capital)",
  //   prov_code: "1124",
  //   mun_code: "112403",
  // },
  // { label: "HAGONOY", prov_code: "1124", mun_code: "112404" },
  // { label: "KIBLAWAN", prov_code: "1124", mun_code: "112406" },
  // { label: "MAGSAYSAY", prov_code: "1124", mun_code: "112407" },
  // { label: "MALALAG", prov_code: "1124", mun_code: "112408" },
  // { label: "MATANAO", prov_code: "1124", mun_code: "112410" },
  // { label: "PADADA", prov_code: "1124", mun_code: "112411" },
  // { label: "SANTA CRUZ", prov_code: "1124", mun_code: "112412" },
  // { label: "SULOP", prov_code: "1124", mun_code: "112414" },
  // { label: "BAGANGA", prov_code: "1125", mun_code: "112501" },
  // { label: "BANAYBANAY", prov_code: "1125", mun_code: "112502" },
  // { label: "BOSTON", prov_code: "1125", mun_code: "112503" },
  // { label: "CARAGA", prov_code: "1125", mun_code: "112504" },
  // { label: "CATEEL", prov_code: "1125", mun_code: "112505" },
  // { label: "GOVERNOR GENEROSO", prov_code: "1125", mun_code: "112506" },
  // { label: "LUPON", prov_code: "1125", mun_code: "112507" },
  // { label: "MANAY", prov_code: "1125", mun_code: "112508" },
  // {
  //   label: "CITY OF MATI (Capital)",
  //   prov_code: "1125",
  //   mun_code: "112509",
  // },
  // { label: "SAN ISIDRO", prov_code: "1125", mun_code: "112510" },
  // { label: "TARRAGONA", prov_code: "1125", mun_code: "112511" },
  // { label: "COMPOSTELA", prov_code: "1182", mun_code: "118201" },
  // { label: "LAAK (SAN VICENTE)", prov_code: "1182", mun_code: "118202" },
  // { label: "MACO", prov_code: "1182", mun_code: "118204" },
  // {
  //   label: "MARAGUSAN (SAN MARIANO)",
  //   prov_code: "1182",
  //   mun_code: "118205",
  // },
  // { label: "MAWAB", prov_code: "1182", mun_code: "118206" },
  // { label: "MONKAYO", prov_code: "1182", mun_code: "118207" },
  // { label: "MONTEVISTA", prov_code: "1182", mun_code: "118208" },
  // { label: "NABUNTURAN (Capital)", prov_code: "1182", mun_code: "118209" },
  // { label: "NEW BATAAN", prov_code: "1182", mun_code: "118210" },
  // { label: "PANTUKAN", prov_code: "1182", mun_code: "118211" },
  // { label: "DON MARCELINO", prov_code: "1186", mun_code: "118601" },
  // {
  //   label: "JOSE ABAD SANTOS (TRINIDAD)",
  //   prov_code: "1186",
  //   mun_code: "118602",
  // },
  // { label: "MALITA", prov_code: "1186", mun_code: "118603" },
  // { label: "SANTA MARIA", prov_code: "1186", mun_code: "118604" },
  // { label: "SARANGANI", prov_code: "1186", mun_code: "118605" },
  // { label: "ALAMADA", prov_code: "1247", mun_code: "124701" },
  // { label: "CARMEN", prov_code: "1247", mun_code: "124702" },
  // { label: "KABACAN", prov_code: "1247", mun_code: "124703" },
  // {
  //   label: "CITY OF KIDAPAWAN (Capital)",
  //   prov_code: "1247",
  //   mun_code: "124704",
  // },
  // { label: "LIBUNGAN", prov_code: "1247", mun_code: "124705" },
  // { label: "MAGPET", prov_code: "1247", mun_code: "124706" },
  // { label: "MAKILALA", prov_code: "1247", mun_code: "124707" },
  // { label: "MATALAM", prov_code: "1247", mun_code: "124708" },
  // { label: "MIDSAYAP", prov_code: "1247", mun_code: "124709" },
  // { label: "M'LANG", prov_code: "1247", mun_code: "124710" },
  // { label: "PIGKAWAYAN", prov_code: "1247", mun_code: "124711" },
  // { label: "PIKIT", prov_code: "1247", mun_code: "124712" },
  // { label: "PRESIDENT ROXAS", prov_code: "1247", mun_code: "124713" },
  // { label: "TULUNAN", prov_code: "1247", mun_code: "124714" },
  // { label: "ANTIPAS", prov_code: "1247", mun_code: "124715" },
  // { label: "BANISILAN", prov_code: "1247", mun_code: "124716" },
  // { label: "ALEOSAN", prov_code: "1247", mun_code: "124717" },
  // { label: "ARAKAN", prov_code: "1247", mun_code: "124718" },
  // { label: "BANGA", prov_code: "1263", mun_code: "126302" },
  // {
  //   label: "GENERAL SANTOS CITY (DADIANGAS)",
  //   prov_code: "1263",
  //   mun_code: "126303",
  // },
  // {
  //   label: "CITY OF KORONADAL (Capital)",
  //   prov_code: "1263",
  //   mun_code: "126306",
  // },
  // { label: "NORALA", prov_code: "1263", mun_code: "126311" },
  // { label: "POLOMOLOK", prov_code: "1263", mun_code: "126312" },
  // { label: "SURALLAH", prov_code: "1263", mun_code: "126313" },
  // { label: "TAMPAKAN", prov_code: "1263", mun_code: "126314" },
  // { label: "TANTANGAN", prov_code: "1263", mun_code: "126315" },
  // { label: "T'BOLI", prov_code: "1263", mun_code: "126316" },
  // { label: "TUPI", prov_code: "1263", mun_code: "126317" },
  // { label: "LAKE SEBU", prov_code: "1263", mun_code: "126319" },
  // { label: "BAGUMBAYAN", prov_code: "1265", mun_code: "126501" },
  // { label: "COLUMBIO", prov_code: "1265", mun_code: "126502" },
  // { label: "ESPERANZA", prov_code: "1265", mun_code: "126503" },
  // { label: "ISULAN (Capital)", prov_code: "1265", mun_code: "126504" },
  // { label: "KALAMANSIG", prov_code: "1265", mun_code: "126505" },
  // { label: "LEBAK", prov_code: "1265", mun_code: "126506" },
  // { label: "LUTAYAN", prov_code: "1265", mun_code: "126507" },
  // {
  //   label: "LAMBAYONG (MARIANO MARCOS)",
  //   prov_code: "1265",
  //   mun_code: "126508",
  // },
  // { label: "PALIMBANG", prov_code: "1265", mun_code: "126509" },
  // { label: "PRESIDENT QUIRINO", prov_code: "1265", mun_code: "126510" },
  // { label: "CITY OF TACURONG", prov_code: "1265", mun_code: "126511" },
  // { label: "SEN. NINOY AQUINO", prov_code: "1265", mun_code: "126512" },
  // { label: "Alabel (Capital)", prov_code: "1280", mun_code: "128001" },
  // { label: "GLAN", prov_code: "1280", mun_code: "128002" },
  // { label: "KIAMBA", prov_code: "1280", mun_code: "128003" },
  // { label: "MAASIM", prov_code: "1280", mun_code: "128004" },
  // { label: "MAITUM", prov_code: "1280", mun_code: "128005" },
  // { label: "MALAPATAN", prov_code: "1280", mun_code: "128006" },
  // { label: "MALUNGON", prov_code: "1280", mun_code: "128007" },
  // { label: "COTABATO CITY", prov_code: "1298", mun_code: "129804" },
  // { label: "TONDO I / II", prov_code: "1339", mun_code: "133901" },
  // { label: "BINONDO", prov_code: "1339", mun_code: "133902" },
  // { label: "QUIAPO", prov_code: "1339", mun_code: "133903" },
  // { label: "SAN NICOLAS", prov_code: "1339", mun_code: "133904" },
  // { label: "SANTA CRUZ", prov_code: "1339", mun_code: "133905" },
  // { label: "SAMPALOC", prov_code: "1339", mun_code: "133906" },
  // { label: "SAN MIGUEL", prov_code: "1339", mun_code: "133907" },
  // { label: "ERMITA", prov_code: "1339", mun_code: "133908" },
  // { label: "INTRAMUROS", prov_code: "1339", mun_code: "133909" },
  // { label: "MALATE", prov_code: "1339", mun_code: "133910" },
  // { label: "PACO", prov_code: "1339", mun_code: "133911" },
  // { label: "PANDACAN", prov_code: "1339", mun_code: "133912" },
  // { label: "PORT AREA", prov_code: "1339", mun_code: "133913" },
  // { label: "SANTA ANA", prov_code: "1339", mun_code: "133914" },
  // { label: "CITY OF MANDALUYONG", prov_code: "1374", mun_code: "137401" },
  // { label: "CITY OF MARIKINA", prov_code: "1374", mun_code: "137402" },
  // { label: "CITY OF PASIG", prov_code: "1374", mun_code: "137403" },
  // { label: "QUEZON CITY", prov_code: "1374", mun_code: "137404" },
  // { label: "CITY OF SAN JUAN", prov_code: "1374", mun_code: "137405" },
  // { label: "CALOOCAN CITY", prov_code: "1375", mun_code: "137501" },
  // { label: "CITY OF MALABON", prov_code: "1375", mun_code: "137502" },
  // { label: "CITY OF NAVOTAS", prov_code: "1375", mun_code: "137503" },
  // { label: "CITY OF VALENZUELA", prov_code: "1375", mun_code: "137504" },
  // { label: "CITY OF LAS PIÑAS", prov_code: "1376", mun_code: "137601" },
  // { label: "CITY OF MAKATI", prov_code: "1376", mun_code: "137602" },
  // { label: "CITY OF MUNTINLUPA", prov_code: "1376", mun_code: "137603" },
  // { label: "CITY OF PARAÑAQUE", prov_code: "1376", mun_code: "137604" },
  // { label: "PASAY CITY", prov_code: "1376", mun_code: "137605" },
  // { label: "PATEROS", prov_code: "1376", mun_code: "137606" },
  // { label: "TAGUIG CITY", prov_code: "1376", mun_code: "137607" },
  // { label: "BANGUED (Capital)", prov_code: "1401", mun_code: "140101" },
  // { label: "BOLINEY", prov_code: "1401", mun_code: "140102" },
  // { label: "BUCAY", prov_code: "1401", mun_code: "140103" },
  // { label: "BUCLOC", prov_code: "1401", mun_code: "140104" },
  // { label: "DAGUIOMAN", prov_code: "1401", mun_code: "140105" },
  // { label: "DANGLAS", prov_code: "1401", mun_code: "140106" },
  // { label: "DOLORES", prov_code: "1401", mun_code: "140107" },
  // { label: "LA PAZ", prov_code: "1401", mun_code: "140108" },
  // { label: "LACUB", prov_code: "1401", mun_code: "140109" },
  // { label: "LAGANGILANG", prov_code: "1401", mun_code: "140110" },
  // { label: "LAGAYAN", prov_code: "1401", mun_code: "140111" },
  // { label: "LANGIDEN", prov_code: "1401", mun_code: "140112" },
  // { label: "LICUAN-BAAY (LICUAN)", prov_code: "1401", mun_code: "140113" },
  // { label: "LUBA", prov_code: "1401", mun_code: "140114" },
  // { label: "MALIBCONG", prov_code: "1401", mun_code: "140115" },
  // { label: "MANABO", prov_code: "1401", mun_code: "140116" },
  // { label: "PIDIGAN", prov_code: "1401", mun_code: "140118" },
  // { label: "PILAR", prov_code: "1401", mun_code: "140119" },
  // { label: "SALLAPADAN", prov_code: "1401", mun_code: "140120" },
  // { label: "SAN ISIDRO", prov_code: "1401", mun_code: "140121" },
  // { label: "SAN JUAN", prov_code: "1401", mun_code: "140122" },
  // { label: "SAN QUINTIN", prov_code: "1401", mun_code: "140123" },
  // { label: "TAYUM", prov_code: "1401", mun_code: "140124" },
  // { label: "TINEG", prov_code: "1401", mun_code: "140125" },
  // { label: "TUBO", prov_code: "1401", mun_code: "140126" },
  // { label: "VILLAVICIOSA", prov_code: "1401", mun_code: "140127" },
  // { label: "ATOK", prov_code: "1411", mun_code: "141101" },
  // { label: "BAGUIO CITY", prov_code: "1411", mun_code: "141102" },
  // { label: "BAKUN", prov_code: "1411", mun_code: "141103" },
  // { label: "BOKOD", prov_code: "1411", mun_code: "141104" },
  // { label: "BUGUIAS", prov_code: "1411", mun_code: "141105" },
  // { label: "ITOGON", prov_code: "1411", mun_code: "141106" },
  // { label: "KABAYAN", prov_code: "1411", mun_code: "141107" },
  // { label: "KAPANGAN", prov_code: "1411", mun_code: "141108" },
  // { label: "KIBUNGAN", prov_code: "1411", mun_code: "141109" },
  // {
  //   label: "LA TRINIDAD (Capital)",
  //   prov_code: "1411",
  //   mun_code: "141110",
  // },
  // { label: "MANKAYAN", prov_code: "1411", mun_code: "141111" },
  // { label: "SABLAN", prov_code: "1411", mun_code: "141112" },
  // { label: "TUBA", prov_code: "1411", mun_code: "141113" },
  // { label: "TUBLAY", prov_code: "1411", mun_code: "141114" },
  // { label: "BANAUE", prov_code: "1427", mun_code: "142701" },
  // { label: "HUNGDUAN", prov_code: "1427", mun_code: "142702" },
  // { label: "KIANGAN", prov_code: "1427", mun_code: "142703" },
  // { label: "LAGAWE (Capital)", prov_code: "1427", mun_code: "142704" },
  // { label: "LAMUT", prov_code: "1427", mun_code: "142705" },
  // { label: "MAYOYAO", prov_code: "1427", mun_code: "142706" },
  // {
  //   label: "ALFONSO LISTA (POTIA)",
  //   prov_code: "1427",
  //   mun_code: "142707",
  // },
  // { label: "AGUINALDO", prov_code: "1427", mun_code: "142708" },
  // { label: "HINGYON", prov_code: "1427", mun_code: "142709" },
  // { label: "TINOC", prov_code: "1427", mun_code: "142710" },
  // { label: "ASIPULO", prov_code: "1427", mun_code: "142711" },
  // { label: "BALBALAN", prov_code: "1432", mun_code: "143201" },
  // { label: "LUBUAGAN", prov_code: "1432", mun_code: "143206" },
  // { label: "PASIL", prov_code: "1432", mun_code: "143208" },
  // { label: "PINUKPUK", prov_code: "1432", mun_code: "143209" },
  // { label: "RIZAL (LIWAN)", prov_code: "1432", mun_code: "143211" },
  // {
  //   label: "CITY OF TABUK (Capital)",
  //   prov_code: "1432",
  //   mun_code: "143213",
  // },
  // { label: "TANUDAN", prov_code: "1432", mun_code: "143214" },
  // { label: "TINGLAYAN", prov_code: "1432", mun_code: "143215" },
  // { label: "BARLIG", prov_code: "1444", mun_code: "144401" },
  // { label: "BAUKO", prov_code: "1444", mun_code: "144402" },
  // { label: "BESAO", prov_code: "1444", mun_code: "144403" },
  // { label: "BONTOC (Capital)", prov_code: "1444", mun_code: "144404" },
  // { label: "NATONIN", prov_code: "1444", mun_code: "144405" },
  // { label: "PARACELIS", prov_code: "1444", mun_code: "144406" },
  // { label: "SABANGAN", prov_code: "1444", mun_code: "144407" },
  // { label: "SADANGA", prov_code: "1444", mun_code: "144408" },
  // { label: "SAGADA", prov_code: "1444", mun_code: "144409" },
  // { label: "TADIAN", prov_code: "1444", mun_code: "144410" },
  // { label: "CALANASAN (BAYAG)", prov_code: "1481", mun_code: "148101" },
  // { label: "CONNER", prov_code: "1481", mun_code: "148102" },
  // { label: "FLORA", prov_code: "1481", mun_code: "148103" },
  // { label: "KABUGAO (Capital)", prov_code: "1481", mun_code: "148104" },
  // { label: "LUNA", prov_code: "1481", mun_code: "148105" },
  // { label: "PUDTOL", prov_code: "1481", mun_code: "148106" },
  // { label: "SANTA MARCELA", prov_code: "1481", mun_code: "148107" },
  // { label: "CITY OF LAMITAN", prov_code: "1507", mun_code: "150702" },
  // { label: "LANTAWAN", prov_code: "1507", mun_code: "150703" },
  // { label: "MALUSO", prov_code: "1507", mun_code: "150704" },
  // { label: "SUMISIP", prov_code: "1507", mun_code: "150705" },
  // { label: "TIPO-TIPO", prov_code: "1507", mun_code: "150706" },
  // { label: "TUBURAN", prov_code: "1507", mun_code: "150707" },
  // { label: "AKBAR", prov_code: "1507", mun_code: "150708" },
  // { label: "AL-BARKA", prov_code: "1507", mun_code: "150709" },
  // { label: "HADJI MOHAMMAD AJUL", prov_code: "1507", mun_code: "150710" },
  // { label: "UNGKAYA PUKAN", prov_code: "1507", mun_code: "150711" },
  // { label: "HADJI MUHTAMAD", prov_code: "1507", mun_code: "150712" },
  // { label: "TABUAN-LASA", prov_code: "1507", mun_code: "150713" },
  // {
  //   label: "BACOLOD-KALAWI (BACOLOD GRANDE)",
  //   prov_code: "1536",
  //   mun_code: "153601",
  // },
  // { label: "BALABAGAN", prov_code: "1536", mun_code: "153602" },
  // { label: "BALINDONG (WATU)", prov_code: "1536", mun_code: "153603" },
  // { label: "BAYANG", prov_code: "1536", mun_code: "153604" },
  // { label: "BINIDAYAN", prov_code: "1536", mun_code: "153605" },
  // { label: "BUBONG", prov_code: "1536", mun_code: "153606" },
  // { label: "BUTIG", prov_code: "1536", mun_code: "153607" },
  // { label: "GANASSI", prov_code: "1536", mun_code: "153609" },
  // { label: "KAPAI", prov_code: "1536", mun_code: "153610" },
  // {
  //   label: "LUMBA-BAYABAO (MAGUING)",
  //   prov_code: "1536",
  //   mun_code: "153611",
  // },
  // { label: "LUMBATAN", prov_code: "1536", mun_code: "153612" },
  // { label: "MADALUM", prov_code: "1536", mun_code: "153613" },
  // { label: "MADAMBA", prov_code: "1536", mun_code: "153614" },
  // { label: "MALABANG", prov_code: "1536", mun_code: "153615" },
  // { label: "MARANTAO", prov_code: "1536", mun_code: "153616" },
  // {
  //   label: "MARAWI CITY (Capital)",
  //   prov_code: "1536",
  //   mun_code: "153617",
  // },
  // { label: "MASIU", prov_code: "1536", mun_code: "153618" },
  // { label: "MULONDO", prov_code: "1536", mun_code: "153619" },
  // {
  //   label: "PAGAYAWAN (TATARIKAN)",
  //   prov_code: "1536",
  //   mun_code: "153620",
  // },
  // { label: "PIAGAPO", prov_code: "1536", mun_code: "153621" },
  // { label: "POONA BAYABAO (GATA)", prov_code: "1536", mun_code: "153622" },
  // { label: "PUALAS", prov_code: "1536", mun_code: "153623" },
  // { label: "DITSAAN-RAMAIN", prov_code: "1536", mun_code: "153624" },
  // { label: "SAGUIARAN", prov_code: "1536", mun_code: "153625" },
  // { label: "TAMPARAN", prov_code: "1536", mun_code: "153626" },
  // { label: "TARAKA", prov_code: "1536", mun_code: "153627" },
  // { label: "TUBARAN", prov_code: "1536", mun_code: "153628" },
  // { label: "TUGAYA", prov_code: "1536", mun_code: "153629" },
  // { label: "WAO", prov_code: "1536", mun_code: "153630" },
  // { label: "MAROGONG", prov_code: "1536", mun_code: "153631" },
  // { label: "CALANOGAS", prov_code: "1536", mun_code: "153632" },
  // { label: "BUADIPOSO-BUNTONG", prov_code: "1536", mun_code: "153633" },
  // { label: "MAGUING", prov_code: "1536", mun_code: "153634" },
  // {
  //   label: "PICONG (SULTAN GUMANDER)",
  //   prov_code: "1536",
  //   mun_code: "153635",
  // },
  // { label: "LUMBAYANAGUE", prov_code: "1536", mun_code: "153636" },
  // { label: "BUMBARAN", prov_code: "1536", mun_code: "153637" },
  // { label: "TAGOLOAN II", prov_code: "1536", mun_code: "153638" },
  // { label: "KAPATAGAN", prov_code: "1536", mun_code: "153639" },
  // { label: "SULTAN DUMALONDONG", prov_code: "1536", mun_code: "153640" },
  // { label: "LUMBACA-UNAYAN", prov_code: "1536", mun_code: "153641" },
  // { label: "AMPATUAN", prov_code: "1538", mun_code: "153801" },
  // { label: "BULDON", prov_code: "1538", mun_code: "153802" },
  // { label: "BULUAN", prov_code: "1538", mun_code: "153803" },
  // { label: "DATU PAGLAS", prov_code: "1538", mun_code: "153805" },
  // { label: "DATU PIANG", prov_code: "1538", mun_code: "153806" },
  // {
  //   label: "DATU ODIN SINSUAT (DINAIG)",
  //   prov_code: "1538",
  //   mun_code: "153807",
  // },
  // {
  //   label: "SHARIFF AGUAK (MAGANOY) (Capital)",
  //   prov_code: "1538",
  //   mun_code: "153808",
  // },
  // { label: "MATANOG", prov_code: "1538", mun_code: "153809" },
  // { label: "PAGALUNGAN", prov_code: "1538", mun_code: "153810" },
  // { label: "PARANG", prov_code: "1538", mun_code: "153811" },
  // {
  //   label: "SULTAN KUDARAT (NULING)",
  //   prov_code: "1538",
  //   mun_code: "153812",
  // },
  // {
  //   label: "SULTAN SA BARONGIS (LAMBAYONG)",
  //   prov_code: "1538",
  //   mun_code: "153813",
  // },
  // { label: "KABUNTALAN (TUMBAO)", prov_code: "1538", mun_code: "153814" },
  // { label: "UPI", prov_code: "1538", mun_code: "153815" },
  // { label: "TALAYAN", prov_code: "1538", mun_code: "153816" },
  // { label: "SOUTH UPI", prov_code: "1538", mun_code: "153817" },
  // { label: "BARIRA", prov_code: "1538", mun_code: "153818" },
  // { label: "GEN. S. K. PENDATUN", prov_code: "1538", mun_code: "153819" },
  // { label: "MAMASAPANO", prov_code: "1538", mun_code: "153820" },
  // { label: "TALITAY", prov_code: "1538", mun_code: "153821" },
  // { label: "PAGAGAWAN", prov_code: "1538", mun_code: "153822" },
  // { label: "PAGLAT", prov_code: "1538", mun_code: "153823" },
  // { label: "SULTAN MASTURA", prov_code: "1538", mun_code: "153824" },
  // { label: "GUINDULUNGAN", prov_code: "1538", mun_code: "153825" },
  // { label: "DATU SAUDI-AMPATUAN", prov_code: "1538", mun_code: "153826" },
  // { label: "DATU UNSAY", prov_code: "1538", mun_code: "153827" },
  // { label: "DATU ABDULLAH SANGKI", prov_code: "1538", mun_code: "153828" },
  // { label: "RAJAH BUAYAN", prov_code: "1538", mun_code: "153829" },
  // { label: "DATU BLAH T. SINSUAT", prov_code: "1538", mun_code: "153830" },
  // {
  //   label: "DATU ANGGAL MIDTIMBANG",
  //   prov_code: "1538",
  //   mun_code: "153831",
  // },
  // { label: "MANGUDADATU", prov_code: "1538", mun_code: "153832" },
  // { label: "PANDAG", prov_code: "1538", mun_code: "153833" },
  // { label: "NORTHERN KABUNTALAN", prov_code: "1538", mun_code: "153834" },
  // { label: "DATU HOFFER AMPATUAN", prov_code: "1538", mun_code: "153835" },
  // { label: "DATU SALIBO", prov_code: "1538", mun_code: "153836" },
  // {
  //   label: "SHARIFF SAYDONA MUSTAPHA",
  //   prov_code: "1538",
  //   mun_code: "153837",
  // },
  // { label: "INDANAN", prov_code: "1566", mun_code: "156601" },
  // { label: "JOLO (Capital)", prov_code: "1566", mun_code: "156602" },
  // { label: "KALINGALAN CALUANG", prov_code: "1566", mun_code: "156603" },
  // { label: "LUUK", prov_code: "1566", mun_code: "156604" },
  // { label: "MAIMBUNG", prov_code: "1566", mun_code: "156605" },
  // {
  //   label: "HADJI PANGLIMA TAHIL (MARUNGGAS)",
  //   prov_code: "1566",
  //   mun_code: "156606",
  // },
  // { label: "OLD PANAMAO", prov_code: "1566", mun_code: "156607" },
  // { label: "PANGUTARAN", prov_code: "1566", mun_code: "156608" },
  // { label: "PARANG", prov_code: "1566", mun_code: "156609" },
  // { label: "PATA", prov_code: "1566", mun_code: "156610" },
  // { label: "PATIKUL", prov_code: "1566", mun_code: "156611" },
  // { label: "SIASI", prov_code: "1566", mun_code: "156612" },
  // { label: "TALIPAO", prov_code: "1566", mun_code: "156613" },
  // { label: "TAPUL", prov_code: "1566", mun_code: "156614" },
  // { label: "TONGKIL", prov_code: "1566", mun_code: "156615" },
  // {
  //   label: "PANGLIMA ESTINO (NEW PANAMAO)",
  //   prov_code: "1566",
  //   mun_code: "156616",
  // },
  // { label: "LUGUS", prov_code: "1566", mun_code: "156617" },
  // { label: "PANDAMI", prov_code: "1566", mun_code: "156618" },
  // { label: "OMAR", prov_code: "1566", mun_code: "156619" },
  // {
  //   label: "PANGLIMA SUGALA (BALIMBING)",
  //   prov_code: "1570",
  //   mun_code: "157001",
  // },
  // { label: "BONGAO (Capital)", prov_code: "1570", mun_code: "157002" },
  // {
  //   label: "MAPUN (CAGAYAN DE TAWI-TAWI)",
  //   prov_code: "1570",
  //   mun_code: "157003",
  // },
  // { label: "SIMUNUL", prov_code: "1570", mun_code: "157004" },
  // { label: "SITANGKAI", prov_code: "1570", mun_code: "157005" },
  // { label: "SOUTH UBIAN", prov_code: "1570", mun_code: "157006" },
  // { label: "TANDUBAS", prov_code: "1570", mun_code: "157007" },
  // { label: "TURTLE ISLANDS", prov_code: "1570", mun_code: "157008" },
  // { label: "LANGUYAN", prov_code: "1570", mun_code: "157009" },
  // { label: "SAPA-SAPA", prov_code: "1570", mun_code: "157010" },
  // { label: "SIBUTU", prov_code: "1570", mun_code: "157011" },
  // { label: "BUENAVISTA", prov_code: "1602", mun_code: "160201" },
  // {
  //   label: "BUTUAN CITY (Capital)",
  //   prov_code: "1602",
  //   mun_code: "160202",
  // },
  // { label: "CITY OF CABADBARAN", prov_code: "1602", mun_code: "160203" },
  // { label: "CARMEN", prov_code: "1602", mun_code: "160204" },
  // { label: "JABONGA", prov_code: "1602", mun_code: "160205" },
  // { label: "KITCHARAO", prov_code: "1602", mun_code: "160206" },
  // { label: "LAS NIEVES", prov_code: "1602", mun_code: "160207" },
  // { label: "MAGALLANES", prov_code: "1602", mun_code: "160208" },
  // { label: "NASIPIT", prov_code: "1602", mun_code: "160209" },
  // { label: "SANTIAGO", prov_code: "1602", mun_code: "160210" },
  // { label: "TUBAY", prov_code: "1602", mun_code: "160211" },
  // {
  //   label: "REMEDIOS T. ROMUALDEZ",
  //   prov_code: "1602",
  //   mun_code: "160212",
  // },
  // { label: "CITY OF BAYUGAN", prov_code: "1603", mun_code: "160301" },
  // { label: "BUNAWAN", prov_code: "1603", mun_code: "160302" },
  // { label: "ESPERANZA", prov_code: "1603", mun_code: "160303" },
  // { label: "LA PAZ", prov_code: "1603", mun_code: "160304" },
  // { label: "LORETO", prov_code: "1603", mun_code: "160305" },
  // {
  //   label: "PROSPERIDAD (Capital)",
  //   prov_code: "1603",
  //   mun_code: "160306",
  // },
  // { label: "ROSARIO", prov_code: "1603", mun_code: "160307" },
  // { label: "SAN FRANCISCO", prov_code: "1603", mun_code: "160308" },
  // { label: "SAN LUIS", prov_code: "1603", mun_code: "160309" },
  // { label: "SANTA JOSEFA", prov_code: "1603", mun_code: "160310" },
  // { label: "TALACOGON", prov_code: "1603", mun_code: "160311" },
  // { label: "TRENTO", prov_code: "1603", mun_code: "160312" },
  // { label: "VERUELA", prov_code: "1603", mun_code: "160313" },
  // { label: "SIBAGAT", prov_code: "1603", mun_code: "160314" },
  // { label: "ALEGRIA", prov_code: "1667", mun_code: "166701" },
  // { label: "BACUAG", prov_code: "1667", mun_code: "166702" },
  // { label: "BURGOS", prov_code: "1667", mun_code: "166704" },
  // { label: "CLAVER", prov_code: "1667", mun_code: "166706" },
  // { label: "DAPA", prov_code: "1667", mun_code: "166707" },
  // { label: "DEL CARMEN", prov_code: "1667", mun_code: "166708" },
  // { label: "GENERAL LUNA", prov_code: "1667", mun_code: "166710" },
  // { label: "GIGAQUIT", prov_code: "1667", mun_code: "166711" },
  // { label: "MAINIT", prov_code: "1667", mun_code: "166714" },
  // { label: "MALIMONO", prov_code: "1667", mun_code: "166715" },
  // { label: "PILAR", prov_code: "1667", mun_code: "166716" },
  // { label: "PLACER", prov_code: "1667", mun_code: "166717" },
  // { label: "SAN BENITO", prov_code: "1667", mun_code: "166718" },
  // {
  //   label: "SAN FRANCISCO (ANAO-AON)",
  //   prov_code: "1667",
  //   mun_code: "166719",
  // },
  // { label: "SAN ISIDRO", prov_code: "1667", mun_code: "166720" },
  // { label: "SANTA MONICA (SAPAO)", prov_code: "1667", mun_code: "166721" },
  // { label: "SISON", prov_code: "1667", mun_code: "166722" },
  // { label: "SOCORRO", prov_code: "1667", mun_code: "166723" },
  // {
  //   label: "SURIGAO CITY (Capital)",
  //   prov_code: "1667",
  //   mun_code: "166724",
  // },
  // { label: "TAGANA-AN", prov_code: "1667", mun_code: "166725" },
  // { label: "TUBOD", prov_code: "1667", mun_code: "166727" },
  // { label: "BAROBO", prov_code: "1668", mun_code: "166801" },
  // { label: "BAYABAS", prov_code: "1668", mun_code: "166802" },
  // { label: "CITY OF BISLIG", prov_code: "1668", mun_code: "166803" },
  // { label: "CAGWAIT", prov_code: "1668", mun_code: "166804" },
  // { label: "CANTILAN", prov_code: "1668", mun_code: "166805" },
  // { label: "CARMEN", prov_code: "1668", mun_code: "166806" },
  // { label: "CARRASCAL", prov_code: "1668", mun_code: "166807" },
  // { label: "CORTES", prov_code: "1668", mun_code: "166808" },
  // { label: "HINATUAN", prov_code: "1668", mun_code: "166809" },
  // { label: "LANUZA", prov_code: "1668", mun_code: "166810" },
  // { label: "LIANGA", prov_code: "1668", mun_code: "166811" },
  // { label: "LINGIG", prov_code: "1668", mun_code: "166812" },
  // { label: "MADRID", prov_code: "1668", mun_code: "166813" },
  // { label: "MARIHATAG", prov_code: "1668", mun_code: "166814" },
  // { label: "SAN AGUSTIN", prov_code: "1668", mun_code: "166815" },
  // { label: "SAN MIGUEL", prov_code: "1668", mun_code: "166816" },
  // { label: "TAGBINA", prov_code: "1668", mun_code: "166817" },
  // { label: "TAGO", prov_code: "1668", mun_code: "166818" },
  // {
  //   label: "CITY OF TANDAG (Capital)",
  //   prov_code: "1668",
  //   mun_code: "166819",
  // },
  // { label: "BASILISA (RIZAL)", prov_code: "1685", mun_code: "168501" },
  // { label: "CAGDIANAO", prov_code: "1685", mun_code: "168502" },
  // { label: "DINAGAT", prov_code: "1685", mun_code: "168503" },
  // { label: "LIBJO (ALBOR)", prov_code: "1685", mun_code: "168504" },
  // { label: "LORETO", prov_code: "1685", mun_code: "168505" },
  // { label: "SAN JOSE (Capital)", prov_code: "1685", mun_code: "168506" },
  // { label: "TUBAJON", prov_code: "1685", mun_code: "168507" },
];

const barangays = [
  // { label: "Adams (Pob.)", mun_code: "012801" },
  // { label: "Bani", mun_code: "012802" },
  // { label: "Buyon", mun_code: "012802" },
  // { label: "Cabaruan", mun_code: "012802" },
  // { label: "Cabulalaan", mun_code: "012802" },
  // { label: "Cabusligan", mun_code: "012802" },
  // { label: "Cadaratan", mun_code: "012802" },
  // { label: "Calioet-Libong", mun_code: "012802" },
  // { label: "Casilian", mun_code: "012802" },
  // { label: "Corocor", mun_code: "012802" },
  // { label: "Duripes", mun_code: "012802" },
  // { label: "Ganagan", mun_code: "012802" },
  // { label: "Libtong", mun_code: "012802" },
  // { label: "Macupit", mun_code: "012802" },
  // { label: "Nambaran", mun_code: "012802" },
  // { label: "Natba", mun_code: "012802" },
  // { label: "Paninaan", mun_code: "012802" },
  // { label: "Pasiocan", mun_code: "012802" },
  // { label: "Pasngal", mun_code: "012802" },
  // { label: "Pipias", mun_code: "012802" },
  // { label: "Pulangi", mun_code: "012802" },
  // { label: "Pungto", mun_code: "012802" },
  // { label: "San Agustin I (Pob.)", mun_code: "012802" },
  // { label: "San Agustin II (Pob.)", mun_code: "012802" },
  // { label: "San Andres I (Pob.)", mun_code: "012802" },
  // { label: "San Andres II (Pob.)", mun_code: "012802" },
  // { label: "San Gabriel I (Pob.)", mun_code: "012802" },
  // { label: "San Gabriel II (Pob.)", mun_code: "012802" },
  // { label: "San Pedro I (Pob.)", mun_code: "012802" },
  // { label: "San Pedro II (Pob.)", mun_code: "012802" },
  // { label: "San Roque I (Pob.)", mun_code: "012802" },
  // { label: "San Roque II (Pob.)", mun_code: "012802" },
  // { label: "San Simon I (Pob.)", mun_code: "012802" },
  // { label: "San Simon II (Pob.)", mun_code: "012802" },
  // { label: "San Vicente (Pob.)", mun_code: "012802" },
  // { label: "Sangil", mun_code: "012802" },
  // { label: "Santa Filomena I (Pob.)", mun_code: "012802" },
  // { label: "Santa Filomena II (Pob.)", mun_code: "012802" },
  // { label: "Santa Rita (Pob.)", mun_code: "012802" },
  // { label: "Santo Cristo I (Pob.)", mun_code: "012802" },
  // { label: "Santo Cristo II (Pob.)", mun_code: "012802" },
  // { label: "Tambidao", mun_code: "012802" },
  // { label: "Teppang", mun_code: "012802" },
  // { label: "Tubburan", mun_code: "012802" },
  // { label: "Alay-Nangbabaan (Alay 15-B)", mun_code: "012803" },
  // { label: "Alogoog", mun_code: "012803" },
  // { label: "Ar-arusip", mun_code: "012803" },
  // { label: "Aring", mun_code: "012803" },
  // { label: "Balbaldez", mun_code: "012803" },
  // { label: "Bato", mun_code: "012803" },
  // { label: "Camanga", mun_code: "012803" },
  // { label: "Canaan (Pob.)", mun_code: "012803" },
  // { label: "Caraitan", mun_code: "012803" },
  // { label: "Gabut Norte", mun_code: "012803" },
  // { label: "Gabut Sur", mun_code: "012803" },
  // { label: "Garreta (Pob.)", mun_code: "012803" },
  // { label: "Labut", mun_code: "012803" },
  // { label: "Lacuben", mun_code: "012803" },
  // { label: "Lubigan", mun_code: "012803" },
  // { label: "Mabusag Norte", mun_code: "012803" },
  // { label: "Mabusag Sur", mun_code: "012803" },
  // { label: "Madupayas", mun_code: "012803" },
  // { label: "Morong", mun_code: "012803" },
  // { label: "Nagrebcan", mun_code: "012803" },
  // { label: "Napu", mun_code: "012803" },
  // { label: "La Virgen Milagrosa (Paguetpet)", mun_code: "012803" },
  // { label: "Pagsanahan Norte", mun_code: "012803" },
  // { label: "Pagsanahan Sur", mun_code: "012803" },
  // { label: "Paltit", mun_code: "012803" },
  // { label: "Parang", mun_code: "012803" },
  // { label: "Pasuc", mun_code: "012803" },
  // { label: "Santa Cruz Norte", mun_code: "012803" },
  // { label: "Santa Cruz Sur", mun_code: "012803" },
  // { label: "Saud", mun_code: "012803" },
  // { label: "Turod", mun_code: "012803" },
  // { label: "Abaca", mun_code: "012804" },
  // { label: "Bacsil", mun_code: "012804" },
  // { label: "Banban", mun_code: "012804" },
  // { label: "Baruyen", mun_code: "012804" },
  // { label: "Dadaor", mun_code: "012804" },
  // { label: "Lanao", mun_code: "012804" },
  // { label: "Malasin", mun_code: "012804" },
  // { label: "Manayon", mun_code: "012804" },
  // { label: "Masikil", mun_code: "012804" },
  // { label: "Nagbalagan", mun_code: "012804" },
  // { label: "Payac", mun_code: "012804" },
  // { label: "San Lorenzo (Pob.)", mun_code: "012804" },
  // { label: "Taguiporo", mun_code: "012804" },
  // { label: "Utol", mun_code: "012804" },
  // { label: "Aglipay (Pob.)", mun_code: "012805" },
  // { label: "Baay", mun_code: "012805" },
  // { label: "Baligat", mun_code: "012805" },
  // { label: "Bungon", mun_code: "012805" },
  // { label: "Baoa East", mun_code: "012805" },
  // { label: "Baoa West", mun_code: "012805" },
  // { label: "Barani (Pob.)", mun_code: "012805" },
  // { label: "Ben-agan (Pob.)", mun_code: "012805" },
  // { label: "Bil-loca", mun_code: "012805" },
  // { label: "Biningan", mun_code: "012805" },
  // { label: "Callaguip (Pob.)", mun_code: "012805" },
  // { label: "Camandingan", mun_code: "012805" },
  // { label: "Camguidan", mun_code: "012805" },
  // { label: "Cangrunaan (Pob.)", mun_code: "012805" },
  // { label: "Capacuan", mun_code: "012805" },
  // { label: "Caunayan (Pob.)", mun_code: "012805" },
  // { label: "Valdez Pob. (Caoayan)", mun_code: "012805" },
  // { label: "Colo", mun_code: "012805" },
  // { label: "Pimentel (Cubol)", mun_code: "012805" },
  // { label: "Dariwdiw", mun_code: "012805" },
  // { label: "Acosta Pob. (Iloilo)", mun_code: "012805" },
  // { label: "Ablan Pob. (Labucao)", mun_code: "012805" },
  // { label: "Lacub (Pob.)", mun_code: "012805" },
  // { label: "Mabaleng", mun_code: "012805" },
  // { label: "Magnuang", mun_code: "012805" },
  // { label: "Maipalig", mun_code: "012805" },
  // { label: "Nagbacalan", mun_code: "012805" },
  // { label: "Naguirangan", mun_code: "012805" },
  // { label: "Ricarte Pob. (Nalasin)", mun_code: "012805" },
  // { label: "Palongpong", mun_code: "012805" },
  // { label: "Palpalicong (Pob.)", mun_code: "012805" },
  // { label: "Parangopong", mun_code: "012805" },
  // { label: "Payao", mun_code: "012805" },
  // { label: "Quiling Norte", mun_code: "012805" },
  // { label: "Quiling Sur", mun_code: "012805" },
  // { label: "Quiom", mun_code: "012805" },
  // { label: "Rayuray", mun_code: "012805" },
  // { label: "San Julian (Pob.)", mun_code: "012805" },
  // { label: "San Mateo", mun_code: "012805" },
  // { label: "San Pedro", mun_code: "012805" },
  // { label: "Suabit (Pob.)", mun_code: "012805" },
  // { label: "Sumader", mun_code: "012805" },
  // { label: "Tabug", mun_code: "012805" },
  // { label: "Ablan Sarat", mun_code: "012806" },
  // { label: "Agaga", mun_code: "012806" },
  // { label: "Bayog", mun_code: "012806" },
  // { label: "Bobon", mun_code: "012806" },
  // { label: "Buduan (Malituek)", mun_code: "012806" },
  // { label: "Nagsurot", mun_code: "012806" },
  // { label: "Paayas", mun_code: "012806" },
  // { label: "Pagali", mun_code: "012806" },
  // { label: "Poblacion", mun_code: "012806" },
  // { label: "Saoit", mun_code: "012806" },
  // { label: "Tanap", mun_code: "012806" },
  // { label: "Angset", mun_code: "012807" },
  // { label: "Barbaqueso (Pob.)", mun_code: "012807" },
  // { label: "Virbira", mun_code: "012807" },
  // { label: "Anggapang Norte", mun_code: "012808" },
  // { label: "Anggapang Sur", mun_code: "012808" },
  // { label: "Bimmanga", mun_code: "012808" },
  // { label: "Cabuusan", mun_code: "012808" },
  // { label: "Comcomloong", mun_code: "012808" },
  // { label: "Gaang", mun_code: "012808" },
  // { label: "Lang-ayan-Baramban", mun_code: "012808" },
  // { label: "Lioes", mun_code: "012808" },
  // { label: "Maglaoi Centro", mun_code: "012808" },
  // { label: "Maglaoi Norte", mun_code: "012808" },
  // { label: "Maglaoi Sur", mun_code: "012808" },
  // { label: "Paguludan-Salindeg", mun_code: "012808" },
  // { label: "Pangil", mun_code: "012808" },
  // { label: "Pias Norte", mun_code: "012808" },
  // { label: "Pias Sur", mun_code: "012808" },
  // { label: "Poblacion I", mun_code: "012808" },
  // { label: "Poblacion II", mun_code: "012808" },
  // { label: "Salugan", mun_code: "012808" },
  // { label: "San Simeon", mun_code: "012808" },
  // { label: "Santa Cruz", mun_code: "012808" },
  // { label: "Tapao-Tigue", mun_code: "012808" },
  // { label: "Torre", mun_code: "012808" },
  // { label: "Victoria", mun_code: "012808" },
  // { label: "Albano (Pob.)", mun_code: "012809" },
  // { label: "Bacsil", mun_code: "012809" },
  // { label: "Bagut", mun_code: "012809" },
  // { label: "Parado (Bangay)", mun_code: "012809" },
  // { label: "Baresbes", mun_code: "012809" },
  // { label: "Barong", mun_code: "012809" },
  // { label: "Bungcag", mun_code: "012809" },
  // { label: "Cali", mun_code: "012809" },
  // { label: "Capasan", mun_code: "012809" },
  // { label: "Dancel (Pob.)", mun_code: "012809" },
  // { label: "Foz", mun_code: "012809" },
  // { label: "Guerrero (Pob.)", mun_code: "012809" },
  // { label: "Lanas", mun_code: "012809" },
  // { label: "Lumbad", mun_code: "012809" },
  // { label: "Madamba (Pob.)", mun_code: "012809" },
  // { label: "Mandaloque", mun_code: "012809" },
  // { label: "Medina", mun_code: "012809" },
  // { label: "Ver (Naglayaan)", mun_code: "012809" },
  // { label: "San Marcelino (Padong)", mun_code: "012809" },
  // { label: "Puruganan (Pob.)", mun_code: "012809" },
  // { label: "Peralta (Pob.)", mun_code: "012809" },
  // { label: "Root (Baldias)", mun_code: "012809" },
  // { label: "Sagpatan", mun_code: "012809" },
  // { label: "Saludares", mun_code: "012809" },
  // { label: "San Esteban", mun_code: "012809" },
  // { label: "Espiritu", mun_code: "012809" },
  // { label: "Sulquiano (Sidiran)", mun_code: "012809" },
  // { label: "San Francisco (Surrate)", mun_code: "012809" },
  // { label: "Suyo", mun_code: "012809" },
  // { label: "San Marcos", mun_code: "012809" },
  // { label: "Elizabeth", mun_code: "012809" },
  // { label: "Dumalneg", mun_code: "012810" },
  // { label: "San Isidro", mun_code: "012810" },
  // { label: "Balioeg", mun_code: "012811" },
  // { label: "Bangsar", mun_code: "012811" },
  // { label: "Barbarangay", mun_code: "012811" },
  // { label: "Bomitog", mun_code: "012811" },
  // { label: "Bugasi", mun_code: "012811" },
  // { label: "Caestebanan", mun_code: "012811" },
  // { label: "Caribquib", mun_code: "012811" },
  // { label: "Catagtaguen", mun_code: "012811" },
  // { label: "Crispina", mun_code: "012811" },
  // { label: "Hilario (Pob.)", mun_code: "012811" },
  // { label: "Imelda", mun_code: "012811" },
  // { label: "Lorenzo (Pob.)", mun_code: "012811" },
  // { label: "Macayepyep", mun_code: "012811" },
  // { label: "Marcos (Pob.)", mun_code: "012811" },
  // { label: "Nagpatayan", mun_code: "012811" },
  // { label: "Valdez", mun_code: "012811" },
  // { label: "Sinamar", mun_code: "012811" },
  // { label: "Tabtabagan", mun_code: "012811" },
  // { label: "Valenciano (Pob.)", mun_code: "012811" },
  // { label: "Binacag", mun_code: "012811" },
  // { label: "Bgy. No. 42, Apaya", mun_code: "012812" },
  // { label: "Bgy. No. 36, Araniw", mun_code: "012812" },
  // { label: "Bgy. No. 56-A, Bacsil North", mun_code: "012812" },
  // { label: "Bgy. No. 56-B, Bacsil South", mun_code: "012812" },
  // { label: "Bgy. No. 41, Balacad", mun_code: "012812" },
  // { label: "Bgy. No. 40, Balatong", mun_code: "012812" },
  // { label: "Bgy. No. 55-A, Barit-Pandan", mun_code: "012812" },
  // { label: "Bgy. No. 47, Bengcag", mun_code: "012812" },
  // { label: "Bgy. No. 50, Buttong", mun_code: "012812" },
  // { label: "Bgy. No. 60-A, Caaoacan", mun_code: "012812" },
  // { label: "Bry. No. 48-A, Cabungaan North", mun_code: "012812" },
  // { label: "Bgy. No. 48-B, Cabungaan South", mun_code: "012812" },
  // { label: "Bgy. No. 37, Calayab", mun_code: "012812" },
  // { label: "Bgy. No. 54-B, Camangaan", mun_code: "012812" },
  // { label: "Bgy. No. 58, Casili", mun_code: "012812" },
  // { label: "Bgy. No. 61, Cataban", mun_code: "012812" },
  // { label: "Bgy. No. 43, Cavit", mun_code: "012812" },
  // { label: "Bgy. No. 49-A, Darayday", mun_code: "012812" },
  // { label: "Bgy. No. 59-B, Dibua North", mun_code: "012812" },
  // { label: "Bgy. No. 59-A, Dibua South", mun_code: "012812" },
  // { label: "Bgy. No. 34-B, Gabu Norte East", mun_code: "012812" },
  // { label: "Bgy. No. 34-A, Gabu Norte West", mun_code: "012812" },
  // { label: "Bgy. No. 35, Gabu Sur", mun_code: "012812" },
  // { label: "Bgy. No. 32-C La Paz East", mun_code: "012812" },
  // { label: "Bgy. No. 33-B, La Paz Proper", mun_code: "012812" },
  // { label: "Bgy. No. 32-B, La Paz West", mun_code: "012812" },
  // { label: "Bgy. No. 54-A, Lagui-Sail", mun_code: "012812" },
  // { label: "Bgy. No. 32-A, La Paz East", mun_code: "012812" },
  // { label: "Bgy. No. 33-A, La Paz Proper", mun_code: "012812" },
  // { label: "Bgy. No. 52-B, Lataag", mun_code: "012812" },
  // { label: "Bgy. No. 60-B, Madiladig", mun_code: "012812" },
  // { label: "Bgy. No. 38-A, Mangato East", mun_code: "012812" },
  // { label: "Bgy. No. 38-B, Mangato West", mun_code: "012812" },
  // { label: "Bgy. No. 62-A, Navotas North", mun_code: "012812" },
  // { label: "Bgy. No. 62-B, Navotas South", mun_code: "012812" },
  // { label: "Bgy. No. 46, Nalbo", mun_code: "012812" },
  // { label: "Bgy. No. 51-A, Nangalisan East", mun_code: "012812" },
  // { label: "Bgy. No. 51-B, Nangalisan West", mun_code: "012812" },
  // {
  //   label: "Bgy. No. 24, Nstra. Sra. De Consolacion (Pob.)",
  //   mun_code: "012812",
  // },
  // {
  //   label: "Bgy. No. 7-A, Nstra. Sra. De Natividad (Pob.)",
  //   mun_code: "012812",
  // },
  // {
  //   label: "Bgy. No. 7-B, Nstra. Sra. De Natividad (Pob.)",
  //   mun_code: "012812",
  // },
  // {
  //   label: "Bgy. No. 27, Nstra. Sra. De Soledad (Pob.)",
  //   mun_code: "012812",
  // },
  // {
  //   label: "Bgy. No. 13, Nstra. Sra. De Visitacion (Pob.)",
  //   mun_code: "012812",
  // },
  // {
  //   label: "Bgy. No. 3, Nstra. Sra. Del Rosario (Pob.)",
  //   mun_code: "012812",
  // },
  // { label: "Bgy. No. 57, Pila", mun_code: "012812" },
  // { label: "Bgy. No. 49-B, Raraburan", mun_code: "012812" },
  // { label: "Bgy. No. 53, Rioeng", mun_code: "012812" },
  // { label: "Bgy. No. 55-B, Salet-Bulangon", mun_code: "012812" },
  // { label: "Bgy. No. 6, San Agustin (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 22, San Andres (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 28, San Bernardo (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 17, San Francisco (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 4, San Guillermo (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 15, San Guillermo (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 12, San Isidro (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 16, San Jacinto (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 10, San Jose (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 1, San Lorenzo (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 26, San Marcelino (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 52-A, San Mateo", mun_code: "012812" },
  // { label: "Bgy. No. 23, San Matias (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 20, San Miguel (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 21, San Pedro (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 5, San Pedro (Pob.)", mun_code: "012812" },
  // { label: "Bry. No. 18, San Quirino (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 8, San Vicente (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 9, Santa Angela (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 11, Santa Balbina (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 25, Santa Cayetana (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 2, Santa Joaquina (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 19, Santa Marcela (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 30-B, Santa Maria", mun_code: "012812" },
  // { label: "Bgy. No. 39, Santa Rosa", mun_code: "012812" },
  // { label: "Bgy. No. 14, Santo Tomas (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 29, Santo Tomas (Pob.)", mun_code: "012812" },
  // { label: "Bgy. No. 30-A, Suyo", mun_code: "012812" },
  // { label: "Bgy. No. 31, Talingaan", mun_code: "012812" },
  // { label: "Bgy. No. 45, Tangid", mun_code: "012812" },
  // { label: "Bgy. No. 55-C, Vira", mun_code: "012812" },
  // { label: "Bgy. No. 44, Zamboanga", mun_code: "012812" },
  // { label: "Pacifico (Agunit)", mun_code: "012813" },
  // { label: "Imelda (Capariaan)", mun_code: "012813" },
  // { label: "Elizabeth (Culao)", mun_code: "012813" },
  // { label: "Daquioag", mun_code: "012813" },
  // { label: "Escoda", mun_code: "012813" },
  // { label: "Ferdinand", mun_code: "012813" },
  // { label: "Fortuna", mun_code: "012813" },
  // { label: "Lydia (Pob.)", mun_code: "012813" },
  // { label: "Mabuti", mun_code: "012813" },
  // { label: "Valdez (Biding)", mun_code: "012813" },
  // { label: "Tabucbuc (Ragas)", mun_code: "012813" },
  // { label: "Santiago", mun_code: "012813" },
  // { label: "Cacafean", mun_code: "012813" },
  // { label: "Acnam", mun_code: "012814" },
  // { label: "Barangobong", mun_code: "012814" },
  // { label: "Barikir", mun_code: "012814" },
  // { label: "Bugayong", mun_code: "012814" },
  // { label: "Cabittauran", mun_code: "012814" },
  // { label: "Caray", mun_code: "012814" },
  // { label: "Garnaden", mun_code: "012814" },
  // { label: "Naguillan (Pagpag-ong)", mun_code: "012814" },
  // { label: "Poblacion", mun_code: "012814" },
  // { label: "Uguis", mun_code: "012814" },
  // { label: "Aggasi", mun_code: "012815" },
  // { label: "Baduang", mun_code: "012815" },
  // { label: "Balaoi", mun_code: "012815" },
  // { label: "Burayoc", mun_code: "012815" },
  // { label: "Caunayan", mun_code: "012815" },
  // { label: "Dampig", mun_code: "012815" },
  // { label: "Ligaya", mun_code: "012815" },
  // { label: "Pancian", mun_code: "012815" },
  // { label: "Pasaleng", mun_code: "012815" },
  // { label: "Poblacion 1", mun_code: "012815" },
  // { label: "Poblacion 2", mun_code: "012815" },
  // { label: "Saguigui", mun_code: "012815" },
  // { label: "Saud", mun_code: "012815" },
  // { label: "Subec", mun_code: "012815" },
  // { label: "Tarrag", mun_code: "012815" },
  // { label: "Caparispisan", mun_code: "012815" },
  // { label: "Bacsil", mun_code: "012816" },
  // { label: "Cabagoan", mun_code: "012816" },
  // { label: "Cabangaran", mun_code: "012816" },
  // { label: "Callaguip", mun_code: "012816" },
  // { label: "Cayubog", mun_code: "012816" },
  // { label: "Dolores", mun_code: "012816" },
  // { label: "Laoa", mun_code: "012816" },
  // { label: "Masintoc", mun_code: "012816" },
  // { label: "Monte", mun_code: "012816" },
  // { label: "Mumulaan", mun_code: "012816" },
  // { label: "Nagbacalan", mun_code: "012816" },
  // { label: "Nalasin", mun_code: "012816" },
  // { label: "Nanguyudan", mun_code: "012816" },
  // { label: "Oaig-Upay-Abulao", mun_code: "012816" },
  // { label: "Pambaran", mun_code: "012816" },
  // { label: "Pannaratan (Pob.)", mun_code: "012816" },
  // { label: "Paratong", mun_code: "012816" },
  // { label: "Pasil", mun_code: "012816" },
  // { label: "Salbang (Pob.)", mun_code: "012816" },
  // { label: "San Agustin", mun_code: "012816" },
  // { label: "San Blas (Pob.)", mun_code: "012816" },
  // { label: "San Juan", mun_code: "012816" },
  // { label: "San Pedro", mun_code: "012816" },
  // { label: "San Roque (Pob.)", mun_code: "012816" },
  // { label: "Sangladan Pob. (Nalbuan)", mun_code: "012816" },
  // { label: "Santa Rita (Pob.)", mun_code: "012816" },
  // { label: "Sideg", mun_code: "012816" },
  // { label: "Suba", mun_code: "012816" },
  // { label: "Sungadan", mun_code: "012816" },
  // { label: "Surgui", mun_code: "012816" },
  // { label: "Veronica", mun_code: "012816" },
  // { label: "Batuli", mun_code: "012817" },
  // { label: "Binsang", mun_code: "012817" },
  // { label: "Nalvo (Cababaan-Nalvo)", mun_code: "012817" },
  // { label: "Caruan", mun_code: "012817" },
  // { label: "Carusikis", mun_code: "012817" },
  // { label: "Carusipan", mun_code: "012817" },
  // { label: "Dadaeman", mun_code: "012817" },
  // { label: "Darupidip", mun_code: "012817" },
  // { label: "Davila", mun_code: "012817" },
  // { label: "Dilanis", mun_code: "012817" },
  // { label: "Dilavo", mun_code: "012817" },
  // { label: "Estancia", mun_code: "012817" },
  // { label: "Naglicuan", mun_code: "012817" },
  // { label: "Nagsanga", mun_code: "012817" },
  // { label: "Ngabangab", mun_code: "012817" },
  // { label: "Pangil", mun_code: "012817" },
  // { label: "Poblacion 1", mun_code: "012817" },
  // { label: "Poblacion 2", mun_code: "012817" },
  // { label: "Poblacion 3", mun_code: "012817" },
  // { label: "Poblacion 4", mun_code: "012817" },
  // { label: "Pragata (Pragata-Bungro)", mun_code: "012817" },
  // { label: "Puyupuyan", mun_code: "012817" },
  // { label: "Sulongan", mun_code: "012817" },
  // { label: "Salpad", mun_code: "012817" },
  // { label: "San Juan", mun_code: "012817" },
  // { label: "Santa Catalina", mun_code: "012817" },
  // { label: "Santa Matilde", mun_code: "012817" },
  // { label: "Sapat", mun_code: "012817" },
  // { label: "Sulbec", mun_code: "012817" },
  // { label: "Surong", mun_code: "012817" },
  // { label: "Susugaen", mun_code: "012817" },
  // { label: "Tabungao", mun_code: "012817" },
  // { label: "Tadao", mun_code: "012817" },
  // { label: "Ab-abut", mun_code: "012818" },
  // { label: "Abucay", mun_code: "012818" },
  // { label: "Anao (Pob.)", mun_code: "012818" },
  // { label: "Arua-ay", mun_code: "012818" },
  // { label: "Bimmanga", mun_code: "012818" },
  // { label: "Boyboy", mun_code: "012818" },
  // { label: "Cabaroan (Pob.)", mun_code: "012818" },
  // { label: "Calambeg", mun_code: "012818" },
  // { label: "Callusa", mun_code: "012818" },
  // { label: "Dupitac", mun_code: "012818" },
  // { label: "Estancia", mun_code: "012818" },
  // { label: "Gayamat", mun_code: "012818" },
  // { label: "Lagandit", mun_code: "012818" },
  // { label: "Libnaoan", mun_code: "012818" },
  // { label: "Loing (Pob.)", mun_code: "012818" },
  // { label: "Maab-abaca", mun_code: "012818" },
  // { label: "Mangitayag", mun_code: "012818" },
  // { label: "Maruaya", mun_code: "012818" },
  // { label: "San Antonio", mun_code: "012818" },
  // { label: "Santa Maria", mun_code: "012818" },
  // { label: "Sucsuquen", mun_code: "012818" },
  // { label: "Tangaoan", mun_code: "012818" },
  // { label: "Tonoton", mun_code: "012818" },
  // { label: "Aglipay", mun_code: "012819" },
  // { label: "Apatut-Lubong", mun_code: "012819" },
  // { label: "Badio", mun_code: "012819" },
  // { label: "Barbar", mun_code: "012819" },
  // { label: "Buanga", mun_code: "012819" },
  // { label: "Bulbulala", mun_code: "012819" },
  // { label: "Bungro", mun_code: "012819" },
  // { label: "Cabaroan", mun_code: "012819" },
  // { label: "Capangdanan", mun_code: "012819" },
  // { label: "Dalayap", mun_code: "012819" },
  // { label: "Darat", mun_code: "012819" },
  // { label: "Gulpeng", mun_code: "012819" },
  // { label: "Liliputen", mun_code: "012819" },
  // { label: "Lumbaan-Bicbica", mun_code: "012819" },
  // { label: "Nagtrigoan", mun_code: "012819" },
  // { label: "Pagdilao (Pob.)", mun_code: "012819" },
  // { label: "Pugaoan", mun_code: "012819" },
  // { label: "Puritac", mun_code: "012819" },
  // { label: "Sacritan", mun_code: "012819" },
  // { label: "Salanap", mun_code: "012819" },
  // { label: "Santo Tomas", mun_code: "012819" },
  // { label: "Tartarabang", mun_code: "012819" },
  // { label: "Puzol", mun_code: "012819" },
  // { label: "Upon", mun_code: "012819" },
  // { label: "Valbuena (Pob.)", mun_code: "012819" },
  // { label: "San Francisco (Pob.)", mun_code: "012820" },
  // { label: "San Ildefonso (Pob.)", mun_code: "012820" },
  // { label: "San Agustin", mun_code: "012820" },
  // { label: "San Baltazar (Pob.)", mun_code: "012820" },
  // { label: "San Bartolome (Pob.)", mun_code: "012820" },
  // { label: "San Cayetano (Pob.)", mun_code: "012820" },
  // { label: "San Eugenio (Pob.)", mun_code: "012820" },
  // { label: "San Fernando (Pob.)", mun_code: "012820" },
  // { label: "San Gregorio (Pob.)", mun_code: "012820" },
  // { label: "San Guillermo", mun_code: "012820" },
  // { label: "San Jose (Pob.)", mun_code: "012820" },
  // { label: "San Juan Bautista (Pob.)", mun_code: "012820" },
  // { label: "San Lorenzo", mun_code: "012820" },
  // { label: "San Lucas (Pob.)", mun_code: "012820" },
  // { label: "San Marcos (Payas)", mun_code: "012820" },
  // { label: "San Miguel (Pob.)", mun_code: "012820" },
  // { label: "San Pablo", mun_code: "012820" },
  // { label: "San Paulo (Pob.)", mun_code: "012820" },
  // { label: "San Pedro (Bingao)", mun_code: "012820" },
  // { label: "San Rufino (Pob.)", mun_code: "012820" },
  // { label: "San Silvestre (Pob.)", mun_code: "012820" },
  // { label: "Santa Asuncion (Samac)", mun_code: "012820" },
  // { label: "Santa Cecilia (Barabar)", mun_code: "012820" },
  // { label: "Santa Monica (Nagrebcan)", mun_code: "012820" },
  // { label: "San Agustin (Pob.)", mun_code: "012821" },
  // { label: "San Andres", mun_code: "012821" },
  // { label: "San Antonio", mun_code: "012821" },
  // { label: "San Bernabe", mun_code: "012821" },
  // { label: "San Cristobal", mun_code: "012821" },
  // { label: "San Felipe", mun_code: "012821" },
  // { label: "San Francisco (Pob.)", mun_code: "012821" },
  // { label: "San Isidro", mun_code: "012821" },
  // { label: "San Joaquin (Pob.)", mun_code: "012821" },
  // { label: "San Jose", mun_code: "012821" },
  // { label: "San Juan", mun_code: "012821" },
  // { label: "San Leandro (Pob.)", mun_code: "012821" },
  // { label: "San Lorenzo", mun_code: "012821" },
  // { label: "San Manuel", mun_code: "012821" },
  // { label: "San Marcos", mun_code: "012821" },
  // { label: "San Nicolas", mun_code: "012821" },
  // { label: "San Pedro", mun_code: "012821" },
  // { label: "San Roque", mun_code: "012821" },
  // { label: "San Vicente (Pob.)", mun_code: "012821" },
  // { label: "Santa Barbara (Pob.)", mun_code: "012821" },
  // { label: "Santa Magdalena", mun_code: "012821" },
  // { label: "Santa Rosa", mun_code: "012821" },
  // { label: "Santo Santiago", mun_code: "012821" },
  // { label: "Santo Tomas", mun_code: "012821" },
  // { label: "Aguitap", mun_code: "012822" },
  // { label: "Bagbag", mun_code: "012822" },
  // { label: "Bagbago", mun_code: "012822" },
  // { label: "Barcelona", mun_code: "012822" },
  // { label: "Bubuos", mun_code: "012822" },
  // { label: "Capurictan", mun_code: "012822" },
  // { label: "Catangraran", mun_code: "012822" },
  // { label: "Darasdas", mun_code: "012822" },
  // { label: "Juan (Pob.)", mun_code: "012822" },
  // { label: "Laureta (Pob.)", mun_code: "012822" },
  // { label: "Lipay", mun_code: "012822" },
  // { label: "Maananteng", mun_code: "012822" },
  // { label: "Manalpac", mun_code: "012822" },
  // { label: "Mariquet", mun_code: "012822" },
  // { label: "Nagpatpatan", mun_code: "012822" },
  // { label: "Nalasin", mun_code: "012822" },
  // { label: "Puttao", mun_code: "012822" },
  // { label: "San Juan", mun_code: "012822" },
  // { label: "San Julian", mun_code: "012822" },
  // { label: "Santa Ana", mun_code: "012822" },
  // { label: "Santiago", mun_code: "012822" },
  // { label: "Talugtog", mun_code: "012822" },
  // { label: "Abkir", mun_code: "012823" },
  // { label: "Alsem", mun_code: "012823" },
  // { label: "Bago", mun_code: "012823" },
  // { label: "Bulbulala", mun_code: "012823" },
  // { label: "Cabangaran", mun_code: "012823" },
  // { label: "Cabayo", mun_code: "012823" },
  // { label: "Cabisocolan", mun_code: "012823" },
  // { label: "Canaam", mun_code: "012823" },
  // { label: "Columbia", mun_code: "012823" },
  // { label: "Dagupan", mun_code: "012823" },
  // { label: "Pedro F. Alviar (Diaton)", mun_code: "012823" },
  // { label: "Dipilat", mun_code: "012823" },
  // { label: "Esperanza", mun_code: "012823" },
  // { label: "Ester", mun_code: "012823" },
  // { label: "Isic Isic", mun_code: "012823" },
  // { label: "Lubnac", mun_code: "012823" },
  // { label: "Mabanbanag", mun_code: "012823" },
  // { label: "Alejo Malasig", mun_code: "012823" },
  // { label: "Manarang", mun_code: "012823" },
  // { label: "Margaay", mun_code: "012823" },
  // { label: "Namoroc", mun_code: "012823" },
  // { label: "Malampa (Peninaan-Malampa)", mun_code: "012823" },
  // { label: "Parparoroc", mun_code: "012823" },
  // { label: "Parut", mun_code: "012823" },
  // { label: "Salsalamagui", mun_code: "012823" },
  // { label: "San Jose (Lipay)", mun_code: "012823" },
  // { label: "San Nicolas (Pob.)", mun_code: "012823" },
  // { label: "San Pedro (Pob.)", mun_code: "012823" },
  // { label: "San Ramon (Pob.)", mun_code: "012823" },
  // { label: "San Roque (Pob.)", mun_code: "012823" },
  // { label: "Santa Maria (Pob.)", mun_code: "012823" },
  // { label: "Tamdagan", mun_code: "012823" },
  // { label: "Visaya", mun_code: "012823" },
  // { label: "Alilem Daya (Pob.)", mun_code: "012901" },
  // { label: "Amilongan", mun_code: "012901" },
  // { label: "Anaao", mun_code: "012901" },
  // { label: "Apang", mun_code: "012901" },
  // { label: "Apaya", mun_code: "012901" },
  // { label: "Batbato", mun_code: "012901" },
  // { label: "Daddaay", mun_code: "012901" },
  // { label: "Dalawa", mun_code: "012901" },
  // { label: "Kiat", mun_code: "012901" },
  // { label: "Bagbagotot", mun_code: "012902" },
  // { label: "Banbanaal", mun_code: "012902" },
  // { label: "Bisangol", mun_code: "012902" },
  // { label: "Cadanglaan", mun_code: "012902" },
  // { label: "Casilagan Norte", mun_code: "012902" },
  // { label: "Casilagan Sur", mun_code: "012902" },
  // { label: "Elefante", mun_code: "012902" },
  // { label: "Guardia", mun_code: "012902" },
  // { label: "Lintic", mun_code: "012902" },
  // { label: "Lopez", mun_code: "012902" },
  // { label: "Montero", mun_code: "012902" },
  // { label: "Naguimba", mun_code: "012902" },
  // { label: "Pila", mun_code: "012902" },
  // { label: "Poblacion", mun_code: "012902" },
  // { label: "Aggay", mun_code: "012903" },
  // { label: "An-annam", mun_code: "012903" },
  // { label: "Balaleng", mun_code: "012903" },
  // { label: "Banaoang", mun_code: "012903" },
  // { label: "Bulag", mun_code: "012903" },
  // { label: "Buquig", mun_code: "012903" },
  // { label: "Cabalanggan", mun_code: "012903" },
  // { label: "Cabaroan", mun_code: "012903" },
  // { label: "Cabusligan", mun_code: "012903" },
  // { label: "Capangdanan", mun_code: "012903" },
  // { label: "Guimod", mun_code: "012903" },
  // { label: "Lingsat", mun_code: "012903" },
  // { label: "Malingeb", mun_code: "012903" },
  // { label: "Mira", mun_code: "012903" },
  // { label: "Naguiddayan", mun_code: "012903" },
  // { label: "Ora", mun_code: "012903" },
  // { label: "Paing", mun_code: "012903" },
  // { label: "Puspus", mun_code: "012903" },
  // { label: "Quimmarayan", mun_code: "012903" },
  // { label: "Sagneb", mun_code: "012903" },
  // { label: "Sagpat", mun_code: "012903" },
  // { label: "San Mariano (Sallacong)", mun_code: "012903" },
  // { label: "San Isidro", mun_code: "012903" },
  // { label: "San Julian", mun_code: "012903" },
  // { label: "Sinabaan", mun_code: "012903" },
  // { label: "Taguiporo", mun_code: "012903" },
  // { label: "Taleb", mun_code: "012903" },
  // { label: "Tay-ac", mun_code: "012903" },
  // { label: "Barangay 1 (Pob.)", mun_code: "012903" },
  // { label: "Barangay 2 (Pob.)", mun_code: "012903" },
  // { label: "Barangay 3 (Pob.)", mun_code: "012903" },
  // { label: "Barangay 4 (Pob.)", mun_code: "012903" },
  // { label: "Barangay 5 (Pob.)", mun_code: "012903" },
  // { label: "Barangay 6 (Pob.)", mun_code: "012903" },
  // { label: "Ambugat", mun_code: "012904" },
  // { label: "Balugang", mun_code: "012904" },
  // { label: "Bangbangar", mun_code: "012904" },
  // { label: "Bessang", mun_code: "012904" },
  // { label: "Cabcaburao", mun_code: "012904" },
  // { label: "Cadacad", mun_code: "012904" },
  // { label: "Callitong", mun_code: "012904" },
  // { label: "Dayanki", mun_code: "012904" },
  // { label: "Lesseb", mun_code: "012904" },
  // { label: "Lubing", mun_code: "012904" },
  // { label: "Lucaban", mun_code: "012904" },
  // { label: "Luna", mun_code: "012904" },
  // { label: "Macaoayan", mun_code: "012904" },
  // { label: "Mambug", mun_code: "012904" },
  // { label: "Manaboc", mun_code: "012904" },
  // { label: "Mapanit", mun_code: "012904" },
  // { label: "Poblacion Sur (Masingit)", mun_code: "012904" },
  // { label: "Nagpanaoan", mun_code: "012904" },
  // { label: "Dirdirig (Dirdirig-Paday)", mun_code: "012904" },
  // { label: "Paduros", mun_code: "012904" },
  // { label: "Patac", mun_code: "012904" },
  // { label: "Poblacion Norte (Bato)", mun_code: "012904" },
  // { label: "Sabangan Pinggan", mun_code: "012904" },
  // { label: "Subadi Norte", mun_code: "012904" },
  // { label: "Subadi Sur", mun_code: "012904" },
  // { label: "Taliao", mun_code: "012904" },
  // { label: "Alinaay", mun_code: "012905" },
  // { label: "Aragan", mun_code: "012905" },
  // { label: "Arnap", mun_code: "012905" },
  // { label: "Baclig (Pob.)", mun_code: "012905" },
  // { label: "Bato", mun_code: "012905" },
  // { label: "Bonifacio (Pob.)", mun_code: "012905" },
  // { label: "Bungro", mun_code: "012905" },
  // { label: "Cacadiran", mun_code: "012905" },
  // { label: "Caellayan", mun_code: "012905" },
  // { label: "Carusipan", mun_code: "012905" },
  // { label: "Catucdaan", mun_code: "012905" },
  // { label: "Cuancabal", mun_code: "012905" },
  // { label: "Cuantacla", mun_code: "012905" },
  // { label: "Daclapan", mun_code: "012905" },
  // { label: "Dardarat", mun_code: "012905" },
  // { label: "Lipit", mun_code: "012905" },
  // { label: "Maradodon", mun_code: "012905" },
  // { label: "Margaay", mun_code: "012905" },
  // { label: "Nagsantaan", mun_code: "012905" },
  // { label: "Nagsincaoan", mun_code: "012905" },
  // { label: "Namruangan", mun_code: "012905" },
  // { label: "Pila", mun_code: "012905" },
  // { label: "Pug-os", mun_code: "012905" },
  // { label: "Quezon (Pob.)", mun_code: "012905" },
  // { label: "Reppaac", mun_code: "012905" },
  // { label: "Rizal (Pob.)", mun_code: "012905" },
  // { label: "Sabang", mun_code: "012905" },
  // { label: "Sagayaden", mun_code: "012905" },
  // { label: "Salapasap", mun_code: "012905" },
  // { label: "Salomague", mun_code: "012905" },
  // { label: "Sisim", mun_code: "012905" },
  // { label: "Turod", mun_code: "012905" },
  // { label: "Turod-Patac", mun_code: "012905" },
  // { label: "Allangigan Primero", mun_code: "012906" },
  // { label: "Allangigan Segundo", mun_code: "012906" },
  // { label: "Amguid", mun_code: "012906" },
  // { label: "Ayudante", mun_code: "012906" },
  // { label: "Bagani Camposanto", mun_code: "012906" },
  // { label: "Bagani Gabor", mun_code: "012906" },
  // { label: "Bagani Tocgo", mun_code: "012906" },
  // { label: "Bagani Ubbog", mun_code: "012906" },
  // { label: "Bagar", mun_code: "012906" },
  // { label: "Balingaoan", mun_code: "012906" },
  // { label: "Bugnay", mun_code: "012906" },
  // { label: "Calaoaan", mun_code: "012906" },
  // { label: "Calongbuyan", mun_code: "012906" },
  // { label: "Caterman", mun_code: "012906" },
  // { label: "Cubcubboot", mun_code: "012906" },
  // { label: "Darapidap", mun_code: "012906" },
  // { label: "Langlangca Primero", mun_code: "012906" },
  // { label: "Langlangca Segundo", mun_code: "012906" },
  // { label: "Oaig-Daya", mun_code: "012906" },
  // { label: "Palacapac", mun_code: "012906" },
  // { label: "Paras", mun_code: "012906" },
  // { label: "Parioc Primero", mun_code: "012906" },
  // { label: "Parioc Segundo", mun_code: "012906" },
  // { label: "Patpata Primero", mun_code: "012906" },
  // { label: "Patpata Segundo", mun_code: "012906" },
  // { label: "Paypayad", mun_code: "012906" },
  // { label: "Salvador Primero", mun_code: "012906" },
  // { label: "Salvador Segundo", mun_code: "012906" },
  // { label: "San Agustin", mun_code: "012906" },
  // { label: "San Andres", mun_code: "012906" },
  // { label: "San Antonio (Pob.)", mun_code: "012906" },
  // { label: "San Isidro (Pob.)", mun_code: "012906" },
  // { label: "San Jose (Pob.)", mun_code: "012906" },
  // { label: "San Juan (Pob.)", mun_code: "012906" },
  // { label: "San Nicolas", mun_code: "012906" },
  // { label: "San Pedro", mun_code: "012906" },
  // { label: "Santo Tomas", mun_code: "012906" },
  // { label: "Tablac", mun_code: "012906" },
  // { label: "Talogtog", mun_code: "012906" },
  // { label: "Tamurong Primero", mun_code: "012906" },
  // { label: "Tamurong Segundo", mun_code: "012906" },
  // { label: "Villarica", mun_code: "012906" },
  // { label: "Anonang Mayor", mun_code: "012907" },
  // { label: "Anonang Menor", mun_code: "012907" },
  // { label: "Baggoc", mun_code: "012907" },
  // { label: "Callaguip", mun_code: "012907" },
  // { label: "Caparacadan", mun_code: "012907" },
  // { label: "Fuerte", mun_code: "012907" },
  // { label: "Manangat", mun_code: "012907" },
  // { label: "Naguilian", mun_code: "012907" },
  // { label: "Nansuagao", mun_code: "012907" },
  // { label: "Pandan", mun_code: "012907" },
  // { label: "Pantay-Quitiquit", mun_code: "012907" },
  // { label: "Don Dimas Querubin (Pob.)", mun_code: "012907" },
  // { label: "Puro", mun_code: "012907" },
  // { label: "Pantay Tamurong", mun_code: "012907" },
  // { label: "Villamar", mun_code: "012907" },
  // { label: "Don Alejandro Quirolgico (Pob.)", mun_code: "012907" },
  // { label: "Don Lorenzo Querubin (Pob.)", mun_code: "012907" },
  // { label: "Aluling", mun_code: "012908" },
  // { label: "Comillas North", mun_code: "012908" },
  // { label: "Comillas South", mun_code: "012908" },
  // { label: "Concepcion (Pob.)", mun_code: "012908" },
  // { label: "Dinwede East", mun_code: "012908" },
  // { label: "Dinwede West", mun_code: "012908" },
  // { label: "Libang", mun_code: "012908" },
  // { label: "Pilipil", mun_code: "012908" },
  // { label: "Remedios", mun_code: "012908" },
  // { label: "Rosario (Pob.)", mun_code: "012908" },
  // { label: "San Juan", mun_code: "012908" },
  // { label: "San Luis", mun_code: "012908" },
  // { label: "Malaya", mun_code: "012908" },
  // { label: "Abaya", mun_code: "012909" },
  // { label: "Baracbac", mun_code: "012909" },
  // { label: "Bidbiday", mun_code: "012909" },
  // { label: "Bitong", mun_code: "012909" },
  // { label: "Borobor", mun_code: "012909" },
  // { label: "Calimugtong", mun_code: "012909" },
  // { label: "Calongbuyan", mun_code: "012909" },
  // { label: "Calumbaya", mun_code: "012909" },
  // { label: "Daldagan", mun_code: "012909" },
  // { label: "Kilang", mun_code: "012909" },
  // { label: "Legaspi", mun_code: "012909" },
  // { label: "Mabayag", mun_code: "012909" },
  // { label: "Matanubong", mun_code: "012909" },
  // { label: "Mckinley", mun_code: "012909" },
  // { label: "Nagsingcaoan", mun_code: "012909" },
  // { label: "Oaig-Daya", mun_code: "012909" },
  // { label: "Pagangpang", mun_code: "012909" },
  // { label: "Patac", mun_code: "012909" },
  // { label: "Poblacion", mun_code: "012909" },
  // { label: "Rubio", mun_code: "012909" },
  // { label: "Sabangan-Bato", mun_code: "012909" },
  // { label: "Sacaang", mun_code: "012909" },
  // { label: "San Vicente", mun_code: "012909" },
  // { label: "Sapang", mun_code: "012909" },
  // { label: "Alfonso (Tangaoan)", mun_code: "012910" },
  // { label: "Bussot", mun_code: "012910" },
  // { label: "Concepcion", mun_code: "012910" },
  // { label: "Dapdappig", mun_code: "012910" },
  // { label: "Matue-Butarag", mun_code: "012910" },
  // { label: "Poblacion Norte", mun_code: "012910" },
  // { label: "Poblacion Sur", mun_code: "012910" },
  // { label: "Banucal", mun_code: "012911" },
  // { label: "Bequi-Walin", mun_code: "012911" },
  // { label: "Bugui", mun_code: "012911" },
  // { label: "Calungbuyan", mun_code: "012911" },
  // { label: "Carcarabasa", mun_code: "012911" },
  // { label: "Labut", mun_code: "012911" },
  // { label: "Poblacion Norte", mun_code: "012911" },
  // { label: "Poblacion Sur", mun_code: "012911" },
  // { label: "San Vicente", mun_code: "012911" },
  // { label: "Suysuyan", mun_code: "012911" },
  // { label: "Tay-ac", mun_code: "012911" },
  // { label: "Alangan", mun_code: "012912" },
  // { label: "Bacar", mun_code: "012912" },
  // { label: "Barbarit", mun_code: "012912" },
  // { label: "Bungro", mun_code: "012912" },
  // { label: "Cabaroan", mun_code: "012912" },
  // { label: "Cadanglaan", mun_code: "012912" },
  // { label: "Caraisan", mun_code: "012912" },
  // { label: "Dacutan", mun_code: "012912" },
  // { label: "Labut", mun_code: "012912" },
  // { label: "Maas-asin", mun_code: "012912" },
  // { label: "Macatcatud", mun_code: "012912" },
  // { label: "Namalpalan", mun_code: "012912" },
  // { label: "Manzante", mun_code: "012912" },
  // { label: "Maratudo", mun_code: "012912" },
  // { label: "Miramar", mun_code: "012912" },
  // { label: "Napo", mun_code: "012912" },
  // { label: "Pagsanaan Norte", mun_code: "012912" },
  // { label: "Pagsanaan Sur", mun_code: "012912" },
  // { label: "Panay Norte", mun_code: "012912" },
  // { label: "Panay Sur", mun_code: "012912" },
  // { label: "Patong", mun_code: "012912" },
  // { label: "Puro", mun_code: "012912" },
  // { label: "San Basilio (Pob.)", mun_code: "012912" },
  // { label: "San Clemente (Pob.)", mun_code: "012912" },
  // { label: "San Julian (Pob.)", mun_code: "012912" },
  // { label: "San Lucas (Pob.)", mun_code: "012912" },
  // { label: "San Ramon (Pob.)", mun_code: "012912" },
  // { label: "San Vicente (Pob.)", mun_code: "012912" },
  // { label: "Santa Monica", mun_code: "012912" },
  // { label: "Sarsaracat", mun_code: "012912" },
  // { label: "Balaweg", mun_code: "012913" },
  // { label: "Bandril", mun_code: "012913" },
  // { label: "Bantugo", mun_code: "012913" },
  // { label: "Cadacad", mun_code: "012913" },
  // { label: "Casilagan", mun_code: "012913" },
  // { label: "Casocos", mun_code: "012913" },
  // { label: "Lapting", mun_code: "012913" },
  // { label: "Mapisi", mun_code: "012913" },
  // { label: "Mission", mun_code: "012913" },
  // { label: "Poblacion East", mun_code: "012913" },
  // { label: "Poblacion West", mun_code: "012913" },
  // { label: "Taleb", mun_code: "012913" },
  // { label: "Abuor", mun_code: "012914" },
  // { label: "Ambulogan", mun_code: "012914" },
  // { label: "Aquib", mun_code: "012914" },
  // { label: "Banglayan", mun_code: "012914" },
  // { label: "Bulanos", mun_code: "012914" },
  // { label: "Cadacad", mun_code: "012914" },
  // { label: "Cagayungan", mun_code: "012914" },
  // { label: "Camarao", mun_code: "012914" },
  // { label: "Casilagan", mun_code: "012914" },
  // { label: "Codoog", mun_code: "012914" },
  // { label: "Dasay", mun_code: "012914" },
  // { label: "Dinalaoan", mun_code: "012914" },
  // { label: "Estancia", mun_code: "012914" },
  // { label: "Lanipao", mun_code: "012914" },
  // { label: "Lungog", mun_code: "012914" },
  // { label: "Margaay", mun_code: "012914" },
  // { label: "Marozo", mun_code: "012914" },
  // { label: "Naguneg", mun_code: "012914" },
  // { label: "Orence", mun_code: "012914" },
  // { label: "Pantoc", mun_code: "012914" },
  // { label: "Paratong", mun_code: "012914" },
  // { label: "Parparia", mun_code: "012914" },
  // { label: "Quinarayan", mun_code: "012914" },
  // { label: "Rivadavia", mun_code: "012914" },
  // { label: "San Antonio", mun_code: "012914" },
  // { label: "San Jose (Pob.)", mun_code: "012914" },
  // { label: "San Pablo", mun_code: "012914" },
  // { label: "San Pedro", mun_code: "012914" },
  // { label: "Santa Lucia (Pob.)", mun_code: "012914" },
  // { label: "Sarmingan", mun_code: "012914" },
  // { label: "Sucoc", mun_code: "012914" },
  // { label: "Sulvec", mun_code: "012914" },
  // { label: "Turod", mun_code: "012914" },
  // { label: "Bantay Abot", mun_code: "012914" },
  // { label: "Banoen", mun_code: "012915" },
  // { label: "Cayus", mun_code: "012915" },
  // { label: "Patungcaleo (Lamag)", mun_code: "012915" },
  // { label: "Malideg", mun_code: "012915" },
  // { label: "Namitpit", mun_code: "012915" },
  // { label: "Patiacan", mun_code: "012915" },
  // { label: "Legleg (Pob.)", mun_code: "012915" },
  // { label: "Suagayan", mun_code: "012915" },
  // { label: "Lamag (Tubtuba)", mun_code: "012915" },
  // { label: "Atabay", mun_code: "012916" },
  // { label: "Calangcuasan", mun_code: "012916" },
  // { label: "Balidbid", mun_code: "012916" },
  // { label: "Baluarte", mun_code: "012916" },
  // { label: "Baybayading", mun_code: "012916" },
  // { label: "Boguibog", mun_code: "012916" },
  // { label: "Bulala-Leguey", mun_code: "012916" },
  // { label: "Kaliwakiw", mun_code: "012916" },
  // { label: "Culiong", mun_code: "012916" },
  // { label: "Dinaratan", mun_code: "012916" },
  // { label: "Kinmarin", mun_code: "012916" },
  // { label: "Lucbuban", mun_code: "012916" },
  // { label: "Madarang", mun_code: "012916" },
  // { label: "Maligcong", mun_code: "012916" },
  // { label: "Pias", mun_code: "012916" },
  // { label: "Poblacion Norte", mun_code: "012916" },
  // { label: "Poblacion Sur", mun_code: "012916" },
  // { label: "San Gaspar", mun_code: "012916" },
  // { label: "San Tiburcio", mun_code: "012916" },
  // { label: "Sorioan", mun_code: "012916" },
  // { label: "Ubbog", mun_code: "012916" },
  // { label: "Cabaroan (Pob.)", mun_code: "012917" },
  // { label: "Kalumsing", mun_code: "012917" },
  // { label: "Lancuas", mun_code: "012917" },
  // { label: "Matibuey", mun_code: "012917" },
  // { label: "Paltoc", mun_code: "012917" },
  // { label: "Sibsibbu", mun_code: "012917" },
  // { label: "Tiagan", mun_code: "012917" },
  // { label: "San Miliano", mun_code: "012917" },
  // { label: "Ansad", mun_code: "012918" },
  // { label: "Apatot", mun_code: "012918" },
  // { label: "Bateria", mun_code: "012918" },
  // { label: "Cabaroan", mun_code: "012918" },
  // { label: "Cappa-cappa", mun_code: "012918" },
  // { label: "Poblacion", mun_code: "012918" },
  // { label: "San Nicolas", mun_code: "012918" },
  // { label: "San Pablo", mun_code: "012918" },
  // { label: "San Rafael", mun_code: "012918" },
  // { label: "Villa Quirino", mun_code: "012918" },
  // { label: "Arnap", mun_code: "012919" },
  // { label: "Bahet", mun_code: "012919" },
  // { label: "Belen", mun_code: "012919" },
  // { label: "Bungro", mun_code: "012919" },
  // { label: "Busiing Sur", mun_code: "012919" },
  // { label: "Busiing Norte", mun_code: "012919" },
  // { label: "Dongalo", mun_code: "012919" },
  // { label: "Gongogong", mun_code: "012919" },
  // { label: "Iboy", mun_code: "012919" },
  // { label: "Otol-Patac", mun_code: "012919" },
  // { label: "Poblacion East", mun_code: "012919" },
  // { label: "Poblacion West", mun_code: "012919" },
  // { label: "Kinamantirisan", mun_code: "012919" },
  // { label: "Sagneb", mun_code: "012919" },
  // { label: "Sagsagat", mun_code: "012919" },
  // { label: "Bacsil", mun_code: "012920" },
  // { label: "Baliw", mun_code: "012920" },
  // { label: "Bannuar (Pob.)", mun_code: "012920" },
  // { label: "Barbar", mun_code: "012920" },
  // { label: "Cabanglotan", mun_code: "012920" },
  // { label: "Cacandongan", mun_code: "012920" },
  // { label: "Camanggaan", mun_code: "012920" },
  // { label: "Camindoroan", mun_code: "012920" },
  // { label: "Caronoan", mun_code: "012920" },
  // { label: "Darao", mun_code: "012920" },
  // { label: "Dardarat", mun_code: "012920" },
  // { label: "Guimod Norte", mun_code: "012920" },
  // { label: "Guimod Sur", mun_code: "012920" },
  // { label: "Immayos Norte", mun_code: "012920" },
  // { label: "Immayos Sur", mun_code: "012920" },
  // { label: "Labnig", mun_code: "012920" },
  // { label: "Lapting", mun_code: "012920" },
  // { label: "Lira (Pob.)", mun_code: "012920" },
  // { label: "Malamin", mun_code: "012920" },
  // { label: "Muraya", mun_code: "012920" },
  // { label: "Nagsabaran", mun_code: "012920" },
  // { label: "Nagsupotan", mun_code: "012920" },
  // { label: "Pandayan (Pob.)", mun_code: "012920" },
  // { label: "Refaro", mun_code: "012920" },
  // { label: "Resurreccion (Pob.)", mun_code: "012920" },
  // { label: "Sabangan", mun_code: "012920" },
  // { label: "San Isidro", mun_code: "012920" },
  // { label: "Saoang", mun_code: "012920" },
  // { label: "Solotsolot", mun_code: "012920" },
  // { label: "Sunggiam", mun_code: "012920" },
  // { label: "Surngit", mun_code: "012920" },
  // { label: "Asilang", mun_code: "012920" },
  // { label: "Bantaoay", mun_code: "012921" },
  // { label: "Bayubay Norte", mun_code: "012921" },
  // { label: "Bayubay Sur", mun_code: "012921" },
  // { label: "Lubong", mun_code: "012921" },
  // { label: "Poblacion", mun_code: "012921" },
  // { label: "Pudoc", mun_code: "012921" },
  // { label: "San Sebastian", mun_code: "012921" },
  // { label: "Ampandula", mun_code: "012922" },
  // { label: "Banaoang", mun_code: "012922" },
  // { label: "Basug", mun_code: "012922" },
  // { label: "Bucalag", mun_code: "012922" },
  // { label: "Cabangaran", mun_code: "012922" },
  // { label: "Calungboyan", mun_code: "012922" },
  // { label: "Casiber", mun_code: "012922" },
  // { label: "Dammay", mun_code: "012922" },
  // { label: "Labut Norte", mun_code: "012922" },
  // { label: "Labut Sur", mun_code: "012922" },
  // { label: "Mabilbila Sur", mun_code: "012922" },
  // { label: "Mabilbila Norte", mun_code: "012922" },
  // { label: "Magsaysay District (Pob.)", mun_code: "012922" },
  // { label: "Manueva", mun_code: "012922" },
  // { label: "Marcos (Pob.)", mun_code: "012922" },
  // { label: "Nagpanaoan", mun_code: "012922" },
  // { label: "Namalangan", mun_code: "012922" },
  // { label: "Oribi", mun_code: "012922" },
  // { label: "Pasungol", mun_code: "012922" },
  // { label: "Quezon (Pob.)", mun_code: "012922" },
  // { label: "Quirino (Pob.)", mun_code: "012922" },
  // { label: "Rancho", mun_code: "012922" },
  // { label: "Rizal", mun_code: "012922" },
  // { label: "Sacuyya Norte", mun_code: "012922" },
  // { label: "Sacuyya Sur", mun_code: "012922" },
  // { label: "Tabucolan", mun_code: "012922" },
  // { label: "Cabaroan", mun_code: "012923" },
  // { label: "Cabittaogan", mun_code: "012923" },
  // { label: "Cabuloan", mun_code: "012923" },
  // { label: "Pangada", mun_code: "012923" },
  // { label: "Paratong", mun_code: "012923" },
  // { label: "Poblacion", mun_code: "012923" },
  // { label: "Sinabaan", mun_code: "012923" },
  // { label: "Subec", mun_code: "012923" },
  // { label: "Tamorong", mun_code: "012923" },
  // { label: "Amarao", mun_code: "012924" },
  // { label: "Babayoan", mun_code: "012924" },
  // { label: "Bacsayan", mun_code: "012924" },
  // { label: "Banay", mun_code: "012924" },
  // { label: "Bayugao Este", mun_code: "012924" },
  // { label: "Bayugao Oeste", mun_code: "012924" },
  // { label: "Besalan", mun_code: "012924" },
  // { label: "Bugbuga", mun_code: "012924" },
  // { label: "Calaoaan", mun_code: "012924" },
  // { label: "Camanggaan", mun_code: "012924" },
  // { label: "Candalican", mun_code: "012924" },
  // { label: "Capariaan", mun_code: "012924" },
  // { label: "Casilagan", mun_code: "012924" },
  // { label: "Coscosnong", mun_code: "012924" },
  // { label: "Daligan", mun_code: "012924" },
  // { label: "Dili", mun_code: "012924" },
  // { label: "Gabor Norte", mun_code: "012924" },
  // { label: "Gabor Sur", mun_code: "012924" },
  // { label: "Lalong", mun_code: "012924" },
  // { label: "Lantag", mun_code: "012924" },
  // { label: "Las-ud", mun_code: "012924" },
  // { label: "Mambog", mun_code: "012924" },
  // { label: "Mantanas", mun_code: "012924" },
  // { label: "Nagtengnga", mun_code: "012924" },
  // { label: "Padaoil", mun_code: "012924" },
  // { label: "Paratong", mun_code: "012924" },
  // { label: "Pattiqui", mun_code: "012924" },
  // { label: "Pidpid", mun_code: "012924" },
  // { label: "Pilar", mun_code: "012924" },
  // { label: "Pinipin", mun_code: "012924" },
  // { label: "Poblacion Este", mun_code: "012924" },
  // { label: "Poblacion Norte", mun_code: "012924" },
  // { label: "Poblacion Weste", mun_code: "012924" },
  // { label: "Poblacion Sur", mun_code: "012924" },
  // { label: "Quinfermin", mun_code: "012924" },
  // { label: "Quinsoriano", mun_code: "012924" },
  // { label: "Sagat", mun_code: "012924" },
  // { label: "San Antonio", mun_code: "012924" },
  // { label: "San Jose", mun_code: "012924" },
  // { label: "San Pedro", mun_code: "012924" },
  // { label: "Saoat", mun_code: "012924" },
  // { label: "Sevilla", mun_code: "012924" },
  // { label: "Sidaoen", mun_code: "012924" },
  // { label: "Suyo", mun_code: "012924" },
  // { label: "Tampugo", mun_code: "012924" },
  // { label: "Turod", mun_code: "012924" },
  // { label: "Villa Garcia", mun_code: "012924" },
  // { label: "Villa Hermosa", mun_code: "012924" },
  // { label: "Villa Laurencia", mun_code: "012924" },
  // { label: "Alincaoeg", mun_code: "012925" },
  // { label: "Angkileng", mun_code: "012925" },
  // { label: "Arangin", mun_code: "012925" },
  // { label: "Ayusan (Pob.)", mun_code: "012925" },
  // { label: "Banbanaba", mun_code: "012925" },
  // { label: "Bao-as", mun_code: "012925" },
  // { label: "Barangobong (Pob.)", mun_code: "012925" },
  // { label: "Buliclic", mun_code: "012925" },
  // { label: "Burgos (Pob.)", mun_code: "012925" },
  // { label: "Cabaritan", mun_code: "012925" },
  // { label: "Catayagan", mun_code: "012925" },
  // { label: "Conconig East", mun_code: "012925" },
  // { label: "Conconig West", mun_code: "012925" },
  // { label: "Damacuag", mun_code: "012925" },
  // { label: "Lubong", mun_code: "012925" },
  // { label: "Luba", mun_code: "012925" },
  // { label: "Nagrebcan", mun_code: "012925" },
  // { label: "Nagtablaan", mun_code: "012925" },
  // { label: "Namatican", mun_code: "012925" },
  // { label: "Nangalisan", mun_code: "012925" },
  // { label: "Palali Norte", mun_code: "012925" },
  // { label: "Palali Sur", mun_code: "012925" },
  // { label: "Paoc Norte", mun_code: "012925" },
  // { label: "Paoc Sur", mun_code: "012925" },
  // { label: "Paratong", mun_code: "012925" },
  // { label: "Pila East", mun_code: "012925" },
  // { label: "Pila West", mun_code: "012925" },
  // { label: "Quinabalayangan", mun_code: "012925" },
  // { label: "Ronda", mun_code: "012925" },
  // { label: "Sabuanan", mun_code: "012925" },
  // { label: "San Juan", mun_code: "012925" },
  // { label: "San Pedro", mun_code: "012925" },
  // { label: "Sapang", mun_code: "012925" },
  // { label: "Suagayan", mun_code: "012925" },
  // { label: "Vical", mun_code: "012925" },
  // { label: "Bani", mun_code: "012925" },
  // { label: "Ag-agrao", mun_code: "012926" },
  // { label: "Ampuagan", mun_code: "012926" },
  // { label: "Baballasioan", mun_code: "012926" },
  // { label: "Baliw Daya", mun_code: "012926" },
  // { label: "Baliw Laud", mun_code: "012926" },
  // { label: "Bia-o", mun_code: "012926" },
  // { label: "Butir", mun_code: "012926" },
  // { label: "Cabaroan", mun_code: "012926" },
  // { label: "Danuman East", mun_code: "012926" },
  // { label: "Danuman West", mun_code: "012926" },
  // { label: "Dunglayan", mun_code: "012926" },
  // { label: "Gusing", mun_code: "012926" },
  // { label: "Langaoan", mun_code: "012926" },
  // { label: "Laslasong Norte", mun_code: "012926" },
  // { label: "Laslasong Sur", mun_code: "012926" },
  // { label: "Laslasong West", mun_code: "012926" },
  // { label: "Lesseb", mun_code: "012926" },
  // { label: "Lingsat", mun_code: "012926" },
  // { label: "Lubong", mun_code: "012926" },
  // { label: "Maynganay Norte", mun_code: "012926" },
  // { label: "Maynganay Sur", mun_code: "012926" },
  // { label: "Nagsayaoan", mun_code: "012926" },
  // { label: "Nagtupacan", mun_code: "012926" },
  // { label: "Nalvo", mun_code: "012926" },
  // { label: "Pacang", mun_code: "012926" },
  // { label: "Penned", mun_code: "012926" },
  // { label: "Poblacion Norte", mun_code: "012926" },
  // { label: "Poblacion Sur", mun_code: "012926" },
  // { label: "Silag", mun_code: "012926" },
  // { label: "Sumagui", mun_code: "012926" },
  // { label: "Suso", mun_code: "012926" },
  // { label: "Tangaoan", mun_code: "012926" },
  // { label: "Tinaan", mun_code: "012926" },
  // { label: "Al-aludig", mun_code: "012927" },
  // { label: "Ambucao", mun_code: "012927" },
  // { label: "San Jose (Baraoas)", mun_code: "012927" },
  // { label: "Baybayabas", mun_code: "012927" },
  // { label: "Bigbiga", mun_code: "012927" },
  // { label: "Bulbulala", mun_code: "012927" },
  // { label: "Busel-busel", mun_code: "012927" },
  // { label: "Butol", mun_code: "012927" },
  // { label: "Caburao", mun_code: "012927" },
  // { label: "Dan-ar", mun_code: "012927" },
  // { label: "Gabao", mun_code: "012927" },
  // { label: "Guinabang", mun_code: "012927" },
  // { label: "Imus", mun_code: "012927" },
  // { label: "Lang-ayan", mun_code: "012927" },
  // { label: "Mambug", mun_code: "012927" },
  // { label: "Nalasin", mun_code: "012927" },
  // { label: "Olo-olo Norte", mun_code: "012927" },
  // { label: "Olo-olo Sur", mun_code: "012927" },
  // { label: "Poblacion Norte", mun_code: "012927" },
  // { label: "Poblacion Sur", mun_code: "012927" },
  // { label: "Sabangan", mun_code: "012927" },
  // { label: "Salincub", mun_code: "012927" },
  // { label: "San Roque", mun_code: "012927" },
  // { label: "Ubbog", mun_code: "012927" },
  // { label: "Binalayangan", mun_code: "012928" },
  // { label: "Binongan", mun_code: "012928" },
  // { label: "Borobor", mun_code: "012928" },
  // { label: "Cabaritan", mun_code: "012928" },
  // { label: "Cabigbigaan", mun_code: "012928" },
  // { label: "Calautit", mun_code: "012928" },
  // { label: "Calay-ab", mun_code: "012928" },
  // { label: "Camestizoan", mun_code: "012928" },
  // { label: "Casili", mun_code: "012928" },
  // { label: "Flora", mun_code: "012928" },
  // { label: "Lagatit", mun_code: "012928" },
  // { label: "Laoingen", mun_code: "012928" },
  // { label: "Lussoc", mun_code: "012928" },
  // { label: "Nalasin", mun_code: "012928" },
  // { label: "Nagbettedan", mun_code: "012928" },
  // { label: "Naglaoa-an", mun_code: "012928" },
  // { label: "Nambaran", mun_code: "012928" },
  // { label: "Nanerman", mun_code: "012928" },
  // { label: "Napo", mun_code: "012928" },
  // { label: "Padu Chico", mun_code: "012928" },
  // { label: "Padu Grande", mun_code: "012928" },
  // { label: "Paguraper", mun_code: "012928" },
  // { label: "Panay", mun_code: "012928" },
  // { label: "Pangpangdan", mun_code: "012928" },
  // { label: "Parada", mun_code: "012928" },
  // { label: "Paras", mun_code: "012928" },
  // { label: "Poblacion", mun_code: "012928" },
  // { label: "Puerta Real", mun_code: "012928" },
  // { label: "Pussuac", mun_code: "012928" },
  // { label: "Quimmarayan", mun_code: "012928" },
  // { label: "San Pablo", mun_code: "012928" },
  // { label: "Santa Cruz", mun_code: "012928" },
  // { label: "Santo Tomas", mun_code: "012928" },
  // { label: "Sived", mun_code: "012928" },
  // { label: "Vacunero", mun_code: "012928" },
  // { label: "Suksukit", mun_code: "012928" },
  // { label: "Abaccan", mun_code: "012929" },
  // { label: "Mabileg", mun_code: "012929" },
  // { label: "Matallucod", mun_code: "012929" },
  // { label: "Poblacion (Madayaw)", mun_code: "012929" },
  // { label: "San Elias", mun_code: "012929" },
  // { label: "San Ramon", mun_code: "012929" },
  // { label: "Santo Rosario", mun_code: "012929" },
  // { label: "Aguing", mun_code: "012930" },
  // { label: "Ballaigui (Pob.)", mun_code: "012930" },
  // { label: "Baliw", mun_code: "012930" },
  // { label: "Baracbac", mun_code: "012930" },
  // { label: "Barikir", mun_code: "012930" },
  // { label: "Battog", mun_code: "012930" },
  // { label: "Binacud", mun_code: "012930" },
  // { label: "Cabangtalan", mun_code: "012930" },
  // { label: "Cabarambanan", mun_code: "012930" },
  // { label: "Cabulalaan", mun_code: "012930" },
  // { label: "Cadanglaan", mun_code: "012930" },
  // { label: "Calingayan", mun_code: "012930" },
  // { label: "Curtin", mun_code: "012930" },
  // { label: "Dadalaquiten Norte", mun_code: "012930" },
  // { label: "Dadalaquiten Sur", mun_code: "012930" },
  // { label: "Duyayyat", mun_code: "012930" },
  // { label: "Jordan", mun_code: "012930" },
  // { label: "Calanutian", mun_code: "012930" },
  // { label: "Katipunan", mun_code: "012930" },
  // { label: "Macabiag (Pob.)", mun_code: "012930" },
  // { label: "Magsaysay", mun_code: "012930" },
  // { label: "Marnay", mun_code: "012930" },
  // { label: "Masadag", mun_code: "012930" },
  // { label: "Nagcullooban", mun_code: "012930" },
  // { label: "Nagbalioartian", mun_code: "012930" },
  // { label: "Nagongburan", mun_code: "012930" },
  // { label: "Namnama (Pob.)", mun_code: "012930" },
  // { label: "Pacis", mun_code: "012930" },
  // { label: "Paratong", mun_code: "012930" },
  // { label: "Dean Leopoldo Yabes (Pug-os)", mun_code: "012930" },
  // { label: "Purag", mun_code: "012930" },
  // { label: "Quibit-quibit", mun_code: "012930" },
  // { label: "Quimmallogong", mun_code: "012930" },
  // { label: "Rang-ay (Pob.)", mun_code: "012930" },
  // { label: "Ricudo", mun_code: "012930" },
  // { label: "Sallacapo", mun_code: "012930" },
  // { label: "Santa Cruz", mun_code: "012930" },
  // { label: "Sapriana", mun_code: "012930" },
  // { label: "Tapao", mun_code: "012930" },
  // { label: "Teppeng", mun_code: "012930" },
  // { label: "Tubigay", mun_code: "012930" },
  // { label: "Ubbog", mun_code: "012930" },
  // { label: "Zapat", mun_code: "012930" },
  // { label: "Banga", mun_code: "012931" },
  // { label: "Caoayan", mun_code: "012931" },
  // { label: "Licungan (Cullang)", mun_code: "012931" },
  // { label: "Danac", mun_code: "012931" },
  // { label: "Pangotan", mun_code: "012931" },
  // { label: "Balbalayang (Pob.)", mun_code: "012931" },
  // { label: "Baringcucurong", mun_code: "012932" },
  // { label: "Cabugao", mun_code: "012932" },
  // { label: "Man-atong", mun_code: "012932" },
  // { label: "Patoc-ao", mun_code: "012932" },
  // { label: "Poblacion (Kimpusa)", mun_code: "012932" },
  // { label: "Suyo Proper", mun_code: "012932" },
  // { label: "Urzadan", mun_code: "012932" },
  // { label: "Uso", mun_code: "012932" },
  // { label: "Ag-aguman", mun_code: "012933" },
  // { label: "Ambalayat", mun_code: "012933" },
  // { label: "Baracbac", mun_code: "012933" },
  // { label: "Bario-an", mun_code: "012933" },
  // { label: "Baritao", mun_code: "012933" },
  // { label: "Borono", mun_code: "012933" },
  // { label: "Becques", mun_code: "012933" },
  // { label: "Bimmanga", mun_code: "012933" },
  // { label: "Bio", mun_code: "012933" },
  // { label: "Bitalag", mun_code: "012933" },
  // { label: "Bucao East", mun_code: "012933" },
  // { label: "Bucao West", mun_code: "012933" },
  // { label: "Cabaroan", mun_code: "012933" },
  // { label: "Cabugbugan", mun_code: "012933" },
  // { label: "Cabulanglangan", mun_code: "012933" },
  // { label: "Dacutan", mun_code: "012933" },
  // { label: "Dardarat", mun_code: "012933" },
  // { label: "Del Pilar (Pob.)", mun_code: "012933" },
  // { label: "Farola", mun_code: "012933" },
  // { label: "Gabur", mun_code: "012933" },
  // { label: "Garitan", mun_code: "012933" },
  // { label: "Jardin", mun_code: "012933" },
  // { label: "Lacong", mun_code: "012933" },
  // { label: "Lantag", mun_code: "012933" },
  // { label: "Las-ud", mun_code: "012933" },
  // { label: "Libtong", mun_code: "012933" },
  // { label: "Lubnac", mun_code: "012933" },
  // { label: "Magsaysay (Pob.)", mun_code: "012933" },
  // { label: "Pacac", mun_code: "012933" },
  // { label: "Pallogan", mun_code: "012933" },
  // { label: "Pudoc East", mun_code: "012933" },
  // { label: "Pudoc West", mun_code: "012933" },
  // { label: "Pula", mun_code: "012933" },
  // { label: "Quirino (Pob.)", mun_code: "012933" },
  // { label: "Ranget", mun_code: "012933" },
  // { label: "Rizal (Pob.)", mun_code: "012933" },
  // { label: "Salvacion", mun_code: "012933" },
  // { label: "San Miguel", mun_code: "012933" },
  // { label: "Sawat", mun_code: "012933" },
  // { label: "Tallaoen", mun_code: "012933" },
  // { label: "Tampugo", mun_code: "012933" },
  // { label: "Tarangotong", mun_code: "012933" },
  // { label: "Ayusan Norte", mun_code: "012934" },
  // { label: "Ayusan Sur", mun_code: "012934" },
  // { label: "Barangay I (Pob.)", mun_code: "012934" },
  // { label: "Barangay II (Pob.)", mun_code: "012934" },
  // { label: "Barangay III (Pob.)", mun_code: "012934" },
  // { label: "Barangay IV (Pob.)", mun_code: "012934" },
  // { label: "Barangay V (Pob.)", mun_code: "012934" },
  // { label: "Barangay VI (Pob.)", mun_code: "012934" },
  // { label: "Barraca", mun_code: "012934" },
  // { label: "Beddeng Laud", mun_code: "012934" },
  // { label: "Beddeng Daya", mun_code: "012934" },
  // { label: "Bongtolan", mun_code: "012934" },
  // { label: "Bulala", mun_code: "012934" },
  // { label: "Cabalangegan", mun_code: "012934" },
  // { label: "Cabaroan Daya", mun_code: "012934" },
  // { label: "Cabaroan Laud", mun_code: "012934" },
  // { label: "Camangaan", mun_code: "012934" },
  // { label: "Capangpangan", mun_code: "012934" },
  // { label: "Mindoro", mun_code: "012934" },
  // { label: "Nagsangalan", mun_code: "012934" },
  // { label: "Pantay Daya", mun_code: "012934" },
  // { label: "Pantay Fatima", mun_code: "012934" },
  // { label: "Pantay Laud", mun_code: "012934" },
  // { label: "Paoa", mun_code: "012934" },
  // { label: "Paratong", mun_code: "012934" },
  // { label: "Pong-ol", mun_code: "012934" },
  // { label: "Purok-a-bassit", mun_code: "012934" },
  // { label: "Purok-a-dackel", mun_code: "012934" },
  // { label: "Raois", mun_code: "012934" },
  // { label: "Rugsuanan", mun_code: "012934" },
  // { label: "Salindeg", mun_code: "012934" },
  // { label: "San Jose", mun_code: "012934" },
  // { label: "San Julian Norte", mun_code: "012934" },
  // { label: "San Julian Sur", mun_code: "012934" },
  // { label: "San Pedro", mun_code: "012934" },
  // { label: "Tamag", mun_code: "012934" },
  // { label: "Barangay VII", mun_code: "012934" },
  // { label: "Barangay VIII", mun_code: "012934" },
  // { label: "Barangay IX", mun_code: "012934" },
  // { label: "Ambitacay", mun_code: "013301" },
  // { label: "Balawarte", mun_code: "013301" },
  // { label: "Capas", mun_code: "013301" },
  // { label: "Consolacion (Pob.)", mun_code: "013301" },
  // { label: "Macalva Central", mun_code: "013301" },
  // { label: "Macalva Norte", mun_code: "013301" },
  // { label: "Macalva Sur", mun_code: "013301" },
  // { label: "Nazareno", mun_code: "013301" },
  // { label: "Purok", mun_code: "013301" },
  // { label: "San Agustin East", mun_code: "013301" },
  // { label: "San Agustin Norte", mun_code: "013301" },
  // { label: "San Agustin Sur", mun_code: "013301" },
  // { label: "San Antonino", mun_code: "013301" },
  // { label: "San Antonio", mun_code: "013301" },
  // { label: "San Francisco", mun_code: "013301" },
  // { label: "San Isidro", mun_code: "013301" },
  // { label: "San Joaquin Norte", mun_code: "013301" },
  // { label: "San Joaquin Sur", mun_code: "013301" },
  // { label: "San Jose Norte", mun_code: "013301" },
  // { label: "San Jose Sur", mun_code: "013301" },
  // { label: "San Juan", mun_code: "013301" },
  // { label: "San Julian Central", mun_code: "013301" },
  // { label: "San Julian East", mun_code: "013301" },
  // { label: "San Julian Norte", mun_code: "013301" },
  // { label: "San Julian West", mun_code: "013301" },
  // { label: "San Manuel Norte", mun_code: "013301" },
  // { label: "San Manuel Sur", mun_code: "013301" },
  // { label: "San Marcos", mun_code: "013301" },
  // { label: "San Miguel", mun_code: "013301" },
  // { label: "San Nicolas Central (Pob.)", mun_code: "013301" },
  // { label: "San Nicolas East", mun_code: "013301" },
  // { label: "San Nicolas Norte (Pob.)", mun_code: "013301" },
  // { label: "San Nicolas West", mun_code: "013301" },
  // { label: "San Nicolas Sur (Pob.)", mun_code: "013301" },
  // { label: "San Pedro", mun_code: "013301" },
  // { label: "San Roque West", mun_code: "013301" },
  // { label: "San Roque East", mun_code: "013301" },
  // { label: "San Vicente Norte", mun_code: "013301" },
  // { label: "San Vicente Sur", mun_code: "013301" },
  // { label: "Santa Ana", mun_code: "013301" },
  // { label: "Santa Barbara (Pob.)", mun_code: "013301" },
  // { label: "Santa Fe", mun_code: "013301" },
  // { label: "Santa Maria", mun_code: "013301" },
  // { label: "Santa Monica", mun_code: "013301" },
  // { label: "Santa Rita (Nalinac)", mun_code: "013301" },
  // { label: "Santa Rita East", mun_code: "013301" },
  // { label: "Santa Rita Norte", mun_code: "013301" },
  // { label: "Santa Rita Sur", mun_code: "013301" },
  // { label: "Santa Rita West", mun_code: "013301" },
  // { label: "Alaska", mun_code: "013302" },
  // { label: "Basca", mun_code: "013302" },
  // { label: "Dulao", mun_code: "013302" },
  // { label: "Gallano", mun_code: "013302" },
  // { label: "Macabato", mun_code: "013302" },
  // { label: "Manga", mun_code: "013302" },
  // { label: "Pangao-aoan East", mun_code: "013302" },
  // { label: "Pangao-aoan West", mun_code: "013302" },
  // { label: "Poblacion", mun_code: "013302" },
  // { label: "Samara", mun_code: "013302" },
  // { label: "San Antonio", mun_code: "013302" },
  // { label: "San Benito Norte", mun_code: "013302" },
  // { label: "San Benito Sur", mun_code: "013302" },
  // { label: "San Eugenio", mun_code: "013302" },
  // { label: "San Juan East", mun_code: "013302" },
  // { label: "San Juan West", mun_code: "013302" },
  // { label: "San Simon East", mun_code: "013302" },
  // { label: "San Simon West", mun_code: "013302" },
  // { label: "Santa Cecilia", mun_code: "013302" },
  // { label: "Santa Lucia", mun_code: "013302" },
  // { label: "Santa Rita East", mun_code: "013302" },
  // { label: "Santa Rita West", mun_code: "013302" },
  // { label: "Santo Rosario East", mun_code: "013302" },
  // { label: "Santo Rosario West", mun_code: "013302" },
  // { label: "Agtipal", mun_code: "013303" },
  // { label: "Arosip", mun_code: "013303" },
  // { label: "Bacqui", mun_code: "013303" },
  // { label: "Bacsil", mun_code: "013303" },
  // { label: "Bagutot", mun_code: "013303" },
  // { label: "Ballogo", mun_code: "013303" },
  // { label: "Baroro", mun_code: "013303" },
  // { label: "Bitalag", mun_code: "013303" },
  // { label: "Bulala", mun_code: "013303" },
  // { label: "Burayoc", mun_code: "013303" },
  // { label: "Bussaoit", mun_code: "013303" },
  // { label: "Cabaroan", mun_code: "013303" },
  // { label: "Cabarsican", mun_code: "013303" },
  // { label: "Cabugao", mun_code: "013303" },
  // { label: "Calautit", mun_code: "013303" },
  // { label: "Carcarmay", mun_code: "013303" },
  // { label: "Casiaman", mun_code: "013303" },
  // { label: "Galongen", mun_code: "013303" },
  // { label: "Guinabang", mun_code: "013303" },
  // { label: "Legleg", mun_code: "013303" },
  // { label: "Lisqueb", mun_code: "013303" },
  // { label: "Mabanengbeng 1st", mun_code: "013303" },
  // { label: "Mabanengbeng 2nd", mun_code: "013303" },
  // { label: "Maragayap", mun_code: "013303" },
  // { label: "Nangalisan", mun_code: "013303" },
  // { label: "Nagatiran", mun_code: "013303" },
  // { label: "Nagsaraboan", mun_code: "013303" },
  // { label: "Nagsimbaanan", mun_code: "013303" },
  // { label: "Narra", mun_code: "013303" },
  // { label: "Ortega", mun_code: "013303" },
  // { label: "Paagan", mun_code: "013303" },
  // { label: "Pandan", mun_code: "013303" },
  // { label: "Pang-pang", mun_code: "013303" },
  // { label: "Poblacion", mun_code: "013303" },
  // { label: "Quirino", mun_code: "013303" },
  // { label: "Raois", mun_code: "013303" },
  // { label: "Salincob", mun_code: "013303" },
  // { label: "San Martin", mun_code: "013303" },
  // { label: "Santa Cruz", mun_code: "013303" },
  // { label: "Santa Rita", mun_code: "013303" },
  // { label: "Sapilang", mun_code: "013303" },
  // { label: "Sayoan", mun_code: "013303" },
  // { label: "Sipulo", mun_code: "013303" },
  // { label: "Tammocalao", mun_code: "013303" },
  // { label: "Ubbog", mun_code: "013303" },
  // { label: "Oya-oy", mun_code: "013303" },
  // { label: "Zaragosa", mun_code: "013303" },
  // { label: "Alibangsay", mun_code: "013304" },
  // { label: "Baay", mun_code: "013304" },
  // { label: "Cambaly", mun_code: "013304" },
  // { label: "Cardiz", mun_code: "013304" },
  // { label: "Dagup", mun_code: "013304" },
  // { label: "Libbo", mun_code: "013304" },
  // { label: "Suyo (Pob.)", mun_code: "013304" },
  // { label: "Tagudtud", mun_code: "013304" },
  // { label: "Tio-angan", mun_code: "013304" },
  // { label: "Wallayan", mun_code: "013304" },
  // { label: "Apatut", mun_code: "013305" },
  // { label: "Ar-arampang", mun_code: "013305" },
  // { label: "Baracbac Este", mun_code: "013305" },
  // { label: "Baracbac Oeste", mun_code: "013305" },
  // { label: "Bet-ang", mun_code: "013305" },
  // { label: "Bulbulala", mun_code: "013305" },
  // { label: "Bungol", mun_code: "013305" },
  // { label: "Butubut Este", mun_code: "013305" },
  // { label: "Butubut Norte", mun_code: "013305" },
  // { label: "Butubut Oeste", mun_code: "013305" },
  // { label: "Butubut Sur", mun_code: "013305" },
  // { label: "Cabuaan Oeste (Pob.)", mun_code: "013305" },
  // { label: "Calliat", mun_code: "013305" },
  // { label: "Calungbuyan", mun_code: "013305" },
  // { label: "Camiling", mun_code: "013305" },
  // { label: "Guinaburan", mun_code: "013305" },
  // { label: "Masupe", mun_code: "013305" },
  // { label: "Nagsabaran Norte", mun_code: "013305" },
  // { label: "Nagsabaran Sur", mun_code: "013305" },
  // { label: "Nalasin", mun_code: "013305" },
  // { label: "Napaset", mun_code: "013305" },
  // { label: "Pagbennecan", mun_code: "013305" },
  // { label: "Pagleddegan", mun_code: "013305" },
  // { label: "Pantar Norte", mun_code: "013305" },
  // { label: "Pantar Sur", mun_code: "013305" },
  // { label: "Pa-o", mun_code: "013305" },
  // { label: "Almieda", mun_code: "013305" },
  // { label: "Paraoir", mun_code: "013305" },
  // { label: "Patpata", mun_code: "013305" },
  // { label: "Dr. Camilo Osias Pob. (Cabuaan Este)", mun_code: "013305" },
  // { label: "Sablut", mun_code: "013305" },
  // { label: "San Pablo", mun_code: "013305" },
  // { label: "Sinapangan Norte", mun_code: "013305" },
  // { label: "Sinapangan Sur", mun_code: "013305" },
  // { label: "Tallipugo", mun_code: "013305" },
  // { label: "Antonino", mun_code: "013305" },
  // { label: "Agdeppa", mun_code: "013306" },
  // { label: "Alzate", mun_code: "013306" },
  // { label: "Bangaoilan East", mun_code: "013306" },
  // { label: "Bangaoilan West", mun_code: "013306" },
  // { label: "Barraca", mun_code: "013306" },
  // { label: "Cadapli", mun_code: "013306" },
  // { label: "Caggao", mun_code: "013306" },
  // { label: "Consuegra", mun_code: "013306" },
  // { label: "General Prim East", mun_code: "013306" },
  // { label: "General Prim West", mun_code: "013306" },
  // { label: "General Terrero", mun_code: "013306" },
  // { label: "Luzong Norte", mun_code: "013306" },
  // { label: "Luzong Sur", mun_code: "013306" },
  // { label: "Maria Cristina East", mun_code: "013306" },
  // { label: "Maria Cristina West", mun_code: "013306" },
  // { label: "Mindoro", mun_code: "013306" },
  // { label: "Nagsabaran", mun_code: "013306" },
  // { label: "Paratong Norte", mun_code: "013306" },
  // { label: "Paratong No. 3", mun_code: "013306" },
  // { label: "Paratong No. 4", mun_code: "013306" },
  // { label: "Central East No. 1 (Pob.)", mun_code: "013306" },
  // { label: "Central East No. 2 (Pob.)", mun_code: "013306" },
  // { label: "Central West No. 1 (Pob.)", mun_code: "013306" },
  // { label: "Central West No. 2 (Pob.)", mun_code: "013306" },
  // { label: "Central West No. 3 (Pob.)", mun_code: "013306" },
  // { label: "Quintarong", mun_code: "013306" },
  // { label: "Reyna Regente", mun_code: "013306" },
  // { label: "Rissing", mun_code: "013306" },
  // { label: "San Blas", mun_code: "013306" },
  // { label: "San Cristobal", mun_code: "013306" },
  // { label: "Sinapangan Norte", mun_code: "013306" },
  // { label: "Sinapangan Sur", mun_code: "013306" },
  // { label: "Ubbog", mun_code: "013306" },
  // { label: "Acao", mun_code: "013307" },
  // { label: "Baccuit Norte", mun_code: "013307" },
  // { label: "Baccuit Sur", mun_code: "013307" },
  // { label: "Bagbag", mun_code: "013307" },
  // { label: "Ballay", mun_code: "013307" },
  // { label: "Bawanta", mun_code: "013307" },
  // { label: "Boy-utan", mun_code: "013307" },
  // { label: "Bucayab", mun_code: "013307" },
  // { label: "Cabalayangan", mun_code: "013307" },
  // { label: "Cabisilan", mun_code: "013307" },
  // { label: "Calumbaya", mun_code: "013307" },
  // { label: "Carmay", mun_code: "013307" },
  // { label: "Casilagan", mun_code: "013307" },
  // { label: "Central East (Pob.)", mun_code: "013307" },
  // { label: "Central West (Pob.)", mun_code: "013307" },
  // { label: "Dili", mun_code: "013307" },
  // { label: "Disso-or", mun_code: "013307" },
  // { label: "Guerrero", mun_code: "013307" },
  // { label: "Nagrebcan", mun_code: "013307" },
  // { label: "Pagdalagan Sur", mun_code: "013307" },
  // { label: "Palintucang", mun_code: "013307" },
  // { label: "Palugsi-Limmansangan", mun_code: "013307" },
  // { label: "Parian Oeste", mun_code: "013307" },
  // { label: "Parian Este", mun_code: "013307" },
  // { label: "Paringao", mun_code: "013307" },
  // { label: "Payocpoc Norte Este", mun_code: "013307" },
  // { label: "Payocpoc Norte Oeste", mun_code: "013307" },
  // { label: "Payocpoc Sur", mun_code: "013307" },
  // { label: "Pilar", mun_code: "013307" },
  // { label: "Pudoc", mun_code: "013307" },
  // { label: "Pottot", mun_code: "013307" },
  // { label: "Pugo", mun_code: "013307" },
  // { label: "Quinavite", mun_code: "013307" },
  // { label: "Lower San Agustin", mun_code: "013307" },
  // { label: "Santa Monica", mun_code: "013307" },
  // { label: "Santiago", mun_code: "013307" },
  // { label: "Taberna", mun_code: "013307" },
  // { label: "Upper San Agustin", mun_code: "013307" },
  // { label: "Urayong", mun_code: "013307" },
  // { label: "Agpay", mun_code: "013308" },
  // { label: "Bilis", mun_code: "013308" },
  // { label: "Caoayan", mun_code: "013308" },
  // { label: "Dalacdac", mun_code: "013308" },
  // { label: "Delles", mun_code: "013308" },
  // { label: "Imelda", mun_code: "013308" },
  // { label: "Libtong", mun_code: "013308" },
  // { label: "Linuan", mun_code: "013308" },
  // { label: "New Poblacion", mun_code: "013308" },
  // { label: "Old Poblacion", mun_code: "013308" },
  // { label: "Lower Tumapoc", mun_code: "013308" },
  // { label: "Upper Tumapoc", mun_code: "013308" },
  // { label: "Bautista", mun_code: "013309" },
  // { label: "Gana", mun_code: "013309" },
  // { label: "Juan Cartas", mun_code: "013309" },
  // { label: "Las-ud", mun_code: "013309" },
  // { label: "Liquicia", mun_code: "013309" },
  // { label: "Poblacion Norte", mun_code: "013309" },
  // { label: "Poblacion Sur", mun_code: "013309" },
  // { label: "San Carlos", mun_code: "013309" },
  // { label: "San Cornelio", mun_code: "013309" },
  // { label: "San Fermin", mun_code: "013309" },
  // { label: "San Gregorio", mun_code: "013309" },
  // { label: "San Jose", mun_code: "013309" },
  // { label: "Santiago Norte", mun_code: "013309" },
  // { label: "Santiago Sur", mun_code: "013309" },
  // { label: "Sobredillo", mun_code: "013309" },
  // { label: "Urayong", mun_code: "013309" },
  // { label: "Wenceslao", mun_code: "013309" },
  // { label: "Alcala (Pob.)", mun_code: "013310" },
  // { label: "Ayaoan", mun_code: "013310" },
  // { label: "Barangobong", mun_code: "013310" },
  // { label: "Barrientos", mun_code: "013310" },
  // { label: "Bungro", mun_code: "013310" },
  // { label: "Buselbusel", mun_code: "013310" },
  // { label: "Cabalitocan", mun_code: "013310" },
  // { label: "Cantoria No. 1", mun_code: "013310" },
  // { label: "Cantoria No. 2", mun_code: "013310" },
  // { label: "Cantoria No. 3", mun_code: "013310" },
  // { label: "Cantoria No. 4", mun_code: "013310" },
  // { label: "Carisquis", mun_code: "013310" },
  // { label: "Darigayos", mun_code: "013310" },
  // { label: "Magallanes (Pob.)", mun_code: "013310" },
  // { label: "Magsiping", mun_code: "013310" },
  // { label: "Mamay", mun_code: "013310" },
  // { label: "Nagrebcan", mun_code: "013310" },
  // { label: "Nalvo Norte", mun_code: "013310" },
  // { label: "Nalvo Sur", mun_code: "013310" },
  // { label: "Napaset", mun_code: "013310" },
  // { label: "Oaqui No. 1", mun_code: "013310" },
  // { label: "Oaqui No. 2", mun_code: "013310" },
  // { label: "Oaqui No. 3", mun_code: "013310" },
  // { label: "Oaqui No. 4", mun_code: "013310" },
  // { label: "Pila", mun_code: "013310" },
  // { label: "Pitpitac", mun_code: "013310" },
  // { label: "Rimos No. 1", mun_code: "013310" },
  // { label: "Rimos No. 2", mun_code: "013310" },
  // { label: "Rimos No. 3", mun_code: "013310" },
  // { label: "Rimos No. 4", mun_code: "013310" },
  // { label: "Rimos No. 5", mun_code: "013310" },
  // { label: "Rissing", mun_code: "013310" },
  // { label: "Salcedo (Pob.)", mun_code: "013310" },
  // { label: "Santo Domingo Norte", mun_code: "013310" },
  // { label: "Santo Domingo Sur", mun_code: "013310" },
  // { label: "Sucoc Norte", mun_code: "013310" },
  // { label: "Sucoc Sur", mun_code: "013310" },
  // { label: "Suyo", mun_code: "013310" },
  // { label: "Tallaoen", mun_code: "013310" },
  // { label: "Victoria (Pob.)", mun_code: "013310" },
  // { label: "Aguioas", mun_code: "013311" },
  // { label: "Al-alinao Norte", mun_code: "013311" },
  // { label: "Al-alinao Sur", mun_code: "013311" },
  // { label: "Ambaracao Norte", mun_code: "013311" },
  // { label: "Ambaracao Sur", mun_code: "013311" },
  // { label: "Angin", mun_code: "013311" },
  // { label: "Balecbec", mun_code: "013311" },
  // { label: "Bancagan", mun_code: "013311" },
  // { label: "Baraoas Norte", mun_code: "013311" },
  // { label: "Baraoas Sur", mun_code: "013311" },
  // { label: "Bariquir", mun_code: "013311" },
  // { label: "Bato", mun_code: "013311" },
  // { label: "Bimmotobot", mun_code: "013311" },
  // { label: "Cabaritan Norte", mun_code: "013311" },
  // { label: "Cabaritan Sur", mun_code: "013311" },
  // { label: "Casilagan", mun_code: "013311" },
  // { label: "Dal-lipaoen", mun_code: "013311" },
  // { label: "Daramuangan", mun_code: "013311" },
  // { label: "Guesset", mun_code: "013311" },
  // { label: "Gusing Norte", mun_code: "013311" },
  // { label: "Gusing Sur", mun_code: "013311" },
  // { label: "Imelda", mun_code: "013311" },
  // { label: "Lioac Norte", mun_code: "013311" },
  // { label: "Lioac Sur", mun_code: "013311" },
  // { label: "Magungunay", mun_code: "013311" },
  // { label: "Mamat-ing Norte", mun_code: "013311" },
  // { label: "Mamat-ing Sur", mun_code: "013311" },
  // { label: "Nagsidorisan", mun_code: "013311" },
  // { label: "Natividad (Pob.)", mun_code: "013311" },
  // { label: "Ortiz (Pob.)", mun_code: "013311" },
  // { label: "Ribsuan", mun_code: "013311" },
  // { label: "San Antonio", mun_code: "013311" },
  // { label: "San Isidro", mun_code: "013311" },
  // { label: "Sili", mun_code: "013311" },
  // { label: "Suguidan Norte", mun_code: "013311" },
  // { label: "Suguidan Sur", mun_code: "013311" },
  // { label: "Tuddingan", mun_code: "013311" },
  // { label: "Ambalite", mun_code: "013312" },
  // { label: "Ambangonan", mun_code: "013312" },
  // { label: "Cares", mun_code: "013312" },
  // { label: "Cuenca", mun_code: "013312" },
  // { label: "Duplas", mun_code: "013312" },
  // { label: "Maoasoas Norte", mun_code: "013312" },
  // { label: "Maoasoas Sur", mun_code: "013312" },
  // { label: "Palina", mun_code: "013312" },
  // { label: "Poblacion East", mun_code: "013312" },
  // { label: "San Luis", mun_code: "013312" },
  // { label: "Saytan", mun_code: "013312" },
  // { label: "Tavora East", mun_code: "013312" },
  // { label: "Tavora Proper", mun_code: "013312" },
  // { label: "Poblacion West", mun_code: "013312" },
  // { label: "Alipang", mun_code: "013313" },
  // { label: "Ambangonan", mun_code: "013313" },
  // { label: "Amlang", mun_code: "013313" },
  // { label: "Bacani", mun_code: "013313" },
  // { label: "Bangar", mun_code: "013313" },
  // { label: "Bani", mun_code: "013313" },
  // { label: "Benteng-Sapilang", mun_code: "013313" },
  // { label: "Cadumanian", mun_code: "013313" },
  // { label: "Camp One", mun_code: "013313" },
  // { label: "Carunuan East", mun_code: "013313" },
  // { label: "Carunuan West", mun_code: "013313" },
  // { label: "Casilagan", mun_code: "013313" },
  // { label: "Cataguingtingan", mun_code: "013313" },
  // { label: "Concepcion", mun_code: "013313" },
  // { label: "Damortis", mun_code: "013313" },
  // { label: "Gumot-Nagcolaran", mun_code: "013313" },
  // { label: "Inabaan Norte", mun_code: "013313" },
  // { label: "Inabaan Sur", mun_code: "013313" },
  // { label: "Nagtagaan", mun_code: "013313" },
  // { label: "Nangcamotian", mun_code: "013313" },
  // { label: "Parasapas", mun_code: "013313" },
  // { label: "Poblacion East", mun_code: "013313" },
  // { label: "Poblacion West", mun_code: "013313" },
  // { label: "Puzon", mun_code: "013313" },
  // { label: "Rabon", mun_code: "013313" },
  // { label: "San Jose", mun_code: "013313" },
  // { label: "Marcos", mun_code: "013313" },
  // { label: "Subusub", mun_code: "013313" },
  // { label: "Tabtabungao", mun_code: "013313" },
  // { label: "Tanglag", mun_code: "013313" },
  // { label: "Tay-ac", mun_code: "013313" },
  // { label: "Udiao", mun_code: "013313" },
  // { label: "Vila", mun_code: "013313" },
  // { label: "Abut", mun_code: "013314" },
  // { label: "Apaleng", mun_code: "013314" },
  // { label: "Bacsil", mun_code: "013314" },
  // { label: "Bangbangolan", mun_code: "013314" },
  // { label: "Bangcusay", mun_code: "013314" },
  // { label: "Barangay I (Pob.)", mun_code: "013314" },
  // { label: "Barangay II (Pob.)", mun_code: "013314" },
  // { label: "Barangay III (Pob.)", mun_code: "013314" },
  // { label: "Barangay IV (Pob.)", mun_code: "013314" },
  // { label: "Baraoas", mun_code: "013314" },
  // { label: "Bato", mun_code: "013314" },
  // { label: "Biday", mun_code: "013314" },
  // { label: "Birunget", mun_code: "013314" },
  // { label: "Bungro", mun_code: "013314" },
  // { label: "Cabaroan (Negro)", mun_code: "013314" },
  // { label: "Cabarsican", mun_code: "013314" },
  // { label: "Cadaclan", mun_code: "013314" },
  // { label: "Calabugao", mun_code: "013314" },
  // { label: "Camansi", mun_code: "013314" },
  // { label: "Canaoay", mun_code: "013314" },
  // { label: "Carlatan", mun_code: "013314" },
  // { label: "Catbangen", mun_code: "013314" },
  // { label: "Dallangayan Este", mun_code: "013314" },
  // { label: "Dallangayan Oeste", mun_code: "013314" },
  // { label: "Dalumpinas Este", mun_code: "013314" },
  // { label: "Dalumpinas Oeste", mun_code: "013314" },
  // { label: "Ilocanos Norte", mun_code: "013314" },
  // { label: "Ilocanos Sur", mun_code: "013314" },
  // { label: "Langcuas", mun_code: "013314" },
  // { label: "Lingsat", mun_code: "013314" },
  // { label: "Madayegdeg", mun_code: "013314" },
  // { label: "Mameltac", mun_code: "013314" },
  // { label: "Masicong", mun_code: "013314" },
  // { label: "Nagyubuyuban", mun_code: "013314" },
  // { label: "Namtutan", mun_code: "013314" },
  // { label: "Narra Este", mun_code: "013314" },
  // { label: "Narra Oeste", mun_code: "013314" },
  // { label: "Pacpaco", mun_code: "013314" },
  // { label: "Pagdalagan", mun_code: "013314" },
  // { label: "Pagdaraoan", mun_code: "013314" },
  // { label: "Pagudpud", mun_code: "013314" },
  // { label: "Pao Norte", mun_code: "013314" },
  // { label: "Pao Sur", mun_code: "013314" },
  // { label: "Parian", mun_code: "013314" },
  // { label: "Pias", mun_code: "013314" },
  // { label: "Poro", mun_code: "013314" },
  // { label: "Puspus", mun_code: "013314" },
  // { label: "Sacyud", mun_code: "013314" },
  // { label: "Sagayad", mun_code: "013314" },
  // { label: "San Agustin", mun_code: "013314" },
  // { label: "San Francisco", mun_code: "013314" },
  // { label: "San Vicente", mun_code: "013314" },
  // { label: "Santiago Norte", mun_code: "013314" },
  // { label: "Santiago Sur", mun_code: "013314" },
  // { label: "Saoay", mun_code: "013314" },
  // { label: "Sevilla", mun_code: "013314" },
  // { label: "Siboan-Otong", mun_code: "013314" },
  // { label: "Tanqui", mun_code: "013314" },
  // { label: "Tanquigan", mun_code: "013314" },
  // { label: "Amontoc", mun_code: "013315" },
  // { label: "Apayao", mun_code: "013315" },
  // { label: "Balbalayang", mun_code: "013315" },
  // { label: "Bayabas", mun_code: "013315" },
  // { label: "Bucao", mun_code: "013315" },
  // { label: "Bumbuneg", mun_code: "013315" },
  // { label: "Lacong", mun_code: "013315" },
  // { label: "Lipay Este", mun_code: "013315" },
  // { label: "Lipay Norte", mun_code: "013315" },
  // { label: "Lipay Proper", mun_code: "013315" },
  // { label: "Lipay Sur", mun_code: "013315" },
  // { label: "Lon-oy", mun_code: "013315" },
  // { label: "Poblacion", mun_code: "013315" },
  // { label: "Polipol", mun_code: "013315" },
  // { label: "Daking", mun_code: "013315" },
  // { label: "Allangigan", mun_code: "013316" },
  // { label: "Aludaid", mun_code: "013316" },
  // { label: "Bacsayan", mun_code: "013316" },
  // { label: "Balballosa", mun_code: "013316" },
  // { label: "Bambanay", mun_code: "013316" },
  // { label: "Bugbugcao", mun_code: "013316" },
  // { label: "Caarusipan", mun_code: "013316" },
  // { label: "Cabaroan", mun_code: "013316" },
  // { label: "Cabugnayan", mun_code: "013316" },
  // { label: "Cacapian", mun_code: "013316" },
  // { label: "Caculangan", mun_code: "013316" },
  // { label: "Calincamasan", mun_code: "013316" },
  // { label: "Casilagan", mun_code: "013316" },
  // { label: "Catdongan", mun_code: "013316" },
  // { label: "Dangdangla", mun_code: "013316" },
  // { label: "Dasay", mun_code: "013316" },
  // { label: "Dinanum", mun_code: "013316" },
  // { label: "Duplas", mun_code: "013316" },
  // { label: "Guinguinabang", mun_code: "013316" },
  // { label: "Ili Norte (Pob.)", mun_code: "013316" },
  // { label: "Ili Sur (Pob.)", mun_code: "013316" },
  // { label: "Legleg", mun_code: "013316" },
  // { label: "Lubing", mun_code: "013316" },
  // { label: "Nadsaag", mun_code: "013316" },
  // { label: "Nagsabaran", mun_code: "013316" },
  // { label: "Naguirangan", mun_code: "013316" },
  // { label: "Naguituban", mun_code: "013316" },
  // { label: "Nagyubuyuban", mun_code: "013316" },
  // { label: "Oaquing", mun_code: "013316" },
  // { label: "Pacpacac", mun_code: "013316" },
  // { label: "Pagdildilan", mun_code: "013316" },
  // { label: "Panicsican", mun_code: "013316" },
  // { label: "Quidem", mun_code: "013316" },
  // { label: "San Felipe", mun_code: "013316" },
  // { label: "Santa Rosa", mun_code: "013316" },
  // { label: "Santo Rosario", mun_code: "013316" },
  // { label: "Saracat", mun_code: "013316" },
  // { label: "Sinapangan", mun_code: "013316" },
  // { label: "Taboc", mun_code: "013316" },
  // { label: "Talogtog", mun_code: "013316" },
  // { label: "Urbiztondo", mun_code: "013316" },
  // { label: "Ambitacay", mun_code: "013317" },
  // { label: "Bail", mun_code: "013317" },
  // { label: "Balaoc", mun_code: "013317" },
  // { label: "Balsaan", mun_code: "013317" },
  // { label: "Baybay", mun_code: "013317" },
  // { label: "Cabaruan", mun_code: "013317" },
  // { label: "Casantaan", mun_code: "013317" },
  // { label: "Casilagan", mun_code: "013317" },
  // { label: "Cupang", mun_code: "013317" },
  // { label: "Damortis", mun_code: "013317" },
  // { label: "Fernando", mun_code: "013317" },
  // { label: "Linong", mun_code: "013317" },
  // { label: "Lomboy", mun_code: "013317" },
  // { label: "Malabago", mun_code: "013317" },
  // { label: "Namboongan", mun_code: "013317" },
  // { label: "Namonitan", mun_code: "013317" },
  // { label: "Narvacan", mun_code: "013317" },
  // { label: "Patac", mun_code: "013317" },
  // { label: "Poblacion", mun_code: "013317" },
  // { label: "Pongpong", mun_code: "013317" },
  // { label: "Raois", mun_code: "013317" },
  // { label: "Tubod", mun_code: "013317" },
  // { label: "Tococ", mun_code: "013317" },
  // { label: "Ubagan", mun_code: "013317" },
  // { label: "Corrooy", mun_code: "013318" },
  // { label: "Lettac Norte", mun_code: "013318" },
  // { label: "Lettac Sur", mun_code: "013318" },
  // { label: "Mangaan", mun_code: "013318" },
  // { label: "Paagan", mun_code: "013318" },
  // { label: "Poblacion", mun_code: "013318" },
  // { label: "Puguil", mun_code: "013318" },
  // { label: "Ramot", mun_code: "013318" },
  // { label: "Sapdaan", mun_code: "013318" },
  // { label: "Sasaba", mun_code: "013318" },
  // { label: "Tubaday", mun_code: "013318" },
  // { label: "Bigbiga", mun_code: "013319" },
  // { label: "Castro", mun_code: "013319" },
  // { label: "Duplas", mun_code: "013319" },
  // { label: "Ipet", mun_code: "013319" },
  // { label: "Ilocano", mun_code: "013319" },
  // { label: "Maliclico", mun_code: "013319" },
  // { label: "Old Central", mun_code: "013319" },
  // { label: "Namaltugan", mun_code: "013319" },
  // { label: "Poblacion", mun_code: "013319" },
  // { label: "Porporiket", mun_code: "013319" },
  // { label: "San Francisco Norte", mun_code: "013319" },
  // { label: "San Francisco Sur", mun_code: "013319" },
  // { label: "San Jose", mun_code: "013319" },
  // { label: "Sengngat", mun_code: "013319" },
  // { label: "Turod", mun_code: "013319" },
  // { label: "Up-uplas", mun_code: "013319" },
  // { label: "Bulalaan", mun_code: "013319" },
  // { label: "Amallapay", mun_code: "013320" },
  // { label: "Anduyan", mun_code: "013320" },
  // { label: "Caoigue", mun_code: "013320" },
  // { label: "Francia Sur", mun_code: "013320" },
  // { label: "Francia West", mun_code: "013320" },
  // { label: "Garcia", mun_code: "013320" },
  // { label: "Gonzales", mun_code: "013320" },
  // { label: "Halog East", mun_code: "013320" },
  // { label: "Halog West", mun_code: "013320" },
  // { label: "Leones East", mun_code: "013320" },
  // { label: "Leones West", mun_code: "013320" },
  // { label: "Linapew", mun_code: "013320" },
  // { label: "Magsaysay", mun_code: "013320" },
  // { label: "Pideg", mun_code: "013320" },
  // { label: "Poblacion", mun_code: "013320" },
  // { label: "Rizal", mun_code: "013320" },
  // { label: "Santa Teresa", mun_code: "013320" },
  // { label: "Lloren", mun_code: "013320" },
  // { label: "Allabon", mun_code: "015501" },
  // { label: "Aloleng", mun_code: "015501" },
  // { label: "Bangan-Oda", mun_code: "015501" },
  // { label: "Baruan", mun_code: "015501" },
  // { label: "Boboy", mun_code: "015501" },
  // { label: "Cayungnan", mun_code: "015501" },
  // { label: "Dangley", mun_code: "015501" },
  // { label: "Gayusan", mun_code: "015501" },
  // { label: "Macaboboni", mun_code: "015501" },
  // { label: "Magsaysay", mun_code: "015501" },
  // { label: "Namatucan", mun_code: "015501" },
  // { label: "Patar", mun_code: "015501" },
  // { label: "Poblacion East", mun_code: "015501" },
  // { label: "Poblacion West", mun_code: "015501" },
  // { label: "San Juan", mun_code: "015501" },
  // { label: "Tupa", mun_code: "015501" },
  // { label: "Viga", mun_code: "015501" },
  // { label: "Bayaoas", mun_code: "015502" },
  // { label: "Baybay", mun_code: "015502" },
  // { label: "Bocacliw", mun_code: "015502" },
  // { label: "Bocboc East", mun_code: "015502" },
  // { label: "Bocboc West", mun_code: "015502" },
  // { label: "Buer", mun_code: "015502" },
  // { label: "Calsib", mun_code: "015502" },
  // { label: "Ninoy", mun_code: "015502" },
  // { label: "Poblacion", mun_code: "015502" },
  // { label: "Pogomboa", mun_code: "015502" },
  // { label: "Pogonsili", mun_code: "015502" },
  // { label: "San Jose", mun_code: "015502" },
  // { label: "Tampac", mun_code: "015502" },
  // { label: "Laoag", mun_code: "015502" },
  // { label: "Manlocboc", mun_code: "015502" },
  // { label: "Panacol", mun_code: "015502" },
  // { label: "Alos", mun_code: "015503" },
  // { label: "Amandiego", mun_code: "015503" },
  // { label: "Amangbangan", mun_code: "015503" },
  // { label: "Balangobong", mun_code: "015503" },
  // { label: "Balayang", mun_code: "015503" },
  // { label: "Bisocol", mun_code: "015503" },
  // { label: "Bolaney", mun_code: "015503" },
  // { label: "Baleyadaan", mun_code: "015503" },
  // { label: "Bued", mun_code: "015503" },
  // { label: "Cabatuan", mun_code: "015503" },
  // { label: "Cayucay", mun_code: "015503" },
  // { label: "Dulacac", mun_code: "015503" },
  // { label: "Inerangan", mun_code: "015503" },
  // { label: "Linmansangan", mun_code: "015503" },
  // { label: "Lucap", mun_code: "015503" },
  // { label: "Macatiw", mun_code: "015503" },
  // { label: "Magsaysay", mun_code: "015503" },
  // { label: "Mona", mun_code: "015503" },
  // { label: "Palamis", mun_code: "015503" },
  // { label: "Pangapisan", mun_code: "015503" },
  // { label: "Poblacion", mun_code: "015503" },
  // { label: "Pocalpocal", mun_code: "015503" },
  // { label: "Pogo", mun_code: "015503" },
  // { label: "Polo", mun_code: "015503" },
  // { label: "Quibuar", mun_code: "015503" },
  // { label: "Sabangan", mun_code: "015503" },
  // { label: "San Jose", mun_code: "015503" },
  // { label: "San Roque", mun_code: "015503" },
  // { label: "San Vicente", mun_code: "015503" },
  // { label: "Santa Maria", mun_code: "015503" },
  // { label: "Tanaytay", mun_code: "015503" },
  // { label: "Tangcarang", mun_code: "015503" },
  // { label: "Tawintawin", mun_code: "015503" },
  // { label: "Telbang", mun_code: "015503" },
  // { label: "Victoria", mun_code: "015503" },
  // { label: "Landoc", mun_code: "015503" },
  // { label: "Maawi", mun_code: "015503" },
  // { label: "Pandan", mun_code: "015503" },
  // { label: "San Antonio (R. Magsaysay)", mun_code: "015503" },
  // { label: "Anulid", mun_code: "015504" },
  // { label: "Atainan", mun_code: "015504" },
  // { label: "Bersamin", mun_code: "015504" },
  // { label: "Canarvacanan", mun_code: "015504" },
  // { label: "Caranglaan", mun_code: "015504" },
  // { label: "Curareng", mun_code: "015504" },
  // { label: "Gualsic", mun_code: "015504" },
  // { label: "Kasikis", mun_code: "015504" },
  // { label: "Laoac", mun_code: "015504" },
  // { label: "Macayo", mun_code: "015504" },
  // { label: "Pindangan Centro", mun_code: "015504" },
  // { label: "Pindangan East", mun_code: "015504" },
  // { label: "Pindangan West", mun_code: "015504" },
  // { label: "Poblacion East", mun_code: "015504" },
  // { label: "Poblacion West", mun_code: "015504" },
  // { label: "San Juan", mun_code: "015504" },
  // { label: "San Nicolas", mun_code: "015504" },
  // { label: "San Pedro Apartado", mun_code: "015504" },
  // { label: "San Pedro IlI", mun_code: "015504" },
  // { label: "San Vicente", mun_code: "015504" },
  // { label: "Vacante", mun_code: "015504" },
  // { label: "Awile", mun_code: "015505" },
  // { label: "Awag", mun_code: "015505" },
  // { label: "Batiarao", mun_code: "015505" },
  // { label: "Cabungan", mun_code: "015505" },
  // { label: "Carot", mun_code: "015505" },
  // { label: "Dolaoan", mun_code: "015505" },
  // { label: "Imbo", mun_code: "015505" },
  // { label: "Macaleeng", mun_code: "015505" },
  // { label: "Macandocandong", mun_code: "015505" },
  // { label: "Mal-ong", mun_code: "015505" },
  // { label: "Namagbagan", mun_code: "015505" },
  // { label: "Poblacion", mun_code: "015505" },
  // { label: "Roxas", mun_code: "015505" },
  // { label: "Sablig", mun_code: "015505" },
  // { label: "San Jose", mun_code: "015505" },
  // { label: "Siapar", mun_code: "015505" },
  // { label: "Tondol", mun_code: "015505" },
  // { label: "Toritori", mun_code: "015505" },
  // { label: "Ariston Este", mun_code: "015506" },
  // { label: "Ariston Weste", mun_code: "015506" },
  // { label: "Bantog", mun_code: "015506" },
  // { label: "Baro", mun_code: "015506" },
  // { label: "Bobonan", mun_code: "015506" },
  // { label: "Cabalitian", mun_code: "015506" },
  // { label: "Calepaan", mun_code: "015506" },
  // { label: "Carosucan Norte", mun_code: "015506" },
  // { label: "Carosucan Sur", mun_code: "015506" },
  // { label: "Coldit", mun_code: "015506" },
  // { label: "Domanpot", mun_code: "015506" },
  // { label: "Dupac", mun_code: "015506" },
  // { label: "Macalong", mun_code: "015506" },
  // { label: "Palaris", mun_code: "015506" },
  // { label: "Poblacion East", mun_code: "015506" },
  // { label: "Poblacion West", mun_code: "015506" },
  // { label: "San Vicente Este", mun_code: "015506" },
  // { label: "San Vicente Weste", mun_code: "015506" },
  // { label: "Sanchez", mun_code: "015506" },
  // { label: "Sobol", mun_code: "015506" },
  // { label: "Toboy", mun_code: "015506" },
  // { label: "Angayan Norte", mun_code: "015507" },
  // { label: "Angayan Sur", mun_code: "015507" },
  // { label: "Capulaan", mun_code: "015507" },
  // { label: "Esmeralda", mun_code: "015507" },
  // { label: "Kita-kita", mun_code: "015507" },
  // { label: "Mabini", mun_code: "015507" },
  // { label: "Mauban", mun_code: "015507" },
  // { label: "Poblacion", mun_code: "015507" },
  // { label: "Pugaro", mun_code: "015507" },
  // { label: "Rajal", mun_code: "015507" },
  // { label: "San Andres", mun_code: "015507" },
  // { label: "San Aurelio 1st", mun_code: "015507" },
  // { label: "San Aurelio 2nd", mun_code: "015507" },
  // { label: "San Aurelio 3rd", mun_code: "015507" },
  // { label: "San Joaquin", mun_code: "015507" },
  // { label: "San Julian", mun_code: "015507" },
  // { label: "San Leon", mun_code: "015507" },
  // { label: "San Marcelino", mun_code: "015507" },
  // { label: "San Miguel", mun_code: "015507" },
  // { label: "San Raymundo", mun_code: "015507" },
  // { label: "Ambabaay", mun_code: "015508" },
  // { label: "Aporao", mun_code: "015508" },
  // { label: "Arwas", mun_code: "015508" },
  // { label: "Ballag", mun_code: "015508" },
  // { label: "Banog Norte", mun_code: "015508" },
  // { label: "Banog Sur", mun_code: "015508" },
  // { label: "Centro Toma", mun_code: "015508" },
  // { label: "Colayo", mun_code: "015508" },
  // { label: "Dacap Norte", mun_code: "015508" },
  // { label: "Dacap Sur", mun_code: "015508" },
  // { label: "Garrita", mun_code: "015508" },
  // { label: "Luac", mun_code: "015508" },
  // { label: "Macabit", mun_code: "015508" },
  // { label: "Masidem", mun_code: "015508" },
  // { label: "Poblacion", mun_code: "015508" },
  // { label: "Quinaoayanan", mun_code: "015508" },
  // { label: "Ranao", mun_code: "015508" },
  // { label: "Ranom Iloco", mun_code: "015508" },
  // { label: "San Jose", mun_code: "015508" },
  // { label: "San Miguel", mun_code: "015508" },
  // { label: "San Simon", mun_code: "015508" },
  // { label: "San Vicente", mun_code: "015508" },
  // { label: "Tiep", mun_code: "015508" },
  // { label: "Tipor", mun_code: "015508" },
  // { label: "Tugui Grande", mun_code: "015508" },
  // { label: "Tugui Norte", mun_code: "015508" },
  // { label: "Calabeng", mun_code: "015508" },
  // { label: "Anambongan", mun_code: "015509" },
  // { label: "Bayoyong", mun_code: "015509" },
  // { label: "Cabeldatan", mun_code: "015509" },
  // { label: "Dumpay", mun_code: "015509" },
  // { label: "Malimpec East", mun_code: "015509" },
  // { label: "Mapolopolo", mun_code: "015509" },
  // { label: "Nalneran", mun_code: "015509" },
  // { label: "Navatat", mun_code: "015509" },
  // { label: "Obong", mun_code: "015509" },
  // { label: "Osmena Sr.", mun_code: "015509" },
  // { label: "Palma", mun_code: "015509" },
  // { label: "Patacbo", mun_code: "015509" },
  // { label: "Poblacion", mun_code: "015509" },
  // { label: "Artacho", mun_code: "015510" },
  // { label: "Cabuaan", mun_code: "015510" },
  // { label: "Cacandongan", mun_code: "015510" },
  // { label: "Diaz", mun_code: "015510" },
  // { label: "Nandacan", mun_code: "015510" },
  // { label: "Nibaliw Norte", mun_code: "015510" },
  // { label: "Nibaliw Sur", mun_code: "015510" },
  // { label: "Palisoc", mun_code: "015510" },
  // { label: "Poblacion East", mun_code: "015510" },
  // { label: "Poblacion West", mun_code: "015510" },
  // { label: "Pogo", mun_code: "015510" },
  // { label: "Poponto", mun_code: "015510" },
  // { label: "Primicias", mun_code: "015510" },
  // { label: "Ketegan", mun_code: "015510" },
  // { label: "Sinabaan", mun_code: "015510" },
  // { label: "Vacante", mun_code: "015510" },
  // { label: "Villanueva", mun_code: "015510" },
  // { label: "Baluyot", mun_code: "015510" },
  // { label: "Alinggan", mun_code: "015511" },
  // { label: "Amamperez", mun_code: "015511" },
  // { label: "Amancosiling Norte", mun_code: "015511" },
  // { label: "Amancosiling Sur", mun_code: "015511" },
  // { label: "Ambayat I", mun_code: "015511" },
  // { label: "Ambayat II", mun_code: "015511" },
  // { label: "Apalen", mun_code: "015511" },
  // { label: "Asin", mun_code: "015511" },
  // { label: "Ataynan", mun_code: "015511" },
  // { label: "Bacnono", mun_code: "015511" },
  // { label: "Balaybuaya", mun_code: "015511" },
  // { label: "Banaban", mun_code: "015511" },
  // { label: "Bani", mun_code: "015511" },
  // { label: "Batangcawa", mun_code: "015511" },
  // { label: "Beleng", mun_code: "015511" },
  // { label: "Bical Norte", mun_code: "015511" },
  // { label: "Bical Sur", mun_code: "015511" },
  // { label: "Bongato East", mun_code: "015511" },
  // { label: "Bongato West", mun_code: "015511" },
  // { label: "Buayaen", mun_code: "015511" },
  // { label: "Buenlag 1st", mun_code: "015511" },
  // { label: "Buenlag 2nd", mun_code: "015511" },
  // { label: "Cadre Site", mun_code: "015511" },
  // { label: "Carungay", mun_code: "015511" },
  // { label: "Caturay", mun_code: "015511" },
  // { label: "Duera", mun_code: "015511" },
  // { label: "Dusoc", mun_code: "015511" },
  // { label: "Hermoza", mun_code: "015511" },
  // { label: "Idong", mun_code: "015511" },
  // { label: "Inanlorenzana", mun_code: "015511" },
  // { label: "Inirangan", mun_code: "015511" },
  // { label: "Iton", mun_code: "015511" },
  // { label: "Langiran", mun_code: "015511" },
  // { label: "Ligue", mun_code: "015511" },
  // { label: "M. H. del Pilar", mun_code: "015511" },
  // { label: "Macayocayo", mun_code: "015511" },
  // { label: "Magsaysay", mun_code: "015511" },
  // { label: "Maigpa", mun_code: "015511" },
  // { label: "Malimpec", mun_code: "015511" },
  // { label: "Malioer", mun_code: "015511" },
  // { label: "Managos", mun_code: "015511" },
  // { label: "Manambong Norte", mun_code: "015511" },
  // { label: "Manambong Parte", mun_code: "015511" },
  // { label: "Manambong Sur", mun_code: "015511" },
  // { label: "Mangayao", mun_code: "015511" },
  // { label: "Nalsian Norte", mun_code: "015511" },
  // { label: "Nalsian Sur", mun_code: "015511" },
  // { label: "Pangdel", mun_code: "015511" },
  // { label: "Pantol", mun_code: "015511" },
  // { label: "Paragos", mun_code: "015511" },
  // { label: "Poblacion Sur", mun_code: "015511" },
  // { label: "Pugo", mun_code: "015511" },
  // { label: "Reynado", mun_code: "015511" },
  // { label: "San Gabriel 1st", mun_code: "015511" },
  // { label: "San Gabriel 2nd", mun_code: "015511" },
  // { label: "San Vicente", mun_code: "015511" },
  // { label: "Sangcagulis", mun_code: "015511" },
  // { label: "Sanlibo", mun_code: "015511" },
  // { label: "Sapang", mun_code: "015511" },
  // { label: "Tamaro", mun_code: "015511" },
  // { label: "Tambac", mun_code: "015511" },
  // { label: "Tampog", mun_code: "015511" },
  // { label: "Darawey (Tangal)", mun_code: "015511" },
  // { label: "Tanolong", mun_code: "015511" },
  // { label: "Tatarao", mun_code: "015511" },
  // { label: "Telbang", mun_code: "015511" },
  // { label: "Tococ East", mun_code: "015511" },
  // { label: "Tococ West", mun_code: "015511" },
  // { label: "Warding", mun_code: "015511" },
  // { label: "Wawa", mun_code: "015511" },
  // { label: "Zone I (Pob.)", mun_code: "015511" },
  // { label: "Zone II (Pob.)", mun_code: "015511" },
  // { label: "Zone III (Pob.)", mun_code: "015511" },
  // { label: "Zone IV (Pob.)", mun_code: "015511" },
  // { label: "Zone V (Pob.)", mun_code: "015511" },
  // { label: "Zone VI (Pob.)", mun_code: "015511" },
  // { label: "Zone VII (Pob.)", mun_code: "015511" },
  // { label: "Balangobong", mun_code: "015512" },
  // { label: "Bued", mun_code: "015512" },
  // { label: "Bugayong", mun_code: "015512" },
  // { label: "Camangaan", mun_code: "015512" },
  // { label: "Canarvacanan", mun_code: "015512" },
  // { label: "Capas", mun_code: "015512" },
  // { label: "Cili", mun_code: "015512" },
  // { label: "Dumayat", mun_code: "015512" },
  // { label: "Linmansangan", mun_code: "015512" },
  // { label: "Mangcasuy", mun_code: "015512" },
  // { label: "Moreno", mun_code: "015512" },
  // { label: "Pasileng Norte", mun_code: "015512" },
  // { label: "Pasileng Sur", mun_code: "015512" },
  // { label: "Poblacion", mun_code: "015512" },
  // { label: "San Felipe Central", mun_code: "015512" },
  // { label: "San Felipe Sur", mun_code: "015512" },
  // { label: "San Pablo", mun_code: "015512" },
  // { label: "Santa Catalina", mun_code: "015512" },
  // { label: "Santa Maria Norte", mun_code: "015512" },
  // { label: "Santiago", mun_code: "015512" },
  // { label: "Sumabnit", mun_code: "015512" },
  // { label: "Tabuyoc", mun_code: "015512" },
  // { label: "Vacante", mun_code: "015512" },
  // { label: "Amancoro", mun_code: "015513" },
  // { label: "Balagan", mun_code: "015513" },
  // { label: "Balogo", mun_code: "015513" },
  // { label: "Basing", mun_code: "015513" },
  // { label: "Baybay Lopez", mun_code: "015513" },
  // { label: "Baybay Polong", mun_code: "015513" },
  // { label: "Biec", mun_code: "015513" },
  // { label: "Buenlag", mun_code: "015513" },
  // { label: "Calit", mun_code: "015513" },
  // { label: "Caloocan Norte", mun_code: "015513" },
  // { label: "Caloocan Sur", mun_code: "015513" },
  // { label: "Camaley", mun_code: "015513" },
  // { label: "Canaoalan", mun_code: "015513" },
  // { label: "Dulag", mun_code: "015513" },
  // { label: "Gayaman", mun_code: "015513" },
  // { label: "Linoc", mun_code: "015513" },
  // { label: "Lomboy", mun_code: "015513" },
  // { label: "Nagpalangan", mun_code: "015513" },
  // { label: "Malindong", mun_code: "015513" },
  // { label: "Manat", mun_code: "015513" },
  // { label: "Naguilayan", mun_code: "015513" },
  // { label: "Pallas", mun_code: "015513" },
  // { label: "Papagueyan", mun_code: "015513" },
  // { label: "Parayao", mun_code: "015513" },
  // { label: "Poblacion", mun_code: "015513" },
  // { label: "Pototan", mun_code: "015513" },
  // { label: "Sabangan", mun_code: "015513" },
  // { label: "Salapingao", mun_code: "015513" },
  // { label: "San Isidro Norte", mun_code: "015513" },
  // { label: "San Isidro Sur", mun_code: "015513" },
  // { label: "Santa Rosa", mun_code: "015513" },
  // { label: "Tombor", mun_code: "015513" },
  // { label: "Caloocan Dupo", mun_code: "015513" },
  // { label: "Arnedo", mun_code: "015514" },
  // { label: "Balingasay", mun_code: "015514" },
  // { label: "Binabalian", mun_code: "015514" },
  // { label: "Cabuyao", mun_code: "015514" },
  // { label: "Catuday", mun_code: "015514" },
  // { label: "Catungi", mun_code: "015514" },
  // { label: "Concordia (Pob.)", mun_code: "015514" },
  // { label: "Culang", mun_code: "015514" },
  // { label: "Dewey", mun_code: "015514" },
  // { label: "Estanza", mun_code: "015514" },
  // { label: "Germinal (Pob.)", mun_code: "015514" },
  // { label: "Goyoden", mun_code: "015514" },
  // { label: "Ilogmalino", mun_code: "015514" },
  // { label: "Lambes (Lames)", mun_code: "015514" },
  // { label: "Liwa-liwa", mun_code: "015514" },
  // { label: "Lucero", mun_code: "015514" },
  // { label: "Luciente 1.0", mun_code: "015514" },
  // { label: "Luciente 2.0", mun_code: "015514" },
  // { label: "Luna", mun_code: "015514" },
  // { label: "Patar", mun_code: "015514" },
  // { label: "Pilar", mun_code: "015514" },
  // { label: "Salud", mun_code: "015514" },
  // { label: "Samang Norte", mun_code: "015514" },
  // { label: "Samang Sur", mun_code: "015514" },
  // { label: "Sampaloc", mun_code: "015514" },
  // { label: "San Roque", mun_code: "015514" },
  // { label: "Tara", mun_code: "015514" },
  // { label: "Tupa", mun_code: "015514" },
  // { label: "Victory", mun_code: "015514" },
  // { label: "Zaragoza", mun_code: "015514" },
  // { label: "Angarian", mun_code: "015515" },
  // { label: "Asinan", mun_code: "015515" },
  // { label: "Banaga", mun_code: "015515" },
  // { label: "Bacabac", mun_code: "015515" },
  // { label: "Bolaoen", mun_code: "015515" },
  // { label: "Buenlag", mun_code: "015515" },
  // { label: "Cabayaoasan", mun_code: "015515" },
  // { label: "Cayanga", mun_code: "015515" },
  // { label: "Gueset", mun_code: "015515" },
  // { label: "Hacienda", mun_code: "015515" },
  // { label: "Laguit Centro", mun_code: "015515" },
  // { label: "Laguit Padilla", mun_code: "015515" },
  // { label: "Magtaking", mun_code: "015515" },
  // { label: "Pangascasan", mun_code: "015515" },
  // { label: "Pantal", mun_code: "015515" },
  // { label: "Poblacion", mun_code: "015515" },
  // { label: "Polong", mun_code: "015515" },
  // { label: "Portic", mun_code: "015515" },
  // { label: "Salasa", mun_code: "015515" },
  // { label: "Salomague Norte", mun_code: "015515" },
  // { label: "Salomague Sur", mun_code: "015515" },
  // { label: "Samat", mun_code: "015515" },
  // { label: "San Francisco", mun_code: "015515" },
  // { label: "Umanday", mun_code: "015515" },
  // { label: "Anapao (Bur Anapac)", mun_code: "015516" },
  // { label: "Cacayasen", mun_code: "015516" },
  // { label: "Concordia", mun_code: "015516" },
  // { label: "Ilio-ilio (Iliw-iliw)", mun_code: "015516" },
  // { label: "Papallasen", mun_code: "015516" },
  // { label: "Poblacion", mun_code: "015516" },
  // { label: "Pogoruac", mun_code: "015516" },
  // { label: "Don Matias", mun_code: "015516" },
  // { label: "San Miguel", mun_code: "015516" },
  // { label: "San Pascual", mun_code: "015516" },
  // { label: "San Vicente", mun_code: "015516" },
  // { label: "Sapa Grande", mun_code: "015516" },
  // { label: "Tambacan", mun_code: "015516" },
  // { label: "Ambonao", mun_code: "015517" },
  // { label: "Ambuetel", mun_code: "015517" },
  // { label: "Banaoang", mun_code: "015517" },
  // { label: "Bued", mun_code: "015517" },
  // { label: "Buenlag", mun_code: "015517" },
  // { label: "Cabilocaan", mun_code: "015517" },
  // { label: "Dinalaoan", mun_code: "015517" },
  // { label: "Doyong", mun_code: "015517" },
  // { label: "Gabon", mun_code: "015517" },
  // { label: "Lasip", mun_code: "015517" },
  // { label: "Longos", mun_code: "015517" },
  // { label: "Lumbang", mun_code: "015517" },
  // { label: "Macabito", mun_code: "015517" },
  // { label: "Malabago", mun_code: "015517" },
  // { label: "Mancup", mun_code: "015517" },
  // { label: "Nagsaing", mun_code: "015517" },
  // { label: "Nalsian", mun_code: "015517" },
  // { label: "Poblacion East", mun_code: "015517" },
  // { label: "Poblacion West", mun_code: "015517" },
  // { label: "Quesban", mun_code: "015517" },
  // { label: "San Miguel", mun_code: "015517" },
  // { label: "San Vicente", mun_code: "015517" },
  // { label: "Songkoy", mun_code: "015517" },
  // { label: "Talibaew", mun_code: "015517" },
  // { label: "Bacayao Norte", mun_code: "015518" },
  // { label: "Bacayao Sur", mun_code: "015518" },
  // { label: "Barangay II (Nueva)", mun_code: "015518" },
  // { label: "Barangay IV (Zamora)", mun_code: "015518" },
  // { label: "Bolosan", mun_code: "015518" },
  // { label: "Bonuan Binloc", mun_code: "015518" },
  // { label: "Bonuan Boquig", mun_code: "015518" },
  // { label: "Bonuan Gueset", mun_code: "015518" },
  // { label: "Calmay", mun_code: "015518" },
  // { label: "Carael", mun_code: "015518" },
  // { label: "Caranglaan", mun_code: "015518" },
  // { label: "Herrero", mun_code: "015518" },
  // { label: "Lasip Chico", mun_code: "015518" },
  // { label: "Lasip Grande", mun_code: "015518" },
  // { label: "Lomboy", mun_code: "015518" },
  // { label: "Lucao", mun_code: "015518" },
  // { label: "Malued", mun_code: "015518" },
  // { label: "Mamalingling", mun_code: "015518" },
  // { label: "Mangin", mun_code: "015518" },
  // { label: "Mayombo", mun_code: "015518" },
  // { label: "Pantal", mun_code: "015518" },
  // { label: "Poblacion Oeste", mun_code: "015518" },
  // { label: "Barangay I (T. Bugallon)", mun_code: "015518" },
  // { label: "Pogo Chico", mun_code: "015518" },
  // { label: "Pogo Grande", mun_code: "015518" },
  // { label: "Pugaro Suit", mun_code: "015518" },
  // { label: "Salapingao", mun_code: "015518" },
  // { label: "Salisay", mun_code: "015518" },
  // { label: "Tambac", mun_code: "015518" },
  // { label: "Tapuac", mun_code: "015518" },
  // { label: "Tebeng", mun_code: "015518" },
  // { label: "Alilao", mun_code: "015519" },
  // { label: "Amalbalan", mun_code: "015519" },
  // { label: "Bobonot", mun_code: "015519" },
  // { label: "Eguia", mun_code: "015519" },
  // { label: "Gais-Guipe", mun_code: "015519" },
  // { label: "Hermosa", mun_code: "015519" },
  // { label: "Macalang", mun_code: "015519" },
  // { label: "Magsaysay", mun_code: "015519" },
  // { label: "Malacapas", mun_code: "015519" },
  // { label: "Malimpin", mun_code: "015519" },
  // { label: "Petal", mun_code: "015519" },
  // { label: "Poblacion", mun_code: "015519" },
  // { label: "San Vicente", mun_code: "015519" },
  // { label: "Tambac", mun_code: "015519" },
  // { label: "Tambobong", mun_code: "015519" },
  // { label: "Uli", mun_code: "015519" },
  // { label: "Viga", mun_code: "015519" },
  // { label: "Bamban", mun_code: "015520" },
  // { label: "Batang", mun_code: "015520" },
  // { label: "Bayambang", mun_code: "015520" },
  // { label: "Cato", mun_code: "015520" },
  // { label: "Doliman", mun_code: "015520" },
  // { label: "Fatima", mun_code: "015520" },
  // { label: "Maya", mun_code: "015520" },
  // { label: "Nangalisan", mun_code: "015520" },
  // { label: "Nayom", mun_code: "015520" },
  // { label: "Pita", mun_code: "015520" },
  // { label: "Poblacion", mun_code: "015520" },
  // { label: "Potol", mun_code: "015520" },
  // { label: "Babuyan", mun_code: "015520" },
  // { label: "Bolo", mun_code: "015521" },
  // { label: "Bongalon", mun_code: "015521" },
  // { label: "Dulig", mun_code: "015521" },
  // { label: "Laois", mun_code: "015521" },
  // { label: "Magsaysay", mun_code: "015521" },
  // { label: "Poblacion", mun_code: "015521" },
  // { label: "San Gonzalo", mun_code: "015521" },
  // { label: "San Jose", mun_code: "015521" },
  // { label: "Tobuan", mun_code: "015521" },
  // { label: "Uyong", mun_code: "015521" },
  // { label: "Aliwekwek", mun_code: "015522" },
  // { label: "Baay", mun_code: "015522" },
  // { label: "Balangobong", mun_code: "015522" },
  // { label: "Balococ", mun_code: "015522" },
  // { label: "Bantayan", mun_code: "015522" },
  // { label: "Basing", mun_code: "015522" },
  // { label: "Capandanan", mun_code: "015522" },
  // { label: "Domalandan Center", mun_code: "015522" },
  // { label: "Domalandan East", mun_code: "015522" },
  // { label: "Domalandan West", mun_code: "015522" },
  // { label: "Dorongan", mun_code: "015522" },
  // { label: "Dulag", mun_code: "015522" },
  // { label: "Estanza", mun_code: "015522" },
  // { label: "Lasip", mun_code: "015522" },
  // { label: "Libsong East", mun_code: "015522" },
  // { label: "Libsong West", mun_code: "015522" },
  // { label: "Malawa", mun_code: "015522" },
  // { label: "Malimpuec", mun_code: "015522" },
  // { label: "Maniboc", mun_code: "015522" },
  // { label: "Matalava", mun_code: "015522" },
  // { label: "Naguelguel", mun_code: "015522" },
  // { label: "Namolan", mun_code: "015522" },
  // { label: "Pangapisan North", mun_code: "015522" },
  // { label: "Pangapisan Sur", mun_code: "015522" },
  // { label: "Poblacion", mun_code: "015522" },
  // { label: "Quibaol", mun_code: "015522" },
  // { label: "Rosario", mun_code: "015522" },
  // { label: "Sabangan", mun_code: "015522" },
  // { label: "Talogtog", mun_code: "015522" },
  // { label: "Tonton", mun_code: "015522" },
  // { label: "Tumbar", mun_code: "015522" },
  // { label: "Wawa", mun_code: "015522" },
  // { label: "Bacnit", mun_code: "015523" },
  // { label: "Barlo", mun_code: "015523" },
  // { label: "Caabiangaan", mun_code: "015523" },
  // { label: "Cabanaetan", mun_code: "015523" },
  // { label: "Cabinuangan", mun_code: "015523" },
  // { label: "Calzada", mun_code: "015523" },
  // { label: "Caranglaan", mun_code: "015523" },
  // { label: "De Guzman", mun_code: "015523" },
  // { label: "Luna", mun_code: "015523" },
  // { label: "Magalong", mun_code: "015523" },
  // { label: "Nibaliw", mun_code: "015523" },
  // { label: "Patar", mun_code: "015523" },
  // { label: "Poblacion", mun_code: "015523" },
  // { label: "San Pedro", mun_code: "015523" },
  // { label: "Tagudin", mun_code: "015523" },
  // { label: "Villacorta", mun_code: "015523" },
  // { label: "Abonagan", mun_code: "015524" },
  // { label: "Agdao", mun_code: "015524" },
  // { label: "Alacan", mun_code: "015524" },
  // { label: "Aliaga", mun_code: "015524" },
  // { label: "Amacalan", mun_code: "015524" },
  // { label: "Anolid", mun_code: "015524" },
  // { label: "Apaya", mun_code: "015524" },
  // { label: "Asin Este", mun_code: "015524" },
  // { label: "Asin Weste", mun_code: "015524" },
  // { label: "Bacundao Este", mun_code: "015524" },
  // { label: "Bacundao Weste", mun_code: "015524" },
  // { label: "Bakitiw", mun_code: "015524" },
  // { label: "Balite", mun_code: "015524" },
  // { label: "Banawang", mun_code: "015524" },
  // { label: "Barang", mun_code: "015524" },
  // { label: "Bawer", mun_code: "015524" },
  // { label: "Binalay", mun_code: "015524" },
  // { label: "Bobon", mun_code: "015524" },
  // { label: "Bolaoit", mun_code: "015524" },
  // { label: "Bongar", mun_code: "015524" },
  // { label: "Butao", mun_code: "015524" },
  // { label: "Cabatling", mun_code: "015524" },
  // { label: "Cabueldatan", mun_code: "015524" },
  // { label: "Calbueg", mun_code: "015524" },
  // { label: "Canan Norte", mun_code: "015524" },
  // { label: "Canan Sur", mun_code: "015524" },
  // { label: "Cawayan Bogtong", mun_code: "015524" },
  // { label: "Don Pedro", mun_code: "015524" },
  // { label: "Gatang", mun_code: "015524" },
  // { label: "Goliman", mun_code: "015524" },
  // { label: "Gomez", mun_code: "015524" },
  // { label: "Guilig", mun_code: "015524" },
  // { label: "Ican", mun_code: "015524" },
  // { label: "Ingalagala", mun_code: "015524" },
  // { label: "Lareg-lareg", mun_code: "015524" },
  // { label: "Lasip", mun_code: "015524" },
  // { label: "Lepa", mun_code: "015524" },
  // { label: "Loqueb Este", mun_code: "015524" },
  // { label: "Loqueb Norte", mun_code: "015524" },
  // { label: "Loqueb Sur", mun_code: "015524" },
  // { label: "Lunec", mun_code: "015524" },
  // { label: "Mabulitec", mun_code: "015524" },
  // { label: "Malimpec", mun_code: "015524" },
  // { label: "Manggan-Dampay", mun_code: "015524" },
  // { label: "Nancapian", mun_code: "015524" },
  // { label: "Nalsian Norte", mun_code: "015524" },
  // { label: "Nalsian Sur", mun_code: "015524" },
  // { label: "Nansangaan", mun_code: "015524" },
  // { label: "Olea", mun_code: "015524" },
  // { label: "Pacuan", mun_code: "015524" },
  // { label: "Palapar Norte", mun_code: "015524" },
  // { label: "Palapar Sur", mun_code: "015524" },
  // { label: "Palong", mun_code: "015524" },
  // { label: "Pamaranum", mun_code: "015524" },
  // { label: "Pasima", mun_code: "015524" },
  // { label: "Payar", mun_code: "015524" },
  // { label: "Poblacion", mun_code: "015524" },
  // { label: "Polong Norte", mun_code: "015524" },
  // { label: "Polong Sur", mun_code: "015524" },
  // { label: "Potiocan", mun_code: "015524" },
  // { label: "San Julian", mun_code: "015524" },
  // { label: "Tabo-Sili", mun_code: "015524" },
  // { label: "Tobor", mun_code: "015524" },
  // { label: "Talospatang", mun_code: "015524" },
  // { label: "Taloy", mun_code: "015524" },
  // { label: "Taloyan", mun_code: "015524" },
  // { label: "Tambac", mun_code: "015524" },
  // { label: "Tolonguat", mun_code: "015524" },
  // { label: "Tomling", mun_code: "015524" },
  // { label: "Umando", mun_code: "015524" },
  // { label: "Viado", mun_code: "015524" },
  // { label: "Waig", mun_code: "015524" },
  // { label: "Warey", mun_code: "015524" },
  // { label: "Babasit", mun_code: "015525" },
  // { label: "Baguinay", mun_code: "015525" },
  // { label: "Baritao", mun_code: "015525" },
  // { label: "Bisal", mun_code: "015525" },
  // { label: "Bucao", mun_code: "015525" },
  // { label: "Cabanbanan", mun_code: "015525" },
  // { label: "Calaocan", mun_code: "015525" },
  // { label: "Inamotan", mun_code: "015525" },
  // { label: "Lelemaan", mun_code: "015525" },
  // { label: "Licsi", mun_code: "015525" },
  // { label: "Lipit Norte", mun_code: "015525" },
  // { label: "Lipit Sur", mun_code: "015525" },
  // { label: "Parian", mun_code: "015525" },
  // { label: "Matolong", mun_code: "015525" },
  // { label: "Mermer", mun_code: "015525" },
  // { label: "Nalsian", mun_code: "015525" },
  // { label: "Oraan East", mun_code: "015525" },
  // { label: "Oraan West", mun_code: "015525" },
  // { label: "Pantal", mun_code: "015525" },
  // { label: "Pao", mun_code: "015525" },
  // { label: "Poblacion", mun_code: "015525" },
  // { label: "Pugaro", mun_code: "015525" },
  // { label: "San Ramon", mun_code: "015525" },
  // { label: "Santa Ines", mun_code: "015525" },
  // { label: "Sapang", mun_code: "015525" },
  // { label: "Tebuel", mun_code: "015525" },
  // { label: "Alitaya", mun_code: "015526" },
  // { label: "Amansabina", mun_code: "015526" },
  // { label: "Anolid", mun_code: "015526" },
  // { label: "Banaoang", mun_code: "015526" },
  // { label: "Bantayan", mun_code: "015526" },
  // { label: "Bari", mun_code: "015526" },
  // { label: "Bateng", mun_code: "015526" },
  // { label: "Buenlag", mun_code: "015526" },
  // { label: "David", mun_code: "015526" },
  // { label: "Embarcadero", mun_code: "015526" },
  // { label: "Gueguesangen", mun_code: "015526" },
  // { label: "Guesang", mun_code: "015526" },
  // { label: "Guiguilonen", mun_code: "015526" },
  // { label: "Guilig", mun_code: "015526" },
  // { label: "Inlambo", mun_code: "015526" },
  // { label: "Lanas", mun_code: "015526" },
  // { label: "Landas", mun_code: "015526" },
  // { label: "Maasin", mun_code: "015526" },
  // { label: "Macayug", mun_code: "015526" },
  // { label: "Malabago", mun_code: "015526" },
  // { label: "Navaluan", mun_code: "015526" },
  // { label: "Nibaliw", mun_code: "015526" },
  // { label: "Osiem", mun_code: "015526" },
  // { label: "Palua", mun_code: "015526" },
  // { label: "Poblacion", mun_code: "015526" },
  // { label: "Pogo", mun_code: "015526" },
  // { label: "Salaan", mun_code: "015526" },
  // { label: "Salay", mun_code: "015526" },
  // { label: "Tebag", mun_code: "015526" },
  // { label: "Talogtog", mun_code: "015526" },
  // { label: "Andangin", mun_code: "015527" },
  // { label: "Arellano Street (Pob.)", mun_code: "015527" },
  // { label: "Bantay", mun_code: "015527" },
  // { label: "Bantocaling", mun_code: "015527" },
  // { label: "Baracbac", mun_code: "015527" },
  // { label: "Peania Pedania (Bedania)", mun_code: "015527" },
  // { label: "Bogtong Bolo", mun_code: "015527" },
  // { label: "Bogtong Bunao", mun_code: "015527" },
  // { label: "Bogtong Centro", mun_code: "015527" },
  // { label: "Bogtong Niog", mun_code: "015527" },
  // { label: "Bogtong Silag", mun_code: "015527" },
  // { label: "Buaya", mun_code: "015527" },
  // { label: "Buenlag", mun_code: "015527" },
  // { label: "Bueno", mun_code: "015527" },
  // { label: "Bunagan", mun_code: "015527" },
  // { label: "Bunlalacao", mun_code: "015527" },
  // { label: "Burgos Street (Pob.)", mun_code: "015527" },
  // { label: "Cabaluyan 1st", mun_code: "015527" },
  // { label: "Cabaluyan 2nd", mun_code: "015527" },
  // { label: "Cabarabuan", mun_code: "015527" },
  // { label: "Cabaruan", mun_code: "015527" },
  // { label: "Cabayaoasan", mun_code: "015527" },
  // { label: "Cabayugan", mun_code: "015527" },
  // { label: "Cacaoiten", mun_code: "015527" },
  // { label: "Calumboyan Norte", mun_code: "015527" },
  // { label: "Calumboyan Sur", mun_code: "015527" },
  // { label: "Calvo (Pob.)", mun_code: "015527" },
  // { label: "Casilagan", mun_code: "015527" },
  // { label: "Catarataraan", mun_code: "015527" },
  // { label: "Caturay Norte", mun_code: "015527" },
  // { label: "Caturay Sur", mun_code: "015527" },
  // { label: "Caviernesan", mun_code: "015527" },
  // { label: "Dorongan Ketaket", mun_code: "015527" },
  // { label: "Dorongan Linmansangan", mun_code: "015527" },
  // { label: "Dorongan Punta", mun_code: "015527" },
  // { label: "Dorongan Sawat", mun_code: "015527" },
  // { label: "Dorongan Valerio", mun_code: "015527" },
  // { label: "General Luna (Pob.)", mun_code: "015527" },
  // { label: "Historia", mun_code: "015527" },
  // { label: "Lawak Langka", mun_code: "015527" },
  // { label: "Linmansangan", mun_code: "015527" },
  // { label: "Lopez (Pob.)", mun_code: "015527" },
  // { label: "Mabini (Pob.)", mun_code: "015527" },
  // { label: "Macarang", mun_code: "015527" },
  // { label: "Malabobo", mun_code: "015527" },
  // { label: "Malibong", mun_code: "015527" },
  // { label: "Malunec", mun_code: "015527" },
  // { label: "Maravilla (Pob.)", mun_code: "015527" },
  // { label: "Maravilla-Arellano Ext. (Pob.)", mun_code: "015527" },
  // { label: "Muelang", mun_code: "015527" },
  // { label: "Naguilayan East", mun_code: "015527" },
  // { label: "Naguilayan West", mun_code: "015527" },
  // { label: "Nancasalan", mun_code: "015527" },
  // { label: "Niog-Cabison-Bulaney", mun_code: "015527" },
  // { label: "Olegario-Caoile (Pob.)", mun_code: "015527" },
  // { label: "Olo Cacamposan", mun_code: "015527" },
  // { label: "Olo Cafabrosan", mun_code: "015527" },
  // { label: "Olo Cagarlitan", mun_code: "015527" },
  // { label: "Pacalat", mun_code: "015527" },
  // { label: "Pampano", mun_code: "015527" },
  // { label: "Parian", mun_code: "015527" },
  // { label: "Paul", mun_code: "015527" },
  // { label: "Pogon-Aniat", mun_code: "015527" },
  // { label: "Pogon-Lomboy (Pob.)", mun_code: "015527" },
  // { label: "Ponglo-Baleg", mun_code: "015527" },
  // { label: "Ponglo-Muelag", mun_code: "015527" },
  // { label: "Quetegan (Pogon-Baleg)", mun_code: "015527" },
  // { label: "Quezon (Pob.)", mun_code: "015527" },
  // { label: "Salavante", mun_code: "015527" },
  // { label: "Sapang", mun_code: "015527" },
  // { label: "Sonson Ongkit", mun_code: "015527" },
  // { label: "Suaco", mun_code: "015527" },
  // { label: "Tagac", mun_code: "015527" },
  // { label: "Takipan", mun_code: "015527" },
  // { label: "Talogtog", mun_code: "015527" },
  // { label: "Tococ Barikir", mun_code: "015527" },
  // { label: "Torre 1st", mun_code: "015527" },
  // { label: "Torre 2nd", mun_code: "015527" },
  // { label: "Torres Bugallon (Pob.)", mun_code: "015527" },
  // { label: "Umangan", mun_code: "015527" },
  // { label: "Zamora (Pob.)", mun_code: "015527" },
  // { label: "Amanoaoac", mun_code: "015528" },
  // { label: "Apaya", mun_code: "015528" },
  // { label: "Aserda", mun_code: "015528" },
  // { label: "Baloling", mun_code: "015528" },
  // { label: "Coral", mun_code: "015528" },
  // { label: "Golden", mun_code: "015528" },
  // { label: "Jimenez", mun_code: "015528" },
  // { label: "Lambayan", mun_code: "015528" },
  // { label: "Luyan (Luyan South)", mun_code: "015528" },
  // { label: "Nilombot", mun_code: "015528" },
  // { label: "Pias", mun_code: "015528" },
  // { label: "Poblacion", mun_code: "015528" },
  // { label: "Primicias", mun_code: "015528" },
  // { label: "Santa Maria (Luyan North)", mun_code: "015528" },
  // { label: "Torres", mun_code: "015528" },
  // { label: "Barangobong", mun_code: "015529" },
  // { label: "Batchelor East", mun_code: "015529" },
  // { label: "Batchelor West", mun_code: "015529" },
  // { label: "Burgos (San Narciso)", mun_code: "015529" },
  // { label: "Cacandungan", mun_code: "015529" },
  // { label: "Calapugan", mun_code: "015529" },
  // { label: "Canarem", mun_code: "015529" },
  // { label: "Luna", mun_code: "015529" },
  // { label: "Poblacion East", mun_code: "015529" },
  // { label: "Poblacion West", mun_code: "015529" },
  // { label: "Rizal", mun_code: "015529" },
  // { label: "Salud", mun_code: "015529" },
  // { label: "San Eugenio", mun_code: "015529" },
  // { label: "San Macario Norte", mun_code: "015529" },
  // { label: "San Macario Sur", mun_code: "015529" },
  // { label: "San Maximo", mun_code: "015529" },
  // { label: "San Miguel", mun_code: "015529" },
  // { label: "Silag", mun_code: "015529" },
  // { label: "Alipangpang", mun_code: "015530" },
  // { label: "Amagbagan", mun_code: "015530" },
  // { label: "Balacag", mun_code: "015530" },
  // { label: "Banding", mun_code: "015530" },
  // { label: "Bantugan", mun_code: "015530" },
  // { label: "Batakil", mun_code: "015530" },
  // { label: "Bobonan", mun_code: "015530" },
  // { label: "Buneg", mun_code: "015530" },
  // { label: "Cablong", mun_code: "015530" },
  // { label: "Dilan", mun_code: "015530" },
  // { label: "Don Benito", mun_code: "015530" },
  // { label: "Haway", mun_code: "015530" },
  // { label: "Imbalbalatong", mun_code: "015530" },
  // { label: "Inoman", mun_code: "015530" },
  // { label: "Laoac", mun_code: "015530" },
  // { label: "Maambal", mun_code: "015530" },
  // { label: "Malasin", mun_code: "015530" },
  // { label: "Malokiat", mun_code: "015530" },
  // { label: "Manaol", mun_code: "015530" },
  // { label: "Nama", mun_code: "015530" },
  // { label: "Nantangalan", mun_code: "015530" },
  // { label: "Palacpalac", mun_code: "015530" },
  // { label: "Palguyod", mun_code: "015530" },
  // { label: "Poblacion I", mun_code: "015530" },
  // { label: "Poblacion II", mun_code: "015530" },
  // { label: "Poblacion III", mun_code: "015530" },
  // { label: "Poblacion IV", mun_code: "015530" },
  // { label: "Rosario", mun_code: "015530" },
  // { label: "Sugcong", mun_code: "015530" },
  // { label: "Talogtog", mun_code: "015530" },
  // { label: "Tulnac", mun_code: "015530" },
  // { label: "Villegas", mun_code: "015530" },
  // { label: "Casanfernandoan", mun_code: "015530" },
  // { label: "Acop", mun_code: "015531" },
  // { label: "Bakitbakit", mun_code: "015531" },
  // { label: "Balingcanaway", mun_code: "015531" },
  // { label: "Cabalaoangan Norte", mun_code: "015531" },
  // { label: "Cabalaoangan Sur", mun_code: "015531" },
  // { label: "Camangaan", mun_code: "015531" },
  // { label: "Capitan Tomas", mun_code: "015531" },
  // { label: "Carmay West", mun_code: "015531" },
  // { label: "Carmen East", mun_code: "015531" },
  // { label: "Carmen West", mun_code: "015531" },
  // { label: "Casanicolasan", mun_code: "015531" },
  // { label: "Coliling", mun_code: "015531" },
  // { label: "Calanutan (Don Felix Coloma)", mun_code: "015531" },
  // { label: "Guiling", mun_code: "015531" },
  // { label: "Palakipak", mun_code: "015531" },
  // { label: "Pangaoan", mun_code: "015531" },
  // { label: "Rabago", mun_code: "015531" },
  // { label: "Rizal", mun_code: "015531" },
  // { label: "Salvacion", mun_code: "015531" },
  // { label: "San Antonio", mun_code: "015531" },
  // { label: "San Bartolome", mun_code: "015531" },
  // { label: "San Isidro", mun_code: "015531" },
  // { label: "San Luis", mun_code: "015531" },
  // { label: "San Pedro East", mun_code: "015531" },
  // { label: "San Pedro West", mun_code: "015531" },
  // { label: "San Vicente", mun_code: "015531" },
  // { label: "San Angel", mun_code: "015531" },
  // { label: "Station District", mun_code: "015531" },
  // { label: "Tomana East", mun_code: "015531" },
  // { label: "Tomana West", mun_code: "015531" },
  // { label: "Zone I (Pob.)", mun_code: "015531" },
  // { label: "Zone IV (Pob.)", mun_code: "015531" },
  // { label: "Carmay East", mun_code: "015531" },
  // { label: "Don Antonio Village", mun_code: "015531" },
  // { label: "Zone II (Pob.)", mun_code: "015531" },
  // { label: "Zone III (Pob.)", mun_code: "015531" },
  // { label: "Zone V (Pob.)", mun_code: "015531" },
  // { label: "Abanon", mun_code: "015532" },
  // { label: "Agdao", mun_code: "015532" },
  // { label: "Anando", mun_code: "015532" },
  // { label: "Ano", mun_code: "015532" },
  // { label: "Antipangol", mun_code: "015532" },
  // { label: "Aponit", mun_code: "015532" },
  // { label: "Bacnar", mun_code: "015532" },
  // { label: "Balaya", mun_code: "015532" },
  // { label: "Balayong", mun_code: "015532" },
  // { label: "Baldog", mun_code: "015532" },
  // { label: "Balite Sur", mun_code: "015532" },
  // { label: "Balococ", mun_code: "015532" },
  // { label: "Bani", mun_code: "015532" },
  // { label: "Bega", mun_code: "015532" },
  // { label: "Bocboc", mun_code: "015532" },
  // { label: "Bugallon-Posadas Street (Pob.)", mun_code: "015532" },
  // { label: "Bogaoan", mun_code: "015532" },
  // { label: "Bolingit", mun_code: "015532" },
  // { label: "Bolosan", mun_code: "015532" },
  // { label: "Bonifacio (Pob.)", mun_code: "015532" },
  // { label: "Buenglat", mun_code: "015532" },
  // { label: "Burgos Padlan (Pob.)", mun_code: "015532" },
  // { label: "Cacaritan", mun_code: "015532" },
  // { label: "Caingal", mun_code: "015532" },
  // { label: "Calobaoan", mun_code: "015532" },
  // { label: "Calomboyan", mun_code: "015532" },
  // { label: "Capataan", mun_code: "015532" },
  // { label: "Caoayan-Kiling", mun_code: "015532" },
  // { label: "Cobol", mun_code: "015532" },
  // { label: "Coliling", mun_code: "015532" },
  // { label: "Cruz", mun_code: "015532" },
  // { label: "Doyong", mun_code: "015532" },
  // { label: "Gamata", mun_code: "015532" },
  // { label: "Guelew", mun_code: "015532" },
  // { label: "Ilang", mun_code: "015532" },
  // { label: "Inerangan", mun_code: "015532" },
  // { label: "Isla", mun_code: "015532" },
  // { label: "Libas", mun_code: "015532" },
  // { label: "Lilimasan", mun_code: "015532" },
  // { label: "Longos", mun_code: "015532" },
  // { label: "Lucban (Pob.)", mun_code: "015532" },
  // { label: "Mabalbalino", mun_code: "015532" },
  // { label: "Mabini (Pob.)", mun_code: "015532" },
  // { label: "Magtaking", mun_code: "015532" },
  // { label: "Maliwara", mun_code: "015532" },
  // { label: "Mamarlao", mun_code: "015532" },
  // { label: "Manzon", mun_code: "015532" },
  // { label: "Matagdem", mun_code: "015532" },
  // { label: "Mestizo Norte", mun_code: "015532" },
  // { label: "Naguilayan", mun_code: "015532" },
  // { label: "Nilentap", mun_code: "015532" },
  // { label: "Padilla-Gomez", mun_code: "015532" },
  // { label: "Pagal", mun_code: "015532" },
  // { label: "Palaming", mun_code: "015532" },
  // { label: "Palaris (Pob.)", mun_code: "015532" },
  // { label: "Palospos", mun_code: "015532" },
  // { label: "Pangalangan", mun_code: "015532" },
  // { label: "Pangoloan", mun_code: "015532" },
  // { label: "Pangpang", mun_code: "015532" },
  // { label: "Paitan-Panoypoy", mun_code: "015532" },
  // { label: "Parayao", mun_code: "015532" },
  // { label: "Payapa", mun_code: "015532" },
  // { label: "Payar", mun_code: "015532" },
  // { label: "Perez Boulevard (Pob.)", mun_code: "015532" },
  // { label: "Polo", mun_code: "015532" },
  // { label: "Quezon Boulevard (Pob.)", mun_code: "015532" },
  // { label: "Quintong", mun_code: "015532" },
  // { label: "Rizal (Pob.)", mun_code: "015532" },
  // { label: "Roxas Boulevard (Pob.)", mun_code: "015532" },
  // { label: "Salinap", mun_code: "015532" },
  // { label: "San Juan", mun_code: "015532" },
  // { label: "San Pedro-Taloy", mun_code: "015532" },
  // { label: "Sapinit", mun_code: "015532" },
  // { label: "PNR Station Site", mun_code: "015532" },
  // { label: "Supo", mun_code: "015532" },
  // { label: "Talang", mun_code: "015532" },
  // { label: "Tamayo", mun_code: "015532" },
  // { label: "Tandoc", mun_code: "015532" },
  // { label: "Tarece", mun_code: "015532" },
  // { label: "Tarectec", mun_code: "015532" },
  // { label: "Tayambani", mun_code: "015532" },
  // { label: "Tebag", mun_code: "015532" },
  // { label: "Turac", mun_code: "015532" },
  // { label: "M. Soriano", mun_code: "015532" },
  // { label: "Tandang Sora", mun_code: "015532" },
  // { label: "Ambalangan-Dalin", mun_code: "015533" },
  // { label: "Angio", mun_code: "015533" },
  // { label: "Anonang", mun_code: "015533" },
  // { label: "Aramal", mun_code: "015533" },
  // { label: "Bigbiga", mun_code: "015533" },
  // { label: "Binday", mun_code: "015533" },
  // { label: "Bolaoen", mun_code: "015533" },
  // { label: "Bolasi", mun_code: "015533" },
  // { label: "Cayanga", mun_code: "015533" },
  // { label: "Gomot", mun_code: "015533" },
  // { label: "Inmalog", mun_code: "015533" },
  // { label: "Lekep-Butao", mun_code: "015533" },
  // { label: "Longos", mun_code: "015533" },
  // { label: "Mabilao", mun_code: "015533" },
  // { label: "Nibaliw Central", mun_code: "015533" },
  // { label: "Nibaliw East", mun_code: "015533" },
  // { label: "Nibaliw Magliba", mun_code: "015533" },
  // { label: "Palapad", mun_code: "015533" },
  // { label: "Poblacion", mun_code: "015533" },
  // { label: "Rabon", mun_code: "015533" },
  // { label: "Sagud-Bahley", mun_code: "015533" },
  // { label: "Sobol", mun_code: "015533" },
  // { label: "Tempra-Guilig", mun_code: "015533" },
  // { label: "Tocok", mun_code: "015533" },
  // { label: "Lipit-Tomeeng", mun_code: "015533" },
  // { label: "Colisao", mun_code: "015533" },
  // { label: "Nibaliw Narvarte (Nibaliw West Compound)", mun_code: "015533" },
  // { label: "Nibaliw Vidal (Nibaliw West Proper)", mun_code: "015533" },
  // { label: "Alacan", mun_code: "015533" },
  // { label: "Cabaruan", mun_code: "015533" },
  // { label: "Inmalog Norte", mun_code: "015533" },
  // { label: "Longos-Amangonan-Parac-Parac Fabrica", mun_code: "015533" },
  // { label: "Longos Proper", mun_code: "015533" },
  // { label: "Tiblong", mun_code: "015533" },
  // { label: "Awai", mun_code: "015534" },
  // { label: "Bolo", mun_code: "015534" },
  // { label: "Capaoay (Pob.)", mun_code: "015534" },
  // { label: "Casibong", mun_code: "015534" },
  // { label: "Imelda (Decrito)", mun_code: "015534" },
  // { label: "Guibel", mun_code: "015534" },
  // { label: "Labney", mun_code: "015534" },
  // { label: "Magsaysay (Capay)", mun_code: "015534" },
  // { label: "Lobong", mun_code: "015534" },
  // { label: "Macayug", mun_code: "015534" },
  // { label: "Bagong Pag-asa (Poblacion East)", mun_code: "015534" },
  // { label: "San Guillermo (Poblacion West)", mun_code: "015534" },
  // { label: "San Jose", mun_code: "015534" },
  // { label: "San Juan", mun_code: "015534" },
  // { label: "San Roque", mun_code: "015534" },
  // { label: "San Vicente", mun_code: "015534" },
  // { label: "Santa Cruz", mun_code: "015534" },
  // { label: "Santa Maria", mun_code: "015534" },
  // { label: "Santo Tomas", mun_code: "015534" },
  // { label: "San Antonio-Arzadon", mun_code: "015535" },
  // { label: "Cabacaraan", mun_code: "015535" },
  // { label: "Cabaritan", mun_code: "015535" },
  // { label: "Flores", mun_code: "015535" },
  // { label: "Guiset Norte (Pob.)", mun_code: "015535" },
  // { label: "Guiset Sur (Pob.)", mun_code: "015535" },
  // { label: "Lapalo", mun_code: "015535" },
  // { label: "Nagsaag", mun_code: "015535" },
  // { label: "Narra", mun_code: "015535" },
  // { label: "San Bonifacio", mun_code: "015535" },
  // { label: "San Juan", mun_code: "015535" },
  // { label: "San Roque", mun_code: "015535" },
  // { label: "San Vicente", mun_code: "015535" },
  // { label: "Santo Domingo", mun_code: "015535" },
  // { label: "Bensican", mun_code: "015536" },
  // { label: "Cabitnongan", mun_code: "015536" },
  // { label: "Caboloan", mun_code: "015536" },
  // { label: "Cacabugaoan", mun_code: "015536" },
  // { label: "Calanutian", mun_code: "015536" },
  // { label: "Calaocan", mun_code: "015536" },
  // { label: "Camanggaan", mun_code: "015536" },
  // { label: "Camindoroan", mun_code: "015536" },
  // { label: "Casaratan", mun_code: "015536" },
  // { label: "Dalumpinas", mun_code: "015536" },
  // { label: "Fianza", mun_code: "015536" },
  // { label: "Lungao", mun_code: "015536" },
  // { label: "Malico", mun_code: "015536" },
  // { label: "Malilion", mun_code: "015536" },
  // { label: "Nagkaysa", mun_code: "015536" },
  // { label: "Nining", mun_code: "015536" },
  // { label: "Poblacion East", mun_code: "015536" },
  // { label: "Poblacion West", mun_code: "015536" },
  // { label: "Salingcob", mun_code: "015536" },
  // { label: "Salpad", mun_code: "015536" },
  // { label: "San Felipe East", mun_code: "015536" },
  // { label: "San Felipe West", mun_code: "015536" },
  // { label: "San Isidro (Sta. Cruzan)", mun_code: "015536" },
  // { label: "San Jose", mun_code: "015536" },
  // { label: "San Rafael Centro", mun_code: "015536" },
  // { label: "San Rafael East", mun_code: "015536" },
  // { label: "San Rafael West", mun_code: "015536" },
  // { label: "San Roque", mun_code: "015536" },
  // { label: "Santa Maria East", mun_code: "015536" },
  // { label: "Santa Maria West", mun_code: "015536" },
  // { label: "Santo Tomas", mun_code: "015536" },
  // { label: "Siblot", mun_code: "015536" },
  // { label: "Sobol", mun_code: "015536" },
  // { label: "Alac", mun_code: "015537" },
  // { label: "Baligayan", mun_code: "015537" },
  // { label: "Bantog", mun_code: "015537" },
  // { label: "Bolintaguen", mun_code: "015537" },
  // { label: "Cabangaran", mun_code: "015537" },
  // { label: "Cabalaoangan", mun_code: "015537" },
  // { label: "Calomboyan", mun_code: "015537" },
  // { label: "Carayacan", mun_code: "015537" },
  // { label: "Casantamarian", mun_code: "015537" },
  // { label: "Gonzalo", mun_code: "015537" },
  // { label: "Labuan", mun_code: "015537" },
  // { label: "Lagasit", mun_code: "015537" },
  // { label: "Lumayao", mun_code: "015537" },
  // { label: "Mabini", mun_code: "015537" },
  // { label: "Mantacdang", mun_code: "015537" },
  // { label: "Nangapugan", mun_code: "015537" },
  // { label: "San Pedro", mun_code: "015537" },
  // { label: "Ungib", mun_code: "015537" },
  // { label: "Poblacion Zone I", mun_code: "015537" },
  // { label: "Poblacion Zone II", mun_code: "015537" },
  // { label: "Poblacion Zone III", mun_code: "015537" },
  // { label: "Alibago", mun_code: "015538" },
  // { label: "Balingueo", mun_code: "015538" },
  // { label: "Banaoang", mun_code: "015538" },
  // { label: "Banzal", mun_code: "015538" },
  // { label: "Botao", mun_code: "015538" },
  // { label: "Cablong", mun_code: "015538" },
  // { label: "Carusocan", mun_code: "015538" },
  // { label: "Dalongue", mun_code: "015538" },
  // { label: "Erfe", mun_code: "015538" },
  // { label: "Gueguesangen", mun_code: "015538" },
  // { label: "Leet", mun_code: "015538" },
  // { label: "Malanay", mun_code: "015538" },
  // { label: "Maningding", mun_code: "015538" },
  // { label: "Maronong", mun_code: "015538" },
  // { label: "Maticmatic", mun_code: "015538" },
  // { label: "Minien East", mun_code: "015538" },
  // { label: "Minien West", mun_code: "015538" },
  // { label: "Nilombot", mun_code: "015538" },
  // { label: "Patayac", mun_code: "015538" },
  // { label: "Payas", mun_code: "015538" },
  // { label: "Poblacion Norte", mun_code: "015538" },
  // { label: "Poblacion Sur", mun_code: "015538" },
  // { label: "Sapang", mun_code: "015538" },
  // { label: "Sonquil", mun_code: "015538" },
  // { label: "Tebag East", mun_code: "015538" },
  // { label: "Tebag West", mun_code: "015538" },
  // { label: "Tuliao", mun_code: "015538" },
  // { label: "Ventinilla (Ventinilla East)", mun_code: "015538" },
  // { label: "Primicias (Ventinilla West)", mun_code: "015538" },
  // { label: "Bal-loy", mun_code: "015539" },
  // { label: "Bantog", mun_code: "015539" },
  // { label: "Caboluan", mun_code: "015539" },
  // { label: "Cal-litang", mun_code: "015539" },
  // { label: "Capandanan", mun_code: "015539" },
  // { label: "Cauplasan", mun_code: "015539" },
  // { label: "Dalayap", mun_code: "015539" },
  // { label: "Libsong", mun_code: "015539" },
  // { label: "Namagbagan", mun_code: "015539" },
  // { label: "Paitan", mun_code: "015539" },
  // { label: "Pataquid", mun_code: "015539" },
  // { label: "Pilar", mun_code: "015539" },
  // { label: "Poblacion East", mun_code: "015539" },
  // { label: "Poblacion West", mun_code: "015539" },
  // { label: "Pugot", mun_code: "015539" },
  // { label: "Samon", mun_code: "015539" },
  // { label: "San Alejandro", mun_code: "015539" },
  // { label: "San Mariano", mun_code: "015539" },
  // { label: "San Pablo", mun_code: "015539" },
  // { label: "San Patricio", mun_code: "015539" },
  // { label: "San Vicente", mun_code: "015539" },
  // { label: "Santa Cruz", mun_code: "015539" },
  // { label: "Santa Rosa", mun_code: "015539" },
  // { label: "La Luna", mun_code: "015540" },
  // { label: "Poblacion East", mun_code: "015540" },
  // { label: "Poblacion West", mun_code: "015540" },
  // { label: "Salvacion", mun_code: "015540" },
  // { label: "San Agustin", mun_code: "015540" },
  // { label: "San Antonio", mun_code: "015540" },
  // { label: "San Jose", mun_code: "015540" },
  // { label: "San Marcos", mun_code: "015540" },
  // { label: "Santo Domingo", mun_code: "015540" },
  // { label: "Agat", mun_code: "015541" },
  // { label: "Alibeng", mun_code: "015541" },
  // { label: "Amagbagan", mun_code: "015541" },
  // { label: "Artacho", mun_code: "015541" },
  // { label: "Asan Norte", mun_code: "015541" },
  // { label: "Asan Sur", mun_code: "015541" },
  // { label: "Bantay Insik", mun_code: "015541" },
  // { label: "Bila", mun_code: "015541" },
  // { label: "Binmeckeg", mun_code: "015541" },
  // { label: "Bulaoen East", mun_code: "015541" },
  // { label: "Bulaoen West", mun_code: "015541" },
  // { label: "Cabaritan", mun_code: "015541" },
  // { label: "Calunetan", mun_code: "015541" },
  // { label: "Camangaan", mun_code: "015541" },
  // { label: "Cauringan", mun_code: "015541" },
  // { label: "Dungon", mun_code: "015541" },
  // { label: "Esperanza", mun_code: "015541" },
  // { label: "Killo", mun_code: "015541" },
  // { label: "Labayug", mun_code: "015541" },
  // { label: "Paldit", mun_code: "015541" },
  // { label: "Pindangan", mun_code: "015541" },
  // { label: "Pinmilapil", mun_code: "015541" },
  // { label: "Poblacion Central", mun_code: "015541" },
  // { label: "Poblacion Norte", mun_code: "015541" },
  // { label: "Poblacion Sur", mun_code: "015541" },
  // { label: "Sagunto", mun_code: "015541" },
  // { label: "Inmalog", mun_code: "015541" },
  // { label: "Tara-tara", mun_code: "015541" },
  // { label: "Baquioen", mun_code: "015542" },
  // { label: "Baybay Norte", mun_code: "015542" },
  // { label: "Baybay Sur", mun_code: "015542" },
  // { label: "Bolaoen", mun_code: "015542" },
  // { label: "Cabalitian", mun_code: "015542" },
  // { label: "Calumbuyan", mun_code: "015542" },
  // { label: "Camagsingalan", mun_code: "015542" },
  // { label: "Caoayan", mun_code: "015542" },
  // { label: "Capantolan", mun_code: "015542" },
  // { label: "Macaycayawan", mun_code: "015542" },
  // { label: "Paitan East", mun_code: "015542" },
  // { label: "Paitan West", mun_code: "015542" },
  // { label: "Pangascasan", mun_code: "015542" },
  // { label: "Poblacion", mun_code: "015542" },
  // { label: "Santo Domingo", mun_code: "015542" },
  // { label: "Seselangen", mun_code: "015542" },
  // { label: "Sioasio East", mun_code: "015542" },
  // { label: "Sioasio West", mun_code: "015542" },
  // { label: "Victoria", mun_code: "015542" },
  // { label: "Agno", mun_code: "015543" },
  // { label: "Amistad", mun_code: "015543" },
  // { label: "Barangobong", mun_code: "015543" },
  // { label: "Carriedo", mun_code: "015543" },
  // { label: "C. Lichauco", mun_code: "015543" },
  // { label: "Evangelista", mun_code: "015543" },
  // { label: "Guzon", mun_code: "015543" },
  // { label: "Lawak", mun_code: "015543" },
  // { label: "Legaspi", mun_code: "015543" },
  // { label: "Libertad", mun_code: "015543" },
  // { label: "Magallanes", mun_code: "015543" },
  // { label: "Panganiban", mun_code: "015543" },
  // { label: "Barangay A (Pob.)", mun_code: "015543" },
  // { label: "Barangay B (Pob.)", mun_code: "015543" },
  // { label: "Barangay C (Pob.)", mun_code: "015543" },
  // { label: "Barangay D (Pob.)", mun_code: "015543" },
  // { label: "Saleng", mun_code: "015543" },
  // { label: "Santo Domingo", mun_code: "015543" },
  // { label: "Toketec", mun_code: "015543" },
  // { label: "Trenchera", mun_code: "015543" },
  // { label: "Zamora", mun_code: "015543" },
  // { label: "Abot Molina", mun_code: "015544" },
  // { label: "Alo-o", mun_code: "015544" },
  // { label: "Amaronan", mun_code: "015544" },
  // { label: "Annam", mun_code: "015544" },
  // { label: "Bantug", mun_code: "015544" },
  // { label: "Baracbac", mun_code: "015544" },
  // { label: "Barat", mun_code: "015544" },
  // { label: "Buenavista", mun_code: "015544" },
  // { label: "Cabalitian", mun_code: "015544" },
  // { label: "Cabaruan", mun_code: "015544" },
  // { label: "Cabatuan", mun_code: "015544" },
  // { label: "Cadiz", mun_code: "015544" },
  // { label: "Calitlitan", mun_code: "015544" },
  // { label: "Capas", mun_code: "015544" },
  // { label: "Carosalesan", mun_code: "015544" },
  // { label: "Casilan", mun_code: "015544" },
  // { label: "Caurdanetaan", mun_code: "015544" },
  // { label: "Concepcion", mun_code: "015544" },
  // { label: "Decreto", mun_code: "015544" },
  // { label: "Diaz", mun_code: "015544" },
  // { label: "Diket", mun_code: "015544" },
  // { label: "Don Justo Abalos (Caroan)", mun_code: "015544" },
  // { label: "Don Montano", mun_code: "015544" },
  // { label: "Esperanza", mun_code: "015544" },
  // { label: "Evangelista", mun_code: "015544" },
  // { label: "Flores", mun_code: "015544" },
  // { label: "Fulgosino", mun_code: "015544" },
  // { label: "Gonzales", mun_code: "015544" },
  // { label: "La Paz", mun_code: "015544" },
  // { label: "Labuan", mun_code: "015544" },
  // { label: "Lauren", mun_code: "015544" },
  // { label: "Lubong", mun_code: "015544" },
  // { label: "Luna Weste", mun_code: "015544" },
  // { label: "Luna Este", mun_code: "015544" },
  // { label: "Mantacdang", mun_code: "015544" },
  // { label: "Maseil-seil", mun_code: "015544" },
  // { label: "Nampalcan", mun_code: "015544" },
  // { label: "Nancalabasaan", mun_code: "015544" },
  // { label: "Pangangaan", mun_code: "015544" },
  // { label: "Papallasen", mun_code: "015544" },
  // { label: "Pemienta", mun_code: "015544" },
  // { label: "Poblacion East", mun_code: "015544" },
  // { label: "Poblacion West", mun_code: "015544" },
  // { label: "Prado", mun_code: "015544" },
  // { label: "Resurreccion", mun_code: "015544" },
  // { label: "Ricos", mun_code: "015544" },
  // { label: "San Andres", mun_code: "015544" },
  // { label: "San Juan", mun_code: "015544" },
  // { label: "San Leon", mun_code: "015544" },
  // { label: "San Pablo", mun_code: "015544" },
  // { label: "San Vicente", mun_code: "015544" },
  // { label: "Santa Maria", mun_code: "015544" },
  // { label: "Santa Rosa", mun_code: "015544" },
  // { label: "Sinabaan", mun_code: "015544" },
  // { label: "Tanggal Sawang", mun_code: "015544" },
  // { label: "Cabangaran", mun_code: "015544" },
  // { label: "Carayungan Sur", mun_code: "015544" },
  // { label: "Del Rosario", mun_code: "015544" },
  // { label: "Angatel", mun_code: "015545" },
  // { label: "Balangay", mun_code: "015545" },
  // { label: "Batangcaoa", mun_code: "015545" },
  // { label: "Baug", mun_code: "015545" },
  // { label: "Bayaoas", mun_code: "015545" },
  // { label: "Bituag", mun_code: "015545" },
  // { label: "Camambugan", mun_code: "015545" },
  // { label: "Dalangiring", mun_code: "015545" },
  // { label: "Duplac", mun_code: "015545" },
  // { label: "Galarin", mun_code: "015545" },
  // { label: "Gueteb", mun_code: "015545" },
  // { label: "Malaca", mun_code: "015545" },
  // { label: "Malayo", mun_code: "015545" },
  // { label: "Malibong", mun_code: "015545" },
  // { label: "Pasibi East", mun_code: "015545" },
  // { label: "Pasibi West", mun_code: "015545" },
  // { label: "Pisuac", mun_code: "015545" },
  // { label: "Poblacion", mun_code: "015545" },
  // { label: "Real", mun_code: "015545" },
  // { label: "Salavante", mun_code: "015545" },
  // { label: "Sawat", mun_code: "015545" },
  // { label: "Anonas", mun_code: "015546" },
  // { label: "Bactad East", mun_code: "015546" },
  // { label: "Dr. Pedro T. Orata (Bactad Proper)", mun_code: "015546" },
  // { label: "Bayaoas", mun_code: "015546" },
  // { label: "Bolaoen", mun_code: "015546" },
  // { label: "Cabaruan", mun_code: "015546" },
  // { label: "Cabuloan", mun_code: "015546" },
  // { label: "Camanang", mun_code: "015546" },
  // { label: "Camantiles", mun_code: "015546" },
  // { label: "Casantaan", mun_code: "015546" },
  // { label: "Catablan", mun_code: "015546" },
  // { label: "Cayambanan", mun_code: "015546" },
  // { label: "Consolacion", mun_code: "015546" },
  // { label: "Dilan Paurido", mun_code: "015546" },
  // { label: "Labit Proper", mun_code: "015546" },
  // { label: "Labit West", mun_code: "015546" },
  // { label: "Mabanogbog", mun_code: "015546" },
  // { label: "Macalong", mun_code: "015546" },
  // { label: "Nancalobasaan", mun_code: "015546" },
  // { label: "Nancamaliran East", mun_code: "015546" },
  // { label: "Nancamaliran West", mun_code: "015546" },
  // { label: "Nancayasan", mun_code: "015546" },
  // { label: "Oltama", mun_code: "015546" },
  // { label: "Palina East", mun_code: "015546" },
  // { label: "Palina West", mun_code: "015546" },
  // { label: "Pinmaludpod", mun_code: "015546" },
  // { label: "Poblacion", mun_code: "015546" },
  // { label: "San Jose", mun_code: "015546" },
  // { label: "San Vicente", mun_code: "015546" },
  // { label: "Santa Lucia", mun_code: "015546" },
  // { label: "Santo Domingo", mun_code: "015546" },
  // { label: "Sugcong", mun_code: "015546" },
  // { label: "Tipuso", mun_code: "015546" },
  // { label: "Tulong", mun_code: "015546" },
  // { label: "Amamperez", mun_code: "015547" },
  // { label: "Bacag", mun_code: "015547" },
  // { label: "Barangobong", mun_code: "015547" },
  // { label: "Barraca", mun_code: "015547" },
  // { label: "Capulaan", mun_code: "015547" },
  // { label: "Caramutan", mun_code: "015547" },
  // { label: "La Paz", mun_code: "015547" },
  // { label: "Labit", mun_code: "015547" },
  // { label: "Lipay", mun_code: "015547" },
  // { label: "Lomboy", mun_code: "015547" },
  // { label: "Piaz (Plaza)", mun_code: "015547" },
  // { label: "Zone V (Pob.)", mun_code: "015547" },
  // { label: "Zone I (Pob.)", mun_code: "015547" },
  // { label: "Zone II (Pob.)", mun_code: "015547" },
  // { label: "Zone III (Pob.)", mun_code: "015547" },
  // { label: "Zone IV (Pob.)", mun_code: "015547" },
  // { label: "Puelay", mun_code: "015547" },
  // { label: "San Blas", mun_code: "015547" },
  // { label: "San Nicolas", mun_code: "015547" },
  // { label: "Tombod", mun_code: "015547" },
  // { label: "Unzad", mun_code: "015547" },
  // { label: "Anis", mun_code: "015548" },
  // { label: "Botique", mun_code: "015548" },
  // { label: "Caaringayan", mun_code: "015548" },
  // { label: "Domingo Alarcio (Cabilaoan East)", mun_code: "015548" },
  // { label: "Cabilaoan West", mun_code: "015548" },
  // { label: "Cabulalaan", mun_code: "015548" },
  // { label: "Calaoagan", mun_code: "015548" },
  // { label: "Calmay", mun_code: "015548" },
  // { label: "Casampagaan", mun_code: "015548" },
  // { label: "Casanestebanan", mun_code: "015548" },
  // { label: "Casantiagoan", mun_code: "015548" },
  // { label: "Inmanduyan", mun_code: "015548" },
  // { label: "Poblacion (Laoac)", mun_code: "015548" },
  // { label: "Lebueg", mun_code: "015548" },
  // { label: "Maraboc", mun_code: "015548" },
  // { label: "Nanbagatan", mun_code: "015548" },
  // { label: "Panaga", mun_code: "015548" },
  // { label: "Talogtog", mun_code: "015548" },
  // { label: "Turko", mun_code: "015548" },
  // { label: "Yatyat", mun_code: "015548" },
  // { label: "Balligi", mun_code: "015548" },
  // { label: "Banuar", mun_code: "015548" },
  // { label: "Ihubok II (Kayvaluganan)", mun_code: "020901" },
  // { label: "Ihubok I (Kaychanarianan)", mun_code: "020901" },
  // { label: "San Antonio", mun_code: "020901" },
  // { label: "San Joaquin", mun_code: "020901" },
  // { label: "Chanarian", mun_code: "020901" },
  // { label: "Kayhuvokan", mun_code: "020901" },
  // { label: "Raele", mun_code: "020902" },
  // { label: "San Rafael (Idiang)", mun_code: "020902" },
  // { label: "Santa Lucia (Kauhauhasan)", mun_code: "020902" },
  // { label: "Santa Maria (Marapuy)", mun_code: "020902" },
  // { label: "Santa Rosa (Kaynatuan)", mun_code: "020902" },
  // { label: "Radiwan", mun_code: "020903" },
  // { label: "Salagao", mun_code: "020903" },
  // { label: "San Vicente (Igang)", mun_code: "020903" },
  // { label: "Tuhel (Pob.)", mun_code: "020903" },
  // { label: "Hanib", mun_code: "020904" },
  // { label: "Kaumbakan", mun_code: "020904" },
  // { label: "Panatayan", mun_code: "020904" },
  // { label: "Uvoy (Pob.)", mun_code: "020904" },
  // { label: "Chavayan", mun_code: "020905" },
  // { label: "Malakdang (Pob.)", mun_code: "020905" },
  // { label: "Nakanmuan", mun_code: "020905" },
  // { label: "Savidug", mun_code: "020905" },
  // { label: "Sinakan (Pob.)", mun_code: "020905" },
  // { label: "Sumnanga", mun_code: "020905" },
  // { label: "Kayvaluganan (Pob.)", mun_code: "020906" },
  // { label: "Imnajbu", mun_code: "020906" },
  // { label: "Itbud", mun_code: "020906" },
  // { label: "Kayuganan (Pob.)", mun_code: "020906" },
  // { label: "Alinunu", mun_code: "021501" },
  // { label: "Bagu", mun_code: "021501" },
  // { label: "Banguian", mun_code: "021501" },
  // { label: "Calog Norte", mun_code: "021501" },
  // { label: "Calog Sur", mun_code: "021501" },
  // { label: "Canayun", mun_code: "021501" },
  // { label: "Centro (Pob.)", mun_code: "021501" },
  // { label: "Dana-Ili", mun_code: "021501" },
  // { label: "Guiddam", mun_code: "021501" },
  // { label: "Libertad", mun_code: "021501" },
  // { label: "Lucban", mun_code: "021501" },
  // { label: "Pinili", mun_code: "021501" },
  // { label: "Santa Filomena", mun_code: "021501" },
  // { label: "Santo Tomas", mun_code: "021501" },
  // { label: "Siguiran", mun_code: "021501" },
  // { label: "Simayung", mun_code: "021501" },
  // { label: "Sirit", mun_code: "021501" },
  // { label: "San Agustin", mun_code: "021501" },
  // { label: "San Julian", mun_code: "021501" },
  // { label: "Santa Rosa", mun_code: "021501" },
  // { label: "Abbeg", mun_code: "021502" },
  // { label: "Afusing Bato", mun_code: "021502" },
  // { label: "Afusing Daga", mun_code: "021502" },
  // { label: "Agani", mun_code: "021502" },
  // { label: "Baculod", mun_code: "021502" },
  // { label: "Baybayog", mun_code: "021502" },
  // { label: "Cabuluan", mun_code: "021502" },
  // { label: "Calantac", mun_code: "021502" },
  // { label: "Carallangan", mun_code: "021502" },
  // { label: "Centro Norte (Pob.)", mun_code: "021502" },
  // { label: "Centro Sur (Pob.)", mun_code: "021502" },
  // { label: "Dalaoig", mun_code: "021502" },
  // { label: "Damurog", mun_code: "021502" },
  // { label: "Jurisdiction", mun_code: "021502" },
  // { label: "Malalatan", mun_code: "021502" },
  // { label: "Maraburab", mun_code: "021502" },
  // { label: "Masin", mun_code: "021502" },
  // { label: "Pagbangkeruan", mun_code: "021502" },
  // { label: "Pared", mun_code: "021502" },
  // { label: "Piggatan", mun_code: "021502" },
  // { label: "Pinopoc", mun_code: "021502" },
  // { label: "Pussian", mun_code: "021502" },
  // { label: "San Esteban", mun_code: "021502" },
  // { label: "Tamban", mun_code: "021502" },
  // { label: "Tupang", mun_code: "021502" },
  // { label: "Bessang", mun_code: "021503" },
  // { label: "Binobongan", mun_code: "021503" },
  // { label: "Bulo", mun_code: "021503" },
  // { label: "Burot", mun_code: "021503" },
  // { label: "Capagaran (Brigida)", mun_code: "021503" },
  // { label: "Capalutan", mun_code: "021503" },
  // { label: "Capanickian Norte", mun_code: "021503" },
  // { label: "Capanickian Sur", mun_code: "021503" },
  // { label: "Cataratan", mun_code: "021503" },
  // { label: "Centro East (Pob.)", mun_code: "021503" },
  // { label: "Centro West (Pob.)", mun_code: "021503" },
  // { label: "Daan-Ili", mun_code: "021503" },
  // { label: "Dagupan", mun_code: "021503" },
  // { label: "Dalayap", mun_code: "021503" },
  // { label: "Gagaddangan", mun_code: "021503" },
  // { label: "Iringan", mun_code: "021503" },
  // { label: "Labben", mun_code: "021503" },
  // { label: "Maluyo", mun_code: "021503" },
  // { label: "Mapurao", mun_code: "021503" },
  // { label: "Matucay", mun_code: "021503" },
  // { label: "Nagattatan", mun_code: "021503" },
  // { label: "Pacac", mun_code: "021503" },
  // { label: "San Juan (Maguininango)", mun_code: "021503" },
  // { label: "Silangan", mun_code: "021503" },
  // { label: "Tamboli", mun_code: "021503" },
  // { label: "Tubel", mun_code: "021503" },
  // { label: "Utan", mun_code: "021503" },
  // { label: "Abolo", mun_code: "021504" },
  // { label: "Agguirit", mun_code: "021504" },
  // { label: "Alitungtung", mun_code: "021504" },
  // { label: "Annabuculan", mun_code: "021504" },
  // { label: "Annafatan", mun_code: "021504" },
  // { label: "Anquiray", mun_code: "021504" },
  // { label: "Babayuan", mun_code: "021504" },
  // { label: "Baccuit", mun_code: "021504" },
  // { label: "Bacring", mun_code: "021504" },
  // { label: "Baculud", mun_code: "021504" },
  // { label: "Balauini", mun_code: "021504" },
  // { label: "Bauan", mun_code: "021504" },
  // { label: "Bayabat", mun_code: "021504" },
  // { label: "Calamagui", mun_code: "021504" },
  // { label: "Calintaan", mun_code: "021504" },
  // { label: "Caratacat", mun_code: "021504" },
  // { label: "Casingsingan Norte", mun_code: "021504" },
  // { label: "Casingsingan Sur", mun_code: "021504" },
  // { label: "Catarauan", mun_code: "021504" },
  // { label: "Centro", mun_code: "021504" },
  // { label: "Concepcion", mun_code: "021504" },
  // { label: "Cordova", mun_code: "021504" },
  // { label: "Dadda", mun_code: "021504" },
  // { label: "Dafunganay", mun_code: "021504" },
  // { label: "Dugayung", mun_code: "021504" },
  // { label: "Estefania", mun_code: "021504" },
  // { label: "Gabut", mun_code: "021504" },
  // { label: "Gangauan", mun_code: "021504" },
  // { label: "Goran", mun_code: "021504" },
  // { label: "Jurisdiccion", mun_code: "021504" },
  // { label: "La Suerte", mun_code: "021504" },
  // { label: "Logung", mun_code: "021504" },
  // { label: "Magogod", mun_code: "021504" },
  // { label: "Manalo", mun_code: "021504" },
  // { label: "Marobbob", mun_code: "021504" },
  // { label: "Masical", mun_code: "021504" },
  // { label: "Monte Alegre", mun_code: "021504" },
  // { label: "Nabbialan", mun_code: "021504" },
  // { label: "Nagsabaran", mun_code: "021504" },
  // { label: "Nangalasauan", mun_code: "021504" },
  // { label: "Nanuccauan", mun_code: "021504" },
  // { label: "Pacac-Grande", mun_code: "021504" },
  // { label: "Palacu", mun_code: "021504" },
  // { label: "Palayag", mun_code: "021504" },
  // { label: "Tana", mun_code: "021504" },
  // { label: "Unag", mun_code: "021504" },
  // { label: "Backiling", mun_code: "021505" },
  // { label: "Bangag", mun_code: "021505" },
  // { label: "Binalan", mun_code: "021505" },
  // { label: "Bisagu", mun_code: "021505" },
  // { label: "Centro 1 (Pob.)", mun_code: "021505" },
  // { label: "Centro 2 (Pob.)", mun_code: "021505" },
  // { label: "Centro 3 (Pob.)", mun_code: "021505" },
  // { label: "Centro 4 (Pob.)", mun_code: "021505" },
  // { label: "Centro 5 (Pob.)", mun_code: "021505" },
  // { label: "Centro 6 (Pob.)", mun_code: "021505" },
  // { label: "Centro 7 (Pob.)", mun_code: "021505" },
  // { label: "Centro 8 (Pob.)", mun_code: "021505" },
  // { label: "Centro 9 (Pob.)", mun_code: "021505" },
  // { label: "Centro 10 (Pob.)", mun_code: "021505" },
  // { label: "Centro 11 (Pob.)", mun_code: "021505" },
  // { label: "Centro 12 (Pob.)", mun_code: "021505" },
  // { label: "Centro 13 (Pob.)", mun_code: "021505" },
  // { label: "Centro 14 (Pob.)", mun_code: "021505" },
  // { label: "Bukig", mun_code: "021505" },
  // { label: "Bulala Norte", mun_code: "021505" },
  // { label: "Bulala Sur", mun_code: "021505" },
  // { label: "Caagaman", mun_code: "021505" },
  // { label: "Centro 15 (Pob.)", mun_code: "021505" },
  // { label: "Dodan", mun_code: "021505" },
  // { label: "Fuga Island", mun_code: "021505" },
  // { label: "Gaddang", mun_code: "021505" },
  // { label: "Linao", mun_code: "021505" },
  // { label: "Mabanguc", mun_code: "021505" },
  // { label: "Macanaya (Pescaria)", mun_code: "021505" },
  // { label: "Maura", mun_code: "021505" },
  // { label: "Minanga", mun_code: "021505" },
  // { label: "Navagan", mun_code: "021505" },
  // { label: "Paddaya", mun_code: "021505" },
  // { label: "Paruddun Norte", mun_code: "021505" },
  // { label: "Paruddun Sur", mun_code: "021505" },
  // { label: "Plaza", mun_code: "021505" },
  // { label: "Punta", mun_code: "021505" },
  // { label: "San Antonio", mun_code: "021505" },
  // { label: "Tallungan", mun_code: "021505" },
  // { label: "Toran", mun_code: "021505" },
  // { label: "Sanja", mun_code: "021505" },
  // { label: "Zinarag", mun_code: "021505" },
  // { label: "Adaoag", mun_code: "021506" },
  // { label: "Agaman (Proper)", mun_code: "021506" },
  // { label: "Alba", mun_code: "021506" },
  // { label: "Annayatan", mun_code: "021506" },
  // { label: "Asassi", mun_code: "021506" },
  // { label: "Asinga-Via", mun_code: "021506" },
  // { label: "Awallan", mun_code: "021506" },
  // { label: "Bacagan", mun_code: "021506" },
  // { label: "Bagunot", mun_code: "021506" },
  // { label: "Barsat East", mun_code: "021506" },
  // { label: "Barsat West", mun_code: "021506" },
  // { label: "Bitag Grande", mun_code: "021506" },
  // { label: "Bunugan", mun_code: "021506" },
  // { label: "Canagatan", mun_code: "021506" },
  // { label: "Carupian", mun_code: "021506" },
  // { label: "Catugay", mun_code: "021506" },
  // { label: "Poblacion (Centro)", mun_code: "021506" },
  // { label: "Dabbac Grande", mun_code: "021506" },
  // { label: "Dalin", mun_code: "021506" },
  // { label: "Dalla", mun_code: "021506" },
  // { label: "Hacienda Intal", mun_code: "021506" },
  // { label: "Ibulo", mun_code: "021506" },
  // { label: "Imurong", mun_code: "021506" },
  // { label: "J. Pallagao", mun_code: "021506" },
  // { label: "Lasilat", mun_code: "021506" },
  // { label: "Masical", mun_code: "021506" },
  // { label: "Mocag", mun_code: "021506" },
  // { label: "Nangalinan", mun_code: "021506" },
  // { label: "Remus", mun_code: "021506" },
  // { label: "San Antonio", mun_code: "021506" },
  // { label: "San Francisco", mun_code: "021506" },
  // { label: "San Isidro", mun_code: "021506" },
  // { label: "San Jose", mun_code: "021506" },
  // { label: "San Miguel", mun_code: "021506" },
  // { label: "San Vicente", mun_code: "021506" },
  // { label: "Santa Margarita", mun_code: "021506" },
  // { label: "Santor", mun_code: "021506" },
  // { label: "Taguing", mun_code: "021506" },
  // { label: "Taguntungan", mun_code: "021506" },
  // { label: "Tallang", mun_code: "021506" },
  // { label: "Temblique", mun_code: "021506" },
  // { label: "Taytay", mun_code: "021506" },
  // { label: "Tungel", mun_code: "021506" },
  // { label: "Mabini", mun_code: "021506" },
  // { label: "Agaman Norte", mun_code: "021506" },
  // { label: "Agaman Sur", mun_code: "021506" },
  // { label: "C. Verzosa ((Valley Cove*)", mun_code: "021506" },
  // { label: "Ammubuan", mun_code: "021507" },
  // { label: "Baran", mun_code: "021507" },
  // { label: "Cabaritan East", mun_code: "021507" },
  // { label: "Cabaritan West", mun_code: "021507" },
  // { label: "Cabayu", mun_code: "021507" },
  // { label: "Cabuluan East", mun_code: "021507" },
  // { label: "Cabuluan West", mun_code: "021507" },
  // { label: "Centro East (Pob.)", mun_code: "021507" },
  // { label: "Centro West (Pob.)", mun_code: "021507" },
  // { label: "Fugu", mun_code: "021507" },
  // { label: "Mabuttal East", mun_code: "021507" },
  // { label: "Mabuttal West", mun_code: "021507" },
  // { label: "Nararagan", mun_code: "021507" },
  // { label: "Palloc", mun_code: "021507" },
  // { label: "Payagan East", mun_code: "021507" },
  // { label: "Payagan West", mun_code: "021507" },
  // { label: "San Juan", mun_code: "021507" },
  // { label: "Santa Cruz", mun_code: "021507" },
  // { label: "Zitanga", mun_code: "021507" },
  // { label: "Ballang", mun_code: "021508" },
  // { label: "Balza", mun_code: "021508" },
  // { label: "Cabaritan", mun_code: "021508" },
  // { label: "Calamegatan", mun_code: "021508" },
  // { label: "Centro (Pob.)", mun_code: "021508" },
  // { label: "Centro West", mun_code: "021508" },
  // { label: "Dalaya", mun_code: "021508" },
  // { label: "Fula", mun_code: "021508" },
  // { label: "Leron", mun_code: "021508" },
  // { label: "Antiporda", mun_code: "021508" },
  // { label: "Maddalero", mun_code: "021508" },
  // { label: "Mala Este", mun_code: "021508" },
  // { label: "Mala Weste", mun_code: "021508" },
  // { label: "Minanga Este", mun_code: "021508" },
  // { label: "Paddaya Este", mun_code: "021508" },
  // { label: "Pattao", mun_code: "021508" },
  // { label: "Quinawegan", mun_code: "021508" },
  // { label: "Remebella", mun_code: "021508" },
  // { label: "San Isidro", mun_code: "021508" },
  // { label: "Santa Isabel", mun_code: "021508" },
  // { label: "Santa Maria", mun_code: "021508" },
  // { label: "Tabbac", mun_code: "021508" },
  // { label: "Villa Cielo", mun_code: "021508" },
  // { label: "Alucao Weste (San Lorenzo*)", mun_code: "021508" },
  // { label: "Minanga Weste", mun_code: "021508" },
  // { label: "Paddaya Weste", mun_code: "021508" },
  // { label: "San Juan", mun_code: "021508" },
  // { label: "San Vicente", mun_code: "021508" },
  // { label: "Villa Gracia", mun_code: "021508" },
  // { label: "Villa Leonora", mun_code: "021508" },
  // { label: "Cabudadan", mun_code: "021509" },
  // { label: "Balatubat", mun_code: "021509" },
  // { label: "Dadao", mun_code: "021509" },
  // { label: "Dibay", mun_code: "021509" },
  // { label: "Dilam", mun_code: "021509" },
  // { label: "Magsidel", mun_code: "021509" },
  // { label: "Naguilian", mun_code: "021509" },
  // { label: "Poblacion", mun_code: "021509" },
  // { label: "Babuyan Claro", mun_code: "021509" },
  // { label: "Centro II", mun_code: "021509" },
  // { label: "Dalupiri", mun_code: "021509" },
  // { label: "Minabel", mun_code: "021509" },
  // { label: "Abagao", mun_code: "021510" },
  // { label: "Afunan Cabayu", mun_code: "021510" },
  // { label: "Agusi", mun_code: "021510" },
  // { label: "Alilinu", mun_code: "021510" },
  // { label: "Baggao", mun_code: "021510" },
  // { label: "Bantay", mun_code: "021510" },
  // { label: "Bulala", mun_code: "021510" },
  // { label: "Casili Norte", mun_code: "021510" },
  // { label: "Catotoran Norte", mun_code: "021510" },
  // { label: "Centro Norte (Pob.)", mun_code: "021510" },
  // { label: "Centro Sur (Pob.)", mun_code: "021510" },
  // { label: "Cullit", mun_code: "021510" },
  // { label: "Dacal-Lafugu", mun_code: "021510" },
  // { label: "Dammang Norte (Joaquin de la Cruz*)", mun_code: "021510" },
  // { label: "Dugo", mun_code: "021510" },
  // { label: "Fusina", mun_code: "021510" },
  // { label: "Gang-ngo", mun_code: "021510" },
  // { label: "Jurisdiction", mun_code: "021510" },
  // { label: "Luec", mun_code: "021510" },
  // { label: "Minanga", mun_code: "021510" },
  // { label: "Paragat", mun_code: "021510" },
  // { label: "Tagum", mun_code: "021510" },
  // { label: "Tuluttuging", mun_code: "021510" },
  // { label: "Ziminila", mun_code: "021510" },
  // { label: "Casili Sur", mun_code: "021510" },
  // { label: "Catotoran Sur", mun_code: "021510" },
  // { label: "Dammang Sur (Felipe Tuzon*)", mun_code: "021510" },
  // { label: "Sapping", mun_code: "021510" },
  // { label: "Alimoan", mun_code: "021511" },
  // { label: "Bacsay Cataraoan Norte", mun_code: "021511" },
  // { label: "Bacsay Mapulapula", mun_code: "021511" },
  // { label: "Bilibigao", mun_code: "021511" },
  // { label: "Buenavista", mun_code: "021511" },
  // { label: "Cadcadir East", mun_code: "021511" },
  // { label: "Capanikian", mun_code: "021511" },
  // { label: "Centro I (Pob.)", mun_code: "021511" },
  // { label: "Centro II (Pob.)", mun_code: "021511" },
  // { label: "Culao", mun_code: "021511" },
  // { label: "Dibalio", mun_code: "021511" },
  // { label: "Kilkiling", mun_code: "021511" },
  // { label: "Lablabig", mun_code: "021511" },
  // { label: "Luzon", mun_code: "021511" },
  // { label: "Mabnang", mun_code: "021511" },
  // { label: "Magdalena", mun_code: "021511" },
  // { label: "Centro VII (Malasin East)", mun_code: "021511" },
  // { label: "Malilitao", mun_code: "021511" },
  // { label: "Centro VI (Minanga)", mun_code: "021511" },
  // { label: "Nagsabaran", mun_code: "021511" },
  // { label: "Centro IV (Nangasangan)", mun_code: "021511" },
  // { label: "Pata East", mun_code: "021511" },
  // { label: "Pinas", mun_code: "021511" },
  // { label: "Santiago", mun_code: "021511" },
  // { label: "Santo Tomas", mun_code: "021511" },
  // { label: "Santa Maria (Surngot)", mun_code: "021511" },
  // { label: "Tabbugan", mun_code: "021511" },
  // { label: "Taggat Norte", mun_code: "021511" },
  // { label: "Union", mun_code: "021511" },
  // { label: "Bacsay Cataraoan Sur", mun_code: "021511" },
  // { label: "Cadcadir West", mun_code: "021511" },
  // { label: "Centro III", mun_code: "021511" },
  // { label: "Centro V (Mina)", mun_code: "021511" },
  // { label: "Centro VIII (Malasin West)", mun_code: "021511" },
  // { label: "Pata West", mun_code: "021511" },
  // { label: "San Antonio (Sayad*/Bimekel*)", mun_code: "021511" },
  // { label: "San Isidro", mun_code: "021511" },
  // { label: "San Vicente", mun_code: "021511" },
  // { label: "Taggat Sur", mun_code: "021511" },
  // { label: "Alibago (Villa Maria)", mun_code: "021512" },
  // { label: "Barangay I (Pob.)", mun_code: "021512" },
  // { label: "Barangay II (Pob.)", mun_code: "021512" },
  // { label: "Barangay III (San Roque*)", mun_code: "021512" },
  // { label: "Divisoria", mun_code: "021512" },
  // { label: "Inga", mun_code: "021512" },
  // { label: "Lanna", mun_code: "021512" },
  // { label: "Lemu Norte", mun_code: "021512" },
  // { label: "Liwan Norte", mun_code: "021512" },
  // { label: "Liwan Sur", mun_code: "021512" },
  // { label: "Maddarulug Norte", mun_code: "021512" },
  // { label: "Magalalag East", mun_code: "021512" },
  // { label: "Maracuru", mun_code: "021512" },
  // { label: "Barangay IV (Pob.)", mun_code: "021512" },
  // { label: "Roma Norte", mun_code: "021512" },
  // { label: "Barangay III-A", mun_code: "021512" },
  // { label: "Batu", mun_code: "021512" },
  // { label: "Lemu Sur", mun_code: "021512" },
  // { label: "Maddarulug Sur", mun_code: "021512" },
  // { label: "Magalalag West (San Nicolas*)", mun_code: "021512" },
  // { label: "Roma Sur", mun_code: "021512" },
  // { label: "San Antonio", mun_code: "021512" },
  // { label: "Abra", mun_code: "021513" },
  // { label: "Aguiguican", mun_code: "021513" },
  // { label: "Bangatan Ngagan", mun_code: "021513" },
  // { label: "Baracaoit", mun_code: "021513" },
  // { label: "Baraoidan", mun_code: "021513" },
  // { label: "Barbarit", mun_code: "021513" },
  // { label: "Basao", mun_code: "021513" },
  // { label: "Cabayu", mun_code: "021513" },
  // { label: "Calaoagan Bassit", mun_code: "021513" },
  // { label: "Calaoagan Dackel", mun_code: "021513" },
  // { label: "Capiddigan", mun_code: "021513" },
  // { label: "Capissayan Norte", mun_code: "021513" },
  // { label: "Capissayan Sur", mun_code: "021513" },
  // { label: "Casicallan Sur", mun_code: "021513" },
  // { label: "Casicallan Norte", mun_code: "021513" },
  // { label: "Centro Norte (Pob.)", mun_code: "021513" },
  // { label: "Centro Sur (Pob.)", mun_code: "021513" },
  // { label: "Cullit", mun_code: "021513" },
  // { label: "Cumao", mun_code: "021513" },
  // { label: "Cunig", mun_code: "021513" },
  // { label: "Dummun", mun_code: "021513" },
  // { label: "Fugu", mun_code: "021513" },
  // { label: "Ganzano", mun_code: "021513" },
  // { label: "Guising", mun_code: "021513" },
  // { label: "Langgan", mun_code: "021513" },
  // { label: "Lapogan", mun_code: "021513" },
  // { label: "L. Adviento", mun_code: "021513" },
  // { label: "Mabuno", mun_code: "021513" },
  // { label: "Nabaccayan", mun_code: "021513" },
  // { label: "Naddungan", mun_code: "021513" },
  // { label: "Nagatutuan", mun_code: "021513" },
  // { label: "Nassiping", mun_code: "021513" },
  // { label: "Newagac", mun_code: "021513" },
  // { label: "Palagao Norte", mun_code: "021513" },
  // { label: "Palagao Sur", mun_code: "021513" },
  // { label: "San Vicente", mun_code: "021513" },
  // { label: "Santa Maria", mun_code: "021513" },
  // { label: "Sidem", mun_code: "021513" },
  // { label: "Santa Ana", mun_code: "021513" },
  // { label: "Tagumay", mun_code: "021513" },
  // { label: "Takiki", mun_code: "021513" },
  // { label: "Taligan", mun_code: "021513" },
  // { label: "Tanglagan", mun_code: "021513" },
  // { label: "T. Elizaga (Mabirbira)", mun_code: "021513" },
  // { label: "Tubungan Este", mun_code: "021513" },
  // { label: "Tubungan Weste", mun_code: "021513" },
  // { label: "Bolos Point", mun_code: "021513" },
  // { label: "San Carlos", mun_code: "021513" },
  // { label: "Amunitan", mun_code: "021514" },
  // { label: "Batangan", mun_code: "021514" },
  // { label: "Baua", mun_code: "021514" },
  // { label: "Cabanbanan Norte", mun_code: "021514" },
  // { label: "Cabanbanan Sur", mun_code: "021514" },
  // { label: "Cabiraoan", mun_code: "021514" },
  // { label: "Callao", mun_code: "021514" },
  // { label: "Calayan", mun_code: "021514" },
  // { label: "Caroan", mun_code: "021514" },
  // { label: "Casitan", mun_code: "021514" },
  // { label: "Flourishing (Pob.)", mun_code: "021514" },
  // { label: "Ipil", mun_code: "021514" },
  // { label: "Isca", mun_code: "021514" },
  // { label: "Magrafil", mun_code: "021514" },
  // { label: "Minanga", mun_code: "021514" },
  // { label: "Rebecca (Nagbabalacan*)", mun_code: "021514" },
  // { label: "Paradise (Pob.)", mun_code: "021514" },
  // { label: "Pateng", mun_code: "021514" },
  // { label: "Progressive (Pob.)", mun_code: "021514" },
  // { label: "San Jose", mun_code: "021514" },
  // { label: "Santa Clara", mun_code: "021514" },
  // { label: "Santa Cruz", mun_code: "021514" },
  // { label: "Santa Maria", mun_code: "021514" },
  // { label: "Smart (Pob.)", mun_code: "021514" },
  // { label: "Tapel", mun_code: "021514" },
  // { label: "Ajat (Pob.)", mun_code: "021515" },
  // { label: "Atulu", mun_code: "021515" },
  // { label: "Baculud", mun_code: "021515" },
  // { label: "Bayo", mun_code: "021515" },
  // { label: "Campo", mun_code: "021515" },
  // { label: "San Esteban (Capitan)", mun_code: "021515" },
  // { label: "Dumpao", mun_code: "021515" },
  // { label: "Gammad", mun_code: "021515" },
  // { label: "Santa Teresa (Gammad Sur)", mun_code: "021515" },
  // { label: "Garab", mun_code: "021515" },
  // { label: "Malabbac", mun_code: "021515" },
  // { label: "Manaoag (Aquiliquilao)", mun_code: "021515" },
  // { label: "Minanga Norte", mun_code: "021515" },
  // { label: "Minanga Sur", mun_code: "021515" },
  // { label: "Nattanzan (Pob.)", mun_code: "021515" },
  // { label: "Redondo", mun_code: "021515" },
  // { label: "Salamague", mun_code: "021515" },
  // { label: "San Isidro (Ugac West)", mun_code: "021515" },
  // { label: "San Lorenzo", mun_code: "021515" },
  // { label: "Santa Barbara", mun_code: "021515" },
  // { label: "Santa Rosa", mun_code: "021515" },
  // { label: "Santiago", mun_code: "021515" },
  // { label: "San Vicente (Ugac East)", mun_code: "021515" },
  // { label: "Abagao", mun_code: "021516" },
  // { label: "Alaguia", mun_code: "021516" },
  // { label: "Bagumbayan", mun_code: "021516" },
  // { label: "Bangag", mun_code: "021516" },
  // { label: "Bical", mun_code: "021516" },
  // { label: "Bicud", mun_code: "021516" },
  // { label: "Binag", mun_code: "021516" },
  // { label: "Cabayabasan (Capacuan)", mun_code: "021516" },
  // { label: "Cagoran", mun_code: "021516" },
  // { label: "Cambong", mun_code: "021516" },
  // { label: "Catayauan", mun_code: "021516" },
  // { label: "Catugan", mun_code: "021516" },
  // { label: "Centro (Pob.)", mun_code: "021516" },
  // { label: "Cullit", mun_code: "021516" },
  // { label: "Dagupan", mun_code: "021516" },
  // { label: "Dalaya", mun_code: "021516" },
  // { label: "Fabrica", mun_code: "021516" },
  // { label: "Fusina", mun_code: "021516" },
  // { label: "Jurisdiction", mun_code: "021516" },
  // { label: "Lalafugan", mun_code: "021516" },
  // { label: "Logac", mun_code: "021516" },
  // { label: "Magallungon (Sta. Teresa*)", mun_code: "021516" },
  // { label: "Magapit", mun_code: "021516" },
  // { label: "Malanao", mun_code: "021516" },
  // { label: "Maxingal", mun_code: "021516" },
  // { label: "Naguilian", mun_code: "021516" },
  // { label: "Paranum", mun_code: "021516" },
  // { label: "Rosario", mun_code: "021516" },
  // { label: "San Antonio (Lafu)", mun_code: "021516" },
  // { label: "San Jose", mun_code: "021516" },
  // { label: "San Juan", mun_code: "021516" },
  // { label: "San Lorenzo", mun_code: "021516" },
  // { label: "San Mariano", mun_code: "021516" },
  // { label: "Santa Maria", mun_code: "021516" },
  // { label: "Tucalana", mun_code: "021516" },
  // { label: "Aggunetan", mun_code: "021517" },
  // { label: "Alannay", mun_code: "021517" },
  // { label: "Battalan", mun_code: "021517" },
  // { label: "Calapangan Norte", mun_code: "021517" },
  // { label: "Calapangan Sur", mun_code: "021517" },
  // { label: "Callao Norte", mun_code: "021517" },
  // { label: "Callao Sur", mun_code: "021517" },
  // { label: "Cataliganan", mun_code: "021517" },
  // { label: "Finugo Norte", mun_code: "021517" },
  // { label: "Gabun", mun_code: "021517" },
  // { label: "Ignacio Jurado (Finugu Sur)", mun_code: "021517" },
  // { label: "Magsaysay", mun_code: "021517" },
  // { label: "Malinta", mun_code: "021517" },
  // { label: "Minanga Sur", mun_code: "021517" },
  // { label: "Minanga Norte", mun_code: "021517" },
  // { label: "Nicolas Agatep", mun_code: "021517" },
  // { label: "Peru", mun_code: "021517" },
  // { label: "Centro I (Pob.)", mun_code: "021517" },
  // { label: "San Pedro", mun_code: "021517" },
  // { label: "Sicalao", mun_code: "021517" },
  // { label: "Tagao", mun_code: "021517" },
  // { label: "Tucalan Passing", mun_code: "021517" },
  // { label: "Viga", mun_code: "021517" },
  // { label: "Cabatacan East (Duldugan*)", mun_code: "021517" },
  // { label: "Cabatacan West", mun_code: "021517" },
  // { label: "Nabannagan East", mun_code: "021517" },
  // { label: "Nabannagan West", mun_code: "021517" },
  // { label: "Centro II (Pob.)", mun_code: "021517" },
  // { label: "Centro III (Pob.)", mun_code: "021517" },
  // { label: "New Orlins", mun_code: "021517" },
  // { label: "Abanqueruan", mun_code: "021518" },
  // { label: "Allasitan", mun_code: "021518" },
  // { label: "Bagu", mun_code: "021518" },
  // { label: "Balingit", mun_code: "021518" },
  // { label: "Bidduang", mun_code: "021518" },
  // { label: "Cabaggan", mun_code: "021518" },
  // { label: "Capalalian", mun_code: "021518" },
  // { label: "Casitan", mun_code: "021518" },
  // { label: "Centro (Pob.)", mun_code: "021518" },
  // { label: "Curva", mun_code: "021518" },
  // { label: "Gattu", mun_code: "021518" },
  // { label: "Masi", mun_code: "021518" },
  // { label: "Nagattatan", mun_code: "021518" },
  // { label: "Nagtupacan", mun_code: "021518" },
  // { label: "San Juan", mun_code: "021518" },
  // { label: "Santa Cruz", mun_code: "021518" },
  // { label: "Tabba", mun_code: "021518" },
  // { label: "Tupanna", mun_code: "021518" },
  // { label: "Aggugaddan", mun_code: "021519" },
  // { label: "Alimanao", mun_code: "021519" },
  // { label: "Baliuag", mun_code: "021519" },
  // { label: "Bical", mun_code: "021519" },
  // { label: "Bugatay", mun_code: "021519" },
  // { label: "Buyun", mun_code: "021519" },
  // { label: "Cabasan", mun_code: "021519" },
  // { label: "Cabbo", mun_code: "021519" },
  // { label: "Callao", mun_code: "021519" },
  // { label: "Camasi", mun_code: "021519" },
  // { label: "Centro (Pob.)", mun_code: "021519" },
  // { label: "Dodan", mun_code: "021519" },
  // { label: "Lapi", mun_code: "021519" },
  // { label: "Malibabag", mun_code: "021519" },
  // { label: "Manga", mun_code: "021519" },
  // { label: "Minanga", mun_code: "021519" },
  // { label: "Nabbabalayan", mun_code: "021519" },
  // { label: "Nanguilattan", mun_code: "021519" },
  // { label: "Nannarian", mun_code: "021519" },
  // { label: "Parabba", mun_code: "021519" },
  // { label: "Patagueleg", mun_code: "021519" },
  // { label: "Quibal", mun_code: "021519" },
  // { label: "San Roque (Litto)", mun_code: "021519" },
  // { label: "Sisim", mun_code: "021519" },
  // { label: "Apayao", mun_code: "021520" },
  // { label: "Aquib", mun_code: "021520" },
  // { label: "Dugayung", mun_code: "021520" },
  // { label: "Gumarueng", mun_code: "021520" },
  // { label: "Macapil", mun_code: "021520" },
  // { label: "Maguilling", mun_code: "021520" },
  // { label: "Minanga", mun_code: "021520" },
  // { label: "Poblacion I", mun_code: "021520" },
  // { label: "Santa Barbara", mun_code: "021520" },
  // { label: "Santo Domingo", mun_code: "021520" },
  // { label: "Sicatna", mun_code: "021520" },
  // { label: "Villa Rey (San Gaspar*)", mun_code: "021520" },
  // { label: "Warat", mun_code: "021520" },
  // { label: "Baung", mun_code: "021520" },
  // { label: "Calaoagan", mun_code: "021520" },
  // { label: "Catarauan", mun_code: "021520" },
  // { label: "Poblacion II", mun_code: "021520" },
  // { label: "Villa Reyno", mun_code: "021520" },
  // { label: "Anagguan", mun_code: "021521" },
  // { label: "Anurturu", mun_code: "021521" },
  // { label: "Anungu", mun_code: "021521" },
  // { label: "Balungcanag", mun_code: "021521" },
  // { label: "Batu", mun_code: "021521" },
  // { label: "Cambabangan", mun_code: "021521" },
  // { label: "Capacuan", mun_code: "021521" },
  // { label: "Dunggan", mun_code: "021521" },
  // { label: "Duyun", mun_code: "021521" },
  // { label: "Gaddangao", mun_code: "021521" },
  // { label: "Gaggabutan East", mun_code: "021521" },
  // { label: "Illuru Norte", mun_code: "021521" },
  // { label: "Lattut", mun_code: "021521" },
  // { label: "Linno (Villa Cruz*)", mun_code: "021521" },
  // { label: "Liwan", mun_code: "021521" },
  // { label: "Mabbang", mun_code: "021521" },
  // { label: "Mauanan", mun_code: "021521" },
  // { label: "Masi (Zinundungan)", mun_code: "021521" },
  // { label: "Minanga", mun_code: "021521" },
  // { label: "Nanauatan", mun_code: "021521" },
  // { label: "Nanungaran", mun_code: "021521" },
  // { label: "Pasingan", mun_code: "021521" },
  // { label: "Poblacion", mun_code: "021521" },
  // { label: "San Juan (Zinundungan)", mun_code: "021521" },
  // { label: "Sinicking", mun_code: "021521" },
  // { label: "Battut", mun_code: "021521" },
  // { label: "Bural (Zinundungan)", mun_code: "021521" },
  // { label: "Gaggabutan West", mun_code: "021521" },
  // { label: "Illuru Sur", mun_code: "021521" },
  // { label: "Bangan", mun_code: "021522" },
  // { label: "Callungan", mun_code: "021522" },
  // { label: "Centro I (Pob.)", mun_code: "021522" },
  // { label: "Centro II (Pob.)", mun_code: "021522" },
  // { label: "Dacal", mun_code: "021522" },
  // { label: "Dagueray", mun_code: "021522" },
  // { label: "Dammang", mun_code: "021522" },
  // { label: "Kittag", mun_code: "021522" },
  // { label: "Langagan", mun_code: "021522" },
  // { label: "Magacan", mun_code: "021522" },
  // { label: "Marzan", mun_code: "021522" },
  // { label: "Masisit", mun_code: "021522" },
  // { label: "Nagrangtayan", mun_code: "021522" },
  // { label: "Namuac", mun_code: "021522" },
  // { label: "San Andres", mun_code: "021522" },
  // { label: "Santiago", mun_code: "021522" },
  // { label: "Santor", mun_code: "021522" },
  // { label: "Tokitok", mun_code: "021522" },
  // { label: "Casagan", mun_code: "021523" },
  // { label: "Casambalangan (Port Irene)", mun_code: "021523" },
  // { label: "Centro (Pob.)", mun_code: "021523" },
  // { label: "Diora-Zinungan", mun_code: "021523" },
  // { label: "Dungeg", mun_code: "021523" },
  // { label: "Kapanikian", mun_code: "021523" },
  // { label: "Marede", mun_code: "021523" },
  // { label: "Palawig", mun_code: "021523" },
  // { label: "Batu-Parada", mun_code: "021523" },
  // { label: "Patunungan", mun_code: "021523" },
  // { label: "Rapuli (Punti)", mun_code: "021523" },
  // { label: "San Vicente (Fort)", mun_code: "021523" },
  // { label: "Santa Clara", mun_code: "021523" },
  // { label: "Santa Cruz", mun_code: "021523" },
  // { label: "Visitacion (Pob.)", mun_code: "021523" },
  // { label: "Tangatan", mun_code: "021523" },
  // { label: "Cadongdongan", mun_code: "021524" },
  // { label: "Capacuan", mun_code: "021524" },
  // { label: "Centro I (Pob.)", mun_code: "021524" },
  // { label: "Centro II (Pob.)", mun_code: "021524" },
  // { label: "Macatel", mun_code: "021524" },
  // { label: "Portabaga", mun_code: "021524" },
  // { label: "San Juan", mun_code: "021524" },
  // { label: "San Miguel", mun_code: "021524" },
  // { label: "Salungsong", mun_code: "021524" },
  // { label: "Sicul", mun_code: "021524" },
  // { label: "Alucao", mun_code: "021525" },
  // { label: "Buyun", mun_code: "021525" },
  // { label: "Centro East (Pob.)", mun_code: "021525" },
  // { label: "Dungeg", mun_code: "021525" },
  // { label: "Luga", mun_code: "021525" },
  // { label: "Masi", mun_code: "021525" },
  // { label: "Mission", mun_code: "021525" },
  // { label: "Simpatuyo", mun_code: "021525" },
  // { label: "Villa", mun_code: "021525" },
  // { label: "Aridawen", mun_code: "021525" },
  // { label: "Caniugan", mun_code: "021525" },
  // { label: "Centro West", mun_code: "021525" },
  // { label: "Simbaluca", mun_code: "021525" },
  // { label: "Abariongan Ruar", mun_code: "021526" },
  // { label: "Abariongan Uneg", mun_code: "021526" },
  // { label: "Balagan", mun_code: "021526" },
  // { label: "Balanni", mun_code: "021526" },
  // { label: "Cabayo", mun_code: "021526" },
  // { label: "Calapangan", mun_code: "021526" },
  // { label: "Calassitan", mun_code: "021526" },
  // { label: "Campo", mun_code: "021526" },
  // { label: "Centro Norte (Pob.)", mun_code: "021526" },
  // { label: "Centro Sur (Pob.)", mun_code: "021526" },
  // { label: "Dungao", mun_code: "021526" },
  // { label: "Lattac", mun_code: "021526" },
  // { label: "Lipatan", mun_code: "021526" },
  // { label: "Lubo", mun_code: "021526" },
  // { label: "Mabitbitnong", mun_code: "021526" },
  // { label: "Mapitac", mun_code: "021526" },
  // { label: "Masical", mun_code: "021526" },
  // { label: "Matalao", mun_code: "021526" },
  // { label: "Nag-uma", mun_code: "021526" },
  // { label: "Namuccayan", mun_code: "021526" },
  // { label: "Niug Norte", mun_code: "021526" },
  // { label: "Niug Sur", mun_code: "021526" },
  // { label: "Palusao", mun_code: "021526" },
  // { label: "San Manuel", mun_code: "021526" },
  // { label: "San Roque", mun_code: "021526" },
  // { label: "Santa Felicitas", mun_code: "021526" },
  // { label: "Santa Maria", mun_code: "021526" },
  // { label: "Sidiran", mun_code: "021526" },
  // { label: "Tabang", mun_code: "021526" },
  // { label: "Tamucco", mun_code: "021526" },
  // { label: "Virginia", mun_code: "021526" },
  // { label: "Andarayan North", mun_code: "021527" },
  // { label: "Lannig", mun_code: "021527" },
  // { label: "Bangag", mun_code: "021527" },
  // { label: "Bantay", mun_code: "021527" },
  // { label: "Basi East", mun_code: "021527" },
  // { label: "Bauan East", mun_code: "021527" },
  // { label: "Cadaanan", mun_code: "021527" },
  // { label: "Calamagui", mun_code: "021527" },
  // { label: "Carilucud", mun_code: "021527" },
  // { label: "Cattaran", mun_code: "021527" },
  // { label: "Centro Northeast (Pob.)", mun_code: "021527" },
  // { label: "Centro Northwest (Pob.)", mun_code: "021527" },
  // { label: "Centro Southeast (Pob.)", mun_code: "021527" },
  // { label: "Centro Southwest (Pob.)", mun_code: "021527" },
  // { label: "Lanna", mun_code: "021527" },
  // { label: "Lingu", mun_code: "021527" },
  // { label: "Maguirig", mun_code: "021527" },
  // { label: "Nabbotuan", mun_code: "021527" },
  // { label: "Nangalisan", mun_code: "021527" },
  // { label: "Natappian East", mun_code: "021527" },
  // { label: "Padul", mun_code: "021527" },
  // { label: "Palao", mun_code: "021527" },
  // { label: "Parug-parug", mun_code: "021527" },
  // { label: "Pataya", mun_code: "021527" },
  // { label: "Sampaguita", mun_code: "021527" },
  // { label: "Maddarulug (Santo Domingo)", mun_code: "021527" },
  // { label: "Ubong", mun_code: "021527" },
  // { label: "Dassun", mun_code: "021527" },
  // { label: "Furagui", mun_code: "021527" },
  // { label: "Gadu", mun_code: "021527" },
  // { label: "Iraga", mun_code: "021527" },
  // { label: "Andarayan South", mun_code: "021527" },
  // { label: "Basi West", mun_code: "021527" },
  // { label: "Bauan West", mun_code: "021527" },
  // { label: "Calillauan", mun_code: "021527" },
  // { label: "Gen. Eulogio Balao", mun_code: "021527" },
  // { label: "Natappian West", mun_code: "021527" },
  // { label: "Malalam-Malacabibi", mun_code: "021527" },
  // { label: "Accusilian", mun_code: "021528" },
  // { label: "Alabiao", mun_code: "021528" },
  // { label: "Alabug", mun_code: "021528" },
  // { label: "Angang", mun_code: "021528" },
  // { label: "Bagumbayan", mun_code: "021528" },
  // { label: "Barancuag", mun_code: "021528" },
  // { label: "Battung", mun_code: "021528" },
  // { label: "Bicok", mun_code: "021528" },
  // { label: "Bugnay", mun_code: "021528" },
  // { label: "Balagao", mun_code: "021528" },
  // { label: "Cagumitan", mun_code: "021528" },
  // { label: "Cato", mun_code: "021528" },
  // { label: "Culong", mun_code: "021528" },
  // { label: "Dagupan", mun_code: "021528" },
  // { label: "Fugu", mun_code: "021528" },
  // { label: "Lakambini", mun_code: "021528" },
  // { label: "Lallayug", mun_code: "021528" },
  // { label: "Malumin", mun_code: "021528" },
  // { label: "Mambacag", mun_code: "021528" },
  // { label: "San Vicente (Maleg)", mun_code: "021528" },
  // { label: "Mungo", mun_code: "021528" },
  // { label: "Naruangan", mun_code: "021528" },
  // { label: "Palca", mun_code: "021528" },
  // { label: "Pata", mun_code: "021528" },
  // { label: "San Juan", mun_code: "021528" },
  // { label: "San Luis (Gurengad)", mun_code: "021528" },
  // { label: "Santo Tomas", mun_code: "021528" },
  // { label: "Taribubu", mun_code: "021528" },
  // { label: "Villa Laida", mun_code: "021528" },
  // { label: "Poblacion I (Ward I Centro)", mun_code: "021528" },
  // { label: "Poblacion II (Ward II Centro)", mun_code: "021528" },
  // { label: "Malalinta", mun_code: "021528" },
  // { label: "Annafunan East", mun_code: "021529" },
  // { label: "Atulayan Norte", mun_code: "021529" },
  // { label: "Bagay", mun_code: "021529" },
  // { label: "Centro 1 (Pob.)", mun_code: "021529" },
  // { label: "Centro 4 (Pob.)", mun_code: "021529" },
  // { label: "Centro 5 (Pob.)", mun_code: "021529" },
  // { label: "Centro 6 (Pob.)", mun_code: "021529" },
  // { label: "Centro 7 (Pob.)", mun_code: "021529" },
  // { label: "Centro 8 (Pob.)", mun_code: "021529" },
  // { label: "Centro 9 (Pob.)", mun_code: "021529" },
  // { label: "Centro 10 (Pob.)", mun_code: "021529" },
  // { label: "Centro 11 (Pob.)", mun_code: "021529" },
  // { label: "Buntun", mun_code: "021529" },
  // { label: "Caggay", mun_code: "021529" },
  // { label: "Capatan", mun_code: "021529" },
  // { label: "Carig", mun_code: "021529" },
  // { label: "Caritan Norte", mun_code: "021529" },
  // { label: "Caritan Sur", mun_code: "021529" },
  // { label: "Cataggaman Nuevo", mun_code: "021529" },
  // { label: "Cataggaman Viejo", mun_code: "021529" },
  // { label: "Gosi Norte", mun_code: "021529" },
  // { label: "Larion Alto", mun_code: "021529" },
  // { label: "Larion Bajo", mun_code: "021529" },
  // { label: "Libag Norte", mun_code: "021529" },
  // { label: "Linao East", mun_code: "021529" },
  // { label: "Nambbalan Norte", mun_code: "021529" },
  // { label: "Pallua Norte", mun_code: "021529" },
  // { label: "Pengue (Pengue-Ruyu)", mun_code: "021529" },
  // { label: "Tagga", mun_code: "021529" },
  // { label: "Tanza", mun_code: "021529" },
  // { label: "Ugac Norte", mun_code: "021529" },
  // { label: "Centro 2 (Pob.)", mun_code: "021529" },
  // { label: "Centro 3 (Pob.)", mun_code: "021529" },
  // { label: "Centro 12 (Pob.)", mun_code: "021529" },
  // { label: "Annafunan West", mun_code: "021529" },
  // { label: "Atulayan Sur", mun_code: "021529" },
  // { label: "Caritan Centro", mun_code: "021529" },
  // { label: "Cataggaman Pardo", mun_code: "021529" },
  // { label: "Dadda", mun_code: "021529" },
  // { label: "Gosi Sur", mun_code: "021529" },
  // { label: "Leonarda", mun_code: "021529" },
  // { label: "Libag Sur", mun_code: "021529" },
  // { label: "Linao Norte", mun_code: "021529" },
  // { label: "Linao West", mun_code: "021529" },
  // { label: "Nambbalan Sur", mun_code: "021529" },
  // { label: "Pallua Sur", mun_code: "021529" },
  // { label: "Reyes", mun_code: "021529" },
  // { label: "San Gabriel", mun_code: "021529" },
  // { label: "Ugac Sur", mun_code: "021529" },
  // { label: "Amistad", mun_code: "023101" },
  // { label: "Antonino (Pob.)", mun_code: "023101" },
  // { label: "Apanay", mun_code: "023101" },
  // { label: "Aurora", mun_code: "023101" },
  // { label: "Bagnos", mun_code: "023101" },
  // { label: "Bagong Sikat", mun_code: "023101" },
  // { label: "Bantug-Petines", mun_code: "023101" },
  // { label: "Bonifacio", mun_code: "023101" },
  // { label: "Burgos", mun_code: "023101" },
  // { label: "Calaocan (Pob.)", mun_code: "023101" },
  // { label: "Callao", mun_code: "023101" },
  // { label: "Dagupan", mun_code: "023101" },
  // { label: "Inanama", mun_code: "023101" },
  // { label: "Linglingay", mun_code: "023101" },
  // { label: "M.H. del Pilar", mun_code: "023101" },
  // { label: "Mabini", mun_code: "023101" },
  // { label: "Magsaysay (Pob.)", mun_code: "023101" },
  // { label: "Mataas na Kahoy", mun_code: "023101" },
  // { label: "Paddad", mun_code: "023101" },
  // { label: "Rizal", mun_code: "023101" },
  // { label: "Rizaluna", mun_code: "023101" },
  // { label: "Salvacion", mun_code: "023101" },
  // { label: "San Antonio (Pob.)", mun_code: "023101" },
  // { label: "San Fernando", mun_code: "023101" },
  // { label: "San Francisco", mun_code: "023101" },
  // { label: "San Juan", mun_code: "023101" },
  // { label: "San Pablo", mun_code: "023101" },
  // { label: "San Pedro", mun_code: "023101" },
  // { label: "Santa Cruz", mun_code: "023101" },
  // { label: "Santa Maria", mun_code: "023101" },
  // { label: "Santo Domingo", mun_code: "023101" },
  // { label: "Santo Tomas", mun_code: "023101" },
  // { label: "Victoria", mun_code: "023101" },
  // { label: "Zamora", mun_code: "023101" },
  // { label: "Allangigan", mun_code: "023102" },
  // { label: "Aniog", mun_code: "023102" },
  // { label: "Baniket", mun_code: "023102" },
  // { label: "Bannawag", mun_code: "023102" },
  // { label: "Bantug", mun_code: "023102" },
  // { label: "Barangcuag", mun_code: "023102" },
  // { label: "Baui", mun_code: "023102" },
  // { label: "Bonifacio", mun_code: "023102" },
  // { label: "Buenavista", mun_code: "023102" },
  // { label: "Bunnay", mun_code: "023102" },
  // { label: "Calabayan-Minanga", mun_code: "023102" },
  // { label: "Calaccab", mun_code: "023102" },
  // { label: "Calaocan", mun_code: "023102" },
  // { label: "Kalusutan", mun_code: "023102" },
  // { label: "Campanario", mun_code: "023102" },
  // { label: "Canangan", mun_code: "023102" },
  // { label: "Centro I (Pob.)", mun_code: "023102" },
  // { label: "Centro II (Pob.)", mun_code: "023102" },
  // { label: "Centro III (Pob.)", mun_code: "023102" },
  // { label: "Consular", mun_code: "023102" },
  // { label: "Cumu", mun_code: "023102" },
  // { label: "Dalakip", mun_code: "023102" },
  // { label: "Dalenat", mun_code: "023102" },
  // { label: "Dipaluda", mun_code: "023102" },
  // { label: "Duroc", mun_code: "023102" },
  // { label: "Esperanza", mun_code: "023102" },
  // { label: "Fugaru", mun_code: "023102" },
  // { label: "Liwliwa", mun_code: "023102" },
  // { label: "Ingud Norte", mun_code: "023102" },
  // { label: "Ingud Sur", mun_code: "023102" },
  // { label: "La Suerte", mun_code: "023102" },
  // { label: "Lomboy", mun_code: "023102" },
  // { label: "Loria", mun_code: "023102" },
  // { label: "Mabuhay", mun_code: "023102" },
  // { label: "Macalauat", mun_code: "023102" },
  // { label: "Macaniao", mun_code: "023102" },
  // { label: "Malannao", mun_code: "023102" },
  // { label: "Malasin", mun_code: "023102" },
  // { label: "Mangandingay", mun_code: "023102" },
  // { label: "Minanga Proper", mun_code: "023102" },
  // { label: "Pappat", mun_code: "023102" },
  // { label: "Pissay", mun_code: "023102" },
  // { label: "Ramona", mun_code: "023102" },
  // { label: "Rancho Bassit", mun_code: "023102" },
  // { label: "Rang-ayan", mun_code: "023102" },
  // { label: "Salay", mun_code: "023102" },
  // { label: "San Ambrocio", mun_code: "023102" },
  // { label: "San Guillermo", mun_code: "023102" },
  // { label: "San Isidro", mun_code: "023102" },
  // { label: "San Marcelo", mun_code: "023102" },
  // { label: "San Roque", mun_code: "023102" },
  // { label: "San Vicente", mun_code: "023102" },
  // { label: "Saranay", mun_code: "023102" },
  // { label: "Sinabbaran", mun_code: "023102" },
  // { label: "Victory", mun_code: "023102" },
  // { label: "Viga", mun_code: "023102" },
  // { label: "Villa Domingo", mun_code: "023102" },
  // { label: "Apiat", mun_code: "023103" },
  // { label: "Bagnos", mun_code: "023103" },
  // { label: "Bagong Tanza", mun_code: "023103" },
  // { label: "Ballesteros", mun_code: "023103" },
  // { label: "Bannagao", mun_code: "023103" },
  // { label: "Bannawag", mun_code: "023103" },
  // { label: "Bolinao", mun_code: "023103" },
  // { label: "Caipilan", mun_code: "023103" },
  // { label: "Camarunggayan", mun_code: "023103" },
  // { label: "Dalig-Kalinga", mun_code: "023103" },
  // { label: "Diamantina", mun_code: "023103" },
  // { label: "Divisoria", mun_code: "023103" },
  // { label: "Esperanza East", mun_code: "023103" },
  // { label: "Esperanza West", mun_code: "023103" },
  // { label: "Kalabaza", mun_code: "023103" },
  // { label: "Rizaluna (Lapuz)", mun_code: "023103" },
  // { label: "Macatal", mun_code: "023103" },
  // { label: "Malasin", mun_code: "023103" },
  // { label: "Nampicuan", mun_code: "023103" },
  // { label: "Villa Nuesa", mun_code: "023103" },
  // { label: "Panecien", mun_code: "023103" },
  // { label: "San Andres", mun_code: "023103" },
  // { label: "San Jose (Pob.)", mun_code: "023103" },
  // { label: "San Rafael", mun_code: "023103" },
  // { label: "San Ramon", mun_code: "023103" },
  // { label: "Santa Rita", mun_code: "023103" },
  // { label: "Santa Rosa", mun_code: "023103" },
  // { label: "Saranay", mun_code: "023103" },
  // { label: "Sili", mun_code: "023103" },
  // { label: "Victoria", mun_code: "023103" },
  // { label: "Villa Fugu", mun_code: "023103" },
  // { label: "San Juan (Pob.)", mun_code: "023103" },
  // { label: "San Pedro-San Pablo (Pob.)", mun_code: "023103" },
  // { label: "Andabuen", mun_code: "023104" },
  // { label: "Ara", mun_code: "023104" },
  // { label: "Binogtungan", mun_code: "023104" },
  // { label: "Capuseran (Capurocan)", mun_code: "023104" },
  // { label: "Dagupan", mun_code: "023104" },
  // { label: "Danipa", mun_code: "023104" },
  // { label: "District II (Pob.)", mun_code: "023104" },
  // { label: "Gomez", mun_code: "023104" },
  // { label: "Guilingan", mun_code: "023104" },
  // { label: "La Salette", mun_code: "023104" },
  // { label: "Makindol", mun_code: "023104" },
  // { label: "Maluno Norte", mun_code: "023104" },
  // { label: "Maluno Sur", mun_code: "023104" },
  // { label: "Nacalma", mun_code: "023104" },
  // { label: "New Magsaysay", mun_code: "023104" },
  // { label: "District I (Pob.)", mun_code: "023104" },
  // { label: "Punit", mun_code: "023104" },
  // { label: "San Carlos", mun_code: "023104" },
  // { label: "San Francisco", mun_code: "023104" },
  // { label: "Santa Cruz", mun_code: "023104" },
  // { label: "Sevillana", mun_code: "023104" },
  // { label: "Sinipit", mun_code: "023104" },
  // { label: "Lucban", mun_code: "023104" },
  // { label: "Villaluz", mun_code: "023104" },
  // { label: "Yeban Norte", mun_code: "023104" },
  // { label: "Yeban Sur", mun_code: "023104" },
  // { label: "Santiago", mun_code: "023104" },
  // { label: "Placer", mun_code: "023104" },
  // { label: "Balliao", mun_code: "023104" },
  // { label: "Bacnor East", mun_code: "023105" },
  // { label: "Bacnor West", mun_code: "023105" },
  // { label: "Caliguian (Pob.)", mun_code: "023105" },
  // { label: "Catabban", mun_code: "023105" },
  // { label: "Cullalabo Del Norte", mun_code: "023105" },
  // { label: "Cullalabo San Antonio", mun_code: "023105" },
  // { label: "Cullalabo Del Sur", mun_code: "023105" },
  // { label: "Dalig", mun_code: "023105" },
  // { label: "Malasin", mun_code: "023105" },
  // { label: "Masigun", mun_code: "023105" },
  // { label: "Raniag", mun_code: "023105" },
  // { label: "San Bonifacio", mun_code: "023105" },
  // { label: "San Miguel", mun_code: "023105" },
  // { label: "San Roque", mun_code: "023105" },
  // { label: "Aggub", mun_code: "023106" },
  // { label: "Anao", mun_code: "023106" },
  // { label: "Angancasilian", mun_code: "023106" },
  // { label: "Balasig", mun_code: "023106" },
  // { label: "Cansan", mun_code: "023106" },
  // { label: "Casibarag Norte", mun_code: "023106" },
  // { label: "Casibarag Sur", mun_code: "023106" },
  // { label: "Catabayungan", mun_code: "023106" },
  // { label: "Cubag", mun_code: "023106" },
  // { label: "Garita", mun_code: "023106" },
  // { label: "Luquilu", mun_code: "023106" },
  // { label: "Mabangug", mun_code: "023106" },
  // { label: "Magassi", mun_code: "023106" },
  // { label: "Ngarag", mun_code: "023106" },
  // { label: "Pilig Abajo", mun_code: "023106" },
  // { label: "Pilig Alto", mun_code: "023106" },
  // { label: "Centro (Pob.)", mun_code: "023106" },
  // { label: "San Bernardo", mun_code: "023106" },
  // { label: "San Juan", mun_code: "023106" },
  // { label: "Saui", mun_code: "023106" },
  // { label: "Tallag", mun_code: "023106" },
  // { label: "Ugad", mun_code: "023106" },
  // { label: "Union", mun_code: "023106" },
  // { label: "Masipi East", mun_code: "023106" },
  // { label: "Masipi West", mun_code: "023106" },
  // { label: "San Antonio", mun_code: "023106" },
  // { label: "Rang-ay (Caggong)", mun_code: "023107" },
  // { label: "Calaocan", mun_code: "023107" },
  // { label: "Canan", mun_code: "023107" },
  // { label: "Centro (Pob.)", mun_code: "023107" },
  // { label: "Culing Centro", mun_code: "023107" },
  // { label: "Culing East", mun_code: "023107" },
  // { label: "Culing West", mun_code: "023107" },
  // { label: "Del Corpuz", mun_code: "023107" },
  // { label: "Del Pilar", mun_code: "023107" },
  // { label: "Diamantina", mun_code: "023107" },
  // { label: "La Paz", mun_code: "023107" },
  // { label: "Luzon", mun_code: "023107" },
  // { label: "Macalaoat", mun_code: "023107" },
  // { label: "Magdalena", mun_code: "023107" },
  // { label: "Magsaysay", mun_code: "023107" },
  // { label: "Namnama", mun_code: "023107" },
  // { label: "Nueva Era", mun_code: "023107" },
  // { label: "Paraiso", mun_code: "023107" },
  // { label: "Sampaloc", mun_code: "023107" },
  // { label: "San Andres (Teodoro Abad)", mun_code: "023107" },
  // { label: "Saranay", mun_code: "023107" },
  // { label: "Tandul", mun_code: "023107" },
  // { label: "Alicaocao", mun_code: "023108" },
  // { label: "Alinam", mun_code: "023108" },
  // { label: "Amobocan", mun_code: "023108" },
  // { label: "Andarayan", mun_code: "023108" },
  // { label: "Baculod", mun_code: "023108" },
  // { label: "Baringin Norte", mun_code: "023108" },
  // { label: "Baringin Sur", mun_code: "023108" },
  // { label: "Buena Suerte", mun_code: "023108" },
  // { label: "Bugallon", mun_code: "023108" },
  // { label: "Buyon", mun_code: "023108" },
  // { label: "Cabaruan", mun_code: "023108" },
  // { label: "Cabugao", mun_code: "023108" },
  // { label: "Carabatan Chica", mun_code: "023108" },
  // { label: "Carabatan Grande", mun_code: "023108" },
  // { label: "Carabatan Punta", mun_code: "023108" },
  // { label: "Carabatan Bacareno", mun_code: "023108" },
  // { label: "Casalatan", mun_code: "023108" },
  // { label: "San Pablo (Casap Hacienda)", mun_code: "023108" },
  // { label: "Cassap Fuera", mun_code: "023108" },
  // { label: "Catalina", mun_code: "023108" },
  // { label: "Culalabat", mun_code: "023108" },
  // { label: "Dabburab", mun_code: "023108" },
  // { label: "De Vera", mun_code: "023108" },
  // { label: "Dianao", mun_code: "023108" },
  // { label: "Disimuray", mun_code: "023108" },
  // { label: "District I (Pob.)", mun_code: "023108" },
  // { label: "District II (Pob.)", mun_code: "023108" },
  // { label: "District III (Pob.)", mun_code: "023108" },
  // { label: "Duminit", mun_code: "023108" },
  // { label: "Faustino (Sipay)", mun_code: "023108" },
  // { label: "Gagabutan", mun_code: "023108" },
  // { label: "Gappal", mun_code: "023108" },
  // { label: "Guayabal", mun_code: "023108" },
  // { label: "Labinab", mun_code: "023108" },
  // { label: "Linglingay", mun_code: "023108" },
  // { label: "Mabantad", mun_code: "023108" },
  // { label: "Maligaya", mun_code: "023108" },
  // { label: "Manaoag", mun_code: "023108" },
  // { label: "Marabulig I", mun_code: "023108" },
  // { label: "Marabulig II", mun_code: "023108" },
  // { label: "Minante I", mun_code: "023108" },
  // { label: "Minante II", mun_code: "023108" },
  // { label: "Nagcampegan", mun_code: "023108" },
  // { label: "Naganacan", mun_code: "023108" },
  // { label: "Nagrumbuan", mun_code: "023108" },
  // { label: "Nungnungan I", mun_code: "023108" },
  // { label: "Nungnungan II", mun_code: "023108" },
  // { label: "Pinoma", mun_code: "023108" },
  // { label: "Rizal", mun_code: "023108" },
  // { label: "Rogus", mun_code: "023108" },
  // { label: "San Antonio", mun_code: "023108" },
  // { label: "San Fermin", mun_code: "023108" },
  // { label: "San Francisco", mun_code: "023108" },
  // { label: "San Isidro", mun_code: "023108" },
  // { label: "San Luis", mun_code: "023108" },
  // { label: "Santa Luciana (Daburab 2)", mun_code: "023108" },
  // { label: "Santa Maria", mun_code: "023108" },
  // { label: "Sillawit", mun_code: "023108" },
  // { label: "Sinippil", mun_code: "023108" },
  // { label: "Tagaran", mun_code: "023108" },
  // { label: "Turayong", mun_code: "023108" },
  // { label: "Union", mun_code: "023108" },
  // { label: "Villa Concepcion", mun_code: "023108" },
  // { label: "Villa Luna", mun_code: "023108" },
  // { label: "Villaflor", mun_code: "023108" },
  // { label: "Aguinaldo (Rizaluna Este)", mun_code: "023109" },
  // { label: "Calimaturod", mun_code: "023109" },
  // { label: "Capirpiriwan", mun_code: "023109" },
  // { label: "Caquilingan (San Luis)", mun_code: "023109" },
  // { label: "Dallao", mun_code: "023109" },
  // { label: "Gayong", mun_code: "023109" },
  // { label: "Laurel (Centro Norte)", mun_code: "023109" },
  // { label: "Magsaysay (Centro Sur Oeste)", mun_code: "023109" },
  // { label: "Malapat", mun_code: "023109" },
  // { label: "Osmena (Centro Sur Este)", mun_code: "023109" },
  // { label: "Quezon (Centro Norte Este)", mun_code: "023109" },
  // { label: "Quirino (Manasin)", mun_code: "023109" },
  // { label: "Rizaluna (Rizaluna Oeste)", mun_code: "023109" },
  // { label: "Roxas Pob. (Centro Sur)", mun_code: "023109" },
  // { label: "Sagat", mun_code: "023109" },
  // { label: "San Juan (San Juan Este)", mun_code: "023109" },
  // { label: "Taliktik", mun_code: "023109" },
  // { label: "Tanggal", mun_code: "023109" },
  // { label: "Tarinsing", mun_code: "023109" },
  // { label: "Turod Norte", mun_code: "023109" },
  // { label: "Turod Sur", mun_code: "023109" },
  // { label: "Villamiemban", mun_code: "023109" },
  // { label: "Villamarzo", mun_code: "023109" },
  // { label: "Anonang (Balitoc*)", mun_code: "023109" },
  // { label: "Camarao", mun_code: "023109" },
  // { label: "Wigan", mun_code: "023109" },
  // { label: "Ayod", mun_code: "023110" },
  // { label: "Bucal Sur", mun_code: "023110" },
  // { label: "Bucal Norte", mun_code: "023110" },
  // { label: "Dibulo", mun_code: "023110" },
  // { label: "Digumased (Pob.)", mun_code: "023110" },
  // { label: "Dimaluade", mun_code: "023110" },
  // { label: "Dicambangan", mun_code: "023111" },
  // { label: "Dicaroyan", mun_code: "023111" },
  // { label: "Dicatian", mun_code: "023111" },
  // { label: "Bicobian", mun_code: "023111" },
  // { label: "Dilakit", mun_code: "023111" },
  // { label: "Dimapnat", mun_code: "023111" },
  // { label: "Dimapula (Pob.)", mun_code: "023111" },
  // { label: "Dimasalansan", mun_code: "023111" },
  // { label: "Dipudo", mun_code: "023111" },
  // { label: "Dibulos", mun_code: "023111" },
  // { label: "Ditarum", mun_code: "023111" },
  // { label: "Sapinit", mun_code: "023111" },
  // { label: "Angoluan", mun_code: "023112" },
  // { label: "Annafunan", mun_code: "023112" },
  // { label: "Arabiat", mun_code: "023112" },
  // { label: "Aromin", mun_code: "023112" },
  // { label: "Babaran", mun_code: "023112" },
  // { label: "Bacradal", mun_code: "023112" },
  // { label: "Benguet", mun_code: "023112" },
  // { label: "Buneg", mun_code: "023112" },
  // { label: "Busilelao", mun_code: "023112" },
  // { label: "Caniguing", mun_code: "023112" },
  // { label: "Carulay", mun_code: "023112" },
  // { label: "Castillo", mun_code: "023112" },
  // { label: "Dammang East", mun_code: "023112" },
  // { label: "Dammang West", mun_code: "023112" },
  // { label: "Dicaraoyan", mun_code: "023112" },
  // { label: "Dugayong", mun_code: "023112" },
  // { label: "Fugu", mun_code: "023112" },
  // { label: "Garit Norte", mun_code: "023112" },
  // { label: "Garit Sur", mun_code: "023112" },
  // { label: "Gucab", mun_code: "023112" },
  // { label: "Gumbauan", mun_code: "023112" },
  // { label: "Ipil", mun_code: "023112" },
  // { label: "Libertad", mun_code: "023112" },
  // { label: "Mabbayad", mun_code: "023112" },
  // { label: "Mabuhay", mun_code: "023112" },
  // { label: "Madadamian", mun_code: "023112" },
  // { label: "Magleticia", mun_code: "023112" },
  // { label: "Malibago", mun_code: "023112" },
  // { label: "Maligaya", mun_code: "023112" },
  // { label: "Malitao", mun_code: "023112" },
  // { label: "Narra", mun_code: "023112" },
  // { label: "Nilumisu", mun_code: "023112" },
  // { label: "Pag-asa", mun_code: "023112" },
  // { label: "Pangal Norte", mun_code: "023112" },
  // { label: "Pangal Sur", mun_code: "023112" },
  // { label: "Rumang-ay", mun_code: "023112" },
  // { label: "Salay", mun_code: "023112" },
  // { label: "Salvacion", mun_code: "023112" },
  // { label: "San Antonio Ugad", mun_code: "023112" },
  // { label: "San Antonio Minit", mun_code: "023112" },
  // { label: "San Carlos", mun_code: "023112" },
  // { label: "San Fabian", mun_code: "023112" },
  // { label: "San Felipe", mun_code: "023112" },
  // { label: "San Juan", mun_code: "023112" },
  // { label: "San Manuel", mun_code: "023112" },
  // { label: "San Miguel", mun_code: "023112" },
  // { label: "San Salvador", mun_code: "023112" },
  // { label: "Santa Ana", mun_code: "023112" },
  // { label: "Santa Cruz", mun_code: "023112" },
  // { label: "Santa Maria", mun_code: "023112" },
  // { label: "Santa Monica", mun_code: "023112" },
  // { label: "Santo Domingo", mun_code: "023112" },
  // { label: "Silauan Sur (Pob.)", mun_code: "023112" },
  // { label: "Silauan Norte (Pob.)", mun_code: "023112" },
  // { label: "Sinabbaran", mun_code: "023112" },
  // { label: "Soyung", mun_code: "023112" },
  // { label: "Taggappan", mun_code: "023112" },
  // { label: "Tuguegarao", mun_code: "023112" },
  // { label: "Villa Campo", mun_code: "023112" },
  // { label: "Villa Fermin", mun_code: "023112" },
  // { label: "Villa Rey", mun_code: "023112" },
  // { label: "Villa Victoria", mun_code: "023112" },
  // { label: "Cabugao (Pob.)", mun_code: "023112" },
  // { label: "Diasan", mun_code: "023112" },
  // { label: "Barcolan", mun_code: "023113" },
  // { label: "Buenavista", mun_code: "023113" },
  // { label: "Dammao", mun_code: "023113" },
  // { label: "Furao", mun_code: "023113" },
  // { label: "Guibang", mun_code: "023113" },
  // { label: "Lenzon", mun_code: "023113" },
  // { label: "Linglingay", mun_code: "023113" },
  // { label: "Mabini", mun_code: "023113" },
  // { label: "Pintor", mun_code: "023113" },
  // { label: "District I (Pob.)", mun_code: "023113" },
  // { label: "District II (Pob.)", mun_code: "023113" },
  // { label: "District III (Pob.)", mun_code: "023113" },
  // { label: "Rizal", mun_code: "023113" },
  // { label: "Songsong", mun_code: "023113" },
  // { label: "Union", mun_code: "023113" },
  // { label: "Upi", mun_code: "023113" },
  // { label: "Cabeseria 27 (Abuan)", mun_code: "023114" },
  // { label: "Aggasian", mun_code: "023114" },
  // { label: "Alibagu", mun_code: "023114" },
  // { label: "Allinguigan 1st", mun_code: "023114" },
  // { label: "Allinguigan 2nd", mun_code: "023114" },
  // { label: "Allinguigan 3rd", mun_code: "023114" },
  // { label: "Arusip", mun_code: "023114" },
  // { label: "Baculod (Pob.)", mun_code: "023114" },
  // { label: "Bagumbayan (Pob.)", mun_code: "023114" },
  // { label: "Baligatan", mun_code: "023114" },
  // { label: "Ballacong", mun_code: "023114" },
  // { label: "Bangag", mun_code: "023114" },
  // { label: "Cabeseria 5 (Baribad)", mun_code: "023114" },
  // { label: "Batong-Labang", mun_code: "023114" },
  // { label: "Bigao", mun_code: "023114" },
  // { label: "Cabeseria 4 (San Manuel)", mun_code: "023114" },
  // { label: "Cabannungan 1st", mun_code: "023114" },
  // { label: "Cabannungan 2nd", mun_code: "023114" },
  // { label: "Cabeseria 6 & 24 (Villa Marcos*)", mun_code: "023114" },
  // { label: "Cabeseria 19 (Villa Suerte*)", mun_code: "023114" },
  // { label: "Cabeseria 25 (Sta. Lucia*)", mun_code: "023114" },
  // { label: "Cabeseria 3 (San Fernando)", mun_code: "023114" },
  // { label: "Cabeseria 23 (San Francisco*)", mun_code: "023114" },
  // { label: "Cadu", mun_code: "023114" },
  // { label: "Calamagui 1st", mun_code: "023114" },
  // { label: "Calamagui 2nd", mun_code: "023114" },
  // { label: "Camunatan", mun_code: "023114" },
  // { label: "Capellan", mun_code: "023114" },
  // { label: "Capo", mun_code: "023114" },
  // { label: "Cabeseria 9 and 11 (Capogotan)", mun_code: "023114" },
  // { label: "Carikkikan Norte", mun_code: "023114" },
  // { label: "Carikkikan Sur", mun_code: "023114" },
  // { label: "Cabeseria 14 and 16 (Casilagan)", mun_code: "023114" },
  // { label: "Cabeseria 2 (Dappat)", mun_code: "023114" },
  // { label: "Fugu", mun_code: "023114" },
  // { label: "Fuyo", mun_code: "023114" },
  // { label: "Gayong-Gayong Norte", mun_code: "023114" },
  // { label: "Gayong-Gayong Sur", mun_code: "023114" },
  // { label: "Guinatan", mun_code: "023114" },
  // { label: "Lullutan", mun_code: "023114" },
  // { label: "Cabeseria 10 (Lapigui)", mun_code: "023114" },
  // { label: "Malalam", mun_code: "023114" },
  // { label: "Malasin (Angeles)", mun_code: "023114" },
  // { label: "Manaring", mun_code: "023114" },
  // { label: "Mangcuram", mun_code: "023114" },
  // { label: "Villa Imelda (Maplas)", mun_code: "023114" },
  // { label: "Marana I", mun_code: "023114" },
  // { label: "Marana II", mun_code: "023114" },
  // { label: "Marana III", mun_code: "023114" },
  // { label: "Minabang", mun_code: "023114" },
  // { label: "Morado", mun_code: "023114" },
  // { label: "Naguilian Norte", mun_code: "023114" },
  // { label: "Naguilian Sur", mun_code: "023114" },
  // { label: "Namnama", mun_code: "023114" },
  // { label: "Nanaguan", mun_code: "023114" },
  // { label: "Cabeseria 7 (Nangalisan)", mun_code: "023114" },
  // { label: "Paliueg", mun_code: "023114" },
  // { label: "Pasa", mun_code: "023114" },
  // { label: "Pilar", mun_code: "023114" },
  // { label: "Quimalabasa", mun_code: "023114" },
  // { label: "Rang-ayan (Bintacan)", mun_code: "023114" },
  // { label: "Rugao", mun_code: "023114" },
  // { label: "Cabeseria 22 (Sablang)", mun_code: "023114" },
  // { label: "Salindingan", mun_code: "023114" },
  // { label: "San Andres (Angarilla)", mun_code: "023114" },
  // { label: "Centro - San Antonio", mun_code: "023114" },
  // { label: "San Felipe", mun_code: "023114" },
  // { label: "San Ignacio (Canapi)", mun_code: "023114" },
  // { label: "San Isidro", mun_code: "023114" },
  // { label: "San Juan", mun_code: "023114" },
  // { label: "San Lorenzo", mun_code: "023114" },
  // { label: "San Pablo", mun_code: "023114" },
  // { label: "Cabeseria 17 and 21 (San Rafael)", mun_code: "023114" },
  // { label: "San Vicente (Pob.)", mun_code: "023114" },
  // { label: "Santa Barbara (Pob.)", mun_code: "023114" },
  // { label: "Santa Catalina", mun_code: "023114" },
  // { label: "Santa Isabel Norte", mun_code: "023114" },
  // { label: "Santa Isabel Sur", mun_code: "023114" },
  // { label: "Santa Victoria", mun_code: "023114" },
  // { label: "Santo Tomas", mun_code: "023114" },
  // { label: "Siffu", mun_code: "023114" },
  // { label: "Sindon Bayabo", mun_code: "023114" },
  // { label: "Sindon Maride", mun_code: "023114" },
  // { label: "Sipay", mun_code: "023114" },
  // { label: "Tangcul", mun_code: "023114" },
  // { label: "Centro Poblacion", mun_code: "023114" },
  // { label: "Bagong Silang", mun_code: "023114" },
  // { label: "Imelda Bliss Village", mun_code: "023114" },
  // { label: "San Rodrigo", mun_code: "023114" },
  // { label: "Santa Maria (Cabeseria 8)", mun_code: "023114" },
  // { label: "Abulan", mun_code: "023115" },
  // { label: "Addalam", mun_code: "023115" },
  // { label: "Arubub", mun_code: "023115" },
  // { label: "Bannawag", mun_code: "023115" },
  // { label: "Bantay", mun_code: "023115" },
  // { label: "Barangay I (Pob.)", mun_code: "023115" },
  // { label: "Barangay II (Pob.)", mun_code: "023115" },
  // { label: "Barangcuag", mun_code: "023115" },
  // { label: "Dalibubon", mun_code: "023115" },
  // { label: "Daligan", mun_code: "023115" },
  // { label: "Diarao", mun_code: "023115" },
  // { label: "Dibuluan", mun_code: "023115" },
  // { label: "Dicamay I", mun_code: "023115" },
  // { label: "Dicamay II", mun_code: "023115" },
  // { label: "Dipangit", mun_code: "023115" },
  // { label: "Disimpit", mun_code: "023115" },
  // { label: "Divinan", mun_code: "023115" },
  // { label: "Dumawing", mun_code: "023115" },
  // { label: "Fugu", mun_code: "023115" },
  // { label: "Lacab", mun_code: "023115" },
  // { label: "Linamanan", mun_code: "023115" },
  // { label: "Linomot", mun_code: "023115" },
  // { label: "Malannit", mun_code: "023115" },
  // { label: "Minuri", mun_code: "023115" },
  // { label: "Namnama", mun_code: "023115" },
  // { label: "Napaliong", mun_code: "023115" },
  // { label: "Palagao", mun_code: "023115" },
  // { label: "Papan Este", mun_code: "023115" },
  // { label: "Papan Weste", mun_code: "023115" },
  // { label: "Payac", mun_code: "023115" },
  // { label: "Pongpongan", mun_code: "023115" },
  // { label: "San Antonio", mun_code: "023115" },
  // { label: "San Isidro", mun_code: "023115" },
  // { label: "San Jose", mun_code: "023115" },
  // { label: "San Roque", mun_code: "023115" },
  // { label: "San Sebastian", mun_code: "023115" },
  // { label: "San Vicente", mun_code: "023115" },
  // { label: "Santa Isabel", mun_code: "023115" },
  // { label: "Santo Domingo", mun_code: "023115" },
  // { label: "Tupax", mun_code: "023115" },
  // { label: "Usol", mun_code: "023115" },
  // { label: "Villa Bello", mun_code: "023115" },
  // { label: "Bustamante", mun_code: "023116" },
  // { label: "Centro 1 (Pob.)", mun_code: "023116" },
  // { label: "Centro 2 (Pob.)", mun_code: "023116" },
  // { label: "Centro 3 (Pob.)", mun_code: "023116" },
  // { label: "Concepcion", mun_code: "023116" },
  // { label: "Dadap", mun_code: "023116" },
  // { label: "Harana", mun_code: "023116" },
  // { label: "Lalog 1", mun_code: "023116" },
  // { label: "Lalog 2", mun_code: "023116" },
  // { label: "Luyao", mun_code: "023116" },
  // { label: "Macugay", mun_code: "023116" },
  // { label: "Mambabanga", mun_code: "023116" },
  // { label: "Pulay", mun_code: "023116" },
  // { label: "Puroc", mun_code: "023116" },
  // { label: "San Isidro", mun_code: "023116" },
  // { label: "San Miguel", mun_code: "023116" },
  // { label: "Santo Domingo", mun_code: "023116" },
  // { label: "Union Kalinga", mun_code: "023116" },
  // { label: "Diana", mun_code: "023117" },
  // { label: "Eleonor (Pob.)", mun_code: "023117" },
  // { label: "Fely (Pob.)", mun_code: "023117" },
  // { label: "Lita (Pob.)", mun_code: "023117" },
  // { label: "Reina Mercedes", mun_code: "023117" },
  // { label: "Minanga", mun_code: "023117" },
  // { label: "Malasin", mun_code: "023117" },
  // { label: "Canadam", mun_code: "023117" },
  // { label: "Aplaya", mun_code: "023117" },
  // { label: "Santa Marina (Dianggo)", mun_code: "023117" },
  // { label: "Aga", mun_code: "023118" },
  // { label: "Andarayan", mun_code: "023118" },
  // { label: "Aneg", mun_code: "023118" },
  // { label: "Bayabo", mun_code: "023118" },
  // { label: "Calinaoan Sur", mun_code: "023118" },
  // { label: "Capitol", mun_code: "023118" },
  // { label: "Carmencita", mun_code: "023118" },
  // { label: "Concepcion", mun_code: "023118" },
  // { label: "Maui", mun_code: "023118" },
  // { label: "Quibal", mun_code: "023118" },
  // { label: "Ragan Almacen", mun_code: "023118" },
  // { label: "Ragan Norte", mun_code: "023118" },
  // { label: "Ragan Sur (Pob.)", mun_code: "023118" },
  // { label: "Rizal (Ragan Almacen Alto)", mun_code: "023118" },
  // { label: "San Andres", mun_code: "023118" },
  // { label: "San Antonio", mun_code: "023118" },
  // { label: "San Isidro", mun_code: "023118" },
  // { label: "San Jose", mun_code: "023118" },
  // { label: "San Juan", mun_code: "023118" },
  // { label: "San Macario", mun_code: "023118" },
  // { label: "San Nicolas (Fusi)", mun_code: "023118" },
  // { label: "San Patricio", mun_code: "023118" },
  // { label: "San Roque", mun_code: "023118" },
  // { label: "Santo Rosario", mun_code: "023118" },
  // { label: "Santor", mun_code: "023118" },
  // { label: "Villa Luz", mun_code: "023118" },
  // { label: "Villa Pereda", mun_code: "023118" },
  // { label: "Visitacion", mun_code: "023118" },
  // { label: "Caloocan", mun_code: "023118" },
  // { label: "San Pedro (Barucbuc Sur)", mun_code: "023119" },
  // { label: "Binmonton", mun_code: "023119" },
  // { label: "Casili", mun_code: "023119" },
  // { label: "Centro I (Pob.)", mun_code: "023119" },
  // { label: "Holy Friday", mun_code: "023119" },
  // { label: "Maligaya", mun_code: "023119" },
  // { label: "Manano", mun_code: "023119" },
  // { label: "Olango", mun_code: "023119" },
  // { label: "Centro II (Pob.)", mun_code: "023119" },
  // { label: "Rang-ayan", mun_code: "023119" },
  // { label: "San Jose Norte I", mun_code: "023119" },
  // { label: "San Jose Sur", mun_code: "023119" },
  // { label: "Siempre Viva Norte", mun_code: "023119" },
  // { label: "Trinidad", mun_code: "023119" },
  // { label: "Victoria", mun_code: "023119" },
  // { label: "San Jose Norte II", mun_code: "023119" },
  // { label: "San Ramon", mun_code: "023119" },
  // { label: "Siempre Viva Sur", mun_code: "023119" },
  // { label: "Aguinaldo", mun_code: "023120" },
  // { label: "Bagong Sikat", mun_code: "023120" },
  // { label: "Burgos", mun_code: "023120" },
  // { label: "Cabaruan", mun_code: "023120" },
  // { label: "Flores", mun_code: "023120" },
  // { label: "La Union", mun_code: "023120" },
  // { label: "Magsaysay (Pob.)", mun_code: "023120" },
  // { label: "Manaring", mun_code: "023120" },
  // { label: "Mansibang", mun_code: "023120" },
  // { label: "Minallo", mun_code: "023120" },
  // { label: "Minanga", mun_code: "023120" },
  // { label: "Palattao", mun_code: "023120" },
  // { label: "Quezon (Pob.)", mun_code: "023120" },
  // { label: "Quinalabasa", mun_code: "023120" },
  // { label: "Quirino (Pob.)", mun_code: "023120" },
  // { label: "Rangayan", mun_code: "023120" },
  // { label: "Rizal", mun_code: "023120" },
  // { label: "Roxas (Pob.)", mun_code: "023120" },
  // { label: "San Manuel", mun_code: "023120" },
  // { label: "Santo Tomas", mun_code: "023120" },
  // { label: "Sunlife", mun_code: "023120" },
  // { label: "Surcoc", mun_code: "023120" },
  // { label: "Tomines", mun_code: "023120" },
  // { label: "Villa Paz", mun_code: "023120" },
  // { label: "Santa Victoria (Villa Capuchino)", mun_code: "023120" },
  // { label: "Bisag", mun_code: "023121" },
  // { label: "Dialaoyao", mun_code: "023121" },
  // { label: "Dicadyuan", mun_code: "023121" },
  // { label: "Didiyan", mun_code: "023121" },
  // { label: "Dimalicu-licu", mun_code: "023121" },
  // { label: "Dimasari", mun_code: "023121" },
  // { label: "Dimatican", mun_code: "023121" },
  // { label: "Maligaya", mun_code: "023121" },
  // { label: "Marikit", mun_code: "023121" },
  // { label: "Dicabisagan East (Pob.)", mun_code: "023121" },
  // { label: "Dicabisagan West (Pob.)", mun_code: "023121" },
  // { label: "Santa Jacinta", mun_code: "023121" },
  // { label: "Villa Robles", mun_code: "023121" },
  // { label: "Culasi", mun_code: "023121" },
  // { label: "Alomanay", mun_code: "023121" },
  // { label: "Diddadungan", mun_code: "023121" },
  // { label: "San Isidro", mun_code: "023121" },
  // { label: "Abut", mun_code: "023122" },
  // { label: "Alunan (Pob.)", mun_code: "023122" },
  // { label: "Arellano (Pob.)", mun_code: "023122" },
  // { label: "Aurora", mun_code: "023122" },
  // { label: "Barucboc Norte", mun_code: "023122" },
  // { label: "Estrada", mun_code: "023122" },
  // { label: "Santos (Pob.)", mun_code: "023122" },
  // { label: "Lepanto", mun_code: "023122" },
  // { label: "Mangga", mun_code: "023122" },
  // { label: "Minagbag", mun_code: "023122" },
  // { label: "Samonte (Pob.)", mun_code: "023122" },
  // { label: "Turod", mun_code: "023122" },
  // { label: "Dunmon", mun_code: "023122" },
  // { label: "Calangigan (Calamagui)", mun_code: "023122" },
  // { label: "San Juan", mun_code: "023122" },
  // { label: "Binarzang", mun_code: "023123" },
  // { label: "Cabaruan", mun_code: "023123" },
  // { label: "Camaal", mun_code: "023123" },
  // { label: "Dolores", mun_code: "023123" },
  // { label: "Luna", mun_code: "023123" },
  // { label: "Manaoag", mun_code: "023123" },
  // { label: "Rizal", mun_code: "023123" },
  // { label: "San Isidro", mun_code: "023123" },
  // { label: "San Jose", mun_code: "023123" },
  // { label: "San Juan", mun_code: "023123" },
  // { label: "San Mateo", mun_code: "023123" },
  // { label: "San Vicente", mun_code: "023123" },
  // { label: "Santa Catalina", mun_code: "023123" },
  // { label: "Santa Lucia (Pob.)", mun_code: "023123" },
  // { label: "Santiago", mun_code: "023123" },
  // { label: "Santo Domingo", mun_code: "023123" },
  // { label: "Sinait", mun_code: "023123" },
  // { label: "Suerte", mun_code: "023123" },
  // { label: "Villa Bulusan", mun_code: "023123" },
  // { label: "Villa Miguel (Ugak)", mun_code: "023123" },
  // { label: "Vintar", mun_code: "023123" },
  // { label: "Ambatali", mun_code: "023124" },
  // { label: "Bantug", mun_code: "023124" },
  // { label: "Bugallon Norte", mun_code: "023124" },
  // { label: "Burgos", mun_code: "023124" },
  // { label: "Nagbacalan", mun_code: "023124" },
  // { label: "Oscariz", mun_code: "023124" },
  // { label: "Pabil", mun_code: "023124" },
  // { label: "Pagrang-ayan", mun_code: "023124" },
  // { label: "Planas", mun_code: "023124" },
  // { label: "Purok ni Bulan", mun_code: "023124" },
  // { label: "Raniag", mun_code: "023124" },
  // { label: "San Antonio", mun_code: "023124" },
  // { label: "San Miguel", mun_code: "023124" },
  // { label: "San Sebastian", mun_code: "023124" },
  // { label: "Villa Beltran", mun_code: "023124" },
  // { label: "Villa Carmen", mun_code: "023124" },
  // { label: "Villa Marcos", mun_code: "023124" },
  // { label: "General Aguinaldo", mun_code: "023124" },
  // { label: "Bugallon Proper (Pob.)", mun_code: "023124" },
  // { label: "Banquero", mun_code: "023125" },
  // { label: "Binarsang", mun_code: "023125" },
  // { label: "Cutog Grande", mun_code: "023125" },
  // { label: "Dangan", mun_code: "023125" },
  // { label: "District I (Pob.)", mun_code: "023125" },
  // { label: "District II (Pob.)", mun_code: "023125" },
  // { label: "Labinab Grande (Pob.)", mun_code: "023125" },
  // { label: "Mallalatang Grande", mun_code: "023125" },
  // { label: "Mallalatang Tunggui", mun_code: "023125" },
  // { label: "Napaccu Grande", mun_code: "023125" },
  // { label: "Salucong", mun_code: "023125" },
  // { label: "Santor", mun_code: "023125" },
  // { label: "Sinippil", mun_code: "023125" },
  // { label: "Tallungan (Pob.)", mun_code: "023125" },
  // { label: "Turod", mun_code: "023125" },
  // { label: "Villador", mun_code: "023125" },
  // { label: "Santiago", mun_code: "023125" },
  // { label: "Anao", mun_code: "023126" },
  // { label: "Imbiao", mun_code: "023126" },
  // { label: "Lanting", mun_code: "023126" },
  // { label: "Lucban", mun_code: "023126" },
  // { label: "Marcos", mun_code: "023126" },
  // { label: "Masigun", mun_code: "023126" },
  // { label: "Rizal (Pob.)", mun_code: "023126" },
  // { label: "Vira (Pob.)", mun_code: "023126" },
  // { label: "Bantug (Pob.)", mun_code: "023126" },
  // { label: "Luna (Pob.)", mun_code: "023126" },
  // { label: "Quiling", mun_code: "023126" },
  // { label: "Rang-ayan", mun_code: "023126" },
  // { label: "San Antonio", mun_code: "023126" },
  // { label: "San Jose", mun_code: "023126" },
  // { label: "San Pedro", mun_code: "023126" },
  // { label: "San Placido", mun_code: "023126" },
  // { label: "San Rafael", mun_code: "023126" },
  // { label: "Simimbaan", mun_code: "023126" },
  // { label: "Sinamar", mun_code: "023126" },
  // { label: "Sotero Nuesa", mun_code: "023126" },
  // { label: "Villa Concepcion", mun_code: "023126" },
  // { label: "Matusalem", mun_code: "023126" },
  // { label: "San Luis", mun_code: "023126" },
  // { label: "Bautista", mun_code: "023127" },
  // { label: "Calaocan", mun_code: "023127" },
  // { label: "Dabubu Grande", mun_code: "023127" },
  // { label: "Dappig", mun_code: "023127" },
  // { label: "Laoag", mun_code: "023127" },
  // { label: "Mapalad", mun_code: "023127" },
  // { label: "Masaya Centro (Pob.)", mun_code: "023127" },
  // { label: "Masaya Norte", mun_code: "023127" },
  // { label: "Masaya Sur", mun_code: "023127" },
  // { label: "Nemmatan", mun_code: "023127" },
  // { label: "Palacian", mun_code: "023127" },
  // { label: "Panang", mun_code: "023127" },
  // { label: "Quimalabasa Norte", mun_code: "023127" },
  // { label: "Quimalabasa Sur", mun_code: "023127" },
  // { label: "Rang-ay", mun_code: "023127" },
  // { label: "Salay", mun_code: "023127" },
  // { label: "San Antonio", mun_code: "023127" },
  // { label: "Santos", mun_code: "023127" },
  // { label: "Sinaoangan Norte", mun_code: "023127" },
  // { label: "Sinaoangan Sur", mun_code: "023127" },
  // { label: "Virgoneza", mun_code: "023127" },
  // { label: "Anonang", mun_code: "023128" },
  // { label: "Aringay", mun_code: "023128" },
  // { label: "Centro 1 (Pob.)", mun_code: "023128" },
  // { label: "Centro 2 (Pob.)", mun_code: "023128" },
  // { label: "Colorado", mun_code: "023128" },
  // { label: "Dietban", mun_code: "023128" },
  // { label: "Dingading", mun_code: "023128" },
  // { label: "Dipacamo", mun_code: "023128" },
  // { label: "Estrella", mun_code: "023128" },
  // { label: "Guam", mun_code: "023128" },
  // { label: "Nakar", mun_code: "023128" },
  // { label: "Palawan", mun_code: "023128" },
  // { label: "Progreso", mun_code: "023128" },
  // { label: "Rizal", mun_code: "023128" },
  // { label: "San Francisco Sur", mun_code: "023128" },
  // { label: "San Mariano Norte", mun_code: "023128" },
  // { label: "San Mariano Sur", mun_code: "023128" },
  // { label: "Sinalugan", mun_code: "023128" },
  // { label: "Villa Remedios", mun_code: "023128" },
  // { label: "Villa Rose", mun_code: "023128" },
  // { label: "Villa Sanchez", mun_code: "023128" },
  // { label: "Villa Teresita", mun_code: "023128" },
  // { label: "Burgos", mun_code: "023128" },
  // { label: "San Francisco Norte", mun_code: "023128" },
  // { label: "Calaoagan", mun_code: "023128" },
  // { label: "San Rafael", mun_code: "023128" },
  // { label: "Camarag", mun_code: "023129" },
  // { label: "Cebu", mun_code: "023129" },
  // { label: "Gomez", mun_code: "023129" },
  // { label: "Gud", mun_code: "023129" },
  // { label: "Nagbukel", mun_code: "023129" },
  // { label: "Patanad", mun_code: "023129" },
  // { label: "Quezon", mun_code: "023129" },
  // { label: "Ramos East", mun_code: "023129" },
  // { label: "Ramos West", mun_code: "023129" },
  // { label: "Rizal East (Pob.)", mun_code: "023129" },
  // { label: "Rizal West (Pob.)", mun_code: "023129" },
  // { label: "Victoria", mun_code: "023129" },
  // { label: "Villaflor", mun_code: "023129" },
  // { label: "Agliam", mun_code: "023130" },
  // { label: "Babanuang", mun_code: "023130" },
  // { label: "Cabaritan", mun_code: "023130" },
  // { label: "Caraniogan", mun_code: "023130" },
  // { label: "Eden", mun_code: "023130" },
  // { label: "Malalinta", mun_code: "023130" },
  // { label: "Mararigue", mun_code: "023130" },
  // { label: "Nueva Era", mun_code: "023130" },
  // { label: "Pisang", mun_code: "023130" },
  // { label: "District 1 (Pob.)", mun_code: "023130" },
  // { label: "District 2 (Pob.)", mun_code: "023130" },
  // { label: "District 3 (Pob.)", mun_code: "023130" },
  // { label: "District 4 (Pob.)", mun_code: "023130" },
  // { label: "San Francisco", mun_code: "023130" },
  // { label: "Sandiat Centro", mun_code: "023130" },
  // { label: "Sandiat East", mun_code: "023130" },
  // { label: "Sandiat West", mun_code: "023130" },
  // { label: "Santa Cruz", mun_code: "023130" },
  // { label: "Villanueva", mun_code: "023130" },
  // { label: "Alibadabad", mun_code: "023131" },
  // { label: "Binatug", mun_code: "023131" },
  // { label: "Bitabian", mun_code: "023131" },
  // { label: "Buyasan", mun_code: "023131" },
  // { label: "Cadsalan", mun_code: "023131" },
  // { label: "Casala", mun_code: "023131" },
  // { label: "Cataguing", mun_code: "023131" },
  // { label: "Daragutan East", mun_code: "023131" },
  // { label: "Daragutan West", mun_code: "023131" },
  // { label: "Del Pilar", mun_code: "023131" },
  // { label: "Dibuluan", mun_code: "023131" },
  // { label: "Dicamay", mun_code: "023131" },
  // { label: "Dipusu", mun_code: "023131" },
  // { label: "Disulap", mun_code: "023131" },
  // { label: "Disusuan", mun_code: "023131" },
  // { label: "Gangalan", mun_code: "023131" },
  // { label: "Ibujan", mun_code: "023131" },
  // { label: "Libertad", mun_code: "023131" },
  // { label: "Macayucayu", mun_code: "023131" },
  // { label: "Mallabo", mun_code: "023131" },
  // { label: "Marannao", mun_code: "023131" },
  // { label: "Minanga", mun_code: "023131" },
  // { label: "Old San Mariano", mun_code: "023131" },
  // { label: "Palutan", mun_code: "023131" },
  // { label: "Panninan", mun_code: "023131" },
  // { label: "Zone I (Pob.)", mun_code: "023131" },
  // { label: "Zone II (Pob.)", mun_code: "023131" },
  // { label: "Zone III (Pob.)", mun_code: "023131" },
  // { label: "San Jose", mun_code: "023131" },
  // { label: "San Pablo", mun_code: "023131" },
  // { label: "San Pedro", mun_code: "023131" },
  // { label: "Santa Filomina", mun_code: "023131" },
  // { label: "Tappa", mun_code: "023131" },
  // { label: "Ueg", mun_code: "023131" },
  // { label: "Zamora", mun_code: "023131" },
  // { label: "Balagan", mun_code: "023131" },
  // { label: "Bagong Sikat", mun_code: "023132" },
  // { label: "Bella Luz", mun_code: "023132" },
  // { label: "Daramuangan Sur", mun_code: "023132" },
  // { label: "Estrella", mun_code: "023132" },
  // { label: "Gaddanan", mun_code: "023132" },
  // { label: "Malasin", mun_code: "023132" },
  // { label: "Mapuroc", mun_code: "023132" },
  // { label: "Marasat Grande", mun_code: "023132" },
  // { label: "Old Centro I", mun_code: "023132" },
  // { label: "Old Centro II", mun_code: "023132" },
  // { label: "Barangay I (Pob.)", mun_code: "023132" },
  // { label: "Barangay II (Pob.)", mun_code: "023132" },
  // { label: "Barangay III (Pob.)", mun_code: "023132" },
  // { label: "Barangay IV (Pob.)", mun_code: "023132" },
  // { label: "Salinungan East", mun_code: "023132" },
  // { label: "Salinungan West", mun_code: "023132" },
  // { label: "San Andres", mun_code: "023132" },
  // { label: "San Antonio", mun_code: "023132" },
  // { label: "San Ignacio", mun_code: "023132" },
  // { label: "San Manuel", mun_code: "023132" },
  // { label: "San Marcos", mun_code: "023132" },
  // { label: "San Roque", mun_code: "023132" },
  // { label: "Sinamar Norte", mun_code: "023132" },
  // { label: "Sinamar Sur", mun_code: "023132" },
  // { label: "Victoria", mun_code: "023132" },
  // { label: "Villafuerte", mun_code: "023132" },
  // { label: "Villa Cruz", mun_code: "023132" },
  // { label: "Villa Magat", mun_code: "023132" },
  // { label: "Villa Gamiao (Buyon)", mun_code: "023132" },
  // { label: "Dagupan", mun_code: "023132" },
  // { label: "Daramuangan Norte", mun_code: "023132" },
  // { label: "Annanuman", mun_code: "023133" },
  // { label: "Auitan", mun_code: "023133" },
  // { label: "Ballacayu", mun_code: "023133" },
  // { label: "Binguang", mun_code: "023133" },
  // { label: "Bungad", mun_code: "023133" },
  // { label: "Dalena", mun_code: "023133" },
  // { label: "Caddangan/Limbauan", mun_code: "023133" },
  // { label: "Calamagui", mun_code: "023133" },
  // { label: "Caralucud", mun_code: "023133" },
  // { label: "Guminga", mun_code: "023133" },
  // { label: "Minanga Norte", mun_code: "023133" },
  // { label: "Minanga Sur", mun_code: "023133" },
  // { label: "San Jose", mun_code: "023133" },
  // { label: "Poblacion", mun_code: "023133" },
  // { label: "Simanu Norte", mun_code: "023133" },
  // { label: "Simanu Sur", mun_code: "023133" },
  // { label: "Tupa (San Vicente)", mun_code: "023133" },
  // { label: "Bangad", mun_code: "023134" },
  // { label: "Buenavista", mun_code: "023134" },
  // { label: "Calamagui North", mun_code: "023134" },
  // { label: "Calamagui East", mun_code: "023134" },
  // { label: "Calamagui West", mun_code: "023134" },
  // { label: "Divisoria", mun_code: "023134" },
  // { label: "Lingaling", mun_code: "023134" },
  // { label: "Mozzozzin Sur", mun_code: "023134" },
  // { label: "Mozzozzin North", mun_code: "023134" },
  // { label: "Naganacan", mun_code: "023134" },
  // { label: "Poblacion 1", mun_code: "023134" },
  // { label: "Poblacion 2", mun_code: "023134" },
  // { label: "Poblacion 3", mun_code: "023134" },
  // { label: "Quinagabian", mun_code: "023134" },
  // { label: "San Antonio", mun_code: "023134" },
  // { label: "San Isidro East", mun_code: "023134" },
  // { label: "San Isidro West", mun_code: "023134" },
  // { label: "San Rafael West", mun_code: "023134" },
  // { label: "San Rafael East", mun_code: "023134" },
  // { label: "Villabuena", mun_code: "023134" },
  // { label: "Abra", mun_code: "023135" },
  // { label: "Ambalatungan", mun_code: "023135" },
  // { label: "Balintocatoc", mun_code: "023135" },
  // { label: "Baluarte", mun_code: "023135" },
  // { label: "Bannawag Norte", mun_code: "023135" },
  // { label: "Batal", mun_code: "023135" },
  // { label: "Buenavista", mun_code: "023135" },
  // { label: "Cabulay", mun_code: "023135" },
  // { label: "Calao East (Pob.)", mun_code: "023135" },
  // { label: "Calao West (Pob.)", mun_code: "023135" },
  // { label: "Calaocan", mun_code: "023135" },
  // { label: "Villa Gonzaga", mun_code: "023135" },
  // { label: "Centro East (Pob.)", mun_code: "023135" },
  // { label: "Centro West (Pob.)", mun_code: "023135" },
  // { label: "Divisoria", mun_code: "023135" },
  // { label: "Dubinan East", mun_code: "023135" },
  // { label: "Dubinan West", mun_code: "023135" },
  // { label: "Luna", mun_code: "023135" },
  // { label: "Mabini", mun_code: "023135" },
  // { label: "Malvar", mun_code: "023135" },
  // { label: "Nabbuan", mun_code: "023135" },
  // { label: "Naggasican", mun_code: "023135" },
  // { label: "Patul", mun_code: "023135" },
  // { label: "Plaridel", mun_code: "023135" },
  // { label: "Rizal", mun_code: "023135" },
  // { label: "Rosario", mun_code: "023135" },
  // { label: "Sagana", mun_code: "023135" },
  // { label: "Salvador", mun_code: "023135" },
  // { label: "San Andres", mun_code: "023135" },
  // { label: "San Isidro", mun_code: "023135" },
  // { label: "San Jose", mun_code: "023135" },
  // { label: "Sinili", mun_code: "023135" },
  // { label: "Sinsayon", mun_code: "023135" },
  // { label: "Santa Rosa", mun_code: "023135" },
  // { label: "Victory Norte", mun_code: "023135" },
  // { label: "Victory Sur", mun_code: "023135" },
  // { label: "Villasis", mun_code: "023135" },
  // { label: "Ammugauan", mun_code: "023136" },
  // { label: "Antagan", mun_code: "023136" },
  // { label: "Bagabag", mun_code: "023136" },
  // { label: "Bagutari", mun_code: "023136" },
  // { label: "Balelleng", mun_code: "023136" },
  // { label: "Barumbong", mun_code: "023136" },
  // { label: "Bubug", mun_code: "023136" },
  // { label: "Bolinao-Culalabo", mun_code: "023136" },
  // { label: "Calinaoan Centro", mun_code: "023136" },
  // { label: "Calinaoan Malasin", mun_code: "023136" },
  // { label: "Calinaoan Norte", mun_code: "023136" },
  // { label: "Centro", mun_code: "023136" },
  // { label: "Colunguan", mun_code: "023136" },
  // { label: "Malapagay", mun_code: "023136" },
  // { label: "San Rafael Abajo", mun_code: "023136" },
  // { label: "San Rafael Alto", mun_code: "023136" },
  // { label: "San Roque", mun_code: "023136" },
  // { label: "San Vicente", mun_code: "023136" },
  // { label: "Uauang-Tuliao", mun_code: "023136" },
  // { label: "Uauang-Galicia", mun_code: "023136" },
  // { label: "Biga Occidental", mun_code: "023136" },
  // { label: "Biga Oriental", mun_code: "023136" },
  // { label: "Calanigan Norte", mun_code: "023136" },
  // { label: "Calanigan Sur", mun_code: "023136" },
  // { label: "Annafunan", mun_code: "023137" },
  // { label: "Antagan I", mun_code: "023137" },
  // { label: "Antagan II", mun_code: "023137" },
  // { label: "Arcon", mun_code: "023137" },
  // { label: "Balug", mun_code: "023137" },
  // { label: "Banig", mun_code: "023137" },
  // { label: "Bantug", mun_code: "023137" },
  // { label: "Bayabo East", mun_code: "023137" },
  // { label: "Caligayan", mun_code: "023137" },
  // { label: "Camasi", mun_code: "023137" },
  // { label: "Carpentero", mun_code: "023137" },
  // { label: "Compania", mun_code: "023137" },
  // { label: "Cumabao", mun_code: "023137" },
  // { label: "Fugu Abajo", mun_code: "023137" },
  // { label: "Fugu Norte", mun_code: "023137" },
  // { label: "Fugu Sur", mun_code: "023137" },
  // { label: "Fermeldy (Hcda. San Francisco)", mun_code: "023137" },
  // { label: "Lalauanan", mun_code: "023137" },
  // { label: "Lanna", mun_code: "023137" },
  // { label: "Lapogan", mun_code: "023137" },
  // { label: "Lingaling", mun_code: "023137" },
  // { label: "Liwanag", mun_code: "023137" },
  // { label: "Santa Visitacion (Maggayu)", mun_code: "023137" },
  // { label: "Malamag East", mun_code: "023137" },
  // { label: "Malamag West", mun_code: "023137" },
  // { label: "Maligaya", mun_code: "023137" },
  // { label: "Minanga", mun_code: "023137" },
  // { label: "Namnama", mun_code: "023137" },
  // { label: "Paragu", mun_code: "023137" },
  // { label: "Pilitan", mun_code: "023137" },
  // { label: "Barangay District 1 (Pob.)", mun_code: "023137" },
  // { label: "Barangay District 2 (Pob.)", mun_code: "023137" },
  // { label: "Barangay District 3 (Pob.)", mun_code: "023137" },
  // { label: "Barangay District 4 (Pob.)", mun_code: "023137" },
  // { label: "San Mateo", mun_code: "023137" },
  // { label: "San Pedro", mun_code: "023137" },
  // { label: "San Vicente", mun_code: "023137" },
  // { label: "Santa", mun_code: "023137" },
  // { label: "Santa Catalina", mun_code: "023137" },
  // { label: "Sinippil", mun_code: "023137" },
  // { label: "Sisim Abajo", mun_code: "023137" },
  // { label: "Sisim Alto", mun_code: "023137" },
  // { label: "Tunggui", mun_code: "023137" },
  // { label: "Ugad", mun_code: "023137" },
  // { label: "Moldero", mun_code: "023137" },
  // { label: "Ammueg", mun_code: "025001" },
  // { label: "Camandag", mun_code: "025001" },
  // { label: "Labang", mun_code: "025001" },
  // { label: "Napo", mun_code: "025001" },
  // { label: "Poblacion", mun_code: "025001" },
  // { label: "Salingsingan", mun_code: "025001" },
  // { label: "Tiblac", mun_code: "025001" },
  // { label: "Dulli", mun_code: "025001" },
  // { label: "Banganan", mun_code: "025002" },
  // { label: "Beti", mun_code: "025002" },
  // { label: "Bone North", mun_code: "025002" },
  // { label: "Bone South", mun_code: "025002" },
  // { label: "Calitlitan", mun_code: "025002" },
  // { label: "Comon", mun_code: "025002" },
  // { label: "Cutar", mun_code: "025002" },
  // { label: "Darapidap", mun_code: "025002" },
  // { label: "Kirang", mun_code: "025002" },
  // { label: "Nagcuartelan", mun_code: "025002" },
  // { label: "Poblacion", mun_code: "025002" },
  // { label: "Santa Clara", mun_code: "025002" },
  // { label: "Tabueng", mun_code: "025002" },
  // { label: "Tucanon", mun_code: "025002" },
  // { label: "Anayo", mun_code: "025002" },
  // { label: "Baan", mun_code: "025002" },
  // { label: "Balite", mun_code: "025002" },
  // { label: "Canabuan", mun_code: "025002" },
  // { label: "Canarem", mun_code: "025002" },
  // { label: "Latar-Nocnoc-San Francisco", mun_code: "025002" },
  // { label: "Ocao-Capiniaan", mun_code: "025002" },
  // { label: "Yaway", mun_code: "025002" },
  // { label: "Bakir", mun_code: "025003" },
  // { label: "Baretbet", mun_code: "025003" },
  // { label: "Careb", mun_code: "025003" },
  // { label: "Lantap", mun_code: "025003" },
  // { label: "Murong", mun_code: "025003" },
  // { label: "Nangalisan", mun_code: "025003" },
  // { label: "Paniki", mun_code: "025003" },
  // { label: "Pogonsino", mun_code: "025003" },
  // { label: "San Geronimo (Pob.)", mun_code: "025003" },
  // { label: "San Pedro (Pob.)", mun_code: "025003" },
  // { label: "Santa Cruz", mun_code: "025003" },
  // { label: "Santa Lucia", mun_code: "025003" },
  // { label: "Tuao North", mun_code: "025003" },
  // { label: "Villa Coloma (Pob.)", mun_code: "025003" },
  // { label: "Quirino (Pob.)", mun_code: "025003" },
  // { label: "Villaros", mun_code: "025003" },
  // { label: "Tuao South", mun_code: "025003" },
  // { label: "Abian", mun_code: "025004" },
  // { label: "Abinganan", mun_code: "025004" },
  // { label: "Aliaga", mun_code: "025004" },
  // { label: "Almaguer North", mun_code: "025004" },
  // { label: "Almaguer South", mun_code: "025004" },
  // { label: "Banggot (Pob.)", mun_code: "025004" },
  // { label: "Barat", mun_code: "025004" },
  // { label: "Buag (Pob.)", mun_code: "025004" },
  // { label: "Calaocan (Pob.)", mun_code: "025004" },
  // { label: "Dullao", mun_code: "025004" },
  // { label: "Homestead", mun_code: "025004" },
  // { label: "Indiana", mun_code: "025004" },
  // { label: "Mabuslo", mun_code: "025004" },
  // { label: "Macate", mun_code: "025004" },
  // { label: "Manamtam", mun_code: "025004" },
  // { label: "Mauan", mun_code: "025004" },
  // { label: "Salinas", mun_code: "025004" },
  // { label: "San Antonio North", mun_code: "025004" },
  // { label: "San Antonio South", mun_code: "025004" },
  // { label: "San Fernando", mun_code: "025004" },
  // { label: "San Leonardo", mun_code: "025004" },
  // { label: "Santo Domingo (Tabangan)", mun_code: "025004" },
  // { label: "Pallas", mun_code: "025004" },
  // { label: "Magsaysay Hills", mun_code: "025004" },
  // { label: "Santo Domingo West", mun_code: "025004" },
  // { label: "Bonfal East", mun_code: "025005" },
  // { label: "Bonfal Proper", mun_code: "025005" },
  // { label: "Bonfal West", mun_code: "025005" },
  // { label: "Buenavista (Vista Hills)", mun_code: "025005" },
  // { label: "Busilac", mun_code: "025005" },
  // { label: "Casat", mun_code: "025005" },
  // { label: "La Torre North", mun_code: "025005" },
  // { label: "Magapuy", mun_code: "025005" },
  // { label: "Magsaysay", mun_code: "025005" },
  // { label: "Masoc", mun_code: "025005" },
  // { label: "Paitan", mun_code: "025005" },
  // { label: "Don Domingo Maddela Pob. (District I)", mun_code: "025005" },
  // { label: "Don Tomas Maddela Pob. (District II)", mun_code: "025005" },
  // { label: "District III Pob. (Don M. Perez)", mun_code: "025005" },
  // { label: "District IV (Pob.)", mun_code: "025005" },
  // { label: "Bansing", mun_code: "025005" },
  // { label: "Cabuaan", mun_code: "025005" },
  // { label: "Don Mariano Marcos", mun_code: "025005" },
  // { label: "Ipil-Cuneg", mun_code: "025005" },
  // { label: "La Torre South", mun_code: "025005" },
  // { label: "Luyang", mun_code: "025005" },
  // { label: "Salvacion", mun_code: "025005" },
  // { label: "San Nicolas North (Luyang)", mun_code: "025005" },
  // { label: "Santa Rosa", mun_code: "025005" },
  // { label: "Vista Alegre (B. Baringin)", mun_code: "025005" },
  // { label: "Arwas", mun_code: "025006" },
  // { label: "Balete", mun_code: "025006" },
  // { label: "Bugnay", mun_code: "025006" },
  // { label: "Decabacan", mun_code: "025006" },
  // { label: "Duruarog", mun_code: "025006" },
  // { label: "Escoting", mun_code: "025006" },
  // { label: "Nagsabaran", mun_code: "025006" },
  // { label: "Namamparan", mun_code: "025006" },
  // { label: "Pinya", mun_code: "025006" },
  // { label: "Poblacion", mun_code: "025006" },
  // { label: "Ampakling", mun_code: "025006" },
  // { label: "Butao", mun_code: "025006" },
  // { label: "Langca", mun_code: "025006" },
  // { label: "Lurad", mun_code: "025006" },
  // { label: "Rosario", mun_code: "025006" },
  // { label: "San Luis", mun_code: "025006" },
  // { label: "San Pablo", mun_code: "025006" },
  // { label: "Villa Aurora", mun_code: "025006" },
  // { label: "Villa Florentino", mun_code: "025006" },
  // { label: "Belance", mun_code: "025007" },
  // { label: "Bulala", mun_code: "025007" },
  // { label: "Inaban", mun_code: "025007" },
  // { label: "Ineangan", mun_code: "025007" },
  // { label: "Lamo", mun_code: "025007" },
  // { label: "Mabasa", mun_code: "025007" },
  // { label: "Malasin (Pob.)", mun_code: "025007" },
  // { label: "Munguia", mun_code: "025007" },
  // { label: "Oyao", mun_code: "025007" },
  // { label: "New Gumiad", mun_code: "025007" },
  // { label: "Yabbi", mun_code: "025007" },
  // { label: "Binnuangan", mun_code: "025007" },
  // { label: "Bitnong", mun_code: "025007" },
  // { label: "Macabenga", mun_code: "025007" },
  // { label: "Parai", mun_code: "025007" },
  // { label: "Abaca", mun_code: "025008" },
  // { label: "Banila", mun_code: "025008" },
  // { label: "Carolotan", mun_code: "025008" },
  // { label: "Gabut", mun_code: "025008" },
  // { label: "Ganao (Lingad)", mun_code: "025008" },
  // { label: "Lukidnon", mun_code: "025008" },
  // { label: "Mangayang", mun_code: "025008" },
  // { label: "Palabotan", mun_code: "025008" },
  // { label: "Biruk", mun_code: "025008" },
  // { label: "Bagumbayan", mun_code: "025008" },
  // { label: "Balsain", mun_code: "025008" },
  // { label: "Canabay", mun_code: "025008" },
  // { label: "Domang", mun_code: "025008" },
  // { label: "Dopaj", mun_code: "025008" },
  // { label: "Kimbutan", mun_code: "025008" },
  // { label: "Kinabuan", mun_code: "025008" },
  // { label: "Sanguit", mun_code: "025008" },
  // { label: "Santa Maria", mun_code: "025008" },
  // { label: "Talbek", mun_code: "025008" },
  // { label: "Antutot", mun_code: "025009" },
  // { label: "Alimit", mun_code: "025009" },
  // { label: "Poblacion (Alloy)", mun_code: "025009" },
  // { label: "Bilet", mun_code: "025009" },
  // { label: "Binogawan", mun_code: "025009" },
  // { label: "Bua", mun_code: "025009" },
  // { label: "Biyoy", mun_code: "025009" },
  // { label: "Capisaan", mun_code: "025009" },
  // { label: "Cordon", mun_code: "025009" },
  // { label: "Didipio", mun_code: "025009" },
  // { label: "Dine", mun_code: "025009" },
  // { label: "Kakiduguen", mun_code: "025009" },
  // { label: "Lupa", mun_code: "025009" },
  // { label: "Macalong", mun_code: "025009" },
  // { label: "Malabing", mun_code: "025009" },
  // { label: "Muta", mun_code: "025009" },
  // { label: "Pao", mun_code: "025009" },
  // { label: "Papaya", mun_code: "025009" },
  // { label: "Pudi", mun_code: "025009" },
  // { label: "Tokod", mun_code: "025009" },
  // { label: "Seguem", mun_code: "025009" },
  // { label: "Tadji", mun_code: "025009" },
  // { label: "Wangal", mun_code: "025009" },
  // { label: "Watwat", mun_code: "025009" },
  // { label: "Camamasi", mun_code: "025009" },
  // { label: "Catarawan", mun_code: "025009" },
  // { label: "Nantawacan", mun_code: "025009" },
  // { label: "Alloy", mun_code: "025009" },
  // { label: "Kongkong", mun_code: "025009" },
  // { label: "Pacquet (Ilongot Res.)", mun_code: "025009" },
  // { label: "Acacia", mun_code: "025010" },
  // { label: "Amilong Labeng", mun_code: "025010" },
  // { label: "Ansipsip", mun_code: "025010" },
  // { label: "Baan", mun_code: "025010" },
  // { label: "Babadi", mun_code: "025010" },
  // { label: "Balangabang", mun_code: "025010" },
  // { label: "Banao", mun_code: "025010" },
  // { label: "Binalian", mun_code: "025010" },
  // { label: "Besong", mun_code: "025010" },
  // { label: "Cabalatan-Alang", mun_code: "025010" },
  // { label: "Cabanglasan", mun_code: "025010" },
  // { label: "Kayapa Proper East", mun_code: "025010" },
  // { label: "Kayapa Proper West", mun_code: "025010" },
  // { label: "Mapayao", mun_code: "025010" },
  // { label: "Nansiakan", mun_code: "025010" },
  // { label: "Pampang (Pob.)", mun_code: "025010" },
  // { label: "Pangawan", mun_code: "025010" },
  // { label: "Pinayag", mun_code: "025010" },
  // { label: "Pingkian", mun_code: "025010" },
  // { label: "San Fabian", mun_code: "025010" },
  // { label: "Talecabcab", mun_code: "025010" },
  // { label: "Tubongan", mun_code: "025010" },
  // { label: "Alang-Salacsac", mun_code: "025010" },
  // { label: "Balete", mun_code: "025010" },
  // { label: "Buyasyas", mun_code: "025010" },
  // { label: "Cabayo", mun_code: "025010" },
  // { label: "Castillo Village", mun_code: "025010" },
  // { label: "Latbang", mun_code: "025010" },
  // { label: "Lawigan", mun_code: "025010" },
  // { label: "Tidang Village", mun_code: "025010" },
  // { label: "Aurora", mun_code: "025011" },
  // { label: "Baresbes", mun_code: "025011" },
  // { label: "Buliwao", mun_code: "025011" },
  // { label: "Bonifacio", mun_code: "025011" },
  // { label: "Calaocan", mun_code: "025011" },
  // { label: "Caliat (Pob.)", mun_code: "025011" },
  // { label: "Darubba", mun_code: "025011" },
  // { label: "Maddiangat", mun_code: "025011" },
  // { label: "Nalubbunan", mun_code: "025011" },
  // { label: "Runruno", mun_code: "025011" },
  // { label: "Maasin", mun_code: "025011" },
  // { label: "Dagupan", mun_code: "025011" },
  // { label: "Bacneng", mun_code: "025012" },
  // { label: "Baliling", mun_code: "025012" },
  // { label: "Bantinan", mun_code: "025012" },
  // { label: "Baracbac", mun_code: "025012" },
  // { label: "Buyasyas", mun_code: "025012" },
  // { label: "Imugan", mun_code: "025012" },
  // { label: "Poblacion", mun_code: "025012" },
  // { label: "Sinapaoan", mun_code: "025012" },
  // { label: "Tactac", mun_code: "025012" },
  // { label: "Villa Flores", mun_code: "025012" },
  // { label: "Atbu", mun_code: "025012" },
  // { label: "Balete", mun_code: "025012" },
  // { label: "Canabuan", mun_code: "025012" },
  // { label: "Malico", mun_code: "025012" },
  // { label: "Santa Rosa", mun_code: "025012" },
  // { label: "Unib", mun_code: "025012" },
  // { label: "Aggub", mun_code: "025013" },
  // { label: "Bangaan", mun_code: "025013" },
  // { label: "Bangar", mun_code: "025013" },
  // { label: "Bascaran", mun_code: "025013" },
  // { label: "Curifang", mun_code: "025013" },
  // { label: "Dadap", mun_code: "025013" },
  // { label: "Lactawan", mun_code: "025013" },
  // { label: "Poblacion North", mun_code: "025013" },
  // { label: "Poblacion South", mun_code: "025013" },
  // { label: "Quezon", mun_code: "025013" },
  // { label: "Quirino", mun_code: "025013" },
  // { label: "Roxas", mun_code: "025013" },
  // { label: "San Juan", mun_code: "025013" },
  // { label: "San Luis", mun_code: "025013" },
  // { label: "Tucal", mun_code: "025013" },
  // { label: "Uddiawan", mun_code: "025013" },
  // { label: "Wacal", mun_code: "025013" },
  // { label: "Bagahabag", mun_code: "025013" },
  // { label: "Communal", mun_code: "025013" },
  // { label: "Concepcion (Calalabangan)", mun_code: "025013" },
  // { label: "Pilar D. Galima", mun_code: "025013" },
  // { label: "Bintawan Sur", mun_code: "025014" },
  // { label: "Ibung", mun_code: "025014" },
  // { label: "Cabuluan", mun_code: "025014" },
  // { label: "Nagbitin", mun_code: "025014" },
  // { label: "Ocapon", mun_code: "025014" },
  // { label: "Pieza", mun_code: "025014" },
  // { label: "Sawmill", mun_code: "025014" },
  // { label: "Poblacion (Turod)", mun_code: "025014" },
  // { label: "Bintawan Norte", mun_code: "025014" },
  // { label: "Abuyo", mun_code: "025015" },
  // { label: "Galintuja", mun_code: "025015" },
  // { label: "Cauayan", mun_code: "025015" },
  // { label: "Lipuga", mun_code: "025015" },
  // { label: "Lublub (Pob.)", mun_code: "025015" },
  // { label: "Pelaway", mun_code: "025015" },
  // { label: "Dagupan", mun_code: "025701" },
  // { label: "Dumabel", mun_code: "025701" },
  // { label: "Guinalbin", mun_code: "025701" },
  // { label: "Ligaya", mun_code: "025701" },
  // { label: "Palacian", mun_code: "025701" },
  // { label: "Pinaripad Sur", mun_code: "025701" },
  // { label: "Progreso (Pob.)", mun_code: "025701" },
  // { label: "Ramos", mun_code: "025701" },
  // { label: "Rang-ayan", mun_code: "025701" },
  // { label: "San Antonio", mun_code: "025701" },
  // { label: "San Francisco", mun_code: "025701" },
  // { label: "San Leonardo (Cabarroguis)", mun_code: "025701" },
  // { label: "San Ramon", mun_code: "025701" },
  // { label: "Victoria", mun_code: "025701" },
  // { label: "Villa Pagaduan", mun_code: "025701" },
  // { label: "Villa Santiago", mun_code: "025701" },
  // { label: "Alicia", mun_code: "025701" },
  // { label: "Cabugao", mun_code: "025701" },
  // { label: "Diodol", mun_code: "025701" },
  // { label: "Nagabgaban", mun_code: "025701" },
  // { label: "Pinaripad Norte", mun_code: "025701" },
  // { label: "San Benigno", mun_code: "025701" },
  // { label: "San Manuel", mun_code: "025701" },
  // { label: "Villa Ventura", mun_code: "025701" },
  // { label: "Banuar", mun_code: "025702" },
  // { label: "Burgos", mun_code: "025702" },
  // { label: "Calaocan", mun_code: "025702" },
  // { label: "Del Pilar", mun_code: "025702" },
  // { label: "Dibibi", mun_code: "025702" },
  // { label: "Eden", mun_code: "025702" },
  // { label: "Gundaway (Pob.)", mun_code: "025702" },
  // { label: "Mangandingay (Pob.)", mun_code: "025702" },
  // { label: "San Marcos", mun_code: "025702" },
  // { label: "Villamor", mun_code: "025702" },
  // { label: "Zamora", mun_code: "025702" },
  // { label: "Villarose", mun_code: "025702" },
  // { label: "Dingasan", mun_code: "025702" },
  // { label: "Tucod", mun_code: "025702" },
  // { label: "Gomez", mun_code: "025702" },
  // { label: "Santo Domingo", mun_code: "025702" },
  // { label: "Andres Bonifacio (Pob.)", mun_code: "025703" },
  // { label: "Aurora East (Pob.)", mun_code: "025703" },
  // { label: "Aurora West (Pob.)", mun_code: "025703" },
  // { label: "Baguio Village", mun_code: "025703" },
  // { label: "Balagbag", mun_code: "025703" },
  // { label: "Bannawag", mun_code: "025703" },
  // { label: "Cajel", mun_code: "025703" },
  // { label: "Campamento", mun_code: "025703" },
  // { label: "Diego Silang", mun_code: "025703" },
  // { label: "Don Mariano Perez, Sr.", mun_code: "025703" },
  // { label: "Dumanisi", mun_code: "025703" },
  // { label: "Gabriela Silang", mun_code: "025703" },
  // { label: "Gulac", mun_code: "025703" },
  // { label: "Guribang", mun_code: "025703" },
  // { label: "Ifugao Village", mun_code: "025703" },
  // { label: "Isidro Paredes", mun_code: "025703" },
  // { label: "Rizal (Pob.)", mun_code: "025703" },
  // { label: "Liwayway", mun_code: "025703" },
  // { label: "Luttuad", mun_code: "025703" },
  // { label: "Magsaysay", mun_code: "025703" },
  // { label: "Makate", mun_code: "025703" },
  // { label: "Maria Clara", mun_code: "025703" },
  // { label: "Ricarte Norte", mun_code: "025703" },
  // { label: "Ricarte Sur", mun_code: "025703" },
  // { label: "San Antonio", mun_code: "025703" },
  // { label: "San Isidro", mun_code: "025703" },
  // { label: "San Pascual", mun_code: "025703" },
  // { label: "Villa Pascua", mun_code: "025703" },
  // { label: "Aklan Village", mun_code: "025703" },
  // { label: "Gregorio Pimentel", mun_code: "025703" },
  // { label: "Don Faustino Pagaduan", mun_code: "025703" },
  // { label: "Abbag", mun_code: "025704" },
  // { label: "Balligui", mun_code: "025704" },
  // { label: "Divisoria Sur (Bisangal)", mun_code: "025704" },
  // { label: "Buenavista", mun_code: "025704" },
  // { label: "Cabaruan", mun_code: "025704" },
  // { label: "Cabua-an", mun_code: "025704" },
  // { label: "Cofcaville", mun_code: "025704" },
  // { label: "Diduyon", mun_code: "025704" },
  // { label: "Dipintin", mun_code: "025704" },
  // { label: "Divisoria Norte", mun_code: "025704" },
  // { label: "Dumabato Norte", mun_code: "025704" },
  // { label: "Dumabato Sur", mun_code: "025704" },
  // { label: "Lusod", mun_code: "025704" },
  // { label: "Manglad", mun_code: "025704" },
  // { label: "Pedlisan", mun_code: "025704" },
  // { label: "Poblacion Norte", mun_code: "025704" },
  // { label: "San Bernabe", mun_code: "025704" },
  // { label: "San Dionisio I", mun_code: "025704" },
  // { label: "San Martin", mun_code: "025704" },
  // { label: "San Pedro", mun_code: "025704" },
  // { label: "San Salvador", mun_code: "025704" },
  // { label: "Santo Tomas", mun_code: "025704" },
  // { label: "Villa Gracia", mun_code: "025704" },
  // { label: "Villa Hermosa Sur", mun_code: "025704" },
  // { label: "Villa Hermosa Norte", mun_code: "025704" },
  // { label: "Ysmael", mun_code: "025704" },
  // { label: "Villa Agullana", mun_code: "025704" },
  // { label: "Poblacion Sur", mun_code: "025704" },
  // { label: "Villa Jose V Ylanan", mun_code: "025704" },
  // { label: "Jose Ancheta", mun_code: "025704" },
  // { label: "Santa Maria", mun_code: "025704" },
  // { label: "Dibul", mun_code: "025705" },
  // { label: "La Paz", mun_code: "025705" },
  // { label: "Magsaysay (Pob.)", mun_code: "025705" },
  // { label: "Rizal (Pob.)", mun_code: "025705" },
  // { label: "Salvacion", mun_code: "025705" },
  // { label: "Santo Tomas", mun_code: "025705" },
  // { label: "Tres Reyes", mun_code: "025705" },
  // { label: "Cardenas", mun_code: "025705" },
  // { label: "Gamis", mun_code: "025705" },
  // { label: "Anak", mun_code: "025706" },
  // { label: "Dipantan", mun_code: "025706" },
  // { label: "Dissimungal", mun_code: "025706" },
  // { label: "Guino (Giayan)", mun_code: "025706" },
  // { label: "La Conwap (Guingin)", mun_code: "025706" },
  // { label: "Landingan", mun_code: "025706" },
  // { label: "Mataddi", mun_code: "025706" },
  // { label: "Matmad", mun_code: "025706" },
  // { label: "Old Gumiad", mun_code: "025706" },
  // { label: "Ponggo", mun_code: "025706" },
  // { label: "San Dionisio II", mun_code: "025706" },
  // { label: "San Pugo", mun_code: "025706" },
  // { label: "San Ramos", mun_code: "025706" },
  // { label: "Sangbay", mun_code: "025706" },
  // { label: "Wasid", mun_code: "025706" },
  // { label: "Asaklat", mun_code: "025706" },
  // { label: "Bangkal", mun_code: "030801" },
  // { label: "Calaylayan (Pob.)", mun_code: "030801" },
  // { label: "Capitangan", mun_code: "030801" },
  // { label: "Gabon", mun_code: "030801" },
  // { label: "Laon (Pob.)", mun_code: "030801" },
  // { label: "Mabatang", mun_code: "030801" },
  // { label: "Omboy", mun_code: "030801" },
  // { label: "Salian", mun_code: "030801" },
  // { label: "Wawa (Pob.)", mun_code: "030801" },
  // { label: "Bagumbayan (Pob.)", mun_code: "030802" },
  // { label: "Banawang", mun_code: "030802" },
  // { label: "Binuangan", mun_code: "030802" },
  // { label: "Binukawan", mun_code: "030802" },
  // { label: "Ibaba", mun_code: "030802" },
  // { label: "Ibis", mun_code: "030802" },
  // { label: "Pag-asa (Wawa-Sibacan)", mun_code: "030802" },
  // { label: "Parang", mun_code: "030802" },
  // { label: "Paysawan", mun_code: "030802" },
  // { label: "Quinawan", mun_code: "030802" },
  // { label: "San Antonio", mun_code: "030802" },
  // { label: "Saysain", mun_code: "030802" },
  // { label: "Tabing-Ilog (Pob.)", mun_code: "030802" },
  // { label: "Atilano L. Ricardo", mun_code: "030802" },
  // { label: "Bagumbayan", mun_code: "030803" },
  // { label: "Cabog-Cabog", mun_code: "030803" },
  // { label: "Munting Batangas (Cadre)", mun_code: "030803" },
  // { label: "Cataning", mun_code: "030803" },
  // { label: "Central", mun_code: "030803" },
  // { label: "Cupang Proper", mun_code: "030803" },
  // { label: "Cupang West", mun_code: "030803" },
  // { label: "Dangcol (Bernabe)", mun_code: "030803" },
  // { label: "Ibayo", mun_code: "030803" },
  // { label: "Malabia", mun_code: "030803" },
  // { label: "Poblacion", mun_code: "030803" },
  // { label: "Pto. Rivas Ibaba", mun_code: "030803" },
  // { label: "Pto. Rivas Itaas", mun_code: "030803" },
  // { label: "San Jose", mun_code: "030803" },
  // { label: "Sibacan", mun_code: "030803" },
  // { label: "Camacho", mun_code: "030803" },
  // { label: "Talisay", mun_code: "030803" },
  // { label: "Tanato", mun_code: "030803" },
  // { label: "Tenejero", mun_code: "030803" },
  // { label: "Tortugas", mun_code: "030803" },
  // { label: "Tuyo", mun_code: "030803" },
  // { label: "Bagong Silang", mun_code: "030803" },
  // { label: "Cupang North", mun_code: "030803" },
  // { label: "Lote", mun_code: "030803" },
  // { label: "Bangal", mun_code: "030804" },
  // { label: "Bonifacio (Pob.)", mun_code: "030804" },
  // { label: "Burgos (Pob.)", mun_code: "030804" },
  // { label: "Colo", mun_code: "030804" },
  // { label: "Daang Bago", mun_code: "030804" },
  // { label: "Dalao", mun_code: "030804" },
  // { label: "Del Pilar (Pob.)", mun_code: "030804" },
  // { label: "Gen. Luna (Pob.)", mun_code: "030804" },
  // { label: "Gomez (Pob.)", mun_code: "030804" },
  // { label: "Happy Valley", mun_code: "030804" },
  // { label: "Kataasan", mun_code: "030804" },
  // { label: "Layac", mun_code: "030804" },
  // { label: "Luacan", mun_code: "030804" },
  // { label: "Mabini Proper (Pob.)", mun_code: "030804" },
  // { label: "Mabini Ext. (Pob.)", mun_code: "030804" },
  // { label: "Magsaysay", mun_code: "030804" },
  // { label: "Naparing", mun_code: "030804" },
  // { label: "New San Jose", mun_code: "030804" },
  // { label: "Old San Jose", mun_code: "030804" },
  // { label: "Padre Dandan (Pob.)", mun_code: "030804" },
  // { label: "Pag-asa", mun_code: "030804" },
  // { label: "Pagalanggang", mun_code: "030804" },
  // { label: "Pinulot", mun_code: "030804" },
  // { label: "Pita", mun_code: "030804" },
  // { label: "Rizal (Pob.)", mun_code: "030804" },
  // { label: "Roosevelt", mun_code: "030804" },
  // { label: "Roxas (Pob.)", mun_code: "030804" },
  // { label: "Saguing", mun_code: "030804" },
  // { label: "San Benito", mun_code: "030804" },
  // { label: "San Isidro (Pob.)", mun_code: "030804" },
  // { label: "San Pablo (Bulate)", mun_code: "030804" },
  // { label: "San Ramon", mun_code: "030804" },
  // { label: "San Simon", mun_code: "030804" },
  // { label: "Sapang Balas", mun_code: "030804" },
  // { label: "Santa Isabel (Tabacan)", mun_code: "030804" },
  // { label: "Torres Bugauen (Pob.)", mun_code: "030804" },
  // { label: "Tucop", mun_code: "030804" },
  // { label: "Zamora (Pob.)", mun_code: "030804" },
  // { label: "Aquino", mun_code: "030804" },
  // { label: "Bayan-bayanan", mun_code: "030804" },
  // { label: "Maligaya", mun_code: "030804" },
  // { label: "Payangan", mun_code: "030804" },
  // { label: "Pentor", mun_code: "030804" },
  // { label: "Tubo-tubo", mun_code: "030804" },
  // { label: "Jose C. Payumo, Jr.", mun_code: "030804" },
  // { label: "A. Rivera (Pob.)", mun_code: "030805" },
  // { label: "Almacen", mun_code: "030805" },
  // { label: "Bacong", mun_code: "030805" },
  // { label: "Balsic", mun_code: "030805" },
  // { label: "Bamban", mun_code: "030805" },
  // { label: "Burgos-Soliman (Pob.)", mun_code: "030805" },
  // { label: "Cataning (Pob.)", mun_code: "030805" },
  // { label: "Culis", mun_code: "030805" },
  // { label: "Daungan (Pob.)", mun_code: "030805" },
  // { label: "Mabiga", mun_code: "030805" },
  // { label: "Mabuco", mun_code: "030805" },
  // { label: "Maite", mun_code: "030805" },
  // { label: "Mambog - Mandama", mun_code: "030805" },
  // { label: "Palihan", mun_code: "030805" },
  // { label: "Pandatung", mun_code: "030805" },
  // { label: "Pulo", mun_code: "030805" },
  // { label: "Saba", mun_code: "030805" },
  // { label: "San Pedro (Pob.)", mun_code: "030805" },
  // { label: "Santo Cristo (Pob.)", mun_code: "030805" },
  // { label: "Sumalo", mun_code: "030805" },
  // { label: "Tipo", mun_code: "030805" },
  // { label: "Judge Roman Cruz Sr. (Mandama)", mun_code: "030805" },
  // { label: "Sacrifice Valley", mun_code: "030805" },
  // { label: "Alangan", mun_code: "030806" },
  // { label: "Kitang I", mun_code: "030806" },
  // { label: "Kitang 2 & Luz", mun_code: "030806" },
  // { label: "Lamao", mun_code: "030806" },
  // { label: "Landing", mun_code: "030806" },
  // { label: "Poblacion", mun_code: "030806" },
  // { label: "Reformista", mun_code: "030806" },
  // { label: "Townsite", mun_code: "030806" },
  // { label: "Wawa", mun_code: "030806" },
  // { label: "Duale", mun_code: "030806" },
  // { label: "San Francisco de Asis", mun_code: "030806" },
  // { label: "St. Francis II", mun_code: "030806" },
  // { label: "Alas-asin", mun_code: "030807" },
  // { label: "Alion", mun_code: "030807" },
  // { label: "Batangas II", mun_code: "030807" },
  // { label: "Cabcaben", mun_code: "030807" },
  // { label: "Lucanin", mun_code: "030807" },
  // { label: "Baseco Country (Nassco)", mun_code: "030807" },
  // { label: "Poblacion", mun_code: "030807" },
  // { label: "San Carlos", mun_code: "030807" },
  // { label: "San Isidro", mun_code: "030807" },
  // { label: "Sisiman", mun_code: "030807" },
  // { label: "Balon-Anito", mun_code: "030807" },
  // { label: "Biaan", mun_code: "030807" },
  // { label: "Camaya", mun_code: "030807" },
  // { label: "Ipag", mun_code: "030807" },
  // { label: "Malaya", mun_code: "030807" },
  // { label: "Maligaya", mun_code: "030807" },
  // { label: "Mt. View", mun_code: "030807" },
  // { label: "Townsite", mun_code: "030807" },
  // { label: "Binaritan", mun_code: "030808" },
  // { label: "Mabayo", mun_code: "030808" },
  // { label: "Nagbalayong", mun_code: "030808" },
  // { label: "Poblacion", mun_code: "030808" },
  // { label: "Sabang", mun_code: "030808" },
  // { label: "Bagong Paraiso (Pob.)", mun_code: "030809" },
  // { label: "Balut (Pob.)", mun_code: "030809" },
  // { label: "Bayan (Pob.)", mun_code: "030809" },
  // { label: "Calero (Pob.)", mun_code: "030809" },
  // { label: "Paking-Carbonero (Pob.)", mun_code: "030809" },
  // { label: "Centro II (Pob.)", mun_code: "030809" },
  // { label: "Dona", mun_code: "030809" },
  // { label: "Kaparangan", mun_code: "030809" },
  // { label: "Masantol", mun_code: "030809" },
  // { label: "Mulawin", mun_code: "030809" },
  // { label: "Pag-asa", mun_code: "030809" },
  // { label: "Palihan (Pob.)", mun_code: "030809" },
  // { label: "Pantalan Bago (Pob.)", mun_code: "030809" },
  // { label: "Pantalan Luma (Pob.)", mun_code: "030809" },
  // { label: "Parang Parang (Pob.)", mun_code: "030809" },
  // { label: "Centro I (Pob.)", mun_code: "030809" },
  // { label: "Sibul", mun_code: "030809" },
  // { label: "Silahis", mun_code: "030809" },
  // { label: "Tala", mun_code: "030809" },
  // { label: "Talimundoc", mun_code: "030809" },
  // { label: "Tapulao", mun_code: "030809" },
  // { label: "Tenejero (Pob.)", mun_code: "030809" },
  // { label: "Tugatog", mun_code: "030809" },
  // { label: "Wawa (Pob.)", mun_code: "030809" },
  // { label: "Apollo", mun_code: "030809" },
  // { label: "Kabalutan", mun_code: "030809" },
  // { label: "Maria Fe", mun_code: "030809" },
  // { label: "Puksuan", mun_code: "030809" },
  // { label: "Tagumpay", mun_code: "030809" },
  // { label: "Arellano (Pob.)", mun_code: "030810" },
  // { label: "Bagumbayan (Pob.)", mun_code: "030810" },
  // { label: "Balagtas (Pob.)", mun_code: "030810" },
  // { label: "Balut (Pob.)", mun_code: "030810" },
  // { label: "Bantan", mun_code: "030810" },
  // { label: "Bilolo", mun_code: "030810" },
  // { label: "Calungusan", mun_code: "030810" },
  // { label: "Camachile", mun_code: "030810" },
  // { label: "Daang Bago (Pob.)", mun_code: "030810" },
  // { label: "Daang Bilolo (Pob.)", mun_code: "030810" },
  // { label: "Daang Pare", mun_code: "030810" },
  // { label: "General Lim (Kaput)", mun_code: "030810" },
  // { label: "Kapunitan", mun_code: "030810" },
  // { label: "Lati (Pob.)", mun_code: "030810" },
  // { label: "Lusungan (Pob.)", mun_code: "030810" },
  // { label: "Puting Buhangin", mun_code: "030810" },
  // { label: "Sabatan", mun_code: "030810" },
  // { label: "San Vicente (Pob.)", mun_code: "030810" },
  // { label: "Santo Domingo", mun_code: "030810" },
  // { label: "Villa Angeles (Pob.)", mun_code: "030810" },
  // { label: "Wakas (Pob.)", mun_code: "030810" },
  // { label: "Wawa (Pob.)", mun_code: "030810" },
  // { label: "Santa Elena", mun_code: "030810" },
  // { label: "Ala-uli", mun_code: "030811" },
  // { label: "Bagumbayan", mun_code: "030811" },
  // { label: "Balut I", mun_code: "030811" },
  // { label: "Balut II", mun_code: "030811" },
  // { label: "Bantan Munti", mun_code: "030811" },
  // { label: "Burgos", mun_code: "030811" },
  // { label: "Del Rosario (Pob.)", mun_code: "030811" },
  // { label: "Diwa", mun_code: "030811" },
  // { label: "Landing", mun_code: "030811" },
  // { label: "Liyang", mun_code: "030811" },
  // { label: "Nagwaling", mun_code: "030811" },
  // { label: "Panilao", mun_code: "030811" },
  // { label: "Pantingan", mun_code: "030811" },
  // { label: "Poblacion", mun_code: "030811" },
  // { label: "Rizal (Pob.)", mun_code: "030811" },
  // { label: "Santa Rosa", mun_code: "030811" },
  // { label: "Wakas North", mun_code: "030811" },
  // { label: "Wakas South", mun_code: "030811" },
  // { label: "Wawa", mun_code: "030811" },
  // { label: "East Calaguiman (Pob.)", mun_code: "030812" },
  // { label: "East Daang Bago (Pob.)", mun_code: "030812" },
  // { label: "Ibaba (Pob.)", mun_code: "030812" },
  // { label: "Imelda", mun_code: "030812" },
  // { label: "Lalawigan", mun_code: "030812" },
  // { label: "Palili", mun_code: "030812" },
  // { label: "San Juan (Pob.)", mun_code: "030812" },
  // { label: "San Roque (Pob.)", mun_code: "030812" },
  // { label: "Santa Lucia", mun_code: "030812" },
  // { label: "Sapa", mun_code: "030812" },
  // { label: "Tabing Ilog", mun_code: "030812" },
  // { label: "Gugo", mun_code: "030812" },
  // { label: "West Calaguiman (Pob.)", mun_code: "030812" },
  // { label: "West Daang Bago (Pob.)", mun_code: "030812" },
  // { label: "Banaban", mun_code: "031401" },
  // { label: "Baybay", mun_code: "031401" },
  // { label: "Binagbag", mun_code: "031401" },
  // { label: "Donacion", mun_code: "031401" },
  // { label: "Encanto", mun_code: "031401" },
  // { label: "Laog", mun_code: "031401" },
  // { label: "Marungko", mun_code: "031401" },
  // { label: "Niugan", mun_code: "031401" },
  // { label: "Paltok", mun_code: "031401" },
  // { label: "Pulong Yantok", mun_code: "031401" },
  // { label: "San Roque (Pob.)", mun_code: "031401" },
  // { label: "Santa Cruz (Pob.)", mun_code: "031401" },
  // { label: "Santa Lucia", mun_code: "031401" },
  // { label: "Santo Cristo (Pob.)", mun_code: "031401" },
  // { label: "Sulucan", mun_code: "031401" },
  // { label: "Taboc", mun_code: "031401" },
  // { label: "Borol 2nd", mun_code: "031402" },
  // { label: "Borol 1st", mun_code: "031402" },
  // { label: "Dalig", mun_code: "031402" },
  // { label: "Longos", mun_code: "031402" },
  // { label: "Panginay", mun_code: "031402" },
  // { label: "Pulong Gubat", mun_code: "031402" },
  // { label: "San Juan", mun_code: "031402" },
  // { label: "Santol", mun_code: "031402" },
  // { label: "Wawa (Pob.)", mun_code: "031402" },
  // { label: "Bagong Nayon", mun_code: "031403" },
  // { label: "Barangca", mun_code: "031403" },
  // { label: "Calantipay", mun_code: "031403" },
  // { label: "Catulinan", mun_code: "031403" },
  // { label: "Concepcion", mun_code: "031403" },
  // { label: "Hinukay", mun_code: "031403" },
  // { label: "Makinabang", mun_code: "031403" },
  // { label: "Matangtubig", mun_code: "031403" },
  // { label: "Pagala", mun_code: "031403" },
  // { label: "Paitan", mun_code: "031403" },
  // { label: "Piel", mun_code: "031403" },
  // { label: "Pinagbarilan", mun_code: "031403" },
  // { label: "Poblacion", mun_code: "031403" },
  // { label: "Sabang", mun_code: "031403" },
  // { label: "San Jose", mun_code: "031403" },
  // { label: "San Roque", mun_code: "031403" },
  // { label: "Santa Barbara", mun_code: "031403" },
  // { label: "Santo Cristo", mun_code: "031403" },
  // { label: "Subic", mun_code: "031403" },
  // { label: "Sulivan", mun_code: "031403" },
  // { label: "Tangos", mun_code: "031403" },
  // { label: "Tarcan", mun_code: "031403" },
  // { label: "Tiaong", mun_code: "031403" },
  // { label: "Tibag", mun_code: "031403" },
  // { label: "Tilapayong", mun_code: "031403" },
  // { label: "Virgen delas Flores", mun_code: "031403" },
  // { label: "Antipona", mun_code: "031404" },
  // { label: "Bagumbayan", mun_code: "031404" },
  // { label: "Bambang", mun_code: "031404" },
  // { label: "Batia", mun_code: "031404" },
  // { label: "Bolacan", mun_code: "031404" },
  // { label: "Bundukan", mun_code: "031404" },
  // { label: "Bunlo", mun_code: "031404" },
  // { label: "Caingin", mun_code: "031404" },
  // { label: "Duhat", mun_code: "031404" },
  // { label: "Igulot", mun_code: "031404" },
  // { label: "Lolomboy", mun_code: "031404" },
  // { label: "Poblacion", mun_code: "031404" },
  // { label: "Sulucan", mun_code: "031404" },
  // { label: "Taal", mun_code: "031404" },
  // { label: "Tambobong", mun_code: "031404" },
  // { label: "Turo", mun_code: "031404" },
  // { label: "Wakas", mun_code: "031404" },
  // { label: "Bagumbayan", mun_code: "031405" },
  // { label: "Balubad", mun_code: "031405" },
  // { label: "Bambang", mun_code: "031405" },
  // { label: "Matungao", mun_code: "031405" },
  // { label: "Maysantol", mun_code: "031405" },
  // { label: "Perez", mun_code: "031405" },
  // { label: "Pitpitan", mun_code: "031405" },
  // { label: "San Francisco", mun_code: "031405" },
  // { label: "San Jose (Pob.)", mun_code: "031405" },
  // { label: "San Nicolas", mun_code: "031405" },
  // { label: "Santa Ana", mun_code: "031405" },
  // { label: "Santa Ines", mun_code: "031405" },
  // { label: "Taliptip", mun_code: "031405" },
  // { label: "Tibig", mun_code: "031405" },
  // { label: "Bonga Mayor", mun_code: "031406" },
  // { label: "Bonga Menor", mun_code: "031406" },
  // { label: "Buisan", mun_code: "031406" },
  // { label: "Camachilihan", mun_code: "031406" },
  // { label: "Cambaog", mun_code: "031406" },
  // { label: "Catacte", mun_code: "031406" },
  // { label: "Liciada", mun_code: "031406" },
  // { label: "Malamig", mun_code: "031406" },
  // { label: "Malawak", mun_code: "031406" },
  // { label: "Poblacion", mun_code: "031406" },
  // { label: "San Pedro", mun_code: "031406" },
  // { label: "Talampas", mun_code: "031406" },
  // { label: "Tanawan", mun_code: "031406" },
  // { label: "Tibagan", mun_code: "031406" },
  // { label: "Balite", mun_code: "031407" },
  // { label: "Balungao", mun_code: "031407" },
  // { label: "Buguion", mun_code: "031407" },
  // { label: "Bulusan", mun_code: "031407" },
  // { label: "Calizon", mun_code: "031407" },
  // { label: "Calumpang", mun_code: "031407" },
  // { label: "Caniogan", mun_code: "031407" },
  // { label: "Corazon", mun_code: "031407" },
  // { label: "Frances", mun_code: "031407" },
  // { label: "Gatbuca", mun_code: "031407" },
  // { label: "Gugo", mun_code: "031407" },
  // { label: "Iba Este", mun_code: "031407" },
  // { label: "Iba O'Este", mun_code: "031407" },
  // { label: "Longos", mun_code: "031407" },
  // { label: "Meysulao", mun_code: "031407" },
  // { label: "Meyto", mun_code: "031407" },
  // { label: "Palimbang", mun_code: "031407" },
  // { label: "Panducot", mun_code: "031407" },
  // { label: "Pio Cruzcosa", mun_code: "031407" },
  // { label: "Poblacion", mun_code: "031407" },
  // { label: "Pungo", mun_code: "031407" },
  // { label: "San Jose", mun_code: "031407" },
  // { label: "San Marcos", mun_code: "031407" },
  // { label: "San Miguel", mun_code: "031407" },
  // { label: "Santa Lucia", mun_code: "031407" },
  // { label: "Sapang Bayan", mun_code: "031407" },
  // { label: "Sergio Bayan", mun_code: "031407" },
  // { label: "Sucol", mun_code: "031407" },
  // { label: "Cutcut", mun_code: "031408" },
  // { label: "Daungan", mun_code: "031408" },
  // { label: "Ilang-Ilang", mun_code: "031408" },
  // { label: "Malis", mun_code: "031408" },
  // { label: "Panginay", mun_code: "031408" },
  // { label: "Poblacion", mun_code: "031408" },
  // { label: "Pritil", mun_code: "031408" },
  // { label: "Pulong Gubat", mun_code: "031408" },
  // { label: "Santa Cruz", mun_code: "031408" },
  // { label: "Santa Rita", mun_code: "031408" },
  // { label: "Tabang", mun_code: "031408" },
  // { label: "Tabe", mun_code: "031408" },
  // { label: "Tiaong", mun_code: "031408" },
  // { label: "Tuktukan", mun_code: "031408" },
  // { label: "Abulalas", mun_code: "031409" },
  // { label: "Carillo", mun_code: "031409" },
  // { label: "Iba", mun_code: "031409" },
  // { label: "Mercado", mun_code: "031409" },
  // { label: "Palapat", mun_code: "031409" },
  // { label: "Pugad", mun_code: "031409" },
  // { label: "Sagrada Familia", mun_code: "031409" },
  // { label: "San Agustin", mun_code: "031409" },
  // { label: "San Isidro", mun_code: "031409" },
  // { label: "San Jose", mun_code: "031409" },
  // { label: "San Juan", mun_code: "031409" },
  // { label: "San Miguel", mun_code: "031409" },
  // { label: "San Nicolas", mun_code: "031409" },
  // { label: "San Pablo", mun_code: "031409" },
  // { label: "San Pascual", mun_code: "031409" },
  // { label: "San Pedro", mun_code: "031409" },
  // { label: "San Roque", mun_code: "031409" },
  // { label: "San Sebastian", mun_code: "031409" },
  // { label: "Santa Cruz", mun_code: "031409" },
  // { label: "Santa Elena", mun_code: "031409" },
  // { label: "Santa Monica", mun_code: "031409" },
  // { label: "Santo Rosario", mun_code: "031409" },
  // { label: "Tampok", mun_code: "031409" },
  // { label: "Tibaguin", mun_code: "031409" },
  // { label: "Iba-Ibayo", mun_code: "031409" },
  // { label: "Anilao", mun_code: "031410" },
  // { label: "Atlag", mun_code: "031410" },
  // { label: "Babatnin", mun_code: "031410" },
  // { label: "Bagna", mun_code: "031410" },
  // { label: "Bagong Bayan", mun_code: "031410" },
  // { label: "Balayong", mun_code: "031410" },
  // { label: "Balite", mun_code: "031410" },
  // { label: "Bangkal", mun_code: "031410" },
  // { label: "Barihan", mun_code: "031410" },
  // { label: "Bulihan", mun_code: "031410" },
  // { label: "Bungahan", mun_code: "031410" },
  // { label: "Dakila", mun_code: "031410" },
  // { label: "Guinhawa", mun_code: "031410" },
  // { label: "Caingin", mun_code: "031410" },
  // { label: "Calero", mun_code: "031410" },
  // { label: "Caliligawan", mun_code: "031410" },
  // { label: "Canalate", mun_code: "031410" },
  // { label: "Caniogan", mun_code: "031410" },
  // { label: "Catmon", mun_code: "031410" },
  // { label: "Ligas", mun_code: "031410" },
  // { label: "Liyang", mun_code: "031410" },
  // { label: "Longos", mun_code: "031410" },
  // { label: "Look 1st", mun_code: "031410" },
  // { label: "Look 2nd", mun_code: "031410" },
  // { label: "Lugam", mun_code: "031410" },
  // { label: "Mabolo", mun_code: "031410" },
  // { label: "Mambog", mun_code: "031410" },
  // { label: "Masile", mun_code: "031410" },
  // { label: "Matimbo", mun_code: "031410" },
  // { label: "Mojon", mun_code: "031410" },
  // { label: "Namayan", mun_code: "031410" },
  // { label: "Niugan", mun_code: "031410" },
  // { label: "Pamarawan", mun_code: "031410" },
  // { label: "Panasahan", mun_code: "031410" },
  // { label: "Pinagbakahan", mun_code: "031410" },
  // { label: "San Agustin", mun_code: "031410" },
  // { label: "San Gabriel", mun_code: "031410" },
  // { label: "San Juan", mun_code: "031410" },
  // { label: "San Pablo", mun_code: "031410" },
  // { label: "San Vicente (Pob.)", mun_code: "031410" },
  // { label: "Santiago", mun_code: "031410" },
  // { label: "Santisima Trinidad", mun_code: "031410" },
  // { label: "Santo Cristo", mun_code: "031410" },
  // { label: "Santo Rosario (Pob.)", mun_code: "031410" },
  // { label: "Santol", mun_code: "031410" },
  // { label: "Sumapang Bata", mun_code: "031410" },
  // { label: "Sumapang Matanda", mun_code: "031410" },
  // { label: "Taal", mun_code: "031410" },
  // { label: "Tikay", mun_code: "031410" },
  // { label: "Cofradia", mun_code: "031410" },
  // { label: "Abangan Norte", mun_code: "031411" },
  // { label: "Abangan Sur", mun_code: "031411" },
  // { label: "Ibayo", mun_code: "031411" },
  // { label: "Lambakin", mun_code: "031411" },
  // { label: "Lias", mun_code: "031411" },
  // { label: "Loma de Gato", mun_code: "031411" },
  // { label: "Nagbalon", mun_code: "031411" },
  // { label: "Patubig", mun_code: "031411" },
  // { label: "Poblacion I", mun_code: "031411" },
  // { label: "Poblacion II", mun_code: "031411" },
  // { label: "Prenza I", mun_code: "031411" },
  // { label: "Prenza II", mun_code: "031411" },
  // { label: "Santa Rosa I", mun_code: "031411" },
  // { label: "Santa Rosa II", mun_code: "031411" },
  // { label: "Saog", mun_code: "031411" },
  // { label: "Tabing Ilog", mun_code: "031411" },
  // { label: "Bagbaguin", mun_code: "031412" },
  // { label: "Bahay Pare", mun_code: "031412" },
  // { label: "Bancal", mun_code: "031412" },
  // { label: "Banga", mun_code: "031412" },
  // { label: "Bayugo", mun_code: "031412" },
  // { label: "Calvario", mun_code: "031412" },
  // { label: "Camalig", mun_code: "031412" },
  // { label: "Hulo", mun_code: "031412" },
  // { label: "Iba", mun_code: "031412" },
  // { label: "Langka", mun_code: "031412" },
  // { label: "Lawa", mun_code: "031412" },
  // { label: "Libtong", mun_code: "031412" },
  // { label: "Liputan", mun_code: "031412" },
  // { label: "Longos", mun_code: "031412" },
  // { label: "Malhacan", mun_code: "031412" },
  // { label: "Pajo", mun_code: "031412" },
  // { label: "Pandayan", mun_code: "031412" },
  // { label: "Pantoc", mun_code: "031412" },
  // { label: "Perez", mun_code: "031412" },
  // { label: "Poblacion", mun_code: "031412" },
  // { label: "Saluysoy", mun_code: "031412" },
  // { label: "Saint Francis (Gasak)", mun_code: "031412" },
  // { label: "Tugatog", mun_code: "031412" },
  // { label: "Ubihan", mun_code: "031412" },
  // { label: "Zamora", mun_code: "031412" },
  // { label: "Caingin", mun_code: "031412" },
  // { label: "Bangkal", mun_code: "031413" },
  // { label: "Baraka", mun_code: "031413" },
  // { label: "Bigte", mun_code: "031413" },
  // { label: "Bitungol", mun_code: "031413" },
  // { label: "Matictic", mun_code: "031413" },
  // { label: "Minuyan", mun_code: "031413" },
  // { label: "Partida", mun_code: "031413" },
  // { label: "Pinagtulayan", mun_code: "031413" },
  // { label: "Poblacion", mun_code: "031413" },
  // { label: "San Mateo", mun_code: "031413" },
  // { label: "Tigbe", mun_code: "031413" },
  // { label: "San Lorenzo", mun_code: "031413" },
  // { label: "Friendship Village Resources (FVR)", mun_code: "031413" },
  // { label: "Binuangan", mun_code: "031414" },
  // { label: "Catanghalan", mun_code: "031414" },
  // { label: "Hulo", mun_code: "031414" },
  // { label: "Lawa", mun_code: "031414" },
  // { label: "Salambao", mun_code: "031414" },
  // { label: "Paco", mun_code: "031414" },
  // { label: "Pag-asa (Pob.)", mun_code: "031414" },
  // { label: "Paliwas", mun_code: "031414" },
  // { label: "Panghulo", mun_code: "031414" },
  // { label: "San Pascual", mun_code: "031414" },
  // { label: "Tawiran", mun_code: "031414" },
  // { label: "Bagbaguin", mun_code: "031415" },
  // { label: "Bagong Barrio", mun_code: "031415" },
  // { label: "Bunsuran III", mun_code: "031415" },
  // { label: "Bunsuran I", mun_code: "031415" },
  // { label: "Bunsuran II", mun_code: "031415" },
  // { label: "Cacarong Bata", mun_code: "031415" },
  // { label: "Cacarong Matanda", mun_code: "031415" },
  // { label: "Cupang", mun_code: "031415" },
  // { label: "Malibong Bata", mun_code: "031415" },
  // { label: "Malibong Matanda", mun_code: "031415" },
  // { label: "Manatal", mun_code: "031415" },
  // { label: "Mapulang Lupa", mun_code: "031415" },
  // { label: "Masagana", mun_code: "031415" },
  // { label: "Masuso", mun_code: "031415" },
  // { label: "Pinagkuartelan", mun_code: "031415" },
  // { label: "Poblacion", mun_code: "031415" },
  // { label: "Real de Cacarong", mun_code: "031415" },
  // { label: "San Roque", mun_code: "031415" },
  // { label: "Siling Bata", mun_code: "031415" },
  // { label: "Siling Matanda", mun_code: "031415" },
  // { label: "Baka-bakahan", mun_code: "031415" },
  // { label: "Binakod", mun_code: "031416" },
  // { label: "Kapitangan", mun_code: "031416" },
  // { label: "Malumot", mun_code: "031416" },
  // { label: "Masukol", mun_code: "031416" },
  // { label: "Pinalagdan", mun_code: "031416" },
  // { label: "Poblacion", mun_code: "031416" },
  // { label: "San Isidro I", mun_code: "031416" },
  // { label: "San Isidro II", mun_code: "031416" },
  // { label: "San Jose", mun_code: "031416" },
  // { label: "San Roque", mun_code: "031416" },
  // { label: "San Vicente", mun_code: "031416" },
  // { label: "Santa Cruz", mun_code: "031416" },
  // { label: "Santo Rosario", mun_code: "031416" },
  // { label: "Agnaya", mun_code: "031417" },
  // { label: "Bagong Silang", mun_code: "031417" },
  // { label: "Banga I", mun_code: "031417" },
  // { label: "Banga II", mun_code: "031417" },
  // { label: "Bintog", mun_code: "031417" },
  // { label: "Bulihan", mun_code: "031417" },
  // { label: "Culianin", mun_code: "031417" },
  // { label: "Dampol", mun_code: "031417" },
  // { label: "Lagundi", mun_code: "031417" },
  // { label: "Lalangan", mun_code: "031417" },
  // { label: "Lumang Bayan", mun_code: "031417" },
  // { label: "Parulan", mun_code: "031417" },
  // { label: "Poblacion", mun_code: "031417" },
  // { label: "Rueda", mun_code: "031417" },
  // { label: "San Jose", mun_code: "031417" },
  // { label: "Santa Ines", mun_code: "031417" },
  // { label: "Sipat", mun_code: "031417" },
  // { label: "Tabang", mun_code: "031417" },
  // { label: "Balatong A", mun_code: "031418" },
  // { label: "Balatong B", mun_code: "031418" },
  // { label: "Cutcot", mun_code: "031418" },
  // { label: "Dampol I", mun_code: "031418" },
  // { label: "Dampol II-A", mun_code: "031418" },
  // { label: "Dampol II-B", mun_code: "031418" },
  // { label: "Dulong Malabon", mun_code: "031418" },
  // { label: "Inaon", mun_code: "031418" },
  // { label: "Longos", mun_code: "031418" },
  // { label: "Lumbac", mun_code: "031418" },
  // { label: "Paltao", mun_code: "031418" },
  // { label: "Penabatan", mun_code: "031418" },
  // { label: "Poblacion", mun_code: "031418" },
  // { label: "Santa Peregrina", mun_code: "031418" },
  // { label: "Santo Cristo", mun_code: "031418" },
  // { label: "Taal", mun_code: "031418" },
  // { label: "Tabon", mun_code: "031418" },
  // { label: "Tibag", mun_code: "031418" },
  // { label: "Tinejero", mun_code: "031418" },
  // { label: "Akle", mun_code: "031419" },
  // { label: "Alagao", mun_code: "031419" },
  // { label: "Anyatam", mun_code: "031419" },
  // { label: "Bagong Barrio", mun_code: "031419" },
  // { label: "Basuit", mun_code: "031419" },
  // { label: "Bubulong Munti", mun_code: "031419" },
  // { label: "Bubulong Malaki", mun_code: "031419" },
  // { label: "Buhol na Mangga", mun_code: "031419" },
  // { label: "Bulusukan", mun_code: "031419" },
  // { label: "Calasag", mun_code: "031419" },
  // { label: "Calawitan", mun_code: "031419" },
  // { label: "Casalat", mun_code: "031419" },
  // { label: "Gabihan", mun_code: "031419" },
  // { label: "Garlang", mun_code: "031419" },
  // { label: "Lapnit", mun_code: "031419" },
  // { label: "Maasim", mun_code: "031419" },
  // { label: "Makapilapil", mun_code: "031419" },
  // { label: "Malipampang", mun_code: "031419" },
  // { label: "Matimbubong", mun_code: "031419" },
  // { label: "Nabaong Garlang", mun_code: "031419" },
  // { label: "Palapala", mun_code: "031419" },
  // { label: "Pasong Bangkal", mun_code: "031419" },
  // { label: "Pinaod", mun_code: "031419" },
  // { label: "Poblacion", mun_code: "031419" },
  // { label: "Pulong Tamo", mun_code: "031419" },
  // { label: "San Juan", mun_code: "031419" },
  // { label: "Santa Catalina Bata", mun_code: "031419" },
  // { label: "Santa Catalina Matanda", mun_code: "031419" },
  // { label: "Sapang Dayap", mun_code: "031419" },
  // { label: "Sapang Putik", mun_code: "031419" },
  // { label: "Sapang Putol", mun_code: "031419" },
  // { label: "Sumandig", mun_code: "031419" },
  // { label: "Telepatio", mun_code: "031419" },
  // { label: "Upig", mun_code: "031419" },
  // { label: "Umpucan", mun_code: "031419" },
  // { label: "Mataas na Parang", mun_code: "031419" },
  // { label: "Bagong Buhay", mun_code: "031420" },
  // { label: "Dulong Bayan", mun_code: "031420" },
  // { label: "Gaya-gaya", mun_code: "031420" },
  // { label: "Kaypian", mun_code: "031420" },
  // { label: "Kaybanban", mun_code: "031420" },
  // { label: "Minuyan", mun_code: "031420" },
  // { label: "Muzon", mun_code: "031420" },
  // { label: "Poblacion", mun_code: "031420" },
  // { label: "Santo Cristo", mun_code: "031420" },
  // { label: "Sapang Palay", mun_code: "031420" },
  // { label: "Tungkong Mangga", mun_code: "031420" },
  // { label: "Citrus", mun_code: "031420" },
  // { label: "San Martin", mun_code: "031420" },
  // { label: "Santa Cruz", mun_code: "031420" },
  // { label: "Fatima", mun_code: "031420" },
  // { label: "San Pedro", mun_code: "031420" },
  // { label: "San Rafael", mun_code: "031420" },
  // { label: "Assumption", mun_code: "031420" },
  // { label: "Bagong Buhay II", mun_code: "031420" },
  // { label: "Bagong Buhay III", mun_code: "031420" },
  // { label: "Ciudad Real", mun_code: "031420" },
  // { label: "Fatima II", mun_code: "031420" },
  // { label: "Fatima III", mun_code: "031420" },
  // { label: "Fatima IV", mun_code: "031420" },
  // { label: "Fatima V", mun_code: "031420" },
  // { label: "Francisco Homes-Guijo", mun_code: "031420" },
  // { label: "Francisco Homes-Mulawin", mun_code: "031420" },
  // { label: "Francisco Homes-Narra", mun_code: "031420" },
  // { label: "Francisco Homes-Yakal", mun_code: "031420" },
  // { label: "Graceville", mun_code: "031420" },
  // { label: "Gumaoc Central", mun_code: "031420" },
  // { label: "Gumaoc East", mun_code: "031420" },
  // { label: "Gumaoc West", mun_code: "031420" },
  // { label: "Lawang Pari", mun_code: "031420" },
  // { label: "Maharlika", mun_code: "031420" },
  // { label: "Minuyan II", mun_code: "031420" },
  // { label: "Minuyan III", mun_code: "031420" },
  // { label: "Minuyan IV", mun_code: "031420" },
  // { label: "Minuyan V", mun_code: "031420" },
  // { label: "Minuyan Proper", mun_code: "031420" },
  // { label: "Paradise III", mun_code: "031420" },
  // { label: "Poblacion I", mun_code: "031420" },
  // { label: "San Isidro", mun_code: "031420" },
  // { label: "San Manuel", mun_code: "031420" },
  // { label: "San Martin II", mun_code: "031420" },
  // { label: "San Martin III", mun_code: "031420" },
  // { label: "San Martin IV", mun_code: "031420" },
  // { label: "San Rafael I", mun_code: "031420" },
  // { label: "San Rafael III", mun_code: "031420" },
  // { label: "San Rafael IV", mun_code: "031420" },
  // { label: "San Rafael V", mun_code: "031420" },
  // { label: "San Roque", mun_code: "031420" },
  // { label: "Santa Cruz II", mun_code: "031420" },
  // { label: "Santa Cruz III", mun_code: "031420" },
  // { label: "Santa Cruz IV", mun_code: "031420" },
  // { label: "Santa Cruz V", mun_code: "031420" },
  // { label: "St. Martin de Porres", mun_code: "031420" },
  // { label: "Bagong Silang", mun_code: "031421" },
  // { label: "Balaong", mun_code: "031421" },
  // { label: "Balite", mun_code: "031421" },
  // { label: "Bantog", mun_code: "031421" },
  // { label: "Bardias", mun_code: "031421" },
  // { label: "Baritan", mun_code: "031421" },
  // { label: "Batasan Bata", mun_code: "031421" },
  // { label: "Batasan Matanda", mun_code: "031421" },
  // { label: "Biak-na-Bato", mun_code: "031421" },
  // { label: "Biclat", mun_code: "031421" },
  // { label: "Buga", mun_code: "031421" },
  // { label: "Buliran", mun_code: "031421" },
  // { label: "Bulualto", mun_code: "031421" },
  // { label: "Calumpang", mun_code: "031421" },
  // { label: "Cambio", mun_code: "031421" },
  // { label: "Camias", mun_code: "031421" },
  // { label: "Ilog-Bulo", mun_code: "031421" },
  // { label: "King Kabayo", mun_code: "031421" },
  // { label: "Labne", mun_code: "031421" },
  // { label: "Lambakin", mun_code: "031421" },
  // { label: "Magmarale", mun_code: "031421" },
  // { label: "Malibay", mun_code: "031421" },
  // { label: "Mandile", mun_code: "031421" },
  // { label: "Masalipit", mun_code: "031421" },
  // { label: "Pacalag", mun_code: "031421" },
  // { label: "Paliwasan", mun_code: "031421" },
  // { label: "Partida", mun_code: "031421" },
  // { label: "Pinambaran", mun_code: "031421" },
  // { label: "Poblacion", mun_code: "031421" },
  // { label: "Pulong Bayabas", mun_code: "031421" },
  // { label: "Sacdalan", mun_code: "031421" },
  // { label: "Salacot", mun_code: "031421" },
  // { label: "Salangan", mun_code: "031421" },
  // { label: "San Agustin", mun_code: "031421" },
  // { label: "San Jose", mun_code: "031421" },
  // { label: "San Juan", mun_code: "031421" },
  // { label: "San Vicente", mun_code: "031421" },
  // { label: "Santa Ines", mun_code: "031421" },
  // { label: "Santa Lucia", mun_code: "031421" },
  // { label: "Santa Rita Bata", mun_code: "031421" },
  // { label: "Santa Rita Matanda", mun_code: "031421" },
  // { label: "Sapang", mun_code: "031421" },
  // { label: "Sibul", mun_code: "031421" },
  // { label: "Tartaro", mun_code: "031421" },
  // { label: "Tibagan", mun_code: "031421" },
  // { label: "Bagong Pag-asa", mun_code: "031421" },
  // { label: "Pulong Duhat", mun_code: "031421" },
  // { label: "Maligaya", mun_code: "031421" },
  // { label: "Tigpalas", mun_code: "031421" },
  // { label: "BMA-Balagtas", mun_code: "031422" },
  // { label: "Banca-banca", mun_code: "031422" },
  // { label: "Caingin", mun_code: "031422" },
  // { label: "Coral na Bato", mun_code: "031422" },
  // { label: "Cruz na Daan", mun_code: "031422" },
  // { label: "Dagat-dagatan", mun_code: "031422" },
  // { label: "Diliman I", mun_code: "031422" },
  // { label: "Diliman II", mun_code: "031422" },
  // { label: "Capihan", mun_code: "031422" },
  // { label: "Libis", mun_code: "031422" },
  // { label: "Lico", mun_code: "031422" },
  // { label: "Maasim", mun_code: "031422" },
  // { label: "Mabalas-balas", mun_code: "031422" },
  // { label: "Maguinao", mun_code: "031422" },
  // { label: "Maronguillo", mun_code: "031422" },
  // { label: "Paco", mun_code: "031422" },
  // { label: "Pansumaloc", mun_code: "031422" },
  // { label: "Pantubig", mun_code: "031422" },
  // { label: "Pasong Bangkal", mun_code: "031422" },
  // { label: "Pasong Callos", mun_code: "031422" },
  // { label: "Pasong Intsik", mun_code: "031422" },
  // { label: "Pinacpinacan", mun_code: "031422" },
  // { label: "Poblacion", mun_code: "031422" },
  // { label: "Pulo", mun_code: "031422" },
  // { label: "Pulong Bayabas", mun_code: "031422" },
  // { label: "Salapungan", mun_code: "031422" },
  // { label: "Sampaloc", mun_code: "031422" },
  // { label: "San Agustin", mun_code: "031422" },
  // { label: "San Roque", mun_code: "031422" },
  // { label: "Talacsan", mun_code: "031422" },
  // { label: "Tambubong", mun_code: "031422" },
  // { label: "Tukod", mun_code: "031422" },
  // { label: "Ulingao", mun_code: "031422" },
  // { label: "Sapang Pahalang", mun_code: "031422" },
  // { label: "Bagbaguin", mun_code: "031423" },
  // { label: "Balasing", mun_code: "031423" },
  // { label: "Buenavista", mun_code: "031423" },
  // { label: "Bulac", mun_code: "031423" },
  // { label: "Camangyanan", mun_code: "031423" },
  // { label: "Catmon", mun_code: "031423" },
  // { label: "Cay Pombo", mun_code: "031423" },
  // { label: "Caysio", mun_code: "031423" },
  // { label: "Guyong", mun_code: "031423" },
  // { label: "Lalakhan", mun_code: "031423" },
  // { label: "Mag-asawang Sapa", mun_code: "031423" },
  // { label: "Mahabang Parang", mun_code: "031423" },
  // { label: "Manggahan", mun_code: "031423" },
  // { label: "Parada", mun_code: "031423" },
  // { label: "Poblacion", mun_code: "031423" },
  // { label: "Pulong Buhangin", mun_code: "031423" },
  // { label: "San Gabriel", mun_code: "031423" },
  // { label: "San Jose Patag", mun_code: "031423" },
  // { label: "San Vicente", mun_code: "031423" },
  // { label: "Santa Clara", mun_code: "031423" },
  // { label: "Santa Cruz", mun_code: "031423" },
  // { label: "Silangan", mun_code: "031423" },
  // { label: "Tabing Bakod", mun_code: "031423" },
  // { label: "Tumana", mun_code: "031423" },
  // { label: "Bayabas", mun_code: "031424" },
  // { label: "Kabayunan", mun_code: "031424" },
  // { label: "Camachin", mun_code: "031424" },
  // { label: "Camachile", mun_code: "031424" },
  // { label: "Kalawakan", mun_code: "031424" },
  // { label: "Pulong Sampalok", mun_code: "031424" },
  // { label: "Talbak", mun_code: "031424" },
  // { label: "Sapang Bulak", mun_code: "031424" },
  // { label: "Betes", mun_code: "034901" },
  // { label: "Bibiclat", mun_code: "034901" },
  // { label: "Bucot", mun_code: "034901" },
  // { label: "La Purisima", mun_code: "034901" },
  // { label: "Magsaysay", mun_code: "034901" },
  // { label: "Macabucod", mun_code: "034901" },
  // { label: "Pantoc", mun_code: "034901" },
  // { label: "Poblacion Centro", mun_code: "034901" },
  // { label: "Poblacion East I", mun_code: "034901" },
  // { label: "Poblacion East II", mun_code: "034901" },
  // { label: "Poblacion West III", mun_code: "034901" },
  // { label: "Poblacion West IV", mun_code: "034901" },
  // { label: "San Carlos", mun_code: "034901" },
  // { label: "San Emiliano", mun_code: "034901" },
  // { label: "San Eustacio", mun_code: "034901" },
  // { label: "San Felipe Bata", mun_code: "034901" },
  // { label: "San Felipe Matanda", mun_code: "034901" },
  // { label: "San Juan", mun_code: "034901" },
  // { label: "San Pablo Bata", mun_code: "034901" },
  // { label: "San Pablo Matanda", mun_code: "034901" },
  // { label: "Santa Monica", mun_code: "034901" },
  // { label: "Santiago", mun_code: "034901" },
  // { label: "Santo Rosario", mun_code: "034901" },
  // { label: "Santo Tomas", mun_code: "034901" },
  // { label: "Sunson", mun_code: "034901" },
  // { label: "Umangan", mun_code: "034901" },
  // { label: "Antipolo", mun_code: "034902" },
  // { label: "Ariendo", mun_code: "034902" },
  // { label: "Bantug", mun_code: "034902" },
  // { label: "Calaanan", mun_code: "034902" },
  // { label: "Commercial (Pob.)", mun_code: "034902" },
  // { label: "Cruz", mun_code: "034902" },
  // { label: "Digmala", mun_code: "034902" },
  // { label: "Curva (J. Tomacruz)", mun_code: "034902" },
  // { label: "Kaingin (Pob.)", mun_code: "034902" },
  // { label: "Labi", mun_code: "034902" },
  // { label: "Larcon", mun_code: "034902" },
  // { label: "Lusok", mun_code: "034902" },
  // { label: "Macabaclay", mun_code: "034902" },
  // { label: "Magtanggol (Pob.)", mun_code: "034902" },
  // { label: "Mantile (Pob.)", mun_code: "034902" },
  // { label: "Olivete", mun_code: "034902" },
  // { label: "Palo Maria (Pob.)", mun_code: "034902" },
  // { label: "Pesa", mun_code: "034902" },
  // { label: "Rizal (Pob.)", mun_code: "034902" },
  // { label: "Sampalucan (Pob.)", mun_code: "034902" },
  // { label: "San Roque (Pob.)", mun_code: "034902" },
  // { label: "Santor", mun_code: "034902" },
  // { label: "Sinipit (Pob.)", mun_code: "034902" },
  // { label: "Sisilang na Ligaya (Pob.)", mun_code: "034902" },
  // { label: "Social (Pob.)", mun_code: "034902" },
  // { label: "Tugatug", mun_code: "034902" },
  // { label: "Tulay na Bato (Pob.)", mun_code: "034902" },
  // { label: "Vega", mun_code: "034902" },
  // { label: "Aduas Centro (Aduas)", mun_code: "034903" },
  // { label: "Bagong Sikat", mun_code: "034903" },
  // { label: "Bagong Buhay", mun_code: "034903" },
  // { label: "Bakero", mun_code: "034903" },
  // { label: "Bakod Bayan", mun_code: "034903" },
  // { label: "Balite", mun_code: "034903" },
  // { label: "Bangad", mun_code: "034903" },
  // { label: "Bantug Bulalo", mun_code: "034903" },
  // { label: "Bantug Norte", mun_code: "034903" },
  // { label: "Barlis", mun_code: "034903" },
  // { label: "Barrera District (Pob.)", mun_code: "034903" },
  // { label: "Bernardo District (Pob.)", mun_code: "034903" },
  // { label: "Bitas", mun_code: "034903" },
  // { label: "Bonifacio District (Pob.)", mun_code: "034903" },
  // { label: "Buliran", mun_code: "034903" },
  // { label: "Caalibangbangan", mun_code: "034903" },
  // { label: "Cabu", mun_code: "034903" },
  // { label: "Campo Tinio", mun_code: "034903" },
  // { label: "Kapitan Pepe (Pob.)", mun_code: "034903" },
  // { label: "Cinco-Cinco", mun_code: "034903" },
  // { label: "City Supermarket (Pob.)", mun_code: "034903" },
  // { label: "Caudillo", mun_code: "034903" },
  // { label: "Communal", mun_code: "034903" },
  // { label: "Cruz Roja", mun_code: "034903" },
  // { label: "Daang Sarile", mun_code: "034903" },
  // { label: "Dalampang", mun_code: "034903" },
  // { label: "Dicarma (Pob.)", mun_code: "034903" },
  // { label: "Dimasalang (Pob.)", mun_code: "034903" },
  // { label: "Dionisio S. Garcia", mun_code: "034903" },
  // { label: "Fatima (Pob.)", mun_code: "034903" },
  // { label: "General Luna (Pob.)", mun_code: "034903" },
  // { label: "Ibabao Bana", mun_code: "034903" },
  // { label: "Imelda District", mun_code: "034903" },
  // { label: "Isla (Pob.)", mun_code: "034903" },
  // { label: "Calawagan (Kalawagan)", mun_code: "034903" },
  // { label: "Kalikid Norte", mun_code: "034903" },
  // { label: "Kalikid Sur", mun_code: "034903" },
  // { label: "Lagare", mun_code: "034903" },
  // { label: "M. S. Garcia", mun_code: "034903" },
  // { label: "Mabini Extension", mun_code: "034903" },
  // { label: "Mabini Homesite", mun_code: "034903" },
  // { label: "Macatbong", mun_code: "034903" },
  // { label: "Magsaysay District", mun_code: "034903" },
  // { label: "Matadero (Pob.)", mun_code: "034903" },
  // { label: "Lourdes (Matungal-tungal)", mun_code: "034903" },
  // { label: "Mayapyap Norte", mun_code: "034903" },
  // { label: "Mayapyap Sur", mun_code: "034903" },
  // { label: "Melojavilla (Pob.)", mun_code: "034903" },
  // { label: "Obrero", mun_code: "034903" },
  // { label: "Padre Crisostomo", mun_code: "034903" },
  // { label: "Pagas", mun_code: "034903" },
  // { label: "Palagay", mun_code: "034903" },
  // { label: "Pamaldan", mun_code: "034903" },
  // { label: "Pangatian", mun_code: "034903" },
  // { label: "Patalac", mun_code: "034903" },
  // { label: "Polilio", mun_code: "034903" },
  // { label: "Pula", mun_code: "034903" },
  // { label: "Quezon District (Pob.)", mun_code: "034903" },
  // { label: "Rizdelis (Pob.)", mun_code: "034903" },
  // { label: "Samon", mun_code: "034903" },
  // { label: "San Isidro", mun_code: "034903" },
  // { label: "San Josef Norte", mun_code: "034903" },
  // { label: "San Josef Sur", mun_code: "034903" },
  // { label: "San Juan Pob. (Acofa)", mun_code: "034903" },
  // { label: "San Roque Norte", mun_code: "034903" },
  // { label: "San Roque Sur", mun_code: "034903" },
  // { label: "Sanbermicristi (Pob.)", mun_code: "034903" },
  // { label: "Sangitan", mun_code: "034903" },
  // { label: "Santa Arcadia", mun_code: "034903" },
  // { label: "Sumacab Norte", mun_code: "034903" },
  // { label: "Valdefuente", mun_code: "034903" },
  // { label: "Valle Cruz", mun_code: "034903" },
  // { label: "Vijandre District (Pob.)", mun_code: "034903" },
  // { label: "Villa Ofelia-Caridad", mun_code: "034903" },
  // { label: "Zulueta District (Pob.)", mun_code: "034903" },
  // { label: "Nabao (Pob.)", mun_code: "034903" },
  // { label: "Padre Burgos (Pob.)", mun_code: "034903" },
  // { label: "Talipapa", mun_code: "034903" },
  // { label: "Aduas Norte", mun_code: "034903" },
  // { label: "Aduas Sur", mun_code: "034903" },
  // { label: "Hermogenes C. Concepcion, Sr.", mun_code: "034903" },
  // { label: "Sapang", mun_code: "034903" },
  // { label: "Sumacab Este", mun_code: "034903" },
  // { label: "Sumacab South", mun_code: "034903" },
  // { label: "Caridad", mun_code: "034903" },
  // { label: "Magsaysay South", mun_code: "034903" },
  // { label: "Maria Theresa", mun_code: "034903" },
  // { label: "Sangitan East", mun_code: "034903" },
  // { label: "Bagong Buhay", mun_code: "034904" },
  // { label: "Bagong Sikat", mun_code: "034904" },
  // { label: "Bagong Silang", mun_code: "034904" },
  // { label: "Concepcion", mun_code: "034904" },
  // { label: "Entablado", mun_code: "034904" },
  // { label: "Maligaya", mun_code: "034904" },
  // { label: "Natividad North (Pob.)", mun_code: "034904" },
  // { label: "Natividad South (Pob.)", mun_code: "034904" },
  // { label: "Palasinan", mun_code: "034904" },
  // { label: "San Antonio", mun_code: "034904" },
  // { label: "San Fernando Norte", mun_code: "034904" },
  // { label: "San Fernando Sur", mun_code: "034904" },
  // { label: "San Gregorio", mun_code: "034904" },
  // { label: "San Juan North (Pob.)", mun_code: "034904" },
  // { label: "San Juan South (Pob.)", mun_code: "034904" },
  // { label: "San Roque", mun_code: "034904" },
  // { label: "San Vicente", mun_code: "034904" },
  // { label: "Santa Rita", mun_code: "034904" },
  // { label: "Sinipit", mun_code: "034904" },
  // { label: "Polilio", mun_code: "034904" },
  // { label: "San Carlos", mun_code: "034904" },
  // { label: "Santa Isabel", mun_code: "034904" },
  // { label: "Santa Ines", mun_code: "034904" },
  // { label: "R.A.Padilla (Baluarte)", mun_code: "034905" },
  // { label: "Bantug", mun_code: "034905" },
  // { label: "Bunga", mun_code: "034905" },
  // { label: "Burgos", mun_code: "034905" },
  // { label: "Capintalan", mun_code: "034905" },
  // { label: "Joson (Digidig)", mun_code: "034905" },
  // { label: "General Luna", mun_code: "034905" },
  // { label: "Minuli", mun_code: "034905" },
  // { label: "Piut", mun_code: "034905" },
  // { label: "Puncan", mun_code: "034905" },
  // { label: "Putlan", mun_code: "034905" },
  // { label: "Salazar", mun_code: "034905" },
  // { label: "San Agustin", mun_code: "034905" },
  // { label: "T. L. Padilla Pob. (Bgy. I)", mun_code: "034905" },
  // { label: "F. C. Otic Pob. (Bgy. II)", mun_code: "034905" },
  // { label: "D. L. Maglanoc Pob. (Bgy.III)", mun_code: "034905" },
  // { label: "G. S. Rosario Pob. (Bgy. IV)", mun_code: "034905" },
  // { label: "Baloy", mun_code: "034906" },
  // { label: "Bambanaba", mun_code: "034906" },
  // { label: "Bantug", mun_code: "034906" },
  // { label: "Bentigan", mun_code: "034906" },
  // { label: "Bibiclat", mun_code: "034906" },
  // { label: "Bonifacio", mun_code: "034906" },
  // { label: "Bued", mun_code: "034906" },
  // { label: "Bulala", mun_code: "034906" },
  // { label: "Burgos", mun_code: "034906" },
  // { label: "Cabileo", mun_code: "034906" },
  // { label: "Cabatuan", mun_code: "034906" },
  // { label: "Cacapasan", mun_code: "034906" },
  // { label: "Calancuasan Norte", mun_code: "034906" },
  // { label: "Calancuasan Sur", mun_code: "034906" },
  // { label: "Colosboa", mun_code: "034906" },
  // { label: "Columbitin", mun_code: "034906" },
  // { label: "Curva", mun_code: "034906" },
  // { label: "District I (Pob. I)", mun_code: "034906" },
  // { label: "District II (Pob. II)", mun_code: "034906" },
  // { label: "District IV (Pob. IV)", mun_code: "034906" },
  // { label: "District V (Pob. V)", mun_code: "034906" },
  // { label: "District VI (Pob. VI)", mun_code: "034906" },
  // { label: "District VII (Pob. VII)", mun_code: "034906" },
  // { label: "District VIII (Pob. VIII)", mun_code: "034906" },
  // { label: "Landig", mun_code: "034906" },
  // { label: "Latap", mun_code: "034906" },
  // { label: "Loob", mun_code: "034906" },
  // { label: "Luna", mun_code: "034906" },
  // { label: "Malbeg-Patalan", mun_code: "034906" },
  // { label: "Malineng", mun_code: "034906" },
  // { label: "Matindeg", mun_code: "034906" },
  // { label: "Maycaban", mun_code: "034906" },
  // { label: "Nacuralan", mun_code: "034906" },
  // { label: "Nagmisahan", mun_code: "034906" },
  // { label: "Paitan Norte", mun_code: "034906" },
  // { label: "Paitan Sur", mun_code: "034906" },
  // { label: "Piglisan", mun_code: "034906" },
  // { label: "Pugo", mun_code: "034906" },
  // { label: "Rizal", mun_code: "034906" },
  // { label: "Sabit", mun_code: "034906" },
  // { label: "Salagusog", mun_code: "034906" },
  // { label: "San Antonio", mun_code: "034906" },
  // { label: "San Jose", mun_code: "034906" },
  // { label: "San Juan", mun_code: "034906" },
  // { label: "Santa Clara", mun_code: "034906" },
  // { label: "Santa Cruz", mun_code: "034906" },
  // { label: "Sinimbaan", mun_code: "034906" },
  // { label: "Tagtagumbao", mun_code: "034906" },
  // { label: "Tutuloy", mun_code: "034906" },
  // { label: "Ungab", mun_code: "034906" },
  // { label: "Villaflores", mun_code: "034906" },
  // { label: "Bagong Sikat", mun_code: "034907" },
  // { label: "Bagting", mun_code: "034907" },
  // { label: "Bantug", mun_code: "034907" },
  // { label: "Bitulok (North Pob.)", mun_code: "034907" },
  // { label: "Bugnan", mun_code: "034907" },
  // { label: "Calabasa", mun_code: "034907" },
  // { label: "Camachile", mun_code: "034907" },
  // { label: "Cuyapa", mun_code: "034907" },
  // { label: "Ligaya", mun_code: "034907" },
  // { label: "Macasandal", mun_code: "034907" },
  // { label: "Malinao", mun_code: "034907" },
  // { label: "Pantoc", mun_code: "034907" },
  // { label: "Pinamalisan", mun_code: "034907" },
  // { label: "South Poblacion", mun_code: "034907" },
  // { label: "Sawmill", mun_code: "034907" },
  // { label: "Tagumpay", mun_code: "034907" },
  // { label: "Bayanihan", mun_code: "034908" },
  // { label: "Bulak", mun_code: "034908" },
  // { label: "Kapalangan", mun_code: "034908" },
  // { label: "Mahipon", mun_code: "034908" },
  // { label: "Malimba", mun_code: "034908" },
  // { label: "Mangino", mun_code: "034908" },
  // { label: "Marelo", mun_code: "034908" },
  // { label: "Pambuan", mun_code: "034908" },
  // { label: "Parcutela", mun_code: "034908" },
  // { label: "San Lorenzo (Pob.)", mun_code: "034908" },
  // { label: "San Nicolas", mun_code: "034908" },
  // { label: "San Roque", mun_code: "034908" },
  // { label: "San Vicente (Pob.)", mun_code: "034908" },
  // { label: "Santa Cruz", mun_code: "034908" },
  // { label: "Santo Cristo Norte", mun_code: "034908" },
  // { label: "Santo Cristo Sur", mun_code: "034908" },
  // { label: "Makabaclay", mun_code: "034908" },
  // { label: "Balante", mun_code: "034908" },
  // { label: "Bungo", mun_code: "034908" },
  // { label: "Mabunga", mun_code: "034908" },
  // { label: "Maburak", mun_code: "034908" },
  // { label: "Puting Tubig", mun_code: "034908" },
  // { label: "Balangkare Norte", mun_code: "034909" },
  // { label: "Balangkare Sur", mun_code: "034909" },
  // { label: "Balaring", mun_code: "034909" },
  // { label: "Belen", mun_code: "034909" },
  // { label: "Bravo", mun_code: "034909" },
  // { label: "Burol", mun_code: "034909" },
  // { label: "Kabulihan", mun_code: "034909" },
  // { label: "Mag-asawang Sampaloc", mun_code: "034909" },
  // { label: "Manarog", mun_code: "034909" },
  // { label: "Mataas na Kahoy", mun_code: "034909" },
  // { label: "Panacsac", mun_code: "034909" },
  // { label: "Picaleon", mun_code: "034909" },
  // { label: "Pinahan", mun_code: "034909" },
  // { label: "Platero", mun_code: "034909" },
  // { label: "Poblacion", mun_code: "034909" },
  // { label: "Pula", mun_code: "034909" },
  // { label: "Pulong Singkamas", mun_code: "034909" },
  // { label: "Sapang Bato", mun_code: "034909" },
  // { label: "Talabutab Norte", mun_code: "034909" },
  // { label: "Talabutab Sur", mun_code: "034909" },
  // { label: "Bago", mun_code: "034910" },
  // { label: "Concepcion", mun_code: "034910" },
  // { label: "Nazareth", mun_code: "034910" },
  // { label: "Padolina", mun_code: "034910" },
  // { label: "Pias", mun_code: "034910" },
  // { label: "San Pedro (Pob.)", mun_code: "034910" },
  // { label: "Poblacion East", mun_code: "034910" },
  // { label: "Poblacion West", mun_code: "034910" },
  // { label: "Rio Chico", mun_code: "034910" },
  // { label: "Poblacion Central", mun_code: "034910" },
  // { label: "Pulong Matong", mun_code: "034910" },
  // { label: "Sampaguita", mun_code: "034910" },
  // { label: "Palale", mun_code: "034910" },
  // { label: "Agcano", mun_code: "034911" },
  // { label: "Ayos Lomboy", mun_code: "034911" },
  // { label: "Bacayao", mun_code: "034911" },
  // { label: "Bagong Barrio", mun_code: "034911" },
  // { label: "Balbalino", mun_code: "034911" },
  // { label: "Balingog East", mun_code: "034911" },
  // { label: "Balingog West", mun_code: "034911" },
  // { label: "Banitan", mun_code: "034911" },
  // { label: "Bantug", mun_code: "034911" },
  // { label: "Bulakid", mun_code: "034911" },
  // { label: "Caballero", mun_code: "034911" },
  // { label: "Cabaruan", mun_code: "034911" },
  // { label: "Caingin Tabing Ilog", mun_code: "034911" },
  // { label: "Calem", mun_code: "034911" },
  // { label: "Camiling", mun_code: "034911" },
  // { label: "Cardinal", mun_code: "034911" },
  // { label: "Casongsong", mun_code: "034911" },
  // { label: "Catimon", mun_code: "034911" },
  // { label: "Cavite", mun_code: "034911" },
  // { label: "Cawayan Bugtong", mun_code: "034911" },
  // { label: "Consuelo", mun_code: "034911" },
  // { label: "Culong", mun_code: "034911" },
  // { label: "Escano", mun_code: "034911" },
  // { label: "Faigal", mun_code: "034911" },
  // { label: "Galvan", mun_code: "034911" },
  // { label: "Guiset", mun_code: "034911" },
  // { label: "Lamorito", mun_code: "034911" },
  // { label: "Lennec", mun_code: "034911" },
  // { label: "Macamias", mun_code: "034911" },
  // { label: "Macapabellag", mun_code: "034911" },
  // { label: "Macatcatuit", mun_code: "034911" },
  // { label: "Manacsac", mun_code: "034911" },
  // { label: "Manggang Marikit", mun_code: "034911" },
  // { label: "Maturanoc", mun_code: "034911" },
  // { label: "Maybubon", mun_code: "034911" },
  // { label: "Naglabrahan", mun_code: "034911" },
  // { label: "Nagpandayan", mun_code: "034911" },
  // { label: "Narvacan I", mun_code: "034911" },
  // { label: "Narvacan II", mun_code: "034911" },
  // { label: "Pacac", mun_code: "034911" },
  // { label: "Partida I", mun_code: "034911" },
  // { label: "Partida II", mun_code: "034911" },
  // { label: "Pasong Inchic", mun_code: "034911" },
  // { label: "Saint John District (Pob.)", mun_code: "034911" },
  // { label: "San Agustin", mun_code: "034911" },
  // { label: "San Andres", mun_code: "034911" },
  // { label: "San Bernardino", mun_code: "034911" },
  // { label: "San Marcelino", mun_code: "034911" },
  // { label: "San Miguel", mun_code: "034911" },
  // { label: "San Rafael", mun_code: "034911" },
  // { label: "San Roque", mun_code: "034911" },
  // { label: "Santa Ana", mun_code: "034911" },
  // { label: "Santa Cruz", mun_code: "034911" },
  // { label: "Santa Lucia", mun_code: "034911" },
  // { label: "Santa Veronica District (Pob.)", mun_code: "034911" },
  // { label: "Santo Cristo District (Pob.)", mun_code: "034911" },
  // { label: "Saranay District (Pob.)", mun_code: "034911" },
  // { label: "Sinulatan", mun_code: "034911" },
  // { label: "Subol", mun_code: "034911" },
  // { label: "Tampac I", mun_code: "034911" },
  // { label: "Tampac II & III", mun_code: "034911" },
  // { label: "Triala", mun_code: "034911" },
  // { label: "Yuson", mun_code: "034911" },
  // { label: "Bunol", mun_code: "034911" },
  // { label: "Calabasa", mun_code: "034912" },
  // { label: "Dampulan (Pob.)", mun_code: "034912" },
  // { label: "Hilera", mun_code: "034912" },
  // { label: "Imbunia", mun_code: "034912" },
  // { label: "Lambakin", mun_code: "034912" },
  // { label: "Langla", mun_code: "034912" },
  // { label: "Magsalisi", mun_code: "034912" },
  // { label: "Malabon-Kaingin", mun_code: "034912" },
  // { label: "Marawa", mun_code: "034912" },
  // { label: "Don Mariano Marcos (Pob.)", mun_code: "034912" },
  // { label: "San Josef (Nabao)", mun_code: "034912" },
  // { label: "Niyugan", mun_code: "034912" },
  // { label: "Pamacpacan", mun_code: "034912" },
  // { label: "Pakol", mun_code: "034912" },
  // { label: "Pinanggaan", mun_code: "034912" },
  // { label: "Ulanin-Pitak", mun_code: "034912" },
  // { label: "Putlod", mun_code: "034912" },
  // { label: "Ocampo-Rivera District (Pob.)", mun_code: "034912" },
  // { label: "San Jose", mun_code: "034912" },
  // { label: "San Pablo", mun_code: "034912" },
  // { label: "San Roque", mun_code: "034912" },
  // { label: "San Vicente", mun_code: "034912" },
  // { label: "Santa Rita", mun_code: "034912" },
  // { label: "Santo Tomas North", mun_code: "034912" },
  // { label: "Santo Tomas South", mun_code: "034912" },
  // { label: "Sapang", mun_code: "034912" },
  // { label: "Barangay I (Pob.)", mun_code: "034913" },
  // { label: "Barangay II (Pob.)", mun_code: "034913" },
  // { label: "Barangay III (Pob.)", mun_code: "034913" },
  // { label: "Barangay IV (Pob.)", mun_code: "034913" },
  // { label: "Betania", mun_code: "034913" },
  // { label: "Canantong", mun_code: "034913" },
  // { label: "Nauzon", mun_code: "034913" },
  // { label: "Pangarulong", mun_code: "034913" },
  // { label: "Pinagbayanan", mun_code: "034913" },
  // { label: "Sagana", mun_code: "034913" },
  // { label: "San Fernando", mun_code: "034913" },
  // { label: "San Isidro", mun_code: "034913" },
  // { label: "San Josef", mun_code: "034913" },
  // { label: "San Juan", mun_code: "034913" },
  // { label: "San Vicente", mun_code: "034913" },
  // { label: "Siclong", mun_code: "034913" },
  // { label: "San Felipe", mun_code: "034913" },
  // { label: "Linao", mun_code: "034914" },
  // { label: "Poblacion Norte", mun_code: "034914" },
  // { label: "Poblacion Sur", mun_code: "034914" },
  // { label: "San Casimiro", mun_code: "034914" },
  // { label: "San Cristobal", mun_code: "034914" },
  // { label: "San Jose", mun_code: "034914" },
  // { label: "San Juan", mun_code: "034914" },
  // { label: "Santa Maria", mun_code: "034914" },
  // { label: "Tabing Ilog", mun_code: "034914" },
  // { label: "Villarosa", mun_code: "034914" },
  // { label: "Aquino", mun_code: "034914" },
  // { label: "A. Bonifacio", mun_code: "034915" },
  // { label: "Caridad Norte", mun_code: "034915" },
  // { label: "Caridad Sur", mun_code: "034915" },
  // { label: "Casile", mun_code: "034915" },
  // { label: "Florida Blanca", mun_code: "034915" },
  // { label: "General Luna", mun_code: "034915" },
  // { label: "General Ricarte", mun_code: "034915" },
  // { label: "Gomez", mun_code: "034915" },
  // { label: "Inanama", mun_code: "034915" },
  // { label: "Ligaya", mun_code: "034915" },
  // { label: "Mabini", mun_code: "034915" },
  // { label: "Murcon", mun_code: "034915" },
  // { label: "Plaridel", mun_code: "034915" },
  // { label: "Bagumbayan (Pob.)", mun_code: "034915" },
  // { label: "San Felipe", mun_code: "034915" },
  // { label: "San Francisco", mun_code: "034915" },
  // { label: "San Nicolas", mun_code: "034915" },
  // { label: "San Vicente", mun_code: "034915" },
  // { label: "Santa Barbara", mun_code: "034915" },
  // { label: "Victoria", mun_code: "034915" },
  // { label: "Villa Viniegas", mun_code: "034915" },
  // { label: "Bosque", mun_code: "034915" },
  // { label: "Agupalo Este", mun_code: "034916" },
  // { label: "Agupalo Weste", mun_code: "034916" },
  // { label: "Alalay Chica", mun_code: "034916" },
  // { label: "Alalay Grande", mun_code: "034916" },
  // { label: "J. U. Tienzo (Arimal)", mun_code: "034916" },
  // { label: "Bagong Flores", mun_code: "034916" },
  // { label: "Balbalungao", mun_code: "034916" },
  // { label: "Burgos", mun_code: "034916" },
  // { label: "Cordero", mun_code: "034916" },
  // { label: "Mapangpang", mun_code: "034916" },
  // { label: "Namulandayan", mun_code: "034916" },
  // { label: "Parista", mun_code: "034916" },
  // { label: "Poblacion East", mun_code: "034916" },
  // { label: "Poblacion North", mun_code: "034916" },
  // { label: "Poblacion South", mun_code: "034916" },
  // { label: "Poblacion West", mun_code: "034916" },
  // { label: "Salvacion I", mun_code: "034916" },
  // { label: "Salvacion II", mun_code: "034916" },
  // { label: "San Antonio Este", mun_code: "034916" },
  // { label: "San Antonio Weste", mun_code: "034916" },
  // { label: "San Isidro", mun_code: "034916" },
  // { label: "San Pedro", mun_code: "034916" },
  // { label: "San Roque", mun_code: "034916" },
  // { label: "Santo Domingo", mun_code: "034916" },
  // { label: "Bagong Sikat", mun_code: "034917" },
  // { label: "Balante", mun_code: "034917" },
  // { label: "Bantug", mun_code: "034917" },
  // { label: "Bical", mun_code: "034917" },
  // { label: "Cabisuculan", mun_code: "034917" },
  // { label: "Calabalabaan", mun_code: "034917" },
  // { label: "Calisitan", mun_code: "034917" },
  // { label: "Catalanacan", mun_code: "034917" },
  // { label: "Curva", mun_code: "034917" },
  // { label: "Franza", mun_code: "034917" },
  // { label: "Gabaldon", mun_code: "034917" },
  // { label: "Labney", mun_code: "034917" },
  // { label: "Licaong", mun_code: "034917" },
  // { label: "Linglingay", mun_code: "034917" },
  // { label: "Mangandingay", mun_code: "034917" },
  // { label: "Magtanggol", mun_code: "034917" },
  // { label: "Maligaya", mun_code: "034917" },
  // { label: "Mapangpang", mun_code: "034917" },
  // { label: "Maragol", mun_code: "034917" },
  // { label: "Matingkis", mun_code: "034917" },
  // { label: "Naglabrahan", mun_code: "034917" },
  // { label: "Palusapis", mun_code: "034917" },
  // { label: "Pandalla", mun_code: "034917" },
  // { label: "Poblacion East", mun_code: "034917" },
  // { label: "Poblacion North", mun_code: "034917" },
  // { label: "Poblacion South", mun_code: "034917" },
  // { label: "Poblacion West", mun_code: "034917" },
  // { label: "Rang-ayan", mun_code: "034917" },
  // { label: "Rizal", mun_code: "034917" },
  // { label: "San Andres", mun_code: "034917" },
  // { label: "San Antonio", mun_code: "034917" },
  // { label: "San Felipe", mun_code: "034917" },
  // { label: "Sapang Cawayan", mun_code: "034917" },
  // { label: "Villa Isla", mun_code: "034917" },
  // { label: "Villa Nati", mun_code: "034917" },
  // { label: "Villa Santos", mun_code: "034917" },
  // { label: "Villa Cuizon", mun_code: "034917" },
  // { label: "Alemania", mun_code: "034918" },
  // { label: "Ambasador Alzate Village", mun_code: "034918" },
  // { label: "Cabaducan East (Pob.)", mun_code: "034918" },
  // { label: "Cabaducan West (Pob.)", mun_code: "034918" },
  // { label: "Cabawangan", mun_code: "034918" },
  // { label: "East Central Poblacion", mun_code: "034918" },
  // { label: "Edy", mun_code: "034918" },
  // { label: "Maeling", mun_code: "034918" },
  // { label: "Mayantoc", mun_code: "034918" },
  // { label: "Medico", mun_code: "034918" },
  // { label: "Monic", mun_code: "034918" },
  // { label: "North Poblacion", mun_code: "034918" },
  // { label: "Northwest Poblacion", mun_code: "034918" },
  // { label: "Estacion (Pob.)", mun_code: "034918" },
  // { label: "West Poblacion", mun_code: "034918" },
  // { label: "Recuerdo", mun_code: "034918" },
  // { label: "South Central Poblacion", mun_code: "034918" },
  // { label: "Southeast Poblacion", mun_code: "034918" },
  // { label: "Southwest Poblacion", mun_code: "034918" },
  // { label: "Tony", mun_code: "034918" },
  // { label: "West Central Poblacion", mun_code: "034918" },
  // { label: "Aulo", mun_code: "034919" },
  // { label: "Bo. Militar (Fort Magsaysay)", mun_code: "034919" },
  // { label: "Ganaderia", mun_code: "034919" },
  // { label: "Maligaya", mun_code: "034919" },
  // { label: "Manacnac", mun_code: "034919" },
  // { label: "Mapait", mun_code: "034919" },
  // { label: "Marcos Village", mun_code: "034919" },
  // { label: "Malate (Pob.)", mun_code: "034919" },
  // { label: "Sapang Buho", mun_code: "034919" },
  // { label: "Singalat", mun_code: "034919" },
  // { label: "Atate", mun_code: "034919" },
  // { label: "Caballero", mun_code: "034919" },
  // { label: "Caimito", mun_code: "034919" },
  // { label: "Imelda Valley", mun_code: "034919" },
  // { label: "Langka", mun_code: "034919" },
  // { label: "Santolan", mun_code: "034919" },
  // { label: "Popolon Pagas", mun_code: "034919" },
  // { label: "Bagong Buhay", mun_code: "034919" },
  // { label: "Cadaclan", mun_code: "034920" },
  // { label: "Cambitala", mun_code: "034920" },
  // { label: "Conversion", mun_code: "034920" },
  // { label: "Ganduz", mun_code: "034920" },
  // { label: "Liberty", mun_code: "034920" },
  // { label: "Malbang", mun_code: "034920" },
  // { label: "Marikit", mun_code: "034920" },
  // { label: "Napon-Napon", mun_code: "034920" },
  // { label: "Poblacion East", mun_code: "034920" },
  // { label: "Poblacion West", mun_code: "034920" },
  // { label: "Sampaloc", mun_code: "034920" },
  // { label: "San Juan", mun_code: "034920" },
  // { label: "Villarica", mun_code: "034920" },
  // { label: "Fatima", mun_code: "034920" },
  // { label: "Callos", mun_code: "034921" },
  // { label: "Poblacion I", mun_code: "034921" },
  // { label: "Poblacion II", mun_code: "034921" },
  // { label: "Poblacion III", mun_code: "034921" },
  // { label: "Poblacion IV", mun_code: "034921" },
  // { label: "Santo Tomas", mun_code: "034921" },
  // { label: "Sinasajan", mun_code: "034921" },
  // { label: "San Josef", mun_code: "034921" },
  // { label: "San Mariano (Maugat)", mun_code: "034921" },
  // { label: "Bertese", mun_code: "034922" },
  // { label: "Dulong Bayan", mun_code: "034922" },
  // { label: "Ilog Baliwag", mun_code: "034922" },
  // { label: "Barangay I (Pob.)", mun_code: "034922" },
  // { label: "Barangay II (Pob.)", mun_code: "034922" },
  // { label: "Pulong Bahay", mun_code: "034922" },
  // { label: "San Alejandro", mun_code: "034922" },
  // { label: "San Andres I", mun_code: "034922" },
  // { label: "San Andres II", mun_code: "034922" },
  // { label: "San Manuel", mun_code: "034922" },
  // { label: "Santa Clara", mun_code: "034922" },
  // { label: "Santa Rita", mun_code: "034922" },
  // { label: "Santo Cristo", mun_code: "034922" },
  // { label: "Santo Tomas Feria", mun_code: "034922" },
  // { label: "San Miguel", mun_code: "034922" },
  // { label: "Agbannawag", mun_code: "034923" },
  // { label: "Bicos", mun_code: "034923" },
  // { label: "Cabucbucan", mun_code: "034923" },
  // { label: "Calaocan District", mun_code: "034923" },
  // { label: "Canaan East", mun_code: "034923" },
  // { label: "Canaan West", mun_code: "034923" },
  // { label: "Casilagan", mun_code: "034923" },
  // { label: "Aglipay (Curva)", mun_code: "034923" },
  // { label: "Del Pilar", mun_code: "034923" },
  // { label: "Estrella", mun_code: "034923" },
  // { label: "General Luna", mun_code: "034923" },
  // { label: "Macapsing", mun_code: "034923" },
  // { label: "Maligaya", mun_code: "034923" },
  // { label: "Paco Roman", mun_code: "034923" },
  // { label: "Pag-asa", mun_code: "034923" },
  // { label: "Poblacion Central", mun_code: "034923" },
  // { label: "Poblacion East", mun_code: "034923" },
  // { label: "Poblacion Norte", mun_code: "034923" },
  // { label: "Poblacion Sur", mun_code: "034923" },
  // { label: "Poblacion West", mun_code: "034923" },
  // { label: "Portal", mun_code: "034923" },
  // { label: "San Esteban", mun_code: "034923" },
  // { label: "Santa Monica", mun_code: "034923" },
  // { label: "Villa Labrador", mun_code: "034923" },
  // { label: "Villa Paraiso", mun_code: "034923" },
  // { label: "San Gregorio", mun_code: "034923" },
  // { label: "Buliran", mun_code: "034924" },
  // { label: "Cama Juan", mun_code: "034924" },
  // { label: "Julo", mun_code: "034924" },
  // { label: "Lawang Kupang", mun_code: "034924" },
  // { label: "Luyos", mun_code: "034924" },
  // { label: "Maugat", mun_code: "034924" },
  // { label: "Panabingan", mun_code: "034924" },
  // { label: "Papaya", mun_code: "034924" },
  // { label: "Poblacion", mun_code: "034924" },
  // { label: "San Francisco", mun_code: "034924" },
  // { label: "San Jose", mun_code: "034924" },
  // { label: "San Mariano", mun_code: "034924" },
  // { label: "Santa Cruz", mun_code: "034924" },
  // { label: "Santo Cristo", mun_code: "034924" },
  // { label: "Santa Barbara", mun_code: "034924" },
  // { label: "Tikiw", mun_code: "034924" },
  // { label: "Alua", mun_code: "034925" },
  // { label: "Calaba", mun_code: "034925" },
  // { label: "Malapit", mun_code: "034925" },
  // { label: "Mangga", mun_code: "034925" },
  // { label: "Poblacion", mun_code: "034925" },
  // { label: "Pulo", mun_code: "034925" },
  // { label: "San Roque", mun_code: "034925" },
  // { label: "Santo Cristo", mun_code: "034925" },
  // { label: "Tabon", mun_code: "034925" },
  // { label: "A. Pascual", mun_code: "034926" },
  // { label: "Abar Ist", mun_code: "034926" },
  // { label: "Abar 2nd", mun_code: "034926" },
  // { label: "Bagong Sikat", mun_code: "034926" },
  // { label: "Caanawan", mun_code: "034926" },
  // { label: "Calaocan", mun_code: "034926" },
  // { label: "Camanacsacan", mun_code: "034926" },
  // { label: "Culaylay", mun_code: "034926" },
  // { label: "Dizol", mun_code: "034926" },
  // { label: "Kaliwanagan", mun_code: "034926" },
  // { label: "Kita-Kita", mun_code: "034926" },
  // { label: "Malasin", mun_code: "034926" },
  // { label: "Manicla", mun_code: "034926" },
  // { label: "Palestina", mun_code: "034926" },
  // { label: "Parang Mangga", mun_code: "034926" },
  // { label: "Villa Joson (Parilla)", mun_code: "034926" },
  // { label: "Pinili", mun_code: "034926" },
  // { label: "Rafael Rueda, Sr. Pob. (District I)", mun_code: "034926" },
  // { label: "Ferdinand E. Marcos Pob. (District II)", mun_code: "034926" },
  // { label: "Canuto Ramos Pob. (District III)", mun_code: "034926" },
  // { label: "Raymundo Eugenio Pob. (District IV)", mun_code: "034926" },
  // { label: "Crisanto Sanchez Pob. (District V)", mun_code: "034926" },
  // { label: "Porais", mun_code: "034926" },
  // { label: "San Agustin", mun_code: "034926" },
  // { label: "San Juan", mun_code: "034926" },
  // { label: "San Mauricio", mun_code: "034926" },
  // { label: "Santo Tomas", mun_code: "034926" },
  // { label: "Sibut", mun_code: "034926" },
  // { label: "Sinipit Bubon", mun_code: "034926" },
  // { label: "Tabulac", mun_code: "034926" },
  // { label: "Tayabo", mun_code: "034926" },
  // { label: "Tondod", mun_code: "034926" },
  // { label: "Tulat", mun_code: "034926" },
  // { label: "Villa Floresca", mun_code: "034926" },
  // { label: "Villa Marina", mun_code: "034926" },
  // { label: "Bonifacio District (Pob.)", mun_code: "034927" },
  // { label: "Burgos District (Pob.)", mun_code: "034927" },
  // { label: "Castellano", mun_code: "034927" },
  // { label: "Diversion", mun_code: "034927" },
  // { label: "Magpapalayoc", mun_code: "034927" },
  // { label: "Mallorca", mun_code: "034927" },
  // { label: "Mambangnan", mun_code: "034927" },
  // { label: "Nieves", mun_code: "034927" },
  // { label: "San Bartolome (Pob.)", mun_code: "034927" },
  // { label: "Rizal District (Pob.)", mun_code: "034927" },
  // { label: "San Anton", mun_code: "034927" },
  // { label: "San Roque", mun_code: "034927" },
  // { label: "Tabuating", mun_code: "034927" },
  // { label: "Tagumpay", mun_code: "034927" },
  // { label: "Tambo Adorable", mun_code: "034927" },
  // { label: "Cojuangco (Pob.)", mun_code: "034928" },
  // { label: "La Fuente", mun_code: "034928" },
  // { label: "Liwayway", mun_code: "034928" },
  // { label: "Maliolio", mun_code: "034928" },
  // { label: "Mapalad", mun_code: "034928" },
  // { label: "Rizal (Pob.)", mun_code: "034928" },
  // { label: "Rajal Centro", mun_code: "034928" },
  // { label: "Rajal Norte", mun_code: "034928" },
  // { label: "Rajal Sur", mun_code: "034928" },
  // { label: "San Gregorio", mun_code: "034928" },
  // { label: "San Mariano", mun_code: "034928" },
  // { label: "San Pedro", mun_code: "034928" },
  // { label: "Santo Rosario", mun_code: "034928" },
  // { label: "Soledad", mun_code: "034928" },
  // { label: "Valenzuela (Pob.)", mun_code: "034928" },
  // { label: "Zamora (Pob.)", mun_code: "034928" },
  // { label: "Aguinaldo", mun_code: "034928" },
  // { label: "Berang", mun_code: "034928" },
  // { label: "Burgos", mun_code: "034928" },
  // { label: "Del Pilar", mun_code: "034928" },
  // { label: "Gomez", mun_code: "034928" },
  // { label: "Inspector", mun_code: "034928" },
  // { label: "Isla", mun_code: "034928" },
  // { label: "Lourdes", mun_code: "034928" },
  // { label: "Luna", mun_code: "034928" },
  // { label: "Mabini", mun_code: "034928" },
  // { label: "San Isidro", mun_code: "034928" },
  // { label: "San Josep", mun_code: "034928" },
  // { label: "Santa Teresita", mun_code: "034928" },
  // { label: "Sapsap", mun_code: "034928" },
  // { label: "Tagpos", mun_code: "034928" },
  // { label: "Tramo", mun_code: "034928" },
  // { label: "Baloc", mun_code: "034929" },
  // { label: "Buasao", mun_code: "034929" },
  // { label: "Burgos", mun_code: "034929" },
  // { label: "Cabugao", mun_code: "034929" },
  // { label: "Casulucan", mun_code: "034929" },
  // { label: "Comitang", mun_code: "034929" },
  // { label: "Concepcion", mun_code: "034929" },
  // { label: "Dolores", mun_code: "034929" },
  // { label: "General Luna", mun_code: "034929" },
  // { label: "Hulo", mun_code: "034929" },
  // { label: "Mabini", mun_code: "034929" },
  // { label: "Malasin", mun_code: "034929" },
  // { label: "Malayantoc", mun_code: "034929" },
  // { label: "Mambarao", mun_code: "034929" },
  // { label: "Poblacion", mun_code: "034929" },
  // { label: "Malaya (Pook Malaya)", mun_code: "034929" },
  // { label: "Pulong Buli", mun_code: "034929" },
  // { label: "Sagaba", mun_code: "034929" },
  // { label: "San Agustin", mun_code: "034929" },
  // { label: "San Fabian", mun_code: "034929" },
  // { label: "San Francisco", mun_code: "034929" },
  // { label: "San Pascual", mun_code: "034929" },
  // { label: "Santa Rita", mun_code: "034929" },
  // { label: "Santo Rosario", mun_code: "034929" },
  // { label: "Andal Alino (Pob.)", mun_code: "034930" },
  // { label: "Bagong Sikat", mun_code: "034930" },
  // { label: "Bagong Silang", mun_code: "034930" },
  // { label: "Bakal I", mun_code: "034930" },
  // { label: "Bakal II", mun_code: "034930" },
  // { label: "Bakal III", mun_code: "034930" },
  // { label: "Baluga", mun_code: "034930" },
  // { label: "Bantug", mun_code: "034930" },
  // { label: "Bantug Hacienda", mun_code: "034930" },
  // { label: "Bantug Hamog (Basang Hamog)", mun_code: "034930" },
  // { label: "Bugtong na Buli", mun_code: "034930" },
  // { label: "Bulac", mun_code: "034930" },
  // { label: "Burnay", mun_code: "034930" },
  // { label: "Calipahan", mun_code: "034930" },
  // { label: "Campos", mun_code: "034930" },
  // { label: "Casulucan Este", mun_code: "034930" },
  // { label: "Collado", mun_code: "034930" },
  // { label: "Dimasalang Norte", mun_code: "034930" },
  // { label: "Dimasalang Sur", mun_code: "034930" },
  // { label: "Dinarayat", mun_code: "034930" },
  // { label: "Esguerra District (Pob.)", mun_code: "034930" },
  // { label: "Gulod", mun_code: "034930" },
  // { label: "Homestead I", mun_code: "034930" },
  // { label: "Homestead II", mun_code: "034930" },
  // { label: "Cabubulaonan", mun_code: "034930" },
  // { label: "Caaniplahan", mun_code: "034930" },
  // { label: "Caputican", mun_code: "034930" },
  // { label: "Kinalanguyan", mun_code: "034930" },
  // { label: "La Torre", mun_code: "034930" },
  // { label: "Lomboy", mun_code: "034930" },
  // { label: "Mabuhay", mun_code: "034930" },
  // { label: "Maestrang Kikay (Pob.)", mun_code: "034930" },
  // { label: "Mamandil", mun_code: "034930" },
  // { label: "Marcos District (Pob.)", mun_code: "034930" },
  // { label: "Purok Matias (Pob.)", mun_code: "034930" },
  // { label: "Matingkis", mun_code: "034930" },
  // { label: "Minabuyoc", mun_code: "034930" },
  // { label: "Pag-asa (Pob.)", mun_code: "034930" },
  // { label: "Paludpod", mun_code: "034930" },
  // { label: "Pantoc Bulac", mun_code: "034930" },
  // { label: "Pinagpanaan", mun_code: "034930" },
  // { label: "Poblacion Sur", mun_code: "034930" },
  // { label: "Pula", mun_code: "034930" },
  // { label: "Pulong San Miguel (Pob.)", mun_code: "034930" },
  // { label: "Sampaloc", mun_code: "034930" },
  // { label: "San Miguel na Munti", mun_code: "034930" },
  // { label: "San Pascual", mun_code: "034930" },
  // { label: "San Ricardo", mun_code: "034930" },
  // { label: "Sibul", mun_code: "034930" },
  // { label: "Sicsican Matanda", mun_code: "034930" },
  // { label: "Tabacao", mun_code: "034930" },
  // { label: "Tagaytay", mun_code: "034930" },
  // { label: "Valle", mun_code: "034930" },
  // { label: "Alula", mun_code: "034931" },
  // { label: "Baybayabas", mun_code: "034931" },
  // { label: "Buted", mun_code: "034931" },
  // { label: "Cabiangan", mun_code: "034931" },
  // { label: "Calisitan", mun_code: "034931" },
  // { label: "Cinense", mun_code: "034931" },
  // { label: "Culiat", mun_code: "034931" },
  // { label: "Maasin", mun_code: "034931" },
  // { label: "Magsaysay (Pob.)", mun_code: "034931" },
  // { label: "Mayamot I", mun_code: "034931" },
  // { label: "Mayamot II", mun_code: "034931" },
  // { label: "Nangabulan", mun_code: "034931" },
  // { label: "Pangit", mun_code: "034931" },
  // { label: "Patola", mun_code: "034931" },
  // { label: "Quezon (Pob.)", mun_code: "034931" },
  // { label: "Quirino (Pob.)", mun_code: "034931" },
  // { label: "Roxas (Pob.)", mun_code: "034931" },
  // { label: "Saguing", mun_code: "034931" },
  // { label: "Sampaloc", mun_code: "034931" },
  // { label: "Santa Catalina", mun_code: "034931" },
  // { label: "Santo Domingo", mun_code: "034931" },
  // { label: "Saringaya", mun_code: "034931" },
  // { label: "Saverona", mun_code: "034931" },
  // { label: "Tandoc", mun_code: "034931" },
  // { label: "Tibag", mun_code: "034931" },
  // { label: "Villa Rosario", mun_code: "034931" },
  // { label: "Villa Boado", mun_code: "034931" },
  // { label: "Batitang", mun_code: "034932" },
  // { label: "Carmen", mun_code: "034932" },
  // { label: "Concepcion", mun_code: "034932" },
  // { label: "Del Pilar", mun_code: "034932" },
  // { label: "General Luna", mun_code: "034932" },
  // { label: "H. Romero", mun_code: "034932" },
  // { label: "Macarse", mun_code: "034932" },
  // { label: "Manaul", mun_code: "034932" },
  // { label: "Mayamot", mun_code: "034932" },
  // { label: "Pantoc", mun_code: "034932" },
  // { label: "San Vicente (Pob.)", mun_code: "034932" },
  // { label: "San Isidro", mun_code: "034932" },
  // { label: "San Rafael", mun_code: "034932" },
  // { label: "Santa Cruz", mun_code: "034932" },
  // { label: "Santa Lucia Old", mun_code: "034932" },
  // { label: "Santa Lucia Young", mun_code: "034932" },
  // { label: "Santo Rosario Old", mun_code: "034932" },
  // { label: "Santo Rosario Young", mun_code: "034932" },
  // { label: "Valeriana", mun_code: "034932" },
  // { label: "Agapito del Rosario", mun_code: "035401" },
  // { label: "Anunas", mun_code: "035401" },
  // { label: "Balibago", mun_code: "035401" },
  // { label: "Capaya", mun_code: "035401" },
  // { label: "Claro M. Recto", mun_code: "035401" },
  // { label: "Cuayan", mun_code: "035401" },
  // { label: "Cutcut", mun_code: "035401" },
  // { label: "Cutud", mun_code: "035401" },
  // { label: "Lourdes North West", mun_code: "035401" },
  // { label: "Lourdes Sur", mun_code: "035401" },
  // { label: "Lourdes Sur East", mun_code: "035401" },
  // { label: "Malabanias", mun_code: "035401" },
  // { label: "Margot", mun_code: "035401" },
  // { label: "Mining", mun_code: "035401" },
  // { label: "Pampang", mun_code: "035401" },
  // { label: "Pandan", mun_code: "035401" },
  // { label: "Pulung Maragul", mun_code: "035401" },
  // { label: "Pulungbulu", mun_code: "035401" },
  // { label: "Pulung Cacutud", mun_code: "035401" },
  // { label: "Salapungan", mun_code: "035401" },
  // { label: "San Jose", mun_code: "035401" },
  // { label: "San Nicolas", mun_code: "035401" },
  // { label: "Santa Teresita", mun_code: "035401" },
  // { label: "Santa Trinidad", mun_code: "035401" },
  // { label: "Santo Cristo", mun_code: "035401" },
  // { label: "Santo Domingo", mun_code: "035401" },
  // { label: "Santo Rosario (Pob.)", mun_code: "035401" },
  // { label: "Sapalibutad", mun_code: "035401" },
  // { label: "Sapangbato", mun_code: "035401" },
  // { label: "Tabun", mun_code: "035401" },
  // { label: "Virgen Delos Remedios", mun_code: "035401" },
  // { label: "Amsic", mun_code: "035401" },
  // { label: "Ninoy Aquino (Marisol)", mun_code: "035401" },
  // { label: "Balucuc", mun_code: "035402" },
  // { label: "Calantipe", mun_code: "035402" },
  // { label: "Cansinala", mun_code: "035402" },
  // { label: "Capalangan", mun_code: "035402" },
  // { label: "Colgante", mun_code: "035402" },
  // { label: "Paligui", mun_code: "035402" },
  // { label: "Sampaloc", mun_code: "035402" },
  // { label: "San Juan (Pob.)", mun_code: "035402" },
  // { label: "San Vicente", mun_code: "035402" },
  // { label: "Sucad", mun_code: "035402" },
  // { label: "Sulipan", mun_code: "035402" },
  // { label: "Tabuyuc (Santo Rosario)", mun_code: "035402" },
  // { label: "Arenas", mun_code: "035403" },
  // { label: "Baliti", mun_code: "035403" },
  // { label: "Batasan", mun_code: "035403" },
  // { label: "Buensuceso", mun_code: "035403" },
  // { label: "Candating", mun_code: "035403" },
  // { label: "Gatiawin", mun_code: "035403" },
  // { label: "Guemasan", mun_code: "035403" },
  // { label: "La Paz (Turu)", mun_code: "035403" },
  // { label: "Lacmit", mun_code: "035403" },
  // { label: "Lacquios", mun_code: "035403" },
  // { label: "Mangga-Cacutud", mun_code: "035403" },
  // { label: "Mapalad", mun_code: "035403" },
  // { label: "Panlinlang", mun_code: "035403" },
  // { label: "Paralaya", mun_code: "035403" },
  // { label: "Plazang Luma", mun_code: "035403" },
  // { label: "Poblacion", mun_code: "035403" },
  // { label: "San Agustin Norte", mun_code: "035403" },
  // { label: "San Agustin Sur", mun_code: "035403" },
  // { label: "San Antonio", mun_code: "035403" },
  // { label: "San Jose Mesulo", mun_code: "035403" },
  // { label: "San Juan Bano", mun_code: "035403" },
  // { label: "San Mateo", mun_code: "035403" },
  // { label: "San Nicolas", mun_code: "035403" },
  // { label: "San Roque Bitas", mun_code: "035403" },
  // { label: "Cupang (Santa Lucia)", mun_code: "035403" },
  // { label: "Matamo (Santa Lucia)", mun_code: "035403" },
  // { label: "Suclayin", mun_code: "035403" },
  // { label: "Telapayong", mun_code: "035403" },
  // { label: "Kaledian (Camba)", mun_code: "035403" },
  // { label: "Balas", mun_code: "035404" },
  // { label: "Cabalantian", mun_code: "035404" },
  // { label: "Cabambangan (Pob.)", mun_code: "035404" },
  // { label: "Cabetican", mun_code: "035404" },
  // { label: "Calibutbut", mun_code: "035404" },
  // { label: "Concepcion", mun_code: "035404" },
  // { label: "Dolores", mun_code: "035404" },
  // { label: "Duat", mun_code: "035404" },
  // { label: "Macabacle", mun_code: "035404" },
  // { label: "Magliman", mun_code: "035404" },
  // { label: "Maliwalu", mun_code: "035404" },
  // { label: "Mesalipit", mun_code: "035404" },
  // { label: "Parulog", mun_code: "035404" },
  // { label: "Potrero", mun_code: "035404" },
  // { label: "San Antonio", mun_code: "035404" },
  // { label: "San Isidro", mun_code: "035404" },
  // { label: "San Vicente", mun_code: "035404" },
  // { label: "Santa Barbara", mun_code: "035404" },
  // { label: "Santa Ines", mun_code: "035404" },
  // { label: "Talba", mun_code: "035404" },
  // { label: "Tinajero", mun_code: "035404" },
  // { label: "Bahay Pare", mun_code: "035405" },
  // { label: "Bambang", mun_code: "035405" },
  // { label: "Barangca", mun_code: "035405" },
  // { label: "Barit", mun_code: "035405" },
  // { label: "Buas (Pob.)", mun_code: "035405" },
  // { label: "Cuayang Bugtong", mun_code: "035405" },
  // { label: "Dalayap", mun_code: "035405" },
  // { label: "Dulong Ilog", mun_code: "035405" },
  // { label: "Gulap", mun_code: "035405" },
  // { label: "Lanang", mun_code: "035405" },
  // { label: "Lourdes", mun_code: "035405" },
  // { label: "Magumbali", mun_code: "035405" },
  // { label: "Mandasig", mun_code: "035405" },
  // { label: "Mandili", mun_code: "035405" },
  // { label: "Mangga", mun_code: "035405" },
  // { label: "Mapaniqui", mun_code: "035405" },
  // { label: "Paligui", mun_code: "035405" },
  // { label: "Pangclara", mun_code: "035405" },
  // { label: "Pansinao", mun_code: "035405" },
  // { label: "Paralaya (Pob.)", mun_code: "035405" },
  // { label: "Pasig", mun_code: "035405" },
  // { label: "Pescadores (Pob.)", mun_code: "035405" },
  // { label: "Pulong Gubat", mun_code: "035405" },
  // { label: "Pulong Palazan", mun_code: "035405" },
  // { label: "Salapungan", mun_code: "035405" },
  // { label: "San Agustin (Pob.)", mun_code: "035405" },
  // { label: "Santo Rosario", mun_code: "035405" },
  // { label: "Tagulod", mun_code: "035405" },
  // { label: "Talang", mun_code: "035405" },
  // { label: "Tenejero", mun_code: "035405" },
  // { label: "Vizal San Pablo", mun_code: "035405" },
  // { label: "Vizal Santo Cristo", mun_code: "035405" },
  // { label: "Anon", mun_code: "035406" },
  // { label: "Apalit", mun_code: "035406" },
  // { label: "Basa Air Base", mun_code: "035406" },
  // { label: "Benedicto", mun_code: "035406" },
  // { label: "Bodega", mun_code: "035406" },
  // { label: "Cabangcalan", mun_code: "035406" },
  // { label: "Calantas", mun_code: "035406" },
  // { label: "Carmencita", mun_code: "035406" },
  // { label: "Consuelo", mun_code: "035406" },
  // { label: "Dampe", mun_code: "035406" },
  // { label: "Del Carmen", mun_code: "035406" },
  // { label: "Fortuna", mun_code: "035406" },
  // { label: "Gutad", mun_code: "035406" },
  // { label: "Mabical", mun_code: "035406" },
  // { label: "Santo Rosario (Malabo)", mun_code: "035406" },
  // { label: "Maligaya", mun_code: "035406" },
  // { label: "Nabuclod", mun_code: "035406" },
  // { label: "Pabanlag", mun_code: "035406" },
  // { label: "Paguiruan", mun_code: "035406" },
  // { label: "Palmayo", mun_code: "035406" },
  // { label: "Pandaguirig", mun_code: "035406" },
  // { label: "Poblacion", mun_code: "035406" },
  // { label: "San Antonio", mun_code: "035406" },
  // { label: "San Isidro", mun_code: "035406" },
  // { label: "San Jose", mun_code: "035406" },
  // { label: "San Nicolas", mun_code: "035406" },
  // { label: "San Pedro", mun_code: "035406" },
  // { label: "San Ramon", mun_code: "035406" },
  // { label: "San Roque", mun_code: "035406" },
  // { label: "Santa Monica", mun_code: "035406" },
  // { label: "Solib", mun_code: "035406" },
  // { label: "Valdez", mun_code: "035406" },
  // { label: "Mawacat", mun_code: "035406" },
  // { label: "Bancal", mun_code: "035407" },
  // { label: "Jose Abad Santos (Siran)", mun_code: "035407" },
  // { label: "Lambac", mun_code: "035407" },
  // { label: "Magsaysay", mun_code: "035407" },
  // { label: "Maquiapo", mun_code: "035407" },
  // { label: "Natividad", mun_code: "035407" },
  // { label: "Plaza Burgos (Pob.)", mun_code: "035407" },
  // { label: "Pulungmasle", mun_code: "035407" },
  // { label: "Rizal", mun_code: "035407" },
  // { label: "San Agustin", mun_code: "035407" },
  // { label: "San Antonio", mun_code: "035407" },
  // { label: "San Isidro", mun_code: "035407" },
  // { label: "San Jose", mun_code: "035407" },
  // { label: "San Juan Bautista", mun_code: "035407" },
  // { label: "San Juan Nepomuceno", mun_code: "035407" },
  // { label: "San Matias", mun_code: "035407" },
  // { label: "San Miguel (Betis)", mun_code: "035407" },
  // { label: "San Nicolas 1st", mun_code: "035407" },
  // { label: "San Nicolas 2nd", mun_code: "035407" },
  // { label: "San Pablo", mun_code: "035407" },
  // { label: "San Pedro", mun_code: "035407" },
  // { label: "San Rafael (Duck Island)", mun_code: "035407" },
  // { label: "San Roque", mun_code: "035407" },
  // { label: "San Vicente (Ibus)", mun_code: "035407" },
  // { label: "San Juan", mun_code: "035407" },
  // { label: "Santa Filomena (Pob.)", mun_code: "035407" },
  // { label: "Santa Ines", mun_code: "035407" },
  // { label: "Santa Ursula", mun_code: "035407" },
  // { label: "Santo Cristo", mun_code: "035407" },
  // { label: "Ascomo", mun_code: "035407" },
  // { label: "Balantacan", mun_code: "035408" },
  // { label: "Bancal Sinubli", mun_code: "035408" },
  // { label: "Bancal Pugad", mun_code: "035408" },
  // { label: "Baruya (San Rafael)", mun_code: "035408" },
  // { label: "Calangain", mun_code: "035408" },
  // { label: "Concepcion", mun_code: "035408" },
  // { label: "Del Carmen", mun_code: "035408" },
  // { label: "De La Paz", mun_code: "035408" },
  // { label: "Don Ignacio Dimson", mun_code: "035408" },
  // { label: "Lourdes (Lauc Pau)", mun_code: "035408" },
  // { label: "Prado Siongco", mun_code: "035408" },
  // { label: "Remedios", mun_code: "035408" },
  // { label: "San Agustin", mun_code: "035408" },
  // { label: "San Antonio", mun_code: "035408" },
  // { label: "San Francisco", mun_code: "035408" },
  // { label: "San Isidro", mun_code: "035408" },
  // { label: "San Jose Apunan", mun_code: "035408" },
  // { label: "San Jose Gumi", mun_code: "035408" },
  // { label: "San Juan (Pob.)", mun_code: "035408" },
  // { label: "San Matias", mun_code: "035408" },
  // { label: "San Miguel", mun_code: "035408" },
  // { label: "San Nicolas 1st (Pob.)", mun_code: "035408" },
  // { label: "San Nicolas 2nd", mun_code: "035408" },
  // { label: "San Pablo 1st", mun_code: "035408" },
  // { label: "San Pablo 2nd", mun_code: "035408" },
  // { label: "San Pedro Palcarangan", mun_code: "035408" },
  // { label: "San Pedro Saug", mun_code: "035408" },
  // { label: "San Roque Arbol", mun_code: "035408" },
  // { label: "San Roque Dau", mun_code: "035408" },
  // { label: "San Vicente", mun_code: "035408" },
  // { label: "Santa Barbara", mun_code: "035408" },
  // { label: "Santa Catalina", mun_code: "035408" },
  // { label: "Santa Cruz", mun_code: "035408" },
  // { label: "Santa Lucia (Pob.)", mun_code: "035408" },
  // { label: "Santa Maria", mun_code: "035408" },
  // { label: "Santa Monica", mun_code: "035408" },
  // { label: "Santa Rita", mun_code: "035408" },
  // { label: "Santa Teresa 1st", mun_code: "035408" },
  // { label: "Santa Teresa 2nd", mun_code: "035408" },
  // { label: "Santiago", mun_code: "035408" },
  // { label: "Santo Domingo", mun_code: "035408" },
  // { label: "Santo Tomas (Pob.)", mun_code: "035408" },
  // { label: "Santo Cristo", mun_code: "035408" },
  // { label: "Atlu-Bola", mun_code: "035409" },
  // { label: "Bical", mun_code: "035409" },
  // { label: "Bundagul", mun_code: "035409" },
  // { label: "Cacutud", mun_code: "035409" },
  // { label: "Calumpang", mun_code: "035409" },
  // { label: "Camachiles", mun_code: "035409" },
  // { label: "Dapdap", mun_code: "035409" },
  // { label: "Dau", mun_code: "035409" },
  // { label: "Dolores", mun_code: "035409" },
  // { label: "Duquit", mun_code: "035409" },
  // { label: "Lakandula", mun_code: "035409" },
  // { label: "Mabiga", mun_code: "035409" },
  // { label: "Macapagal Village", mun_code: "035409" },
  // { label: "Mamatitang", mun_code: "035409" },
  // { label: "Mangalit", mun_code: "035409" },
  // { label: "Marcos Village", mun_code: "035409" },
  // { label: "Mawaque", mun_code: "035409" },
  // { label: "Paralayunan", mun_code: "035409" },
  // { label: "Poblacion", mun_code: "035409" },
  // { label: "San Francisco", mun_code: "035409" },
  // { label: "San Joaquin", mun_code: "035409" },
  // { label: "Santa Ines", mun_code: "035409" },
  // { label: "Santa Maria", mun_code: "035409" },
  // { label: "Santo Rosario", mun_code: "035409" },
  // { label: "Sapang Balen", mun_code: "035409" },
  // { label: "Sapang Biabas", mun_code: "035409" },
  // { label: "Tabun", mun_code: "035409" },
  // { label: "Batasan", mun_code: "035410" },
  // { label: "Caduang Tete", mun_code: "035410" },
  // { label: "Candelaria", mun_code: "035410" },
  // { label: "Castuli", mun_code: "035410" },
  // { label: "Consuelo", mun_code: "035410" },
  // { label: "Dalayap", mun_code: "035410" },
  // { label: "Mataguiti", mun_code: "035410" },
  // { label: "San Esteban", mun_code: "035410" },
  // { label: "San Francisco", mun_code: "035410" },
  // { label: "San Gabriel (Pob.)", mun_code: "035410" },
  // { label: "San Isidro", mun_code: "035410" },
  // { label: "San Jose", mun_code: "035410" },
  // { label: "San Juan", mun_code: "035410" },
  // { label: "San Rafael", mun_code: "035410" },
  // { label: "San Roque", mun_code: "035410" },
  // { label: "San Vicente", mun_code: "035410" },
  // { label: "Santa Cruz (Pob.)", mun_code: "035410" },
  // { label: "Santa Lutgarda", mun_code: "035410" },
  // { label: "Santa Maria", mun_code: "035410" },
  // { label: "Santa Rita (Pob.)", mun_code: "035410" },
  // { label: "Santo Rosario (Pob.)", mun_code: "035410" },
  // { label: "Saplad David", mun_code: "035410" },
  // { label: "Tacasan", mun_code: "035410" },
  // { label: "Telacsan", mun_code: "035410" },
  // { label: "Camias", mun_code: "035411" },
  // { label: "Dolores", mun_code: "035411" },
  // { label: "Escaler", mun_code: "035411" },
  // { label: "La Paz", mun_code: "035411" },
  // { label: "Navaling", mun_code: "035411" },
  // { label: "San Agustin", mun_code: "035411" },
  // { label: "San Antonio", mun_code: "035411" },
  // { label: "San Franciso", mun_code: "035411" },
  // { label: "San Ildefonso", mun_code: "035411" },
  // { label: "San Isidro", mun_code: "035411" },
  // { label: "San Jose", mun_code: "035411" },
  // { label: "San Miguel", mun_code: "035411" },
  // { label: "San Nicolas 1st (Pob.)", mun_code: "035411" },
  // { label: "San Nicolas 2nd", mun_code: "035411" },
  // { label: "San Pablo (Pob.)", mun_code: "035411" },
  // { label: "San Pedro I", mun_code: "035411" },
  // { label: "San Pedro II", mun_code: "035411" },
  // { label: "San Roque", mun_code: "035411" },
  // { label: "San Vicente", mun_code: "035411" },
  // { label: "Santa Cruz (Pob.)", mun_code: "035411" },
  // { label: "Santa Lucia", mun_code: "035411" },
  // { label: "Santa Maria", mun_code: "035411" },
  // { label: "Santo Rosario", mun_code: "035411" },
  // { label: "Bucanan", mun_code: "035411" },
  // { label: "Turu", mun_code: "035411" },
  // { label: "Ayala", mun_code: "035411" },
  // { label: "Alauli", mun_code: "035412" },
  // { label: "Bagang", mun_code: "035412" },
  // { label: "Balibago", mun_code: "035412" },
  // { label: "Bebe Anac", mun_code: "035412" },
  // { label: "Bebe Matua", mun_code: "035412" },
  // { label: "Bulacus", mun_code: "035412" },
  // { label: "San Agustin (Caingin)", mun_code: "035412" },
  // { label: "Santa Monica (Caingin)", mun_code: "035412" },
  // { label: "Cambasi", mun_code: "035412" },
  // { label: "Malauli", mun_code: "035412" },
  // { label: "Nigui", mun_code: "035412" },
  // { label: "Palimpe", mun_code: "035412" },
  // { label: "Puti", mun_code: "035412" },
  // { label: "Sagrada (Tibagin)", mun_code: "035412" },
  // { label: "San Isidro Anac", mun_code: "035412" },
  // { label: "San Isidro Matua (Pob.)", mun_code: "035412" },
  // { label: "San Nicolas (Pob.)", mun_code: "035412" },
  // { label: "San Pedro", mun_code: "035412" },
  // { label: "Santa Cruz", mun_code: "035412" },
  // { label: "Santa Lucia Matua", mun_code: "035412" },
  // { label: "Santa Lucia Paguiba", mun_code: "035412" },
  // { label: "Santa Lucia Wakas", mun_code: "035412" },
  // { label: "Santa Lucia Anac (Pob.)", mun_code: "035412" },
  // { label: "Sapang Kawayan", mun_code: "035412" },
  // { label: "Sua", mun_code: "035412" },
  // { label: "Acli", mun_code: "035413" },
  // { label: "Anao", mun_code: "035413" },
  // { label: "Balas", mun_code: "035413" },
  // { label: "Buenavista", mun_code: "035413" },
  // { label: "Camuning", mun_code: "035413" },
  // { label: "Cawayan", mun_code: "035413" },
  // { label: "Concepcion", mun_code: "035413" },
  // { label: "Culubasa", mun_code: "035413" },
  // { label: "Divisoria", mun_code: "035413" },
  // { label: "Dolores (Piring)", mun_code: "035413" },
  // { label: "Eden", mun_code: "035413" },
  // { label: "Gandus", mun_code: "035413" },
  // { label: "Lagundi", mun_code: "035413" },
  // { label: "Laput", mun_code: "035413" },
  // { label: "Laug", mun_code: "035413" },
  // { label: "Masamat", mun_code: "035413" },
  // { label: "Masangsang", mun_code: "035413" },
  // { label: "Nueva Victoria", mun_code: "035413" },
  // { label: "Pandacaqui", mun_code: "035413" },
  // { label: "Pangatlan", mun_code: "035413" },
  // { label: "Panipuan", mun_code: "035413" },
  // { label: "Parian (Pob.)", mun_code: "035413" },
  // { label: "Sabanilla", mun_code: "035413" },
  // { label: "San Antonio", mun_code: "035413" },
  // { label: "San Carlos", mun_code: "035413" },
  // { label: "San Jose Malino", mun_code: "035413" },
  // { label: "San Jose Matulid", mun_code: "035413" },
  // { label: "San Juan", mun_code: "035413" },
  // { label: "San Lorenzo", mun_code: "035413" },
  // { label: "San Miguel", mun_code: "035413" },
  // { label: "San Nicolas", mun_code: "035413" },
  // { label: "San Pablo", mun_code: "035413" },
  // { label: "San Patricio", mun_code: "035413" },
  // { label: "San Rafael", mun_code: "035413" },
  // { label: "San Roque", mun_code: "035413" },
  // { label: "San Vicente", mun_code: "035413" },
  // { label: "Santa Cruz", mun_code: "035413" },
  // { label: "Santa Maria", mun_code: "035413" },
  // { label: "Santo Domingo", mun_code: "035413" },
  // { label: "Santo Rosario", mun_code: "035413" },
  // { label: "Sapang Maisac", mun_code: "035413" },
  // { label: "Suclaban", mun_code: "035413" },
  // { label: "Tangle (Tanglay)", mun_code: "035413" },
  // { label: "Bulac", mun_code: "035414" },
  // { label: "Dawe", mun_code: "035414" },
  // { label: "Lourdes", mun_code: "035414" },
  // { label: "Maniango", mun_code: "035414" },
  // { label: "San Francisco 1st", mun_code: "035414" },
  // { label: "San Francisco 2nd", mun_code: "035414" },
  // { label: "San Isidro", mun_code: "035414" },
  // { label: "San Nicolas (Pob.)", mun_code: "035414" },
  // { label: "San Pedro", mun_code: "035414" },
  // { label: "Santa Catalina", mun_code: "035414" },
  // { label: "Santa Maria", mun_code: "035414" },
  // { label: "Santa Rita", mun_code: "035414" },
  // { label: "Santo Domingo", mun_code: "035414" },
  // { label: "Santo Rosario (Pob.)", mun_code: "035414" },
  // { label: "Saplad", mun_code: "035414" },
  // { label: "Babo Pangulo", mun_code: "035415" },
  // { label: "Babo Sacan", mun_code: "035415" },
  // { label: "Balubad", mun_code: "035415" },
  // { label: "Calzadang Bayu", mun_code: "035415" },
  // { label: "Camias", mun_code: "035415" },
  // { label: "Cangatba", mun_code: "035415" },
  // { label: "Diaz", mun_code: "035415" },
  // { label: "Dolores (Hacienda Dolores)", mun_code: "035415" },
  // { label: "Jalung", mun_code: "035415" },
  // { label: "Mancatian", mun_code: "035415" },
  // { label: "Manibaug Libutad", mun_code: "035415" },
  // { label: "Manibaug Paralaya", mun_code: "035415" },
  // { label: "Manibaug Pasig", mun_code: "035415" },
  // { label: "Manuali", mun_code: "035415" },
  // { label: "Mitla Proper", mun_code: "035415" },
  // { label: "Palat", mun_code: "035415" },
  // { label: "Pias", mun_code: "035415" },
  // { label: "Pio", mun_code: "035415" },
  // { label: "Planas", mun_code: "035415" },
  // { label: "Poblacion", mun_code: "035415" },
  // { label: "Pulong Santol", mun_code: "035415" },
  // { label: "Salu", mun_code: "035415" },
  // { label: "San Jose Mitla", mun_code: "035415" },
  // { label: "Santa Cruz", mun_code: "035415" },
  // { label: "Sepung Bulaun", mun_code: "035415" },
  // { label: "Sinura", mun_code: "035415" },
  // { label: "Villa Maria (Aetas)", mun_code: "035415" },
  // { label: "Inararo (Aetas)", mun_code: "035415" },
  // { label: "Sapang Uwak (Aetas)", mun_code: "035415" },
  // { label: "Alasas", mun_code: "035416" },
  // { label: "Baliti", mun_code: "035416" },
  // { label: "Bulaon", mun_code: "035416" },
  // { label: "Calulut", mun_code: "035416" },
  // { label: "Dela Paz Norte", mun_code: "035416" },
  // { label: "Dela Paz Sur", mun_code: "035416" },
  // { label: "Del Carmen", mun_code: "035416" },
  // { label: "Del Pilar", mun_code: "035416" },
  // { label: "Del Rosario", mun_code: "035416" },
  // { label: "Dolores", mun_code: "035416" },
  // { label: "Juliana", mun_code: "035416" },
  // { label: "Lara", mun_code: "035416" },
  // { label: "Lourdes", mun_code: "035416" },
  // { label: "Magliman", mun_code: "035416" },
  // { label: "Maimpis", mun_code: "035416" },
  // { label: "Malino", mun_code: "035416" },
  // { label: "Malpitic", mun_code: "035416" },
  // { label: "Pandaras", mun_code: "035416" },
  // { label: "Panipuan", mun_code: "035416" },
  // { label: "Santo Rosario (Pob.)", mun_code: "035416" },
  // { label: "Quebiauan", mun_code: "035416" },
  // { label: "Saguin", mun_code: "035416" },
  // { label: "San Agustin", mun_code: "035416" },
  // { label: "San Felipe", mun_code: "035416" },
  // { label: "San Isidro", mun_code: "035416" },
  // { label: "San Jose", mun_code: "035416" },
  // { label: "San Juan", mun_code: "035416" },
  // { label: "San Nicolas", mun_code: "035416" },
  // { label: "San Pedro", mun_code: "035416" },
  // { label: "Santa Lucia", mun_code: "035416" },
  // { label: "Santa Teresita", mun_code: "035416" },
  // { label: "Sindalan", mun_code: "035416" },
  // { label: "Telabastagan", mun_code: "035416" },
  // { label: "Pulung Bulu", mun_code: "035416" },
  // { label: "San Agustin", mun_code: "035417" },
  // { label: "San Carlos", mun_code: "035417" },
  // { label: "San Isidro", mun_code: "035417" },
  // { label: "San Jose", mun_code: "035417" },
  // { label: "San Juan", mun_code: "035417" },
  // { label: "San Nicolas", mun_code: "035417" },
  // { label: "San Roque", mun_code: "035417" },
  // { label: "San Sebastian", mun_code: "035417" },
  // { label: "Santa Catalina", mun_code: "035417" },
  // { label: "Santa Cruz Pambilog", mun_code: "035417" },
  // { label: "Santa Cruz Poblacion", mun_code: "035417" },
  // { label: "Santa Lucia", mun_code: "035417" },
  // { label: "Santa Monica", mun_code: "035417" },
  // { label: "Santa Rita", mun_code: "035417" },
  // { label: "Santo Rosario", mun_code: "035417" },
  // { label: "Santo Tomas", mun_code: "035417" },
  // { label: "Concepcion", mun_code: "035418" },
  // { label: "De La Paz", mun_code: "035418" },
  // { label: "San Juan (Pob.)", mun_code: "035418" },
  // { label: "San Agustin", mun_code: "035418" },
  // { label: "San Isidro", mun_code: "035418" },
  // { label: "San Jose", mun_code: "035418" },
  // { label: "San Miguel", mun_code: "035418" },
  // { label: "San Nicolas", mun_code: "035418" },
  // { label: "San Pablo Libutad", mun_code: "035418" },
  // { label: "San Pablo Proper", mun_code: "035418" },
  // { label: "San Pedro", mun_code: "035418" },
  // { label: "Santa Cruz", mun_code: "035418" },
  // { label: "Santa Monica", mun_code: "035418" },
  // { label: "San Agustin", mun_code: "035419" },
  // { label: "San Bartolome", mun_code: "035419" },
  // { label: "San Isidro", mun_code: "035419" },
  // { label: "San Joaquin (Pob.)", mun_code: "035419" },
  // { label: "San Jose", mun_code: "035419" },
  // { label: "San Juan", mun_code: "035419" },
  // { label: "San Nicolas", mun_code: "035419" },
  // { label: "San Pablo", mun_code: "035419" },
  // { label: "San Pedro", mun_code: "035419" },
  // { label: "San Roque", mun_code: "035419" },
  // { label: "Santa Lucia", mun_code: "035419" },
  // { label: "Santa Maria", mun_code: "035419" },
  // { label: "Santiago", mun_code: "035419" },
  // { label: "Santo Rosario", mun_code: "035419" },
  // { label: "Becuran", mun_code: "035420" },
  // { label: "Dila-dila", mun_code: "035420" },
  // { label: "San Agustin", mun_code: "035420" },
  // { label: "San Basilio", mun_code: "035420" },
  // { label: "San Isidro", mun_code: "035420" },
  // { label: "San Jose (Pob.)", mun_code: "035420" },
  // { label: "San Juan", mun_code: "035420" },
  // { label: "San Matias", mun_code: "035420" },
  // { label: "San Vicente", mun_code: "035420" },
  // { label: "Santa Monica", mun_code: "035420" },
  // { label: "Moras De La Paz", mun_code: "035421" },
  // { label: "Poblacion", mun_code: "035421" },
  // { label: "San Bartolome", mun_code: "035421" },
  // { label: "San Matias", mun_code: "035421" },
  // { label: "San Vicente", mun_code: "035421" },
  // { label: "Santo Rosario (Pau)", mun_code: "035421" },
  // { label: "Batang 1st", mun_code: "035422" },
  // { label: "Batang 2nd", mun_code: "035422" },
  // { label: "Mabuanbuan", mun_code: "035422" },
  // { label: "Malusac", mun_code: "035422" },
  // { label: "Santa Lucia (Pob.)", mun_code: "035422" },
  // { label: "San Antonio", mun_code: "035422" },
  // { label: "San Nicolas 1st", mun_code: "035422" },
  // { label: "San Nicolas 2nd", mun_code: "035422" },
  // { label: "San Pedro", mun_code: "035422" },
  // { label: "Santa Monica", mun_code: "035422" },
  // { label: "Santo Tomas", mun_code: "035422" },
  // { label: "Sabitanan", mun_code: "035422" },
  // { label: "Baguindoc (Baguinloc)", mun_code: "036901" },
  // { label: "Bantog", mun_code: "036901" },
  // { label: "Campos", mun_code: "036901" },
  // { label: "Carmen", mun_code: "036901" },
  // { label: "Casili", mun_code: "036901" },
  // { label: "Don Ramon", mun_code: "036901" },
  // { label: "Hernando", mun_code: "036901" },
  // { label: "Poblacion", mun_code: "036901" },
  // { label: "Rizal", mun_code: "036901" },
  // { label: "San Francisco East", mun_code: "036901" },
  // { label: "San Francisco West", mun_code: "036901" },
  // { label: "San Jose North", mun_code: "036901" },
  // { label: "San Jose South", mun_code: "036901" },
  // { label: "San Juan", mun_code: "036901" },
  // { label: "San Roque", mun_code: "036901" },
  // { label: "Santo Domingo", mun_code: "036901" },
  // { label: "Sinense", mun_code: "036901" },
  // { label: "Suaverdez", mun_code: "036901" },
  // { label: "Anupul", mun_code: "036902" },
  // { label: "Banaba", mun_code: "036902" },
  // { label: "Bangcu", mun_code: "036902" },
  // { label: "Culubasa", mun_code: "036902" },
  // { label: "Dela Cruz", mun_code: "036902" },
  // { label: "La Paz", mun_code: "036902" },
  // { label: "Lourdes", mun_code: "036902" },
  // { label: "Malonzo", mun_code: "036902" },
  // { label: "Virgen de los Remedios (Pacalcal)", mun_code: "036902" },
  // { label: "San Nicolas (Pob.)", mun_code: "036902" },
  // { label: "San Pedro", mun_code: "036902" },
  // { label: "San Rafael", mun_code: "036902" },
  // { label: "San Roque", mun_code: "036902" },
  // { label: "San Vicente", mun_code: "036902" },
  // { label: "Anoling 1st", mun_code: "036903" },
  // { label: "Anoling 2nd", mun_code: "036903" },
  // { label: "Anoling 3rd", mun_code: "036903" },
  // { label: "Bacabac", mun_code: "036903" },
  // { label: "Bacsay", mun_code: "036903" },
  // { label: "Bancay 1st", mun_code: "036903" },
  // { label: "Bancay 2nd", mun_code: "036903" },
  // { label: "Bilad", mun_code: "036903" },
  // { label: "Birbira", mun_code: "036903" },
  // { label: "Bobon Caarosipan", mun_code: "036903" },
  // { label: "Bobon 1st", mun_code: "036903" },
  // { label: "Bobon 2nd", mun_code: "036903" },
  // { label: "Cabanabaan", mun_code: "036903" },
  // { label: "Cacamilingan Norte", mun_code: "036903" },
  // { label: "Cacamilingan Sur", mun_code: "036903" },
  // { label: "Caniag", mun_code: "036903" },
  // { label: "Carael", mun_code: "036903" },
  // { label: "Cayaoan", mun_code: "036903" },
  // { label: "Cayasan", mun_code: "036903" },
  // { label: "Florida", mun_code: "036903" },
  // { label: "Lasong", mun_code: "036903" },
  // { label: "Libueg", mun_code: "036903" },
  // { label: "Malacampa", mun_code: "036903" },
  // { label: "Manakem", mun_code: "036903" },
  // { label: "Manupeg", mun_code: "036903" },
  // { label: "Marawi", mun_code: "036903" },
  // { label: "Matubog", mun_code: "036903" },
  // { label: "Nagrambacan", mun_code: "036903" },
  // { label: "Nagserialan", mun_code: "036903" },
  // { label: "Palimbo Proper", mun_code: "036903" },
  // { label: "Palimbo-Caarosipan", mun_code: "036903" },
  // { label: "Pao 1st", mun_code: "036903" },
  // { label: "Pao 2nd", mun_code: "036903" },
  // { label: "Pao 3rd", mun_code: "036903" },
  // { label: "Papaac", mun_code: "036903" },
  // { label: "Pindangan 1st", mun_code: "036903" },
  // { label: "Pindangan 2nd", mun_code: "036903" },
  // { label: "Poblacion A", mun_code: "036903" },
  // { label: "Poblacion B", mun_code: "036903" },
  // { label: "Poblacion C", mun_code: "036903" },
  // { label: "Poblacion D", mun_code: "036903" },
  // { label: "Poblacion E", mun_code: "036903" },
  // { label: "Poblacion F", mun_code: "036903" },
  // { label: "Poblacion G", mun_code: "036903" },
  // { label: "Poblacion H", mun_code: "036903" },
  // { label: "Poblacion I", mun_code: "036903" },
  // { label: "Poblacion J", mun_code: "036903" },
  // { label: "Santa Maria", mun_code: "036903" },
  // { label: "Sawat", mun_code: "036903" },
  // { label: "Sinilian 1st", mun_code: "036903" },
  // { label: "Sinilian 2nd", mun_code: "036903" },
  // { label: "Sinilian 3rd", mun_code: "036903" },
  // { label: "Sinilian Cacalibosoan", mun_code: "036903" },
  // { label: "Sinulatan 1st", mun_code: "036903" },
  // { label: "Sinulatan 2nd", mun_code: "036903" },
  // { label: "Surgui 1st", mun_code: "036903" },
  // { label: "Surgui 2nd", mun_code: "036903" },
  // { label: "Surgui 3rd", mun_code: "036903" },
  // { label: "Tambugan", mun_code: "036903" },
  // { label: "Telbang", mun_code: "036903" },
  // { label: "Tuec", mun_code: "036903" },
  // { label: "Aranguren", mun_code: "036904" },
  // { label: "Bueno", mun_code: "036904" },
  // { label: "Cubcub (Pob.)", mun_code: "036904" },
  // { label: "Cutcut 1st", mun_code: "036904" },
  // { label: "Cutcut 2nd", mun_code: "036904" },
  // { label: "Dolores", mun_code: "036904" },
  // { label: "Estrada (Calingcuan)", mun_code: "036904" },
  // { label: "Lawy", mun_code: "036904" },
  // { label: "Manga", mun_code: "036904" },
  // { label: "Manlapig", mun_code: "036904" },
  // { label: "O'Donnell", mun_code: "036904" },
  // { label: "Santa Lucia", mun_code: "036904" },
  // { label: "Santa Rita", mun_code: "036904" },
  // { label: "Santo Domingo 1st", mun_code: "036904" },
  // { label: "Santo Domingo 2nd", mun_code: "036904" },
  // { label: "Santo Rosario", mun_code: "036904" },
  // { label: "Talaga", mun_code: "036904" },
  // { label: "Maruglu", mun_code: "036904" },
  // { label: "Santa Juliana", mun_code: "036904" },
  // { label: "Cristo Rey", mun_code: "036904" },
  // { label: "Alfonso", mun_code: "036905" },
  // { label: "Balutu", mun_code: "036905" },
  // { label: "Cafe", mun_code: "036905" },
  // { label: "Calius Gueco", mun_code: "036905" },
  // { label: "Caluluan", mun_code: "036905" },
  // { label: "Castillo", mun_code: "036905" },
  // { label: "Corazon de Jesus", mun_code: "036905" },
  // { label: "Culatingan", mun_code: "036905" },
  // { label: "Dungan", mun_code: "036905" },
  // { label: "Dutung-A-Matas", mun_code: "036905" },
  // { label: "Lilibangan", mun_code: "036905" },
  // { label: "Mabilog", mun_code: "036905" },
  // { label: "Magao", mun_code: "036905" },
  // { label: "Malupa", mun_code: "036905" },
  // { label: "Minane", mun_code: "036905" },
  // { label: "Panalicsian (Panalicsican)", mun_code: "036905" },
  // { label: "Pando", mun_code: "036905" },
  // { label: "Parang", mun_code: "036905" },
  // { label: "Parulung", mun_code: "036905" },
  // { label: "Pitabunan", mun_code: "036905" },
  // { label: "San Agustin (Murcia)", mun_code: "036905" },
  // { label: "San Antonio", mun_code: "036905" },
  // { label: "San Bartolome", mun_code: "036905" },
  // { label: "San Francisco", mun_code: "036905" },
  // { label: "San Isidro (Almendras)", mun_code: "036905" },
  // { label: "San Jose (Pob.)", mun_code: "036905" },
  // { label: "San Juan (Castro)", mun_code: "036905" },
  // { label: "San Martin", mun_code: "036905" },
  // { label: "San Nicolas (Pob.)", mun_code: "036905" },
  // { label: "San Nicolas Balas", mun_code: "036905" },
  // { label: "Santa Cruz", mun_code: "036905" },
  // { label: "Santa Maria", mun_code: "036905" },
  // { label: "Santa Monica", mun_code: "036905" },
  // { label: "Santa Rita", mun_code: "036905" },
  // { label: "Santa Rosa", mun_code: "036905" },
  // { label: "Santiago", mun_code: "036905" },
  // { label: "Santo Cristo", mun_code: "036905" },
  // { label: "Santo Rosario (Magunting)", mun_code: "036905" },
  // { label: "Talimunduc Marimla", mun_code: "036905" },
  // { label: "Talimunduc San Miguel", mun_code: "036905" },
  // { label: "Telabanca", mun_code: "036905" },
  // { label: "Tinang", mun_code: "036905" },
  // { label: "San Vicente (Caluis/Cobra)", mun_code: "036905" },
  // { label: "Green Village", mun_code: "036905" },
  // { label: "Abagon", mun_code: "036906" },
  // { label: "Amacalan", mun_code: "036906" },
  // { label: "Apsayan", mun_code: "036906" },
  // { label: "Ayson", mun_code: "036906" },
  // { label: "Bawa", mun_code: "036906" },
  // { label: "Buenlag", mun_code: "036906" },
  // { label: "Bularit", mun_code: "036906" },
  // { label: "Calayaan", mun_code: "036906" },
  // { label: "Carbonel", mun_code: "036906" },
  // { label: "Cardona", mun_code: "036906" },
  // { label: "Caturay", mun_code: "036906" },
  // { label: "Danzo", mun_code: "036906" },
  // { label: "Dicolor", mun_code: "036906" },
  // { label: "Don Basilio", mun_code: "036906" },
  // { label: "Luna", mun_code: "036906" },
  // { label: "Mabini", mun_code: "036906" },
  // { label: "Magaspac", mun_code: "036906" },
  // { label: "Malayep", mun_code: "036906" },
  // { label: "Matapitap", mun_code: "036906" },
  // { label: "Matayumcab", mun_code: "036906" },
  // { label: "New Salem", mun_code: "036906" },
  // { label: "Oloybuaya", mun_code: "036906" },
  // { label: "Padapada", mun_code: "036906" },
  // { label: "Parsolingan", mun_code: "036906" },
  // { label: "Pinasling (Pinasung)", mun_code: "036906" },
  // { label: "Plastado", mun_code: "036906" },
  // { label: "Poblacion 1", mun_code: "036906" },
  // { label: "Poblacion 2", mun_code: "036906" },
  // { label: "Poblacion 3", mun_code: "036906" },
  // { label: "Quezon", mun_code: "036906" },
  // { label: "Rizal", mun_code: "036906" },
  // { label: "Salapungan", mun_code: "036906" },
  // { label: "San Agustin", mun_code: "036906" },
  // { label: "San Antonio", mun_code: "036906" },
  // { label: "San Bartolome", mun_code: "036906" },
  // { label: "San Jose", mun_code: "036906" },
  // { label: "Santa Lucia", mun_code: "036906" },
  // { label: "Santiago", mun_code: "036906" },
  // { label: "Sembrano", mun_code: "036906" },
  // { label: "Singat", mun_code: "036906" },
  // { label: "Sulipa", mun_code: "036906" },
  // { label: "Tagumbao", mun_code: "036906" },
  // { label: "Tangcaran", mun_code: "036906" },
  // { label: "Villa Paz", mun_code: "036906" },
  // { label: "Balanoy", mun_code: "036907" },
  // { label: "Bantog-Caricutan", mun_code: "036907" },
  // { label: "Caramutan", mun_code: "036907" },
  // { label: "Caut", mun_code: "036907" },
  // { label: "Comillas", mun_code: "036907" },
  // { label: "Dumarais", mun_code: "036907" },
  // { label: "Guevarra", mun_code: "036907" },
  // { label: "Kapanikian", mun_code: "036907" },
  // { label: "La Purisima", mun_code: "036907" },
  // { label: "Lara", mun_code: "036907" },
  // { label: "Laungcupang", mun_code: "036907" },
  // { label: "Lomboy", mun_code: "036907" },
  // { label: "Macalong", mun_code: "036907" },
  // { label: "Matayumtayum", mun_code: "036907" },
  // { label: "Mayang", mun_code: "036907" },
  // { label: "Motrico", mun_code: "036907" },
  // { label: "Paludpud", mun_code: "036907" },
  // { label: "Rizal", mun_code: "036907" },
  // { label: "San Isidro (Pob.)", mun_code: "036907" },
  // { label: "San Roque (Pob.)", mun_code: "036907" },
  // { label: "Sierra", mun_code: "036907" },
  // { label: "Ambalingit", mun_code: "036908" },
  // { label: "Baybayaoas", mun_code: "036908" },
  // { label: "Bigbiga", mun_code: "036908" },
  // { label: "Binbinaca", mun_code: "036908" },
  // { label: "Calabtangan", mun_code: "036908" },
  // { label: "Caocaoayan", mun_code: "036908" },
  // { label: "Carabaoan", mun_code: "036908" },
  // { label: "Cubcub", mun_code: "036908" },
  // { label: "Gayonggayong", mun_code: "036908" },
  // { label: "Gossood", mun_code: "036908" },
  // { label: "Labney", mun_code: "036908" },
  // { label: "Mamonit", mun_code: "036908" },
  // { label: "Maniniog", mun_code: "036908" },
  // { label: "Mapandan", mun_code: "036908" },
  // { label: "Nambalan", mun_code: "036908" },
  // { label: "Pedro L. Quines", mun_code: "036908" },
  // { label: "Pitombayog", mun_code: "036908" },
  // { label: "Poblacion Norte", mun_code: "036908" },
  // { label: "Poblacion Sur", mun_code: "036908" },
  // { label: "Rotrottooc", mun_code: "036908" },
  // { label: "San Bartolome", mun_code: "036908" },
  // { label: "San Jose", mun_code: "036908" },
  // { label: "Taldiapan", mun_code: "036908" },
  // { label: "Tangcarang", mun_code: "036908" },
  // { label: "Ablang-Sapang", mun_code: "036909" },
  // { label: "Aringin", mun_code: "036909" },
  // { label: "Atencio", mun_code: "036909" },
  // { label: "Banaoang East", mun_code: "036909" },
  // { label: "Banaoang West", mun_code: "036909" },
  // { label: "Baquero Norte", mun_code: "036909" },
  // { label: "Baquero Sur", mun_code: "036909" },
  // { label: "Burgos", mun_code: "036909" },
  // { label: "Calamay", mun_code: "036909" },
  // { label: "Calapan", mun_code: "036909" },
  // { label: "Camangaan East", mun_code: "036909" },
  // { label: "Camangaan West", mun_code: "036909" },
  // { label: "Camposanto 1 - Norte", mun_code: "036909" },
  // { label: "Camposanto 1 - Sur", mun_code: "036909" },
  // { label: "Camposanto 2", mun_code: "036909" },
  // { label: "Capaoayan", mun_code: "036909" },
  // { label: "Lapsing", mun_code: "036909" },
  // { label: "Mabini", mun_code: "036909" },
  // { label: "Maluac", mun_code: "036909" },
  // { label: "Poblacion 1", mun_code: "036909" },
  // { label: "Poblacion 2", mun_code: "036909" },
  // { label: "Poblacion 3", mun_code: "036909" },
  // { label: "Poblacion 4", mun_code: "036909" },
  // { label: "Rizal", mun_code: "036909" },
  // { label: "San Juan", mun_code: "036909" },
  // { label: "San Julian", mun_code: "036909" },
  // { label: "San Leon", mun_code: "036909" },
  // { label: "San Pedro", mun_code: "036909" },
  // { label: "San Roque", mun_code: "036909" },
  // { label: "Santa Lucia East", mun_code: "036909" },
  // { label: "Santa Lucia West", mun_code: "036909" },
  // { label: "Santa Maria", mun_code: "036909" },
  // { label: "Santa Monica", mun_code: "036909" },
  // { label: "Tubectubang", mun_code: "036909" },
  // { label: "Tolega Norte", mun_code: "036909" },
  // { label: "Tolega Sur", mun_code: "036909" },
  // { label: "Villa", mun_code: "036909" },
  // { label: "Abogado", mun_code: "036910" },
  // { label: "Acocolao", mun_code: "036910" },
  // { label: "Aduas", mun_code: "036910" },
  // { label: "Apulid", mun_code: "036910" },
  // { label: "Balaoang", mun_code: "036910" },
  // { label: "Barang (Borang)", mun_code: "036910" },
  // { label: "Brillante", mun_code: "036910" },
  // { label: "Burgos", mun_code: "036910" },
  // { label: "Cabayaoasan", mun_code: "036910" },
  // { label: "Canan", mun_code: "036910" },
  // { label: "Carino", mun_code: "036910" },
  // { label: "Cayanga", mun_code: "036910" },
  // { label: "Colibangbang", mun_code: "036910" },
  // { label: "Coral", mun_code: "036910" },
  // { label: "Dapdap", mun_code: "036910" },
  // { label: "Estacion", mun_code: "036910" },
  // { label: "Mabilang", mun_code: "036910" },
  // { label: "Manaois", mun_code: "036910" },
  // { label: "Matalapitap", mun_code: "036910" },
  // { label: "Nagmisaan", mun_code: "036910" },
  // { label: "Nancamarinan", mun_code: "036910" },
  // { label: "Nipaco", mun_code: "036910" },
  // { label: "Patalan", mun_code: "036910" },
  // { label: "Poblacion Norte", mun_code: "036910" },
  // { label: "Poblacion Sur", mun_code: "036910" },
  // { label: "Rang-ayan", mun_code: "036910" },
  // { label: "Salumague", mun_code: "036910" },
  // { label: "Samput", mun_code: "036910" },
  // { label: "San Carlos", mun_code: "036910" },
  // { label: "San Isidro", mun_code: "036910" },
  // { label: "San Juan de Milla", mun_code: "036910" },
  // { label: "Santa Ines", mun_code: "036910" },
  // { label: "Sinigpit", mun_code: "036910" },
  // { label: "Tablang", mun_code: "036910" },
  // { label: "Ventenilla", mun_code: "036910" },
  // { label: "Balite", mun_code: "036911" },
  // { label: "Buenavista", mun_code: "036911" },
  // { label: "Cadanglaan", mun_code: "036911" },
  // { label: "Estipona", mun_code: "036911" },
  // { label: "Linao", mun_code: "036911" },
  // { label: "Maasin", mun_code: "036911" },
  // { label: "Matindeg", mun_code: "036911" },
  // { label: "Maungib", mun_code: "036911" },
  // { label: "Naya", mun_code: "036911" },
  // { label: "Nilasin 1st", mun_code: "036911" },
  // { label: "Nilasin 2nd", mun_code: "036911" },
  // { label: "Poblacion 1", mun_code: "036911" },
  // { label: "Poblacion 2", mun_code: "036911" },
  // { label: "Poblacion 3", mun_code: "036911" },
  // { label: "Poroc", mun_code: "036911" },
  // { label: "Singat", mun_code: "036911" },
  // { label: "Coral-Iloco", mun_code: "036912" },
  // { label: "Guiteb", mun_code: "036912" },
  // { label: "Pance", mun_code: "036912" },
  // { label: "Poblacion Center", mun_code: "036912" },
  // { label: "Poblacion North", mun_code: "036912" },
  // { label: "Poblacion South", mun_code: "036912" },
  // { label: "San Juan", mun_code: "036912" },
  // { label: "San Raymundo", mun_code: "036912" },
  // { label: "Toledo", mun_code: "036912" },
  // { label: "Balloc", mun_code: "036913" },
  // { label: "Bamban", mun_code: "036913" },
  // { label: "Casipo", mun_code: "036913" },
  // { label: "Catagudingan", mun_code: "036913" },
  // { label: "Daldalayap", mun_code: "036913" },
  // { label: "Doclong 1", mun_code: "036913" },
  // { label: "Doclong 2", mun_code: "036913" },
  // { label: "Maasin", mun_code: "036913" },
  // { label: "Nagsabaran", mun_code: "036913" },
  // { label: "Pit-ao", mun_code: "036913" },
  // { label: "Poblacion Norte", mun_code: "036913" },
  // { label: "Poblacion Sur", mun_code: "036913" },
  // { label: "Colubot", mun_code: "036914" },
  // { label: "Lanat", mun_code: "036914" },
  // { label: "Legaspi", mun_code: "036914" },
  // { label: "Mangandingay", mun_code: "036914" },
  // { label: "Matarannoc", mun_code: "036914" },
  // { label: "Pacpaco", mun_code: "036914" },
  // { label: "Poblacion", mun_code: "036914" },
  // { label: "Salcedo", mun_code: "036914" },
  // { label: "San Agustin", mun_code: "036914" },
  // { label: "San Felipe", mun_code: "036914" },
  // { label: "San Jacinto", mun_code: "036914" },
  // { label: "San Miguel", mun_code: "036914" },
  // { label: "San Narciso", mun_code: "036914" },
  // { label: "San Vicente", mun_code: "036914" },
  // { label: "Santa Maria", mun_code: "036914" },
  // { label: "Baldios", mun_code: "036915" },
  // { label: "Botbotones", mun_code: "036915" },
  // { label: "Caanamongan", mun_code: "036915" },
  // { label: "Cabaruan", mun_code: "036915" },
  // { label: "Cabugbugan", mun_code: "036915" },
  // { label: "Caduldulaoan", mun_code: "036915" },
  // { label: "Calipayan", mun_code: "036915" },
  // { label: "Macaguing", mun_code: "036915" },
  // { label: "Nambalan", mun_code: "036915" },
  // { label: "Padapada", mun_code: "036915" },
  // { label: "Pilpila", mun_code: "036915" },
  // { label: "Pinpinas", mun_code: "036915" },
  // { label: "Poblacion East", mun_code: "036915" },
  // { label: "Poblacion West", mun_code: "036915" },
  // { label: "Pugo-Cecilio", mun_code: "036915" },
  // { label: "San Francisco", mun_code: "036915" },
  // { label: "San Sotero", mun_code: "036915" },
  // { label: "San Vicente", mun_code: "036915" },
  // { label: "Santa Ines Centro", mun_code: "036915" },
  // { label: "Santa Ines East", mun_code: "036915" },
  // { label: "Santa Ines West", mun_code: "036915" },
  // { label: "Taguiporo", mun_code: "036915" },
  // { label: "Timmaguab", mun_code: "036915" },
  // { label: "Vargas", mun_code: "036915" },
  // { label: "Aguso", mun_code: "036916" },
  // { label: "Alvindia Segundo", mun_code: "036916" },
  // { label: "Amucao", mun_code: "036916" },
  // { label: "Armenia", mun_code: "036916" },
  // { label: "Asturias", mun_code: "036916" },
  // { label: "Atioc", mun_code: "036916" },
  // { label: "Balanti", mun_code: "036916" },
  // { label: "Balete", mun_code: "036916" },
  // { label: "Balibago I", mun_code: "036916" },
  // { label: "Balibago II", mun_code: "036916" },
  // { label: "Balingcanaway", mun_code: "036916" },
  // { label: "Banaba", mun_code: "036916" },
  // { label: "Bantog", mun_code: "036916" },
  // { label: "Baras-baras", mun_code: "036916" },
  // { label: "Batang-batang", mun_code: "036916" },
  // { label: "Binauganan", mun_code: "036916" },
  // { label: "Bora", mun_code: "036916" },
  // { label: "Buenavista", mun_code: "036916" },
  // { label: "Buhilit (Bubulit)", mun_code: "036916" },
  // { label: "Burot", mun_code: "036916" },
  // { label: "Calingcuan", mun_code: "036916" },
  // { label: "Capehan", mun_code: "036916" },
  // { label: "Carangian", mun_code: "036916" },
  // { label: "Central", mun_code: "036916" },
  // { label: "Culipat", mun_code: "036916" },
  // { label: "Cut-cut I", mun_code: "036916" },
  // { label: "Cut-cut II", mun_code: "036916" },
  // { label: "Dalayap", mun_code: "036916" },
  // { label: "Dela Paz", mun_code: "036916" },
  // { label: "Dolores", mun_code: "036916" },
  // { label: "Laoang", mun_code: "036916" },
  // { label: "Ligtasan", mun_code: "036916" },
  // { label: "Lourdes", mun_code: "036916" },
  // { label: "Mabini", mun_code: "036916" },
  // { label: "Maligaya", mun_code: "036916" },
  // { label: "Maliwalo", mun_code: "036916" },
  // { label: "Mapalacsiao", mun_code: "036916" },
  // { label: "Mapalad", mun_code: "036916" },
  // { label: "Matatalaib", mun_code: "036916" },
  // { label: "Paraiso", mun_code: "036916" },
  // { label: "Poblacion", mun_code: "036916" },
  // { label: "San Carlos", mun_code: "036916" },
  // { label: "San Francisco", mun_code: "036916" },
  // { label: "San Isidro", mun_code: "036916" },
  // { label: "San Jose", mun_code: "036916" },
  // { label: "San Jose de Urquico", mun_code: "036916" },
  // { label: "San Juan de Mata", mun_code: "036916" },
  // { label: "San Luis", mun_code: "036916" },
  // { label: "San Manuel", mun_code: "036916" },
  // { label: "San Miguel", mun_code: "036916" },
  // { label: "San Nicolas", mun_code: "036916" },
  // { label: "San Pablo", mun_code: "036916" },
  // { label: "San Pascual", mun_code: "036916" },
  // { label: "San Rafael", mun_code: "036916" },
  // { label: "San Roque", mun_code: "036916" },
  // { label: "San Sebastian", mun_code: "036916" },
  // { label: "San Vicente", mun_code: "036916" },
  // { label: "Santa Cruz (Alvindia Primero)", mun_code: "036916" },
  // { label: "Santa Maria", mun_code: "036916" },
  // { label: "Santo Cristo", mun_code: "036916" },
  // { label: "Santo Domingo", mun_code: "036916" },
  // { label: "Sapang Maragul", mun_code: "036916" },
  // { label: "Sapang Tagalog", mun_code: "036916" },
  // { label: "Sepung Calzada", mun_code: "036916" },
  // { label: "Sinait", mun_code: "036916" },
  // { label: "Suizo", mun_code: "036916" },
  // { label: "Tariji", mun_code: "036916" },
  // { label: "Tibag", mun_code: "036916" },
  // { label: "Tibagan", mun_code: "036916" },
  // { label: "Trinidad (Trinidad Primero)", mun_code: "036916" },
  // { label: "Ungot", mun_code: "036916" },
  // { label: "Matadero", mun_code: "036916" },
  // { label: "Salapungan", mun_code: "036916" },
  // { label: "Villa Bacolor", mun_code: "036916" },
  // { label: "Care", mun_code: "036916" },
  // { label: "Baculong", mun_code: "036917" },
  // { label: "Balayang", mun_code: "036917" },
  // { label: "Balbaloto", mun_code: "036917" },
  // { label: "Bangar", mun_code: "036917" },
  // { label: "Bantog", mun_code: "036917" },
  // { label: "Batangbatang", mun_code: "036917" },
  // { label: "Bulo", mun_code: "036917" },
  // { label: "Cabuluan", mun_code: "036917" },
  // { label: "Calibungan", mun_code: "036917" },
  // { label: "Canarem", mun_code: "036917" },
  // { label: "Cruz", mun_code: "036917" },
  // { label: "Lalapac", mun_code: "036917" },
  // { label: "Maluid", mun_code: "036917" },
  // { label: "Mangolago", mun_code: "036917" },
  // { label: "Masalasa", mun_code: "036917" },
  // { label: "Palacpalac", mun_code: "036917" },
  // { label: "San Agustin", mun_code: "036917" },
  // { label: "San Andres", mun_code: "036917" },
  // { label: "San Fernando (Pob.)", mun_code: "036917" },
  // { label: "San Francisco", mun_code: "036917" },
  // { label: "San Gavino (Pob.)", mun_code: "036917" },
  // { label: "San Jacinto", mun_code: "036917" },
  // { label: "San Nicolas (Pob.)", mun_code: "036917" },
  // { label: "San Vicente", mun_code: "036917" },
  // { label: "Santa Barbara", mun_code: "036917" },
  // { label: "Santa Lucia (Pob.)", mun_code: "036917" },
  // { label: "Burgos", mun_code: "036918" },
  // { label: "David", mun_code: "036918" },
  // { label: "Iba", mun_code: "036918" },
  // { label: "Labney", mun_code: "036918" },
  // { label: "Lawacamulag", mun_code: "036918" },
  // { label: "Lubigan", mun_code: "036918" },
  // { label: "Maamot", mun_code: "036918" },
  // { label: "Mababanaba", mun_code: "036918" },
  // { label: "Moriones", mun_code: "036918" },
  // { label: "Pao", mun_code: "036918" },
  // { label: "San Juan de Valdez", mun_code: "036918" },
  // { label: "Sula", mun_code: "036918" },
  // { label: "Villa Aglipay", mun_code: "036918" },
  // { label: "Bangan", mun_code: "037101" },
  // { label: "Batonlapoc", mun_code: "037101" },
  // { label: "Beneg", mun_code: "037101" },
  // { label: "Capayawan", mun_code: "037101" },
  // { label: "Carael", mun_code: "037101" },
  // { label: "Danacbunga", mun_code: "037101" },
  // { label: "Maguisguis", mun_code: "037101" },
  // { label: "Mambog", mun_code: "037101" },
  // { label: "Moraza", mun_code: "037101" },
  // { label: "Paco (Pob.)", mun_code: "037101" },
  // { label: "Panan", mun_code: "037101" },
  // { label: "Parel", mun_code: "037101" },
  // { label: "Paudpod", mun_code: "037101" },
  // { label: "Poonbato", mun_code: "037101" },
  // { label: "Porac", mun_code: "037101" },
  // { label: "San Isidro", mun_code: "037101" },
  // { label: "San Juan", mun_code: "037101" },
  // { label: "San Miguel", mun_code: "037101" },
  // { label: "Santiago", mun_code: "037101" },
  // { label: "Tampo (Pob.)", mun_code: "037101" },
  // { label: "Taugtog", mun_code: "037101" },
  // { label: "Villar", mun_code: "037101" },
  // { label: "Bancal", mun_code: "037101" },
  // { label: "Belbel", mun_code: "037101" },
  // { label: "Binuclutan", mun_code: "037101" },
  // { label: "Burgos", mun_code: "037101" },
  // { label: "Cabatuan", mun_code: "037101" },
  // { label: "Malomboy", mun_code: "037101" },
  // { label: "Nacolcol", mun_code: "037101" },
  // { label: "Owaog-Nibloc", mun_code: "037101" },
  // { label: "Palis", mun_code: "037101" },
  // { label: "Anonang", mun_code: "037102" },
  // { label: "Apo-apo", mun_code: "037102" },
  // { label: "Arew", mun_code: "037102" },
  // { label: "Banuambayo (Pob.)", mun_code: "037102" },
  // { label: "Cadmang-Reserva", mun_code: "037102" },
  // { label: "Camiling (Camiing)", mun_code: "037102" },
  // { label: "Casabaan", mun_code: "037102" },
  // { label: "Dolores (Pob.)", mun_code: "037102" },
  // { label: "Del Carmen (Pob.)", mun_code: "037102" },
  // { label: "Laoag", mun_code: "037102" },
  // { label: "Lomboy", mun_code: "037102" },
  // { label: "Longos", mun_code: "037102" },
  // { label: "Mabanglit", mun_code: "037102" },
  // { label: "New San Juan", mun_code: "037102" },
  // { label: "San Antonio", mun_code: "037102" },
  // { label: "San Isidro", mun_code: "037102" },
  // { label: "San Juan (Pob.)", mun_code: "037102" },
  // { label: "San Rafael", mun_code: "037102" },
  // { label: "Santa Rita", mun_code: "037102" },
  // { label: "Tondo", mun_code: "037102" },
  // { label: "Felmida-Diaz", mun_code: "037102" },
  // { label: "Babancal", mun_code: "037103" },
  // { label: "Binabalian", mun_code: "037103" },
  // { label: "Catol", mun_code: "037103" },
  // { label: "Dampay", mun_code: "037103" },
  // { label: "Lauis", mun_code: "037103" },
  // { label: "Libertador", mun_code: "037103" },
  // { label: "Malabon (San Roque)", mun_code: "037103" },
  // { label: "Malimanga", mun_code: "037103" },
  // { label: "Pamibian", mun_code: "037103" },
  // { label: "Panayonan", mun_code: "037103" },
  // { label: "Pinagrealan", mun_code: "037103" },
  // { label: "Poblacion", mun_code: "037103" },
  // { label: "Sinabacan", mun_code: "037103" },
  // { label: "Taposo", mun_code: "037103" },
  // { label: "Uacon", mun_code: "037103" },
  // { label: "Yamot", mun_code: "037103" },
  // { label: "Balaybay", mun_code: "037104" },
  // { label: "Buenavista", mun_code: "037104" },
  // { label: "Del Pilar", mun_code: "037104" },
  // { label: "Looc", mun_code: "037104" },
  // { label: "Magsaysay", mun_code: "037104" },
  // { label: "Nagbayan", mun_code: "037104" },
  // { label: "Nagbunga", mun_code: "037104" },
  // { label: "San Agustin", mun_code: "037104" },
  // { label: "San Jose (Pob.)", mun_code: "037104" },
  // { label: "San Juan (Pob.)", mun_code: "037104" },
  // { label: "San Nicolas", mun_code: "037104" },
  // { label: "San Pablo (Pob.)", mun_code: "037104" },
  // { label: "San Roque", mun_code: "037104" },
  // { label: "Santa Maria", mun_code: "037104" },
  // { label: "Amungan", mun_code: "037105" },
  // { label: "Zone 2 Pob. (Aypa)", mun_code: "037105" },
  // { label: "Zone 5 Pob. (Bano)", mun_code: "037105" },
  // { label: "Bangantalinga", mun_code: "037105" },
  // { label: "Zone 6 Pob. (Baytan)", mun_code: "037105" },
  // { label: "Zone 3 Pob. (Botlay)", mun_code: "037105" },
  // { label: "Dirita-Baloguen", mun_code: "037105" },
  // { label: "Zone 1 Pob. (Libaba)", mun_code: "037105" },
  // { label: "Lipay-Dingin-Panibuatan", mun_code: "037105" },
  // { label: "Palanginan (Palanguinan-Tambak)", mun_code: "037105" },
  // { label: "Zone 4 Pob. (Sagapan)", mun_code: "037105" },
  // { label: "San Agustin", mun_code: "037105" },
  // { label: "Santa Barbara", mun_code: "037105" },
  // { label: "Santo Rosario", mun_code: "037105" },
  // { label: "Baloganon", mun_code: "037106" },
  // { label: "Bamban", mun_code: "037106" },
  // { label: "Bani", mun_code: "037106" },
  // { label: "Collat", mun_code: "037106" },
  // { label: "Inhobol", mun_code: "037106" },
  // { label: "North Poblacion", mun_code: "037106" },
  // { label: "San Lorenzo", mun_code: "037106" },
  // { label: "San Salvador", mun_code: "037106" },
  // { label: "Santa Rita", mun_code: "037106" },
  // { label: "Santo Rosario", mun_code: "037106" },
  // { label: "South Poblacion", mun_code: "037106" },
  // { label: "Taltal", mun_code: "037106" },
  // { label: "Tapuac", mun_code: "037106" },
  // { label: "Asinan", mun_code: "037107" },
  // { label: "Banicain", mun_code: "037107" },
  // { label: "Barreto", mun_code: "037107" },
  // { label: "East Bajac-bajac", mun_code: "037107" },
  // { label: "East Tapinac", mun_code: "037107" },
  // { label: "Gordon Heights", mun_code: "037107" },
  // { label: "Kalaklan", mun_code: "037107" },
  // { label: "New Kalalake", mun_code: "037107" },
  // { label: "Mabayuan", mun_code: "037107" },
  // { label: "New Cabalan", mun_code: "037107" },
  // { label: "New Ilalim", mun_code: "037107" },
  // { label: "New Kababae", mun_code: "037107" },
  // { label: "Pag-asa", mun_code: "037107" },
  // { label: "Santa Rita", mun_code: "037107" },
  // { label: "West Bajac-bajac", mun_code: "037107" },
  // { label: "West Tapinac", mun_code: "037107" },
  // { label: "Old Cabalan", mun_code: "037107" },
  // { label: "Alwa", mun_code: "037108" },
  // { label: "Bato", mun_code: "037108" },
  // { label: "Bulawen", mun_code: "037108" },
  // { label: "Cauyan", mun_code: "037108" },
  // { label: "Garreta", mun_code: "037108" },
  // { label: "Libaba", mun_code: "037108" },
  // { label: "Liozon", mun_code: "037108" },
  // { label: "Lipay", mun_code: "037108" },
  // { label: "Locloc", mun_code: "037108" },
  // { label: "Macarang", mun_code: "037108" },
  // { label: "Magalawa", mun_code: "037108" },
  // { label: "Pangolingan", mun_code: "037108" },
  // { label: "East Poblacion", mun_code: "037108" },
  // { label: "West Poblacion", mun_code: "037108" },
  // { label: "Salaza", mun_code: "037108" },
  // { label: "San Juan", mun_code: "037108" },
  // { label: "Santo Tomas", mun_code: "037108" },
  // { label: "Tition (San Vicente)", mun_code: "037108" },
  // { label: "Angeles", mun_code: "037109" },
  // { label: "Antipolo (Pob.)", mun_code: "037109" },
  // { label: "Burgos (Pob.)", mun_code: "037109" },
  // { label: "West Dirita", mun_code: "037109" },
  // { label: "East Dirita", mun_code: "037109" },
  // { label: "Luna (Pob.)", mun_code: "037109" },
  // { label: "Pundaquit", mun_code: "037109" },
  // { label: "San Esteban", mun_code: "037109" },
  // { label: "San Gregorio (Pob.)", mun_code: "037109" },
  // { label: "San Juan (Pob.)", mun_code: "037109" },
  // { label: "San Miguel", mun_code: "037109" },
  // { label: "San Nicolas (Pob.)", mun_code: "037109" },
  // { label: "Santiago", mun_code: "037109" },
  // { label: "Rizal", mun_code: "037109" },
  // { label: "Amagna (Pob.)", mun_code: "037110" },
  // { label: "Apostol (Pob.)", mun_code: "037110" },
  // { label: "Balincaguing", mun_code: "037110" },
  // { label: "Feria (Pob.)", mun_code: "037110" },
  // { label: "Maloma", mun_code: "037110" },
  // { label: "Manglicmot (Pob.)", mun_code: "037110" },
  // { label: "Rosete (Pob.)", mun_code: "037110" },
  // { label: "San Rafael", mun_code: "037110" },
  // { label: "Sindol", mun_code: "037110" },
  // { label: "Aglao", mun_code: "037111" },
  // { label: "Buhawen", mun_code: "037111" },
  // { label: "Burgos (Pob.)", mun_code: "037111" },
  // { label: "Consuelo Norte", mun_code: "037111" },
  // { label: "Consuelo Sur (Pob.)", mun_code: "037111" },
  // { label: "La Paz (Pob.)", mun_code: "037111" },
  // { label: "Laoag", mun_code: "037111" },
  // { label: "Linasin", mun_code: "037111" },
  // { label: "Linusungan", mun_code: "037111" },
  // { label: "Lucero (Pob.)", mun_code: "037111" },
  // { label: "Nagbunga", mun_code: "037111" },
  // { label: "Rizal (Pob.)", mun_code: "037111" },
  // { label: "San Guillermo (Pob.)", mun_code: "037111" },
  // { label: "San Isidro (Pob.)", mun_code: "037111" },
  // { label: "San Rafael", mun_code: "037111" },
  // { label: "Santa Fe", mun_code: "037111" },
  // { label: "Central (Pob.)", mun_code: "037111" },
  // { label: "Rabanes", mun_code: "037111" },
  // { label: "Alusiis", mun_code: "037112" },
  // { label: "Beddeng", mun_code: "037112" },
  // { label: "Candelaria (Pob.)", mun_code: "037112" },
  // { label: "Dallipawen", mun_code: "037112" },
  // { label: "Grullo", mun_code: "037112" },
  // { label: "La Paz", mun_code: "037112" },
  // { label: "Libertad (Pob.)", mun_code: "037112" },
  // { label: "Namatacan", mun_code: "037112" },
  // { label: "Natividad (Pob.)", mun_code: "037112" },
  // { label: "Paite", mun_code: "037112" },
  // { label: "Patrocinio (Pob.)", mun_code: "037112" },
  // { label: "San Jose (Pob.)", mun_code: "037112" },
  // { label: "San Juan (Pob.)", mun_code: "037112" },
  // { label: "San Pascual (Pob.)", mun_code: "037112" },
  // { label: "San Rafael (Pob.)", mun_code: "037112" },
  // { label: "Siminublan", mun_code: "037112" },
  // { label: "Omaya", mun_code: "037112" },
  // { label: "Babuyan", mun_code: "037113" },
  // { label: "Bolitoc", mun_code: "037113" },
  // { label: "Bangcol", mun_code: "037113" },
  // { label: "Bayto", mun_code: "037113" },
  // { label: "Biay", mun_code: "037113" },
  // { label: "Canaynayan", mun_code: "037113" },
  // { label: "Gama", mun_code: "037113" },
  // { label: "Guinabon", mun_code: "037113" },
  // { label: "Guisguis", mun_code: "037113" },
  // { label: "Lipay", mun_code: "037113" },
  // { label: "Lomboy", mun_code: "037113" },
  // { label: "Lucapon North", mun_code: "037113" },
  // { label: "Lucapon South", mun_code: "037113" },
  // { label: "Malabago", mun_code: "037113" },
  // { label: "Naulo", mun_code: "037113" },
  // { label: "Poblacion North", mun_code: "037113" },
  // { label: "Pagatpat", mun_code: "037113" },
  // { label: "Pamonoran", mun_code: "037113" },
  // { label: "Sabang", mun_code: "037113" },
  // { label: "San Fernando", mun_code: "037113" },
  // { label: "Poblacion South", mun_code: "037113" },
  // { label: "Tabalong", mun_code: "037113" },
  // { label: "Tubotubo North", mun_code: "037113" },
  // { label: "Tubotubo South", mun_code: "037113" },
  // { label: "Bulawon", mun_code: "037113" },
  // { label: "Aningway Sacatihan", mun_code: "037114" },
  // { label: "Asinan Poblacion", mun_code: "037114" },
  // { label: "Asinan Proper", mun_code: "037114" },
  // { label: "Baraca-Camachile (Pob.)", mun_code: "037114" },
  // { label: "Batiawan", mun_code: "037114" },
  // { label: "Calapacuan", mun_code: "037114" },
  // { label: "Calapandayan (Pob.)", mun_code: "037114" },
  // { label: "Cawag", mun_code: "037114" },
  // { label: "Ilwas (Pob.)", mun_code: "037114" },
  // { label: "Mangan-Vaca", mun_code: "037114" },
  // { label: "Matain", mun_code: "037114" },
  // { label: "Naugsol", mun_code: "037114" },
  // { label: "Pamatawan", mun_code: "037114" },
  // { label: "San Isidro", mun_code: "037114" },
  // { label: "Santo Tomas", mun_code: "037114" },
  // { label: "Wawandue (Pob.)", mun_code: "037114" },
  // { label: "Barangay I (Pob.)", mun_code: "037701" },
  // { label: "Barangay II (Pob.)", mun_code: "037701" },
  // { label: "Barangay III (Pob.)", mun_code: "037701" },
  // { label: "Barangay IV (Pob.)", mun_code: "037701" },
  // { label: "Barangay V (Pob.)", mun_code: "037701" },
  // { label: "Buhangin", mun_code: "037701" },
  // { label: "Calabuanan", mun_code: "037701" },
  // { label: "Obligacion", mun_code: "037701" },
  // { label: "Pingit", mun_code: "037701" },
  // { label: "Reserva", mun_code: "037701" },
  // { label: "Sabang", mun_code: "037701" },
  // { label: "Suclayin", mun_code: "037701" },
  // { label: "Zabali", mun_code: "037701" },
  // { label: "Barangay 1 (Pob.)", mun_code: "037702" },
  // { label: "Barangay 2 (Pob.)", mun_code: "037702" },
  // { label: "Barangay 3 (Pob.)", mun_code: "037702" },
  // { label: "Barangay 4 (Pob.)", mun_code: "037702" },
  // { label: "Barangay 5 (Pob.)", mun_code: "037702" },
  // { label: "Barangay 6 (Pob.)", mun_code: "037702" },
  // { label: "Barangay 7 (Pob.)", mun_code: "037702" },
  // { label: "Barangay 8 (Pob.)", mun_code: "037702" },
  // { label: "Calabgan", mun_code: "037702" },
  // { label: "Calangcuasan", mun_code: "037702" },
  // { label: "Calantas", mun_code: "037702" },
  // { label: "Culat", mun_code: "037702" },
  // { label: "Dibet", mun_code: "037702" },
  // { label: "Esperanza", mun_code: "037702" },
  // { label: "Lual", mun_code: "037702" },
  // { label: "Marikit", mun_code: "037702" },
  // { label: "Tabas", mun_code: "037702" },
  // { label: "Tinib", mun_code: "037702" },
  // { label: "Bianuan", mun_code: "037702" },
  // { label: "Cozo", mun_code: "037702" },
  // { label: "Dibacong", mun_code: "037702" },
  // { label: "Ditinagyan", mun_code: "037702" },
  // { label: "Esteves", mun_code: "037702" },
  // { label: "San Ildefonso", mun_code: "037702" },
  // { label: "Diagyan", mun_code: "037703" },
  // { label: "Dicabasan", mun_code: "037703" },
  // { label: "Dilaguidi", mun_code: "037703" },
  // { label: "Dimaseset", mun_code: "037703" },
  // { label: "Diniog", mun_code: "037703" },
  // { label: "Lawang", mun_code: "037703" },
  // { label: "Maligaya (Pob.)", mun_code: "037703" },
  // { label: "Manggitahan", mun_code: "037703" },
  // { label: "Masagana (Pob.)", mun_code: "037703" },
  // { label: "Ura", mun_code: "037703" },
  // { label: "Esperanza", mun_code: "037703" },
  // { label: "Abuleg", mun_code: "037704" },
  // { label: "Zone I (Pob.)", mun_code: "037704" },
  // { label: "Zone II (Pob.)", mun_code: "037704" },
  // { label: "Nipoo (Bulo)", mun_code: "037704" },
  // { label: "Dibaraybay", mun_code: "037704" },
  // { label: "Ditawini", mun_code: "037704" },
  // { label: "Mapalad", mun_code: "037704" },
  // { label: "Paleg", mun_code: "037704" },
  // { label: "Simbahan", mun_code: "037704" },
  // { label: "Aplaya", mun_code: "037705" },
  // { label: "Butas Na Bato", mun_code: "037705" },
  // { label: "Cabog (Matawe)", mun_code: "037705" },
  // { label: "Caragsacan", mun_code: "037705" },
  // { label: "Davildavilan", mun_code: "037705" },
  // { label: "Dikapanikian", mun_code: "037705" },
  // { label: "Ibona", mun_code: "037705" },
  // { label: "Paltic", mun_code: "037705" },
  // { label: "Poblacion", mun_code: "037705" },
  // { label: "Tanawan", mun_code: "037705" },
  // { label: "Umiray (Malamig)", mun_code: "037705" },
  // { label: "Bayabas", mun_code: "037706" },
  // { label: "Buenavista", mun_code: "037706" },
  // { label: "Borlongan", mun_code: "037706" },
  // { label: "Calaocan", mun_code: "037706" },
  // { label: "Dianed", mun_code: "037706" },
  // { label: "Diarabasin", mun_code: "037706" },
  // { label: "Dibutunan", mun_code: "037706" },
  // { label: "Dimabuno", mun_code: "037706" },
  // { label: "Dinadiawan", mun_code: "037706" },
  // { label: "Ditale", mun_code: "037706" },
  // { label: "Gupa", mun_code: "037706" },
  // { label: "Ipil", mun_code: "037706" },
  // { label: "Laboy", mun_code: "037706" },
  // { label: "Lipit", mun_code: "037706" },
  // { label: "Lobbot", mun_code: "037706" },
  // { label: "Maligaya", mun_code: "037706" },
  // { label: "Mijares", mun_code: "037706" },
  // { label: "Mucdol", mun_code: "037706" },
  // { label: "North Poblacion", mun_code: "037706" },
  // { label: "Puangi", mun_code: "037706" },
  // { label: "Salay", mun_code: "037706" },
  // { label: "Sapangkawayan", mun_code: "037706" },
  // { label: "South Poblacion", mun_code: "037706" },
  // { label: "Toytoyan", mun_code: "037706" },
  // { label: "Diamanen", mun_code: "037706" },
  // { label: "Alcala", mun_code: "037707" },
  // { label: "Bagtu", mun_code: "037707" },
  // { label: "Bangco", mun_code: "037707" },
  // { label: "Bannawag", mun_code: "037707" },
  // { label: "Barangay I (Pob.)", mun_code: "037707" },
  // { label: "Barangay II (Pob.)", mun_code: "037707" },
  // { label: "Barangay III (Pob.)", mun_code: "037707" },
  // { label: "Barangay IV (Pob.)", mun_code: "037707" },
  // { label: "Baubo", mun_code: "037707" },
  // { label: "Bayanihan", mun_code: "037707" },
  // { label: "Bazal", mun_code: "037707" },
  // { label: "Cabituculan East", mun_code: "037707" },
  // { label: "Cabituculan West", mun_code: "037707" },
  // { label: "Debucao", mun_code: "037707" },
  // { label: "Decoliat", mun_code: "037707" },
  // { label: "Detailen", mun_code: "037707" },
  // { label: "Diaat", mun_code: "037707" },
  // { label: "Dialatman", mun_code: "037707" },
  // { label: "Diaman", mun_code: "037707" },
  // { label: "Dianawan", mun_code: "037707" },
  // { label: "Dikildit", mun_code: "037707" },
  // { label: "Dimanpudso", mun_code: "037707" },
  // { label: "Diome", mun_code: "037707" },
  // { label: "Estonilo", mun_code: "037707" },
  // { label: "Florida", mun_code: "037707" },
  // { label: "Galintuja", mun_code: "037707" },
  // { label: "Cadayacan", mun_code: "037707" },
  // { label: "Malasin", mun_code: "037707" },
  // { label: "Ponglo", mun_code: "037707" },
  // { label: "Quirino", mun_code: "037707" },
  // { label: "Ramada", mun_code: "037707" },
  // { label: "San Joaquin", mun_code: "037707" },
  // { label: "San Jose", mun_code: "037707" },
  // { label: "San Leonardo", mun_code: "037707" },
  // { label: "Santa Lucia", mun_code: "037707" },
  // { label: "Santo Tomas", mun_code: "037707" },
  // { label: "Suguit", mun_code: "037707" },
  // { label: "Villa Aurora", mun_code: "037707" },
  // { label: "Wenceslao", mun_code: "037707" },
  // { label: "San Juan", mun_code: "037707" },
  // { label: "Bacong", mun_code: "037708" },
  // { label: "Barangay I (Pob.)", mun_code: "037708" },
  // { label: "Barangay II (Pob.)", mun_code: "037708" },
  // { label: "Barangay III (Pob.)", mun_code: "037708" },
  // { label: "Barangay IV (Pob.)", mun_code: "037708" },
  // { label: "Dibalo", mun_code: "037708" },
  // { label: "Dibayabay", mun_code: "037708" },
  // { label: "Dibut", mun_code: "037708" },
  // { label: "Dikapinisan", mun_code: "037708" },
  // { label: "Dimanayat", mun_code: "037708" },
  // { label: "Diteki", mun_code: "037708" },
  // { label: "Ditumabo", mun_code: "037708" },
  // { label: "L. Pimentel", mun_code: "037708" },
  // { label: "Nonong Senior", mun_code: "037708" },
  // { label: "Real", mun_code: "037708" },
  // { label: "San Isidro", mun_code: "037708" },
  // { label: "San Jose", mun_code: "037708" },
  // { label: "Zarah", mun_code: "037708" },
  // { label: "Adia", mun_code: "041001" },
  // { label: "Bagong Sikat", mun_code: "041001" },
  // { label: "Balangon", mun_code: "041001" },
  // { label: "Bilibinwang", mun_code: "041001" },
  // { label: "Bangin", mun_code: "041001" },
  // { label: "Barigon", mun_code: "041001" },
  // { label: "Coral Na Munti", mun_code: "041001" },
  // { label: "Guitna", mun_code: "041001" },
  // { label: "Mabini", mun_code: "041001" },
  // { label: "Pamiga", mun_code: "041001" },
  // { label: "Panhulan", mun_code: "041001" },
  // { label: "Pansipit", mun_code: "041001" },
  // { label: "Poblacion", mun_code: "041001" },
  // { label: "Pook", mun_code: "041001" },
  // { label: "San Jacinto", mun_code: "041001" },
  // { label: "San Teodoro", mun_code: "041001" },
  // { label: "Santa Cruz", mun_code: "041001" },
  // { label: "Santo Tomas", mun_code: "041001" },
  // { label: "Subic Ibaba", mun_code: "041001" },
  // { label: "Subic Ilaya", mun_code: "041001" },
  // { label: "Banyaga", mun_code: "041001" },
  // { label: "Balagbag", mun_code: "041002" },
  // { label: "Concepcion", mun_code: "041002" },
  // { label: "Concordia", mun_code: "041002" },
  // { label: "Dalipit East", mun_code: "041002" },
  // { label: "Dalipit West", mun_code: "041002" },
  // { label: "Dominador East", mun_code: "041002" },
  // { label: "Dominador West", mun_code: "041002" },
  // { label: "Munlawin Sur", mun_code: "041002" },
  // { label: "Munlawin Norte", mun_code: "041002" },
  // { label: "Muzon Primero", mun_code: "041002" },
  // { label: "Muzon Segundo", mun_code: "041002" },
  // { label: "Pinagkurusan", mun_code: "041002" },
  // { label: "Ping-As", mun_code: "041002" },
  // { label: "Poblacion East", mun_code: "041002" },
  // { label: "Poblacion West", mun_code: "041002" },
  // { label: "San Jose", mun_code: "041002" },
  // { label: "Santa Cruz", mun_code: "041002" },
  // { label: "Tadlac", mun_code: "041002" },
  // { label: "San Juan", mun_code: "041002" },
  // { label: "Baclaran", mun_code: "041003" },
  // { label: "Barangay 1 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 10 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 11 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 12 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 2 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 3 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 4 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 5 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 6 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 7 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 8 (Pob.)", mun_code: "041003" },
  // { label: "Barangay 9 (Pob.)", mun_code: "041003" },
  // { label: "Calan", mun_code: "041003" },
  // { label: "Caloocan", mun_code: "041003" },
  // { label: "Calzada", mun_code: "041003" },
  // { label: "Canda", mun_code: "041003" },
  // { label: "Carenahan", mun_code: "041003" },
  // { label: "Caybunga", mun_code: "041003" },
  // { label: "Cayponce", mun_code: "041003" },
  // { label: "Dalig", mun_code: "041003" },
  // { label: "Dao", mun_code: "041003" },
  // { label: "Dilao", mun_code: "041003" },
  // { label: "Duhatan", mun_code: "041003" },
  // { label: "Durungao", mun_code: "041003" },
  // { label: "Gimalas", mun_code: "041003" },
  // { label: "Gumamela", mun_code: "041003" },
  // { label: "Lagnas", mun_code: "041003" },
  // { label: "Lanatan", mun_code: "041003" },
  // { label: "Langgangan", mun_code: "041003" },
  // { label: "Lucban Putol", mun_code: "041003" },
  // { label: "Lucban Pook", mun_code: "041003" },
  // { label: "Magabe", mun_code: "041003" },
  // { label: "Malalay", mun_code: "041003" },
  // { label: "Munting Tubig", mun_code: "041003" },
  // { label: "Navotas", mun_code: "041003" },
  // { label: "Patugo", mun_code: "041003" },
  // { label: "Palikpikan", mun_code: "041003" },
  // { label: "Pooc", mun_code: "041003" },
  // { label: "Sambat", mun_code: "041003" },
  // { label: "Sampaga", mun_code: "041003" },
  // { label: "San Juan", mun_code: "041003" },
  // { label: "San Piro", mun_code: "041003" },
  // { label: "Santol", mun_code: "041003" },
  // { label: "Sukol", mun_code: "041003" },
  // { label: "Tactac", mun_code: "041003" },
  // { label: "Taludtud", mun_code: "041003" },
  // { label: "Tanggoy", mun_code: "041003" },
  // { label: "Alangilan", mun_code: "041004" },
  // { label: "Calawit", mun_code: "041004" },
  // { label: "Looc", mun_code: "041004" },
  // { label: "Magapi", mun_code: "041004" },
  // { label: "Makina", mun_code: "041004" },
  // { label: "Malabanan", mun_code: "041004" },
  // { label: "Paligawan", mun_code: "041004" },
  // { label: "Palsara", mun_code: "041004" },
  // { label: "Poblacion", mun_code: "041004" },
  // { label: "Sala", mun_code: "041004" },
  // { label: "Sampalocan", mun_code: "041004" },
  // { label: "Solis", mun_code: "041004" },
  // { label: "San Sebastian", mun_code: "041004" },
  // { label: "Alangilan", mun_code: "041005" },
  // { label: "Balagtas", mun_code: "041005" },
  // { label: "Balete", mun_code: "041005" },
  // { label: "Banaba Center", mun_code: "041005" },
  // { label: "Banaba Kanluran", mun_code: "041005" },
  // { label: "Banaba Silangan", mun_code: "041005" },
  // { label: "Banaba Ibaba", mun_code: "041005" },
  // { label: "Bilogo", mun_code: "041005" },
  // { label: "Maapas", mun_code: "041005" },
  // { label: "Bolbok", mun_code: "041005" },
  // { label: "Bukal", mun_code: "041005" },
  // { label: "Calicanto", mun_code: "041005" },
  // { label: "Catandala", mun_code: "041005" },
  // { label: "Concepcion", mun_code: "041005" },
  // { label: "Conde Itaas", mun_code: "041005" },
  // { label: "Conde Labak", mun_code: "041005" },
  // { label: "Cuta", mun_code: "041005" },
  // { label: "Dalig", mun_code: "041005" },
  // { label: "Dela Paz", mun_code: "041005" },
  // { label: "Dela Paz Pulot Aplaya", mun_code: "041005" },
  // { label: "Dela Paz Pulot Itaas", mun_code: "041005" },
  // { label: "Domoclay", mun_code: "041005" },
  // { label: "Dumantay", mun_code: "041005" },
  // { label: "Gulod Itaas", mun_code: "041005" },
  // { label: "Gulod Labak", mun_code: "041005" },
  // { label: "Haligue Kanluran", mun_code: "041005" },
  // { label: "Haligue Silangan", mun_code: "041005" },
  // { label: "Ilihan", mun_code: "041005" },
  // { label: "Kumba", mun_code: "041005" },
  // { label: "Kumintang Ibaba", mun_code: "041005" },
  // { label: "Kumintang Ilaya", mun_code: "041005" },
  // { label: "Libjo", mun_code: "041005" },
  // { label: "Liponpon, Isla Verde", mun_code: "041005" },
  // { label: "Mahabang Dahilig", mun_code: "041005" },
  // { label: "Mahabang Parang", mun_code: "041005" },
  // { label: "Mahacot Silangan", mun_code: "041005" },
  // { label: "Mahacot Kanluran", mun_code: "041005" },
  // { label: "Malalim", mun_code: "041005" },
  // { label: "Malibayo", mun_code: "041005" },
  // { label: "Malitam", mun_code: "041005" },
  // { label: "Maruclap", mun_code: "041005" },
  // { label: "Mabacong (Matoco)", mun_code: "041005" },
  // { label: "Pagkilatan", mun_code: "041005" },
  // { label: "Paharang Kanluran", mun_code: "041005" },
  // { label: "Paharang Silangan", mun_code: "041005" },
  // { label: "Pallocan Silangan", mun_code: "041005" },
  // { label: "Pallocan Kanluran", mun_code: "041005" },
  // { label: "Pinamucan", mun_code: "041005" },
  // { label: "Pinamucan Ibaba", mun_code: "041005" },
  // { label: "Pinamucan Silangan", mun_code: "041005" },
  // { label: "Barangay 1 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 10 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 11 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 12 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 13 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 14 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 15 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 16 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 17 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 18 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 19 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 2 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 20 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 21 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 22 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 23 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 24 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 3 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 4 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 5 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 6 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 7 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 8 (Pob.)", mun_code: "041005" },
  // { label: "Barangay 9 (Pob.)", mun_code: "041005" },
  // { label: "Sampaga", mun_code: "041005" },
  // { label: "San Agapito, Isla Verde", mun_code: "041005" },
  // { label: "San Agustin Kanluran, Isla Verde", mun_code: "041005" },
  // { label: "San Agustin Silangan, Isla Verde", mun_code: "041005" },
  // { label: "San Andres, Isla Verde", mun_code: "041005" },
  // { label: "San Antonio, Isla Verde", mun_code: "041005" },
  // { label: "San Isidro", mun_code: "041005" },
  // { label: "San Jose Sico", mun_code: "041005" },
  // { label: "San Miguel", mun_code: "041005" },
  // { label: "San Pedro", mun_code: "041005" },
  // { label: "Santa Clara", mun_code: "041005" },
  // { label: "Santa Rita Aplaya", mun_code: "041005" },
  // { label: "Santa Rita Karsada", mun_code: "041005" },
  // { label: "Santo Domingo", mun_code: "041005" },
  // { label: "Simlong", mun_code: "041005" },
  // { label: "Sirang Lupa", mun_code: "041005" },
  // { label: "Sorosoro Ibaba", mun_code: "041005" },
  // { label: "Sorosoro Ilaya", mun_code: "041005" },
  // { label: "Sorosoro Karsada", mun_code: "041005" },
  // { label: "Tabangao Aplaya (Tabangao Proper)", mun_code: "041005" },
  // { label: "Tabangao Ambulong", mun_code: "041005" },
  // { label: "Tabangao Dao", mun_code: "041005" },
  // { label: "Talahib Pandayan", mun_code: "041005" },
  // { label: "Talahib Payapa", mun_code: "041005" },
  // { label: "Talumpok Kanluran", mun_code: "041005" },
  // { label: "Talumpok Silangan", mun_code: "041005" },
  // { label: "Tinga Itaas", mun_code: "041005" },
  // { label: "Tinga Labak", mun_code: "041005" },
  // { label: "Tulo", mun_code: "041005" },
  // { label: "Wawa", mun_code: "041005" },
  // { label: "Alagao", mun_code: "041006" },
  // { label: "Aplaya", mun_code: "041006" },
  // { label: "As-Is", mun_code: "041006" },
  // { label: "Bagong Silang", mun_code: "041006" },
  // { label: "Baguilawa", mun_code: "041006" },
  // { label: "Balayong", mun_code: "041006" },
  // { label: "Barangay I (Pob.)", mun_code: "041006" },
  // { label: "Barangay II (Pob.)", mun_code: "041006" },
  // { label: "Barangay III (Pob.)", mun_code: "041006" },
  // { label: "Barangay IV (Pob.)", mun_code: "041006" },
  // { label: "Bolo", mun_code: "041006" },
  // { label: "Colvo", mun_code: "041006" },
  // { label: "Cupang", mun_code: "041006" },
  // { label: "Durungao", mun_code: "041006" },
  // { label: "Gulibay", mun_code: "041006" },
  // { label: "Inicbulan", mun_code: "041006" },
  // { label: "Locloc", mun_code: "041006" },
  // { label: "Magalang-Galang", mun_code: "041006" },
  // { label: "Malindig", mun_code: "041006" },
  // { label: "Manalupong", mun_code: "041006" },
  // { label: "Manghinao Proper", mun_code: "041006" },
  // { label: "Manghinao Uno", mun_code: "041006" },
  // { label: "New Danglayan", mun_code: "041006" },
  // { label: "Orense", mun_code: "041006" },
  // { label: "Pitugo", mun_code: "041006" },
  // { label: "Rizal", mun_code: "041006" },
  // { label: "Sampaguita", mun_code: "041006" },
  // { label: "San Agustin", mun_code: "041006" },
  // { label: "San Andres Proper", mun_code: "041006" },
  // { label: "San Andres Uno", mun_code: "041006" },
  // { label: "San Diego", mun_code: "041006" },
  // { label: "San Miguel", mun_code: "041006" },
  // { label: "San Pablo", mun_code: "041006" },
  // { label: "San Pedro", mun_code: "041006" },
  // { label: "San Roque", mun_code: "041006" },
  // { label: "San Teodoro", mun_code: "041006" },
  // { label: "San Vicente", mun_code: "041006" },
  // { label: "Santa Maria", mun_code: "041006" },
  // { label: "Santo Domingo", mun_code: "041006" },
  // { label: "Sinala", mun_code: "041006" },
  // { label: "Bagong Tubig", mun_code: "041007" },
  // { label: "Baclas", mun_code: "041007" },
  // { label: "Balimbing", mun_code: "041007" },
  // { label: "Bambang", mun_code: "041007" },
  // { label: "Barangay 1 (Pob.)", mun_code: "041007" },
  // { label: "Barangay 2 (Pob.)", mun_code: "041007" },
  // { label: "Barangay 3 (Pob.)", mun_code: "041007" },
  // { label: "Barangay 4 (Pob.)", mun_code: "041007" },
  // { label: "Barangay 5 (Pob.)", mun_code: "041007" },
  // { label: "Barangay 6 (Pob.)", mun_code: "041007" },
  // { label: "Bisaya", mun_code: "041007" },
  // { label: "Cahil", mun_code: "041007" },
  // { label: "Caluangan", mun_code: "041007" },
  // { label: "Calantas", mun_code: "041007" },
  // { label: "Camastilisan", mun_code: "041007" },
  // { label: "Coral Ni Lopez (Sugod)", mun_code: "041007" },
  // { label: "Coral Ni Bacal", mun_code: "041007" },
  // { label: "Dacanlao", mun_code: "041007" },
  // { label: "Dila", mun_code: "041007" },
  // { label: "Loma", mun_code: "041007" },
  // { label: "Lumbang Calzada", mun_code: "041007" },
  // { label: "Lumbang Na Bata", mun_code: "041007" },
  // { label: "Lumbang Na Matanda", mun_code: "041007" },
  // { label: "Madalunot", mun_code: "041007" },
  // { label: "Makina", mun_code: "041007" },
  // { label: "Matipok", mun_code: "041007" },
  // { label: "Munting Coral", mun_code: "041007" },
  // { label: "Niyugan", mun_code: "041007" },
  // { label: "Pantay", mun_code: "041007" },
  // { label: "Puting Bato West", mun_code: "041007" },
  // { label: "Puting Kahoy", mun_code: "041007" },
  // { label: "Puting Bato East", mun_code: "041007" },
  // { label: "Quisumbing", mun_code: "041007" },
  // { label: "Salong", mun_code: "041007" },
  // { label: "San Rafael", mun_code: "041007" },
  // { label: "Sinisian", mun_code: "041007" },
  // { label: "Taklang Anak", mun_code: "041007" },
  // { label: "Talisay", mun_code: "041007" },
  // { label: "Tamayo", mun_code: "041007" },
  // { label: "Timbain", mun_code: "041007" },
  // { label: "Bagong Silang", mun_code: "041008" },
  // { label: "Baha", mun_code: "041008" },
  // { label: "Balibago", mun_code: "041008" },
  // { label: "Balitoc", mun_code: "041008" },
  // { label: "Biga", mun_code: "041008" },
  // { label: "Bucal", mun_code: "041008" },
  // { label: "Carlosa", mun_code: "041008" },
  // { label: "Carretunan", mun_code: "041008" },
  // { label: "Encarnacion", mun_code: "041008" },
  // { label: "Gulod", mun_code: "041008" },
  // { label: "Hukay", mun_code: "041008" },
  // { label: "Lucsuhin", mun_code: "041008" },
  // { label: "Luya", mun_code: "041008" },
  // { label: "Paraiso", mun_code: "041008" },
  // { label: "Barangay 1 (Pob.)", mun_code: "041008" },
  // { label: "Barangay 2 (Pob.)", mun_code: "041008" },
  // { label: "Barangay 3 (Pob.)", mun_code: "041008" },
  // { label: "Barangay 4 (Pob.)", mun_code: "041008" },
  // { label: "Quilitisan", mun_code: "041008" },
  // { label: "Real", mun_code: "041008" },
  // { label: "Sambungan", mun_code: "041008" },
  // { label: "Santa Ana", mun_code: "041008" },
  // { label: "Talibayog", mun_code: "041008" },
  // { label: "Talisay", mun_code: "041008" },
  // { label: "Tanagan", mun_code: "041008" },
  // { label: "Balagbag", mun_code: "041009" },
  // { label: "Bungahan", mun_code: "041009" },
  // { label: "Calumayin", mun_code: "041009" },
  // { label: "Dalipit East", mun_code: "041009" },
  // { label: "Dalipit West", mun_code: "041009" },
  // { label: "Dita", mun_code: "041009" },
  // { label: "Don Juan", mun_code: "041009" },
  // { label: "Emmanuel", mun_code: "041009" },
  // { label: "Ibabao", mun_code: "041009" },
  // { label: "Labac", mun_code: "041009" },
  // { label: "Pinagkaisahan", mun_code: "041009" },
  // { label: "San Felipe", mun_code: "041009" },
  // { label: "San Isidro", mun_code: "041009" },
  // { label: "Barangay 1 (Pob.)", mun_code: "041009" },
  // { label: "Barangay 2 (Pob.)", mun_code: "041009" },
  // { label: "Barangay 3 (Pob.)", mun_code: "041009" },
  // { label: "Barangay 4 (Pob.)", mun_code: "041009" },
  // { label: "Barangay 5 (Pob.)", mun_code: "041009" },
  // { label: "Barangay 6 (Pob.)", mun_code: "041009" },
  // { label: "Barangay 7 (Pob.)", mun_code: "041009" },
  // { label: "Barangay 8 (Pob.)", mun_code: "041009" },
  // { label: "Bago", mun_code: "041010" },
  // { label: "Balanga", mun_code: "041010" },
  // { label: "Bungahan", mun_code: "041010" },
  // { label: "Calamias", mun_code: "041010" },
  // { label: "Catandala", mun_code: "041010" },
  // { label: "Coliat", mun_code: "041010" },
  // { label: "Dayapan", mun_code: "041010" },
  // { label: "Lapu-lapu", mun_code: "041010" },
  // { label: "Lucsuhin", mun_code: "041010" },
  // { label: "Mabalor", mun_code: "041010" },
  // { label: "Malainin", mun_code: "041010" },
  // { label: "Matala", mun_code: "041010" },
  // { label: "Munting-Tubig", mun_code: "041010" },
  // { label: "Palindan", mun_code: "041010" },
  // { label: "Pangao", mun_code: "041010" },
  // { label: "Panghayaan", mun_code: "041010" },
  // { label: "Poblacion", mun_code: "041010" },
  // { label: "Quilo", mun_code: "041010" },
  // { label: "Sabang", mun_code: "041010" },
  // { label: "Salaban I", mun_code: "041010" },
  // { label: "San Agustin", mun_code: "041010" },
  // { label: "Sandalan", mun_code: "041010" },
  // { label: "Talaibon", mun_code: "041010" },
  // { label: "Tulay Na Patpat", mun_code: "041010" },
  // { label: "Salaban II", mun_code: "041010" },
  // { label: "As-Is", mun_code: "041011" },
  // { label: "Balakilong", mun_code: "041011" },
  // { label: "Berinayan", mun_code: "041011" },
  // { label: "Bugaan East", mun_code: "041011" },
  // { label: "Bugaan West", mun_code: "041011" },
  // { label: "Buso-buso", mun_code: "041011" },
  // { label: "Dayap Itaas", mun_code: "041011" },
  // { label: "Gulod", mun_code: "041011" },
  // { label: "J. Leviste", mun_code: "041011" },
  // { label: "Molinete", mun_code: "041011" },
  // { label: "Niyugan", mun_code: "041011" },
  // { label: "Paliparan", mun_code: "041011" },
  // { label: "Barangay 1 (Pob.)", mun_code: "041011" },
  // { label: "Barangay 2 (Pob.)", mun_code: "041011" },
  // { label: "Barangay 3 (Pob.)", mun_code: "041011" },
  // { label: "Barangay 4 (Pob.)", mun_code: "041011" },
  // { label: "Barangay 5 (Pob.)", mun_code: "041011" },
  // { label: "San Gabriel", mun_code: "041011" },
  // { label: "San Gregorio", mun_code: "041011" },
  // { label: "Santa Maria", mun_code: "041011" },
  // { label: "Ticub", mun_code: "041011" },
  // { label: "Anak-Dagat", mun_code: "041012" },
  // { label: "Arumahan", mun_code: "041012" },
  // { label: "Ayao-iyao", mun_code: "041012" },
  // { label: "Bagong Pook", mun_code: "041012" },
  // { label: "Bagong Sikat", mun_code: "041012" },
  // { label: "Balanga", mun_code: "041012" },
  // { label: "Bukal", mun_code: "041012" },
  // { label: "Cahilan I", mun_code: "041012" },
  // { label: "Cahilan II", mun_code: "041012" },
  // { label: "Dayapan", mun_code: "041012" },
  // { label: "Dita", mun_code: "041012" },
  // { label: "Gulod", mun_code: "041012" },
  // { label: "Lucky", mun_code: "041012" },
  // { label: "Maguihan", mun_code: "041012" },
  // { label: "Mahabang Dahilig", mun_code: "041012" },
  // { label: "Mahayahay", mun_code: "041012" },
  // { label: "Maigsing Dahilig", mun_code: "041012" },
  // { label: "Maligaya", mun_code: "041012" },
  // { label: "Malinis", mun_code: "041012" },
  // { label: "Masalisi", mun_code: "041012" },
  // { label: "Mataas Na Bayan", mun_code: "041012" },
  // { label: "Matingain I", mun_code: "041012" },
  // { label: "Matingain II", mun_code: "041012" },
  // { label: "Mayasang", mun_code: "041012" },
  // { label: "Niugan", mun_code: "041012" },
  // { label: "Nonong Casto", mun_code: "041012" },
  // { label: "Palanas", mun_code: "041012" },
  // { label: "Payapa Ibaba", mun_code: "041012" },
  // { label: "Payapa Ilaya", mun_code: "041012" },
  // { label: "District I (Pob.)", mun_code: "041012" },
  // { label: "District II (Pob.)", mun_code: "041012" },
  // { label: "District III (Pob.)", mun_code: "041012" },
  // { label: "District IV (Pob.)", mun_code: "041012" },
  // { label: "Rizal", mun_code: "041012" },
  // { label: "Sambal Ibaba", mun_code: "041012" },
  // { label: "Sambal Ilaya", mun_code: "041012" },
  // { label: "San Isidro Ibaba", mun_code: "041012" },
  // { label: "San Isidro Itaas", mun_code: "041012" },
  // { label: "Sangalang", mun_code: "041012" },
  // { label: "Talaga", mun_code: "041012" },
  // { label: "Tubigan", mun_code: "041012" },
  // { label: "Tubuan", mun_code: "041012" },
  // { label: "Wawa Ibaba", mun_code: "041012" },
  // { label: "Wawa Ilaya", mun_code: "041012" },
  // { label: "Sinisian East", mun_code: "041012" },
  // { label: "Sinisian West", mun_code: "041012" },
  // { label: "Bagong Pook", mun_code: "041013" },
  // { label: "Balibago", mun_code: "041013" },
  // { label: "Binubusan", mun_code: "041013" },
  // { label: "Bungahan", mun_code: "041013" },
  // { label: "Cumba", mun_code: "041013" },
  // { label: "Humayingan", mun_code: "041013" },
  // { label: "Kapito", mun_code: "041013" },
  // { label: "Lumaniag", mun_code: "041013" },
  // { label: "Luyahan", mun_code: "041013" },
  // { label: "Malaruhatan", mun_code: "041013" },
  // { label: "Matabungkay", mun_code: "041013" },
  // { label: "Barangay 1 (Pob.)", mun_code: "041013" },
  // { label: "Barangay 2 (Pob.)", mun_code: "041013" },
  // { label: "Barangay 3 (Pob.)", mun_code: "041013" },
  // { label: "Barangay 4 (Pob.)", mun_code: "041013" },
  // { label: "Barangay 5 (Pob.)", mun_code: "041013" },
  // { label: "Prenza", mun_code: "041013" },
  // { label: "Puting-Kahoy", mun_code: "041013" },
  // { label: "San Diego", mun_code: "041013" },
  // { label: "Adya", mun_code: "041014" },
  // { label: "Anilao", mun_code: "041014" },
  // { label: "Anilao-Labac", mun_code: "041014" },
  // { label: "Antipolo Del Norte", mun_code: "041014" },
  // { label: "Antipolo Del Sur", mun_code: "041014" },
  // { label: "Bagong Pook", mun_code: "041014" },
  // { label: "San Sebastian (Balagbag)", mun_code: "041014" },
  // { label: "Balintawak", mun_code: "041014" },
  // { label: "Banaybanay", mun_code: "041014" },
  // { label: "Bolbok", mun_code: "041014" },
  // { label: "Bugtong na Pulo", mun_code: "041014" },
  // { label: "Bulacnin", mun_code: "041014" },
  // { label: "Bulaklakan", mun_code: "041014" },
  // { label: "Calamias", mun_code: "041014" },
  // { label: "Cumba", mun_code: "041014" },
  // { label: "Dagatan", mun_code: "041014" },
  // { label: "Duhatan", mun_code: "041014" },
  // { label: "Halang", mun_code: "041014" },
  // { label: "Inosloban", mun_code: "041014" },
  // { label: "Kayumanggi", mun_code: "041014" },
  // { label: "Latag", mun_code: "041014" },
  // { label: "Lodlod", mun_code: "041014" },
  // { label: "Lumbang", mun_code: "041014" },
  // { label: "Mabini", mun_code: "041014" },
  // { label: "Malagonlong", mun_code: "041014" },
  // { label: "Malitlit", mun_code: "041014" },
  // { label: "Marauoy", mun_code: "041014" },
  // { label: "Mataas Na Lupa", mun_code: "041014" },
  // { label: "Munting Pulo", mun_code: "041014" },
  // { label: "Pagolingin Bata", mun_code: "041014" },
  // { label: "Pagolingin East", mun_code: "041014" },
  // { label: "Pagolingin West", mun_code: "041014" },
  // { label: "Pangao", mun_code: "041014" },
  // { label: "Pinagkawitan", mun_code: "041014" },
  // { label: "Pinagtongulan", mun_code: "041014" },
  // { label: "Plaridel", mun_code: "041014" },
  // { label: "Poblacion Barangay 1", mun_code: "041014" },
  // { label: "Poblacion Barangay 10", mun_code: "041014" },
  // { label: "Poblacion Barangay 11", mun_code: "041014" },
  // { label: "Poblacion Barangay 2", mun_code: "041014" },
  // { label: "Poblacion Barangay 3", mun_code: "041014" },
  // { label: "Poblacion Barangay 4", mun_code: "041014" },
  // { label: "Poblacion Barangay 5", mun_code: "041014" },
  // { label: "Poblacion Barangay 6", mun_code: "041014" },
  // { label: "Poblacion Barangay 7", mun_code: "041014" },
  // { label: "Poblacion Barangay 8", mun_code: "041014" },
  // { label: "Poblacion Barangay 9", mun_code: "041014" },
  // { label: "Pusil", mun_code: "041014" },
  // { label: "Quezon", mun_code: "041014" },
  // { label: "Rizal", mun_code: "041014" },
  // { label: "Sabang", mun_code: "041014" },
  // { label: "Sampaguita", mun_code: "041014" },
  // { label: "San Benito", mun_code: "041014" },
  // { label: "San Carlos", mun_code: "041014" },
  // { label: "San Celestino", mun_code: "041014" },
  // { label: "San Francisco", mun_code: "041014" },
  // { label: "San Guillermo", mun_code: "041014" },
  // { label: "San Jose", mun_code: "041014" },
  // { label: "San Lucas", mun_code: "041014" },
  // { label: "San Salvador", mun_code: "041014" },
  // { label: "Sapac", mun_code: "041014" },
  // { label: "Sico", mun_code: "041014" },
  // { label: "Santo Toribio", mun_code: "041014" },
  // { label: "Talisay", mun_code: "041014" },
  // { label: "Tambo", mun_code: "041014" },
  // { label: "Tangob", mun_code: "041014" },
  // { label: "Tanguay", mun_code: "041014" },
  // { label: "Tibig", mun_code: "041014" },
  // { label: "Tipacan", mun_code: "041014" },
  // { label: "Poblacion Barangay 9-A", mun_code: "041014" },
  // { label: "Barangay 12 (Pob.)", mun_code: "041014" },
  // { label: "Apar", mun_code: "041015" },
  // { label: "Balatbat", mun_code: "041015" },
  // { label: "Balibago", mun_code: "041015" },
  // { label: "Banalo", mun_code: "041015" },
  // { label: "Biga", mun_code: "041015" },
  // { label: "Bignay", mun_code: "041015" },
  // { label: "Calo", mun_code: "041015" },
  // { label: "Calumpit", mun_code: "041015" },
  // { label: "Fabrica", mun_code: "041015" },
  // { label: "Jaybanga", mun_code: "041015" },
  // { label: "Lagadlarin", mun_code: "041015" },
  // { label: "Mabilog Na Bundok", mun_code: "041015" },
  // { label: "Malabrigo", mun_code: "041015" },
  // { label: "Malalim Na Sanog", mun_code: "041015" },
  // { label: "Malapad Na Parang", mun_code: "041015" },
  // { label: "Masaguitsit", mun_code: "041015" },
  // { label: "Nagtalongtong", mun_code: "041015" },
  // { label: "Nagtoctoc", mun_code: "041015" },
  // { label: "Olo-olo", mun_code: "041015" },
  // { label: "Pinaghawanan", mun_code: "041015" },
  // { label: "San Miguel", mun_code: "041015" },
  // { label: "San Nicolas", mun_code: "041015" },
  // { label: "Sawang", mun_code: "041015" },
  // { label: "Soloc", mun_code: "041015" },
  // { label: "Tayuman", mun_code: "041015" },
  // { label: "Poblacion", mun_code: "041015" },
  // { label: "Anilao Proper", mun_code: "041016" },
  // { label: "Anilao East", mun_code: "041016" },
  // { label: "Bagalangit", mun_code: "041016" },
  // { label: "Bulacan", mun_code: "041016" },
  // { label: "Calamias", mun_code: "041016" },
  // { label: "Estrella", mun_code: "041016" },
  // { label: "Gasang", mun_code: "041016" },
  // { label: "Laurel", mun_code: "041016" },
  // { label: "Ligaya", mun_code: "041016" },
  // { label: "Mainaga", mun_code: "041016" },
  // { label: "Mainit", mun_code: "041016" },
  // { label: "Majuben", mun_code: "041016" },
  // { label: "Malimatoc I", mun_code: "041016" },
  // { label: "Malimatoc II", mun_code: "041016" },
  // { label: "Nag-Iba", mun_code: "041016" },
  // { label: "Pilahan", mun_code: "041016" },
  // { label: "Poblacion", mun_code: "041016" },
  // { label: "Pulang Lupa", mun_code: "041016" },
  // { label: "Pulong Anahao", mun_code: "041016" },
  // { label: "Pulong Balibaguhan", mun_code: "041016" },
  // { label: "Pulong Niogan", mun_code: "041016" },
  // { label: "Saguing", mun_code: "041016" },
  // { label: "Sampaguita", mun_code: "041016" },
  // { label: "San Francisco", mun_code: "041016" },
  // { label: "San Jose", mun_code: "041016" },
  // { label: "San Juan", mun_code: "041016" },
  // { label: "San Teodoro", mun_code: "041016" },
  // { label: "Santa Ana", mun_code: "041016" },
  // { label: "Santa Mesa", mun_code: "041016" },
  // { label: "Santo Tomas", mun_code: "041016" },
  // { label: "Solo", mun_code: "041016" },
  // { label: "Talaga Proper", mun_code: "041016" },
  // { label: "Talaga East", mun_code: "041016" },
  // { label: "Bagong Pook", mun_code: "041017" },
  // { label: "Bilucao (San Isidro Western)", mun_code: "041017" },
  // { label: "Bulihan", mun_code: "041017" },
  // { label: "San Gregorio", mun_code: "041017" },
  // { label: "Luta Del Norte", mun_code: "041017" },
  // { label: "Luta Del Sur", mun_code: "041017" },
  // { label: "Poblacion", mun_code: "041017" },
  // { label: "San Andres", mun_code: "041017" },
  // { label: "San Fernando", mun_code: "041017" },
  // { label: "San Isidro East", mun_code: "041017" },
  // { label: "San Juan", mun_code: "041017" },
  // { label: "San Pedro II (Western)", mun_code: "041017" },
  // { label: "San Pedro I (Eastern)", mun_code: "041017" },
  // { label: "San Pioquinto", mun_code: "041017" },
  // { label: "Santiago", mun_code: "041017" },
  // { label: "District I (Pob.)", mun_code: "041018" },
  // { label: "District II (Pob.)", mun_code: "041018" },
  // { label: "District III (Pob.)", mun_code: "041018" },
  // { label: "District IV (Pob.)", mun_code: "041018" },
  // { label: "Bayorbor", mun_code: "041018" },
  // { label: "Bubuyan", mun_code: "041018" },
  // { label: "Calingatan", mun_code: "041018" },
  // { label: "Kinalaglagan", mun_code: "041018" },
  // { label: "Loob", mun_code: "041018" },
  // { label: "Lumang Lipa", mun_code: "041018" },
  // { label: "Manggahan", mun_code: "041018" },
  // { label: "Nangkaan", mun_code: "041018" },
  // { label: "San Sebastian", mun_code: "041018" },
  // { label: "Santol", mun_code: "041018" },
  // { label: "Upa", mun_code: "041018" },
  // { label: "Barangay II-A (Pob.)", mun_code: "041018" },
  // { label: "Aga", mun_code: "041019" },
  // { label: "Balaytigui", mun_code: "041019" },
  // { label: "Banilad", mun_code: "041019" },
  // { label: "Barangay 10 (Pob.)", mun_code: "041019" },
  // { label: "Barangay 11 (Pob.)", mun_code: "041019" },
  // { label: "Barangay 12 (Pob.)", mun_code: "041019" },
  // { label: "Barangay 2 (Pob.)", mun_code: "041019" },
  // { label: "Barangay 3 (Pob.)", mun_code: "041019" },
  // { label: "Barangay 4 (Pob.)", mun_code: "041019" },
  // { label: "Barangay 5 (Pob.)", mun_code: "041019" },
  // { label: "Barangay 6 (Pob.)", mun_code: "041019" },
  // { label: "Barangay 7 (Pob.)", mun_code: "041019" },
  // { label: "Barangay 8 (Pob.)", mun_code: "041019" },
  // { label: "Barangay 9 (Pob.)", mun_code: "041019" },
  // { label: "Bilaran", mun_code: "041019" },
  // { label: "Bucana", mun_code: "041019" },
  // { label: "Bulihan", mun_code: "041019" },
  // { label: "Bunducan", mun_code: "041019" },
  // { label: "Butucan", mun_code: "041019" },
  // { label: "Calayo", mun_code: "041019" },
  // { label: "Catandaan", mun_code: "041019" },
  // { label: "Kaylaway", mun_code: "041019" },
  // { label: "Kayrilaw", mun_code: "041019" },
  // { label: "Cogunan", mun_code: "041019" },
  // { label: "Dayap", mun_code: "041019" },
  // { label: "Latag", mun_code: "041019" },
  // { label: "Looc", mun_code: "041019" },
  // { label: "Lumbangan", mun_code: "041019" },
  // { label: "Malapad Na Bato", mun_code: "041019" },
  // { label: "Mataas Na Pulo", mun_code: "041019" },
  // { label: "Maugat", mun_code: "041019" },
  // { label: "Munting Indan", mun_code: "041019" },
  // { label: "Natipuan", mun_code: "041019" },
  // { label: "Pantalan", mun_code: "041019" },
  // { label: "Papaya", mun_code: "041019" },
  // { label: "Putat", mun_code: "041019" },
  // { label: "Reparo", mun_code: "041019" },
  // { label: "Talangan", mun_code: "041019" },
  // { label: "Tumalim", mun_code: "041019" },
  // { label: "Utod", mun_code: "041019" },
  // { label: "Wawa", mun_code: "041019" },
  // { label: "Barangay 1 (Pob.)", mun_code: "041019" },
  // { label: "Banaba", mun_code: "041020" },
  // { label: "Banaybanay", mun_code: "041020" },
  // { label: "Bawi", mun_code: "041020" },
  // { label: "Bukal", mun_code: "041020" },
  // { label: "Castillo", mun_code: "041020" },
  // { label: "Cawongan", mun_code: "041020" },
  // { label: "Manggas", mun_code: "041020" },
  // { label: "Maugat East", mun_code: "041020" },
  // { label: "Maugat West", mun_code: "041020" },
  // { label: "Pansol", mun_code: "041020" },
  // { label: "Payapa", mun_code: "041020" },
  // { label: "Poblacion", mun_code: "041020" },
  // { label: "Quilo-quilo North", mun_code: "041020" },
  // { label: "Quilo-quilo South", mun_code: "041020" },
  // { label: "San Felipe", mun_code: "041020" },
  // { label: "San Miguel", mun_code: "041020" },
  // { label: "Tamak", mun_code: "041020" },
  // { label: "Tangob", mun_code: "041020" },
  // { label: "Alupay", mun_code: "041021" },
  // { label: "Antipolo", mun_code: "041021" },
  // { label: "Bagong Pook", mun_code: "041021" },
  // { label: "Balibago", mun_code: "041021" },
  // { label: "Bayawang", mun_code: "041021" },
  // { label: "Baybayin", mun_code: "041021" },
  // { label: "Bulihan", mun_code: "041021" },
  // { label: "Cahigam", mun_code: "041021" },
  // { label: "Calantas", mun_code: "041021" },
  // { label: "Colongan", mun_code: "041021" },
  // { label: "Itlugan", mun_code: "041021" },
  // { label: "Lumbangan", mun_code: "041021" },
  // { label: "Maalas-As", mun_code: "041021" },
  // { label: "Mabato", mun_code: "041021" },
  // { label: "Mabunga", mun_code: "041021" },
  // { label: "Macalamcam A", mun_code: "041021" },
  // { label: "Macalamcam B", mun_code: "041021" },
  // { label: "Malaya", mun_code: "041021" },
  // { label: "Maligaya", mun_code: "041021" },
  // { label: "Marilag", mun_code: "041021" },
  // { label: "Masaya", mun_code: "041021" },
  // { label: "Matamis", mun_code: "041021" },
  // { label: "Mavalor", mun_code: "041021" },
  // { label: "Mayuro", mun_code: "041021" },
  // { label: "Namuco", mun_code: "041021" },
  // { label: "Namunga", mun_code: "041021" },
  // { label: "Natu", mun_code: "041021" },
  // { label: "Nasi", mun_code: "041021" },
  // { label: "Palakpak", mun_code: "041021" },
  // { label: "Pinagsibaan", mun_code: "041021" },
  // { label: "Barangay A (Pob.)", mun_code: "041021" },
  // { label: "Barangay B (Pob.)", mun_code: "041021" },
  // { label: "Barangay C (Pob.)", mun_code: "041021" },
  // { label: "Barangay D (Pob.)", mun_code: "041021" },
  // { label: "Barangay E (Pob.)", mun_code: "041021" },
  // { label: "Putingkahoy", mun_code: "041021" },
  // { label: "Quilib", mun_code: "041021" },
  // { label: "Salao", mun_code: "041021" },
  // { label: "San Carlos", mun_code: "041021" },
  // { label: "San Ignacio", mun_code: "041021" },
  // { label: "San Isidro", mun_code: "041021" },
  // { label: "San Jose", mun_code: "041021" },
  // { label: "San Roque", mun_code: "041021" },
  // { label: "Santa Cruz", mun_code: "041021" },
  // { label: "Timbugan", mun_code: "041021" },
  // { label: "Tiquiwan", mun_code: "041021" },
  // { label: "Leviste (Tubahan)", mun_code: "041021" },
  // { label: "Tulos", mun_code: "041021" },
  // { label: "Aguila", mun_code: "041022" },
  // { label: "Anus", mun_code: "041022" },
  // { label: "Aya", mun_code: "041022" },
  // { label: "Bagong Pook", mun_code: "041022" },
  // { label: "Balagtasin", mun_code: "041022" },
  // { label: "Balagtasin I", mun_code: "041022" },
  // { label: "Banaybanay I", mun_code: "041022" },
  // { label: "Banaybanay II", mun_code: "041022" },
  // { label: "Bigain I", mun_code: "041022" },
  // { label: "Bigain II", mun_code: "041022" },
  // { label: "Calansayan", mun_code: "041022" },
  // { label: "Dagatan", mun_code: "041022" },
  // { label: "Don Luis", mun_code: "041022" },
  // { label: "Galamay-Amo", mun_code: "041022" },
  // { label: "Lalayat", mun_code: "041022" },
  // { label: "Lapolapo I", mun_code: "041022" },
  // { label: "Lapolapo II", mun_code: "041022" },
  // { label: "Lepute", mun_code: "041022" },
  // { label: "Lumil", mun_code: "041022" },
  // { label: "Natunuan", mun_code: "041022" },
  // { label: "Palanca", mun_code: "041022" },
  // { label: "Pinagtung-Ulan", mun_code: "041022" },
  // { label: "Poblacion Barangay I", mun_code: "041022" },
  // { label: "Poblacion Barangay II", mun_code: "041022" },
  // { label: "Poblacion Barangay III", mun_code: "041022" },
  // { label: "Poblacion Barangay IV", mun_code: "041022" },
  // { label: "Sabang", mun_code: "041022" },
  // { label: "Salaban", mun_code: "041022" },
  // { label: "Santo Cristo", mun_code: "041022" },
  // { label: "Mojon-Tampoy", mun_code: "041022" },
  // { label: "Taysan", mun_code: "041022" },
  // { label: "Tugtug", mun_code: "041022" },
  // { label: "Bigain South", mun_code: "041022" },
  // { label: "Abung", mun_code: "041023" },
  // { label: "Balagbag", mun_code: "041023" },
  // { label: "Barualte", mun_code: "041023" },
  // { label: "Bataan", mun_code: "041023" },
  // { label: "Buhay Na Sapa", mun_code: "041023" },
  // { label: "Bulsa", mun_code: "041023" },
  // { label: "Calicanto", mun_code: "041023" },
  // { label: "Calitcalit", mun_code: "041023" },
  // { label: "Calubcub I", mun_code: "041023" },
  // { label: "Calubcub II", mun_code: "041023" },
  // { label: "Catmon", mun_code: "041023" },
  // { label: "Coloconto", mun_code: "041023" },
  // { label: "Escribano", mun_code: "041023" },
  // { label: "Hugom", mun_code: "041023" },
  // { label: "Imelda (Tubog)", mun_code: "041023" },
  // { label: "Janaojanao", mun_code: "041023" },
  // { label: "Laiya-Ibabao", mun_code: "041023" },
  // { label: "Laiya-Aplaya", mun_code: "041023" },
  // { label: "Libato", mun_code: "041023" },
  // { label: "Lipahan", mun_code: "041023" },
  // { label: "Mabalanoy", mun_code: "041023" },
  // { label: "Nagsaulay", mun_code: "041023" },
  // { label: "Maraykit", mun_code: "041023" },
  // { label: "Muzon", mun_code: "041023" },
  // { label: "Palahanan I", mun_code: "041023" },
  // { label: "Palahanan II", mun_code: "041023" },
  // { label: "Palingowak", mun_code: "041023" },
  // { label: "Pinagbayanan", mun_code: "041023" },
  // { label: "Poblacion", mun_code: "041023" },
  // { label: "Poctol", mun_code: "041023" },
  // { label: "Pulangbato", mun_code: "041023" },
  // { label: "Putingbuhangin", mun_code: "041023" },
  // { label: "Quipot", mun_code: "041023" },
  // { label: "Sampiro", mun_code: "041023" },
  // { label: "Sapangan", mun_code: "041023" },
  // { label: "Sico I", mun_code: "041023" },
  // { label: "Sico II", mun_code: "041023" },
  // { label: "Subukin", mun_code: "041023" },
  // { label: "Talahiban I", mun_code: "041023" },
  // { label: "Talahiban II", mun_code: "041023" },
  // { label: "Ticalan", mun_code: "041023" },
  // { label: "Tipaz", mun_code: "041023" },
  // { label: "Abiacao", mun_code: "041024" },
  // { label: "Bagong Tubig", mun_code: "041024" },
  // { label: "Balagtasin", mun_code: "041024" },
  // { label: "Balite", mun_code: "041024" },
  // { label: "Banoyo", mun_code: "041024" },
  // { label: "Boboy", mun_code: "041024" },
  // { label: "Bonliw", mun_code: "041024" },
  // { label: "Calumpang West", mun_code: "041024" },
  // { label: "Calumpang East", mun_code: "041024" },
  // { label: "Dulangan", mun_code: "041024" },
  // { label: "Durungao", mun_code: "041024" },
  // { label: "Locloc", mun_code: "041024" },
  // { label: "Luya", mun_code: "041024" },
  // { label: "Mahabang Parang", mun_code: "041024" },
  // { label: "Manggahan", mun_code: "041024" },
  // { label: "Muzon", mun_code: "041024" },
  // { label: "San Antonio", mun_code: "041024" },
  // { label: "San Isidro", mun_code: "041024" },
  // { label: "San Jose", mun_code: "041024" },
  // { label: "San Martin", mun_code: "041024" },
  // { label: "Santa Monica", mun_code: "041024" },
  // { label: "Taliba", mun_code: "041024" },
  // { label: "Talon", mun_code: "041024" },
  // { label: "Tejero", mun_code: "041024" },
  // { label: "Tungal", mun_code: "041024" },
  // { label: "Poblacion", mun_code: "041024" },
  // { label: "Abelo", mun_code: "041025" },
  // { label: "Balete", mun_code: "041025" },
  // { label: "Baluk-baluk", mun_code: "041025" },
  // { label: "Bancoro", mun_code: "041025" },
  // { label: "Bangin", mun_code: "041025" },
  // { label: "Calangay", mun_code: "041025" },
  // { label: "Hipit", mun_code: "041025" },
  // { label: "Maabud North", mun_code: "041025" },
  // { label: "Maabud South", mun_code: "041025" },
  // { label: "Munlawin", mun_code: "041025" },
  // { label: "Pansipit", mun_code: "041025" },
  // { label: "Poblacion", mun_code: "041025" },
  // { label: "Sinturisan", mun_code: "041025" },
  // { label: "Tagudtod", mun_code: "041025" },
  // { label: "Talang", mun_code: "041025" },
  // { label: "Alas-as", mun_code: "041025" },
  // { label: "Pulang-Bato", mun_code: "041025" },
  // { label: "Alalum", mun_code: "041026" },
  // { label: "Antipolo", mun_code: "041026" },
  // { label: "Balimbing", mun_code: "041026" },
  // { label: "Banaba", mun_code: "041026" },
  // { label: "Bayanan", mun_code: "041026" },
  // { label: "Danglayan", mun_code: "041026" },
  // { label: "Del Pilar", mun_code: "041026" },
  // { label: "Gelerang Kawayan", mun_code: "041026" },
  // { label: "Ilat North", mun_code: "041026" },
  // { label: "Ilat South", mun_code: "041026" },
  // { label: "Kaingin", mun_code: "041026" },
  // { label: "Laurel", mun_code: "041026" },
  // { label: "Malaking Pook", mun_code: "041026" },
  // { label: "Mataas Na Lupa", mun_code: "041026" },
  // { label: "Natunuan North", mun_code: "041026" },
  // { label: "Natunuan South", mun_code: "041026" },
  // { label: "Padre Castillo", mun_code: "041026" },
  // { label: "Palsahingin", mun_code: "041026" },
  // { label: "Pila", mun_code: "041026" },
  // { label: "Poblacion", mun_code: "041026" },
  // { label: "Pook Ni Banal", mun_code: "041026" },
  // { label: "Pook Ni Kapitan", mun_code: "041026" },
  // { label: "Resplandor", mun_code: "041026" },
  // { label: "Sambat", mun_code: "041026" },
  // { label: "San Antonio", mun_code: "041026" },
  // { label: "San Mariano", mun_code: "041026" },
  // { label: "San Mateo", mun_code: "041026" },
  // { label: "Santa Elena", mun_code: "041026" },
  // { label: "Antipolo", mun_code: "041027" },
  // { label: "Bihis", mun_code: "041027" },
  // { label: "Burol", mun_code: "041027" },
  // { label: "Calayaan", mun_code: "041027" },
  // { label: "Calumala", mun_code: "041027" },
  // { label: "Cuta East", mun_code: "041027" },
  // { label: "Cutang Cawayan", mun_code: "041027" },
  // { label: "Irukan", mun_code: "041027" },
  // { label: "Pacifico", mun_code: "041027" },
  // { label: "Poblacion I", mun_code: "041027" },
  // { label: "Saimsim", mun_code: "041027" },
  // { label: "Sampa", mun_code: "041027" },
  // { label: "Sinipian", mun_code: "041027" },
  // { label: "Tambo Ibaba", mun_code: "041027" },
  // { label: "Tambo Ilaya", mun_code: "041027" },
  // { label: "Poblacion II", mun_code: "041027" },
  // { label: "Poblacion III", mun_code: "041027" },
  // { label: "Barangay I (Pob.)", mun_code: "041028" },
  // { label: "Barangay II (Pob.)", mun_code: "041028" },
  // { label: "Barangay III (Pob.)", mun_code: "041028" },
  // { label: "Barangay IV (Pob.)", mun_code: "041028" },
  // { label: "San Agustin", mun_code: "041028" },
  // { label: "San Antonio", mun_code: "041028" },
  // { label: "San Bartolome", mun_code: "041028" },
  // { label: "San Felix", mun_code: "041028" },
  // { label: "San Fernando", mun_code: "041028" },
  // { label: "San Francisco", mun_code: "041028" },
  // { label: "San Isidro Norte", mun_code: "041028" },
  // { label: "San Isidro Sur", mun_code: "041028" },
  // { label: "San Joaquin", mun_code: "041028" },
  // { label: "San Jose", mun_code: "041028" },
  // { label: "San Juan", mun_code: "041028" },
  // { label: "San Luis", mun_code: "041028" },
  // { label: "San Miguel", mun_code: "041028" },
  // { label: "San Pablo", mun_code: "041028" },
  // { label: "San Pedro", mun_code: "041028" },
  // { label: "San Rafael", mun_code: "041028" },
  // { label: "San Roque", mun_code: "041028" },
  // { label: "San Vicente", mun_code: "041028" },
  // { label: "Santa Ana", mun_code: "041028" },
  // { label: "Santa Anastacia", mun_code: "041028" },
  // { label: "Santa Clara", mun_code: "041028" },
  // { label: "Santa Cruz", mun_code: "041028" },
  // { label: "Santa Elena", mun_code: "041028" },
  // { label: "Santa Maria", mun_code: "041028" },
  // { label: "Santiago", mun_code: "041028" },
  // { label: "Santa Teresita", mun_code: "041028" },
  // { label: "Apacay", mun_code: "041029" },
  // { label: "Balisong", mun_code: "041029" },
  // { label: "Bihis", mun_code: "041029" },
  // { label: "Bolbok", mun_code: "041029" },
  // { label: "Buli", mun_code: "041029" },
  // { label: "Butong", mun_code: "041029" },
  // { label: "Carasuche", mun_code: "041029" },
  // { label: "Cawit", mun_code: "041029" },
  // { label: "Caysasay", mun_code: "041029" },
  // { label: "Cubamba", mun_code: "041029" },
  // { label: "Cultihan", mun_code: "041029" },
  // { label: "Gahol", mun_code: "041029" },
  // { label: "Halang", mun_code: "041029" },
  // { label: "Iba", mun_code: "041029" },
  // { label: "Ilog", mun_code: "041029" },
  // { label: "Imamawo", mun_code: "041029" },
  // { label: "Ipil", mun_code: "041029" },
  // { label: "Luntal", mun_code: "041029" },
  // { label: "Mahabang Lodlod", mun_code: "041029" },
  // { label: "Niogan", mun_code: "041029" },
  // { label: "Pansol", mun_code: "041029" },
  // { label: "Poblacion 11", mun_code: "041029" },
  // { label: "Poblacion 1", mun_code: "041029" },
  // { label: "Poblacion 10", mun_code: "041029" },
  // { label: "Poblacion 12", mun_code: "041029" },
  // { label: "Poblacion 2", mun_code: "041029" },
  // { label: "Poblacion 3", mun_code: "041029" },
  // { label: "Poblacion 4", mun_code: "041029" },
  // { label: "Poblacion 5", mun_code: "041029" },
  // { label: "Poblacion 6", mun_code: "041029" },
  // { label: "Poblacion 7", mun_code: "041029" },
  // { label: "Poblacion 8", mun_code: "041029" },
  // { label: "Poblacion 9", mun_code: "041029" },
  // { label: "Pook", mun_code: "041029" },
  // { label: "Seiran", mun_code: "041029" },
  // { label: "Laguile", mun_code: "041029" },
  // { label: "Latag", mun_code: "041029" },
  // { label: "Tierra Alta", mun_code: "041029" },
  // { label: "Tulo", mun_code: "041029" },
  // { label: "Tatlong Maria", mun_code: "041029" },
  // { label: "Poblacion 13", mun_code: "041029" },
  // { label: "Poblacion 14", mun_code: "041029" },
  // { label: "Aya", mun_code: "041030" },
  // { label: "Balas", mun_code: "041030" },
  // { label: "Banga", mun_code: "041030" },
  // { label: "Buco", mun_code: "041030" },
  // { label: "Caloocan", mun_code: "041030" },
  // { label: "Leynes", mun_code: "041030" },
  // { label: "Miranda", mun_code: "041030" },
  // { label: "Poblacion Barangay 1", mun_code: "041030" },
  // { label: "Poblacion Barangay 2", mun_code: "041030" },
  // { label: "Poblacion Barangay 3", mun_code: "041030" },
  // { label: "Poblacion Barangay 4", mun_code: "041030" },
  // { label: "Poblacion Barangay 5", mun_code: "041030" },
  // { label: "Poblacion Barangay 6", mun_code: "041030" },
  // { label: "Poblacion Barangay 7", mun_code: "041030" },
  // { label: "Poblacion Barangay 8", mun_code: "041030" },
  // { label: "Quiling", mun_code: "041030" },
  // { label: "Sampaloc", mun_code: "041030" },
  // { label: "San Guillermo", mun_code: "041030" },
  // { label: "Santa Maria", mun_code: "041030" },
  // { label: "Tranca", mun_code: "041030" },
  // { label: "Tumaway", mun_code: "041030" },
  // { label: "Altura Bata", mun_code: "041031" },
  // { label: "Altura Matanda", mun_code: "041031" },
  // { label: "Altura-South", mun_code: "041031" },
  // { label: "Ambulong", mun_code: "041031" },
  // { label: "Banadero", mun_code: "041031" },
  // { label: "Bagbag", mun_code: "041031" },
  // { label: "Bagumbayan", mun_code: "041031" },
  // { label: "Balele", mun_code: "041031" },
  // { label: "Banjo East", mun_code: "041031" },
  // { label: "Banjo Laurel (Banjo West)", mun_code: "041031" },
  // { label: "Bilog-bilog", mun_code: "041031" },
  // { label: "Boot", mun_code: "041031" },
  // { label: "Cale", mun_code: "041031" },
  // { label: "Darasa", mun_code: "041031" },
  // { label: "Pagaspas", mun_code: "041031" },
  // { label: "Gonzales", mun_code: "041031" },
  // { label: "Hidalgo", mun_code: "041031" },
  // { label: "Janopol", mun_code: "041031" },
  // { label: "Janopol Oriental", mun_code: "041031" },
  // { label: "Laurel", mun_code: "041031" },
  // { label: "Luyos", mun_code: "041031" },
  // { label: "Mabini", mun_code: "041031" },
  // { label: "Malaking Pulo", mun_code: "041031" },
  // { label: "Maria Paz", mun_code: "041031" },
  // { label: "Maugat", mun_code: "041031" },
  // { label: "Natatas", mun_code: "041031" },
  // { label: "Pantay Matanda", mun_code: "041031" },
  // { label: "Pantay Bata", mun_code: "041031" },
  // { label: "Poblacion Barangay 1", mun_code: "041031" },
  // { label: "Poblacion Barangay 2", mun_code: "041031" },
  // { label: "Poblacion Barangay 3", mun_code: "041031" },
  // { label: "Poblacion Barangay 4", mun_code: "041031" },
  // { label: "Poblacion Barangay 5", mun_code: "041031" },
  // { label: "Poblacion Barangay 6", mun_code: "041031" },
  // { label: "Poblacion Barangay 7", mun_code: "041031" },
  // { label: "Sala", mun_code: "041031" },
  // { label: "Sambat", mun_code: "041031" },
  // { label: "San Jose", mun_code: "041031" },
  // { label: "Santor", mun_code: "041031" },
  // { label: "Sulpoc", mun_code: "041031" },
  // { label: "Suplang", mun_code: "041031" },
  // { label: "Talaga", mun_code: "041031" },
  // { label: "Tinurik", mun_code: "041031" },
  // { label: "Trapiche", mun_code: "041031" },
  // { label: "Ulango", mun_code: "041031" },
  // { label: "Wawa", mun_code: "041031" },
  // { label: "Bacao", mun_code: "041032" },
  // { label: "Bilogo", mun_code: "041032" },
  // { label: "Bukal", mun_code: "041032" },
  // { label: "Dagatan", mun_code: "041032" },
  // { label: "Guinhawa", mun_code: "041032" },
  // { label: "Laurel", mun_code: "041032" },
  // { label: "Mabayabas", mun_code: "041032" },
  // { label: "Mahanadiong", mun_code: "041032" },
  // { label: "Mapulo", mun_code: "041032" },
  // { label: "Mataas Na Lupa", mun_code: "041032" },
  // { label: "Pag-Asa", mun_code: "041032" },
  // { label: "Panghayaan", mun_code: "041032" },
  // { label: "Pinagbayanan", mun_code: "041032" },
  // { label: "Poblacion East", mun_code: "041032" },
  // { label: "Poblacion West", mun_code: "041032" },
  // { label: "San Isidro", mun_code: "041032" },
  // { label: "San Marcelino", mun_code: "041032" },
  // { label: "Tilambo", mun_code: "041032" },
  // { label: "Corona", mun_code: "041033" },
  // { label: "Gamao", mun_code: "041033" },
  // { label: "Makawayan", mun_code: "041033" },
  // { label: "Marikaban", mun_code: "041033" },
  // { label: "Papaya", mun_code: "041033" },
  // { label: "Pisa", mun_code: "041033" },
  // { label: "Barangay 13 (Poblacion 1)", mun_code: "041033" },
  // { label: "Barangay 14 (Poblacion 2)", mun_code: "041033" },
  // { label: "Barangay 15 (Poblacion 3)", mun_code: "041033" },
  // { label: "San Isidro", mun_code: "041033" },
  // { label: "San Jose", mun_code: "041033" },
  // { label: "San Juan", mun_code: "041033" },
  // { label: "San Pedro", mun_code: "041033" },
  // { label: "Santo Tomas", mun_code: "041033" },
  // { label: "Talahib", mun_code: "041033" },
  // { label: "Acle", mun_code: "041034" },
  // { label: "Bayudbud", mun_code: "041034" },
  // { label: "Bolboc", mun_code: "041034" },
  // { label: "Dalima", mun_code: "041034" },
  // { label: "Dao", mun_code: "041034" },
  // { label: "Guinhawa", mun_code: "041034" },
  // { label: "Lumbangan", mun_code: "041034" },
  // { label: "Luntal", mun_code: "041034" },
  // { label: "Magahis", mun_code: "041034" },
  // { label: "Malibu", mun_code: "041034" },
  // { label: "Mataywanac", mun_code: "041034" },
  // { label: "Palincaro", mun_code: "041034" },
  // { label: "Luna (Pob.)", mun_code: "041034" },
  // { label: "Burgos (Pob.)", mun_code: "041034" },
  // { label: "Rizal (Pob.)", mun_code: "041034" },
  // { label: "Rillo (Pob.)", mun_code: "041034" },
  // { label: "Putol", mun_code: "041034" },
  // { label: "Sabang", mun_code: "041034" },
  // { label: "San Jose", mun_code: "041034" },
  // { label: "Talon", mun_code: "041034" },
  // { label: "Toong", mun_code: "041034" },
  // { label: "Tuyon-tuyon", mun_code: "041034" },
  // { label: "Amuyong", mun_code: "042101" },
  // { label: "Barangay I (Pob.)", mun_code: "042101" },
  // { label: "Barangay II (Pob.)", mun_code: "042101" },
  // { label: "Barangay III (Pob.)", mun_code: "042101" },
  // { label: "Barangay IV (Pob.)", mun_code: "042101" },
  // { label: "Barangay V (Pob.)", mun_code: "042101" },
  // { label: "Buck Estate", mun_code: "042101" },
  // { label: "Esperanza Ibaba", mun_code: "042101" },
  // { label: "Kaytitinga I", mun_code: "042101" },
  // { label: "Luksuhin", mun_code: "042101" },
  // { label: "Mangas I", mun_code: "042101" },
  // { label: "Marahan I", mun_code: "042101" },
  // { label: "Matagbak I", mun_code: "042101" },
  // { label: "Pajo", mun_code: "042101" },
  // { label: "Sikat", mun_code: "042101" },
  // { label: "Sinaliw Malaki", mun_code: "042101" },
  // { label: "Sinaliw na Munti", mun_code: "042101" },
  // { label: "Sulsugin", mun_code: "042101" },
  // { label: "Taywanak Ibaba", mun_code: "042101" },
  // { label: "Taywanak Ilaya", mun_code: "042101" },
  // { label: "Upli", mun_code: "042101" },
  // { label: "Kaysuyo", mun_code: "042101" },
  // { label: "Luksuhin Ilaya", mun_code: "042101" },
  // { label: "Palumlum", mun_code: "042101" },
  // { label: "Bilog", mun_code: "042101" },
  // { label: "Esperanza Ilaya", mun_code: "042101" },
  // { label: "Kaytitinga II", mun_code: "042101" },
  // { label: "Kaytitinga III", mun_code: "042101" },
  // { label: "Mangas II", mun_code: "042101" },
  // { label: "Marahan II", mun_code: "042101" },
  // { label: "Matagbak II", mun_code: "042101" },
  // { label: "Santa Teresa", mun_code: "042101" },
  // { label: "Banaybanay", mun_code: "042102" },
  // { label: "Bucal", mun_code: "042102" },
  // { label: "Dagatan", mun_code: "042102" },
  // { label: "Halang", mun_code: "042102" },
  // { label: "Loma", mun_code: "042102" },
  // { label: "Maitim I", mun_code: "042102" },
  // { label: "Maymangga", mun_code: "042102" },
  // { label: "Minantok Kanluran", mun_code: "042102" },
  // { label: "Pangil", mun_code: "042102" },
  // { label: "Barangay I (Pob.)", mun_code: "042102" },
  // { label: "Barangay X (Pob.)", mun_code: "042102" },
  // { label: "Barangay XI (Pob.)", mun_code: "042102" },
  // { label: "Barangay XII (Pob.)", mun_code: "042102" },
  // { label: "Barangay II (Pob.)", mun_code: "042102" },
  // { label: "Barangay III (Pob.)", mun_code: "042102" },
  // { label: "Barangay IV (Pob.)", mun_code: "042102" },
  // { label: "Barangay V (Pob.)", mun_code: "042102" },
  // { label: "Barangay VI (Pob.)", mun_code: "042102" },
  // { label: "Barangay VII (Pob.)", mun_code: "042102" },
  // { label: "Barangay VIII (Pob.)", mun_code: "042102" },
  // { label: "Barangay IX (Pob.)", mun_code: "042102" },
  // { label: "Salaban", mun_code: "042102" },
  // { label: "Talon", mun_code: "042102" },
  // { label: "Tamacan", mun_code: "042102" },
  // { label: "Buho", mun_code: "042102" },
  // { label: "Minantok Silangan", mun_code: "042102" },
  // { label: "Alima", mun_code: "042103" },
  // { label: "Aniban I", mun_code: "042103" },
  // { label: "Banalo", mun_code: "042103" },
  // { label: "Bayanan", mun_code: "042103" },
  // { label: "Daang Bukid", mun_code: "042103" },
  // { label: "Digman", mun_code: "042103" },
  // { label: "Dulong Bayan", mun_code: "042103" },
  // { label: "Habay I", mun_code: "042103" },
  // { label: "Kaingin (Pob.)", mun_code: "042103" },
  // { label: "Ligas I", mun_code: "042103" },
  // { label: "Mabolo I", mun_code: "042103" },
  // { label: "Maliksi I", mun_code: "042103" },
  // { label: "Mambog I", mun_code: "042103" },
  // { label: "Molino I", mun_code: "042103" },
  // { label: "Niog I", mun_code: "042103" },
  // { label: "P.F. Espiritu I (Panapaan)", mun_code: "042103" },
  // { label: "Real I", mun_code: "042103" },
  // { label: "Salinas I", mun_code: "042103" },
  // { label: "San Nicolas I", mun_code: "042103" },
  // { label: "Sineguelasan", mun_code: "042103" },
  // { label: "Tabing Dagat", mun_code: "042103" },
  // { label: "Talaba I", mun_code: "042103" },
  // { label: "Zapote I", mun_code: "042103" },
  // { label: "Queens Row Central", mun_code: "042103" },
  // { label: "Queens Row East", mun_code: "042103" },
  // { label: "Queens Row West", mun_code: "042103" },
  // { label: "Aniban II", mun_code: "042103" },
  // { label: "Aniban III", mun_code: "042103" },
  // { label: "Aniban IV", mun_code: "042103" },
  // { label: "Aniban V", mun_code: "042103" },
  // { label: "Campo Santo", mun_code: "042103" },
  // { label: "Habay II", mun_code: "042103" },
  // { label: "Ligas II", mun_code: "042103" },
  // { label: "Ligas III", mun_code: "042103" },
  // { label: "Mabolo II", mun_code: "042103" },
  // { label: "Mabolo III", mun_code: "042103" },
  // { label: "Maliksi II", mun_code: "042103" },
  // { label: "Maliksi III", mun_code: "042103" },
  // { label: "Mambog II", mun_code: "042103" },
  // { label: "Mambog III", mun_code: "042103" },
  // { label: "Mambog IV", mun_code: "042103" },
  // { label: "Mambog V", mun_code: "042103" },
  // { label: "Molino II", mun_code: "042103" },
  // { label: "Molino III", mun_code: "042103" },
  // { label: "Molino IV", mun_code: "042103" },
  // { label: "Molino V", mun_code: "042103" },
  // { label: "Molino VI", mun_code: "042103" },
  // { label: "Molino VII", mun_code: "042103" },
  // { label: "Niog II", mun_code: "042103" },
  // { label: "Niog III", mun_code: "042103" },
  // { label: "P.F. Espiritu II", mun_code: "042103" },
  // { label: "P.F. Espiritu III", mun_code: "042103" },
  // { label: "P.F. Espiritu IV", mun_code: "042103" },
  // { label: "P.F. Espiritu V", mun_code: "042103" },
  // { label: "P.F. Espiritu VI", mun_code: "042103" },
  // { label: "P.F. Espiritu VII", mun_code: "042103" },
  // { label: "P.F. Espiritu VIII", mun_code: "042103" },
  // { label: "Real II", mun_code: "042103" },
  // { label: "Salinas II", mun_code: "042103" },
  // { label: "Salinas III", mun_code: "042103" },
  // { label: "Salinas IV", mun_code: "042103" },
  // { label: "San Nicolas II", mun_code: "042103" },
  // { label: "San Nicolas III", mun_code: "042103" },
  // { label: "Talaba II", mun_code: "042103" },
  // { label: "Talaba III", mun_code: "042103" },
  // { label: "Talaba IV", mun_code: "042103" },
  // { label: "Talaba V", mun_code: "042103" },
  // { label: "Talaba VI", mun_code: "042103" },
  // { label: "Talaba VII", mun_code: "042103" },
  // { label: "Zapote II", mun_code: "042103" },
  // { label: "Zapote III", mun_code: "042103" },
  // { label: "Zapote IV", mun_code: "042103" },
  // { label: "Zapote V", mun_code: "042103" },
  // { label: "Bancal", mun_code: "042104" },
  // { label: "Cabilang Baybay", mun_code: "042104" },
  // { label: "Lantic", mun_code: "042104" },
  // { label: "Mabuhay", mun_code: "042104" },
  // { label: "Maduya", mun_code: "042104" },
  // { label: "Milagrosa", mun_code: "042104" },
  // { label: "Barangay 1 (Pob.)", mun_code: "042104" },
  // { label: "Barangay 2 (Pob.)", mun_code: "042104" },
  // { label: "Barangay 3 (Pob.)", mun_code: "042104" },
  // { label: "Barangay 4 (Pob.)", mun_code: "042104" },
  // { label: "Barangay 5 (Pob.)", mun_code: "042104" },
  // { label: "Barangay 6 (Pob.)", mun_code: "042104" },
  // { label: "Barangay 7 (Pob.)", mun_code: "042104" },
  // { label: "Barangay 8 (Pob.)", mun_code: "042104" },
  // { label: "Barangay 1 (Hen. M. Alvarez)", mun_code: "042105" },
  // { label: "Barangay 10 (Kingfisher)", mun_code: "042105" },
  // { label: "Barangay 2 (C. Tirona)", mun_code: "042105" },
  // { label: "Barangay 11 (Lawin)", mun_code: "042105" },
  // { label: "Barangay 12 (Love Bird)", mun_code: "042105" },
  // { label: "Barangay 13 (Aguila)", mun_code: "042105" },
  // { label: "Barangay 14 (Loro)", mun_code: "042105" },
  // { label: "Barangay 15 (Kilyawan)", mun_code: "042105" },
  // { label: "Barangay 16 (Martines)", mun_code: "042105" },
  // { label: "Barangay 17 (Kalapati)", mun_code: "042105" },
  // { label: "Barangay 18 (Maya)", mun_code: "042105" },
  // { label: "Barangay 19 (Gemini)", mun_code: "042105" },
  // { label: "Barangay 20 (Virgo)", mun_code: "042105" },
  // { label: "Barangay 21 (Scorpio)", mun_code: "042105" },
  // { label: "Barangay 22 (Leo)", mun_code: "042105" },
  // { label: "Barangay 23 (Aquarius)", mun_code: "042105" },
  // { label: "Barangay 24 (Libra)", mun_code: "042105" },
  // { label: "Barangay 25 (Capricorn)", mun_code: "042105" },
  // { label: "Barangay 26 (Cancer)", mun_code: "042105" },
  // { label: "Barangay 27 (Sagitarius)", mun_code: "042105" },
  // { label: "Barangay 28 (Taurus)", mun_code: "042105" },
  // { label: "Barangay 29 (Lao-lao)", mun_code: "042105" },
  // { label: "Barangay 3 (Hen. E. Aguinaldo)", mun_code: "042105" },
  // { label: "Barangay 30 (Bid-bid)", mun_code: "042105" },
  // { label: "Barangay 31 (Maya-maya)", mun_code: "042105" },
  // { label: "Barangay 32 (Salay-salay)", mun_code: "042105" },
  // { label: "Barangay 33 (Buwan-buwan)", mun_code: "042105" },
  // { label: "Barangay 34 (Lapu-lapu)", mun_code: "042105" },
  // { label: "Barangay 35 (Hasa-hasa)", mun_code: "042105" },
  // { label: "Barangay 36 (Sap-sap)", mun_code: "042105" },
  // { label: "Barangay 37 (Cadena De Amor)", mun_code: "042105" },
  // { label: "Barangay 38 (Sampaguita)", mun_code: "042105" },
  // { label: "Barangay 39 (Jasmin)", mun_code: "042105" },
  // { label: "Barangay 4 (Hen. M. Trias)", mun_code: "042105" },
  // { label: "Barangay 40 (Gumamela)", mun_code: "042105" },
  // { label: "Barangay 41 (Rosal)", mun_code: "042105" },
  // { label: "Barangay 42 (Pinagbuklod)", mun_code: "042105" },
  // { label: "Barangay 43 (Pinagpala)", mun_code: "042105" },
  // { label: "Barangay 44 (Maligaya)", mun_code: "042105" },
  // { label: "Barangay 45 (Kaunlaran)", mun_code: "042105" },
  // { label: "Barangay 46 (Sinagtala)", mun_code: "042105" },
  // { label: "Barangay 47 (Pagkakaisa)", mun_code: "042105" },
  // { label: "Barangay 48 (Narra)", mun_code: "042105" },
  // { label: "Barangay 49 (Akasya)", mun_code: "042105" },
  // { label: "Barangay 5 (Hen. E. Evangelista)", mun_code: "042105" },
  // { label: "Barangay 50 (Kabalyero)", mun_code: "042105" },
  // { label: "Barangay 51 (Kamagong)", mun_code: "042105" },
  // { label: "Barangay 52 (Ipil)", mun_code: "042105" },
  // { label: "Barangay 53 (Yakal)", mun_code: "042105" },
  // { label: "Barangay 54 (Pechay)", mun_code: "042105" },
  // { label: "Barangay 55 (Ampalaya)", mun_code: "042105" },
  // { label: "Barangay 56 (Labanos)", mun_code: "042105" },
  // { label: "Barangay 57 (Repolyo)", mun_code: "042105" },
  // { label: "Barangay 58 (Patola)", mun_code: "042105" },
  // { label: "Barangay 59 (Sitaw)", mun_code: "042105" },
  // { label: "Barangay 6 (Diego Silang)", mun_code: "042105" },
  // { label: "Barangay 60 (Letsugas)", mun_code: "042105" },
  // { label: "Barangay 61 (Talong)", mun_code: "042105" },
  // { label: "Barangay 62 (Kangkong)", mun_code: "042105" },
  // { label: "Barangay 7 (Kapitan Kong)", mun_code: "042105" },
  // { label: "Barangay 8 (Manuel S. Rojas)", mun_code: "042105" },
  // { label: "Barangay 9 (Kanaway)", mun_code: "042105" },
  // { label: "Barangay 10-A (Kingfisher-A)", mun_code: "042105" },
  // { label: "Barangay 10-B (Kingfisher-B)", mun_code: "042105" },
  // { label: "Barangay 22-A (Leo A)", mun_code: "042105" },
  // { label: "Barangay 29-A (Lao-lao A)", mun_code: "042105" },
  // { label: "Barangay 36-A (Sap-sap A)", mun_code: "042105" },
  // { label: "Barangay 37-A (Cadena de Amor A)", mun_code: "042105" },
  // { label: "Barangay 38-A (Sampaguita A)", mun_code: "042105" },
  // { label: "Barangay 42-A (Pinagbuklod A)", mun_code: "042105" },
  // { label: "Barangay 42-B (Pinagbuklod B)", mun_code: "042105" },
  // { label: "Barangay 42-C (Pinagbuklod C)", mun_code: "042105" },
  // { label: "Barangay 45-A (Kaunlaran A)", mun_code: "042105" },
  // { label: "Barangay 47-A (Pagkakaisa A)", mun_code: "042105" },
  // { label: "Barangay 47-B (Pagkakaisa B)", mun_code: "042105" },
  // { label: "Barangay 48-A (Narra A)", mun_code: "042105" },
  // { label: "Barangay 49-A (Akasya A)", mun_code: "042105" },
  // { label: "Barangay 53-A (Yakal A)", mun_code: "042105" },
  // { label: "Barangay 53-B (Yakal B)", mun_code: "042105" },
  // { label: "Barangay 54-A (Pechay A)", mun_code: "042105" },
  // { label: "Barangay 58-A (Patola A)", mun_code: "042105" },
  // { label: "Barangay 61-A (Talong A)", mun_code: "042105" },
  // { label: "Barangay 62-A (Kangkong A)", mun_code: "042105" },
  // { label: "Barangay 62-B (Kangkong B)", mun_code: "042105" },
  // { label: "Burol", mun_code: "042106" },
  // { label: "Langkaan I", mun_code: "042106" },
  // { label: "Paliparan I", mun_code: "042106" },
  // { label: "Sabang", mun_code: "042106" },
  // { label: "Salawag", mun_code: "042106" },
  // { label: "Salitran I", mun_code: "042106" },
  // { label: "Sampaloc I", mun_code: "042106" },
  // { label: "San Agustin I", mun_code: "042106" },
  // { label: "San Jose", mun_code: "042106" },
  // { label: "Zone I (Pob.)", mun_code: "042106" },
  // { label: "Zone II (Pob.)", mun_code: "042106" },
  // { label: "Zone III (Pob.)", mun_code: "042106" },
  // { label: "Zone IV (Pob.)", mun_code: "042106" },
  // { label: "Datu Esmael (Bago-a-ingud)", mun_code: "042106" },
  // { label: "Emmanuel Bergado I", mun_code: "042106" },
  // { label: "Fatima I", mun_code: "042106" },
  // { label: "Luzviminda I", mun_code: "042106" },
  // { label: "Saint Peter I", mun_code: "042106" },
  // { label: "San Andres I", mun_code: "042106" },
  // { label: "San Antonio De Padua I", mun_code: "042106" },
  // { label: "San Dionisio (Barangay 1)", mun_code: "042106" },
  // { label: "San Esteban (Barangay 4)", mun_code: "042106" },
  // { label: "San Francisco I", mun_code: "042106" },
  // { label: "San Isidro Labrador I", mun_code: "042106" },
  // { label: "San Juan (San Juan I)", mun_code: "042106" },
  // { label: "San Lorenzo Ruiz I", mun_code: "042106" },
  // { label: "San Luis I", mun_code: "042106" },
  // { label: "San Manuel I", mun_code: "042106" },
  // { label: "San Mateo", mun_code: "042106" },
  // { label: "San Miguel", mun_code: "042106" },
  // { label: "San Nicolas I", mun_code: "042106" },
  // { label: "San Roque (Sta. Cristina II)", mun_code: "042106" },
  // { label: "San Simon (Barangay 7)", mun_code: "042106" },
  // { label: "Santa Cristina I", mun_code: "042106" },
  // { label: "Santa Cruz I", mun_code: "042106" },
  // { label: "Santa Fe", mun_code: "042106" },
  // { label: "Santa Lucia (San Juan II)", mun_code: "042106" },
  // { label: "Santa Maria (Barangay 20)", mun_code: "042106" },
  // { label: "Santo Cristo (Barangay 3)", mun_code: "042106" },
  // { label: "Burol I", mun_code: "042106" },
  // { label: "Burol II", mun_code: "042106" },
  // { label: "Burol III", mun_code: "042106" },
  // { label: "Emmanuel Bergado II", mun_code: "042106" },
  // { label: "Fatima II", mun_code: "042106" },
  // { label: "Fatima III", mun_code: "042106" },
  // { label: "Langkaan II", mun_code: "042106" },
  // { label: "Luzviminda II", mun_code: "042106" },
  // { label: "Paliparan II", mun_code: "042106" },
  // { label: "Paliparan III", mun_code: "042106" },
  // { label: "Saint Peter II", mun_code: "042106" },
  // { label: "Salitran II", mun_code: "042106" },
  // { label: "Salitran III", mun_code: "042106" },
  // { label: "Salitran IV", mun_code: "042106" },
  // { label: "Sampaloc II", mun_code: "042106" },
  // { label: "Sampaloc III", mun_code: "042106" },
  // { label: "Sampaloc IV", mun_code: "042106" },
  // { label: "Sampaloc V", mun_code: "042106" },
  // { label: "San Agustin II", mun_code: "042106" },
  // { label: "San Agustin III", mun_code: "042106" },
  // { label: "San Andres II", mun_code: "042106" },
  // { label: "San Antonio De Padua II", mun_code: "042106" },
  // { label: "San Francisco II", mun_code: "042106" },
  // { label: "San Isidro Labrador II", mun_code: "042106" },
  // { label: "San Lorenzo Ruiz II", mun_code: "042106" },
  // { label: "San Luis II", mun_code: "042106" },
  // { label: "San Manuel II", mun_code: "042106" },
  // { label: "San Miguel II", mun_code: "042106" },
  // { label: "San Nicolas II", mun_code: "042106" },
  // { label: "Santa Cristina II", mun_code: "042106" },
  // { label: "Santa Cruz II", mun_code: "042106" },
  // { label: "Zone I-B", mun_code: "042106" },
  // { label: "H-2", mun_code: "042106" },
  // { label: "Victoria Reyes", mun_code: "042106" },
  // { label: "A. Dalusag", mun_code: "042107" },
  // { label: "Batas Dao", mun_code: "042107" },
  // { label: "Kabulusan", mun_code: "042107" },
  // { label: "Kaymisas", mun_code: "042107" },
  // { label: "Kaypaaba", mun_code: "042107" },
  // { label: "Lumipa", mun_code: "042107" },
  // { label: "Narvaez", mun_code: "042107" },
  // { label: "Poblacion I", mun_code: "042107" },
  // { label: "Tabora", mun_code: "042107" },
  // { label: "Poblacion II", mun_code: "042107" },
  // { label: "Poblacion III", mun_code: "042107" },
  // { label: "Poblacion IV", mun_code: "042107" },
  // { label: "Alingaro", mun_code: "042108" },
  // { label: "Bacao I", mun_code: "042108" },
  // { label: "Gov. Ferrer Pob. (Bgy. 1)", mun_code: "042108" },
  // { label: "Sampalucan Pob. (Bgy. 2)", mun_code: "042108" },
  // { label: "Dulong Bayan Pob. (Bgy. 3)", mun_code: "042108" },
  // { label: "San Gabriel Pob. (Bgy. 4)", mun_code: "042108" },
  // { label: "Bagumbayan Pob. (Bgy. 5)", mun_code: "042108" },
  // { label: "Vibora Pob. (Bgy. 6)", mun_code: "042108" },
  // { label: "Ninety Sixth Pob. (Bgy. 8)", mun_code: "042108" },
  // { label: "Prinza Pob. (Bgy. 9)", mun_code: "042108" },
  // { label: "Biclatan", mun_code: "042108" },
  // { label: "Buenavista I", mun_code: "042108" },
  // { label: "Corregidor Pob. (Bgy. 10)", mun_code: "042108" },
  // { label: "Javalera", mun_code: "042108" },
  // { label: "Manggahan", mun_code: "042108" },
  // { label: "Navarro", mun_code: "042108" },
  // { label: "Panungyanan", mun_code: "042108" },
  // { label: "Pasong Camachile I", mun_code: "042108" },
  // { label: "Pasong Kawayan I", mun_code: "042108" },
  // { label: "Pasong Kawayan II", mun_code: "042108" },
  // { label: "Pinagtipunan", mun_code: "042108" },
  // { label: "San Francisco", mun_code: "042108" },
  // { label: "San Juan I", mun_code: "042108" },
  // { label: "Santa Clara", mun_code: "042108" },
  // { label: "Santiago", mun_code: "042108" },
  // { label: "Tapia", mun_code: "042108" },
  // { label: "Tejero", mun_code: "042108" },
  // { label: "Arnaldo Pob. (Bgy. 7)", mun_code: "042108" },
  // { label: "Bacao II", mun_code: "042108" },
  // { label: "Buenavista II", mun_code: "042108" },
  // { label: "Buenavista III", mun_code: "042108" },
  // { label: "Pasong Camachile II", mun_code: "042108" },
  // { label: "San Juan II", mun_code: "042108" },
  // { label: "Alapan I-A", mun_code: "042109" },
  // { label: "Alapan II-A", mun_code: "042109" },
  // { label: "Anabu I-A", mun_code: "042109" },
  // { label: "Anabu II-A", mun_code: "042109" },
  // { label: "Poblacion I-A (Pob.)", mun_code: "042109" },
  // { label: "Poblacion II-A (Pob.)", mun_code: "042109" },
  // { label: "Poblacion III-A (Pob.)", mun_code: "042109" },
  // { label: "Poblacion IV-A (Pob.)", mun_code: "042109" },
  // { label: "Bayan Luma I", mun_code: "042109" },
  // { label: "Bucandala I", mun_code: "042109" },
  // { label: "Carsadang Bago I", mun_code: "042109" },
  // { label: "Malagasang I-A", mun_code: "042109" },
  // { label: "Malagasang II-A", mun_code: "042109" },
  // { label: "Medicion I-A", mun_code: "042109" },
  // { label: "Medicion II-A", mun_code: "042109" },
  // { label: "Pag-Asa I", mun_code: "042109" },
  // { label: "Palico I", mun_code: "042109" },
  // { label: "Pasong Buaya I", mun_code: "042109" },
  // { label: "Tanzang Luma I", mun_code: "042109" },
  // { label: "Toclong I-A", mun_code: "042109" },
  // { label: "Toclong II-A", mun_code: "042109" },
  // { label: "Alapan I-B", mun_code: "042109" },
  // { label: "Alapan I-C", mun_code: "042109" },
  // { label: "Alapan II-B", mun_code: "042109" },
  // { label: "Anabu I-B", mun_code: "042109" },
  // { label: "Anabu I-C", mun_code: "042109" },
  // { label: "Anabu I-D", mun_code: "042109" },
  // { label: "Anabu I-E", mun_code: "042109" },
  // { label: "Anabu I-F", mun_code: "042109" },
  // { label: "Anabu I-G", mun_code: "042109" },
  // { label: "Anabu II-B", mun_code: "042109" },
  // { label: "Anabu II-C", mun_code: "042109" },
  // { label: "Anabu II-D", mun_code: "042109" },
  // { label: "Anabu II-E", mun_code: "042109" },
  // { label: "Anabu II-F", mun_code: "042109" },
  // { label: "Bagong Silang (Bahayang Pag-Asa)", mun_code: "042109" },
  // { label: "Bayan Luma II", mun_code: "042109" },
  // { label: "Bayan Luma III", mun_code: "042109" },
  // { label: "Bayan Luma IV", mun_code: "042109" },
  // { label: "Bayan Luma V", mun_code: "042109" },
  // { label: "Bayan Luma VI", mun_code: "042109" },
  // { label: "Bayan Luma VII", mun_code: "042109" },
  // { label: "Bayan Luma VIII", mun_code: "042109" },
  // { label: "Bayan Luma IX", mun_code: "042109" },
  // { label: "Bucandala II", mun_code: "042109" },
  // { label: "Bucandala III", mun_code: "042109" },
  // { label: "Bucandala IV", mun_code: "042109" },
  // { label: "Bucandala V", mun_code: "042109" },
  // { label: "Buhay na Tubig", mun_code: "042109" },
  // { label: "Carsadang Bago II", mun_code: "042109" },
  // { label: "Magdalo", mun_code: "042109" },
  // { label: "Maharlika", mun_code: "042109" },
  // { label: "Malagasang I-B", mun_code: "042109" },
  // { label: "Malagasang I-C", mun_code: "042109" },
  // { label: "Malagasang I-D", mun_code: "042109" },
  // { label: "Malagasang I-E", mun_code: "042109" },
  // { label: "Malagasang I-F", mun_code: "042109" },
  // { label: "Malagasang I-G", mun_code: "042109" },
  // { label: "Malagasang II-B", mun_code: "042109" },
  // { label: "Malagasang II-C", mun_code: "042109" },
  // { label: "Malagasang II-D", mun_code: "042109" },
  // { label: "Malagasang II-E", mun_code: "042109" },
  // { label: "Malagasang II-F", mun_code: "042109" },
  // { label: "Malagasang II-G", mun_code: "042109" },
  // { label: "Mariano Espeleta I", mun_code: "042109" },
  // { label: "Mariano Espeleta II", mun_code: "042109" },
  // { label: "Mariano Espeleta III", mun_code: "042109" },
  // { label: "Medicion I-B", mun_code: "042109" },
  // { label: "Medicion I-C", mun_code: "042109" },
  // { label: "Medicion I-D", mun_code: "042109" },
  // { label: "Medicion II-B", mun_code: "042109" },
  // { label: "Medicion II-C", mun_code: "042109" },
  // { label: "Medicion II-D", mun_code: "042109" },
  // { label: "Medicion II-E", mun_code: "042109" },
  // { label: "Medicion II-F", mun_code: "042109" },
  // { label: "Pag-Asa II", mun_code: "042109" },
  // { label: "Pag-Asa III", mun_code: "042109" },
  // { label: "Palico II", mun_code: "042109" },
  // { label: "Palico III", mun_code: "042109" },
  // { label: "Palico IV", mun_code: "042109" },
  // { label: "Pasong Buaya II", mun_code: "042109" },
  // { label: "Pinagbuklod", mun_code: "042109" },
  // { label: "Poblacion I-B", mun_code: "042109" },
  // { label: "Poblacion I-C", mun_code: "042109" },
  // { label: "Poblacion II-B", mun_code: "042109" },
  // { label: "Poblacion III-B", mun_code: "042109" },
  // { label: "Poblacion IV-B", mun_code: "042109" },
  // { label: "Poblacion IV-C", mun_code: "042109" },
  // { label: "Poblacion IV-D", mun_code: "042109" },
  // { label: "Tanzang Luma II", mun_code: "042109" },
  // { label: "Tanzang Luma III", mun_code: "042109" },
  // { label: "Tanzang Luma IV (Southern City)", mun_code: "042109" },
  // { label: "Tanzang Luma V", mun_code: "042109" },
  // { label: "Tanzang Luma VI", mun_code: "042109" },
  // { label: "Toclong I-B", mun_code: "042109" },
  // { label: "Toclong I-C", mun_code: "042109" },
  // { label: "Toclong II-B", mun_code: "042109" },
  // { label: "Agus-us", mun_code: "042110" },
  // { label: "Alulod", mun_code: "042110" },
  // { label: "Banaba Cerca", mun_code: "042110" },
  // { label: "Banaba Lejos", mun_code: "042110" },
  // { label: "Bancod", mun_code: "042110" },
  // { label: "Buna Cerca", mun_code: "042110" },
  // { label: "Buna Lejos I", mun_code: "042110" },
  // { label: "Calumpang Cerca", mun_code: "042110" },
  // { label: "Calumpang Lejos I", mun_code: "042110" },
  // { label: "Carasuchi", mun_code: "042110" },
  // { label: "Kayquit I", mun_code: "042110" },
  // { label: "Daine I", mun_code: "042110" },
  // { label: "Guyam Malaki", mun_code: "042110" },
  // { label: "Guyam Munti", mun_code: "042110" },
  // { label: "Harasan", mun_code: "042110" },
  // { label: "Kaytambog", mun_code: "042110" },
  // { label: "Limbon", mun_code: "042110" },
  // { label: "Lumampong Balagbag", mun_code: "042110" },
  // { label: "Lumampong Halayhay", mun_code: "042110" },
  // { label: "Mahabangkahoy Lejos", mun_code: "042110" },
  // { label: "Mahabangkahoy Cerca", mun_code: "042110" },
  // { label: "Barangay 1 (Pob.)", mun_code: "042110" },
  // { label: "Barangay 2 (Pob.)", mun_code: "042110" },
  // { label: "Barangay 3 (Pob.)", mun_code: "042110" },
  // { label: "Barangay 4 (Pob.)", mun_code: "042110" },
  // { label: "Pulo", mun_code: "042110" },
  // { label: "Tambo Balagbag", mun_code: "042110" },
  // { label: "Tambo Ilaya", mun_code: "042110" },
  // { label: "Tambo Malaki", mun_code: "042110" },
  // { label: "Tambo Kulit", mun_code: "042110" },
  // { label: "Buna Lejos II", mun_code: "042110" },
  // { label: "Daine II", mun_code: "042110" },
  // { label: "Kayquit II", mun_code: "042110" },
  // { label: "Kayquit III", mun_code: "042110" },
  // { label: "Kaytapos", mun_code: "042110" },
  // { label: "Mataas na Lupa (Checkpoint)", mun_code: "042110" },
  // { label: "Binakayan-Kanluran", mun_code: "042111" },
  // { label: "Gahak", mun_code: "042111" },
  // { label: "Kaingen", mun_code: "042111" },
  // { label: "Marulas", mun_code: "042111" },
  // { label: "Panamitan", mun_code: "042111" },
  // { label: "Poblacion", mun_code: "042111" },
  // { label: "Magdalo (Putol)", mun_code: "042111" },
  // { label: "San Sebastian", mun_code: "042111" },
  // { label: "Santa Isabel", mun_code: "042111" },
  // { label: "Tabon I", mun_code: "042111" },
  // { label: "Toclong", mun_code: "042111" },
  // { label: "Wakas I", mun_code: "042111" },
  // { label: "Batong Dalig", mun_code: "042111" },
  // { label: "Balsahan-Bisita", mun_code: "042111" },
  // { label: "Binakayan-Aplaya", mun_code: "042111" },
  // { label: "Congbalay-Legaspi", mun_code: "042111" },
  // { label: "Manggahan-Lawin", mun_code: "042111" },
  // { label: "Pulvorista", mun_code: "042111" },
  // { label: "Samala-Marquez", mun_code: "042111" },
  // { label: "Tabon II", mun_code: "042111" },
  // { label: "Tabon III", mun_code: "042111" },
  // { label: "Tramo-Bantayan", mun_code: "042111" },
  // { label: "Wakas II", mun_code: "042111" },
  // { label: "Baliwag", mun_code: "042112" },
  // { label: "Bendita I", mun_code: "042112" },
  // { label: "Caluangan", mun_code: "042112" },
  // { label: "Medina", mun_code: "042112" },
  // { label: "Pacheco", mun_code: "042112" },
  // { label: "Barangay 1 (Pob.)", mun_code: "042112" },
  // { label: "Barangay 2 (Pob.)", mun_code: "042112" },
  // { label: "Barangay 3 (Pob.)", mun_code: "042112" },
  // { label: "Barangay 4 (Pob.)", mun_code: "042112" },
  // { label: "Barangay 5 (Pob.)", mun_code: "042112" },
  // { label: "Ramirez", mun_code: "042112" },
  // { label: "Tua", mun_code: "042112" },
  // { label: "Urdaneta", mun_code: "042112" },
  // { label: "Kabulusan", mun_code: "042112" },
  // { label: "Bendita II", mun_code: "042112" },
  // { label: "San Agustin", mun_code: "042112" },
  // { label: "Caingin Pob. (Barangay 3)", mun_code: "042113" },
  // { label: "Bucal I", mun_code: "042113" },
  // { label: "Bucal II", mun_code: "042113" },
  // { label: "Bucal III A", mun_code: "042113" },
  // { label: "Bucal IV A", mun_code: "042113" },
  // { label: "San Miguel I A (Caputatan)", mun_code: "042113" },
  // { label: "Garita I A", mun_code: "042113" },
  // { label: "Mabato", mun_code: "042113" },
  // { label: "Talipusngo", mun_code: "042113" },
  // { label: "Pantihan I (Balayungan)", mun_code: "042113" },
  // { label: "Pantihan II (Sagbat)", mun_code: "042113" },
  // { label: "Pantihan III (Pook na Munti)", mun_code: "042113" },
  // { label: "Pantihan IV (Pook ni Sara)", mun_code: "042113" },
  // { label: "Patungan", mun_code: "042113" },
  // { label: "Pinagsanhan I A", mun_code: "042113" },
  // { label: "Poblacion I A", mun_code: "042113" },
  // { label: "Poblacion II A", mun_code: "042113" },
  // { label: "Tulay Silangan", mun_code: "042113" },
  // { label: "Layong Mabilog", mun_code: "042113" },
  // { label: "Bucal III B", mun_code: "042113" },
  // { label: "Bucal IV B", mun_code: "042113" },
  // { label: "Garita I B", mun_code: "042113" },
  // { label: "Pinagsanhan I B", mun_code: "042113" },
  // { label: "Poblacion I B", mun_code: "042113" },
  // { label: "Poblacion II B", mun_code: "042113" },
  // { label: "San Miguel I B", mun_code: "042113" },
  // { label: "Tulay Kanluran", mun_code: "042113" },
  // { label: "Anuling Lejos I (Anuling)", mun_code: "042114" },
  // { label: "Asis I", mun_code: "042114" },
  // { label: "Galicia I", mun_code: "042114" },
  // { label: "Palocpoc I", mun_code: "042114" },
  // { label: "Panungyan I", mun_code: "042114" },
  // { label: "Poblacion I (Barangay I)", mun_code: "042114" },
  // { label: "Poblacion II (Barangay II)", mun_code: "042114" },
  // { label: "Poblacion III (Barangay III)", mun_code: "042114" },
  // { label: "Poblacion IV (Barangay IV)", mun_code: "042114" },
  // { label: "Poblacion V (Barangay V)", mun_code: "042114" },
  // { label: "Poblacion VI (Barangay VI)", mun_code: "042114" },
  // { label: "Poblacion VII (Barangay VII)", mun_code: "042114" },
  // { label: "Anuling Cerca I", mun_code: "042114" },
  // { label: "Anuling Cerca II", mun_code: "042114" },
  // { label: "Anuling Lejos II", mun_code: "042114" },
  // { label: "Asis II", mun_code: "042114" },
  // { label: "Asis III", mun_code: "042114" },
  // { label: "Banayad", mun_code: "042114" },
  // { label: "Bukal", mun_code: "042114" },
  // { label: "Galicia II", mun_code: "042114" },
  // { label: "Galicia III", mun_code: "042114" },
  // { label: "Miguel Mojica", mun_code: "042114" },
  // { label: "Palocpoc II", mun_code: "042114" },
  // { label: "Panungyan II", mun_code: "042114" },
  // { label: "Bagong Karsada", mun_code: "042115" },
  // { label: "Balsahan", mun_code: "042115" },
  // { label: "Bancaan", mun_code: "042115" },
  // { label: "Bucana Malaki", mun_code: "042115" },
  // { label: "Bucana Sasahan", mun_code: "042115" },
  // { label: "Capt. C. Nazareno (Pob.)", mun_code: "042115" },
  // { label: "Calubcob", mun_code: "042115" },
  // { label: "Palangue 2 & 3", mun_code: "042115" },
  // { label: "Gomez-Zamora (Pob.)", mun_code: "042115" },
  // { label: "Halang", mun_code: "042115" },
  // { label: "Humbac", mun_code: "042115" },
  // { label: "Ibayo Estacion", mun_code: "042115" },
  // { label: "Ibayo Silangan", mun_code: "042115" },
  // { label: "Kanluran", mun_code: "042115" },
  // { label: "Labac", mun_code: "042115" },
  // { label: "Latoria", mun_code: "042115" },
  // { label: "Mabolo", mun_code: "042115" },
  // { label: "Makina", mun_code: "042115" },
  // { label: "Malainen Bago", mun_code: "042115" },
  // { label: "Malainen Luma", mun_code: "042115" },
  // { label: "Molino", mun_code: "042115" },
  // { label: "Munting Mapino", mun_code: "042115" },
  // { label: "Muzon", mun_code: "042115" },
  // { label: "Palangue 1", mun_code: "042115" },
  // { label: "Sabang", mun_code: "042115" },
  // { label: "San Roque", mun_code: "042115" },
  // { label: "Santulan", mun_code: "042115" },
  // { label: "Sapa", mun_code: "042115" },
  // { label: "Timalan Balsahan", mun_code: "042115" },
  // { label: "Timalan Concepcion", mun_code: "042115" },
  // { label: "Magdiwang", mun_code: "042116" },
  // { label: "Poblacion", mun_code: "042116" },
  // { label: "Salcedo I", mun_code: "042116" },
  // { label: "San Antonio I", mun_code: "042116" },
  // { label: "San Juan I", mun_code: "042116" },
  // { label: "San Rafael I", mun_code: "042116" },
  // { label: "San Rafael II", mun_code: "042116" },
  // { label: "San Jose I", mun_code: "042116" },
  // { label: "Santa Rosa I", mun_code: "042116" },
  // { label: "Salcedo II", mun_code: "042116" },
  // { label: "San Antonio II", mun_code: "042116" },
  // { label: "San Jose II", mun_code: "042116" },
  // { label: "San Juan II", mun_code: "042116" },
  // { label: "San Rafael III", mun_code: "042116" },
  // { label: "San Rafael IV", mun_code: "042116" },
  // { label: "Santa Rosa II", mun_code: "042116" },
  // { label: "Silangan I", mun_code: "042117" },
  // { label: "Bagbag I", mun_code: "042117" },
  // { label: "Kanluran", mun_code: "042117" },
  // { label: "Ligtong I", mun_code: "042117" },
  // { label: "Ligtong II", mun_code: "042117" },
  // { label: "Muzon I", mun_code: "042117" },
  // { label: "Poblacion", mun_code: "042117" },
  // { label: "Sapa I", mun_code: "042117" },
  // { label: "Tejeros Convention", mun_code: "042117" },
  // { label: "Wawa I", mun_code: "042117" },
  // { label: "Ligtong III", mun_code: "042117" },
  // { label: "Bagbag II", mun_code: "042117" },
  // { label: "Ligtong IV", mun_code: "042117" },
  // { label: "Muzon II", mun_code: "042117" },
  // { label: "Sapa II", mun_code: "042117" },
  // { label: "Sapa III", mun_code: "042117" },
  // { label: "Sapa IV", mun_code: "042117" },
  // { label: "Silangan II", mun_code: "042117" },
  // { label: "Wawa II", mun_code: "042117" },
  // { label: "Wawa III", mun_code: "042117" },
  // { label: "Adlas", mun_code: "042118" },
  // { label: "Balite I", mun_code: "042118" },
  // { label: "Balite II", mun_code: "042118" },
  // { label: "Balubad", mun_code: "042118" },
  // { label: "Batas", mun_code: "042118" },
  // { label: "Biga I", mun_code: "042118" },
  // { label: "Biluso", mun_code: "042118" },
  // { label: "Buho", mun_code: "042118" },
  // { label: "Bucal", mun_code: "042118" },
  // { label: "Bulihan", mun_code: "042118" },
  // { label: "Cabangaan", mun_code: "042118" },
  // { label: "Carmen", mun_code: "042118" },
  // { label: "Hukay", mun_code: "042118" },
  // { label: "Iba", mun_code: "042118" },
  // { label: "Inchican", mun_code: "042118" },
  // { label: "Kalubkob", mun_code: "042118" },
  // { label: "Kaong", mun_code: "042118" },
  // { label: "Lalaan I", mun_code: "042118" },
  // { label: "Lalaan II", mun_code: "042118" },
  // { label: "Litlit", mun_code: "042118" },
  // { label: "Lucsuhin", mun_code: "042118" },
  // { label: "Lumil", mun_code: "042118" },
  // { label: "Maguyam", mun_code: "042118" },
  // { label: "Malabag", mun_code: "042118" },
  // { label: "Mataas Na Burol", mun_code: "042118" },
  // { label: "Munting Ilog", mun_code: "042118" },
  // { label: "Paligawan", mun_code: "042118" },
  // { label: "Pasong Langka", mun_code: "042118" },
  // { label: "Barangay I (Pob.)", mun_code: "042118" },
  // { label: "Barangay II (Pob.)", mun_code: "042118" },
  // { label: "Barangay III (Pob.)", mun_code: "042118" },
  // { label: "Barangay IV (Pob.)", mun_code: "042118" },
  // { label: "Barangay V (Pob.)", mun_code: "042118" },
  // { label: "Pooc I", mun_code: "042118" },
  // { label: "Pulong Bunga", mun_code: "042118" },
  // { label: "Pulong Saging", mun_code: "042118" },
  // { label: "Puting Kahoy", mun_code: "042118" },
  // { label: "Sabutan", mun_code: "042118" },
  // { label: "San Miguel I", mun_code: "042118" },
  // { label: "San Vicente I", mun_code: "042118" },
  // { label: "Santol", mun_code: "042118" },
  // { label: "Tartaria", mun_code: "042118" },
  // { label: "Tibig", mun_code: "042118" },
  // { label: "Tubuan I", mun_code: "042118" },
  // { label: "Ulat", mun_code: "042118" },
  // { label: "Acacia", mun_code: "042118" },
  // { label: "Anahaw I", mun_code: "042118" },
  // { label: "Ipil I", mun_code: "042118" },
  // { label: "Narra I", mun_code: "042118" },
  // { label: "Yakal", mun_code: "042118" },
  // { label: "Anahaw II", mun_code: "042118" },
  // { label: "Banaba", mun_code: "042118" },
  // { label: "Biga II", mun_code: "042118" },
  // { label: "Hoyo", mun_code: "042118" },
  // { label: "Ipil II", mun_code: "042118" },
  // { label: "Malaking Tatyao", mun_code: "042118" },
  // { label: "Narra II", mun_code: "042118" },
  // { label: "Narra III", mun_code: "042118" },
  // { label: "Pooc II", mun_code: "042118" },
  // { label: "San Miguel II", mun_code: "042118" },
  // { label: "San Vicente II", mun_code: "042118" },
  // { label: "Toledo", mun_code: "042118" },
  // { label: "Tubuan II", mun_code: "042118" },
  // { label: "Tubuan III", mun_code: "042118" },
  // { label: "Asisan", mun_code: "042119" },
  // { label: "Bagong Tubig", mun_code: "042119" },
  // { label: "Dapdap West", mun_code: "042119" },
  // { label: "Francisco (San Francisco)", mun_code: "042119" },
  // { label: "Guinhawa South", mun_code: "042119" },
  // { label: "Iruhin West", mun_code: "042119" },
  // { label: "Calabuso (Calabuso South & North)", mun_code: "042119" },
  // { label: "Kaybagal South (Pob.)", mun_code: "042119" },
  // { label: "Mag-Asawang Ilat", mun_code: "042119" },
  // { label: "Maharlika West", mun_code: "042119" },
  // { label: "Maitim 2nd East", mun_code: "042119" },
  // { label: "Mendez Crossing West", mun_code: "042119" },
  // { label: "Neogan", mun_code: "042119" },
  // { label: "Patutong Malaki South", mun_code: "042119" },
  // { label: "Sambong", mun_code: "042119" },
  // { label: "San Jose", mun_code: "042119" },
  // { label: "Silang Junction South", mun_code: "042119" },
  // { label: "Sungay South", mun_code: "042119" },
  // { label: "Tolentino West", mun_code: "042119" },
  // { label: "Zambal", mun_code: "042119" },
  // { label: "Iruhin East", mun_code: "042119" },
  // { label: "Kaybagal North", mun_code: "042119" },
  // { label: "Maitim 2nd West", mun_code: "042119" },
  // { label: "Dapdap East", mun_code: "042119" },
  // { label: "Guinhawa North", mun_code: "042119" },
  // { label: "Iruhin South", mun_code: "042119" },
  // { label: "Kaybagal East", mun_code: "042119" },
  // { label: "Maharlika East", mun_code: "042119" },
  // { label: "Maitim 2nd Central", mun_code: "042119" },
  // { label: "Mendez Crossing East", mun_code: "042119" },
  // { label: "Patutong Malaki North", mun_code: "042119" },
  // { label: "Silang Junction North", mun_code: "042119" },
  // { label: "Sungay North", mun_code: "042119" },
  // { label: "Tolentino East", mun_code: "042119" },
  // { label: "Amaya I", mun_code: "042120" },
  // { label: "Bagtas", mun_code: "042120" },
  // { label: "Biga", mun_code: "042120" },
  // { label: "Biwas", mun_code: "042120" },
  // { label: "Bucal", mun_code: "042120" },
  // { label: "Bunga", mun_code: "042120" },
  // { label: "Calibuyo", mun_code: "042120" },
  // { label: "Capipisa", mun_code: "042120" },
  // { label: "Daang Amaya I", mun_code: "042120" },
  // { label: "Halayhay", mun_code: "042120" },
  // { label: "Julugan I", mun_code: "042120" },
  // { label: "Mulawin", mun_code: "042120" },
  // { label: "Paradahan I", mun_code: "042120" },
  // { label: "Barangay I (Pob.)", mun_code: "042120" },
  // { label: "Barangay II (Pob.)", mun_code: "042120" },
  // { label: "Barangay III (Pob.)", mun_code: "042120" },
  // { label: "Barangay IV (Pob.)", mun_code: "042120" },
  // { label: "Punta I", mun_code: "042120" },
  // { label: "Sahud Ulan", mun_code: "042120" },
  // { label: "Sanja Mayor", mun_code: "042120" },
  // { label: "Santol", mun_code: "042120" },
  // { label: "Tanauan", mun_code: "042120" },
  // { label: "Tres Cruses", mun_code: "042120" },
  // { label: "Lambingan", mun_code: "042120" },
  // { label: "Amaya II", mun_code: "042120" },
  // { label: "Amaya III", mun_code: "042120" },
  // { label: "Amaya IV", mun_code: "042120" },
  // { label: "Amaya V", mun_code: "042120" },
  // { label: "Amaya VI", mun_code: "042120" },
  // { label: "Amaya VII", mun_code: "042120" },
  // { label: "Daang Amaya II", mun_code: "042120" },
  // { label: "Daang Amaya III", mun_code: "042120" },
  // { label: "Julugan II", mun_code: "042120" },
  // { label: "Julugan III", mun_code: "042120" },
  // { label: "Julugan IV", mun_code: "042120" },
  // { label: "Julugan V", mun_code: "042120" },
  // { label: "Julugan VI", mun_code: "042120" },
  // { label: "Julugan VII", mun_code: "042120" },
  // { label: "Julugan VIII", mun_code: "042120" },
  // { label: "Paradahan II", mun_code: "042120" },
  // { label: "Punta II", mun_code: "042120" },
  // { label: "Poblacion I (Barangay I)", mun_code: "042121" },
  // { label: "Poblacion II (Barangay II)", mun_code: "042121" },
  // { label: "Bucana", mun_code: "042121" },
  // { label: "Poblacion III (Barangay III)", mun_code: "042121" },
  // { label: "San Jose", mun_code: "042121" },
  // { label: "San Juan I", mun_code: "042121" },
  // { label: "Sapang I", mun_code: "042121" },
  // { label: "Poblacion I A", mun_code: "042121" },
  // { label: "San Juan II", mun_code: "042121" },
  // { label: "Sapang II", mun_code: "042121" },
  // { label: "Cabezas", mun_code: "042122" },
  // { label: "Cabuco", mun_code: "042122" },
  // { label: "De Ocampo", mun_code: "042122" },
  // { label: "Lallana", mun_code: "042122" },
  // { label: "San Agustin (Pob.)", mun_code: "042122" },
  // { label: "Osorio", mun_code: "042122" },
  // { label: "Conchu (Lagundian)", mun_code: "042122" },
  // { label: "Perez (Lucbanan)", mun_code: "042122" },
  // { label: "Aguado (Piscal Mundo)", mun_code: "042122" },
  // { label: "Gregorio (Aliang)", mun_code: "042122" },
  // { label: "Inocencio (B. Pook)", mun_code: "042122" },
  // { label: "Lapidario (Bayog)", mun_code: "042122" },
  // { label: "Luciano (Bitangan)", mun_code: "042122" },
  // { label: "Aldiano Olaes", mun_code: "042123" },
  // { label: "Barangay 1 Poblacion (Area I)", mun_code: "042123" },
  // { label: "Barangay 2 Poblacion", mun_code: "042123" },
  // { label: "Barangay 3 Poblacion", mun_code: "042123" },
  // { label: "Barangay 4 Poblacion", mun_code: "042123" },
  // { label: "Barangay 5 Poblacion", mun_code: "042123" },
  // { label: "Benjamin Tirona (Area D)", mun_code: "042123" },
  // { label: "Bernardo Pulido (Area H)", mun_code: "042123" },
  // { label: "Epifanio Malia", mun_code: "042123" },
  // { label: "Francisco De Castro (Sunshine Vill.)", mun_code: "042123" },
  // { label: "Francisco Reyes", mun_code: "042123" },
  // { label: "Fiorello Carimag (Area C)", mun_code: "042123" },
  // { label: "Gavino Maderan", mun_code: "042123" },
  // { label: "Gregoria De Jesus", mun_code: "042123" },
  // { label: "Inocencio Salud", mun_code: "042123" },
  // { label: "Jacinto Lumbreras", mun_code: "042123" },
  // { label: "Kapitan Kua (Area F)", mun_code: "042123" },
  // { label: "Koronel Jose P. Elises (Area E)", mun_code: "042123" },
  // { label: "Macario Dacon", mun_code: "042123" },
  // { label: "Marcelino Memije", mun_code: "042123" },
  // { label: "Nicolasa Virata (San Jose)", mun_code: "042123" },
  // { label: "Pantaleon Granados (Area G)", mun_code: "042123" },
  // { label: "Ramon Cruz (Area J)", mun_code: "042123" },
  // { label: "San Gabriel (Area K)", mun_code: "042123" },
  // { label: "San Jose", mun_code: "042123" },
  // { label: "Severino De Las Alas", mun_code: "042123" },
  // { label: "Tiniente Tiago", mun_code: "042123" },
  // { label: "Del Carmen", mun_code: "043401" },
  // { label: "Palma", mun_code: "043401" },
  // { label: "Barangay I (Pob.)", mun_code: "043401" },
  // { label: "Barangay II (Pob.)", mun_code: "043401" },
  // { label: "Barangay III (Pob.)", mun_code: "043401" },
  // { label: "Barangay IV (Pob.)", mun_code: "043401" },
  // { label: "San Agustin", mun_code: "043401" },
  // { label: "San Andres", mun_code: "043401" },
  // { label: "San Benito", mun_code: "043401" },
  // { label: "San Gregorio", mun_code: "043401" },
  // { label: "San Ildefonso", mun_code: "043401" },
  // { label: "San Juan", mun_code: "043401" },
  // { label: "San Miguel", mun_code: "043401" },
  // { label: "San Roque", mun_code: "043401" },
  // { label: "Santa Rosa", mun_code: "043401" },
  // { label: "Bitin", mun_code: "043402" },
  // { label: "Calo", mun_code: "043402" },
  // { label: "Dila", mun_code: "043402" },
  // { label: "Maitim", mun_code: "043402" },
  // { label: "Masaya", mun_code: "043402" },
  // { label: "Paciano Rizal", mun_code: "043402" },
  // { label: "Puypuy", mun_code: "043402" },
  // { label: "San Antonio", mun_code: "043402" },
  // { label: "San Isidro", mun_code: "043402" },
  // { label: "Santa Cruz", mun_code: "043402" },
  // { label: "Santo Domingo", mun_code: "043402" },
  // { label: "Tagumpay", mun_code: "043402" },
  // { label: "Tranca", mun_code: "043402" },
  // { label: "San Agustin (Pob.)", mun_code: "043402" },
  // { label: "San Nicolas (Pob.)", mun_code: "043402" },
  // { label: "Bungahan", mun_code: "043403" },
  // { label: "Santo Tomas (Calabuso)", mun_code: "043403" },
  // { label: "Canlalay", mun_code: "043403" },
  // { label: "Casile", mun_code: "043403" },
  // { label: "De La Paz", mun_code: "043403" },
  // { label: "Ganado", mun_code: "043403" },
  // { label: "San Francisco (Halang)", mun_code: "043403" },
  // { label: "Langkiwa", mun_code: "043403" },
  // { label: "Loma", mun_code: "043403" },
  // { label: "Malaban", mun_code: "043403" },
  // { label: "Malamig", mun_code: "043403" },
  // { label: "Mampalasan", mun_code: "043403" },
  // { label: "Platero", mun_code: "043403" },
  // { label: "Poblacion", mun_code: "043403" },
  // { label: "San Antonio", mun_code: "043403" },
  // { label: "San Jose", mun_code: "043403" },
  // { label: "San Vicente", mun_code: "043403" },
  // { label: "Soro-soro", mun_code: "043403" },
  // { label: "Santo Domingo", mun_code: "043403" },
  // { label: "Timbao", mun_code: "043403" },
  // { label: "Tubigan", mun_code: "043403" },
  // { label: "Zapote", mun_code: "043403" },
  // { label: "Baclaran", mun_code: "043404" },
  // { label: "Banaybanay", mun_code: "043404" },
  // { label: "Banlic", mun_code: "043404" },
  // { label: "Butong", mun_code: "043404" },
  // { label: "Bigaa", mun_code: "043404" },
  // { label: "Casile", mun_code: "043404" },
  // { label: "Gulod", mun_code: "043404" },
  // { label: "Mamatid", mun_code: "043404" },
  // { label: "Marinig", mun_code: "043404" },
  // { label: "Niugan", mun_code: "043404" },
  // { label: "Pittland", mun_code: "043404" },
  // { label: "Pulo", mun_code: "043404" },
  // { label: "Sala", mun_code: "043404" },
  // { label: "San Isidro", mun_code: "043404" },
  // { label: "Diezmo", mun_code: "043404" },
  // { label: "Barangay Uno (Pob.)", mun_code: "043404" },
  // { label: "Barangay Dos (Pob.)", mun_code: "043404" },
  // { label: "Barangay Tres (Pob.)", mun_code: "043404" },
  // { label: "Bagong Kalsada", mun_code: "043405" },
  // { label: "Banadero", mun_code: "043405" },
  // { label: "Banlic", mun_code: "043405" },
  // { label: "Barandal", mun_code: "043405" },
  // { label: "Bubuyan", mun_code: "043405" },
  // { label: "Bucal", mun_code: "043405" },
  // { label: "Bunggo", mun_code: "043405" },
  // { label: "Burol", mun_code: "043405" },
  // { label: "Camaligan", mun_code: "043405" },
  // { label: "Canlubang", mun_code: "043405" },
  // { label: "Halang", mun_code: "043405" },
  // { label: "Hornalan", mun_code: "043405" },
  // { label: "Kay-Anlog", mun_code: "043405" },
  // { label: "Laguerta", mun_code: "043405" },
  // { label: "La Mesa", mun_code: "043405" },
  // { label: "Lawa", mun_code: "043405" },
  // { label: "Lecheria", mun_code: "043405" },
  // { label: "Lingga", mun_code: "043405" },
  // { label: "Looc", mun_code: "043405" },
  // { label: "Mabato", mun_code: "043405" },
  // { label: "Makiling", mun_code: "043405" },
  // { label: "Mapagong", mun_code: "043405" },
  // { label: "Masili", mun_code: "043405" },
  // { label: "Maunong", mun_code: "043405" },
  // { label: "Mayapa", mun_code: "043405" },
  // { label: "Paciano Rizal", mun_code: "043405" },
  // { label: "Palingon", mun_code: "043405" },
  // { label: "Palo-Alto", mun_code: "043405" },
  // { label: "Pansol", mun_code: "043405" },
  // { label: "Parian", mun_code: "043405" },
  // { label: "Barangay 1 (Pob.)", mun_code: "043405" },
  // { label: "Barangay 2 (Pob.)", mun_code: "043405" },
  // { label: "Barangay 3 (Pob.)", mun_code: "043405" },
  // { label: "Barangay 4 (Pob.)", mun_code: "043405" },
  // { label: "Barangay 5 (Pob.)", mun_code: "043405" },
  // { label: "Barangay 6 (Pob.)", mun_code: "043405" },
  // { label: "Barangay 7 (Pob.)", mun_code: "043405" },
  // { label: "Prinza", mun_code: "043405" },
  // { label: "Punta", mun_code: "043405" },
  // { label: "Puting Lupa", mun_code: "043405" },
  // { label: "Real", mun_code: "043405" },
  // { label: "Sucol", mun_code: "043405" },
  // { label: "Saimsim", mun_code: "043405" },
  // { label: "Sampiruhan", mun_code: "043405" },
  // { label: "San Cristobal", mun_code: "043405" },
  // { label: "San Jose", mun_code: "043405" },
  // { label: "San Juan", mun_code: "043405" },
  // { label: "Sirang Lupa", mun_code: "043405" },
  // { label: "Milagrosa (Tulo)", mun_code: "043405" },
  // { label: "Turbina", mun_code: "043405" },
  // { label: "Ulango", mun_code: "043405" },
  // { label: "Uwisan", mun_code: "043405" },
  // { label: "Batino", mun_code: "043405" },
  // { label: "Majada Labas", mun_code: "043405" },
  // { label: "Balayhangin", mun_code: "043406" },
  // { label: "Bangyas", mun_code: "043406" },
  // { label: "Dayap", mun_code: "043406" },
  // { label: "Hanggan", mun_code: "043406" },
  // { label: "Imok", mun_code: "043406" },
  // { label: "Lamot 1", mun_code: "043406" },
  // { label: "Lamot 2", mun_code: "043406" },
  // { label: "Limao", mun_code: "043406" },
  // { label: "Mabacan", mun_code: "043406" },
  // { label: "Masiit", mun_code: "043406" },
  // { label: "Paliparan", mun_code: "043406" },
  // { label: "Perez", mun_code: "043406" },
  // { label: "Kanluran (Pob.)", mun_code: "043406" },
  // { label: "Silangan (Pob.)", mun_code: "043406" },
  // { label: "Prinza", mun_code: "043406" },
  // { label: "San Isidro", mun_code: "043406" },
  // { label: "Santo Tomas", mun_code: "043406" },
  // { label: "Anglas", mun_code: "043407" },
  // { label: "Bangco", mun_code: "043407" },
  // { label: "Bukal", mun_code: "043407" },
  // { label: "Bulajo", mun_code: "043407" },
  // { label: "Cansuso", mun_code: "043407" },
  // { label: "Duhat", mun_code: "043407" },
  // { label: "Inao-Awan", mun_code: "043407" },
  // { label: "Kanluran Talaongan", mun_code: "043407" },
  // { label: "Labayo", mun_code: "043407" },
  // { label: "Layasin", mun_code: "043407" },
  // { label: "Layug", mun_code: "043407" },
  // { label: "Mahipon", mun_code: "043407" },
  // { label: "Paowin", mun_code: "043407" },
  // { label: "Poblacion", mun_code: "043407" },
  // { label: "Sisilmin", mun_code: "043407" },
  // { label: "Silangan Talaongan", mun_code: "043407" },
  // { label: "Sumucab", mun_code: "043407" },
  // { label: "Tibatib", mun_code: "043407" },
  // { label: "Udia", mun_code: "043407" },
  // { label: "Asana (Pob.)", mun_code: "043408" },
  // { label: "Bacong-Sigsigan", mun_code: "043408" },
  // { label: "Bagong Pag-Asa (Pob.)", mun_code: "043408" },
  // { label: "Balitoc", mun_code: "043408" },
  // { label: "Banaba (Pob.)", mun_code: "043408" },
  // { label: "Batuhan", mun_code: "043408" },
  // { label: "Bulihan", mun_code: "043408" },
  // { label: "Caballero (Pob.)", mun_code: "043408" },
  // { label: "Calumpang (Pob.)", mun_code: "043408" },
  // { label: "Kapatalan", mun_code: "043408" },
  // { label: "Cuebang Bato", mun_code: "043408" },
  // { label: "Damayan (Pob.)", mun_code: "043408" },
  // { label: "Kataypuanan", mun_code: "043408" },
  // { label: "Liyang", mun_code: "043408" },
  // { label: "Maate", mun_code: "043408" },
  // { label: "Magdalo (Pob.)", mun_code: "043408" },
  // { label: "Mayatba", mun_code: "043408" },
  // { label: "Minayutan", mun_code: "043408" },
  // { label: "Salangbato", mun_code: "043408" },
  // { label: "Tunhac", mun_code: "043408" },
  // { label: "Longos", mun_code: "043409" },
  // { label: "San Antonio", mun_code: "043409" },
  // { label: "San Juan (Pob.)", mun_code: "043409" },
  // { label: "Bagong Anyo (Pob.)", mun_code: "043410" },
  // { label: "Bayate", mun_code: "043410" },
  // { label: "Bubukal", mun_code: "043410" },
  // { label: "Bongkol", mun_code: "043410" },
  // { label: "Cabuyao", mun_code: "043410" },
  // { label: "Calumpang", mun_code: "043410" },
  // { label: "Culoy", mun_code: "043410" },
  // { label: "Dagatan", mun_code: "043410" },
  // { label: "Daniw (Danliw)", mun_code: "043410" },
  // { label: "Dita", mun_code: "043410" },
  // { label: "Ibabang Palina", mun_code: "043410" },
  // { label: "Ibabang San Roque", mun_code: "043410" },
  // { label: "Ibabang Sungi", mun_code: "043410" },
  // { label: "Ibabang Taykin", mun_code: "043410" },
  // { label: "Ilayang Palina", mun_code: "043410" },
  // { label: "Ilayang San Roque", mun_code: "043410" },
  // { label: "Ilayang Sungi", mun_code: "043410" },
  // { label: "Ilayang Taykin", mun_code: "043410" },
  // { label: "Kanlurang Bukal", mun_code: "043410" },
  // { label: "Laguan", mun_code: "043410" },
  // { label: "Rizal (Pob.)", mun_code: "043410" },
  // { label: "Luquin", mun_code: "043410" },
  // { label: "Malabo-Kalantukan", mun_code: "043410" },
  // { label: "Masikap (Pob.)", mun_code: "043410" },
  // { label: "Maslun (Pob.)", mun_code: "043410" },
  // { label: "Mojon", mun_code: "043410" },
  // { label: "Novaliches", mun_code: "043410" },
  // { label: "Oples", mun_code: "043410" },
  // { label: "Pag-Asa (Pob.)", mun_code: "043410" },
  // { label: "Palayan", mun_code: "043410" },
  // { label: "San Isidro", mun_code: "043410" },
  // { label: "Silangang Bukal", mun_code: "043410" },
  // { label: "Tuy-Baanan", mun_code: "043410" },
  // { label: "Anos", mun_code: "043411" },
  // { label: "Bagong Silang", mun_code: "043411" },
  // { label: "Bambang", mun_code: "043411" },
  // { label: "Batong Malake", mun_code: "043411" },
  // { label: "Baybayin (Pob.)", mun_code: "043411" },
  // { label: "Bayog", mun_code: "043411" },
  // { label: "Lalakay", mun_code: "043411" },
  // { label: "Maahas", mun_code: "043411" },
  // { label: "Mayondon", mun_code: "043411" },
  // { label: "Putho Tuntungin", mun_code: "043411" },
  // { label: "San Antonio", mun_code: "043411" },
  // { label: "Tadlak", mun_code: "043411" },
  // { label: "Timugan (Pob.)", mun_code: "043411" },
  // { label: "Malinta", mun_code: "043411" },
  // { label: "De La Paz", mun_code: "043412" },
  // { label: "Barangay Zone I (Pob.)", mun_code: "043412" },
  // { label: "Barangay Zone II (Pob.)", mun_code: "043412" },
  // { label: "Barangay Zone III (Pob.)", mun_code: "043412" },
  // { label: "Barangay Zone IV (Pob.)", mun_code: "043412" },
  // { label: "Barangay Zone V (Pob.)", mun_code: "043412" },
  // { label: "Barangay Zone VI (Pob.)", mun_code: "043412" },
  // { label: "Barangay Zone VII (Pob.)", mun_code: "043412" },
  // { label: "Barangay Zone VIII (Pob.)", mun_code: "043412" },
  // { label: "San Antonio", mun_code: "043412" },
  // { label: "San Buenaventura", mun_code: "043412" },
  // { label: "San Diego", mun_code: "043412" },
  // { label: "San Isidro", mun_code: "043412" },
  // { label: "San Jose", mun_code: "043412" },
  // { label: "San Juan", mun_code: "043412" },
  // { label: "San Luis", mun_code: "043412" },
  // { label: "San Pablo", mun_code: "043412" },
  // { label: "San Pedro", mun_code: "043412" },
  // { label: "San Rafael", mun_code: "043412" },
  // { label: "San Roque", mun_code: "043412" },
  // { label: "San Salvador", mun_code: "043412" },
  // { label: "Santo Domingo", mun_code: "043412" },
  // { label: "Santo Tomas", mun_code: "043412" },
  // { label: "Bagong Silang", mun_code: "043413" },
  // { label: "Balimbingan (Pob.)", mun_code: "043413" },
  // { label: "Balubad", mun_code: "043413" },
  // { label: "Caliraya", mun_code: "043413" },
  // { label: "Concepcion", mun_code: "043413" },
  // { label: "Lewin", mun_code: "043413" },
  // { label: "Maracta (Pob.)", mun_code: "043413" },
  // { label: "Maytalang I", mun_code: "043413" },
  // { label: "Maytalang II", mun_code: "043413" },
  // { label: "Primera Parang (Pob.)", mun_code: "043413" },
  // { label: "Primera Pulo (Pob.)", mun_code: "043413" },
  // { label: "Salac (Pob.)", mun_code: "043413" },
  // { label: "Segunda Parang (Pob.)", mun_code: "043413" },
  // { label: "Segunda Pulo (Pob.)", mun_code: "043413" },
  // { label: "Wawa", mun_code: "043413" },
  // { label: "Amuyong", mun_code: "043414" },
  // { label: "Lambac (Pob.)", mun_code: "043414" },
  // { label: "Lucong", mun_code: "043414" },
  // { label: "Matalatala", mun_code: "043414" },
  // { label: "Nanguma", mun_code: "043414" },
  // { label: "Numero", mun_code: "043414" },
  // { label: "Paagahan", mun_code: "043414" },
  // { label: "Bayanihan (Pob.)", mun_code: "043414" },
  // { label: "Libis ng Nayon (Pob.)", mun_code: "043414" },
  // { label: "Maligaya (Pob.)", mun_code: "043414" },
  // { label: "Masikap (Pob.)", mun_code: "043414" },
  // { label: "Pag-Asa (Pob.)", mun_code: "043414" },
  // { label: "Sinagtala (Pob.)", mun_code: "043414" },
  // { label: "San Antonio", mun_code: "043414" },
  // { label: "San Miguel", mun_code: "043414" },
  // { label: "Alipit", mun_code: "043415" },
  // { label: "Malaking Ambling", mun_code: "043415" },
  // { label: "Munting Ambling", mun_code: "043415" },
  // { label: "Baanan", mun_code: "043415" },
  // { label: "Balanac", mun_code: "043415" },
  // { label: "Bucal", mun_code: "043415" },
  // { label: "Buenavista", mun_code: "043415" },
  // { label: "Bungkol", mun_code: "043415" },
  // { label: "Buo", mun_code: "043415" },
  // { label: "Burlungan", mun_code: "043415" },
  // { label: "Cigaras", mun_code: "043415" },
  // { label: "Ibabang Atingay", mun_code: "043415" },
  // { label: "Ibabang Butnong", mun_code: "043415" },
  // { label: "Ilayang Atingay", mun_code: "043415" },
  // { label: "Ilayang Butnong", mun_code: "043415" },
  // { label: "Ilog", mun_code: "043415" },
  // { label: "Malinao", mun_code: "043415" },
  // { label: "Maravilla", mun_code: "043415" },
  // { label: "Poblacion", mun_code: "043415" },
  // { label: "Sabang", mun_code: "043415" },
  // { label: "Salasad", mun_code: "043415" },
  // { label: "Tanawan", mun_code: "043415" },
  // { label: "Tipunan", mun_code: "043415" },
  // { label: "Halayhayin", mun_code: "043415" },
  // { label: "Amonoy", mun_code: "043416" },
  // { label: "Bakia", mun_code: "043416" },
  // { label: "Bukal", mun_code: "043416" },
  // { label: "Balanac", mun_code: "043416" },
  // { label: "Balayong", mun_code: "043416" },
  // { label: "Banilad", mun_code: "043416" },
  // { label: "Banti", mun_code: "043416" },
  // { label: "Bitaoy", mun_code: "043416" },
  // { label: "Botocan", mun_code: "043416" },
  // { label: "Burgos", mun_code: "043416" },
  // { label: "Burol", mun_code: "043416" },
  // { label: "Coralao", mun_code: "043416" },
  // { label: "Gagalot", mun_code: "043416" },
  // { label: "Ibabang Banga", mun_code: "043416" },
  // { label: "Ibabang Bayucain", mun_code: "043416" },
  // { label: "Ilayang Banga", mun_code: "043416" },
  // { label: "Ilayang Bayucain", mun_code: "043416" },
  // { label: "Isabang", mun_code: "043416" },
  // { label: "Malinao", mun_code: "043416" },
  // { label: "May-It", mun_code: "043416" },
  // { label: "Munting Kawayan", mun_code: "043416" },
  // { label: "Oobi", mun_code: "043416" },
  // { label: "Olla", mun_code: "043416" },
  // { label: "Origuel (Pob.)", mun_code: "043416" },
  // { label: "Panalaban", mun_code: "043416" },
  // { label: "Panglan", mun_code: "043416" },
  // { label: "Pangil", mun_code: "043416" },
  // { label: "Piit", mun_code: "043416" },
  // { label: "Pook", mun_code: "043416" },
  // { label: "Rizal", mun_code: "043416" },
  // { label: "San Francisco (Pob.)", mun_code: "043416" },
  // { label: "San Isidro", mun_code: "043416" },
  // { label: "San Miguel (Pob.)", mun_code: "043416" },
  // { label: "San Roque", mun_code: "043416" },
  // { label: "Santa Catalina (Pob.)", mun_code: "043416" },
  // { label: "Suba", mun_code: "043416" },
  // { label: "Tanawan", mun_code: "043416" },
  // { label: "Taytay", mun_code: "043416" },
  // { label: "Talortor", mun_code: "043416" },
  // { label: "Villa Nogales", mun_code: "043416" },
  // { label: "Abo", mun_code: "043417" },
  // { label: "Alibungbungan", mun_code: "043417" },
  // { label: "Alumbrado", mun_code: "043417" },
  // { label: "Balayong", mun_code: "043417" },
  // { label: "Balimbing", mun_code: "043417" },
  // { label: "Balinacon", mun_code: "043417" },
  // { label: "Bambang", mun_code: "043417" },
  // { label: "Banago", mun_code: "043417" },
  // { label: "Banca-banca", mun_code: "043417" },
  // { label: "Bangcuro", mun_code: "043417" },
  // { label: "Banilad", mun_code: "043417" },
  // { label: "Bayaquitos", mun_code: "043417" },
  // { label: "Buboy", mun_code: "043417" },
  // { label: "Buenavista", mun_code: "043417" },
  // { label: "Buhanginan", mun_code: "043417" },
  // { label: "Bukal", mun_code: "043417" },
  // { label: "Bunga", mun_code: "043417" },
  // { label: "Cabuyew", mun_code: "043417" },
  // { label: "Calumpang", mun_code: "043417" },
  // { label: "Kanluran Kabubuhayan", mun_code: "043417" },
  // { label: "Silangan Kabubuhayan", mun_code: "043417" },
  // { label: "Labangan", mun_code: "043417" },
  // { label: "Lawaguin", mun_code: "043417" },
  // { label: "Kanluran Lazaan", mun_code: "043417" },
  // { label: "Silangan Lazaan", mun_code: "043417" },
  // { label: "Lagulo", mun_code: "043417" },
  // { label: "Maiit", mun_code: "043417" },
  // { label: "Malaya", mun_code: "043417" },
  // { label: "Malinao", mun_code: "043417" },
  // { label: "Manaol", mun_code: "043417" },
  // { label: "Maravilla", mun_code: "043417" },
  // { label: "Nagcalbang", mun_code: "043417" },
  // { label: "Poblacion I (Pob.)", mun_code: "043417" },
  // { label: "Poblacion II (Pob.)", mun_code: "043417" },
  // { label: "Poblacion III (Pob.)", mun_code: "043417" },
  // { label: "Oples", mun_code: "043417" },
  // { label: "Palayan", mun_code: "043417" },
  // { label: "Palina", mun_code: "043417" },
  // { label: "Sabang", mun_code: "043417" },
  // { label: "San Francisco", mun_code: "043417" },
  // { label: "Sibulan", mun_code: "043417" },
  // { label: "Silangan Napapatid", mun_code: "043417" },
  // { label: "Silangan Ilaya", mun_code: "043417" },
  // { label: "Sinipian", mun_code: "043417" },
  // { label: "Santa Lucia", mun_code: "043417" },
  // { label: "Sulsuguin", mun_code: "043417" },
  // { label: "Talahib", mun_code: "043417" },
  // { label: "Talangan", mun_code: "043417" },
  // { label: "Taytay", mun_code: "043417" },
  // { label: "Tipacan", mun_code: "043417" },
  // { label: "Wakat", mun_code: "043417" },
  // { label: "Yukos", mun_code: "043417" },
  // { label: "Bagumbayan (Pob.)", mun_code: "043418" },
  // { label: "Bangkusay (Pob.)", mun_code: "043418" },
  // { label: "Ermita (Pob.)", mun_code: "043418" },
  // { label: "Ibaba del Norte (Pob.)", mun_code: "043418" },
  // { label: "Ibaba del Sur (Pob.)", mun_code: "043418" },
  // { label: "Ilaya del Norte (Pob.)", mun_code: "043418" },
  // { label: "Ilaya del Sur (Pob.)", mun_code: "043418" },
  // { label: "Maytoong (Pob.)", mun_code: "043418" },
  // { label: "Quinale (Pob.)", mun_code: "043418" },
  // { label: "Anibong", mun_code: "043419" },
  // { label: "Buboy", mun_code: "043419" },
  // { label: "Cabanbanan", mun_code: "043419" },
  // { label: "Calusiche", mun_code: "043419" },
  // { label: "Dingin", mun_code: "043419" },
  // { label: "Lambac", mun_code: "043419" },
  // { label: "Layugan", mun_code: "043419" },
  // { label: "Magdapio", mun_code: "043419" },
  // { label: "Maulawin", mun_code: "043419" },
  // { label: "Pinagsanjan", mun_code: "043419" },
  // { label: "Barangay I (Pob.)", mun_code: "043419" },
  // { label: "Barangay II (Pob.)", mun_code: "043419" },
  // { label: "Sabang", mun_code: "043419" },
  // { label: "Sampaloc", mun_code: "043419" },
  // { label: "San Isidro", mun_code: "043419" },
  // { label: "Banilan", mun_code: "043420" },
  // { label: "Burgos (Pob.)", mun_code: "043420" },
  // { label: "Casa Real", mun_code: "043420" },
  // { label: "Casinsin", mun_code: "043420" },
  // { label: "Dorado", mun_code: "043420" },
  // { label: "Gonzales (Pob.)", mun_code: "043420" },
  // { label: "Kabulusan", mun_code: "043420" },
  // { label: "Matikiw", mun_code: "043420" },
  // { label: "Rizal (Pob.)", mun_code: "043420" },
  // { label: "Saray", mun_code: "043420" },
  // { label: "Taft (Pob.)", mun_code: "043420" },
  // { label: "Tavera (Pob.)", mun_code: "043420" },
  // { label: "Balian", mun_code: "043421" },
  // { label: "Dambo", mun_code: "043421" },
  // { label: "Galalan", mun_code: "043421" },
  // { label: "Isla (Pob.)", mun_code: "043421" },
  // { label: "Mabato-Azufre", mun_code: "043421" },
  // { label: "Natividad (Pob.)", mun_code: "043421" },
  // { label: "San Jose (Pob.)", mun_code: "043421" },
  // { label: "Sulib", mun_code: "043421" },
  // { label: "Aplaya", mun_code: "043422" },
  // { label: "Bagong Pook", mun_code: "043422" },
  // { label: "Bukal", mun_code: "043422" },
  // { label: "Bulilan Norte (Pob.)", mun_code: "043422" },
  // { label: "Bulilan Sur (Pob.)", mun_code: "043422" },
  // { label: "Concepcion", mun_code: "043422" },
  // { label: "Labuin", mun_code: "043422" },
  // { label: "Linga", mun_code: "043422" },
  // { label: "Masico", mun_code: "043422" },
  // { label: "Mojon", mun_code: "043422" },
  // { label: "Pansol", mun_code: "043422" },
  // { label: "Pinagbayanan", mun_code: "043422" },
  // { label: "San Antonio", mun_code: "043422" },
  // { label: "San Miguel", mun_code: "043422" },
  // { label: "Santa Clara Norte (Pob.)", mun_code: "043422" },
  // { label: "Santa Clara Sur (Pob.)", mun_code: "043422" },
  // { label: "Tubuan", mun_code: "043422" },
  // { label: "Antipolo", mun_code: "043423" },
  // { label: "Entablado", mun_code: "043423" },
  // { label: "Laguan", mun_code: "043423" },
  // { label: "Paule 1", mun_code: "043423" },
  // { label: "Paule 2", mun_code: "043423" },
  // { label: "East Poblacion", mun_code: "043423" },
  // { label: "West Poblacion", mun_code: "043423" },
  // { label: "Pook", mun_code: "043423" },
  // { label: "Tala", mun_code: "043423" },
  // { label: "Talaga", mun_code: "043423" },
  // { label: "Tuy", mun_code: "043423" },
  // { label: "Bagong Bayan II-A (Pob.)", mun_code: "043424" },
  // { label: "Bagong Pook VI-C (Pob.)", mun_code: "043424" },
  // { label: "Barangay I-A (Pob.)", mun_code: "043424" },
  // { label: "Barangay I-B (Pob.)", mun_code: "043424" },
  // { label: "Barangay II-A (Pob.)", mun_code: "043424" },
  // { label: "Barangay II-B (Pob.)", mun_code: "043424" },
  // { label: "Barangay II-C (Pob.)", mun_code: "043424" },
  // { label: "Barangay II-D (Pob.)", mun_code: "043424" },
  // { label: "Barangay II-E (Pob.)", mun_code: "043424" },
  // { label: "Barangay II-F (Pob.)", mun_code: "043424" },
  // { label: "Barangay III-A (Pob.)", mun_code: "043424" },
  // { label: "Barangay III-B (Pob.)", mun_code: "043424" },
  // { label: "Barangay III-C (Pob.)", mun_code: "043424" },
  // { label: "Barangay III-D (Pob.)", mun_code: "043424" },
  // { label: "Barangay III-E (Pob.)", mun_code: "043424" },
  // { label: "Barangay III-F (Pob.)", mun_code: "043424" },
  // { label: "Barangay IV-A (Pob.)", mun_code: "043424" },
  // { label: "Barangay IV-B (Pob.)", mun_code: "043424" },
  // { label: "Barangay IV-C (Pob.)", mun_code: "043424" },
  // { label: "Barangay V-A (Pob.)", mun_code: "043424" },
  // { label: "Barangay V-B (Pob.)", mun_code: "043424" },
  // { label: "Barangay V-C (Pob.)", mun_code: "043424" },
  // { label: "Barangay V-D (Pob.)", mun_code: "043424" },
  // { label: "Barangay VI-A (Pob.)", mun_code: "043424" },
  // { label: "Barangay VI-B (Pob.)", mun_code: "043424" },
  // { label: "Barangay VI-D (Pob.)", mun_code: "043424" },
  // { label: "Barangay VI-E (Pob.)", mun_code: "043424" },
  // { label: "Barangay VII-A (Pob.)", mun_code: "043424" },
  // { label: "Barangay VII-B (Pob.)", mun_code: "043424" },
  // { label: "Barangay VII-C (Pob.)", mun_code: "043424" },
  // { label: "Barangay VII-D (Pob.)", mun_code: "043424" },
  // { label: "Barangay VII-E (Pob.)", mun_code: "043424" },
  // { label: "Bautista", mun_code: "043424" },
  // { label: "Concepcion", mun_code: "043424" },
  // { label: "Del Remedio", mun_code: "043424" },
  // { label: "Dolores", mun_code: "043424" },
  // { label: "San Antonio 1", mun_code: "043424" },
  // { label: "San Antonio 2", mun_code: "043424" },
  // { label: "San Bartolome", mun_code: "043424" },
  // { label: "San Buenaventura", mun_code: "043424" },
  // { label: "San Crispin", mun_code: "043424" },
  // { label: "San Cristobal", mun_code: "043424" },
  // { label: "San Diego", mun_code: "043424" },
  // { label: "San Francisco", mun_code: "043424" },
  // { label: "San Gabriel", mun_code: "043424" },
  // { label: "San Gregorio", mun_code: "043424" },
  // { label: "San Ignacio", mun_code: "043424" },
  // { label: "San Isidro", mun_code: "043424" },
  // { label: "San Joaquin", mun_code: "043424" },
  // { label: "San Jose", mun_code: "043424" },
  // { label: "San Juan", mun_code: "043424" },
  // { label: "San Lorenzo", mun_code: "043424" },
  // { label: "San Lucas 1", mun_code: "043424" },
  // { label: "San Lucas 2", mun_code: "043424" },
  // { label: "San Marcos", mun_code: "043424" },
  // { label: "San Mateo", mun_code: "043424" },
  // { label: "San Miguel", mun_code: "043424" },
  // { label: "San Nicolas", mun_code: "043424" },
  // { label: "San Pedro", mun_code: "043424" },
  // { label: "San Rafael", mun_code: "043424" },
  // { label: "San Roque", mun_code: "043424" },
  // { label: "San Vicente", mun_code: "043424" },
  // { label: "Santa Ana", mun_code: "043424" },
  // { label: "Santa Catalina", mun_code: "043424" },
  // { label: "Santa Cruz", mun_code: "043424" },
  // { label: "Santa Felomina", mun_code: "043424" },
  // { label: "Santa Isabel", mun_code: "043424" },
  // { label: "Santa Maria Magdalena", mun_code: "043424" },
  // { label: "Santa Veronica", mun_code: "043424" },
  // { label: "Santiago I", mun_code: "043424" },
  // { label: "Santiago II", mun_code: "043424" },
  // { label: "Santisimo Rosario", mun_code: "043424" },
  // { label: "Santo Angel", mun_code: "043424" },
  // { label: "Santo Cristo", mun_code: "043424" },
  // { label: "Soledad", mun_code: "043424" },
  // { label: "Atisan", mun_code: "043424" },
  // { label: "Santa Elena", mun_code: "043424" },
  // { label: "Santa Maria", mun_code: "043424" },
  // { label: "Santa Monica", mun_code: "043424" },
  // { label: "Bagong Silang", mun_code: "043425" },
  // { label: "Cuyab", mun_code: "043425" },
  // { label: "Estrella", mun_code: "043425" },
  // { label: "G.S.I.S.", mun_code: "043425" },
  // { label: "Landayan", mun_code: "043425" },
  // { label: "Langgam", mun_code: "043425" },
  // { label: "Laram", mun_code: "043425" },
  // { label: "Magsaysay", mun_code: "043425" },
  // { label: "Nueva", mun_code: "043425" },
  // { label: "Poblacion", mun_code: "043425" },
  // { label: "Riverside", mun_code: "043425" },
  // { label: "San Antonio", mun_code: "043425" },
  // { label: "San Roque", mun_code: "043425" },
  // { label: "San Vicente", mun_code: "043425" },
  // { label: "United Bayanihan", mun_code: "043425" },
  // { label: "United Better Living", mun_code: "043425" },
  // { label: "Sampaguita Village", mun_code: "043425" },
  // { label: "Calendola", mun_code: "043425" },
  // { label: "Narra", mun_code: "043425" },
  // { label: "Alipit", mun_code: "043426" },
  // { label: "Bagumbayan", mun_code: "043426" },
  // { label: "Bubukal", mun_code: "043426" },
  // { label: "Calios", mun_code: "043426" },
  // { label: "Duhat", mun_code: "043426" },
  // { label: "Gatid", mun_code: "043426" },
  // { label: "Jasaan", mun_code: "043426" },
  // { label: "Labuin", mun_code: "043426" },
  // { label: "Malinao", mun_code: "043426" },
  // { label: "Oogong", mun_code: "043426" },
  // { label: "Pagsawitan", mun_code: "043426" },
  // { label: "Palasan", mun_code: "043426" },
  // { label: "Patimbao", mun_code: "043426" },
  // { label: "Barangay I (Pob.)", mun_code: "043426" },
  // { label: "Barangay II (Pob.)", mun_code: "043426" },
  // { label: "Barangay III (Pob.)", mun_code: "043426" },
  // { label: "Barangay IV (Pob.)", mun_code: "043426" },
  // { label: "Barangay V (Pob.)", mun_code: "043426" },
  // { label: "San Jose", mun_code: "043426" },
  // { label: "San Juan", mun_code: "043426" },
  // { label: "San Pablo Norte", mun_code: "043426" },
  // { label: "San Pablo Sur", mun_code: "043426" },
  // { label: "Santisima Cruz", mun_code: "043426" },
  // { label: "Santo Angel Central", mun_code: "043426" },
  // { label: "Santo Angel Norte", mun_code: "043426" },
  // { label: "Santo Angel Sur", mun_code: "043426" },
  // { label: "Adia", mun_code: "043427" },
  // { label: "Bagong Pook", mun_code: "043427" },
  // { label: "Bagumbayan", mun_code: "043427" },
  // { label: "Bubukal", mun_code: "043427" },
  // { label: "Cabooan", mun_code: "043427" },
  // { label: "Calangay", mun_code: "043427" },
  // { label: "Cambuja", mun_code: "043427" },
  // { label: "Coralan", mun_code: "043427" },
  // { label: "Cueva", mun_code: "043427" },
  // { label: "Inayapan", mun_code: "043427" },
  // { label: "Jose Laurel, Sr.", mun_code: "043427" },
  // { label: "Kayhakat", mun_code: "043427" },
  // { label: "Macasipac", mun_code: "043427" },
  // { label: "Masinao", mun_code: "043427" },
  // { label: "Mataling-Ting", mun_code: "043427" },
  // { label: "Pao-o", mun_code: "043427" },
  // { label: "Parang Ng Buho", mun_code: "043427" },
  // { label: "Barangay I (Pob.)", mun_code: "043427" },
  // { label: "Barangay II (Pob.)", mun_code: "043427" },
  // { label: "Barangay III (Pob.)", mun_code: "043427" },
  // { label: "Barangay IV (Pob.)", mun_code: "043427" },
  // { label: "Jose Rizal", mun_code: "043427" },
  // { label: "Santiago", mun_code: "043427" },
  // { label: "Talangka", mun_code: "043427" },
  // { label: "Tungkod", mun_code: "043427" },
  // { label: "Aplaya", mun_code: "043428" },
  // { label: "Balibago", mun_code: "043428" },
  // { label: "Caingin", mun_code: "043428" },
  // { label: "Dila", mun_code: "043428" },
  // { label: "Dita", mun_code: "043428" },
  // { label: "Don Jose", mun_code: "043428" },
  // { label: "Ibaba", mun_code: "043428" },
  // { label: "Labas", mun_code: "043428" },
  // { label: "Macabling", mun_code: "043428" },
  // { label: "Malitlit", mun_code: "043428" },
  // { label: "Malusak (Pob.)", mun_code: "043428" },
  // { label: "Market Area (Pob.)", mun_code: "043428" },
  // { label: "Kanluran (Pob.)", mun_code: "043428" },
  // { label: "Pook", mun_code: "043428" },
  // { label: "Pulong Santa Cruz", mun_code: "043428" },
  // { label: "Santo Domingo", mun_code: "043428" },
  // { label: "Sinalhan", mun_code: "043428" },
  // { label: "Tagapo", mun_code: "043428" },
  // { label: "Acevida", mun_code: "043429" },
  // { label: "Bagong Pag-Asa (Pob.)", mun_code: "043429" },
  // { label: "Bagumbarangay (Pob.)", mun_code: "043429" },
  // { label: "Buhay", mun_code: "043429" },
  // { label: "Gen. Luna", mun_code: "043429" },
  // { label: "Halayhayin", mun_code: "043429" },
  // { label: "Mendiola", mun_code: "043429" },
  // { label: "Kapatalan", mun_code: "043429" },
  // { label: "Laguio", mun_code: "043429" },
  // { label: "Liyang", mun_code: "043429" },
  // { label: "Llavac", mun_code: "043429" },
  // { label: "Pandeno", mun_code: "043429" },
  // { label: "Magsaysay", mun_code: "043429" },
  // { label: "Macatad", mun_code: "043429" },
  // { label: "Mayatba", mun_code: "043429" },
  // { label: "P. Burgos", mun_code: "043429" },
  // { label: "G. Redor (Pob.)", mun_code: "043429" },
  // { label: "Salubungan", mun_code: "043429" },
  // { label: "Wawa", mun_code: "043429" },
  // { label: "J. Rizal (Pob.)", mun_code: "043429" },
  // { label: "Banca-banca", mun_code: "043430" },
  // { label: "Daniw", mun_code: "043430" },
  // { label: "Masapang", mun_code: "043430" },
  // { label: "Nanhaya (Pob.)", mun_code: "043430" },
  // { label: "Pagalangan", mun_code: "043430" },
  // { label: "San Benito", mun_code: "043430" },
  // { label: "San Felix", mun_code: "043430" },
  // { label: "San Francisco", mun_code: "043430" },
  // { label: "San Roque (Pob.)", mun_code: "043430" },
  // { label: "Binagbag", mun_code: "045601" },
  // { label: "Dayap", mun_code: "045601" },
  // { label: "Ibabang Kinagunan", mun_code: "045601" },
  // { label: "Ilayang Kinagunan", mun_code: "045601" },
  // { label: "Kanlurang Calutan", mun_code: "045601" },
  // { label: "Kanlurang Maligaya", mun_code: "045601" },
  // { label: "Salvacion", mun_code: "045601" },
  // { label: "Silangang Calutan", mun_code: "045601" },
  // { label: "Silangang Maligaya", mun_code: "045601" },
  // { label: "Sildora", mun_code: "045601" },
  // { label: "Poblacion I", mun_code: "045601" },
  // { label: "Poblacion II", mun_code: "045601" },
  // { label: "Angeles", mun_code: "045602" },
  // { label: "Bacong", mun_code: "045602" },
  // { label: "Balungay", mun_code: "045602" },
  // { label: "Buenavista", mun_code: "045602" },
  // { label: "Caglate", mun_code: "045602" },
  // { label: "Camagong", mun_code: "045602" },
  // { label: "Gordon", mun_code: "045602" },
  // { label: "Pambilan Norte", mun_code: "045602" },
  // { label: "Pambilan Sur", mun_code: "045602" },
  // { label: "Barangay 1 (Pob.)", mun_code: "045602" },
  // { label: "Barangay 2 (Pob.)", mun_code: "045602" },
  // { label: "Barangay 3 (Pob.)", mun_code: "045602" },
  // { label: "Barangay 4 (Pob.)", mun_code: "045602" },
  // { label: "Barangay 5 (Pob.)", mun_code: "045602" },
  // { label: "Villa Esperanza", mun_code: "045602" },
  // { label: "Villa Jesus Este", mun_code: "045602" },
  // { label: "Villa Jesus Weste", mun_code: "045602" },
  // { label: "Villa Norte", mun_code: "045602" },
  // { label: "Villa Victoria", mun_code: "045602" },
  // { label: "Angeles", mun_code: "045603" },
  // { label: "Balubad", mun_code: "045603" },
  // { label: "Balugohin", mun_code: "045603" },
  // { label: "Barangay Zone 1 (Pob.)", mun_code: "045603" },
  // { label: "Barangay Zone 2 (Pob.)", mun_code: "045603" },
  // { label: "Barangay Zone 3 (Pob.)", mun_code: "045603" },
  // { label: "Barangay Zone 4 (Pob.)", mun_code: "045603" },
  // { label: "Buhangin", mun_code: "045603" },
  // { label: "Caridad Ibaba", mun_code: "045603" },
  // { label: "Caridad Ilaya", mun_code: "045603" },
  // { label: "Habingan", mun_code: "045603" },
  // { label: "Inaclagan", mun_code: "045603" },
  // { label: "Inalig", mun_code: "045603" },
  // { label: "Kilait", mun_code: "045603" },
  // { label: "Kulawit", mun_code: "045603" },
  // { label: "Lakip", mun_code: "045603" },
  // { label: "Lubi", mun_code: "045603" },
  // { label: "Lumutan", mun_code: "045603" },
  // { label: "Magsaysay", mun_code: "045603" },
  // { label: "Malinao Ibaba", mun_code: "045603" },
  // { label: "Malinao Ilaya", mun_code: "045603" },
  // { label: "Malusak", mun_code: "045603" },
  // { label: "Manggalayan Bundok", mun_code: "045603" },
  // { label: "Manggalayan Labak", mun_code: "045603" },
  // { label: "Matanag", mun_code: "045603" },
  // { label: "Montes Balaon", mun_code: "045603" },
  // { label: "Montes Kallagan", mun_code: "045603" },
  // { label: "Ponon", mun_code: "045603" },
  // { label: "Rizal", mun_code: "045603" },
  // { label: "San Andres Bundok", mun_code: "045603" },
  // { label: "San Andres Labak", mun_code: "045603" },
  // { label: "San Isidro", mun_code: "045603" },
  // { label: "San Jose Balatok", mun_code: "045603" },
  // { label: "San Rafael", mun_code: "045603" },
  // { label: "Santa Catalina", mun_code: "045603" },
  // { label: "Sapaan", mun_code: "045603" },
  // { label: "Sokol", mun_code: "045603" },
  // { label: "Tagbakin", mun_code: "045603" },
  // { label: "Talaba", mun_code: "045603" },
  // { label: "Tinandog", mun_code: "045603" },
  // { label: "Villa Ibaba", mun_code: "045603" },
  // { label: "Villa Ilaya", mun_code: "045603" },
  // { label: "Bagong Silang", mun_code: "045605" },
  // { label: "Batabat Norte", mun_code: "045605" },
  // { label: "Batabat Sur", mun_code: "045605" },
  // { label: "Buenavista", mun_code: "045605" },
  // { label: "Bukal", mun_code: "045605" },
  // { label: "Bulo", mun_code: "045605" },
  // { label: "Cabong", mun_code: "045605" },
  // { label: "Cadlit", mun_code: "045605" },
  // { label: "Catulin", mun_code: "045605" },
  // { label: "Cawa", mun_code: "045605" },
  // { label: "De La Paz", mun_code: "045605" },
  // { label: "Del Rosario", mun_code: "045605" },
  // { label: "Hagonghong", mun_code: "045605" },
  // { label: "Ibabang Wasay", mun_code: "045605" },
  // { label: "Ilayang Wasay", mun_code: "045605" },
  // { label: "Lilukin", mun_code: "045605" },
  // { label: "Mabini", mun_code: "045605" },
  // { label: "Mabutag", mun_code: "045605" },
  // { label: "Magallanes", mun_code: "045605" },
  // { label: "Maligaya (Esperanza)", mun_code: "045605" },
  // { label: "Manlana", mun_code: "045605" },
  // { label: "Masaya", mun_code: "045605" },
  // { label: "Poblacion", mun_code: "045605" },
  // { label: "Rizal", mun_code: "045605" },
  // { label: "Sabang Pinamasagan", mun_code: "045605" },
  // { label: "Sabang Piris", mun_code: "045605" },
  // { label: "San Diego", mun_code: "045605" },
  // { label: "San Isidro Ibaba", mun_code: "045605" },
  // { label: "San Isidro Ilaya", mun_code: "045605" },
  // { label: "San Pablo", mun_code: "045605" },
  // { label: "San Pedro (Villa Rodrigo)", mun_code: "045605" },
  // { label: "San Vicente", mun_code: "045605" },
  // { label: "Siain", mun_code: "045605" },
  // { label: "Villa Aurora", mun_code: "045605" },
  // { label: "Villa Batabat", mun_code: "045605" },
  // { label: "Villa Magsaysay", mun_code: "045605" },
  // { label: "Villa Veronica", mun_code: "045605" },
  // { label: "Aluyon", mun_code: "045606" },
  // { label: "Amot", mun_code: "045606" },
  // { label: "Anibawan", mun_code: "045606" },
  // { label: "Bonifacio", mun_code: "045606" },
  // { label: "Cabugao", mun_code: "045606" },
  // { label: "Cabungalunan", mun_code: "045606" },
  // { label: "Calutcot", mun_code: "045606" },
  // { label: "Caniwan", mun_code: "045606" },
  // { label: "Carlagan", mun_code: "045606" },
  // { label: "Mabini", mun_code: "045606" },
  // { label: "Palasan", mun_code: "045606" },
  // { label: "Poblacion", mun_code: "045606" },
  // { label: "Rizal", mun_code: "045606" },
  // { label: "San Rafael", mun_code: "045606" },
  // { label: "Agoho", mun_code: "045607" },
  // { label: "Anahawan", mun_code: "045607" },
  // { label: "Anas", mun_code: "045607" },
  // { label: "Apad Lutao", mun_code: "045607" },
  // { label: "Apad Quezon", mun_code: "045607" },
  // { label: "Apad Taisan", mun_code: "045607" },
  // { label: "Atulayan", mun_code: "045607" },
  // { label: "Baclaran", mun_code: "045607" },
  // { label: "Bagong Silang", mun_code: "045607" },
  // { label: "Balibago", mun_code: "045607" },
  // { label: "Bangkuruhan", mun_code: "045607" },
  // { label: "Bantolinao", mun_code: "045607" },
  // { label: "Barangay I (Pob.)", mun_code: "045607" },
  // { label: "Barangay II (Pob.)", mun_code: "045607" },
  // { label: "Barangay III (Pob.)", mun_code: "045607" },
  // { label: "Barangay IV (Pob.)", mun_code: "045607" },
  // { label: "Barangay V (Pob.)", mun_code: "045607" },
  // { label: "Bigaan", mun_code: "045607" },
  // { label: "Binutas", mun_code: "045607" },
  // { label: "Biyan", mun_code: "045607" },
  // { label: "Bukal", mun_code: "045607" },
  // { label: "Buli", mun_code: "045607" },
  // { label: "Dapdap", mun_code: "045607" },
  // { label: "Dominlog", mun_code: "045607" },
  // { label: "Guinosayan", mun_code: "045607" },
  // { label: "Ipil", mun_code: "045607" },
  // { label: "Kalibo (Santa Cruz)", mun_code: "045607" },
  // { label: "Kapaluhan", mun_code: "045607" },
  // { label: "Katangtang", mun_code: "045607" },
  // { label: "Kigtan", mun_code: "045607" },
  // { label: "Kinamaligan", mun_code: "045607" },
  // { label: "Kinalin Ibaba", mun_code: "045607" },
  // { label: "Kinalin Ilaya", mun_code: "045607" },
  // { label: "Kumaludkud", mun_code: "045607" },
  // { label: "Kunalum", mun_code: "045607" },
  // { label: "Kuyaoyao", mun_code: "045607" },
  // { label: "Lagay", mun_code: "045607" },
  // { label: "Lainglaingan", mun_code: "045607" },
  // { label: "Lungib", mun_code: "045607" },
  // { label: "Mabini", mun_code: "045607" },
  // { label: "Madlangdungan", mun_code: "045607" },
  // { label: "Maglipad (Rosario)", mun_code: "045607" },
  // { label: "Maligaya", mun_code: "045607" },
  // { label: "Mambaling", mun_code: "045607" },
  // { label: "Manhulugin", mun_code: "045607" },
  // { label: "Marilag (Punaya)", mun_code: "045607" },
  // { label: "Mulay", mun_code: "045607" },
  // { label: "Pandanan", mun_code: "045607" },
  // { label: "Pansol", mun_code: "045607" },
  // { label: "Patihan", mun_code: "045607" },
  // { label: "Pinagbayanan", mun_code: "045607" },
  // { label: "Pinagkamaligan", mun_code: "045607" },
  // { label: "Pinagsakahan", mun_code: "045607" },
  // { label: "Pinagtalleran", mun_code: "045607" },
  // { label: "Rizal Ibaba", mun_code: "045607" },
  // { label: "Rizal Ilaya", mun_code: "045607" },
  // { label: "Sabang I", mun_code: "045607" },
  // { label: "Sabang II", mun_code: "045607" },
  // { label: "Salvacion", mun_code: "045607" },
  // { label: "San Quintin", mun_code: "045607" },
  // { label: "San Roque Ibaba", mun_code: "045607" },
  // { label: "San Roque Ilaya", mun_code: "045607" },
  // { label: "Santa Cecilia", mun_code: "045607" },
  // { label: "Santa Maria", mun_code: "045607" },
  // { label: "Santa Milagrosa", mun_code: "045607" },
  // { label: "Santa Rosa", mun_code: "045607" },
  // { label: "Santo Angel (Pangahoy)", mun_code: "045607" },
  // { label: "Santo Domingo", mun_code: "045607" },
  // { label: "Sinag", mun_code: "045607" },
  // { label: "Sumilang", mun_code: "045607" },
  // { label: "Sumulong", mun_code: "045607" },
  // { label: "Tabansak", mun_code: "045607" },
  // { label: "Talingting", mun_code: "045607" },
  // { label: "Tamis", mun_code: "045607" },
  // { label: "Tikiwan", mun_code: "045607" },
  // { label: "Tiniguiban", mun_code: "045607" },
  // { label: "Villa Magsino", mun_code: "045607" },
  // { label: "Villa San Isidro", mun_code: "045607" },
  // { label: "Yaganak", mun_code: "045607" },
  // { label: "Poblacion", mun_code: "045608" },
  // { label: "Buenavista East", mun_code: "045608" },
  // { label: "Buenavista West", mun_code: "045608" },
  // { label: "Bukal Norte", mun_code: "045608" },
  // { label: "Bukal Sur", mun_code: "045608" },
  // { label: "Kinatihan I", mun_code: "045608" },
  // { label: "Kinatihan II", mun_code: "045608" },
  // { label: "Malabanban Norte", mun_code: "045608" },
  // { label: "Malabanban Sur", mun_code: "045608" },
  // { label: "Mangilag Norte", mun_code: "045608" },
  // { label: "Mangilag Sur", mun_code: "045608" },
  // { label: "Masalukot I", mun_code: "045608" },
  // { label: "Masalukot II", mun_code: "045608" },
  // { label: "Masalukot III", mun_code: "045608" },
  // { label: "Masalukot IV", mun_code: "045608" },
  // { label: "Masin Norte", mun_code: "045608" },
  // { label: "Masin Sur", mun_code: "045608" },
  // { label: "Mayabobo", mun_code: "045608" },
  // { label: "Pahinga Norte", mun_code: "045608" },
  // { label: "Pahinga Sur", mun_code: "045608" },
  // { label: "San Andres", mun_code: "045608" },
  // { label: "San Isidro", mun_code: "045608" },
  // { label: "Santa Catalina Norte", mun_code: "045608" },
  // { label: "Santa Catalina Sur", mun_code: "045608" },
  // { label: "Masalukot V", mun_code: "045608" },
  // { label: "Ajos", mun_code: "045610" },
  // { label: "Anusan", mun_code: "045610" },
  // { label: "Barangay 1 (Pob.)", mun_code: "045610" },
  // { label: "Barangay 2 (Pob.)", mun_code: "045610" },
  // { label: "Barangay 3 (Pob.)", mun_code: "045610" },
  // { label: "Barangay 4 (Pob.)", mun_code: "045610" },
  // { label: "Barangay 5 (Pob.)", mun_code: "045610" },
  // { label: "Barangay 6 (Pob.)", mun_code: "045610" },
  // { label: "Barangay 7 (Pob.)", mun_code: "045610" },
  // { label: "Barangay 8 (Pob.)", mun_code: "045610" },
  // { label: "Barangay 9 (Pob.)", mun_code: "045610" },
  // { label: "Bolo", mun_code: "045610" },
  // { label: "Bulagsong", mun_code: "045610" },
  // { label: "Camandiison", mun_code: "045610" },
  // { label: "Canculajao", mun_code: "045610" },
  // { label: "Catumbo", mun_code: "045610" },
  // { label: "Cawayanin Ibaba", mun_code: "045610" },
  // { label: "Cawayanin Ilaya", mun_code: "045610" },
  // { label: "Cutcutan", mun_code: "045610" },
  // { label: "Dahican", mun_code: "045610" },
  // { label: "Doongan Ibaba", mun_code: "045610" },
  // { label: "Doongan Ilaya", mun_code: "045610" },
  // { label: "Gatasan", mun_code: "045610" },
  // { label: "Macpac", mun_code: "045610" },
  // { label: "Madulao", mun_code: "045610" },
  // { label: "Matandang Sabang Kanluran", mun_code: "045610" },
  // { label: "Matandang Sabang Silangan", mun_code: "045610" },
  // { label: "Milagrosa", mun_code: "045610" },
  // { label: "Navitas", mun_code: "045610" },
  // { label: "Pacabit", mun_code: "045610" },
  // { label: "San Antonio Magkupa", mun_code: "045610" },
  // { label: "San Antonio Pala", mun_code: "045610" },
  // { label: "San Isidro", mun_code: "045610" },
  // { label: "San Jose (Anyao)", mun_code: "045610" },
  // { label: "San Pablo (Suha)", mun_code: "045610" },
  // { label: "San Roque (Doyon)", mun_code: "045610" },
  // { label: "San Vicente Kanluran", mun_code: "045610" },
  // { label: "San Vicente Silangan", mun_code: "045610" },
  // { label: "Santa Maria (Dao)", mun_code: "045610" },
  // { label: "Tagabas Ibaba", mun_code: "045610" },
  // { label: "Tagabas Ilaya", mun_code: "045610" },
  // { label: "Tagbacan Ibaba", mun_code: "045610" },
  // { label: "Tagbacan Ilaya", mun_code: "045610" },
  // { label: "Tagbacan Silangan", mun_code: "045610" },
  // { label: "Tuhian", mun_code: "045610" },
  // { label: "Barangay 10 (Pob.)", mun_code: "045610" },
  // { label: "Antonino (Ayusan)", mun_code: "045615" },
  // { label: "Bagong Anyo (Pob.)", mun_code: "045615" },
  // { label: "Bayanihan (Pob.)", mun_code: "045615" },
  // { label: "Bulakin I", mun_code: "045615" },
  // { label: "Bungoy", mun_code: "045615" },
  // { label: "Cabatang", mun_code: "045615" },
  // { label: "Dagatan", mun_code: "045615" },
  // { label: "Kinabuhayan", mun_code: "045615" },
  // { label: "Maligaya (Pob.)", mun_code: "045615" },
  // { label: "Manggahan", mun_code: "045615" },
  // { label: "Pinagdanlayan", mun_code: "045615" },
  // { label: "Putol", mun_code: "045615" },
  // { label: "San Mateo", mun_code: "045615" },
  // { label: "Santa Lucia", mun_code: "045615" },
  // { label: "Silanganan (Pob.)", mun_code: "045615" },
  // { label: "Bulakin II", mun_code: "045615" },
  // { label: "Bacong Ibaba", mun_code: "045616" },
  // { label: "Bacong Ilaya", mun_code: "045616" },
  // { label: "Barangay 1 (Pob.)", mun_code: "045616" },
  // { label: "Barangay 2 (Pob.)", mun_code: "045616" },
  // { label: "Barangay 3 (Pob.)", mun_code: "045616" },
  // { label: "Barangay 4 (Pob.)", mun_code: "045616" },
  // { label: "Barangay 5 (Pob.)", mun_code: "045616" },
  // { label: "Barangay 6 (Pob.)", mun_code: "045616" },
  // { label: "Barangay 7 (Pob.)", mun_code: "045616" },
  // { label: "Barangay 8 (Pob.)", mun_code: "045616" },
  // { label: "Barangay 9 (Pob.)", mun_code: "045616" },
  // { label: "Lavides", mun_code: "045616" },
  // { label: "Magsaysay", mun_code: "045616" },
  // { label: "Malaya", mun_code: "045616" },
  // { label: "Nieva", mun_code: "045616" },
  // { label: "Recto", mun_code: "045616" },
  // { label: "San Ignacio Ibaba", mun_code: "045616" },
  // { label: "San Ignacio Ilaya", mun_code: "045616" },
  // { label: "San Isidro Ibaba", mun_code: "045616" },
  // { label: "San Isidro Ilaya", mun_code: "045616" },
  // { label: "San Jose", mun_code: "045616" },
  // { label: "San Nicolas", mun_code: "045616" },
  // { label: "San Vicente", mun_code: "045616" },
  // { label: "Santa Maria Ibaba", mun_code: "045616" },
  // { label: "Santa Maria Ilaya", mun_code: "045616" },
  // { label: "Sumilang", mun_code: "045616" },
  // { label: "Villarica", mun_code: "045616" },
  // { label: "Anoling", mun_code: "045617" },
  // { label: "Banglos", mun_code: "045617" },
  // { label: "Batangan", mun_code: "045617" },
  // { label: "Catablingan", mun_code: "045617" },
  // { label: "Canaway", mun_code: "045617" },
  // { label: "Lumutan", mun_code: "045617" },
  // { label: "Mahabang Lalim", mun_code: "045617" },
  // { label: "Maigang", mun_code: "045617" },
  // { label: "Maligaya", mun_code: "045617" },
  // { label: "Magsikap", mun_code: "045617" },
  // { label: "Minahan Norte", mun_code: "045617" },
  // { label: "Minahan Sur", mun_code: "045617" },
  // { label: "Pagsangahan", mun_code: "045617" },
  // { label: "Pamplona", mun_code: "045617" },
  // { label: "Pisa", mun_code: "045617" },
  // { label: "Poblacion", mun_code: "045617" },
  // { label: "Sablang", mun_code: "045617" },
  // { label: "San Marcelino", mun_code: "045617" },
  // { label: "Umiray", mun_code: "045617" },
  // { label: "A. Mabini", mun_code: "045618" },
  // { label: "Aloneros", mun_code: "045618" },
  // { label: "Arbismen", mun_code: "045618" },
  // { label: "Bagong Silang", mun_code: "045618" },
  // { label: "Balinarin", mun_code: "045618" },
  // { label: "Bukal Maligaya", mun_code: "045618" },
  // { label: "Cabibihan", mun_code: "045618" },
  // { label: "Cabong Norte", mun_code: "045618" },
  // { label: "Cabong Sur", mun_code: "045618" },
  // { label: "Calimpak", mun_code: "045618" },
  // { label: "Capuluan Central", mun_code: "045618" },
  // { label: "Capuluan Tulon", mun_code: "045618" },
  // { label: "Dancalan Caimawan", mun_code: "045618" },
  // { label: "Dancalan Central", mun_code: "045618" },
  // { label: "Danlagan Batis", mun_code: "045618" },
  // { label: "Danlagan Cabayao", mun_code: "045618" },
  // { label: "Danlagan Central", mun_code: "045618" },
  // { label: "Danlagan Reserva", mun_code: "045618" },
  // { label: "Del Rosario", mun_code: "045618" },
  // { label: "Dungawan Central", mun_code: "045618" },
  // { label: "Dungawan Paalyunan", mun_code: "045618" },
  // { label: "Dungawan Pantay", mun_code: "045618" },
  // { label: "Ermita", mun_code: "045618" },
  // { label: "Gapas", mun_code: "045618" },
  // { label: "Himbubulo Este", mun_code: "045618" },
  // { label: "Himbubulo Weste", mun_code: "045618" },
  // { label: "Hinabaan", mun_code: "045618" },
  // { label: "Ligpit Bantayan", mun_code: "045618" },
  // { label: "Lubigan", mun_code: "045618" },
  // { label: "Magallanes", mun_code: "045618" },
  // { label: "Magsaysay", mun_code: "045618" },
  // { label: "Manggagawa", mun_code: "045618" },
  // { label: "Manggalang", mun_code: "045618" },
  // { label: "Manlayo", mun_code: "045618" },
  // { label: "Poblacion", mun_code: "045618" },
  // { label: "Salakan", mun_code: "045618" },
  // { label: "San Antonio", mun_code: "045618" },
  // { label: "San Isidro", mun_code: "045618" },
  // { label: "San Jose", mun_code: "045618" },
  // { label: "San Lorenzo", mun_code: "045618" },
  // { label: "San Luis I", mun_code: "045618" },
  // { label: "San Luis II", mun_code: "045618" },
  // { label: "San Miguel", mun_code: "045618" },
  // { label: "San Pedro I", mun_code: "045618" },
  // { label: "San Pedro II", mun_code: "045618" },
  // { label: "San Roque", mun_code: "045618" },
  // { label: "Santa Cruz", mun_code: "045618" },
  // { label: "Santa Maria", mun_code: "045618" },
  // { label: "Santa Teresita", mun_code: "045618" },
  // { label: "Sintones", mun_code: "045618" },
  // { label: "Sisi", mun_code: "045618" },
  // { label: "Tikay", mun_code: "045618" },
  // { label: "Triumpo", mun_code: "045618" },
  // { label: "Villa Hiwasayan", mun_code: "045618" },
  // { label: "Adia Bitaog", mun_code: "045619" },
  // { label: "Anonangin", mun_code: "045619" },
  // { label: "Bagong Buhay (Pob.)", mun_code: "045619" },
  // { label: "Bamban", mun_code: "045619" },
  // { label: "Bantad", mun_code: "045619" },
  // { label: "Batong Dalig", mun_code: "045619" },
  // { label: "Biga", mun_code: "045619" },
  // { label: "Binambang", mun_code: "045619" },
  // { label: "Buensuceso", mun_code: "045619" },
  // { label: "Bungahan", mun_code: "045619" },
  // { label: "Butaguin", mun_code: "045619" },
  // { label: "Calumangin", mun_code: "045619" },
  // { label: "Camohaguin", mun_code: "045619" },
  // { label: "Casasahan Ibaba", mun_code: "045619" },
  // { label: "Casasahan Ilaya", mun_code: "045619" },
  // { label: "Cawayan", mun_code: "045619" },
  // { label: "Gayagayaan", mun_code: "045619" },
  // { label: "Gitnang Barrio", mun_code: "045619" },
  // { label: "Hardinan", mun_code: "045619" },
  // { label: "Inaclagan", mun_code: "045619" },
  // { label: "Inagbuhan Ilaya", mun_code: "045619" },
  // { label: "Hagakhakin", mun_code: "045619" },
  // { label: "Labnig", mun_code: "045619" },
  // { label: "Laguna", mun_code: "045619" },
  // { label: "Mabini (Pob.)", mun_code: "045619" },
  // { label: "Mabunga", mun_code: "045619" },
  // { label: "Malabtog", mun_code: "045619" },
  // { label: "Manlayaan", mun_code: "045619" },
  // { label: "Marcelo H. Del Pilar", mun_code: "045619" },
  // { label: "Mataas Na Bundok", mun_code: "045619" },
  // { label: "Maunlad (Pob.)", mun_code: "045619" },
  // { label: "Pagsabangan", mun_code: "045619" },
  // { label: "Panikihan", mun_code: "045619" },
  // { label: "Pipisik (Pob.)", mun_code: "045619" },
  // { label: "Progreso", mun_code: "045619" },
  // { label: "Rizal (Pob.)", mun_code: "045619" },
  // { label: "Rosario", mun_code: "045619" },
  // { label: "San Agustin", mun_code: "045619" },
  // { label: "San Diego Poblacion", mun_code: "045619" },
  // { label: "San Isidro Kanluran", mun_code: "045619" },
  // { label: "San Isidro Silangan", mun_code: "045619" },
  // { label: "San Juan De Jesus", mun_code: "045619" },
  // { label: "San Vicente", mun_code: "045619" },
  // { label: "Sastre", mun_code: "045619" },
  // { label: "Tabing Dagat (Pob.)", mun_code: "045619" },
  // { label: "Tumayan", mun_code: "045619" },
  // { label: "Villa Arcaya", mun_code: "045619" },
  // { label: "Villa Bota", mun_code: "045619" },
  // { label: "Villa Fuerte", mun_code: "045619" },
  // { label: "Villa Mendoza", mun_code: "045619" },
  // { label: "Villa Padua", mun_code: "045619" },
  // { label: "Villa Perez", mun_code: "045619" },
  // { label: "Villa M. Principe", mun_code: "045619" },
  // { label: "Villa Victoria", mun_code: "045619" },
  // { label: "San Diego", mun_code: "045619" },
  // { label: "Villa Nava", mun_code: "045619" },
  // { label: "Lagyo", mun_code: "045619" },
  // { label: "Abiawin", mun_code: "045620" },
  // { label: "Agos-agos", mun_code: "045620" },
  // { label: "Alitas", mun_code: "045620" },
  // { label: "Amolongin", mun_code: "045620" },
  // { label: "Anibong", mun_code: "045620" },
  // { label: "Antikin", mun_code: "045620" },
  // { label: "Bacong", mun_code: "045620" },
  // { label: "Balobo", mun_code: "045620" },
  // { label: "Bantilan", mun_code: "045620" },
  // { label: "Banugao", mun_code: "045620" },
  // { label: "Poblacion 1 (Barangay 1)", mun_code: "045620" },
  // { label: "Poblacion 38 (Poblacion Barangay 2)", mun_code: "045620" },
  // { label: "Poblacion 39 (Poblacion Barangay 3)", mun_code: "045620" },
  // { label: "Batican", mun_code: "045620" },
  // { label: "Binonoan", mun_code: "045620" },
  // { label: "Binulasan", mun_code: "045620" },
  // { label: "Boboin", mun_code: "045620" },
  // { label: "Comon", mun_code: "045620" },
  // { label: "Dinahican", mun_code: "045620" },
  // { label: "Gumian", mun_code: "045620" },
  // { label: "Ilog", mun_code: "045620" },
  // { label: "Ingas", mun_code: "045620" },
  // { label: "Catambungan", mun_code: "045620" },
  // { label: "Cawaynin", mun_code: "045620" },
  // { label: "Langgas", mun_code: "045620" },
  // { label: "Libjo", mun_code: "045620" },
  // { label: "Lual", mun_code: "045620" },
  // { label: "Magsaysay", mun_code: "045620" },
  // { label: "Maypulot", mun_code: "045620" },
  // { label: "Miswa", mun_code: "045620" },
  // { label: "Pilaway", mun_code: "045620" },
  // { label: "Pinaglapatan", mun_code: "045620" },
  // { label: "Pulo", mun_code: "045620" },
  // { label: "Silangan", mun_code: "045620" },
  // { label: "Tongohin", mun_code: "045620" },
  // { label: "Tudturan", mun_code: "045620" },
  // { label: "Bukal", mun_code: "045621" },
  // { label: "Casuguran", mun_code: "045621" },
  // { label: "Gango", mun_code: "045621" },
  // { label: "Talisoy (Pob.)", mun_code: "045621" },
  // { label: "Apad", mun_code: "045621" },
  // { label: "Bacungan", mun_code: "045622" },
  // { label: "Bagacay", mun_code: "045622" },
  // { label: "Banabahin Ibaba", mun_code: "045622" },
  // { label: "Banabahin Ilaya", mun_code: "045622" },
  // { label: "Burgos (Pob.)", mun_code: "045622" },
  // { label: "Gomez (Pob.)", mun_code: "045622" },
  // { label: "Magsaysay (Pob.)", mun_code: "045622" },
  // { label: "Talolong (Pob.)", mun_code: "045622" },
  // { label: "Bayabas", mun_code: "045622" },
  // { label: "Bebito", mun_code: "045622" },
  // { label: "Bigajo", mun_code: "045622" },
  // { label: "Binahian A", mun_code: "045622" },
  // { label: "Binahian B", mun_code: "045622" },
  // { label: "Binahian C", mun_code: "045622" },
  // { label: "Bocboc", mun_code: "045622" },
  // { label: "Buenavista", mun_code: "045622" },
  // { label: "Buyacanin", mun_code: "045622" },
  // { label: "Cagacag", mun_code: "045622" },
  // { label: "Calantipayan", mun_code: "045622" },
  // { label: "Canda Ibaba", mun_code: "045622" },
  // { label: "Canda Ilaya", mun_code: "045622" },
  // { label: "Cawayan", mun_code: "045622" },
  // { label: "Cawayanin", mun_code: "045622" },
  // { label: "Cogorin Ibaba", mun_code: "045622" },
  // { label: "Cogorin Ilaya", mun_code: "045622" },
  // { label: "Concepcion", mun_code: "045622" },
  // { label: "Danlagan", mun_code: "045622" },
  // { label: "De La Paz", mun_code: "045622" },
  // { label: "Del Pilar", mun_code: "045622" },
  // { label: "Del Rosario", mun_code: "045622" },
  // { label: "Esperanza Ibaba", mun_code: "045622" },
  // { label: "Esperanza Ilaya", mun_code: "045622" },
  // { label: "Guihay", mun_code: "045622" },
  // { label: "Guinuangan", mun_code: "045622" },
  // { label: "Guites", mun_code: "045622" },
  // { label: "Hondagua", mun_code: "045622" },
  // { label: "Ilayang Ilog A", mun_code: "045622" },
  // { label: "Ilayang Ilog B", mun_code: "045622" },
  // { label: "Inalusan", mun_code: "045622" },
  // { label: "Jongo", mun_code: "045622" },
  // { label: "Lalaguna", mun_code: "045622" },
  // { label: "Lourdes", mun_code: "045622" },
  // { label: "Mabanban", mun_code: "045622" },
  // { label: "Mabini", mun_code: "045622" },
  // { label: "Magallanes", mun_code: "045622" },
  // { label: "Maguilayan", mun_code: "045622" },
  // { label: "Mahayod-Hayod", mun_code: "045622" },
  // { label: "Mal-ay", mun_code: "045622" },
  // { label: "Mandoog", mun_code: "045622" },
  // { label: "Manguisian", mun_code: "045622" },
  // { label: "Matinik", mun_code: "045622" },
  // { label: "Monteclaro", mun_code: "045622" },
  // { label: "Pamampangin", mun_code: "045622" },
  // { label: "Pansol", mun_code: "045622" },
  // { label: "Pisipis", mun_code: "045622" },
  // { label: "Rizal (Rural)", mun_code: "045622" },
  // { label: "Roma", mun_code: "045622" },
  // { label: "Rosario", mun_code: "045622" },
  // { label: "Samat", mun_code: "045622" },
  // { label: "San Andres", mun_code: "045622" },
  // { label: "San Antonio", mun_code: "045622" },
  // { label: "San Francisco A", mun_code: "045622" },
  // { label: "San Francisco B", mun_code: "045622" },
  // { label: "San Isidro", mun_code: "045622" },
  // { label: "San Jose", mun_code: "045622" },
  // { label: "San Miguel (Dao)", mun_code: "045622" },
  // { label: "San Pedro", mun_code: "045622" },
  // { label: "San Rafael", mun_code: "045622" },
  // { label: "San Roque", mun_code: "045622" },
  // { label: "Santa Catalina", mun_code: "045622" },
  // { label: "Santa Elena", mun_code: "045622" },
  // { label: "Santa Jacobe", mun_code: "045622" },
  // { label: "Santa Lucia", mun_code: "045622" },
  // { label: "Santa Maria", mun_code: "045622" },
  // { label: "Santa Rosa", mun_code: "045622" },
  // { label: "Silang", mun_code: "045622" },
  // { label: "Sugod", mun_code: "045622" },
  // { label: "Sumalang", mun_code: "045622" },
  // { label: "Tan-ag Ibaba", mun_code: "045622" },
  // { label: "Tan-ag Ilaya", mun_code: "045622" },
  // { label: "Tocalin", mun_code: "045622" },
  // { label: "Vegaflor", mun_code: "045622" },
  // { label: "Veronica", mun_code: "045622" },
  // { label: "Villa Aurora", mun_code: "045622" },
  // { label: "Villa Espina", mun_code: "045622" },
  // { label: "Villa Hermosa", mun_code: "045622" },
  // { label: "Villa Geda", mun_code: "045622" },
  // { label: "Villamonte", mun_code: "045622" },
  // { label: "Villanacaob", mun_code: "045622" },
  // { label: "Rizal (Poblacion)", mun_code: "045622" },
  // { label: "Santa Teresa (Mayupag)", mun_code: "045622" },
  // { label: "Abang", mun_code: "045623" },
  // { label: "Aliliw", mun_code: "045623" },
  // { label: "Atulinao", mun_code: "045623" },
  // { label: "Ayuti", mun_code: "045623" },
  // { label: "Barangay 2 (Pob.)", mun_code: "045623" },
  // { label: "Barangay 3 (Pob.)", mun_code: "045623" },
  // { label: "Barangay 4 (Pob.)", mun_code: "045623" },
  // { label: "Barangay 5 (Pob.)", mun_code: "045623" },
  // { label: "Barangay 6 (Pob.)", mun_code: "045623" },
  // { label: "Barangay 7 (Pob.)", mun_code: "045623" },
  // { label: "Barangay 8 (Pob.)", mun_code: "045623" },
  // { label: "Barangay 9 (Pob.)", mun_code: "045623" },
  // { label: "Barangay 10 (Pob.)", mun_code: "045623" },
  // { label: "Igang", mun_code: "045623" },
  // { label: "Kabatete", mun_code: "045623" },
  // { label: "Kakawit", mun_code: "045623" },
  // { label: "Kalangay", mun_code: "045623" },
  // { label: "Kalyaat", mun_code: "045623" },
  // { label: "Kilib", mun_code: "045623" },
  // { label: "Kulapi", mun_code: "045623" },
  // { label: "Mahabang Parang", mun_code: "045623" },
  // { label: "Malupak", mun_code: "045623" },
  // { label: "Manasa", mun_code: "045623" },
  // { label: "May-It", mun_code: "045623" },
  // { label: "Nagsinamo", mun_code: "045623" },
  // { label: "Nalunao", mun_code: "045623" },
  // { label: "Palola", mun_code: "045623" },
  // { label: "Piis", mun_code: "045623" },
  // { label: "Barangay 1 (Pob.)", mun_code: "045623" },
  // { label: "Samil", mun_code: "045623" },
  // { label: "Tiawe", mun_code: "045623" },
  // { label: "Tinamnan", mun_code: "045623" },
  // { label: "Barangay 1 (Pob.)", mun_code: "045624" },
  // { label: "Barangay 10 (Pob.)", mun_code: "045624" },
  // { label: "Barangay 11 (Pob.)", mun_code: "045624" },
  // { label: "Barangay 2 (Pob.)", mun_code: "045624" },
  // { label: "Barangay 3 (Pob.)", mun_code: "045624" },
  // { label: "Barangay 4 (Pob.)", mun_code: "045624" },
  // { label: "Barangay 5 (Pob.)", mun_code: "045624" },
  // { label: "Barangay 6 (Pob.)", mun_code: "045624" },
  // { label: "Barangay 7 (Pob.)", mun_code: "045624" },
  // { label: "Barangay 8 (Pob.)", mun_code: "045624" },
  // { label: "Barangay 9 (Pob.)", mun_code: "045624" },
  // { label: "Barra", mun_code: "045624" },
  // { label: "Bocohan", mun_code: "045624" },
  // { label: "Mayao Castillo", mun_code: "045624" },
  // { label: "Cotta", mun_code: "045624" },
  // { label: "Gulang-gulang", mun_code: "045624" },
  // { label: "Dalahican", mun_code: "045624" },
  // { label: "Domoit", mun_code: "045624" },
  // { label: "Ibabang Dupay", mun_code: "045624" },
  // { label: "Ibabang Iyam", mun_code: "045624" },
  // { label: "Ibabang Talim", mun_code: "045624" },
  // { label: "Ilayang Dupay", mun_code: "045624" },
  // { label: "Ilayang Iyam", mun_code: "045624" },
  // { label: "Ilayang Talim", mun_code: "045624" },
  // { label: "Isabang", mun_code: "045624" },
  // { label: "Mayao Crossing", mun_code: "045624" },
  // { label: "Mayao Kanluran", mun_code: "045624" },
  // { label: "Mayao Parada", mun_code: "045624" },
  // { label: "Mayao Silangan", mun_code: "045624" },
  // { label: "Ransohan", mun_code: "045624" },
  // { label: "Salinas", mun_code: "045624" },
  // { label: "Talao-talao", mun_code: "045624" },
  // { label: "Market View", mun_code: "045624" },
  // { label: "Amontay", mun_code: "045625" },
  // { label: "Anos", mun_code: "045625" },
  // { label: "Buyao", mun_code: "045625" },
  // { label: "Candangal", mun_code: "045625" },
  // { label: "Calantas", mun_code: "045625" },
  // { label: "Lahing", mun_code: "045625" },
  // { label: "Luctob", mun_code: "045625" },
  // { label: "Mabini Ibaba", mun_code: "045625" },
  // { label: "Mabini Ilaya", mun_code: "045625" },
  // { label: "Malabahay", mun_code: "045625" },
  // { label: "Mambog", mun_code: "045625" },
  // { label: "Olongtao Ibaba", mun_code: "045625" },
  // { label: "Olongtao Ilaya", mun_code: "045625" },
  // { label: "Padre Herrera", mun_code: "045625" },
  // { label: "Pajarillo", mun_code: "045625" },
  // { label: "Pinagbayanan", mun_code: "045625" },
  // { label: "Rodriquez (Pob.)", mun_code: "045625" },
  // { label: "Rizal (Pob.)", mun_code: "045625" },
  // { label: "Castillo (Pob.)", mun_code: "045625" },
  // { label: "Pag-Asa (Pob.)", mun_code: "045625" },
  // { label: "Masipag (Pob.)", mun_code: "045625" },
  // { label: "Damayan (Pob.)", mun_code: "045625" },
  // { label: "San Isidro", mun_code: "045625" },
  // { label: "San Jose", mun_code: "045625" },
  // { label: "San Nicolas", mun_code: "045625" },
  // { label: "San Vicente", mun_code: "045625" },
  // { label: "Taguin", mun_code: "045625" },
  // { label: "Tubigan Ibaba", mun_code: "045625" },
  // { label: "Tubigan Ilaya", mun_code: "045625" },
  // { label: "Vista Hermosa", mun_code: "045625" },
  // { label: "Abo-abo", mun_code: "045627" },
  // { label: "Alitap", mun_code: "045627" },
  // { label: "Baao", mun_code: "045627" },
  // { label: "Balaybalay", mun_code: "045627" },
  // { label: "Bato", mun_code: "045627" },
  // { label: "Cagbalete I", mun_code: "045627" },
  // { label: "Cagbalete II", mun_code: "045627" },
  // { label: "Cagsiay I", mun_code: "045627" },
  // { label: "Cagsiay II", mun_code: "045627" },
  // { label: "Cagsiay III", mun_code: "045627" },
  // { label: "Concepcion", mun_code: "045627" },
  // { label: "Liwayway", mun_code: "045627" },
  // { label: "Lucutan", mun_code: "045627" },
  // { label: "Luya-luya", mun_code: "045627" },
  // { label: "Macasin", mun_code: "045627" },
  // { label: "Lual (Pob.)", mun_code: "045627" },
  // { label: "Mabato (Pob.)", mun_code: "045627" },
  // { label: "Daungan (Pob.)", mun_code: "045627" },
  // { label: "Bagong Bayan (Pob.)", mun_code: "045627" },
  // { label: "Sadsaran (Pob.)", mun_code: "045627" },
  // { label: "Rizaliana (Pob.)", mun_code: "045627" },
  // { label: "Polo", mun_code: "045627" },
  // { label: "Remedios I", mun_code: "045627" },
  // { label: "Remedios II", mun_code: "045627" },
  // { label: "Rosario", mun_code: "045627" },
  // { label: "San Gabriel", mun_code: "045627" },
  // { label: "San Isidro", mun_code: "045627" },
  // { label: "San Jose", mun_code: "045627" },
  // { label: "San Lorenzo", mun_code: "045627" },
  // { label: "San Miguel", mun_code: "045627" },
  // { label: "San Rafael", mun_code: "045627" },
  // { label: "San Roque", mun_code: "045627" },
  // { label: "San Vicente", mun_code: "045627" },
  // { label: "Santa Lucia", mun_code: "045627" },
  // { label: "Santo Angel", mun_code: "045627" },
  // { label: "Santol", mun_code: "045627" },
  // { label: "Soledad", mun_code: "045627" },
  // { label: "Tapucan", mun_code: "045627" },
  // { label: "Lual Rural", mun_code: "045627" },
  // { label: "Ajos", mun_code: "045628" },
  // { label: "Amuguis", mun_code: "045628" },
  // { label: "Anonang", mun_code: "045628" },
  // { label: "Bagong Silang", mun_code: "045628" },
  // { label: "Bagupaye", mun_code: "045628" },
  // { label: "Barangay 1 (Pob.)", mun_code: "045628" },
  // { label: "Barangay 2 (Pob.)", mun_code: "045628" },
  // { label: "Barangay 3 (Pob.)", mun_code: "045628" },
  // { label: "Barangay 4 (Pob.)", mun_code: "045628" },
  // { label: "Bolo", mun_code: "045628" },
  // { label: "Buenavista", mun_code: "045628" },
  // { label: "Burgos", mun_code: "045628" },
  // { label: "Butanyog", mun_code: "045628" },
  // { label: "Canuyep", mun_code: "045628" },
  // { label: "F. Nanadiego", mun_code: "045628" },
  // { label: "Ibabang Cambuga", mun_code: "045628" },
  // { label: "Ibabang Yuni", mun_code: "045628" },
  // { label: "Ilayang Cambuga (Mabini)", mun_code: "045628" },
  // { label: "Ilayang Yuni", mun_code: "045628" },
  // { label: "Latangan", mun_code: "045628" },
  // { label: "Magsaysay", mun_code: "045628" },
  // { label: "Matataja", mun_code: "045628" },
  // { label: "Pakiing", mun_code: "045628" },
  // { label: "Patabog", mun_code: "045628" },
  // { label: "Sagongon", mun_code: "045628" },
  // { label: "San Isidro", mun_code: "045628" },
  // { label: "San Pedro", mun_code: "045628" },
  // { label: "Santa Rosa", mun_code: "045628" },
  // { label: "Cabuyao Norte", mun_code: "045629" },
  // { label: "Cabuyao Sur", mun_code: "045629" },
  // { label: "Danlagan", mun_code: "045629" },
  // { label: "Duhat", mun_code: "045629" },
  // { label: "Hinguiwin", mun_code: "045629" },
  // { label: "Kinagunan Ibaba", mun_code: "045629" },
  // { label: "Kinagunan Ilaya", mun_code: "045629" },
  // { label: "Lipata", mun_code: "045629" },
  // { label: "Marao", mun_code: "045629" },
  // { label: "Marquez", mun_code: "045629" },
  // { label: "Burgos (Pob.)", mun_code: "045629" },
  // { label: "Campo (Pob.)", mun_code: "045629" },
  // { label: "Basiao (Pob.)", mun_code: "045629" },
  // { label: "Punta (Pob.)", mun_code: "045629" },
  // { label: "Rizal", mun_code: "045629" },
  // { label: "San Isidro", mun_code: "045629" },
  // { label: "San Vicente", mun_code: "045629" },
  // { label: "Sipa", mun_code: "045629" },
  // { label: "Tulay Buhangin", mun_code: "045629" },
  // { label: "Villapaz", mun_code: "045629" },
  // { label: "Walay", mun_code: "045629" },
  // { label: "Yawe", mun_code: "045629" },
  // { label: "Alupaye", mun_code: "045630" },
  // { label: "Antipolo", mun_code: "045630" },
  // { label: "Bantigue", mun_code: "045630" },
  // { label: "Bigo", mun_code: "045630" },
  // { label: "Binahaan", mun_code: "045630" },
  // { label: "Bukal", mun_code: "045630" },
  // { label: "Ibabang Bagumbungan", mun_code: "045630" },
  // { label: "Ibabang Palsabangon", mun_code: "045630" },
  // { label: "Ibabang Polo", mun_code: "045630" },
  // { label: "Ikirin", mun_code: "045630" },
  // { label: "Ilayang Bagumbungan", mun_code: "045630" },
  // { label: "Ilayang Palsabangon", mun_code: "045630" },
  // { label: "Ilayang Polo", mun_code: "045630" },
  // { label: "Kanluran Malicboy", mun_code: "045630" },
  // { label: "Mapagong", mun_code: "045630" },
  // { label: "Mayhay", mun_code: "045630" },
  // { label: "Pinagbayanan", mun_code: "045630" },
  // { label: "Barangay 1 Castillo (Pob.)", mun_code: "045630" },
  // { label: "Barangay 2 Daungan (Pob.)", mun_code: "045630" },
  // { label: "Barangay 3 Del Carmen (Pob.)", mun_code: "045630" },
  // { label: "Barangay 4 Parang (Pob.)", mun_code: "045630" },
  // { label: "Barangay 5 Santa Catalina (Pob.)", mun_code: "045630" },
  // { label: "Barangay 6 Tambak (Pob.)", mun_code: "045630" },
  // { label: "Silangan Malicboy", mun_code: "045630" },
  // { label: "Talipan", mun_code: "045630" },
  // { label: "Tukalan", mun_code: "045630" },
  // { label: "Balungay", mun_code: "045631" },
  // { label: "Bato", mun_code: "045631" },
  // { label: "Bonbon", mun_code: "045631" },
  // { label: "Calasumanga", mun_code: "045631" },
  // { label: "Kinalagti", mun_code: "045631" },
  // { label: "Libo", mun_code: "045631" },
  // { label: "Lipata", mun_code: "045631" },
  // { label: "Matangkap", mun_code: "045631" },
  // { label: "Milawid", mun_code: "045631" },
  // { label: "Pagitan", mun_code: "045631" },
  // { label: "Pandan", mun_code: "045631" },
  // { label: "San Juan (Pob.)", mun_code: "045631" },
  // { label: "Amaga", mun_code: "045632" },
  // { label: "Busdak", mun_code: "045632" },
  // { label: "Kilogan", mun_code: "045632" },
  // { label: "Luod", mun_code: "045632" },
  // { label: "Patnanungan Norte", mun_code: "045632" },
  // { label: "Patnanungan Sur (Pob.)", mun_code: "045632" },
  // { label: "Maabot", mun_code: "045633" },
  // { label: "Mainit Norte", mun_code: "045633" },
  // { label: "Mainit Sur", mun_code: "045633" },
  // { label: "Pambuhan", mun_code: "045633" },
  // { label: "Pinagtubigan Este", mun_code: "045633" },
  // { label: "Pinagtubigan Weste", mun_code: "045633" },
  // { label: "Pagkakaisa Pob. (Barangay 1)", mun_code: "045633" },
  // { label: "Mapagmahal Pob. (Barangay 2)", mun_code: "045633" },
  // { label: "Bagong Pag-Asa Pob. (Barangay 3)", mun_code: "045633" },
  // { label: "Bagong Silang Pob. (Barangay 4)", mun_code: "045633" },
  // { label: "Rizal", mun_code: "045633" },
  // { label: "Sangirin", mun_code: "045633" },
  // { label: "Villamanzano Norte", mun_code: "045633" },
  // { label: "Villamanzano Sur", mun_code: "045633" },
  // { label: "Amontay", mun_code: "045634" },
  // { label: "Cometa", mun_code: "045634" },
  // { label: "Biga", mun_code: "045634" },
  // { label: "Bilucao", mun_code: "045634" },
  // { label: "Cabulihan", mun_code: "045634" },
  // { label: "Cawayanin", mun_code: "045634" },
  // { label: "Gangahin", mun_code: "045634" },
  // { label: "Ibabang Burgos", mun_code: "045634" },
  // { label: "Ibabang Pacatin", mun_code: "045634" },
  // { label: "Ibabang Soliyao", mun_code: "045634" },
  // { label: "Ilayang Burgos", mun_code: "045634" },
  // { label: "Ilayang Pacatin", mun_code: "045634" },
  // { label: "Ilayang Soliyao", mun_code: "045634" },
  // { label: "Nag-Cruz", mun_code: "045634" },
  // { label: "Payte", mun_code: "045634" },
  // { label: "Pinagbayanan", mun_code: "045634" },
  // { label: "Masaya (Pob.)", mun_code: "045634" },
  // { label: "Manggahan (Pob.)", mun_code: "045634" },
  // { label: "Dulong Bayan (Pob.)", mun_code: "045634" },
  // { label: "Pag-Asa (Pob.)", mun_code: "045634" },
  // { label: "Castillo (Pob.)", mun_code: "045634" },
  // { label: "Maaliw (Pob.)", mun_code: "045634" },
  // { label: "Mayubok (Pob.)", mun_code: "045634" },
  // { label: "Pamilihan (Pob.)", mun_code: "045634" },
  // { label: "Dalampasigan (Pob.)", mun_code: "045634" },
  // { label: "Poctol", mun_code: "045634" },
  // { label: "Quezon", mun_code: "045634" },
  // { label: "Quinagasan", mun_code: "045634" },
  // { label: "Rizalino", mun_code: "045634" },
  // { label: "Saguinsinan", mun_code: "045634" },
  // { label: "Sampaloc", mun_code: "045634" },
  // { label: "San Roque", mun_code: "045634" },
  // { label: "Sisirin", mun_code: "045634" },
  // { label: "Sumag Este", mun_code: "045634" },
  // { label: "Sumag Norte", mun_code: "045634" },
  // { label: "Sumag Weste", mun_code: "045634" },
  // { label: "Concepcion", mun_code: "045635" },
  // { label: "Duhat", mun_code: "045635" },
  // { label: "Ilaya", mun_code: "045635" },
  // { label: "Ilosong", mun_code: "045635" },
  // { label: "Tanauan", mun_code: "045635" },
  // { label: "Central (Pob.)", mun_code: "045635" },
  // { label: "Paang Bundok (Pob.)", mun_code: "045635" },
  // { label: "Pampaaralan (Pob.)", mun_code: "045635" },
  // { label: "M. L. Tumagay Pob. (Remas Mabuton)", mun_code: "045635" },
  // { label: "Anawan", mun_code: "045636" },
  // { label: "Atulayan", mun_code: "045636" },
  // { label: "Balesin", mun_code: "045636" },
  // { label: "Binibitinan", mun_code: "045636" },
  // { label: "Bislian", mun_code: "045636" },
  // { label: "Bucao", mun_code: "045636" },
  // { label: "Canicanian", mun_code: "045636" },
  // { label: "Kalubakis", mun_code: "045636" },
  // { label: "Languyin", mun_code: "045636" },
  // { label: "Libjo", mun_code: "045636" },
  // { label: "Pamatdan", mun_code: "045636" },
  // { label: "Pilion", mun_code: "045636" },
  // { label: "Pinaglubayan", mun_code: "045636" },
  // { label: "Poblacion", mun_code: "045636" },
  // { label: "Sabang", mun_code: "045636" },
  // { label: "Salipsip", mun_code: "045636" },
  // { label: "Sibulan", mun_code: "045636" },
  // { label: "Taluong", mun_code: "045636" },
  // { label: "Tamulaya-Anitong", mun_code: "045636" },
  // { label: "Apad", mun_code: "045637" },
  // { label: "Argosino", mun_code: "045637" },
  // { label: "Cagbalogo", mun_code: "045637" },
  // { label: "Caridad", mun_code: "045637" },
  // { label: "Cometa", mun_code: "045637" },
  // { label: "Del Pilar", mun_code: "045637" },
  // { label: "Guinhawa", mun_code: "045637" },
  // { label: "Gumubat", mun_code: "045637" },
  // { label: "Magsino", mun_code: "045637" },
  // { label: "Barangay I (Pob.)", mun_code: "045637" },
  // { label: "Barangay II (Pob.)", mun_code: "045637" },
  // { label: "Barangay III (Pob.)", mun_code: "045637" },
  // { label: "Barangay IV (Pob.)", mun_code: "045637" },
  // { label: "Barangay V (Pob.)", mun_code: "045637" },
  // { label: "Barangay VI (Pob.)", mun_code: "045637" },
  // { label: "Sabang", mun_code: "045637" },
  // { label: "Silangan", mun_code: "045637" },
  // { label: "Tagkawa", mun_code: "045637" },
  // { label: "Villa Belen", mun_code: "045637" },
  // { label: "Villa Francia", mun_code: "045637" },
  // { label: "Villa Gomez", mun_code: "045637" },
  // { label: "Villa Mercedes", mun_code: "045637" },
  // { label: "Poblacion I (Barangay 1)", mun_code: "045638" },
  // { label: "Capalong", mun_code: "045638" },
  // { label: "Cawayan", mun_code: "045638" },
  // { label: "Kiloloran", mun_code: "045638" },
  // { label: "Llavac", mun_code: "045638" },
  // { label: "Lubayat", mun_code: "045638" },
  // { label: "Malapad", mun_code: "045638" },
  // { label: "Maragondon", mun_code: "045638" },
  // { label: "Pandan", mun_code: "045638" },
  // { label: "Tanauan", mun_code: "045638" },
  // { label: "Tignoan", mun_code: "045638" },
  // { label: "Ungos", mun_code: "045638" },
  // { label: "Poblacion 61 (Barangay 2)", mun_code: "045638" },
  // { label: "Maunlad", mun_code: "045638" },
  // { label: "Bagong Silang", mun_code: "045638" },
  // { label: "Masikap", mun_code: "045638" },
  // { label: "Tagumpay", mun_code: "045638" },
  // { label: "Alupay", mun_code: "045639" },
  // { label: "Apasan", mun_code: "045639" },
  // { label: "Banot", mun_code: "045639" },
  // { label: "Bataan", mun_code: "045639" },
  // { label: "Bayongon", mun_code: "045639" },
  // { label: "Bilucao", mun_code: "045639" },
  // { label: "Caldong", mun_code: "045639" },
  // { label: "Ibabang Owain", mun_code: "045639" },
  // { label: "Ilayang Owain", mun_code: "045639" },
  // { label: "Mamala", mun_code: "045639" },
  // { label: "San Bueno", mun_code: "045639" },
  // { label: "San Isidro (Pob.)", mun_code: "045639" },
  // { label: "San Roque (Pob.)", mun_code: "045639" },
  // { label: "Taquico", mun_code: "045639" },
  // { label: "Alibihaban", mun_code: "045640" },
  // { label: "Camflora", mun_code: "045640" },
  // { label: "Mangero", mun_code: "045640" },
  // { label: "Pansoy", mun_code: "045640" },
  // { label: "Tala", mun_code: "045640" },
  // { label: "Talisay", mun_code: "045640" },
  // { label: "Poblacion", mun_code: "045640" },
  // { label: "Arawan", mun_code: "045641" },
  // { label: "Bagong Niing", mun_code: "045641" },
  // { label: "Balat Atis", mun_code: "045641" },
  // { label: "Briones", mun_code: "045641" },
  // { label: "Bulihan", mun_code: "045641" },
  // { label: "Buliran", mun_code: "045641" },
  // { label: "Callejon", mun_code: "045641" },
  // { label: "Corazon", mun_code: "045641" },
  // { label: "Manuel del Valle, Sr.", mun_code: "045641" },
  // { label: "Loob", mun_code: "045641" },
  // { label: "Magsaysay", mun_code: "045641" },
  // { label: "Matipunso", mun_code: "045641" },
  // { label: "Niing", mun_code: "045641" },
  // { label: "Poblacion", mun_code: "045641" },
  // { label: "Pulo", mun_code: "045641" },
  // { label: "Pury", mun_code: "045641" },
  // { label: "Sampaga", mun_code: "045641" },
  // { label: "Sampaguita", mun_code: "045641" },
  // { label: "San Jose", mun_code: "045641" },
  // { label: "Sinturisan", mun_code: "045641" },
  // { label: "Butanguiad", mun_code: "045642" },
  // { label: "Casay", mun_code: "045642" },
  // { label: "Cawayan I", mun_code: "045642" },
  // { label: "Cawayan II", mun_code: "045642" },
  // { label: "Huyon-Uyon", mun_code: "045642" },
  // { label: "Ibabang Tayuman", mun_code: "045642" },
  // { label: "Ilayang Tayuman", mun_code: "045642" },
  // { label: "Inabuan", mun_code: "045642" },
  // { label: "Nasalaan", mun_code: "045642" },
  // { label: "Pagsangahan", mun_code: "045642" },
  // { label: "Poblacion", mun_code: "045642" },
  // { label: "Pugon", mun_code: "045642" },
  // { label: "Silongin", mun_code: "045642" },
  // { label: "Don Juan Vercelos", mun_code: "045642" },
  // { label: "Abuyon", mun_code: "045644" },
  // { label: "Andres Bonifacio", mun_code: "045644" },
  // { label: "Bani", mun_code: "045644" },
  // { label: "Binay", mun_code: "045644" },
  // { label: "Buenavista", mun_code: "045644" },
  // { label: "Busokbusokan", mun_code: "045644" },
  // { label: "Calwit", mun_code: "045644" },
  // { label: "Guinhalinan", mun_code: "045644" },
  // { label: "Lacdayan", mun_code: "045644" },
  // { label: "Maguiting", mun_code: "045644" },
  // { label: "Manlampong", mun_code: "045644" },
  // { label: "Pagkakaisa (Pob.)", mun_code: "045644" },
  // { label: "Maligaya (Pob.)", mun_code: "045644" },
  // { label: "Bayanihan (Pob.)", mun_code: "045644" },
  // { label: "Pagdadamayan (Pob.)", mun_code: "045644" },
  // { label: "Punta", mun_code: "045644" },
  // { label: "Rizal", mun_code: "045644" },
  // { label: "San Isidro", mun_code: "045644" },
  // { label: "San Juan", mun_code: "045644" },
  // { label: "San Vicente", mun_code: "045644" },
  // { label: "Vigo Central", mun_code: "045644" },
  // { label: "Villa Aurin (Pinagsama)", mun_code: "045644" },
  // { label: "Villa Reyes", mun_code: "045644" },
  // { label: "White Cliff", mun_code: "045644" },
  // { label: "Antipolo", mun_code: "045645" },
  // { label: "Balubal", mun_code: "045645" },
  // { label: "Concepcion Pinagbakuran", mun_code: "045645" },
  // { label: "Bignay 1", mun_code: "045645" },
  // { label: "Bignay 2", mun_code: "045645" },
  // { label: "Bucal", mun_code: "045645" },
  // { label: "Canda", mun_code: "045645" },
  // { label: "Concepcion Banahaw", mun_code: "045645" },
  // { label: "Concepcion No. 1", mun_code: "045645" },
  // { label: "Concepcion Palasan", mun_code: "045645" },
  // { label: "Gibanga", mun_code: "045645" },
  // { label: "Guisguis-San Roque", mun_code: "045645" },
  // { label: "Guisguis-Talon", mun_code: "045645" },
  // { label: "Janagdong 1", mun_code: "045645" },
  // { label: "Janagdong 2", mun_code: "045645" },
  // { label: "Limbon", mun_code: "045645" },
  // { label: "Lutucan Bata", mun_code: "045645" },
  // { label: "Lutucan Malabag", mun_code: "045645" },
  // { label: "Lutucan 1", mun_code: "045645" },
  // { label: "Mamala I", mun_code: "045645" },
  // { label: "Manggalang 1", mun_code: "045645" },
  // { label: "Manggalang Tulo-tulo", mun_code: "045645" },
  // { label: "Manggalang-Bantilan", mun_code: "045645" },
  // { label: "Manggalang-Kiling", mun_code: "045645" },
  // { label: "Montecillo", mun_code: "045645" },
  // { label: "Morong", mun_code: "045645" },
  // { label: "Pili", mun_code: "045645" },
  // { label: "Barangay 2 (Pob.)", mun_code: "045645" },
  // { label: "Barangay 3 (Pob.)", mun_code: "045645" },
  // { label: "Barangay 4 (Pob.)", mun_code: "045645" },
  // { label: "Barangay 5 (Pob.)", mun_code: "045645" },
  // { label: "Barangay 6 (Pob.)", mun_code: "045645" },
  // { label: "Sampaloc Bogon", mun_code: "045645" },
  // { label: "Sampaloc 1", mun_code: "045645" },
  // { label: "Sampaloc 2", mun_code: "045645" },
  // { label: "Sampaloc Santo Cristo", mun_code: "045645" },
  // { label: "Talaan Aplaya", mun_code: "045645" },
  // { label: "Talaanpantoc", mun_code: "045645" },
  // { label: "Tumbaga 1", mun_code: "045645" },
  // { label: "Tumbaga 2", mun_code: "045645" },
  // { label: "Barangay 1 (Pob.)", mun_code: "045645" },
  // { label: "Mamala II", mun_code: "045645" },
  // { label: "Aldavoc", mun_code: "045646" },
  // { label: "Aliji", mun_code: "045646" },
  // { label: "Bagong Silang", mun_code: "045646" },
  // { label: "Bamban", mun_code: "045646" },
  // { label: "Bosigon", mun_code: "045646" },
  // { label: "Bukal", mun_code: "045646" },
  // { label: "Cabugwang", mun_code: "045646" },
  // { label: "Cagascas", mun_code: "045646" },
  // { label: "Casispalan", mun_code: "045646" },
  // { label: "Colong-colong", mun_code: "045646" },
  // { label: "Del Rosario", mun_code: "045646" },
  // { label: "Cabibihan", mun_code: "045646" },
  // { label: "Candalapdap", mun_code: "045646" },
  // { label: "Katimo", mun_code: "045646" },
  // { label: "Kinatakutan", mun_code: "045646" },
  // { label: "Landing", mun_code: "045646" },
  // { label: "Laurel", mun_code: "045646" },
  // { label: "Magsaysay", mun_code: "045646" },
  // { label: "Maguibuay", mun_code: "045646" },
  // { label: "Mahinta", mun_code: "045646" },
  // { label: "Malbog", mun_code: "045646" },
  // { label: "Manato Central", mun_code: "045646" },
  // { label: "Manato Station", mun_code: "045646" },
  // { label: "Mangayao", mun_code: "045646" },
  // { label: "Mansilay", mun_code: "045646" },
  // { label: "Mapulot", mun_code: "045646" },
  // { label: "Munting Parang", mun_code: "045646" },
  // { label: "Payapa", mun_code: "045646" },
  // { label: "Poblacion", mun_code: "045646" },
  // { label: "Rizal", mun_code: "045646" },
  // { label: "Sabang", mun_code: "045646" },
  // { label: "San Diego", mun_code: "045646" },
  // { label: "San Francisco", mun_code: "045646" },
  // { label: "San Isidro", mun_code: "045646" },
  // { label: "San Roque", mun_code: "045646" },
  // { label: "San Vicente", mun_code: "045646" },
  // { label: "Santa Cecilia", mun_code: "045646" },
  // { label: "Santa Monica", mun_code: "045646" },
  // { label: "Santo Tomas", mun_code: "045646" },
  // { label: "Seguiwan", mun_code: "045646" },
  // { label: "Tabason", mun_code: "045646" },
  // { label: "Tunton", mun_code: "045646" },
  // { label: "Victoria", mun_code: "045646" },
  // { label: "Alitao", mun_code: "045647" },
  // { label: "Alupay", mun_code: "045647" },
  // { label: "Angeles Zone I (Pob.)", mun_code: "045647" },
  // { label: "Angeles Zone II", mun_code: "045647" },
  // { label: "Angeles Zone III", mun_code: "045647" },
  // { label: "Angeles Zone IV", mun_code: "045647" },
  // { label: "Angustias Zone I (Pob.)", mun_code: "045647" },
  // { label: "Angustias Zone II", mun_code: "045647" },
  // { label: "Angustias Zone III", mun_code: "045647" },
  // { label: "Angustias Zone IV", mun_code: "045647" },
  // { label: "Anos", mun_code: "045647" },
  // { label: "Ayaas", mun_code: "045647" },
  // { label: "Baguio", mun_code: "045647" },
  // { label: "Banilad", mun_code: "045647" },
  // { label: "Calantas", mun_code: "045647" },
  // { label: "Camaysa", mun_code: "045647" },
  // { label: "Dapdap", mun_code: "045647" },
  // { label: "Gibanga", mun_code: "045647" },
  // { label: "Alsam Ibaba", mun_code: "045647" },
  // { label: "Bukal Ibaba", mun_code: "045647" },
  // { label: "Ilasan Ibaba", mun_code: "045647" },
  // { label: "Nangka Ibaba", mun_code: "045647" },
  // { label: "Palale Ibaba", mun_code: "045647" },
  // { label: "Ibas", mun_code: "045647" },
  // { label: "Alsam Ilaya", mun_code: "045647" },
  // { label: "Bukal Ilaya", mun_code: "045647" },
  // { label: "Ilasan Ilaya", mun_code: "045647" },
  // { label: "Nangka Ilaya", mun_code: "045647" },
  // { label: "Palale Ilaya", mun_code: "045647" },
  // { label: "Ipilan", mun_code: "045647" },
  // { label: "Isabang", mun_code: "045647" },
  // { label: "Calumpang", mun_code: "045647" },
  // { label: "Domoit Kanluran", mun_code: "045647" },
  // { label: "Katigan Kanluran", mun_code: "045647" },
  // { label: "Palale Kanluran", mun_code: "045647" },
  // { label: "Lakawan", mun_code: "045647" },
  // { label: "Lalo", mun_code: "045647" },
  // { label: "Lawigue", mun_code: "045647" },
  // { label: "Lita (Pob.)", mun_code: "045647" },
  // { label: "Malaoa", mun_code: "045647" },
  // { label: "Masin", mun_code: "045647" },
  // { label: "Mate", mun_code: "045647" },
  // { label: "Mateuna", mun_code: "045647" },
  // { label: "Mayowe", mun_code: "045647" },
  // { label: "Opias", mun_code: "045647" },
  // { label: "Pandakaki", mun_code: "045647" },
  // { label: "Pook", mun_code: "045647" },
  // { label: "Potol", mun_code: "045647" },
  // { label: "San Diego Zone I (Pob.)", mun_code: "045647" },
  // { label: "San Diego Zone II", mun_code: "045647" },
  // { label: "San Diego Zone III", mun_code: "045647" },
  // { label: "San Diego Zone IV", mun_code: "045647" },
  // { label: "San Isidro Zone I (Pob.)", mun_code: "045647" },
  // { label: "San Isidro Zone II", mun_code: "045647" },
  // { label: "San Isidro Zone III", mun_code: "045647" },
  // { label: "San Isidro Zone IV", mun_code: "045647" },
  // { label: "San Roque Zone I (Pob.)", mun_code: "045647" },
  // { label: "San Roque Zone II", mun_code: "045647" },
  // { label: "Domoit Silangan", mun_code: "045647" },
  // { label: "Katigan Silangan", mun_code: "045647" },
  // { label: "Palale Silangan", mun_code: "045647" },
  // { label: "Talolong", mun_code: "045647" },
  // { label: "Tamlong", mun_code: "045647" },
  // { label: "Tongko", mun_code: "045647" },
  // { label: "Valencia", mun_code: "045647" },
  // { label: "Wakas", mun_code: "045647" },
  // { label: "Anastacia (Tagbak)", mun_code: "045648" },
  // { label: "Ayusan I", mun_code: "045648" },
  // { label: "Barangay I (Pob.)", mun_code: "045648" },
  // { label: "Barangay II (Pob.)", mun_code: "045648" },
  // { label: "Barangay III (Pob.)", mun_code: "045648" },
  // { label: "Barangay IV (Pob.)", mun_code: "045648" },
  // { label: "Behia", mun_code: "045648" },
  // { label: "Bukal", mun_code: "045648" },
  // { label: "Bula", mun_code: "045648" },
  // { label: "Bulakin", mun_code: "045648" },
  // { label: "Cabatang", mun_code: "045648" },
  // { label: "Cabay", mun_code: "045648" },
  // { label: "Del Rosario", mun_code: "045648" },
  // { label: "Lagalag", mun_code: "045648" },
  // { label: "Lalig", mun_code: "045648" },
  // { label: "Lumingon", mun_code: "045648" },
  // { label: "Lusacan", mun_code: "045648" },
  // { label: "Paiisa", mun_code: "045648" },
  // { label: "Palagaran", mun_code: "045648" },
  // { label: "Quipot", mun_code: "045648" },
  // { label: "San Agustin", mun_code: "045648" },
  // { label: "San Isidro", mun_code: "045648" },
  // { label: "San Jose", mun_code: "045648" },
  // { label: "San Juan", mun_code: "045648" },
  // { label: "San Pedro", mun_code: "045648" },
  // { label: "Tagbakin", mun_code: "045648" },
  // { label: "Talisay", mun_code: "045648" },
  // { label: "Tamisian", mun_code: "045648" },
  // { label: "San Francisco", mun_code: "045648" },
  // { label: "Aquino", mun_code: "045648" },
  // { label: "Ayusan II", mun_code: "045648" },
  // { label: "Almacen", mun_code: "045649" },
  // { label: "Balagtas", mun_code: "045649" },
  // { label: "Balanacan", mun_code: "045649" },
  // { label: "Bulo Ibaba", mun_code: "045649" },
  // { label: "Bulo Ilaya", mun_code: "045649" },
  // { label: "Bonifacio", mun_code: "045649" },
  // { label: "Burgos", mun_code: "045649" },
  // { label: "Caigdal", mun_code: "045649" },
  // { label: "General Luna", mun_code: "045649" },
  // { label: "Kalilayan Ibaba", mun_code: "045649" },
  // { label: "Cabulihan Ibaba", mun_code: "045649" },
  // { label: "Mairok Ibaba", mun_code: "045649" },
  // { label: "Kalilayan Ilaya", mun_code: "045649" },
  // { label: "Cabulihan Ilaya", mun_code: "045649" },
  // { label: "Mabini", mun_code: "045649" },
  // { label: "Mairok Ilaya", mun_code: "045649" },
  // { label: "Malvar", mun_code: "045649" },
  // { label: "Maputat", mun_code: "045649" },
  // { label: "Muliguin", mun_code: "045649" },
  // { label: "Pagaguasan", mun_code: "045649" },
  // { label: "Panaon Ibaba", mun_code: "045649" },
  // { label: "Panaon Ilaya", mun_code: "045649" },
  // { label: "Plaridel", mun_code: "045649" },
  // { label: "F. De Jesus (Pob.)", mun_code: "045649" },
  // { label: "R. Lapu-lapu (Pob.)", mun_code: "045649" },
  // { label: "Raja Soliman (Pob.)", mun_code: "045649" },
  // { label: "R. Magsaysay (Pob.)", mun_code: "045649" },
  // { label: "Poctol", mun_code: "045649" },
  // { label: "Punta", mun_code: "045649" },
  // { label: "Rizal Ibaba", mun_code: "045649" },
  // { label: "Rizal Ilaya", mun_code: "045649" },
  // { label: "San Roque", mun_code: "045649" },
  // { label: "Socorro", mun_code: "045649" },
  // { label: "Tagumpay", mun_code: "045649" },
  // { label: "Tubas", mun_code: "045649" },
  // { label: "Tubigan", mun_code: "045649" },
  // { label: "Bagumbayan", mun_code: "045801" },
  // { label: "Kalayaan", mun_code: "045801" },
  // { label: "Poblacion Ibaba", mun_code: "045801" },
  // { label: "Poblacion Itaas", mun_code: "045801" },
  // { label: "San Isidro", mun_code: "045801" },
  // { label: "San Pedro", mun_code: "045801" },
  // { label: "San Roque", mun_code: "045801" },
  // { label: "San Vicente", mun_code: "045801" },
  // { label: "Mahabang Parang", mun_code: "045801" },
  // { label: "Calawis", mun_code: "045802" },
  // { label: "Cupang", mun_code: "045802" },
  // { label: "Dela Paz (Pob.)", mun_code: "045802" },
  // { label: "Mayamot", mun_code: "045802" },
  // { label: "San Isidro (Pob.)", mun_code: "045802" },
  // { label: "San Jose (Pob.)", mun_code: "045802" },
  // { label: "San Roque (Pob.)", mun_code: "045802" },
  // { label: "Mambugan", mun_code: "045802" },
  // { label: "Bagong Nayon", mun_code: "045802" },
  // { label: "Beverly Hills", mun_code: "045802" },
  // { label: "Dalig", mun_code: "045802" },
  // { label: "Inarawan", mun_code: "045802" },
  // { label: "San Juan", mun_code: "045802" },
  // { label: "San Luis", mun_code: "045802" },
  // { label: "Santa Cruz", mun_code: "045802" },
  // { label: "Muntingdilaw", mun_code: "045802" },
  // { label: "Evangelista", mun_code: "045803" },
  // { label: "Rizal (Pob.)", mun_code: "045803" },
  // { label: "San Jose", mun_code: "045803" },
  // { label: "San Salvador", mun_code: "045803" },
  // { label: "Santiago", mun_code: "045803" },
  // { label: "Concepcion", mun_code: "045803" },
  // { label: "San Juan", mun_code: "045803" },
  // { label: "San Miguel", mun_code: "045803" },
  // { label: "Mabini", mun_code: "045803" },
  // { label: "Pinugay", mun_code: "045803" },
  // { label: "Bangad", mun_code: "045804" },
  // { label: "Batingan", mun_code: "045804" },
  // { label: "Bilibiran", mun_code: "045804" },
  // { label: "Binitagan", mun_code: "045804" },
  // { label: "Bombong", mun_code: "045804" },
  // { label: "Buhangin", mun_code: "045804" },
  // { label: "Calumpang", mun_code: "045804" },
  // { label: "Ginoong Sanay", mun_code: "045804" },
  // { label: "Gulod", mun_code: "045804" },
  // { label: "Habagatan", mun_code: "045804" },
  // { label: "Ithan", mun_code: "045804" },
  // { label: "Janosa", mun_code: "045804" },
  // { label: "Kalawaan", mun_code: "045804" },
  // { label: "Kalinawan", mun_code: "045804" },
  // { label: "Kasile", mun_code: "045804" },
  // { label: "Kaytome", mun_code: "045804" },
  // { label: "Kinaboogan", mun_code: "045804" },
  // { label: "Kinagatan", mun_code: "045804" },
  // { label: "Libis (Pob.)", mun_code: "045804" },
  // { label: "Limbon-limbon", mun_code: "045804" },
  // { label: "Lunsad", mun_code: "045804" },
  // { label: "Mahabang Parang", mun_code: "045804" },
  // { label: "Macamot", mun_code: "045804" },
  // { label: "Mambog", mun_code: "045804" },
  // { label: "Palangoy", mun_code: "045804" },
  // { label: "Pantok", mun_code: "045804" },
  // { label: "Pila Pila", mun_code: "045804" },
  // { label: "Pinagdilawan", mun_code: "045804" },
  // { label: "Pipindan", mun_code: "045804" },
  // { label: "Rayap", mun_code: "045804" },
  // { label: "Sapang", mun_code: "045804" },
  // { label: "Tabon", mun_code: "045804" },
  // { label: "Tagpos", mun_code: "045804" },
  // { label: "Tatala", mun_code: "045804" },
  // { label: "Tayuman", mun_code: "045804" },
  // { label: "Layunan (Pob.)", mun_code: "045804" },
  // { label: "Libid (Pob.)", mun_code: "045804" },
  // { label: "Malakaban", mun_code: "045804" },
  // { label: "Pag-Asa", mun_code: "045804" },
  // { label: "San Carlos", mun_code: "045804" },
  // { label: "San Andres (Pob.)", mun_code: "045805" },
  // { label: "San Isidro", mun_code: "045805" },
  // { label: "San Juan", mun_code: "045805" },
  // { label: "San Roque", mun_code: "045805" },
  // { label: "Santa Rosa", mun_code: "045805" },
  // { label: "Santo Domingo", mun_code: "045805" },
  // { label: "Balibago", mun_code: "045806" },
  // { label: "Boor", mun_code: "045806" },
  // { label: "Calahan", mun_code: "045806" },
  // { label: "Dalig", mun_code: "045806" },
  // { label: "Del Remedio (Pob.)", mun_code: "045806" },
  // { label: "Iglesia (Pob.)", mun_code: "045806" },
  // { label: "Lambac", mun_code: "045806" },
  // { label: "Looc", mun_code: "045806" },
  // { label: "Malanggam-Calubacan", mun_code: "045806" },
  // { label: "Nagsulo", mun_code: "045806" },
  // { label: "Navotas", mun_code: "045806" },
  // { label: "Patunhay", mun_code: "045806" },
  // { label: "Real (Pob.)", mun_code: "045806" },
  // { label: "Sampad", mun_code: "045806" },
  // { label: "San Roque (Pob.)", mun_code: "045806" },
  // { label: "Subay", mun_code: "045806" },
  // { label: "Ticulio", mun_code: "045806" },
  // { label: "Tuna", mun_code: "045806" },
  // { label: "Bagumbong", mun_code: "045807" },
  // { label: "Bayugo", mun_code: "045807" },
  // { label: "Second District (Pob.)", mun_code: "045807" },
  // { label: "Third District (Pob.)", mun_code: "045807" },
  // { label: "Lubo", mun_code: "045807" },
  // { label: "Pagkalinawan", mun_code: "045807" },
  // { label: "Palaypalay", mun_code: "045807" },
  // { label: "Punta", mun_code: "045807" },
  // { label: "Sipsipin", mun_code: "045807" },
  // { label: "Special District (Pob.)", mun_code: "045807" },
  // { label: "Paalaman", mun_code: "045807" },
  // { label: "Balite (Pob.)", mun_code: "045808" },
  // { label: "Burgos", mun_code: "045808" },
  // { label: "Geronimo", mun_code: "045808" },
  // { label: "Macabud", mun_code: "045808" },
  // { label: "Manggahan", mun_code: "045808" },
  // { label: "Mascap", mun_code: "045808" },
  // { label: "Puray", mun_code: "045808" },
  // { label: "Rosario", mun_code: "045808" },
  // { label: "San Isidro", mun_code: "045808" },
  // { label: "San Jose", mun_code: "045808" },
  // { label: "San Rafael", mun_code: "045808" },
  // { label: "Bombongan", mun_code: "045809" },
  // { label: "Can-Cal-Lan (Caniogan-Calero-Lanang)", mun_code: "045809" },
  // { label: "Lagundi", mun_code: "045809" },
  // { label: "Maybancal", mun_code: "045809" },
  // { label: "San Guillermo", mun_code: "045809" },
  // { label: "San Jose (Pob.)", mun_code: "045809" },
  // { label: "San Juan (Pob.)", mun_code: "045809" },
  // { label: "San Pedro (Pob.)", mun_code: "045809" },
  // { label: "Bagumbayan (Pob.)", mun_code: "045810" },
  // { label: "Halayhayin", mun_code: "045810" },
  // { label: "Hulo (Pob.)", mun_code: "045810" },
  // { label: "Imatong (Pob.)", mun_code: "045810" },
  // { label: "Malaya", mun_code: "045810" },
  // { label: "Niogan", mun_code: "045810" },
  // { label: "Quisao", mun_code: "045810" },
  // { label: "Wawa (Pob.)", mun_code: "045810" },
  // { label: "Takungan (Pob.)", mun_code: "045810" },
  // { label: "Ampid I", mun_code: "045811" },
  // { label: "Dulong Bayan 1", mun_code: "045811" },
  // { label: "Dulong Bayan 2", mun_code: "045811" },
  // { label: "Guinayang", mun_code: "045811" },
  // { label: "Guitnang Bayan I (Pob.)", mun_code: "045811" },
  // { label: "Guitnang Bayan II (Pob.)", mun_code: "045811" },
  // { label: "Malanday", mun_code: "045811" },
  // { label: "Maly", mun_code: "045811" },
  // { label: "Santa Ana", mun_code: "045811" },
  // { label: "Ampid II", mun_code: "045811" },
  // { label: "Banaba", mun_code: "045811" },
  // { label: "Gulod Malaya", mun_code: "045811" },
  // { label: "Pintong Bocawe", mun_code: "045811" },
  // { label: "Silangan", mun_code: "045811" },
  // { label: "Cayabu", mun_code: "045812" },
  // { label: "Cuyambay", mun_code: "045812" },
  // { label: "Daraitan", mun_code: "045812" },
  // { label: "Katipunan-Bayan (Pob.)", mun_code: "045812" },
  // { label: "Kaybuto (Pob.)", mun_code: "045812" },
  // { label: "Laiban", mun_code: "045812" },
  // { label: "Mag-Ampon (Pob.)", mun_code: "045812" },
  // { label: "Mamuyao", mun_code: "045812" },
  // { label: "Pinagkamaligan (Pob.)", mun_code: "045812" },
  // { label: "Plaza Aldea (Pob.)", mun_code: "045812" },
  // { label: "Sampaloc", mun_code: "045812" },
  // { label: "San Andres", mun_code: "045812" },
  // { label: "San Isidro (Pob.)", mun_code: "045812" },
  // { label: "Santa Inez", mun_code: "045812" },
  // { label: "Tabing Ilog (Pob.)", mun_code: "045812" },
  // { label: "Tandang Kutyo (Pob.)", mun_code: "045812" },
  // { label: "Tinucan", mun_code: "045812" },
  // { label: "Wawa (Pob.)", mun_code: "045812" },
  // { label: "Dolores (Pob.)", mun_code: "045813" },
  // { label: "Muzon", mun_code: "045813" },
  // { label: "San Isidro", mun_code: "045813" },
  // { label: "San Juan", mun_code: "045813" },
  // { label: "Santa Ana", mun_code: "045813" },
  // { label: "Bagumbayan", mun_code: "045814" },
  // { label: "Dalig", mun_code: "045814" },
  // { label: "Dulumbayan", mun_code: "045814" },
  // { label: "May-Iba", mun_code: "045814" },
  // { label: "Poblacion", mun_code: "045814" },
  // { label: "Prinza", mun_code: "045814" },
  // { label: "San Gabriel", mun_code: "045814" },
  // { label: "San Roque", mun_code: "045814" },
  // { label: "Calumpang Santo Cristo", mun_code: "045814" },
  // { label: "Agot", mun_code: "174001" },
  // { label: "Agumaymayan", mun_code: "174001" },
  // { label: "Amoingon", mun_code: "174001" },
  // { label: "Apitong", mun_code: "174001" },
  // { label: "Balagasan", mun_code: "174001" },
  // { label: "Balaring", mun_code: "174001" },
  // { label: "Balimbing", mun_code: "174001" },
  // { label: "Balogo", mun_code: "174001" },
  // { label: "Bangbangalon", mun_code: "174001" },
  // { label: "Bamban", mun_code: "174001" },
  // { label: "Bantad", mun_code: "174001" },
  // { label: "Bantay", mun_code: "174001" },
  // { label: "Bayuti", mun_code: "174001" },
  // { label: "Binunga", mun_code: "174001" },
  // { label: "Boi", mun_code: "174001" },
  // { label: "Boton", mun_code: "174001" },
  // { label: "Buliasnin", mun_code: "174001" },
  // { label: "Bunganay", mun_code: "174001" },
  // { label: "Maligaya", mun_code: "174001" },
  // { label: "Caganhao", mun_code: "174001" },
  // { label: "Canat", mun_code: "174001" },
  // { label: "Catubugan", mun_code: "174001" },
  // { label: "Cawit", mun_code: "174001" },
  // { label: "Daig", mun_code: "174001" },
  // { label: "Daypay", mun_code: "174001" },
  // { label: "Duyay", mun_code: "174001" },
  // { label: "Ihatub", mun_code: "174001" },
  // { label: "Isok II Pob. (Kalamias)", mun_code: "174001" },
  // { label: "Hinapulan", mun_code: "174001" },
  // { label: "Laylay", mun_code: "174001" },
  // { label: "Lupac", mun_code: "174001" },
  // { label: "Mahinhin", mun_code: "174001" },
  // { label: "Mainit", mun_code: "174001" },
  // { label: "Malbog", mun_code: "174001" },
  // { label: "Malusak (Pob.)", mun_code: "174001" },
  // { label: "Mansiwat", mun_code: "174001" },
  // { label: "Mataas Na Bayan (Pob.)", mun_code: "174001" },
  // { label: "Maybo", mun_code: "174001" },
  // { label: "Mercado (Pob.)", mun_code: "174001" },
  // { label: "Murallon (Pob.)", mun_code: "174001" },
  // { label: "Ogbac", mun_code: "174001" },
  // { label: "Pawa", mun_code: "174001" },
  // { label: "Pili", mun_code: "174001" },
  // { label: "Poctoy", mun_code: "174001" },
  // { label: "Poras", mun_code: "174001" },
  // { label: "Puting Buhangin", mun_code: "174001" },
  // { label: "Puyog", mun_code: "174001" },
  // { label: "Sabong", mun_code: "174001" },
  // { label: "San Miguel (Pob.)", mun_code: "174001" },
  // { label: "Santol", mun_code: "174001" },
  // { label: "Sawi", mun_code: "174001" },
  // { label: "Tabi", mun_code: "174001" },
  // { label: "Tabigue", mun_code: "174001" },
  // { label: "Tagwak", mun_code: "174001" },
  // { label: "Tambunan", mun_code: "174001" },
  // { label: "Tampus (Pob.)", mun_code: "174001" },
  // { label: "Tanza", mun_code: "174001" },
  // { label: "Tugos", mun_code: "174001" },
  // { label: "Tumagabok", mun_code: "174001" },
  // { label: "Tumapon", mun_code: "174001" },
  // { label: "Isok I (Pob.)", mun_code: "174001" },
  // { label: "Bagacay", mun_code: "174002" },
  // { label: "Bagtingon", mun_code: "174002" },
  // { label: "Bicas-bicas", mun_code: "174002" },
  // { label: "Caigangan", mun_code: "174002" },
  // { label: "Daykitin", mun_code: "174002" },
  // { label: "Libas", mun_code: "174002" },
  // { label: "Malbog", mun_code: "174002" },
  // { label: "Sihi", mun_code: "174002" },
  // { label: "Timbo (Sanggulong)", mun_code: "174002" },
  // { label: "Tungib-Lipata", mun_code: "174002" },
  // { label: "Yook", mun_code: "174002" },
  // { label: "Barangay I (Pob.)", mun_code: "174002" },
  // { label: "Barangay II (Pob.)", mun_code: "174002" },
  // { label: "Barangay III (Pob.)", mun_code: "174002" },
  // { label: "Barangay IV (Pob.)", mun_code: "174002" },
  // { label: "Antipolo", mun_code: "174003" },
  // { label: "Bachao Ibaba", mun_code: "174003" },
  // { label: "Bachao Ilaya", mun_code: "174003" },
  // { label: "Bacongbacong", mun_code: "174003" },
  // { label: "Bahi", mun_code: "174003" },
  // { label: "Bangbang", mun_code: "174003" },
  // { label: "Banot", mun_code: "174003" },
  // { label: "Banuyo", mun_code: "174003" },
  // { label: "Bognuyan", mun_code: "174003" },
  // { label: "Cabugao", mun_code: "174003" },
  // { label: "Dawis", mun_code: "174003" },
  // { label: "Dili", mun_code: "174003" },
  // { label: "Libtangin", mun_code: "174003" },
  // { label: "Mahunig", mun_code: "174003" },
  // { label: "Mangiliol", mun_code: "174003" },
  // { label: "Masiga", mun_code: "174003" },
  // { label: "Matandang Gasan", mun_code: "174003" },
  // { label: "Pangi", mun_code: "174003" },
  // { label: "Pingan", mun_code: "174003" },
  // { label: "Tabionan", mun_code: "174003" },
  // { label: "Tapuyan", mun_code: "174003" },
  // { label: "Tiguion", mun_code: "174003" },
  // { label: "Barangay I (Pob.)", mun_code: "174003" },
  // { label: "Barangay II (Pob.)", mun_code: "174003" },
  // { label: "Barangay III (Pob.)", mun_code: "174003" },
  // { label: "Anapog-Sibucao", mun_code: "174004" },
  // { label: "Argao", mun_code: "174004" },
  // { label: "Balanacan", mun_code: "174004" },
  // { label: "Banto", mun_code: "174004" },
  // { label: "Bintakay", mun_code: "174004" },
  // { label: "Bocboc", mun_code: "174004" },
  // { label: "Butansapa", mun_code: "174004" },
  // { label: "Candahon", mun_code: "174004" },
  // { label: "Capayang", mun_code: "174004" },
  // { label: "Danao", mun_code: "174004" },
  // { label: "Dulong Bayan (Pob.)", mun_code: "174004" },
  // { label: "Gitnang Bayan (Pob.)", mun_code: "174004" },
  // { label: "Guisian", mun_code: "174004" },
  // { label: "Hinadharan", mun_code: "174004" },
  // { label: "Hinanggayon", mun_code: "174004" },
  // { label: "Ino", mun_code: "174004" },
  // { label: "Janagdong", mun_code: "174004" },
  // { label: "Lamesa", mun_code: "174004" },
  // { label: "Laon", mun_code: "174004" },
  // { label: "Magapua", mun_code: "174004" },
  // { label: "Malayak", mun_code: "174004" },
  // { label: "Malusak", mun_code: "174004" },
  // { label: "Mampaitan", mun_code: "174004" },
  // { label: "Mangyan-Mababad", mun_code: "174004" },
  // { label: "Market Site (Pob.)", mun_code: "174004" },
  // { label: "Mataas Na Bayan", mun_code: "174004" },
  // { label: "Mendez", mun_code: "174004" },
  // { label: "Nangka I", mun_code: "174004" },
  // { label: "Nangka II", mun_code: "174004" },
  // { label: "Paye", mun_code: "174004" },
  // { label: "Pili", mun_code: "174004" },
  // { label: "Puting Buhangin", mun_code: "174004" },
  // { label: "Sayao", mun_code: "174004" },
  // { label: "Silangan", mun_code: "174004" },
  // { label: "Sumangga", mun_code: "174004" },
  // { label: "Tarug", mun_code: "174004" },
  // { label: "Villa Mendez (Pob.)", mun_code: "174004" },
  // { label: "Alobo", mun_code: "174005" },
  // { label: "Angas", mun_code: "174005" },
  // { label: "Aturan", mun_code: "174005" },
  // { label: "Bagong Silang Pob. (2nd Zone)", mun_code: "174005" },
  // { label: "Baguidbirin", mun_code: "174005" },
  // { label: "Baliis", mun_code: "174005" },
  // { label: "Balogo", mun_code: "174005" },
  // { label: "Banahaw Pob. (3rd Zone)", mun_code: "174005" },
  // { label: "Bangcuangan", mun_code: "174005" },
  // { label: "Banogbog", mun_code: "174005" },
  // { label: "Biga", mun_code: "174005" },
  // { label: "Botilao", mun_code: "174005" },
  // { label: "Buyabod", mun_code: "174005" },
  // { label: "Dating Bayan", mun_code: "174005" },
  // { label: "Devilla", mun_code: "174005" },
  // { label: "Dolores", mun_code: "174005" },
  // { label: "Haguimit", mun_code: "174005" },
  // { label: "Hupi", mun_code: "174005" },
  // { label: "Ipil", mun_code: "174005" },
  // { label: "Jolo", mun_code: "174005" },
  // { label: "Kaganhao", mun_code: "174005" },
  // { label: "Kalangkang", mun_code: "174005" },
  // { label: "Kamandugan", mun_code: "174005" },
  // { label: "Kasily", mun_code: "174005" },
  // { label: "Kilo-kilo", mun_code: "174005" },
  // { label: "Labo", mun_code: "174005" },
  // { label: "Lamesa", mun_code: "174005" },
  // { label: "Landy", mun_code: "174005" },
  // { label: "Lapu-lapu Pob. (5th Zone)", mun_code: "174005" },
  // { label: "Libjo", mun_code: "174005" },
  // { label: "Lipa", mun_code: "174005" },
  // { label: "Lusok", mun_code: "174005" },
  // { label: "Maharlika Pob. (1st Zone)", mun_code: "174005" },
  // { label: "Makulapnit", mun_code: "174005" },
  // { label: "Maniwaya", mun_code: "174005" },
  // { label: "Manlibunan", mun_code: "174005" },
  // { label: "Masaguisi", mun_code: "174005" },
  // { label: "Masalukot", mun_code: "174005" },
  // { label: "Matalaba", mun_code: "174005" },
  // { label: "Mongpong", mun_code: "174005" },
  // { label: "Morales", mun_code: "174005" },
  // { label: "Napo", mun_code: "174005" },
  // { label: "Pag-Asa Pob. (4th Zone)", mun_code: "174005" },
  // { label: "Pantayin", mun_code: "174005" },
  // { label: "Polo", mun_code: "174005" },
  // { label: "Pulong-Parang", mun_code: "174005" },
  // { label: "Punong", mun_code: "174005" },
  // { label: "San Antonio", mun_code: "174005" },
  // { label: "San Isidro", mun_code: "174005" },
  // { label: "Tagum", mun_code: "174005" },
  // { label: "Tamayo", mun_code: "174005" },
  // { label: "Tambangan", mun_code: "174005" },
  // { label: "Tawiran", mun_code: "174005" },
  // { label: "Taytay", mun_code: "174005" },
  // { label: "Bangwayin", mun_code: "174006" },
  // { label: "Bayakbakin", mun_code: "174006" },
  // { label: "Bolo", mun_code: "174006" },
  // { label: "Bonliw", mun_code: "174006" },
  // { label: "Buangan", mun_code: "174006" },
  // { label: "Cabuyo", mun_code: "174006" },
  // { label: "Cagpo", mun_code: "174006" },
  // { label: "Dampulan", mun_code: "174006" },
  // { label: "Kay Duke", mun_code: "174006" },
  // { label: "Mabuhay", mun_code: "174006" },
  // { label: "Makawayan", mun_code: "174006" },
  // { label: "Malibago", mun_code: "174006" },
  // { label: "Malinao", mun_code: "174006" },
  // { label: "Maranlig", mun_code: "174006" },
  // { label: "Marlangga", mun_code: "174006" },
  // { label: "Matuyatuya", mun_code: "174006" },
  // { label: "Nangka", mun_code: "174006" },
  // { label: "Pakaskasan", mun_code: "174006" },
  // { label: "Payanas", mun_code: "174006" },
  // { label: "Poblacion", mun_code: "174006" },
  // { label: "Poctoy", mun_code: "174006" },
  // { label: "Sibuyao", mun_code: "174006" },
  // { label: "Suha", mun_code: "174006" },
  // { label: "Talawan", mun_code: "174006" },
  // { label: "Tigwi", mun_code: "174006" },
  // { label: "Balao", mun_code: "175101" },
  // { label: "Cabacao", mun_code: "175101" },
  // { label: "Lumangbayan", mun_code: "175101" },
  // { label: "Poblacion", mun_code: "175101" },
  // { label: "San Vicente", mun_code: "175101" },
  // { label: "Tibag", mun_code: "175101" },
  // { label: "Udalo (Camurong)", mun_code: "175101" },
  // { label: "Wawa", mun_code: "175101" },
  // { label: "Armado", mun_code: "175101" },
  // { label: "Santa Maria", mun_code: "175101" },
  // { label: "Concepcion", mun_code: "175102" },
  // { label: "Iriron", mun_code: "175102" },
  // { label: "Malpalon", mun_code: "175102" },
  // { label: "New Dagupan", mun_code: "175102" },
  // { label: "Poblacion", mun_code: "175102" },
  // { label: "Poypoy", mun_code: "175102" },
  // { label: "Tanyag", mun_code: "175102" },
  // { label: "Agkawayan", mun_code: "175103" },
  // { label: "Ambil", mun_code: "175103" },
  // { label: "Balikyas", mun_code: "175103" },
  // { label: "Bonbon (Pob.)", mun_code: "175103" },
  // { label: "Bulacan", mun_code: "175103" },
  // { label: "Burol", mun_code: "175103" },
  // { label: "Guitna (Pob.)", mun_code: "175103" },
  // { label: "Kanluran (Pob.)", mun_code: "175103" },
  // { label: "Talaotao", mun_code: "175103" },
  // { label: "Binakas", mun_code: "175104" },
  // { label: "Cabra", mun_code: "175104" },
  // { label: "Maligaya", mun_code: "175104" },
  // { label: "Maliig", mun_code: "175104" },
  // { label: "Tagbac", mun_code: "175104" },
  // { label: "Tangal", mun_code: "175104" },
  // { label: "Tilik", mun_code: "175104" },
  // { label: "Vigo", mun_code: "175104" },
  // { label: "Surville (Pob.)", mun_code: "175104" },
  // { label: "Araw At Bituin (Pob.)", mun_code: "175104" },
  // { label: "Bagong Sikat (Pob.)", mun_code: "175104" },
  // { label: "Banaag At Pag-Asa (Pob.)", mun_code: "175104" },
  // { label: "Likas Ng Silangan (Pob.)", mun_code: "175104" },
  // { label: "Maginhawa (Pob.)", mun_code: "175104" },
  // { label: "Ninikat Ng Pag-Asa (Pob.)", mun_code: "175104" },
  // { label: "Paraiso (Pob.)", mun_code: "175104" },
  // { label: "Alibog", mun_code: "175105" },
  // { label: "Caguray", mun_code: "175105" },
  // { label: "Calawag", mun_code: "175105" },
  // { label: "Gapasan", mun_code: "175105" },
  // { label: "Laste", mun_code: "175105" },
  // { label: "Lourdes", mun_code: "175105" },
  // { label: "Nicolas (Bulo)", mun_code: "175105" },
  // { label: "Paclolo", mun_code: "175105" },
  // { label: "Poblacion", mun_code: "175105" },
  // { label: "Purnaga", mun_code: "175105" },
  // { label: "Santa Teresa", mun_code: "175105" },
  // { label: "Sibalat", mun_code: "175105" },
  // { label: "Balansay", mun_code: "175106" },
  // { label: "Fatima (Tii)", mun_code: "175106" },
  // { label: "Payompon", mun_code: "175106" },
  // { label: "San Luis (Ligang)", mun_code: "175106" },
  // { label: "Talabaan", mun_code: "175106" },
  // { label: "Tangkalan", mun_code: "175106" },
  // { label: "Tayamaan", mun_code: "175106" },
  // { label: "Poblacion 1 (Barangay 1)", mun_code: "175106" },
  // { label: "Poblacion 2 (Barangay 2)", mun_code: "175106" },
  // { label: "Poblacion 3 (Barangay 3)", mun_code: "175106" },
  // { label: "Poblacion 4 (Barangay 4)", mun_code: "175106" },
  // { label: "Poblacion 5 (Barangay 5)", mun_code: "175106" },
  // { label: "Poblacion 6 (Barangay 6)", mun_code: "175106" },
  // { label: "Poblacion 7 (Barangay 7)", mun_code: "175106" },
  // { label: "Poblacion 8 (Barangay 8)", mun_code: "175106" },
  // { label: "Alipaoy", mun_code: "175107" },
  // { label: "Harrison", mun_code: "175107" },
  // { label: "Lumangbayan", mun_code: "175107" },
  // { label: "Mananao", mun_code: "175107" },
  // { label: "Marikit", mun_code: "175107" },
  // { label: "Mapalad Pob. (Bgy 1)", mun_code: "175107" },
  // { label: "Handang Tumulong Pob. (Bgy 2)", mun_code: "175107" },
  // { label: "Silahis Ng Pag-Asa Pob. (Bgy 3)", mun_code: "175107" },
  // { label: "Pag-Asa Ng Bayan Pob. (Bgy 4)", mun_code: "175107" },
  // { label: "Bagong Silang Pob. (Bgy 5)", mun_code: "175107" },
  // { label: "San Jose Pob. (Bgy 6)", mun_code: "175107" },
  // { label: "Tubili", mun_code: "175107" },
  // { label: "Adela", mun_code: "175108" },
  // { label: "Aguas", mun_code: "175108" },
  // { label: "Magsikap", mun_code: "175108" },
  // { label: "Malawaan", mun_code: "175108" },
  // { label: "Pitogo", mun_code: "175108" },
  // { label: "Rizal", mun_code: "175108" },
  // { label: "Rumbang", mun_code: "175108" },
  // { label: "Salvacion", mun_code: "175108" },
  // { label: "San Pedro", mun_code: "175108" },
  // { label: "Manoot", mun_code: "175108" },
  // { label: "Batong Buhay", mun_code: "175109" },
  // { label: "Buenavista", mun_code: "175109" },
  // { label: "Burgos", mun_code: "175109" },
  // { label: "Claudio Salgado", mun_code: "175109" },
  // { label: "General Emilio Aguinaldo", mun_code: "175109" },
  // { label: "Ibud", mun_code: "175109" },
  // { label: "Ilvita", mun_code: "175109" },
  // { label: "Ligaya", mun_code: "175109" },
  // { label: "Poblacion (Lumangbayan)", mun_code: "175109" },
  // { label: "Paetan", mun_code: "175109" },
  // { label: "Pag-Asa", mun_code: "175109" },
  // { label: "San Agustin", mun_code: "175109" },
  // { label: "San Francisco", mun_code: "175109" },
  // { label: "San Nicolas", mun_code: "175109" },
  // { label: "San Vicente", mun_code: "175109" },
  // { label: "Santa Lucia", mun_code: "175109" },
  // { label: "Tagumpay", mun_code: "175109" },
  // { label: "Victoria", mun_code: "175109" },
  // { label: "Lagnas", mun_code: "175109" },
  // { label: "Malisbong", mun_code: "175109" },
  // { label: "Tuban", mun_code: "175109" },
  // { label: "Ambulong", mun_code: "175110" },
  // { label: "Ansiray", mun_code: "175110" },
  // { label: "Bagong Sikat", mun_code: "175110" },
  // { label: "Bangkal", mun_code: "175110" },
  // { label: "Barangay 1 (Pob.)", mun_code: "175110" },
  // { label: "Barangay 2 (Pob.)", mun_code: "175110" },
  // { label: "Barangay 3 (Pob.)", mun_code: "175110" },
  // { label: "Barangay 4 (Pob.)", mun_code: "175110" },
  // { label: "Barangay 5 (Pob.)", mun_code: "175110" },
  // { label: "Barangay 6 (Pob.)", mun_code: "175110" },
  // { label: "Barangay 7 (Pob.)", mun_code: "175110" },
  // { label: "Barangay 8 (Pob.)", mun_code: "175110" },
  // { label: "Batasan", mun_code: "175110" },
  // { label: "Bayotbot", mun_code: "175110" },
  // { label: "Bubog", mun_code: "175110" },
  // { label: "Buri", mun_code: "175110" },
  // { label: "Camburay", mun_code: "175110" },
  // { label: "Caminawit", mun_code: "175110" },
  // { label: "Catayungan", mun_code: "175110" },
  // { label: "Central", mun_code: "175110" },
  // { label: "Iling Proper", mun_code: "175110" },
  // { label: "Inasakan", mun_code: "175110" },
  // { label: "Ipil", mun_code: "175110" },
  // { label: "La Curva", mun_code: "175110" },
  // { label: "Labangan Iling", mun_code: "175110" },
  // { label: "Labangan Poblacion", mun_code: "175110" },
  // { label: "Mabini", mun_code: "175110" },
  // { label: "Magbay", mun_code: "175110" },
  // { label: "Mangarin", mun_code: "175110" },
  // { label: "Mapaya", mun_code: "175110" },
  // { label: "Murtha", mun_code: "175110" },
  // { label: "Monte Claro", mun_code: "175110" },
  // { label: "Natandol", mun_code: "175110" },
  // { label: "Pag-Asa", mun_code: "175110" },
  // { label: "Pawican", mun_code: "175110" },
  // { label: "San Agustin", mun_code: "175110" },
  // { label: "San Isidro", mun_code: "175110" },
  // { label: "San Roque", mun_code: "175110" },
  // { label: "Alacaak", mun_code: "175111" },
  // { label: "Barahan", mun_code: "175111" },
  // { label: "Casague", mun_code: "175111" },
  // { label: "Dayap", mun_code: "175111" },
  // { label: "Lumangbayan", mun_code: "175111" },
  // { label: "Mulawin", mun_code: "175111" },
  // { label: "Pinagturilan (San Pedro)", mun_code: "175111" },
  // { label: "Poblacion I (Barangay 1)", mun_code: "175111" },
  // { label: "San Vicente", mun_code: "175111" },
  // { label: "Poblacion II (Barangay 2)", mun_code: "175111" },
  // { label: "Kurtinganan", mun_code: "175111" },
  // { label: "Alag", mun_code: "175201" },
  // { label: "Bangkatan", mun_code: "175201" },
  // { label: "Burbuli", mun_code: "175201" },
  // { label: "Catwiran I", mun_code: "175201" },
  // { label: "Catwiran II", mun_code: "175201" },
  // { label: "Dulangan I", mun_code: "175201" },
  // { label: "Dulangan II", mun_code: "175201" },
  // { label: "Lumang Bayan", mun_code: "175201" },
  // { label: "Malapad", mun_code: "175201" },
  // { label: "Mangangan I", mun_code: "175201" },
  // { label: "Mangangan II", mun_code: "175201" },
  // { label: "Mayabig", mun_code: "175201" },
  // { label: "Pambisan", mun_code: "175201" },
  // { label: "Pulang-Tubig", mun_code: "175201" },
  // { label: "Putican-Cabulo", mun_code: "175201" },
  // { label: "San Andres", mun_code: "175201" },
  // { label: "San Ignacio (Dulangan III)", mun_code: "175201" },
  // { label: "Santa Cruz", mun_code: "175201" },
  // { label: "Santa Rosa I", mun_code: "175201" },
  // { label: "Santa Rosa II", mun_code: "175201" },
  // { label: "Tabon-tabon", mun_code: "175201" },
  // { label: "Tagumpay", mun_code: "175201" },
  // { label: "Water", mun_code: "175201" },
  // { label: "Baras (Mangyan Minority)", mun_code: "175201" },
  // { label: "Bayanan", mun_code: "175201" },
  // { label: "Lantuyang (Mangyan Minority)", mun_code: "175201" },
  // { label: "Poblacion", mun_code: "175201" },
  // { label: "Alcadesma", mun_code: "175202" },
  // { label: "Bato", mun_code: "175202" },
  // { label: "Conrazon", mun_code: "175202" },
  // { label: "Malo", mun_code: "175202" },
  // { label: "Manihala", mun_code: "175202" },
  // { label: "Pag-Asa", mun_code: "175202" },
  // { label: "Poblacion", mun_code: "175202" },
  // { label: "Proper Bansud", mun_code: "175202" },
  // { label: "Rosacara", mun_code: "175202" },
  // { label: "Salcedo", mun_code: "175202" },
  // { label: "Sumagui", mun_code: "175202" },
  // { label: "Proper Tiguisan", mun_code: "175202" },
  // { label: "Villa Pag-Asa", mun_code: "175202" },
  // { label: "Anilao", mun_code: "175203" },
  // { label: "Batangan", mun_code: "175203" },
  // { label: "Bukal", mun_code: "175203" },
  // { label: "Camantigue", mun_code: "175203" },
  // { label: "Carmundo", mun_code: "175203" },
  // { label: "Cawayan", mun_code: "175203" },
  // { label: "Dayhagan", mun_code: "175203" },
  // { label: "Formon", mun_code: "175203" },
  // { label: "Hagan", mun_code: "175203" },
  // { label: "Hagupit", mun_code: "175203" },
  // { label: "Kaligtasan", mun_code: "175203" },
  // { label: "Labasan", mun_code: "175203" },
  // { label: "Labonan", mun_code: "175203" },
  // { label: "Libertad", mun_code: "175203" },
  // { label: "Lisap", mun_code: "175203" },
  // { label: "Luna", mun_code: "175203" },
  // { label: "Malitbog", mun_code: "175203" },
  // { label: "Mapang", mun_code: "175203" },
  // { label: "Masaguisi", mun_code: "175203" },
  // { label: "Morente", mun_code: "175203" },
  // { label: "Ogbot", mun_code: "175203" },
  // { label: "Orconuma", mun_code: "175203" },
  // { label: "Polusahi", mun_code: "175203" },
  // { label: "Sagana", mun_code: "175203" },
  // { label: "San Isidro", mun_code: "175203" },
  // { label: "San Jose", mun_code: "175203" },
  // { label: "San Juan", mun_code: "175203" },
  // { label: "Santa Cruz", mun_code: "175203" },
  // { label: "Sigange", mun_code: "175203" },
  // { label: "Tawas", mun_code: "175203" },
  // { label: "Poblacion", mun_code: "175203" },
  // { label: "Aplaya", mun_code: "175203" },
  // { label: "Bagumbayan I", mun_code: "175203" },
  // { label: "Bagumbayan II", mun_code: "175203" },
  // { label: "Ipil", mun_code: "175203" },
  // { label: "Mina de Oro", mun_code: "175203" },
  // { label: "Bagong Sikat", mun_code: "175204" },
  // { label: "Balatasan", mun_code: "175204" },
  // { label: "Benli (Mangyan Settlement)", mun_code: "175204" },
  // { label: "Cabugao", mun_code: "175204" },
  // { label: "Cambunang (Pob.)", mun_code: "175204" },
  // { label: "Campaasan (Pob.)", mun_code: "175204" },
  // { label: "Maasin", mun_code: "175204" },
  // { label: "Maujao", mun_code: "175204" },
  // { label: "Milagrosa (Guiob)", mun_code: "175204" },
  // { label: "Nasukob (Pob.)", mun_code: "175204" },
  // { label: "Poblacion", mun_code: "175204" },
  // { label: "San Francisco (Alimawan)", mun_code: "175204" },
  // { label: "San Isidro", mun_code: "175204" },
  // { label: "San Juan", mun_code: "175204" },
  // { label: "San Roque (Buyayao)", mun_code: "175204" },
  // { label: "Balingayan", mun_code: "175205" },
  // { label: "Balite", mun_code: "175205" },
  // { label: "Baruyan", mun_code: "175205" },
  // { label: "Batino", mun_code: "175205" },
  // { label: "Bayanan I", mun_code: "175205" },
  // { label: "Bayanan II", mun_code: "175205" },
  // { label: "Biga", mun_code: "175205" },
  // { label: "Bondoc", mun_code: "175205" },
  // { label: "Bucayao", mun_code: "175205" },
  // { label: "Buhuan", mun_code: "175205" },
  // { label: "Bulusan", mun_code: "175205" },
  // { label: "Santa Rita (Bungahan)", mun_code: "175205" },
  // { label: "Calero (Pob.)", mun_code: "175205" },
  // { label: "Camansihan", mun_code: "175205" },
  // { label: "Camilmil", mun_code: "175205" },
  // { label: "Canubing I", mun_code: "175205" },
  // { label: "Canubing II", mun_code: "175205" },
  // { label: "Comunal", mun_code: "175205" },
  // { label: "Guinobatan", mun_code: "175205" },
  // { label: "Gulod", mun_code: "175205" },
  // { label: "Gutad", mun_code: "175205" },
  // { label: "Ibaba East (Pob.)", mun_code: "175205" },
  // { label: "Ibaba West (Pob.)", mun_code: "175205" },
  // { label: "Ilaya (Pob.)", mun_code: "175205" },
  // { label: "Lalud", mun_code: "175205" },
  // { label: "Lazareto", mun_code: "175205" },
  // { label: "Libis (Pob.)", mun_code: "175205" },
  // { label: "Lumang Bayan", mun_code: "175205" },
  // { label: "Mahal Na Pangalan", mun_code: "175205" },
  // { label: "Maidlang", mun_code: "175205" },
  // { label: "Malad", mun_code: "175205" },
  // { label: "Malamig", mun_code: "175205" },
  // { label: "Managpi", mun_code: "175205" },
  // { label: "Masipit", mun_code: "175205" },
  // { label: "Nag-Iba I", mun_code: "175205" },
  // { label: "Navotas", mun_code: "175205" },
  // { label: "Pachoca", mun_code: "175205" },
  // { label: "Palhi", mun_code: "175205" },
  // { label: "Panggalaan", mun_code: "175205" },
  // { label: "Parang", mun_code: "175205" },
  // { label: "Patas", mun_code: "175205" },
  // { label: "Personas", mun_code: "175205" },
  // { label: "Putingtubig", mun_code: "175205" },
  // { label: "Salong", mun_code: "175205" },
  // { label: "San Antonio", mun_code: "175205" },
  // { label: "San Vicente Central (Pob.)", mun_code: "175205" },
  // { label: "San Vicente East (Pob.)", mun_code: "175205" },
  // { label: "San Vicente North (Pob.)", mun_code: "175205" },
  // { label: "San Vicente South (Pob.)", mun_code: "175205" },
  // { label: "San Vicente West (Pob.)", mun_code: "175205" },
  // { label: "Santa Cruz", mun_code: "175205" },
  // { label: "Santa Isabel", mun_code: "175205" },
  // { label: "Sapul", mun_code: "175205" },
  // { label: "Silonay", mun_code: "175205" },
  // { label: "Santa Maria Village", mun_code: "175205" },
  // { label: "Suqui", mun_code: "175205" },
  // { label: "Tawagan", mun_code: "175205" },
  // { label: "Tawiran", mun_code: "175205" },
  // { label: "Tibag", mun_code: "175205" },
  // { label: "Wawa", mun_code: "175205" },
  // { label: "Nag-Iba II", mun_code: "175205" },
  // { label: "Agsalin", mun_code: "175206" },
  // { label: "Agos", mun_code: "175206" },
  // { label: "Andres Bonifacio", mun_code: "175206" },
  // { label: "Balete", mun_code: "175206" },
  // { label: "Banus", mun_code: "175206" },
  // { label: "Banutan", mun_code: "175206" },
  // { label: "Buong Lupa", mun_code: "175206" },
  // { label: "Bulaklakan", mun_code: "175206" },
  // { label: "Gaudencio Antonino", mun_code: "175206" },
  // { label: "Guimbonan", mun_code: "175206" },
  // { label: "Kawit", mun_code: "175206" },
  // { label: "Lucio Laurel", mun_code: "175206" },
  // { label: "Macario Adriatico", mun_code: "175206" },
  // { label: "Malamig", mun_code: "175206" },
  // { label: "Malayong", mun_code: "175206" },
  // { label: "Maligaya (Pob.)", mun_code: "175206" },
  // { label: "Malubay", mun_code: "175206" },
  // { label: "Manguyang", mun_code: "175206" },
  // { label: "Maragooc", mun_code: "175206" },
  // { label: "Mirayan", mun_code: "175206" },
  // { label: "Narra", mun_code: "175206" },
  // { label: "Papandungin", mun_code: "175206" },
  // { label: "San Antonio", mun_code: "175206" },
  // { label: "Santa Maria", mun_code: "175206" },
  // { label: "Santa Theresa", mun_code: "175206" },
  // { label: "Tambong", mun_code: "175206" },
  // { label: "Alma Villa", mun_code: "175206" },
  // { label: "B. Del Mundo", mun_code: "175207" },
  // { label: "Balugo", mun_code: "175207" },
  // { label: "Bonbon", mun_code: "175207" },
  // { label: "Budburan", mun_code: "175207" },
  // { label: "Cabalwa", mun_code: "175207" },
  // { label: "Don Pedro", mun_code: "175207" },
  // { label: "Maliwanag", mun_code: "175207" },
  // { label: "Manaul", mun_code: "175207" },
  // { label: "Panaytayan", mun_code: "175207" },
  // { label: "Poblacion", mun_code: "175207" },
  // { label: "Roma", mun_code: "175207" },
  // { label: "Santa Brigida", mun_code: "175207" },
  // { label: "Santa Maria", mun_code: "175207" },
  // { label: "Villa Celestial", mun_code: "175207" },
  // { label: "Wasig", mun_code: "175207" },
  // { label: "Santa Teresita", mun_code: "175207" },
  // { label: "Waygan", mun_code: "175207" },
  // { label: "Adrialuna", mun_code: "175208" },
  // { label: "Antipolo", mun_code: "175208" },
  // { label: "Apitong", mun_code: "175208" },
  // { label: "Arangin", mun_code: "175208" },
  // { label: "Aurora", mun_code: "175208" },
  // { label: "Bacungan", mun_code: "175208" },
  // { label: "Bagong Buhay", mun_code: "175208" },
  // { label: "Bancuro", mun_code: "175208" },
  // { label: "Barcenaga", mun_code: "175208" },
  // { label: "Bayani", mun_code: "175208" },
  // { label: "Buhangin", mun_code: "175208" },
  // { label: "Concepcion", mun_code: "175208" },
  // { label: "Dao", mun_code: "175208" },
  // { label: "Del Pilar", mun_code: "175208" },
  // { label: "Estrella", mun_code: "175208" },
  // { label: "Evangelista", mun_code: "175208" },
  // { label: "Gamao", mun_code: "175208" },
  // { label: "General Esco", mun_code: "175208" },
  // { label: "Herrera", mun_code: "175208" },
  // { label: "Inarawan", mun_code: "175208" },
  // { label: "Kalinisan", mun_code: "175208" },
  // { label: "Laguna", mun_code: "175208" },
  // { label: "Mabini", mun_code: "175208" },
  // { label: "Andres Ilagan (Mag-asawang Tubig)", mun_code: "175208" },
  // { label: "Mahabang Parang", mun_code: "175208" },
  // { label: "Malaya", mun_code: "175208" },
  // { label: "Malinao", mun_code: "175208" },
  // { label: "Malvar", mun_code: "175208" },
  // { label: "Masagana", mun_code: "175208" },
  // { label: "Masaguing", mun_code: "175208" },
  // { label: "Melgar A", mun_code: "175208" },
  // { label: "Metolza", mun_code: "175208" },
  // { label: "Montelago", mun_code: "175208" },
  // { label: "Montemayor", mun_code: "175208" },
  // { label: "Motoderazo", mun_code: "175208" },
  // { label: "Mulawin", mun_code: "175208" },
  // { label: "Nag-Iba I", mun_code: "175208" },
  // { label: "Nag-Iba II", mun_code: "175208" },
  // { label: "Pagkakaisa", mun_code: "175208" },
  // { label: "Paniquian", mun_code: "175208" },
  // { label: "Pinagsabangan I", mun_code: "175208" },
  // { label: "Pinagsabangan II", mun_code: "175208" },
  // { label: "Poblacion I (Barangay I)", mun_code: "175208" },
  // { label: "Poblacion II (Barangay II)", mun_code: "175208" },
  // { label: "Poblacion III (Barangay III)", mun_code: "175208" },
  // { label: "Sampaguita", mun_code: "175208" },
  // { label: "San Agustin I", mun_code: "175208" },
  // { label: "San Agustin II", mun_code: "175208" },
  // { label: "San Andres", mun_code: "175208" },
  // { label: "San Antonio", mun_code: "175208" },
  // { label: "San Carlos", mun_code: "175208" },
  // { label: "San Isidro", mun_code: "175208" },
  // { label: "San Jose", mun_code: "175208" },
  // { label: "San Luis", mun_code: "175208" },
  // { label: "San Nicolas", mun_code: "175208" },
  // { label: "San Pedro", mun_code: "175208" },
  // { label: "Santa Isabel", mun_code: "175208" },
  // { label: "Santa Maria", mun_code: "175208" },
  // { label: "Santiago", mun_code: "175208" },
  // { label: "Tagumpay", mun_code: "175208" },
  // { label: "Tigkan", mun_code: "175208" },
  // { label: "Melgar B", mun_code: "175208" },
  // { label: "Santa Cruz", mun_code: "175208" },
  // { label: "Balite", mun_code: "175208" },
  // { label: "Banuton", mun_code: "175208" },
  // { label: "Caburo", mun_code: "175208" },
  // { label: "Magtibay", mun_code: "175208" },
  // { label: "Paitan", mun_code: "175208" },
  // { label: "Anoling", mun_code: "175209" },
  // { label: "Bacungan", mun_code: "175209" },
  // { label: "Bangbang", mun_code: "175209" },
  // { label: "Banilad", mun_code: "175209" },
  // { label: "Buli", mun_code: "175209" },
  // { label: "Cacawan", mun_code: "175209" },
  // { label: "Calingag", mun_code: "175209" },
  // { label: "Del Razon", mun_code: "175209" },
  // { label: "Inclanay", mun_code: "175209" },
  // { label: "Lumangbayan", mun_code: "175209" },
  // { label: "Malaya", mun_code: "175209" },
  // { label: "Maliangcog", mun_code: "175209" },
  // { label: "Maningcol", mun_code: "175209" },
  // { label: "Marayos", mun_code: "175209" },
  // { label: "Marfrancisco", mun_code: "175209" },
  // { label: "Nabuslot", mun_code: "175209" },
  // { label: "Pagalagala", mun_code: "175209" },
  // { label: "Palayan", mun_code: "175209" },
  // { label: "Pambisan Malaki", mun_code: "175209" },
  // { label: "Pambisan Munti", mun_code: "175209" },
  // { label: "Panggulayan", mun_code: "175209" },
  // { label: "Papandayan", mun_code: "175209" },
  // { label: "Pili", mun_code: "175209" },
  // { label: "Zone II (Pob.)", mun_code: "175209" },
  // { label: "Zone III (Pob.)", mun_code: "175209" },
  // { label: "Zone IV (Pob.)", mun_code: "175209" },
  // { label: "Quinabigan", mun_code: "175209" },
  // { label: "Ranzo", mun_code: "175209" },
  // { label: "Rosario", mun_code: "175209" },
  // { label: "Sabang", mun_code: "175209" },
  // { label: "Santa Isabel", mun_code: "175209" },
  // { label: "Santa Maria", mun_code: "175209" },
  // { label: "Santa Rita", mun_code: "175209" },
  // { label: "Wawa", mun_code: "175209" },
  // { label: "Zone I (Pob.)", mun_code: "175209" },
  // { label: "Guinhawa", mun_code: "175209" },
  // { label: "Bacawan", mun_code: "175210" },
  // { label: "Bacungan", mun_code: "175210" },
  // { label: "Batuhan", mun_code: "175210" },
  // { label: "Bayanan", mun_code: "175210" },
  // { label: "Biga", mun_code: "175210" },
  // { label: "Buhay Na Tubig", mun_code: "175210" },
  // { label: "Calubasanhon", mun_code: "175210" },
  // { label: "Calima", mun_code: "175210" },
  // { label: "Casiligan", mun_code: "175210" },
  // { label: "Malibago", mun_code: "175210" },
  // { label: "Maluanluan", mun_code: "175210" },
  // { label: "Matulatula", mun_code: "175210" },
  // { label: "Pahilahan", mun_code: "175210" },
  // { label: "Panikihan", mun_code: "175210" },
  // { label: "Zone I (Pob.)", mun_code: "175210" },
  // { label: "Zone II (Pob.)", mun_code: "175210" },
  // { label: "Pula", mun_code: "175210" },
  // { label: "Puting Cacao", mun_code: "175210" },
  // { label: "Tagbakin", mun_code: "175210" },
  // { label: "Tagumpay", mun_code: "175210" },
  // { label: "Tiguihan", mun_code: "175210" },
  // { label: "Campamento", mun_code: "175210" },
  // { label: "Misong", mun_code: "175210" },
  // { label: "Aninuan", mun_code: "175211" },
  // { label: "Balatero", mun_code: "175211" },
  // { label: "Dulangan", mun_code: "175211" },
  // { label: "Palangan", mun_code: "175211" },
  // { label: "Sabang", mun_code: "175211" },
  // { label: "San Antonio", mun_code: "175211" },
  // { label: "San Isidro", mun_code: "175211" },
  // { label: "Sinandigan", mun_code: "175211" },
  // { label: "Tabinay", mun_code: "175211" },
  // { label: "Villaflor", mun_code: "175211" },
  // { label: "Poblacion", mun_code: "175211" },
  // { label: "Baclayan", mun_code: "175211" },
  // { label: "Bagumbayan (Pob.)", mun_code: "175212" },
  // { label: "Cantil", mun_code: "175212" },
  // { label: "Dangay", mun_code: "175212" },
  // { label: "Happy Valley", mun_code: "175212" },
  // { label: "Libertad", mun_code: "175212" },
  // { label: "Libtong", mun_code: "175212" },
  // { label: "Mabuhay", mun_code: "175212" },
  // { label: "Maraska", mun_code: "175212" },
  // { label: "Odiong", mun_code: "175212" },
  // { label: "Paclasan (Pob.)", mun_code: "175212" },
  // { label: "San Aquilino", mun_code: "175212" },
  // { label: "San Isidro", mun_code: "175212" },
  // { label: "San Jose", mun_code: "175212" },
  // { label: "San Mariano", mun_code: "175212" },
  // { label: "San Miguel", mun_code: "175212" },
  // { label: "San Rafael", mun_code: "175212" },
  // { label: "San Vicente", mun_code: "175212" },
  // { label: "Uyao", mun_code: "175212" },
  // { label: "Victoria", mun_code: "175212" },
  // { label: "Little Tanauan", mun_code: "175212" },
  // { label: "Bigaan", mun_code: "175213" },
  // { label: "Calangatan", mun_code: "175213" },
  // { label: "Calsapa", mun_code: "175213" },
  // { label: "Ilag", mun_code: "175213" },
  // { label: "Lumangbayan", mun_code: "175213" },
  // { label: "Tacligan", mun_code: "175213" },
  // { label: "Poblacion", mun_code: "175213" },
  // { label: "Caagutayan", mun_code: "175213" },
  // { label: "Bagsok", mun_code: "175214" },
  // { label: "Batong Dalig", mun_code: "175214" },
  // { label: "Bayuin", mun_code: "175214" },
  // { label: "Calocmoy", mun_code: "175214" },
  // { label: "Catiningan", mun_code: "175214" },
  // { label: "Villareal (Daan)", mun_code: "175214" },
  // { label: "Fortuna", mun_code: "175214" },
  // { label: "Happy Valley", mun_code: "175214" },
  // { label: "Calubayan", mun_code: "175214" },
  // { label: "Leuteboro I", mun_code: "175214" },
  // { label: "Leuteboro II", mun_code: "175214" },
  // { label: "Mabuhay I", mun_code: "175214" },
  // { label: "Malugay", mun_code: "175214" },
  // { label: "Matungao", mun_code: "175214" },
  // { label: "Monteverde", mun_code: "175214" },
  // { label: "Pasi I", mun_code: "175214" },
  // { label: "Pasi II", mun_code: "175214" },
  // { label: "Zone I (Pob.)", mun_code: "175214" },
  // { label: "Zone II (Pob.)", mun_code: "175214" },
  // { label: "Zone III (Pob.)", mun_code: "175214" },
  // { label: "Zone IV (Pob.)", mun_code: "175214" },
  // { label: "Santo Domingo (Lapog)", mun_code: "175214" },
  // { label: "Subaan", mun_code: "175214" },
  // { label: "Bugtong Na Tuog", mun_code: "175214" },
  // { label: "Mabuhay II", mun_code: "175214" },
  // { label: "Ma. Concepcion", mun_code: "175214" },
  // { label: "Alcate", mun_code: "175215" },
  // { label: "Babangonan", mun_code: "175215" },
  // { label: "Bagong Silang", mun_code: "175215" },
  // { label: "Bagong Buhay", mun_code: "175215" },
  // { label: "Bambanin", mun_code: "175215" },
  // { label: "Bethel", mun_code: "175215" },
  // { label: "Canaan", mun_code: "175215" },
  // { label: "Concepcion", mun_code: "175215" },
  // { label: "Duongan", mun_code: "175215" },
  // { label: "Loyal", mun_code: "175215" },
  // { label: "Mabini", mun_code: "175215" },
  // { label: "Macatoc", mun_code: "175215" },
  // { label: "Malabo", mun_code: "175215" },
  // { label: "Merit", mun_code: "175215" },
  // { label: "Ordovilla", mun_code: "175215" },
  // { label: "Pakyas", mun_code: "175215" },
  // { label: "Poblacion I", mun_code: "175215" },
  // { label: "Poblacion II", mun_code: "175215" },
  // { label: "Poblacion III", mun_code: "175215" },
  // { label: "Poblacion IV", mun_code: "175215" },
  // { label: "Sampaguita", mun_code: "175215" },
  // { label: "San Antonio", mun_code: "175215" },
  // { label: "San Gabriel", mun_code: "175215" },
  // { label: "San Gelacio", mun_code: "175215" },
  // { label: "San Isidro", mun_code: "175215" },
  // { label: "San Juan", mun_code: "175215" },
  // { label: "San Narciso", mun_code: "175215" },
  // { label: "Urdaneta", mun_code: "175215" },
  // { label: "Villa Cerveza", mun_code: "175215" },
  // { label: "Jose Leido Jr.", mun_code: "175215" },
  // { label: "San Cristobal", mun_code: "175215" },
  // { label: "Antonino", mun_code: "175215" },
  // { label: "Apo-Aporawan", mun_code: "175301" },
  // { label: "Apoc-apoc", mun_code: "175301" },
  // { label: "Aporawan", mun_code: "175301" },
  // { label: "Barake", mun_code: "175301" },
  // { label: "Cabigaan", mun_code: "175301" },
  // { label: "Gogognan", mun_code: "175301" },
  // { label: "Iraan", mun_code: "175301" },
  // { label: "Isaub", mun_code: "175301" },
  // { label: "Jose Rizal", mun_code: "175301" },
  // { label: "Mabini", mun_code: "175301" },
  // { label: "Magbabadil", mun_code: "175301" },
  // { label: "Plaridel", mun_code: "175301" },
  // { label: "Ramon Magsaysay", mun_code: "175301" },
  // { label: "Sagpangan", mun_code: "175301" },
  // { label: "San Juan", mun_code: "175301" },
  // { label: "Tagpait", mun_code: "175301" },
  // { label: "Tigman", mun_code: "175301" },
  // { label: "Poblacion", mun_code: "175301" },
  // { label: "Culandanum", mun_code: "175301" },
  // { label: "Algeciras", mun_code: "175302" },
  // { label: "Concepcion", mun_code: "175302" },
  // { label: "Diit", mun_code: "175302" },
  // { label: "Maracanao", mun_code: "175302" },
  // { label: "Matarawis", mun_code: "175302" },
  // { label: "Abagat (Pob.)", mun_code: "175302" },
  // { label: "Bangcal (Pob.)", mun_code: "175302" },
  // { label: "Cambian (Pob.)", mun_code: "175302" },
  // { label: "Villafria", mun_code: "175302" },
  // { label: "Villasol", mun_code: "175302" },
  // { label: "Balogo", mun_code: "175303" },
  // { label: "Dagman", mun_code: "175303" },
  // { label: "Dalayawon", mun_code: "175303" },
  // { label: "Lumacad", mun_code: "175303" },
  // { label: "Madoldolon", mun_code: "175303" },
  // { label: "Mauringuen", mun_code: "175303" },
  // { label: "San Jose De Oro", mun_code: "175303" },
  // { label: "Taloto", mun_code: "175303" },
  // { label: "Tinintinan", mun_code: "175303" },
  // { label: "Tudela (Calandagan)", mun_code: "175303" },
  // { label: "Poblacion (Centro)", mun_code: "175303" },
  // { label: "Agutayan", mun_code: "175304" },
  // { label: "Bugsuk (New Cagayancillo)", mun_code: "175304" },
  // { label: "Bancalaan", mun_code: "175304" },
  // { label: "Indalawan", mun_code: "175304" },
  // { label: "Catagupan", mun_code: "175304" },
  // { label: "Malaking Ilog", mun_code: "175304" },
  // { label: "Mangsee", mun_code: "175304" },
  // { label: "Melville", mun_code: "175304" },
  // { label: "Pandanan", mun_code: "175304" },
  // { label: "Pasig", mun_code: "175304" },
  // { label: "Rabor", mun_code: "175304" },
  // { label: "Ramos", mun_code: "175304" },
  // { label: "Salang", mun_code: "175304" },
  // { label: "Sebaring", mun_code: "175304" },
  // { label: "Poblacion I", mun_code: "175304" },
  // { label: "Poblacion II", mun_code: "175304" },
  // { label: "Poblacion III", mun_code: "175304" },
  // { label: "Poblacion IV", mun_code: "175304" },
  // { label: "Poblacion V", mun_code: "175304" },
  // { label: "Poblacion VI", mun_code: "175304" },
  // { label: "Bono-bono", mun_code: "175305" },
  // { label: "Bulalacao", mun_code: "175305" },
  // { label: "Buliluyan", mun_code: "175305" },
  // { label: "Culandanum", mun_code: "175305" },
  // { label: "Igang-igang", mun_code: "175305" },
  // { label: "Inogbong", mun_code: "175305" },
  // { label: "Iwahig", mun_code: "175305" },
  // { label: "Malihud", mun_code: "175305" },
  // { label: "Malitub", mun_code: "175305" },
  // { label: "Marangas (Pob.)", mun_code: "175305" },
  // { label: "Ocayan", mun_code: "175305" },
  // { label: "Puring", mun_code: "175305" },
  // { label: "Rio Tuba", mun_code: "175305" },
  // { label: "Sandoval", mun_code: "175305" },
  // { label: "Sapa", mun_code: "175305" },
  // { label: "Sarong", mun_code: "175305" },
  // { label: "Sumbiling", mun_code: "175305" },
  // { label: "Tabud", mun_code: "175305" },
  // { label: "Tagnato", mun_code: "175305" },
  // { label: "Tagolango", mun_code: "175305" },
  // { label: "Taratak", mun_code: "175305" },
  // { label: "Tarusan", mun_code: "175305" },
  // { label: "Amas", mun_code: "175306" },
  // { label: "Aribungos", mun_code: "175306" },
  // { label: "Barong-barong", mun_code: "175306" },
  // { label: "Calasaguen", mun_code: "175306" },
  // { label: "Imulnod", mun_code: "175306" },
  // { label: "Ipilan", mun_code: "175306" },
  // { label: "Maasin", mun_code: "175306" },
  // { label: "Mainit", mun_code: "175306" },
  // { label: "Malis", mun_code: "175306" },
  // { label: "Mambalot", mun_code: "175306" },
  // { label: "Oring-oring", mun_code: "175306" },
  // { label: "Pangobilian", mun_code: "175306" },
  // { label: "Poblacion I", mun_code: "175306" },
  // { label: "Poblacion II", mun_code: "175306" },
  // { label: "Salogon", mun_code: "175306" },
  // { label: "Saraza", mun_code: "175306" },
  // { label: "Tubtub", mun_code: "175306" },
  // { label: "Bogtong", mun_code: "175307" },
  // { label: "Buluang", mun_code: "175307" },
  // { label: "Cheey", mun_code: "175307" },
  // { label: "Concepcion", mun_code: "175307" },
  // { label: "Maglalambay", mun_code: "175307" },
  // { label: "New Busuanga (Pob.)", mun_code: "175307" },
  // { label: "Old Busuanga", mun_code: "175307" },
  // { label: "Panlaitan", mun_code: "175307" },
  // { label: "Quezon", mun_code: "175307" },
  // { label: "Sagrada", mun_code: "175307" },
  // { label: "Salvacion", mun_code: "175307" },
  // { label: "San Isidro", mun_code: "175307" },
  // { label: "San Rafael", mun_code: "175307" },
  // { label: "Bantayan (Pob.)", mun_code: "175308" },
  // { label: "Calsada (Pob.)", mun_code: "175308" },
  // { label: "Convento (Pob.)", mun_code: "175308" },
  // { label: "Lipot North (Pob.)", mun_code: "175308" },
  // { label: "Lipot South (Pob.)", mun_code: "175308" },
  // { label: "Magsaysay", mun_code: "175308" },
  // { label: "Mampio", mun_code: "175308" },
  // { label: "Nusa", mun_code: "175308" },
  // { label: "Santa Cruz", mun_code: "175308" },
  // { label: "Tacas (Pob.)", mun_code: "175308" },
  // { label: "Talaga", mun_code: "175308" },
  // { label: "Wahig (Pob.)", mun_code: "175308" },
  // { label: "Banuang Daan", mun_code: "175309" },
  // { label: "Bintuan", mun_code: "175309" },
  // { label: "Borac", mun_code: "175309" },
  // { label: "Buenavista", mun_code: "175309" },
  // { label: "Bulalacao", mun_code: "175309" },
  // { label: "Cabugao", mun_code: "175309" },
  // { label: "Decabobo", mun_code: "175309" },
  // { label: "Decalachao", mun_code: "175309" },
  // { label: "Guadalupe", mun_code: "175309" },
  // { label: "Lajala", mun_code: "175309" },
  // { label: "Malawig", mun_code: "175309" },
  // { label: "Marcilla", mun_code: "175309" },
  // { label: "Barangay I (Pob.)", mun_code: "175309" },
  // { label: "Barangay II (Pob.)", mun_code: "175309" },
  // { label: "Barangay III (Pob.)", mun_code: "175309" },
  // { label: "Barangay IV (Pob.)", mun_code: "175309" },
  // { label: "Barangay V (Pob.)", mun_code: "175309" },
  // { label: "San Jose", mun_code: "175309" },
  // { label: "San Nicolas", mun_code: "175309" },
  // { label: "Tagumpay", mun_code: "175309" },
  // { label: "Tara", mun_code: "175309" },
  // { label: "Turda", mun_code: "175309" },
  // { label: "Barangay VI (Pob.)", mun_code: "175309" },
  // { label: "Balading", mun_code: "175310" },
  // { label: "Bangcal (Pob.)", mun_code: "175310" },
  // { label: "Cabigsing (Pob.)", mun_code: "175310" },
  // { label: "Caburian", mun_code: "175310" },
  // { label: "Caponayan", mun_code: "175310" },
  // { label: "Catadman (Pob.)", mun_code: "175310" },
  // { label: "Funda", mun_code: "175310" },
  // { label: "Lagaoriao (Pob.)", mun_code: "175310" },
  // { label: "Lubid", mun_code: "175310" },
  // { label: "Manamoc", mun_code: "175310" },
  // { label: "Maringian", mun_code: "175310" },
  // { label: "Lungsod (Pob.)", mun_code: "175310" },
  // { label: "Pawa", mun_code: "175310" },
  // { label: "San Carlos", mun_code: "175310" },
  // { label: "Suba", mun_code: "175310" },
  // { label: "Tenga-tenga (Pob.)", mun_code: "175310" },
  // { label: "Tocadan (Pob.)", mun_code: "175310" },
  // { label: "Bacao", mun_code: "175311" },
  // { label: "Bohol", mun_code: "175311" },
  // { label: "Calasag", mun_code: "175311" },
  // { label: "Capayas", mun_code: "175311" },
  // { label: "Catep", mun_code: "175311" },
  // { label: "Culasian", mun_code: "175311" },
  // { label: "Danleg", mun_code: "175311" },
  // { label: "Dumaran (Pob.)", mun_code: "175311" },
  // { label: "Itangil", mun_code: "175311" },
  // { label: "Ilian", mun_code: "175311" },
  // { label: "Magsaysay", mun_code: "175311" },
  // { label: "San Juan", mun_code: "175311" },
  // { label: "Santa Teresita", mun_code: "175311" },
  // { label: "Santo Tomas", mun_code: "175311" },
  // { label: "Tanatanaon", mun_code: "175311" },
  // { label: "Santa Maria", mun_code: "175311" },
  // { label: "Bagong Bayan", mun_code: "175312" },
  // { label: "Buena Suerte Pob. (Barangay 2)", mun_code: "175312" },
  // { label: "Barotuan", mun_code: "175312" },
  // { label: "Bebeladan", mun_code: "175312" },
  // { label: "Corong-corong Pob. (Barangay 4)", mun_code: "175312" },
  // { label: "Mabini", mun_code: "175312" },
  // { label: "Manlag", mun_code: "175312" },
  // { label: "Masagana Pob. (Barangay 3)", mun_code: "175312" },
  // { label: "New Ibajay", mun_code: "175312" },
  // { label: "Maligaya Pob. (Barangay 1)", mun_code: "175312" },
  // { label: "San Fernando", mun_code: "175312" },
  // { label: "Sibaltan", mun_code: "175312" },
  // { label: "Teneguiban", mun_code: "175312" },
  // { label: "Villa Libertad", mun_code: "175312" },
  // { label: "Villa Paz", mun_code: "175312" },
  // { label: "Bucana", mun_code: "175312" },
  // { label: "Aberawan", mun_code: "175312" },
  // { label: "Barangonan (Iloc)", mun_code: "175313" },
  // { label: "Cabunlawan", mun_code: "175313" },
  // { label: "Calibangbangan", mun_code: "175313" },
  // { label: "Decabaitot", mun_code: "175313" },
  // { label: "Maroyogroyog", mun_code: "175313" },
  // { label: "Nangalao", mun_code: "175313" },
  // { label: "New Culaylayan", mun_code: "175313" },
  // { label: "Pical", mun_code: "175313" },
  // { label: "San Miguel (Pob.)", mun_code: "175313" },
  // { label: "San Nicolas", mun_code: "175313" },
  // { label: "Alcoba", mun_code: "175314" },
  // { label: "Balaguen", mun_code: "175314" },
  // { label: "Canipo", mun_code: "175314" },
  // { label: "Cocoro", mun_code: "175314" },
  // { label: "Danawan (Pob.)", mun_code: "175314" },
  // { label: "Emilod", mun_code: "175314" },
  // { label: "Igabas", mun_code: "175314" },
  // { label: "Lacaren", mun_code: "175314" },
  // { label: "Los Angeles", mun_code: "175314" },
  // { label: "Lucbuan", mun_code: "175314" },
  // { label: "Rizal", mun_code: "175314" },
  // { label: "Antipuluan", mun_code: "175315" },
  // { label: "Aramaywan", mun_code: "175315" },
  // { label: "Batang-batang", mun_code: "175315" },
  // { label: "Bato-bato", mun_code: "175315" },
  // { label: "Burirao", mun_code: "175315" },
  // { label: "Caguisan", mun_code: "175315" },
  // { label: "Calategas", mun_code: "175315" },
  // { label: "Elvita", mun_code: "175315" },
  // { label: "Estrella Village", mun_code: "175315" },
  // { label: "Ipilan", mun_code: "175315" },
  // { label: "Malatgao", mun_code: "175315" },
  // { label: "Malinao", mun_code: "175315" },
  // { label: "Narra (Pob.)", mun_code: "175315" },
  // { label: "Panacan", mun_code: "175315" },
  // { label: "Princess Urduja", mun_code: "175315" },
  // { label: "Sandoval", mun_code: "175315" },
  // { label: "Tacras", mun_code: "175315" },
  // { label: "Taritien", mun_code: "175315" },
  // { label: "Teresa", mun_code: "175315" },
  // { label: "Tinagong Dagat", mun_code: "175315" },
  // { label: "Bagong Sikat", mun_code: "175315" },
  // { label: "Panacan 2", mun_code: "175315" },
  // { label: "Babuyan", mun_code: "175316" },
  // { label: "Bacungan", mun_code: "175316" },
  // { label: "Bagong Bayan", mun_code: "175316" },
  // { label: "Bagong Pag-Asa (Pob.)", mun_code: "175316" },
  // { label: "Bagong Sikat (Pob.)", mun_code: "175316" },
  // { label: "Bagong Silang (Pob.)", mun_code: "175316" },
  // { label: "Bahile", mun_code: "175316" },
  // { label: "Bancao-bancao", mun_code: "175316" },
  // { label: "Binduyan", mun_code: "175316" },
  // { label: "Buenavista", mun_code: "175316" },
  // { label: "Cabayugan", mun_code: "175316" },
  // { label: "Concepcion", mun_code: "175316" },
  // { label: "Inagawan", mun_code: "175316" },
  // { label: "Irawan", mun_code: "175316" },
  // { label: "Iwahig (Pob.)", mun_code: "175316" },
  // { label: "Kalipay (Pob.)", mun_code: "175316" },
  // { label: "Kamuning", mun_code: "175316" },
  // { label: "Langogan", mun_code: "175316" },
  // { label: "Liwanag (Pob.)", mun_code: "175316" },
  // { label: "Lucbuan", mun_code: "175316" },
  // { label: "Mabuhay (Pob.)", mun_code: "175316" },
  // { label: "Macarascas", mun_code: "175316" },
  // { label: "Magkakaibigan (Pob.)", mun_code: "175316" },
  // { label: "Maligaya (Pob.)", mun_code: "175316" },
  // { label: "Manalo", mun_code: "175316" },
  // { label: "Manggahan (Pob.)", mun_code: "175316" },
  // { label: "Maningning (Pob.)", mun_code: "175316" },
  // { label: "Maoyon", mun_code: "175316" },
  // { label: "Marufinas", mun_code: "175316" },
  // { label: "Maruyogon", mun_code: "175316" },
  // { label: "Masigla (Pob.)", mun_code: "175316" },
  // { label: "Masikap (Pob.)", mun_code: "175316" },
  // { label: "Masipag (Pob.)", mun_code: "175316" },
  // { label: "Matahimik (Pob.)", mun_code: "175316" },
  // { label: "Matiyaga (Pob.)", mun_code: "175316" },
  // { label: "Maunlad (Pob.)", mun_code: "175316" },
  // { label: "Milagrosa (Pob.)", mun_code: "175316" },
  // { label: "Model (Pob.)", mun_code: "175316" },
  // { label: "Montible (Pob.)", mun_code: "175316" },
  // { label: "Napsan", mun_code: "175316" },
  // { label: "New Panggangan", mun_code: "175316" },
  // { label: "Pagkakaisa (Pob.)", mun_code: "175316" },
  // { label: "Princesa (Pob.)", mun_code: "175316" },
  // { label: "Salvacion", mun_code: "175316" },
  // { label: "San Jose", mun_code: "175316" },
  // { label: "San Miguel", mun_code: "175316" },
  // { label: "San Pedro", mun_code: "175316" },
  // { label: "San Rafael", mun_code: "175316" },
  // { label: "Santa Cruz", mun_code: "175316" },
  // { label: "Santa Lourdes", mun_code: "175316" },
  // { label: "Santa Lucia (Pob.)", mun_code: "175316" },
  // { label: "Santa Monica", mun_code: "175316" },
  // { label: "Seaside (Pob.)", mun_code: "175316" },
  // { label: "Sicsican", mun_code: "175316" },
  // { label: "Simpocan", mun_code: "175316" },
  // { label: "Tagabinit", mun_code: "175316" },
  // { label: "Tagburos", mun_code: "175316" },
  // { label: "Tagumpay (Pob.)", mun_code: "175316" },
  // { label: "Tanabag", mun_code: "175316" },
  // { label: "Tanglaw (Pob.)", mun_code: "175316" },
  // { label: "Barangay ng mga Mangingisda", mun_code: "175316" },
  // { label: "Inagawan Sub-Colony", mun_code: "175316" },
  // { label: "Luzviminda", mun_code: "175316" },
  // { label: "Mandaragat", mun_code: "175316" },
  // { label: "San Manuel", mun_code: "175316" },
  // { label: "Tiniguiban", mun_code: "175316" },
  // { label: "Alfonso XIII (Pob.)", mun_code: "175317" },
  // { label: "Aramaywan", mun_code: "175317" },
  // { label: "Berong", mun_code: "175317" },
  // { label: "Calumpang", mun_code: "175317" },
  // { label: "Isugod", mun_code: "175317" },
  // { label: "Quinlogan", mun_code: "175317" },
  // { label: "Maasin", mun_code: "175317" },
  // { label: "Panitian", mun_code: "175317" },
  // { label: "Pinaglabanan", mun_code: "175317" },
  // { label: "Sowangan", mun_code: "175317" },
  // { label: "Tabon", mun_code: "175317" },
  // { label: "Calatagbak", mun_code: "175317" },
  // { label: "Malatgao", mun_code: "175317" },
  // { label: "Tagusao", mun_code: "175317" },
  // { label: "Abaroan", mun_code: "175318" },
  // { label: "Antonino", mun_code: "175318" },
  // { label: "Bagong Bayan", mun_code: "175318" },
  // { label: "Caramay", mun_code: "175318" },
  // { label: "Dumarao", mun_code: "175318" },
  // { label: "Iraan", mun_code: "175318" },
  // { label: "Jolo", mun_code: "175318" },
  // { label: "Magara (Arasan)", mun_code: "175318" },
  // { label: "Malcampo", mun_code: "175318" },
  // { label: "Mendoza", mun_code: "175318" },
  // { label: "Narra (Minara)", mun_code: "175318" },
  // { label: "New Barbacan (Retac)", mun_code: "175318" },
  // { label: "New Cuyo", mun_code: "175318" },
  // { label: "Barangay 1 (Pob.)", mun_code: "175318" },
  // { label: "Rizal", mun_code: "175318" },
  // { label: "Salvacion", mun_code: "175318" },
  // { label: "San Isidro", mun_code: "175318" },
  // { label: "San Jose", mun_code: "175318" },
  // { label: "San Miguel", mun_code: "175318" },
  // { label: "San Nicolas", mun_code: "175318" },
  // { label: "Sandoval", mun_code: "175318" },
  // { label: "Tagumpay", mun_code: "175318" },
  // { label: "Taradungan", mun_code: "175318" },
  // { label: "Tinitian", mun_code: "175318" },
  // { label: "Tumarbong", mun_code: "175318" },
  // { label: "Barangay II (Pob.)", mun_code: "175318" },
  // { label: "Barangay III (Pob.)", mun_code: "175318" },
  // { label: "Barangay IV (Pob.)", mun_code: "175318" },
  // { label: "Barangay V Pob. (Porao Island)", mun_code: "175318" },
  // { label: "Barangay VI Pob. (Johnson Island)", mun_code: "175318" },
  // { label: "Nicanor Zabala", mun_code: "175318" },
  // { label: "Alimanguan", mun_code: "175319" },
  // { label: "Binga", mun_code: "175319" },
  // { label: "Caruray", mun_code: "175319" },
  // { label: "Kemdeng", mun_code: "175319" },
  // { label: "New Agutaya", mun_code: "175319" },
  // { label: "New Canipo", mun_code: "175319" },
  // { label: "Port Barton", mun_code: "175319" },
  // { label: "Poblacion (San Vicente)", mun_code: "175319" },
  // { label: "San Isidro", mun_code: "175319" },
  // { label: "Abongan", mun_code: "175320" },
  // { label: "Banbanan", mun_code: "175320" },
  // { label: "Bantulan", mun_code: "175320" },
  // { label: "Batas", mun_code: "175320" },
  // { label: "Bato", mun_code: "175320" },
  // { label: "Beton", mun_code: "175320" },
  // { label: "Busy Bees", mun_code: "175320" },
  // { label: "Calawag", mun_code: "175320" },
  // { label: "Casian", mun_code: "175320" },
  // { label: "Cataban", mun_code: "175320" },
  // { label: "Debangan", mun_code: "175320" },
  // { label: "Depla", mun_code: "175320" },
  // { label: "Liminangcong", mun_code: "175320" },
  // { label: "Meytegued", mun_code: "175320" },
  // { label: "New Guinlo", mun_code: "175320" },
  // { label: "Old Guinlo", mun_code: "175320" },
  // { label: "Pamantolon", mun_code: "175320" },
  // { label: "Pancol", mun_code: "175320" },
  // { label: "Paly (Paly Island)", mun_code: "175320" },
  // { label: "Poblacion", mun_code: "175320" },
  // { label: "Pularaquen (Canique)", mun_code: "175320" },
  // { label: "San Jose", mun_code: "175320" },
  // { label: "Sandoval", mun_code: "175320" },
  // { label: "Silanga", mun_code: "175320" },
  // { label: "Alacalian", mun_code: "175320" },
  // { label: "Baras (Pangpang)", mun_code: "175320" },
  // { label: "Libertad", mun_code: "175320" },
  // { label: "Minapla", mun_code: "175320" },
  // { label: "Talog", mun_code: "175320" },
  // { label: "Tumbod", mun_code: "175320" },
  // { label: "Paglaum", mun_code: "175320" },
  // { label: "Pag-Asa (Pob.)", mun_code: "175321" },
  // { label: "Balala", mun_code: "175322" },
  // { label: "Baldat", mun_code: "175322" },
  // { label: "Binudac", mun_code: "175322" },
  // { label: "Culango", mun_code: "175322" },
  // { label: "Galoc", mun_code: "175322" },
  // { label: "Jardin", mun_code: "175322" },
  // { label: "Libis", mun_code: "175322" },
  // { label: "Luac", mun_code: "175322" },
  // { label: "Malaking Patag", mun_code: "175322" },
  // { label: "Tiza", mun_code: "175322" },
  // { label: "Burabod", mun_code: "175322" },
  // { label: "Halsey", mun_code: "175322" },
  // { label: "De Carabao", mun_code: "175322" },
  // { label: "Bunog", mun_code: "175323" },
  // { label: "Campong Ulay", mun_code: "175323" },
  // { label: "Candawaga", mun_code: "175323" },
  // { label: "Canipaan", mun_code: "175323" },
  // { label: "Culasian", mun_code: "175323" },
  // { label: "Iraan", mun_code: "175323" },
  // { label: "Latud", mun_code: "175323" },
  // { label: "Panalingaan", mun_code: "175323" },
  // { label: "Punta Baja", mun_code: "175323" },
  // { label: "Ransang", mun_code: "175323" },
  // { label: "Taburi", mun_code: "175323" },
  // { label: "Abo-abo", mun_code: "175324" },
  // { label: "Iraray", mun_code: "175324" },
  // { label: "Isumbo", mun_code: "175324" },
  // { label: "Labog", mun_code: "175324" },
  // { label: "Panitian", mun_code: "175324" },
  // { label: "Pulot Center", mun_code: "175324" },
  // { label: "Pulot Interior (Pulot II)", mun_code: "175324" },
  // { label: "Pulot Shore (Pulot I)", mun_code: "175324" },
  // { label: "Punang", mun_code: "175324" },
  // { label: "Bonlao", mun_code: "175901" },
  // { label: "Calagonsao", mun_code: "175901" },
  // { label: "Camili", mun_code: "175901" },
  // { label: "Camod-Om", mun_code: "175901" },
  // { label: "Madalag", mun_code: "175901" },
  // { label: "Poblacion", mun_code: "175901" },
  // { label: "San Isidro", mun_code: "175901" },
  // { label: "Tugdan", mun_code: "175901" },
  // { label: "Bagsik", mun_code: "175901" },
  // { label: "Gui-ob", mun_code: "175901" },
  // { label: "Lawan", mun_code: "175901" },
  // { label: "San Roque", mun_code: "175901" },
  // { label: "Balogo", mun_code: "175902" },
  // { label: "Banice", mun_code: "175902" },
  // { label: "Hambi-an", mun_code: "175902" },
  // { label: "Lagang", mun_code: "175902" },
  // { label: "Libtong", mun_code: "175902" },
  // { label: "Mainit", mun_code: "175902" },
  // { label: "Nabalay", mun_code: "175902" },
  // { label: "Nasunogan", mun_code: "175902" },
  // { label: "Poblacion", mun_code: "175902" },
  // { label: "Sibay", mun_code: "175902" },
  // { label: "Tan-Ag", mun_code: "175902" },
  // { label: "Toctoc", mun_code: "175902" },
  // { label: "Togbongan", mun_code: "175902" },
  // { label: "Togong", mun_code: "175902" },
  // { label: "Tungonan", mun_code: "175902" },
  // { label: "Tumalum", mun_code: "175902" },
  // { label: "Yabawon", mun_code: "175902" },
  // { label: "Alibagon", mun_code: "175903" },
  // { label: "Cambajao", mun_code: "175903" },
  // { label: "Cambalo", mun_code: "175903" },
  // { label: "Cambijang", mun_code: "175903" },
  // { label: "Cantagda", mun_code: "175903" },
  // { label: "Danao", mun_code: "175903" },
  // { label: "Gutivan", mun_code: "175903" },
  // { label: "Lico", mun_code: "175903" },
  // { label: "Lumbang Este", mun_code: "175903" },
  // { label: "Lumbang Weste", mun_code: "175903" },
  // { label: "Marigondon", mun_code: "175903" },
  // { label: "Poblacion", mun_code: "175903" },
  // { label: "Sugod", mun_code: "175903" },
  // { label: "Taguilos", mun_code: "175903" },
  // { label: "Balogo", mun_code: "175904" },
  // { label: "Linao", mun_code: "175904" },
  // { label: "Poblacion", mun_code: "175904" },
  // { label: "Pagsangahan", mun_code: "175904" },
  // { label: "Pangulo", mun_code: "175904" },
  // { label: "San Roque", mun_code: "175904" },
  // { label: "Talisay", mun_code: "175904" },
  // { label: "Bachawan", mun_code: "175905" },
  // { label: "Calabasahan", mun_code: "175905" },
  // { label: "Dalajican", mun_code: "175905" },
  // { label: "Masudsud", mun_code: "175905" },
  // { label: "Poblacion", mun_code: "175905" },
  // { label: "Sampong", mun_code: "175905" },
  // { label: "San Pedro (Agbatang)", mun_code: "175905" },
  // { label: "San Vicente", mun_code: "175905" },
  // { label: "Masadya", mun_code: "175905" },
  // { label: "Alegria", mun_code: "175906" },
  // { label: "Ambulong", mun_code: "175906" },
  // { label: "Colongcolong", mun_code: "175906" },
  // { label: "Gobon", mun_code: "175906" },
  // { label: "Guintiguiban", mun_code: "175906" },
  // { label: "Ilijan", mun_code: "175906" },
  // { label: "Labnig", mun_code: "175906" },
  // { label: "Mabini", mun_code: "175906" },
  // { label: "Mahaba", mun_code: "175906" },
  // { label: "Mangansag", mun_code: "175906" },
  // { label: "Poblacion", mun_code: "175906" },
  // { label: "San Agustin", mun_code: "175906" },
  // { label: "San Roque", mun_code: "175906" },
  // { label: "San Vicente", mun_code: "175906" },
  // { label: "Tacasan", mun_code: "175906" },
  // { label: "Agojo", mun_code: "175907" },
  // { label: "Balatucan", mun_code: "175907" },
  // { label: "Buenavista", mun_code: "175907" },
  // { label: "Camandao", mun_code: "175907" },
  // { label: "Guinhayaan", mun_code: "175907" },
  // { label: "Limon Norte", mun_code: "175907" },
  // { label: "Limon Sur", mun_code: "175907" },
  // { label: "Manhac", mun_code: "175907" },
  // { label: "Pili", mun_code: "175907" },
  // { label: "Poblacion", mun_code: "175907" },
  // { label: "Punta", mun_code: "175907" },
  // { label: "Tuguis", mun_code: "175907" },
  // { label: "Agsao", mun_code: "175908" },
  // { label: "Agutay", mun_code: "175908" },
  // { label: "Ambulong", mun_code: "175908" },
  // { label: "Dulangan", mun_code: "175908" },
  // { label: "Ipil", mun_code: "175908" },
  // { label: "Jao-asan", mun_code: "175908" },
  // { label: "Poblacion", mun_code: "175908" },
  // { label: "Silum", mun_code: "175908" },
  // { label: "Tampayan", mun_code: "175908" },
  // { label: "Amatong", mun_code: "175909" },
  // { label: "Anahao", mun_code: "175909" },
  // { label: "Bangon", mun_code: "175909" },
  // { label: "Batiano", mun_code: "175909" },
  // { label: "Budiong", mun_code: "175909" },
  // { label: "Canduyong", mun_code: "175909" },
  // { label: "Dapawan", mun_code: "175909" },
  // { label: "Gabawan", mun_code: "175909" },
  // { label: "Libertad", mun_code: "175909" },
  // { label: "Malilico", mun_code: "175909" },
  // { label: "Mayha", mun_code: "175909" },
  // { label: "Panique", mun_code: "175909" },
  // { label: "Pato-o", mun_code: "175909" },
  // { label: "Ligaya (Pob.)", mun_code: "175909" },
  // { label: "Liwanag (Pob.)", mun_code: "175909" },
  // { label: "Poctoy", mun_code: "175909" },
  // { label: "Progreso Este", mun_code: "175909" },
  // { label: "Progreso Weste", mun_code: "175909" },
  // { label: "Rizal", mun_code: "175909" },
  // { label: "Tabing Dagat (Pob.)", mun_code: "175909" },
  // { label: "Tabobo-an", mun_code: "175909" },
  // { label: "Tulay", mun_code: "175909" },
  // { label: "Tumingad", mun_code: "175909" },
  // { label: "Liwayway (Pob.)", mun_code: "175909" },
  // { label: "Tuburan", mun_code: "175909" },
  // { label: "Agbaluto", mun_code: "175910" },
  // { label: "Agpanabat", mun_code: "175910" },
  // { label: "Agbudia", mun_code: "175910" },
  // { label: "Agnaga", mun_code: "175910" },
  // { label: "Agnay", mun_code: "175910" },
  // { label: "Agnipa", mun_code: "175910" },
  // { label: "Agtongo", mun_code: "175910" },
  // { label: "Alad", mun_code: "175910" },
  // { label: "Bagacay", mun_code: "175910" },
  // { label: "Cajimos", mun_code: "175910" },
  // { label: "Calabogo", mun_code: "175910" },
  // { label: "Capaclan", mun_code: "175910" },
  // { label: "Ginablan", mun_code: "175910" },
  // { label: "Guimpingan", mun_code: "175910" },
  // { label: "Ilauran", mun_code: "175910" },
  // { label: "Lamao", mun_code: "175910" },
  // { label: "Li-o", mun_code: "175910" },
  // { label: "Logbon", mun_code: "175910" },
  // { label: "Lunas", mun_code: "175910" },
  // { label: "Lonos", mun_code: "175910" },
  // { label: "Macalas", mun_code: "175910" },
  // { label: "Mapula", mun_code: "175910" },
  // { label: "Cobrador (Naguso)", mun_code: "175910" },
  // { label: "Palje", mun_code: "175910" },
  // { label: "Barangay I (Pob.)", mun_code: "175910" },
  // { label: "Barangay II (Pob.)", mun_code: "175910" },
  // { label: "Barangay III (Pob.)", mun_code: "175910" },
  // { label: "Barangay IV (Pob.)", mun_code: "175910" },
  // { label: "Sablayan", mun_code: "175910" },
  // { label: "Sawang", mun_code: "175910" },
  // { label: "Tambac", mun_code: "175910" },
  // { label: "Bachawan", mun_code: "175911" },
  // { label: "Binongahan", mun_code: "175911" },
  // { label: "Buli", mun_code: "175911" },
  // { label: "Cabolutan", mun_code: "175911" },
  // { label: "Cagbuaya", mun_code: "175911" },
  // { label: "Carmen", mun_code: "175911" },
  // { label: "Cawayan", mun_code: "175911" },
  // { label: "Dubduban", mun_code: "175911" },
  // { label: "Binugusan", mun_code: "175911" },
  // { label: "Lusong", mun_code: "175911" },
  // { label: "Mahabang Baybay (Long Beach)", mun_code: "175911" },
  // { label: "Poblacion", mun_code: "175911" },
  // { label: "Sugod", mun_code: "175911" },
  // { label: "Camantaya", mun_code: "175911" },
  // { label: "Agpudlos", mun_code: "175912" },
  // { label: "Calunacon", mun_code: "175912" },
  // { label: "Linawan", mun_code: "175912" },
  // { label: "Mabini", mun_code: "175912" },
  // { label: "Marigondon Norte", mun_code: "175912" },
  // { label: "Marigondon Sur", mun_code: "175912" },
  // { label: "Matutuna", mun_code: "175912" },
  // { label: "Pag-Alad", mun_code: "175912" },
  // { label: "Poblacion", mun_code: "175912" },
  // { label: "Tan-Agan", mun_code: "175912" },
  // { label: "Victoria", mun_code: "175912" },
  // { label: "Juncarlo", mun_code: "175912" },
  // { label: "Agtiwa", mun_code: "175913" },
  // { label: "Azarga", mun_code: "175913" },
  // { label: "Campalingo", mun_code: "175913" },
  // { label: "Canjalon", mun_code: "175913" },
  // { label: "Mabini", mun_code: "175913" },
  // { label: "Mabulo", mun_code: "175913" },
  // { label: "Otod", mun_code: "175913" },
  // { label: "Panangcalan", mun_code: "175913" },
  // { label: "Pili", mun_code: "175913" },
  // { label: "Poblacion", mun_code: "175913" },
  // { label: "Taclobo", mun_code: "175913" },
  // { label: "Busay", mun_code: "175914" },
  // { label: "Combot", mun_code: "175914" },
  // { label: "Lanas", mun_code: "175914" },
  // { label: "Pinamihagan", mun_code: "175914" },
  // { label: "Poblacion (Agcogon)", mun_code: "175914" },
  // { label: "Agmanic", mun_code: "175915" },
  // { label: "Canyayo", mun_code: "175915" },
  // { label: "Danao Norte", mun_code: "175915" },
  // { label: "Danao Sur", mun_code: "175915" },
  // { label: "Guinbirayan", mun_code: "175915" },
  // { label: "Guintigbasan", mun_code: "175915" },
  // { label: "Magsaysay", mun_code: "175915" },
  // { label: "Mat-i", mun_code: "175915" },
  // { label: "Pandan", mun_code: "175915" },
  // { label: "Poblacion", mun_code: "175915" },
  // { label: "Tabugon", mun_code: "175915" },
  // { label: "Agnonoc", mun_code: "175916" },
  // { label: "Bunsoran", mun_code: "175916" },
  // { label: "Claro M. Recto", mun_code: "175916" },
  // { label: "Poblacion (Ferrol)", mun_code: "175916" },
  // { label: "Hinaguman", mun_code: "175916" },
  // { label: "Tubigon", mun_code: "175916" },
  // { label: "Bonga", mun_code: "175917" },
  // { label: "Concepcion Norte (Pob.)", mun_code: "175917" },
  // { label: "Concepcion Sur", mun_code: "175917" },
  // { label: "Paroyhog", mun_code: "175917" },
  // { label: "San Isidro", mun_code: "175917" },
  // { label: "Baclayon", mun_code: "050501" },
  // { label: "Banao", mun_code: "050501" },
  // { label: "Bariw", mun_code: "050501" },
  // { label: "Basud", mun_code: "050501" },
  // { label: "Bayandong", mun_code: "050501" },
  // { label: "Bonga (Upper)", mun_code: "050501" },
  // { label: "Buang", mun_code: "050501" },
  // { label: "Cabasan", mun_code: "050501" },
  // { label: "Cagbulacao", mun_code: "050501" },
  // { label: "Cagraray", mun_code: "050501" },
  // { label: "Cajogutan", mun_code: "050501" },
  // { label: "Cawayan", mun_code: "050501" },
  // { label: "Damacan", mun_code: "050501" },
  // { label: "Gubat Ilawod", mun_code: "050501" },
  // { label: "Gubat Iraya", mun_code: "050501" },
  // { label: "Hindi", mun_code: "050501" },
  // { label: "Igang", mun_code: "050501" },
  // { label: "Langaton", mun_code: "050501" },
  // { label: "Manaet", mun_code: "050501" },
  // { label: "Mapulang Daga", mun_code: "050501" },
  // { label: "Mataas", mun_code: "050501" },
  // { label: "Misibis", mun_code: "050501" },
  // { label: "Nahapunan", mun_code: "050501" },
  // { label: "Namanday", mun_code: "050501" },
  // { label: "Namantao", mun_code: "050501" },
  // { label: "Napao", mun_code: "050501" },
  // { label: "Panarayon", mun_code: "050501" },
  // { label: "Pigcobohan", mun_code: "050501" },
  // { label: "Pili Ilawod", mun_code: "050501" },
  // { label: "Pili Iraya", mun_code: "050501" },
  // { label: "Barangay 1 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 10 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 11 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 12 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 13 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 14 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 2 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 3 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 4 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 5 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 6 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 7 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 8 (Pob.)", mun_code: "050501" },
  // { label: "Barangay 9 (Pob.)", mun_code: "050501" },
  // { label: "Pongco (Lower Bonga)", mun_code: "050501" },
  // { label: "Busdac (San Jose)", mun_code: "050501" },
  // { label: "San Pablo", mun_code: "050501" },
  // { label: "San Pedro", mun_code: "050501" },
  // { label: "Sogod", mun_code: "050501" },
  // { label: "Sula", mun_code: "050501" },
  // { label: "Tambilagao (Tambognon)", mun_code: "050501" },
  // { label: "Tambongon (Tambilagao)", mun_code: "050501" },
  // { label: "Tanagan", mun_code: "050501" },
  // { label: "Uson", mun_code: "050501" },
  // { label: "Vinisitahan-Basud (Mainland)", mun_code: "050501" },
  // { label: "Vinisitahan-Napao (lsland)", mun_code: "050501" },
  // { label: "Anoling", mun_code: "050502" },
  // { label: "Baligang", mun_code: "050502" },
  // { label: "Bantonan", mun_code: "050502" },
  // { label: "Bariw", mun_code: "050502" },
  // { label: "Binanderahan", mun_code: "050502" },
  // { label: "Binitayan", mun_code: "050502" },
  // { label: "Bongabong", mun_code: "050502" },
  // { label: "Calabidongan", mun_code: "050502" },
  // { label: "Comun", mun_code: "050502" },
  // { label: "Cotmon", mun_code: "050502" },
  // { label: "Del Rosario", mun_code: "050502" },
  // { label: "Gapo", mun_code: "050502" },
  // { label: "Gotob", mun_code: "050502" },
  // { label: "Ilawod", mun_code: "050502" },
  // { label: "Iluluan", mun_code: "050502" },
  // { label: "Libod", mun_code: "050502" },
  // { label: "Ligban", mun_code: "050502" },
  // { label: "Mabunga", mun_code: "050502" },
  // { label: "Magogon", mun_code: "050502" },
  // { label: "Manawan", mun_code: "050502" },
  // { label: "Maninila", mun_code: "050502" },
  // { label: "Mina", mun_code: "050502" },
  // { label: "Miti", mun_code: "050502" },
  // { label: "Palanog", mun_code: "050502" },
  // { label: "Panoypoy", mun_code: "050502" },
  // { label: "Pariaan", mun_code: "050502" },
  // { label: "Quinartilan", mun_code: "050502" },
  // { label: "Quirangay", mun_code: "050502" },
  // { label: "Quitinday", mun_code: "050502" },
  // { label: "Salugan", mun_code: "050502" },
  // { label: "Solong", mun_code: "050502" },
  // { label: "Sua", mun_code: "050502" },
  // { label: "Sumlang", mun_code: "050502" },
  // { label: "Tagaytay", mun_code: "050502" },
  // { label: "Tagoytoy", mun_code: "050502" },
  // { label: "Taladong", mun_code: "050502" },
  // { label: "Taloto", mun_code: "050502" },
  // { label: "Taplacon", mun_code: "050502" },
  // { label: "Tinago", mun_code: "050502" },
  // { label: "Tumpa", mun_code: "050502" },
  // { label: "Barangay 1 (Pob.)", mun_code: "050502" },
  // { label: "Barangay 2 (Pob.)", mun_code: "050502" },
  // { label: "Barangay 3 (Pob.)", mun_code: "050502" },
  // { label: "Barangay 4 (Pob.)", mun_code: "050502" },
  // { label: "Barangay 5 (Pob.)", mun_code: "050502" },
  // { label: "Barangay 6 (Pob.)", mun_code: "050502" },
  // { label: "Barangay 7 (Pob.)", mun_code: "050502" },
  // { label: "Caguiba", mun_code: "050502" },
  // { label: "Alcala", mun_code: "050503" },
  // { label: "Alobo", mun_code: "050503" },
  // { label: "Anislag", mun_code: "050503" },
  // { label: "Bagumbayan", mun_code: "050503" },
  // { label: "Balinad", mun_code: "050503" },
  // { label: "Bascaran", mun_code: "050503" },
  // { label: "Bigao", mun_code: "050503" },
  // { label: "Binitayan", mun_code: "050503" },
  // { label: "Bongalon", mun_code: "050503" },
  // { label: "Budiao", mun_code: "050503" },
  // { label: "Burgos", mun_code: "050503" },
  // { label: "Busay", mun_code: "050503" },
  // { label: "Cullat", mun_code: "050503" },
  // { label: "Canaron", mun_code: "050503" },
  // { label: "Dela Paz", mun_code: "050503" },
  // { label: "Dinoronan", mun_code: "050503" },
  // { label: "Gabawan", mun_code: "050503" },
  // { label: "Gapo", mun_code: "050503" },
  // { label: "Ibaugan", mun_code: "050503" },
  // { label: "Ilawod Area Pob. (Dist. 2)", mun_code: "050503" },
  // { label: "Inarado", mun_code: "050503" },
  // { label: "Kidaco", mun_code: "050503" },
  // { label: "Kilicao", mun_code: "050503" },
  // { label: "Kimantong", mun_code: "050503" },
  // { label: "Kinawitan", mun_code: "050503" },
  // { label: "Kiwalo", mun_code: "050503" },
  // { label: "Lacag", mun_code: "050503" },
  // { label: "Mabini", mun_code: "050503" },
  // { label: "Malabog", mun_code: "050503" },
  // { label: "Malobago", mun_code: "050503" },
  // { label: "Maopi", mun_code: "050503" },
  // { label: "Market Area Pob. (Dist. 1)", mun_code: "050503" },
  // { label: "Maroroy", mun_code: "050503" },
  // { label: "Matnog", mun_code: "050503" },
  // { label: "Mayon", mun_code: "050503" },
  // { label: "Mi-isi", mun_code: "050503" },
  // { label: "Nabasan", mun_code: "050503" },
  // { label: "Namantao", mun_code: "050503" },
  // { label: "Pandan", mun_code: "050503" },
  // { label: "Sagpon", mun_code: "050503" },
  // { label: "Salvacion", mun_code: "050503" },
  // { label: "San Rafael", mun_code: "050503" },
  // { label: "San Ramon", mun_code: "050503" },
  // { label: "San Roque", mun_code: "050503" },
  // { label: "San Vicente Grande", mun_code: "050503" },
  // { label: "Sipi", mun_code: "050503" },
  // { label: "Tabon-tabon", mun_code: "050503" },
  // { label: "Tagas", mun_code: "050503" },
  // { label: "Talahib", mun_code: "050503" },
  // { label: "Villahermosa", mun_code: "050503" },
  // { label: "Agpay", mun_code: "050504" },
  // { label: "Balite", mun_code: "050504" },
  // { label: "Banao", mun_code: "050504" },
  // { label: "Batbat", mun_code: "050504" },
  // { label: "Binogsacan Lower", mun_code: "050504" },
  // { label: "Bololo", mun_code: "050504" },
  // { label: "Bubulusan", mun_code: "050504" },
  // { label: "Calzada", mun_code: "050504" },
  // { label: "Catomag", mun_code: "050504" },
  // { label: "Ilawod", mun_code: "050504" },
  // { label: "Inamnan Grande", mun_code: "050504" },
  // { label: "Inascan", mun_code: "050504" },
  // { label: "Iraya", mun_code: "050504" },
  // { label: "Lomacao", mun_code: "050504" },
  // { label: "Maguiron", mun_code: "050504" },
  // { label: "Maipon", mun_code: "050504" },
  // { label: "Malabnig", mun_code: "050504" },
  // { label: "Malipo", mun_code: "050504" },
  // { label: "Malobago", mun_code: "050504" },
  // { label: "Maninila", mun_code: "050504" },
  // { label: "Mapaco", mun_code: "050504" },
  // { label: "Masarawag", mun_code: "050504" },
  // { label: "Mauraro", mun_code: "050504" },
  // { label: "Minto", mun_code: "050504" },
  // { label: "Morera", mun_code: "050504" },
  // { label: "Muladbucad Grande", mun_code: "050504" },
  // { label: "Ongo", mun_code: "050504" },
  // { label: "Palanas", mun_code: "050504" },
  // { label: "Poblacion", mun_code: "050504" },
  // { label: "Pood", mun_code: "050504" },
  // { label: "Quitago", mun_code: "050504" },
  // { label: "Quibongbongan", mun_code: "050504" },
  // { label: "San Francisco", mun_code: "050504" },
  // { label: "San Jose (Ogsong)", mun_code: "050504" },
  // { label: "San Rafael", mun_code: "050504" },
  // { label: "Sinungtan", mun_code: "050504" },
  // { label: "Tandarora", mun_code: "050504" },
  // { label: "Travesia", mun_code: "050504" },
  // { label: "Binogsacan Upper", mun_code: "050504" },
  // { label: "Bagacay", mun_code: "050505" },
  // { label: "Rizal Pob. (Bgy. 1)", mun_code: "050505" },
  // { label: "Mabini Pob. (Bgy. 2)", mun_code: "050505" },
  // { label: "Plaza Pob. (Bgy. 3)", mun_code: "050505" },
  // { label: "Magsaysay Pob (Bgy. 4)", mun_code: "050505" },
  // { label: "Calzada Pob. (Bgy. 7)", mun_code: "050505" },
  // { label: "Quitinday Pob. (Bgy. 8)", mun_code: "050505" },
  // { label: "White Deer Pob. (Bgy. 9)", mun_code: "050505" },
  // { label: "Bautista", mun_code: "050505" },
  // { label: "Cabraran", mun_code: "050505" },
  // { label: "Del Rosario", mun_code: "050505" },
  // { label: "Estrella", mun_code: "050505" },
  // { label: "Florista", mun_code: "050505" },
  // { label: "Mamlad", mun_code: "050505" },
  // { label: "Maogog", mun_code: "050505" },
  // { label: "Mercado Pob. (Bgy. 5)", mun_code: "050505" },
  // { label: "Salvacion", mun_code: "050505" },
  // { label: "San Isidro", mun_code: "050505" },
  // { label: "San Roque", mun_code: "050505" },
  // { label: "San Vicente", mun_code: "050505" },
  // { label: "Sinagaran", mun_code: "050505" },
  // { label: "Villa Paz", mun_code: "050505" },
  // { label: "Aurora Pob. (Bgy. 6)", mun_code: "050505" },
  // { label: "Bgy. 47 - Arimbay", mun_code: "050506" },
  // { label: "Bgy. 64 - Bagacay (Bgy. 41 Bagacay)", mun_code: "050506" },
  // { label: "Bgy. 48 - Bagong Abre (Bgy. 42)", mun_code: "050506" },
  // { label: "Bgy. 66 - Banquerohan (Bgy. 43)", mun_code: "050506" },
  // { label: "Bgy. 1 - Em's Barrio (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 11 - Maoyod Pob. (Bgy. 10 & 11)", mun_code: "050506" },
  // { label: "Bgy. 12 - Tula-tula (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 13 - Ilawod West Pob. (Ilawod 1)", mun_code: "050506" },
  // { label: "Bgy. 14 - Ilawod Pob. (Ilawod 2)", mun_code: "050506" },
  // { label: "Bgy. 15 - Ilawod East Pob. (Ilawod 3)", mun_code: "050506" },
  // {
  //   label: "Bgy. 16 - Kawit-East Washington Drive (Pob.)",
  //   mun_code: "050506",
  // },
  // { label: "Bgy. 17 - Rizal Sreet., Ilawod (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 2 - Em's Barrio South (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 21 - Binanuahan West (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 22 - Binanuahan East (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 23 - Imperial Court Subd. (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 25 - Lapu-lapu (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 26 - Dinagaan (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 27 - Victory Village South (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 28 - Victory Village North (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 29 - Sabang (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 3 - Em's Barrio East (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 36 - Kapantawan (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 30 - Pigcale (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 31 - Centro-Baybay (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 34 - Oro Site-Magallanes St. (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 35 - Tinago (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 37 - Bitano (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 39 - Bonot (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 4 - Sagpon Pob. (Sagpon 1)", mun_code: "050506" },
  // { label: "Bgy. 5 - Sagmin Pob. (Sagpon 2)", mun_code: "050506" },
  // { label: "Bgy. 8 - Bagumbayan (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 9 - Pinaric (Pob.)", mun_code: "050506" },
  // { label: "Bgy. 67 - Bariis (Bgy. 46)", mun_code: "050506" },
  // { label: "Bgy. 49 - Bigaa (Bgy. 44)", mun_code: "050506" },
  // { label: "Bgy. 41 - Bogtong (Bgy. 45)", mun_code: "050506" },
  // { label: "Bgy. 53 - Bonga (Bgy. 48)", mun_code: "050506" },
  // { label: "Bgy. 69 - Buenavista (Bgy.47)", mun_code: "050506" },
  // { label: "Bgy. 51 - Buyuan (Bgy. 49)", mun_code: "050506" },
  // { label: "Bgy. 70 - Cagbacong (Bgy. 50)", mun_code: "050506" },
  // { label: "Bgy. 40 - Cruzada (Bgy. 52)", mun_code: "050506" },
  // { label: "Bgy. 57 - Dap-dap (Bgy. 69)", mun_code: "050506" },
  // { label: "Bgy. 45 - Dita (Bgy. 51)", mun_code: "050506" },
  // { label: "Bgy. 55 - Estanza (Bgy. 53)", mun_code: "050506" },
  // { label: "Bgy. 38 - Gogon (Bgy. 54)", mun_code: "050506" },
  // { label: "Bgy. 62 - Homapon (Bgy. 55)", mun_code: "050506" },
  // { label: "Bgy. 65 - Imalnod (Bgy. 57)", mun_code: "050506" },
  // { label: "Bgy. 54 - Mabinit (Bgy. 59)", mun_code: "050506" },
  // { label: "Bgy. 63 - Mariawa (Bgy. 56)", mun_code: "050506" },
  // { label: "Bgy. 61 - Maslog (Bgy. 58)", mun_code: "050506" },
  // { label: "Bgy. 50 - Padang (Bgy. 60)", mun_code: "050506" },
  // { label: "Bgy. 44 - Pawa (Bgy. 61)", mun_code: "050506" },
  // { label: "Bgy. 59 - Puro (Bgy. 63)", mun_code: "050506" },
  // { label: "Bgy. 42 - Rawis (Bgy. 65)", mun_code: "050506" },
  // { label: "Bgy. 68 - San Francisco (Bgy. 62)", mun_code: "050506" },
  // { label: "Bgy. 46 - San Joaquin (Bgy. 64)", mun_code: "050506" },
  // { label: "Bgy. 32 - San Roque (Bgy. 66)", mun_code: "050506" },
  // { label: "Bgy. 43 - Tamaoyan (Bgy. 67)", mun_code: "050506" },
  // { label: "Bgy. 56 - Taysan (Bgy. 68)", mun_code: "050506" },
  // { label: "Bgy. 52 - Matanag", mun_code: "050506" },
  // { label: "Bgy. 10 - Cabugao", mun_code: "050506" },
  // { label: "Bgy. 24 - Rizal Street", mun_code: "050506" },
  // { label: "Bgy. 58 - Buragwis", mun_code: "050506" },
  // { label: "Bgy. 60 - Lamba", mun_code: "050506" },
  // { label: "Alongong", mun_code: "050507" },
  // { label: "Apud", mun_code: "050507" },
  // { label: "Bacolod", mun_code: "050507" },
  // { label: "Zone I (Pob.)", mun_code: "050507" },
  // { label: "Zone II (Pob.)", mun_code: "050507" },
  // { label: "Zone III (Pob.)", mun_code: "050507" },
  // { label: "Zone IV (Pob.)", mun_code: "050507" },
  // { label: "Zone V (Pob.)", mun_code: "050507" },
  // { label: "Zone VI (Pob.)", mun_code: "050507" },
  // { label: "Zone VII (Pob.)", mun_code: "050507" },
  // { label: "Bariw", mun_code: "050507" },
  // { label: "Bonbon", mun_code: "050507" },
  // { label: "Buga", mun_code: "050507" },
  // { label: "Bulusan", mun_code: "050507" },
  // { label: "Burabod", mun_code: "050507" },
  // { label: "Caguscos", mun_code: "050507" },
  // { label: "East Carisac", mun_code: "050507" },
  // { label: "West Carisac", mun_code: "050507" },
  // { label: "Harigue", mun_code: "050507" },
  // { label: "Libtong", mun_code: "050507" },
  // { label: "Linao", mun_code: "050507" },
  // { label: "Mabayawas", mun_code: "050507" },
  // { label: "Macabugos", mun_code: "050507" },
  // { label: "Magallang", mun_code: "050507" },
  // { label: "Malabiga", mun_code: "050507" },
  // { label: "Marayag", mun_code: "050507" },
  // { label: "Matara", mun_code: "050507" },
  // { label: "Molosbolos", mun_code: "050507" },
  // { label: "Natasan", mun_code: "050507" },
  // { label: "Nogpo", mun_code: "050507" },
  // { label: "Pantao", mun_code: "050507" },
  // { label: "Rawis", mun_code: "050507" },
  // { label: "Sagrada Familia", mun_code: "050507" },
  // { label: "Salvacion", mun_code: "050507" },
  // { label: "Sampongan", mun_code: "050507" },
  // { label: "San Agustin", mun_code: "050507" },
  // { label: "San Antonio", mun_code: "050507" },
  // { label: "San Isidro", mun_code: "050507" },
  // { label: "San Jose", mun_code: "050507" },
  // { label: "San Pascual", mun_code: "050507" },
  // { label: "San Ramon", mun_code: "050507" },
  // { label: "San Vicente", mun_code: "050507" },
  // { label: "Santa Cruz", mun_code: "050507" },
  // { label: "Talin-talin", mun_code: "050507" },
  // { label: "Tambo", mun_code: "050507" },
  // { label: "Villa Petrona", mun_code: "050507" },
  // { label: "Abella", mun_code: "050508" },
  // { label: "Allang", mun_code: "050508" },
  // { label: "Amtic", mun_code: "050508" },
  // { label: "Bacong", mun_code: "050508" },
  // { label: "Bagumbayan", mun_code: "050508" },
  // { label: "Balanac", mun_code: "050508" },
  // { label: "Baligang", mun_code: "050508" },
  // { label: "Barayong", mun_code: "050508" },
  // { label: "Basag", mun_code: "050508" },
  // { label: "Batang", mun_code: "050508" },
  // { label: "Bay", mun_code: "050508" },
  // { label: "Binanowan", mun_code: "050508" },
  // { label: "Binatagan (Pob.)", mun_code: "050508" },
  // { label: "Bobonsuran", mun_code: "050508" },
  // { label: "Bonga", mun_code: "050508" },
  // { label: "Busac", mun_code: "050508" },
  // { label: "Busay", mun_code: "050508" },
  // { label: "Cabarian", mun_code: "050508" },
  // { label: "Calzada (Pob.)", mun_code: "050508" },
  // { label: "Catburawan", mun_code: "050508" },
  // { label: "Cavasi", mun_code: "050508" },
  // { label: "Culliat", mun_code: "050508" },
  // { label: "Dunao", mun_code: "050508" },
  // { label: "Francia", mun_code: "050508" },
  // { label: "Guilid", mun_code: "050508" },
  // { label: "Herrera", mun_code: "050508" },
  // { label: "Layon", mun_code: "050508" },
  // { label: "Macalidong", mun_code: "050508" },
  // { label: "Mahaba", mun_code: "050508" },
  // { label: "Malama", mun_code: "050508" },
  // { label: "Maonon", mun_code: "050508" },
  // { label: "Nasisi", mun_code: "050508" },
  // { label: "Nabonton", mun_code: "050508" },
  // { label: "Oma-oma", mun_code: "050508" },
  // { label: "Palapas", mun_code: "050508" },
  // { label: "Pandan", mun_code: "050508" },
  // { label: "Paulba", mun_code: "050508" },
  // { label: "Paulog", mun_code: "050508" },
  // { label: "Pinamaniquian", mun_code: "050508" },
  // { label: "Pinit", mun_code: "050508" },
  // { label: "Ranao-ranao", mun_code: "050508" },
  // { label: "San Vicente", mun_code: "050508" },
  // { label: "Santa Cruz (Pob.)", mun_code: "050508" },
  // { label: "Tagpo", mun_code: "050508" },
  // { label: "Tambo", mun_code: "050508" },
  // { label: "Tandarora", mun_code: "050508" },
  // { label: "Tastas", mun_code: "050508" },
  // { label: "Tinago", mun_code: "050508" },
  // { label: "Tinampo", mun_code: "050508" },
  // { label: "Tiongson", mun_code: "050508" },
  // { label: "Tomolin", mun_code: "050508" },
  // { label: "Tuburan", mun_code: "050508" },
  // { label: "Tula-tula Grande", mun_code: "050508" },
  // { label: "Tupas", mun_code: "050508" },
  // { label: "Binitayan", mun_code: "050509" },
  // { label: "Calbayog", mun_code: "050509" },
  // { label: "Canaway", mun_code: "050509" },
  // { label: "Barangay I (Pob.)", mun_code: "050509" },
  // { label: "Barangay II (Pob.)", mun_code: "050509" },
  // { label: "Barangay III (Pob.)", mun_code: "050509" },
  // { label: "Barangay IV (Pob.)", mun_code: "050509" },
  // { label: "Barangay V (Pob.)", mun_code: "050509" },
  // { label: "Salvacion", mun_code: "050509" },
  // { label: "San Antonio Santicon (Pob.)", mun_code: "050509" },
  // { label: "San Antonio Sulong", mun_code: "050509" },
  // { label: "San Francisco", mun_code: "050509" },
  // { label: "San Isidro Ilawod", mun_code: "050509" },
  // { label: "San Isidro Iraya", mun_code: "050509" },
  // { label: "San Jose", mun_code: "050509" },
  // { label: "San Roque", mun_code: "050509" },
  // { label: "Santa Cruz", mun_code: "050509" },
  // { label: "Santa Teresa", mun_code: "050509" },
  // { label: "Awang", mun_code: "050510" },
  // { label: "Bagatangki", mun_code: "050510" },
  // { label: "Balading", mun_code: "050510" },
  // { label: "Balza", mun_code: "050510" },
  // { label: "Bariw", mun_code: "050510" },
  // { label: "Baybay", mun_code: "050510" },
  // { label: "Bulang", mun_code: "050510" },
  // { label: "Burabod", mun_code: "050510" },
  // { label: "Cabunturan", mun_code: "050510" },
  // { label: "Comun", mun_code: "050510" },
  // { label: "Diaro", mun_code: "050510" },
  // { label: "Estancia", mun_code: "050510" },
  // { label: "Jonop", mun_code: "050510" },
  // { label: "Labnig", mun_code: "050510" },
  // { label: "Libod", mun_code: "050510" },
  // { label: "Malolos", mun_code: "050510" },
  // { label: "Matalipni", mun_code: "050510" },
  // { label: "Ogob", mun_code: "050510" },
  // { label: "Pawa", mun_code: "050510" },
  // { label: "Payahan", mun_code: "050510" },
  // { label: "Poblacion", mun_code: "050510" },
  // { label: "Bagumbayan", mun_code: "050510" },
  // { label: "Quinarabasahan", mun_code: "050510" },
  // { label: "Santa Elena", mun_code: "050510" },
  // { label: "Soa", mun_code: "050510" },
  // { label: "Sugcad", mun_code: "050510" },
  // { label: "Tagoytoy", mun_code: "050510" },
  // { label: "Tanawan", mun_code: "050510" },
  // { label: "Tuliw", mun_code: "050510" },
  // { label: "Balabagon", mun_code: "050511" },
  // { label: "Balasbas", mun_code: "050511" },
  // { label: "Bamban", mun_code: "050511" },
  // { label: "Buyo", mun_code: "050511" },
  // { label: "Cabacongan", mun_code: "050511" },
  // { label: "Cabit", mun_code: "050511" },
  // { label: "Cawayan", mun_code: "050511" },
  // { label: "Cawit", mun_code: "050511" },
  // { label: "Holugan", mun_code: "050511" },
  // { label: "It-Ba (Pob.)", mun_code: "050511" },
  // { label: "Malobago", mun_code: "050511" },
  // { label: "Manumbalay", mun_code: "050511" },
  // { label: "Nagotgot", mun_code: "050511" },
  // { label: "Pawa", mun_code: "050511" },
  // { label: "Tinapian", mun_code: "050511" },
  // { label: "Badbad", mun_code: "050512" },
  // { label: "Badian", mun_code: "050512" },
  // { label: "Bagsa", mun_code: "050512" },
  // { label: "Bagumbayan", mun_code: "050512" },
  // { label: "Balogo", mun_code: "050512" },
  // { label: "Banao", mun_code: "050512" },
  // { label: "Bangiawon", mun_code: "050512" },
  // { label: "Bongoran", mun_code: "050512" },
  // { label: "Bogtong", mun_code: "050512" },
  // { label: "Busac", mun_code: "050512" },
  // { label: "Cadawag", mun_code: "050512" },
  // { label: "Cagmanaba", mun_code: "050512" },
  // { label: "Calaguimit", mun_code: "050512" },
  // { label: "Calpi", mun_code: "050512" },
  // { label: "Calzada", mun_code: "050512" },
  // { label: "Camagong", mun_code: "050512" },
  // { label: "Casinagan", mun_code: "050512" },
  // { label: "Centro Poblacion", mun_code: "050512" },
  // { label: "Coliat", mun_code: "050512" },
  // { label: "Del Rosario", mun_code: "050512" },
  // { label: "Gumabao", mun_code: "050512" },
  // { label: "Ilaor Norte", mun_code: "050512" },
  // { label: "Ilaor Sur", mun_code: "050512" },
  // { label: "Iraya Norte", mun_code: "050512" },
  // { label: "Iraya Sur", mun_code: "050512" },
  // { label: "Manga", mun_code: "050512" },
  // { label: "Maporong", mun_code: "050512" },
  // { label: "Maramba", mun_code: "050512" },
  // { label: "Moroponros", mun_code: "050512" },
  // { label: "Matambo", mun_code: "050512" },
  // { label: "Mayag", mun_code: "050512" },
  // { label: "Mayao", mun_code: "050512" },
  // { label: "Nagas", mun_code: "050512" },
  // { label: "San Pascual (Nale)", mun_code: "050512" },
  // { label: "Obaliw-Rinas", mun_code: "050512" },
  // { label: "Pistola", mun_code: "050512" },
  // { label: "Ramay", mun_code: "050512" },
  // { label: "Rizal", mun_code: "050512" },
  // { label: "Saban", mun_code: "050512" },
  // { label: "San Agustin", mun_code: "050512" },
  // { label: "San Antonio", mun_code: "050512" },
  // { label: "San Isidro", mun_code: "050512" },
  // { label: "San Jose", mun_code: "050512" },
  // { label: "San Juan", mun_code: "050512" },
  // { label: "San Miguel", mun_code: "050512" },
  // { label: "San Ramon", mun_code: "050512" },
  // { label: "San Vicente (Suca)", mun_code: "050512" },
  // { label: "Talisay", mun_code: "050512" },
  // { label: "Talongog", mun_code: "050512" },
  // { label: "Tapel", mun_code: "050512" },
  // { label: "Tobgon", mun_code: "050512" },
  // { label: "Tobog", mun_code: "050512" },
  // { label: "Tablon", mun_code: "050512" },
  // { label: "Agol", mun_code: "050513" },
  // { label: "Alabangpuro", mun_code: "050513" },
  // { label: "Basicao Coastal", mun_code: "050513" },
  // { label: "Basicao Interior", mun_code: "050513" },
  // { label: "Banawan (Binawan)", mun_code: "050513" },
  // { label: "Binodegahan", mun_code: "050513" },
  // { label: "Buenavista", mun_code: "050513" },
  // { label: "Buyo", mun_code: "050513" },
  // { label: "Caratagan", mun_code: "050513" },
  // { label: "Cuyaoyao", mun_code: "050513" },
  // { label: "Flores", mun_code: "050513" },
  // { label: "Lawinon", mun_code: "050513" },
  // { label: "Macasitas", mun_code: "050513" },
  // { label: "Malapay", mun_code: "050513" },
  // { label: "Malidong", mun_code: "050513" },
  // { label: "Mamlad", mun_code: "050513" },
  // { label: "Marigondon", mun_code: "050513" },
  // { label: "Matanglad", mun_code: "050513" },
  // { label: "Nablangbulod", mun_code: "050513" },
  // { label: "Oringon", mun_code: "050513" },
  // { label: "Palapas", mun_code: "050513" },
  // { label: "Panganiran", mun_code: "050513" },
  // { label: "Barangay I (Pob.)", mun_code: "050513" },
  // { label: "Barangay II (Pob.)", mun_code: "050513" },
  // { label: "Barangay III (Pob.)", mun_code: "050513" },
  // { label: "Barangay IV (Pob.)", mun_code: "050513" },
  // { label: "Barangay V (Pob.)", mun_code: "050513" },
  // { label: "Rawis", mun_code: "050513" },
  // { label: "Salvacion", mun_code: "050513" },
  // { label: "Santo Cristo", mun_code: "050513" },
  // { label: "Sukip", mun_code: "050513" },
  // { label: "Tibabo", mun_code: "050513" },
  // { label: "La Medalla", mun_code: "050513" },
  // { label: "Agos", mun_code: "050514" },
  // { label: "Alnay", mun_code: "050514" },
  // { label: "Alomon", mun_code: "050514" },
  // { label: "Amoguis", mun_code: "050514" },
  // { label: "Anopol", mun_code: "050514" },
  // { label: "Apad", mun_code: "050514" },
  // { label: "Balaba", mun_code: "050514" },
  // { label: "Balangibang", mun_code: "050514" },
  // { label: "Balinad", mun_code: "050514" },
  // { label: "Basud", mun_code: "050514" },
  // { label: "Binagbangan (Pintor)", mun_code: "050514" },
  // { label: "Buyo", mun_code: "050514" },
  // { label: "Centro Occidental (Pob.)", mun_code: "050514" },
  // { label: "Centro Oriental (Pob.)", mun_code: "050514" },
  // { label: "Cepres", mun_code: "050514" },
  // { label: "Cotmon", mun_code: "050514" },
  // { label: "Cotnogan", mun_code: "050514" },
  // { label: "Danao", mun_code: "050514" },
  // { label: "Gabon", mun_code: "050514" },
  // { label: "Gamot", mun_code: "050514" },
  // { label: "Itaran", mun_code: "050514" },
  // { label: "Kinale", mun_code: "050514" },
  // { label: "Kinuartilan", mun_code: "050514" },
  // { label: "La Medalla", mun_code: "050514" },
  // { label: "La Purisima", mun_code: "050514" },
  // { label: "Lanigay", mun_code: "050514" },
  // { label: "Lidong", mun_code: "050514" },
  // { label: "Lourdes", mun_code: "050514" },
  // { label: "Magpanambo", mun_code: "050514" },
  // { label: "Magurang", mun_code: "050514" },
  // { label: "Matacon", mun_code: "050514" },
  // { label: "Maynaga", mun_code: "050514" },
  // { label: "Maysua", mun_code: "050514" },
  // { label: "Mendez", mun_code: "050514" },
  // { label: "Napo", mun_code: "050514" },
  // { label: "Pinagdapugan", mun_code: "050514" },
  // { label: "Ponso", mun_code: "050514" },
  // { label: "Salvacion", mun_code: "050514" },
  // { label: "San Roque", mun_code: "050514" },
  // { label: "Santicon", mun_code: "050514" },
  // { label: "Santa Cruz", mun_code: "050514" },
  // { label: "Santa Teresita", mun_code: "050514" },
  // { label: "Sugcad", mun_code: "050514" },
  // { label: "Ubaliw", mun_code: "050514" },
  // { label: "Bagaobawan", mun_code: "050515" },
  // { label: "Batan", mun_code: "050515" },
  // { label: "Bilbao", mun_code: "050515" },
  // { label: "Binosawan", mun_code: "050515" },
  // { label: "Bogtong", mun_code: "050515" },
  // { label: "Buenavista", mun_code: "050515" },
  // { label: "Buhatan", mun_code: "050515" },
  // { label: "Calanaga", mun_code: "050515" },
  // { label: "Caracaran", mun_code: "050515" },
  // { label: "Carogcog", mun_code: "050515" },
  // { label: "Dap-dap", mun_code: "050515" },
  // { label: "Gaba", mun_code: "050515" },
  // { label: "Galicia", mun_code: "050515" },
  // { label: "Guadalupe", mun_code: "050515" },
  // { label: "Hamorawon", mun_code: "050515" },
  // { label: "Lagundi", mun_code: "050515" },
  // { label: "Liguan", mun_code: "050515" },
  // { label: "Linao", mun_code: "050515" },
  // { label: "Malobago", mun_code: "050515" },
  // { label: "Mananao", mun_code: "050515" },
  // { label: "Mancao", mun_code: "050515" },
  // { label: "Manila", mun_code: "050515" },
  // { label: "Masaga", mun_code: "050515" },
  // { label: "Morocborocan", mun_code: "050515" },
  // { label: "Nagcalsot", mun_code: "050515" },
  // { label: "Pagcolbon", mun_code: "050515" },
  // { label: "Poblacion", mun_code: "050515" },
  // { label: "Sagrada", mun_code: "050515" },
  // { label: "San Ramon", mun_code: "050515" },
  // { label: "Santa Barbara", mun_code: "050515" },
  // { label: "Tinocawan", mun_code: "050515" },
  // { label: "Tinopan", mun_code: "050515" },
  // { label: "Viga", mun_code: "050515" },
  // { label: "Villahermosa", mun_code: "050515" },
  // { label: "Alimsog", mun_code: "050516" },
  // { label: "Buhatan", mun_code: "050516" },
  // { label: "Calayucay", mun_code: "050516" },
  // { label: "Fidel Surtida", mun_code: "050516" },
  // { label: "Lidong", mun_code: "050516" },
  // { label: "Bagong San Roque", mun_code: "050516" },
  // { label: "San Juan Pob. (Bgy. 2)", mun_code: "050516" },
  // { label: "Santo Domingo Pob. (Bgy. 4)", mun_code: "050516" },
  // { label: "San Pedro Pob. (Bgy. 5)", mun_code: "050516" },
  // { label: "San Vicente Pob. (Bgy. 6.)", mun_code: "050516" },
  // { label: "San Rafael Pob. (Bgy. 7)", mun_code: "050516" },
  // { label: "Del Rosario Pob. (Bgy. 3)", mun_code: "050516" },
  // { label: "San Francisco Pob. (Bgy. 1)", mun_code: "050516" },
  // { label: "Nagsiya Pob. (Bgy. 8)", mun_code: "050516" },
  // { label: "Salvacion", mun_code: "050516" },
  // { label: "San Andres", mun_code: "050516" },
  // { label: "San Fernando", mun_code: "050516" },
  // { label: "San Isidro", mun_code: "050516" },
  // { label: "San Roque", mun_code: "050516" },
  // { label: "Santa Misericordia", mun_code: "050516" },
  // { label: "Market Site Pob. (Bgy. 9)", mun_code: "050516" },
  // { label: "Pandayan Pob. (Bgy. 10)", mun_code: "050516" },
  // { label: "Agnas (San Miguel Island)", mun_code: "050517" },
  // { label: "Bacolod", mun_code: "050517" },
  // { label: "Bangkilingan", mun_code: "050517" },
  // { label: "Bantayan", mun_code: "050517" },
  // { label: "Baranghawon", mun_code: "050517" },
  // { label: "Basagan", mun_code: "050517" },
  // { label: "Basud (Pob.)", mun_code: "050517" },
  // { label: "Bombon (Pob.)", mun_code: "050517" },
  // { label: "Bonot", mun_code: "050517" },
  // { label: "San Isidro (Boring)", mun_code: "050517" },
  // { label: "Buang", mun_code: "050517" },
  // { label: "Buhian", mun_code: "050517" },
  // { label: "Cobo", mun_code: "050517" },
  // { label: "Comon", mun_code: "050517" },
  // { label: "Cormidal", mun_code: "050517" },
  // { label: "Divino Rostro (Pob.)", mun_code: "050517" },
  // { label: "Fatima", mun_code: "050517" },
  // { label: "Guinobat", mun_code: "050517" },
  // { label: "Hacienda (San Miguel Island)", mun_code: "050517" },
  // { label: "Magapo", mun_code: "050517" },
  // { label: "Mariroc", mun_code: "050517" },
  // { label: "Matagbac", mun_code: "050517" },
  // { label: "Oras", mun_code: "050517" },
  // { label: "Oson", mun_code: "050517" },
  // { label: "Panal", mun_code: "050517" },
  // { label: "Pawa", mun_code: "050517" },
  // { label: "Pinagbobong", mun_code: "050517" },
  // { label: "Quinale Cabasan (Pob.)", mun_code: "050517" },
  // { label: "Quinastillojan", mun_code: "050517" },
  // { label: "Rawis (San Miguel Island)", mun_code: "050517" },
  // { label: "Sagurong (San Miguel Island)", mun_code: "050517" },
  // { label: "Salvacion", mun_code: "050517" },
  // { label: "San Antonio", mun_code: "050517" },
  // { label: "San Carlos", mun_code: "050517" },
  // { label: "San Juan (Pob.)", mun_code: "050517" },
  // { label: "San Lorenzo", mun_code: "050517" },
  // { label: "San Ramon", mun_code: "050517" },
  // { label: "San Roque", mun_code: "050517" },
  // { label: "San Vicente", mun_code: "050517" },
  // { label: "Santo Cristo (Pob.)", mun_code: "050517" },
  // { label: "Sua-Igot", mun_code: "050517" },
  // { label: "Tabiguian", mun_code: "050517" },
  // { label: "Tagas", mun_code: "050517" },
  // { label: "Tayhi (Pob.)", mun_code: "050517" },
  // { label: "Visita (San Miguel Island)", mun_code: "050517" },
  // { label: "Bagumbayan", mun_code: "050518" },
  // { label: "Bariis", mun_code: "050518" },
  // { label: "Baybay", mun_code: "050518" },
  // { label: "Belen (Malabog)", mun_code: "050518" },
  // { label: "Biyong", mun_code: "050518" },
  // { label: "Bolo", mun_code: "050518" },
  // { label: "Cale", mun_code: "050518" },
  // { label: "Cararayan", mun_code: "050518" },
  // { label: "Coro-coro", mun_code: "050518" },
  // { label: "Dap-dap", mun_code: "050518" },
  // { label: "Gajo", mun_code: "050518" },
  // { label: "Joroan", mun_code: "050518" },
  // { label: "Libjo", mun_code: "050518" },
  // { label: "Libtong", mun_code: "050518" },
  // { label: "Matalibong", mun_code: "050518" },
  // { label: "Maynonong", mun_code: "050518" },
  // { label: "Mayong", mun_code: "050518" },
  // { label: "Misibis", mun_code: "050518" },
  // { label: "Naga", mun_code: "050518" },
  // { label: "Nagas", mun_code: "050518" },
  // { label: "Oyama", mun_code: "050518" },
  // { label: "Putsan", mun_code: "050518" },
  // { label: "San Bernardo", mun_code: "050518" },
  // { label: "Sogod", mun_code: "050518" },
  // { label: "Tigbi (Pob.)", mun_code: "050518" },
  // { label: "Angas", mun_code: "051601" },
  // { label: "Bactas", mun_code: "051601" },
  // { label: "Binatagan", mun_code: "051601" },
  // { label: "Caayunan", mun_code: "051601" },
  // { label: "Guinatungan", mun_code: "051601" },
  // { label: "Hinampacan", mun_code: "051601" },
  // { label: "Langa", mun_code: "051601" },
  // { label: "Laniton", mun_code: "051601" },
  // { label: "Lidong", mun_code: "051601" },
  // { label: "Mampili", mun_code: "051601" },
  // { label: "Mandazo", mun_code: "051601" },
  // { label: "Mangcamagong", mun_code: "051601" },
  // { label: "Manmuntay", mun_code: "051601" },
  // { label: "Mantugawe", mun_code: "051601" },
  // { label: "Matnog", mun_code: "051601" },
  // { label: "Mocong", mun_code: "051601" },
  // { label: "Oliva", mun_code: "051601" },
  // { label: "Pagsangahan", mun_code: "051601" },
  // { label: "Pinagwarasan", mun_code: "051601" },
  // { label: "Plaridel", mun_code: "051601" },
  // { label: "Poblacion 1", mun_code: "051601" },
  // { label: "San Felipe", mun_code: "051601" },
  // { label: "San Jose", mun_code: "051601" },
  // { label: "San Pascual", mun_code: "051601" },
  // { label: "Taba-taba", mun_code: "051601" },
  // { label: "Tacad", mun_code: "051601" },
  // { label: "Taisan", mun_code: "051601" },
  // { label: "Tuaca", mun_code: "051601" },
  // { label: "Poblacion 2", mun_code: "051601" },
  // { label: "Alayao", mun_code: "051602" },
  // { label: "Binawangan", mun_code: "051602" },
  // { label: "Calabaca", mun_code: "051602" },
  // { label: "Camagsaan", mun_code: "051602" },
  // { label: "Catabaguangan", mun_code: "051602" },
  // { label: "Catioan", mun_code: "051602" },
  // { label: "Del Pilar", mun_code: "051602" },
  // { label: "Itok", mun_code: "051602" },
  // { label: "Lucbanan", mun_code: "051602" },
  // { label: "Mabini", mun_code: "051602" },
  // { label: "Mactang", mun_code: "051602" },
  // { label: "Mataque", mun_code: "051602" },
  // { label: "Old Camp", mun_code: "051602" },
  // { label: "Poblacion", mun_code: "051602" },
  // { label: "Magsaysay", mun_code: "051602" },
  // { label: "San Antonio", mun_code: "051602" },
  // { label: "San Isidro", mun_code: "051602" },
  // { label: "San Roque", mun_code: "051602" },
  // { label: "Tanawan", mun_code: "051602" },
  // { label: "Ubang", mun_code: "051602" },
  // { label: "Villa Aurora", mun_code: "051602" },
  // { label: "Villa Belen", mun_code: "051602" },
  // { label: "Alawihao", mun_code: "051603" },
  // { label: "Awitan", mun_code: "051603" },
  // { label: "Bagasbas", mun_code: "051603" },
  // { label: "Bibirao", mun_code: "051603" },
  // { label: "Borabod", mun_code: "051603" },
  // { label: "Calasgasan", mun_code: "051603" },
  // { label: "Camambugan", mun_code: "051603" },
  // { label: "Cobangbang (Carumpit)", mun_code: "051603" },
  // { label: "Dogongan", mun_code: "051603" },
  // { label: "Gahonon", mun_code: "051603" },
  // { label: "Gubat", mun_code: "051603" },
  // { label: "Lag-On", mun_code: "051603" },
  // { label: "Magang", mun_code: "051603" },
  // { label: "Mambalite", mun_code: "051603" },
  // { label: "Mancruz (Mangcruz)", mun_code: "051603" },
  // { label: "Pamorangon", mun_code: "051603" },
  // { label: "Barangay I (Pob.)", mun_code: "051603" },
  // { label: "Barangay II (Pob.)", mun_code: "051603" },
  // { label: "Barangay III (Pob.)", mun_code: "051603" },
  // { label: "Barangay IV (Pob.)", mun_code: "051603" },
  // { label: "Barangay V (Pob.)", mun_code: "051603" },
  // { label: "Barangay VI (Pob.)", mun_code: "051603" },
  // { label: "Barangay VII (Pob.)", mun_code: "051603" },
  // { label: "Barangay VIII (Pob.)", mun_code: "051603" },
  // { label: "San Isidro", mun_code: "051603" },
  // { label: "Daculang Bolo", mun_code: "051604" },
  // { label: "Dagotdotan", mun_code: "051604" },
  // { label: "Langga", mun_code: "051604" },
  // { label: "Laniton", mun_code: "051604" },
  // { label: "Maisog", mun_code: "051604" },
  // { label: "Mampurog", mun_code: "051604" },
  // { label: "Manlimonsito", mun_code: "051604" },
  // { label: "Matacong (Pob.)", mun_code: "051604" },
  // { label: "Salvacion", mun_code: "051604" },
  // { label: "San Antonio", mun_code: "051604" },
  // { label: "San Isidro", mun_code: "051604" },
  // { label: "San Ramon", mun_code: "051604" },
  // { label: "Bagong Bayan", mun_code: "051605" },
  // { label: "Calero", mun_code: "051605" },
  // { label: "Dahican", mun_code: "051605" },
  // { label: "Dayhagan", mun_code: "051605" },
  // { label: "Larap", mun_code: "051605" },
  // { label: "Luklukan Norte", mun_code: "051605" },
  // { label: "Luklukan Sur", mun_code: "051605" },
  // { label: "Motherlode", mun_code: "051605" },
  // { label: "Nakalaya", mun_code: "051605" },
  // { label: "Pag-Asa", mun_code: "051605" },
  // { label: "Parang", mun_code: "051605" },
  // { label: "Plaridel", mun_code: "051605" },
  // { label: "North Poblacion", mun_code: "051605" },
  // { label: "South Poblacion", mun_code: "051605" },
  // { label: "Salvacion", mun_code: "051605" },
  // { label: "San Isidro", mun_code: "051605" },
  // { label: "San Jose", mun_code: "051605" },
  // { label: "San Martin", mun_code: "051605" },
  // { label: "San Pedro", mun_code: "051605" },
  // { label: "San Rafael", mun_code: "051605" },
  // { label: "Santa Cruz", mun_code: "051605" },
  // { label: "Santa Elena", mun_code: "051605" },
  // { label: "Santa Milagrosa", mun_code: "051605" },
  // { label: "Santa Rosa Norte", mun_code: "051605" },
  // { label: "Santa Rosa Sur", mun_code: "051605" },
  // { label: "Tamisan", mun_code: "051605" },
  // { label: "Alabelam", mun_code: "051606" },
  // { label: "Awitan", mun_code: "051606" },
  // { label: "Baay", mun_code: "051606" },
  // { label: "Bagacay", mun_code: "051606" },
  // { label: "Bagong Silang I", mun_code: "051606" },
  // { label: "Bagong Silang II", mun_code: "051606" },
  // { label: "Bakiad", mun_code: "051606" },
  // { label: "Bautista", mun_code: "051606" },
  // { label: "Bayabas", mun_code: "051606" },
  // { label: "Bayan-bayan", mun_code: "051606" },
  // { label: "Benit", mun_code: "051606" },
  // { label: "Anahaw (Pob.)", mun_code: "051606" },
  // { label: "Gumamela (Pob.)", mun_code: "051606" },
  // { label: "San Francisco (Pob.)", mun_code: "051606" },
  // { label: "Kalamunding (Pob.)", mun_code: "051606" },
  // { label: "Bulhao", mun_code: "051606" },
  // { label: "Cabatuhan", mun_code: "051606" },
  // { label: "Cabusay", mun_code: "051606" },
  // { label: "Calabasa", mun_code: "051606" },
  // { label: "Canapawan", mun_code: "051606" },
  // { label: "Daguit", mun_code: "051606" },
  // { label: "Dalas", mun_code: "051606" },
  // { label: "Dumagmang", mun_code: "051606" },
  // { label: "Exciban", mun_code: "051606" },
  // { label: "Fundado", mun_code: "051606" },
  // { label: "Guinacutan", mun_code: "051606" },
  // { label: "Guisican", mun_code: "051606" },
  // { label: "Iberica", mun_code: "051606" },
  // { label: "Lugui", mun_code: "051606" },
  // { label: "Mabilo I", mun_code: "051606" },
  // { label: "Mabilo II", mun_code: "051606" },
  // { label: "Macogon", mun_code: "051606" },
  // { label: "Mahawan-hawan", mun_code: "051606" },
  // { label: "Malangcao-Basud", mun_code: "051606" },
  // { label: "Malasugui", mun_code: "051606" },
  // { label: "Malatap", mun_code: "051606" },
  // { label: "Malaya", mun_code: "051606" },
  // { label: "Malibago", mun_code: "051606" },
  // { label: "Maot", mun_code: "051606" },
  // { label: "Masalong", mun_code: "051606" },
  // { label: "Matanlang", mun_code: "051606" },
  // { label: "Napaod", mun_code: "051606" },
  // { label: "Pag-Asa", mun_code: "051606" },
  // { label: "Pangpang", mun_code: "051606" },
  // { label: "Pinya (Pob.)", mun_code: "051606" },
  // { label: "San Antonio", mun_code: "051606" },
  // { label: "Santa Cruz", mun_code: "051606" },
  // { label: "Bagong Silang III (Silang)", mun_code: "051606" },
  // { label: "Submakin", mun_code: "051606" },
  // { label: "Talobatib", mun_code: "051606" },
  // { label: "Tigbinan", mun_code: "051606" },
  // { label: "Tulay Na Lupa", mun_code: "051606" },
  // { label: "Apuao", mun_code: "051607" },
  // { label: "Barangay I (Pob.)", mun_code: "051607" },
  // { label: "Barangay II (Pob.)", mun_code: "051607" },
  // { label: "Barangay III (Pob.)", mun_code: "051607" },
  // { label: "Barangay IV (Pob.)", mun_code: "051607" },
  // { label: "Barangay V (Pob.)", mun_code: "051607" },
  // { label: "Barangay VI (Pob.)", mun_code: "051607" },
  // { label: "Barangay VII (Pob.)", mun_code: "051607" },
  // { label: "Caringo", mun_code: "051607" },
  // { label: "Catandunganon", mun_code: "051607" },
  // { label: "Cayucyucan", mun_code: "051607" },
  // { label: "Colasi", mun_code: "051607" },
  // { label: "Del Rosario (Tagongtong)", mun_code: "051607" },
  // { label: "Gaboc", mun_code: "051607" },
  // { label: "Hamoraon", mun_code: "051607" },
  // { label: "Hinipaan", mun_code: "051607" },
  // { label: "Lalawigan", mun_code: "051607" },
  // { label: "Lanot", mun_code: "051607" },
  // { label: "Mambungalon", mun_code: "051607" },
  // { label: "Manguisoc", mun_code: "051607" },
  // { label: "Masalongsalong", mun_code: "051607" },
  // { label: "Matoogtoog", mun_code: "051607" },
  // { label: "Pambuhan", mun_code: "051607" },
  // { label: "Quinapaguian", mun_code: "051607" },
  // { label: "San Roque", mun_code: "051607" },
  // { label: "Tarum", mun_code: "051607" },
  // { label: "Awitan", mun_code: "051608" },
  // { label: "Bagumbayan", mun_code: "051608" },
  // { label: "Bakal", mun_code: "051608" },
  // { label: "Batobalani", mun_code: "051608" },
  // { label: "Calaburnay", mun_code: "051608" },
  // { label: "Capacuan", mun_code: "051608" },
  // { label: "Casalugan", mun_code: "051608" },
  // { label: "Dagang", mun_code: "051608" },
  // { label: "Dalnac", mun_code: "051608" },
  // { label: "Dancalan", mun_code: "051608" },
  // { label: "Gumaus", mun_code: "051608" },
  // { label: "Labnig", mun_code: "051608" },
  // { label: "Macolabo Island", mun_code: "051608" },
  // { label: "Malacbang", mun_code: "051608" },
  // { label: "Malaguit", mun_code: "051608" },
  // { label: "Mampungo", mun_code: "051608" },
  // { label: "Mangkasay", mun_code: "051608" },
  // { label: "Maybato", mun_code: "051608" },
  // { label: "Palanas", mun_code: "051608" },
  // { label: "Pinagbirayan Malaki", mun_code: "051608" },
  // { label: "Pinagbirayan Munti", mun_code: "051608" },
  // { label: "Poblacion Norte", mun_code: "051608" },
  // { label: "Poblacion Sur", mun_code: "051608" },
  // { label: "Tabas", mun_code: "051608" },
  // { label: "Talusan", mun_code: "051608" },
  // { label: "Tawig", mun_code: "051608" },
  // { label: "Tugos", mun_code: "051608" },
  // { label: "Asdum", mun_code: "051609" },
  // { label: "Cabanbanan", mun_code: "051609" },
  // { label: "Calabagas", mun_code: "051609" },
  // { label: "Fabrica", mun_code: "051609" },
  // { label: "Iraya Sur", mun_code: "051609" },
  // { label: "Man-Ogob", mun_code: "051609" },
  // { label: "Poblacion District I (Silangan/Bgy. 1)", mun_code: "051609" },
  // { label: "Poblacion District II (Kanluran/Bgy. 2)", mun_code: "051609" },
  // { label: "San Jose (Iraya Norte)", mun_code: "051609" },
  // { label: "Basiad", mun_code: "051610" },
  // { label: "Bulala", mun_code: "051610" },
  // { label: "Polungguitguit", mun_code: "051610" },
  // { label: "Rizal", mun_code: "051610" },
  // { label: "Salvacion", mun_code: "051610" },
  // { label: "San Lorenzo", mun_code: "051610" },
  // { label: "San Pedro", mun_code: "051610" },
  // { label: "San Vicente", mun_code: "051610" },
  // { label: "Santa Elena (Pob.)", mun_code: "051610" },
  // { label: "Villa San Isidro", mun_code: "051610" },
  // { label: "Don Tomas", mun_code: "051610" },
  // { label: "Guitol", mun_code: "051610" },
  // { label: "Kabuluan", mun_code: "051610" },
  // { label: "Kagtalaba", mun_code: "051610" },
  // { label: "Maulawin", mun_code: "051610" },
  // { label: "Patag Ibaba", mun_code: "051610" },
  // { label: "Patag Iraya", mun_code: "051610" },
  // { label: "Plaridel", mun_code: "051610" },
  // { label: "Tabugon", mun_code: "051610" },
  // { label: "Binanuaan", mun_code: "051611" },
  // { label: "Caawigan", mun_code: "051611" },
  // { label: "Cahabaan", mun_code: "051611" },
  // { label: "Calintaan", mun_code: "051611" },
  // { label: "Del Carmen", mun_code: "051611" },
  // { label: "Gabon", mun_code: "051611" },
  // { label: "Itomang", mun_code: "051611" },
  // { label: "Poblacion", mun_code: "051611" },
  // { label: "San Francisco", mun_code: "051611" },
  // { label: "San Isidro", mun_code: "051611" },
  // { label: "San Jose", mun_code: "051611" },
  // { label: "San Nicolas", mun_code: "051611" },
  // { label: "Santa Cruz", mun_code: "051611" },
  // { label: "Santa Elena", mun_code: "051611" },
  // { label: "Aguit-It", mun_code: "051612" },
  // { label: "Banocboc", mun_code: "051612" },
  // { label: "Cagbalogo", mun_code: "051612" },
  // { label: "Calangcawan Norte", mun_code: "051612" },
  // { label: "Calangcawan Sur", mun_code: "051612" },
  // { label: "Guinacutan", mun_code: "051612" },
  // { label: "Mangcayo", mun_code: "051612" },
  // { label: "Mangcawayan", mun_code: "051612" },
  // { label: "Manlucugan", mun_code: "051612" },
  // { label: "Matango", mun_code: "051612" },
  // { label: "Napilihan", mun_code: "051612" },
  // { label: "Pinagtigasan", mun_code: "051612" },
  // { label: "Barangay I (Pob.)", mun_code: "051612" },
  // { label: "Barangay II (Pob.)", mun_code: "051612" },
  // { label: "Barangay III (Pob.)", mun_code: "051612" },
  // { label: "Sabang", mun_code: "051612" },
  // { label: "Santo Domingo", mun_code: "051612" },
  // { label: "Singi", mun_code: "051612" },
  // { label: "Sula", mun_code: "051612" },
  // { label: "Agdangan Pob. (San Cayetano)", mun_code: "051701" },
  // { label: "Antipolo", mun_code: "051701" },
  // { label: "Bagumbayan", mun_code: "051701" },
  // { label: "Caranday (La Purisima)", mun_code: "051701" },
  // { label: "Cristo Rey", mun_code: "051701" },
  // { label: "Del Pilar", mun_code: "051701" },
  // { label: "Del Rosario (Pob.)", mun_code: "051701" },
  // { label: "Iyagan", mun_code: "051701" },
  // { label: "La Medalla", mun_code: "051701" },
  // { label: "Lourdes", mun_code: "051701" },
  // { label: "Nababarera", mun_code: "051701" },
  // { label: "Sagrada", mun_code: "051701" },
  // { label: "Salvacion", mun_code: "051701" },
  // { label: "Buluang (San Antonio)", mun_code: "051701" },
  // { label: "San Francisco (Pob.)", mun_code: "051701" },
  // { label: "San Isidro", mun_code: "051701" },
  // { label: "San Jose (Pob.)", mun_code: "051701" },
  // { label: "San Juan", mun_code: "051701" },
  // { label: "San Nicolas (Pob.)", mun_code: "051701" },
  // { label: "San Rafael (Ikpan)", mun_code: "051701" },
  // { label: "Pugay (San Rafael)", mun_code: "051701" },
  // { label: "San Ramon (Pob.)", mun_code: "051701" },
  // { label: "San Roque (Pob.)", mun_code: "051701" },
  // { label: "San Vicente", mun_code: "051701" },
  // { label: "Santa Cruz (Pob.)", mun_code: "051701" },
  // { label: "Santa Eulalia", mun_code: "051701" },
  // { label: "Santa Isabel", mun_code: "051701" },
  // { label: "Santa Teresa (Viga)", mun_code: "051701" },
  // { label: "Santa Teresita", mun_code: "051701" },
  // { label: "Tapol", mun_code: "051701" },
  // { label: "Cabanbanan", mun_code: "051702" },
  // { label: "Cabungan", mun_code: "051702" },
  // { label: "Camangahan (Caorasan)", mun_code: "051702" },
  // { label: "Cayogcog", mun_code: "051702" },
  // { label: "Coguit", mun_code: "051702" },
  // { label: "Duran", mun_code: "051702" },
  // { label: "Laganac", mun_code: "051702" },
  // { label: "Luluasan", mun_code: "051702" },
  // { label: "Montenegro (Maguiron)", mun_code: "051702" },
  // { label: "Pararao", mun_code: "051702" },
  // { label: "Siramag (Pob.)", mun_code: "051702" },
  // { label: "Pulang Daga", mun_code: "051702" },
  // { label: "Sagrada Nacacale", mun_code: "051702" },
  // { label: "San Francisco", mun_code: "051702" },
  // { label: "Santiago Nacacale", mun_code: "051702" },
  // { label: "Tapayas", mun_code: "051702" },
  // { label: "Tomatarayo", mun_code: "051702" },
  // { label: "Agos", mun_code: "051703" },
  // { label: "Bacolod", mun_code: "051703" },
  // { label: "Buluang", mun_code: "051703" },
  // { label: "Caricot", mun_code: "051703" },
  // { label: "Cawacagan", mun_code: "051703" },
  // { label: "Cotmon", mun_code: "051703" },
  // { label: "Cristo Rey", mun_code: "051703" },
  // { label: "Del Rosario", mun_code: "051703" },
  // { label: "Divina Pastora (Pob.)", mun_code: "051703" },
  // { label: "Goyudan", mun_code: "051703" },
  // { label: "Lobong", mun_code: "051703" },
  // { label: "Lubigan", mun_code: "051703" },
  // { label: "Mainit", mun_code: "051703" },
  // { label: "Manga (Mangga)", mun_code: "051703" },
  // { label: "Masoli", mun_code: "051703" },
  // { label: "Neighborhood", mun_code: "051703" },
  // { label: "Pagatpatan", mun_code: "051703" },
  // { label: "Palo", mun_code: "051703" },
  // { label: "Payak", mun_code: "051703" },
  // { label: "Sagrada (Sagrada Familia)", mun_code: "051703" },
  // { label: "Salvacion", mun_code: "051703" },
  // { label: "San Isidro (Pob.)", mun_code: "051703" },
  // { label: "San Juan", mun_code: "051703" },
  // { label: "San Miguel", mun_code: "051703" },
  // { label: "San Rafael (Pob.)", mun_code: "051703" },
  // { label: "San Roque", mun_code: "051703" },
  // { label: "San Vicente", mun_code: "051703" },
  // { label: "Santa Cruz (Pob.)", mun_code: "051703" },
  // { label: "Santiago (Pob.)", mun_code: "051703" },
  // { label: "Sooc", mun_code: "051703" },
  // { label: "Tagpolo", mun_code: "051703" },
  // { label: "Tres Reyes (Pob.)", mun_code: "051703" },
  // { label: "San Antonio", mun_code: "051704" },
  // { label: "San Francisco", mun_code: "051704" },
  // { label: "San Isidro (Pob.)", mun_code: "051704" },
  // { label: "San Jose (Pob.)", mun_code: "051704" },
  // { label: "Pagao (San Juan)", mun_code: "051704" },
  // { label: "San Roque (Pob.)", mun_code: "051704" },
  // { label: "Siembre", mun_code: "051704" },
  // { label: "Santo Domingo", mun_code: "051704" },
  // { label: "Antipolo", mun_code: "051705" },
  // { label: "Burocbusoc", mun_code: "051705" },
  // { label: "Cabatuan", mun_code: "051705" },
  // { label: "Cagmaslog", mun_code: "051705" },
  // { label: "Amlongan (Del Rosario)", mun_code: "051705" },
  // { label: "De La Fe", mun_code: "051705" },
  // { label: "Divino Rostro", mun_code: "051705" },
  // { label: "Ipil (Fatima)", mun_code: "051705" },
  // { label: "Gabas", mun_code: "051705" },
  // { label: "Ibayugan", mun_code: "051705" },
  // { label: "Igbac", mun_code: "051705" },
  // { label: "Iraya (Del Rosario)", mun_code: "051705" },
  // { label: "Labawon (Santa Teresita)", mun_code: "051705" },
  // { label: "Delos Angeles (Los Angeles)", mun_code: "051705" },
  // { label: "Monte Calvario", mun_code: "051705" },
  // { label: "Namurabod", mun_code: "051705" },
  // { label: "Sagrada Familia", mun_code: "051705" },
  // { label: "Salvacion", mun_code: "051705" },
  // { label: "San Antonio", mun_code: "051705" },
  // { label: "San Buenaventura (Pob.)", mun_code: "051705" },
  // { label: "San Francisco (Parada)", mun_code: "051705" },
  // { label: "San Isidro", mun_code: "051705" },
  // { label: "San Jose Baybayon", mun_code: "051705" },
  // { label: "San Jose Salay", mun_code: "051705" },
  // { label: "Macaangay", mun_code: "051705" },
  // { label: "San Pascual (Pob.)", mun_code: "051705" },
  // { label: "San Pedro (Pob.)", mun_code: "051705" },
  // { label: "San Rafael", mun_code: "051705" },
  // { label: "San Ramon", mun_code: "051705" },
  // { label: "San Roque (Pob.)", mun_code: "051705" },
  // { label: "San Vicente", mun_code: "051705" },
  // { label: "Santa Clara (Pob.)", mun_code: "051705" },
  // { label: "Santa Cruz", mun_code: "051705" },
  // { label: "Santa Elena (Pob.)", mun_code: "051705" },
  // { label: "Santa Isabel", mun_code: "051705" },
  // { label: "Santa Justina", mun_code: "051705" },
  // { label: "Lourdes (Santa Lourdes)", mun_code: "051705" },
  // { label: "Tambo", mun_code: "051705" },
  // { label: "Bagoladio", mun_code: "051706" },
  // { label: "Bagumbayan", mun_code: "051706" },
  // { label: "Balaogan", mun_code: "051706" },
  // { label: "Caorasan", mun_code: "051706" },
  // { label: "Casugad", mun_code: "051706" },
  // { label: "Causip", mun_code: "051706" },
  // { label: "Fabrica", mun_code: "051706" },
  // { label: "Inoyonan", mun_code: "051706" },
  // { label: "Itangon", mun_code: "051706" },
  // { label: "Kinalabasahan", mun_code: "051706" },
  // { label: "La Purisima", mun_code: "051706" },
  // { label: "La Victoria", mun_code: "051706" },
  // { label: "Lanipga", mun_code: "051706" },
  // { label: "Lubgan", mun_code: "051706" },
  // { label: "Ombao Heights", mun_code: "051706" },
  // { label: "Ombao Polpog", mun_code: "051706" },
  // { label: "Palsong", mun_code: "051706" },
  // { label: "Panoypoyan", mun_code: "051706" },
  // { label: "Pawili", mun_code: "051706" },
  // { label: "Sagrada (Sagrada Familia)", mun_code: "051706" },
  // { label: "Salvacion (Pob.)", mun_code: "051706" },
  // { label: "San Agustin", mun_code: "051706" },
  // { label: "San Francisco", mun_code: "051706" },
  // { label: "San Isidro", mun_code: "051706" },
  // { label: "San Jose", mun_code: "051706" },
  // { label: "San Miguel", mun_code: "051706" },
  // { label: "San Ramon", mun_code: "051706" },
  // { label: "San Roque (Pob.)", mun_code: "051706" },
  // { label: "San Roque Heights", mun_code: "051706" },
  // { label: "Santa Elena", mun_code: "051706" },
  // { label: "Santo Domingo", mun_code: "051706" },
  // { label: "Taisan", mun_code: "051706" },
  // { label: "Barcelonita", mun_code: "051707" },
  // { label: "Biong", mun_code: "051707" },
  // { label: "Camagong", mun_code: "051707" },
  // { label: "Castillo", mun_code: "051707" },
  // { label: "New Poblacion", mun_code: "051707" },
  // { label: "Pandan", mun_code: "051707" },
  // { label: "San Pedro", mun_code: "051707" },
  // { label: "Santa Cruz", mun_code: "051707" },
  // { label: "Santa Lutgarda (Pob.)", mun_code: "051707" },
  // { label: "Balatasan", mun_code: "051708" },
  // { label: "Balombon", mun_code: "051708" },
  // { label: "Balongay", mun_code: "051708" },
  // { label: "Belen", mun_code: "051708" },
  // { label: "Bigaas", mun_code: "051708" },
  // { label: "Binanuaanan Grande", mun_code: "051708" },
  // { label: "Binaliw", mun_code: "051708" },
  // { label: "Bonot-Santa Rosa", mun_code: "051708" },
  // { label: "Burabod", mun_code: "051708" },
  // { label: "Cabanbanan", mun_code: "051708" },
  // { label: "Cagsao", mun_code: "051708" },
  // { label: "Camuning", mun_code: "051708" },
  // { label: "Comaguingking", mun_code: "051708" },
  // { label: "Del Carmen (Pob.)", mun_code: "051708" },
  // { label: "Dominorog", mun_code: "051708" },
  // { label: "Fabrica", mun_code: "051708" },
  // { label: "Harobay", mun_code: "051708" },
  // { label: "La Purisima", mun_code: "051708" },
  // { label: "Lugsad", mun_code: "051708" },
  // { label: "Manguiring", mun_code: "051708" },
  // { label: "Pagatpat (San Jose)", mun_code: "051708" },
  // { label: "Paolbo", mun_code: "051708" },
  // { label: "Pinada", mun_code: "051708" },
  // { label: "Punta Tarawal", mun_code: "051708" },
  // { label: "Quinale", mun_code: "051708" },
  // { label: "Sabang", mun_code: "051708" },
  // { label: "Salvacion-Baybay", mun_code: "051708" },
  // { label: "San Antonio Poblacion", mun_code: "051708" },
  // { label: "San Antonio (Quipayo)", mun_code: "051708" },
  // { label: "San Bernardino", mun_code: "051708" },
  // { label: "San Francisco (Pob.)", mun_code: "051708" },
  // { label: "San Isidro", mun_code: "051708" },
  // { label: "San Lucas", mun_code: "051708" },
  // { label: "San Miguel (Pob.)", mun_code: "051708" },
  // { label: "San Pablo (Pob.)", mun_code: "051708" },
  // { label: "San Roque", mun_code: "051708" },
  // { label: "San Vicente (Pob.)", mun_code: "051708" },
  // { label: "Santa Cruz Ratay", mun_code: "051708" },
  // { label: "Santa Cruz Poblacion", mun_code: "051708" },
  // { label: "Santa Isabel (Pob.)", mun_code: "051708" },
  // { label: "Santa Salud (Pob.)", mun_code: "051708" },
  // { label: "Santo Domingo", mun_code: "051708" },
  // { label: "Siba-o", mun_code: "051708" },
  // { label: "Sibobo", mun_code: "051708" },
  // { label: "Sogod", mun_code: "051708" },
  // { label: "Tomagodtod", mun_code: "051708" },
  // { label: "Dugcal", mun_code: "051709" },
  // { label: "Marupit", mun_code: "051709" },
  // { label: "San Francisco", mun_code: "051709" },
  // { label: "San Juan-San Ramon (Pob.)", mun_code: "051709" },
  // { label: "San Lucas (Pob.)", mun_code: "051709" },
  // { label: "San Marcos (Pob.)", mun_code: "051709" },
  // { label: "San Mateo (Pob.)", mun_code: "051709" },
  // { label: "San Jose-San Pablo (Pob.)", mun_code: "051709" },
  // { label: "San Roque", mun_code: "051709" },
  // { label: "Santo Domingo (Pob.)", mun_code: "051709" },
  // { label: "Santo Tomas (Pob.)", mun_code: "051709" },
  // { label: "Sua", mun_code: "051709" },
  // { label: "Tarosanan", mun_code: "051709" },
  // { label: "Baras (Pob.)", mun_code: "051710" },
  // { label: "Del Rosario", mun_code: "051710" },
  // { label: "Dinaga (Pob.)", mun_code: "051710" },
  // { label: "Fundado", mun_code: "051710" },
  // { label: "Haring", mun_code: "051710" },
  // { label: "Iquin", mun_code: "051710" },
  // { label: "Linaga", mun_code: "051710" },
  // { label: "Mangayawan", mun_code: "051710" },
  // { label: "Palo", mun_code: "051710" },
  // { label: "Pangpang (Pob.)", mun_code: "051710" },
  // { label: "Poro", mun_code: "051710" },
  // { label: "San Agustin", mun_code: "051710" },
  // { label: "San Francisco", mun_code: "051710" },
  // { label: "San Jose East", mun_code: "051710" },
  // { label: "San Jose West", mun_code: "051710" },
  // { label: "San Juan", mun_code: "051710" },
  // { label: "San Nicolas", mun_code: "051710" },
  // { label: "San Roque", mun_code: "051710" },
  // { label: "San Vicente", mun_code: "051710" },
  // { label: "Santa Cruz (Pob.)", mun_code: "051710" },
  // { label: "Santa Teresita", mun_code: "051710" },
  // { label: "Sua", mun_code: "051710" },
  // { label: "Talidtid", mun_code: "051710" },
  // { label: "Tibgao (Pob.)", mun_code: "051710" },
  // { label: "Agaas", mun_code: "051711" },
  // { label: "Antolon", mun_code: "051711" },
  // { label: "Bacgong", mun_code: "051711" },
  // { label: "Bahay", mun_code: "051711" },
  // { label: "Bikal", mun_code: "051711" },
  // { label: "Binanuahan (Pob.)", mun_code: "051711" },
  // { label: "Cabacongan", mun_code: "051711" },
  // { label: "Cadong", mun_code: "051711" },
  // { label: "Colongcogong", mun_code: "051711" },
  // { label: "Canatuan", mun_code: "051711" },
  // { label: "Caputatan", mun_code: "051711" },
  // { label: "Gogon", mun_code: "051711" },
  // { label: "Daraga", mun_code: "051711" },
  // { label: "Gata", mun_code: "051711" },
  // { label: "Gibgos", mun_code: "051711" },
  // { label: "Guijalo", mun_code: "051711" },
  // { label: "Hanopol", mun_code: "051711" },
  // { label: "Hanoy", mun_code: "051711" },
  // { label: "Haponan", mun_code: "051711" },
  // { label: "Ilawod", mun_code: "051711" },
  // { label: "Ili-Centro (Pob.)", mun_code: "051711" },
  // { label: "Lidong", mun_code: "051711" },
  // { label: "Lubas", mun_code: "051711" },
  // { label: "Malabog", mun_code: "051711" },
  // { label: "Maligaya", mun_code: "051711" },
  // { label: "Mampirao", mun_code: "051711" },
  // { label: "Mandiclum (Mandiclom)", mun_code: "051711" },
  // { label: "Maqueda", mun_code: "051711" },
  // { label: "Minalaba", mun_code: "051711" },
  // { label: "Oring", mun_code: "051711" },
  // { label: "Oroc-Osoc", mun_code: "051711" },
  // { label: "Pagolinan", mun_code: "051711" },
  // { label: "Pandanan", mun_code: "051711" },
  // { label: "Paniman", mun_code: "051711" },
  // { label: "Patag-Belen", mun_code: "051711" },
  // { label: "Pili-Centro", mun_code: "051711" },
  // { label: "Pili-Tabiguian", mun_code: "051711" },
  // { label: "Poloan", mun_code: "051711" },
  // { label: "Salvacion", mun_code: "051711" },
  // { label: "San Roque", mun_code: "051711" },
  // { label: "San Vicente", mun_code: "051711" },
  // { label: "Santa Cruz", mun_code: "051711" },
  // { label: "Solnopan", mun_code: "051711" },
  // { label: "Tabgon", mun_code: "051711" },
  // { label: "Tabiguian", mun_code: "051711" },
  // { label: "Tabog", mun_code: "051711" },
  // { label: "Tawog (Pob.)", mun_code: "051711" },
  // { label: "Toboan", mun_code: "051711" },
  // { label: "Terogo", mun_code: "051711" },
  // { label: "Bagong Silang", mun_code: "051712" },
  // { label: "Bucal", mun_code: "051712" },
  // { label: "Cabasag", mun_code: "051712" },
  // { label: "Comadaycaday", mun_code: "051712" },
  // { label: "Domagondong", mun_code: "051712" },
  // { label: "Kinalangan", mun_code: "051712" },
  // { label: "Comadogcadog", mun_code: "051712" },
  // { label: "Mabini", mun_code: "051712" },
  // { label: "Magais I", mun_code: "051712" },
  // { label: "Magais II", mun_code: "051712" },
  // { label: "Mansalaya", mun_code: "051712" },
  // { label: "Nagkalit", mun_code: "051712" },
  // { label: "Palaspas", mun_code: "051712" },
  // { label: "Pamplona", mun_code: "051712" },
  // { label: "Pasay", mun_code: "051712" },
  // { label: "Pinagdapian", mun_code: "051712" },
  // { label: "Pinugusan", mun_code: "051712" },
  // { label: "Zone I Fatima (Pob.)", mun_code: "051712" },
  // { label: "Zone II San Antonio (Pob.)", mun_code: "051712" },
  // { label: "Poblacion Zone III", mun_code: "051712" },
  // { label: "Sabang", mun_code: "051712" },
  // { label: "Salvacion", mun_code: "051712" },
  // { label: "San Juan", mun_code: "051712" },
  // { label: "San Pablo", mun_code: "051712" },
  // { label: "Santa Rita I", mun_code: "051712" },
  // { label: "Santa Rita II", mun_code: "051712" },
  // { label: "Sinagawsawan", mun_code: "051712" },
  // { label: "Sinuknipan I", mun_code: "051712" },
  // { label: "Sinuknipan II", mun_code: "051712" },
  // { label: "Sugsugin", mun_code: "051712" },
  // { label: "Tabion", mun_code: "051712" },
  // { label: "Tomagoktok", mun_code: "051712" },
  // { label: "Cagbunga", mun_code: "051713" },
  // { label: "Dahilig", mun_code: "051713" },
  // { label: "Loob", mun_code: "051713" },
  // { label: "Malbong", mun_code: "051713" },
  // { label: "Namuat", mun_code: "051713" },
  // { label: "Sampaloc", mun_code: "051713" },
  // { label: "District I (Pob.)", mun_code: "051713" },
  // { label: "District II (Pob.)", mun_code: "051713" },
  // { label: "Ason (Anson)", mun_code: "051714" },
  // { label: "Bahi", mun_code: "051714" },
  // { label: "Binagasbasan", mun_code: "051714" },
  // { label: "Burabod", mun_code: "051714" },
  // { label: "Cagamutan", mun_code: "051714" },
  // { label: "Cagnipa", mun_code: "051714" },
  // { label: "Canlong", mun_code: "051714" },
  // { label: "Dangla", mun_code: "051714" },
  // { label: "Del Pilar", mun_code: "051714" },
  // { label: "Denrica", mun_code: "051714" },
  // { label: "Harrison", mun_code: "051714" },
  // { label: "Mansangat", mun_code: "051714" },
  // { label: "Pambuhan", mun_code: "051714" },
  // { label: "Barangay I (Pob.)", mun_code: "051714" },
  // { label: "Barangay II (Pob.)", mun_code: "051714" },
  // { label: "Barangay III (Pob.)", mun_code: "051714" },
  // { label: "Barangay IV (Pob.)", mun_code: "051714" },
  // { label: "Sagrada", mun_code: "051714" },
  // { label: "Salvacion", mun_code: "051714" },
  // { label: "San Vicente", mun_code: "051714" },
  // { label: "Sumaoy", mun_code: "051714" },
  // { label: "Tamiawon", mun_code: "051714" },
  // { label: "Toytoy", mun_code: "051714" },
  // { label: "Abucayan", mun_code: "051715" },
  // { label: "Bagumbayan Grande (Pob.)", mun_code: "051715" },
  // { label: "Balaynan", mun_code: "051715" },
  // { label: "Belen (Pob.)", mun_code: "051715" },
  // { label: "Buyo", mun_code: "051715" },
  // { label: "Cagaycay", mun_code: "051715" },
  // { label: "Catagbacan", mun_code: "051715" },
  // { label: "Digdigon", mun_code: "051715" },
  // { label: "Gimaga", mun_code: "051715" },
  // { label: "Halawig-Gogon", mun_code: "051715" },
  // { label: "Hiwacloy", mun_code: "051715" },
  // { label: "La Purisima (Pob.)", mun_code: "051715" },
  // { label: "Lamon", mun_code: "051715" },
  // { label: "Matacla", mun_code: "051715" },
  // { label: "Maymatan", mun_code: "051715" },
  // { label: "Maysalay", mun_code: "051715" },
  // { label: "Napawon (Napunuon)", mun_code: "051715" },
  // { label: "Panday (Pob.)", mun_code: "051715" },
  // { label: "Payatan", mun_code: "051715" },
  // { label: "Pinaglabanan", mun_code: "051715" },
  // { label: "Salog", mun_code: "051715" },
  // { label: "San Benito (Pob.)", mun_code: "051715" },
  // { label: "San Isidro West", mun_code: "051715" },
  // { label: "San Isidro (Pob.)", mun_code: "051715" },
  // { label: "San Jose (Pob.)", mun_code: "051715" },
  // { label: "San Juan Bautista (Pob.)", mun_code: "051715" },
  // { label: "San Juan Evangelista (Pob.)", mun_code: "051715" },
  // { label: "San Pedro (Aroro)", mun_code: "051715" },
  // { label: "Scout Fuentebella", mun_code: "051715" },
  // { label: "Tabgon", mun_code: "051715" },
  // { label: "Tagongtong", mun_code: "051715" },
  // { label: "Tamban (Mabini)", mun_code: "051715" },
  // { label: "Taytay (Halgon East)", mun_code: "051715" },
  // { label: "Antipolo", mun_code: "051716" },
  // { label: "Cristo Rey", mun_code: "051716" },
  // { label: "Del Rosario (Banao)", mun_code: "051716" },
  // { label: "Francia", mun_code: "051716" },
  // { label: "La Anunciacion", mun_code: "051716" },
  // { label: "La Medalla", mun_code: "051716" },
  // { label: "La Purisima", mun_code: "051716" },
  // { label: "La Trinidad", mun_code: "051716" },
  // { label: "Perpetual Help", mun_code: "051716" },
  // { label: "Sagrada", mun_code: "051716" },
  // { label: "Salvacion", mun_code: "051716" },
  // { label: "San Agustin", mun_code: "051716" },
  // { label: "San Andres", mun_code: "051716" },
  // { label: "San Antonio", mun_code: "051716" },
  // { label: "San Francisco (Pob.)", mun_code: "051716" },
  // { label: "San Isidro", mun_code: "051716" },
  // { label: "San Jose", mun_code: "051716" },
  // { label: "San Juan", mun_code: "051716" },
  // { label: "San Miguel", mun_code: "051716" },
  // { label: "San Nicolas", mun_code: "051716" },
  // { label: "San Pedro", mun_code: "051716" },
  // { label: "San Rafael", mun_code: "051716" },
  // { label: "San Ramon", mun_code: "051716" },
  // { label: "San Roque (Pob.)", mun_code: "051716" },
  // { label: "San Vicente Norte", mun_code: "051716" },
  // { label: "San Vicente Sur", mun_code: "051716" },
  // { label: "Santa Cruz Norte", mun_code: "051716" },
  // { label: "Santa Cruz Sur", mun_code: "051716" },
  // { label: "Santa Elena", mun_code: "051716" },
  // { label: "Santa Isabel", mun_code: "051716" },
  // { label: "Santa Maria", mun_code: "051716" },
  // { label: "Santa Teresita", mun_code: "051716" },
  // { label: "Santiago", mun_code: "051716" },
  // { label: "Santo Domingo", mun_code: "051716" },
  // { label: "Agosais", mun_code: "051717" },
  // { label: "Agpo-Camagong-Tabog", mun_code: "051717" },
  // { label: "Amoguis", mun_code: "051717" },
  // { label: "Bocogan", mun_code: "051717" },
  // { label: "Balaton", mun_code: "051717" },
  // { label: "Binanuahan", mun_code: "051717" },
  // { label: "Burabod", mun_code: "051717" },
  // { label: "Cabotonan", mun_code: "051717" },
  // { label: "Dahat", mun_code: "051717" },
  // { label: "Del Carmen", mun_code: "051717" },
  // { label: "Ginorangan (Genorangan)", mun_code: "051717" },
  // { label: "Gimagtocon", mun_code: "051717" },
  // { label: "Gubat", mun_code: "051717" },
  // { label: "Guibahoy", mun_code: "051717" },
  // { label: "Himanag", mun_code: "051717" },
  // { label: "Kinahologan", mun_code: "051717" },
  // { label: "Loho", mun_code: "051717" },
  // { label: "Manamoc", mun_code: "051717" },
  // { label: "Mangogon", mun_code: "051717" },
  // { label: "Mapid", mun_code: "051717" },
  // { label: "Olas", mun_code: "051717" },
  // { label: "Omalo", mun_code: "051717" },
  // { label: "Panagan", mun_code: "051717" },
  // { label: "Panicuan", mun_code: "051717" },
  // { label: "Pinamihagan", mun_code: "051717" },
  // { label: "San Francisco (Pob.)", mun_code: "051717" },
  // { label: "San Isidro", mun_code: "051717" },
  // { label: "San Isidro Sur (Pob.)", mun_code: "051717" },
  // { label: "San Isidro Norte (Pob.)", mun_code: "051717" },
  // { label: "San Rafael", mun_code: "051717" },
  // { label: "San Ramon", mun_code: "051717" },
  // { label: "San Roque", mun_code: "051717" },
  // { label: "San Sebastian", mun_code: "051717" },
  // { label: "San Vicente (Pob.)", mun_code: "051717" },
  // { label: "Santa Cruz (Pob.)", mun_code: "051717" },
  // { label: "Santa Maria (Pob.)", mun_code: "051717" },
  // { label: "Saripongpong (Pob.)", mun_code: "051717" },
  // { label: "Sipaco", mun_code: "051717" },
  // { label: "Aslong", mun_code: "051718" },
  // { label: "Awayan", mun_code: "051718" },
  // { label: "Bagacay", mun_code: "051718" },
  // { label: "Bagadion", mun_code: "051718" },
  // { label: "Bagamelon", mun_code: "051718" },
  // { label: "Bagumbayan", mun_code: "051718" },
  // { label: "Bahao", mun_code: "051718" },
  // { label: "Bahay", mun_code: "051718" },
  // { label: "Beguito Nuevo", mun_code: "051718" },
  // { label: "Beguito Viejo", mun_code: "051718" },
  // { label: "Begajo Norte", mun_code: "051718" },
  // { label: "Begajo Sur", mun_code: "051718" },
  // { label: "Bikal", mun_code: "051718" },
  // { label: "Busak", mun_code: "051718" },
  // { label: "Caima", mun_code: "051718" },
  // { label: "Calabnigan", mun_code: "051718" },
  // { label: "Camambugan", mun_code: "051718" },
  // { label: "Cambalidio", mun_code: "051718" },
  // { label: "Candami", mun_code: "051718" },
  // { label: "Candato", mun_code: "051718" },
  // { label: "Cawayan", mun_code: "051718" },
  // { label: "Concepcion", mun_code: "051718" },
  // { label: "Cuyapi", mun_code: "051718" },
  // { label: "Danawan", mun_code: "051718" },
  // { label: "Duang Niog", mun_code: "051718" },
  // { label: "Handong", mun_code: "051718" },
  // { label: "Ibid", mun_code: "051718" },
  // { label: "Inalahan", mun_code: "051718" },
  // { label: "Labao", mun_code: "051718" },
  // { label: "Libod I", mun_code: "051718" },
  // { label: "Libod II", mun_code: "051718" },
  // { label: "Loba-loba", mun_code: "051718" },
  // { label: "Mabini", mun_code: "051718" },
  // { label: "Malansad Nuevo", mun_code: "051718" },
  // { label: "Malansad Viejo", mun_code: "051718" },
  // { label: "Malbogon", mun_code: "051718" },
  // { label: "Malinao", mun_code: "051718" },
  // { label: "Mambalite", mun_code: "051718" },
  // { label: "Mambayawas", mun_code: "051718" },
  // { label: "Mambulo Nuevo", mun_code: "051718" },
  // { label: "Mambulo Viejo", mun_code: "051718" },
  // { label: "Mancawayan", mun_code: "051718" },
  // { label: "Mandacanan", mun_code: "051718" },
  // { label: "Mantalisay", mun_code: "051718" },
  // { label: "Padlos", mun_code: "051718" },
  // { label: "Pag-Oring Nuevo", mun_code: "051718" },
  // { label: "Pag-Oring Viejo", mun_code: "051718" },
  // { label: "Palangon", mun_code: "051718" },
  // { label: "Palong", mun_code: "051718" },
  // { label: "Patag", mun_code: "051718" },
  // { label: "Planza", mun_code: "051718" },
  // { label: "Poblacion", mun_code: "051718" },
  // { label: "Potot", mun_code: "051718" },
  // { label: "Puro-Batia", mun_code: "051718" },
  // { label: "Rongos", mun_code: "051718" },
  // { label: "Salvacion", mun_code: "051718" },
  // { label: "San Isidro", mun_code: "051718" },
  // { label: "San Juan", mun_code: "051718" },
  // { label: "San Pablo", mun_code: "051718" },
  // { label: "San Vicente", mun_code: "051718" },
  // { label: "Sibujo", mun_code: "051718" },
  // { label: "Sigamot", mun_code: "051718" },
  // { label: "Station-Church Site", mun_code: "051718" },
  // { label: "Taban-Fundado", mun_code: "051718" },
  // { label: "Tampuhan", mun_code: "051718" },
  // { label: "Tanag", mun_code: "051718" },
  // { label: "Tarum", mun_code: "051718" },
  // { label: "Tinalmud Nuevo", mun_code: "051718" },
  // { label: "Tinalmud Viejo", mun_code: "051718" },
  // { label: "Tinangkihan", mun_code: "051718" },
  // { label: "Udoc", mun_code: "051718" },
  // { label: "Umalo", mun_code: "051718" },
  // { label: "Uson", mun_code: "051718" },
  // { label: "Villasocorro", mun_code: "051718" },
  // { label: "Villadima (Santa Cruz)", mun_code: "051718" },
  // { label: "Alleomar", mun_code: "051719" },
  // { label: "Bagangan Sr.", mun_code: "051719" },
  // { label: "Bagong Sikat", mun_code: "051719" },
  // { label: "Bel-Cruz", mun_code: "051719" },
  // { label: "Bangon", mun_code: "051719" },
  // { label: "Barrera Jr.", mun_code: "051719" },
  // { label: "Barrera Sr.", mun_code: "051719" },
  // { label: "Belwang", mun_code: "051719" },
  // { label: "Buenasuerte", mun_code: "051719" },
  // { label: "Bulawan Jr.", mun_code: "051719" },
  // { label: "Bulawan Sr.", mun_code: "051719" },
  // { label: "Cabutagan", mun_code: "051719" },
  // { label: "Kaibigan", mun_code: "051719" },
  // { label: "Casay", mun_code: "051719" },
  // { label: "Colacling (Del Rosario)", mun_code: "051719" },
  // { label: "Cristo Rey", mun_code: "051719" },
  // { label: "Del Carmen", mun_code: "051719" },
  // { label: "Haguimit", mun_code: "051719" },
  // { label: "Haluban (Pigbasagan)", mun_code: "051719" },
  // { label: "La Purisima", mun_code: "051719" },
  // { label: "Lourdes", mun_code: "051719" },
  // { label: "Mangcawayan", mun_code: "051719" },
  // { label: "Napolidan", mun_code: "051719" },
  // { label: "Poblacion", mun_code: "051719" },
  // { label: "Polantuna", mun_code: "051719" },
  // { label: "Sagrada", mun_code: "051719" },
  // { label: "Salvacion", mun_code: "051719" },
  // { label: "San Isidro", mun_code: "051719" },
  // { label: "San Jose", mun_code: "051719" },
  // { label: "San Pedro", mun_code: "051719" },
  // { label: "San Rafael Norte", mun_code: "051719" },
  // { label: "San Rafael Sur", mun_code: "051719" },
  // { label: "San Ramon", mun_code: "051719" },
  // { label: "San Vicente", mun_code: "051719" },
  // { label: "Sooc", mun_code: "051719" },
  // { label: "Tanawan", mun_code: "051719" },
  // { label: "Tible", mun_code: "051719" },
  // { label: "Tapi (Lupi Nuevo)", mun_code: "051719" },
  // { label: "Barobaybay", mun_code: "051720" },
  // { label: "Bell (Pob.)", mun_code: "051720" },
  // { label: "Carangcang", mun_code: "051720" },
  // { label: "Carigsa", mun_code: "051720" },
  // { label: "Casuray", mun_code: "051720" },
  // { label: "Monserrat (Pob.)", mun_code: "051720" },
  // { label: "Ponong", mun_code: "051720" },
  // { label: "San Francisco (Pob.)", mun_code: "051720" },
  // { label: "San Isidro (Pob.)", mun_code: "051720" },
  // { label: "San Juan (Pob.)", mun_code: "051720" },
  // { label: "San Miguel", mun_code: "051720" },
  // { label: "San Pantaleon (Pob.)", mun_code: "051720" },
  // { label: "Santa Lucia (Pob.)", mun_code: "051720" },
  // { label: "Santa Rosa", mun_code: "051720" },
  // { label: "Santo Tomas (Pob.)", mun_code: "051720" },
  // { label: "Alimbuyog", mun_code: "051721" },
  // { label: "Amparado (Pob.)", mun_code: "051721" },
  // { label: "Balagbag", mun_code: "051721" },
  // { label: "Borongborongan", mun_code: "051721" },
  // { label: "Cabugao", mun_code: "051721" },
  // { label: "Capucnasan", mun_code: "051721" },
  // { label: "Dalipay", mun_code: "051721" },
  // { label: "Del Rosario (Pob.)", mun_code: "051721" },
  // { label: "Flordeliz", mun_code: "051721" },
  // { label: "Lipot", mun_code: "051721" },
  // { label: "Mayaopayawan", mun_code: "051721" },
  // { label: "Maycatmon", mun_code: "051721" },
  // { label: "Maydaso", mun_code: "051721" },
  // { label: "San Antonio", mun_code: "051721" },
  // { label: "San Jose (Pob.)", mun_code: "051721" },
  // { label: "San Miguel (Pob.)", mun_code: "051721" },
  // { label: "San Roque (Pob.)", mun_code: "051721" },
  // { label: "San Vicente (Pob.)", mun_code: "051721" },
  // { label: "Santo Domingo (Pob.)", mun_code: "051721" },
  // { label: "Tarusanan", mun_code: "051721" },
  // { label: "Antipolo", mun_code: "051722" },
  // { label: "Bagolatao", mun_code: "051722" },
  // { label: "Bagongbong", mun_code: "051722" },
  // { label: "Baliuag Nuevo", mun_code: "051722" },
  // { label: "Baliuag Viejo", mun_code: "051722" },
  // { label: "Catanusan", mun_code: "051722" },
  // { label: "Del Carmen-Del Rosario (Pob.)", mun_code: "051722" },
  // { label: "Del Socorro", mun_code: "051722" },
  // { label: "Hamoraon", mun_code: "051722" },
  // { label: "Hobo", mun_code: "051722" },
  // { label: "Irayang Solong", mun_code: "051722" },
  // { label: "Magadap", mun_code: "051722" },
  // { label: "Malitbog", mun_code: "051722" },
  // { label: "Manapao", mun_code: "051722" },
  // { label: "Mataoroc", mun_code: "051722" },
  // { label: "Sagrada (Sagrada Familia)", mun_code: "051722" },
  // { label: "Salingogon", mun_code: "051722" },
  // { label: "San Antonio", mun_code: "051722" },
  // { label: "San Felipe-Santiago (Pob.)", mun_code: "051722" },
  // { label: "San Francisco (Pob.)", mun_code: "051722" },
  // { label: "San Jose", mun_code: "051722" },
  // { label: "San Juan-San Lorenzo (Pob.)", mun_code: "051722" },
  // { label: "Taban", mun_code: "051722" },
  // { label: "Tariric", mun_code: "051722" },
  // { label: "Timbang", mun_code: "051722" },
  // { label: "Angustia (Angustia Inapatan)", mun_code: "051723" },
  // { label: "Antipolo Old", mun_code: "051723" },
  // { label: "Antipolo Young", mun_code: "051723" },
  // { label: "Aro-aldao", mun_code: "051723" },
  // { label: "Bustrac", mun_code: "051723" },
  // { label: "Inapatan (Del Rosario Inapatan)", mun_code: "051723" },
  // { label: "Dolorosa (Dolorosa Inapatan)", mun_code: "051723" },
  // { label: "Duran (Jesus Duran)", mun_code: "051723" },
  // { label: "La Purisima (La Purisima Agupit)", mun_code: "051723" },
  // { label: "Lourdes Old", mun_code: "051723" },
  // { label: "Lourdes Young", mun_code: "051723" },
  // { label: "La Opinion", mun_code: "051723" },
  // { label: "Paloyon Proper (Sagrada Paloyon)", mun_code: "051723" },
  // { label: "Salvacion Que Gatos", mun_code: "051723" },
  // { label: "San Antonio (Pob.)", mun_code: "051723" },
  // { label: "San Antonio Ogbon", mun_code: "051723" },
  // { label: "San Esteban (Pob.)", mun_code: "051723" },
  // { label: "San Francisco (Pob.)", mun_code: "051723" },
  // { label: "San Isidro (Pob.)", mun_code: "051723" },
  // { label: "San Isidro Inapatan", mun_code: "051723" },
  // { label: "Malawag (San Jose Malawag)", mun_code: "051723" },
  // { label: "San Jose (San Jose Pangaraon)", mun_code: "051723" },
  // { label: "San Juan (Pob.)", mun_code: "051723" },
  // { label: "San Luis (Pob.)", mun_code: "051723" },
  // { label: "San Miguel (Pob.)", mun_code: "051723" },
  // { label: "San Nicolas (Pob.)", mun_code: "051723" },
  // { label: "San Roque (Pob.)", mun_code: "051723" },
  // { label: "San Roque Madawon", mun_code: "051723" },
  // { label: "San Roque Sagumay", mun_code: "051723" },
  // { label: "San Vicente Gorong-Gorong", mun_code: "051723" },
  // { label: "San Vicente Ogbon", mun_code: "051723" },
  // { label: "Santa Barbara (Maliban)", mun_code: "051723" },
  // { label: "Santa Cruz", mun_code: "051723" },
  // { label: "Santa Elena Baras", mun_code: "051723" },
  // { label: "Santa Lucia Baras", mun_code: "051723" },
  // { label: "Santiago Old", mun_code: "051723" },
  // { label: "Santiago Young", mun_code: "051723" },
  // { label: "Santo Domingo", mun_code: "051723" },
  // { label: "Tandaay", mun_code: "051723" },
  // { label: "Topas Proper", mun_code: "051723" },
  // { label: "Topas Sogod", mun_code: "051723" },
  // { label: "Paloyon Oriental", mun_code: "051723" },
  // { label: "Abella", mun_code: "051724" },
  // { label: "Bagumbayan Norte", mun_code: "051724" },
  // { label: "Bagumbayan Sur", mun_code: "051724" },
  // { label: "Balatas", mun_code: "051724" },
  // { label: "Calauag", mun_code: "051724" },
  // { label: "Cararayan", mun_code: "051724" },
  // { label: "Carolina", mun_code: "051724" },
  // { label: "Concepcion Grande", mun_code: "051724" },
  // { label: "Dayangdang", mun_code: "051724" },
  // { label: "Del Rosario", mun_code: "051724" },
  // { label: "Dinaga", mun_code: "051724" },
  // { label: "Igualdad Interior", mun_code: "051724" },
  // { label: "Lerma", mun_code: "051724" },
  // { label: "Liboton", mun_code: "051724" },
  // { label: "Mabolo", mun_code: "051724" },
  // { label: "Pacol", mun_code: "051724" },
  // { label: "Panicuason", mun_code: "051724" },
  // { label: "Sabang", mun_code: "051724" },
  // { label: "San Felipe", mun_code: "051724" },
  // { label: "San Francisco (Pob.)", mun_code: "051724" },
  // { label: "San Isidro", mun_code: "051724" },
  // { label: "Santa Cruz", mun_code: "051724" },
  // { label: "Tabuco", mun_code: "051724" },
  // { label: "Tinago", mun_code: "051724" },
  // { label: "Triangulo", mun_code: "051724" },
  // { label: "Ayugan", mun_code: "051725" },
  // { label: "Cabariwan", mun_code: "051725" },
  // { label: "Cagmanaba", mun_code: "051725" },
  // { label: "Del Rosario", mun_code: "051725" },
  // { label: "Gatbo", mun_code: "051725" },
  // { label: "Guinaban", mun_code: "051725" },
  // { label: "Hanawan", mun_code: "051725" },
  // { label: "Hibago", mun_code: "051725" },
  // { label: "La Purisima Nuevo", mun_code: "051725" },
  // { label: "May-Ogob", mun_code: "051725" },
  // { label: "New Moriones", mun_code: "051725" },
  // { label: "Old Moriones", mun_code: "051725" },
  // { label: "Pinit", mun_code: "051725" },
  // { label: "Poblacion Central", mun_code: "051725" },
  // { label: "Poblacion East", mun_code: "051725" },
  // { label: "Poblacion West", mun_code: "051725" },
  // { label: "Salvacion", mun_code: "051725" },
  // { label: "San Antonio", mun_code: "051725" },
  // { label: "San Francisco", mun_code: "051725" },
  // { label: "San Jose Oras", mun_code: "051725" },
  // { label: "San Roque Commonal", mun_code: "051725" },
  // { label: "San Vicente", mun_code: "051725" },
  // { label: "Santa Cruz", mun_code: "051725" },
  // { label: "Villaflorida", mun_code: "051725" },
  // { label: "Batang", mun_code: "051726" },
  // { label: "Burabod", mun_code: "051726" },
  // { label: "Cagbibi", mun_code: "051726" },
  // { label: "Cagbunga", mun_code: "051726" },
  // { label: "Calawat", mun_code: "051726" },
  // { label: "Del Rosario", mun_code: "051726" },
  // { label: "Patong", mun_code: "051726" },
  // { label: "Poblacion", mun_code: "051726" },
  // { label: "Salvacion", mun_code: "051726" },
  // { label: "San Gabriel", mun_code: "051726" },
  // { label: "San Isidro", mun_code: "051726" },
  // { label: "San Rafael", mun_code: "051726" },
  // { label: "San Ramon", mun_code: "051726" },
  // { label: "San Vicente", mun_code: "051726" },
  // { label: "Veneracion (Taguilid)", mun_code: "051726" },
  // { label: "Tambo", mun_code: "051726" },
  // { label: "Tampadong", mun_code: "051726" },
  // { label: "Antipolo", mun_code: "051727" },
  // { label: "Bagong Silang", mun_code: "051727" },
  // { label: "Bahay", mun_code: "051727" },
  // { label: "Balogo", mun_code: "051727" },
  // { label: "Caranan", mun_code: "051727" },
  // { label: "Cuco", mun_code: "051727" },
  // { label: "Dalupaon", mun_code: "051727" },
  // { label: "Macad (Hebrio Lourdes)", mun_code: "051727" },
  // { label: "Hubo", mun_code: "051727" },
  // { label: "Itulan", mun_code: "051727" },
  // { label: "Odicon", mun_code: "051727" },
  // { label: "Quitang", mun_code: "051727" },
  // { label: "Salvacion", mun_code: "051727" },
  // { label: "San Antonio", mun_code: "051727" },
  // { label: "San Cirilo (Pob.)", mun_code: "051727" },
  // { label: "Santa Rosa Del Norte (Pob.)", mun_code: "051727" },
  // { label: "Santa Rosa Del Sur (Pob.)", mun_code: "051727" },
  // { label: "Tilnac", mun_code: "051727" },
  // { label: "Tinalmud", mun_code: "051727" },
  // { label: "Anayan", mun_code: "051728" },
  // { label: "Bagong Sirang", mun_code: "051728" },
  // { label: "Binanwaanan", mun_code: "051728" },
  // { label: "Binobong", mun_code: "051728" },
  // { label: "Cadlan", mun_code: "051728" },
  // { label: "Caroyroyan", mun_code: "051728" },
  // { label: "Curry", mun_code: "051728" },
  // { label: "Del Rosario", mun_code: "051728" },
  // { label: "Himaao", mun_code: "051728" },
  // { label: "La Purisima", mun_code: "051728" },
  // { label: "New San Roque", mun_code: "051728" },
  // { label: "Old San Roque (Pob.)", mun_code: "051728" },
  // { label: "Palestina", mun_code: "051728" },
  // { label: "Pawili", mun_code: "051728" },
  // { label: "Sagurong", mun_code: "051728" },
  // { label: "Sagrada", mun_code: "051728" },
  // { label: "San Agustin", mun_code: "051728" },
  // { label: "San Antonio (Pob.)", mun_code: "051728" },
  // { label: "San Isidro (Pob.)", mun_code: "051728" },
  // { label: "San Jose", mun_code: "051728" },
  // { label: "San Juan (Pob.)", mun_code: "051728" },
  // { label: "San Vicente (Pob.)", mun_code: "051728" },
  // { label: "Santiago (Pob.)", mun_code: "051728" },
  // { label: "Tagbong", mun_code: "051728" },
  // { label: "Tinangis", mun_code: "051728" },
  // { label: "Ayugao", mun_code: "051729" },
  // { label: "Bagong Sirang", mun_code: "051729" },
  // { label: "Baliguian", mun_code: "051729" },
  // { label: "Bantugan", mun_code: "051729" },
  // { label: "Bicalen", mun_code: "051729" },
  // { label: "Bitaogan", mun_code: "051729" },
  // { label: "Buenavista", mun_code: "051729" },
  // { label: "Bulalacao", mun_code: "051729" },
  // { label: "Cagnipa", mun_code: "051729" },
  // { label: "Lagha", mun_code: "051729" },
  // { label: "Lidong", mun_code: "051729" },
  // { label: "Liwacsa", mun_code: "051729" },
  // { label: "Maangas", mun_code: "051729" },
  // { label: "Pagsangahan", mun_code: "051729" },
  // { label: "Patrocinio", mun_code: "051729" },
  // { label: "Pili", mun_code: "051729" },
  // { label: "Santa Maria (Pob.)", mun_code: "051729" },
  // { label: "Tanawan", mun_code: "051729" },
  // { label: "Agao-ao", mun_code: "051730" },
  // { label: "Agrupacion", mun_code: "051730" },
  // { label: "Amomokpok", mun_code: "051730" },
  // { label: "Apad", mun_code: "051730" },
  // { label: "Apale", mun_code: "051730" },
  // { label: "Banga Caves", mun_code: "051730" },
  // { label: "Baya", mun_code: "051730" },
  // { label: "Binahan Proper", mun_code: "051730" },
  // { label: "Binahan Upper", mun_code: "051730" },
  // { label: "Buenasuerte", mun_code: "051730" },
  // { label: "Cabadisan", mun_code: "051730" },
  // { label: "Cabinitan", mun_code: "051730" },
  // { label: "Cabugao", mun_code: "051730" },
  // { label: "Caditaan", mun_code: "051730" },
  // { label: "Cale", mun_code: "051730" },
  // { label: "Godofredo Reyes Sr. (Catabangan Crossing)", mun_code: "051730" },
  // { label: "Catabangan Proper", mun_code: "051730" },
  // { label: "Inandawa", mun_code: "051730" },
  // { label: "Laguio", mun_code: "051730" },
  // { label: "Lanipga-Cawayan", mun_code: "051730" },
  // { label: "Liboro", mun_code: "051730" },
  // { label: "Lohong", mun_code: "051730" },
  // { label: "Lower Omon", mun_code: "051730" },
  // { label: "Lower Santa Cruz", mun_code: "051730" },
  // { label: "Panaytayan", mun_code: "051730" },
  // { label: "Panaytayan Nuevo", mun_code: "051730" },
  // { label: "Patalunan", mun_code: "051730" },
  // { label: "Poblacion Ilaod", mun_code: "051730" },
  // { label: "Poblacion Iraya", mun_code: "051730" },
  // { label: "Port Junction Norte", mun_code: "051730" },
  // { label: "Port Junction Sur", mun_code: "051730" },
  // { label: "Salvacion", mun_code: "051730" },
  // { label: "Samay", mun_code: "051730" },
  // { label: "San Rafael", mun_code: "051730" },
  // { label: "F. Simeon (Pugod)", mun_code: "051730" },
  // { label: "Tagbac", mun_code: "051730" },
  // { label: "Upper Omon", mun_code: "051730" },
  // { label: "Upper Santa Cruz", mun_code: "051730" },
  // { label: "Aniog", mun_code: "051731" },
  // { label: "Atulayan", mun_code: "051731" },
  // { label: "Bongalon", mun_code: "051731" },
  // { label: "Buracan", mun_code: "051731" },
  // { label: "Catalotoan", mun_code: "051731" },
  // { label: "Del Carmen (Pob.)", mun_code: "051731" },
  // { label: "Kilantaao", mun_code: "051731" },
  // { label: "Kilomaon", mun_code: "051731" },
  // { label: "Mabca", mun_code: "051731" },
  // { label: "Minadongjol", mun_code: "051731" },
  // { label: "Nato", mun_code: "051731" },
  // { label: "Patitinan", mun_code: "051731" },
  // { label: "San Antonio (Pob.)", mun_code: "051731" },
  // { label: "San Isidro (Pob.)", mun_code: "051731" },
  // { label: "San Roque (Pob.)", mun_code: "051731" },
  // { label: "Sibaguan", mun_code: "051731" },
  // { label: "Tinorongan", mun_code: "051731" },
  // { label: "Turague", mun_code: "051731" },
  // { label: "Alianza", mun_code: "051732" },
  // { label: "Beberon", mun_code: "051732" },
  // { label: "Bical", mun_code: "051732" },
  // { label: "Bocal", mun_code: "051732" },
  // { label: "Bonifacio (Pob.)", mun_code: "051732" },
  // { label: "Buenavista (Pob.)", mun_code: "051732" },
  // { label: "Calascagas", mun_code: "051732" },
  // { label: "Cotmo", mun_code: "051732" },
  // { label: "Daculang Tubig", mun_code: "051732" },
  // { label: "Del Pilar (Pob.)", mun_code: "051732" },
  // { label: "Grijalvo", mun_code: "051732" },
  // { label: "Lupi", mun_code: "051732" },
  // { label: "Pamukid", mun_code: "051732" },
  // { label: "Pinamasagan", mun_code: "051732" },
  // { label: "Pipian", mun_code: "051732" },
  // { label: "Planza", mun_code: "051732" },
  // { label: "Rizal (Pob.)", mun_code: "051732" },
  // { label: "San Joaquin", mun_code: "051732" },
  // { label: "Santa Cruz", mun_code: "051732" },
  // { label: "Tagpocol", mun_code: "051732" },
  // { label: "Adiangao", mun_code: "051733" },
  // { label: "Bagacay", mun_code: "051733" },
  // { label: "Bahay", mun_code: "051733" },
  // { label: "Boclod", mun_code: "051733" },
  // { label: "Calalahan", mun_code: "051733" },
  // { label: "Calawit", mun_code: "051733" },
  // { label: "Camagong", mun_code: "051733" },
  // { label: "Catalotoan", mun_code: "051733" },
  // { label: "Danlog", mun_code: "051733" },
  // { label: "Del Carmen (Pob.)", mun_code: "051733" },
  // { label: "Dolo", mun_code: "051733" },
  // { label: "Kinalansan", mun_code: "051733" },
  // { label: "Mampirao", mun_code: "051733" },
  // { label: "Manzana", mun_code: "051733" },
  // { label: "Minoro", mun_code: "051733" },
  // { label: "Palale", mun_code: "051733" },
  // { label: "Ponglon", mun_code: "051733" },
  // { label: "Pugay", mun_code: "051733" },
  // { label: "Sabang", mun_code: "051733" },
  // { label: "Salogon", mun_code: "051733" },
  // { label: "San Antonio (Pob.)", mun_code: "051733" },
  // { label: "San Juan (Pob.)", mun_code: "051733" },
  // { label: "San Vicente (Pob.)", mun_code: "051733" },
  // { label: "Santa Cruz (Pob.)", mun_code: "051733" },
  // { label: "Soledad (Pob.)", mun_code: "051733" },
  // { label: "Tagas", mun_code: "051733" },
  // { label: "Tambangan", mun_code: "051733" },
  // { label: "Telegrafo", mun_code: "051733" },
  // { label: "Tominawog", mun_code: "051733" },
  // { label: "Aldezar", mun_code: "051734" },
  // { label: "Alteza", mun_code: "051734" },
  // { label: "Anib", mun_code: "051734" },
  // { label: "Awayan", mun_code: "051734" },
  // { label: "Azucena", mun_code: "051734" },
  // { label: "Bagong Sirang", mun_code: "051734" },
  // { label: "Binahian", mun_code: "051734" },
  // { label: "Bolo Sur", mun_code: "051734" },
  // { label: "Bolo Norte", mun_code: "051734" },
  // { label: "Bulan", mun_code: "051734" },
  // { label: "Bulawan", mun_code: "051734" },
  // { label: "Cabuyao", mun_code: "051734" },
  // { label: "Caima", mun_code: "051734" },
  // { label: "Calagbangan", mun_code: "051734" },
  // { label: "Calampinay", mun_code: "051734" },
  // { label: "Carayrayan", mun_code: "051734" },
  // { label: "Cotmo", mun_code: "051734" },
  // { label: "Gabi", mun_code: "051734" },
  // { label: "Gaongan", mun_code: "051734" },
  // { label: "Impig", mun_code: "051734" },
  // { label: "Lipilip", mun_code: "051734" },
  // { label: "Lubigan Jr.", mun_code: "051734" },
  // { label: "Lubigan Sr.", mun_code: "051734" },
  // { label: "Malaguico", mun_code: "051734" },
  // { label: "Malubago", mun_code: "051734" },
  // { label: "Manangle", mun_code: "051734" },
  // { label: "Mangga", mun_code: "051734" },
  // { label: "Mangapo", mun_code: "051734" },
  // { label: "Manlubang", mun_code: "051734" },
  // { label: "Mantila", mun_code: "051734" },
  // { label: "North Centro (Pob.)", mun_code: "051734" },
  // { label: "North Villazar", mun_code: "051734" },
  // { label: "Sagrada Familia", mun_code: "051734" },
  // { label: "Salanda", mun_code: "051734" },
  // { label: "Salvacion", mun_code: "051734" },
  // { label: "San Isidro", mun_code: "051734" },
  // { label: "San Vicente", mun_code: "051734" },
  // { label: "Serranzana", mun_code: "051734" },
  // { label: "South Centro (Pob.)", mun_code: "051734" },
  // { label: "South Villazar", mun_code: "051734" },
  // { label: "Taisan", mun_code: "051734" },
  // { label: "Tara", mun_code: "051734" },
  // { label: "Tible", mun_code: "051734" },
  // { label: "Tula-tula", mun_code: "051734" },
  // { label: "Vigaan", mun_code: "051734" },
  // { label: "Yabo", mun_code: "051734" },
  // { label: "Bagong Sirang", mun_code: "051735" },
  // { label: "Bahao", mun_code: "051735" },
  // { label: "Boboan", mun_code: "051735" },
  // { label: "Butawanan", mun_code: "051735" },
  // { label: "Cabugao", mun_code: "051735" },
  // { label: "Fundado", mun_code: "051735" },
  // { label: "Homestead", mun_code: "051735" },
  // { label: "La Purisima", mun_code: "051735" },
  // { label: "Mabuhay", mun_code: "051735" },
  // { label: "Malaconini", mun_code: "051735" },
  // { label: "Nalayahan", mun_code: "051735" },
  // { label: "Matandang Siruma", mun_code: "051735" },
  // { label: "Pinitan", mun_code: "051735" },
  // { label: "Poblacion", mun_code: "051735" },
  // { label: "Pamintan-Bantilan", mun_code: "051735" },
  // { label: "Salvacion", mun_code: "051735" },
  // { label: "San Andres", mun_code: "051735" },
  // { label: "San Ramon (Daldagon)", mun_code: "051735" },
  // { label: "Sulpa", mun_code: "051735" },
  // { label: "Tandoc", mun_code: "051735" },
  // { label: "Tongo-Bantigue", mun_code: "051735" },
  // { label: "Vito", mun_code: "051735" },
  // { label: "Abo", mun_code: "051736" },
  // { label: "Cabalinadan", mun_code: "051736" },
  // { label: "Caraycayon", mun_code: "051736" },
  // { label: "Casuna", mun_code: "051736" },
  // { label: "Consocep", mun_code: "051736" },
  // { label: "Coyaoyao", mun_code: "051736" },
  // { label: "Gaao", mun_code: "051736" },
  // { label: "Gingaroy", mun_code: "051736" },
  // { label: "Gubat", mun_code: "051736" },
  // { label: "Huyonhuyon", mun_code: "051736" },
  // { label: "Libod", mun_code: "051736" },
  // { label: "Mabalodbalod", mun_code: "051736" },
  // { label: "May-Anao", mun_code: "051736" },
  // { label: "Panagan", mun_code: "051736" },
  // { label: "Poblacion", mun_code: "051736" },
  // { label: "Salvacion", mun_code: "051736" },
  // { label: "San Antonio", mun_code: "051736" },
  // { label: "San Francisco", mun_code: "051736" },
  // { label: "San Miguel", mun_code: "051736" },
  // { label: "San Rafael", mun_code: "051736" },
  // { label: "Talojongon", mun_code: "051736" },
  // { label: "Tinawagan", mun_code: "051736" },
  // { label: "Vinagre", mun_code: "051736" },
  // { label: "Agay-Ayan", mun_code: "051737" },
  // { label: "Antipolo", mun_code: "051737" },
  // { label: "Bagacay", mun_code: "051737" },
  // { label: "Banga", mun_code: "051737" },
  // { label: "Bolaobalite", mun_code: "051737" },
  // { label: "Bani", mun_code: "051737" },
  // { label: "Bataan", mun_code: "051737" },
  // { label: "Binalay", mun_code: "051737" },
  // { label: "Buenavista", mun_code: "051737" },
  // { label: "Buyo", mun_code: "051737" },
  // { label: "Cagliliog", mun_code: "051737" },
  // { label: "Caloco", mun_code: "051737" },
  // { label: "Camagong", mun_code: "051737" },
  // { label: "Canayonan", mun_code: "051737" },
  // { label: "Cawaynan", mun_code: "051737" },
  // { label: "Daligan", mun_code: "051737" },
  // { label: "Filarca (Pob.)", mun_code: "051737" },
  // { label: "La Purisima (Pob.)", mun_code: "051737" },
  // { label: "Lupi", mun_code: "051737" },
  // { label: "Magsaysay (Camp 4)", mun_code: "051737" },
  // { label: "Magtang", mun_code: "051737" },
  // { label: "Mananao", mun_code: "051737" },
  // { label: "La Medalla (Mile 9)", mun_code: "051737" },
  // { label: "New Caaluan", mun_code: "051737" },
  // { label: "Olag Grande", mun_code: "051737" },
  // { label: "Old Caaluan", mun_code: "051737" },
  // { label: "Pag-Asa", mun_code: "051737" },
  // { label: "Pantat", mun_code: "051737" },
  // { label: "Sagrada (Camp 6)", mun_code: "051737" },
  // { label: "Salvacion", mun_code: "051737" },
  // { label: "San Antonio", mun_code: "051737" },
  // { label: "San Isidro (Pob.)", mun_code: "051737" },
  // { label: "San Jose (Tiltilan)", mun_code: "051737" },
  // { label: "San Pascual (Pob.)", mun_code: "051737" },
  // { label: "San Ramon (Camp 7)", mun_code: "051737" },
  // { label: "San Roque", mun_code: "051737" },
  // { label: "San Vicente", mun_code: "051737" },
  // { label: "Santa Cruz (Pob.)", mun_code: "051737" },
  // { label: "Sogod", mun_code: "051737" },
  // { label: "Tambang", mun_code: "051737" },
  // { label: "Tierra Nevada", mun_code: "051737" },
  // { label: "Union", mun_code: "051737" },
  // { label: "Salvacion Poblacion", mun_code: "051737" },
  // { label: "Antipolo", mun_code: "052001" },
  // { label: "Bacak", mun_code: "052001" },
  // { label: "Bagatabao", mun_code: "052001" },
  // { label: "Bugao", mun_code: "052001" },
  // { label: "Cahan", mun_code: "052001" },
  // { label: "Hinipaan", mun_code: "052001" },
  // { label: "Magsaysay", mun_code: "052001" },
  // { label: "Poblacion", mun_code: "052001" },
  // { label: "Quigaray", mun_code: "052001" },
  // { label: "Quezon (Pancayanan)", mun_code: "052001" },
  // { label: "Sagrada", mun_code: "052001" },
  // { label: "Salvacion (Panuto)", mun_code: "052001" },
  // { label: "San Isidro", mun_code: "052001" },
  // { label: "San Rafael (Mahantod)", mun_code: "052001" },
  // { label: "San Vicente", mun_code: "052001" },
  // { label: "Santa Mesa", mun_code: "052001" },
  // { label: "Santa Teresa", mun_code: "052001" },
  // { label: "Suchan", mun_code: "052001" },
  // { label: "Abihao", mun_code: "052002" },
  // { label: "Agban", mun_code: "052002" },
  // { label: "Bagong Sirang", mun_code: "052002" },
  // { label: "Benticayan", mun_code: "052002" },
  // { label: "Buenavista", mun_code: "052002" },
  // { label: "Caragumihan", mun_code: "052002" },
  // { label: "Batolinao", mun_code: "052002" },
  // { label: "Danao", mun_code: "052002" },
  // { label: "Sagrada", mun_code: "052002" },
  // { label: "Ginitligan", mun_code: "052002" },
  // { label: "Guinsaanan", mun_code: "052002" },
  // { label: "J. M. Alberto", mun_code: "052002" },
  // { label: "Macutal", mun_code: "052002" },
  // { label: "Moning", mun_code: "052002" },
  // { label: "Nagbarorong", mun_code: "052002" },
  // { label: "P. Teston", mun_code: "052002" },
  // { label: "Paniquihan", mun_code: "052002" },
  // { label: "Eastern Poblacion", mun_code: "052002" },
  // { label: "Puraran", mun_code: "052002" },
  // { label: "Putsan", mun_code: "052002" },
  // { label: "Quezon", mun_code: "052002" },
  // { label: "Rizal", mun_code: "052002" },
  // { label: "Salvacion", mun_code: "052002" },
  // { label: "San Lorenzo", mun_code: "052002" },
  // { label: "San Miguel", mun_code: "052002" },
  // { label: "Santa Maria", mun_code: "052002" },
  // { label: "Tilod", mun_code: "052002" },
  // { label: "Western Poblacion", mun_code: "052002" },
  // { label: "Bagumbayan", mun_code: "052003" },
  // { label: "Banawang", mun_code: "052003" },
  // { label: "Batalay", mun_code: "052003" },
  // { label: "Binanwahan", mun_code: "052003" },
  // { label: "Bote", mun_code: "052003" },
  // { label: "Buenavista", mun_code: "052003" },
  // { label: "Cabugao", mun_code: "052003" },
  // { label: "Cagraray", mun_code: "052003" },
  // { label: "Carorian", mun_code: "052003" },
  // { label: "Guinobatan", mun_code: "052003" },
  // { label: "Libjo", mun_code: "052003" },
  // { label: "Marinawa", mun_code: "052003" },
  // { label: "Mintay", mun_code: "052003" },
  // { label: "Oguis", mun_code: "052003" },
  // { label: "Pananaogan", mun_code: "052003" },
  // { label: "Libod Poblacion", mun_code: "052003" },
  // { label: "San Andres", mun_code: "052003" },
  // { label: "San Pedro", mun_code: "052003" },
  // { label: "San Roque", mun_code: "052003" },
  // { label: "Santa Isabel", mun_code: "052003" },
  // { label: "Sibacungan", mun_code: "052003" },
  // { label: "Sipi", mun_code: "052003" },
  // { label: "Talisay", mun_code: "052003" },
  // { label: "Tamburan", mun_code: "052003" },
  // { label: "Tilis", mun_code: "052003" },
  // { label: "Ilawod (Pob.)", mun_code: "052003" },
  // { label: "Baybay (Pob.)", mun_code: "052004" },
  // { label: "Bocon", mun_code: "052004" },
  // { label: "Bothoan (Pob.)", mun_code: "052004" },
  // { label: "Buenavista", mun_code: "052004" },
  // { label: "Bulalacao", mun_code: "052004" },
  // { label: "Camburo", mun_code: "052004" },
  // { label: "Dariao", mun_code: "052004" },
  // { label: "Datag East", mun_code: "052004" },
  // { label: "Datag West", mun_code: "052004" },
  // { label: "Guiamlong", mun_code: "052004" },
  // { label: "Hitoma", mun_code: "052004" },
  // { label: "Icanbato (Pob.)", mun_code: "052004" },
  // { label: "Inalmasinan", mun_code: "052004" },
  // { label: "Iyao", mun_code: "052004" },
  // { label: "Mabini", mun_code: "052004" },
  // { label: "Maui", mun_code: "052004" },
  // { label: "Maysuran", mun_code: "052004" },
  // { label: "Milaviga", mun_code: "052004" },
  // { label: "Panique", mun_code: "052004" },
  // { label: "Sabangan", mun_code: "052004" },
  // { label: "Sabloyon", mun_code: "052004" },
  // { label: "Salvacion", mun_code: "052004" },
  // { label: "Supang", mun_code: "052004" },
  // { label: "Toytoy (Pob.)", mun_code: "052004" },
  // { label: "Tubli", mun_code: "052004" },
  // { label: "Tucao", mun_code: "052004" },
  // { label: "Obi", mun_code: "052004" },
  // { label: "Biong", mun_code: "052005" },
  // { label: "Dororian", mun_code: "052005" },
  // { label: "Poblacion District I", mun_code: "052005" },
  // { label: "Poblacion District II", mun_code: "052005" },
  // { label: "Poblacion District III", mun_code: "052005" },
  // { label: "San Pedro", mun_code: "052005" },
  // { label: "San Vicente", mun_code: "052005" },
  // { label: "Sicmil", mun_code: "052005" },
  // { label: "Sioron", mun_code: "052005" },
  // { label: "Bagawang", mun_code: "052006" },
  // { label: "Baldoc", mun_code: "052006" },
  // { label: "Canlubi", mun_code: "052006" },
  // { label: "Santa Cruz (Catagbacan)", mun_code: "052006" },
  // { label: "Catamban", mun_code: "052006" },
  // { label: "Cobo", mun_code: "052006" },
  // { label: "Hiyop", mun_code: "052006" },
  // { label: "Libod (Pob.)", mun_code: "052006" },
  // { label: "Lourdes", mun_code: "052006" },
  // { label: "Lumabao", mun_code: "052006" },
  // { label: "Marambong", mun_code: "052006" },
  // { label: "Napo (Pob.)", mun_code: "052006" },
  // { label: "Oga", mun_code: "052006" },
  // { label: "Pandan Del Norte (Pob.)", mun_code: "052006" },
  // { label: "Pandan Del Sur (Pob.)", mun_code: "052006" },
  // { label: "Panuto", mun_code: "052006" },
  // { label: "Salvacion (Tariwara)", mun_code: "052006" },
  // { label: "San Andres (Dinungsuran)", mun_code: "052006" },
  // { label: "San Isidro (Langob)", mun_code: "052006" },
  // { label: "Porot (San Jose)", mun_code: "052006" },
  // { label: "San Rafael (Bogtong)", mun_code: "052006" },
  // { label: "San Roque", mun_code: "052006" },
  // { label: "Tabugoc", mun_code: "052006" },
  // { label: "Tokio", mun_code: "052006" },
  // { label: "Wagdas", mun_code: "052006" },
  // { label: "Alinawan", mun_code: "052007" },
  // { label: "Babaguan", mun_code: "052007" },
  // { label: "Bagong Bayan", mun_code: "052007" },
  // { label: "Burabod", mun_code: "052007" },
  // { label: "Cabuyoan", mun_code: "052007" },
  // { label: "Cagdarao", mun_code: "052007" },
  // { label: "Mabini", mun_code: "052007" },
  // { label: "Maculiw", mun_code: "052007" },
  // { label: "Panay", mun_code: "052007" },
  // { label: "Taopon (Pangcayanan)", mun_code: "052007" },
  // { label: "Salvacion (Pob.)", mun_code: "052007" },
  // { label: "San Antonio", mun_code: "052007" },
  // { label: "San Joaquin (Pob.)", mun_code: "052007" },
  // { label: "San Jose (Pob.)", mun_code: "052007" },
  // { label: "San Juan (Pob.)", mun_code: "052007" },
  // { label: "San Miguel", mun_code: "052007" },
  // { label: "San Nicolas (Pob.)", mun_code: "052007" },
  // { label: "San Pedro (Pob.)", mun_code: "052007" },
  // { label: "San Vicente (Pob.)", mun_code: "052007" },
  // { label: "Santa Ana (Pob.)", mun_code: "052007" },
  // { label: "Santa Maria (Pob.)", mun_code: "052007" },
  // { label: "Santo Santiago (Pob.)", mun_code: "052007" },
  // { label: "Tibo", mun_code: "052007" },
  // { label: "Agojo", mun_code: "052008" },
  // { label: "Alibuag", mun_code: "052008" },
  // { label: "Asgad (Juan M. Alberto)", mun_code: "052008" },
  // { label: "Bagong Sirang", mun_code: "052008" },
  // { label: "Barihay", mun_code: "052008" },
  // { label: "Batong Paloway", mun_code: "052008" },
  // { label: "Belmonte (Pob.)", mun_code: "052008" },
  // { label: "Bislig", mun_code: "052008" },
  // { label: "Bon-ot", mun_code: "052008" },
  // { label: "Cabungahan", mun_code: "052008" },
  // { label: "Cabcab", mun_code: "052008" },
  // { label: "Carangag", mun_code: "052008" },
  // { label: "Catagbacan", mun_code: "052008" },
  // { label: "Codon", mun_code: "052008" },
  // { label: "Comagaycay", mun_code: "052008" },
  // { label: "Datag", mun_code: "052008" },
  // { label: "Divino Rostro (Pob.)", mun_code: "052008" },
  // { label: "Esperanza (Pob.)", mun_code: "052008" },
  // { label: "Hilawan", mun_code: "052008" },
  // { label: "Lictin", mun_code: "052008" },
  // { label: "Lubas", mun_code: "052008" },
  // { label: "Manambrag", mun_code: "052008" },
  // { label: "Mayngaway", mun_code: "052008" },
  // { label: "Palawig", mun_code: "052008" },
  // { label: "Puting Baybay", mun_code: "052008" },
  // { label: "Rizal", mun_code: "052008" },
  // { label: "Salvacion (Pob.)", mun_code: "052008" },
  // { label: "San Isidro", mun_code: "052008" },
  // { label: "San Jose", mun_code: "052008" },
  // { label: "San Roque (Pob.)", mun_code: "052008" },
  // { label: "San Vicente", mun_code: "052008" },
  // { label: "Santa Cruz (Pob.)", mun_code: "052008" },
  // { label: "Sapang Palay (Pob.)", mun_code: "052008" },
  // { label: "Tibang", mun_code: "052008" },
  // { label: "Timbaan", mun_code: "052008" },
  // { label: "Tominawog", mun_code: "052008" },
  // { label: "Wagdas (Pob.)", mun_code: "052008" },
  // { label: "Yocti", mun_code: "052008" },
  // { label: "Balatohan", mun_code: "052009" },
  // { label: "Salvacion (Patagan)", mun_code: "052009" },
  // { label: "Boton", mun_code: "052009" },
  // { label: "Buhi", mun_code: "052009" },
  // { label: "Dayawa", mun_code: "052009" },
  // { label: "Atsan (District I)", mun_code: "052009" },
  // { label: "Poblacion District II", mun_code: "052009" },
  // { label: "Poblacion District III", mun_code: "052009" },
  // { label: "J. M. Alberto", mun_code: "052009" },
  // { label: "Katipunan", mun_code: "052009" },
  // { label: "Kilikilihan", mun_code: "052009" },
  // { label: "Mabato", mun_code: "052009" },
  // { label: "Obo", mun_code: "052009" },
  // { label: "Pacogon", mun_code: "052009" },
  // { label: "Pagsangahan", mun_code: "052009" },
  // { label: "Pangilao", mun_code: "052009" },
  // { label: "Paraiso", mun_code: "052009" },
  // { label: "Santa Elena (Patagan)", mun_code: "052009" },
  // { label: "Progreso", mun_code: "052009" },
  // { label: "San Juan (Aroyao)", mun_code: "052009" },
  // { label: "San Marcos", mun_code: "052009" },
  // { label: "Siay", mun_code: "052009" },
  // { label: "Solong", mun_code: "052009" },
  // { label: "Tobrehon", mun_code: "052009" },
  // { label: "Almojuela", mun_code: "052010" },
  // { label: "Ananong", mun_code: "052010" },
  // { label: "Asuncion (Pob.)", mun_code: "052010" },
  // { label: "Batohonan", mun_code: "052010" },
  // { label: "Begonia", mun_code: "052010" },
  // { label: "Botinagan", mun_code: "052010" },
  // { label: "Buenavista", mun_code: "052010" },
  // { label: "Burgos", mun_code: "052010" },
  // { label: "Del Pilar", mun_code: "052010" },
  // { label: "Mabini", mun_code: "052010" },
  // { label: "Magsaysay", mun_code: "052010" },
  // { label: "Ogbong", mun_code: "052010" },
  // { label: "Pedro Vera (Summit)", mun_code: "052010" },
  // { label: "Quezon", mun_code: "052010" },
  // { label: "Quirino (Abugan)", mun_code: "052010" },
  // { label: "Rizal", mun_code: "052010" },
  // { label: "Roxas", mun_code: "052010" },
  // { label: "Sagrada", mun_code: "052010" },
  // { label: "San Isidro (Pob.)", mun_code: "052010" },
  // { label: "San Jose Poblacion", mun_code: "052010" },
  // { label: "San Jose Oco", mun_code: "052010" },
  // { label: "San Pedro (Pob.)", mun_code: "052010" },
  // { label: "San Roque (Pob.)", mun_code: "052010" },
  // { label: "San Vicente (Pob.)", mun_code: "052010" },
  // { label: "Santa Rosa", mun_code: "052010" },
  // { label: "Soboc", mun_code: "052010" },
  // { label: "Tambongon", mun_code: "052010" },
  // { label: "Tinago", mun_code: "052010" },
  // { label: "Villa Aurora", mun_code: "052010" },
  // { label: "Antipolo Del Norte", mun_code: "052011" },
  // { label: "Antipolo Del Sur", mun_code: "052011" },
  // { label: "Balite", mun_code: "052011" },
  // { label: "Batag", mun_code: "052011" },
  // { label: "Bigaa", mun_code: "052011" },
  // { label: "Buenavista", mun_code: "052011" },
  // { label: "Buyo", mun_code: "052011" },
  // { label: "Cabihian", mun_code: "052011" },
  // { label: "Calabnigan", mun_code: "052011" },
  // { label: "Calampong", mun_code: "052011" },
  // { label: "Calatagan Proper", mun_code: "052011" },
  // { label: "Calatagan Tibang", mun_code: "052011" },
  // { label: "Capilihan", mun_code: "052011" },
  // { label: "Casoocan", mun_code: "052011" },
  // { label: "Cavinitan", mun_code: "052011" },
  // { label: "Gogon Sirangan", mun_code: "052011" },
  // { label: "Concepcion (Pob.)", mun_code: "052011" },
  // { label: "Constantino (Pob.)", mun_code: "052011" },
  // { label: "Danicop", mun_code: "052011" },
  // { label: "Dugui San Vicente", mun_code: "052011" },
  // { label: "Dugui San Isidro", mun_code: "052011" },
  // { label: "Dugui Too", mun_code: "052011" },
  // { label: "F. Tacorda Village", mun_code: "052011" },
  // { label: "Francia (Pob.)", mun_code: "052011" },
  // { label: "Gogon Centro", mun_code: "052011" },
  // { label: "Hawan Grande", mun_code: "052011" },
  // { label: "Hawan Ilaya", mun_code: "052011" },
  // { label: "Hicming", mun_code: "052011" },
  // { label: "Igang", mun_code: "052011" },
  // { label: "Juan M. Alberto (Poniton)", mun_code: "052011" },
  // { label: "Lanao (Pob.)", mun_code: "052011" },
  // { label: "Magnesia Del Norte", mun_code: "052011" },
  // { label: "Magnesia Del Sur", mun_code: "052011" },
  // { label: "Marcelo Alberto (Pob.)", mun_code: "052011" },
  // { label: "Marilima", mun_code: "052011" },
  // { label: "Pajo Baguio", mun_code: "052011" },
  // { label: "Pajo San Isidro", mun_code: "052011" },
  // { label: "Palnab Del Norte", mun_code: "052011" },
  // { label: "Palnab Del Sur", mun_code: "052011" },
  // { label: "Palta Big", mun_code: "052011" },
  // { label: "Palta Salvacion", mun_code: "052011" },
  // { label: "Palta Small", mun_code: "052011" },
  // { label: "Rawis (Pob.)", mun_code: "052011" },
  // { label: "Salvacion", mun_code: "052011" },
  // { label: "San Isidro Village", mun_code: "052011" },
  // { label: "San Jose (Pob.)", mun_code: "052011" },
  // { label: "San Juan (Pob.)", mun_code: "052011" },
  // { label: "San Pablo (Pob.)", mun_code: "052011" },
  // { label: "San Pedro (Pob.)", mun_code: "052011" },
  // { label: "San Roque (Pob.)", mun_code: "052011" },
  // { label: "San Vicente", mun_code: "052011" },
  // { label: "Ibong Sapa (San Vicente Sur)", mun_code: "052011" },
  // { label: "Santa Cruz", mun_code: "052011" },
  // { label: "Santa Elena (Pob.)", mun_code: "052011" },
  // { label: "Santo Cristo", mun_code: "052011" },
  // { label: "Santo Domingo", mun_code: "052011" },
  // { label: "Simamla", mun_code: "052011" },
  // { label: "Sogod-Simamla", mun_code: "052011" },
  // { label: "Talisoy", mun_code: "052011" },
  // { label: "Sogod-Tibgao", mun_code: "052011" },
  // { label: "Tubaon", mun_code: "052011" },
  // { label: "Valencia", mun_code: "052011" },
  // { label: "Ambolong", mun_code: "054101" },
  // { label: "Amoroy", mun_code: "054101" },
  // { label: "Amutag", mun_code: "054101" },
  // { label: "Bagauma", mun_code: "054101" },
  // { label: "Balawing", mun_code: "054101" },
  // { label: "Balete", mun_code: "054101" },
  // { label: "Bangon", mun_code: "054101" },
  // { label: "Cabangcalan", mun_code: "054101" },
  // { label: "Cabas-An", mun_code: "054101" },
  // { label: "Calanay", mun_code: "054101" },
  // { label: "Capsay", mun_code: "054101" },
  // { label: "Concepcion", mun_code: "054101" },
  // { label: "Dayhagan", mun_code: "054101" },
  // { label: "Don Pablo Dela Rosa", mun_code: "054101" },
  // { label: "Gumahang", mun_code: "054101" },
  // { label: "Jaboyoan", mun_code: "054101" },
  // { label: "Lanang", mun_code: "054101" },
  // { label: "Luy-a", mun_code: "054101" },
  // { label: "Macabug", mun_code: "054101" },
  // { label: "Malubi", mun_code: "054101" },
  // { label: "Managanaga", mun_code: "054101" },
  // { label: "Manamoc", mun_code: "054101" },
  // { label: "Mariposa", mun_code: "054101" },
  // { label: "Mataba", mun_code: "054101" },
  // { label: "Matalangtalang", mun_code: "054101" },
  // { label: "Matangog", mun_code: "054101" },
  // { label: "Nabongsoran", mun_code: "054101" },
  // { label: "Pangle", mun_code: "054101" },
  // { label: "Panique", mun_code: "054101" },
  // { label: "Pinanaan", mun_code: "054101" },
  // { label: "Poblacion", mun_code: "054101" },
  // { label: "Puro", mun_code: "054101" },
  // { label: "San Agustin", mun_code: "054101" },
  // { label: "San Isidro", mun_code: "054101" },
  // { label: "Sawang", mun_code: "054101" },
  // { label: "Syndicate", mun_code: "054101" },
  // { label: "Talabaan", mun_code: "054101" },
  // { label: "Talib", mun_code: "054101" },
  // { label: "Tigbao", mun_code: "054101" },
  // { label: "Tinago", mun_code: "054101" },
  // { label: "Tinigban", mun_code: "054101" },
  // { label: "Baao", mun_code: "054102" },
  // { label: "Banase", mun_code: "054102" },
  // { label: "Batuila", mun_code: "054102" },
  // { label: "Cagara", mun_code: "054102" },
  // { label: "Cagpandan", mun_code: "054102" },
  // { label: "Cancahorao", mun_code: "054102" },
  // { label: "Canjunday", mun_code: "054102" },
  // { label: "Docol", mun_code: "054102" },
  // { label: "Eastern Capsay", mun_code: "054102" },
  // { label: "Gabi", mun_code: "054102" },
  // { label: "Gangao", mun_code: "054102" },
  // { label: "Lagta", mun_code: "054102" },
  // { label: "Lahong Proper", mun_code: "054102" },
  // { label: "Lahong Interior", mun_code: "054102" },
  // { label: "Lipata", mun_code: "054102" },
  // { label: "Madangcalan", mun_code: "054102" },
  // { label: "Magdalena", mun_code: "054102" },
  // { label: "Manoboc", mun_code: "054102" },
  // { label: "Obongon Diot", mun_code: "054102" },
  // { label: "Poblacion", mun_code: "054102" },
  // { label: "Polot", mun_code: "054102" },
  // { label: "Potoson", mun_code: "054102" },
  // { label: "Sog-Ong", mun_code: "054102" },
  // { label: "Tinapian", mun_code: "054102" },
  // { label: "Baybay (Lumocab)", mun_code: "054103" },
  // { label: "Bongcanaway", mun_code: "054103" },
  // { label: "Mabuhay (Bongcanaway III)", mun_code: "054103" },
  // { label: "Calumpang", mun_code: "054103" },
  // { label: "Cantil", mun_code: "054103" },
  // { label: "Casamongan", mun_code: "054103" },
  // { label: "Dao", mun_code: "054103" },
  // { label: "Danao", mun_code: "054103" },
  // { label: "Guinbanwahan", mun_code: "054103" },
  // { label: "Ilaya", mun_code: "054103" },
  // { label: "Jangan", mun_code: "054103" },
  // { label: "Jintotolo", mun_code: "054103" },
  // { label: "Mapili", mun_code: "054103" },
  // { label: "Mapitogo", mun_code: "054103" },
  // { label: "Pajo", mun_code: "054103" },
  // { label: "Palane", mun_code: "054103" },
  // { label: "Panguiranan", mun_code: "054103" },
  // { label: "Panubigan", mun_code: "054103" },
  // { label: "Poblacion (Balud)", mun_code: "054103" },
  // { label: "Pulanduta", mun_code: "054103" },
  // { label: "Quinayangan Diotay", mun_code: "054103" },
  // { label: "Quinayangan Tonga", mun_code: "054103" },
  // { label: "Salvacion", mun_code: "054103" },
  // { label: "Sampad", mun_code: "054103" },
  // { label: "San Andres (Quinayangan Dacu)", mun_code: "054103" },
  // { label: "San Antonio", mun_code: "054103" },
  // { label: "Sapatos", mun_code: "054103" },
  // { label: "Talisay", mun_code: "054103" },
  // { label: "Tonga", mun_code: "054103" },
  // { label: "Ubo", mun_code: "054103" },
  // { label: "Victory (Victoria)", mun_code: "054103" },
  // { label: "Villa Alvarez", mun_code: "054103" },
  // { label: "Burgos", mun_code: "054104" },
  // { label: "Canares", mun_code: "054104" },
  // { label: "Costa Rica", mun_code: "054104" },
  // { label: "Danao", mun_code: "054104" },
  // { label: "Gibraltar", mun_code: "054104" },
  // { label: "Mabuhay", mun_code: "054104" },
  // { label: "Matabao", mun_code: "054104" },
  // { label: "Nasandig", mun_code: "054104" },
  // { label: "Panisihan", mun_code: "054104" },
  // { label: "Poblacion", mun_code: "054104" },
  // { label: "Rizal", mun_code: "054104" },
  // { label: "Royroy", mun_code: "054104" },
  // { label: "Sawang", mun_code: "054104" },
  // { label: "Abaca", mun_code: "054105" },
  // { label: "Aguada", mun_code: "054105" },
  // { label: "Badiang", mun_code: "054105" },
  // { label: "Bagumbayan", mun_code: "054105" },
  // { label: "Cadulawan", mun_code: "054105" },
  // { label: "Cagbatang", mun_code: "054105" },
  // { label: "Chimenea", mun_code: "054105" },
  // { label: "Concepcion", mun_code: "054105" },
  // { label: "Curvada", mun_code: "054105" },
  // { label: "Divisoria", mun_code: "054105" },
  // { label: "Domorog", mun_code: "054105" },
  // { label: "Estampar", mun_code: "054105" },
  // { label: "Gahit", mun_code: "054105" },
  // { label: "Libtong", mun_code: "054105" },
  // { label: "Liong", mun_code: "054105" },
  // { label: "Maanahao", mun_code: "054105" },
  // { label: "Madamba", mun_code: "054105" },
  // { label: "Malobago", mun_code: "054105" },
  // { label: "Matayum", mun_code: "054105" },
  // { label: "Matubinao", mun_code: "054105" },
  // { label: "Mintac", mun_code: "054105" },
  // { label: "Nadawisan", mun_code: "054105" },
  // { label: "Pawican", mun_code: "054105" },
  // { label: "Pitogo", mun_code: "054105" },
  // { label: "Poblacion", mun_code: "054105" },
  // { label: "Quezon", mun_code: "054105" },
  // { label: "San Isidro", mun_code: "054105" },
  // { label: "San Jose", mun_code: "054105" },
  // { label: "San Pedro", mun_code: "054105" },
  // { label: "San Rafael", mun_code: "054105" },
  // { label: "Santa Teresita", mun_code: "054105" },
  // { label: "Tagboan", mun_code: "054105" },
  // { label: "Tuybo", mun_code: "054105" },
  // { label: "Villa Pogado", mun_code: "054105" },
  // { label: "Begia", mun_code: "054106" },
  // { label: "Cabayugan", mun_code: "054106" },
  // { label: "Cabungahan", mun_code: "054106" },
  // { label: "Calapayan", mun_code: "054106" },
  // { label: "Calumpang", mun_code: "054106" },
  // { label: "Dalipe", mun_code: "054106" },
  // { label: "Divisoria", mun_code: "054106" },
  // { label: "Guiom", mun_code: "054106" },
  // { label: "Gilotongan", mun_code: "054106" },
  // { label: "Itombato", mun_code: "054106" },
  // { label: "Libertad", mun_code: "054106" },
  // { label: "Looc", mun_code: "054106" },
  // { label: "Mactan", mun_code: "054106" },
  // { label: "Madbad", mun_code: "054106" },
  // { label: "R.M. Magbalon (Bebinan)", mun_code: "054106" },
  // { label: "Mahayahay", mun_code: "054106" },
  // { label: "Maihao", mun_code: "054106" },
  // { label: "Malbug", mun_code: "054106" },
  // { label: "Naro", mun_code: "054106" },
  // { label: "Pananawan", mun_code: "054106" },
  // { label: "Poblacion", mun_code: "054106" },
  // { label: "Pulot", mun_code: "054106" },
  // { label: "Recodo", mun_code: "054106" },
  // { label: "San Jose", mun_code: "054106" },
  // { label: "San Vicente", mun_code: "054106" },
  // { label: "Taberna", mun_code: "054106" },
  // { label: "Talisay", mun_code: "054106" },
  // { label: "Tuburan", mun_code: "054106" },
  // { label: "Villahermosa", mun_code: "054106" },
  // { label: "Chico Island", mun_code: "054106" },
  // { label: "Lague-lague", mun_code: "054106" },
  // { label: "Palobandera", mun_code: "054106" },
  // { label: "Pin-As", mun_code: "054106" },
  // { label: "Iraya", mun_code: "054106" },
  // { label: "Punta Batsan", mun_code: "054106" },
  // { label: "Tubog", mun_code: "054106" },
  // { label: "Albasan", mun_code: "054107" },
  // { label: "Buyo", mun_code: "054107" },
  // { label: "Calpi", mun_code: "054107" },
  // { label: "Canomay", mun_code: "054107" },
  // { label: "Cawayan", mun_code: "054107" },
  // { label: "Poblacion District I (Bgy. 1)", mun_code: "054107" },
  // { label: "Poblacion District II (Bgy. 2)", mun_code: "054107" },
  // { label: "Mababang Baybay", mun_code: "054107" },
  // { label: "Mabiton", mun_code: "054107" },
  // { label: "Manapao", mun_code: "054107" },
  // { label: "Nabasagan", mun_code: "054107" },
  // { label: "Nonoc", mun_code: "054107" },
  // { label: "Pasig", mun_code: "054107" },
  // { label: "Quezon", mun_code: "054107" },
  // { label: "San Isidro", mun_code: "054107" },
  // { label: "San Ramon", mun_code: "054107" },
  // { label: "San Vicente", mun_code: "054107" },
  // { label: "Taguilid", mun_code: "054107" },
  // { label: "Imelda", mun_code: "054107" },
  // { label: "Balantay", mun_code: "054108" },
  // { label: "Balocawe", mun_code: "054108" },
  // { label: "Banahao", mun_code: "054108" },
  // { label: "Buenaflor", mun_code: "054108" },
  // { label: "Buracan", mun_code: "054108" },
  // { label: "Cabanoyoan", mun_code: "054108" },
  // { label: "Cabrera", mun_code: "054108" },
  // { label: "Cadulan", mun_code: "054108" },
  // { label: "Calabad", mun_code: "054108" },
  // { label: "Canomay", mun_code: "054108" },
  // { label: "Divisoria", mun_code: "054108" },
  // { label: "Gaid", mun_code: "054108" },
  // { label: "Gregorio Alino (Pia-ong)", mun_code: "054108" },
  // { label: "Magcaraguit", mun_code: "054108" },
  // { label: "Mambog", mun_code: "054108" },
  // { label: "Poblacion", mun_code: "054108" },
  // { label: "Rizal", mun_code: "054108" },
  // { label: "San Vicente", mun_code: "054108" },
  // { label: "Suba", mun_code: "054108" },
  // { label: "T.R. Yangco (Yanco)", mun_code: "054108" },
  // { label: "Agoho", mun_code: "054109" },
  // { label: "Almero", mun_code: "054109" },
  // { label: "Baras", mun_code: "054109" },
  // { label: "Domorog", mun_code: "054109" },
  // { label: "Guadalupe", mun_code: "054109" },
  // { label: "Iligan", mun_code: "054109" },
  // { label: "Labangtaytay", mun_code: "054109" },
  // { label: "Labrador", mun_code: "054109" },
  // { label: "Libertad", mun_code: "054109" },
  // { label: "Magsaysay", mun_code: "054109" },
  // { label: "Masbaranon", mun_code: "054109" },
  // { label: "Poblacion", mun_code: "054109" },
  // { label: "Potingbato", mun_code: "054109" },
  // { label: "Rizal", mun_code: "054109" },
  // { label: "San Roque", mun_code: "054109" },
  // { label: "Santiago", mun_code: "054109" },
  // { label: "Sorosimbajan", mun_code: "054109" },
  // { label: "Tawad", mun_code: "054109" },
  // { label: "Tunga", mun_code: "054109" },
  // { label: "Villa", mun_code: "054109" },
  // { label: "Alas", mun_code: "054110" },
  // { label: "Ayat", mun_code: "054110" },
  // { label: "Bat-Ongan", mun_code: "054110" },
  // { label: "Bugtong", mun_code: "054110" },
  // { label: "Buri", mun_code: "054110" },
  // { label: "Cabitan", mun_code: "054110" },
  // { label: "Cagmasoso", mun_code: "054110" },
  // { label: "Canomoy", mun_code: "054110" },
  // { label: "Centro", mun_code: "054110" },
  // { label: "Dayao", mun_code: "054110" },
  // { label: "Guincaiptan", mun_code: "054110" },
  // { label: "Lantangan", mun_code: "054110" },
  // { label: "Looc", mun_code: "054110" },
  // { label: "Mabatobato", mun_code: "054110" },
  // { label: "Maolingon", mun_code: "054110" },
  // { label: "Nailaban", mun_code: "054110" },
  // { label: "Nanipsan", mun_code: "054110" },
  // { label: "Pinamangcaan", mun_code: "054110" },
  // { label: "Poblacion", mun_code: "054110" },
  // { label: "Polo Dacu", mun_code: "054110" },
  // { label: "San Juan", mun_code: "054110" },
  // { label: "San Pablo", mun_code: "054110" },
  // { label: "Santa Fe", mun_code: "054110" },
  // { label: "Tagpu", mun_code: "054110" },
  // { label: "Tumalaytay", mun_code: "054110" },
  // { label: "Laguinbanwa", mun_code: "054110" },
  // { label: "Anas", mun_code: "054111" },
  // { label: "Asid", mun_code: "054111" },
  // { label: "B. Titong", mun_code: "054111" },
  // { label: "Bagumbayan", mun_code: "054111" },
  // { label: "Bantigue", mun_code: "054111" },
  // { label: "Bapor (Pob.)", mun_code: "054111" },
  // { label: "Batuhan", mun_code: "054111" },
  // { label: "Bayombon", mun_code: "054111" },
  // { label: "Biyong", mun_code: "054111" },
  // { label: "Bolo", mun_code: "054111" },
  // { label: "Cagay", mun_code: "054111" },
  // { label: "Cawayan Exterior", mun_code: "054111" },
  // { label: "Cawayan Interior", mun_code: "054111" },
  // { label: "Centro (Pob.)", mun_code: "054111" },
  // { label: "Espinosa", mun_code: "054111" },
  // { label: "F. Magallanes", mun_code: "054111" },
  // { label: "Ibingay", mun_code: "054111" },
  // { label: "Igang", mun_code: "054111" },
  // { label: "Kalipay (Pob.)", mun_code: "054111" },
  // { label: "Kinamaligan", mun_code: "054111" },
  // { label: "Malinta", mun_code: "054111" },
  // { label: "Mayngaran", mun_code: "054111" },
  // { label: "Nursery", mun_code: "054111" },
  // { label: "Pating (Pob.)", mun_code: "054111" },
  // { label: "Pawa", mun_code: "054111" },
  // { label: "Sinalongan", mun_code: "054111" },
  // { label: "Tugbo", mun_code: "054111" },
  // { label: "Ubongan Dacu", mun_code: "054111" },
  // { label: "Usab", mun_code: "054111" },
  // { label: "Bacolod", mun_code: "054112" },
  // { label: "Bangad", mun_code: "054112" },
  // { label: "Bara", mun_code: "054112" },
  // { label: "Bonbon", mun_code: "054112" },
  // { label: "Calasuche", mun_code: "054112" },
  // { label: "Calumpang (Taisan)", mun_code: "054112" },
  // { label: "Capaculan", mun_code: "054112" },
  // { label: "Cayabon", mun_code: "054112" },
  // { label: "Guinluthangan", mun_code: "054112" },
  // { label: "Jamorawon", mun_code: "054112" },
  // { label: "Magsalangi", mun_code: "054112" },
  // { label: "Matagbac", mun_code: "054112" },
  // { label: "Matanglad", mun_code: "054112" },
  // { label: "Matiporon", mun_code: "054112" },
  // { label: "Moises R. Espinosa", mun_code: "054112" },
  // { label: "Narangasan", mun_code: "054112" },
  // { label: "Pamangpangon", mun_code: "054112" },
  // { label: "Poblacion East", mun_code: "054112" },
  // { label: "Poblacion West", mun_code: "054112" },
  // { label: "Paraiso (Potot)", mun_code: "054112" },
  // { label: "San Antonio", mun_code: "054112" },
  // { label: "San Carlos", mun_code: "054112" },
  // { label: "Sawmill", mun_code: "054112" },
  // { label: "Tagbon", mun_code: "054112" },
  // { label: "Tawad", mun_code: "054112" },
  // { label: "Tigbao", mun_code: "054112" },
  // { label: "Tinaclipan (Bato)", mun_code: "054112" },
  // { label: "Baang", mun_code: "054113" },
  // { label: "Bagacay", mun_code: "054113" },
  // { label: "Balatucan", mun_code: "054113" },
  // { label: "Barag", mun_code: "054113" },
  // { label: "Dacu", mun_code: "054113" },
  // { label: "Fabrica", mun_code: "054113" },
  // { label: "Guintorelan", mun_code: "054113" },
  // { label: "Holjogon", mun_code: "054113" },
  // { label: "Lalaguna", mun_code: "054113" },
  // { label: "Lomocloc", mun_code: "054113" },
  // { label: "Luyong Catungan", mun_code: "054113" },
  // { label: "Mabuhay", mun_code: "054113" },
  // { label: "Mandali", mun_code: "054113" },
  // { label: "Mapuyo", mun_code: "054113" },
  // { label: "Marintoc", mun_code: "054113" },
  // { label: "Nasunduan", mun_code: "054113" },
  // { label: "Pinamalatican", mun_code: "054113" },
  // { label: "Pinamarbuhan", mun_code: "054113" },
  // { label: "Poblacion Dist. I", mun_code: "054113" },
  // { label: "Poblacion Dist. II", mun_code: "054113" },
  // { label: "Polot", mun_code: "054113" },
  // { label: "Sambulawan", mun_code: "054113" },
  // { label: "Santa Maria", mun_code: "054113" },
  // { label: "Sawmill", mun_code: "054113" },
  // { label: "Tabuc", mun_code: "054113" },
  // { label: "Tugawe", mun_code: "054113" },
  // { label: "Tugbo", mun_code: "054113" },
  // { label: "Umabay Exterior", mun_code: "054113" },
  // { label: "Umabay Interior", mun_code: "054113" },
  // { label: "Cantorna", mun_code: "054114" },
  // { label: "Famosa", mun_code: "054114" },
  // { label: "Macarthur", mun_code: "054114" },
  // { label: "Maglambong", mun_code: "054114" },
  // { label: "Morocborocan", mun_code: "054114" },
  // { label: "Poblacion", mun_code: "054114" },
  // { label: "Guinhadap", mun_code: "054114" },
  // { label: "Real", mun_code: "054114" },
  // { label: "Rizal", mun_code: "054114" },
  // { label: "Togoron", mun_code: "054114" },
  // { label: "Antipolo", mun_code: "054115" },
  // { label: "Banco", mun_code: "054115" },
  // { label: "Biga-a", mun_code: "054115" },
  // { label: "Bontod", mun_code: "054115" },
  // { label: "Buenasuerte", mun_code: "054115" },
  // { label: "Intusan", mun_code: "054115" },
  // { label: "Jose A. Abenir Sr. (Libtong)", mun_code: "054115" },
  // { label: "Maanahao", mun_code: "054115" },
  // { label: "Mabini", mun_code: "054115" },
  // { label: "Malibas", mun_code: "054115" },
  // { label: "Maravilla", mun_code: "054115" },
  // { label: "Matugnao", mun_code: "054115" },
  // { label: "Miabas", mun_code: "054115" },
  // { label: "Nabangig", mun_code: "054115" },
  // { label: "Nipa", mun_code: "054115" },
  // { label: "Parina", mun_code: "054115" },
  // { label: "Poblacion", mun_code: "054115" },
  // { label: "Salvacion", mun_code: "054115" },
  // { label: "San Antonio", mun_code: "054115" },
  // { label: "San Carlos", mun_code: "054115" },
  // { label: "San Isidro", mun_code: "054115" },
  // { label: "Santa Cruz", mun_code: "054115" },
  // { label: "Malatawan", mun_code: "054115" },
  // { label: "Alegria", mun_code: "054116" },
  // { label: "Buenasuerte", mun_code: "054116" },
  // { label: "Bugang", mun_code: "054116" },
  // { label: "Bugtong", mun_code: "054116" },
  // { label: "Bunducan", mun_code: "054116" },
  // { label: "Cabangrayan", mun_code: "054116" },
  // { label: "Calongongan", mun_code: "054116" },
  // { label: "Casabangan", mun_code: "054116" },
  // { label: "Guindawahan", mun_code: "054116" },
  // { label: "Labigan", mun_code: "054116" },
  // { label: "Lampuyang", mun_code: "054116" },
  // { label: "Mabuhay", mun_code: "054116" },
  // { label: "Palho", mun_code: "054116" },
  // { label: "Poblacion", mun_code: "054116" },
  // { label: "Salvacion", mun_code: "054116" },
  // { label: "Tanque", mun_code: "054116" },
  // { label: "Tubigan", mun_code: "054116" },
  // { label: "Tubog", mun_code: "054116" },
  // { label: "Aguada", mun_code: "054117" },
  // { label: "Ban-Ao", mun_code: "054117" },
  // { label: "Burabod", mun_code: "054117" },
  // { label: "Cabangcalan", mun_code: "054117" },
  // { label: "Calumpang", mun_code: "054117" },
  // { label: "Camayabsan", mun_code: "054117" },
  // { label: "Daanlungsod", mun_code: "054117" },
  // { label: "Dangpanan", mun_code: "054117" },
  // { label: "Daraga", mun_code: "054117" },
  // { label: "Guin-Awayan", mun_code: "054117" },
  // { label: "Guinhan-Ayan", mun_code: "054117" },
  // { label: "Katipunan", mun_code: "054117" },
  // { label: "Libas", mun_code: "054117" },
  // { label: "Locso-An", mun_code: "054117" },
  // { label: "Luna", mun_code: "054117" },
  // { label: "Mahayag", mun_code: "054117" },
  // { label: "Mahayahay", mun_code: "054117" },
  // { label: "Manlut-Od", mun_code: "054117" },
  // { label: "Matagantang", mun_code: "054117" },
  // { label: "Naboctot", mun_code: "054117" },
  // { label: "Nagarao", mun_code: "054117" },
  // { label: "Nainday", mun_code: "054117" },
  // { label: "Naocondiot", mun_code: "054117" },
  // { label: "Pasiagon", mun_code: "054117" },
  // { label: "Pili", mun_code: "054117" },
  // { label: "Poblacion", mun_code: "054117" },
  // { label: "Puro", mun_code: "054117" },
  // { label: "Quibrada", mun_code: "054117" },
  // { label: "San Marcos", mun_code: "054117" },
  // { label: "Santa Cruz", mun_code: "054117" },
  // { label: "Taboc", mun_code: "054117" },
  // { label: "Tan-Awan", mun_code: "054117" },
  // { label: "Taverna", mun_code: "054117" },
  // { label: "Tubod", mun_code: "054117" },
  // { label: "Villa Inocencio", mun_code: "054117" },
  // { label: "Altavista", mun_code: "054118" },
  // { label: "Benitinan", mun_code: "054118" },
  // { label: "Buenasuerte", mun_code: "054118" },
  // { label: "Buenavista", mun_code: "054118" },
  // { label: "Buenos Aires", mun_code: "054118" },
  // { label: "Buyo", mun_code: "054118" },
  // { label: "Corbada", mun_code: "054118" },
  // { label: "Daplian", mun_code: "054118" },
  // { label: "Del Rosario", mun_code: "054118" },
  // { label: "Ipil", mun_code: "054118" },
  // { label: "Lahong", mun_code: "054118" },
  // { label: "Lumbia", mun_code: "054118" },
  // { label: "Magkaipit", mun_code: "054118" },
  // { label: "Minio", mun_code: "054118" },
  // { label: "Pinamoghaan", mun_code: "054118" },
  // { label: "Baybaydagat Pob. (District 1)", mun_code: "054118" },
  // { label: "Silangan Pob. (District 2)", mun_code: "054118" },
  // { label: "Magsasaka Pob. (District 3)", mun_code: "054118" },
  // { label: "Bayanihan Pob. (District 4)", mun_code: "054118" },
  // { label: "Progreso", mun_code: "054118" },
  // { label: "Resurreccion", mun_code: "054118" },
  // { label: "Salvacion", mun_code: "054118" },
  // { label: "Sowa", mun_code: "054118" },
  // { label: "Talisay", mun_code: "054118" },
  // { label: "Valparaiso", mun_code: "054118" },
  // { label: "Bagacay", mun_code: "054119" },
  // { label: "Bagahanglad", mun_code: "054119" },
  // { label: "Bartolabac", mun_code: "054119" },
  // { label: "Burgos", mun_code: "054119" },
  // { label: "Calipat-An", mun_code: "054119" },
  // { label: "Danao", mun_code: "054119" },
  // { label: "Dorong-an Daplian", mun_code: "054119" },
  // { label: "Interior", mun_code: "054119" },
  // { label: "Jagna-an", mun_code: "054119" },
  // { label: "Luna", mun_code: "054119" },
  // { label: "Mabini", mun_code: "054119" },
  // { label: "District I (Pob.)", mun_code: "054119" },
  // { label: "District II (Pob.)", mun_code: "054119" },
  // { label: "District III (Pob.)", mun_code: "054119" },
  // { label: "District IV (Pob.)", mun_code: "054119" },
  // { label: "Roosevelt", mun_code: "054119" },
  // { label: "San Isidro", mun_code: "054119" },
  // { label: "Santa Rosa", mun_code: "054119" },
  // { label: "Washington", mun_code: "054119" },
  // { label: "Boca Chica", mun_code: "054120" },
  // { label: "Bolod (Pob.)", mun_code: "054120" },
  // { label: "Busing", mun_code: "054120" },
  // { label: "Dangcalan", mun_code: "054120" },
  // { label: "Halabangbaybay", mun_code: "054120" },
  // { label: "Iniwaran", mun_code: "054120" },
  // { label: "Ki-Buaya (Rizal)", mun_code: "054120" },
  // { label: "Ki-Romero (Roxas)", mun_code: "054120" },
  // { label: "Laurente", mun_code: "054120" },
  // { label: "Mabini", mun_code: "054120" },
  // { label: "Mabuhay", mun_code: "054120" },
  // { label: "Mapanique", mun_code: "054120" },
  // { label: "Nazareno", mun_code: "054120" },
  // { label: "Pinamasingan", mun_code: "054120" },
  // { label: "Quintina", mun_code: "054120" },
  // { label: "San Jose", mun_code: "054120" },
  // { label: "San Pedro", mun_code: "054120" },
  // { label: "San Rafael", mun_code: "054120" },
  // { label: "Santa Cruz", mun_code: "054120" },
  // { label: "Terraplin (Pob.)", mun_code: "054120" },
  // { label: "Cueva", mun_code: "054120" },
  // { label: "Malaking Ilog", mun_code: "054120" },
  // { label: "Arado", mun_code: "054121" },
  // { label: "Armenia", mun_code: "054121" },
  // { label: "Aurora", mun_code: "054121" },
  // { label: "Badling", mun_code: "054121" },
  // { label: "Bonifacio", mun_code: "054121" },
  // { label: "Buenasuerte", mun_code: "054121" },
  // { label: "Buenavista", mun_code: "054121" },
  // { label: "Campana", mun_code: "054121" },
  // { label: "Candelaria", mun_code: "054121" },
  // { label: "Centro", mun_code: "054121" },
  // { label: "Crossing", mun_code: "054121" },
  // { label: "Dapdap", mun_code: "054121" },
  // { label: "Del Carmen", mun_code: "054121" },
  // { label: "Del Rosario", mun_code: "054121" },
  // { label: "Libertad", mun_code: "054121" },
  // { label: "Madao", mun_code: "054121" },
  // { label: "Mabini", mun_code: "054121" },
  // { label: "Magsaysay", mun_code: "054121" },
  // { label: "Marcella", mun_code: "054121" },
  // { label: "Miaga", mun_code: "054121" },
  // { label: "Mongahay", mun_code: "054121" },
  // { label: "Morocborocan", mun_code: "054121" },
  // { label: "Mabuhay", mun_code: "054121" },
  // { label: "Paguihaman", mun_code: "054121" },
  // { label: "Panicijan", mun_code: "054121" },
  // { label: "Poblacion", mun_code: "054121" },
  // { label: "Quezon", mun_code: "054121" },
  // { label: "San Isidro", mun_code: "054121" },
  // { label: "San Jose", mun_code: "054121" },
  // { label: "San Mateo", mun_code: "054121" },
  // { label: "San Ramon", mun_code: "054121" },
  // { label: "San Vicente", mun_code: "054121" },
  // { label: "Santo Cristo", mun_code: "054121" },
  // { label: "Sawang", mun_code: "054121" },
  // { label: "Simawa", mun_code: "054121" },
  // { label: "Alegria", mun_code: "056202" },
  // { label: "Bagacay", mun_code: "056202" },
  // { label: "Bangate", mun_code: "056202" },
  // { label: "Bugtong", mun_code: "056202" },
  // { label: "Cagang", mun_code: "056202" },
  // { label: "Fabrica", mun_code: "056202" },
  // { label: "Jibong", mun_code: "056202" },
  // { label: "Lago", mun_code: "056202" },
  // { label: "Layog", mun_code: "056202" },
  // { label: "Luneta", mun_code: "056202" },
  // { label: "Macabari", mun_code: "056202" },
  // { label: "Mapapac", mun_code: "056202" },
  // { label: "Olandia", mun_code: "056202" },
  // { label: "Paghaluban", mun_code: "056202" },
  // { label: "Poblacion Central", mun_code: "056202" },
  // { label: "Poblacion Norte", mun_code: "056202" },
  // { label: "Poblacion Sur", mun_code: "056202" },
  // { label: "Putiao", mun_code: "056202" },
  // { label: "San Antonio", mun_code: "056202" },
  // { label: "San Isidro", mun_code: "056202" },
  // { label: "San Ramon", mun_code: "056202" },
  // { label: "San Vicente", mun_code: "056202" },
  // { label: "Santa Cruz", mun_code: "056202" },
  // { label: "Santa Lourdes", mun_code: "056202" },
  // { label: "Tagdon", mun_code: "056202" },
  // { label: "A. Bonifacio", mun_code: "056203" },
  // { label: "Abad Santos", mun_code: "056203" },
  // { label: "Aguinaldo", mun_code: "056203" },
  // { label: "Antipolo", mun_code: "056203" },
  // { label: "Zone I Pob. (Bgy. 1- South Ilawod)", mun_code: "056203" },
  // { label: "Zone II Pob. (Bgy. 2- West Ilawod)", mun_code: "056203" },
  // { label: "Zone III Pob. (Bgy. 3- East Ilawod)", mun_code: "056203" },
  // { label: "Zone IV Pob. (Bgy. 4- West Central)", mun_code: "056203" },
  // { label: "Zone V Pob. (Bgy. 5-Lanipan)", mun_code: "056203" },
  // { label: "Zone VI Pob. (Bgy. 6- Baybay)", mun_code: "056203" },
  // { label: "Bical", mun_code: "056203" },
  // { label: "Beguin", mun_code: "056203" },
  // { label: "Bonga", mun_code: "056203" },
  // { label: "Butag", mun_code: "056203" },
  // { label: "Cadandanan", mun_code: "056203" },
  // { label: "Calomagon", mun_code: "056203" },
  // { label: "Calpi", mun_code: "056203" },
  // { label: "Cocok-Cabitan", mun_code: "056203" },
  // { label: "Daganas", mun_code: "056203" },
  // { label: "Danao", mun_code: "056203" },
  // { label: "Dolos", mun_code: "056203" },
  // { label: "E. Quirino", mun_code: "056203" },
  // { label: "Fabrica", mun_code: "056203" },
  // { label: "Gate", mun_code: "056203" },
  // { label: "Benigno S. Aquino (Imelda)", mun_code: "056203" },
  // { label: "Inararan", mun_code: "056203" },
  // { label: "J. Gerona", mun_code: "056203" },
  // { label: "Jamorawon", mun_code: "056203" },
  // { label: "Libertad", mun_code: "056203" },
  // { label: "Lajong", mun_code: "056203" },
  // { label: "Magsaysay", mun_code: "056203" },
  // { label: "Managanaga", mun_code: "056203" },
  // { label: "Marinab", mun_code: "056203" },
  // { label: "Nasuje", mun_code: "056203" },
  // { label: "Montecalvario", mun_code: "056203" },
  // { label: "N. Roque (Rizal)", mun_code: "056203" },
  // { label: "Namo", mun_code: "056203" },
  // { label: "Obrero", mun_code: "056203" },
  // { label: "Otavi", mun_code: "056203" },
  // { label: "Padre Diaz", mun_code: "056203" },
  // { label: "Palale", mun_code: "056203" },
  // { label: "J.P. Laurel (Pon-od)", mun_code: "056203" },
  // { label: "Quezon", mun_code: "056203" },
  // { label: "R. Gerona", mun_code: "056203" },
  // { label: "Recto", mun_code: "056203" },
  // { label: "M. Roxas", mun_code: "056203" },
  // { label: "Sagrada", mun_code: "056203" },
  // { label: "San Francisco", mun_code: "056203" },
  // { label: "San Isidro", mun_code: "056203" },
  // { label: "San Juan Bag-o", mun_code: "056203" },
  // { label: "San Juan Daan", mun_code: "056203" },
  // { label: "San Rafael", mun_code: "056203" },
  // { label: "San Ramon", mun_code: "056203" },
  // { label: "San Vicente", mun_code: "056203" },
  // { label: "Santa Remedios", mun_code: "056203" },
  // { label: "Santa Teresita", mun_code: "056203" },
  // { label: "Sigad", mun_code: "056203" },
  // { label: "Somagongsong", mun_code: "056203" },
  // { label: "G. Del Pilar (Tanga)", mun_code: "056203" },
  // { label: "Taromata", mun_code: "056203" },
  // { label: "Zone VII Pob. (Bgy. 7- Iraya)", mun_code: "056203" },
  // { label: "Zone VIII Pob. (Bgy. 8- Loyo)", mun_code: "056203" },
  // { label: "Bagacay", mun_code: "056204" },
  // { label: "Central (Pob.)", mun_code: "056204" },
  // { label: "Cogon", mun_code: "056204" },
  // { label: "Dancalan", mun_code: "056204" },
  // { label: "Dapdap (Pob.)", mun_code: "056204" },
  // { label: "Lalud", mun_code: "056204" },
  // { label: "Looban (Pob.)", mun_code: "056204" },
  // { label: "Mabuhay (Pob.)", mun_code: "056204" },
  // { label: "Madlawon (Pob.)", mun_code: "056204" },
  // { label: "Poctol (Pob.)", mun_code: "056204" },
  // { label: "Porog", mun_code: "056204" },
  // { label: "Sabang (Pob.)", mun_code: "056204" },
  // { label: "Salvacion", mun_code: "056204" },
  // { label: "San Antonio", mun_code: "056204" },
  // { label: "San Bernardo", mun_code: "056204" },
  // { label: "San Francisco", mun_code: "056204" },
  // { label: "San Isidro", mun_code: "056204" },
  // { label: "San Jose", mun_code: "056204" },
  // { label: "San Rafael", mun_code: "056204" },
  // { label: "San Roque", mun_code: "056204" },
  // { label: "San Vicente (Buhang)", mun_code: "056204" },
  // { label: "Santa Barbara", mun_code: "056204" },
  // { label: "Sapngan (Pob.)", mun_code: "056204" },
  // { label: "Tinampo", mun_code: "056204" },
  // { label: "Adovis (Pob.)", mun_code: "056205" },
  // { label: "Boton", mun_code: "056205" },
  // { label: "Burgos", mun_code: "056205" },
  // { label: "Casay", mun_code: "056205" },
  // { label: "Cawit", mun_code: "056205" },
  // { label: "Central (Pob.)", mun_code: "056205" },
  // { label: "Colambis", mun_code: "056205" },
  // { label: "Escuala", mun_code: "056205" },
  // { label: "Cogon", mun_code: "056205" },
  // { label: "Inlagadian", mun_code: "056205" },
  // { label: "Lungib", mun_code: "056205" },
  // { label: "Mabini", mun_code: "056205" },
  // { label: "Ponong", mun_code: "056205" },
  // { label: "Rizal", mun_code: "056205" },
  // { label: "San Antonio (Boho)", mun_code: "056205" },
  // { label: "San Isidro", mun_code: "056205" },
  // { label: "San Juan", mun_code: "056205" },
  // { label: "San Pascual", mun_code: "056205" },
  // { label: "Santa Cruz", mun_code: "056205" },
  // { label: "Somal-ot (Pob.)", mun_code: "056205" },
  // { label: "Tigbao", mun_code: "056205" },
  // { label: "Timbayog (Pob.)", mun_code: "056205" },
  // { label: "Tiris", mun_code: "056205" },
  // { label: "Trece Martirez", mun_code: "056205" },
  // { label: "Tulay", mun_code: "056205" },
  // { label: "Amomonting", mun_code: "056206" },
  // { label: "Bagalayag", mun_code: "056206" },
  // { label: "Bagong Sirang", mun_code: "056206" },
  // { label: "Bonga", mun_code: "056206" },
  // { label: "Buenavista", mun_code: "056206" },
  // { label: "Burabod", mun_code: "056206" },
  // { label: "Caburacan", mun_code: "056206" },
  // { label: "Canjela", mun_code: "056206" },
  // { label: "Cogon", mun_code: "056206" },
  // { label: "Cumadcad", mun_code: "056206" },
  // { label: "Dangcalan", mun_code: "056206" },
  // { label: "Dinapa", mun_code: "056206" },
  // { label: "La Union", mun_code: "056206" },
  // { label: "Libtong", mun_code: "056206" },
  // { label: "Loreto", mun_code: "056206" },
  // { label: "Macalaya", mun_code: "056206" },
  // { label: "Maracabac", mun_code: "056206" },
  // { label: "Mayon", mun_code: "056206" },
  // { label: "Milagrosa", mun_code: "056206" },
  // { label: "Miluya", mun_code: "056206" },
  // { label: "Maypangi", mun_code: "056206" },
  // { label: "Monte Carmelo", mun_code: "056206" },
  // { label: "Oras", mun_code: "056206" },
  // { label: "Pandan", mun_code: "056206" },
  // { label: "Poblacion", mun_code: "056206" },
  // { label: "Quirapi", mun_code: "056206" },
  // { label: "Saclayan", mun_code: "056206" },
  // { label: "Salvacion", mun_code: "056206" },
  // { label: "San Isidro", mun_code: "056206" },
  // { label: "San Rafael", mun_code: "056206" },
  // { label: "San Roque", mun_code: "056206" },
  // { label: "San Vicente", mun_code: "056206" },
  // { label: "Sogoy", mun_code: "056206" },
  // { label: "Tomalaytay", mun_code: "056206" },
  // { label: "Alin", mun_code: "056207" },
  // { label: "Awai", mun_code: "056207" },
  // { label: "Banban", mun_code: "056207" },
  // { label: "Bandi", mun_code: "056207" },
  // { label: "Banuang Gurang", mun_code: "056207" },
  // { label: "Baras", mun_code: "056207" },
  // { label: "Bayawas", mun_code: "056207" },
  // { label: "Bororan Barangay 1 (Pob.)", mun_code: "056207" },
  // { label: "Cabugao", mun_code: "056207" },
  // { label: "Central Barangay 2 (Pob.)", mun_code: "056207" },
  // { label: "Cristo", mun_code: "056207" },
  // { label: "Dancalan", mun_code: "056207" },
  // { label: "De Vera", mun_code: "056207" },
  // { label: "Gimagaan", mun_code: "056207" },
  // { label: "Girawan", mun_code: "056207" },
  // { label: "Gogon", mun_code: "056207" },
  // { label: "Gura", mun_code: "056207" },
  // { label: "Juan Adre", mun_code: "056207" },
  // { label: "Lourdes", mun_code: "056207" },
  // { label: "Mabini", mun_code: "056207" },
  // { label: "Malapoc", mun_code: "056207" },
  // { label: "Malinao", mun_code: "056207" },
  // { label: "Market Site Barangay 3 (Pob.)", mun_code: "056207" },
  // { label: "New Maguisa", mun_code: "056207" },
  // { label: "Ogod (Crossing)", mun_code: "056207" },
  // { label: "Old Maguisa", mun_code: "056207" },
  // { label: "Orange", mun_code: "056207" },
  // { label: "Pangpang", mun_code: "056207" },
  // { label: "Parina", mun_code: "056207" },
  // { label: "Pawala", mun_code: "056207" },
  // { label: "Pinamanaan", mun_code: "056207" },
  // { label: "Poso Pob. (Barangay 5)", mun_code: "056207" },
  // { label: "Punta Waling-Waling Pob. (Barangay 4)", mun_code: "056207" },
  // { label: "Rawis", mun_code: "056207" },
  // { label: "San Antonio", mun_code: "056207" },
  // { label: "San Isidro", mun_code: "056207" },
  // { label: "San Jose", mun_code: "056207" },
  // { label: "San Rafael", mun_code: "056207" },
  // { label: "San Ramon", mun_code: "056207" },
  // { label: "San Vicente", mun_code: "056207" },
  // { label: "Santa Cruz", mun_code: "056207" },
  // { label: "Sevilla", mun_code: "056207" },
  // { label: "Sibago", mun_code: "056207" },
  // { label: "Suguian", mun_code: "056207" },
  // { label: "Tagbac", mun_code: "056207" },
  // { label: "Tinanogan", mun_code: "056207" },
  // { label: "Tongdol", mun_code: "056207" },
  // { label: "Tres Marias", mun_code: "056207" },
  // { label: "Tuba", mun_code: "056207" },
  // { label: "Tupas", mun_code: "056207" },
  // { label: "Vinisitahan", mun_code: "056207" },
  // { label: "Ariman", mun_code: "056208" },
  // { label: "Bagacay", mun_code: "056208" },
  // { label: "Balud Del Norte (Pob.)", mun_code: "056208" },
  // { label: "Balud Del Sur (Pob.)", mun_code: "056208" },
  // { label: "Benguet", mun_code: "056208" },
  // { label: "Bentuco", mun_code: "056208" },
  // { label: "Beriran", mun_code: "056208" },
  // { label: "Buenavista", mun_code: "056208" },
  // { label: "Bulacao", mun_code: "056208" },
  // { label: "Cabigaan", mun_code: "056208" },
  // { label: "Cabiguhan", mun_code: "056208" },
  // { label: "Carriedo", mun_code: "056208" },
  // { label: "Casili", mun_code: "056208" },
  // { label: "Cogon", mun_code: "056208" },
  // { label: "Cota Na Daco (Pob.)", mun_code: "056208" },
  // { label: "Dita", mun_code: "056208" },
  // { label: "Jupi", mun_code: "056208" },
  // { label: "Lapinig", mun_code: "056208" },
  // { label: "Luna-Candol (Pob.)", mun_code: "056208" },
  // { label: "Manapao", mun_code: "056208" },
  // { label: "Manook (Pob.)", mun_code: "056208" },
  // { label: "Naagtan", mun_code: "056208" },
  // { label: "Nato", mun_code: "056208" },
  // { label: "Nazareno", mun_code: "056208" },
  // { label: "Ogao", mun_code: "056208" },
  // { label: "Paco", mun_code: "056208" },
  // { label: "Panganiban (Pob.)", mun_code: "056208" },
  // { label: "Paradijon (Pob.)", mun_code: "056208" },
  // { label: "Patag", mun_code: "056208" },
  // { label: "Payawin", mun_code: "056208" },
  // { label: "Pinontingan (Pob.)", mun_code: "056208" },
  // { label: "Rizal", mun_code: "056208" },
  // { label: "San Ignacio", mun_code: "056208" },
  // { label: "Sangat", mun_code: "056208" },
  // { label: "Santa Ana", mun_code: "056208" },
  // { label: "Tabi", mun_code: "056208" },
  // { label: "Tagaytay", mun_code: "056208" },
  // { label: "Tigkiw", mun_code: "056208" },
  // { label: "Tiris", mun_code: "056208" },
  // { label: "Togawe", mun_code: "056208" },
  // { label: "Union", mun_code: "056208" },
  // { label: "Villareal", mun_code: "056208" },
  // { label: "Bagsangan", mun_code: "056209" },
  // { label: "Bacolod (Pob.)", mun_code: "056209" },
  // { label: "Batang", mun_code: "056209" },
  // { label: "Bolos", mun_code: "056209" },
  // { label: "Buenavista", mun_code: "056209" },
  // { label: "Bulawan", mun_code: "056209" },
  // { label: "Carriedo", mun_code: "056209" },
  // { label: "Casini", mun_code: "056209" },
  // { label: "Cawayan", mun_code: "056209" },
  // { label: "Cogon", mun_code: "056209" },
  // { label: "Gabao", mun_code: "056209" },
  // { label: "Gulang-Gulang", mun_code: "056209" },
  // { label: "Gumapia", mun_code: "056209" },
  // { label: "Santo Domingo (Lamboon)", mun_code: "056209" },
  // { label: "Liang", mun_code: "056209" },
  // { label: "Macawayan", mun_code: "056209" },
  // { label: "Mapaso", mun_code: "056209" },
  // { label: "Monbon", mun_code: "056209" },
  // { label: "Patag", mun_code: "056209" },
  // { label: "Salvacion", mun_code: "056209" },
  // { label: "San Agustin (Pob.)", mun_code: "056209" },
  // { label: "San Isidro", mun_code: "056209" },
  // { label: "San Juan (Pob.)", mun_code: "056209" },
  // { label: "San Julian (Pob.)", mun_code: "056209" },
  // { label: "San Pedro", mun_code: "056209" },
  // { label: "Tabon-Tabon", mun_code: "056209" },
  // { label: "Tinampo", mun_code: "056209" },
  // { label: "Tongdol", mun_code: "056209" },
  // { label: "Anog", mun_code: "056210" },
  // { label: "Aroroy", mun_code: "056210" },
  // { label: "Bacolod", mun_code: "056210" },
  // { label: "Binanuahan", mun_code: "056210" },
  // { label: "Biriran", mun_code: "056210" },
  // { label: "Buraburan", mun_code: "056210" },
  // { label: "Calateo", mun_code: "056210" },
  // { label: "Calmayon", mun_code: "056210" },
  // { label: "Carohayon", mun_code: "056210" },
  // { label: "Catanagan", mun_code: "056210" },
  // { label: "Catanusan", mun_code: "056210" },
  // { label: "Cogon", mun_code: "056210" },
  // { label: "Embarcadero", mun_code: "056210" },
  // { label: "Guruyan", mun_code: "056210" },
  // { label: "Lajong", mun_code: "056210" },
  // { label: "Maalo", mun_code: "056210" },
  // { label: "North Poblacion", mun_code: "056210" },
  // { label: "South Poblacion", mun_code: "056210" },
  // { label: "Puting Sapa", mun_code: "056210" },
  // { label: "Rangas", mun_code: "056210" },
  // { label: "Sablayan", mun_code: "056210" },
  // { label: "Sipaya", mun_code: "056210" },
  // { label: "Taboc", mun_code: "056210" },
  // { label: "Tinago", mun_code: "056210" },
  // { label: "Tughan", mun_code: "056210" },
  // { label: "Aguada Norte", mun_code: "056211" },
  // { label: "Aguada Sur", mun_code: "056211" },
  // { label: "Anibong", mun_code: "056211" },
  // { label: "Bacalon", mun_code: "056211" },
  // { label: "Bacolod", mun_code: "056211" },
  // { label: "Banacud", mun_code: "056211" },
  // { label: "Biga", mun_code: "056211" },
  // { label: "Behia", mun_code: "056211" },
  // { label: "Binisitahan del Norte", mun_code: "056211" },
  // { label: "Binisitahan del Sur", mun_code: "056211" },
  // { label: "Biton", mun_code: "056211" },
  // { label: "Bulala", mun_code: "056211" },
  // { label: "Busay", mun_code: "056211" },
  // { label: "Caditaan", mun_code: "056211" },
  // { label: "Cagbolo", mun_code: "056211" },
  // { label: "Cagtalaba", mun_code: "056211" },
  // { label: "Cawit Extension", mun_code: "056211" },
  // { label: "Cawit Proper", mun_code: "056211" },
  // { label: "Ginangra", mun_code: "056211" },
  // { label: "Hubo", mun_code: "056211" },
  // { label: "Incarizan", mun_code: "056211" },
  // { label: "Lapinig", mun_code: "056211" },
  // { label: "Magsaysay", mun_code: "056211" },
  // { label: "Malbog", mun_code: "056211" },
  // { label: "Pantalan", mun_code: "056211" },
  // { label: "Pawik", mun_code: "056211" },
  // { label: "Pili", mun_code: "056211" },
  // { label: "Poblacion", mun_code: "056211" },
  // { label: "Salvacion", mun_code: "056211" },
  // { label: "Santa Elena", mun_code: "056211" },
  // { label: "Siuton", mun_code: "056211" },
  // { label: "Tagas", mun_code: "056211" },
  // { label: "Tulatula Norte", mun_code: "056211" },
  // { label: "Tulatula Sur", mun_code: "056211" },
  // { label: "Balocawe", mun_code: "056212" },
  // { label: "Banogao", mun_code: "056212" },
  // { label: "Banuangdaan", mun_code: "056212" },
  // { label: "Bariis", mun_code: "056212" },
  // { label: "Bolo", mun_code: "056212" },
  // { label: "Bon-Ot Big", mun_code: "056212" },
  // { label: "Bon-Ot Small", mun_code: "056212" },
  // { label: "Cabagahan", mun_code: "056212" },
  // { label: "Calayuan", mun_code: "056212" },
  // { label: "Calintaan", mun_code: "056212" },
  // { label: "Caloocan (Pob.)", mun_code: "056212" },
  // { label: "Calpi", mun_code: "056212" },
  // { label: "Camachiles (Pob.)", mun_code: "056212" },
  // { label: "Camcaman (Pob.)", mun_code: "056212" },
  // { label: "Coron-coron", mun_code: "056212" },
  // { label: "Culasi", mun_code: "056212" },
  // { label: "Gadgaron", mun_code: "056212" },
  // { label: "Genablan Occidental", mun_code: "056212" },
  // { label: "Genablan Oriental", mun_code: "056212" },
  // { label: "Hidhid", mun_code: "056212" },
  // { label: "Laboy", mun_code: "056212" },
  // { label: "Lajong", mun_code: "056212" },
  // { label: "Mambajog", mun_code: "056212" },
  // { label: "Manjunlad", mun_code: "056212" },
  // { label: "Manurabi", mun_code: "056212" },
  // { label: "Naburacan", mun_code: "056212" },
  // { label: "Paghuliran", mun_code: "056212" },
  // { label: "Pangi", mun_code: "056212" },
  // { label: "Pawa", mun_code: "056212" },
  // { label: "Poropandan", mun_code: "056212" },
  // { label: "Santa Isabel", mun_code: "056212" },
  // { label: "Sinalmacan", mun_code: "056212" },
  // { label: "Sinang-Atan", mun_code: "056212" },
  // { label: "Sinibaran", mun_code: "056212" },
  // { label: "Sisigon", mun_code: "056212" },
  // { label: "Sua", mun_code: "056212" },
  // { label: "Sulangan", mun_code: "056212" },
  // { label: "Tablac (Pob.)", mun_code: "056212" },
  // { label: "Tabunan (Pob.)", mun_code: "056212" },
  // { label: "Tugas", mun_code: "056212" },
  // { label: "Abas", mun_code: "056213" },
  // { label: "Abucay", mun_code: "056213" },
  // { label: "Bantayan", mun_code: "056213" },
  // { label: "Banuyo (Pob.)", mun_code: "056213" },
  // { label: "Bayasong", mun_code: "056213" },
  // { label: "Bayawas", mun_code: "056213" },
  // { label: "Binanuahan (Pob.)", mun_code: "056213" },
  // { label: "Cabiguan", mun_code: "056213" },
  // { label: "Cagdongon", mun_code: "056213" },
  // { label: "Calongay", mun_code: "056213" },
  // { label: "Calpi", mun_code: "056213" },
  // { label: "Catamlangan", mun_code: "056213" },
  // { label: "Comapo-capo", mun_code: "056213" },
  // { label: "Danlog", mun_code: "056213" },
  // { label: "Dao (Pob.)", mun_code: "056213" },
  // { label: "Dapdap", mun_code: "056213" },
  // { label: "Del Rosario (Bual)", mun_code: "056213" },
  // { label: "Esmerada", mun_code: "056213" },
  // { label: "Esperanza", mun_code: "056213" },
  // { label: "Guiron", mun_code: "056213" },
  // { label: "Ginablan", mun_code: "056213" },
  // { label: "Inang", mun_code: "056213" },
  // { label: "Inapugan", mun_code: "056213" },
  // { label: "Lubiano", mun_code: "056213" },
  // { label: "Leona", mun_code: "056213" },
  // { label: "Lipason", mun_code: "056213" },
  // { label: "Lourdes", mun_code: "056213" },
  // { label: "Lungib", mun_code: "056213" },
  // { label: "Lumbang", mun_code: "056213" },
  // { label: "Mabanate", mun_code: "056213" },
  // { label: "Malbog", mun_code: "056213" },
  // { label: "Marifosque (Pob.)", mun_code: "056213" },
  // { label: "Mercedes", mun_code: "056213" },
  // { label: "Migabod", mun_code: "056213" },
  // { label: "Naspi", mun_code: "056213" },
  // { label: "Palanas", mun_code: "056213" },
  // { label: "Pangpang", mun_code: "056213" },
  // { label: "Pinagsalog", mun_code: "056213" },
  // { label: "Pineda", mun_code: "056213" },
  // { label: "Poctol", mun_code: "056213" },
  // { label: "Pudo", mun_code: "056213" },
  // { label: "Putiao", mun_code: "056213" },
  // { label: "Sacnangan", mun_code: "056213" },
  // { label: "Salvacion", mun_code: "056213" },
  // { label: "San Antonio (Millabas)", mun_code: "056213" },
  // { label: "San Antonio (Sapa)", mun_code: "056213" },
  // { label: "San Jose", mun_code: "056213" },
  // { label: "San Rafael", mun_code: "056213" },
  // { label: "Santa Fe", mun_code: "056213" },
  // { label: "Brillante (Pob.)", mun_code: "056214" },
  // { label: "Bulawan", mun_code: "056214" },
  // { label: "Calao", mun_code: "056214" },
  // { label: "Carayat", mun_code: "056214" },
  // { label: "Diamante", mun_code: "056214" },
  // { label: "Gogon", mun_code: "056214" },
  // { label: "Lupi", mun_code: "056214" },
  // { label: "Manlabong", mun_code: "056214" },
  // { label: "Maningcay De Oro", mun_code: "056214" },
  // { label: "Perlas", mun_code: "056214" },
  // { label: "Quidolog", mun_code: "056214" },
  // { label: "Rizal", mun_code: "056214" },
  // { label: "San Antonio", mun_code: "056214" },
  // { label: "San Fernando", mun_code: "056214" },
  // { label: "San Isidro", mun_code: "056214" },
  // { label: "San Juan", mun_code: "056214" },
  // { label: "San Rafael", mun_code: "056214" },
  // { label: "San Ramon", mun_code: "056214" },
  // { label: "Santa Lourdes", mun_code: "056214" },
  // { label: "Santo Domingo", mun_code: "056214" },
  // { label: "Talisayan", mun_code: "056214" },
  // { label: "Tupaz", mun_code: "056214" },
  // { label: "Ulag", mun_code: "056214" },
  // { label: "La Esperanza", mun_code: "056215" },
  // { label: "Barangay Poblacion I", mun_code: "056215" },
  // { label: "Barangay Poblacion II", mun_code: "056215" },
  // { label: "Barangay Poblacion III", mun_code: "056215" },
  // { label: "Barangay Poblacion IV", mun_code: "056215" },
  // { label: "Salvacion", mun_code: "056215" },
  // { label: "San Antonio", mun_code: "056215" },
  // { label: "San Bartolome (Talaongan)", mun_code: "056215" },
  // { label: "San Eugenio", mun_code: "056215" },
  // { label: "San Isidro", mun_code: "056215" },
  // { label: "San Rafael", mun_code: "056215" },
  // { label: "San Roque", mun_code: "056215" },
  // { label: "San Sebastian", mun_code: "056215" },
  // { label: "Abuyog", mun_code: "056216" },
  // { label: "Almendras-Cogon (Pob.)", mun_code: "056216" },
  // { label: "Balogo (Sorsogon East District) ", mun_code: "056216" },
  // { label: "Barayong", mun_code: "056216" },
  // { label: "Basud", mun_code: "056216" },
  // { label: "Bibincahan", mun_code: "056216" },
  // { label: "Bitan-o/Dalipay (Pob.)", mun_code: "056216" },
  // { label: "Bucalbucalan", mun_code: "056216" },
  // { label: "Buenavista (Sorsogon West District)", mun_code: "056216" },
  // { label: "Buhatan", mun_code: "056216" },
  // { label: "Bulabog", mun_code: "056216" },
  // { label: "Burabod (Pob.)", mun_code: "056216" },
  // { label: "Cabid-An", mun_code: "056216" },
  // { label: "Cambulaga", mun_code: "056216" },
  // { label: "Capuy", mun_code: "056216" },
  // { label: "Gimaloto", mun_code: "056216" },
  // { label: "Guinlajon", mun_code: "056216" },
  // { label: "Macabog", mun_code: "056216" },
  // { label: "Marinas", mun_code: "056216" },
  // { label: "Pamurayan", mun_code: "056216" },
  // { label: "Pangpang", mun_code: "056216" },
  // { label: "Panlayaan", mun_code: "056216" },
  // { label: "Piot (Pob.)", mun_code: "056216" },
  // { label: "Polvorista (Pob.)", mun_code: "056216" },
  // { label: "Rizal", mun_code: "056216" },
  // { label: "Salog (Pob.)", mun_code: "056216" },
  // { label: "Salvacion (Sorsogon West District)", mun_code: "056216" },
  // { label: "Sampaloc (Pob.)", mun_code: "056216" },
  // { label: "San Isidro (Sorsogon West District)", mun_code: "056216" },
  // { label: "San Juan (Roro)", mun_code: "056216" },
  // { label: "Sirangan (Pob.)", mun_code: "056216" },
  // { label: "Sulucan (Pob.)", mun_code: "056216" },
  // { label: "Talisay (Pob.)", mun_code: "056216" },
  // { label: "Ticol", mun_code: "056216" },
  // { label: "Tugos", mun_code: "056216" },
  // { label: "Balete", mun_code: "056216" },
  // { label: "Balogo (Bacon District) ", mun_code: "056216" },
  // { label: "Bato", mun_code: "056216" },
  // { label: "Bon-Ot", mun_code: "056216" },
  // { label: "Buenavista (Bacon District)", mun_code: "056216" },
  // { label: "Cabarbuhan", mun_code: "056216" },
  // { label: "Caricaran", mun_code: "056216" },
  // { label: "Del Rosario", mun_code: "056216" },
  // { label: "Gatbo", mun_code: "056216" },
  // { label: "Jamislagan", mun_code: "056216" },
  // { label: "Maricrum", mun_code: "056216" },
  // { label: "Osiao", mun_code: "056216" },
  // { label: "Poblacion", mun_code: "056216" },
  // { label: "Rawis", mun_code: "056216" },
  // { label: "Salvacion (Bacon District)", mun_code: "056216" },
  // { label: "San Isidro (Bacon District)", mun_code: "056216" },
  // { label: "San Juan", mun_code: "056216" },
  // { label: "San Pascual", mun_code: "056216" },
  // { label: "San Ramon", mun_code: "056216" },
  // { label: "San Roque", mun_code: "056216" },
  // { label: "San Vicente", mun_code: "056216" },
  // { label: "Santa Cruz", mun_code: "056216" },
  // { label: "Santa Lucia", mun_code: "056216" },
  // { label: "Santo Domingo", mun_code: "056216" },
  // { label: "Sawanga", mun_code: "056216" },
  // { label: "Sugod", mun_code: "056216" },
  // { label: "Cabangila", mun_code: "060401" },
  // { label: "Cabugao", mun_code: "060401" },
  // { label: "Catmon", mun_code: "060401" },
  // { label: "Dalipdip", mun_code: "060401" },
  // { label: "Ginictan", mun_code: "060401" },
  // { label: "Linayasan", mun_code: "060401" },
  // { label: "Lumaynay", mun_code: "060401" },
  // { label: "Lupo", mun_code: "060401" },
  // { label: "Man-up", mun_code: "060401" },
  // { label: "Odiong", mun_code: "060401" },
  // { label: "Poblacion", mun_code: "060401" },
  // { label: "Quinasay-an", mun_code: "060401" },
  // { label: "Talon", mun_code: "060401" },
  // { label: "Tibiao", mun_code: "060401" },
  // { label: "Aranas", mun_code: "060402" },
  // { label: "Arcangel", mun_code: "060402" },
  // { label: "Calizo", mun_code: "060402" },
  // { label: "Cortes", mun_code: "060402" },
  // { label: "Feliciano", mun_code: "060402" },
  // { label: "Fulgencio", mun_code: "060402" },
  // { label: "Guanko", mun_code: "060402" },
  // { label: "Morales", mun_code: "060402" },
  // { label: "Oquendo", mun_code: "060402" },
  // { label: "Poblacion", mun_code: "060402" },
  // { label: "Agbanawan", mun_code: "060403" },
  // { label: "Bacan", mun_code: "060403" },
  // { label: "Badiangan", mun_code: "060403" },
  // { label: "Cerrudo", mun_code: "060403" },
  // { label: "Cupang", mun_code: "060403" },
  // { label: "Daguitan", mun_code: "060403" },
  // { label: "Daja Norte", mun_code: "060403" },
  // { label: "Daja Sur", mun_code: "060403" },
  // { label: "Dingle", mun_code: "060403" },
  // { label: "Jumarap", mun_code: "060403" },
  // { label: "Lapnag", mun_code: "060403" },
  // { label: "Libas", mun_code: "060403" },
  // { label: "Linabuan Sur", mun_code: "060403" },
  // { label: "Mambog", mun_code: "060403" },
  // { label: "Mangan", mun_code: "060403" },
  // { label: "Muguing", mun_code: "060403" },
  // { label: "Pagsanghan", mun_code: "060403" },
  // { label: "Palale", mun_code: "060403" },
  // { label: "Poblacion", mun_code: "060403" },
  // { label: "Polo", mun_code: "060403" },
  // { label: "Polocate", mun_code: "060403" },
  // { label: "San Isidro", mun_code: "060403" },
  // { label: "Sibalew", mun_code: "060403" },
  // { label: "Sigcay", mun_code: "060403" },
  // { label: "Taba-ao", mun_code: "060403" },
  // { label: "Tabayon", mun_code: "060403" },
  // { label: "Tinapuay", mun_code: "060403" },
  // { label: "Torralba", mun_code: "060403" },
  // { label: "Ugsod", mun_code: "060403" },
  // { label: "Venturanza", mun_code: "060403" },
  // { label: "Ambolong", mun_code: "060404" },
  // { label: "Angas", mun_code: "060404" },
  // { label: "Bay-ang", mun_code: "060404" },
  // { label: "Caiyang", mun_code: "060404" },
  // { label: "Cabugao", mun_code: "060404" },
  // { label: "Camaligan", mun_code: "060404" },
  // { label: "Camanci", mun_code: "060404" },
  // { label: "Ipil", mun_code: "060404" },
  // { label: "Lalab", mun_code: "060404" },
  // { label: "Lupit", mun_code: "060404" },
  // { label: "Magpag-ong", mun_code: "060404" },
  // { label: "Magubahay", mun_code: "060404" },
  // { label: "Mambuquiao", mun_code: "060404" },
  // { label: "Man-up", mun_code: "060404" },
  // { label: "Mandong", mun_code: "060404" },
  // { label: "Napti", mun_code: "060404" },
  // { label: "Palay", mun_code: "060404" },
  // { label: "Poblacion", mun_code: "060404" },
  // { label: "Songcolan", mun_code: "060404" },
  // { label: "Tabon", mun_code: "060404" },
  // { label: "Alegria", mun_code: "060405" },
  // { label: "Bagongbayan", mun_code: "060405" },
  // { label: "Balusbos", mun_code: "060405" },
  // { label: "Bel-is", mun_code: "060405" },
  // { label: "Cabugan", mun_code: "060405" },
  // { label: "El Progreso", mun_code: "060405" },
  // { label: "Habana", mun_code: "060405" },
  // { label: "Katipunan", mun_code: "060405" },
  // { label: "Mayapay", mun_code: "060405" },
  // { label: "Nazareth", mun_code: "060405" },
  // { label: "Panilongan", mun_code: "060405" },
  // { label: "Poblacion", mun_code: "060405" },
  // { label: "Santander", mun_code: "060405" },
  // { label: "Tag-osip", mun_code: "060405" },
  // { label: "Tigum", mun_code: "060405" },
  // { label: "Agbago", mun_code: "060406" },
  // { label: "Agdugayan", mun_code: "060406" },
  // { label: "Antipolo", mun_code: "060406" },
  // { label: "Aparicio", mun_code: "060406" },
  // { label: "Aquino", mun_code: "060406" },
  // { label: "Aslum", mun_code: "060406" },
  // { label: "Bagacay", mun_code: "060406" },
  // { label: "Batuan", mun_code: "060406" },
  // { label: "Buenavista", mun_code: "060406" },
  // { label: "Bugtongbato", mun_code: "060406" },
  // { label: "Cabugao", mun_code: "060406" },
  // { label: "Capilijan", mun_code: "060406" },
  // { label: "Colongcolong", mun_code: "060406" },
  // { label: "Laguinbanua", mun_code: "060406" },
  // { label: "Mabusao", mun_code: "060406" },
  // { label: "Malindog", mun_code: "060406" },
  // { label: "Maloco", mun_code: "060406" },
  // { label: "Mina-a", mun_code: "060406" },
  // { label: "Monlaque", mun_code: "060406" },
  // { label: "Naile", mun_code: "060406" },
  // { label: "Naisud", mun_code: "060406" },
  // { label: "Naligusan", mun_code: "060406" },
  // { label: "Ondoy", mun_code: "060406" },
  // { label: " ", mun_code: "060406" },
  // { label: "Polo", mun_code: "060406" },
  // { label: "Regador", mun_code: "060406" },
  // { label: "Rivera", mun_code: "060406" },
  // { label: "Rizal", mun_code: "060406" },
  // { label: "San Isidro", mun_code: "060406" },
  // { label: "San Jose", mun_code: "060406" },
  // { label: "Santa Cruz", mun_code: "060406" },
  // { label: "Tagbaya", mun_code: "060406" },
  // { label: "Tul-ang", mun_code: "060406" },
  // { label: "Unat", mun_code: "060406" },
  // { label: "Yawan", mun_code: "060406" },
  // { label: "Andagaw", mun_code: "060407" },
  // { label: "Bachaw Norte", mun_code: "060407" },
  // { label: "Bachaw Sur", mun_code: "060407" },
  // { label: "Briones", mun_code: "060407" },
  // { label: "Buswang New", mun_code: "060407" },
  // { label: "Buswang Old", mun_code: "060407" },
  // { label: "Caano", mun_code: "060407" },
  // { label: "Estancia", mun_code: "060407" },
  // { label: "Linabuan Norte", mun_code: "060407" },
  // { label: "Mabilo", mun_code: "060407" },
  // { label: "Mobo", mun_code: "060407" },
  // { label: "Nalook", mun_code: "060407" },
  // { label: "Poblacion", mun_code: "060407" },
  // { label: "Pook", mun_code: "060407" },
  // { label: "Tigayon", mun_code: "060407" },
  // { label: "Tinigaw", mun_code: "060407" },
  // { label: "Agcawilan", mun_code: "060408" },
  // { label: "Bagto", mun_code: "060408" },
  // { label: "Bugasongan", mun_code: "060408" },
  // { label: "Carugdog", mun_code: "060408" },
  // { label: "Cogon", mun_code: "060408" },
  // { label: "Ibao", mun_code: "060408" },
  // { label: "Mina", mun_code: "060408" },
  // { label: "Poblacion", mun_code: "060408" },
  // { label: "Santa Cruz", mun_code: "060408" },
  // { label: "Santa Cruz Bigaa", mun_code: "060408" },
  // { label: "Silakat-Nonok", mun_code: "060408" },
  // { label: "Tayhawan", mun_code: "060408" },
  // { label: "Agmailig", mun_code: "060409" },
  // { label: "Alfonso XII", mun_code: "060409" },
  // { label: "Batobato", mun_code: "060409" },
  // { label: "Bonza", mun_code: "060409" },
  // { label: "Calacabian", mun_code: "060409" },
  // { label: "Calamcan", mun_code: "060409" },
  // { label: "Can-Awan", mun_code: "060409" },
  // { label: "Casit-an", mun_code: "060409" },
  // { label: "Dalagsa-an", mun_code: "060409" },
  // { label: "Guadalupe", mun_code: "060409" },
  // { label: "Janlud", mun_code: "060409" },
  // { label: "Julita", mun_code: "060409" },
  // { label: "Luctoga", mun_code: "060409" },
  // { label: "Magugba", mun_code: "060409" },
  // { label: "Manika", mun_code: "060409" },
  // { label: "Ogsip", mun_code: "060409" },
  // { label: "Ortega", mun_code: "060409" },
  // { label: "Oyang", mun_code: "060409" },
  // { label: "Pampango", mun_code: "060409" },
  // { label: "Pinonoy", mun_code: "060409" },
  // { label: "Poblacion", mun_code: "060409" },
  // { label: "Rivera", mun_code: "060409" },
  // { label: "Rosal", mun_code: "060409" },
  // { label: "Sibalew", mun_code: "060409" },
  // { label: "Alaminos", mun_code: "060410" },
  // { label: "Alas-as", mun_code: "060410" },
  // { label: "Bacyang", mun_code: "060410" },
  // { label: "Balactasan", mun_code: "060410" },
  // { label: "Cabangahan", mun_code: "060410" },
  // { label: "Cabilawan", mun_code: "060410" },
  // { label: "Catabana", mun_code: "060410" },
  // { label: "Dit-Ana", mun_code: "060410" },
  // { label: "Galicia", mun_code: "060410" },
  // { label: "Guinatu-an", mun_code: "060410" },
  // { label: "Logohon", mun_code: "060410" },
  // { label: "Mamba", mun_code: "060410" },
  // { label: "Maria Cristina", mun_code: "060410" },
  // { label: "Medina", mun_code: "060410" },
  // { label: "Mercedes", mun_code: "060410" },
  // { label: "Napnot", mun_code: "060410" },
  // { label: "Pang-Itan", mun_code: "060410" },
  // { label: "Paningayan", mun_code: "060410" },
  // { label: "Panipiason", mun_code: "060410" },
  // { label: "Poblacion", mun_code: "060410" },
  // { label: "San Jose", mun_code: "060410" },
  // { label: "Singay", mun_code: "060410" },
  // { label: "Talangban", mun_code: "060410" },
  // { label: "Talimagao", mun_code: "060410" },
  // { label: "Tigbawan", mun_code: "060410" },
  // { label: "Agbalogo", mun_code: "060411" },
  // { label: "Aglucay", mun_code: "060411" },
  // { label: "Alibagon", mun_code: "060411" },
  // { label: "Bagong Barrio", mun_code: "060411" },
  // { label: "Baybay", mun_code: "060411" },
  // { label: "Cabatanga", mun_code: "060411" },
  // { label: "Cajilo", mun_code: "060411" },
  // { label: "Calangcang", mun_code: "060411" },
  // { label: "Calimbajan", mun_code: "060411" },
  // { label: "Castillo", mun_code: "060411" },
  // { label: "Cayangwan", mun_code: "060411" },
  // { label: "Dumga", mun_code: "060411" },
  // { label: "Libang", mun_code: "060411" },
  // { label: "Mantiguib", mun_code: "060411" },
  // { label: "Poblacion", mun_code: "060411" },
  // { label: "Tibiawan", mun_code: "060411" },
  // { label: "Tina", mun_code: "060411" },
  // { label: "Tugas", mun_code: "060411" },
  // { label: "Argao", mun_code: "060412" },
  // { label: "Balabag", mun_code: "060412" },
  // { label: "Balusbus", mun_code: "060412" },
  // { label: "Cabulihan", mun_code: "060412" },
  // { label: "Caticlan", mun_code: "060412" },
  // { label: "Cogon", mun_code: "060412" },
  // { label: "Cubay Norte", mun_code: "060412" },
  // { label: "Cubay Sur", mun_code: "060412" },
  // { label: "Dumlog", mun_code: "060412" },
  // { label: "Manoc-Manoc", mun_code: "060412" },
  // { label: "Naasug", mun_code: "060412" },
  // { label: "Nabaoy", mun_code: "060412" },
  // { label: "Napaan", mun_code: "060412" },
  // { label: "Poblacion", mun_code: "060412" },
  // { label: "San Viray", mun_code: "060412" },
  // { label: "Yapak", mun_code: "060412" },
  // { label: "Motag", mun_code: "060412" },
  // { label: "Banaybanay", mun_code: "060413" },
  // { label: "Biga-a", mun_code: "060413" },
  // { label: "Bulabud", mun_code: "060413" },
  // { label: "Cabayugan", mun_code: "060413" },
  // { label: "Capataga", mun_code: "060413" },
  // { label: "Cogon", mun_code: "060413" },
  // { label: "Dangcalan", mun_code: "060413" },
  // { label: "Kinalangay Nuevo", mun_code: "060413" },
  // { label: "Kinalangay Viejo", mun_code: "060413" },
  // { label: "Lilo-an", mun_code: "060413" },
  // { label: "Malandayon", mun_code: "060413" },
  // { label: "Manhanip", mun_code: "060413" },
  // { label: "Navitas", mun_code: "060413" },
  // { label: "Osman", mun_code: "060413" },
  // { label: "Poblacion", mun_code: "060413" },
  // { label: "Rosario", mun_code: "060413" },
  // { label: "San Dimas", mun_code: "060413" },
  // { label: "San Ramon", mun_code: "060413" },
  // { label: "San Roque", mun_code: "060413" },
  // { label: "Sipac", mun_code: "060413" },
  // { label: "Sugnod", mun_code: "060413" },
  // { label: "Tambuan", mun_code: "060413" },
  // { label: "Tigpalas", mun_code: "060413" },
  // { label: "Alimbo-Baybay", mun_code: "060414" },
  // { label: "Buenasuerte", mun_code: "060414" },
  // { label: "Buenafortuna", mun_code: "060414" },
  // { label: "Buenavista", mun_code: "060414" },
  // { label: "Gibon", mun_code: "060414" },
  // { label: "Habana", mun_code: "060414" },
  // { label: "Laserna", mun_code: "060414" },
  // { label: "Libertad", mun_code: "060414" },
  // { label: "Magallanes", mun_code: "060414" },
  // { label: "Matabana", mun_code: "060414" },
  // { label: "Nagustan", mun_code: "060414" },
  // { label: "Pawa", mun_code: "060414" },
  // { label: "Pinatuad", mun_code: "060414" },
  // { label: "Poblacion", mun_code: "060414" },
  // { label: "Rizal", mun_code: "060414" },
  // { label: "Solido", mun_code: "060414" },
  // { label: "Tagororoc", mun_code: "060414" },
  // { label: "Toledo", mun_code: "060414" },
  // { label: "Unidos", mun_code: "060414" },
  // { label: "Union", mun_code: "060414" },
  // { label: "Candelaria", mun_code: "060415" },
  // { label: "Cawayan", mun_code: "060415" },
  // { label: "Dumaguit", mun_code: "060415" },
  // { label: "Fatima", mun_code: "060415" },
  // { label: "Guinbaliwan", mun_code: "060415" },
  // { label: "Jalas", mun_code: "060415" },
  // { label: "Jugas", mun_code: "060415" },
  // { label: "Lawa-an", mun_code: "060415" },
  // { label: "Mabilo", mun_code: "060415" },
  // { label: "Mataphao", mun_code: "060415" },
  // { label: "Ochando", mun_code: "060415" },
  // { label: "Pinamuk-an", mun_code: "060415" },
  // { label: "Poblacion", mun_code: "060415" },
  // { label: "Polo", mun_code: "060415" },
  // { label: "Puis", mun_code: "060415" },
  // { label: "Tambak", mun_code: "060415" },
  // { label: "Albasan", mun_code: "060416" },
  // { label: "Aliputos", mun_code: "060416" },
  // { label: "Badio", mun_code: "060416" },
  // { label: "Bubog", mun_code: "060416" },
  // { label: "Bulwang", mun_code: "060416" },
  // { label: "Camanci Norte", mun_code: "060416" },
  // { label: "Camanci Sur", mun_code: "060416" },
  // { label: "Dongon East", mun_code: "060416" },
  // { label: "Dongon West", mun_code: "060416" },
  // { label: "Joyao-joyao", mun_code: "060416" },
  // { label: "Laguinbanua East", mun_code: "060416" },
  // { label: "Laguinbanua West", mun_code: "060416" },
  // { label: "Marianos", mun_code: "060416" },
  // { label: "Navitas", mun_code: "060416" },
  // { label: "Poblacion", mun_code: "060416" },
  // { label: "Pusiw", mun_code: "060416" },
  // { label: "Tabangka", mun_code: "060416" },
  // { label: "Afga", mun_code: "060417" },
  // { label: "Baybay", mun_code: "060417" },
  // { label: "Dapdap", mun_code: "060417" },
  // { label: "Dumatad", mun_code: "060417" },
  // { label: "Jawili", mun_code: "060417" },
  // { label: "Lanipga", mun_code: "060417" },
  // { label: "Napatag", mun_code: "060417" },
  // { label: "Panayakan", mun_code: "060417" },
  // { label: "Poblacion", mun_code: "060417" },
  // { label: "Pudiot", mun_code: "060417" },
  // { label: "Tagas", mun_code: "060417" },
  // { label: "Tamalagon", mun_code: "060417" },
  // { label: "Tamokoe", mun_code: "060417" },
  // { label: "Tondog", mun_code: "060417" },
  // { label: "Vivo", mun_code: "060417" },
  // { label: "Bayo Grande", mun_code: "060601" },
  // { label: "Butuan", mun_code: "060601" },
  // { label: "Casay", mun_code: "060601" },
  // { label: "Casay Viejo", mun_code: "060601" },
  // { label: "Iba", mun_code: "060601" },
  // { label: "Igbarabatuan", mun_code: "060601" },
  // { label: "Igpalge", mun_code: "060601" },
  // { label: "Igtumarom", mun_code: "060601" },
  // { label: "Lisub A", mun_code: "060601" },
  // { label: "Lisub B", mun_code: "060601" },
  // { label: "Mabuyong", mun_code: "060601" },
  // { label: "Magdalena", mun_code: "060601" },
  // { label: "Nasuli C", mun_code: "060601" },
  // { label: "Nato", mun_code: "060601" },
  // { label: "Poblacion", mun_code: "060601" },
  // { label: "Sagua", mun_code: "060601" },
  // { label: "Salvacion", mun_code: "060601" },
  // { label: "San Francisco", mun_code: "060601" },
  // { label: "San Ramon", mun_code: "060601" },
  // { label: "San Roque", mun_code: "060601" },
  // { label: "Tagaytay", mun_code: "060601" },
  // { label: "Talisayan", mun_code: "060601" },
  // { label: "Baghari", mun_code: "060602" },
  // { label: "Bahuyan", mun_code: "060602" },
  // { label: "Beri", mun_code: "060602" },
  // { label: "Biga-a", mun_code: "060602" },
  // { label: "Binangbang", mun_code: "060602" },
  // { label: "Binangbang Centro", mun_code: "060602" },
  // { label: "Binanu-an", mun_code: "060602" },
  // { label: "Cadiao", mun_code: "060602" },
  // { label: "Calapadan", mun_code: "060602" },
  // { label: "Capoyuan", mun_code: "060602" },
  // { label: "Cubay", mun_code: "060602" },
  // { label: "Esparar", mun_code: "060602" },
  // { label: "Gua", mun_code: "060602" },
  // { label: "Idao", mun_code: "060602" },
  // { label: "Igpalge", mun_code: "060602" },
  // { label: "Igtunarum", mun_code: "060602" },
  // { label: "Embrangga-an", mun_code: "060602" },
  // { label: "Integasan", mun_code: "060602" },
  // { label: "Ipil", mun_code: "060602" },
  // { label: "Jinalinan", mun_code: "060602" },
  // { label: "Lanas", mun_code: "060602" },
  // { label: "Langcaon (Evelio Javier)", mun_code: "060602" },
  // { label: "Lisub", mun_code: "060602" },
  // { label: "Lombuyan", mun_code: "060602" },
  // { label: "Mablad", mun_code: "060602" },
  // { label: "Magtulis", mun_code: "060602" },
  // { label: "Marigne", mun_code: "060602" },
  // { label: "Mayabay", mun_code: "060602" },
  // { label: "Mayos", mun_code: "060602" },
  // { label: "Nalusdan", mun_code: "060602" },
  // { label: "Narirong", mun_code: "060602" },
  // { label: "Palma", mun_code: "060602" },
  // { label: "Poblacion", mun_code: "060602" },
  // { label: "San Antonio", mun_code: "060602" },
  // { label: "San Ramon", mun_code: "060602" },
  // { label: "Soligao", mun_code: "060602" },
  // { label: "Tabongtabong", mun_code: "060602" },
  // { label: "Tig-Alaran", mun_code: "060602" },
  // { label: "Yapo", mun_code: "060602" },
  // { label: "Borocboroc", mun_code: "060603" },
  // { label: "Buenavista", mun_code: "060603" },
  // { label: "Concepcion", mun_code: "060603" },
  // { label: "Delima", mun_code: "060603" },
  // { label: "Ipil", mun_code: "060603" },
  // { label: "Maradiona", mun_code: "060603" },
  // { label: "Mojon", mun_code: "060603" },
  // { label: "Poblacion", mun_code: "060603" },
  // { label: "Rombang", mun_code: "060603" },
  // { label: "Salvacion", mun_code: "060603" },
  // { label: "Sinaja", mun_code: "060603" },
  // { label: "Anilawan", mun_code: "060604" },
  // { label: "Arangote", mun_code: "060604" },
  // { label: "Bagtason", mun_code: "060604" },
  // { label: "Camangahan", mun_code: "060604" },
  // { label: "Cubay North", mun_code: "060604" },
  // { label: "Cubay South", mun_code: "060604" },
  // { label: "Guija", mun_code: "060604" },
  // { label: "Igbalangao", mun_code: "060604" },
  // { label: "Igsoro", mun_code: "060604" },
  // { label: "Ilaures", mun_code: "060604" },
  // { label: "Jinalinan", mun_code: "060604" },
  // { label: "Lacayon", mun_code: "060604" },
  // { label: "Maray", mun_code: "060604" },
  // { label: "Paliwan", mun_code: "060604" },
  // { label: "Pangalcagan", mun_code: "060604" },
  // { label: "Centro Ilawod (Pob.)", mun_code: "060604" },
  // { label: "Centro Ilaya (Pob.)", mun_code: "060604" },
  // { label: "Centro Pojo (Pob.)", mun_code: "060604" },
  // { label: "Sabang East", mun_code: "060604" },
  // { label: "Sabang West", mun_code: "060604" },
  // { label: "Tagudtud North", mun_code: "060604" },
  // { label: "Tagudtud South", mun_code: "060604" },
  // { label: "Talisay", mun_code: "060604" },
  // { label: "Tica", mun_code: "060604" },
  // { label: "Tono-an", mun_code: "060604" },
  // { label: "Yapu", mun_code: "060604" },
  // { label: "Zaragoza", mun_code: "060604" },
  // { label: "Alegria", mun_code: "060605" },
  // { label: "Bacong", mun_code: "060605" },
  // { label: "Banago", mun_code: "060605" },
  // { label: "Bonbon", mun_code: "060605" },
  // { label: "Dawis", mun_code: "060605" },
  // { label: "Dionela", mun_code: "060605" },
  // { label: "Harigue", mun_code: "060605" },
  // { label: "Hininga-an", mun_code: "060605" },
  // { label: "Imba", mun_code: "060605" },
  // { label: "Masanag", mun_code: "060605" },
  // { label: "Poblacion", mun_code: "060605" },
  // { label: "Sabang", mun_code: "060605" },
  // { label: "Salamento", mun_code: "060605" },
  // { label: "Semirara", mun_code: "060605" },
  // { label: "Sibato", mun_code: "060605" },
  // { label: "Sibay", mun_code: "060605" },
  // { label: "Sibolo", mun_code: "060605" },
  // { label: "Tinogboc", mun_code: "060605" },
  // { label: "Alojipan", mun_code: "060606" },
  // { label: "Bagacay", mun_code: "060606" },
  // { label: "Balac-balac", mun_code: "060606" },
  // { label: "Magsaysay (Balua)", mun_code: "060606" },
  // { label: "Batbatan Island", mun_code: "060606" },
  // { label: "Batonan Norte", mun_code: "060606" },
  // { label: "Batonan Sur", mun_code: "060606" },
  // { label: "Bita", mun_code: "060606" },
  // { label: "Bitadton Norte", mun_code: "060606" },
  // { label: "Bitadton Sur", mun_code: "060606" },
  // { label: "Buenavista", mun_code: "060606" },
  // { label: "Buhi", mun_code: "060606" },
  // { label: "Camancijan", mun_code: "060606" },
  // { label: "Caridad", mun_code: "060606" },
  // { label: "Carit-an", mun_code: "060606" },
  // { label: "Condes", mun_code: "060606" },
  // { label: "Esperanza", mun_code: "060606" },
  // { label: "Fe", mun_code: "060606" },
  // { label: "Flores", mun_code: "060606" },
  // { label: "Jalandoni", mun_code: "060606" },
  // { label: "Janlagasi", mun_code: "060606" },
  // { label: "Lamputong", mun_code: "060606" },
  // { label: "Lipata", mun_code: "060606" },
  // { label: "Malalison Island", mun_code: "060606" },
  // { label: "Maniguin", mun_code: "060606" },
  // { label: "Naba", mun_code: "060606" },
  // { label: "Osorio", mun_code: "060606" },
  // { label: "Paningayan", mun_code: "060606" },
  // { label: "Centro Poblacion", mun_code: "060606" },
  // { label: "Centro Norte (Pob.)", mun_code: "060606" },
  // { label: "Centro Sur (Pob.)", mun_code: "060606" },
  // { label: "Salde", mun_code: "060606" },
  // { label: "San Antonio", mun_code: "060606" },
  // { label: "San Gregorio", mun_code: "060606" },
  // { label: "San Juan", mun_code: "060606" },
  // { label: "San Luis", mun_code: "060606" },
  // { label: "San Pascual", mun_code: "060606" },
  // { label: "San Vicente", mun_code: "060606" },
  // { label: "Simbola", mun_code: "060606" },
  // { label: "Tigbobolo", mun_code: "060606" },
  // { label: "Tinabusan", mun_code: "060606" },
  // { label: "Tomao", mun_code: "060606" },
  // { label: "Valderama", mun_code: "060606" },
  // { label: "Abaca", mun_code: "060607" },
  // { label: "Aras-Asan", mun_code: "060607" },
  // { label: "Arobo", mun_code: "060607" },
  // { label: "Atabay", mun_code: "060607" },
  // { label: "Atiotes", mun_code: "060607" },
  // { label: "Bagumbayan", mun_code: "060607" },
  // { label: "Balloscas", mun_code: "060607" },
  // { label: "Balud", mun_code: "060607" },
  // { label: "Barasanan A", mun_code: "060607" },
  // { label: "Barasanan B", mun_code: "060607" },
  // { label: "Barasanan C", mun_code: "060607" },
  // { label: "Bariri", mun_code: "060607" },
  // { label: "Camandagan", mun_code: "060607" },
  // { label: "Cato-ogan", mun_code: "060607" },
  // { label: "Danawan", mun_code: "060607" },
  // { label: "Diclum", mun_code: "060607" },
  // { label: "Fatima", mun_code: "060607" },
  // { label: "Gamad (Igdamacio)", mun_code: "060607" },
  // { label: "Igbalogo", mun_code: "060607" },
  // { label: "Igbangcal-A", mun_code: "060607" },
  // { label: "Igbangcal-B", mun_code: "060607" },
  // { label: "Igbangcal-C", mun_code: "060607" },
  // { label: "Igcabuad", mun_code: "060607" },
  // { label: "Igcado", mun_code: "060607" },
  // { label: "Igcalawagan", mun_code: "060607" },
  // { label: "Igcapuyas", mun_code: "060607" },
  // { label: "Igcasicad (San Pedro)", mun_code: "060607" },
  // { label: "Igdalaguit", mun_code: "060607" },
  // { label: "Igdanlog", mun_code: "060607" },
  // { label: "Igdurarog", mun_code: "060607" },
  // { label: "Igtugas", mun_code: "060607" },
  // { label: "Lawigan", mun_code: "060607" },
  // { label: "Manaling (Cata-an)", mun_code: "060607" },
  // { label: "Masayo", mun_code: "060607" },
  // { label: "Nagsubuan", mun_code: "060607" },
  // { label: "Paciencia", mun_code: "060607" },
  // { label: "Poblacion Norte", mun_code: "060607" },
  // { label: "Poblacion Sur", mun_code: "060607" },
  // { label: "Portillo", mun_code: "060607" },
  // { label: "Quezon", mun_code: "060607" },
  // { label: "Opsan (San Jose)", mun_code: "060607" },
  // { label: "Nasuli-A (San Ramon)", mun_code: "060607" },
  // { label: "Salamague (Santa Maria)", mun_code: "060607" },
  // { label: "Santo Tomas", mun_code: "060607" },
  // { label: "Tacbuyan", mun_code: "060607" },
  // { label: "Tene", mun_code: "060607" },
  // { label: "Villaflor", mun_code: "060607" },
  // { label: "Ysulat", mun_code: "060607" },
  // { label: "Igcadac", mun_code: "060607" },
  // { label: "Lindero", mun_code: "060607" },
  // { label: "Apdo", mun_code: "060608" },
  // { label: "Asluman", mun_code: "060608" },
  // { label: "Banawon", mun_code: "060608" },
  // { label: "Bia-an", mun_code: "060608" },
  // { label: "Bongbongan I-II", mun_code: "060608" },
  // { label: "Bongbongan III", mun_code: "060608" },
  // { label: "Botbot", mun_code: "060608" },
  // { label: "Budbudan", mun_code: "060608" },
  // { label: "Buhang", mun_code: "060608" },
  // { label: "Calacja I", mun_code: "060608" },
  // { label: "Calacja II", mun_code: "060608" },
  // { label: "Calala", mun_code: "060608" },
  // { label: "Cantulan", mun_code: "060608" },
  // { label: "Caridad", mun_code: "060608" },
  // { label: "Caromangay", mun_code: "060608" },
  // { label: "Casalngan", mun_code: "060608" },
  // { label: "Dangcalan", mun_code: "060608" },
  // { label: "Del Pilar", mun_code: "060608" },
  // { label: "Fabrica", mun_code: "060608" },
  // { label: "Funda", mun_code: "060608" },
  // { label: "General Fullon (Tina)", mun_code: "060608" },
  // { label: "Guintas", mun_code: "060608" },
  // { label: "Igbical", mun_code: "060608" },
  // { label: "Igbucagay", mun_code: "060608" },
  // { label: "Inabasan", mun_code: "060608" },
  // { label: "Ingwan-Batangan", mun_code: "060608" },
  // { label: "La Paz", mun_code: "060608" },
  // { label: "Gov. Evelio B. Javier (Lanag)", mun_code: "060608" },
  // { label: "Linaban", mun_code: "060608" },
  // { label: "Malandog", mun_code: "060608" },
  // { label: "Mapatag", mun_code: "060608" },
  // { label: "Masanag", mun_code: "060608" },
  // { label: "Nalihawan", mun_code: "060608" },
  // { label: "Pamandayan (Botbot)", mun_code: "060608" },
  // { label: "Pasu-Jungao", mun_code: "060608" },
  // { label: "Piape I", mun_code: "060608" },
  // { label: "Piape II", mun_code: "060608" },
  // { label: "Piape III", mun_code: "060608" },
  // { label: "Pili 1, 2, 3", mun_code: "060608" },
  // { label: "Poblacion 1", mun_code: "060608" },
  // { label: "Poblacion 2", mun_code: "060608" },
  // { label: "Poblacion 3", mun_code: "060608" },
  // { label: "Poblacion 4", mun_code: "060608" },
  // { label: "Poblacion 5", mun_code: "060608" },
  // { label: "Pu-ao", mun_code: "060608" },
  // { label: "Suloc", mun_code: "060608" },
  // { label: "Villavert-Jimenez", mun_code: "060608" },
  // { label: "Banban", mun_code: "060609" },
  // { label: "Bongbongan", mun_code: "060609" },
  // { label: "Cabariwan", mun_code: "060609" },
  // { label: "Cadajug", mun_code: "060609" },
  // { label: "Canituan", mun_code: "060609" },
  // { label: "Capnayan", mun_code: "060609" },
  // { label: "Casit-an", mun_code: "060609" },
  // { label: "Guinbanga-an", mun_code: "060609" },
  // { label: "Guiamon", mun_code: "060609" },
  // { label: "Guisijan", mun_code: "060609" },
  // { label: "Igtadiao", mun_code: "060609" },
  // { label: "Intao", mun_code: "060609" },
  // { label: "Jaguikican", mun_code: "060609" },
  // { label: "Jinalinan", mun_code: "060609" },
  // { label: "Lactudan", mun_code: "060609" },
  // { label: "Latazon", mun_code: "060609" },
  // { label: "Laua-an", mun_code: "060609" },
  // { label: "Loon", mun_code: "060609" },
  // { label: "Liberato", mun_code: "060609" },
  // { label: "Lindero", mun_code: "060609" },
  // { label: "Liya-liya", mun_code: "060609" },
  // { label: "Lugta", mun_code: "060609" },
  // { label: "Lupa-an", mun_code: "060609" },
  // { label: "Magyapo", mun_code: "060609" },
  // { label: "Maria", mun_code: "060609" },
  // { label: "Mauno", mun_code: "060609" },
  // { label: "Maybunga", mun_code: "060609" },
  // { label: "Necesito (Paniatan)", mun_code: "060609" },
  // { label: "Oloc", mun_code: "060609" },
  // { label: "Omlot", mun_code: "060609" },
  // { label: "Pandanan", mun_code: "060609" },
  // { label: "Paningayan", mun_code: "060609" },
  // { label: "Pascuala", mun_code: "060609" },
  // { label: "Poblacion (Centro)", mun_code: "060609" },
  // { label: "San Ramon", mun_code: "060609" },
  // { label: "Santiago", mun_code: "060609" },
  // { label: "Tibacan", mun_code: "060609" },
  // { label: "Tigunhao", mun_code: "060609" },
  // { label: "Virginia", mun_code: "060609" },
  // { label: "Bagongbayan", mun_code: "060609" },
  // { label: "Barusbus", mun_code: "060610" },
  // { label: "Bulanao", mun_code: "060610" },
  // { label: "Cubay", mun_code: "060610" },
  // { label: "Codiong", mun_code: "060610" },
  // { label: "Igcagay", mun_code: "060610" },
  // { label: "Inyawan", mun_code: "060610" },
  // { label: "Lindero", mun_code: "060610" },
  // { label: "Maramig", mun_code: "060610" },
  // { label: "Pucio", mun_code: "060610" },
  // { label: "Pajo", mun_code: "060610" },
  // { label: "Panangkilon", mun_code: "060610" },
  // { label: "Paz", mun_code: "060610" },
  // { label: "Centro Este (Pob.)", mun_code: "060610" },
  // { label: "Centro Weste (Pob.)", mun_code: "060610" },
  // { label: "San Roque", mun_code: "060610" },
  // { label: "Tinigbas", mun_code: "060610" },
  // { label: "Tinindugan", mun_code: "060610" },
  // { label: "Taboc", mun_code: "060610" },
  // { label: "Union", mun_code: "060610" },
  // { label: "Aracay", mun_code: "060611" },
  // { label: "Badiangan", mun_code: "060611" },
  // { label: "Bagumbayan", mun_code: "060611" },
  // { label: "Baybay", mun_code: "060611" },
  // { label: "Botbot", mun_code: "060611" },
  // { label: "Buang", mun_code: "060611" },
  // { label: "Cabugao", mun_code: "060611" },
  // { label: "Candari", mun_code: "060611" },
  // { label: "Carmen", mun_code: "060611" },
  // { label: "Centro Sur (Pob.)", mun_code: "060611" },
  // { label: "Dionela", mun_code: "060611" },
  // { label: "Dumrog", mun_code: "060611" },
  // { label: "Duyong", mun_code: "060611" },
  // { label: "Fragante", mun_code: "060611" },
  // { label: "Guia", mun_code: "060611" },
  // { label: "Idiacacan", mun_code: "060611" },
  // { label: "Jinalinan", mun_code: "060611" },
  // { label: "Luhod-Bayang", mun_code: "060611" },
  // { label: "Maadios", mun_code: "060611" },
  // { label: "Mag-aba", mun_code: "060611" },
  // { label: "Napuid", mun_code: "060611" },
  // { label: "Nauring", mun_code: "060611" },
  // { label: "Patria", mun_code: "060611" },
  // { label: "Perfecta", mun_code: "060611" },
  // { label: "Centro Norte (Pob.)", mun_code: "060611" },
  // { label: "San Andres", mun_code: "060611" },
  // { label: "San Joaquin", mun_code: "060611" },
  // { label: "Santa Ana", mun_code: "060611" },
  // { label: "Santa Cruz", mun_code: "060611" },
  // { label: "Santa Fe", mun_code: "060611" },
  // { label: "Santo Rosario", mun_code: "060611" },
  // { label: "Talisay", mun_code: "060611" },
  // { label: "Tingib", mun_code: "060611" },
  // { label: "Zaldivar", mun_code: "060611" },
  // { label: "Amparo", mun_code: "060612" },
  // { label: "Apgahan", mun_code: "060612" },
  // { label: "Aureliana", mun_code: "060612" },
  // { label: "Badiangan", mun_code: "060612" },
  // { label: "Bernaldo A. Julagting (Bitas)", mun_code: "060612" },
  // { label: "Carit-an", mun_code: "060612" },
  // { label: "Cuyapiao", mun_code: "060612" },
  // { label: "Villa Elio", mun_code: "060612" },
  // { label: "Gella", mun_code: "060612" },
  // { label: "Igbarawan", mun_code: "060612" },
  // { label: "Igbobon", mun_code: "060612" },
  // { label: "Igburi", mun_code: "060612" },
  // { label: "La Rioja", mun_code: "060612" },
  // { label: "Mabasa", mun_code: "060612" },
  // { label: "Macarina", mun_code: "060612" },
  // { label: "Magarang", mun_code: "060612" },
  // { label: "Magsaysay", mun_code: "060612" },
  // { label: "Padang", mun_code: "060612" },
  // { label: "Pandanan", mun_code: "060612" },
  // { label: "Patlabawon", mun_code: "060612" },
  // { label: "Poblacion", mun_code: "060612" },
  // { label: "Quezon", mun_code: "060612" },
  // { label: "Salaguiawan", mun_code: "060612" },
  // { label: "Samalague", mun_code: "060612" },
  // { label: "San Rafael", mun_code: "060612" },
  // { label: "Tobias Fornier", mun_code: "060612" },
  // { label: "Tamayoc", mun_code: "060612" },
  // { label: "Tigbalogo", mun_code: "060612" },
  // { label: "Villa Crespo", mun_code: "060612" },
  // { label: "Villa Cruz", mun_code: "060612" },
  // { label: "Villa Flores", mun_code: "060612" },
  // { label: "Villa Laua-an", mun_code: "060612" },
  // { label: "Villa Sal", mun_code: "060612" },
  // { label: "Villa Salomon", mun_code: "060612" },
  // { label: "Vista Alegre", mun_code: "060612" },
  // { label: "Atabay", mun_code: "060613" },
  // { label: "Badiang", mun_code: "060613" },
  // { label: "Barangay 1 (Pob.)", mun_code: "060613" },
  // { label: "Barangay 2 (Pob.)", mun_code: "060613" },
  // { label: "Barangay 3 (Pob.)", mun_code: "060613" },
  // { label: "Barangay 4 (Pob.)", mun_code: "060613" },
  // { label: "Barangay 5 (Pob.)", mun_code: "060613" },
  // { label: "Barangay 6 (Pob.)", mun_code: "060613" },
  // { label: "Barangay 7 (Pob.)", mun_code: "060613" },
  // { label: "Barangay 8 (Pob.)", mun_code: "060613" },
  // { label: "Bariri", mun_code: "060613" },
  // { label: "Bugarot (Catungan-Bugarot)", mun_code: "060613" },
  // { label: "Cansadan (Cansadan-Tubudan)", mun_code: "060613" },
  // { label: "Durog", mun_code: "060613" },
  // { label: "Funda-Dalipe", mun_code: "060613" },
  // { label: "Igbonglo", mun_code: "060613" },
  // { label: "Inabasan", mun_code: "060613" },
  // { label: "Madrangca", mun_code: "060613" },
  // { label: "Magcalon", mun_code: "060613" },
  // { label: "Malaiba", mun_code: "060613" },
  // { label: "Maybato Norte", mun_code: "060613" },
  // { label: "Maybato Sur", mun_code: "060613" },
  // { label: "Mojon", mun_code: "060613" },
  // { label: "Pantao", mun_code: "060613" },
  // { label: "San Angel", mun_code: "060613" },
  // { label: "San Fernando", mun_code: "060613" },
  // { label: "San Pedro", mun_code: "060613" },
  // { label: "Supa", mun_code: "060613" },
  // { label: "Agricula", mun_code: "060614" },
  // { label: "Alegria", mun_code: "060614" },
  // { label: "Aningalan", mun_code: "060614" },
  // { label: "Atabay", mun_code: "060614" },
  // { label: "Bagumbayan", mun_code: "060614" },
  // { label: "Baladjay", mun_code: "060614" },
  // { label: "Banbanan", mun_code: "060614" },
  // { label: "Barangbang", mun_code: "060614" },
  // { label: "Bawang", mun_code: "060614" },
  // { label: "Bugo", mun_code: "060614" },
  // { label: "Bulan-bulan", mun_code: "060614" },
  // { label: "Cabiawan", mun_code: "060614" },
  // { label: "Cabunga-an", mun_code: "060614" },
  // { label: "Cadolonan", mun_code: "060614" },
  // { label: "Poblacion (Calag-itan)", mun_code: "060614" },
  // { label: "Carawisan I", mun_code: "060614" },
  // { label: "Carawisan II", mun_code: "060614" },
  // { label: "Carmelo I", mun_code: "060614" },
  // { label: "Carmelo II", mun_code: "060614" },
  // { label: "General Fullon", mun_code: "060614" },
  // { label: "General Luna", mun_code: "060614" },
  // { label: "Orquia (Igcatumbal)", mun_code: "060614" },
  // { label: "Iguirindon", mun_code: "060614" },
  // { label: "Insubuan", mun_code: "060614" },
  // { label: "La Union", mun_code: "060614" },
  // { label: "Lapak", mun_code: "060614" },
  // { label: "Lumpatan", mun_code: "060614" },
  // { label: "Magdalena", mun_code: "060614" },
  // { label: "Maragubdub", mun_code: "060614" },
  // { label: "Nagbangi I (Amatong)", mun_code: "060614" },
  // { label: "Nagbangi II", mun_code: "060614" },
  // { label: "Nasuli", mun_code: "060614" },
  // { label: "Osorio I", mun_code: "060614" },
  // { label: "Osorio II", mun_code: "060614" },
  // { label: "Panpanan I", mun_code: "060614" },
  // { label: "Panpanan II", mun_code: "060614" },
  // { label: "Ramon Magsaysay", mun_code: "060614" },
  // { label: "Rizal", mun_code: "060614" },
  // { label: "San Rafael", mun_code: "060614" },
  // { label: "Sinundolan", mun_code: "060614" },
  // { label: "Sumaray", mun_code: "060614" },
  // { label: "Trinidad", mun_code: "060614" },
  // { label: "Tubudan", mun_code: "060614" },
  // { label: "Vilvar", mun_code: "060614" },
  // { label: "Walker", mun_code: "060614" },
  // { label: "Abiera", mun_code: "060615" },
  // { label: "Aguila", mun_code: "060615" },
  // { label: "Alegre", mun_code: "060615" },
  // { label: "Aras-Asan", mun_code: "060615" },
  // { label: "Bacalan", mun_code: "060615" },
  // { label: "Callan", mun_code: "060615" },
  // { label: "Nauhon", mun_code: "060615" },
  // { label: "P. Javier", mun_code: "060615" },
  // { label: "Poblacion", mun_code: "060615" },
  // { label: "Idio", mun_code: "060615" },
  // { label: "Alangan", mun_code: "060616" },
  // { label: "Valentin Grasparil (Bad-as)", mun_code: "060616" },
  // { label: "Bari", mun_code: "060616" },
  // { label: "Biga-a", mun_code: "060616" },
  // { label: "Bongbongan I", mun_code: "060616" },
  // { label: "Bongbongan II", mun_code: "060616" },
  // { label: "Bongsod", mun_code: "060616" },
  // { label: "Bontol", mun_code: "060616" },
  // { label: "Bugnay", mun_code: "060616" },
  // { label: "Bulalacao", mun_code: "060616" },
  // { label: "Cabanbanan", mun_code: "060616" },
  // { label: "Cabariuan", mun_code: "060616" },
  // { label: "Cabladan", mun_code: "060616" },
  // { label: "Cadoldolan", mun_code: "060616" },
  // { label: "Calo-oy", mun_code: "060616" },
  // { label: "Calog", mun_code: "060616" },
  // { label: "Catmon", mun_code: "060616" },
  // { label: "Catungan I", mun_code: "060616" },
  // { label: "Catungan II", mun_code: "060616" },
  // { label: "Catungan III", mun_code: "060616" },
  // { label: "Catungan IV", mun_code: "060616" },
  // { label: "Cubay-Sermon", mun_code: "060616" },
  // { label: "Esperanza I", mun_code: "060616" },
  // { label: "Esperanza II", mun_code: "060616" },
  // { label: "Esperanza III", mun_code: "060616" },
  // { label: "Igcococ", mun_code: "060616" },
  // { label: "Igdalaquit", mun_code: "060616" },
  // { label: "Igdagmay", mun_code: "060616" },
  // { label: "Iglanot", mun_code: "060616" },
  // { label: "Igpanolong", mun_code: "060616" },
  // { label: "Igparas", mun_code: "060616" },
  // { label: "Igsuming", mun_code: "060616" },
  // { label: "Ilabas", mun_code: "060616" },
  // { label: "Imparayan", mun_code: "060616" },
  // { label: "Inabasan", mun_code: "060616" },
  // { label: "Indag-an", mun_code: "060616" },
  // { label: "Initan", mun_code: "060616" },
  // { label: "Insarayan", mun_code: "060616" },
  // { label: "Lacaron", mun_code: "060616" },
  // { label: "Lagdo", mun_code: "060616" },
  // { label: "Lambayagan", mun_code: "060616" },
  // { label: "Luna", mun_code: "060616" },
  // { label: "Luyang", mun_code: "060616" },
  // { label: "Maasin", mun_code: "060616" },
  // { label: "Mabini", mun_code: "060616" },
  // { label: "Millamena", mun_code: "060616" },
  // { label: "Mojon", mun_code: "060616" },
  // { label: "Nagdayao", mun_code: "060616" },
  // { label: "Cubay-Napultan", mun_code: "060616" },
  // { label: "Nazareth", mun_code: "060616" },
  // { label: "Odiong", mun_code: "060616" },
  // { label: "Olaga", mun_code: "060616" },
  // { label: "Pangpang", mun_code: "060616" },
  // { label: "Panlagangan", mun_code: "060616" },
  // { label: "Pantao", mun_code: "060616" },
  // { label: "Pasong", mun_code: "060616" },
  // { label: "Pis-Anan", mun_code: "060616" },
  // { label: "District I (Pob.)", mun_code: "060616" },
  // { label: "District II (Pob.)", mun_code: "060616" },
  // { label: "District III (Pob.)", mun_code: "060616" },
  // { label: "District IV (Pob.)", mun_code: "060616" },
  // { label: "Rombang", mun_code: "060616" },
  // { label: "Salvacion", mun_code: "060616" },
  // { label: "San Juan", mun_code: "060616" },
  // { label: "Sido", mun_code: "060616" },
  // { label: "Solong", mun_code: "060616" },
  // { label: "Tabongtabong", mun_code: "060616" },
  // { label: "Tig-Ohot", mun_code: "060616" },
  // { label: "Tigbalua I", mun_code: "060616" },
  // { label: "Tordesillas", mun_code: "060616" },
  // { label: "Tulatula", mun_code: "060616" },
  // { label: "Villafont", mun_code: "060616" },
  // { label: "Villahermosa", mun_code: "060616" },
  // { label: "Villar", mun_code: "060616" },
  // { label: "Tigbalua II", mun_code: "060616" },
  // { label: "Alegre", mun_code: "060617" },
  // { label: "Amar", mun_code: "060617" },
  // { label: "Bandoja (Lupa-an)", mun_code: "060617" },
  // { label: "Castillo", mun_code: "060617" },
  // { label: "Esparagoza", mun_code: "060617" },
  // { label: "Importante", mun_code: "060617" },
  // { label: "La Paz", mun_code: "060617" },
  // { label: "Malabor", mun_code: "060617" },
  // { label: "Martinez", mun_code: "060617" },
  // { label: "Natividad", mun_code: "060617" },
  // { label: "Pitac", mun_code: "060617" },
  // { label: "Poblacion", mun_code: "060617" },
  // { label: "Salazar", mun_code: "060617" },
  // { label: "San Francisco Norte", mun_code: "060617" },
  // { label: "San Francisco Sur", mun_code: "060617" },
  // { label: "San Isidro", mun_code: "060617" },
  // { label: "Santa Ana", mun_code: "060617" },
  // { label: "Santa Justa", mun_code: "060617" },
  // { label: "Santo Rosario", mun_code: "060617" },
  // { label: "Tigbaboy", mun_code: "060617" },
  // { label: "Tuno", mun_code: "060617" },
  // { label: "Bakiang", mun_code: "060618" },
  // { label: "Binanogan", mun_code: "060618" },
  // { label: "Borocboroc", mun_code: "060618" },
  // { label: "Bugnay", mun_code: "060618" },
  // { label: "Buluangan I", mun_code: "060618" },
  // { label: "Buluangan II", mun_code: "060618" },
  // { label: "Bunsod", mun_code: "060618" },
  // { label: "Busog", mun_code: "060618" },
  // { label: "Cananghan", mun_code: "060618" },
  // { label: "Canipayan", mun_code: "060618" },
  // { label: "Cansilayan", mun_code: "060618" },
  // { label: "Culyat", mun_code: "060618" },
  // { label: "Iglinab", mun_code: "060618" },
  // { label: "Igmasandig", mun_code: "060618" },
  // { label: "Lublub", mun_code: "060618" },
  // { label: "Manlacbo", mun_code: "060618" },
  // { label: "Pandanan", mun_code: "060618" },
  // { label: "San Agustin", mun_code: "060618" },
  // { label: "Takas (Pob.)", mun_code: "060618" },
  // { label: "Tigmamale", mun_code: "060618" },
  // { label: "Ubos (Pob.)", mun_code: "060618" },
  // { label: "Alon", mun_code: "060618" },
  // { label: "Agcabugao", mun_code: "061901" },
  // { label: "Agdahon", mun_code: "061901" },
  // { label: "Agnaga", mun_code: "061901" },
  // { label: "Angub", mun_code: "061901" },
  // { label: "Balingasag", mun_code: "061901" },
  // { label: "Bito-on Ilawod", mun_code: "061901" },
  // { label: "Bito-on Ilaya", mun_code: "061901" },
  // { label: "Bun-od", mun_code: "061901" },
  // { label: "Carataya", mun_code: "061901" },
  // { label: "Lunayan", mun_code: "061901" },
  // { label: "Mahunodhunod", mun_code: "061901" },
  // { label: "Maindang", mun_code: "061901" },
  // { label: "Mainit", mun_code: "061901" },
  // { label: "Malagab-i", mun_code: "061901" },
  // { label: "Nagba", mun_code: "061901" },
  // { label: "Poblacion Ilawod", mun_code: "061901" },
  // { label: "Poblacion Ilaya", mun_code: "061901" },
  // { label: "Poblacion Takas", mun_code: "061901" },
  // { label: "Puti-an", mun_code: "061901" },
  // { label: "San Antonio", mun_code: "061901" },
  // { label: "Sinabsaban", mun_code: "061901" },
  // { label: "Mahabang Sapa", mun_code: "061901" },
  // { label: "Aganan", mun_code: "061902" },
  // { label: "Agtambi", mun_code: "061902" },
  // { label: "Agtanguay", mun_code: "061902" },
  // { label: "Balucuan", mun_code: "061902" },
  // { label: "Bita", mun_code: "061902" },
  // { label: "Centro", mun_code: "061902" },
  // { label: "Daplas", mun_code: "061902" },
  // { label: "Duyoc", mun_code: "061902" },
  // { label: "Ilas Sur", mun_code: "061902" },
  // { label: "Lacaron", mun_code: "061902" },
  // { label: "Malonoy", mun_code: "061902" },
  // { label: "Manhoy", mun_code: "061902" },
  // { label: "Mapulang Bato", mun_code: "061902" },
  // { label: "Matagnop", mun_code: "061902" },
  // { label: "Nasunogan", mun_code: "061902" },
  // { label: "Poblacion Ilawod", mun_code: "061902" },
  // { label: "Poblacion Ilaya", mun_code: "061902" },
  // { label: "Quinabcaban", mun_code: "061902" },
  // { label: "Quinayuya", mun_code: "061902" },
  // { label: "San Agustin (Ilas Norte)", mun_code: "061902" },
  // { label: "Concepcion", mun_code: "061903" },
  // { label: "Consolacion", mun_code: "061903" },
  // { label: "Dolores", mun_code: "061903" },
  // { label: "Duran", mun_code: "061903" },
  // { label: "San Agustin", mun_code: "061903" },
  // { label: "San Jose", mun_code: "061903" },
  // { label: "San Martin", mun_code: "061903" },
  // { label: "San Miguel", mun_code: "061903" },
  // { label: "San Rafael", mun_code: "061903" },
  // { label: "San Roque", mun_code: "061903" },
  // { label: "Santa Carmen", mun_code: "061903" },
  // { label: "Santa Cruz", mun_code: "061903" },
  // { label: "Santa Monica", mun_code: "061903" },
  // { label: "Santa Rita", mun_code: "061903" },
  // { label: "Santa Teresa", mun_code: "061903" },
  // { label: "Santo Angel", mun_code: "061903" },
  // { label: "Santo Rosario", mun_code: "061903" },
  // { label: "Poblacion", mun_code: "061903" },
  // { label: "Agbatuan", mun_code: "061904" },
  // { label: "Aglalana", mun_code: "061904" },
  // { label: "Aglanot", mun_code: "061904" },
  // { label: "Agsirab", mun_code: "061904" },
  // { label: "Alipasiawan", mun_code: "061904" },
  // { label: "Astorga", mun_code: "061904" },
  // { label: "Bayog", mun_code: "061904" },
  // { label: "Bungsuan", mun_code: "061904" },
  // { label: "Calapawan", mun_code: "061904" },
  // { label: "Cubi", mun_code: "061904" },
  // { label: "Dacuton", mun_code: "061904" },
  // { label: "Dangula", mun_code: "061904" },
  // { label: "Gibato", mun_code: "061904" },
  // { label: "Codingle", mun_code: "061904" },
  // { label: "Guinotos", mun_code: "061904" },
  // { label: "Jambad", mun_code: "061904" },
  // { label: "Janguslob", mun_code: "061904" },
  // { label: "Lawaan", mun_code: "061904" },
  // { label: "Malonoy", mun_code: "061904" },
  // { label: "Nagsulang", mun_code: "061904" },
  // { label: "Ongol Ilawod", mun_code: "061904" },
  // { label: "Ongol Ilaya", mun_code: "061904" },
  // { label: "Poblacion Ilawod", mun_code: "061904" },
  // { label: "Poblacion Ilaya", mun_code: "061904" },
  // { label: "Sagrada Familia", mun_code: "061904" },
  // { label: "Salcedo", mun_code: "061904" },
  // { label: "San Juan", mun_code: "061904" },
  // { label: "Sibariwan", mun_code: "061904" },
  // { label: "Tamulalod", mun_code: "061904" },
  // { label: "Taslan", mun_code: "061904" },
  // { label: "Tina", mun_code: "061904" },
  // { label: "Tinaytayan", mun_code: "061904" },
  // { label: "Traciano", mun_code: "061904" },
  // { label: "Agmalobo", mun_code: "061905" },
  // { label: "Agustin Navarra", mun_code: "061905" },
  // { label: "Balaring", mun_code: "061905" },
  // { label: "Basiao", mun_code: "061905" },
  // { label: "Cabugao", mun_code: "061905" },
  // { label: "Cudian", mun_code: "061905" },
  // { label: "Ilaya-Ivisan", mun_code: "061905" },
  // { label: "Malocloc Norte", mun_code: "061905" },
  // { label: "Malocloc Sur", mun_code: "061905" },
  // { label: "Matnog", mun_code: "061905" },
  // { label: "Mianay", mun_code: "061905" },
  // { label: "Ondoy", mun_code: "061905" },
  // { label: "Poblacion Norte", mun_code: "061905" },
  // { label: "Poblacion Sur", mun_code: "061905" },
  // { label: "Santa Cruz", mun_code: "061905" },
  // { label: "Agambulong", mun_code: "061906" },
  // { label: "Agbun-od", mun_code: "061906" },
  // { label: "Agcagay", mun_code: "061906" },
  // { label: "Aglibacao", mun_code: "061906" },
  // { label: "Agloloway", mun_code: "061906" },
  // { label: "Bayebaye", mun_code: "061906" },
  // { label: "Caridad", mun_code: "061906" },
  // { label: "Esperanza", mun_code: "061906" },
  // { label: "Fe", mun_code: "061906" },
  // { label: "Ganzon", mun_code: "061906" },
  // { label: "Guintas", mun_code: "061906" },
  // { label: "Igang", mun_code: "061906" },
  // { label: "Jaena Norte", mun_code: "061906" },
  // { label: "Jaena Sur", mun_code: "061906" },
  // { label: "Jagnaya", mun_code: "061906" },
  // { label: "Lapaz", mun_code: "061906" },
  // { label: "Linambasan", mun_code: "061906" },
  // { label: "Lucero", mun_code: "061906" },
  // { label: "Maantol", mun_code: "061906" },
  // { label: "Masgrau", mun_code: "061906" },
  // { label: "Milan", mun_code: "061906" },
  // { label: "Molet", mun_code: "061906" },
  // { label: "Pangabat", mun_code: "061906" },
  // { label: "Pangabuan", mun_code: "061906" },
  // { label: "Pasol-o", mun_code: "061906" },
  // { label: "Poblacion", mun_code: "061906" },
  // { label: "San Jose", mun_code: "061906" },
  // { label: "San Juan", mun_code: "061906" },
  // { label: "San Vicente", mun_code: "061906" },
  // { label: "Santo Rosario", mun_code: "061906" },
  // { label: "Aglimocon", mun_code: "061907" },
  // { label: "Alasaging", mun_code: "061907" },
  // { label: "Alayunan", mun_code: "061907" },
  // { label: "Balighot", mun_code: "061907" },
  // { label: "Batabat", mun_code: "061907" },
  // { label: "Bongbongan", mun_code: "061907" },
  // { label: "Cabungahan", mun_code: "061907" },
  // { label: "Canapian", mun_code: "061907" },
  // { label: "Carataya", mun_code: "061907" },
  // { label: "Duluan", mun_code: "061907" },
  // { label: "East Villaflores", mun_code: "061907" },
  // { label: "Fernandez", mun_code: "061907" },
  // { label: "Guinbi-alan", mun_code: "061907" },
  // { label: "Indayagan", mun_code: "061907" },
  // { label: "Jebaca", mun_code: "061907" },
  // { label: "Maalan", mun_code: "061907" },
  // { label: "Manayupit", mun_code: "061907" },
  // { label: "New Guia", mun_code: "061907" },
  // { label: "Quevedo (Ngalan)", mun_code: "061907" },
  // { label: "Old Guia", mun_code: "061907" },
  // { label: "Palaguian", mun_code: "061907" },
  // { label: "Parallan", mun_code: "061907" },
  // { label: "Poblacion Ilawod", mun_code: "061907" },
  // { label: "Poblacion Ilaya", mun_code: "061907" },
  // { label: "Poblacion Tabuc", mun_code: "061907" },
  // { label: "Quinabonglan", mun_code: "061907" },
  // { label: "Quinat-uyan", mun_code: "061907" },
  // { label: "Salgan", mun_code: "061907" },
  // { label: "Tapulang", mun_code: "061907" },
  // { label: "Tuburan", mun_code: "061907" },
  // { label: "West Villaflores", mun_code: "061907" },
  // { label: "Atiplo", mun_code: "061908" },
  // { label: "Balat-an", mun_code: "061908" },
  // { label: "Balit", mun_code: "061908" },
  // { label: "Batiano", mun_code: "061908" },
  // { label: "Bating", mun_code: "061908" },
  // { label: "Bato Bato", mun_code: "061908" },
  // { label: "Baye", mun_code: "061908" },
  // { label: "Bergante", mun_code: "061908" },
  // { label: "Bunga", mun_code: "061908" },
  // { label: "Bula", mun_code: "061908" },
  // { label: "Bungsi", mun_code: "061908" },
  // { label: "Burias", mun_code: "061908" },
  // { label: "Caidquid", mun_code: "061908" },
  // { label: "Cala-agus", mun_code: "061908" },
  // { label: "Libo-o", mun_code: "061908" },
  // { label: "Manibad", mun_code: "061908" },
  // { label: "Maralag", mun_code: "061908" },
  // { label: "Najus-an", mun_code: "061908" },
  // { label: "Pangpang Norte", mun_code: "061908" },
  // { label: "Pangpang Sur", mun_code: "061908" },
  // { label: "Pinay", mun_code: "061908" },
  // { label: "Poblacion Proper", mun_code: "061908" },
  // { label: "Poblacion Tabuc", mun_code: "061908" },
  // { label: "Sinondojan", mun_code: "061908" },
  // { label: "Tugas", mun_code: "061908" },
  // { label: "Tumalalud", mun_code: "061908" },
  // { label: "Agbalo", mun_code: "061909" },
  // { label: "Agbanban", mun_code: "061909" },
  // { label: "Agojo", mun_code: "061909" },
  // { label: "Anhawon", mun_code: "061909" },
  // { label: "Bagacay", mun_code: "061909" },
  // { label: "Bago Chiquito", mun_code: "061909" },
  // { label: "Bago Grande", mun_code: "061909" },
  // { label: "Bahit", mun_code: "061909" },
  // { label: "Bantique", mun_code: "061909" },
  // { label: "Bato", mun_code: "061909" },
  // { label: "Binangig", mun_code: "061909" },
  // { label: "Binantuan", mun_code: "061909" },
  // { label: "Bonga", mun_code: "061909" },
  // { label: "Buntod", mun_code: "061909" },
  // { label: "Butacal", mun_code: "061909" },
  // { label: "Cabugao Este", mun_code: "061909" },
  // { label: "Cabugao Oeste", mun_code: "061909" },
  // { label: "Calapawan", mun_code: "061909" },
  // { label: "Calitan", mun_code: "061909" },
  // { label: "Candual", mun_code: "061909" },
  // { label: "Cogon", mun_code: "061909" },
  // { label: "Daga", mun_code: "061909" },
  // { label: "Ilamnay", mun_code: "061909" },
  // { label: "Jamul-awon", mun_code: "061909" },
  // { label: "Lanipga", mun_code: "061909" },
  // { label: "Lat-Asan", mun_code: "061909" },
  // { label: "Libon", mun_code: "061909" },
  // { label: "Linao", mun_code: "061909" },
  // { label: "Linateran", mun_code: "061909" },
  // { label: "Lomboy", mun_code: "061909" },
  // { label: "Lus-Onan", mun_code: "061909" },
  // { label: "Magubilan", mun_code: "061909" },
  // { label: "Navitas", mun_code: "061909" },
  // { label: "Pawa", mun_code: "061909" },
  // { label: "Pili", mun_code: "061909" },
  // { label: "Poblacion Ilawod", mun_code: "061909" },
  // { label: "Poblacion Ilaya", mun_code: "061909" },
  // { label: "Poblacion Tabuc", mun_code: "061909" },
  // { label: "Talasa", mun_code: "061909" },
  // { label: "Tanza Norte", mun_code: "061909" },
  // { label: "Tanza Sur", mun_code: "061909" },
  // { label: "Tico", mun_code: "061909" },
  // { label: "Agbabadiang", mun_code: "061910" },
  // { label: "Agkilo", mun_code: "061910" },
  // { label: "Agloway", mun_code: "061910" },
  // { label: "Ambilay", mun_code: "061910" },
  // { label: "Bahit", mun_code: "061910" },
  // { label: "Balatucan", mun_code: "061910" },
  // { label: "Banga-an", mun_code: "061910" },
  // { label: "Cabugao", mun_code: "061910" },
  // { label: "Cabangahan", mun_code: "061910" },
  // { label: "Cadio", mun_code: "061910" },
  // { label: "Cala-an", mun_code: "061910" },
  // { label: "Capagao", mun_code: "061910" },
  // { label: "Cogon", mun_code: "061910" },
  // { label: "Conciencia", mun_code: "061910" },
  // { label: "Ensenagan", mun_code: "061910" },
  // { label: "Intampilan", mun_code: "061910" },
  // { label: "Pasugue", mun_code: "061910" },
  // { label: "Poblacion Ilawod", mun_code: "061910" },
  // { label: "Poblacion Ilaya", mun_code: "061910" },
  // { label: "Quios", mun_code: "061910" },
  // { label: "Salocon", mun_code: "061910" },
  // { label: "Tabuc Norte", mun_code: "061910" },
  // { label: "Tabuc Sur", mun_code: "061910" },
  // { label: "Timpas", mun_code: "061910" },
  // { label: "Tincupon", mun_code: "061910" },
  // { label: "Tinigban", mun_code: "061910" },
  // { label: "Balogo", mun_code: "061911" },
  // { label: "Binaobawan", mun_code: "061911" },
  // { label: "Blasco", mun_code: "061911" },
  // { label: "Casanayan", mun_code: "061911" },
  // { label: "Cayus", mun_code: "061911" },
  // { label: "Dayhagan", mun_code: "061911" },
  // { label: "Dulangan", mun_code: "061911" },
  // { label: "Monteflor", mun_code: "061911" },
  // { label: "Natividad", mun_code: "061911" },
  // { label: "Olalo", mun_code: "061911" },
  // { label: "Poblacion", mun_code: "061911" },
  // { label: "Rosario", mun_code: "061911" },
  // { label: "San Antonio", mun_code: "061911" },
  // { label: "San Blas", mun_code: "061911" },
  // { label: "San Esteban", mun_code: "061911" },
  // { label: "San Fernando", mun_code: "061911" },
  // { label: "San Nicolas", mun_code: "061911" },
  // { label: "San Pedro", mun_code: "061911" },
  // { label: "San Ramon", mun_code: "061911" },
  // { label: "San Silvestre", mun_code: "061911" },
  // { label: "Sinamongan", mun_code: "061911" },
  // { label: "Santa Fe", mun_code: "061911" },
  // { label: "Tabun-acan", mun_code: "061911" },
  // { label: "Yating", mun_code: "061911" },
  // { label: "Agbanog", mun_code: "061912" },
  // { label: "Agdalipe", mun_code: "061912" },
  // { label: "Ameligan", mun_code: "061912" },
  // { label: "Bailan", mun_code: "061912" },
  // { label: "Banate", mun_code: "061912" },
  // { label: "Bantigue", mun_code: "061912" },
  // { label: "Binuntucan", mun_code: "061912" },
  // { label: "Cabugao", mun_code: "061912" },
  // { label: "Gabuc (Caugiat)", mun_code: "061912" },
  // { label: "Guba", mun_code: "061912" },
  // { label: "Hipona", mun_code: "061912" },
  // { label: "Intungcan", mun_code: "061912" },
  // { label: "Jolongajog", mun_code: "061912" },
  // { label: "Lantangan", mun_code: "061912" },
  // { label: "Linampongan", mun_code: "061912" },
  // { label: "Malag-it", mun_code: "061912" },
  // { label: "Manapao", mun_code: "061912" },
  // { label: "Ilawod (Pob.)", mun_code: "061912" },
  // { label: "Ilaya (Pob.)", mun_code: "061912" },
  // { label: "Rizal", mun_code: "061912" },
  // { label: "San Pedro", mun_code: "061912" },
  // { label: "Solo", mun_code: "061912" },
  // { label: "Sublangon", mun_code: "061912" },
  // { label: "Tabuc", mun_code: "061912" },
  // { label: "Tacas", mun_code: "061912" },
  // { label: "Yatingan", mun_code: "061912" },
  // { label: "Aranguel", mun_code: "061913" },
  // { label: "Badiangon", mun_code: "061913" },
  // { label: "Bayuyan", mun_code: "061913" },
  // { label: "Cabugcabug", mun_code: "061913" },
  // { label: "Carmencita", mun_code: "061913" },
  // { label: "Cubay", mun_code: "061913" },
  // { label: "Culilang", mun_code: "061913" },
  // { label: "Goce", mun_code: "061913" },
  // { label: "Hanglid", mun_code: "061913" },
  // { label: "Ibaca", mun_code: "061913" },
  // { label: "Madulano", mun_code: "061913" },
  // { label: "Manoling", mun_code: "061913" },
  // { label: "Marita", mun_code: "061913" },
  // { label: "Pandan", mun_code: "061913" },
  // { label: "Pantalan Cabugcabug", mun_code: "061913" },
  // { label: "Pinamihagan", mun_code: "061913" },
  // { label: "Poblacion (Elizalde)", mun_code: "061913" },
  // { label: "Pondol", mun_code: "061913" },
  // { label: "Quiajo", mun_code: "061913" },
  // { label: "Sangkal", mun_code: "061913" },
  // { label: "Vizcaya", mun_code: "061913" },
  // { label: "Adlawan", mun_code: "061914" },
  // { label: "Bago", mun_code: "061914" },
  // { label: "Balijuagan", mun_code: "061914" },
  // { label: "Banica", mun_code: "061914" },
  // { label: "Poblacion I (Barangay I)", mun_code: "061914" },
  // { label: "Poblacion X (Barangay X)", mun_code: "061914" },
  // { label: "Poblacion XI (Barangay XI)", mun_code: "061914" },
  // { label: "Poblacion II (Barangay II)", mun_code: "061914" },
  // { label: "Poblacion III (Barangay III)", mun_code: "061914" },
  // { label: "Poblacion IV (Barangay IV)", mun_code: "061914" },
  // { label: "Poblacion V (Barangay V)", mun_code: "061914" },
  // { label: "Poblacion VI (Barangay VI)", mun_code: "061914" },
  // { label: "Poblacion VII (Barangay VII)", mun_code: "061914" },
  // { label: "Poblacion VIII (Barangay VIII)", mun_code: "061914" },
  // { label: "Poblacion IX (Barangay IX)", mun_code: "061914" },
  // { label: "Barra", mun_code: "061914" },
  // { label: "Bato", mun_code: "061914" },
  // { label: "Baybay", mun_code: "061914" },
  // { label: "Bolo", mun_code: "061914" },
  // { label: "Cabugao", mun_code: "061914" },
  // { label: "Cagay", mun_code: "061914" },
  // { label: "Cogon", mun_code: "061914" },
  // { label: "Culajao", mun_code: "061914" },
  // { label: "Culasi", mun_code: "061914" },
  // { label: "Dumolog", mun_code: "061914" },
  // { label: "Dayao", mun_code: "061914" },
  // { label: "Dinginan", mun_code: "061914" },
  // { label: "Gabu-an", mun_code: "061914" },
  // { label: "Inzo Arnaldo Village (Cadimahan)", mun_code: "061914" },
  // { label: "Jumaguicjic", mun_code: "061914" },
  // { label: "Lanot", mun_code: "061914" },
  // { label: "Lawa-an", mun_code: "061914" },
  // { label: "Liong", mun_code: "061914" },
  // { label: "Libas", mun_code: "061914" },
  // { label: "Loctugan", mun_code: "061914" },
  // { label: "Lonoy", mun_code: "061914" },
  // { label: "Milibili", mun_code: "061914" },
  // { label: "Mongpong", mun_code: "061914" },
  // { label: "Olotayan", mun_code: "061914" },
  // { label: "Punta Cogon", mun_code: "061914" },
  // { label: "Punta Tabuc", mun_code: "061914" },
  // { label: "San Jose", mun_code: "061914" },
  // { label: "Sibaguan", mun_code: "061914" },
  // { label: "Talon", mun_code: "061914" },
  // { label: "Tanque", mun_code: "061914" },
  // { label: "Tanza", mun_code: "061914" },
  // { label: "Tiza", mun_code: "061914" },
  // { label: "Agsilab", mun_code: "061915" },
  // { label: "Agtatacay Norte", mun_code: "061915" },
  // { label: "Agtatacay Sur", mun_code: "061915" },
  // { label: "Bilao", mun_code: "061915" },
  // { label: "Damayan", mun_code: "061915" },
  // { label: "Dapdapan", mun_code: "061915" },
  // { label: "Lonoy", mun_code: "061915" },
  // { label: "Majanlud", mun_code: "061915" },
  // { label: "Maninang", mun_code: "061915" },
  // { label: "Poblacion", mun_code: "061915" },
  // { label: "Acbo", mun_code: "061916" },
  // { label: "Amaga", mun_code: "061916" },
  // { label: "Balucuan", mun_code: "061916" },
  // { label: "Bangonbangon", mun_code: "061916" },
  // { label: "Capuyhan", mun_code: "061916" },
  // { label: "Cogon", mun_code: "061916" },
  // { label: "Dayhagon", mun_code: "061916" },
  // { label: "Guintas", mun_code: "061916" },
  // { label: "Malapad Cogon", mun_code: "061916" },
  // { label: "Mangoso", mun_code: "061916" },
  // { label: "Mansacul", mun_code: "061916" },
  // { label: "Matangcong", mun_code: "061916" },
  // { label: "Matinabus", mun_code: "061916" },
  // { label: "Mianay", mun_code: "061916" },
  // { label: "Oyong", mun_code: "061916" },
  // { label: "Pagbunitan", mun_code: "061916" },
  // { label: "Parian", mun_code: "061916" },
  // { label: "Pinamalatican", mun_code: "061916" },
  // { label: "Poblacion Norte", mun_code: "061916" },
  // { label: "Poblacion Sur", mun_code: "061916" },
  // { label: "Tawog", mun_code: "061916" },
  // { label: "Abangay", mun_code: "061917" },
  // { label: "Agcococ", mun_code: "061917" },
  // { label: "Aglinab", mun_code: "061917" },
  // { label: "Aglupacan", mun_code: "061917" },
  // { label: "Agpalali", mun_code: "061917" },
  // { label: "Apero", mun_code: "061917" },
  // { label: "Artuz", mun_code: "061917" },
  // { label: "Bag-Ong Barrio", mun_code: "061917" },
  // { label: "Bato-bato", mun_code: "061917" },
  // { label: "Buri", mun_code: "061917" },
  // { label: "Camburanan", mun_code: "061917" },
  // { label: "Candelaria", mun_code: "061917" },
  // { label: "Carida", mun_code: "061917" },
  // { label: "Cristina", mun_code: "061917" },
  // { label: "Da-an Banwa", mun_code: "061917" },
  // { label: "Da-an Norte", mun_code: "061917" },
  // { label: "Da-an Sur", mun_code: "061917" },
  // { label: "Garcia", mun_code: "061917" },
  // { label: "Gebio-an", mun_code: "061917" },
  // { label: "Hilwan", mun_code: "061917" },
  // { label: "Initan", mun_code: "061917" },
  // { label: "Katipunan", mun_code: "061917" },
  // { label: "Lagdungan", mun_code: "061917" },
  // { label: "Lahug", mun_code: "061917" },
  // { label: "Libertad", mun_code: "061917" },
  // { label: "Mabini", mun_code: "061917" },
  // { label: "Maliao", mun_code: "061917" },
  // { label: "Malitbog", mun_code: "061917" },
  // { label: "Minan", mun_code: "061917" },
  // { label: "Nayawan", mun_code: "061917" },
  // { label: "Poblacion", mun_code: "061917" },
  // { label: "Rizal Norte", mun_code: "061917" },
  // { label: "Rizal Sur", mun_code: "061917" },
  // { label: "Roosevelt", mun_code: "061917" },
  // { label: "Roxas", mun_code: "061917" },
  // { label: "Salong", mun_code: "061917" },
  // { label: "San Antonio", mun_code: "061917" },
  // { label: "San Francisco", mun_code: "061917" },
  // { label: "San Jose", mun_code: "061917" },
  // { label: "San Julian", mun_code: "061917" },
  // { label: "San Miguel Ilawod", mun_code: "061917" },
  // { label: "San Miguel Ilaya", mun_code: "061917" },
  // { label: "San Nicolas", mun_code: "061917" },
  // { label: "San Pedro", mun_code: "061917" },
  // { label: "San Roque", mun_code: "061917" },
  // { label: "San Vicente", mun_code: "061917" },
  // { label: "Santa Ana", mun_code: "061917" },
  // { label: "Santa Petronila", mun_code: "061917" },
  // { label: "Senonod", mun_code: "061917" },
  // { label: "Siya", mun_code: "061917" },
  // { label: "Switch", mun_code: "061917" },
  // { label: "Tabon", mun_code: "061917" },
  // { label: "Tacayan", mun_code: "061917" },
  // { label: "Taft", mun_code: "061917" },
  // { label: "Taganghin", mun_code: "061917" },
  // { label: "Taslan", mun_code: "061917" },
  // { label: "Wright", mun_code: "061917" },
  // { label: "Adcadarao", mun_code: "063001" },
  // { label: "Agbobolo", mun_code: "063001" },
  // { label: "Badiangan", mun_code: "063001" },
  // { label: "Barrido", mun_code: "063001" },
  // { label: "Bato Biasong", mun_code: "063001" },
  // { label: "Bay-ang", mun_code: "063001" },
  // { label: "Bucana Bunglas", mun_code: "063001" },
  // { label: "Central", mun_code: "063001" },
  // { label: "Culasi", mun_code: "063001" },
  // { label: "Lanjagan", mun_code: "063001" },
  // { label: "Luca", mun_code: "063001" },
  // { label: "Malayu-an", mun_code: "063001" },
  // { label: "Mangorocoro", mun_code: "063001" },
  // { label: "Nasidman", mun_code: "063001" },
  // { label: "Pantalan Nabaye", mun_code: "063001" },
  // { label: "Pantalan Navarro", mun_code: "063001" },
  // { label: "Pedada", mun_code: "063001" },
  // { label: "Pili", mun_code: "063001" },
  // { label: "Pinantan Diel", mun_code: "063001" },
  // { label: "Pinantan Elizalde", mun_code: "063001" },
  // { label: "Pinay Espinosa", mun_code: "063001" },
  // { label: "Poblacion", mun_code: "063001" },
  // { label: "Progreso", mun_code: "063001" },
  // { label: "Puente Bunglas", mun_code: "063001" },
  // { label: "Punta Buri", mun_code: "063001" },
  // { label: "Rojas", mun_code: "063001" },
  // { label: "San Antonio", mun_code: "063001" },
  // { label: "Silagon", mun_code: "063001" },
  // { label: "Santo Rosario", mun_code: "063001" },
  // { label: "Tagubanhan", mun_code: "063001" },
  // { label: "Taguhangin", mun_code: "063001" },
  // { label: "Tanduyan", mun_code: "063001" },
  // { label: "Tipacla", mun_code: "063001" },
  // { label: "Tubogan", mun_code: "063001" },
  // { label: "Abang-abang", mun_code: "063002" },
  // { label: "Agsing", mun_code: "063002" },
  // { label: "Atabay", mun_code: "063002" },
  // { label: "Ba-ong", mun_code: "063002" },
  // { label: "Baguingin-Lanot", mun_code: "063002" },
  // { label: "Bagsakan", mun_code: "063002" },
  // { label: "Bagumbayan-Ilajas", mun_code: "063002" },
  // { label: "Balabago", mun_code: "063002" },
  // { label: "Ban-ag", mun_code: "063002" },
  // { label: "Bancal", mun_code: "063002" },
  // { label: "Binalud", mun_code: "063002" },
  // { label: "Bugang", mun_code: "063002" },
  // { label: "Buhay", mun_code: "063002" },
  // { label: "Bulod", mun_code: "063002" },
  // { label: "Cabacanan Proper", mun_code: "063002" },
  // { label: "Cabacanan Rizal", mun_code: "063002" },
  // { label: "Cagay", mun_code: "063002" },
  // { label: "Coline", mun_code: "063002" },
  // { label: "Coline-Dalag", mun_code: "063002" },
  // { label: "Cunsad", mun_code: "063002" },
  // { label: "Cuyad", mun_code: "063002" },
  // { label: "Dalid", mun_code: "063002" },
  // { label: "Dao", mun_code: "063002" },
  // { label: "Gines", mun_code: "063002" },
  // { label: "Ginomoy", mun_code: "063002" },
  // { label: "Ingwan", mun_code: "063002" },
  // { label: "Laylayan", mun_code: "063002" },
  // { label: "Lico", mun_code: "063002" },
  // { label: "Luan-luan", mun_code: "063002" },
  // { label: "Malamhay", mun_code: "063002" },
  // { label: "Malamboy-Bondolan", mun_code: "063002" },
  // { label: "Mambawi", mun_code: "063002" },
  // { label: "Manasa", mun_code: "063002" },
  // { label: "Manduyog", mun_code: "063002" },
  // { label: "Pajo", mun_code: "063002" },
  // { label: "Pianda-an Norte", mun_code: "063002" },
  // { label: "Pianda-an Sur", mun_code: "063002" },
  // { label: "Punong", mun_code: "063002" },
  // { label: "Quinaspan", mun_code: "063002" },
  // { label: "Sinamay", mun_code: "063002" },
  // { label: "Sulong", mun_code: "063002" },
  // { label: "Taban-Manguining", mun_code: "063002" },
  // { label: "Tabug", mun_code: "063002" },
  // { label: "Tarug", mun_code: "063002" },
  // { label: "Tugaslon", mun_code: "063002" },
  // { label: "Ubodan", mun_code: "063002" },
  // { label: "Ugbo", mun_code: "063002" },
  // { label: "Ulay-Bugang", mun_code: "063002" },
  // { label: "Ulay-Hinablan", mun_code: "063002" },
  // { label: "Umingan", mun_code: "063002" },
  // { label: "Poblacion", mun_code: "063002" },
  // { label: "Agbatuan", mun_code: "063003" },
  // { label: "Badiang", mun_code: "063003" },
  // { label: "Balabag", mun_code: "063003" },
  // { label: "Balunos", mun_code: "063003" },
  // { label: "Cag-an", mun_code: "063003" },
  // { label: "Camiros", mun_code: "063003" },
  // { label: "Sambag Culob", mun_code: "063003" },
  // { label: "Dangula-an", mun_code: "063003" },
  // { label: "Guipis", mun_code: "063003" },
  // { label: "Manganese", mun_code: "063003" },
  // { label: "Medina", mun_code: "063003" },
  // { label: "Mostro", mun_code: "063003" },
  // { label: "Palaypay", mun_code: "063003" },
  // { label: "Pantalan", mun_code: "063003" },
  // { label: "Poblacion", mun_code: "063003" },
  // { label: "San Carlos", mun_code: "063003" },
  // { label: "San Juan Crisostomo", mun_code: "063003" },
  // { label: "Santa Rita", mun_code: "063003" },
  // { label: "Santo Rosario", mun_code: "063003" },
  // { label: "Serallo", mun_code: "063003" },
  // { label: "Vista Alegre", mun_code: "063003" },
  // { label: "Agusipan", mun_code: "063004" },
  // { label: "Astorga", mun_code: "063004" },
  // { label: "Bita-oyan", mun_code: "063004" },
  // { label: "Botong", mun_code: "063004" },
  // { label: "Budiawe", mun_code: "063004" },
  // { label: "Cabanga-an", mun_code: "063004" },
  // { label: "Cabayogan", mun_code: "063004" },
  // { label: "Calansanan", mun_code: "063004" },
  // { label: "Catubig", mun_code: "063004" },
  // { label: "Guinawahan", mun_code: "063004" },
  // { label: "Ilongbukid", mun_code: "063004" },
  // { label: "Indorohan", mun_code: "063004" },
  // { label: "Iniligan", mun_code: "063004" },
  // { label: "Latawan", mun_code: "063004" },
  // { label: "Linayuan", mun_code: "063004" },
  // { label: "Mainguit", mun_code: "063004" },
  // { label: "Malublub", mun_code: "063004" },
  // { label: "Manaolan", mun_code: "063004" },
  // { label: "Mapili Grande", mun_code: "063004" },
  // { label: "Mapili Sanjo", mun_code: "063004" },
  // { label: "Odiongan", mun_code: "063004" },
  // { label: "Poblacion (Badiangan)", mun_code: "063004" },
  // { label: "San Julian", mun_code: "063004" },
  // { label: "Sariri", mun_code: "063004" },
  // { label: "Sianon", mun_code: "063004" },
  // { label: "Sinuagan", mun_code: "063004" },
  // { label: "Talaba", mun_code: "063004" },
  // { label: "Tamocol", mun_code: "063004" },
  // { label: "Teneclan", mun_code: "063004" },
  // { label: "Tina", mun_code: "063004" },
  // { label: "Bingauan", mun_code: "063004" },
  // { label: "Aranjuez", mun_code: "063005" },
  // { label: "Bacolod", mun_code: "063005" },
  // { label: "Balanti-an", mun_code: "063005" },
  // { label: "Batuan", mun_code: "063005" },
  // { label: "Cabalic", mun_code: "063005" },
  // { label: "Camambugan", mun_code: "063005" },
  // { label: "Dolores", mun_code: "063005" },
  // { label: "Gimamanay", mun_code: "063005" },
  // { label: "Ipil", mun_code: "063005" },
  // { label: "Kinalkalan", mun_code: "063005" },
  // { label: "Lawis", mun_code: "063005" },
  // { label: "Malapoc", mun_code: "063005" },
  // { label: "Mamhut Norte", mun_code: "063005" },
  // { label: "Mamhut Sur", mun_code: "063005" },
  // { label: "Maya", mun_code: "063005" },
  // { label: "Pani-an", mun_code: "063005" },
  // { label: "Poblacion Norte", mun_code: "063005" },
  // { label: "Poblacion Sur", mun_code: "063005" },
  // { label: "Quiasan", mun_code: "063005" },
  // { label: "Salong", mun_code: "063005" },
  // { label: "Salvacion", mun_code: "063005" },
  // { label: "Tingui-an", mun_code: "063005" },
  // { label: "Zaragosa", mun_code: "063005" },
  // { label: "Alacaygan", mun_code: "063006" },
  // { label: "Bariga", mun_code: "063006" },
  // { label: "Belen", mun_code: "063006" },
  // { label: "Bobon", mun_code: "063006" },
  // { label: "Bularan", mun_code: "063006" },
  // { label: "Carmelo", mun_code: "063006" },
  // { label: "De La Paz", mun_code: "063006" },
  // { label: "Dugwakan", mun_code: "063006" },
  // { label: "Juanico", mun_code: "063006" },
  // { label: "Libertad", mun_code: "063006" },
  // { label: "Magdalo", mun_code: "063006" },
  // { label: "Managopaya", mun_code: "063006" },
  // { label: "Merced", mun_code: "063006" },
  // { label: "Poblacion", mun_code: "063006" },
  // { label: "San Salvador", mun_code: "063006" },
  // { label: "Talokgangan", mun_code: "063006" },
  // { label: "Zona Sur", mun_code: "063006" },
  // { label: "Fuentes*", mun_code: "063006" },
  // { label: "Acuit", mun_code: "063007" },
  // { label: "Agcuyawan Calsada", mun_code: "063007" },
  // { label: "Agcuyawan Pulo", mun_code: "063007" },
  // { label: "Bagongbong", mun_code: "063007" },
  // { label: "Baras", mun_code: "063007" },
  // { label: "Bungca", mun_code: "063007" },
  // { label: "Cabilauan", mun_code: "063007" },
  // { label: "Cruz", mun_code: "063007" },
  // { label: "Guintas", mun_code: "063007" },
  // { label: "Igbong", mun_code: "063007" },
  // { label: "Ilaud Poblacion", mun_code: "063007" },
  // { label: "Ilaya Poblacion", mun_code: "063007" },
  // { label: "Jalaud", mun_code: "063007" },
  // { label: "Lagubang", mun_code: "063007" },
  // { label: "Lanas", mun_code: "063007" },
  // { label: "Lico-an", mun_code: "063007" },
  // { label: "Linao", mun_code: "063007" },
  // { label: "Monpon", mun_code: "063007" },
  // { label: "Palaciawan", mun_code: "063007" },
  // { label: "Patag", mun_code: "063007" },
  // { label: "Salihid", mun_code: "063007" },
  // { label: "So-ol", mun_code: "063007" },
  // { label: "Sohoton", mun_code: "063007" },
  // { label: "Tabuc-Suba", mun_code: "063007" },
  // { label: "Tabucan", mun_code: "063007" },
  // { label: "Talisay", mun_code: "063007" },
  // { label: "Tinorian", mun_code: "063007" },
  // { label: "Tiwi", mun_code: "063007" },
  // { label: "Tubungan", mun_code: "063007" },
  // { label: "Bugnay", mun_code: "063008" },
  // { label: "California", mun_code: "063008" },
  // { label: "Del Pilar", mun_code: "063008" },
  // { label: "General Luna", mun_code: "063008" },
  // { label: "La Fortuna", mun_code: "063008" },
  // { label: "Lipata", mun_code: "063008" },
  // { label: "Natividad", mun_code: "063008" },
  // { label: "Nueva Invencion", mun_code: "063008" },
  // { label: "Nueva Sevilla", mun_code: "063008" },
  // { label: "Poblacion", mun_code: "063008" },
  // { label: "Puerto Princesa", mun_code: "063008" },
  // { label: "Rizal", mun_code: "063008" },
  // { label: "San Antonio", mun_code: "063008" },
  // { label: "San Fernando", mun_code: "063008" },
  // { label: "San Francisco", mun_code: "063008" },
  // { label: "San Geronimo", mun_code: "063008" },
  // { label: "San Juan", mun_code: "063008" },
  // { label: "San Lucas", mun_code: "063008" },
  // { label: "San Miguel", mun_code: "063008" },
  // { label: "San Roque", mun_code: "063008" },
  // { label: "Santiago", mun_code: "063008" },
  // { label: "Santo Domingo", mun_code: "063008" },
  // { label: "Santo Tomas", mun_code: "063008" },
  // { label: "Ugasan", mun_code: "063008" },
  // { label: "Vista Alegre", mun_code: "063008" },
  // { label: "Alapasco", mun_code: "063009" },
  // { label: "Alinsolong", mun_code: "063009" },
  // { label: "Banban", mun_code: "063009" },
  // { label: "Batad Viejo", mun_code: "063009" },
  // { label: "Binon-an", mun_code: "063009" },
  // { label: "Bolhog", mun_code: "063009" },
  // { label: "Bulak Norte", mun_code: "063009" },
  // { label: "Bulak Sur", mun_code: "063009" },
  // { label: "Cabagohan", mun_code: "063009" },
  // { label: "Calangag", mun_code: "063009" },
  // { label: "Caw-i", mun_code: "063009" },
  // { label: "Drancalan", mun_code: "063009" },
  // { label: "Embarcadero", mun_code: "063009" },
  // { label: "Hamod", mun_code: "063009" },
  // { label: "Malico", mun_code: "063009" },
  // { label: "Nangka", mun_code: "063009" },
  // { label: "Pasayan", mun_code: "063009" },
  // { label: "Poblacion", mun_code: "063009" },
  // { label: "Quiazan Florete", mun_code: "063009" },
  // { label: "Quiazan Lopez", mun_code: "063009" },
  // { label: "Salong", mun_code: "063009" },
  // { label: "Santa Ana", mun_code: "063009" },
  // { label: "Tanao", mun_code: "063009" },
  // { label: "Tapi-an", mun_code: "063009" },
  // { label: "Agba-o", mun_code: "063010" },
  // { label: "Alabidhan", mun_code: "063010" },
  // { label: "Bulabog", mun_code: "063010" },
  // { label: "Cairohan", mun_code: "063010" },
  // { label: "Guinhulacan", mun_code: "063010" },
  // { label: "Inamyungan", mun_code: "063010" },
  // { label: "Malitbog Ilawod", mun_code: "063010" },
  // { label: "Malitbog Ilaya", mun_code: "063010" },
  // { label: "Ngingi-an", mun_code: "063010" },
  // { label: "Poblacion", mun_code: "063010" },
  // { label: "Quinangyana", mun_code: "063010" },
  // { label: "Quinar-Upan", mun_code: "063010" },
  // { label: "Tapacon", mun_code: "063010" },
  // { label: "Tubod", mun_code: "063010" },
  // { label: "Acao", mun_code: "063012" },
  // { label: "Amerang", mun_code: "063012" },
  // { label: "Amurao", mun_code: "063012" },
  // { label: "Anuang", mun_code: "063012" },
  // { label: "Ayaman", mun_code: "063012" },
  // { label: "Ayong", mun_code: "063012" },
  // { label: "Bacan", mun_code: "063012" },
  // { label: "Balabag", mun_code: "063012" },
  // { label: "Baluyan", mun_code: "063012" },
  // { label: "Banguit", mun_code: "063012" },
  // { label: "Bulay", mun_code: "063012" },
  // { label: "Cadoldolan", mun_code: "063012" },
  // { label: "Cagban", mun_code: "063012" },
  // { label: "Calawagan", mun_code: "063012" },
  // { label: "Calayo", mun_code: "063012" },
  // { label: "Duyanduyan", mun_code: "063012" },
  // { label: "Gaub", mun_code: "063012" },
  // { label: "Gines Interior", mun_code: "063012" },
  // { label: "Gines Patag", mun_code: "063012" },
  // { label: "Guibuangan Tigbauan", mun_code: "063012" },
  // { label: "Inabasan", mun_code: "063012" },
  // { label: "Inaca", mun_code: "063012" },
  // { label: "Inaladan", mun_code: "063012" },
  // { label: "Ingas", mun_code: "063012" },
  // { label: "Ito Norte", mun_code: "063012" },
  // { label: "Ito Sur", mun_code: "063012" },
  // { label: "Janipaan Central", mun_code: "063012" },
  // { label: "Janipaan Este", mun_code: "063012" },
  // { label: "Janipaan Oeste", mun_code: "063012" },
  // { label: "Janipaan Olo", mun_code: "063012" },
  // { label: "Jelicuon Lusaya", mun_code: "063012" },
  // { label: "Jelicuon Montinola", mun_code: "063012" },
  // { label: "Lag-an", mun_code: "063012" },
  // { label: "Leong", mun_code: "063012" },
  // { label: "Lutac", mun_code: "063012" },
  // { label: "Manguna", mun_code: "063012" },
  // { label: "Maraguit", mun_code: "063012" },
  // { label: "Morubuan", mun_code: "063012" },
  // { label: "Pacatin", mun_code: "063012" },
  // { label: "Pagotpot", mun_code: "063012" },
  // { label: "Pamul-Ogan", mun_code: "063012" },
  // { label: "Pamuringao Proper", mun_code: "063012" },
  // { label: "Pamuringao Garrido", mun_code: "063012" },
  // { label: "Zone I Pob. (Barangay 1)", mun_code: "063012" },
  // { label: "Zone X Pob. (Barangay 10)", mun_code: "063012" },
  // { label: "Zone XI Pob. (Barangay 11)", mun_code: "063012" },
  // { label: "Zone II Pob. (Barangay 2)", mun_code: "063012" },
  // { label: "Zone III Pob. (Barangay 3)", mun_code: "063012" },
  // { label: "Zone IV Pob. (Barangay 4)", mun_code: "063012" },
  // { label: "Zone V Pob. (Barangay 5)", mun_code: "063012" },
  // { label: "Zone VI Pob. (Barangay 6 )", mun_code: "063012" },
  // { label: "Zone VII Pob. (Barangay 7)", mun_code: "063012" },
  // { label: "Zone VIII Pob. (Barangay 8)", mun_code: "063012" },
  // { label: "Zone IX Pob. (Barangay 9)", mun_code: "063012" },
  // { label: "Pungtod", mun_code: "063012" },
  // { label: "Puyas", mun_code: "063012" },
  // { label: "Salacay", mun_code: "063012" },
  // { label: "Sulanga", mun_code: "063012" },
  // { label: "Tabucan", mun_code: "063012" },
  // { label: "Tacdangan", mun_code: "063012" },
  // { label: "Talanghauan", mun_code: "063012" },
  // { label: "Tigbauan Road", mun_code: "063012" },
  // { label: "Tinio-an", mun_code: "063012" },
  // { label: "Tiring", mun_code: "063012" },
  // { label: "Tupol Central", mun_code: "063012" },
  // { label: "Tupol Este", mun_code: "063012" },
  // { label: "Tupol Oeste", mun_code: "063012" },
  // { label: "Tuy-an", mun_code: "063012" },
  // { label: "Agcalaga", mun_code: "063013" },
  // { label: "Aglibacao", mun_code: "063013" },
  // { label: "Aglonok", mun_code: "063013" },
  // { label: "Alibunan", mun_code: "063013" },
  // { label: "Badlan Grande", mun_code: "063013" },
  // { label: "Badu", mun_code: "063013" },
  // { label: "Balaticon", mun_code: "063013" },
  // { label: "Banban Grande", mun_code: "063013" },
  // { label: "Binolosan Grande", mun_code: "063013" },
  // { label: "Cabagiao", mun_code: "063013" },
  // { label: "Cabugao", mun_code: "063013" },
  // { label: "Cahigon", mun_code: "063013" },
  // { label: "Barrio Calinog", mun_code: "063013" },
  // { label: "Camalongo", mun_code: "063013" },
  // { label: "Canabajan", mun_code: "063013" },
  // { label: "Caratagan", mun_code: "063013" },
  // { label: "Carvasana", mun_code: "063013" },
  // { label: "Dalid", mun_code: "063013" },
  // { label: "Datagan", mun_code: "063013" },
  // { label: "Gama Grande", mun_code: "063013" },
  // { label: "Garangan", mun_code: "063013" },
  // { label: "Guinbonyugan", mun_code: "063013" },
  // { label: "Guiso", mun_code: "063013" },
  // { label: "Hilwan", mun_code: "063013" },
  // { label: "Impalidan", mun_code: "063013" },
  // { label: "Ipil", mun_code: "063013" },
  // { label: "Jamin-ay", mun_code: "063013" },
  // { label: "Lampaya", mun_code: "063013" },
  // { label: "Libot", mun_code: "063013" },
  // { label: "Lonoy", mun_code: "063013" },
  // { label: "Malaguinabot", mun_code: "063013" },
  // { label: "Malapawe", mun_code: "063013" },
  // { label: "Malitbog Centro", mun_code: "063013" },
  // { label: "Mambiranan", mun_code: "063013" },
  // { label: "Manaripay", mun_code: "063013" },
  // { label: "Marandig", mun_code: "063013" },
  // { label: "Masaroy", mun_code: "063013" },
  // { label: "Maspasan", mun_code: "063013" },
  // { label: "Nalbugan", mun_code: "063013" },
  // { label: "Owak", mun_code: "063013" },
  // { label: "Poblacion Centro", mun_code: "063013" },
  // { label: "Poblacion Delgado", mun_code: "063013" },
  // { label: "Poblacion Rizal Ilaud", mun_code: "063013" },
  // { label: "Poblacion Ilaya", mun_code: "063013" },
  // { label: "Baje San Julian", mun_code: "063013" },
  // { label: "San Nicolas", mun_code: "063013" },
  // { label: "Simsiman", mun_code: "063013" },
  // { label: "Tabucan", mun_code: "063013" },
  // { label: "Tahing", mun_code: "063013" },
  // { label: "Tibiao", mun_code: "063013" },
  // { label: "Tigbayog", mun_code: "063013" },
  // { label: "Toyungan", mun_code: "063013" },
  // { label: "Ulayan", mun_code: "063013" },
  // { label: "Malag-it", mun_code: "063013" },
  // { label: "Supanga", mun_code: "063013" },
  // { label: "Abong", mun_code: "063014" },
  // { label: "Alipata", mun_code: "063014" },
  // { label: "Asluman", mun_code: "063014" },
  // { label: "Bancal", mun_code: "063014" },
  // { label: "Barangcalan", mun_code: "063014" },
  // { label: "Barosbos", mun_code: "063014" },
  // { label: "Punta Batuanan", mun_code: "063014" },
  // { label: "Binuluangan", mun_code: "063014" },
  // { label: "Bito-on", mun_code: "063014" },
  // { label: "Bolo", mun_code: "063014" },
  // { label: "Buaya", mun_code: "063014" },
  // { label: "Buenavista", mun_code: "063014" },
  // { label: "Isla De Cana", mun_code: "063014" },
  // { label: "Cabilao Grande", mun_code: "063014" },
  // { label: "Cabuguana", mun_code: "063014" },
  // { label: "Cawayan", mun_code: "063014" },
  // { label: "Dayhagan", mun_code: "063014" },
  // { label: "Gabi", mun_code: "063014" },
  // { label: "Granada", mun_code: "063014" },
  // { label: "Guinticgan", mun_code: "063014" },
  // { label: "Lantangan", mun_code: "063014" },
  // { label: "Manlot", mun_code: "063014" },
  // { label: "Nalumsan", mun_code: "063014" },
  // { label: "Pantalan", mun_code: "063014" },
  // { label: "Poblacion", mun_code: "063014" },
  // { label: "Punta (Bolocawe)", mun_code: "063014" },
  // { label: "San Fernando", mun_code: "063014" },
  // { label: "Tabugon", mun_code: "063014" },
  // { label: "Talingting", mun_code: "063014" },
  // { label: "Tarong", mun_code: "063014" },
  // { label: "Tinigban", mun_code: "063014" },
  // { label: "Tupaz", mun_code: "063014" },
  // { label: "Aglosong", mun_code: "063015" },
  // { label: "Agnaga", mun_code: "063015" },
  // { label: "Bacjawan Norte", mun_code: "063015" },
  // { label: "Bacjawan Sur", mun_code: "063015" },
  // { label: "Bagongon", mun_code: "063015" },
  // { label: "Batiti", mun_code: "063015" },
  // { label: "Botlog", mun_code: "063015" },
  // { label: "Calamigan", mun_code: "063015" },
  // { label: "Dungon", mun_code: "063015" },
  // { label: "Igbon", mun_code: "063015" },
  // { label: "Jamul-Awon", mun_code: "063015" },
  // { label: "Lo-ong", mun_code: "063015" },
  // { label: "Macalbang", mun_code: "063015" },
  // { label: "Macatunao", mun_code: "063015" },
  // { label: "Malangabang", mun_code: "063015" },
  // { label: "Maliogliog", mun_code: "063015" },
  // { label: "Nipa", mun_code: "063015" },
  // { label: "Plandico", mun_code: "063015" },
  // { label: "Poblacion", mun_code: "063015" },
  // { label: "Polopina", mun_code: "063015" },
  // { label: "Salvacion", mun_code: "063015" },
  // { label: "Talotu-an", mun_code: "063015" },
  // { label: "Tambaliza", mun_code: "063015" },
  // { label: "Tamis-ac", mun_code: "063015" },
  // { label: "Abangay", mun_code: "063016" },
  // { label: "Agsalanan", mun_code: "063016" },
  // { label: "Agtatacay", mun_code: "063016" },
  // { label: "Alegria", mun_code: "063016" },
  // { label: "Bongloy", mun_code: "063016" },
  // { label: "Buenavista", mun_code: "063016" },
  // { label: "Caguyuman", mun_code: "063016" },
  // { label: "Calicuang", mun_code: "063016" },
  // { label: "Camambugan", mun_code: "063016" },
  // { label: "Dawis", mun_code: "063016" },
  // { label: "Ginalinan Nuevo", mun_code: "063016" },
  // { label: "Ginalinan Viejo", mun_code: "063016" },
  // { label: "Gutao", mun_code: "063016" },
  // { label: "Ilajas", mun_code: "063016" },
  // { label: "Libo-o", mun_code: "063016" },
  // { label: "Licu-an", mun_code: "063016" },
  // { label: "Lincud", mun_code: "063016" },
  // { label: "Matangharon", mun_code: "063016" },
  // { label: "Moroboro", mun_code: "063016" },
  // { label: "Namatay", mun_code: "063016" },
  // { label: "Nazuni", mun_code: "063016" },
  // { label: "Pandan", mun_code: "063016" },
  // { label: "Poblacion", mun_code: "063016" },
  // { label: "Potolan", mun_code: "063016" },
  // { label: "San Jose", mun_code: "063016" },
  // { label: "San Matias", mun_code: "063016" },
  // { label: "Siniba-an", mun_code: "063016" },
  // { label: "Tabugon", mun_code: "063016" },
  // { label: "Tambunac", mun_code: "063016" },
  // { label: "Tanghawan", mun_code: "063016" },
  // { label: "Tiguib", mun_code: "063016" },
  // { label: "Tinocuan", mun_code: "063016" },
  // { label: "Tulatula-an", mun_code: "063016" },
  // { label: "Agutayan", mun_code: "063017" },
  // { label: "Angare", mun_code: "063017" },
  // { label: "Anjawan", mun_code: "063017" },
  // { label: "Baac", mun_code: "063017" },
  // { label: "Bagongbong", mun_code: "063017" },
  // { label: "Balangigan", mun_code: "063017" },
  // { label: "Balingasag", mun_code: "063017" },
  // { label: "Banugan", mun_code: "063017" },
  // { label: "Batuan", mun_code: "063017" },
  // { label: "Bita", mun_code: "063017" },
  // { label: "Buenavista", mun_code: "063017" },
  // { label: "Bugtongan", mun_code: "063017" },
  // { label: "Cabudian", mun_code: "063017" },
  // { label: "Calaca-an", mun_code: "063017" },
  // { label: "Calang", mun_code: "063017" },
  // { label: "Calawinan", mun_code: "063017" },
  // { label: "Capaycapay", mun_code: "063017" },
  // { label: "Capuling", mun_code: "063017" },
  // { label: "Catig", mun_code: "063017" },
  // { label: "Dila-an", mun_code: "063017" },
  // { label: "Fundacion", mun_code: "063017" },
  // { label: "Inadlawan", mun_code: "063017" },
  // { label: "Jagdong", mun_code: "063017" },
  // { label: "Jaguimit", mun_code: "063017" },
  // { label: "Lacadon", mun_code: "063017" },
  // { label: "Luag", mun_code: "063017" },
  // { label: "Malusgod", mun_code: "063017" },
  // { label: "Maribuyong", mun_code: "063017" },
  // { label: "Minanga", mun_code: "063017" },
  // { label: "Monpon", mun_code: "063017" },
  // { label: "Navalas", mun_code: "063017" },
  // { label: "Pader", mun_code: "063017" },
  // { label: "Pandan", mun_code: "063017" },
  // { label: "Ponong Grande", mun_code: "063017" },
  // { label: "Purog", mun_code: "063017" },
  // { label: "Romblon", mun_code: "063017" },
  // { label: "San Isidro", mun_code: "063017" },
  // { label: "Sawe", mun_code: "063017" },
  // { label: "Taminla", mun_code: "063017" },
  // { label: "Tinocuan", mun_code: "063017" },
  // { label: "Tipolo", mun_code: "063017" },
  // { label: "Poblacion A", mun_code: "063017" },
  // { label: "Poblacion B", mun_code: "063017" },
  // { label: "Poblacion C", mun_code: "063017" },
  // { label: "Poblacion D", mun_code: "063017" },
  // { label: "Bacay", mun_code: "063018" },
  // { label: "Bacong", mun_code: "063018" },
  // { label: "Balabag", mun_code: "063018" },
  // { label: "Balud", mun_code: "063018" },
  // { label: "Bantud", mun_code: "063018" },
  // { label: "Bantud Fabrica", mun_code: "063018" },
  // { label: "Baras", mun_code: "063018" },
  // { label: "Barasan", mun_code: "063018" },
  // { label: "Bolilao", mun_code: "063018" },
  // { label: "Calao", mun_code: "063018" },
  // { label: "Cali", mun_code: "063018" },
  // { label: "Cansilayan", mun_code: "063018" },
  // { label: "Capaliz", mun_code: "063018" },
  // { label: "Cayos", mun_code: "063018" },
  // { label: "Compayan", mun_code: "063018" },
  // { label: "Dacutan", mun_code: "063018" },
  // { label: "Ermita", mun_code: "063018" },
  // { label: "Pd Monfort South (Guinsampanan)", mun_code: "063018" },
  // { label: "Ilaya 1st", mun_code: "063018" },
  // { label: "Ilaya 2nd", mun_code: "063018" },
  // { label: "Ilaya 3rd", mun_code: "063018" },
  // { label: "Jardin", mun_code: "063018" },
  // { label: "Lacturan", mun_code: "063018" },
  // { label: "Pd Monfort North (Lublub)", mun_code: "063018" },
  // { label: "Managuit", mun_code: "063018" },
  // { label: "Maquina", mun_code: "063018" },
  // { label: "Nanding Lopez", mun_code: "063018" },
  // { label: "Pagdugue", mun_code: "063018" },
  // { label: "Paloc Bigque", mun_code: "063018" },
  // { label: "Paloc Sool", mun_code: "063018" },
  // { label: "Patlad", mun_code: "063018" },
  // { label: "Pulao", mun_code: "063018" },
  // { label: "Rosario", mun_code: "063018" },
  // { label: "Sapao", mun_code: "063018" },
  // { label: "Sulangan", mun_code: "063018" },
  // { label: "Tabucan", mun_code: "063018" },
  // { label: "Talusan", mun_code: "063018" },
  // { label: "Tambobo", mun_code: "063018" },
  // { label: "Tamboilan", mun_code: "063018" },
  // { label: "Victorias", mun_code: "063018" },
  // { label: "Burgos-Regidor (Pob.)", mun_code: "063018" },
  // { label: "Aurora-del Pilar (Pob.)", mun_code: "063018" },
  // { label: "Buenaflor Embarkadero (Pob.)", mun_code: "063018" },
  // { label: "Lopez Jaena - Rizal (Pob.)", mun_code: "063018" },
  // { label: "Basa-Mabini Bonifacio (Pob.)", mun_code: "063018" },
  // { label: "Lumbia (Ana Cuenca)", mun_code: "063019" },
  // { label: "Bayas (Bayas Island)", mun_code: "063019" },
  // { label: "Bayuyan", mun_code: "063019" },
  // { label: "Botongan", mun_code: "063019" },
  // { label: "Calapdan", mun_code: "063019" },
  // { label: "Cano-an", mun_code: "063019" },
  // { label: "Daan Banua", mun_code: "063019" },
  // { label: "Daculan", mun_code: "063019" },
  // { label: "Gogo", mun_code: "063019" },
  // { label: "Jolog", mun_code: "063019" },
  // { label: "Loguingot (Loguingot Island)", mun_code: "063019" },
  // { label: "Malbog", mun_code: "063019" },
  // { label: "Manipulon", mun_code: "063019" },
  // { label: "Pa-on", mun_code: "063019" },
  // { label: "Pani-an", mun_code: "063019" },
  // { label: "Poblacion Zone 1", mun_code: "063019" },
  // { label: "Lonoy (Roman Mosqueda)", mun_code: "063019" },
  // { label: "San Roque", mun_code: "063019" },
  // { label: "Santa Ana", mun_code: "063019" },
  // { label: "Tabu-an", mun_code: "063019" },
  // { label: "Tacbuyan", mun_code: "063019" },
  // { label: "Tanza", mun_code: "063019" },
  // { label: "Poblacion Zone II", mun_code: "063019" },
  // { label: "Poblacion Zone III", mun_code: "063019" },
  // { label: "Anono-o", mun_code: "063020" },
  // { label: "Bacong", mun_code: "063020" },
  // { label: "Bagumbayan Pob. (Bagumbayan-Granada)", mun_code: "063020" },
  // { label: "Balantad-Carlos Fruto (Pob.)", mun_code: "063020" },
  // { label: "Baras", mun_code: "063020" },
  // { label: "Binanua-an", mun_code: "063020" },
  // { label: "Torreblanca-Blumentritt (Pob.)", mun_code: "063020" },
  // { label: "Bongol San Miguel", mun_code: "063020" },
  // { label: "Bongol San Vicente", mun_code: "063020" },
  // { label: "Bulad", mun_code: "063020" },
  // { label: "Buluangan", mun_code: "063020" },
  // { label: "Burgos-Gengos (Pob.)", mun_code: "063020" },
  // { label: "Cabasi", mun_code: "063020" },
  // { label: "Cabubugan", mun_code: "063020" },
  // { label: "Calampitao", mun_code: "063020" },
  // { label: "Camangahan", mun_code: "063020" },
  // { label: "Generosa-Cristobal Colon (Pob.)", mun_code: "063020" },
  // { label: "Gerona-Gimeno (Pob.)", mun_code: "063020" },
  // { label: "Girado-Magsaysay (Pob.)", mun_code: "063020" },
  // { label: "Gotera (Pob.)", mun_code: "063020" },
  // { label: "Igcocolo", mun_code: "063020" },
  // { label: "Libo-on Gonzales (Pob.)", mun_code: "063020" },
  // { label: "Lubacan", mun_code: "063020" },
  // { label: "Nahapay", mun_code: "063020" },
  // { label: "Nalundan", mun_code: "063020" },
  // { label: "Nanga", mun_code: "063020" },
  // { label: "Nito-an Lupsag", mun_code: "063020" },
  // { label: "Particion", mun_code: "063020" },
  // { label: "Pescadores (Pob.)", mun_code: "063020" },
  // { label: "Rizal-Tuguisan (Pob.)", mun_code: "063020" },
  // { label: "Sipitan-Badiang", mun_code: "063020" },
  // { label: "Iyasan", mun_code: "063020" },
  // { label: "Santa Rosa-Laguna", mun_code: "063020" },
  // { label: "Alameda", mun_code: "063021" },
  // { label: "Amorogtong", mun_code: "063021" },
  // { label: "Anilawan", mun_code: "063021" },
  // { label: "Bagacay", mun_code: "063021" },
  // { label: "Bagacayan", mun_code: "063021" },
  // { label: "Bagay", mun_code: "063021" },
  // { label: "Balibagan", mun_code: "063021" },
  // { label: "Barasan", mun_code: "063021" },
  // { label: "Binanua-an", mun_code: "063021" },
  // { label: "Boclod", mun_code: "063021" },
  // { label: "Buenavista", mun_code: "063021" },
  // { label: "Buga", mun_code: "063021" },
  // { label: "Bugnay", mun_code: "063021" },
  // { label: "Calampitao", mun_code: "063021" },
  // { label: "Cale", mun_code: "063021" },
  // { label: "Corucuan", mun_code: "063021" },
  // { label: "Catiringan", mun_code: "063021" },
  // { label: "Igcabugao", mun_code: "063021" },
  // { label: "Igpigus", mun_code: "063021" },
  // { label: "Igtalongon", mun_code: "063021" },
  // { label: "Indaluyon", mun_code: "063021" },
  // { label: "Jovellar", mun_code: "063021" },
  // { label: "Kinagdan", mun_code: "063021" },
  // { label: "Lab-on", mun_code: "063021" },
  // { label: "Lacay Dol-Dol", mun_code: "063021" },
  // { label: "Lumangan", mun_code: "063021" },
  // { label: "Lutungan", mun_code: "063021" },
  // { label: "Mantangon", mun_code: "063021" },
  // { label: "Mulangan", mun_code: "063021" },
  // { label: "Pasong", mun_code: "063021" },
  // { label: "Passi", mun_code: "063021" },
  // { label: "Pinaopawan", mun_code: "063021" },
  // { label: "Barangay 1 Poblacion", mun_code: "063021" },
  // { label: "Barangay 2 Poblacion", mun_code: "063021" },
  // { label: "Barangay 3 Poblacion", mun_code: "063021" },
  // { label: "Barangay 4 Poblacion", mun_code: "063021" },
  // { label: "Barangay 5 Poblacion", mun_code: "063021" },
  // { label: "Barangay 6 Poblacion", mun_code: "063021" },
  // { label: "Riro-an", mun_code: "063021" },
  // { label: "San Ambrosio", mun_code: "063021" },
  // { label: "Santa Barbara", mun_code: "063021" },
  // { label: "Signe", mun_code: "063021" },
  // { label: "Tabiac", mun_code: "063021" },
  // { label: "Talayatay", mun_code: "063021" },
  // { label: "Taytay", mun_code: "063021" },
  // { label: "Tigbanaba", mun_code: "063021" },
  // { label: "Santa Cruz", mun_code: "063022" },
  // { label: "Aguinaldo", mun_code: "063022" },
  // { label: "Airport (Tabucan Airport)", mun_code: "063022" },
  // { label: "Alalasan Lapuz", mun_code: "063022" },
  // { label: "Arguelles", mun_code: "063022" },
  // { label: "Arsenal Aduana", mun_code: "063022" },
  // { label: "Bakhaw", mun_code: "063022" },
  // { label: "Balabago", mun_code: "063022" },
  // { label: "Balantang", mun_code: "063022" },
  // { label: "Baldoza", mun_code: "063022" },
  // { label: "Sinikway (Bangkerohan Lapuz)", mun_code: "063022" },
  // { label: "Bantud", mun_code: "063022" },
  // { label: "Banuyao", mun_code: "063022" },
  // { label: "Baybay Tanza", mun_code: "063022" },
  // { label: "Benedicto (Jaro)", mun_code: "063022" },
  // { label: "Bito-on", mun_code: "063022" },
  // { label: "Monica Blumentritt", mun_code: "063022" },
  // { label: "Bolilao", mun_code: "063022" },
  // { label: "Bonifacio Tanza", mun_code: "063022" },
  // { label: "Bonifacio (Arevalo)", mun_code: "063022" },
  // { label: "Buhang", mun_code: "063022" },
  // { label: "Buhang Taft North", mun_code: "063022" },
  // { label: "Buntatala", mun_code: "063022" },
  // { label: "Seminario (Burgos Jalandoni)", mun_code: "063022" },
  // { label: "Caingin", mun_code: "063022" },
  // { label: "Calahunan", mun_code: "063022" },
  // { label: "Calaparan", mun_code: "063022" },
  // { label: "Calumpang", mun_code: "063022" },
  // { label: "Camalig", mun_code: "063022" },
  // { label: "El 98 Castilla (Claudio Lopez)", mun_code: "063022" },
  // { label: "Cochero", mun_code: "063022" },
  // { label: "Compania", mun_code: "063022" },
  // { label: "Concepcion-Montes", mun_code: "063022" },
  // { label: "Cuartero", mun_code: "063022" },
  // { label: "Cubay", mun_code: "063022" },
  // { label: "Danao", mun_code: "063022" },
  // { label: "Mabolo-Delgado", mun_code: "063022" },
  // { label: "Democracia", mun_code: "063022" },
  // { label: "Desamparados", mun_code: "063022" },
  // { label: "Divinagracia", mun_code: "063022" },
  // { label: "Don Esteban-Lapuz", mun_code: "063022" },
  // { label: "Dulonan", mun_code: "063022" },
  // { label: "Dungon", mun_code: "063022" },
  // { label: "Dungon A", mun_code: "063022" },
  // { label: "Dungon B", mun_code: "063022" },
  // { label: "East Baluarte", mun_code: "063022" },
  // { label: "East Timawa", mun_code: "063022" },
  // { label: "Edganzon", mun_code: "063022" },
  // { label: "Tanza-Esperanza", mun_code: "063022" },
  // { label: "Fajardo", mun_code: "063022" },
  // { label: "Flores", mun_code: "063022" },
  // { label: "South Fundidor", mun_code: "063022" },
  // { label: "General Hughes-Montes", mun_code: "063022" },
  // { label: "Gloria", mun_code: "063022" },
  // { label: "Gustilo", mun_code: "063022" },
  // { label: "Guzman-Jesena", mun_code: "063022" },
  // { label: "Habog-habog Salvacion", mun_code: "063022" },
  // { label: "Hibao-an Sur", mun_code: "063022" },
  // { label: "Hinactacan", mun_code: "063022" },
  // { label: "Hipodromo", mun_code: "063022" },
  // { label: "Inday", mun_code: "063022" },
  // { label: "Infante", mun_code: "063022" },
  // { label: "Ingore", mun_code: "063022" },
  // { label: "Jalandoni Estate-Lapuz", mun_code: "063022" },
  // { label: "Jalandoni-Wilson", mun_code: "063022" },
  // { label: "Delgado-Jalandoni-Bagumbayan", mun_code: "063022" },
  // { label: "Javellana", mun_code: "063022" },
  // { label: "Jereos", mun_code: "063022" },
  // { label: "Calubihan", mun_code: "063022" },
  // { label: "Kasingkasing", mun_code: "063022" },
  // { label: "Katilingban", mun_code: "063022" },
  // { label: "Kauswagan", mun_code: "063022" },
  // { label: "Our Lady Of Fatima", mun_code: "063022" },
  // { label: "Laguda", mun_code: "063022" },
  // { label: "Lanit", mun_code: "063022" },
  // { label: "Lapuz Norte", mun_code: "063022" },
  // { label: "Lapuz Sur", mun_code: "063022" },
  // { label: "Legaspi dela Rama", mun_code: "063022" },
  // { label: "Liberation", mun_code: "063022" },
  // { label: "Libertad, Santa Isabel", mun_code: "063022" },
  // { label: "Libertad-Lapuz", mun_code: "063022" },
  // { label: "Lopez Jaena (Jaro)", mun_code: "063022" },
  // { label: "Loboc-Lapuz", mun_code: "063022" },
  // { label: "Lopez Jaena Norte", mun_code: "063022" },
  // { label: "Lopez Jaena Sur", mun_code: "063022" },
  // { label: "Luna (Jaro)", mun_code: "063022" },
  // { label: "M. V. Hechanova", mun_code: "063022" },
  // { label: "Burgos-Mabini-Plaza", mun_code: "063022" },
  // { label: "Macarthur", mun_code: "063022" },
  // { label: "Magdalo", mun_code: "063022" },
  // { label: "Magsaysay", mun_code: "063022" },
  // { label: "Magsaysay Village", mun_code: "063022" },
  // { label: "Malipayon-Delgado", mun_code: "063022" },
  // { label: "Mansaya-Lapuz", mun_code: "063022" },
  // { label: "Marcelo H. del Pilar", mun_code: "063022" },
  // { label: "Maria Clara", mun_code: "063022" },
  // { label: "Maria Cristina", mun_code: "063022" },
  // { label: "Mohon", mun_code: "063022" },
  // { label: "Molo Boulevard", mun_code: "063022" },
  // { label: "Montinola", mun_code: "063022" },
  // { label: "Muelle Loney-Montes", mun_code: "063022" },
  // { label: "Nabitasan", mun_code: "063022" },
  // { label: "Navais", mun_code: "063022" },
  // { label: "Nonoy", mun_code: "063022" },
  // { label: "North Fundidor", mun_code: "063022" },
  // { label: "North Baluarte", mun_code: "063022" },
  // { label: "North San Jose", mun_code: "063022" },
  // { label: "Obrero-Lapuz", mun_code: "063022" },
  // { label: "Ortiz", mun_code: "063022" },
  // { label: "Our Lady Of Lourdes", mun_code: "063022" },
  // { label: "Rizal Palapala I", mun_code: "063022" },
  // { label: "Rizal Palapala II", mun_code: "063022" },
  // { label: "PHHC Block 17", mun_code: "063022" },
  // { label: "PHHC Block 22 NHA", mun_code: "063022" },
  // { label: "Poblacion Molo", mun_code: "063022" },
  // { label: "President Roxas", mun_code: "063022" },
  // { label: "Progreso-Lapuz", mun_code: "063022" },
  // { label: "Punong-Lapuz", mun_code: "063022" },
  // { label: "Quezon", mun_code: "063022" },
  // { label: "Quintin Salas", mun_code: "063022" },
  // { label: "Rima-Rizal", mun_code: "063022" },
  // { label: "Rizal Estanzuela", mun_code: "063022" },
  // { label: "Rizal Ibarra", mun_code: "063022" },
  // { label: "Railway", mun_code: "063022" },
  // { label: "Roxas Village", mun_code: "063022" },
  // { label: "Sambag", mun_code: "063022" },
  // { label: "Sampaguita", mun_code: "063022" },
  // { label: "San Agustin", mun_code: "063022" },
  // { label: "San Antonio", mun_code: "063022" },
  // { label: "San Felix", mun_code: "063022" },
  // { label: "San Isidro (Jaro)", mun_code: "063022" },
  // { label: "Hibao-an Norte", mun_code: "063022" },
  // { label: "San Jose (Jaro)", mun_code: "063022" },
  // { label: "San Jose (Arevalo)", mun_code: "063022" },
  // { label: "San Juan", mun_code: "063022" },
  // { label: "San Nicolas", mun_code: "063022" },
  // { label: "San Pedro (Molo)", mun_code: "063022" },
  // { label: "San Pedro (Jaro)", mun_code: "063022" },
  // { label: "San Rafael", mun_code: "063022" },
  // { label: "San Roque", mun_code: "063022" },
  // { label: "San Vicente", mun_code: "063022" },
  // { label: "Santa Filomena", mun_code: "063022" },
  // { label: "Santa Rosa", mun_code: "063022" },
  // { label: "Santo Domingo", mun_code: "063022" },
  // { label: "Santo Rosario-Duran", mun_code: "063022" },
  // { label: "Simon Ledesma", mun_code: "063022" },
  // { label: "So-oc", mun_code: "063022" },
  // { label: "South Baluarte", mun_code: "063022" },
  // { label: "South San Jose", mun_code: "063022" },
  // { label: "Taal", mun_code: "063022" },
  // { label: "Tabuc Suba (Jaro)", mun_code: "063022" },
  // { label: "Tabucan", mun_code: "063022" },
  // { label: "Tacas", mun_code: "063022" },
  // { label: "Abeto Mirasol Taft South (Quirino Abeto)", mun_code: "063022" },
  // { label: "Tagbac", mun_code: "063022" },
  // { label: "Tap-oc", mun_code: "063022" },
  // { label: "Taytay Zone II", mun_code: "063022" },
  // { label: "Ticud (La Paz)", mun_code: "063022" },
  // { label: "Timawa Tanza I", mun_code: "063022" },
  // { label: "Timawa Tanza II", mun_code: "063022" },
  // { label: "Ungka", mun_code: "063022" },
  // { label: "Veterans Village", mun_code: "063022" },
  // { label: "Villa Anita", mun_code: "063022" },
  // { label: "West Habog-habog", mun_code: "063022" },
  // { label: "West Timawa", mun_code: "063022" },
  // { label: "Yulo-Arroyo", mun_code: "063022" },
  // { label: "Yulo Drive", mun_code: "063022" },
  // { label: "Zamora-Melliza", mun_code: "063022" },
  // { label: "Pale Benedicto Rizal (Mandurriao)", mun_code: "063022" },
  // { label: "Kahirupan", mun_code: "063022" },
  // { label: "Luna (La Paz)", mun_code: "063022" },
  // { label: "San Isidro (La Paz)", mun_code: "063022" },
  // { label: "San Jose (City Proper)", mun_code: "063022" },
  // { label: "Tabuc Suba (La Paz)", mun_code: "063022" },
  // { label: "Rizal (La Paz)", mun_code: "063022" },
  // { label: "Abangay", mun_code: "063023" },
  // { label: "Agcarope", mun_code: "063023" },
  // { label: "Aglobong", mun_code: "063023" },
  // { label: "Aguingay", mun_code: "063023" },
  // { label: "Anhawan", mun_code: "063023" },
  // { label: "Atimonan", mun_code: "063023" },
  // { label: "Balanac", mun_code: "063023" },
  // { label: "Barasalon", mun_code: "063023" },
  // { label: "Bongol", mun_code: "063023" },
  // { label: "Cabantog", mun_code: "063023" },
  // { label: "Calmay", mun_code: "063023" },
  // { label: "Canawili", mun_code: "063023" },
  // { label: "Canawillian", mun_code: "063023" },
  // { label: "Caranas", mun_code: "063023" },
  // { label: "Caraudan", mun_code: "063023" },
  // { label: "Carigangan", mun_code: "063023" },
  // { label: "Cunsad", mun_code: "063023" },
  // { label: "Dabong", mun_code: "063023" },
  // { label: "Damires", mun_code: "063023" },
  // { label: "Damo-ong", mun_code: "063023" },
  // { label: "Danao", mun_code: "063023" },
  // { label: "Gines", mun_code: "063023" },
  // { label: "Guadalupe", mun_code: "063023" },
  // { label: "Jibolo", mun_code: "063023" },
  // { label: "Kuyot", mun_code: "063023" },
  // { label: "Madong", mun_code: "063023" },
  // { label: "Manacabac", mun_code: "063023" },
  // { label: "Mangil", mun_code: "063023" },
  // { label: "Matag-ub", mun_code: "063023" },
  // { label: "Monte-Magapa", mun_code: "063023" },
  // { label: "Pangilihan", mun_code: "063023" },
  // { label: "Panuran", mun_code: "063023" },
  // { label: "Pararinga", mun_code: "063023" },
  // { label: "Patong-patong", mun_code: "063023" },
  // { label: "Quipot", mun_code: "063023" },
  // { label: "Santo Tomas", mun_code: "063023" },
  // { label: "Sarawag", mun_code: "063023" },
  // { label: "Tambal", mun_code: "063023" },
  // { label: "Tamu-an", mun_code: "063023" },
  // { label: "Tiringanan", mun_code: "063023" },
  // { label: "Tolarucan", mun_code: "063023" },
  // { label: "Tuburan", mun_code: "063023" },
  // { label: "Ubian", mun_code: "063023" },
  // { label: "Yabon", mun_code: "063023" },
  // { label: "Aquino Nobleza East (Pob.)", mun_code: "063023" },
  // { label: "Aquino Nobleza West (Pob.)", mun_code: "063023" },
  // { label: "R. Armada (Pob.)", mun_code: "063023" },
  // { label: "Concepcion Pob. (D.G. Abordo)", mun_code: "063023" },
  // { label: "Golgota (Pob.)", mun_code: "063023" },
  // { label: "Locsin (Pob.)", mun_code: "063023" },
  // { label: "Don T. Lutero Center (Pob.)", mun_code: "063023" },
  // { label: "Don T. Lutero East (Pob.)", mun_code: "063023" },
  // {
  //   label: "Don T. Lutero West Pob. (Don T. Lutero North)",
  //   mun_code: "063023",
  // },
  // { label: "Crispin Salazar North (Pob.)", mun_code: "063023" },
  // { label: "Crispin Salazar South (Pob.)", mun_code: "063023" },
  // { label: "San Julian (Pob.)", mun_code: "063023" },
  // { label: "San Pedro (Pob.)", mun_code: "063023" },
  // { label: "Santa Rita (Pob.)", mun_code: "063023" },
  // { label: "Capt. A. Tirador (Pob.)", mun_code: "063023" },
  // { label: "S. M. Villa (Pob.)", mun_code: "063023" },
  // { label: "Agsirab", mun_code: "063025" },
  // { label: "Agtuman", mun_code: "063025" },
  // { label: "Alugmawa", mun_code: "063025" },
  // { label: "Badiangan", mun_code: "063025" },
  // { label: "Bogongbong", mun_code: "063025" },
  // { label: "Balagiao", mun_code: "063025" },
  // { label: "Banban", mun_code: "063025" },
  // { label: "Bansag", mun_code: "063025" },
  // { label: "Bayuco", mun_code: "063025" },
  // { label: "Binaba-an Armada", mun_code: "063025" },
  // { label: "Binaba-an Labayno", mun_code: "063025" },
  // { label: "Binaba-an Limoso", mun_code: "063025" },
  // { label: "Binaba-an Portigo", mun_code: "063025" },
  // { label: "Binaba-an Tirador", mun_code: "063025" },
  // { label: "Bonbon", mun_code: "063025" },
  // { label: "Bontoc", mun_code: "063025" },
  // { label: "Buri", mun_code: "063025" },
  // { label: "Burirao", mun_code: "063025" },
  // { label: "Buwang", mun_code: "063025" },
  // { label: "Cabatangan", mun_code: "063025" },
  // { label: "Cabugao", mun_code: "063025" },
  // { label: "Cabunlawan", mun_code: "063025" },
  // { label: "Caguisanan", mun_code: "063025" },
  // { label: "Caloy-Ahan", mun_code: "063025" },
  // { label: "Caninguan", mun_code: "063025" },
  // { label: "Capangyan", mun_code: "063025" },
  // { label: "Cayan Este", mun_code: "063025" },
  // { label: "Cayan Oeste", mun_code: "063025" },
  // { label: "Corot-on", mun_code: "063025" },
  // { label: "Coto", mun_code: "063025" },
  // { label: "Cubay", mun_code: "063025" },
  // { label: "Cunarum", mun_code: "063025" },
  // { label: "Daanbanwa", mun_code: "063025" },
  // { label: "Gines", mun_code: "063025" },
  // { label: "Hipgos", mun_code: "063025" },
  // { label: "Jayubo", mun_code: "063025" },
  // { label: "Jorog", mun_code: "063025" },
  // { label: "Lanot Grande", mun_code: "063025" },
  // { label: "Legayada", mun_code: "063025" },
  // { label: "Lumanay", mun_code: "063025" },
  // { label: "Madarag", mun_code: "063025" },
  // { label: "Magbato", mun_code: "063025" },
  // { label: "Maite Grande", mun_code: "063025" },
  // { label: "Malag-it", mun_code: "063025" },
  // { label: "Manaulan", mun_code: "063025" },
  // { label: "Maribong", mun_code: "063025" },
  // { label: "Marong", mun_code: "063025" },
  // { label: "Misi", mun_code: "063025" },
  // { label: "Natividad", mun_code: "063025" },
  // { label: "Pajo", mun_code: "063025" },
  // { label: "Pandan", mun_code: "063025" },
  // { label: "Panuran", mun_code: "063025" },
  // { label: "Pasig", mun_code: "063025" },
  // { label: "Patag", mun_code: "063025" },
  // { label: "Poblacion Ilawod", mun_code: "063025" },
  // { label: "Poblacion Ilaya", mun_code: "063025" },
  // { label: "Poong", mun_code: "063025" },
  // { label: "Pughanan", mun_code: "063025" },
  // { label: "Pungsod", mun_code: "063025" },
  // { label: "Quiling", mun_code: "063025" },
  // { label: "Sagcup", mun_code: "063025" },
  // { label: "San Gregorio", mun_code: "063025" },
  // { label: "Sibacungan", mun_code: "063025" },
  // { label: "Sibaguan", mun_code: "063025" },
  // { label: "Simsiman", mun_code: "063025" },
  // { label: "Supoc", mun_code: "063025" },
  // { label: "Tampucao", mun_code: "063025" },
  // { label: "Tranghawan", mun_code: "063025" },
  // { label: "Tubungan", mun_code: "063025" },
  // { label: "Tuburan", mun_code: "063025" },
  // { label: "Walang", mun_code: "063025" },
  // { label: "M.V. Hechanova (Balagon)", mun_code: "063026" },
  // { label: "Bigke", mun_code: "063026" },
  // { label: "Buntatala", mun_code: "063026" },
  // { label: "Cagamutan Norte", mun_code: "063026" },
  // { label: "Cagamutan Sur", mun_code: "063026" },
  // { label: "Calaboa", mun_code: "063026" },
  // { label: "Camangay", mun_code: "063026" },
  // { label: "Cari Mayor", mun_code: "063026" },
  // { label: "Cari Minor", mun_code: "063026" },
  // { label: "Gua-an", mun_code: "063026" },
  // { label: "Guihaman", mun_code: "063026" },
  // { label: "Guinobatan", mun_code: "063026" },
  // { label: "Guintas", mun_code: "063026" },
  // { label: "Lapayon", mun_code: "063026" },
  // { label: "Nabitasan", mun_code: "063026" },
  // { label: "Napnud", mun_code: "063026" },
  // { label: "Poblacion", mun_code: "063026" },
  // { label: "San Vicente", mun_code: "063026" },
  // { label: "Agpipili", mun_code: "063027" },
  // { label: "Alcantara", mun_code: "063027" },
  // { label: "Anabo", mun_code: "063027" },
  // { label: "Bankal", mun_code: "063027" },
  // { label: "Buenavista", mun_code: "063027" },
  // { label: "Cabantohan", mun_code: "063027" },
  // { label: "Dalipe", mun_code: "063027" },
  // { label: "Dapdapan", mun_code: "063027" },
  // { label: "Gerongan", mun_code: "063027" },
  // { label: "Imbaulan", mun_code: "063027" },
  // { label: "Layogbato", mun_code: "063027" },
  // { label: "Marapal", mun_code: "063027" },
  // { label: "Milan", mun_code: "063027" },
  // { label: "Nagsulang", mun_code: "063027" },
  // { label: "Nasapahan", mun_code: "063027" },
  // { label: "Omio", mun_code: "063027" },
  // { label: "Pacuan", mun_code: "063027" },
  // { label: "Poblacion NW Zone", mun_code: "063027" },
  // { label: "Poblacion SE Zone", mun_code: "063027" },
  // { label: "Pontoc", mun_code: "063027" },
  // { label: "San Antonio", mun_code: "063027" },
  // { label: "San Diego", mun_code: "063027" },
  // { label: "San Jose Moto", mun_code: "063027" },
  // { label: "Sepanton", mun_code: "063027" },
  // { label: "Sincua", mun_code: "063027" },
  // { label: "Tabunan", mun_code: "063027" },
  // { label: "Tugas", mun_code: "063027" },
  // { label: "Velasco", mun_code: "063027" },
  // { label: "Yawyawan", mun_code: "063027" },
  // { label: "Agboy Norte", mun_code: "063028" },
  // { label: "Agboy Sur", mun_code: "063028" },
  // { label: "Agta", mun_code: "063028" },
  // { label: "Ambulong", mun_code: "063028" },
  // { label: "Anonang", mun_code: "063028" },
  // { label: "Apian", mun_code: "063028" },
  // { label: "Avanzada", mun_code: "063028" },
  // { label: "Awis", mun_code: "063028" },
  // { label: "Ayabang", mun_code: "063028" },
  // { label: "Ayubo", mun_code: "063028" },
  // { label: "Bacolod", mun_code: "063028" },
  // { label: "Baje", mun_code: "063028" },
  // { label: "Banagan", mun_code: "063028" },
  // { label: "Barangbang", mun_code: "063028" },
  // { label: "Barasan", mun_code: "063028" },
  // { label: "Bayag Norte", mun_code: "063028" },
  // { label: "Bayag Sur", mun_code: "063028" },
  // { label: "Binolbog", mun_code: "063028" },
  // { label: "Biri Norte", mun_code: "063028" },
  // { label: "Biri Sur", mun_code: "063028" },
  // { label: "Bobon", mun_code: "063028" },
  // { label: "Bucari", mun_code: "063028" },
  // { label: "Buenavista", mun_code: "063028" },
  // { label: "Buga", mun_code: "063028" },
  // { label: "Bulad", mun_code: "063028" },
  // { label: "Bulwang", mun_code: "063028" },
  // { label: "Cabolo-an", mun_code: "063028" },
  // { label: "Cabunga-an", mun_code: "063028" },
  // { label: "Cabutongan", mun_code: "063028" },
  // { label: "Cagay", mun_code: "063028" },
  // { label: "Camandag", mun_code: "063028" },
  // { label: "Camando", mun_code: "063028" },
  // { label: "Cananaman", mun_code: "063028" },
  // { label: "Capt. Fernando", mun_code: "063028" },
  // { label: "Carara-an", mun_code: "063028" },
  // { label: "Carolina", mun_code: "063028" },
  // { label: "Cawilihan", mun_code: "063028" },
  // { label: "Coyugan Norte", mun_code: "063028" },
  // { label: "Coyugan Sur", mun_code: "063028" },
  // { label: "Danao", mun_code: "063028" },
  // { label: "Dorog", mun_code: "063028" },
  // { label: "Dusacan", mun_code: "063028" },
  // { label: "Gines", mun_code: "063028" },
  // { label: "Gumboc", mun_code: "063028" },
  // { label: "Igcadios", mun_code: "063028" },
  // { label: "Ingay", mun_code: "063028" },
  // { label: "Isian Norte", mun_code: "063028" },
  // { label: "Isian Victoria", mun_code: "063028" },
  // { label: "Jamog Gines", mun_code: "063028" },
  // { label: "Lanag", mun_code: "063028" },
  // { label: "Lang-og", mun_code: "063028" },
  // { label: "Ligtos", mun_code: "063028" },
  // { label: "Lonoc", mun_code: "063028" },
  // { label: "Lampaya", mun_code: "063028" },
  // { label: "Magcapay", mun_code: "063028" },
  // { label: "Maliao", mun_code: "063028" },
  // { label: "Malublub", mun_code: "063028" },
  // { label: "Manampunay", mun_code: "063028" },
  // { label: "Marirong", mun_code: "063028" },
  // { label: "Mina", mun_code: "063028" },
  // { label: "Mocol", mun_code: "063028" },
  // { label: "Nagbangi", mun_code: "063028" },
  // { label: "Nalbang", mun_code: "063028" },
  // { label: "Odong-odong", mun_code: "063028" },
  // { label: "Oluangan", mun_code: "063028" },
  // { label: "Omambong", mun_code: "063028" },
  // { label: "Paoy", mun_code: "063028" },
  // { label: "Pandan", mun_code: "063028" },
  // { label: "Panginman", mun_code: "063028" },
  // { label: "Pepe", mun_code: "063028" },
  // { label: "Poblacion", mun_code: "063028" },
  // { label: "Paga", mun_code: "063028" },
  // { label: "Salngan", mun_code: "063028" },
  // { label: "Samlague", mun_code: "063028" },
  // { label: "Siol Norte", mun_code: "063028" },
  // { label: "Siol Sur", mun_code: "063028" },
  // { label: "Tacuyong Norte", mun_code: "063028" },
  // { label: "Tacuyong Sur", mun_code: "063028" },
  // { label: "Tagsing", mun_code: "063028" },
  // { label: "Talacuan", mun_code: "063028" },
  // { label: "Ticuan", mun_code: "063028" },
  // { label: "Tina-an Norte", mun_code: "063028" },
  // { label: "Tina-an Sur", mun_code: "063028" },
  // { label: "Tunguan", mun_code: "063028" },
  // { label: "Tu-og", mun_code: "063028" },
  // { label: "Abay", mun_code: "063029" },
  // { label: "Abilay", mun_code: "063029" },
  // {
  //   label: "AGROCEL Pob. (Aguinaldo-Roxas--Celso Mayor)",
  //   mun_code: "063029",
  // },
  // { label: "Amerang", mun_code: "063029" },
  // { label: "Bagacay East", mun_code: "063029" },
  // { label: "Bagacay West", mun_code: "063029" },
  // { label: "Bug-ot", mun_code: "063029" },
  // { label: "Bolo", mun_code: "063029" },
  // { label: "Bulay", mun_code: "063029" },
  // { label: "Buntalan", mun_code: "063029" },
  // { label: "Burak", mun_code: "063029" },
  // { label: "Cabangcalan", mun_code: "063029" },
  // { label: "Cabatac", mun_code: "063029" },
  // { label: "Caigon", mun_code: "063029" },
  // { label: "Cananghan", mun_code: "063029" },
  // { label: "Canawili", mun_code: "063029" },
  // { label: "Dagami", mun_code: "063029" },
  // { label: "Daja", mun_code: "063029" },
  // { label: "Dalusan", mun_code: "063029" },
  // { label: "DELCAR Pob. (Delgado-Cartagena)", mun_code: "063029" },
  // { label: "Inabasan", mun_code: "063029" },
  // { label: "Layog", mun_code: "063029" },
  // { label: "Linab", mun_code: "063029" },
  // { label: "MARI Pob. (Mabini-Rizal)", mun_code: "063029" },
  // { label: "Magsaysay", mun_code: "063029" },
  // { label: "Mandog", mun_code: "063029" },
  // { label: "Miapa", mun_code: "063029" },
  // { label: "Nagba", mun_code: "063029" },
  // { label: "Nasaka", mun_code: "063029" },
  // { label: "Naslo-Bucao", mun_code: "063029" },
  // { label: "Nasuli", mun_code: "063029" },
  // { label: "Panalian", mun_code: "063029" },
  // { label: "Piandaan East", mun_code: "063029" },
  // { label: "Piandaan West", mun_code: "063029" },
  // { label: "Pispis", mun_code: "063029" },
  // { label: "Punong", mun_code: "063029" },
  // { label: "Sinubsuban", mun_code: "063029" },
  // { label: "Siwalo", mun_code: "063029" },
  // { label: "Santa Rita", mun_code: "063029" },
  // { label: "Subog", mun_code: "063029" },
  // {
  //   label: "THTP Pob. (Taft-Thompson--Hughes-del Pilar)",
  //   mun_code: "063029",
  // },
  // { label: "Tigbauan", mun_code: "063029" },
  // { label: "Trangka", mun_code: "063029" },
  // { label: "Tubang", mun_code: "063029" },
  // { label: "Tulahong", mun_code: "063029" },
  // { label: "Tuy-an East", mun_code: "063029" },
  // { label: "Tuy-an West", mun_code: "063029" },
  // { label: "Ubian", mun_code: "063029" },
  // { label: "Agdum", mun_code: "063030" },
  // { label: "Aguiauan", mun_code: "063030" },
  // { label: "Alimodias", mun_code: "063030" },
  // { label: "Awang", mun_code: "063030" },
  // { label: "Oya-oy", mun_code: "063030" },
  // { label: "Bacauan", mun_code: "063030" },
  // { label: "Bacolod", mun_code: "063030" },
  // { label: "Bagumbayan", mun_code: "063030" },
  // { label: "Banbanan", mun_code: "063030" },
  // { label: "Banga", mun_code: "063030" },
  // { label: "Bangladan", mun_code: "063030" },
  // { label: "Banuyao", mun_code: "063030" },
  // { label: "Baraclayan", mun_code: "063030" },
  // { label: "Bariri", mun_code: "063030" },
  // { label: "Baybay Norte (Pob.)", mun_code: "063030" },
  // { label: "Baybay Sur (Pob.)", mun_code: "063030" },
  // { label: "Belen", mun_code: "063030" },
  // { label: "Bolho (Pob.)", mun_code: "063030" },
  // { label: "Bolocaue", mun_code: "063030" },
  // { label: "Buenavista Norte", mun_code: "063030" },
  // { label: "Buenavista Sur", mun_code: "063030" },
  // { label: "Bugtong Lumangan", mun_code: "063030" },
  // { label: "Bugtong Naulid", mun_code: "063030" },
  // { label: "Cabalaunan", mun_code: "063030" },
  // { label: "Cabangcalan", mun_code: "063030" },
  // { label: "Cabunotan", mun_code: "063030" },
  // { label: "Cadoldolan", mun_code: "063030" },
  // { label: "Cagbang", mun_code: "063030" },
  // { label: "Caitib", mun_code: "063030" },
  // { label: "Calagtangan", mun_code: "063030" },
  // { label: "Calampitao", mun_code: "063030" },
  // { label: "Cavite", mun_code: "063030" },
  // { label: "Cawayanan", mun_code: "063030" },
  // { label: "Cubay", mun_code: "063030" },
  // { label: "Cubay Ubos", mun_code: "063030" },
  // { label: "Dalije", mun_code: "063030" },
  // { label: "Damilisan", mun_code: "063030" },
  // { label: "Dawog", mun_code: "063030" },
  // { label: "Diday", mun_code: "063030" },
  // { label: "Dingle", mun_code: "063030" },
  // { label: "Durog", mun_code: "063030" },
  // { label: "Frantilla", mun_code: "063030" },
  // { label: "Fundacion", mun_code: "063030" },
  // { label: "Gines", mun_code: "063030" },
  // { label: "Guibongan", mun_code: "063030" },
  // { label: "Igbita", mun_code: "063030" },
  // { label: "Igbugo", mun_code: "063030" },
  // { label: "Igcabidio", mun_code: "063030" },
  // { label: "Igcabito-on", mun_code: "063030" },
  // { label: "Igcatambor", mun_code: "063030" },
  // { label: "Igdalaquit", mun_code: "063030" },
  // { label: "Igdulaca", mun_code: "063030" },
  // { label: "Igpajo", mun_code: "063030" },
  // { label: "Igpandan", mun_code: "063030" },
  // { label: "Igpuro", mun_code: "063030" },
  // { label: "Igpuro-Bariri", mun_code: "063030" },
  // { label: "Igsoligue", mun_code: "063030" },
  // { label: "Igtuba", mun_code: "063030" },
  // { label: "Ilog-ilog", mun_code: "063030" },
  // { label: "Indag-an", mun_code: "063030" },
  // { label: "Kirayan Norte", mun_code: "063030" },
  // { label: "Kirayan Sur", mun_code: "063030" },
  // { label: "Kirayan Tacas", mun_code: "063030" },
  // { label: "La Consolacion", mun_code: "063030" },
  // { label: "Lacadon", mun_code: "063030" },
  // { label: "Lanutan", mun_code: "063030" },
  // { label: "Lumangan", mun_code: "063030" },
  // { label: "Mabayan", mun_code: "063030" },
  // { label: "Maduyo", mun_code: "063030" },
  // { label: "Malagyan", mun_code: "063030" },
  // { label: "Mambatad", mun_code: "063030" },
  // { label: "Maninila", mun_code: "063030" },
  // { label: "Maricolcol", mun_code: "063030" },
  // { label: "Maringyan", mun_code: "063030" },
  // { label: "Mat-y (Pob.)", mun_code: "063030" },
  // { label: "Matalngon", mun_code: "063030" },
  // { label: "Naclub", mun_code: "063030" },
  // { label: "Nam-o Sur", mun_code: "063030" },
  // { label: "Nam-o Norte", mun_code: "063030" },
  // { label: "Narat-an", mun_code: "063030" },
  // { label: "Narorogan", mun_code: "063030" },
  // { label: "Naulid", mun_code: "063030" },
  // { label: "Olango", mun_code: "063030" },
  // { label: "Ongyod", mun_code: "063030" },
  // { label: "Onop", mun_code: "063030" },
  // { label: "Oyungan", mun_code: "063030" },
  // { label: "Palaca", mun_code: "063030" },
  // { label: "Paro-on", mun_code: "063030" },
  // { label: "Potrido", mun_code: "063030" },
  // { label: "Pudpud", mun_code: "063030" },
  // { label: "Pungtod Monteclaro", mun_code: "063030" },
  // { label: "Pungtod Naulid", mun_code: "063030" },
  // { label: "Sag-on", mun_code: "063030" },
  // { label: "San Fernando", mun_code: "063030" },
  // { label: "San Jose", mun_code: "063030" },
  // { label: "San Rafael", mun_code: "063030" },
  // { label: "Sapa (Miagao)", mun_code: "063030" },
  // { label: "Saring", mun_code: "063030" },
  // { label: "Sibucao", mun_code: "063030" },
  // { label: "Taal", mun_code: "063030" },
  // { label: "Tabunacan", mun_code: "063030" },
  // { label: "Tacas (Pob.)", mun_code: "063030" },
  // { label: "Tambong", mun_code: "063030" },
  // { label: "Tan-agan", mun_code: "063030" },
  // { label: "Tatoy", mun_code: "063030" },
  // { label: "Ticdalan", mun_code: "063030" },
  // { label: "Tig-amaga", mun_code: "063030" },
  // { label: "Tig-Apog-Apog", mun_code: "063030" },
  // { label: "Tigbagacay", mun_code: "063030" },
  // { label: "Tiglawa", mun_code: "063030" },
  // { label: "Tigmalapad", mun_code: "063030" },
  // { label: "Tigmarabo", mun_code: "063030" },
  // { label: "To-og", mun_code: "063030" },
  // { label: "Tugura-ao", mun_code: "063030" },
  // { label: "Tumagboc", mun_code: "063030" },
  // { label: "Ubos Ilawod (Pob.)", mun_code: "063030" },
  // { label: "Ubos Ilaya (Pob.)", mun_code: "063030" },
  // { label: "Valencia", mun_code: "063030" },
  // { label: "Wayang", mun_code: "063030" },
  // { label: "Abat", mun_code: "063031" },
  // { label: "Agmanaphao", mun_code: "063031" },
  // { label: "Amiroy", mun_code: "063031" },
  // { label: "Badiangan", mun_code: "063031" },
  // { label: "Bangac", mun_code: "063031" },
  // { label: "Cabalabaguan", mun_code: "063031" },
  // { label: "Capul-an", mun_code: "063031" },
  // { label: "Dala", mun_code: "063031" },
  // { label: "Guibuangan", mun_code: "063031" },
  // { label: "Janipa-an West", mun_code: "063031" },
  // { label: "Janipa-an East", mun_code: "063031" },
  // { label: "Mina East (Pob.)", mun_code: "063031" },
  // { label: "Mina West (Pob.)", mun_code: "063031" },
  // { label: "Nasirum", mun_code: "063031" },
  // { label: "Naumuan", mun_code: "063031" },
  // { label: "Singay", mun_code: "063031" },
  // { label: "Talibong Grande", mun_code: "063031" },
  // { label: "Tipolo", mun_code: "063031" },
  // { label: "Tolarucan", mun_code: "063031" },
  // { label: "Tumay", mun_code: "063031" },
  // { label: "Yugot", mun_code: "063031" },
  // { label: "Baclayan", mun_code: "063032" },
  // { label: "Badiang", mun_code: "063032" },
  // { label: "Balabag", mun_code: "063032" },
  // { label: "Bilidan", mun_code: "063032" },
  // { label: "Bita-og Gaja", mun_code: "063032" },
  // { label: "Bololacao", mun_code: "063032" },
  // { label: "Burot", mun_code: "063032" },
  // { label: "Cabilauan", mun_code: "063032" },
  // { label: "Cabugao", mun_code: "063032" },
  // { label: "Cagban", mun_code: "063032" },
  // { label: "Calumbuyan", mun_code: "063032" },
  // { label: "Damires", mun_code: "063032" },
  // { label: "Dawis", mun_code: "063032" },
  // { label: "General Delgado", mun_code: "063032" },
  // { label: "Guinobatan", mun_code: "063032" },
  // { label: "Janipa-an Oeste", mun_code: "063032" },
  // { label: "Jelicuon Este", mun_code: "063032" },
  // { label: "Jelicuon Oeste", mun_code: "063032" },
  // { label: "Pasil", mun_code: "063032" },
  // { label: "Poblacion", mun_code: "063032" },
  // { label: "Wari-wari", mun_code: "063032" },
  // { label: "Abilay Norte", mun_code: "063034" },
  // { label: "Abilay Sur", mun_code: "063034" },
  // { label: "Alegre", mun_code: "063034" },
  // { label: "Batuan Ilaud", mun_code: "063034" },
  // { label: "Batuan Ilaya", mun_code: "063034" },
  // { label: "Bita Norte", mun_code: "063034" },
  // { label: "Bita Sur", mun_code: "063034" },
  // { label: "Botong", mun_code: "063034" },
  // { label: "Buray", mun_code: "063034" },
  // { label: "Cabanbanan", mun_code: "063034" },
  // { label: "Caboloan Norte", mun_code: "063034" },
  // { label: "Caboloan Sur", mun_code: "063034" },
  // { label: "Cadinglian", mun_code: "063034" },
  // { label: "Cagbang", mun_code: "063034" },
  // { label: "Calam-isan", mun_code: "063034" },
  // { label: "Galang", mun_code: "063034" },
  // { label: "Lambuyao", mun_code: "063034" },
  // { label: "Mambog", mun_code: "063034" },
  // { label: "Pakiad", mun_code: "063034" },
  // { label: "Poblacion East", mun_code: "063034" },
  // { label: "Poblacion North", mun_code: "063034" },
  // { label: "Poblacion South", mun_code: "063034" },
  // { label: "Poblacion West", mun_code: "063034" },
  // { label: "Polo Maestra Bita", mun_code: "063034" },
  // { label: "Rizal", mun_code: "063034" },
  // { label: "Salngan", mun_code: "063034" },
  // { label: "Sambaludan", mun_code: "063034" },
  // { label: "San Antonio", mun_code: "063034" },
  // { label: "San Nicolas", mun_code: "063034" },
  // { label: "Santa Clara", mun_code: "063034" },
  // { label: "Santa Monica", mun_code: "063034" },
  // { label: "Santa Rita", mun_code: "063034" },
  // { label: "Tagbac Norte", mun_code: "063034" },
  // { label: "Tagbac Sur", mun_code: "063034" },
  // { label: "Trapiche", mun_code: "063034" },
  // { label: "Tuburan", mun_code: "063034" },
  // { label: "Turog-Turog", mun_code: "063034" },
  // { label: "Agdahon", mun_code: "063035" },
  // { label: "Agdayao", mun_code: "063035" },
  // { label: "Aglalana", mun_code: "063035" },
  // { label: "Agtabo", mun_code: "063035" },
  // { label: "Agtambo", mun_code: "063035" },
  // { label: "Alimono", mun_code: "063035" },
  // { label: "Arac", mun_code: "063035" },
  // { label: "Ayuyan", mun_code: "063035" },
  // { label: "Bacuranan", mun_code: "063035" },
  // { label: "Bagacay", mun_code: "063035" },
  // { label: "Batu", mun_code: "063035" },
  // { label: "Bayan", mun_code: "063035" },
  // { label: "Bitaogan", mun_code: "063035" },
  // { label: "Buenavista", mun_code: "063035" },
  // { label: "Buyo", mun_code: "063035" },
  // { label: "Cabunga", mun_code: "063035" },
  // { label: "Cadilang", mun_code: "063035" },
  // { label: "Cairohan", mun_code: "063035" },
  // { label: "Dalicanan", mun_code: "063035" },
  // { label: "Gemat-y", mun_code: "063035" },
  // { label: "Gemumua-agahon", mun_code: "063035" },
  // { label: "Gegachac", mun_code: "063035" },
  // { label: "Gines Viejo", mun_code: "063035" },
  // { label: "Imbang Grande", mun_code: "063035" },
  // { label: "Jaguimitan", mun_code: "063035" },
  // { label: "Libo-o", mun_code: "063035" },
  // { label: "Maasin", mun_code: "063035" },
  // { label: "Magdungao", mun_code: "063035" },
  // { label: "Malag-it Grande", mun_code: "063035" },
  // { label: "Mambiranan Grande", mun_code: "063035" },
  // { label: "Man-it", mun_code: "063035" },
  // { label: "Mantulang", mun_code: "063035" },
  // { label: "Mulapula", mun_code: "063035" },
  // { label: "Nueva Union", mun_code: "063035" },
  // { label: "Pangi", mun_code: "063035" },
  // { label: "Pagaypay", mun_code: "063035" },
  // { label: "Poblacion Ilawod", mun_code: "063035" },
  // { label: "Poblacion Ilaya", mun_code: "063035" },
  // { label: "Punong", mun_code: "063035" },
  // { label: "Quinagaringan Grande", mun_code: "063035" },
  // { label: "Sablogon", mun_code: "063035" },
  // { label: "Salngan", mun_code: "063035" },
  // { label: "Santo Tomas", mun_code: "063035" },
  // { label: "Sarapan", mun_code: "063035" },
  // { label: "Tagubong", mun_code: "063035" },
  // { label: "Talongonan", mun_code: "063035" },
  // { label: "Tubod", mun_code: "063035" },
  // { label: "Tuburan", mun_code: "063035" },
  // { label: "Aganan", mun_code: "063036" },
  // { label: "Amparo", mun_code: "063036" },
  // { label: "Anilao", mun_code: "063036" },
  // { label: "Balabag", mun_code: "063036" },
  // { label: "Purok I (Pob.)", mun_code: "063036" },
  // { label: "Purok II (Pob.)", mun_code: "063036" },
  // { label: "Purok III (Pob.)", mun_code: "063036" },
  // { label: "Purok IV (Pob.)", mun_code: "063036" },
  // { label: "Cabugao Norte", mun_code: "063036" },
  // { label: "Cabugao Sur", mun_code: "063036" },
  // { label: "Jibao-an", mun_code: "063036" },
  // { label: "Mali-ao", mun_code: "063036" },
  // { label: "Pagsanga-an", mun_code: "063036" },
  // { label: "Pandac", mun_code: "063036" },
  // { label: "Tigum", mun_code: "063036" },
  // { label: "Ungka I", mun_code: "063036" },
  // { label: "Ungka II", mun_code: "063036" },
  // { label: "Pal-agon", mun_code: "063036" },
  // { label: "Abangay", mun_code: "063037" },
  // { label: "Amamaros", mun_code: "063037" },
  // { label: "Bagacay", mun_code: "063037" },
  // { label: "Barasan", mun_code: "063037" },
  // { label: "Batuan", mun_code: "063037" },
  // { label: "Bongco", mun_code: "063037" },
  // { label: "Cahaguichican", mun_code: "063037" },
  // { label: "Callan", mun_code: "063037" },
  // { label: "Cansilayan", mun_code: "063037" },
  // { label: "Casalsagan", mun_code: "063037" },
  // { label: "Cato-ogan", mun_code: "063037" },
  // { label: "Cau-ayan", mun_code: "063037" },
  // { label: "Culob", mun_code: "063037" },
  // { label: "Danao", mun_code: "063037" },
  // { label: "Dapitan", mun_code: "063037" },
  // { label: "Dawis", mun_code: "063037" },
  // { label: "Dongsol", mun_code: "063037" },
  // { label: "Fundacion", mun_code: "063037" },
  // { label: "Guinacas", mun_code: "063037" },
  // { label: "Guibuangan", mun_code: "063037" },
  // { label: "Igang", mun_code: "063037" },
  // { label: "Intaluan", mun_code: "063037" },
  // { label: "Iwa Ilaud", mun_code: "063037" },
  // { label: "Iwa Ilaya", mun_code: "063037" },
  // { label: "Jamabalud", mun_code: "063037" },
  // { label: "Jebioc", mun_code: "063037" },
  // { label: "Lay-Ahan", mun_code: "063037" },
  // { label: "Primitivo Ledesma Ward (Pob.)", mun_code: "063037" },
  // { label: "Lopez Jaena Ward (Pob.)", mun_code: "063037" },
  // { label: "Lumbo", mun_code: "063037" },
  // { label: "Macatol", mun_code: "063037" },
  // { label: "Malusgod", mun_code: "063037" },
  // { label: "Naslo", mun_code: "063037" },
  // { label: "Nabitasan", mun_code: "063037" },
  // { label: "Naga", mun_code: "063037" },
  // { label: "Nanga", mun_code: "063037" },
  // { label: "Pajo", mun_code: "063037" },
  // { label: "Palanguia", mun_code: "063037" },
  // { label: "Fernando Parcon Ward (Pob.)", mun_code: "063037" },
  // { label: "Pitogo", mun_code: "063037" },
  // { label: "Polot-an", mun_code: "063037" },
  // { label: "Purog", mun_code: "063037" },
  // { label: "Rumbang", mun_code: "063037" },
  // { label: "San Jose Ward (Pob.)", mun_code: "063037" },
  // { label: "Sinuagan", mun_code: "063037" },
  // { label: "Tuburan", mun_code: "063037" },
  // { label: "Tumcon Ilaya", mun_code: "063037" },
  // { label: "Tumcon Ilaud", mun_code: "063037" },
  // { label: "Ubang", mun_code: "063037" },
  // { label: "Zarrague", mun_code: "063037" },
  // { label: "Agdaliran", mun_code: "063038" },
  // { label: "Amayong", mun_code: "063038" },
  // { label: "Bagacay", mun_code: "063038" },
  // { label: "Batuan", mun_code: "063038" },
  // { label: "Bondulan", mun_code: "063038" },
  // { label: "Canas", mun_code: "063038" },
  // { label: "Capinang", mun_code: "063038" },
  // { label: "Cubay", mun_code: "063038" },
  // { label: "Cudionan", mun_code: "063038" },
  // { label: "Dugman", mun_code: "063038" },
  // { label: "Hacienda Conchita", mun_code: "063038" },
  // { label: "Madanlog", mun_code: "063038" },
  // { label: "Mandu-awak", mun_code: "063038" },
  // { label: "Moto", mun_code: "063038" },
  // { label: "Naborot", mun_code: "063038" },
  // { label: "Nipa", mun_code: "063038" },
  // { label: "Odiongan", mun_code: "063038" },
  // { label: "Pangi", mun_code: "063038" },
  // { label: "Pase", mun_code: "063038" },
  // { label: "Poblacion", mun_code: "063038" },
  // { label: "San Nicolas", mun_code: "063038" },
  // { label: "Santol", mun_code: "063038" },
  // { label: "Siempreviva", mun_code: "063038" },
  // { label: "Sua", mun_code: "063038" },
  // { label: "Talo-ato", mun_code: "063038" },
  // { label: "Tamangi", mun_code: "063038" },
  // { label: "Tiabas", mun_code: "063038" },
  // { label: "Tuble", mun_code: "063038" },
  // { label: "Abaca", mun_code: "063039" },
  // { label: "Asisig", mun_code: "063039" },
  // { label: "Bantayan", mun_code: "063039" },
  // { label: "Braulan", mun_code: "063039" },
  // { label: "Cabugao Nuevo", mun_code: "063039" },
  // { label: "Cabugao Viejo", mun_code: "063039" },
  // { label: "Camiri", mun_code: "063039" },
  // { label: "Compo", mun_code: "063039" },
  // { label: "Catan-Agan", mun_code: "063039" },
  // { label: "Cubay", mun_code: "063039" },
  // { label: "Dacal", mun_code: "063039" },
  // { label: "Dumiles", mun_code: "063039" },
  // { label: "Garita", mun_code: "063039" },
  // { label: "Gines Nuevo", mun_code: "063039" },
  // { label: "Imbesad-an", mun_code: "063039" },
  // { label: "Iprog", mun_code: "063039" },
  // { label: "Lip-ac", mun_code: "063039" },
  // { label: "Madarag", mun_code: "063039" },
  // { label: "Mapili", mun_code: "063039" },
  // { label: "Paga", mun_code: "063039" },
  // { label: "Palje", mun_code: "063039" },
  // { label: "Poblacion Ilawod", mun_code: "063039" },
  // { label: "Poblacion Ilaya", mun_code: "063039" },
  // { label: "Quinolpan", mun_code: "063039" },
  // { label: "Rumagayray", mun_code: "063039" },
  // { label: "San Antonio", mun_code: "063039" },
  // { label: "Tambunac", mun_code: "063039" },
  // { label: "Amboyu-an", mun_code: "063040" },
  // { label: "Andres Bonifacio", mun_code: "063040" },
  // { label: "Antalon", mun_code: "063040" },
  // { label: "Bad-as", mun_code: "063040" },
  // { label: "Bagumbayan", mun_code: "063040" },
  // { label: "Balabago", mun_code: "063040" },
  // { label: "Baybay", mun_code: "063040" },
  // { label: "Bayunan (Panday Oro)", mun_code: "063040" },
  // { label: "Bolbogan", mun_code: "063040" },
  // { label: "Bulho", mun_code: "063040" },
  // { label: "Bucaya", mun_code: "063040" },
  // { label: "Cadluman", mun_code: "063040" },
  // { label: "Cadoldolan", mun_code: "063040" },
  // { label: "Camia", mun_code: "063040" },
  // { label: "Camaba-an", mun_code: "063040" },
  // { label: "Cata-an", mun_code: "063040" },
  // { label: "Crossing Dapuyan", mun_code: "063040" },
  // { label: "Cubay", mun_code: "063040" },
  // { label: "Cumarascas", mun_code: "063040" },
  // { label: "Dacdacanan", mun_code: "063040" },
  // { label: "Danawan", mun_code: "063040" },
  // { label: "Doldol", mun_code: "063040" },
  // { label: "Dongoc", mun_code: "063040" },
  // { label: "Escalantera", mun_code: "063040" },
  // { label: "Ginot-an", mun_code: "063040" },
  // { label: "Huna", mun_code: "063040" },
  // { label: "Igbaje", mun_code: "063040" },
  // { label: "Igbangcal", mun_code: "063040" },
  // { label: "Igbinangon", mun_code: "063040" },
  // { label: "Igburi", mun_code: "063040" },
  // { label: "Igcabutong", mun_code: "063040" },
  // { label: "Igcadlum", mun_code: "063040" },
  // { label: "Igcaphang", mun_code: "063040" },
  // { label: "Igcaratong", mun_code: "063040" },
  // { label: "Igcondao", mun_code: "063040" },
  // { label: "Igcores", mun_code: "063040" },
  // { label: "Igdagmay", mun_code: "063040" },
  // { label: "Igdomingding", mun_code: "063040" },
  // { label: "Iglilico", mun_code: "063040" },
  // { label: "Igpayong", mun_code: "063040" },
  // { label: "Jawod", mun_code: "063040" },
  // { label: "Langca", mun_code: "063040" },
  // { label: "Languanan", mun_code: "063040" },
  // { label: "Lawigan", mun_code: "063040" },
  // { label: "Lomboy", mun_code: "063040" },
  // { label: "Lopez Vito", mun_code: "063040" },
  // { label: "Mabini Norte", mun_code: "063040" },
  // { label: "Mabini Sur", mun_code: "063040" },
  // { label: "Manhara", mun_code: "063040" },
  // { label: "Maninila", mun_code: "063040" },
  // { label: "Masagud", mun_code: "063040" },
  // { label: "Matambog", mun_code: "063040" },
  // { label: "Mayunoc", mun_code: "063040" },
  // { label: "Montinola", mun_code: "063040" },
  // { label: "Nagquirisan", mun_code: "063040" },
  // { label: "Nadsadan", mun_code: "063040" },
  // { label: "Nagsipit", mun_code: "063040" },
  // { label: "New Gumawan", mun_code: "063040" },
  // { label: "Panatan", mun_code: "063040" },
  // { label: "Pitogo", mun_code: "063040" },
  // { label: "Purok 1 (Pob.)", mun_code: "063040" },
  // { label: "Purok 2 (Pob.)", mun_code: "063040" },
  // { label: "Purok 3 (Pob.)", mun_code: "063040" },
  // { label: "Purok 4 (Pob.)", mun_code: "063040" },
  // { label: "Purok 5 (Pob.)", mun_code: "063040" },
  // { label: "Qui-anan", mun_code: "063040" },
  // { label: "Roma", mun_code: "063040" },
  // { label: "San Luis", mun_code: "063040" },
  // { label: "San Mateo Norte", mun_code: "063040" },
  // { label: "San Mateo Sur", mun_code: "063040" },
  // { label: "Santiago", mun_code: "063040" },
  // { label: "Sinogbuhan", mun_code: "063040" },
  // { label: "Siwaragan", mun_code: "063040" },
  // { label: "Lomboyan (Santa Ana)", mun_code: "063040" },
  // { label: "Santa Rita", mun_code: "063040" },
  // { label: "Talagutac", mun_code: "063040" },
  // { label: "Tapikan", mun_code: "063040" },
  // { label: "Taslan", mun_code: "063040" },
  // { label: "Tiglawa", mun_code: "063040" },
  // { label: "Tiolas", mun_code: "063040" },
  // { label: "To-og", mun_code: "063040" },
  // { label: "Torocadan", mun_code: "063040" },
  // { label: "Ulay", mun_code: "063040" },
  // { label: "Bonga", mun_code: "063040" },
  // { label: "Guibongan Bayunan", mun_code: "063040" },
  // { label: "Bgy. 1 Pob. (Roxas St.)", mun_code: "063041" },
  // { label: "Bgy. 10 (Pob. R.V.Sanchez St. South)", mun_code: "063041" },
  // { label: "Bgy. 11 Pob. (R.V.Sanchez St. North)", mun_code: "063041" },
  // { label: "Bgy. 12 Pob. (Sales Malaga Saliedo)", mun_code: "063041" },
  // { label: "Bgy. 13 Pob. (Sta.Rita-Saclauso St.)", mun_code: "063041" },
  // { label: "Bgy. 14 Pob. (San Miguel North)", mun_code: "063041" },
  // { label: "Bgy. 15 Pob. (San Miguel South)", mun_code: "063041" },
  // { label: "Bgy. 16 Pob. (San Agustin St.)", mun_code: "063041" },
  // { label: "Bgy. 2 Pob. (Savilla Sto. Rosario)", mun_code: "063041" },
  // { label: "Bgy. 3 Pob. (A.S.Suarez St. Zone 1)", mun_code: "063041" },
  // { label: "Bgy. 4 Pob. (A.S.Suarez East)", mun_code: "063041" },
  // { label: "Bgy. 5 Pob. (Santiago St.North)", mun_code: "063041" },
  // { label: "Bgy. 6 Pob. (Santiago St.South)", mun_code: "063041" },
  // { label: "Bgy. 7 Pob. (San Roque St.South)", mun_code: "063041" },
  // { label: "Bgy. 8 Pob. (Montano - San Roque)", mun_code: "063041" },
  // { label: "Bgy. 9 Pob. (Salazar San Jose)", mun_code: "063041" },
  // { label: "Consolacion", mun_code: "063041" },
  // { label: "Igtambo", mun_code: "063041" },
  // { label: "San Antonio", mun_code: "063041" },
  // { label: "San Jose", mun_code: "063041" },
  // { label: "Santa Cruz", mun_code: "063041" },
  // { label: "Santa Teresa", mun_code: "063041" },
  // { label: "Santo Angel", mun_code: "063041" },
  // { label: "Aripdip", mun_code: "063042" },
  // { label: "Bagacay", mun_code: "063042" },
  // { label: "Calaigang", mun_code: "063042" },
  // { label: "Ilongbukid", mun_code: "063042" },
  // { label: "Poscolon", mun_code: "063042" },
  // { label: "San Andres", mun_code: "063042" },
  // { label: "San Dionisio", mun_code: "063042" },
  // { label: "San Florentino", mun_code: "063042" },
  // { label: "Poblacion", mun_code: "063042" },
  // { label: "Agusipan", mun_code: "063043" },
  // { label: "Agutayan", mun_code: "063043" },
  // { label: "Bagumbayan", mun_code: "063043" },
  // { label: "Balabag", mun_code: "063043" },
  // { label: "Balibagan Este", mun_code: "063043" },
  // { label: "Balibagan Oeste", mun_code: "063043" },
  // { label: "Ban-ag", mun_code: "063043" },
  // { label: "Bantay", mun_code: "063043" },
  // { label: "Barangay Zone I (Pob.)", mun_code: "063043" },
  // { label: "Barangay Zone II (Pob.)", mun_code: "063043" },
  // { label: "Barangay Zone III (Pob.)", mun_code: "063043" },
  // { label: "Barangay Zone IV (Pob.)", mun_code: "063043" },
  // { label: "Barangay Zone V (Pob.)", mun_code: "063043" },
  // { label: "Barasan Este", mun_code: "063043" },
  // { label: "Barasan Oeste", mun_code: "063043" },
  // { label: "Binangkilan", mun_code: "063043" },
  // { label: "Bitaog-Taytay", mun_code: "063043" },
  // { label: "Bolong Este", mun_code: "063043" },
  // { label: "Bolong Oeste", mun_code: "063043" },
  // { label: "Buayahon", mun_code: "063043" },
  // { label: "Buyo", mun_code: "063043" },
  // { label: "Cabugao Norte", mun_code: "063043" },
  // { label: "Cabugao Sur", mun_code: "063043" },
  // { label: "Cadagmayan Norte", mun_code: "063043" },
  // { label: "Cadagmayan Sur", mun_code: "063043" },
  // { label: "Cafe", mun_code: "063043" },
  // { label: "Calaboa Este", mun_code: "063043" },
  // { label: "Calaboa Oeste", mun_code: "063043" },
  // { label: "Camambugan", mun_code: "063043" },
  // { label: "Canipayan", mun_code: "063043" },
  // { label: "Conaynay", mun_code: "063043" },
  // { label: "Daga", mun_code: "063043" },
  // { label: "Dalid", mun_code: "063043" },
  // { label: "Duyanduyan", mun_code: "063043" },
  // { label: "Gen. Martin T. Delgado", mun_code: "063043" },
  // { label: "Guno", mun_code: "063043" },
  // { label: "Inangayan", mun_code: "063043" },
  // { label: "Jibao-an", mun_code: "063043" },
  // { label: "Lacadon", mun_code: "063043" },
  // { label: "Lanag", mun_code: "063043" },
  // { label: "Lupa", mun_code: "063043" },
  // { label: "Magancina", mun_code: "063043" },
  // { label: "Malawog", mun_code: "063043" },
  // { label: "Mambuyo", mun_code: "063043" },
  // { label: "Manhayang", mun_code: "063043" },
  // { label: "Miraga-Guibuangan", mun_code: "063043" },
  // { label: "Nasugban", mun_code: "063043" },
  // { label: "Omambog", mun_code: "063043" },
  // { label: "Pal-Agon", mun_code: "063043" },
  // { label: "Pungsod", mun_code: "063043" },
  // { label: "San Sebastian", mun_code: "063043" },
  // { label: "Sangcate", mun_code: "063043" },
  // { label: "Tagsing", mun_code: "063043" },
  // { label: "Talanghauan", mun_code: "063043" },
  // { label: "Talongadian", mun_code: "063043" },
  // { label: "Tigtig", mun_code: "063043" },
  // { label: "Tungay", mun_code: "063043" },
  // { label: "Tuburan", mun_code: "063043" },
  // { label: "Tugas", mun_code: "063043" },
  // { label: "Barangay Zone VI (Pob.)", mun_code: "063043" },
  // { label: "Aguirre", mun_code: "063044" },
  // { label: "Aldeguer", mun_code: "063044" },
  // { label: "Alibayog", mun_code: "063044" },
  // { label: "Anoring", mun_code: "063044" },
  // { label: "Apelo", mun_code: "063044" },
  // { label: "Apologista", mun_code: "063044" },
  // { label: "Aposaga", mun_code: "063044" },
  // { label: "Arante", mun_code: "063044" },
  // { label: "Ardemil", mun_code: "063044" },
  // { label: "Aspera", mun_code: "063044" },
  // { label: "Aswe-Pabriaga", mun_code: "063044" },
  // { label: "Bagaygay", mun_code: "063044" },
  // { label: "Bakabak", mun_code: "063044" },
  // { label: "Batitao", mun_code: "063044" },
  // { label: "Bato", mun_code: "063044" },
  // { label: "Del Castillo", mun_code: "063044" },
  // { label: "Castor", mun_code: "063044" },
  // { label: "Crespo", mun_code: "063044" },
  // { label: "Devera", mun_code: "063044" },
  // { label: "Domingo", mun_code: "063044" },
  // { label: "Ferraris", mun_code: "063044" },
  // { label: "Gildore", mun_code: "063044" },
  // { label: "Improgo", mun_code: "063044" },
  // { label: "Juaneza", mun_code: "063044" },
  // { label: "Labigan", mun_code: "063044" },
  // { label: "Lanciola", mun_code: "063044" },
  // { label: "Latawan", mun_code: "063044" },
  // { label: "Malapaya", mun_code: "063044" },
  // { label: "Muyco", mun_code: "063044" },
  // { label: "Padios", mun_code: "063044" },
  // { label: "Pasig", mun_code: "063044" },
  // { label: "Poblacion Ilawod", mun_code: "063044" },
  // { label: "Poblacion Ilaya", mun_code: "063044" },
  // { label: "Poblacion Market", mun_code: "063044" },
  // { label: "Posadas", mun_code: "063044" },
  // { label: "Preciosa", mun_code: "063044" },
  // { label: "Salcedo", mun_code: "063044" },
  // { label: "San Luis", mun_code: "063044" },
  // { label: "Tady", mun_code: "063044" },
  // { label: "Tentay", mun_code: "063044" },
  // { label: "Villahermosa", mun_code: "063044" },
  // { label: "Zerrudo", mun_code: "063044" },
  // { label: "Alupidian", mun_code: "063045" },
  // { label: "Atabayan", mun_code: "063045" },
  // { label: "Bagacay", mun_code: "063045" },
  // { label: "Baguingin", mun_code: "063045" },
  // { label: "Bagumbayan", mun_code: "063045" },
  // { label: "Bangkal", mun_code: "063045" },
  // { label: "Bantud", mun_code: "063045" },
  // { label: "Barangay 1 (Pob.)", mun_code: "063045" },
  // { label: "Barangay 2 (Pob.)", mun_code: "063045" },
  // { label: "Barangay 3 (Pob.)", mun_code: "063045" },
  // { label: "Barangay 4 (Pob.)", mun_code: "063045" },
  // { label: "Barangay 5 (Pob.)", mun_code: "063045" },
  // { label: "Barangay 6 (Pob.)", mun_code: "063045" },
  // { label: "Barangay 7 (Pob.)", mun_code: "063045" },
  // { label: "Barangay 8 (Pob.)", mun_code: "063045" },
  // { label: "Barangay 9 (Pob.)", mun_code: "063045" },
  // { label: "Barosong", mun_code: "063045" },
  // { label: "Barroc", mun_code: "063045" },
  // { label: "Bayuco", mun_code: "063045" },
  // { label: "Binaliuan Mayor", mun_code: "063045" },
  // { label: "Binaliuan Menor", mun_code: "063045" },
  // { label: "Bitas", mun_code: "063045" },
  // { label: "Buenavista", mun_code: "063045" },
  // { label: "Bugasongan", mun_code: "063045" },
  // { label: "Buyu-an", mun_code: "063045" },
  // { label: "Canabuan", mun_code: "063045" },
  // { label: "Cansilayan", mun_code: "063045" },
  // { label: "Cordova Norte", mun_code: "063045" },
  // { label: "Cordova Sur", mun_code: "063045" },
  // { label: "Danao", mun_code: "063045" },
  // { label: "Dapdap", mun_code: "063045" },
  // { label: "Dorong-an", mun_code: "063045" },
  // { label: "Guisian", mun_code: "063045" },
  // { label: "Isawan", mun_code: "063045" },
  // { label: "Isian", mun_code: "063045" },
  // { label: "Jamog", mun_code: "063045" },
  // { label: "Lanag", mun_code: "063045" },
  // { label: "Linobayan", mun_code: "063045" },
  // { label: "Lubog", mun_code: "063045" },
  // { label: "Nagba", mun_code: "063045" },
  // { label: "Namocon", mun_code: "063045" },
  // { label: "Napnapan Norte", mun_code: "063045" },
  // { label: "Napnapan Sur", mun_code: "063045" },
  // { label: "Olo Barroc", mun_code: "063045" },
  // { label: "Parara Norte", mun_code: "063045" },
  // { label: "Parara Sur", mun_code: "063045" },
  // { label: "San Rafael", mun_code: "063045" },
  // { label: "Sermon", mun_code: "063045" },
  // { label: "Sipitan", mun_code: "063045" },
  // { label: "Supa", mun_code: "063045" },
  // { label: "Tan Pael", mun_code: "063045" },
  // { label: "Taro", mun_code: "063045" },
  // { label: "Adgao", mun_code: "063046" },
  // { label: "Ago", mun_code: "063046" },
  // { label: "Ambarihon", mun_code: "063046" },
  // { label: "Ayubo", mun_code: "063046" },
  // { label: "Bacan", mun_code: "063046" },
  // { label: "Bagunanay", mun_code: "063046" },
  // { label: "Badiang", mun_code: "063046" },
  // { label: "Balicua", mun_code: "063046" },
  // { label: "Bantayanan", mun_code: "063046" },
  // { label: "Zone I (Pob.)", mun_code: "063046" },
  // { label: "Zone II (Pob.)", mun_code: "063046" },
  // { label: "Zone III (Pob.)", mun_code: "063046" },
  // { label: "Batga", mun_code: "063046" },
  // { label: "Bato", mun_code: "063046" },
  // { label: "Bikil", mun_code: "063046" },
  // { label: "Boloc", mun_code: "063046" },
  // { label: "Bondoc", mun_code: "063046" },
  // { label: "Borong", mun_code: "063046" },
  // { label: "Buenavista", mun_code: "063046" },
  // { label: "Cadabdab", mun_code: "063046" },
  // { label: "Daga-ay", mun_code: "063046" },
  // { label: "Desposorio", mun_code: "063046" },
  // { label: "Igdampog Norte", mun_code: "063046" },
  // { label: "Igdampog Sur", mun_code: "063046" },
  // { label: "Igpaho", mun_code: "063046" },
  // { label: "Igtuble", mun_code: "063046" },
  // { label: "Ingay", mun_code: "063046" },
  // { label: "Isauan", mun_code: "063046" },
  // { label: "Jolason", mun_code: "063046" },
  // { label: "Jona", mun_code: "063046" },
  // { label: "La-ag", mun_code: "063046" },
  // { label: "Lanag Norte", mun_code: "063046" },
  // { label: "Lanag Sur", mun_code: "063046" },
  // { label: "Male", mun_code: "063046" },
  // { label: "Mayang", mun_code: "063046" },
  // { label: "Molina", mun_code: "063046" },
  // { label: "Morcillas", mun_code: "063046" },
  // { label: "Nagba", mun_code: "063046" },
  // { label: "Navillan", mun_code: "063046" },
  // { label: "Pinamacalan", mun_code: "063046" },
  // { label: "San Jose", mun_code: "063046" },
  // { label: "Sibucauan", mun_code: "063046" },
  // { label: "Singon", mun_code: "063046" },
  // { label: "Tabat", mun_code: "063046" },
  // { label: "Tagpu-an", mun_code: "063046" },
  // { label: "Talento", mun_code: "063046" },
  // { label: "Teniente Benito", mun_code: "063046" },
  // { label: "Victoria", mun_code: "063046" },
  // { label: "Balud Lilo-an", mun_code: "063047" },
  // { label: "Balud I", mun_code: "063047" },
  // { label: "Balud II", mun_code: "063047" },
  // { label: "Dawis Centro", mun_code: "063047" },
  // { label: "Dawis Norte", mun_code: "063047" },
  // { label: "Dawis Sur", mun_code: "063047" },
  // { label: "Gines", mun_code: "063047" },
  // { label: "Inagdangan Centro", mun_code: "063047" },
  // { label: "Inagdangan Norte", mun_code: "063047" },
  // { label: "Inagdangan Sur", mun_code: "063047" },
  // { label: "Jalaud Norte", mun_code: "063047" },
  // { label: "Jalaud Sur", mun_code: "063047" },
  // { label: "Libongcogon", mun_code: "063047" },
  // { label: "Malunang", mun_code: "063047" },
  // { label: "Pajo", mun_code: "063047" },
  // { label: "Ilawod Poblacion", mun_code: "063047" },
  // { label: "Ilaya Poblacion", mun_code: "063047" },
  // { label: "Sambag", mun_code: "063047" },
  // { label: "Sigangao", mun_code: "063047" },
  // { label: "Talauguis", mun_code: "063047" },
  // { label: "Talibong", mun_code: "063047" },
  // { label: "Tubigan", mun_code: "063047" },
  // { label: "Tuburan", mun_code: "063047" },
  // { label: "Tuburan Sulbod", mun_code: "063047" },
  { label: "Alangilan", mun_code: "064501" },
  { label: "Alijis", mun_code: "064501" },
  { label: "Banago", mun_code: "064501" },
  { label: "Barangay 1 (Pob.)", mun_code: "064501" },
  { label: "Barangay 10 (Pob.)", mun_code: "064501" },
  { label: "Barangay 11 (Pob.)", mun_code: "064501" },
  { label: "Barangay 12 (Pob.)", mun_code: "064501" },
  { label: "Barangay 13 (Pob.)", mun_code: "064501" },
  { label: "Barangay 14 (Pob.)", mun_code: "064501" },
  { label: "Barangay 15 (Pob.)", mun_code: "064501" },
  { label: "Barangay 16 (Pob.)", mun_code: "064501" },
  { label: "Barangay 17 (Pob.)", mun_code: "064501" },
  { label: "Barangay 18 (Pob.)", mun_code: "064501" },
  { label: "Barangay 19 (Pob.)", mun_code: "064501" },
  { label: "Barangay 2 (Pob.)", mun_code: "064501" },
  { label: "Barangay 20 (Pob.)", mun_code: "064501" },
  { label: "Barangay 21 (Pob.)", mun_code: "064501" },
  { label: "Barangay 22 (Pob.)", mun_code: "064501" },
  { label: "Barangay 23 (Pob.)", mun_code: "064501" },
  { label: "Barangay 24 (Pob.)", mun_code: "064501" },
  { label: "Barangay 25 (Pob.)", mun_code: "064501" },
  { label: "Barangay 26 (Pob.)", mun_code: "064501" },
  { label: "Barangay 27 (Pob.)", mun_code: "064501" },
  { label: "Barangay 28 (Pob.)", mun_code: "064501" },
  { label: "Barangay 29 (Pob.)", mun_code: "064501" },
  { label: "Barangay 3 (Pob.)", mun_code: "064501" },
  { label: "Barangay 30 (Pob.)", mun_code: "064501" },
  { label: "Barangay 31 (Pob.)", mun_code: "064501" },
  { label: "Barangay 32 (Pob.)", mun_code: "064501" },
  { label: "Barangay 33 (Pob.)", mun_code: "064501" },
  { label: "Barangay 34 (Pob.)", mun_code: "064501" },
  { label: "Barangay 35 (Pob.)", mun_code: "064501" },
  { label: "Barangay 36 (Pob.)", mun_code: "064501" },
  { label: "Barangay 37 (Pob.)", mun_code: "064501" },
  { label: "Barangay 38 (Pob.)", mun_code: "064501" },
  { label: "Barangay 39 (Pob.)", mun_code: "064501" },
  { label: "Barangay 4 (Pob.)", mun_code: "064501" },
  { label: "Barangay 40 (Pob.)", mun_code: "064501" },
  { label: "Barangay 41 (Pob.)", mun_code: "064501" },
  { label: "Barangay 5 (Pob.)", mun_code: "064501" },
  { label: "Barangay 6 (Pob.)", mun_code: "064501" },
  { label: "Barangay 7 (Pob.)", mun_code: "064501" },
  { label: "Barangay 8 (Pob.)", mun_code: "064501" },
  { label: "Barangay 9 (Pob.)", mun_code: "064501" },
  { label: "Bata", mun_code: "064501" },
  { label: "Cabug", mun_code: "064501" },
  { label: "Estefania", mun_code: "064501" },
  { label: "Felisa", mun_code: "064501" },
  { label: "Granada", mun_code: "064501" },
  { label: "Mandalagan", mun_code: "064501" },
  { label: "Mansilingan", mun_code: "064501" },
  { label: "Montevista", mun_code: "064501" },
  { label: "Pahanocoy", mun_code: "064501" },
  { label: "Punta Taytay", mun_code: "064501" },
  { label: "Singcang-Airport", mun_code: "064501" },
  { label: "Sum-ag", mun_code: "064501" },
  { label: "Taculing", mun_code: "064501" },
  { label: "Tangub", mun_code: "064501" },
  { label: "Villamonte", mun_code: "064501" },
  { label: "Vista Alegre", mun_code: "064501" },
  { label: "Handumanan", mun_code: "064501" },
  { label: "Abuanan", mun_code: "064502" },
  { label: "Alianza", mun_code: "064502" },
  { label: "Atipuluan", mun_code: "064502" },
  { label: "Bacong-Montilla", mun_code: "064502" },
  { label: "Bagroy", mun_code: "064502" },
  { label: "Balingasag", mun_code: "064502" },
  { label: "Binubuhan", mun_code: "064502" },
  { label: "Busay", mun_code: "064502" },
  { label: "Calumangan", mun_code: "064502" },
  { label: "Caridad", mun_code: "064502" },
  { label: "Dulao", mun_code: "064502" },
  { label: "Ilijan", mun_code: "064502" },
  { label: "Lag-Asan", mun_code: "064502" },
  { label: "Ma-ao Barrio", mun_code: "064502" },
  { label: "Jorge L. Araneta (Ma-ao Central)", mun_code: "064502" },
  { label: "Mailum", mun_code: "064502" },
  { label: "Malingin", mun_code: "064502" },
  { label: "Napoles", mun_code: "064502" },
  { label: "Pacol", mun_code: "064502" },
  { label: "Poblacion", mun_code: "064502" },
  { label: "Sagasa", mun_code: "064502" },
  { label: "Tabunan", mun_code: "064502" },
  { label: "Taloc", mun_code: "064502" },
  { label: "Sampinit", mun_code: "064502" },
  { label: "Amontay", mun_code: "064503" },
  { label: "Bagroy", mun_code: "064503" },
  { label: "Bi-ao", mun_code: "064503" },
  { label: "Canmoros (Pob.)", mun_code: "064503" },
  { label: "Enclaro", mun_code: "064503" },
  { label: "Marina (Pob.)", mun_code: "064503" },
  { label: "Paglaum (Pob.)", mun_code: "064503" },
  { label: "Payao", mun_code: "064503" },
  { label: "Progreso (Pob.)", mun_code: "064503" },
  { label: "San Jose", mun_code: "064503" },
  { label: "San Juan (Pob.)", mun_code: "064503" },
  { label: "San Pedro (Pob.)", mun_code: "064503" },
  { label: "San Teodoro (Pob.)", mun_code: "064503" },
  { label: "San Vicente (Pob.)", mun_code: "064503" },
  { label: "Santo Rosario (Pob.)", mun_code: "064503" },
  { label: "Santol", mun_code: "064503" },
  { label: "Andres Bonifacio", mun_code: "064504" },
  { label: "Banquerohan", mun_code: "064504" },
  { label: "Barangay 1 Pob. (Zone 1)", mun_code: "064504" },
  { label: "Barangay 2 Pob. (Zone 2)", mun_code: "064504" },
  { label: "Barangay 3 Pob. (Zone 3)", mun_code: "064504" },
  { label: "Barangay 4 Pob. (Zone 4)", mun_code: "064504" },
  { label: "Barangay 5 Pob. (Zone 5)", mun_code: "064504" },
  { label: "Barangay 6 Pob. (Zone 6)", mun_code: "064504" },
  { label: "Burgos", mun_code: "064504" },
  { label: "Cabahug", mun_code: "064504" },
  { label: "Cadiz Viejo", mun_code: "064504" },
  { label: "Caduha-an", mun_code: "064504" },
  { label: "Celestino Villacin", mun_code: "064504" },
  { label: "Daga", mun_code: "064504" },
  { label: "V. F. Gustilo", mun_code: "064504" },
  { label: "Jerusalem", mun_code: "064504" },
  { label: "Luna", mun_code: "064504" },
  { label: "Mabini", mun_code: "064504" },
  { label: "Magsaysay", mun_code: "064504" },
  { label: "Sicaba", mun_code: "064504" },
  { label: "Tiglawigan", mun_code: "064504" },
  { label: "Tinampa-an", mun_code: "064504" },
  { label: "Agpangi", mun_code: "064505" },
  { label: "Ani-e", mun_code: "064505" },
  { label: "Bagacay", mun_code: "064505" },
  { label: "Bantayanon", mun_code: "064505" },
  { label: "Buenavista", mun_code: "064505" },
  { label: "Cabungahan", mun_code: "064505" },
  { label: "Calampisawan", mun_code: "064505" },
  { label: "Cambayobo", mun_code: "064505" },
  { label: "Castellano", mun_code: "064505" },
  { label: "Cruz", mun_code: "064505" },
  { label: "Dolis", mun_code: "064505" },
  { label: "Hilub-Ang", mun_code: "064505" },
  { label: "Hinab-Ongan", mun_code: "064505" },
  { label: "Ilaya", mun_code: "064505" },
  { label: "Laga-an", mun_code: "064505" },
  { label: "Lalong", mun_code: "064505" },
  { label: "Lemery", mun_code: "064505" },
  { label: "Lipat-on", mun_code: "064505" },
  { label: "Lo-ok (Pob.)", mun_code: "064505" },
  { label: "Ma-aslob", mun_code: "064505" },
  { label: "Macasilao", mun_code: "064505" },
  { label: "Malanog", mun_code: "064505" },
  { label: "Malatas", mun_code: "064505" },
  { label: "Marcelo", mun_code: "064505" },
  { label: "Mina-utok", mun_code: "064505" },
  { label: "Menchaca", mun_code: "064505" },
  { label: "Minapasuk", mun_code: "064505" },
  { label: "Mahilum", mun_code: "064505" },
  { label: "Paghumayan", mun_code: "064505" },
  { label: "Pantao", mun_code: "064505" },
  { label: "Patun-an", mun_code: "064505" },
  { label: "Pinocutan", mun_code: "064505" },
  { label: "Refugio", mun_code: "064505" },
  { label: "San Benito", mun_code: "064505" },
  { label: "San Isidro", mun_code: "064505" },
  { label: "Suba (Pob.)", mun_code: "064505" },
  { label: "Telim", mun_code: "064505" },
  { label: "Tigbao", mun_code: "064505" },
  { label: "Tigbon", mun_code: "064505" },
  { label: "Winaswasan", mun_code: "064505" },
  { label: "Agboy", mun_code: "064506" },
  { label: "Banga", mun_code: "064506" },
  { label: "Cabia-an", mun_code: "064506" },
  { label: "Caningay", mun_code: "064506" },
  { label: "Gatuslao", mun_code: "064506" },
  { label: "Haba", mun_code: "064506" },
  { label: "Payauan", mun_code: "064506" },
  { label: "Poblacion East", mun_code: "064506" },
  { label: "Poblacion West", mun_code: "064506" },
  { label: "Abaca", mun_code: "064507" },
  { label: "Baclao", mun_code: "064507" },
  { label: "Poblacion", mun_code: "064507" },
  { label: "Basak", mun_code: "064507" },
  { label: "Bulata", mun_code: "064507" },
  { label: "Caliling", mun_code: "064507" },
  { label: "Camalanda-an", mun_code: "064507" },
  { label: "Camindangan", mun_code: "064507" },
  { label: "Elihan", mun_code: "064507" },
  { label: "Guiljungan", mun_code: "064507" },
  { label: "Inayawan", mun_code: "064507" },
  { label: "Isio", mun_code: "064507" },
  { label: "Linaon", mun_code: "064507" },
  { label: "Lumbia", mun_code: "064507" },
  { label: "Mambugsay", mun_code: "064507" },
  { label: "Man-Uling", mun_code: "064507" },
  { label: "Masaling", mun_code: "064507" },
  { label: "Molobolo", mun_code: "064507" },
  { label: "Sura", mun_code: "064507" },
  { label: "Talacdan", mun_code: "064507" },
  { label: "Tambad", mun_code: "064507" },
  { label: "Tiling", mun_code: "064507" },
  { label: "Tomina", mun_code: "064507" },
  { label: "Tuyom", mun_code: "064507" },
  { label: "Yao-yao", mun_code: "064507" },
  { label: "Alacaygan", mun_code: "064508" },
  { label: "Alicante", mun_code: "064508" },
  { label: "Poblacion I (Barangay 1)", mun_code: "064508" },
  { label: "Poblacion II (Barangay 2)", mun_code: "064508" },
  { label: "Poblacion III (Barangay 3)", mun_code: "064508" },
  { label: "Batea", mun_code: "064508" },
  { label: "Consing", mun_code: "064508" },
  { label: "Cudangdang", mun_code: "064508" },
  { label: "Damgo", mun_code: "064508" },
  { label: "Gahit", mun_code: "064508" },
  { label: "Canlusong", mun_code: "064508" },
  { label: "Latasan", mun_code: "064508" },
  { label: "Madalag", mun_code: "064508" },
  { label: "Manta-angan", mun_code: "064508" },
  { label: "Nanca", mun_code: "064508" },
  { label: "Pasil", mun_code: "064508" },
  { label: "San Isidro", mun_code: "064508" },
  { label: "San Jose", mun_code: "064508" },
  { label: "Tabigue", mun_code: "064508" },
  { label: "Tanza", mun_code: "064508" },
  { label: "Tuburan", mun_code: "064508" },
  { label: "Tomongtong", mun_code: "064508" },
  { label: "Alimango", mun_code: "064509" },
  { label: "Balintawak (Pob.)", mun_code: "064509" },
  { label: "Magsaysay (Binabongol)", mun_code: "064509" },
  { label: "Binaguiohan", mun_code: "064509" },
  { label: "Buenavista", mun_code: "064509" },
  { label: "Cervantes", mun_code: "064509" },
  { label: "Dian-ay", mun_code: "064509" },
  { label: "Hacienda Fe", mun_code: "064509" },
  { label: "Jonobjonob", mun_code: "064509" },
  { label: "Japitan", mun_code: "064509" },
  { label: "Langub", mun_code: "064509" },
  { label: "Libertad", mun_code: "064509" },
  { label: "Mabini", mun_code: "064509" },
  { label: "Malasibog", mun_code: "064509" },
  { label: "Paitan", mun_code: "064509" },
  { label: "Pinapugasan", mun_code: "064509" },
  { label: "Old Poblacion", mun_code: "064509" },
  { label: "Rizal", mun_code: "064509" },
  { label: "Tamlang", mun_code: "064509" },
  { label: "Udtongan", mun_code: "064509" },
  { label: "Washington", mun_code: "064509" },
  { label: "Aguisan", mun_code: "064510" },
  { label: "Buenavista", mun_code: "064510" },
  { label: "Cabadiangan", mun_code: "064510" },
  { label: "Cabanbanan", mun_code: "064510" },
  { label: "Carabalan", mun_code: "064510" },
  { label: "Caradio-an", mun_code: "064510" },
  { label: "Libacao", mun_code: "064510" },
  { label: "Mambagaton", mun_code: "064510" },
  { label: "Nabali-an", mun_code: "064510" },
  { label: "Mahalang", mun_code: "064510" },
  { label: "San Antonio", mun_code: "064510" },
  { label: "Sara-et", mun_code: "064510" },
  { label: "Su-ay", mun_code: "064510" },
  { label: "Talaban", mun_code: "064510" },
  { label: "To-oy", mun_code: "064510" },
  { label: "Barangay I (Pob.)", mun_code: "064510" },
  { label: "Barangay II (Pob.)", mun_code: "064510" },
  { label: "Barangay III (Pob.)", mun_code: "064510" },
  { label: "Barangay IV (Pob.)", mun_code: "064510" },
  { label: "Anahaw", mun_code: "064511" },
  { label: "Aranda", mun_code: "064511" },
  { label: "Barangay I (Pob.)", mun_code: "064511" },
  { label: "Barangay II (Pob.)", mun_code: "064511" },
  { label: "Barangay III (Pob.)", mun_code: "064511" },
  { label: "Barangay IV (Pob.)", mun_code: "064511" },
  { label: "Bato", mun_code: "064511" },
  { label: "Calapi", mun_code: "064511" },
  { label: "Camalobalo", mun_code: "064511" },
  { label: "Camba-og", mun_code: "064511" },
  { label: "Cambugsa", mun_code: "064511" },
  { label: "Candumarao", mun_code: "064511" },
  { label: "Gargato", mun_code: "064511" },
  { label: "Himaya", mun_code: "064511" },
  { label: "Miranda", mun_code: "064511" },
  { label: "Nanunga", mun_code: "064511" },
  { label: "Narauis", mun_code: "064511" },
  { label: "Palayog", mun_code: "064511" },
  { label: "Paticui", mun_code: "064511" },
  { label: "Pilar", mun_code: "064511" },
  { label: "Quiwi", mun_code: "064511" },
  { label: "Tagda", mun_code: "064511" },
  { label: "Tuguis", mun_code: "064511" },
  { label: "Baga-as", mun_code: "064511" },
  { label: "Alim", mun_code: "064512" },
  { label: "Asia", mun_code: "064512" },
  { label: "Bacuyangan", mun_code: "064512" },
  { label: "Barangay I (Pob.)", mun_code: "064512" },
  { label: "Barangay II (Pob.)", mun_code: "064512" },
  { label: "Bulwangan", mun_code: "064512" },
  { label: "Culipapa", mun_code: "064512" },
  { label: "Damutan", mun_code: "064512" },
  { label: "Daug", mun_code: "064512" },
  { label: "Po-ok", mun_code: "064512" },
  { label: "San Rafael", mun_code: "064512" },
  { label: "Sangke", mun_code: "064512" },
  { label: "Talacagay", mun_code: "064512" },
  { label: "Andulauan", mun_code: "064513" },
  { label: "Balicotoc", mun_code: "064513" },
  { label: "Bocana", mun_code: "064513" },
  { label: "Calubang", mun_code: "064513" },
  { label: "Canlamay", mun_code: "064513" },
  { label: "Consuelo", mun_code: "064513" },
  { label: "Dancalan", mun_code: "064513" },
  { label: "Delicioso", mun_code: "064513" },
  { label: "Galicia", mun_code: "064513" },
  { label: "Manalad", mun_code: "064513" },
  { label: "Pinggot", mun_code: "064513" },
  { label: "Barangay I (Pob.)", mun_code: "064513" },
  { label: "Barangay II (Pob.)", mun_code: "064513" },
  { label: "Tabu", mun_code: "064513" },
  { label: "Vista Alegre", mun_code: "064513" },
  { label: "Amin", mun_code: "064514" },
  { label: "Banogbanog", mun_code: "064514" },
  { label: "Bulad", mun_code: "064514" },
  { label: "Bungahin", mun_code: "064514" },
  { label: "Cabcab", mun_code: "064514" },
  { label: "Camangcamang", mun_code: "064514" },
  { label: "Camp Clark", mun_code: "064514" },
  { label: "Cansalongon", mun_code: "064514" },
  { label: "Guintubhan", mun_code: "064514" },
  { label: "Libas", mun_code: "064514" },
  { label: "Limalima", mun_code: "064514" },
  { label: "Makilignit", mun_code: "064514" },
  { label: "Mansablay", mun_code: "064514" },
  { label: "Maytubig", mun_code: "064514" },
  { label: "Panaquiao", mun_code: "064514" },
  { label: "Barangay 1 (Pob.)", mun_code: "064514" },
  { label: "Barangay 2 (Pob.)", mun_code: "064514" },
  { label: "Barangay 3 (Pob.)", mun_code: "064514" },
  { label: "Barangay 4 (Pob.)", mun_code: "064514" },
  { label: "Barangay 5 (Pob.)", mun_code: "064514" },
  { label: "Barangay 6 (Pob.)", mun_code: "064514" },
  { label: "Barangay 7 (Pob.)", mun_code: "064514" },
  { label: "Barangay 8 (Pob.)", mun_code: "064514" },
  { label: "Barangay 9 (Pob.)", mun_code: "064514" },
  { label: "Riverside", mun_code: "064514" },
  { label: "Rumirang", mun_code: "064514" },
  { label: "San Agustin", mun_code: "064514" },
  { label: "Sebucawan", mun_code: "064514" },
  { label: "Sikatuna", mun_code: "064514" },
  { label: "Tinongan", mun_code: "064514" },
  { label: "Bantayan", mun_code: "064515" },
  { label: "Binicuil", mun_code: "064515" },
  { label: "Camansi", mun_code: "064515" },
  { label: "Camingawan", mun_code: "064515" },
  { label: "Camugao", mun_code: "064515" },
  { label: "Carol-an", mun_code: "064515" },
  { label: "Daan Banua", mun_code: "064515" },
  { label: "Hilamonan", mun_code: "064515" },
  { label: "Inapoy", mun_code: "064515" },
  { label: "Linao", mun_code: "064515" },
  { label: "Locotan", mun_code: "064515" },
  { label: "Magballo", mun_code: "064515" },
  { label: "Oringao", mun_code: "064515" },
  { label: "Orong", mun_code: "064515" },
  { label: "Pinaguinpinan", mun_code: "064515" },
  { label: "Barangay 5 (Pob.)", mun_code: "064515" },
  { label: "Barangay 6 (Pob.)", mun_code: "064515" },
  { label: "Barangay 7 (Pob.)", mun_code: "064515" },
  { label: "Barangay 8 (Pob.)", mun_code: "064515" },
  { label: "Barangay 9 (Pob.)", mun_code: "064515" },
  { label: "Barangay 1 (Pob.)", mun_code: "064515" },
  { label: "Barangay 2 (Pob.)", mun_code: "064515" },
  { label: "Barangay 3 (Pob.)", mun_code: "064515" },
  { label: "Barangay 4 (Pob.)", mun_code: "064515" },
  { label: "Salong", mun_code: "064515" },
  { label: "Tabugon", mun_code: "064515" },
  { label: "Tagoc", mun_code: "064515" },
  { label: "Talubangi", mun_code: "064515" },
  { label: "Tampalon", mun_code: "064515" },
  { label: "Tan-Awan", mun_code: "064515" },
  { label: "Tapi", mun_code: "064515" },
  { label: "Tagukon", mun_code: "064515" },
  { label: "Ara-al", mun_code: "064516" },
  { label: "Ayungon", mun_code: "064516" },
  { label: "Balabag", mun_code: "064516" },
  { label: "Batuan", mun_code: "064516" },
  { label: "Roberto S. Benedicto (Consuelo)", mun_code: "064516" },
  { label: "Cubay", mun_code: "064516" },
  { label: "Haguimit", mun_code: "064516" },
  { label: "La Granja", mun_code: "064516" },
  { label: "Nagasi", mun_code: "064516" },
  { label: "Barangay I (Pob.)", mun_code: "064516" },
  { label: "Barangay II (Pob.)", mun_code: "064516" },
  { label: "Barangay III (Pob.)", mun_code: "064516" },
  { label: "San Miguel", mun_code: "064516" },
  { label: "Yubo", mun_code: "064516" },
  { label: "Biaknabato", mun_code: "064517" },
  { label: "Cabacungan", mun_code: "064517" },
  { label: "Cabagnaan", mun_code: "064517" },
  { label: "Camandag", mun_code: "064517" },
  { label: "Lalagsan", mun_code: "064517" },
  { label: "Manghanoy", mun_code: "064517" },
  { label: "Mansalanao", mun_code: "064517" },
  { label: "Masulog", mun_code: "064517" },
  { label: "Nato", mun_code: "064517" },
  { label: "Puso", mun_code: "064517" },
  { label: "Robles (Pob.)", mun_code: "064517" },
  { label: "Sag-Ang", mun_code: "064517" },
  { label: "Talaptap", mun_code: "064517" },
  { label: "Chambery", mun_code: "064518" },
  { label: "Barangay I (Pob.)", mun_code: "064518" },
  { label: "Barangay I-A (Pob.)", mun_code: "064518" },
  { label: "Barangay I-B (Pob.)", mun_code: "064518" },
  { label: "Barangay II (Pob.)", mun_code: "064518" },
  { label: "Barangay II-A (Pob.)", mun_code: "064518" },
  { label: "Punta Mesa", mun_code: "064518" },
  { label: "Punta Salong", mun_code: "064518" },
  { label: "Purisima", mun_code: "064518" },
  { label: "San Pablo", mun_code: "064518" },
  { label: "Santa Teresa", mun_code: "064518" },
  { label: "Tortosa", mun_code: "064518" },
  { label: "Barangay 1 (Pob.)", mun_code: "064519" },
  { label: "Barangay 2 (Pob.)", mun_code: "064519" },
  { label: "Barangay 3 (Pob.)", mun_code: "064519" },
  { label: "Barangay 4 (Pob.)", mun_code: "064519" },
  { label: "Barangay 5 (Pob.)", mun_code: "064519" },
  { label: "Barangay 6 (Pob.)", mun_code: "064519" },
  { label: "Barangay 7 (Pob.)", mun_code: "064519" },
  { label: "Crossing Magallon", mun_code: "064519" },
  { label: "Guinpana-an", mun_code: "064519" },
  { label: "Inolingan", mun_code: "064519" },
  { label: "Macagahay", mun_code: "064519" },
  { label: "Magallon Cadre", mun_code: "064519" },
  { label: "Montilla", mun_code: "064519" },
  { label: "Odiong", mun_code: "064519" },
  { label: "Quintin Remo", mun_code: "064519" },
  { label: "Abo-abo", mun_code: "064520" },
  { label: "Alegria", mun_code: "064520" },
  { label: "Amayco", mun_code: "064520" },
  { label: "Zone I (Pob.)", mun_code: "064520" },
  { label: "Zone II (Pob.)", mun_code: "064520" },
  { label: "Zone III (Pob.)", mun_code: "064520" },
  { label: "Zone IV (Pob.)", mun_code: "064520" },
  { label: "Zone V (Pob.)", mun_code: "064520" },
  { label: "Blumentritt", mun_code: "064520" },
  { label: "Buenavista", mun_code: "064520" },
  { label: "Caliban", mun_code: "064520" },
  { label: "Canlandog", mun_code: "064520" },
  { label: "Cansilayan", mun_code: "064520" },
  { label: "Damsite", mun_code: "064520" },
  { label: "Iglau-an", mun_code: "064520" },
  { label: "Lopez Jaena", mun_code: "064520" },
  { label: "Minoyan", mun_code: "064520" },
  { label: "Pandanon", mun_code: "064520" },
  { label: "San Miguel", mun_code: "064520" },
  { label: "Santa Cruz", mun_code: "064520" },
  { label: "Santa Rosa", mun_code: "064520" },
  { label: "Salvacion", mun_code: "064520" },
  { label: "Talotog", mun_code: "064520" },
  { label: "Antipolo", mun_code: "064521" },
  { label: "Barangay I (Pob.)", mun_code: "064521" },
  { label: "Barangay II (Pob.)", mun_code: "064521" },
  { label: "Barangay III (Pob.)", mun_code: "064521" },
  { label: "Buenavista Gibong", mun_code: "064521" },
  { label: "Buenavista Rizal", mun_code: "064521" },
  { label: "Burgos", mun_code: "064521" },
  { label: "Cambarus", mun_code: "064521" },
  { label: "Canroma", mun_code: "064521" },
  { label: "Don Salvador Benedicto (Carmen)", mun_code: "064521" },
  { label: "General Malvar", mun_code: "064521" },
  { label: "Gomez", mun_code: "064521" },
  { label: "M. H. Del Pilar", mun_code: "064521" },
  { label: "Mabini", mun_code: "064521" },
  { label: "Miranda", mun_code: "064521" },
  { label: "Pandan", mun_code: "064521" },
  { label: "Recreo", mun_code: "064521" },
  { label: "San Isidro", mun_code: "064521" },
  { label: "San Juan", mun_code: "064521" },
  { label: "Zamora", mun_code: "064521" },
  { label: "Barangay Zone 1-A (Pob.)", mun_code: "064522" },
  { label: "Barangay Zone 4-A (Pob.)", mun_code: "064522" },
  { label: "Barangay Zone 1 (Pob.)", mun_code: "064522" },
  { label: "Barangay Zone 2 (Pob.)", mun_code: "064522" },
  { label: "Barangay Zone 3 (Pob.)", mun_code: "064522" },
  { label: "Barangay Zone 4 (Pob.)", mun_code: "064522" },
  { label: "Barangay Zone 5 (Pob.)", mun_code: "064522" },
  { label: "Barangay Zone 6 (Pob.)", mun_code: "064522" },
  { label: "Barangay Zone 7 (Pob.)", mun_code: "064522" },
  { label: "Canjusa", mun_code: "064522" },
  { label: "Crossing Pulupandan", mun_code: "064522" },
  { label: "Culo", mun_code: "064522" },
  { label: "Mabini", mun_code: "064522" },
  { label: "Pag-ayon", mun_code: "064522" },
  { label: "Palaka Norte", mun_code: "064522" },
  { label: "Palaka Sur", mun_code: "064522" },
  { label: "Patic", mun_code: "064522" },
  { label: "Tapong", mun_code: "064522" },
  { label: "Ubay", mun_code: "064522" },
  { label: "Utod", mun_code: "064522" },
  { label: "Andres Bonifacio", mun_code: "064523" },
  { label: "Bato", mun_code: "064523" },
  { label: "Baviera", mun_code: "064523" },
  { label: "Bulanon", mun_code: "064523" },
  { label: "Campo Himoga-an", mun_code: "064523" },
  { label: "Campo Santiago", mun_code: "064523" },
  { label: "Colonia Divina", mun_code: "064523" },
  { label: "Fabrica", mun_code: "064523" },
  { label: "General Luna", mun_code: "064523" },
  { label: "Himoga-an Baybay", mun_code: "064523" },
  { label: "Lopez Jaena", mun_code: "064523" },
  { label: "Malubon", mun_code: "064523" },
  { label: "Makiling", mun_code: "064523" },
  { label: "Molocaboc", mun_code: "064523" },
  { label: "Old Sagay", mun_code: "064523" },
  { label: "Paraiso", mun_code: "064523" },
  { label: "Plaridel", mun_code: "064523" },
  { label: "Poblacion I (Barangay 1)", mun_code: "064523" },
  { label: "Poblacion II (Barangay 2)", mun_code: "064523" },
  { label: "Puey", mun_code: "064523" },
  { label: "Rizal", mun_code: "064523" },
  { label: "Taba-ao", mun_code: "064523" },
  { label: "Tadlong", mun_code: "064523" },
  { label: "Vito", mun_code: "064523" },
  { label: "Rafaela Barrera", mun_code: "064523" },
  { label: "Bagonbon", mun_code: "064524" },
  { label: "Buluangan", mun_code: "064524" },
  { label: "Codcod", mun_code: "064524" },
  { label: "Ermita", mun_code: "064524" },
  { label: "Guadalupe", mun_code: "064524" },
  { label: "Nataban", mun_code: "064524" },
  { label: "Palampas", mun_code: "064524" },
  { label: "Barangay I (Pob.)", mun_code: "064524" },
  { label: "Barangay II (Pob.)", mun_code: "064524" },
  { label: "Barangay III (Pob.)", mun_code: "064524" },
  { label: "Barangay IV (Pob.)", mun_code: "064524" },
  { label: "Barangay V (Pob.)", mun_code: "064524" },
  { label: "Barangay VI (Pob.)", mun_code: "064524" },
  { label: "Prosperidad", mun_code: "064524" },
  { label: "Punao", mun_code: "064524" },
  { label: "Quezon", mun_code: "064524" },
  { label: "Rizal", mun_code: "064524" },
  { label: "San Juan", mun_code: "064524" },
  { label: "Bagonawa", mun_code: "064525" },
  { label: "Baliwagan", mun_code: "064525" },
  { label: "Batuan", mun_code: "064525" },
  { label: "Guintorilan", mun_code: "064525" },
  { label: "Nayon", mun_code: "064525" },
  { label: "Poblacion", mun_code: "064525" },
  { label: "Sibucao", mun_code: "064525" },
  { label: "Tabao Baybay", mun_code: "064525" },
  { label: "Tabao Rizal", mun_code: "064525" },
  { label: "Tibsoc", mun_code: "064525" },
  { label: "Balaring", mun_code: "064526" },
  { label: "Barangay I (Pob.)", mun_code: "064526" },
  { label: "Barangay II (Pob.)", mun_code: "064526" },
  { label: "Barangay III (Pob.)", mun_code: "064526" },
  { label: "Barangay IV (Pob.)", mun_code: "064526" },
  { label: "Barangay V (Pob.)", mun_code: "064526" },
  { label: "Barangay VI Pob. (Hawaiian)", mun_code: "064526" },
  { label: "Eustaquio Lopez", mun_code: "064526" },
  { label: "Guimbala-on", mun_code: "064526" },
  { label: "Guinhalaran", mun_code: "064526" },
  { label: "Kapitan Ramon", mun_code: "064526" },
  { label: "Lantad", mun_code: "064526" },
  { label: "Mambulac", mun_code: "064526" },
  { label: "Rizal", mun_code: "064526" },
  { label: "Bagtic", mun_code: "064526" },
  { label: "Patag", mun_code: "064526" },
  { label: "Barangay 1 (Pob.)", mun_code: "064527" },
  { label: "Barangay 2 (Pob.)", mun_code: "064527" },
  { label: "Barangay 3 (Pob.)", mun_code: "064527" },
  { label: "Barangay 4 (Pob.)", mun_code: "064527" },
  { label: "Barangay 5 (Pob.)", mun_code: "064527" },
  { label: "Cabadiangan", mun_code: "064527" },
  { label: "Camindangan", mun_code: "064527" },
  { label: "Canturay", mun_code: "064527" },
  { label: "Cartagena", mun_code: "064527" },
  { label: "Cayhagan", mun_code: "064527" },
  { label: "Gil Montilla", mun_code: "064527" },
  { label: "Mambaroto", mun_code: "064527" },
  { label: "Manlucahoc", mun_code: "064527" },
  { label: "Maricalum", mun_code: "064527" },
  { label: "Nabulao", mun_code: "064527" },
  { label: "Nauhang", mun_code: "064527" },
  { label: "San Jose", mun_code: "064527" },
  { label: "Bubog", mun_code: "064528" },
  { label: "Cabatangan", mun_code: "064528" },
  { label: "Zone 4-A (Pob.)", mun_code: "064528" },
  { label: "Zone 4 (Pob.)", mun_code: "064528" },
  { label: "Concepcion", mun_code: "064528" },
  { label: "Dos Hermanas", mun_code: "064528" },
  { label: "Efigenio Lizares", mun_code: "064528" },
  { label: "Zone 7 (Pob.)", mun_code: "064528" },
  { label: "Zone 14-B (Pob.)", mun_code: "064528" },
  { label: "Zone 12-A (Pob.)", mun_code: "064528" },
  { label: "Zone 10 (Pob.)", mun_code: "064528" },
  { label: "Zone 5 (Pob.)", mun_code: "064528" },
  { label: "Zone 16 (Pob.)", mun_code: "064528" },
  { label: "Matab-ang", mun_code: "064528" },
  { label: "Zone 9 (Pob.)", mun_code: "064528" },
  { label: "Zone 6 (Pob.)", mun_code: "064528" },
  { label: "Zone 14 (Pob.)", mun_code: "064528" },
  { label: "San Fernando", mun_code: "064528" },
  { label: "Zone 15 (Pob.)", mun_code: "064528" },
  { label: "Zone 14-A (Pob.)", mun_code: "064528" },
  { label: "Zone 11 (Pob.)", mun_code: "064528" },
  { label: "Zone 8 (Pob.)", mun_code: "064528" },
  { label: "Zone 12 (Pob.)", mun_code: "064528" },
  { label: "Zone 1 (Pob.)", mun_code: "064528" },
  { label: "Zone 2 (Pob.)", mun_code: "064528" },
  { label: "Zone 3 (Pob.)", mun_code: "064528" },
  { label: "Katilingban", mun_code: "064528" },
  { label: "Bandila", mun_code: "064529" },
  { label: "Bug-ang", mun_code: "064529" },
  { label: "General Luna", mun_code: "064529" },
  { label: "Magticol", mun_code: "064529" },
  { label: "Poblacion", mun_code: "064529" },
  { label: "Salamanca", mun_code: "064529" },
  { label: "San Isidro", mun_code: "064529" },
  { label: "San Jose", mun_code: "064529" },
  { label: "Tabun-ac", mun_code: "064529" },
  { label: "Alijis", mun_code: "064530" },
  { label: "Ayungon", mun_code: "064530" },
  { label: "Bagumbayan", mun_code: "064530" },
  { label: "Batuan", mun_code: "064530" },
  { label: "Bayabas", mun_code: "064530" },
  { label: "Central Tabao", mun_code: "064530" },
  { label: "Doldol", mun_code: "064530" },
  { label: "Guintorilan", mun_code: "064530" },
  { label: "Lacaron", mun_code: "064530" },
  { label: "Mabini", mun_code: "064530" },
  { label: "Pacol", mun_code: "064530" },
  { label: "Palaka", mun_code: "064530" },
  { label: "Paloma", mun_code: "064530" },
  { label: "Poblacion", mun_code: "064530" },
  { label: "Sagua Banua", mun_code: "064530" },
  { label: "Tabao Proper", mun_code: "064530" },
  { label: "Barangay I (Pob.)", mun_code: "064531" },
  { label: "Barangay II (Pob.)", mun_code: "064531" },
  { label: "Barangay III (Pob.)", mun_code: "064531" },
  { label: "Barangay IV (Pob.)", mun_code: "064531" },
  { label: "Barangay V (Pob.)", mun_code: "064531" },
  { label: "Barangay VI (Pob.)", mun_code: "064531" },
  { label: "Barangay VII (Pob.)", mun_code: "064531" },
  { label: "Barangay VIII (Pob.)", mun_code: "064531" },
  { label: "Barangay IX (Daan Banwa)", mun_code: "064531" },
  { label: "Barangay X (Estado)", mun_code: "064531" },
  { label: "Barangay XI (Gawahon)", mun_code: "064531" },
  { label: "Barangay XII", mun_code: "064531" },
  { label: "Barangay XIII", mun_code: "064531" },
  { label: "Barangay XIV", mun_code: "064531" },
  { label: "Barangay XV", mun_code: "064531" },
  { label: "Barangay XV-A", mun_code: "064531" },
  { label: "Barangay XVI", mun_code: "064531" },
  { label: "Barangay XVI-A", mun_code: "064531" },
  { label: "Barangay XVII", mun_code: "064531" },
  { label: "Barangay XVIII", mun_code: "064531" },
  { label: "Barangay XVIII-A", mun_code: "064531" },
  { label: "Barangay XIX", mun_code: "064531" },
  { label: "Barangay XIX-A", mun_code: "064531" },
  { label: "Barangay XX", mun_code: "064531" },
  { label: "Barangay XXI", mun_code: "064531" },
  { label: "Barangay VI-A", mun_code: "064531" },
  { label: "Bago (Lalung)", mun_code: "064532" },
  { label: "Bagong Silang (Marcelo)", mun_code: "064532" },
  { label: "Bunga", mun_code: "064532" },
  { label: "Igmaya-an", mun_code: "064532" },
  { label: "Kumaliskis", mun_code: "064532" },
  { label: "Pandanon", mun_code: "064532" },
  { label: "Pinowayan (Prosperidad)", mun_code: "064532" },
  // { label: "Agsanayan", mun_code: "067901" },
  // { label: "Avila", mun_code: "067901" },
  // { label: "Banban", mun_code: "067901" },
  // { label: "Bacjao (Calumingan)", mun_code: "067901" },
  // { label: "Cansilayan", mun_code: "067901" },
  // { label: "Dagsa-an", mun_code: "067901" },
  // { label: "Daragan", mun_code: "067901" },
  // { label: "East Valencia", mun_code: "067901" },
  // { label: "Getulio", mun_code: "067901" },
  // { label: "Mabini", mun_code: "067901" },
  // { label: "Magsaysay", mun_code: "067901" },
  // { label: "Mclain", mun_code: "067901" },
  // { label: "Montpiller", mun_code: "067901" },
  // { label: "Navalas", mun_code: "067901" },
  // { label: "Nazaret", mun_code: "067901" },
  // { label: "New Poblacion (Calingao)", mun_code: "067901" },
  // { label: "Old Poblacion", mun_code: "067901" },
  // { label: "Rizal", mun_code: "067901" },
  // { label: "Salvacion", mun_code: "067901" },
  // { label: "San Fernando", mun_code: "067901" },
  // { label: "San Isidro", mun_code: "067901" },
  // { label: "San Miguel", mun_code: "067901" },
  // { label: "San Nicolas", mun_code: "067901" },
  // { label: "San Pedro", mun_code: "067901" },
  // { label: "San Roque", mun_code: "067901" },
  // { label: "Santo Rosario", mun_code: "067901" },
  // { label: "Sawang", mun_code: "067901" },
  // { label: "Supang", mun_code: "067901" },
  // { label: "Tacay", mun_code: "067901" },
  // { label: "Taminla", mun_code: "067901" },
  // { label: "Tanag", mun_code: "067901" },
  // { label: "Tastasan", mun_code: "067901" },
  // { label: "Tinadtaran", mun_code: "067901" },
  // { label: "Umilig", mun_code: "067901" },
  // { label: "Zaldivar", mun_code: "067901" },
  // { label: "Alaguisoc", mun_code: "067902" },
  // { label: "Balcon Maravilla", mun_code: "067902" },
  // { label: "Balcon Melliza", mun_code: "067902" },
  // { label: "Bugnay", mun_code: "067902" },
  // { label: "Buluangan", mun_code: "067902" },
  // { label: "Espinosa", mun_code: "067902" },
  // { label: "Hoskyn", mun_code: "067902" },
  // { label: "Lawi", mun_code: "067902" },
  // { label: "Morobuan", mun_code: "067902" },
  // { label: "Poblacion", mun_code: "067902" },
  // { label: "Rizal", mun_code: "067902" },
  // { label: "San Miguel", mun_code: "067902" },
  // { label: "Sinapsapan", mun_code: "067902" },
  // { label: "Santa Teresa", mun_code: "067902" },
  // { label: "Cabalagnan", mun_code: "067903" },
  // { label: "Calaya", mun_code: "067903" },
  // { label: "Canhawan", mun_code: "067903" },
  // { label: "Concordia Sur", mun_code: "067903" },
  // { label: "Dolores", mun_code: "067903" },
  // { label: "Guiwanon", mun_code: "067903" },
  // { label: "Igang", mun_code: "067903" },
  // { label: "Igdarapdap", mun_code: "067903" },
  // { label: "La Paz", mun_code: "067903" },
  // { label: "Lanipe", mun_code: "067903" },
  // { label: "Lucmayan", mun_code: "067903" },
  // { label: "Magamay", mun_code: "067903" },
  // { label: "Napandong", mun_code: "067903" },
  // { label: "Oracon Sur", mun_code: "067903" },
  // { label: "Pandaraonan", mun_code: "067903" },
  // { label: "Panobolon", mun_code: "067903" },
  // { label: "Poblacion", mun_code: "067903" },
  // { label: "Salvacion", mun_code: "067903" },
  // { label: "San Antonio", mun_code: "067903" },
  // { label: "San Roque", mun_code: "067903" },
  // { label: "Santo Domingo", mun_code: "067903" },
  // { label: "Tando", mun_code: "067903" },
  // { label: "Aguilar", mun_code: "067904" },
  // { label: "Cabano", mun_code: "067904" },
  // { label: "Cabungahan", mun_code: "067904" },
  // { label: "Constancia", mun_code: "067904" },
  // { label: "Gaban", mun_code: "067904" },
  // { label: "Igcawayan", mun_code: "067904" },
  // { label: "M. Chavez", mun_code: "067904" },
  // { label: "San Enrique (Lebas)", mun_code: "067904" },
  // { label: "Sapal", mun_code: "067904" },
  // { label: "Sebario", mun_code: "067904" },
  // { label: "Suclaran", mun_code: "067904" },
  // { label: "Tamborong", mun_code: "067904" },
  // { label: "Alegria", mun_code: "067905" },
  // { label: "Ayangan", mun_code: "067905" },
  // { label: "Bubog", mun_code: "067905" },
  // { label: "Concordia Norte", mun_code: "067905" },
  // { label: "Dasal", mun_code: "067905" },
  // { label: "Inampologan", mun_code: "067905" },
  // { label: "Maabay", mun_code: "067905" },
  // { label: "Millan", mun_code: "067905" },
  // { label: "Oracon Norte", mun_code: "067905" },
  // { label: "Ravina", mun_code: "067905" },
  // { label: "Sabang", mun_code: "067905" },
  // { label: "San Isidro", mun_code: "067905" },
  // { label: "Sebaste", mun_code: "067905" },
  // { label: "Tanglad", mun_code: "067905" },
  // { label: "Bahi", mun_code: "071201" },
  // { label: "Basacdacu", mun_code: "071201" },
  // { label: "Cantiguib", mun_code: "071201" },
  // { label: "Dangay", mun_code: "071201" },
  // { label: "East Poblacion", mun_code: "071201" },
  // { label: "Ponong", mun_code: "071201" },
  // { label: "San Agustin", mun_code: "071201" },
  // { label: "Santa Filomena", mun_code: "071201" },
  // { label: "Tagbuane", mun_code: "071201" },
  // { label: "Toril", mun_code: "071201" },
  // { label: "West Poblacion", mun_code: "071201" },
  // { label: "Cabatang", mun_code: "071202" },
  // { label: "Cagongcagong", mun_code: "071202" },
  // { label: "Cambaol", mun_code: "071202" },
  // { label: "Cayacay", mun_code: "071202" },
  // { label: "Del Monte", mun_code: "071202" },
  // { label: "Katipunan", mun_code: "071202" },
  // { label: "La Hacienda", mun_code: "071202" },
  // { label: "Mahayag", mun_code: "071202" },
  // { label: "Napo", mun_code: "071202" },
  // { label: "Pagahat", mun_code: "071202" },
  // { label: "Poblacion (Calingganay)", mun_code: "071202" },
  // { label: "Progreso", mun_code: "071202" },
  // { label: "Putlongcam", mun_code: "071202" },
  // { label: "Sudlon (Omhor)", mun_code: "071202" },
  // { label: "Untaga", mun_code: "071202" },
  // { label: "Almaria", mun_code: "071203" },
  // { label: "Bacong", mun_code: "071203" },
  // { label: "Badiang", mun_code: "071203" },
  // { label: "Buenasuerte", mun_code: "071203" },
  // { label: "Candabong", mun_code: "071203" },
  // { label: "Casica", mun_code: "071203" },
  // { label: "Katipunan", mun_code: "071203" },
  // { label: "Linawan", mun_code: "071203" },
  // { label: "Lundag", mun_code: "071203" },
  // { label: "Poblacion", mun_code: "071203" },
  // { label: "Santa Cruz", mun_code: "071203" },
  // { label: "Suba", mun_code: "071203" },
  // { label: "Talisay", mun_code: "071203" },
  // { label: "Tanod", mun_code: "071203" },
  // { label: "Tawid", mun_code: "071203" },
  // { label: "Virgen", mun_code: "071203" },
  // { label: "Angilan", mun_code: "071204" },
  // { label: "Bantolinao", mun_code: "071204" },
  // { label: "Bicahan", mun_code: "071204" },
  // { label: "Bitaugan", mun_code: "071204" },
  // { label: "Bungahan", mun_code: "071204" },
  // { label: "Canlaas", mun_code: "071204" },
  // { label: "Cansibuan", mun_code: "071204" },
  // { label: "Can-omay", mun_code: "071204" },
  // { label: "Celing", mun_code: "071204" },
  // { label: "Danao", mun_code: "071204" },
  // { label: "Danicop", mun_code: "071204" },
  // { label: "Mag-aso", mun_code: "071204" },
  // { label: "Poblacion", mun_code: "071204" },
  // { label: "Quinapon-an", mun_code: "071204" },
  // { label: "Santo Rosario", mun_code: "071204" },
  // { label: "Tabuan", mun_code: "071204" },
  // { label: "Tagubaas", mun_code: "071204" },
  // { label: "Tupas", mun_code: "071204" },
  // { label: "Ubojan", mun_code: "071204" },
  // { label: "Viga", mun_code: "071204" },
  // { label: "Villa Aurora (Canoc-oc)", mun_code: "071204" },
  // { label: "Cambanac", mun_code: "071205" },
  // { label: "Dasitam", mun_code: "071205" },
  // { label: "Buenaventura", mun_code: "071205" },
  // { label: "Guiwanon", mun_code: "071205" },
  // { label: "Landican", mun_code: "071205" },
  // { label: "Laya", mun_code: "071205" },
  // { label: "Libertad", mun_code: "071205" },
  // { label: "Montana", mun_code: "071205" },
  // { label: "Pamilacan", mun_code: "071205" },
  // { label: "Payahan", mun_code: "071205" },
  // { label: "Poblacion", mun_code: "071205" },
  // { label: "San Isidro", mun_code: "071205" },
  // { label: "San Roque", mun_code: "071205" },
  // { label: "San Vicente", mun_code: "071205" },
  // { label: "Santa Cruz", mun_code: "071205" },
  // { label: "Taguihon", mun_code: "071205" },
  // { label: "Tanday", mun_code: "071205" },
  // { label: "Baucan Norte", mun_code: "071206" },
  // { label: "Baucan Sur", mun_code: "071206" },
  // { label: "Boctol", mun_code: "071206" },
  // { label: "Boyog Norte", mun_code: "071206" },
  // { label: "Boyog Proper", mun_code: "071206" },
  // { label: "Boyog Sur", mun_code: "071206" },
  // { label: "Cabad", mun_code: "071206" },
  // { label: "Candasig", mun_code: "071206" },
  // { label: "Cantalid", mun_code: "071206" },
  // { label: "Cantomimbo", mun_code: "071206" },
  // { label: "Cogon", mun_code: "071206" },
  // { label: "Datag Norte", mun_code: "071206" },
  // { label: "Datag Sur", mun_code: "071206" },
  // { label: "Del Carmen Este (Pob.)", mun_code: "071206" },
  // { label: "Del Carmen Norte (Pob.)", mun_code: "071206" },
  // { label: "Del Carmen Weste (Pob.)", mun_code: "071206" },
  // { label: "Del Carmen Sur (Pob.)", mun_code: "071206" },
  // { label: "Del Rosario", mun_code: "071206" },
  // { label: "Dorol", mun_code: "071206" },
  // { label: "Haguilanan Grande", mun_code: "071206" },
  // { label: "Hanopol Este", mun_code: "071206" },
  // { label: "Hanopol Norte", mun_code: "071206" },
  // { label: "Hanopol Weste", mun_code: "071206" },
  // { label: "Magsija", mun_code: "071206" },
  // { label: "Maslog", mun_code: "071206" },
  // { label: "Sagasa", mun_code: "071206" },
  // { label: "Sal-ing", mun_code: "071206" },
  // { label: "San Isidro", mun_code: "071206" },
  // { label: "San Roque", mun_code: "071206" },
  // { label: "Tagustusan", mun_code: "071206" },
  // { label: "Aloja", mun_code: "071207" },
  // { label: "Cabacnitan", mun_code: "071207" },
  // { label: "Cambacay", mun_code: "071207" },
  // { label: "Cantigdas", mun_code: "071207" },
  // { label: "Garcia", mun_code: "071207" },
  // { label: "Janlud", mun_code: "071207" },
  // { label: "Poblacion Norte", mun_code: "071207" },
  // { label: "Poblacion Sur", mun_code: "071207" },
  // { label: "Poblacion Vieja (Longsudaan)", mun_code: "071207" },
  // { label: "Quezon", mun_code: "071207" },
  // { label: "Quirino", mun_code: "071207" },
  // { label: "Rizal", mun_code: "071207" },
  // { label: "Rosariohan", mun_code: "071207" },
  // { label: "Behind The Clouds (San Jose)", mun_code: "071207" },
  // { label: "Santa Cruz", mun_code: "071207" },
  // { label: "Bonifacio", mun_code: "071208" },
  // { label: "Bugang Norte", mun_code: "071208" },
  // { label: "Bugang Sur", mun_code: "071208" },
  // { label: "Cabacnitan (Magsaysay)", mun_code: "071208" },
  // { label: "Cambigsi", mun_code: "071208" },
  // { label: "Campagao", mun_code: "071208" },
  // { label: "Cansumbol", mun_code: "071208" },
  // { label: "Dagohoy", mun_code: "071208" },
  // { label: "Owac", mun_code: "071208" },
  // { label: "Poblacion", mun_code: "071208" },
  // { label: "Quezon", mun_code: "071208" },
  // { label: "Riverside", mun_code: "071208" },
  // { label: "Rizal", mun_code: "071208" },
  // { label: "Roxas", mun_code: "071208" },
  // { label: "Subayon", mun_code: "071208" },
  // { label: "Villa Aurora", mun_code: "071208" },
  // { label: "Villa Suerte", mun_code: "071208" },
  // { label: "Yanaya", mun_code: "071208" },
  // { label: "Zamora", mun_code: "071208" },
  // { label: "Anonang", mun_code: "071209" },
  // { label: "Asinan", mun_code: "071209" },
  // { label: "Bago", mun_code: "071209" },
  // { label: "Baluarte", mun_code: "071209" },
  // { label: "Bantuan", mun_code: "071209" },
  // { label: "Bato", mun_code: "071209" },
  // { label: "Bonotbonot", mun_code: "071209" },
  // { label: "Bugaong", mun_code: "071209" },
  // { label: "Cambuhat", mun_code: "071209" },
  // { label: "Cambus-oc", mun_code: "071209" },
  // { label: "Cangawa", mun_code: "071209" },
  // { label: "Cantomugcad", mun_code: "071209" },
  // { label: "Cantores", mun_code: "071209" },
  // { label: "Cantuba", mun_code: "071209" },
  // { label: "Catigbian", mun_code: "071209" },
  // { label: "Cawag", mun_code: "071209" },
  // { label: "Cruz", mun_code: "071209" },
  // { label: "Dait", mun_code: "071209" },
  // { label: "Eastern Cabul-an", mun_code: "071209" },
  // { label: "Hunan", mun_code: "071209" },
  // { label: "Lapacan Norte", mun_code: "071209" },
  // { label: "Lapacan Sur", mun_code: "071209" },
  // { label: "Lubang", mun_code: "071209" },
  // { label: "Lusong (Plateau)", mun_code: "071209" },
  // { label: "Magkaya", mun_code: "071209" },
  // { label: "Merryland", mun_code: "071209" },
  // { label: "Nueva Granada", mun_code: "071209" },
  // { label: "Nueva Montana", mun_code: "071209" },
  // { label: "Overland", mun_code: "071209" },
  // { label: "Panghagban", mun_code: "071209" },
  // { label: "Poblacion", mun_code: "071209" },
  // { label: "Puting Bato", mun_code: "071209" },
  // { label: "Rufo Hill", mun_code: "071209" },
  // { label: "Sweetland", mun_code: "071209" },
  // { label: "Western Cabul-an", mun_code: "071209" },
  // { label: "Abucayan Norte", mun_code: "071210" },
  // { label: "Abucayan Sur", mun_code: "071210" },
  // { label: "Banlasan", mun_code: "071210" },
  // { label: "Bentig", mun_code: "071210" },
  // { label: "Binogawan", mun_code: "071210" },
  // { label: "Bonbon", mun_code: "071210" },
  // { label: "Cabayugan", mun_code: "071210" },
  // { label: "Cabudburan", mun_code: "071210" },
  // { label: "Calunasan", mun_code: "071210" },
  // { label: "Camias", mun_code: "071210" },
  // { label: "Canguha", mun_code: "071210" },
  // { label: "Catmonan", mun_code: "071210" },
  // { label: "Desamparados (Pob.)", mun_code: "071210" },
  // { label: "Kahayag", mun_code: "071210" },
  // { label: "Kinabag-an", mun_code: "071210" },
  // { label: "Labuon", mun_code: "071210" },
  // { label: "Lawis", mun_code: "071210" },
  // { label: "Liboron", mun_code: "071210" },
  // { label: "Lo-oc", mun_code: "071210" },
  // { label: "Lomboy", mun_code: "071210" },
  // { label: "Lucob", mun_code: "071210" },
  // { label: "Madangog", mun_code: "071210" },
  // { label: "Magtongtong", mun_code: "071210" },
  // { label: "Mandaug", mun_code: "071210" },
  // { label: "Mantatao", mun_code: "071210" },
  // { label: "Sampoangon", mun_code: "071210" },
  // { label: "San Isidro", mun_code: "071210" },
  // { label: "Santa Cruz (Pob.)", mun_code: "071210" },
  // { label: "Sojoton", mun_code: "071210" },
  // { label: "Talisay", mun_code: "071210" },
  // { label: "Tinibgan", mun_code: "071210" },
  // { label: "Tultugan", mun_code: "071210" },
  // { label: "Ulbujan", mun_code: "071210" },
  // { label: "Abihilan", mun_code: "071211" },
  // { label: "Anoling", mun_code: "071211" },
  // { label: "Boyo-an", mun_code: "071211" },
  // { label: "Cadapdapan", mun_code: "071211" },
  // { label: "Cambane", mun_code: "071211" },
  // { label: "Can-olin", mun_code: "071211" },
  // { label: "Canawa", mun_code: "071211" },
  // { label: "Cogtong", mun_code: "071211" },
  // { label: "La Union", mun_code: "071211" },
  // { label: "Luan", mun_code: "071211" },
  // { label: "Lungsoda-an", mun_code: "071211" },
  // { label: "Mahangin", mun_code: "071211" },
  // { label: "Pagahat", mun_code: "071211" },
  // { label: "Panadtaran", mun_code: "071211" },
  // { label: "Panas", mun_code: "071211" },
  // { label: "Poblacion", mun_code: "071211" },
  // { label: "San Isidro", mun_code: "071211" },
  // { label: "Tambongan", mun_code: "071211" },
  // { label: "Tawid", mun_code: "071211" },
  // { label: "Tugas", mun_code: "071211" },
  // { label: "Tubod (Tres Rosas)", mun_code: "071211" },
  // { label: "Alegria", mun_code: "071212" },
  // { label: "Bicao", mun_code: "071212" },
  // { label: "Buenavista", mun_code: "071212" },
  // { label: "Buenos Aires", mun_code: "071212" },
  // { label: "Calatrava", mun_code: "071212" },
  // { label: "El Progreso", mun_code: "071212" },
  // { label: "El Salvador", mun_code: "071212" },
  // { label: "Guadalupe", mun_code: "071212" },
  // { label: "Katipunan", mun_code: "071212" },
  // { label: "La Libertad", mun_code: "071212" },
  // { label: "La Paz", mun_code: "071212" },
  // { label: "La Salvacion", mun_code: "071212" },
  // { label: "La Victoria", mun_code: "071212" },
  // { label: "Matin-ao", mun_code: "071212" },
  // { label: "Montehermoso", mun_code: "071212" },
  // { label: "Montesuerte", mun_code: "071212" },
  // { label: "Montesunting", mun_code: "071212" },
  // { label: "Montevideo", mun_code: "071212" },
  // { label: "Nueva Fuerza", mun_code: "071212" },
  // { label: "Nueva Vida Este", mun_code: "071212" },
  // { label: "Nueva Vida Sur", mun_code: "071212" },
  // { label: "Nueva Vida Norte", mun_code: "071212" },
  // { label: "Poblacion Norte", mun_code: "071212" },
  // { label: "Poblacion Sur", mun_code: "071212" },
  // { label: "Tambo-an", mun_code: "071212" },
  // { label: "Vallehermoso", mun_code: "071212" },
  // { label: "Villaflor", mun_code: "071212" },
  // { label: "Villafuerte", mun_code: "071212" },
  // { label: "Villarcayo", mun_code: "071212" },
  // { label: "Alegria", mun_code: "071213" },
  // { label: "Ambuan", mun_code: "071213" },
  // { label: "Baang", mun_code: "071213" },
  // { label: "Bagtic", mun_code: "071213" },
  // { label: "Bongbong", mun_code: "071213" },
  // { label: "Cambailan", mun_code: "071213" },
  // { label: "Candumayao", mun_code: "071213" },
  // { label: "Kang-iras", mun_code: "071213" },
  // { label: "Causwagan Norte", mun_code: "071213" },
  // { label: "Hagbuaya", mun_code: "071213" },
  // { label: "Haguilanan", mun_code: "071213" },
  // { label: "Libertad Sur", mun_code: "071213" },
  // { label: "Liboron", mun_code: "071213" },
  // { label: "Mahayag Norte", mun_code: "071213" },
  // { label: "Mahayag Sur", mun_code: "071213" },
  // { label: "Maitum", mun_code: "071213" },
  // { label: "Mantasida", mun_code: "071213" },
  // { label: "Poblacion", mun_code: "071213" },
  // { label: "Rizal", mun_code: "071213" },
  // { label: "Sinakayanan", mun_code: "071213" },
  // { label: "Triple Union", mun_code: "071213" },
  // { label: "Poblacion Weste", mun_code: "071213" },
  // { label: "Bacani", mun_code: "071214" },
  // { label: "Bogtongbod", mun_code: "071214" },
  // { label: "Bonbon", mun_code: "071214" },
  // { label: "Bontud", mun_code: "071214" },
  // { label: "Buacao", mun_code: "071214" },
  // { label: "Buangan", mun_code: "071214" },
  // { label: "Cabog", mun_code: "071214" },
  // { label: "Caboy", mun_code: "071214" },
  // { label: "Caluwasan", mun_code: "071214" },
  // { label: "Candajec", mun_code: "071214" },
  // { label: "Cantoyoc", mun_code: "071214" },
  // { label: "Comaang", mun_code: "071214" },
  // { label: "Danahao", mun_code: "071214" },
  // { label: "Katipunan", mun_code: "071214" },
  // { label: "Lajog", mun_code: "071214" },
  // { label: "Mataub", mun_code: "071214" },
  // { label: "Nahawan", mun_code: "071214" },
  // { label: "Poblacion Centro", mun_code: "071214" },
  // { label: "Poblacion Norte", mun_code: "071214" },
  // { label: "Poblacion Sur", mun_code: "071214" },
  // { label: "Tangaran", mun_code: "071214" },
  // { label: "Tontunan", mun_code: "071214" },
  // { label: "Tubod", mun_code: "071214" },
  // { label: "Villaflor", mun_code: "071214" },
  // { label: "Anislag", mun_code: "071215" },
  // { label: "Canangca-an", mun_code: "071215" },
  // { label: "Canapnapan", mun_code: "071215" },
  // { label: "Cancatac", mun_code: "071215" },
  // { label: "Pandol", mun_code: "071215" },
  // { label: "Poblacion", mun_code: "071215" },
  // { label: "Sambog", mun_code: "071215" },
  // { label: "Tanday", mun_code: "071215" },
  // { label: "De la Paz", mun_code: "071216" },
  // { label: "Fatima", mun_code: "071216" },
  // { label: "Loreto", mun_code: "071216" },
  // { label: "Lourdes", mun_code: "071216" },
  // { label: "Malayo Norte", mun_code: "071216" },
  // { label: "Malayo Sur", mun_code: "071216" },
  // { label: "Monserrat", mun_code: "071216" },
  // { label: "New Lourdes", mun_code: "071216" },
  // { label: "Patrocinio", mun_code: "071216" },
  // { label: "Poblacion", mun_code: "071216" },
  // { label: "Rosario", mun_code: "071216" },
  // { label: "Salvador", mun_code: "071216" },
  // { label: "San Roque", mun_code: "071216" },
  // { label: "Upper de la Paz", mun_code: "071216" },
  // { label: "Babag", mun_code: "071217" },
  // { label: "Can-oling", mun_code: "071217" },
  // { label: "Candelaria", mun_code: "071217" },
  // { label: "Estaca", mun_code: "071217" },
  // { label: "Cagawasan", mun_code: "071217" },
  // { label: "Cagawitan", mun_code: "071217" },
  // { label: "Caluasan", mun_code: "071217" },
  // { label: "La Esperanza", mun_code: "071217" },
  // { label: "Mahayag", mun_code: "071217" },
  // { label: "Malitbog", mun_code: "071217" },
  // { label: "Poblacion", mun_code: "071217" },
  // { label: "San Miguel", mun_code: "071217" },
  // { label: "San Vicente", mun_code: "071217" },
  // { label: "Santa Cruz", mun_code: "071217" },
  // { label: "Villa Aurora", mun_code: "071217" },
  // { label: "Cabatuan", mun_code: "071218" },
  // { label: "Cantubod", mun_code: "071218" },
  // { label: "Carbon", mun_code: "071218" },
  // { label: "Concepcion", mun_code: "071218" },
  // { label: "Dagohoy", mun_code: "071218" },
  // { label: "Hibale", mun_code: "071218" },
  // { label: "Magtangtang", mun_code: "071218" },
  // { label: "Nahud", mun_code: "071218" },
  // { label: "Poblacion", mun_code: "071218" },
  // { label: "Remedios", mun_code: "071218" },
  // { label: "San Carlos", mun_code: "071218" },
  // { label: "San Miguel", mun_code: "071218" },
  // { label: "Santa Fe", mun_code: "071218" },
  // { label: "Tabok", mun_code: "071218" },
  // { label: "Taming", mun_code: "071218" },
  // { label: "Villa Anunciado", mun_code: "071218" },
  // { label: "Biking", mun_code: "071219" },
  // { label: "Bingag", mun_code: "071219" },
  // { label: "San Isidro (Canlongon)", mun_code: "071219" },
  // { label: "Catarman", mun_code: "071219" },
  // { label: "Dao", mun_code: "071219" },
  // { label: "Mayacabac", mun_code: "071219" },
  // { label: "Poblacion", mun_code: "071219" },
  // { label: "Songculan", mun_code: "071219" },
  // { label: "Tabalong", mun_code: "071219" },
  // { label: "Tinago", mun_code: "071219" },
  // { label: "Totolan", mun_code: "071219" },
  // { label: "Mariveles", mun_code: "071219" },
  // { label: "Abihid", mun_code: "071220" },
  // { label: "Alemania", mun_code: "071220" },
  // { label: "Baguhan", mun_code: "071220" },
  // { label: "Bakilid", mun_code: "071220" },
  // { label: "Balbalan", mun_code: "071220" },
  // { label: "Banban", mun_code: "071220" },
  // { label: "Bauhugan", mun_code: "071220" },
  // { label: "Bilisan", mun_code: "071220" },
  // { label: "Cabagakian", mun_code: "071220" },
  // { label: "Cabanbanan", mun_code: "071220" },
  // { label: "Cadap-agan", mun_code: "071220" },
  // { label: "Cambacol", mun_code: "071220" },
  // { label: "Cambayaon", mun_code: "071220" },
  // { label: "Canhayupon", mun_code: "071220" },
  // { label: "Canlambong", mun_code: "071220" },
  // { label: "Casingan", mun_code: "071220" },
  // { label: "Catugasan", mun_code: "071220" },
  // { label: "Datag", mun_code: "071220" },
  // { label: "Guindaguitan", mun_code: "071220" },
  // { label: "Guingoyuran", mun_code: "071220" },
  // { label: "Ile", mun_code: "071220" },
  // { label: "Lapsaon", mun_code: "071220" },
  // { label: "Limokon Ilaod", mun_code: "071220" },
  // { label: "Limokon Ilaya", mun_code: "071220" },
  // { label: "Luyo", mun_code: "071220" },
  // { label: "Malijao", mun_code: "071220" },
  // { label: "Oac", mun_code: "071220" },
  // { label: "Pagsa", mun_code: "071220" },
  // { label: "Pangihawan", mun_code: "071220" },
  // { label: "Sawang", mun_code: "071220" },
  // { label: "Puangyuta", mun_code: "071220" },
  // { label: "Tangohay", mun_code: "071220" },
  // { label: "Taongon Cabatuan", mun_code: "071220" },
  // { label: "Tawid Bitaog", mun_code: "071220" },
  // { label: "Taongon Can-andam", mun_code: "071220" },
  // { label: "Alejawan", mun_code: "071221" },
  // { label: "Angilan", mun_code: "071221" },
  // { label: "Anibongan", mun_code: "071221" },
  // { label: "Bangwalog", mun_code: "071221" },
  // { label: "Cansuhay", mun_code: "071221" },
  // { label: "Danao", mun_code: "071221" },
  // { label: "Duay", mun_code: "071221" },
  // { label: "Guinsularan", mun_code: "071221" },
  // { label: "Itum", mun_code: "071221" },
  // { label: "Langkis", mun_code: "071221" },
  // { label: "Lobogon", mun_code: "071221" },
  // { label: "Madua Norte", mun_code: "071221" },
  // { label: "Madua Sur", mun_code: "071221" },
  // { label: "Mambool", mun_code: "071221" },
  // { label: "Mawi", mun_code: "071221" },
  // { label: "Payao", mun_code: "071221" },
  // { label: "San Antonio (Pob.)", mun_code: "071221" },
  // { label: "San Isidro", mun_code: "071221" },
  // { label: "San Pedro", mun_code: "071221" },
  // { label: "Imelda", mun_code: "071221" },
  // { label: "Taytay", mun_code: "071221" },
  // { label: "Abijilan", mun_code: "071222" },
  // { label: "Antipolo", mun_code: "071222" },
  // { label: "Basiao", mun_code: "071222" },
  // { label: "Cagwang", mun_code: "071222" },
  // { label: "Calma", mun_code: "071222" },
  // { label: "Cambuyo", mun_code: "071222" },
  // { label: "Canayaon East", mun_code: "071222" },
  // { label: "Canayaon West", mun_code: "071222" },
  // { label: "Candanas", mun_code: "071222" },
  // { label: "Candulao", mun_code: "071222" },
  // { label: "Catmon", mun_code: "071222" },
  // { label: "Cayam", mun_code: "071222" },
  // { label: "Cupa", mun_code: "071222" },
  // { label: "Datag", mun_code: "071222" },
  // { label: "Estaca", mun_code: "071222" },
  // { label: "Libertad", mun_code: "071222" },
  // { label: "Lungsodaan East", mun_code: "071222" },
  // { label: "Lungsodaan West", mun_code: "071222" },
  // { label: "Malinao", mun_code: "071222" },
  // { label: "Manaba", mun_code: "071222" },
  // { label: "Pasong", mun_code: "071222" },
  // { label: "Poblacion East", mun_code: "071222" },
  // { label: "Poblacion West", mun_code: "071222" },
  // { label: "Sacaon", mun_code: "071222" },
  // { label: "Sampong", mun_code: "071222" },
  // { label: "Tabuan", mun_code: "071222" },
  // { label: "Togbongon", mun_code: "071222" },
  // { label: "Ulbujan East", mun_code: "071222" },
  // { label: "Ulbujan West", mun_code: "071222" },
  // { label: "Victoria", mun_code: "071222" },
  // { label: "Basdio", mun_code: "071223" },
  // { label: "Bato", mun_code: "071223" },
  // { label: "Bayong", mun_code: "071223" },
  // { label: "Biabas", mun_code: "071223" },
  // { label: "Bulawan", mun_code: "071223" },
  // { label: "Cabantian", mun_code: "071223" },
  // { label: "Canhaway", mun_code: "071223" },
  // { label: "Cansiwang", mun_code: "071223" },
  // { label: "Casbu", mun_code: "071223" },
  // { label: "Catungawan Sur", mun_code: "071223" },
  // { label: "Catungawan Norte", mun_code: "071223" },
  // { label: "Guinacot", mun_code: "071223" },
  // { label: "Guio-ang", mun_code: "071223" },
  // { label: "Lombog", mun_code: "071223" },
  // { label: "Mayuga", mun_code: "071223" },
  // { label: "Sawang (Pob.)", mun_code: "071223" },
  // { label: "Tabajan (Pob.)", mun_code: "071223" },
  // { label: "Tabunok", mun_code: "071223" },
  // { label: "Trinidad", mun_code: "071223" },
  // { label: "Anonang", mun_code: "071224" },
  // { label: "Bahan", mun_code: "071224" },
  // { label: "Badiang", mun_code: "071224" },
  // { label: "Baguhan", mun_code: "071224" },
  // { label: "Banahao", mun_code: "071224" },
  // { label: "Baogo", mun_code: "071224" },
  // { label: "Bugang", mun_code: "071224" },
  // { label: "Cagawasan", mun_code: "071224" },
  // { label: "Cagayan", mun_code: "071224" },
  // { label: "Cambitoon", mun_code: "071224" },
  // { label: "Canlinte", mun_code: "071224" },
  // { label: "Cawayan", mun_code: "071224" },
  // { label: "Cogon", mun_code: "071224" },
  // { label: "Cuaming", mun_code: "071224" },
  // { label: "Dagnawan", mun_code: "071224" },
  // { label: "Dagohoy", mun_code: "071224" },
  // { label: "Dait Sur", mun_code: "071224" },
  // { label: "Datag", mun_code: "071224" },
  // { label: "Fatima", mun_code: "071224" },
  // { label: "Hambongan", mun_code: "071224" },
  // { label: "Ilaud (Pob.)", mun_code: "071224" },
  // { label: "Ilaya", mun_code: "071224" },
  // { label: "Ilihan", mun_code: "071224" },
  // { label: "Lapacan Norte", mun_code: "071224" },
  // { label: "Lapacan Sur", mun_code: "071224" },
  // { label: "Lawis", mun_code: "071224" },
  // { label: "Liloan Norte", mun_code: "071224" },
  // { label: "Liloan Sur", mun_code: "071224" },
  // { label: "Lomboy", mun_code: "071224" },
  // { label: "Lonoy Cainsican", mun_code: "071224" },
  // { label: "Lonoy Roma", mun_code: "071224" },
  // { label: "Lutao", mun_code: "071224" },
  // { label: "Luyo", mun_code: "071224" },
  // { label: "Mabuhay", mun_code: "071224" },
  // { label: "Maria Rosario", mun_code: "071224" },
  // { label: "Nabuad", mun_code: "071224" },
  // { label: "Napo", mun_code: "071224" },
  // { label: "Ondol", mun_code: "071224" },
  // { label: "Poblacion", mun_code: "071224" },
  // { label: "Riverside", mun_code: "071224" },
  // { label: "Saa", mun_code: "071224" },
  // { label: "San Isidro", mun_code: "071224" },
  // { label: "San Jose", mun_code: "071224" },
  // { label: "Santo Rosario", mun_code: "071224" },
  // { label: "Sua", mun_code: "071224" },
  // { label: "Tambook", mun_code: "071224" },
  // { label: "Tungod", mun_code: "071224" },
  // { label: "U-og", mun_code: "071224" },
  // { label: "Ubujan", mun_code: "071224" },
  // { label: "Alejawan", mun_code: "071225" },
  // { label: "Balili", mun_code: "071225" },
  // { label: "Boctol", mun_code: "071225" },
  // { label: "Buyog", mun_code: "071225" },
  // { label: "Bunga Ilaya", mun_code: "071225" },
  // { label: "Bunga Mar", mun_code: "071225" },
  // { label: "Cabunga-an", mun_code: "071225" },
  // { label: "Calabacita", mun_code: "071225" },
  // { label: "Cambugason", mun_code: "071225" },
  // { label: "Can-ipol", mun_code: "071225" },
  // { label: "Canjulao", mun_code: "071225" },
  // { label: "Cantagay", mun_code: "071225" },
  // { label: "Cantuyoc", mun_code: "071225" },
  // { label: "Can-uba", mun_code: "071225" },
  // { label: "Can-upao", mun_code: "071225" },
  // { label: "Faraon", mun_code: "071225" },
  // { label: "Ipil", mun_code: "071225" },
  // { label: "Kinagbaan", mun_code: "071225" },
  // { label: "Laca", mun_code: "071225" },
  // { label: "Larapan", mun_code: "071225" },
  // { label: "Lonoy", mun_code: "071225" },
  // { label: "Looc", mun_code: "071225" },
  // { label: "Malbog", mun_code: "071225" },
  // { label: "Mayana", mun_code: "071225" },
  // { label: "Naatang", mun_code: "071225" },
  // { label: "Nausok", mun_code: "071225" },
  // { label: "Odiong", mun_code: "071225" },
  // { label: "Pagina", mun_code: "071225" },
  // { label: "Pangdan", mun_code: "071225" },
  // { label: "Poblacion (Pondol)", mun_code: "071225" },
  // { label: "Tejero", mun_code: "071225" },
  // { label: "Tubod Mar", mun_code: "071225" },
  // { label: "Tubod Monte", mun_code: "071225" },
  // { label: "Alumar", mun_code: "071226" },
  // { label: "Banacon", mun_code: "071226" },
  // { label: "Buyog", mun_code: "071226" },
  // { label: "Cabasakan", mun_code: "071226" },
  // { label: "Campao Occidental", mun_code: "071226" },
  // { label: "Campao Oriental", mun_code: "071226" },
  // { label: "Cangmundo", mun_code: "071226" },
  // { label: "Carlos P. Garcia", mun_code: "071226" },
  // { label: "Corte Baud", mun_code: "071226" },
  // { label: "Handumon", mun_code: "071226" },
  // { label: "Jagoliao", mun_code: "071226" },
  // { label: "Jandayan Norte", mun_code: "071226" },
  // { label: "Jandayan Sur", mun_code: "071226" },
  // { label: "Mahanay (Mahanay Island)", mun_code: "071226" },
  // { label: "Nasingin", mun_code: "071226" },
  // { label: "Pandanon", mun_code: "071226" },
  // { label: "Poblacion", mun_code: "071226" },
  // { label: "Saguise", mun_code: "071226" },
  // { label: "Salog", mun_code: "071226" },
  // { label: "San Jose", mun_code: "071226" },
  // { label: "Taytay", mun_code: "071226" },
  // { label: "Tugas", mun_code: "071226" },
  // { label: "Tulang", mun_code: "071226" },
  // { label: "Banban", mun_code: "071227" },
  // { label: "Bonkokan Ilaya", mun_code: "071227" },
  // { label: "Bonkokan Ubos", mun_code: "071227" },
  // { label: "Calvario", mun_code: "071227" },
  // { label: "Candulang", mun_code: "071227" },
  // { label: "Catugasan", mun_code: "071227" },
  // { label: "Cayupo", mun_code: "071227" },
  // { label: "Cogon", mun_code: "071227" },
  // { label: "Jambawan", mun_code: "071227" },
  // { label: "La Fortuna", mun_code: "071227" },
  // { label: "Lomanoy", mun_code: "071227" },
  // { label: "Macalingan", mun_code: "071227" },
  // { label: "Malinao East", mun_code: "071227" },
  // { label: "Malinao West", mun_code: "071227" },
  // { label: "Nagsulay", mun_code: "071227" },
  // { label: "Poblacion", mun_code: "071227" },
  // { label: "Taug", mun_code: "071227" },
  // { label: "Tiguis", mun_code: "071227" },
  // { label: "Agape", mun_code: "071228" },
  // { label: "Alegria Norte", mun_code: "071228" },
  // { label: "Alegria Sur", mun_code: "071228" },
  // { label: "Bonbon", mun_code: "071228" },
  // { label: "Botoc Occidental", mun_code: "071228" },
  // { label: "Botoc Oriental", mun_code: "071228" },
  // { label: "Calvario", mun_code: "071228" },
  // { label: "Concepcion", mun_code: "071228" },
  // { label: "Hinawanan", mun_code: "071228" },
  // { label: "Las Salinas Norte", mun_code: "071228" },
  // { label: "Las Salinas Sur", mun_code: "071228" },
  // { label: "Palo", mun_code: "071228" },
  // { label: "Poblacion Ibabao", mun_code: "071228" },
  // { label: "Poblacion Ubos", mun_code: "071228" },
  // { label: "Sagnap", mun_code: "071228" },
  // { label: "Tambangan", mun_code: "071228" },
  // { label: "Tangcasan Norte", mun_code: "071228" },
  // { label: "Tangcasan Sur", mun_code: "071228" },
  // { label: "Tayong Occidental", mun_code: "071228" },
  // { label: "Tayong Oriental", mun_code: "071228" },
  // { label: "Tocdog Dacu", mun_code: "071228" },
  // { label: "Tocdog Ilaya", mun_code: "071228" },
  // { label: "Villalimpia", mun_code: "071228" },
  // { label: "Yanangan", mun_code: "071228" },
  // { label: "Agape", mun_code: "071229" },
  // { label: "Alegria", mun_code: "071229" },
  // { label: "Bagumbayan", mun_code: "071229" },
  // { label: "Bahian", mun_code: "071229" },
  // { label: "Bonbon Lower", mun_code: "071229" },
  // { label: "Bonbon Upper", mun_code: "071229" },
  // { label: "Buenavista", mun_code: "071229" },
  // { label: "Bugho", mun_code: "071229" },
  // { label: "Cabadiangan", mun_code: "071229" },
  // { label: "Calunasan Norte", mun_code: "071229" },
  // { label: "Calunasan Sur", mun_code: "071229" },
  // { label: "Camayaan", mun_code: "071229" },
  // { label: "Cambance", mun_code: "071229" },
  // { label: "Candabong", mun_code: "071229" },
  // { label: "Candasag", mun_code: "071229" },
  // { label: "Canlasid", mun_code: "071229" },
  // { label: "Gon-ob", mun_code: "071229" },
  // { label: "Gotozon", mun_code: "071229" },
  // { label: "Jimilian", mun_code: "071229" },
  // { label: "Oy", mun_code: "071229" },
  // { label: "Poblacion Sawang", mun_code: "071229" },
  // { label: "Poblacion Ondol", mun_code: "071229" },
  // { label: "Quinoguitan", mun_code: "071229" },
  // { label: "Taytay", mun_code: "071229" },
  // { label: "Tigbao", mun_code: "071229" },
  // { label: "Ugpong", mun_code: "071229" },
  // { label: "Valladolid", mun_code: "071229" },
  // { label: "Villaflor", mun_code: "071229" },
  // { label: "Agsoso", mun_code: "071230" },
  // { label: "Badbad Occidental", mun_code: "071230" },
  // { label: "Badbad Oriental", mun_code: "071230" },
  // { label: "Bagacay Katipunan", mun_code: "071230" },
  // { label: "Bagacay Kawayan", mun_code: "071230" },
  // { label: "Bagacay Saong", mun_code: "071230" },
  // { label: "Bahi", mun_code: "071230" },
  // { label: "Basac", mun_code: "071230" },
  // { label: "Basdacu", mun_code: "071230" },
  // { label: "Basdio", mun_code: "071230" },
  // { label: "Biasong", mun_code: "071230" },
  // { label: "Bongco", mun_code: "071230" },
  // { label: "Bugho", mun_code: "071230" },
  // { label: "Cabacongan", mun_code: "071230" },
  // { label: "Cabadug", mun_code: "071230" },
  // { label: "Cabug", mun_code: "071230" },
  // { label: "Calayugan Norte", mun_code: "071230" },
  // { label: "Calayugan Sur", mun_code: "071230" },
  // { label: "Canmaag", mun_code: "071230" },
  // { label: "Cambaquiz", mun_code: "071230" },
  // { label: "Campatud", mun_code: "071230" },
  // { label: "Candaigan", mun_code: "071230" },
  // { label: "Canhangdon Occidental", mun_code: "071230" },
  // { label: "Canhangdon Oriental", mun_code: "071230" },
  // { label: "Canigaan", mun_code: "071230" },
  // { label: "Canmanoc", mun_code: "071230" },
  // { label: "Cansuagwit", mun_code: "071230" },
  // { label: "Cansubayon", mun_code: "071230" },
  // { label: "Catagbacan Handig", mun_code: "071230" },
  // { label: "Catagbacan Norte", mun_code: "071230" },
  // { label: "Catagbacan Sur", mun_code: "071230" },
  // { label: "Cantam-is Bago", mun_code: "071230" },
  // { label: "Cantaongon", mun_code: "071230" },
  // { label: "Cantumocad", mun_code: "071230" },
  // { label: "Cantam-is Baslay", mun_code: "071230" },
  // { label: "Cogon Norte (Pob.)", mun_code: "071230" },
  // { label: "Cogon Sur", mun_code: "071230" },
  // { label: "Cuasi", mun_code: "071230" },
  // { label: "Genomoan", mun_code: "071230" },
  // { label: "Lintuan", mun_code: "071230" },
  // { label: "Looc", mun_code: "071230" },
  // { label: "Mocpoc Norte", mun_code: "071230" },
  // { label: "Mocpoc Sur", mun_code: "071230" },
  // { label: "Nagtuang", mun_code: "071230" },
  // { label: "Napo (Pob.)", mun_code: "071230" },
  // { label: "Nueva Vida", mun_code: "071230" },
  // { label: "Panangquilon", mun_code: "071230" },
  // { label: "Pantudlan", mun_code: "071230" },
  // { label: "Pig-ot", mun_code: "071230" },
  // { label: "Moto Norte (Pob.)", mun_code: "071230" },
  // { label: "Moto Sur (Pob.)", mun_code: "071230" },
  // { label: "Pondol", mun_code: "071230" },
  // { label: "Quinobcoban", mun_code: "071230" },
  // { label: "Sondol", mun_code: "071230" },
  // { label: "Song-on", mun_code: "071230" },
  // { label: "Talisay", mun_code: "071230" },
  // { label: "Tan-awan", mun_code: "071230" },
  // { label: "Tangnan", mun_code: "071230" },
  // { label: "Taytay", mun_code: "071230" },
  // { label: "Ticugan", mun_code: "071230" },
  // { label: "Tiwi", mun_code: "071230" },
  // { label: "Tontonan", mun_code: "071230" },
  // { label: "Tubodacu", mun_code: "071230" },
  // { label: "Tubodio", mun_code: "071230" },
  // { label: "Tubuan", mun_code: "071230" },
  // { label: "Ubayon", mun_code: "071230" },
  // { label: "Ubojan", mun_code: "071230" },
  // { label: "Abaca", mun_code: "071231" },
  // { label: "Abad Santos", mun_code: "071231" },
  // { label: "Aguipo", mun_code: "071231" },
  // { label: "Concepcion (Banlas)", mun_code: "071231" },
  // { label: "Baybayon", mun_code: "071231" },
  // { label: "Bulawan", mun_code: "071231" },
  // { label: "Cabidian", mun_code: "071231" },
  // { label: "Cawayanan", mun_code: "071231" },
  // { label: "Del Mar", mun_code: "071231" },
  // { label: "Lungsoda-an", mun_code: "071231" },
  // { label: "Marcelo", mun_code: "071231" },
  // { label: "Minol", mun_code: "071231" },
  // { label: "Paraiso", mun_code: "071231" },
  // { label: "Poblacion I", mun_code: "071231" },
  // { label: "Poblacion II", mun_code: "071231" },
  // { label: "San Isidro", mun_code: "071231" },
  // { label: "San Jose", mun_code: "071231" },
  // { label: "San Rafael", mun_code: "071231" },
  // { label: "San Roque (Cabulao)", mun_code: "071231" },
  // { label: "Tambo", mun_code: "071231" },
  // { label: "Tangkigan", mun_code: "071231" },
  // { label: "Valaga", mun_code: "071231" },
  // { label: "San Roque (Aghao)", mun_code: "071232" },
  // { label: "Agahay", mun_code: "071232" },
  // { label: "Aliguay", mun_code: "071232" },
  // { label: "Anislag", mun_code: "071232" },
  // { label: "Bayacabac", mun_code: "071232" },
  // { label: "Bood", mun_code: "071232" },
  // { label: "Busao", mun_code: "071232" },
  // { label: "Cabawan", mun_code: "071232" },
  // { label: "Candavid", mun_code: "071232" },
  // { label: "Dipatlong", mun_code: "071232" },
  // { label: "Guiwanon", mun_code: "071232" },
  // { label: "Jandig", mun_code: "071232" },
  // { label: "Lagtangon", mun_code: "071232" },
  // { label: "Lincod", mun_code: "071232" },
  // { label: "Pagnitoan", mun_code: "071232" },
  // { label: "Poblacion", mun_code: "071232" },
  // { label: "Punsod", mun_code: "071232" },
  // { label: "Punta Cruz", mun_code: "071232" },
  // { label: "San Isidro", mun_code: "071232" },
  // { label: "San Vicente", mun_code: "071232" },
  // { label: "Tinibgan", mun_code: "071232" },
  // { label: "Toril", mun_code: "071232" },
  // { label: "Bil-isan", mun_code: "071233" },
  // { label: "Bolod", mun_code: "071233" },
  // { label: "Danao", mun_code: "071233" },
  // { label: "Doljo", mun_code: "071233" },
  // { label: "Libaong", mun_code: "071233" },
  // { label: "Looc", mun_code: "071233" },
  // { label: "Lourdes", mun_code: "071233" },
  // { label: "Poblacion", mun_code: "071233" },
  // { label: "Tangnan", mun_code: "071233" },
  // { label: "Tawala", mun_code: "071233" },
  // { label: "Aurora", mun_code: "071234" },
  // { label: "Bagacay", mun_code: "071234" },
  // { label: "Bagumbayan", mun_code: "071234" },
  // { label: "Bayong", mun_code: "071234" },
  // { label: "Buenasuerte", mun_code: "071234" },
  // { label: "Cagawasan", mun_code: "071234" },
  // { label: "Cansungay", mun_code: "071234" },
  // { label: "Catagda-an", mun_code: "071234" },
  // { label: "Del Pilar", mun_code: "071234" },
  // { label: "Estaca", mun_code: "071234" },
  // { label: "Ilaud", mun_code: "071234" },
  // { label: "Inaghuban", mun_code: "071234" },
  // { label: "La Suerte", mun_code: "071234" },
  // { label: "Lumbay", mun_code: "071234" },
  // { label: "Lundag", mun_code: "071234" },
  // { label: "Pamacsalan", mun_code: "071234" },
  // { label: "Poblacion", mun_code: "071234" },
  // { label: "Rizal", mun_code: "071234" },
  // { label: "San Carlos", mun_code: "071234" },
  // { label: "San Isidro", mun_code: "071234" },
  // { label: "San Vicente", mun_code: "071234" },
  // { label: "Aguining", mun_code: "071235" },
  // { label: "Basiao", mun_code: "071235" },
  // { label: "Baud", mun_code: "071235" },
  // { label: "Bayog", mun_code: "071235" },
  // { label: "Bogo", mun_code: "071235" },
  // { label: "Bonbonon", mun_code: "071235" },
  // { label: "Canmangao", mun_code: "071235" },
  // { label: "Campamanog", mun_code: "071235" },
  // { label: "Gaus", mun_code: "071235" },
  // { label: "Kabangkalan", mun_code: "071235" },
  // { label: "Lapinig", mun_code: "071235" },
  // { label: "Lipata", mun_code: "071235" },
  // { label: "Poblacion", mun_code: "071235" },
  // { label: "Popoo", mun_code: "071235" },
  // { label: "Saguise", mun_code: "071235" },
  // { label: "San Jose (Tawid)", mun_code: "071235" },
  // { label: "Santo Rosario", mun_code: "071235" },
  // { label: "Tilmobo", mun_code: "071235" },
  // { label: "Tugas", mun_code: "071235" },
  // { label: "Tugnao", mun_code: "071235" },
  // { label: "Villa Milagrosa", mun_code: "071235" },
  // { label: "Butan", mun_code: "071235" },
  // { label: "San Vicente", mun_code: "071235" },
  // { label: "Calangahan", mun_code: "071236" },
  // { label: "Canmano", mun_code: "071236" },
  // { label: "Canmaya Centro", mun_code: "071236" },
  // { label: "Canmaya Diot", mun_code: "071236" },
  // { label: "Dagnawan", mun_code: "071236" },
  // { label: "Kabasacan", mun_code: "071236" },
  // { label: "Kagawasan", mun_code: "071236" },
  // { label: "Katipunan", mun_code: "071236" },
  // { label: "Langtad", mun_code: "071236" },
  // { label: "Libertad Norte", mun_code: "071236" },
  // { label: "Libertad Sur", mun_code: "071236" },
  // { label: "Mantalongon", mun_code: "071236" },
  // { label: "Poblacion", mun_code: "071236" },
  // { label: "Sagbayan Sur", mun_code: "071236" },
  // { label: "San Agustin", mun_code: "071236" },
  // { label: "San Antonio", mun_code: "071236" },
  // { label: "San Isidro", mun_code: "071236" },
  // { label: "San Ramon", mun_code: "071236" },
  // { label: "San Roque", mun_code: "071236" },
  // { label: "San Vicente Norte", mun_code: "071236" },
  // { label: "San Vicente Sur", mun_code: "071236" },
  // { label: "Santa Catalina", mun_code: "071236" },
  // { label: "Santa Cruz", mun_code: "071236" },
  // { label: "Ubojan", mun_code: "071236" },
  // { label: "Abehilan", mun_code: "071237" },
  // { label: "Baunos", mun_code: "071237" },
  // { label: "Cabanugan", mun_code: "071237" },
  // { label: "Caimbang", mun_code: "071237" },
  // { label: "Cambansag", mun_code: "071237" },
  // { label: "Candungao", mun_code: "071237" },
  // { label: "Cansague Norte", mun_code: "071237" },
  // { label: "Cansague Sur", mun_code: "071237" },
  // { label: "Causwagan Sur", mun_code: "071237" },
  // { label: "Masonoy", mun_code: "071237" },
  // { label: "Poblacion", mun_code: "071237" },
  // { label: "Baryong Daan", mun_code: "071237" },
  // { label: "Bayongan", mun_code: "071238" },
  // { label: "Bugang", mun_code: "071238" },
  // { label: "Cabangahan", mun_code: "071238" },
  // { label: "Kagawasan", mun_code: "071238" },
  // { label: "Camanaga", mun_code: "071238" },
  // { label: "Cambangay Norte", mun_code: "071238" },
  // { label: "Capayas", mun_code: "071238" },
  // { label: "Corazon", mun_code: "071238" },
  // { label: "Garcia", mun_code: "071238" },
  // { label: "Hagbuyo", mun_code: "071238" },
  // { label: "Caluasan", mun_code: "071238" },
  // { label: "Mahayag", mun_code: "071238" },
  // { label: "Poblacion", mun_code: "071238" },
  // { label: "San Isidro", mun_code: "071238" },
  // { label: "San Jose", mun_code: "071238" },
  // { label: "San Vicente", mun_code: "071238" },
  // { label: "Tomoc", mun_code: "071238" },
  // { label: "Bayawahan", mun_code: "071239" },
  // { label: "Cabancalan", mun_code: "071239" },
  // { label: "Calinga-an", mun_code: "071239" },
  // { label: "Calinginan Norte", mun_code: "071239" },
  // { label: "Calinginan Sur", mun_code: "071239" },
  // { label: "Cambagui", mun_code: "071239" },
  // { label: "Ewon", mun_code: "071239" },
  // { label: "Guinob-an", mun_code: "071239" },
  // { label: "Lagtangan", mun_code: "071239" },
  // { label: "Licolico", mun_code: "071239" },
  // { label: "Lobgob", mun_code: "071239" },
  // { label: "Magsaysay", mun_code: "071239" },
  // { label: "Poblacion", mun_code: "071239" },
  // { label: "Abachanan", mun_code: "071240" },
  // { label: "Anibongan", mun_code: "071240" },
  // { label: "Bugsoc", mun_code: "071240" },
  // { label: "Canlangit", mun_code: "071240" },
  // { label: "Canta-ub", mun_code: "071240" },
  // { label: "Casilay", mun_code: "071240" },
  // { label: "Danicop", mun_code: "071240" },
  // { label: "Dusita", mun_code: "071240" },
  // { label: "Cahayag", mun_code: "071240" },
  // { label: "La Union", mun_code: "071240" },
  // { label: "Lataban", mun_code: "071240" },
  // { label: "Magsaysay", mun_code: "071240" },
  // { label: "Matin-ao", mun_code: "071240" },
  // { label: "Man-od", mun_code: "071240" },
  // { label: "Poblacion", mun_code: "071240" },
  // { label: "Salvador", mun_code: "071240" },
  // { label: "San Agustin", mun_code: "071240" },
  // { label: "San Isidro", mun_code: "071240" },
  // { label: "San Jose", mun_code: "071240" },
  // { label: "San Juan", mun_code: "071240" },
  // { label: "Santa Cruz", mun_code: "071240" },
  // { label: "Villa Garcia", mun_code: "071240" },
  // { label: "Abucay Norte", mun_code: "071241" },
  // { label: "Abucay Sur", mun_code: "071241" },
  // { label: "Badiang", mun_code: "071241" },
  // { label: "Bahaybahay", mun_code: "071241" },
  // { label: "Cambuac Norte", mun_code: "071241" },
  // { label: "Cambuac Sur", mun_code: "071241" },
  // { label: "Canagong", mun_code: "071241" },
  // { label: "Libjo", mun_code: "071241" },
  // { label: "Poblacion I", mun_code: "071241" },
  // { label: "Poblacion II", mun_code: "071241" },
  // { label: "Bool", mun_code: "071242" },
  // { label: "Booy", mun_code: "071242" },
  // { label: "Cabawan", mun_code: "071242" },
  // { label: "Cogon", mun_code: "071242" },
  // { label: "Dao", mun_code: "071242" },
  // { label: "Dampas", mun_code: "071242" },
  // { label: "Manga", mun_code: "071242" },
  // { label: "Mansasa", mun_code: "071242" },
  // { label: "Poblacion I", mun_code: "071242" },
  // { label: "Poblacion II", mun_code: "071242" },
  // { label: "Poblacion III", mun_code: "071242" },
  // { label: "San Isidro", mun_code: "071242" },
  // { label: "Taloto", mun_code: "071242" },
  // { label: "Tiptip", mun_code: "071242" },
  // { label: "Ubujan", mun_code: "071242" },
  // { label: "Bagacay", mun_code: "071243" },
  // { label: "Balintawak", mun_code: "071243" },
  // { label: "Burgos", mun_code: "071243" },
  // { label: "Busalian", mun_code: "071243" },
  // { label: "Calituban", mun_code: "071243" },
  // { label: "Cataban", mun_code: "071243" },
  // { label: "Guindacpan", mun_code: "071243" },
  // { label: "Magsaysay", mun_code: "071243" },
  // { label: "Mahanay", mun_code: "071243" },
  // { label: "Nocnocan", mun_code: "071243" },
  // { label: "Poblacion", mun_code: "071243" },
  // { label: "Rizal", mun_code: "071243" },
  // { label: "Sag", mun_code: "071243" },
  // { label: "San Agustin", mun_code: "071243" },
  // { label: "San Carlos", mun_code: "071243" },
  // { label: "San Francisco", mun_code: "071243" },
  // { label: "San Isidro", mun_code: "071243" },
  // { label: "San Jose", mun_code: "071243" },
  // { label: "San Pedro", mun_code: "071243" },
  // { label: "San Roque", mun_code: "071243" },
  // { label: "Sikatuna", mun_code: "071243" },
  // { label: "Suba", mun_code: "071243" },
  // { label: "Tanghaligue", mun_code: "071243" },
  // { label: "Zamora", mun_code: "071243" },
  // { label: "Banlasan", mun_code: "071244" },
  // { label: "Bongbong", mun_code: "071244" },
  // { label: "Catoogan", mun_code: "071244" },
  // { label: "Guinobatan", mun_code: "071244" },
  // { label: "Hinlayagan Ilaud", mun_code: "071244" },
  // { label: "Hinlayagan Ilaya", mun_code: "071244" },
  // { label: "Kauswagan", mun_code: "071244" },
  // { label: "Kinan-oan", mun_code: "071244" },
  // { label: "La Union", mun_code: "071244" },
  // { label: "La Victoria", mun_code: "071244" },
  // { label: "Mabuhay Cabigohan", mun_code: "071244" },
  // { label: "Mahagbu", mun_code: "071244" },
  // { label: "Manuel M. Roxas", mun_code: "071244" },
  // { label: "Poblacion", mun_code: "071244" },
  // { label: "San Isidro", mun_code: "071244" },
  // { label: "San Vicente", mun_code: "071244" },
  // { label: "Santo Tomas", mun_code: "071244" },
  // { label: "Soom", mun_code: "071244" },
  // { label: "Tagum Norte", mun_code: "071244" },
  // { label: "Tagum Sur", mun_code: "071244" },
  // { label: "Bagongbanwa", mun_code: "071245" },
  // { label: "Bunacan", mun_code: "071245" },
  // { label: "Banlasan", mun_code: "071245" },
  // { label: "Batasan (Batasan Island)", mun_code: "071245" },
  // { label: "Bilangbilangan (Bilangbilangan Island)", mun_code: "071245" },
  // { label: "Bosongon", mun_code: "071245" },
  // { label: "Buenos Aires", mun_code: "071245" },
  // { label: "Cabulihan", mun_code: "071245" },
  // { label: "Cahayag", mun_code: "071245" },
  // { label: "Cawayanan", mun_code: "071245" },
  // { label: "Centro (Pob.)", mun_code: "071245" },
  // { label: "Genonocan", mun_code: "071245" },
  // { label: "Guiwanon", mun_code: "071245" },
  // { label: "Ilihan Norte", mun_code: "071245" },
  // { label: "Ilihan Sur", mun_code: "071245" },
  // { label: "Libertad", mun_code: "071245" },
  // { label: "Macaas", mun_code: "071245" },
  // { label: "Mocaboc Island", mun_code: "071245" },
  // { label: "Matabao", mun_code: "071245" },
  // { label: "Panadtaran", mun_code: "071245" },
  // { label: "Panaytayon", mun_code: "071245" },
  // { label: "Pandan", mun_code: "071245" },
  // { label: "Pangapasan (Pangapasan Island)", mun_code: "071245" },
  // { label: "Pinayagan Norte", mun_code: "071245" },
  // { label: "Pinayagan Sur", mun_code: "071245" },
  // { label: "Pooc Occidental (Pob.)", mun_code: "071245" },
  // { label: "Pooc Oriental (Pob.)", mun_code: "071245" },
  // { label: "Potohan", mun_code: "071245" },
  // { label: "Talenceras", mun_code: "071245" },
  // { label: "Tan-awan", mun_code: "071245" },
  // { label: "Tinangnan", mun_code: "071245" },
  // { label: "Ubay Island", mun_code: "071245" },
  // { label: "Ubojan", mun_code: "071245" },
  // { label: "Villanueva", mun_code: "071245" },
  // { label: "Achila", mun_code: "071246" },
  // { label: "Bay-ang", mun_code: "071246" },
  // { label: "Biabas", mun_code: "071246" },
  // { label: "Benliw", mun_code: "071246" },
  // { label: "Bongbong", mun_code: "071246" },
  // { label: "Bood", mun_code: "071246" },
  // { label: "Buenavista", mun_code: "071246" },
  // { label: "Cagting", mun_code: "071246" },
  // { label: "Camali-an", mun_code: "071246" },
  // { label: "Camambugan", mun_code: "071246" },
  // { label: "Casate", mun_code: "071246" },
  // { label: "Katarungan", mun_code: "071246" },
  // { label: "Cuya", mun_code: "071246" },
  // { label: "Fatima", mun_code: "071246" },
  // { label: "Gabi", mun_code: "071246" },
  // { label: "Governor Boyles", mun_code: "071246" },
  // { label: "Guintabo-an", mun_code: "071246" },
  // { label: "Hambabauran", mun_code: "071246" },
  // { label: "Humayhumay", mun_code: "071246" },
  // { label: "Ilihan", mun_code: "071246" },
  // { label: "Imelda", mun_code: "071246" },
  // { label: "Juagdan", mun_code: "071246" },
  // { label: "Calanggaman", mun_code: "071246" },
  // { label: "Los Angeles", mun_code: "071246" },
  // { label: "Lomangog", mun_code: "071246" },
  // { label: "Pag-asa", mun_code: "071246" },
  // { label: "Pangpang", mun_code: "071246" },
  // { label: "Poblacion", mun_code: "071246" },
  // { label: "San Francisco", mun_code: "071246" },
  // { label: "San Isidro", mun_code: "071246" },
  // { label: "San Pascual", mun_code: "071246" },
  // { label: "San Vicente", mun_code: "071246" },
  // { label: "Sentinila", mun_code: "071246" },
  // { label: "Sinandigan", mun_code: "071246" },
  // { label: "Tapal", mun_code: "071246" },
  // { label: "Tapon", mun_code: "071246" },
  // { label: "Tintinan", mun_code: "071246" },
  // { label: "Tipolo", mun_code: "071246" },
  // { label: "Tubog", mun_code: "071246" },
  // { label: "Tuboran", mun_code: "071246" },
  // { label: "Union", mun_code: "071246" },
  // { label: "Villa Teresita", mun_code: "071246" },
  // { label: "Bulilis", mun_code: "071246" },
  // { label: "California", mun_code: "071246" },
  // { label: "Adlawan", mun_code: "071247" },
  // { label: "Anas", mun_code: "071247" },
  // { label: "Anonang", mun_code: "071247" },
  // { label: "Anoyon", mun_code: "071247" },
  // { label: "Balingasao", mun_code: "071247" },
  // { label: "Botong", mun_code: "071247" },
  // { label: "Buyog", mun_code: "071247" },
  // { label: "Canduao Occidental", mun_code: "071247" },
  // { label: "Canduao Oriental", mun_code: "071247" },
  // { label: "Canlusong", mun_code: "071247" },
  // { label: "Canmanico", mun_code: "071247" },
  // { label: "Cansibao", mun_code: "071247" },
  // { label: "Catug-a", mun_code: "071247" },
  // { label: "Cutcutan", mun_code: "071247" },
  // { label: "Danao", mun_code: "071247" },
  // { label: "Genoveva", mun_code: "071247" },
  // { label: "Ginopolan (Ginopolan Proper)", mun_code: "071247" },
  // { label: "La Victoria", mun_code: "071247" },
  // { label: "Lantang", mun_code: "071247" },
  // { label: "Limocon", mun_code: "071247" },
  // { label: "Loctob", mun_code: "071247" },
  // { label: "Magsaysay", mun_code: "071247" },
  // { label: "Marawis", mun_code: "071247" },
  // { label: "Maubo", mun_code: "071247" },
  // { label: "Nailo", mun_code: "071247" },
  // { label: "Omjon", mun_code: "071247" },
  // { label: "Pangi-an", mun_code: "071247" },
  // { label: "Poblacion Occidental", mun_code: "071247" },
  // { label: "Poblacion Oriental", mun_code: "071247" },
  // { label: "Simang", mun_code: "071247" },
  // { label: "Taug", mun_code: "071247" },
  // { label: "Tausion", mun_code: "071247" },
  // { label: "Taytay", mun_code: "071247" },
  // { label: "Ticum", mun_code: "071247" },
  // { label: "Banderahan (Upper Ginopolan)", mun_code: "071247" },
  // { label: "Bilangbilangan Dako", mun_code: "071248" },
  // { label: "Bilangbilangan Diot", mun_code: "071248" },
  // { label: "Hingotanan East", mun_code: "071248" },
  // { label: "Hingotanan West", mun_code: "071248" },
  // { label: "Liberty", mun_code: "071248" },
  // { label: "Malingin", mun_code: "071248" },
  // { label: "Mandawa", mun_code: "071248" },
  // { label: "Maomawan", mun_code: "071248" },
  // { label: "Nueva Esperanza", mun_code: "071248" },
  // { label: "Nueva Estrella", mun_code: "071248" },
  // { label: "Pinamgo", mun_code: "071248" },
  // { label: "Poblacion (Bien Unido)", mun_code: "071248" },
  // { label: "Puerto San Pedro", mun_code: "071248" },
  // { label: "Sagasa", mun_code: "071248" },
  // { label: "Tuboran", mun_code: "071248" },
  // { label: "Cabadiangan", mun_code: "072201" },
  // { label: "Cabil-isan", mun_code: "072201" },
  // { label: "Candabong", mun_code: "072201" },
  // { label: "Lawaan", mun_code: "072201" },
  // { label: "Manga", mun_code: "072201" },
  // { label: "Palanas", mun_code: "072201" },
  // { label: "Poblacion", mun_code: "072201" },
  // { label: "Polo", mun_code: "072201" },
  // { label: "Salagmaya", mun_code: "072201" },
  // { label: "Atabay", mun_code: "072202" },
  // { label: "Daan-Lungsod", mun_code: "072202" },
  // { label: "Guiwang", mun_code: "072202" },
  // { label: "Nug-as", mun_code: "072202" },
  // { label: "Pasol", mun_code: "072202" },
  // { label: "Poblacion", mun_code: "072202" },
  // { label: "Pugalo", mun_code: "072202" },
  // { label: "San Agustin", mun_code: "072202" },
  // { label: "Compostela", mun_code: "072203" },
  // { label: "Guadalupe", mun_code: "072203" },
  // { label: "Legaspi", mun_code: "072203" },
  // { label: "Lepanto", mun_code: "072203" },
  // { label: "Madridejos", mun_code: "072203" },
  // { label: "Montpeller", mun_code: "072203" },
  // { label: "Poblacion", mun_code: "072203" },
  // { label: "Santa Filomena", mun_code: "072203" },
  // { label: "Valencia", mun_code: "072203" },
  // { label: "Angilan", mun_code: "072204" },
  // { label: "Bojo", mun_code: "072204" },
  // { label: "Bonbon", mun_code: "072204" },
  // { label: "Esperanza", mun_code: "072204" },
  // { label: "Kandingan", mun_code: "072204" },
  // { label: "Kantabogon", mun_code: "072204" },
  // { label: "Kawasan", mun_code: "072204" },
  // { label: "Olango", mun_code: "072204" },
  // { label: "Poblacion", mun_code: "072204" },
  // { label: "Punay", mun_code: "072204" },
  // { label: "Rosario", mun_code: "072204" },
  // { label: "Saksak", mun_code: "072204" },
  // { label: "Tampa-an", mun_code: "072204" },
  // { label: "Toyokon", mun_code: "072204" },
  // { label: "Zaragosa", mun_code: "072204" },
  // { label: "Alambijud", mun_code: "072205" },
  // { label: "Anajao", mun_code: "072205" },
  // { label: "Apo", mun_code: "072205" },
  // { label: "Balaas", mun_code: "072205" },
  // { label: "Balisong", mun_code: "072205" },
  // { label: "Binlod", mun_code: "072205" },
  // { label: "Bogo", mun_code: "072205" },
  // { label: "Butong", mun_code: "072205" },
  // { label: "Bug-ot", mun_code: "072205" },
  // { label: "Bulasa", mun_code: "072205" },
  // { label: "Calagasan", mun_code: "072205" },
  // { label: "Canbantug", mun_code: "072205" },
  // { label: "Canbanua", mun_code: "072205" },
  // { label: "Cansuje", mun_code: "072205" },
  // { label: "Capio-an", mun_code: "072205" },
  // { label: "Casay", mun_code: "072205" },
  // { label: "Catang", mun_code: "072205" },
  // { label: "Colawin", mun_code: "072205" },
  // { label: "Conalum", mun_code: "072205" },
  // { label: "Guiwanon", mun_code: "072205" },
  // { label: "Gutlang", mun_code: "072205" },
  // { label: "Jampang", mun_code: "072205" },
  // { label: "Jomgao", mun_code: "072205" },
  // { label: "Lamacan", mun_code: "072205" },
  // { label: "Langtad", mun_code: "072205" },
  // { label: "Langub", mun_code: "072205" },
  // { label: "Lapay", mun_code: "072205" },
  // { label: "Lengigon", mun_code: "072205" },
  // { label: "Linut-od", mun_code: "072205" },
  // { label: "Mabasa", mun_code: "072205" },
  // { label: "Mandilikit", mun_code: "072205" },
  // { label: "Mompeller", mun_code: "072205" },
  // { label: "Panadtaran", mun_code: "072205" },
  // { label: "Poblacion", mun_code: "072205" },
  // { label: "Sua", mun_code: "072205" },
  // { label: "Sumaguan", mun_code: "072205" },
  // { label: "Tabayag", mun_code: "072205" },
  // { label: "Talaga", mun_code: "072205" },
  // { label: "Talaytay", mun_code: "072205" },
  // { label: "Talo-ot", mun_code: "072205" },
  // { label: "Tiguib", mun_code: "072205" },
  // { label: "Tulang", mun_code: "072205" },
  // { label: "Tulic", mun_code: "072205" },
  // { label: "Ubaub", mun_code: "072205" },
  // { label: "Usmad", mun_code: "072205" },
  // { label: "Agbanga", mun_code: "072206" },
  // { label: "Agtugop", mun_code: "072206" },
  // { label: "Bago", mun_code: "072206" },
  // { label: "Bairan", mun_code: "072206" },
  // { label: "Banban", mun_code: "072206" },
  // { label: "Baye", mun_code: "072206" },
  // { label: "Bog-o", mun_code: "072206" },
  // { label: "Kaluangan", mun_code: "072206" },
  // { label: "Lanao", mun_code: "072206" },
  // { label: "Langub", mun_code: "072206" },
  // { label: "Looc Norte", mun_code: "072206" },
  // { label: "Lunas", mun_code: "072206" },
  // { label: "Magcalape", mun_code: "072206" },
  // { label: "Manguiao", mun_code: "072206" },
  // { label: "New Bago", mun_code: "072206" },
  // { label: "Owak", mun_code: "072206" },
  // { label: "Poblacion", mun_code: "072206" },
  // { label: "Saksak", mun_code: "072206" },
  // { label: "San Isidro", mun_code: "072206" },
  // { label: "San Roque", mun_code: "072206" },
  // { label: "Santa Lucia", mun_code: "072206" },
  // { label: "Santa Rita", mun_code: "072206" },
  // { label: "Tag-amakan", mun_code: "072206" },
  // { label: "Tagbubonga", mun_code: "072206" },
  // { label: "Tubigagmanok", mun_code: "072206" },
  // { label: "Tubod", mun_code: "072206" },
  // { label: "Ubogon", mun_code: "072206" },
  // { label: "Alawijao", mun_code: "072207" },
  // { label: "Balhaan", mun_code: "072207" },
  // { label: "Banhigan", mun_code: "072207" },
  // { label: "Basak", mun_code: "072207" },
  // { label: "Basiao", mun_code: "072207" },
  // { label: "Bato", mun_code: "072207" },
  // { label: "Bugas", mun_code: "072207" },
  // { label: "Calangcang", mun_code: "072207" },
  // { label: "Candiis", mun_code: "072207" },
  // { label: "Dagatan", mun_code: "072207" },
  // { label: "Dobdob", mun_code: "072207" },
  // { label: "Ginablan", mun_code: "072207" },
  // { label: "Lambug", mun_code: "072207" },
  // { label: "Malabago", mun_code: "072207" },
  // { label: "Malhiao", mun_code: "072207" },
  // { label: "Manduyong", mun_code: "072207" },
  // { label: "Matutinao", mun_code: "072207" },
  // { label: "Patong", mun_code: "072207" },
  // { label: "Poblacion", mun_code: "072207" },
  // { label: "Sanlagan", mun_code: "072207" },
  // { label: "Santicon", mun_code: "072207" },
  // { label: "Sohoton", mun_code: "072207" },
  // { label: "Sulsugan", mun_code: "072207" },
  // { label: "Talayong", mun_code: "072207" },
  // { label: "Taytay", mun_code: "072207" },
  // { label: "Tigbao", mun_code: "072207" },
  // { label: "Tiguib", mun_code: "072207" },
  // { label: "Tubod", mun_code: "072207" },
  // { label: "Zaragosa", mun_code: "072207" },
  // { label: "Abucayan", mun_code: "072208" },
  // { label: "Aliwanay", mun_code: "072208" },
  // { label: "Arpili", mun_code: "072208" },
  // { label: "Bayong", mun_code: "072208" },
  // { label: "Biasong", mun_code: "072208" },
  // { label: "Buanoy", mun_code: "072208" },
  // { label: "Cabagdalan", mun_code: "072208" },
  // { label: "Cabasiangan", mun_code: "072208" },
  // { label: "Cambuhawe", mun_code: "072208" },
  // { label: "Cansomoroy", mun_code: "072208" },
  // { label: "Cantibas", mun_code: "072208" },
  // { label: "Cantuod", mun_code: "072208" },
  // { label: "Duangan", mun_code: "072208" },
  // { label: "Gaas", mun_code: "072208" },
  // { label: "Ginatilan", mun_code: "072208" },
  // { label: "Hingatmonan", mun_code: "072208" },
  // { label: "Lamesa", mun_code: "072208" },
  // { label: "Liki", mun_code: "072208" },
  // { label: "Luca", mun_code: "072208" },
  // { label: "Matun-og", mun_code: "072208" },
  // { label: "Nangka", mun_code: "072208" },
  // { label: "Pondol", mun_code: "072208" },
  // { label: "Prenza", mun_code: "072208" },
  // { label: "Singsing", mun_code: "072208" },
  // { label: "Sunog", mun_code: "072208" },
  // { label: "Vito", mun_code: "072208" },
  // { label: "Baliwagan (Pob.)", mun_code: "072208" },
  // { label: "Atop-atop", mun_code: "072209" },
  // { label: "Baigad", mun_code: "072209" },
  // { label: "Baod", mun_code: "072209" },
  // { label: "Binaobao (Pob.)", mun_code: "072209" },
  // { label: "Botigues", mun_code: "072209" },
  // { label: "Kabac", mun_code: "072209" },
  // { label: "Doong", mun_code: "072209" },
  // { label: "Hilotongan", mun_code: "072209" },
  // { label: "Guiwanon", mun_code: "072209" },
  // { label: "Kabangbang", mun_code: "072209" },
  // { label: "Kampingganon", mun_code: "072209" },
  // { label: "Kangkaibe", mun_code: "072209" },
  // { label: "Lipayran", mun_code: "072209" },
  // { label: "Luyongbaybay", mun_code: "072209" },
  // { label: "Mojon", mun_code: "072209" },
  // { label: "Obo-ob", mun_code: "072209" },
  // { label: "Patao", mun_code: "072209" },
  // { label: "Putian", mun_code: "072209" },
  // { label: "Sillon", mun_code: "072209" },
  // { label: "Sungko", mun_code: "072209" },
  // { label: "Suba (Pob.)", mun_code: "072209" },
  // { label: "Sulangan", mun_code: "072209" },
  // { label: "Tamiao", mun_code: "072209" },
  // { label: "Bantigue (Pob.)", mun_code: "072209" },
  // { label: "Ticad (Pob.)", mun_code: "072209" },
  // { label: "Azucena", mun_code: "072210" },
  // { label: "Bagakay", mun_code: "072210" },
  // { label: "Balao", mun_code: "072210" },
  // { label: "Bolocboloc", mun_code: "072210" },
  // { label: "Budbud", mun_code: "072210" },
  // { label: "Bugtong Kawayan", mun_code: "072210" },
  // { label: "Cabcaban", mun_code: "072210" },
  // { label: "Campangga", mun_code: "072210" },
  // { label: "Dakit", mun_code: "072210" },
  // { label: "Giloctog", mun_code: "072210" },
  // { label: "Guibuangan", mun_code: "072210" },
  // { label: "Giwanon", mun_code: "072210" },
  // { label: "Gunting", mun_code: "072210" },
  // { label: "Hilasgasan", mun_code: "072210" },
  // { label: "Japitan", mun_code: "072210" },
  // { label: "Cagay", mun_code: "072210" },
  // { label: "Kalubihan", mun_code: "072210" },
  // { label: "Kangdampas", mun_code: "072210" },
  // { label: "Candugay", mun_code: "072210" },
  // { label: "Luhod", mun_code: "072210" },
  // { label: "Lupo", mun_code: "072210" },
  // { label: "Luyo", mun_code: "072210" },
  // { label: "Maghanoy", mun_code: "072210" },
  // { label: "Maigang", mun_code: "072210" },
  // { label: "Malolos", mun_code: "072210" },
  // { label: "Mantalongon", mun_code: "072210" },
  // { label: "Mantayupan", mun_code: "072210" },
  // { label: "Mayana", mun_code: "072210" },
  // { label: "Minolos", mun_code: "072210" },
  // { label: "Nabunturan", mun_code: "072210" },
  // { label: "Nasipit", mun_code: "072210" },
  // { label: "Pancil", mun_code: "072210" },
  // { label: "Pangpang", mun_code: "072210" },
  // { label: "Paril", mun_code: "072210" },
  // { label: "Patupat", mun_code: "072210" },
  // { label: "Poblacion", mun_code: "072210" },
  // { label: "San Rafael", mun_code: "072210" },
  // { label: "Santa Ana", mun_code: "072210" },
  // { label: "Sayaw", mun_code: "072210" },
  // { label: "Tal-ot", mun_code: "072210" },
  // { label: "Tubod", mun_code: "072210" },
  // { label: "Vito", mun_code: "072210" },
  // { label: "Cogon (Pob.)", mun_code: "072211" },
  // { label: "Anonang Norte", mun_code: "072211" },
  // { label: "Anonang Sur", mun_code: "072211" },
  // { label: "Banban", mun_code: "072211" },
  // { label: "Binabag", mun_code: "072211" },
  // { label: "Bungtod (Pob.)", mun_code: "072211" },
  // { label: "Carbon (Pob.)", mun_code: "072211" },
  // { label: "Cayang", mun_code: "072211" },
  // { label: "Dakit", mun_code: "072211" },
  // { label: "Don Pedro Rodriguez", mun_code: "072211" },
  // { label: "Gairan", mun_code: "072211" },
  // { label: "Guadalupe", mun_code: "072211" },
  // { label: "La Paz", mun_code: "072211" },
  // { label: "La Purisima Concepcion (Pob.)", mun_code: "072211" },
  // { label: "Libertad", mun_code: "072211" },
  // { label: "Lourdes (Pob.)", mun_code: "072211" },
  // { label: "Malingin", mun_code: "072211" },
  // { label: "Marangog", mun_code: "072211" },
  // { label: "Nailon", mun_code: "072211" },
  // { label: "Odlot", mun_code: "072211" },
  // { label: "Pandan (Pandan Heights)", mun_code: "072211" },
  // { label: "Polambato", mun_code: "072211" },
  // { label: "Sambag (Pob.)", mun_code: "072211" },
  // { label: "San Vicente (Pob.)", mun_code: "072211" },
  // { label: "Santo Rosario (Pob.)", mun_code: "072211" },
  // { label: "Siocon", mun_code: "072211" },
  // { label: "Taytayan", mun_code: "072211" },
  // { label: "Sudlonon", mun_code: "072211" },
  // { label: "Baclayan", mun_code: "072212" },
  // { label: "El Pardo", mun_code: "072212" },
  // { label: "Granada", mun_code: "072212" },
  // { label: "Lower Becerril", mun_code: "072212" },
  // { label: "Poblacion", mun_code: "072212" },
  // { label: "San Antonio", mun_code: "072212" },
  // { label: "Upper Becerril", mun_code: "072212" },
  // { label: "Arbor", mun_code: "072212" },
  // { label: "Lunop", mun_code: "072212" },
  // { label: "Nangka", mun_code: "072212" },
  // { label: "South Granada", mun_code: "072212" },
  // { label: "Bagacay", mun_code: "072213" },
  // { label: "Bili", mun_code: "072213" },
  // { label: "Bingay", mun_code: "072213" },
  // { label: "Bongdo", mun_code: "072213" },
  // { label: "Bongdo Gua", mun_code: "072213" },
  // { label: "Bongoyan", mun_code: "072213" },
  // { label: "Cadaruhan", mun_code: "072213" },
  // { label: "Cajel", mun_code: "072213" },
  // { label: "Campusong", mun_code: "072213" },
  // { label: "Clavera", mun_code: "072213" },
  // { label: "Don Gregorio Antigua (Taytayan)", mun_code: "072213" },
  // { label: "Laaw", mun_code: "072213" },
  // { label: "Lugo", mun_code: "072213" },
  // { label: "Managase", mun_code: "072213" },
  // { label: "Poblacion", mun_code: "072213" },
  // { label: "Sagay", mun_code: "072213" },
  // { label: "San Jose", mun_code: "072213" },
  // { label: "Tabunan", mun_code: "072213" },
  // { label: "Tagnucan", mun_code: "072213" },
  // { label: "Bolinawan", mun_code: "072214" },
  // { label: "Buenavista", mun_code: "072214" },
  // { label: "Calidngan", mun_code: "072214" },
  // { label: "Can-asujan", mun_code: "072214" },
  // { label: "Guadalupe", mun_code: "072214" },
  // { label: "Liburon", mun_code: "072214" },
  // { label: "Napo", mun_code: "072214" },
  // { label: "Ocana", mun_code: "072214" },
  // { label: "Perrelos", mun_code: "072214" },
  // { label: "Valencia", mun_code: "072214" },
  // { label: "Valladolid", mun_code: "072214" },
  // { label: "Poblacion I", mun_code: "072214" },
  // { label: "Poblacion II", mun_code: "072214" },
  // { label: "Poblacion III", mun_code: "072214" },
  // { label: "Tuyom", mun_code: "072214" },
  // { label: "Baring", mun_code: "072215" },
  // { label: "Cantipay", mun_code: "072215" },
  // { label: "Cantumog", mun_code: "072215" },
  // { label: "Cantukong", mun_code: "072215" },
  // { label: "Caurasan", mun_code: "072215" },
  // { label: "Corte", mun_code: "072215" },
  // { label: "Dawis Norte", mun_code: "072215" },
  // { label: "Dawis Sur", mun_code: "072215" },
  // { label: "Cogon East", mun_code: "072215" },
  // { label: "Hagnaya", mun_code: "072215" },
  // { label: "Ipil", mun_code: "072215" },
  // { label: "Lanipga", mun_code: "072215" },
  // { label: "Liboron", mun_code: "072215" },
  // { label: "Lower Natimao-an", mun_code: "072215" },
  // { label: "Luyang", mun_code: "072215" },
  // { label: "Poblacion", mun_code: "072215" },
  // { label: "Puente", mun_code: "072215" },
  // { label: "Sac-on", mun_code: "072215" },
  // { label: "Triumfo", mun_code: "072215" },
  // { label: "Upper Natimao-an", mun_code: "072215" },
  // { label: "Cogon West", mun_code: "072215" },
  // { label: "Agsuwao", mun_code: "072216" },
  // { label: "Amancion", mun_code: "072216" },
  // { label: "Anapog", mun_code: "072216" },
  // { label: "Bactas", mun_code: "072216" },
  // { label: "Bongyas", mun_code: "072216" },
  // { label: "Basak", mun_code: "072216" },
  // { label: "Binongkalan", mun_code: "072216" },
  // { label: "Cabungaan", mun_code: "072216" },
  // { label: "Cambangkaya", mun_code: "072216" },
  // { label: "Can-ibuang", mun_code: "072216" },
  // { label: "Catmondaan", mun_code: "072216" },
  // { label: "Duyan", mun_code: "072216" },
  // { label: "Ginabucan", mun_code: "072216" },
  // { label: "Macaas", mun_code: "072216" },
  // { label: "Panalipan", mun_code: "072216" },
  // { label: "Tabili", mun_code: "072216" },
  // { label: "Tinabyonan", mun_code: "072216" },
  // { label: "San Jose Pob. (Catadman)", mun_code: "072216" },
  // { label: "Corazon (Pob.)", mun_code: "072216" },
  // { label: "Flores (Pob.)", mun_code: "072216" },
  // { label: "Adlaon", mun_code: "072217" },
  // { label: "Agsungot", mun_code: "072217" },
  // { label: "Apas", mun_code: "072217" },
  // { label: "Babag", mun_code: "072217" },
  // { label: "Basak Pardo", mun_code: "072217" },
  // { label: "Bacayan", mun_code: "072217" },
  // { label: "Banilad", mun_code: "072217" },
  // { label: "Basak San Nicolas", mun_code: "072217" },
  // { label: "Binaliw", mun_code: "072217" },
  // { label: "Bonbon", mun_code: "072217" },
  // { label: "Budla-an (Pob.)", mun_code: "072217" },
  // { label: "Buhisan", mun_code: "072217" },
  // { label: "Bulacao", mun_code: "072217" },
  // { label: "Buot-Taup Pardo", mun_code: "072217" },
  // { label: "Busay (Pob.)", mun_code: "072217" },
  // { label: "Calamba", mun_code: "072217" },
  // { label: "Cambinocot", mun_code: "072217" },
  // { label: "Capitol Site (Pob.)", mun_code: "072217" },
  // { label: "Carreta", mun_code: "072217" },
  // { label: "Central (Pob.)", mun_code: "072217" },
  // { label: "Cogon Ramos (Pob.)", mun_code: "072217" },
  // { label: "Cogon Pardo", mun_code: "072217" },
  // { label: "Day-as", mun_code: "072217" },
  // { label: "Duljo (Pob.)", mun_code: "072217" },
  // { label: "Ermita (Pob.)", mun_code: "072217" },
  // { label: "Guadalupe", mun_code: "072217" },
  // { label: "Guba", mun_code: "072217" },
  // { label: "Hippodromo", mun_code: "072217" },
  // { label: "Inayawan", mun_code: "072217" },
  // { label: "Kalubihan (Pob.)", mun_code: "072217" },
  // { label: "Kalunasan", mun_code: "072217" },
  // { label: "Kamagayan (Pob.)", mun_code: "072217" },
  // { label: "Camputhaw (Pob.)", mun_code: "072217" },
  // { label: "Kasambagan", mun_code: "072217" },
  // { label: "Kinasang-an Pardo", mun_code: "072217" },
  // { label: "Labangon", mun_code: "072217" },
  // { label: "Lahug (Pob.)", mun_code: "072217" },
  // { label: "Lorega (Lorega San Miguel)", mun_code: "072217" },
  // { label: "Lusaran", mun_code: "072217" },
  // { label: "Luz", mun_code: "072217" },
  // { label: "Mabini", mun_code: "072217" },
  // { label: "Mabolo", mun_code: "072217" },
  // { label: "Malubog", mun_code: "072217" },
  // { label: "Mambaling", mun_code: "072217" },
  // { label: "Pahina Central (Pob.)", mun_code: "072217" },
  // { label: "Pahina San Nicolas", mun_code: "072217" },
  // { label: "Pamutan", mun_code: "072217" },
  // { label: "Pardo (Pob.)", mun_code: "072217" },
  // { label: "Pari-an", mun_code: "072217" },
  // { label: "Paril", mun_code: "072217" },
  // { label: "Pasil", mun_code: "072217" },
  // { label: "Pit-os", mun_code: "072217" },
  // { label: "Pulangbato", mun_code: "072217" },
  // { label: "Pung-ol-Sibugay", mun_code: "072217" },
  // { label: "Punta Princesa", mun_code: "072217" },
  // { label: "Quiot Pardo", mun_code: "072217" },
  // { label: "Sambag I (Pob.)", mun_code: "072217" },
  // { label: "Sambag II (Pob.)", mun_code: "072217" },
  // { label: "San Antonio (Pob.)", mun_code: "072217" },
  // { label: "San Jose", mun_code: "072217" },
  // { label: "San Nicolas Central", mun_code: "072217" },
  // { label: "San Roque (Ciudad)", mun_code: "072217" },
  // { label: "Santa Cruz (Pob.)", mun_code: "072217" },
  // { label: "Sawang Calero (Pob.)", mun_code: "072217" },
  // { label: "Sinsin", mun_code: "072217" },
  // { label: "Sirao", mun_code: "072217" },
  // { label: "Suba Pob. (Suba San Nicolas)", mun_code: "072217" },
  // { label: "Sudlon I", mun_code: "072217" },
  // { label: "Sapangdaku", mun_code: "072217" },
  // { label: "T. Padilla", mun_code: "072217" },
  // { label: "Tabunan", mun_code: "072217" },
  // { label: "Tagbao", mun_code: "072217" },
  // { label: "Talamban", mun_code: "072217" },
  // { label: "Taptap", mun_code: "072217" },
  // { label: "Tejero (Villa Gonzalo)", mun_code: "072217" },
  // { label: "Tinago", mun_code: "072217" },
  // { label: "Tisa", mun_code: "072217" },
  // { label: "To-ong Pardo", mun_code: "072217" },
  // { label: "Zapatera", mun_code: "072217" },
  // { label: "Sudlon II", mun_code: "072217" },
  // { label: "Bagalnga", mun_code: "072218" },
  // { label: "Basak", mun_code: "072218" },
  // { label: "Buluang", mun_code: "072218" },
  // { label: "Cabadiangan", mun_code: "072218" },
  // { label: "Cambayog", mun_code: "072218" },
  // { label: "Canamucan", mun_code: "072218" },
  // { label: "Cogon", mun_code: "072218" },
  // { label: "Dapdap", mun_code: "072218" },
  // { label: "Estaca", mun_code: "072218" },
  // { label: "Lupa", mun_code: "072218" },
  // { label: "Magay", mun_code: "072218" },
  // { label: "Mulao", mun_code: "072218" },
  // { label: "Panangban", mun_code: "072218" },
  // { label: "Poblacion", mun_code: "072218" },
  // { label: "Tag-ube", mun_code: "072218" },
  // { label: "Tamiao", mun_code: "072218" },
  // { label: "Tubigan", mun_code: "072218" },
  // { label: "Cabangahan", mun_code: "072219" },
  // { label: "Cansaga", mun_code: "072219" },
  // { label: "Casili", mun_code: "072219" },
  // { label: "Danglag", mun_code: "072219" },
  // { label: "Garing", mun_code: "072219" },
  // { label: "Jugan", mun_code: "072219" },
  // { label: "Lamac", mun_code: "072219" },
  // { label: "Lanipga", mun_code: "072219" },
  // { label: "Nangka", mun_code: "072219" },
  // { label: "Panas", mun_code: "072219" },
  // { label: "Panoypoy", mun_code: "072219" },
  // { label: "Pitogo", mun_code: "072219" },
  // { label: "Poblacion Occidental", mun_code: "072219" },
  // { label: "Poblacion Oriental", mun_code: "072219" },
  // { label: "Polog", mun_code: "072219" },
  // { label: "Pulpogan", mun_code: "072219" },
  // { label: "Sacsac", mun_code: "072219" },
  // { label: "Tayud", mun_code: "072219" },
  // { label: "Tilhaong", mun_code: "072219" },
  // { label: "Tolotolo", mun_code: "072219" },
  // { label: "Tugbongan", mun_code: "072219" },
  // { label: "Alegria", mun_code: "072220" },
  // { label: "Bangbang", mun_code: "072220" },
  // { label: "Buagsong", mun_code: "072220" },
  // { label: "Catarman", mun_code: "072220" },
  // { label: "Cogon", mun_code: "072220" },
  // { label: "Dapitan", mun_code: "072220" },
  // { label: "Day-as", mun_code: "072220" },
  // { label: "Gabi", mun_code: "072220" },
  // { label: "Gilutongan", mun_code: "072220" },
  // { label: "Ibabao", mun_code: "072220" },
  // { label: "Pilipog", mun_code: "072220" },
  // { label: "Poblacion", mun_code: "072220" },
  // { label: "San Miguel", mun_code: "072220" },
  // { label: "Aguho", mun_code: "072221" },
  // { label: "Bagay", mun_code: "072221" },
  // { label: "Bakhawan", mun_code: "072221" },
  // { label: "Bateria", mun_code: "072221" },
  // { label: "Bitoon", mun_code: "072221" },
  // { label: "Calape", mun_code: "072221" },
  // { label: "Carnaza", mun_code: "072221" },
  // { label: "Dalingding", mun_code: "072221" },
  // { label: "Lanao", mun_code: "072221" },
  // { label: "Logon", mun_code: "072221" },
  // { label: "Malbago", mun_code: "072221" },
  // { label: "Malingin", mun_code: "072221" },
  // { label: "Maya", mun_code: "072221" },
  // { label: "Pajo", mun_code: "072221" },
  // { label: "Paypay", mun_code: "072221" },
  // { label: "Poblacion", mun_code: "072221" },
  // { label: "Talisay", mun_code: "072221" },
  // { label: "Tapilon", mun_code: "072221" },
  // { label: "Tinubdan", mun_code: "072221" },
  // { label: "Tominjao", mun_code: "072221" },
  // { label: "Ablayan", mun_code: "072222" },
  // { label: "Babayongan", mun_code: "072222" },
  // { label: "Balud", mun_code: "072222" },
  // { label: "Banhigan", mun_code: "072222" },
  // { label: "Bulak", mun_code: "072222" },
  // { label: "Caliongan", mun_code: "072222" },
  // { label: "Caleriohan", mun_code: "072222" },
  // { label: "Casay", mun_code: "072222" },
  // { label: "Catolohan", mun_code: "072222" },
  // { label: "Cawayan", mun_code: "072222" },
  // { label: "Consolacion", mun_code: "072222" },
  // { label: "Coro", mun_code: "072222" },
  // { label: "Dugyan", mun_code: "072222" },
  // { label: "Dumalan", mun_code: "072222" },
  // { label: "Jolomaynon", mun_code: "072222" },
  // { label: "Lanao", mun_code: "072222" },
  // { label: "Langkas", mun_code: "072222" },
  // { label: "Lumbang", mun_code: "072222" },
  // { label: "Malones", mun_code: "072222" },
  // { label: "Maloray", mun_code: "072222" },
  // { label: "Mananggal", mun_code: "072222" },
  // { label: "Manlapay", mun_code: "072222" },
  // { label: "Mantalongon", mun_code: "072222" },
  // { label: "Nalhub", mun_code: "072222" },
  // { label: "Obo", mun_code: "072222" },
  // { label: "Obong", mun_code: "072222" },
  // { label: "Panas", mun_code: "072222" },
  // { label: "Poblacion", mun_code: "072222" },
  // { label: "Sacsac", mun_code: "072222" },
  // { label: "Tapun", mun_code: "072222" },
  // { label: "Tuba", mun_code: "072222" },
  // { label: "Salug", mun_code: "072222" },
  // { label: "Tabon", mun_code: "072222" },
  // { label: "Baliang", mun_code: "072223" },
  // { label: "Bayabas", mun_code: "072223" },
  // { label: "Binaliw", mun_code: "072223" },
  // { label: "Cabungahan", mun_code: "072223" },
  // { label: "Cagat-Lamac", mun_code: "072223" },
  // { label: "Cahumayan", mun_code: "072223" },
  // { label: "Cambanay", mun_code: "072223" },
  // { label: "Cambubho", mun_code: "072223" },
  // { label: "Cogon-Cruz", mun_code: "072223" },
  // { label: "Danasan", mun_code: "072223" },
  // { label: "Dungga", mun_code: "072223" },
  // { label: "Dunggoan", mun_code: "072223" },
  // { label: "Guinacot", mun_code: "072223" },
  // { label: "Guinsay", mun_code: "072223" },
  // { label: "Ibo", mun_code: "072223" },
  // { label: "Langosig", mun_code: "072223" },
  // { label: "Lawaan", mun_code: "072223" },
  // { label: "Licos", mun_code: "072223" },
  // { label: "Looc", mun_code: "072223" },
  // { label: "Magtagobtob", mun_code: "072223" },
  // { label: "Malapoc", mun_code: "072223" },
  // { label: "Manlayag", mun_code: "072223" },
  // { label: "Mantija", mun_code: "072223" },
  // { label: "Masaba", mun_code: "072223" },
  // { label: "Maslog", mun_code: "072223" },
  // { label: "Nangka", mun_code: "072223" },
  // { label: "Oguis", mun_code: "072223" },
  // { label: "Pili", mun_code: "072223" },
  // { label: "Poblacion", mun_code: "072223" },
  // { label: "Quisol", mun_code: "072223" },
  // { label: "Sabang", mun_code: "072223" },
  // { label: "Sacsac", mun_code: "072223" },
  // { label: "Sandayong Norte", mun_code: "072223" },
  // { label: "Sandayong Sur", mun_code: "072223" },
  // { label: "Santa Rosa", mun_code: "072223" },
  // { label: "Santican", mun_code: "072223" },
  // { label: "Sibacan", mun_code: "072223" },
  // { label: "Suba", mun_code: "072223" },
  // { label: "Taboc", mun_code: "072223" },
  // { label: "Taytay", mun_code: "072223" },
  // { label: "Togonon", mun_code: "072223" },
  // { label: "Tuburan Sur", mun_code: "072223" },
  // { label: "Balaygtiki", mun_code: "072224" },
  // { label: "Bitoon", mun_code: "072224" },
  // { label: "Bulak", mun_code: "072224" },
  // { label: "Bullogan", mun_code: "072224" },
  // { label: "Doldol", mun_code: "072224" },
  // { label: "Kabalaasnan", mun_code: "072224" },
  // { label: "Kabatbatan", mun_code: "072224" },
  // { label: "Calaboon", mun_code: "072224" },
  // { label: "Kambanog", mun_code: "072224" },
  // { label: "Camboang", mun_code: "072224" },
  // { label: "Candabong", mun_code: "072224" },
  // { label: "Kang-actol", mun_code: "072224" },
  // { label: "Kanghalo", mun_code: "072224" },
  // { label: "Kanghumaod", mun_code: "072224" },
  // { label: "Kanguha", mun_code: "072224" },
  // { label: "Kantangkas", mun_code: "072224" },
  // { label: "Kanyuko", mun_code: "072224" },
  // { label: "Cogon", mun_code: "072224" },
  // { label: "Kolabtingon", mun_code: "072224" },
  // { label: "Cotcoton", mun_code: "072224" },
  // { label: "Lamak", mun_code: "072224" },
  // { label: "Lawaan", mun_code: "072224" },
  // { label: "Liong", mun_code: "072224" },
  // { label: "Manlapay", mun_code: "072224" },
  // { label: "Masa", mun_code: "072224" },
  // { label: "Matalao", mun_code: "072224" },
  // { label: "Paculob", mun_code: "072224" },
  // { label: "Panlaan", mun_code: "072224" },
  // { label: "Pawa", mun_code: "072224" },
  // { label: "Ilaya (Pob.)", mun_code: "072224" },
  // { label: "Poblacion Looc", mun_code: "072224" },
  // { label: "Poblacion Sima", mun_code: "072224" },
  // { label: "Tangil", mun_code: "072224" },
  // { label: "Tapon", mun_code: "072224" },
  // { label: "Tubod-Bitoon", mun_code: "072224" },
  // { label: "Tubod-Dugoan", mun_code: "072224" },
  // { label: "Poblacion Central", mun_code: "072224" },
  // { label: "Anao", mun_code: "072225" },
  // { label: "Cagsing", mun_code: "072225" },
  // { label: "Calabawan", mun_code: "072225" },
  // { label: "Cambagte", mun_code: "072225" },
  // { label: "Campisong", mun_code: "072225" },
  // { label: "Canorong", mun_code: "072225" },
  // { label: "Guiwanon", mun_code: "072225" },
  // { label: "Looc", mun_code: "072225" },
  // { label: "Malatbo", mun_code: "072225" },
  // { label: "Mangaco", mun_code: "072225" },
  // { label: "Palanas", mun_code: "072225" },
  // { label: "Poblacion", mun_code: "072225" },
  // { label: "Salamanca", mun_code: "072225" },
  // { label: "San Roque", mun_code: "072225" },
  // { label: "Agus", mun_code: "072226" },
  // { label: "Babag", mun_code: "072226" },
  // { label: "Bankal", mun_code: "072226" },
  // { label: "Baring", mun_code: "072226" },
  // { label: "Basak", mun_code: "072226" },
  // { label: "Buaya", mun_code: "072226" },
  // { label: "Calawisan", mun_code: "072226" },
  // { label: "Canjulao", mun_code: "072226" },
  // { label: "Caw-oy", mun_code: "072226" },
  // { label: "Cawhagan", mun_code: "072226" },
  // { label: "Caubian", mun_code: "072226" },
  // { label: "Gun-ob", mun_code: "072226" },
  // { label: "Ibo", mun_code: "072226" },
  // { label: "Looc", mun_code: "072226" },
  // { label: "Mactan", mun_code: "072226" },
  // { label: "Maribago", mun_code: "072226" },
  // { label: "Marigondon", mun_code: "072226" },
  // { label: "Pajac", mun_code: "072226" },
  // { label: "Pajo", mun_code: "072226" },
  // { label: "Pangan-an", mun_code: "072226" },
  // { label: "Poblacion", mun_code: "072226" },
  // { label: "Pusok", mun_code: "072226" },
  // { label: "Sabang", mun_code: "072226" },
  // { label: "Santa Rosa", mun_code: "072226" },
  // { label: "Subabasbas", mun_code: "072226" },
  // { label: "Talima", mun_code: "072226" },
  // { label: "Tingo", mun_code: "072226" },
  // { label: "Tungasan", mun_code: "072226" },
  // { label: "San Vicente", mun_code: "072226" },
  // { label: "Cabadiangan", mun_code: "072227" },
  // { label: "Calero", mun_code: "072227" },
  // { label: "Catarman", mun_code: "072227" },
  // { label: "Cotcot", mun_code: "072227" },
  // { label: "Jubay", mun_code: "072227" },
  // { label: "Lataban", mun_code: "072227" },
  // { label: "Mulao", mun_code: "072227" },
  // { label: "Poblacion", mun_code: "072227" },
  // { label: "San Roque", mun_code: "072227" },
  // { label: "San Vicente", mun_code: "072227" },
  // { label: "Santa Cruz", mun_code: "072227" },
  // { label: "Tabla", mun_code: "072227" },
  // { label: "Tayud", mun_code: "072227" },
  // { label: "Yati", mun_code: "072227" },
  // { label: "Bunakan", mun_code: "072228" },
  // { label: "Kangwayan", mun_code: "072228" },
  // { label: "Kaongkod", mun_code: "072228" },
  // { label: "Kodia", mun_code: "072228" },
  // { label: "Maalat", mun_code: "072228" },
  // { label: "Malbago", mun_code: "072228" },
  // { label: "Mancilang", mun_code: "072228" },
  // { label: "Pili", mun_code: "072228" },
  // { label: "Poblacion", mun_code: "072228" },
  // { label: "San Agustin", mun_code: "072228" },
  // { label: "Tabagak", mun_code: "072228" },
  // { label: "Talangnan", mun_code: "072228" },
  // { label: "Tarong", mun_code: "072228" },
  // { label: "Tugas", mun_code: "072228" },
  // { label: "Tolosa (Calatagan)", mun_code: "072229" },
  // { label: "Labrador (Bulod)", mun_code: "072229" },
  // { label: "Looc", mun_code: "072229" },
  // { label: "Lombo", mun_code: "072229" },
  // { label: "Mahanlud", mun_code: "072229" },
  // { label: "Mindanao (Pajo)", mun_code: "072229" },
  // { label: "Salmeron (Bulak)", mun_code: "072229" },
  // { label: "Sorsogon (Balimaya)", mun_code: "072229" },
  // { label: "Barangay I (Pob.)", mun_code: "072229" },
  // { label: "Barangay II (Pob.)", mun_code: "072229" },
  // { label: "Alang-alang", mun_code: "072230" },
  // { label: "Bakilid", mun_code: "072230" },
  // { label: "Banilad", mun_code: "072230" },
  // { label: "Basak", mun_code: "072230" },
  // { label: "Cabancalan", mun_code: "072230" },
  // { label: "Cambaro", mun_code: "072230" },
  // { label: "Canduman", mun_code: "072230" },
  // { label: "Casili", mun_code: "072230" },
  // { label: "Casuntingan", mun_code: "072230" },
  // { label: "Centro (Pob.)", mun_code: "072230" },
  // { label: "Cubacub", mun_code: "072230" },
  // { label: "Guizo", mun_code: "072230" },
  // { label: "Ibabao-Estancia", mun_code: "072230" },
  // { label: "Jagobiao", mun_code: "072230" },
  // { label: "Labogon", mun_code: "072230" },
  // { label: "Looc", mun_code: "072230" },
  // { label: "Maguikay", mun_code: "072230" },
  // { label: "Mantuyong", mun_code: "072230" },
  // { label: "Opao", mun_code: "072230" },
  // { label: "Pakna-an", mun_code: "072230" },
  // { label: "Pagsabungan", mun_code: "072230" },
  // { label: "Subangdaku", mun_code: "072230" },
  // { label: "Tabok", mun_code: "072230" },
  // { label: "Tawason", mun_code: "072230" },
  // { label: "Tingub", mun_code: "072230" },
  // { label: "Tipolo", mun_code: "072230" },
  // { label: "Umapad", mun_code: "072230" },
  // { label: "Antipolo", mun_code: "072231" },
  // { label: "Curva", mun_code: "072231" },
  // { label: "Daanlungsod", mun_code: "072231" },
  // { label: "Dalingding Sur", mun_code: "072231" },
  // { label: "Dayhagon", mun_code: "072231" },
  // { label: "Gibitngil", mun_code: "072231" },
  // { label: "Canhabagat", mun_code: "072231" },
  // { label: "Caputatan Norte", mun_code: "072231" },
  // { label: "Caputatan Sur", mun_code: "072231" },
  // { label: "Kawit", mun_code: "072231" },
  // { label: "Lamintak Norte", mun_code: "072231" },
  // { label: "Luy-a", mun_code: "072231" },
  // { label: "Panugnawan", mun_code: "072231" },
  // { label: "Poblacion", mun_code: "072231" },
  // { label: "Tindog", mun_code: "072231" },
  // { label: "Don Virgilio Gonzales", mun_code: "072231" },
  // { label: "Lamintak Sur", mun_code: "072231" },
  // { label: "Maharuhay", mun_code: "072231" },
  // { label: "Mahawak", mun_code: "072231" },
  // { label: "Cadulawan", mun_code: "072232" },
  // { label: "Calajo-an", mun_code: "072232" },
  // { label: "Camp 7", mun_code: "072232" },
  // { label: "Camp 8", mun_code: "072232" },
  // { label: "Cuanos", mun_code: "072232" },
  // { label: "Guindaruhan", mun_code: "072232" },
  // { label: "Linao", mun_code: "072232" },
  // { label: "Manduang", mun_code: "072232" },
  // { label: "Pakigne", mun_code: "072232" },
  // { label: "Poblacion Ward I", mun_code: "072232" },
  // { label: "Poblacion Ward II", mun_code: "072232" },
  // { label: "Poblacion Ward III", mun_code: "072232" },
  // { label: "Poblacion Ward IV", mun_code: "072232" },
  // { label: "Tubod", mun_code: "072232" },
  // { label: "Tulay", mun_code: "072232" },
  // { label: "Tunghaan", mun_code: "072232" },
  // { label: "Tungkop", mun_code: "072232" },
  // { label: "Vito", mun_code: "072232" },
  // { label: "Tungkil", mun_code: "072232" },
  // { label: "Agbalanga", mun_code: "072233" },
  // { label: "Bala", mun_code: "072233" },
  // { label: "Balabagon", mun_code: "072233" },
  // { label: "Basdiot", mun_code: "072233" },
  // { label: "Batadbatad", mun_code: "072233" },
  // { label: "Bugho", mun_code: "072233" },
  // { label: "Buguil", mun_code: "072233" },
  // { label: "Busay", mun_code: "072233" },
  // { label: "Lanao", mun_code: "072233" },
  // { label: "Poblacion East", mun_code: "072233" },
  // { label: "Poblacion West", mun_code: "072233" },
  // { label: "Saavedra", mun_code: "072233" },
  // { label: "Tomonoy", mun_code: "072233" },
  // { label: "Tuble", mun_code: "072233" },
  // { label: "Tunga", mun_code: "072233" },
  // { label: "Alfaco", mun_code: "072234" },
  // { label: "Bairan", mun_code: "072234" },
  // { label: "Balirong", mun_code: "072234" },
  // { label: "Cabungahan", mun_code: "072234" },
  // { label: "Cantao-an", mun_code: "072234" },
  // { label: "Central Poblacion", mun_code: "072234" },
  // { label: "Cogon", mun_code: "072234" },
  // { label: "Colon", mun_code: "072234" },
  // { label: "East Poblacion", mun_code: "072234" },
  // { label: "Inoburan", mun_code: "072234" },
  // { label: "Inayagan", mun_code: "072234" },
  // { label: "Jaguimit", mun_code: "072234" },
  // { label: "Lanas", mun_code: "072234" },
  // { label: "Langtad", mun_code: "072234" },
  // { label: "Lutac", mun_code: "072234" },
  // { label: "Mainit", mun_code: "072234" },
  // { label: "Mayana", mun_code: "072234" },
  // { label: "Naalad", mun_code: "072234" },
  // { label: "North Poblacion", mun_code: "072234" },
  // { label: "Pangdan", mun_code: "072234" },
  // { label: "Patag", mun_code: "072234" },
  // { label: "South Poblacion", mun_code: "072234" },
  // { label: "Tagjaguimit", mun_code: "072234" },
  // { label: "Tangke", mun_code: "072234" },
  // { label: "Tinaan", mun_code: "072234" },
  // { label: "Tuyan", mun_code: "072234" },
  // { label: "Uling", mun_code: "072234" },
  // { label: "West Poblacion", mun_code: "072234" },
  // { label: "Alo", mun_code: "072235" },
  // { label: "Bangcogon", mun_code: "072235" },
  // { label: "Bonbon", mun_code: "072235" },
  // { label: "Calumpang", mun_code: "072235" },
  // { label: "Canangca-an", mun_code: "072235" },
  // { label: "Can-ukban", mun_code: "072235" },
  // { label: "Cansalo-ay", mun_code: "072235" },
  // { label: "Daanlungsod", mun_code: "072235" },
  // { label: "Gawi", mun_code: "072235" },
  // { label: "Hagdan", mun_code: "072235" },
  // { label: "Lagunde", mun_code: "072235" },
  // { label: "Looc", mun_code: "072235" },
  // { label: "Luka", mun_code: "072235" },
  // { label: "Mainit", mun_code: "072235" },
  // { label: "Manlum", mun_code: "072235" },
  // { label: "Nueva Caceres", mun_code: "072235" },
  // { label: "Poblacion", mun_code: "072235" },
  // { label: "Pungtod", mun_code: "072235" },
  // { label: "Tan-awan", mun_code: "072235" },
  // { label: "Tumalog", mun_code: "072235" },
  // { label: "Biasong", mun_code: "072236" },
  // { label: "Cawit", mun_code: "072236" },
  // { label: "Dapdap", mun_code: "072236" },
  // { label: "Esperanza", mun_code: "072236" },
  // { label: "Lanao", mun_code: "072236" },
  // { label: "Lower Poblacion", mun_code: "072236" },
  // { label: "Moabog", mun_code: "072236" },
  // { label: "Montserrat", mun_code: "072236" },
  // { label: "San Isidro", mun_code: "072236" },
  // { label: "San Juan", mun_code: "072236" },
  // { label: "Upper Poblacion", mun_code: "072236" },
  // { label: "Villahermosa", mun_code: "072236" },
  // { label: "Imelda", mun_code: "072236" },
  // { label: "Anislag", mun_code: "072237" },
  // { label: "Anopog", mun_code: "072237" },
  // { label: "Binabag", mun_code: "072237" },
  // { label: "Buhingtubig", mun_code: "072237" },
  // { label: "Busay", mun_code: "072237" },
  // { label: "Butong", mun_code: "072237" },
  // { label: "Cabiangon", mun_code: "072237" },
  // { label: "Camugao", mun_code: "072237" },
  // { label: "Duangan", mun_code: "072237" },
  // { label: "Guimbawian", mun_code: "072237" },
  // { label: "Lamac", mun_code: "072237" },
  // { label: "Lut-od", mun_code: "072237" },
  // { label: "Mangoto", mun_code: "072237" },
  // { label: "Opao", mun_code: "072237" },
  // { label: "Pandacan", mun_code: "072237" },
  // { label: "Poblacion", mun_code: "072237" },
  // { label: "Punod", mun_code: "072237" },
  // { label: "Rizal", mun_code: "072237" },
  // { label: "Sacsac", mun_code: "072237" },
  // { label: "Sambagon", mun_code: "072237" },
  // { label: "Sibago", mun_code: "072237" },
  // { label: "Tajao", mun_code: "072237" },
  // { label: "Tangub", mun_code: "072237" },
  // { label: "Tanibag", mun_code: "072237" },
  // { label: "Tupas", mun_code: "072237" },
  // { label: "Tutay", mun_code: "072237" },
  // { label: "Adela", mun_code: "072238" },
  // { label: "Altavista", mun_code: "072238" },
  // { label: "Cagcagan", mun_code: "072238" },
  // { label: "Cansabusab", mun_code: "072238" },
  // { label: "Daan Paz", mun_code: "072238" },
  // { label: "Eastern Poblacion", mun_code: "072238" },
  // { label: "Esperanza", mun_code: "072238" },
  // { label: "Libertad", mun_code: "072238" },
  // { label: "Mabini", mun_code: "072238" },
  // { label: "Mercedes", mun_code: "072238" },
  // { label: "Pagsa", mun_code: "072238" },
  // { label: "Paz", mun_code: "072238" },
  // { label: "Rizal", mun_code: "072238" },
  // { label: "San Jose", mun_code: "072238" },
  // { label: "Santa Rita", mun_code: "072238" },
  // { label: "Teguis", mun_code: "072238" },
  // { label: "Western Poblacion", mun_code: "072238" },
  // { label: "Butong", mun_code: "072239" },
  // { label: "Can-abuhon", mun_code: "072239" },
  // { label: "Canduling", mun_code: "072239" },
  // { label: "Cansalonoy", mun_code: "072239" },
  // { label: "Cansayahon", mun_code: "072239" },
  // { label: "Ilaya", mun_code: "072239" },
  // { label: "Langin", mun_code: "072239" },
  // { label: "Libo-o", mun_code: "072239" },
  // { label: "Malalay", mun_code: "072239" },
  // { label: "Palanas", mun_code: "072239" },
  // { label: "Poblacion", mun_code: "072239" },
  // { label: "Santa Cruz", mun_code: "072239" },
  // { label: "Tupas", mun_code: "072239" },
  // { label: "Vive", mun_code: "072239" },
  // { label: "Basak", mun_code: "072240" },
  // { label: "Bonbon", mun_code: "072240" },
  // { label: "Bulangsuran", mun_code: "072240" },
  // { label: "Calatagan", mun_code: "072240" },
  // { label: "Cambigong", mun_code: "072240" },
  // { label: "Canorong", mun_code: "072240" },
  // { label: "Colase", mun_code: "072240" },
  // { label: "Dalahikan", mun_code: "072240" },
  // { label: "Jumangpas", mun_code: "072240" },
  // { label: "Camburoy", mun_code: "072240" },
  // { label: "Poblacion", mun_code: "072240" },
  // { label: "San Sebastian", mun_code: "072240" },
  // { label: "Suba", mun_code: "072240" },
  // { label: "Tangbo", mun_code: "072240" },
  // { label: "Monteverde", mun_code: "072240" },
  // { label: "Balud", mun_code: "072241" },
  // { label: "Balungag", mun_code: "072241" },
  // { label: "Basak", mun_code: "072241" },
  // { label: "Bugho", mun_code: "072241" },
  // { label: "Cabatbatan", mun_code: "072241" },
  // { label: "Greenhills", mun_code: "072241" },
  // { label: "Lantawan", mun_code: "072241" },
  // { label: "Liburon", mun_code: "072241" },
  // { label: "Magsico", mun_code: "072241" },
  // { label: "Poblacion North", mun_code: "072241" },
  // { label: "Panadtaran", mun_code: "072241" },
  // { label: "Pitalo", mun_code: "072241" },
  // { label: "San Isidro", mun_code: "072241" },
  // { label: "Sangat", mun_code: "072241" },
  // { label: "Poblacion South", mun_code: "072241" },
  // { label: "Tabionan", mun_code: "072241" },
  // { label: "Tananas", mun_code: "072241" },
  // { label: "Tinubdan", mun_code: "072241" },
  // { label: "Tonggo", mun_code: "072241" },
  // { label: "Tubod", mun_code: "072241" },
  // { label: "Ilaya", mun_code: "072241" },
  // { label: "Montealegre", mun_code: "072242" },
  // { label: "Cabunga-an", mun_code: "072242" },
  // { label: "Campo", mun_code: "072242" },
  // { label: "Consuelo", mun_code: "072242" },
  // { label: "Esperanza", mun_code: "072242" },
  // { label: "Himensulan", mun_code: "072242" },
  // { label: "Northern Poblacion", mun_code: "072242" },
  // { label: "San Isidro", mun_code: "072242" },
  // { label: "Santa Cruz", mun_code: "072242" },
  // { label: "Santiago", mun_code: "072242" },
  // { label: "Sonog", mun_code: "072242" },
  // { label: "Southern Poblacion", mun_code: "072242" },
  // { label: "Unidos", mun_code: "072242" },
  // { label: "Union", mun_code: "072242" },
  // { label: "Western Poblacion", mun_code: "072242" },
  // { label: "Anapog", mun_code: "072243" },
  // { label: "Argawanon", mun_code: "072243" },
  // { label: "Bagtic", mun_code: "072243" },
  // { label: "Bancasan", mun_code: "072243" },
  // { label: "Batad", mun_code: "072243" },
  // { label: "Busogon", mun_code: "072243" },
  // { label: "Calambua", mun_code: "072243" },
  // { label: "Canagahan", mun_code: "072243" },
  // { label: "Dapdap", mun_code: "072243" },
  // { label: "Gawaygaway", mun_code: "072243" },
  // { label: "Hagnaya", mun_code: "072243" },
  // { label: "Kayam", mun_code: "072243" },
  // { label: "Kinawahan", mun_code: "072243" },
  // { label: "Lambusan", mun_code: "072243" },
  // { label: "Lawis", mun_code: "072243" },
  // { label: "Libaong", mun_code: "072243" },
  // { label: "Looc", mun_code: "072243" },
  // { label: "Luyang", mun_code: "072243" },
  // { label: "Mano", mun_code: "072243" },
  // { label: "Poblacion", mun_code: "072243" },
  // { label: "Punta", mun_code: "072243" },
  // { label: "Sab-a", mun_code: "072243" },
  // { label: "San Miguel", mun_code: "072243" },
  // { label: "Tacup", mun_code: "072243" },
  // { label: "Tambongon", mun_code: "072243" },
  // { label: "To-ong", mun_code: "072243" },
  // { label: "Victoria", mun_code: "072243" },
  // { label: "Hagdan", mun_code: "072244" },
  // { label: "Hilantagaan", mun_code: "072244" },
  // { label: "Kinatarkan", mun_code: "072244" },
  // { label: "Langub", mun_code: "072244" },
  // { label: "Maricaban", mun_code: "072244" },
  // { label: "Okoy", mun_code: "072244" },
  // { label: "Poblacion", mun_code: "072244" },
  // { label: "Balidbid", mun_code: "072244" },
  // { label: "Pooc", mun_code: "072244" },
  // { label: "Talisay", mun_code: "072244" },
  // { label: "Bunlan", mun_code: "072245" },
  // { label: "Cabutongan", mun_code: "072245" },
  // { label: "Candamiang", mun_code: "072245" },
  // { label: "Liloan", mun_code: "072245" },
  // { label: "Lip-tong", mun_code: "072245" },
  // { label: "Looc", mun_code: "072245" },
  // { label: "Pasil", mun_code: "072245" },
  // { label: "Poblacion", mun_code: "072245" },
  // { label: "Talisay", mun_code: "072245" },
  // { label: "Canlumacad", mun_code: "072245" },
  // { label: "Abugon", mun_code: "072246" },
  // { label: "Bae", mun_code: "072246" },
  // { label: "Bagacay", mun_code: "072246" },
  // { label: "Bahay", mun_code: "072246" },
  // { label: "Banlot", mun_code: "072246" },
  // { label: "Basak", mun_code: "072246" },
  // { label: "Bato", mun_code: "072246" },
  // { label: "Cagay", mun_code: "072246" },
  // { label: "Can-aga", mun_code: "072246" },
  // { label: "Candaguit", mun_code: "072246" },
  // { label: "Cantolaroy", mun_code: "072246" },
  // { label: "Dugoan", mun_code: "072246" },
  // { label: "Guimbangco-an", mun_code: "072246" },
  // { label: "Lamacan", mun_code: "072246" },
  // { label: "Libo", mun_code: "072246" },
  // { label: "Lindogon", mun_code: "072246" },
  // { label: "Magcagong", mun_code: "072246" },
  // { label: "Manatad", mun_code: "072246" },
  // { label: "Mangyan", mun_code: "072246" },
  // { label: "Papan", mun_code: "072246" },
  // { label: "Poblacion", mun_code: "072246" },
  // { label: "Sabang", mun_code: "072246" },
  // { label: "Sayao", mun_code: "072246" },
  // { label: "Simala", mun_code: "072246" },
  // { label: "Tubod", mun_code: "072246" },
  // { label: "Ampongol", mun_code: "072247" },
  // { label: "Bagakay", mun_code: "072247" },
  // { label: "Bagatayam", mun_code: "072247" },
  // { label: "Bawo", mun_code: "072247" },
  // { label: "Cabalawan", mun_code: "072247" },
  // { label: "Cabangahan", mun_code: "072247" },
  // { label: "Calumboyan", mun_code: "072247" },
  // { label: "Dakit", mun_code: "072247" },
  // { label: "Damolog", mun_code: "072247" },
  // { label: "Ibabao", mun_code: "072247" },
  // { label: "Liki", mun_code: "072247" },
  // { label: "Lubo", mun_code: "072247" },
  // { label: "Mohon", mun_code: "072247" },
  // { label: "Nahus-an", mun_code: "072247" },
  // { label: "Poblacion", mun_code: "072247" },
  // { label: "Tabunok", mun_code: "072247" },
  // { label: "Takay", mun_code: "072247" },
  // { label: "Pansoy", mun_code: "072247" },
  // { label: "Alang-alang", mun_code: "072248" },
  // { label: "Caduawan", mun_code: "072248" },
  // { label: "Kal-anan", mun_code: "072248" },
  // { label: "Camoboan", mun_code: "072248" },
  // { label: "Canaocanao", mun_code: "072248" },
  // { label: "Combado", mun_code: "072248" },
  // { label: "Daantabogon", mun_code: "072248" },
  // { label: "Ilihan", mun_code: "072248" },
  // { label: "Labangon", mun_code: "072248" },
  // { label: "Libjo", mun_code: "072248" },
  // { label: "Loong", mun_code: "072248" },
  // { label: "Mabuli", mun_code: "072248" },
  // { label: "Managase", mun_code: "072248" },
  // { label: "Manlagtang", mun_code: "072248" },
  // { label: "Maslog", mun_code: "072248" },
  // { label: "Muabog", mun_code: "072248" },
  // { label: "Pio", mun_code: "072248" },
  // { label: "Poblacion", mun_code: "072248" },
  // { label: "Salag", mun_code: "072248" },
  // { label: "Sambag", mun_code: "072248" },
  // { label: "San Isidro", mun_code: "072248" },
  // { label: "San Vicente", mun_code: "072248" },
  // { label: "Somosa", mun_code: "072248" },
  // { label: "Taba-ao", mun_code: "072248" },
  // { label: "Tapul", mun_code: "072248" },
  // { label: "Bongon", mun_code: "072249" },
  // { label: "Kanlim-ao", mun_code: "072249" },
  // { label: "Kanluhangon", mun_code: "072249" },
  // { label: "Kantubaon", mun_code: "072249" },
  // { label: "Dalid", mun_code: "072249" },
  // { label: "Mabunao", mun_code: "072249" },
  // { label: "Maravilla", mun_code: "072249" },
  // { label: "Olivo", mun_code: "072249" },
  // { label: "Poblacion", mun_code: "072249" },
  // { label: "Tabunok", mun_code: "072249" },
  // { label: "Tigbawan", mun_code: "072249" },
  // { label: "Villahermosa", mun_code: "072249" },
  // { label: "Bulacao", mun_code: "072250" },
  // { label: "Cadulawan", mun_code: "072250" },
  // { label: "Cansojong", mun_code: "072250" },
  // { label: "Dumlog", mun_code: "072250" },
  // { label: "Jaclupan", mun_code: "072250" },
  // { label: "Lagtang", mun_code: "072250" },
  // { label: "Lawaan I", mun_code: "072250" },
  // { label: "Linao", mun_code: "072250" },
  // { label: "Maghaway", mun_code: "072250" },
  // { label: "Manipis", mun_code: "072250" },
  // { label: "Mohon", mun_code: "072250" },
  // { label: "Poblacion", mun_code: "072250" },
  // { label: "Pooc", mun_code: "072250" },
  // { label: "San Isidro", mun_code: "072250" },
  // { label: "San Roque", mun_code: "072250" },
  // { label: "Tabunoc", mun_code: "072250" },
  // { label: "Tangke", mun_code: "072250" },
  // { label: "Tapul", mun_code: "072250" },
  // { label: "Biasong", mun_code: "072250" },
  // { label: "Camp IV", mun_code: "072250" },
  // { label: "Lawaan II", mun_code: "072250" },
  // { label: "Lawaan III", mun_code: "072250" },
  // { label: "Awihao", mun_code: "072251" },
  // { label: "Bagakay", mun_code: "072251" },
  // { label: "Bato", mun_code: "072251" },
  // { label: "Biga", mun_code: "072251" },
  // { label: "Bulongan", mun_code: "072251" },
  // { label: "Bunga", mun_code: "072251" },
  // { label: "Cabitoonan", mun_code: "072251" },
  // { label: "Calongcalong", mun_code: "072251" },
  // { label: "Cambang-ug", mun_code: "072251" },
  // { label: "Camp 8", mun_code: "072251" },
  // { label: "Canlumampao", mun_code: "072251" },
  // { label: "Cantabaco", mun_code: "072251" },
  // { label: "Capitan Claudio", mun_code: "072251" },
  // { label: "Carmen", mun_code: "072251" },
  // { label: "Daanglungsod", mun_code: "072251" },
  // { label: "Don Andres Soriano (Lutopan)", mun_code: "072251" },
  // { label: "Dumlog", mun_code: "072251" },
  // { label: "Ibo", mun_code: "072251" },
  // { label: "Ilihan", mun_code: "072251" },
  // { label: "Landahan", mun_code: "072251" },
  // { label: "Loay", mun_code: "072251" },
  // { label: "Luray II", mun_code: "072251" },
  // { label: "Juan Climaco, Sr. (Magdugo)", mun_code: "072251" },
  // { label: "Gen. Climaco (Malubog)", mun_code: "072251" },
  // { label: "Matab-ang", mun_code: "072251" },
  // { label: "Media Once", mun_code: "072251" },
  // { label: "Pangamihan", mun_code: "072251" },
  // { label: "Poblacion", mun_code: "072251" },
  // { label: "Poog", mun_code: "072251" },
  // { label: "Putingbato", mun_code: "072251" },
  // { label: "Sagay", mun_code: "072251" },
  // { label: "Sam-ang", mun_code: "072251" },
  // { label: "Sangi", mun_code: "072251" },
  // { label: "Subayon", mun_code: "072251" },
  // { label: "Talavera", mun_code: "072251" },
  // { label: "Tungkay", mun_code: "072251" },
  // { label: "Tubod", mun_code: "072251" },
  // { label: "Alegria", mun_code: "072252" },
  // { label: "Amatugan", mun_code: "072252" },
  // { label: "Antipolo", mun_code: "072252" },
  // { label: "Apalan", mun_code: "072252" },
  // { label: "Bagasawe", mun_code: "072252" },
  // { label: "Bakyawan", mun_code: "072252" },
  // { label: "Bangkito", mun_code: "072252" },
  // { label: "Bulwang", mun_code: "072252" },
  // { label: "Kabangkalan", mun_code: "072252" },
  // { label: "Kalangahan", mun_code: "072252" },
  // { label: "Kamansi", mun_code: "072252" },
  // { label: "Kan-an", mun_code: "072252" },
  // { label: "Kanlunsing", mun_code: "072252" },
  // { label: "Kansi", mun_code: "072252" },
  // { label: "Caridad", mun_code: "072252" },
  // { label: "Carmelo", mun_code: "072252" },
  // { label: "Cogon", mun_code: "072252" },
  // { label: "Colonia", mun_code: "072252" },
  // { label: "Daan Lungsod", mun_code: "072252" },
  // { label: "Fortaliza", mun_code: "072252" },
  // { label: "Ga-ang", mun_code: "072252" },
  // { label: "Gimama-a", mun_code: "072252" },
  // { label: "Jagbuaya", mun_code: "072252" },
  // { label: "Kabkaban", mun_code: "072252" },
  // { label: "Kagba-o", mun_code: "072252" },
  // { label: "Kampoot", mun_code: "072252" },
  // { label: "Kaorasan", mun_code: "072252" },
  // { label: "Libo", mun_code: "072252" },
  // { label: "Lusong", mun_code: "072252" },
  // { label: "Macupa", mun_code: "072252" },
  // { label: "Mag-alwa", mun_code: "072252" },
  // { label: "Mag-antoy", mun_code: "072252" },
  // { label: "Mag-atubang", mun_code: "072252" },
  // { label: "Maghan-ay", mun_code: "072252" },
  // { label: "Mangga", mun_code: "072252" },
  // { label: "Marmol", mun_code: "072252" },
  // { label: "Molobolo", mun_code: "072252" },
  // { label: "Montealegre", mun_code: "072252" },
  // { label: "Putat", mun_code: "072252" },
  // { label: "San Juan", mun_code: "072252" },
  // { label: "Sandayong", mun_code: "072252" },
  // { label: "Siotes", mun_code: "072252" },
  // { label: "Sumon", mun_code: "072252" },
  // { label: "Tominjao", mun_code: "072252" },
  // { label: "Tomugpa", mun_code: "072252" },
  // { label: "Barangay I (Pob.)", mun_code: "072252" },
  // { label: "Barangay II (Pob.)", mun_code: "072252" },
  // { label: "Barangay III (Pob.)", mun_code: "072252" },
  // { label: "Barangay IV (Pob.)", mun_code: "072252" },
  // { label: "Barangay V (Pob.)", mun_code: "072252" },
  // { label: "Barangay VI (Pob.)", mun_code: "072252" },
  // { label: "Barangay VII (Pob.)", mun_code: "072252" },
  // { label: "Barangay VIII (Pob.)", mun_code: "072252" },
  // { label: "Buenavista", mun_code: "072253" },
  // { label: "Calmante", mun_code: "072253" },
  // { label: "Daan Secante", mun_code: "072253" },
  // { label: "General", mun_code: "072253" },
  // { label: "McArthur", mun_code: "072253" },
  // { label: "Northern Poblacion", mun_code: "072253" },
  // { label: "Puertobello", mun_code: "072253" },
  // { label: "Santander", mun_code: "072253" },
  // { label: "Secante Bag-o", mun_code: "072253" },
  // { label: "Southern Poblacion", mun_code: "072253" },
  // { label: "Villahermosa", mun_code: "072253" },
  { label: "Bio-os", mun_code: "074601" },
  { label: "Jantianon", mun_code: "074601" },
  { label: "Jugno", mun_code: "074601" },
  { label: "Mag-abo", mun_code: "074601" },
  { label: "Poblacion", mun_code: "074601" },
  { label: "Silab", mun_code: "074601" },
  { label: "Tambojangin", mun_code: "074601" },
  { label: "Tandayag", mun_code: "074601" },
  { label: "Amdus", mun_code: "074602" },
  { label: "Jandalamanon", mun_code: "074602" },
  { label: "Anibong", mun_code: "074602" },
  { label: "Atabay", mun_code: "074602" },
  { label: "Awa-an", mun_code: "074602" },
  { label: "Ban-ban", mun_code: "074602" },
  { label: "Calagcalag", mun_code: "074602" },
  { label: "Candana-ay", mun_code: "074602" },
  { label: "Carol-an", mun_code: "074602" },
  { label: "Gomentoc", mun_code: "074602" },
  { label: "Inacban", mun_code: "074602" },
  { label: "Iniban", mun_code: "074602" },
  { label: "Kilaban", mun_code: "074602" },
  { label: "Lamigan", mun_code: "074602" },
  { label: "Maaslum", mun_code: "074602" },
  { label: "Mabato", mun_code: "074602" },
  { label: "Manogtong", mun_code: "074602" },
  { label: "Nabhang", mun_code: "074602" },
  { label: "Tambo", mun_code: "074602" },
  { label: "Tampocon I", mun_code: "074602" },
  { label: "Tampocon II", mun_code: "074602" },
  { label: "Tibyawan", mun_code: "074602" },
  { label: "Tiguib", mun_code: "074602" },
  { label: "Poblacion", mun_code: "074602" },
  { label: "Balayagmanok", mun_code: "074603" },
  { label: "Banilad", mun_code: "074603" },
  { label: "Buntis", mun_code: "074603" },
  { label: "Buntod", mun_code: "074603" },
  { label: "Calangag", mun_code: "074603" },
  { label: "Combado", mun_code: "074603" },
  { label: "Doldol", mun_code: "074603" },
  { label: "Isugan", mun_code: "074603" },
  { label: "Liptong", mun_code: "074603" },
  { label: "Lutao", mun_code: "074603" },
  { label: "Magsuhot", mun_code: "074603" },
  { label: "Malabago", mun_code: "074603" },
  { label: "Mampas", mun_code: "074603" },
  { label: "North Poblacion", mun_code: "074603" },
  { label: "Sacsac", mun_code: "074603" },
  { label: "San Miguel", mun_code: "074603" },
  { label: "South Poblacion", mun_code: "074603" },
  { label: "Sulodpan", mun_code: "074603" },
  { label: "Timbanga", mun_code: "074603" },
  { label: "Timbao", mun_code: "074603" },
  { label: "Tubod", mun_code: "074603" },
  { label: "West Poblacion", mun_code: "074603" },
  { label: "Barangay I (Pob.)", mun_code: "074604" },
  { label: "Barangay II (Pob.)", mun_code: "074604" },
  { label: "Basak", mun_code: "074604" },
  { label: "Cabanlutan", mun_code: "074604" },
  { label: "Calasga-an", mun_code: "074604" },
  { label: "Cambagahan", mun_code: "074604" },
  { label: "Cambaguio", mun_code: "074604" },
  { label: "Cambanjao", mun_code: "074604" },
  { label: "Cambuilao", mun_code: "074604" },
  { label: "Canlargo", mun_code: "074604" },
  { label: "Consolacion", mun_code: "074604" },
  { label: "Dansulan", mun_code: "074604" },
  { label: "Hangyad", mun_code: "074604" },
  { label: "La Paz", mun_code: "074604" },
  { label: "Lo-oc", mun_code: "074604" },
  { label: "Lonoy", mun_code: "074604" },
  { label: "Mabunao", mun_code: "074604" },
  { label: "Manlipac", mun_code: "074604" },
  { label: "Mansangaban", mun_code: "074604" },
  { label: "Okiot", mun_code: "074604" },
  { label: "Olympia", mun_code: "074604" },
  { label: "Panala-an", mun_code: "074604" },
  { label: "Panam-angan", mun_code: "074604" },
  { label: "Rosario", mun_code: "074604" },
  { label: "Sab-ahan", mun_code: "074604" },
  { label: "San Isidro", mun_code: "074604" },
  { label: "Katacgahan (Tacgahan)", mun_code: "074604" },
  { label: "Tagpo", mun_code: "074604" },
  { label: "Talungon", mun_code: "074604" },
  { label: "Tamisu", mun_code: "074604" },
  { label: "Tamogong", mun_code: "074604" },
  { label: "Tangculogan", mun_code: "074604" },
  { label: "Valencia", mun_code: "074604" },
  { label: "Actin", mun_code: "074605" },
  { label: "Bal-os", mun_code: "074605" },
  { label: "Bongalonan", mun_code: "074605" },
  { label: "Cabalayongan", mun_code: "074605" },
  { label: "Cabatuanan", mun_code: "074605" },
  { label: "Linantayan", mun_code: "074605" },
  { label: "Maglinao", mun_code: "074605" },
  { label: "Nagbo-alao", mun_code: "074605" },
  { label: "Olandao", mun_code: "074605" },
  { label: "Poblacion", mun_code: "074605" },
  { label: "Ali-is", mun_code: "074606" },
  { label: "Banaybanay", mun_code: "074606" },
  { label: "Banga", mun_code: "074606" },
  { label: "Villasol (Bato)", mun_code: "074606" },
  { label: "Boyco", mun_code: "074606" },
  { label: "Bugay", mun_code: "074606" },
  { label: "Cansumalig", mun_code: "074606" },
  { label: "Dawis", mun_code: "074606" },
  { label: "Kalamtukan", mun_code: "074606" },
  { label: "Kalumboyan", mun_code: "074606" },
  { label: "Malabugas", mun_code: "074606" },
  { label: "Mandu-ao", mun_code: "074606" },
  { label: "Maninihon", mun_code: "074606" },
  { label: "Minaba", mun_code: "074606" },
  { label: "Nangka", mun_code: "074606" },
  { label: "Narra", mun_code: "074606" },
  { label: "Pagatban", mun_code: "074606" },
  { label: "Poblacion", mun_code: "074606" },
  { label: "San Jose", mun_code: "074606" },
  { label: "San Miguel", mun_code: "074606" },
  { label: "San Roque", mun_code: "074606" },
  { label: "Suba (Pob.)", mun_code: "074606" },
  { label: "Tabuan", mun_code: "074606" },
  { label: "Tayawan", mun_code: "074606" },
  { label: "Tinago (Pob.)", mun_code: "074606" },
  { label: "Ubos (Pob.)", mun_code: "074606" },
  { label: "Villareal", mun_code: "074606" },
  { label: "San Isidro", mun_code: "074606" },
  { label: "Atotes", mun_code: "074607" },
  { label: "Batangan", mun_code: "074607" },
  { label: "Bulod", mun_code: "074607" },
  { label: "Cabcaban", mun_code: "074607" },
  { label: "Cabugan", mun_code: "074607" },
  { label: "Camudlas", mun_code: "074607" },
  { label: "Canluto", mun_code: "074607" },
  { label: "Danao", mun_code: "074607" },
  { label: "Danawan", mun_code: "074607" },
  { label: "Domolog", mun_code: "074607" },
  { label: "Malaga", mun_code: "074607" },
  { label: "Manseje", mun_code: "074607" },
  { label: "Matobato", mun_code: "074607" },
  { label: "Nagcasunog", mun_code: "074607" },
  { label: "Nalundan", mun_code: "074607" },
  { label: "Pangalaycayan", mun_code: "074607" },
  { label: "Poblacion (Payabon)", mun_code: "074607" },
  { label: "Salong", mun_code: "074607" },
  { label: "Tagaytay", mun_code: "074607" },
  { label: "Tinaogan", mun_code: "074607" },
  { label: "Tubod", mun_code: "074607" },
  { label: "Bayog", mun_code: "074608" },
  { label: "Binalbagan", mun_code: "074608" },
  { label: "Bucalan (East Budsalan)", mun_code: "074608" },
  { label: "Linothangan", mun_code: "074608" },
  { label: "Lumapao", mun_code: "074608" },
  { label: "Malaiba", mun_code: "074608" },
  { label: "Masulog", mun_code: "074608" },
  { label: "Panubigan", mun_code: "074608" },
  { label: "Mabigo (Pob.)", mun_code: "074608" },
  { label: "Pula", mun_code: "074608" },
  { label: "Budlasan (West Budlasan)", mun_code: "074608" },
  { label: "Ninoy Aquino", mun_code: "074608" },
  { label: "Anahawan", mun_code: "074609" },
  { label: "Apo Island", mun_code: "074609" },
  { label: "Bagacay", mun_code: "074609" },
  { label: "Baslay", mun_code: "074609" },
  { label: "Batuhon Dacu", mun_code: "074609" },
  { label: "Boloc-boloc", mun_code: "074609" },
  { label: "Bulak", mun_code: "074609" },
  { label: "Bunga", mun_code: "074609" },
  { label: "Casile", mun_code: "074609" },
  { label: "Libjo", mun_code: "074609" },
  { label: "Lipayo", mun_code: "074609" },
  { label: "Maayongtubig", mun_code: "074609" },
  { label: "Mag-aso", mun_code: "074609" },
  { label: "Magsaysay", mun_code: "074609" },
  { label: "Malongcay Dacu", mun_code: "074609" },
  { label: "Masaplod Norte", mun_code: "074609" },
  { label: "Masaplod Sur", mun_code: "074609" },
  { label: "Panubtuban", mun_code: "074609" },
  { label: "Poblacion I", mun_code: "074609" },
  { label: "Poblacion II", mun_code: "074609" },
  { label: "Poblacion III", mun_code: "074609" },
  { label: "Tugawe", mun_code: "074609" },
  { label: "Tunga-tunga", mun_code: "074609" },
  { label: "Bagacay", mun_code: "074610" },
  { label: "Bajumpandan", mun_code: "074610" },
  { label: "Balugo", mun_code: "074610" },
  { label: "Banilad", mun_code: "074610" },
  { label: "Bantayan", mun_code: "074610" },
  { label: "Batinguel", mun_code: "074610" },
  { label: "Bunao", mun_code: "074610" },
  { label: "Cadawinonan", mun_code: "074610" },
  { label: "Calindagan", mun_code: "074610" },
  { label: "Camanjac", mun_code: "074610" },
  { label: "Candau-ay", mun_code: "074610" },
  { label: "Cantil-e", mun_code: "074610" },
  { label: "Daro", mun_code: "074610" },
  { label: "Junob", mun_code: "074610" },
  { label: "Looc", mun_code: "074610" },
  { label: "Mangnao-Canal", mun_code: "074610" },
  { label: "Motong", mun_code: "074610" },
  { label: "Piapi", mun_code: "074610" },
  { label: "Poblacion No. 1 (Barangay 1)", mun_code: "074610" },
  { label: "Poblacion No. 2 (Barangay 2)", mun_code: "074610" },
  { label: "Poblacion No. 3 (Barangay 3)", mun_code: "074610" },
  { label: "Poblacion No. 4 (Barangay 4)", mun_code: "074610" },
  { label: "Poblacion No. 5 (Barangay 5)", mun_code: "074610" },
  { label: "Poblacion No. 6 (Barangay 6)", mun_code: "074610" },
  { label: "Poblacion No. 7 (Barangay 7)", mun_code: "074610" },
  { label: "Poblacion No. 8 (Barangay 8)", mun_code: "074610" },
  { label: "Pulantubig", mun_code: "074610" },
  { label: "Tabuctubig", mun_code: "074610" },
  { label: "Taclobo", mun_code: "074610" },
  { label: "Talay", mun_code: "074610" },
  { label: "Bakid", mun_code: "074611" },
  { label: "Balogo", mun_code: "074611" },
  { label: "Banwaque", mun_code: "074611" },
  { label: "Basak", mun_code: "074611" },
  { label: "Binobohan", mun_code: "074611" },
  { label: "Buenavista", mun_code: "074611" },
  { label: "Bulado", mun_code: "074611" },
  { label: "Calamba", mun_code: "074611" },
  { label: "Calupa-an", mun_code: "074611" },
  { label: "Hibaiyo", mun_code: "074611" },
  { label: "Hilaitan", mun_code: "074611" },
  { label: "Hinakpan", mun_code: "074611" },
  { label: "Humayhumay", mun_code: "074611" },
  { label: "Imelda", mun_code: "074611" },
  { label: "Kagawasan", mun_code: "074611" },
  { label: "Linantuyan", mun_code: "074611" },
  { label: "Luz", mun_code: "074611" },
  { label: "Mabunga", mun_code: "074611" },
  { label: "Mckinley", mun_code: "074611" },
  { label: "Nagsaha", mun_code: "074611" },
  { label: "Magsaysay", mun_code: "074611" },
  { label: "Malusay", mun_code: "074611" },
  { label: "Maniak", mun_code: "074611" },
  { label: "Padre Zamora", mun_code: "074611" },
  { label: "Plagatasanon", mun_code: "074611" },
  { label: "Planas", mun_code: "074611" },
  { label: "Poblacion", mun_code: "074611" },
  { label: "Sandayao", mun_code: "074611" },
  { label: "Tacpao", mun_code: "074611" },
  { label: "Tinayunan Beach", mun_code: "074611" },
  { label: "Tinayunan Hill", mun_code: "074611" },
  { label: "Trinidad", mun_code: "074611" },
  { label: "Villegas", mun_code: "074611" },
  { label: "Aglahug", mun_code: "074612" },
  { label: "Agutayon", mun_code: "074612" },
  { label: "Apanangon", mun_code: "074612" },
  { label: "Bae", mun_code: "074612" },
  { label: "Bala-as", mun_code: "074612" },
  { label: "Bangcal", mun_code: "074612" },
  { label: "Banog", mun_code: "074612" },
  { label: "Buto", mun_code: "074612" },
  { label: "Cabang", mun_code: "074612" },
  { label: "Camandayon", mun_code: "074612" },
  { label: "Cangharay", mun_code: "074612" },
  { label: "Canlahao", mun_code: "074612" },
  { label: "Dayoyo", mun_code: "074612" },
  { label: "Eli", mun_code: "074612" },
  { label: "Lacaon", mun_code: "074612" },
  { label: "Mahanlud", mun_code: "074612" },
  { label: "Malabago", mun_code: "074612" },
  { label: "Mambaid", mun_code: "074612" },
  { label: "Mongpong", mun_code: "074612" },
  { label: "Owacan", mun_code: "074612" },
  { label: "Pacuan", mun_code: "074612" },
  { label: "Panglaya-an", mun_code: "074612" },
  { label: "North Poblacion", mun_code: "074612" },
  { label: "South Poblacion", mun_code: "074612" },
  { label: "Polopantao", mun_code: "074612" },
  { label: "Sampiniton", mun_code: "074612" },
  { label: "Talamban", mun_code: "074612" },
  { label: "Tamao", mun_code: "074612" },
  { label: "Aniniaw", mun_code: "074613" },
  { label: "Aya", mun_code: "074613" },
  { label: "Bagtic", mun_code: "074613" },
  { label: "Biga-a", mun_code: "074613" },
  { label: "Busilak", mun_code: "074613" },
  { label: "Cangabo", mun_code: "074613" },
  { label: "Cantupa", mun_code: "074613" },
  { label: "Eli", mun_code: "074613" },
  { label: "Guihob", mun_code: "074613" },
  { label: "Kansumandig", mun_code: "074613" },
  { label: "Mambulod", mun_code: "074613" },
  { label: "Mandapaton", mun_code: "074613" },
  { label: "Manghulyawon", mun_code: "074613" },
  { label: "Manluminsag", mun_code: "074613" },
  { label: "Mapalasan", mun_code: "074613" },
  { label: "Maragondong", mun_code: "074613" },
  { label: "Martilo", mun_code: "074613" },
  { label: "Nasungan", mun_code: "074613" },
  { label: "Pacuan", mun_code: "074613" },
  { label: "Pangca", mun_code: "074613" },
  { label: "Pisong", mun_code: "074613" },
  { label: "Pitogo", mun_code: "074613" },
  { label: "Poblacion North", mun_code: "074613" },
  { label: "Poblacion South", mun_code: "074613" },
  { label: "San Jose", mun_code: "074613" },
  { label: "Solongon", mun_code: "074613" },
  { label: "Tala-on", mun_code: "074613" },
  { label: "Talayong", mun_code: "074613" },
  { label: "Elecia (Talostos)", mun_code: "074613" },
  { label: "Abis", mun_code: "074614" },
  { label: "Arebasore", mun_code: "074614" },
  { label: "Bagtic", mun_code: "074614" },
  { label: "Banban", mun_code: "074614" },
  { label: "Barras", mun_code: "074614" },
  { label: "Bato", mun_code: "074614" },
  { label: "Bugnay", mun_code: "074614" },
  { label: "Bulibulihan", mun_code: "074614" },
  { label: "Bulwang", mun_code: "074614" },
  { label: "Campanun-an", mun_code: "074614" },
  { label: "Canggohob", mun_code: "074614" },
  { label: "Cansal-ing", mun_code: "074614" },
  { label: "Dagbasan", mun_code: "074614" },
  { label: "Dahile", mun_code: "074614" },
  { label: "Himocdongon", mun_code: "074614" },
  { label: "Hagtu", mun_code: "074614" },
  { label: "Inapoy", mun_code: "074614" },
  { label: "Lamdas", mun_code: "074614" },
  { label: "Lumbangan", mun_code: "074614" },
  { label: "Luyang", mun_code: "074614" },
  { label: "Manlingay", mun_code: "074614" },
  { label: "Mayaposi", mun_code: "074614" },
  { label: "Napasu-an", mun_code: "074614" },
  { label: "New Namangka", mun_code: "074614" },
  { label: "Old Namangka", mun_code: "074614" },
  { label: "Pandanon", mun_code: "074614" },
  { label: "Paniabonan", mun_code: "074614" },
  { label: "Pantao", mun_code: "074614" },
  { label: "Poblacion", mun_code: "074614" },
  { label: "Samac", mun_code: "074614" },
  { label: "Tadlong", mun_code: "074614" },
  { label: "Tara", mun_code: "074614" },
  { label: "Alangilanan", mun_code: "074615" },
  { label: "Bagtic", mun_code: "074615" },
  { label: "Balaas", mun_code: "074615" },
  { label: "Bantolinao", mun_code: "074615" },
  { label: "Bolisong", mun_code: "074615" },
  { label: "Butong", mun_code: "074615" },
  { label: "Campuyo", mun_code: "074615" },
  { label: "Candabong", mun_code: "074615" },
  { label: "Concepcion", mun_code: "074615" },
  { label: "Dungo-an", mun_code: "074615" },
  { label: "Kauswagan", mun_code: "074615" },
  { label: "Libjo", mun_code: "074615" },
  { label: "Lamogong", mun_code: "074615" },
  { label: "Maaslum", mun_code: "074615" },
  { label: "Mandalupang", mun_code: "074615" },
  { label: "Panciao", mun_code: "074615" },
  { label: "Poblacion", mun_code: "074615" },
  { label: "Sac-sac", mun_code: "074615" },
  { label: "Salvacion", mun_code: "074615" },
  { label: "San Isidro", mun_code: "074615" },
  { label: "San Jose", mun_code: "074615" },
  { label: "Santa Monica", mun_code: "074615" },
  { label: "Suba", mun_code: "074615" },
  { label: "Sundo-an", mun_code: "074615" },
  { label: "Tanglad", mun_code: "074615" },
  { label: "Tubod", mun_code: "074615" },
  { label: "Tupas", mun_code: "074615" },
  { label: "Abante", mun_code: "074616" },
  { label: "Balayong", mun_code: "074616" },
  { label: "Banawe", mun_code: "074616" },
  { label: "Datagon", mun_code: "074616" },
  { label: "Fatima", mun_code: "074616" },
  { label: "Inawasan", mun_code: "074616" },
  { label: "Magsusunog", mun_code: "074616" },
  { label: "Malalangsi", mun_code: "074616" },
  { label: "Mamburao", mun_code: "074616" },
  { label: "Mangoto", mun_code: "074616" },
  { label: "Poblacion", mun_code: "074616" },
  { label: "San Isidro", mun_code: "074616" },
  { label: "Santa Agueda", mun_code: "074616" },
  { label: "Simborio", mun_code: "074616" },
  { label: "Yupisan", mun_code: "074616" },
  { label: "Calicanan", mun_code: "074616" },
  { label: "Basak", mun_code: "074617" },
  { label: "Basiao", mun_code: "074617" },
  { label: "Cambaloctot", mun_code: "074617" },
  { label: "Cancawas", mun_code: "074617" },
  { label: "Janayjanay", mun_code: "074617" },
  { label: "Jilocon", mun_code: "074617" },
  { label: "Naiba", mun_code: "074617" },
  { label: "Poblacion", mun_code: "074617" },
  { label: "San Roque", mun_code: "074617" },
  { label: "Siapo", mun_code: "074617" },
  { label: "Tampi", mun_code: "074617" },
  { label: "Tapon Norte", mun_code: "074617" },
  { label: "Alangilan", mun_code: "074618" },
  { label: "Amio", mun_code: "074618" },
  { label: "Buenavista", mun_code: "074618" },
  { label: "Kabulacan", mun_code: "074618" },
  { label: "Caigangan", mun_code: "074618" },
  { label: "Caranoche", mun_code: "074618" },
  { label: "Cawitan", mun_code: "074618" },
  { label: "Fatima", mun_code: "074618" },
  { label: "Mabuhay", mun_code: "074618" },
  { label: "Manalongon", mun_code: "074618" },
  { label: "Mansagomayon", mun_code: "074618" },
  { label: "Milagrosa", mun_code: "074618" },
  { label: "Nagbinlod", mun_code: "074618" },
  { label: "Nagbalaye", mun_code: "074618" },
  { label: "Obat", mun_code: "074618" },
  { label: "Poblacion", mun_code: "074618" },
  { label: "San Francisco", mun_code: "074618" },
  { label: "San Jose", mun_code: "074618" },
  { label: "San Miguel", mun_code: "074618" },
  { label: "San Pedro", mun_code: "074618" },
  { label: "Santo Rosario", mun_code: "074618" },
  { label: "Talalak", mun_code: "074618" },
  { label: "Albiga", mun_code: "074619" },
  { label: "Apoloy", mun_code: "074619" },
  { label: "Bonawon", mun_code: "074619" },
  { label: "Bonbonon", mun_code: "074619" },
  { label: "Cabangahan", mun_code: "074619" },
  { label: "Canaway", mun_code: "074619" },
  { label: "Casala-an", mun_code: "074619" },
  { label: "Caticugan", mun_code: "074619" },
  { label: "Datag", mun_code: "074619" },
  { label: "Giliga-on", mun_code: "074619" },
  { label: "Inalad", mun_code: "074619" },
  { label: "Malabuhan", mun_code: "074619" },
  { label: "Maloh", mun_code: "074619" },
  { label: "Mantiquil", mun_code: "074619" },
  { label: "Mantuyop", mun_code: "074619" },
  { label: "Napacao", mun_code: "074619" },
  { label: "Poblacion I", mun_code: "074619" },
  { label: "Poblacion II", mun_code: "074619" },
  { label: "Poblacion III", mun_code: "074619" },
  { label: "Poblacion IV", mun_code: "074619" },
  { label: "Salag", mun_code: "074619" },
  { label: "San Jose", mun_code: "074619" },
  { label: "Sandulot", mun_code: "074619" },
  { label: "Si-it", mun_code: "074619" },
  { label: "Sumaliring", mun_code: "074619" },
  { label: "Tayak", mun_code: "074619" },
  { label: "Agan-an", mun_code: "074620" },
  { label: "Ajong", mun_code: "074620" },
  { label: "Balugo", mun_code: "074620" },
  { label: "Bolocboloc", mun_code: "074620" },
  { label: "Calabnugan", mun_code: "074620" },
  { label: "Cangmating", mun_code: "074620" },
  { label: "Enrique Villanueva", mun_code: "074620" },
  { label: "Looc", mun_code: "074620" },
  { label: "Magatas", mun_code: "074620" },
  { label: "Maningcao", mun_code: "074620" },
  { label: "Maslog", mun_code: "074620" },
  { label: "Poblacion", mun_code: "074620" },
  { label: "San Antonio", mun_code: "074620" },
  { label: "Tubigon", mun_code: "074620" },
  { label: "Tubtubon", mun_code: "074620" },
  { label: "Azagra", mun_code: "074621" },
  { label: "Bahi-an", mun_code: "074621" },
  { label: "Luca", mun_code: "074621" },
  { label: "Manipis", mun_code: "074621" },
  { label: "Novallas", mun_code: "074621" },
  { label: "Obogon", mun_code: "074621" },
  { label: "Pal-ew", mun_code: "074621" },
  { label: "Poblacion I (Barangay 1)", mun_code: "074621" },
  { label: "Poblacion II (Barangay 2)", mun_code: "074621" },
  { label: "Poblacion III (Barangay 3)", mun_code: "074621" },
  { label: "Poblacion IV (Barangay 4)", mun_code: "074621" },
  { label: "Poblacion V (Barangay 5)", mun_code: "074621" },
  { label: "Poblacion VI (Barangay 6)", mun_code: "074621" },
  { label: "Poblacion VII (Barangay 7)", mun_code: "074621" },
  { label: "Poblacion VIII (Barangay 8)", mun_code: "074621" },
  { label: "Poblacion IX (Barangay 9)", mun_code: "074621" },
  { label: "Polo", mun_code: "074621" },
  { label: "San Isidro", mun_code: "074621" },
  { label: "San Jose", mun_code: "074621" },
  { label: "San Miguel", mun_code: "074621" },
  { label: "Santa Cruz Nuevo", mun_code: "074621" },
  { label: "Santa Cruz Viejo", mun_code: "074621" },
  { label: "Tugas", mun_code: "074621" },
  { label: "Bacong", mun_code: "074622" },
  { label: "Bago", mun_code: "074622" },
  { label: "Banga", mun_code: "074622" },
  { label: "Cabulotan", mun_code: "074622" },
  { label: "Cambaye", mun_code: "074622" },
  { label: "Dalaupon", mun_code: "074622" },
  { label: "Guincalaban", mun_code: "074622" },
  { label: "Ilaya-Tayasan", mun_code: "074622" },
  { label: "Jilabangan", mun_code: "074622" },
  { label: "Lag-it", mun_code: "074622" },
  { label: "Linao", mun_code: "074622" },
  { label: "Lutay", mun_code: "074622" },
  { label: "Maglihe", mun_code: "074622" },
  { label: "Matauta", mun_code: "074622" },
  { label: "Magtuhao", mun_code: "074622" },
  { label: "Matuog", mun_code: "074622" },
  { label: "Numnum", mun_code: "074622" },
  { label: "Palaslan", mun_code: "074622" },
  { label: "Pindahan", mun_code: "074622" },
  { label: "Pinalubngan", mun_code: "074622" },
  { label: "Pinocawan", mun_code: "074622" },
  { label: "Poblacion", mun_code: "074622" },
  { label: "Santa Cruz", mun_code: "074622" },
  { label: "Saying", mun_code: "074622" },
  { label: "Suquib", mun_code: "074622" },
  { label: "Tamao", mun_code: "074622" },
  { label: "Tambulan", mun_code: "074622" },
  { label: "Tanlad", mun_code: "074622" },
  { label: "Apolong", mun_code: "074623" },
  { label: "Balabag East", mun_code: "074623" },
  { label: "Balabag West", mun_code: "074623" },
  { label: "Balayagmanok", mun_code: "074623" },
  { label: "Balili", mun_code: "074623" },
  { label: "Balugo", mun_code: "074623" },
  { label: "Bongbong", mun_code: "074623" },
  { label: "Bong-ao", mun_code: "074623" },
  { label: "Calayugan", mun_code: "074623" },
  { label: "Cambucad", mun_code: "074623" },
  { label: "Dobdob", mun_code: "074623" },
  { label: "Jawa", mun_code: "074623" },
  { label: "Caidiocan", mun_code: "074623" },
  { label: "Liptong", mun_code: "074623" },
  { label: "Lunga", mun_code: "074623" },
  { label: "Malabo", mun_code: "074623" },
  { label: "Malaunay", mun_code: "074623" },
  { label: "Mampas", mun_code: "074623" },
  { label: "Palinpinon", mun_code: "074623" },
  { label: "North Poblacion", mun_code: "074623" },
  { label: "South Poblacion", mun_code: "074623" },
  { label: "Puhagan", mun_code: "074623" },
  { label: "Pulangbato", mun_code: "074623" },
  { label: "Sagbang", mun_code: "074623" },
  { label: "Bagawines", mun_code: "074624" },
  { label: "Bairan", mun_code: "074624" },
  { label: "Don Espiridion Villegas", mun_code: "074624" },
  { label: "Guba", mun_code: "074624" },
  { label: "Cabulihan", mun_code: "074624" },
  { label: "Macapso", mun_code: "074624" },
  { label: "Malangsa", mun_code: "074624" },
  { label: "Molobolo", mun_code: "074624" },
  { label: "Maglahos", mun_code: "074624" },
  { label: "Pinocawan", mun_code: "074624" },
  { label: "Poblacion", mun_code: "074624" },
  { label: "Puan", mun_code: "074624" },
  { label: "Tabon", mun_code: "074624" },
  { label: "Tagbino", mun_code: "074624" },
  { label: "Ulay", mun_code: "074624" },
  { label: "Basac", mun_code: "074625" },
  { label: "Calango", mun_code: "074625" },
  { label: "Lotuban", mun_code: "074625" },
  { label: "Malongcay Diot", mun_code: "074625" },
  { label: "Maluay", mun_code: "074625" },
  { label: "Mayabon", mun_code: "074625" },
  { label: "Nabago", mun_code: "074625" },
  { label: "Nasig-id", mun_code: "074625" },
  { label: "Najandig", mun_code: "074625" },
  { label: "Poblacion", mun_code: "074625" },
  // { label: "Balolong", mun_code: "076101" },
  // { label: "Bino-ongan", mun_code: "076101" },
  // { label: "Bitaug", mun_code: "076101" },
  // { label: "Bolot", mun_code: "076101" },
  // { label: "Camogao", mun_code: "076101" },
  // { label: "Cangmangki", mun_code: "076101" },
  // { label: "Libo", mun_code: "076101" },
  // { label: "Lomangcapan", mun_code: "076101" },
  // { label: "Lotloton", mun_code: "076101" },
  // { label: "Manan-ao", mun_code: "076101" },
  // { label: "Olave", mun_code: "076101" },
  // { label: "Parian", mun_code: "076101" },
  // { label: "Poblacion", mun_code: "076101" },
  // { label: "Tulapos", mun_code: "076101" },
  // { label: "Bagacay", mun_code: "076102" },
  // { label: "Balolang", mun_code: "076102" },
  // { label: "Basac", mun_code: "076102" },
  // { label: "Bintangan", mun_code: "076102" },
  // { label: "Bontod", mun_code: "076102" },
  // { label: "Cabulihan", mun_code: "076102" },
  // { label: "Calunasan", mun_code: "076102" },
  // { label: "Candigum", mun_code: "076102" },
  // { label: "Cang-allas", mun_code: "076102" },
  // { label: "Cang-apa", mun_code: "076102" },
  // { label: "Cangbagsa", mun_code: "076102" },
  // { label: "Cangmalalag", mun_code: "076102" },
  // { label: "Canlambo", mun_code: "076102" },
  // { label: "Canlasog", mun_code: "076102" },
  // { label: "Catamboan", mun_code: "076102" },
  // { label: "Helen (Datag)", mun_code: "076102" },
  // { label: "Nonoc", mun_code: "076102" },
  // { label: "North Poblacion", mun_code: "076102" },
  // { label: "South Poblacion", mun_code: "076102" },
  // { label: "Ponong", mun_code: "076102" },
  // { label: "Sabang", mun_code: "076102" },
  // { label: "Sandugan", mun_code: "076102" },
  // { label: "Taculing", mun_code: "076102" },
  // { label: "Campalanas", mun_code: "076103" },
  // { label: "Cangclaran", mun_code: "076103" },
  // { label: "Cangomantong", mun_code: "076103" },
  // { label: "Capalasanan", mun_code: "076103" },
  // { label: "Catamboan (Pob.)", mun_code: "076103" },
  // { label: "Gabayan", mun_code: "076103" },
  // { label: "Kimba", mun_code: "076103" },
  // { label: "Kinamandagan", mun_code: "076103" },
  // { label: "Lower Cabangcalan", mun_code: "076103" },
  // { label: "Nagerong", mun_code: "076103" },
  // { label: "Po-o", mun_code: "076103" },
  // { label: "Simacolong", mun_code: "076103" },
  // { label: "Tagmanocan", mun_code: "076103" },
  // { label: "Talayong", mun_code: "076103" },
  // { label: "Tigbawan (Pob.)", mun_code: "076103" },
  // { label: "Tignao", mun_code: "076103" },
  // { label: "Upper Cabangcalan", mun_code: "076103" },
  // { label: "Ytaya", mun_code: "076103" },
  // { label: "Bogo", mun_code: "076104" },
  // { label: "Bonga", mun_code: "076104" },
  // { label: "Cabal-asan", mun_code: "076104" },
  // { label: "Calunasan", mun_code: "076104" },
  // { label: "Candaping A", mun_code: "076104" },
  // { label: "Candaping B", mun_code: "076104" },
  // { label: "Cantaroc A", mun_code: "076104" },
  // { label: "Cantaroc B", mun_code: "076104" },
  // { label: "Cantugbas", mun_code: "076104" },
  // { label: "Lico-an", mun_code: "076104" },
  // { label: "Lilo-an", mun_code: "076104" },
  // { label: "Looc", mun_code: "076104" },
  // { label: "Logucan", mun_code: "076104" },
  // { label: "Minalulan", mun_code: "076104" },
  // { label: "Nabutay", mun_code: "076104" },
  // { label: "Olang", mun_code: "076104" },
  // { label: "Pisong A", mun_code: "076104" },
  // { label: "Pisong B", mun_code: "076104" },
  // { label: "Poblacion Norte", mun_code: "076104" },
  // { label: "Poblacion Sur", mun_code: "076104" },
  // { label: "Saguing", mun_code: "076104" },
  // { label: "Sawang", mun_code: "076104" },
  // { label: "Canasagan", mun_code: "076105" },
  // { label: "Candura", mun_code: "076105" },
  // { label: "Cangmunag", mun_code: "076105" },
  // { label: "Cansayang", mun_code: "076105" },
  // { label: "Catulayan", mun_code: "076105" },
  // { label: "Lala-o", mun_code: "076105" },
  // { label: "Maite", mun_code: "076105" },
  // { label: "Napo", mun_code: "076105" },
  // { label: "Paliton", mun_code: "076105" },
  // { label: "Poblacion", mun_code: "076105" },
  // { label: "Solangon", mun_code: "076105" },
  // { label: "Tag-ibo", mun_code: "076105" },
  // { label: "Tambisan", mun_code: "076105" },
  // { label: "Timbaon", mun_code: "076105" },
  // { label: "Tubod", mun_code: "076105" },
  // { label: "Banban", mun_code: "076106" },
  // { label: "Bolos", mun_code: "076106" },
  // { label: "Caipilan", mun_code: "076106" },
  // { label: "Caitican", mun_code: "076106" },
  // { label: "Calalinan", mun_code: "076106" },
  // { label: "Cang-atuyom", mun_code: "076106" },
  // { label: "Canal", mun_code: "076106" },
  // { label: "Candanay Norte", mun_code: "076106" },
  // { label: "Candanay Sur", mun_code: "076106" },
  // { label: "Cang-adieng", mun_code: "076106" },
  // { label: "Cang-agong", mun_code: "076106" },
  // { label: "Cang-alwang", mun_code: "076106" },
  // { label: "Cang-asa", mun_code: "076106" },
  // { label: "Cang-inte", mun_code: "076106" },
  // { label: "Cang-isad", mun_code: "076106" },
  // { label: "Canghunoghunog", mun_code: "076106" },
  // { label: "Cangmatnog", mun_code: "076106" },
  // { label: "Cangmohao", mun_code: "076106" },
  // { label: "Cantabon", mun_code: "076106" },
  // { label: "Caticugan", mun_code: "076106" },
  // { label: "Dumanhog", mun_code: "076106" },
  // { label: "Ibabao", mun_code: "076106" },
  // { label: "Lambojon", mun_code: "076106" },
  // { label: "Luyang", mun_code: "076106" },
  // { label: "Luzong", mun_code: "076106" },
  // { label: "Olo", mun_code: "076106" },
  // { label: "Pangi", mun_code: "076106" },
  // { label: "Panlautan", mun_code: "076106" },
  // { label: "Pasihagon", mun_code: "076106" },
  // { label: "Pili", mun_code: "076106" },
  // { label: "Poblacion", mun_code: "076106" },
  // { label: "Polangyuta", mun_code: "076106" },
  // { label: "Ponong", mun_code: "076106" },
  // { label: "Sabang", mun_code: "076106" },
  // { label: "San Antonio", mun_code: "076106" },
  // { label: "Songculan", mun_code: "076106" },
  // { label: "Tacdog", mun_code: "076106" },
  // { label: "Tacloban", mun_code: "076106" },
  // { label: "Tambisan", mun_code: "076106" },
  // { label: "Tebjong", mun_code: "076106" },
  // { label: "Tinago", mun_code: "076106" },
  // { label: "Tongo", mun_code: "076106" },
  // { label: "Aguinaldo", mun_code: "082601" },
  // { label: "Balud (Pob.)", mun_code: "082601" },
  // { label: "Bato (San Luis)", mun_code: "082601" },
  // { label: "Beri", mun_code: "082601" },
  // { label: "Bigo", mun_code: "082601" },
  // { label: "Buenavista", mun_code: "082601" },
  // { label: "Cagsalay", mun_code: "082601" },
  // { label: "Campacion", mun_code: "082601" },
  // { label: "Carapdapan", mun_code: "082601" },
  // { label: "Casidman", mun_code: "082601" },
  // { label: "Catumsan", mun_code: "082601" },
  // { label: "Central (Pob.)", mun_code: "082601" },
  // { label: "Concepcion", mun_code: "082601" },
  // { label: "Garden (Pob.)", mun_code: "082601" },
  // { label: "Inayawan", mun_code: "082601" },
  // { label: "Macarthur", mun_code: "082601" },
  // { label: "Rawis (Pob.)", mun_code: "082601" },
  // { label: "Tangbo", mun_code: "082601" },
  // { label: "Tawagan", mun_code: "082601" },
  // { label: "Tebalawon", mun_code: "082601" },
  // { label: "Bacjao", mun_code: "082602" },
  // { label: "Cag-olango", mun_code: "082602" },
  // { label: "Cansumangcay", mun_code: "082602" },
  // { label: "Guinmaayohan", mun_code: "082602" },
  // { label: "Barangay Poblacion I", mun_code: "082602" },
  // { label: "Barangay Poblacion II", mun_code: "082602" },
  // { label: "Barangay Poblacion III", mun_code: "082602" },
  // { label: "Barangay Poblacion IV", mun_code: "082602" },
  // { label: "Barangay Poblacion V", mun_code: "082602" },
  // { label: "Barangay Poblacion VI", mun_code: "082602" },
  // { label: "San Miguel", mun_code: "082602" },
  // { label: "Santa Rosa", mun_code: "082602" },
  // { label: "Maybunga", mun_code: "082602" },
  // { label: "Balogo", mun_code: "082603" },
  // { label: "Bangon", mun_code: "082603" },
  // { label: "Cabay", mun_code: "082603" },
  // { label: "Caisawan", mun_code: "082603" },
  // { label: "Cantubi", mun_code: "082603" },
  // { label: "General Malvar", mun_code: "082603" },
  // { label: "Guinpoliran", mun_code: "082603" },
  // { label: "Julag", mun_code: "082603" },
  // { label: "Magsaysay", mun_code: "082603" },
  // { label: "Maramag", mun_code: "082603" },
  // { label: "Poblacion I", mun_code: "082603" },
  // { label: "Poblacion II", mun_code: "082603" },
  // { label: "Poblacion III", mun_code: "082603" },
  // { label: "Poblacion IV", mun_code: "082603" },
  // { label: "Poblacion V", mun_code: "082603" },
  // { label: "Alang-alang", mun_code: "082604" },
  // { label: "Amantacop", mun_code: "082604" },
  // { label: "Ando", mun_code: "082604" },
  // { label: "Balacdas", mun_code: "082604" },
  // { label: "Balud", mun_code: "082604" },
  // { label: "Banuyo", mun_code: "082604" },
  // { label: "Baras", mun_code: "082604" },
  // { label: "Bato", mun_code: "082604" },
  // { label: "Bayobay", mun_code: "082604" },
  // { label: "Benowangan", mun_code: "082604" },
  // { label: "Bugas", mun_code: "082604" },
  // { label: "Cabalagnan", mun_code: "082604" },
  // { label: "Cabong", mun_code: "082604" },
  // { label: "Cagbonga", mun_code: "082604" },
  // { label: "Calico-an", mun_code: "082604" },
  // { label: "Calingatngan", mun_code: "082604" },
  // { label: "Campesao", mun_code: "082604" },
  // { label: "Can-abong", mun_code: "082604" },
  // { label: "Can-aga", mun_code: "082604" },
  // { label: "Camada", mun_code: "082604" },
  // { label: "Canjaway", mun_code: "082604" },
  // { label: "Canlaray", mun_code: "082604" },
  // { label: "Canyopay", mun_code: "082604" },
  // { label: "Divinubo", mun_code: "082604" },
  // { label: "Hebacong", mun_code: "082604" },
  // { label: "Hindang", mun_code: "082604" },
  // { label: "Lalawigan", mun_code: "082604" },
  // { label: "Libuton", mun_code: "082604" },
  // { label: "Locso-on", mun_code: "082604" },
  // { label: "Maybacong", mun_code: "082604" },
  // { label: "Maypangdan", mun_code: "082604" },
  // { label: "Pepelitan", mun_code: "082604" },
  // { label: "Pinanag-an", mun_code: "082604" },
  // { label: "Purok D1 (Pob.)", mun_code: "082604" },
  // { label: "Purok A (Pob.)", mun_code: "082604" },
  // { label: "Purok B (Pob.)", mun_code: "082604" },
  // { label: "Purok C (Pob.)", mun_code: "082604" },
  // { label: "Purok D2 (Pob.)", mun_code: "082604" },
  // { label: "Purok E (Pob.)", mun_code: "082604" },
  // { label: "Purok F (Pob.)", mun_code: "082604" },
  // { label: "Purok G (Pob.)", mun_code: "082604" },
  // { label: "Purok H (Pob.)", mun_code: "082604" },
  // { label: "Punta Maria", mun_code: "082604" },
  // { label: "Sabang North", mun_code: "082604" },
  // { label: "Sabang South", mun_code: "082604" },
  // { label: "San Andres", mun_code: "082604" },
  // { label: "San Gabriel", mun_code: "082604" },
  // { label: "San Gregorio", mun_code: "082604" },
  // { label: "San Jose", mun_code: "082604" },
  // { label: "San Mateo", mun_code: "082604" },
  // { label: "San Pablo", mun_code: "082604" },
  // { label: "San Saturnino", mun_code: "082604" },
  // { label: "Santa Fe", mun_code: "082604" },
  // { label: "Siha", mun_code: "082604" },
  // { label: "Songco", mun_code: "082604" },
  // { label: "Sohutan", mun_code: "082604" },
  // { label: "Suribao", mun_code: "082604" },
  // { label: "Surok", mun_code: "082604" },
  // { label: "Taboc", mun_code: "082604" },
  // { label: "Tabunan", mun_code: "082604" },
  // { label: "Tamoso", mun_code: "082604" },
  // { label: "Balagon", mun_code: "082605" },
  // { label: "Baruk", mun_code: "082605" },
  // { label: "Boco", mun_code: "082605" },
  // { label: "Caghalong", mun_code: "082605" },
  // { label: "Camantang", mun_code: "082605" },
  // { label: "Can-ilay", mun_code: "082605" },
  // { label: "Cansangaya", mun_code: "082605" },
  // { label: "Canteros", mun_code: "082605" },
  // { label: "Carolina", mun_code: "082605" },
  // { label: "Guibuangan", mun_code: "082605" },
  // { label: "Jepaco", mun_code: "082605" },
  // { label: "Mabuhay", mun_code: "082605" },
  // { label: "Malogo", mun_code: "082605" },
  // { label: "Obong", mun_code: "082605" },
  // { label: "Pandol", mun_code: "082605" },
  // { label: "Barangay 1 Poblacion", mun_code: "082605" },
  // { label: "Barangay 10 Poblacion", mun_code: "082605" },
  // { label: "Barangay 2 Poblacion", mun_code: "082605" },
  // { label: "Barangay 3 Poblacion", mun_code: "082605" },
  // { label: "Barangay 4 Poblacion", mun_code: "082605" },
  // { label: "Barangay 5 Poblacion", mun_code: "082605" },
  // { label: "Barangay 6 Poblacion", mun_code: "082605" },
  // { label: "Barangay 7 Poblacion", mun_code: "082605" },
  // { label: "Barangay 8 Poblacion", mun_code: "082605" },
  // { label: "Barangay 9 Poblacion", mun_code: "082605" },
  // { label: "Salvacion", mun_code: "082605" },
  // { label: "Solong", mun_code: "082605" },
  // { label: "Rawis", mun_code: "082605" },
  // { label: "Aroganga", mun_code: "082606" },
  // { label: "Magongbong", mun_code: "082606" },
  // { label: "Buenavista", mun_code: "082606" },
  // { label: "Cabago-an", mun_code: "082606" },
  // { label: "Caglao-an", mun_code: "082606" },
  // { label: "Cagtabon", mun_code: "082606" },
  // { label: "Dampigan", mun_code: "082606" },
  // { label: "Dapdap", mun_code: "082606" },
  // { label: "Del Pilar", mun_code: "082606" },
  // { label: "Denigpian", mun_code: "082606" },
  // { label: "Gap-ang", mun_code: "082606" },
  // { label: "Japitan", mun_code: "082606" },
  // { label: "Jicontol", mun_code: "082606" },
  // { label: "Hilabaan", mun_code: "082606" },
  // { label: "Hinolaso", mun_code: "082606" },
  // { label: "Libertad", mun_code: "082606" },
  // { label: "Magsaysay", mun_code: "082606" },
  // { label: "Malobago", mun_code: "082606" },
  // { label: "Barangay 1 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 10 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 11 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 12 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 13 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 14 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 15 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 2 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 3 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 4 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 5 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 6 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 7 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 8 (Pob.)", mun_code: "082606" },
  // { label: "Barangay 9 (Pob.)", mun_code: "082606" },
  // { label: "Rizal", mun_code: "082606" },
  // { label: "San Isidro (Malabag)", mun_code: "082606" },
  // { label: "San Pascual", mun_code: "082606" },
  // { label: "San Roque", mun_code: "082606" },
  // { label: "San Vicente", mun_code: "082606" },
  // { label: "Santa Cruz", mun_code: "082606" },
  // { label: "Tanauan", mun_code: "082606" },
  // { label: "Villahermosa", mun_code: "082606" },
  // { label: "Bonghon", mun_code: "082606" },
  // { label: "Malaintos", mun_code: "082606" },
  // { label: "Tikling", mun_code: "082606" },
  // { label: "Alang-alang", mun_code: "082607" },
  // { label: "Binalay", mun_code: "082607" },
  // { label: "Calutan", mun_code: "082607" },
  // { label: "Camcuevas", mun_code: "082607" },
  // { label: "Domrog", mun_code: "082607" },
  // { label: "Limbujan", mun_code: "082607" },
  // { label: "Magsaysay", mun_code: "082607" },
  // { label: "Pingan", mun_code: "082607" },
  // { label: "Poblacion Barangay 1", mun_code: "082607" },
  // { label: "Poblacion Barangay 2", mun_code: "082607" },
  // { label: "Poblacion Barangay 3", mun_code: "082607" },
  // { label: "Poblacion Barangay 4", mun_code: "082607" },
  // { label: "Poblacion Barangay 5", mun_code: "082607" },
  // { label: "Poblacion Barangay 6", mun_code: "082607" },
  // { label: "Poblacion Barangay 7", mun_code: "082607" },
  // { label: "Poblacion Barangay 8", mun_code: "082607" },
  // { label: "Laurel", mun_code: "082607" },
  // { label: "Roxas", mun_code: "082607" },
  // { label: "Quirino", mun_code: "082607" },
  // { label: "San Isidro", mun_code: "082607" },
  // { label: "San Roque", mun_code: "082607" },
  // { label: "Santa Cruz (Opong)", mun_code: "082607" },
  // { label: "Santa Fe", mun_code: "082607" },
  // { label: "Tugop", mun_code: "082607" },
  // { label: "Vigan", mun_code: "082607" },
  // { label: "Macapagal", mun_code: "082607" },
  // { label: "Aguinaldo", mun_code: "082607" },
  // { label: "Quezon", mun_code: "082607" },
  // { label: "Tandang Sora", mun_code: "082607" },
  // { label: "Barangay 1 (Pob.)", mun_code: "082608" },
  // { label: "Barangay 2 (Pob.)", mun_code: "082608" },
  // { label: "Barangay 3 (Pob.)", mun_code: "082608" },
  // { label: "Barangay 4 (Pob.)", mun_code: "082608" },
  // { label: "Barangay 5 (Pob.)", mun_code: "082608" },
  // { label: "Barangay 6 (Pob.)", mun_code: "082608" },
  // { label: "Barangay 7 (Pob.)", mun_code: "082608" },
  // { label: "Biga", mun_code: "082608" },
  // { label: "Coticot", mun_code: "082608" },
  // { label: "Gigoso", mun_code: "082608" },
  // { label: "Huknan", mun_code: "082608" },
  // { label: "Parina", mun_code: "082608" },
  // { label: "Paya", mun_code: "082608" },
  // { label: "President Roxas", mun_code: "082608" },
  // { label: "San Miguel", mun_code: "082608" },
  // { label: "Santa Cruz (Cansingkol)", mun_code: "082608" },
  // { label: "Barangay 8 (Pob.)", mun_code: "082608" },
  // { label: "San Isidro (Malabag)", mun_code: "082608" },
  // { label: "Alingarog", mun_code: "082609" },
  // { label: "Bagua", mun_code: "082609" },
  // { label: "Banaag", mun_code: "082609" },
  // { label: "Banahao", mun_code: "082609" },
  // { label: "Baras", mun_code: "082609" },
  // { label: "Barbo", mun_code: "082609" },
  // { label: "Bitaugan", mun_code: "082609" },
  // { label: "Bungtod", mun_code: "082609" },
  // { label: "Bucao", mun_code: "082609" },
  // { label: "Buenavista", mun_code: "082609" },
  // { label: "Cagdara-o", mun_code: "082609" },
  // { label: "Cagusu-an", mun_code: "082609" },
  // { label: "Camparang", mun_code: "082609" },
  // { label: "Campoyong", mun_code: "082609" },
  // { label: "Cantahay", mun_code: "082609" },
  // { label: "Casuguran", mun_code: "082609" },
  // { label: "Cogon", mun_code: "082609" },
  // { label: "Culasi", mun_code: "082609" },
  // { label: "Poblacion Ward 10", mun_code: "082609" },
  // { label: "Poblacion Ward 9-A", mun_code: "082609" },
  // { label: "Gahoy", mun_code: "082609" },
  // { label: "Habag", mun_code: "082609" },
  // { label: "Hamorawon", mun_code: "082609" },
  // { label: "Inapulangan", mun_code: "082609" },
  // { label: "Poblacion Ward 4-A", mun_code: "082609" },
  // { label: "Lupok (Pob.)", mun_code: "082609" },
  // { label: "Mayana", mun_code: "082609" },
  // { label: "Ngolos", mun_code: "082609" },
  // { label: "Pagbabangnan", mun_code: "082609" },
  // { label: "Pagnamitan", mun_code: "082609" },
  // { label: "Poblacion Ward 1", mun_code: "082609" },
  // { label: "Poblacion Ward 2", mun_code: "082609" },
  // { label: "Poblacion Ward 11", mun_code: "082609" },
  // { label: "Poblacion Ward 12", mun_code: "082609" },
  // { label: "Poblacion Ward 3", mun_code: "082609" },
  // { label: "Poblacion Ward 4", mun_code: "082609" },
  // { label: "Poblacion Ward 5", mun_code: "082609" },
  // { label: "Poblacion Ward 6", mun_code: "082609" },
  // { label: "Poblacion Ward 7", mun_code: "082609" },
  // { label: "Poblacion Ward 8", mun_code: "082609" },
  // { label: "Poblacion Ward 9", mun_code: "082609" },
  // { label: "Salug", mun_code: "082609" },
  // { label: "San Antonio", mun_code: "082609" },
  // { label: "San Jose", mun_code: "082609" },
  // { label: "San Pedro", mun_code: "082609" },
  // { label: "Sapao", mun_code: "082609" },
  // { label: "Sulangan", mun_code: "082609" },
  // { label: "Suluan", mun_code: "082609" },
  // { label: "Surok", mun_code: "082609" },
  // { label: "Taytay", mun_code: "082609" },
  // { label: "Timala", mun_code: "082609" },
  // { label: "Trinidad", mun_code: "082609" },
  // { label: "Victory Island", mun_code: "082609" },
  // { label: "Canawayon", mun_code: "082609" },
  // { label: "Dalaragan", mun_code: "082609" },
  // { label: "Hagna", mun_code: "082609" },
  // { label: "Hollywood", mun_code: "082609" },
  // { label: "San Juan", mun_code: "082609" },
  // { label: "Tagporo", mun_code: "082609" },
  // { label: "Batang", mun_code: "082610" },
  // { label: "Cacatmonan", mun_code: "082610" },
  // { label: "Garawon", mun_code: "082610" },
  // { label: "Canciledes", mun_code: "082610" },
  // { label: "Carmen", mun_code: "082610" },
  // { label: "Nagaja", mun_code: "082610" },
  // { label: "Padang", mun_code: "082610" },
  // { label: "Barangay 1 (Pob.)", mun_code: "082610" },
  // { label: "Barangay 2 (Pob.)", mun_code: "082610" },
  // { label: "Barangay 3 (Pob.)", mun_code: "082610" },
  // { label: "Barangay 4 (Pob.)", mun_code: "082610" },
  // { label: "San Isidro", mun_code: "082610" },
  // { label: "San Miguel", mun_code: "082610" },
  // { label: "Agsaman", mun_code: "082611" },
  // { label: "Cagmanaba", mun_code: "082611" },
  // { label: "Dorillo", mun_code: "082611" },
  // { label: "Jewaran", mun_code: "082611" },
  // { label: "Mabuhay", mun_code: "082611" },
  // { label: "Magsaysay", mun_code: "082611" },
  // { label: "Barangay 1 (Pob.)", mun_code: "082611" },
  // { label: "Barangay 2 (Pob.)", mun_code: "082611" },
  // { label: "Barangay 3 (Pob.)", mun_code: "082611" },
  // { label: "Barangay 4 (Pob.)", mun_code: "082611" },
  // { label: "Recare", mun_code: "082611" },
  // { label: "Roxas", mun_code: "082611" },
  // { label: "San Roque", mun_code: "082611" },
  // { label: "Betaog", mun_code: "082612" },
  // { label: "Bolusao", mun_code: "082612" },
  // { label: "Guinob-an", mun_code: "082612" },
  // { label: "Maslog", mun_code: "082612" },
  // { label: "Barangay Poblacion 1", mun_code: "082612" },
  // { label: "Barangay Poblacion 2", mun_code: "082612" },
  // { label: "Barangay Poblacion 3", mun_code: "082612" },
  // { label: "Barangay Poblacion 4", mun_code: "082612" },
  // { label: "Barangay Poblacion 5", mun_code: "082612" },
  // { label: "Barangay Poblacion 6", mun_code: "082612" },
  // { label: "Barangay Poblacion 7", mun_code: "082612" },
  // { label: "Barangay Poblacion 8", mun_code: "082612" },
  // { label: "Barangay Poblacion 9", mun_code: "082612" },
  // { label: "Barangay Poblacion 10", mun_code: "082612" },
  // { label: "San Isidro", mun_code: "082612" },
  // { label: "Taguite", mun_code: "082612" },
  // { label: "Antipolo", mun_code: "082613" },
  // { label: "Babanikhon", mun_code: "082613" },
  // { label: "Bacayawan", mun_code: "082613" },
  // { label: "Barobo", mun_code: "082613" },
  // { label: "Burak", mun_code: "082613" },
  // { label: "Can-ato", mun_code: "082613" },
  // { label: "Candoros", mun_code: "082613" },
  // { label: "Canliwag", mun_code: "082613" },
  // { label: "Cantomco", mun_code: "082613" },
  // { label: "Hugpa", mun_code: "082613" },
  // { label: "Maca-anga", mun_code: "082613" },
  // { label: "Magtino", mun_code: "082613" },
  // { label: "Mina-anod", mun_code: "082613" },
  // { label: "Naubay", mun_code: "082613" },
  // { label: "Piliw", mun_code: "082613" },
  // { label: "Barangay 1 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 2 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 3 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 4 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 5 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 6 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 7 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 8 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 9 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 10 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 11 (Pob.)", mun_code: "082613" },
  // { label: "Barangay 12 (Pob.)", mun_code: "082613" },
  // { label: "San Jose", mun_code: "082613" },
  // { label: "San Miguel", mun_code: "082613" },
  // { label: "San Roque", mun_code: "082613" },
  // { label: "So-ong", mun_code: "082613" },
  // { label: "Tabok", mun_code: "082613" },
  // { label: "Waso", mun_code: "082613" },
  // { label: "Bulawan", mun_code: "082614" },
  // { label: "Carayacay", mun_code: "082614" },
  // { label: "Libertad", mun_code: "082614" },
  // { label: "Malobago", mun_code: "082614" },
  // { label: "Maputi", mun_code: "082614" },
  // { label: "Barangay 1 (Pob.)", mun_code: "082614" },
  // { label: "Barangay 2 (Pob.)", mun_code: "082614" },
  // { label: "San Miguel", mun_code: "082614" },
  // { label: "San Roque", mun_code: "082614" },
  // { label: "Tangbo", mun_code: "082614" },
  // { label: "Taytay", mun_code: "082614" },
  // { label: "Tugas", mun_code: "082614" },
  // { label: "Camada", mun_code: "082615" },
  // { label: "Campakerit (Botay)", mun_code: "082615" },
  // { label: "Canloterio", mun_code: "082615" },
  // { label: "Del Pilar", mun_code: "082615" },
  // { label: "Guindalitan", mun_code: "082615" },
  // { label: "Lapgap", mun_code: "082615" },
  // { label: "Malobago", mun_code: "082615" },
  // { label: "Maybocog", mun_code: "082615" },
  // { label: "Maytigbao", mun_code: "082615" },
  // { label: "Omawas", mun_code: "082615" },
  // { label: "Patag", mun_code: "082615" },
  // { label: "Barangay Poblacion 1", mun_code: "082615" },
  // { label: "Barangay Poblacion 2", mun_code: "082615" },
  // { label: "Barangay Poblacion 3", mun_code: "082615" },
  // { label: "Barangay Poblacion 4", mun_code: "082615" },
  // { label: "Barangay Poblacion 5", mun_code: "082615" },
  // { label: "Barangay Poblacion 6", mun_code: "082615" },
  // { label: "Barangay Poblacion 7", mun_code: "082615" },
  // { label: "San Gabriel", mun_code: "082615" },
  // { label: "Tagaslian", mun_code: "082615" },
  // { label: "Anuron", mun_code: "082616" },
  // { label: "Banuyo", mun_code: "082616" },
  // { label: "Bobon", mun_code: "082616" },
  // { label: "Busay", mun_code: "082616" },
  // { label: "Buyayawon", mun_code: "082616" },
  // { label: "Cabunga-an", mun_code: "082616" },
  // { label: "Cambante", mun_code: "082616" },
  // { label: "Barangay 1 Poblacion", mun_code: "082616" },
  // { label: "Barangay 2 Poblacion", mun_code: "082616" },
  // { label: "Barangay 3 Poblacion", mun_code: "082616" },
  // { label: "San Jose", mun_code: "082616" },
  // { label: "Sung-an", mun_code: "082616" },
  // { label: "Palamrag (Cabiliri-an)", mun_code: "082616" },
  // { label: "Barangay 4 Poblacion", mun_code: "082616" },
  // { label: "Port Kennedy", mun_code: "082616" },
  // { label: "San Roque", mun_code: "082616" },
  // { label: "Agsam", mun_code: "082617" },
  // { label: "Bagacay", mun_code: "082617" },
  // { label: "Balingasag", mun_code: "082617" },
  // { label: "Balocawe (Pob.)", mun_code: "082617" },
  // { label: "Bantayan", mun_code: "082617" },
  // { label: "Batang", mun_code: "082617" },
  // { label: "Bato", mun_code: "082617" },
  // { label: "Binalayan", mun_code: "082617" },
  // { label: "Buntay", mun_code: "082617" },
  // { label: "Burak", mun_code: "082617" },
  // { label: "Butnga (Pob.)", mun_code: "082617" },
  // { label: "Cadian", mun_code: "082617" },
  // { label: "Cagdine", mun_code: "082617" },
  // { label: "Cagpile", mun_code: "082617" },
  // { label: "Cagtoog", mun_code: "082617" },
  // { label: "Camanga (Pob.)", mun_code: "082617" },
  // { label: "Dalid", mun_code: "082617" },
  // { label: "Dao", mun_code: "082617" },
  // { label: "Factoria", mun_code: "082617" },
  // { label: "Gamot", mun_code: "082617" },
  // { label: "Iwayan", mun_code: "082617" },
  // { label: "Japay", mun_code: "082617" },
  // { label: "Kalaw", mun_code: "082617" },
  // { label: "Mabuhay", mun_code: "082617" },
  // { label: "Malingon", mun_code: "082617" },
  // { label: "Minap-os", mun_code: "082617" },
  // { label: "Nadacpan", mun_code: "082617" },
  // { label: "Naga", mun_code: "082617" },
  // { label: "Pangudtan", mun_code: "082617" },
  // { label: "Paypayon (Pob.)", mun_code: "082617" },
  // { label: "Riverside (Pob.)", mun_code: "082617" },
  // { label: "Rizal", mun_code: "082617" },
  // { label: "Sabang", mun_code: "082617" },
  // { label: "San Eduardo", mun_code: "082617" },
  // { label: "Santa Monica", mun_code: "082617" },
  // { label: "Saugan", mun_code: "082617" },
  // { label: "Saurong", mun_code: "082617" },
  // { label: "Tawagan (Pob.)", mun_code: "082617" },
  // { label: "Tiguib (Pob.)", mun_code: "082617" },
  // { label: "Trinidad (Maycorot)", mun_code: "082617" },
  // { label: "Alang-alang", mun_code: "082617" },
  // { label: "San Roque (Pob.)", mun_code: "082617" },
  // { label: "Anislag", mun_code: "082618" },
  // { label: "Bagte", mun_code: "082618" },
  // { label: "Barangay No. 1 (Pob.)", mun_code: "082618" },
  // { label: "Barangay No. 2 (Pob.)", mun_code: "082618" },
  // { label: "Barangay No. 3 (Pob.)", mun_code: "082618" },
  // { label: "Barangay No. 4 (Pob.)", mun_code: "082618" },
  // { label: "Barangay No. 5 (Pob.)", mun_code: "082618" },
  // { label: "Buenavista", mun_code: "082618" },
  // { label: "Caculangan", mun_code: "082618" },
  // { label: "Cagdaja", mun_code: "082618" },
  // { label: "Cambilla", mun_code: "082618" },
  // { label: "Cantenio", mun_code: "082618" },
  // { label: "Naga", mun_code: "082618" },
  // { label: "Paco", mun_code: "082618" },
  // { label: "Rizal (Pana-ugan)", mun_code: "082618" },
  // { label: "San Pedro", mun_code: "082618" },
  // { label: "San Vicente", mun_code: "082618" },
  // { label: "Santa Cruz (Loro Diyo)", mun_code: "082618" },
  // { label: "Santa Margarita", mun_code: "082618" },
  // { label: "Palactad (Valley)", mun_code: "082618" },
  // { label: "Alang-alang", mun_code: "082618" },
  // { label: "Barangay No. 6 (Pob.)", mun_code: "082618" },
  // { label: "Barangay No. 7 (Pob.)", mun_code: "082618" },
  // { label: "San Isidro", mun_code: "082618" },
  // { label: "Abejao", mun_code: "082619" },
  // { label: "Alog", mun_code: "082619" },
  // { label: "Asgad", mun_code: "082619" },
  // { label: "Bagtong", mun_code: "082619" },
  // { label: "Balud", mun_code: "082619" },
  // { label: "Barangay 1 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 13 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 2 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 3 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 4 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 5 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 6 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 7 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 8 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 9 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 10 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 11 (Pob.)", mun_code: "082619" },
  // { label: "Barangay 12 (Pob.)", mun_code: "082619" },
  // { label: "Buabua", mun_code: "082619" },
  // { label: "Burak", mun_code: "082619" },
  // { label: "Butig", mun_code: "082619" },
  // { label: "Cagaut", mun_code: "082619" },
  // { label: "Camanga", mun_code: "082619" },
  // { label: "Cantomoja", mun_code: "082619" },
  // { label: "Carapdapan", mun_code: "082619" },
  // { label: "Caridad", mun_code: "082619" },
  // { label: "Casili-on", mun_code: "082619" },
  // { label: "Iberan", mun_code: "082619" },
  // { label: "Jagnaya", mun_code: "082619" },
  // { label: "Lusod", mun_code: "082619" },
  // { label: "Malbog", mun_code: "082619" },
  // { label: "Maliwaliw", mun_code: "082619" },
  // { label: "Matarinao", mun_code: "082619" },
  // { label: "Naparaan", mun_code: "082619" },
  // { label: "Seguinon", mun_code: "082619" },
  // { label: "San Roque (Bugay)", mun_code: "082619" },
  // { label: "Santa Cruz", mun_code: "082619" },
  // { label: "Tacla-on", mun_code: "082619" },
  // { label: "Tagbacan", mun_code: "082619" },
  // { label: "Palanas", mun_code: "082619" },
  // { label: "Talangdawan", mun_code: "082619" },
  // { label: "Bunacan", mun_code: "082620" },
  // { label: "Campidhan", mun_code: "082620" },
  // { label: "Casoroy", mun_code: "082620" },
  // { label: "Libas", mun_code: "082620" },
  // { label: "Nena (Luna)", mun_code: "082620" },
  // { label: "Pagbabangnan", mun_code: "082620" },
  // { label: "Barangay No. 1 Poblacion", mun_code: "082620" },
  // { label: "Barangay No. 2 Poblacion", mun_code: "082620" },
  // { label: "Barangay No. 3 Poblacion", mun_code: "082620" },
  // { label: "Barangay No. 4 Poblacion", mun_code: "082620" },
  // { label: "Barangay No. 5 Poblacion", mun_code: "082620" },
  // { label: "Barangay No. 6 Poblacion", mun_code: "082620" },
  // { label: "Putong", mun_code: "082620" },
  // { label: "San Isidro", mun_code: "082620" },
  // { label: "San Miguel", mun_code: "082620" },
  // { label: "Lunang", mun_code: "082620" },
  // { label: "Alugan", mun_code: "082621" },
  // { label: "Bahay", mun_code: "082621" },
  // { label: "Bangon", mun_code: "082621" },
  // { label: "Baras (Lipata)", mun_code: "082621" },
  // { label: "Binogawan", mun_code: "082621" },
  // { label: "Cajagwayan", mun_code: "082621" },
  // { label: "Japunan", mun_code: "082621" },
  // { label: "Natividad", mun_code: "082621" },
  // { label: "Pangpang", mun_code: "082621" },
  // { label: "Barangay No. 1 (Pob.)", mun_code: "082621" },
  // { label: "Barangay No. 2 (Pob.)", mun_code: "082621" },
  // { label: "Barangay No. 3 (Pob.)", mun_code: "082621" },
  // { label: "Barangay No. 4 (Pob.)", mun_code: "082621" },
  // { label: "Barangay No. 5 (Pob.)", mun_code: "082621" },
  // { label: "Santa Cruz", mun_code: "082621" },
  // { label: "Tabo", mun_code: "082621" },
  // { label: "Tan-awan", mun_code: "082621" },
  // { label: "A-et", mun_code: "082622" },
  // { label: "Baybay (Pob.)", mun_code: "082622" },
  // { label: "Kandalakit", mun_code: "082622" },
  // { label: "Del Remedio", mun_code: "082622" },
  // { label: "Loyola Heights (Pob.)", mun_code: "082622" },
  // { label: "Mabini", mun_code: "082622" },
  // { label: "Maglipay (Pob.)", mun_code: "082622" },
  // { label: "Maramara (Pob.)", mun_code: "082622" },
  // { label: "Riverside (Pob.)", mun_code: "082622" },
  // { label: "San Francisco", mun_code: "082622" },
  // { label: "San Isidro", mun_code: "082622" },
  // { label: "San Juan", mun_code: "082622" },
  // { label: "San Mateo", mun_code: "082622" },
  // { label: "San Vicente", mun_code: "082622" },
  // { label: "Santo Tomas", mun_code: "082622" },
  // { label: "Tabi (Pob.)", mun_code: "082622" },
  // { label: "Abucay (Pob.)", mun_code: "082622" },
  // { label: "Batiawan", mun_code: "082623" },
  // { label: "Beto", mun_code: "082623" },
  // { label: "Binaloan", mun_code: "082623" },
  // { label: "Bongdo", mun_code: "082623" },
  // { label: "Dacul", mun_code: "082623" },
  // { label: "Danao", mun_code: "082623" },
  // { label: "Del Remedios", mun_code: "082623" },
  // { label: "Gayam", mun_code: "082623" },
  // { label: "Lomatud (Burak)", mun_code: "082623" },
  // { label: "Mabuhay", mun_code: "082623" },
  // { label: "Malinao", mun_code: "082623" },
  // { label: "Mantang", mun_code: "082623" },
  // { label: "Nato", mun_code: "082623" },
  // { label: "Pangabutan", mun_code: "082623" },
  // { label: "Poblacion Barangay 1", mun_code: "082623" },
  // { label: "Poblacion Barangay 2", mun_code: "082623" },
  // { label: "Poblacion Barangay 3", mun_code: "082623" },
  // { label: "Poblacion Barangay 4", mun_code: "082623" },
  // { label: "Poblacion Barangay 5", mun_code: "082623" },
  // { label: "Poblacion Barangay 6", mun_code: "082623" },
  // { label: "Polangi", mun_code: "082623" },
  // { label: "San Luis", mun_code: "082623" },
  // { label: "San Pablo", mun_code: "082623" },
  // { label: "San Rafael", mun_code: "082623" },
  { label: "Alangilan", mun_code: "083701" },
  { label: "Anibongan", mun_code: "083701" },
  { label: "Buaya", mun_code: "083701" },
  { label: "Bagacay", mun_code: "083701" },
  { label: "Bahay", mun_code: "083701" },
  { label: "Balinsasayao", mun_code: "083701" },
  { label: "Balocawe", mun_code: "083701" },
  { label: "Balocawehay", mun_code: "083701" },
  { label: "Barayong", mun_code: "083701" },
  { label: "Bayabas", mun_code: "083701" },
  { label: "Bito (Pob.)", mun_code: "083701" },
  { label: "Buenavista", mun_code: "083701" },
  { label: "Bulak", mun_code: "083701" },
  { label: "Bunga", mun_code: "083701" },
  { label: "Buntay (Pob.)", mun_code: "083701" },
  { label: "Burubud-an", mun_code: "083701" },
  { label: "Cagbolo", mun_code: "083701" },
  { label: "Can-uguib (Pob.)", mun_code: "083701" },
  { label: "Can-aporong", mun_code: "083701" },
  { label: "Canmarating", mun_code: "083701" },
  { label: "Capilian", mun_code: "083701" },
  { label: "Cadac-an", mun_code: "083701" },
  { label: "Combis", mun_code: "083701" },
  { label: "Dingle", mun_code: "083701" },
  { label: "Guintagbucan (Pob.)", mun_code: "083701" },
  { label: "Hampipila", mun_code: "083701" },
  { label: "Katipunan", mun_code: "083701" },
  { label: "Kikilo", mun_code: "083701" },
  { label: "Laray", mun_code: "083701" },
  { label: "Lawa-an", mun_code: "083701" },
  { label: "Libertad", mun_code: "083701" },
  { label: "Loyonsawang (Pob.)", mun_code: "083701" },
  { label: "Mahagna (New Cagbolo)", mun_code: "083701" },
  { label: "Mag-atubang", mun_code: "083701" },
  { label: "Mahayahay", mun_code: "083701" },
  { label: "Maitum", mun_code: "083701" },
  { label: "Malaguicay", mun_code: "083701" },
  { label: "Matagnao", mun_code: "083701" },
  { label: "Nalibunan (Pob.)", mun_code: "083701" },
  { label: "Nebga", mun_code: "083701" },
  { label: "Odiongan", mun_code: "083701" },
  { label: "Pagsang-an", mun_code: "083701" },
  { label: "Paguite", mun_code: "083701" },
  { label: "Parasanon", mun_code: "083701" },
  { label: "Picas Sur", mun_code: "083701" },
  { label: "Pilar", mun_code: "083701" },
  { label: "Pinamanagan", mun_code: "083701" },
  { label: "Salvacion", mun_code: "083701" },
  { label: "San Francisco", mun_code: "083701" },
  { label: "San Isidro", mun_code: "083701" },
  { label: "San Roque", mun_code: "083701" },
  { label: "Santa Fe (Pob.)", mun_code: "083701" },
  { label: "Santa Lucia (Pob.)", mun_code: "083701" },
  { label: "Tabigue", mun_code: "083701" },
  { label: "Tadoc", mun_code: "083701" },
  { label: "New Taligue", mun_code: "083701" },
  { label: "Old Taligue", mun_code: "083701" },
  { label: "Tib-o", mun_code: "083701" },
  { label: "Tinalian", mun_code: "083701" },
  { label: "Tinocolan", mun_code: "083701" },
  { label: "Tuy-a", mun_code: "083701" },
  { label: "Victory (Pob.)", mun_code: "083701" },
  { label: "Aslum", mun_code: "083702" },
  { label: "Astorga (Burabod)", mun_code: "083702" },
  { label: "Bato", mun_code: "083702" },
  { label: "Binongto-an", mun_code: "083702" },
  { label: "Binotong", mun_code: "083702" },
  { label: "Bobonon", mun_code: "083702" },
  { label: "Borseth", mun_code: "083702" },
  { label: "Buenavista", mun_code: "083702" },
  { label: "Bugho", mun_code: "083702" },
  { label: "Buri", mun_code: "083702" },
  { label: "Cabadsan", mun_code: "083702" },
  { label: "Calaasan", mun_code: "083702" },
  { label: "Cambahanon", mun_code: "083702" },
  { label: "Cambolao", mun_code: "083702" },
  { label: "Canvertudes", mun_code: "083702" },
  { label: "Capiz", mun_code: "083702" },
  { label: "Cavite", mun_code: "083702" },
  { label: "Cogon", mun_code: "083702" },
  { label: "Dapdap", mun_code: "083702" },
  { label: "Divisoria", mun_code: "083702" },
  { label: "Ekiran", mun_code: "083702" },
  { label: "Hinapolan", mun_code: "083702" },
  { label: "Hubang", mun_code: "083702" },
  { label: "Hupit", mun_code: "083702" },
  { label: "Langit", mun_code: "083702" },
  { label: "Lingayon", mun_code: "083702" },
  { label: "Lourdes", mun_code: "083702" },
  { label: "Lukay", mun_code: "083702" },
  { label: "Magsaysay", mun_code: "083702" },
  { label: "Mudboron", mun_code: "083702" },
  { label: "P. Barrantes", mun_code: "083702" },
  { label: "Pepita", mun_code: "083702" },
  { label: "Salvacion Poblacion", mun_code: "083702" },
  { label: "San Antonio", mun_code: "083702" },
  { label: "San Diego", mun_code: "083702" },
  { label: "San Francisco East", mun_code: "083702" },
  { label: "San Francisco West", mun_code: "083702" },
  { label: "San Isidro", mun_code: "083702" },
  { label: "San Pedro", mun_code: "083702" },
  { label: "San Vicente", mun_code: "083702" },
  { label: "Santiago", mun_code: "083702" },
  { label: "Santol", mun_code: "083702" },
  { label: "Tabangohay", mun_code: "083702" },
  { label: "Tombo", mun_code: "083702" },
  { label: "Veteranos", mun_code: "083702" },
  { label: "Blumentritt (Pob.)", mun_code: "083702" },
  { label: "Holy Child I (Pob.)", mun_code: "083702" },
  { label: "Holy Child II (Pob.)", mun_code: "083702" },
  { label: "Milagrosa (Pob.)", mun_code: "083702" },
  { label: "San Antonio Pob. (Patong Norte)", mun_code: "083702" },
  { label: "San Roque (Pob.)", mun_code: "083702" },
  { label: "Salvacion", mun_code: "083702" },
  { label: "Antipolo", mun_code: "083703" },
  { label: "Balugo", mun_code: "083703" },
  { label: "Benolho", mun_code: "083703" },
  { label: "Cambalading", mun_code: "083703" },
  { label: "Damula-an", mun_code: "083703" },
  { label: "Mahayag", mun_code: "083703" },
  { label: "Mahayahay", mun_code: "083703" },
  { label: "Poblacion", mun_code: "083703" },
  { label: "Salvacion", mun_code: "083703" },
  { label: "San Pedro", mun_code: "083703" },
  { label: "Seguinon", mun_code: "083703" },
  { label: "Sherwood", mun_code: "083703" },
  { label: "Tabgas", mun_code: "083703" },
  { label: "Talisayan", mun_code: "083703" },
  { label: "Tinag-an", mun_code: "083703" },
  { label: "Biasong", mun_code: "083705" },
  { label: "Gov. E. Jaro (Bagahupi)", mun_code: "083705" },
  { label: "Malibago", mun_code: "083705" },
  { label: "Magcasuang", mun_code: "083705" },
  { label: "Planza", mun_code: "083705" },
  { label: "Poblacion District I", mun_code: "083705" },
  { label: "Poblacion District II", mun_code: "083705" },
  { label: "Poblacion District III", mun_code: "083705" },
  { label: "Poblacion District IV", mun_code: "083705" },
  { label: "Rizal I", mun_code: "083705" },
  { label: "San Ricardo", mun_code: "083705" },
  { label: "Sangputan", mun_code: "083705" },
  { label: "Pagsulhugon", mun_code: "083705" },
  { label: "Taguite", mun_code: "083705" },
  { label: "Uban", mun_code: "083705" },
  { label: "Victory", mun_code: "083705" },
  { label: "Villa Magsaysay", mun_code: "083705" },
  { label: "Bacong", mun_code: "083705" },
  { label: "Bagong Silang", mun_code: "083705" },
  { label: "Lukay", mun_code: "083705" },
  { label: "Rizal II", mun_code: "083705" },
  { label: "San Agustin", mun_code: "083705" },
  { label: "Guintigui-an", mun_code: "083705" },
  { label: "Naga-asan", mun_code: "083705" },
  { label: "San Isidro", mun_code: "083705" },
  { label: "Abango", mun_code: "083706" },
  { label: "Amahit", mun_code: "083706" },
  { label: "Balire", mun_code: "083706" },
  { label: "Balud", mun_code: "083706" },
  { label: "Bukid", mun_code: "083706" },
  { label: "Bulod", mun_code: "083706" },
  { label: "Busay", mun_code: "083706" },
  { label: "Cabolo-an", mun_code: "083706" },
  { label: "Calingcaguing", mun_code: "083706" },
  { label: "Can-isak", mun_code: "083706" },
  { label: "Canomantag", mun_code: "083706" },
  { label: "Duka", mun_code: "083706" },
  { label: "Guindaohan", mun_code: "083706" },
  { label: "Hiagsam", mun_code: "083706" },
  { label: "Hilaba", mun_code: "083706" },
  { label: "Hinugayan", mun_code: "083706" },
  { label: "Ibag", mun_code: "083706" },
  { label: "Minuhang", mun_code: "083706" },
  { label: "Minuswang", mun_code: "083706" },
  { label: "Pikas", mun_code: "083706" },
  { label: "Pitogo", mun_code: "083706" },
  { label: "Poblacion Dist. I", mun_code: "083706" },
  { label: "Poblacion Dist. II", mun_code: "083706" },
  { label: "Poblacion Dist. III", mun_code: "083706" },
  { label: "Poblacion Dist. IV", mun_code: "083706" },
  { label: "Poblacion Dist. V", mun_code: "083706" },
  { label: "Poblacion Dist. VI (New Road)", mun_code: "083706" },
  { label: "Pongso", mun_code: "083706" },
  { label: "Roosevelt", mun_code: "083706" },
  { label: "San Isidro", mun_code: "083706" },
  { label: "Santa Rosa", mun_code: "083706" },
  { label: "Santarin", mun_code: "083706" },
  { label: "Tutug-an", mun_code: "083706" },
  { label: "Cabarasan", mun_code: "083706" },
  { label: "Cuta", mun_code: "083706" },
  { label: "Domogdog", mun_code: "083706" },
  { label: "San Roque", mun_code: "083706" },
  { label: "Alegria", mun_code: "083707" },
  { label: "Alejos", mun_code: "083707" },
  { label: "Amagos", mun_code: "083707" },
  { label: "Anahawan", mun_code: "083707" },
  { label: "Bago", mun_code: "083707" },
  { label: "Bagong Bayan District (Pob.)", mun_code: "083707" },
  { label: "Buli", mun_code: "083707" },
  { label: "Cebuana", mun_code: "083707" },
  { label: "Daan Lungsod", mun_code: "083707" },
  { label: "Dawahon", mun_code: "083707" },
  { label: "Himamaa", mun_code: "083707" },
  { label: "Dolho", mun_code: "083707" },
  { label: "Domagocdoc", mun_code: "083707" },
  { label: "Guerrero District (Pob.)", mun_code: "083707" },
  { label: "Iniguihan District (Pob.)", mun_code: "083707" },
  { label: "Katipunan", mun_code: "083707" },
  { label: "Liberty (Binaliw)", mun_code: "083707" },
  { label: "Mabini", mun_code: "083707" },
  { label: "Naga", mun_code: "083707" },
  { label: "Plaridel", mun_code: "083707" },
  { label: "Kalanggaman District (Pob.)", mun_code: "083707" },
  { label: "Ponong", mun_code: "083707" },
  { label: "San Agustin", mun_code: "083707" },
  { label: "Tabunok", mun_code: "083707" },
  { label: "Tagaytay", mun_code: "083707" },
  { label: "Tinago District (Pob.)", mun_code: "083707" },
  { label: "Tugas", mun_code: "083707" },
  { label: "Imelda", mun_code: "083707" },
  { label: "Marcelo", mun_code: "083707" },
  { label: "Rivilla", mun_code: "083707" },
  { label: "Altavista", mun_code: "083708" },
  { label: "Ambacan", mun_code: "083708" },
  { label: "Amguhan", mun_code: "083708" },
  { label: "Ampihanon", mun_code: "083708" },
  { label: "Balao", mun_code: "083708" },
  { label: "Banahao", mun_code: "083708" },
  { label: "Biasong", mun_code: "083708" },
  { label: "Bidlinan", mun_code: "083708" },
  { label: "Bitanhuan", mun_code: "083708" },
  { label: "Bubon", mun_code: "083708" },
  { label: "Buenavista", mun_code: "083708" },
  { label: "Bunga", mun_code: "083708" },
  { label: "Butigan", mun_code: "083708" },
  { label: "Kabatuan", mun_code: "083708" },
  { label: "Caridad", mun_code: "083708" },
  { label: "Ga-as", mun_code: "083708" },
  { label: "Gabas", mun_code: "083708" },
  { label: "Gakat", mun_code: "083708" },
  { label: "Guadalupe (Utod)", mun_code: "083708" },
  { label: "Gubang", mun_code: "083708" },
  { label: "Hibunawan", mun_code: "083708" },
  { label: "Higuloan", mun_code: "083708" },
  { label: "Hilapnitan", mun_code: "083708" },
  { label: "Hipusngo", mun_code: "083708" },
  { label: "Igang", mun_code: "083708" },
  { label: "Imelda", mun_code: "083708" },
  { label: "Jaena", mun_code: "083708" },
  { label: "Kabalasan", mun_code: "083708" },
  { label: "Kabungaan", mun_code: "083708" },
  { label: "Kagumay", mun_code: "083708" },
  { label: "Kambonggan", mun_code: "083708" },
  { label: "Candadam", mun_code: "083708" },
  { label: "Kan-ipa", mun_code: "083708" },
  { label: "Kansungka", mun_code: "083708" },
  { label: "Kantagnos", mun_code: "083708" },
  { label: "Kilim", mun_code: "083708" },
  { label: "Lintaon", mun_code: "083708" },
  { label: "Maganhan", mun_code: "083708" },
  { label: "Mahayahay", mun_code: "083708" },
  { label: "Mailhi", mun_code: "083708" },
  { label: "Maitum", mun_code: "083708" },
  { label: "Makinhas", mun_code: "083708" },
  { label: "Mapgap", mun_code: "083708" },
  { label: "Marcos", mun_code: "083708" },
  { label: "Maslug", mun_code: "083708" },
  { label: "Matam-is", mun_code: "083708" },
  { label: "Maybog", mun_code: "083708" },
  { label: "Maypatag", mun_code: "083708" },
  { label: "Monterico", mun_code: "083708" },
  { label: "Palhi", mun_code: "083708" },
  { label: "Pangasungan", mun_code: "083708" },
  { label: "Pansagan", mun_code: "083708" },
  { label: "Patag", mun_code: "083708" },
  { label: "Plaridel", mun_code: "083708" },
  { label: "Poblacion Zone 2", mun_code: "083708" },
  { label: "Poblacion Zone 3", mun_code: "083708" },
  { label: "Poblacion Zone 4", mun_code: "083708" },
  { label: "Poblacion Zone 5", mun_code: "083708" },
  { label: "Poblacion Zone 6", mun_code: "083708" },
  { label: "Poblacion Zone 7", mun_code: "083708" },
  { label: "Poblacion Zone 8", mun_code: "083708" },
  { label: "Poblacion Zone 9", mun_code: "083708" },
  { label: "Poblacion Zone 10", mun_code: "083708" },
  { label: "Poblacion Zone 11", mun_code: "083708" },
  { label: "Poblacion Zone 12", mun_code: "083708" },
  { label: "Poblacion Zone 13", mun_code: "083708" },
  { label: "Poblacion Zone 14", mun_code: "083708" },
  { label: "Poblacion Zone 15", mun_code: "083708" },
  { label: "Poblacion Zone 16", mun_code: "083708" },
  { label: "Poblacion Zone 17", mun_code: "083708" },
  { label: "Poblacion Zone 18", mun_code: "083708" },
  { label: "Poblacion Zone 19", mun_code: "083708" },
  { label: "Poblacion Zone 20", mun_code: "083708" },
  { label: "Poblacion Zone 21", mun_code: "083708" },
  { label: "Poblacion Zone 22", mun_code: "083708" },
  { label: "Poblacion Zone 23", mun_code: "083708" },
  { label: "Pomponan", mun_code: "083708" },
  { label: "Punta", mun_code: "083708" },
  { label: "Sabang", mun_code: "083708" },
  { label: "San Agustin", mun_code: "083708" },
  { label: "San Isidro", mun_code: "083708" },
  { label: "San Juan", mun_code: "083708" },
  { label: "Santa Cruz", mun_code: "083708" },
  { label: "Santo Rosario", mun_code: "083708" },
  { label: "Sapa", mun_code: "083708" },
  { label: "Ciabo", mun_code: "083708" },
  { label: "Villa Solidaridad", mun_code: "083708" },
  { label: "Zacarito", mun_code: "083708" },
  { label: "Poblacion Zone 1", mun_code: "083708" },
  { label: "Cogon", mun_code: "083708" },
  { label: "Monte Verde", mun_code: "083708" },
  { label: "Villa Mag-aso", mun_code: "083708" },
  { label: "Abuyogon", mun_code: "083710" },
  { label: "Anonang", mun_code: "083710" },
  { label: "Arado", mun_code: "083710" },
  { label: "Balao", mun_code: "083710" },
  { label: "Balatson", mun_code: "083710" },
  { label: "Balorinay", mun_code: "083710" },
  { label: "Bobon", mun_code: "083710" },
  { label: "Buenavista", mun_code: "083710" },
  { label: "Buri", mun_code: "083710" },
  { label: "Caanislagan", mun_code: "083710" },
  { label: "Cadahunan", mun_code: "083710" },
  { label: "Cagangon", mun_code: "083710" },
  { label: "Cali", mun_code: "083710" },
  { label: "Calsadahay", mun_code: "083710" },
  { label: "Candag-on", mun_code: "083710" },
  { label: "Cansiboy", mun_code: "083710" },
  { label: "Catagbacan", mun_code: "083710" },
  { label: "Poblacion District I", mun_code: "083710" },
  { label: "Poblacion District II", mun_code: "083710" },
  { label: "Poblacion District III", mun_code: "083710" },
  { label: "Poblacion District IV", mun_code: "083710" },
  { label: "Poblacion District V", mun_code: "083710" },
  { label: "Poblacion District VI", mun_code: "083710" },
  { label: "Poblacion District VII", mun_code: "083710" },
  { label: "Poblacion District VIII", mun_code: "083710" },
  { label: "Poblacion District IX", mun_code: "083710" },
  { label: "Dumalag (Pusod)", mun_code: "083710" },
  { label: "Ilihan", mun_code: "083710" },
  { label: "Esperanza", mun_code: "083710" },
  { label: "Gitablan", mun_code: "083710" },
  { label: "Hapunan", mun_code: "083710" },
  { label: "Hibonawan", mun_code: "083710" },
  { label: "Hugpa East", mun_code: "083710" },
  { label: "Hugpa West", mun_code: "083710" },
  { label: "Kalao", mun_code: "083710" },
  { label: "Kaparasanan", mun_code: "083710" },
  { label: "Laguiwan", mun_code: "083710" },
  { label: "Libas", mun_code: "083710" },
  { label: "Limburan", mun_code: "083710" },
  { label: "Logsongan", mun_code: "083710" },
  { label: "Maabab", mun_code: "083710" },
  { label: "Maghubas", mun_code: "083710" },
  { label: "Mahagnao", mun_code: "083710" },
  { label: "Malabca", mun_code: "083710" },
  { label: "Malaguinabot", mun_code: "083710" },
  { label: "Malaihao (San Ramon)", mun_code: "083710" },
  { label: "Matin-ao", mun_code: "083710" },
  { label: "Moguing", mun_code: "083710" },
  { label: "Paghudlan", mun_code: "083710" },
  { label: "Paitan", mun_code: "083710" },
  { label: "Pangdan", mun_code: "083710" },
  { label: "Patag", mun_code: "083710" },
  { label: "Patong", mun_code: "083710" },
  { label: "Pawa", mun_code: "083710" },
  { label: "Roxas", mun_code: "083710" },
  { label: "Sambel", mun_code: "083710" },
  { label: "San Esteban", mun_code: "083710" },
  { label: "San Fernando", mun_code: "083710" },
  { label: "San Jose East", mun_code: "083710" },
  { label: "San Jose West", mun_code: "083710" },
  { label: "San Pablo", mun_code: "083710" },
  { label: "Tabuanon", mun_code: "083710" },
  { label: "Tagadtaran", mun_code: "083710" },
  { label: "Taghuyan", mun_code: "083710" },
  { label: "Takin", mun_code: "083710" },
  { label: "Tambis (Naboya)", mun_code: "083710" },
  { label: "Toloyao", mun_code: "083710" },
  { label: "Villa Aurora", mun_code: "083710" },
  { label: "Villa Corazon", mun_code: "083710" },
  { label: "Villa Patria", mun_code: "083710" },
  { label: "Villa Rosas (Cabang)", mun_code: "083710" },
  { label: "Kagbana", mun_code: "083710" },
  { label: "Damulo-an", mun_code: "083710" },
  { label: "Dina-ayan", mun_code: "083710" },
  { label: "Gamay", mun_code: "083710" },
  { label: "Kalipayan", mun_code: "083710" },
  { label: "Tambuko", mun_code: "083710" },
  { label: "Abanilla", mun_code: "083713" },
  { label: "Agas", mun_code: "083713" },
  { label: "Anislagan", mun_code: "083713" },
  { label: "Bunacan", mun_code: "083713" },
  { label: "Cabalquinto", mun_code: "083713" },
  { label: "Cabalhin", mun_code: "083713" },
  { label: "Cabradilla", mun_code: "083713" },
  { label: "Caneja", mun_code: "083713" },
  { label: "Cantonghao", mun_code: "083713" },
  { label: "Caroyocan", mun_code: "083713" },
  { label: "Casiongan", mun_code: "083713" },
  { label: "Cristina", mun_code: "083713" },
  { label: "Dalumpines", mun_code: "083713" },
  { label: "Don Luis", mun_code: "083713" },
  { label: "Dulao", mun_code: "083713" },
  { label: "Efe", mun_code: "083713" },
  { label: "Enage", mun_code: "083713" },
  { label: "Espinosa", mun_code: "083713" },
  { label: "Garganera", mun_code: "083713" },
  { label: "Ferdinand E. Marcos", mun_code: "083713" },
  { label: "Garrido", mun_code: "083713" },
  { label: "Guadalupe (Guadalupe Mendoza)", mun_code: "083713" },
  { label: "Gutosan", mun_code: "083713" },
  { label: "Igang", mun_code: "083713" },
  { label: "Inalad", mun_code: "083713" },
  { label: "Jubay", mun_code: "083713" },
  { label: "Juson", mun_code: "083713" },
  { label: "Kawayan Bogtong", mun_code: "083713" },
  { label: "Kawayanan", mun_code: "083713" },
  { label: "Kokoy Romualdez", mun_code: "083713" },
  { label: "Labtic", mun_code: "083713" },
  { label: "Laray", mun_code: "083713" },
  { label: "M. Veloso", mun_code: "083713" },
  { label: "Mahait", mun_code: "083713" },
  { label: "Malobago", mun_code: "083713" },
  { label: "Matagok", mun_code: "083713" },
  { label: "Nipa", mun_code: "083713" },
  { label: "Obispo", mun_code: "083713" },
  { label: "Pagatpat", mun_code: "083713" },
  { label: "Pangpang", mun_code: "083713" },
  { label: "Patag", mun_code: "083713" },
  { label: "Pates", mun_code: "083713" },
  { label: "Paula", mun_code: "083713" },
  { label: "Padoga", mun_code: "083713" },
  { label: "Petrolio", mun_code: "083713" },
  { label: "Poblacion", mun_code: "083713" },
  { label: "Railes", mun_code: "083713" },
  { label: "Tabla", mun_code: "083713" },
  { label: "Tagharigue", mun_code: "083713" },
  { label: "Tuburan", mun_code: "083713" },
  { label: "Villahermosa", mun_code: "083713" },
  { label: "Villalon", mun_code: "083713" },
  { label: "Villanueva", mun_code: "083713" },
  { label: "Balucanad", mun_code: "083714" },
  { label: "Balud", mun_code: "083714" },
  { label: "Balugo", mun_code: "083714" },
  { label: "Cabul-an", mun_code: "083714" },
  { label: "Gayad", mun_code: "083714" },
  { label: "Culasian", mun_code: "083714" },
  { label: "Guinadiongan", mun_code: "083714" },
  { label: "Lemon", mun_code: "083714" },
  { label: "Libertad", mun_code: "083714" },
  { label: "Manloy", mun_code: "083714" },
  { label: "Nauguisan", mun_code: "083714" },
  { label: "Pinamopoan", mun_code: "083714" },
  { label: "Poblacion Zone I", mun_code: "083714" },
  { label: "Poblacion Zone II", mun_code: "083714" },
  { label: "Potot", mun_code: "083714" },
  { label: "San Joaquin", mun_code: "083714" },
  { label: "Talairan", mun_code: "083714" },
  { label: "Talisay", mun_code: "083714" },
  { label: "Tolibao", mun_code: "083714" },
  { label: "Visares", mun_code: "083714" },
  { label: "Balilit", mun_code: "083715" },
  { label: "Barayong", mun_code: "083715" },
  { label: "Barugohay Central", mun_code: "083715" },
  { label: "Barugohay Norte", mun_code: "083715" },
  { label: "Barugohay Sur", mun_code: "083715" },
  { label: "Baybay (Pob.)", mun_code: "083715" },
  { label: "Binibihan", mun_code: "083715" },
  { label: "Bislig", mun_code: "083715" },
  { label: "Caghalo", mun_code: "083715" },
  { label: "Camansi", mun_code: "083715" },
  { label: "Canal", mun_code: "083715" },
  { label: "Candigahub", mun_code: "083715" },
  { label: "Canlampay", mun_code: "083715" },
  { label: "Cogon", mun_code: "083715" },
  { label: "Cutay", mun_code: "083715" },
  { label: "East Visoria", mun_code: "083715" },
  { label: "Guindapunan East", mun_code: "083715" },
  { label: "Guindapunan West", mun_code: "083715" },
  { label: "Hiluctogan", mun_code: "083715" },
  { label: "Jugaban (Pob.)", mun_code: "083715" },
  { label: "Libo", mun_code: "083715" },
  { label: "Lower Hiraan", mun_code: "083715" },
  { label: "Lower Sogod", mun_code: "083715" },
  { label: "Macalpi", mun_code: "083715" },
  { label: "Manloy", mun_code: "083715" },
  { label: "Nauguisan", mun_code: "083715" },
  { label: "Pangna", mun_code: "083715" },
  { label: "Parag-um", mun_code: "083715" },
  { label: "Parina", mun_code: "083715" },
  { label: "Piloro", mun_code: "083715" },
  { label: "Ponong (Pob.)", mun_code: "083715" },
  { label: "Sagkahan", mun_code: "083715" },
  { label: "San Mateo (Pob.)", mun_code: "083715" },
  { label: "Santa Fe", mun_code: "083715" },
  { label: "Sawang (Pob.)", mun_code: "083715" },
  { label: "Tagak", mun_code: "083715" },
  { label: "Tangnan", mun_code: "083715" },
  { label: "Tigbao", mun_code: "083715" },
  { label: "Tinaguban", mun_code: "083715" },
  { label: "Upper Hiraan", mun_code: "083715" },
  { label: "Upper Sogod", mun_code: "083715" },
  { label: "Uyawan", mun_code: "083715" },
  { label: "West Visoria", mun_code: "083715" },
  { label: "Paglaum", mun_code: "083715" },
  { label: "San Juan", mun_code: "083715" },
  { label: "Bagong Lipunan", mun_code: "083715" },
  { label: "Canfabi", mun_code: "083715" },
  { label: "Rizal (Tagak East)", mun_code: "083715" },
  { label: "San Isidro", mun_code: "083715" },
  { label: "Abaca", mun_code: "083717" },
  { label: "Abre", mun_code: "083717" },
  { label: "Balilit", mun_code: "083717" },
  { label: "Banayon", mun_code: "083717" },
  { label: "Bayabas", mun_code: "083717" },
  { label: "Bolirao", mun_code: "083717" },
  { label: "Buenavista", mun_code: "083717" },
  { label: "Buntay", mun_code: "083717" },
  { label: "Caanislagan", mun_code: "083717" },
  { label: "Cabariwan", mun_code: "083717" },
  { label: "Cabuloran", mun_code: "083717" },
  { label: "Cabunga-an", mun_code: "083717" },
  { label: "Calipayan", mun_code: "083717" },
  { label: "Calsadahay", mun_code: "083717" },
  { label: "Caluctogan", mun_code: "083717" },
  { label: "Calutan", mun_code: "083717" },
  { label: "Camono-an", mun_code: "083717" },
  { label: "Candagara", mun_code: "083717" },
  { label: "Canlingga", mun_code: "083717" },
  { label: "Cansamada East", mun_code: "083717" },
  { label: "Digahongan", mun_code: "083717" },
  { label: "Guinarona", mun_code: "083717" },
  { label: "Hiabangan", mun_code: "083717" },
  { label: "Hilabago", mun_code: "083717" },
  { label: "Hinabuyan", mun_code: "083717" },
  { label: "Hinologan", mun_code: "083717" },
  { label: "Hitumnog", mun_code: "083717" },
  { label: "Katipunan", mun_code: "083717" },
  { label: "Los Martires", mun_code: "083717" },
  { label: "Lobe-lobe", mun_code: "083717" },
  { label: "Macaalang", mun_code: "083717" },
  { label: "Maliwaliw", mun_code: "083717" },
  { label: "Maragondong", mun_code: "083717" },
  { label: "Ormocay", mun_code: "083717" },
  { label: "Palacio", mun_code: "083717" },
  { label: "Panda", mun_code: "083717" },
  { label: "Patoc", mun_code: "083717" },
  { label: "Plaridel", mun_code: "083717" },
  { label: "Sampao West Pob. (Dist. 8)", mun_code: "083717" },
  { label: "Lapu-lapu Pob. (Dist. 2)", mun_code: "083717" },
  { label: "Lusad Pob. (Dist. 6)", mun_code: "083717" },
  { label: "Sampao East Pob. (Dist. 9)", mun_code: "083717" },
  { label: "San Antonio Pob. (Dist. 5)", mun_code: "083717" },
  { label: "San Jose Pob. (Dist. 1)", mun_code: "083717" },
  { label: "Sta. Mesa Pob. (Dist. 7)", mun_code: "083717" },
  { label: "Tunga Pob. (Dist. 4)", mun_code: "083717" },
  { label: "San Roque Pob. (Dist. 3)", mun_code: "083717" },
  { label: "Poponton", mun_code: "083717" },
  { label: "Rizal", mun_code: "083717" },
  { label: "Salvacion", mun_code: "083717" },
  { label: "San Benito", mun_code: "083717" },
  { label: "Santo Domingo", mun_code: "083717" },
  { label: "Sirab", mun_code: "083717" },
  { label: "Tagkip", mun_code: "083717" },
  { label: "Tin-ao", mun_code: "083717" },
  { label: "Victoria", mun_code: "083717" },
  { label: "Balugo", mun_code: "083717" },
  { label: "Cansamada West", mun_code: "083717" },
  { label: "Capulhan", mun_code: "083717" },
  { label: "Lobe-lobe East", mun_code: "083717" },
  { label: "Paraiso", mun_code: "083717" },
  { label: "Sampaguita", mun_code: "083717" },
  { label: "Sawahon", mun_code: "083717" },
  { label: "Talinhugon", mun_code: "083717" },
  { label: "Tuya", mun_code: "083717" },
  { label: "Alegre", mun_code: "083718" },
  { label: "Arado", mun_code: "083718" },
  { label: "Bulod", mun_code: "083718" },
  { label: "Batug", mun_code: "083718" },
  { label: "Bolongtohan", mun_code: "083718" },
  { label: "Cabacungan", mun_code: "083718" },
  { label: "Cabarasan", mun_code: "083718" },
  { label: "Cabato-an", mun_code: "083718" },
  { label: "Calipayan", mun_code: "083718" },
  { label: "Calubian", mun_code: "083718" },
  { label: "Camitoc", mun_code: "083718" },
  { label: "Camote", mun_code: "083718" },
  { label: "Dacay", mun_code: "083718" },
  { label: "Del Carmen", mun_code: "083718" },
  { label: "Del Pilar", mun_code: "083718" },
  { label: "Fatima", mun_code: "083718" },
  { label: "General Roxas", mun_code: "083718" },
  { label: "Luan", mun_code: "083718" },
  { label: "Magsaysay", mun_code: "083718" },
  { label: "Maricum", mun_code: "083718" },
  { label: "Barbo (Pob.)", mun_code: "083718" },
  { label: "Buntay (Pob.)", mun_code: "083718" },
  { label: "Cambula District (Pob.)", mun_code: "083718" },
  { label: "Candao (Pob.)", mun_code: "083718" },
  { label: "Catmonan (Pob.)", mun_code: "083718" },
  { label: "Combis (Pob.)", mun_code: "083718" },
  { label: "Highway (Pob.)", mun_code: "083718" },
  { label: "Market Site (Pob.)", mun_code: "083718" },
  { label: "San Miguel (Pob.)", mun_code: "083718" },
  { label: "Serrano (Pob.)", mun_code: "083718" },
  { label: "Sungi (Pob.)", mun_code: "083718" },
  { label: "Rawis", mun_code: "083718" },
  { label: "Rizal", mun_code: "083718" },
  { label: "Romualdez", mun_code: "083718" },
  { label: "Sabang Daguitan", mun_code: "083718" },
  { label: "Salvacion", mun_code: "083718" },
  { label: "San Agustin", mun_code: "083718" },
  { label: "San Antonio", mun_code: "083718" },
  { label: "San Isidro", mun_code: "083718" },
  { label: "San Jose", mun_code: "083718" },
  { label: "San Rafael", mun_code: "083718" },
  { label: "San Vicente", mun_code: "083718" },
  { label: "Tabu", mun_code: "083718" },
  { label: "Tigbao", mun_code: "083718" },
  { label: "Victory", mun_code: "083718" },
  { label: "Agutayan", mun_code: "083719" },
  { label: "Atabay", mun_code: "083719" },
  { label: "Baas", mun_code: "083719" },
  { label: "Bagumbayan", mun_code: "083719" },
  { label: "Baliw", mun_code: "083719" },
  { label: "Bantigue", mun_code: "083719" },
  { label: "Bung-aw", mun_code: "083719" },
  { label: "Cacao", mun_code: "083719" },
  { label: "Campina", mun_code: "083719" },
  { label: "Catandog 1", mun_code: "083719" },
  { label: "Catandog 2", mun_code: "083719" },
  { label: "Concepcion", mun_code: "083719" },
  { label: "Himo-aw", mun_code: "083719" },
  { label: "Hitudpan", mun_code: "083719" },
  { label: "Imelda Marcos (Pong-on)", mun_code: "083719" },
  { label: "Kang-iras", mun_code: "083719" },
  { label: "Lamak", mun_code: "083719" },
  { label: "Libertad", mun_code: "083719" },
  { label: "Liberty", mun_code: "083719" },
  { label: "Lunang", mun_code: "083719" },
  { label: "Magnangoy", mun_code: "083719" },
  { label: "Marangog", mun_code: "083719" },
  { label: "Matapay", mun_code: "083719" },
  { label: "Naval", mun_code: "083719" },
  { label: "Owak", mun_code: "083719" },
  { label: "Pa-a", mun_code: "083719" },
  { label: "Central Barangay (Pob.)", mun_code: "083719" },
  { label: "Eastern Barangay (Pob.)", mun_code: "083719" },
  { label: "Western Barangay (Pob.)", mun_code: "083719" },
  { label: "Pontod", mun_code: "083719" },
  { label: "Proteccion", mun_code: "083719" },
  { label: "San Agustin", mun_code: "083719" },
  { label: "San Antonio", mun_code: "083719" },
  { label: "San Isidro", mun_code: "083719" },
  { label: "San Juan", mun_code: "083719" },
  { label: "San Roque", mun_code: "083719" },
  { label: "Santa Cruz", mun_code: "083719" },
  { label: "Santa Margarita", mun_code: "083719" },
  { label: "Tabunok", mun_code: "083719" },
  { label: "Tagnate", mun_code: "083719" },
  { label: "Talisay", mun_code: "083719" },
  { label: "Tambis", mun_code: "083719" },
  { label: "Tejero", mun_code: "083719" },
  { label: "Tuguipa", mun_code: "083719" },
  { label: "Utanan", mun_code: "083719" },
  { label: "Bagong Lipunan", mun_code: "083719" },
  { label: "Bon-ot", mun_code: "083719" },
  { label: "Hampangan", mun_code: "083719" },
  { label: "Kangha-as", mun_code: "083719" },
  { label: "Manaul", mun_code: "083719" },
  { label: "Anahaw", mun_code: "083720" },
  { label: "Anolon", mun_code: "083720" },
  { label: "Baldoza", mun_code: "083720" },
  { label: "Bontoc", mun_code: "083720" },
  { label: "Bulacan", mun_code: "083720" },
  { label: "Canha-ayon", mun_code: "083720" },
  { label: "Capudlosan", mun_code: "083720" },
  { label: "Himacugo", mun_code: "083720" },
  { label: "Doos Del Norte", mun_code: "083720" },
  { label: "Doos Del Sur", mun_code: "083720" },
  { label: "Himokilan Island", mun_code: "083720" },
  { label: "Katipunan", mun_code: "083720" },
  { label: "Maasin", mun_code: "083720" },
  { label: "Mabagon", mun_code: "083720" },
  { label: "Mahilum", mun_code: "083720" },
  { label: "Poblacion 1", mun_code: "083720" },
  { label: "Poblacion 2", mun_code: "083720" },
  { label: "San Vicente", mun_code: "083720" },
  { label: "Tabok", mun_code: "083720" },
  { label: "Tagbibi", mun_code: "083720" },
  { label: "Apid", mun_code: "083721" },
  { label: "Cabulisan", mun_code: "083721" },
  { label: "Caminto", mun_code: "083721" },
  { label: "Can-angay", mun_code: "083721" },
  { label: "Caulisihan", mun_code: "083721" },
  { label: "Conalum", mun_code: "083721" },
  { label: "De los Santos (Mahilum)", mun_code: "083721" },
  { label: "Esperanza", mun_code: "083721" },
  { label: "Guadalupe", mun_code: "083721" },
  { label: "Guinsanga-an", mun_code: "083721" },
  { label: "Hinabay", mun_code: "083721" },
  { label: "Jubasan", mun_code: "083721" },
  { label: "Linao", mun_code: "083721" },
  { label: "Macagoco", mun_code: "083721" },
  { label: "Maljo", mun_code: "083721" },
  { label: "Marao", mun_code: "083721" },
  { label: "Poblacion", mun_code: "083721" },
  { label: "Tahud", mun_code: "083721" },
  { label: "Taotaon", mun_code: "083721" },
  { label: "Tinago", mun_code: "083721" },
  { label: "Anislag", mun_code: "083722" },
  { label: "Antipolo", mun_code: "083722" },
  { label: "Apale", mun_code: "083722" },
  { label: "Bantigue", mun_code: "083722" },
  { label: "Benog", mun_code: "083722" },
  { label: "Bilwang", mun_code: "083722" },
  { label: "Can-andan", mun_code: "083722" },
  { label: "Cangag", mun_code: "083722" },
  { label: "Consolacion", mun_code: "083722" },
  { label: "Honan", mun_code: "083722" },
  { label: "Libertad", mun_code: "083722" },
  { label: "Mahayag", mun_code: "083722" },
  { label: "Marvel (Pob.)", mun_code: "083722" },
  { label: "Matlang", mun_code: "083722" },
  { label: "Monte Alegre", mun_code: "083722" },
  { label: "Puting Bato", mun_code: "083722" },
  { label: "San Francisco", mun_code: "083722" },
  { label: "San Roque", mun_code: "083722" },
  { label: "Santa Cruz", mun_code: "083722" },
  { label: "Santo Rosario", mun_code: "083722" },
  { label: "Tabunok", mun_code: "083722" },
  { label: "Tolingon", mun_code: "083722" },
  { label: "Tubod", mun_code: "083722" },
  { label: "Alahag", mun_code: "083723" },
  { label: "Anibongan", mun_code: "083723" },
  { label: "Badiang", mun_code: "083723" },
  { label: "Batug", mun_code: "083723" },
  { label: "Buenavista", mun_code: "083723" },
  { label: "Bukid", mun_code: "083723" },
  { label: "Burabod", mun_code: "083723" },
  { label: "Buri", mun_code: "083723" },
  { label: "Kaglawaan", mun_code: "083723" },
  { label: "Canhandugan", mun_code: "083723" },
  { label: "Crossing Rubas", mun_code: "083723" },
  { label: "Daro", mun_code: "083723" },
  { label: "Hiagsam", mun_code: "083723" },
  { label: "Hibunawon", mun_code: "083723" },
  { label: "Hibucawan", mun_code: "083723" },
  { label: "Kalinawan", mun_code: "083723" },
  { label: "Likod", mun_code: "083723" },
  { label: "Macanip", mun_code: "083723" },
  { label: "Macopa", mun_code: "083723" },
  { label: "Mag-aso", mun_code: "083723" },
  { label: "Malobago", mun_code: "083723" },
  { label: "Olotan", mun_code: "083723" },
  { label: "Pange", mun_code: "083723" },
  { label: "Parasan", mun_code: "083723" },
  { label: "Pitogo", mun_code: "083723" },
  { label: "District I (Pob.)", mun_code: "083723" },
  { label: "District II (Pob.)", mun_code: "083723" },
  { label: "District III (Pob.)", mun_code: "083723" },
  { label: "District IV (Pob.)", mun_code: "083723" },
  { label: "Sagkahan", mun_code: "083723" },
  { label: "San Agustin", mun_code: "083723" },
  { label: "San Pedro", mun_code: "083723" },
  { label: "San Roque", mun_code: "083723" },
  { label: "Santa Cruz", mun_code: "083723" },
  { label: "Sari-sari", mun_code: "083723" },
  { label: "Tinambacan", mun_code: "083723" },
  { label: "Tuba", mun_code: "083723" },
  { label: "Uguiao", mun_code: "083723" },
  { label: "Villagonzoilo (Villa Consuelo)", mun_code: "083723" },
  { label: "Villa Paz", mun_code: "083723" },
  { label: "Bias Zabala", mun_code: "083723" },
  { label: "Atipolo", mun_code: "083723" },
  { label: "Canapuan", mun_code: "083723" },
  { label: "La Paz", mun_code: "083723" },
  { label: "Palanog", mun_code: "083723" },
  { label: "Abuyogay", mun_code: "083724" },
  { label: "Andres Bonifacio", mun_code: "083724" },
  { label: "Batug", mun_code: "083724" },
  { label: "Binulho", mun_code: "083724" },
  { label: "Calzada", mun_code: "083724" },
  { label: "Cancayang", mun_code: "083724" },
  { label: "Caranhug", mun_code: "083724" },
  { label: "Caraye", mun_code: "083724" },
  { label: "Casalungan", mun_code: "083724" },
  { label: "Comatin", mun_code: "083724" },
  { label: "Guindapunan", mun_code: "083724" },
  { label: "Inayupan", mun_code: "083724" },
  { label: "Laray", mun_code: "083724" },
  { label: "Magsaysay (Responde)", mun_code: "083724" },
  { label: "Malitbogay", mun_code: "083724" },
  { label: "Manarug", mun_code: "083724" },
  { label: "Manlilisid", mun_code: "083724" },
  { label: "Naliwatan", mun_code: "083724" },
  { label: "Odiong", mun_code: "083724" },
  { label: "Picas Norte", mun_code: "083724" },
  { label: "Pinocawan", mun_code: "083724" },
  { label: "Poblacion Zone 1", mun_code: "083724" },
  { label: "Poblacion Zone 2", mun_code: "083724" },
  { label: "Rizal", mun_code: "083724" },
  { label: "Santa Cruz", mun_code: "083724" },
  { label: "Talisayan", mun_code: "083724" },
  { label: "San Sotero (Tambis)", mun_code: "083724" },
  { label: "Ulhay", mun_code: "083724" },
  { label: "Alegria", mun_code: "083725" },
  { label: "Anibong", mun_code: "083725" },
  { label: "Aslum", mun_code: "083725" },
  { label: "Balante", mun_code: "083725" },
  { label: "Bongdo", mun_code: "083725" },
  { label: "Bonifacio", mun_code: "083725" },
  { label: "Bugho", mun_code: "083725" },
  { label: "Calbasag", mun_code: "083725" },
  { label: "Caridad", mun_code: "083725" },
  { label: "Cuya-e", mun_code: "083725" },
  { label: "Dita", mun_code: "083725" },
  { label: "Gitabla", mun_code: "083725" },
  { label: "Hindang", mun_code: "083725" },
  { label: "Inawangan", mun_code: "083725" },
  { label: "Jurao", mun_code: "083725" },
  { label: "Poblacion District I", mun_code: "083725" },
  { label: "Poblacion District II", mun_code: "083725" },
  { label: "Poblacion District III", mun_code: "083725" },
  { label: "Poblacion District IV", mun_code: "083725" },
  { label: "San Andres", mun_code: "083725" },
  { label: "San Pablo", mun_code: "083725" },
  { label: "Santa Cruz", mun_code: "083725" },
  { label: "Tagkip", mun_code: "083725" },
  { label: "Tolosahay", mun_code: "083725" },
  { label: "Villa Hermosa", mun_code: "083725" },
  { label: "Aguiting", mun_code: "083726" },
  { label: "Cacao", mun_code: "083726" },
  { label: "Kawayan", mun_code: "083726" },
  { label: "Hiluctogan", mun_code: "083726" },
  { label: "Libertad", mun_code: "083726" },
  { label: "Libongao", mun_code: "083726" },
  { label: "Lim-ao", mun_code: "083726" },
  { label: "Lonoy", mun_code: "083726" },
  { label: "Mahawan", mun_code: "083726" },
  { label: "Masarayao", mun_code: "083726" },
  { label: "Monte Alegre", mun_code: "083726" },
  { label: "Monte Bello", mun_code: "083726" },
  { label: "Naghalin", mun_code: "083726" },
  { label: "Natubgan", mun_code: "083726" },
  { label: "Poblacion", mun_code: "083726" },
  { label: "Rizal", mun_code: "083726" },
  { label: "San Ignacio", mun_code: "083726" },
  { label: "San Isidro", mun_code: "083726" },
  { label: "Santo Domingo", mun_code: "083726" },
  { label: "Tagaytay", mun_code: "083726" },
  { label: "Tongonan", mun_code: "083726" },
  { label: "Tugbong", mun_code: "083726" },
  { label: "Bagacay East", mun_code: "083728" },
  { label: "Bagacay West", mun_code: "083728" },
  { label: "Bongtod", mun_code: "083728" },
  { label: "Bocawon", mun_code: "083728" },
  { label: "Buracan", mun_code: "083728" },
  { label: "Caabangan", mun_code: "083728" },
  { label: "Cacao", mun_code: "083728" },
  { label: "Cagngaran", mun_code: "083728" },
  { label: "Calabnian", mun_code: "083728" },
  { label: "Calaghusan", mun_code: "083728" },
  { label: "Caltayan", mun_code: "083728" },
  { label: "Cogon", mun_code: "083728" },
  { label: "Duyog", mun_code: "083728" },
  { label: "Gimenarat East", mun_code: "083728" },
  { label: "Gimenarat West", mun_code: "083728" },
  { label: "Limba", mun_code: "083728" },
  { label: "Lubi-lubi", mun_code: "083728" },
  { label: "Luneta", mun_code: "083728" },
  { label: "Mag-aso", mun_code: "083728" },
  { label: "Moroboro", mun_code: "083728" },
  { label: "Pansud", mun_code: "083728" },
  { label: "Pawa", mun_code: "083728" },
  { label: "Piliway", mun_code: "083728" },
  { label: "Poblacion District 1", mun_code: "083728" },
  { label: "Poblacion District 2", mun_code: "083728" },
  { label: "Poblacion District 3", mun_code: "083728" },
  { label: "Poblacion District 4", mun_code: "083728" },
  { label: "Quiong", mun_code: "083728" },
  { label: "Rizal", mun_code: "083728" },
  { label: "San Victoray", mun_code: "083728" },
  { label: "Santa Ana", mun_code: "083728" },
  { label: "Santa Elena", mun_code: "083728" },
  { label: "Tabang", mun_code: "083728" },
  { label: "Tarugan", mun_code: "083728" },
  { label: "Bachao", mun_code: "083729" },
  { label: "Baco", mun_code: "083729" },
  { label: "Bagaba-o", mun_code: "083729" },
  { label: "Basud", mun_code: "083729" },
  { label: "Belen", mun_code: "083729" },
  { label: "Burabod", mun_code: "083729" },
  { label: "Calaguise", mun_code: "083729" },
  { label: "Consuegra", mun_code: "083729" },
  { label: "Culasi", mun_code: "083729" },
  { label: "Danus", mun_code: "083729" },
  { label: "Elizabeth", mun_code: "083729" },
  { label: "Kawayan", mun_code: "083729" },
  { label: "Libas", mun_code: "083729" },
  { label: "Maanda", mun_code: "083729" },
  { label: "Macupa", mun_code: "083729" },
  { label: "Mataloto", mun_code: "083729" },
  { label: "Palarao", mun_code: "083729" },
  { label: "Palid I (Ilawod)", mun_code: "083729" },
  { label: "Palid II (Iraya)", mun_code: "083729" },
  { label: "Parasan", mun_code: "083729" },
  { label: "Poblacion", mun_code: "083729" },
  { label: "Salog", mun_code: "083729" },
  { label: "Sambulawan", mun_code: "083729" },
  { label: "Tag-abaca", mun_code: "083729" },
  { label: "Tapol", mun_code: "083729" },
  { label: "Tigbawan", mun_code: "083729" },
  { label: "Tinocdugan", mun_code: "083729" },
  { label: "Toctoc", mun_code: "083729" },
  { label: "Ugbon", mun_code: "083729" },
  { label: "Wague", mun_code: "083729" },
  { label: "Batug", mun_code: "083730" },
  { label: "Burabod", mun_code: "083730" },
  { label: "Capudlosan", mun_code: "083730" },
  { label: "Casuntingan", mun_code: "083730" },
  { label: "Causwagan", mun_code: "083730" },
  { label: "Danao", mun_code: "083730" },
  { label: "General Luna", mun_code: "083730" },
  { label: "Kiling", mun_code: "083730" },
  { label: "Lanawan", mun_code: "083730" },
  { label: "Liwayway", mun_code: "083730" },
  { label: "Maya", mun_code: "083730" },
  { label: "Oguisan", mun_code: "083730" },
  { label: "Palale 1", mun_code: "083730" },
  { label: "Palale 2", mun_code: "083730" },
  { label: "Poblacion District 1", mun_code: "083730" },
  { label: "Poblacion District 2", mun_code: "083730" },
  { label: "Poblacion District 3", mun_code: "083730" },
  { label: "Pongon", mun_code: "083730" },
  { label: "Quezon", mun_code: "083730" },
  { label: "Romualdez", mun_code: "083730" },
  { label: "Salvacion", mun_code: "083730" },
  { label: "San Antonio", mun_code: "083730" },
  { label: "San Isidro", mun_code: "083730" },
  { label: "San Pedro", mun_code: "083730" },
  { label: "San Vicente", mun_code: "083730" },
  { label: "Santa Isabel", mun_code: "083730" },
  { label: "Tinawan", mun_code: "083730" },
  { label: "Tuyo", mun_code: "083730" },
  { label: "Villa Imelda", mun_code: "083730" },
  { label: "Campin", mun_code: "083731" },
  { label: "Cuatro De Agosto", mun_code: "083731" },
  { label: "Hilusig", mun_code: "083731" },
  { label: "Himamara", mun_code: "083731" },
  { label: "Hinaguimitan", mun_code: "083731" },
  { label: "Liberacion", mun_code: "083731" },
  { label: "Mabuhay", mun_code: "083731" },
  { label: "Mabunga", mun_code: "083731" },
  { label: "Magsuganao", mun_code: "083731" },
  { label: "Mahayag", mun_code: "083731" },
  { label: "Mahayahay", mun_code: "083731" },
  { label: "Malinao", mun_code: "083731" },
  { label: "Malipoon", mun_code: "083731" },
  { label: "Paril", mun_code: "083731" },
  { label: "Pinamonoan", mun_code: "083731" },
  { label: "Poblacion", mun_code: "083731" },
  { label: "Polahongon", mun_code: "083731" },
  { label: "San Isidro", mun_code: "083731" },
  { label: "San Juan", mun_code: "083731" },
  { label: "Santa Cruz", mun_code: "083731" },
  { label: "Tagaytay", mun_code: "083731" },
  { label: "Uguis", mun_code: "083731" },
  { label: "Union", mun_code: "083731" },
  { label: "Upper Mahaplag", mun_code: "083731" },
  { label: "Hiluctogan", mun_code: "083731" },
  { label: "Maligaya", mun_code: "083731" },
  { label: "Balagtas", mun_code: "083733" },
  { label: "Bulak", mun_code: "083733" },
  { label: "Cambadbad", mun_code: "083733" },
  { label: "Candelaria", mun_code: "083733" },
  { label: "Cansoso", mun_code: "083733" },
  { label: "Mansahaon", mun_code: "083733" },
  { label: "Masaba", mun_code: "083733" },
  { label: "Naulayan", mun_code: "083733" },
  { label: "Bonoy (Pob.)", mun_code: "083733" },
  { label: "Mansalip (Pob.)", mun_code: "083733" },
  { label: "Riverside (Pob.)", mun_code: "083733" },
  { label: "Talisay (Pob.)", mun_code: "083733" },
  { label: "San Dionisio", mun_code: "083733" },
  { label: "San Marcelino", mun_code: "083733" },
  { label: "San Sebastian", mun_code: "083733" },
  { label: "San Vicente", mun_code: "083733" },
  { label: "Santa Rosa", mun_code: "083733" },
  { label: "Santo Rosario", mun_code: "083733" },
  { label: "Imelda", mun_code: "083733" },
  { label: "Malazarte", mun_code: "083733" },
  { label: "San Guillermo", mun_code: "083733" },
  { label: "Agbanga", mun_code: "083734" },
  { label: "Altavista", mun_code: "083734" },
  { label: "Cahagnaan", mun_code: "083734" },
  { label: "Calumpang", mun_code: "083734" },
  { label: "Caningag", mun_code: "083734" },
  { label: "Caridad Norte", mun_code: "083734" },
  { label: "Caridad Sur", mun_code: "083734" },
  { label: "Elevado", mun_code: "083734" },
  { label: "Esperanza", mun_code: "083734" },
  { label: "Hitoog", mun_code: "083734" },
  { label: "Itum", mun_code: "083734" },
  { label: "Lowan", mun_code: "083734" },
  { label: "Monte Alegre", mun_code: "083734" },
  { label: "San Isidro (Pob.)", mun_code: "083734" },
  { label: "San Pedro (Pob.)", mun_code: "083734" },
  { label: "President Garcia", mun_code: "083734" },
  { label: "Punong", mun_code: "083734" },
  { label: "San Juan", mun_code: "083734" },
  { label: "San Salvador", mun_code: "083734" },
  { label: "San Vicente", mun_code: "083734" },
  { label: "Santa Fe", mun_code: "083734" },
  { label: "Santa Paz", mun_code: "083734" },
  { label: "Tag-os", mun_code: "083734" },
  { label: "Templanza", mun_code: "083734" },
  { label: "Tigbao", mun_code: "083734" },
  { label: "Waterloo", mun_code: "083734" },
  { label: "Zaragoza", mun_code: "083734" },
  { label: "Bagong Lipunan", mun_code: "083734" },
  { label: "Taglibas Imelda", mun_code: "083734" },
  { label: "A. Bonifacio", mun_code: "083735" },
  { label: "Mabini", mun_code: "083735" },
  { label: "Burgos", mun_code: "083735" },
  { label: "Calipayan", mun_code: "083735" },
  { label: "Camansi", mun_code: "083735" },
  { label: "General Antonio Luna", mun_code: "083735" },
  { label: "Liberty", mun_code: "083735" },
  { label: "Ormocay", mun_code: "083735" },
  { label: "Poblacion Zone 1", mun_code: "083735" },
  { label: "Poblacion Zone 2", mun_code: "083735" },
  { label: "Poblacion Zone 3", mun_code: "083735" },
  { label: "San Roque", mun_code: "083735" },
  { label: "Santa Cruz", mun_code: "083735" },
  { label: "Talisay", mun_code: "083735" },
  { label: "Union", mun_code: "083735" },
  { label: "Wilson", mun_code: "083735" },
  { label: "Binabaye", mun_code: "083736" },
  { label: "Cabaliwan", mun_code: "083736" },
  { label: "Calunangan", mun_code: "083736" },
  { label: "Calunasan", mun_code: "083736" },
  { label: "Cambalong", mun_code: "083736" },
  { label: "Can-unzo", mun_code: "083736" },
  { label: "Canbantug", mun_code: "083736" },
  { label: "Casilda", mun_code: "083736" },
  { label: "Lamanoc", mun_code: "083736" },
  { label: "Libas", mun_code: "083736" },
  { label: "Libjo", mun_code: "083736" },
  { label: "Lundag", mun_code: "083736" },
  { label: "Mahalit", mun_code: "083736" },
  { label: "Mahayag", mun_code: "083736" },
  { label: "Masumbang", mun_code: "083736" },
  { label: "Poblacion", mun_code: "083736" },
  { label: "Puerto Bello", mun_code: "083736" },
  { label: "San Isidro", mun_code: "083736" },
  { label: "San Jose", mun_code: "083736" },
  { label: "Macario", mun_code: "083736" },
  { label: "Tubod", mun_code: "083736" },
  { label: "Mat-e", mun_code: "083736" },
  { label: "Alegria", mun_code: "083738" },
  { label: "Bagong", mun_code: "083738" },
  { label: "Labrador (Balion)", mun_code: "083738" },
  { label: "Bantigue", mun_code: "083738" },
  { label: "Batuan", mun_code: "083738" },
  { label: "Biliboy", mun_code: "083738" },
  { label: "Borok", mun_code: "083738" },
  { label: "Cabaon-an", mun_code: "083738" },
  { label: "Cabulihan", mun_code: "083738" },
  { label: "Cagbuhangin", mun_code: "083738" },
  { label: "Can-adieng", mun_code: "083738" },
  { label: "Can-untog", mun_code: "083738" },
  { label: "Catmon", mun_code: "083738" },
  { label: "Cogon Combado", mun_code: "083738" },
  { label: "Concepcion", mun_code: "083738" },
  { label: "Curva", mun_code: "083738" },
  { label: "Danao", mun_code: "083738" },
  { label: "Dayhagan", mun_code: "083738" },
  { label: "Barangay 1 (Pob.)", mun_code: "083738" },
  { label: "Barangay 10 (Pob.)", mun_code: "083738" },
  { label: "Barangay 11 (Pob.)", mun_code: "083738" },
  { label: "Barangay 12 (Pob.)", mun_code: "083738" },
  { label: "Barangay 13 (Pob.)", mun_code: "083738" },
  { label: "Barangay 14 (Pob.)", mun_code: "083738" },
  { label: "Barangay 15 (Pob.)", mun_code: "083738" },
  { label: "Barangay 16 (Pob.)", mun_code: "083738" },
  { label: "Barangay 17 (Pob.)", mun_code: "083738" },
  { label: "Barangay 18 (Pob.)", mun_code: "083738" },
  { label: "Barangay 19 (Pob.)", mun_code: "083738" },
  { label: "Barangay 2 (Pob.)", mun_code: "083738" },
  { label: "Barangay 20 (Pob.)", mun_code: "083738" },
  { label: "Barangay 21 (Pob.)", mun_code: "083738" },
  { label: "Barangay 22 (Pob.)", mun_code: "083738" },
  { label: "Barangay 23 (Pob.)", mun_code: "083738" },
  { label: "Barangay 24 (Pob.)", mun_code: "083738" },
  { label: "Barangay 25 (Pob.)", mun_code: "083738" },
  { label: "Barangay 26 (Pob.)", mun_code: "083738" },
  { label: "Barangay 27 (Pob.)", mun_code: "083738" },
  { label: "Barangay 28 (Pob.)", mun_code: "083738" },
  { label: "Barangay 3 (Pob.)", mun_code: "083738" },
  { label: "Barangay 4 (Pob.)", mun_code: "083738" },
  { label: "Barangay 5 (Pob.)", mun_code: "083738" },
  { label: "Barangay 6 (Pob.)", mun_code: "083738" },
  { label: "Barangay 7 (Pob.)", mun_code: "083738" },
  { label: "Barangay 8 (Pob.)", mun_code: "083738" },
  { label: "Barangay 9 (Pob.)", mun_code: "083738" },
  { label: "Dolores", mun_code: "083738" },
  { label: "Domonar", mun_code: "083738" },
  { label: "Don Felipe Larrazabal", mun_code: "083738" },
  { label: "Donghol", mun_code: "083738" },
  { label: "Esperanza", mun_code: "083738" },
  { label: "Hibunawon", mun_code: "083738" },
  { label: "Hugpa", mun_code: "083738" },
  { label: "Ipil", mun_code: "083738" },
  { label: "Lao", mun_code: "083738" },
  { label: "Libertad", mun_code: "083738" },
  { label: "Liloan", mun_code: "083738" },
  { label: "Linao", mun_code: "083738" },
  { label: "Mabini", mun_code: "083738" },
  { label: "Macabug", mun_code: "083738" },
  { label: "Magaswi", mun_code: "083738" },
  { label: "Mahayag", mun_code: "083738" },
  { label: "Mahayahay", mun_code: "083738" },
  { label: "Manlilinao", mun_code: "083738" },
  { label: "Margen", mun_code: "083738" },
  { label: "Mas-in", mun_code: "083738" },
  { label: "Matica-a", mun_code: "083738" },
  { label: "Milagro", mun_code: "083738" },
  { label: "Monterico", mun_code: "083738" },
  { label: "Nasunogan", mun_code: "083738" },
  { label: "Naungan", mun_code: "083738" },
  { label: "Nueva Vista", mun_code: "083738" },
  { label: "Patag", mun_code: "083738" },
  { label: "Punta", mun_code: "083738" },
  { label: "Quezon, Jr.", mun_code: "083738" },
  { label: "Rufina M. Tan", mun_code: "083738" },
  { label: "Sabang Bao", mun_code: "083738" },
  { label: "Salvacion", mun_code: "083738" },
  { label: "San Antonio", mun_code: "083738" },
  { label: "San Isidro", mun_code: "083738" },
  { label: "San Jose", mun_code: "083738" },
  { label: "San Juan", mun_code: "083738" },
  { label: "San Vicente", mun_code: "083738" },
  { label: "San Pablo (Simangan)", mun_code: "083738" },
  { label: "Sumangga", mun_code: "083738" },
  { label: "Valencia", mun_code: "083738" },
  { label: "Barangay 29 (Pob.)", mun_code: "083738" },
  { label: "Airport", mun_code: "083738" },
  { label: "Cabintan", mun_code: "083738" },
  { label: "Camp Downes", mun_code: "083738" },
  { label: "Gaas", mun_code: "083738" },
  { label: "Green Valley", mun_code: "083738" },
  { label: "Licuma", mun_code: "083738" },
  { label: "Liberty", mun_code: "083738" },
  { label: "Leondoni", mun_code: "083738" },
  { label: "Nueva Sociedad", mun_code: "083738" },
  { label: "Tambulilid", mun_code: "083738" },
  { label: "Tongonan", mun_code: "083738" },
  { label: "Don Potenciano Larrazabal", mun_code: "083738" },
  { label: "Kadaohan", mun_code: "083738" },
  { label: "Guintigui-an", mun_code: "083738" },
  { label: "Danhug", mun_code: "083738" },
  { label: "Alta Vista", mun_code: "083738" },
  { label: "Bagong Buhay", mun_code: "083738" },
  { label: "Bayog", mun_code: "083738" },
  { label: "Juaton", mun_code: "083738" },
  { label: "Luna", mun_code: "083738" },
  { label: "Mabato", mun_code: "083738" },
  { label: "Anahaway", mun_code: "083739" },
  { label: "Arado", mun_code: "083739" },
  { label: "Baras", mun_code: "083739" },
  { label: "Barayong", mun_code: "083739" },
  { label: "Cabarasan Daku", mun_code: "083739" },
  { label: "Cabarasan Guti", mun_code: "083739" },
  { label: "Campetik", mun_code: "083739" },
  { label: "Candahug", mun_code: "083739" },
  { label: "Cangumbang", mun_code: "083739" },
  { label: "Canhidoc", mun_code: "083739" },
  { label: "Capirawan", mun_code: "083739" },
  { label: "Castilla", mun_code: "083739" },
  { label: "Cogon (Cogon San Joaquin)", mun_code: "083739" },
  { label: "Gacao", mun_code: "083739" },
  { label: "Guindapunan", mun_code: "083739" },
  { label: "Libertad", mun_code: "083739" },
  { label: "Naga-naga", mun_code: "083739" },
  { label: "Pawing", mun_code: "083739" },
  { label: "Buri (Pob.)", mun_code: "083739" },
  { label: "Cavite East (Pob.)", mun_code: "083739" },
  { label: "Cavite West (Pob.)", mun_code: "083739" },
  { label: "Luntad (Pob.)", mun_code: "083739" },
  { label: "Santa Cruz (Pob.)", mun_code: "083739" },
  { label: "Salvacion", mun_code: "083739" },
  { label: "San Agustin", mun_code: "083739" },
  { label: "San Antonio", mun_code: "083739" },
  { label: "San Isidro", mun_code: "083739" },
  { label: "San Joaquin", mun_code: "083739" },
  { label: "San Jose", mun_code: "083739" },
  { label: "San Miguel (Pob.)", mun_code: "083739" },
  { label: "Tacuranga", mun_code: "083739" },
  { label: "Teraza", mun_code: "083739" },
  { label: "San Fernando", mun_code: "083739" },
  { label: "Baguinbin", mun_code: "083740" },
  { label: "Belen", mun_code: "083740" },
  { label: "Buenavista", mun_code: "083740" },
  { label: "Caduhaan", mun_code: "083740" },
  { label: "Cambakbak", mun_code: "083740" },
  { label: "Cambinoy", mun_code: "083740" },
  { label: "Cangcosme", mun_code: "083740" },
  { label: "Cangmuya", mun_code: "083740" },
  { label: "Canipaan", mun_code: "083740" },
  { label: "Cantandoy", mun_code: "083740" },
  { label: "Cantuhaon", mun_code: "083740" },
  { label: "Catigahan", mun_code: "083740" },
  { label: "Cruz", mun_code: "083740" },
  { label: "Duljugan", mun_code: "083740" },
  { label: "Guiwan 1 (Pob.)", mun_code: "083740" },
  { label: "Guiwan 2 (Pob.)", mun_code: "083740" },
  { label: "Himarco", mun_code: "083740" },
  { label: "Hinagbuan", mun_code: "083740" },
  { label: "Lat-osan", mun_code: "083740" },
  { label: "Liberty", mun_code: "083740" },
  { label: "Mazawalo Pob. (Lili-on)", mun_code: "083740" },
  { label: "Lomonon", mun_code: "083740" },
  { label: "Mabini", mun_code: "083740" },
  { label: "Magsaysay", mun_code: "083740" },
  { label: "Masaba", mun_code: "083740" },
  { label: "Parilla", mun_code: "083740" },
  { label: "Plaridel", mun_code: "083740" },
  { label: "Central 1 (Pob.)", mun_code: "083740" },
  { label: "Central 2 (Pob.)", mun_code: "083740" },
  { label: "Hinablayan Pob. (Central 3)", mun_code: "083740" },
  { label: "Rizal", mun_code: "083740" },
  { label: "Sabang", mun_code: "083740" },
  { label: "San Guillermo", mun_code: "083740" },
  { label: "San Isidro", mun_code: "083740" },
  { label: "San Joaquin", mun_code: "083740" },
  { label: "San Juan", mun_code: "083740" },
  { label: "San Miguel", mun_code: "083740" },
  { label: "San Pablo", mun_code: "083740" },
  { label: "San Pedro", mun_code: "083740" },
  { label: "San Roque", mun_code: "083740" },
  { label: "Santiago", mun_code: "083740" },
  { label: "Taberna", mun_code: "083740" },
  { label: "Tabunok", mun_code: "083740" },
  { label: "Tambis", mun_code: "083740" },
  { label: "Tinabilan", mun_code: "083740" },
  { label: "Tinago", mun_code: "083740" },
  { label: "Tinubdan", mun_code: "083740" },
  { label: "Pinagdait Pob. (Ypil I)", mun_code: "083740" },
  { label: "Pinaghi-usa Pob. (Ypil II)", mun_code: "083740" },
  { label: "Bitaog Pob. (Ypil III)", mun_code: "083740" },
  { label: "Arabunog", mun_code: "083741" },
  { label: "Aringit", mun_code: "083741" },
  { label: "Bahay", mun_code: "083741" },
  { label: "Cabaohan", mun_code: "083741" },
  { label: "Calsadahay", mun_code: "083741" },
  { label: "Cancaraja", mun_code: "083741" },
  { label: "Caninoan", mun_code: "083741" },
  { label: "Capilla", mun_code: "083741" },
  { label: "Colawen", mun_code: "083741" },
  { label: "Dumarag", mun_code: "083741" },
  { label: "Guindapunan", mun_code: "083741" },
  { label: "Halaba", mun_code: "083741" },
  { label: "Jones", mun_code: "083741" },
  { label: "Lanawan", mun_code: "083741" },
  { label: "Lima", mun_code: "083741" },
  { label: "Macalpiay", mun_code: "083741" },
  { label: "Malitbogay", mun_code: "083741" },
  { label: "Manaybanay", mun_code: "083741" },
  { label: "Maricum", mun_code: "083741" },
  { label: "Patong", mun_code: "083741" },
  { label: "District 1 (Pob.)", mun_code: "083741" },
  { label: "District 2 (Pob.)", mun_code: "083741" },
  { label: "District 3 (Pob.)", mun_code: "083741" },
  { label: "District 4 (Pob.)", mun_code: "083741" },
  { label: "Sapsap", mun_code: "083741" },
  { label: "Socsocon", mun_code: "083741" },
  { label: "Tingib", mun_code: "083741" },
  { label: "Yapad", mun_code: "083741" },
  { label: "Lourdes", mun_code: "083741" },
  { label: "Banat-e", mun_code: "083742" },
  { label: "Basud", mun_code: "083742" },
  { label: "Bawod (Pob.)", mun_code: "083742" },
  { label: "Biasong", mun_code: "083742" },
  { label: "Bunacan", mun_code: "083742" },
  { label: "Cabungaan", mun_code: "083742" },
  { label: "Crossing (Pob.)", mun_code: "083742" },
  { label: "Daja-daku", mun_code: "083742" },
  { label: "Daja-diot", mun_code: "083742" },
  { label: "Hacienda Maria", mun_code: "083742" },
  { label: "Linao", mun_code: "083742" },
  { label: "Matungao", mun_code: "083742" },
  { label: "Paril", mun_code: "083742" },
  { label: "San Jose", mun_code: "083742" },
  { label: "Taglawigan", mun_code: "083742" },
  { label: "Tinago", mun_code: "083742" },
  { label: "Busay", mun_code: "083742" },
  { label: "San Miguel", mun_code: "083742" },
  { label: "Bagacay", mun_code: "083743" },
  { label: "Bahay", mun_code: "083743" },
  { label: "Bairan", mun_code: "083743" },
  { label: "Cabatianuhan", mun_code: "083743" },
  { label: "Canap", mun_code: "083743" },
  { label: "Capilihan", mun_code: "083743" },
  { label: "Caraycaray", mun_code: "083743" },
  { label: "Libtong (East Poblacion)", mun_code: "083743" },
  { label: "Guinciaman", mun_code: "083743" },
  { label: "Impo", mun_code: "083743" },
  { label: "Kinalumsan", mun_code: "083743" },
  { label: "Lukay", mun_code: "083743" },
  { label: "Malaguinabot", mun_code: "083743" },
  { label: "Malpag", mun_code: "083743" },
  { label: "Mawodpawod", mun_code: "083743" },
  { label: "Patong", mun_code: "083743" },
  { label: "Pinarigusan", mun_code: "083743" },
  { label: "San Andres", mun_code: "083743" },
  { label: "Santa Cruz", mun_code: "083743" },
  { label: "Santol", mun_code: "083743" },
  { label: "Cayare (West Poblacion)", mun_code: "083743" },
  { label: "Baculanad", mun_code: "083744" },
  { label: "Badiangay", mun_code: "083744" },
  { label: "Bulod", mun_code: "083744" },
  { label: "Catoogan", mun_code: "083744" },
  { label: "Katipunan", mun_code: "083744" },
  { label: "Milagrosa", mun_code: "083744" },
  { label: "Pilit", mun_code: "083744" },
  { label: "Pitogo", mun_code: "083744" },
  { label: "Zone 1 (Pob.)", mun_code: "083744" },
  { label: "Zone 2 (Pob.)", mun_code: "083744" },
  { label: "Zone 3 (Pob.)", mun_code: "083744" },
  { label: "San Isidro", mun_code: "083744" },
  { label: "San Juan", mun_code: "083744" },
  { label: "San Miguelay", mun_code: "083744" },
  { label: "San Roque", mun_code: "083744" },
  { label: "Tibak", mun_code: "083744" },
  { label: "Victoria", mun_code: "083744" },
  { label: "Cutay", mun_code: "083744" },
  { label: "Gapas", mun_code: "083744" },
  { label: "Zone 4 Pob. (Cabangcalan)", mun_code: "083744" },
  { label: "Butason I", mun_code: "083745" },
  { label: "Butason II", mun_code: "083745" },
  { label: "Campokpok", mun_code: "083745" },
  { label: "Catmon", mun_code: "083745" },
  { label: "Gimarco", mun_code: "083745" },
  { label: "Gibacungan", mun_code: "083745" },
  { label: "Inangatan", mun_code: "083745" },
  { label: "Manlawaan", mun_code: "083745" },
  { label: "Omaganhan", mun_code: "083745" },
  { label: "Poblacion", mun_code: "083745" },
  { label: "Santa Rosa", mun_code: "083745" },
  { label: "Tabing", mun_code: "083745" },
  { label: "Tugas", mun_code: "083745" },
  { label: "Amandangay", mun_code: "083746" },
  { label: "Aslum", mun_code: "083746" },
  { label: "Balingasag", mun_code: "083746" },
  { label: "Belisong", mun_code: "083746" },
  { label: "Cambucao", mun_code: "083746" },
  { label: "Capahuan", mun_code: "083746" },
  { label: "Guingawan", mun_code: "083746" },
  { label: "Jabong", mun_code: "083746" },
  { label: "Mercadohay", mun_code: "083746" },
  { label: "Mering", mun_code: "083746" },
  { label: "Mohon", mun_code: "083746" },
  { label: "District I Pob. (Quezon)", mun_code: "083746" },
  { label: "District II Pob. (Rizal)", mun_code: "083746" },
  { label: "District III Pob. (Bonifacio)", mun_code: "083746" },
  { label: "District IV Pob. (Macarthur)", mun_code: "083746" },
  { label: "San Pablo (Mooc)", mun_code: "083746" },
  { label: "Barangay 2", mun_code: "083747" },
  { label: "Nula-tula (Bgys. 3 & 3A)", mun_code: "083747" },
  { label: "Libertad (Barangays 1 & 4)", mun_code: "083747" },
  { label: "Barangay 5", mun_code: "083747" },
  { label: "Barangay 6", mun_code: "083747" },
  { label: "Barangay 6-A", mun_code: "083747" },
  { label: "Barangay 7", mun_code: "083747" },
  { label: "Barangay 8", mun_code: "083747" },
  { label: "Barangay 100 (San Roque)", mun_code: "083747" },
  { label: "Barangay 101 (New Kawayan)", mun_code: "083747" },
  { label: "Barangay 102 (Old Kawayan)", mun_code: "083747" },
  { label: "Barangay 103 (Palanog)", mun_code: "083747" },
  { label: "Barangay 103-A (San Paglaum)", mun_code: "083747" },
  { label: "Barangay 104 (Salvacion)", mun_code: "083747" },
  { label: "Barangay 105 (Suhi)", mun_code: "083747" },
  { label: "Barangay 107 (Santa Elena)", mun_code: "083747" },
  { label: "Barangay 108 (Tagapuro)", mun_code: "083747" },
  { label: "Barangay 12 (Palanog Resettlement)", mun_code: "083747" },
  { label: "Barangay 13", mun_code: "083747" },
  { label: "Barangay 14", mun_code: "083747" },
  { label: "Barangay 15", mun_code: "083747" },
  { label: "Barangay 16", mun_code: "083747" },
  { label: "Barangay 17", mun_code: "083747" },
  { label: "Barangay 18", mun_code: "083747" },
  { label: "Barangay 19", mun_code: "083747" },
  { label: "Barangay 20", mun_code: "083747" },
  { label: "Barangay 21", mun_code: "083747" },
  { label: "Barangay 21-A", mun_code: "083747" },
  { label: "Barangay 22", mun_code: "083747" },
  { label: "Barangay 23", mun_code: "083747" },
  { label: "Barangay 24", mun_code: "083747" },
  { label: "Barangay 25", mun_code: "083747" },
  { label: "Barangay 26", mun_code: "083747" },
  { label: "Barangay 27", mun_code: "083747" },
  { label: "Barangay 28", mun_code: "083747" },
  { label: "Barangay 29", mun_code: "083747" },
  { label: "Barangay 30", mun_code: "083747" },
  { label: "Barangay 31", mun_code: "083747" },
  { label: "Barangay 32", mun_code: "083747" },
  { label: "Barangay 33", mun_code: "083747" },
  { label: "Barangay 34", mun_code: "083747" },
  { label: "Barangay 35", mun_code: "083747" },
  { label: "Barangay 35-A", mun_code: "083747" },
  { label: "Barangay 36", mun_code: "083747" },
  { label: "Barangay 37", mun_code: "083747" },
  { label: "Barangay 37-A", mun_code: "083747" },
  { label: "Barangay 38", mun_code: "083747" },
  { label: "Barangay 39", mun_code: "083747" },
  { label: "Barangay 40", mun_code: "083747" },
  { label: "Barangay 41", mun_code: "083747" },
  { label: "Barangay 42", mun_code: "083747" },
  { label: "Barangay 43", mun_code: "083747" },
  { label: "Barangay 43-A", mun_code: "083747" },
  { label: "Barangay 43-B", mun_code: "083747" },
  { label: "Barangay 44", mun_code: "083747" },
  { label: "Barangay 44-A", mun_code: "083747" },
  { label: "Barangay 45", mun_code: "083747" },
  { label: "Barangay 46", mun_code: "083747" },
  { label: "Barangay 47", mun_code: "083747" },
  { label: "Barangay 48", mun_code: "083747" },
  { label: "Barangay 49", mun_code: "083747" },
  { label: "Barangay 50", mun_code: "083747" },
  { label: "Barangay 50-A", mun_code: "083747" },
  { label: "Barangay 50-B", mun_code: "083747" },
  { label: "Barangay 51", mun_code: "083747" },
  { label: "Barangay 52", mun_code: "083747" },
  { label: "Barangay 53", mun_code: "083747" },
  { label: "Barangay 54", mun_code: "083747" },
  { label: "El Reposo (Barangays 55 & 55A)", mun_code: "083747" },
  { label: "Barangay 56", mun_code: "083747" },
  { label: "Barangay 57", mun_code: "083747" },
  { label: "Barangay 58", mun_code: "083747" },
  { label: "Barangay 59", mun_code: "083747" },
  { label: "Barangay 60", mun_code: "083747" },
  { label: "Barangay 60-A", mun_code: "083747" },
  { label: "Barangay 61", mun_code: "083747" },
  { label: "Barangay 62", mun_code: "083747" },
  { label: "Barangay 63", mun_code: "083747" },
  { label: "Barangay 64", mun_code: "083747" },
  { label: "Barangay 65", mun_code: "083747" },
  { label: "Barangay 66", mun_code: "083747" },
  { label: "Barangay 66-A", mun_code: "083747" },
  { label: "Barangay 67", mun_code: "083747" },
  { label: "Barangay 68", mun_code: "083747" },
  { label: "Barangay 69", mun_code: "083747" },
  { label: "Barangay 70", mun_code: "083747" },
  { label: "Barangay 71", mun_code: "083747" },
  { label: "Barangay 72", mun_code: "083747" },
  { label: "Barangay 73", mun_code: "083747" },
  { label: "Barangay 74", mun_code: "083747" },
  { label: "Barangay 75", mun_code: "083747" },
  { label: "Barangay 76", mun_code: "083747" },
  { label: "Barangay 77", mun_code: "083747" },
  { label: "Barangay 78 (Marasbaras)", mun_code: "083747" },
  { label: "Barangay 79 (Marasbaras)", mun_code: "083747" },
  { label: "Barangay 80 (Marasbaras)", mun_code: "083747" },
  { label: "Barangay 81 (Marasbaras)", mun_code: "083747" },
  { label: "Barangay 82 (Marasbaras)", mun_code: "083747" },
  { label: "Barangay 83 (San Jose)", mun_code: "083747" },
  { label: "Barangay 83-A (San Jose)", mun_code: "083747" },
  { label: "Barangay 84 (San Jose)", mun_code: "083747" },
  { label: "Barangay 85 (San Jose)", mun_code: "083747" },
  { label: "Barangay 86", mun_code: "083747" },
  { label: "Barangay 87", mun_code: "083747" },
  { label: "Barangay 88", mun_code: "083747" },
  { label: "Barangay 89", mun_code: "083747" },
  { label: "Barangay 90 (San Jose)", mun_code: "083747" },
  { label: "Barangay 91 (Abucay)", mun_code: "083747" },
  { label: "Barangay 92 (Apitong)", mun_code: "083747" },
  { label: "Barangay 93 (Bagacay)", mun_code: "083747" },
  { label: "Barangay 94 (Tigbao)", mun_code: "083747" },
  { label: "Barangay 95 (Caibaan)", mun_code: "083747" },
  { label: "Barangay 96 (Calanipawan)", mun_code: "083747" },
  { label: "Barangay 97 (Cabalawan)", mun_code: "083747" },
  { label: "Barangay 98 (Camansinay)", mun_code: "083747" },
  { label: "Barangay 99 (Diit)", mun_code: "083747" },
  { label: "Barangay 109 (V & G Subd.)", mun_code: "083747" },
  { label: "Barangay 109-A", mun_code: "083747" },
  { label: "Barangay 110 (Utap)", mun_code: "083747" },
  { label: "Barangay 5-A", mun_code: "083747" },
  { label: "Barangay 36-A", mun_code: "083747" },
  { label: "Barangay 42-A", mun_code: "083747" },
  { label: "Barangay 48-A", mun_code: "083747" },
  { label: "Barangay 48-B", mun_code: "083747" },
  { label: "Barangay 51-A", mun_code: "083747" },
  { label: "Barangay 54-A", mun_code: "083747" },
  { label: "Barangay 56-A", mun_code: "083747" },
  { label: "Barangay 59-A", mun_code: "083747" },
  { label: "Barangay 59-B", mun_code: "083747" },
  { label: "Barangay 62-A", mun_code: "083747" },
  { label: "Barangay 62-B", mun_code: "083747" },
  { label: "Barangay 83-B", mun_code: "083747" },
  { label: "Barangay 83-C (San Jose)", mun_code: "083747" },
  { label: "Barangay 95-A (Caibaan)", mun_code: "083747" },
  { label: "Barangay 8-A", mun_code: "083747" },
  { label: "Barangay 23-A", mun_code: "083747" },
  { label: "Barangay 94-A", mun_code: "083747" },
  { label: "Ada", mun_code: "083748" },
  { label: "Amanluran", mun_code: "083748" },
  { label: "Arado", mun_code: "083748" },
  { label: "Atipolo", mun_code: "083748" },
  { label: "Balud", mun_code: "083748" },
  { label: "Bangon", mun_code: "083748" },
  { label: "Bantagan", mun_code: "083748" },
  { label: "Baras", mun_code: "083748" },
  { label: "Binolo", mun_code: "083748" },
  { label: "Binongto-an", mun_code: "083748" },
  { label: "Bislig", mun_code: "083748" },
  { label: "Cabalagnan", mun_code: "083748" },
  { label: "Cabarasan Guti", mun_code: "083748" },
  { label: "Cabonga-an", mun_code: "083748" },
  { label: "Cabuynan", mun_code: "083748" },
  { label: "Cahumayhumayan", mun_code: "083748" },
  { label: "Calogcog", mun_code: "083748" },
  { label: "Calsadahay", mun_code: "083748" },
  { label: "Camire", mun_code: "083748" },
  { label: "Canbalisara", mun_code: "083748" },
  { label: "Catigbian", mun_code: "083748" },
  { label: "Catmon", mun_code: "083748" },
  { label: "Cogon", mun_code: "083748" },
  { label: "Guindag-an", mun_code: "083748" },
  { label: "Guingawan", mun_code: "083748" },
  { label: "Hilagpad", mun_code: "083748" },
  { label: "Lapay", mun_code: "083748" },
  { label: "Limbuhan Daku", mun_code: "083748" },
  { label: "Limbuhan Guti", mun_code: "083748" },
  { label: "Linao", mun_code: "083748" },
  { label: "Magay", mun_code: "083748" },
  { label: "Maghulod", mun_code: "083748" },
  { label: "Malaguicay", mun_code: "083748" },
  { label: "Maribi", mun_code: "083748" },
  { label: "Mohon", mun_code: "083748" },
  { label: "Pago", mun_code: "083748" },
  { label: "Pasil", mun_code: "083748" },
  { label: "Pikas", mun_code: "083748" },
  { label: "Buntay (Pob.)", mun_code: "083748" },
  { label: "Canramos (Pob.)", mun_code: "083748" },
  { label: "Licod (Pob.)", mun_code: "083748" },
  { label: "San Miguel (Pob.)", mun_code: "083748" },
  { label: "Salvador", mun_code: "083748" },
  { label: "San Isidro", mun_code: "083748" },
  { label: "San Roque (Pob.)", mun_code: "083748" },
  { label: "San Victor", mun_code: "083748" },
  { label: "Santa Cruz", mun_code: "083748" },
  { label: "Santa Elena", mun_code: "083748" },
  { label: "Solano", mun_code: "083748" },
  { label: "Talolora", mun_code: "083748" },
  { label: "Tugop", mun_code: "083748" },
  { label: "Kiling", mun_code: "083748" },
  { label: "Sacme", mun_code: "083748" },
  { label: "Burak", mun_code: "083749" },
  { label: "Canmogsay", mun_code: "083749" },
  { label: "Cantariwis", mun_code: "083749" },
  { label: "Capangihan", mun_code: "083749" },
  { label: "Malbog", mun_code: "083749" },
  { label: "Olot", mun_code: "083749" },
  { label: "Opong", mun_code: "083749" },
  { label: "Poblacion", mun_code: "083749" },
  { label: "Quilao", mun_code: "083749" },
  { label: "San Roque", mun_code: "083749" },
  { label: "San Vicente", mun_code: "083749" },
  { label: "Tanghas", mun_code: "083749" },
  { label: "Telegrafo", mun_code: "083749" },
  { label: "Imelda", mun_code: "083749" },
  { label: "Astorga (Upart)", mun_code: "083750" },
  { label: "Balire", mun_code: "083750" },
  { label: "Banawang", mun_code: "083750" },
  { label: "San Antonio (Pob.)", mun_code: "083750" },
  { label: "San Pedro", mun_code: "083750" },
  { label: "San Roque", mun_code: "083750" },
  { label: "San Vicente (Pob.)", mun_code: "083750" },
  { label: "Abijao", mun_code: "083751" },
  { label: "Balite", mun_code: "083751" },
  { label: "Bugabuga", mun_code: "083751" },
  { label: "Cabungahan", mun_code: "083751" },
  { label: "Cabunga-an", mun_code: "083751" },
  { label: "Cagnocot", mun_code: "083751" },
  { label: "Cahigan", mun_code: "083751" },
  { label: "Calbugos", mun_code: "083751" },
  { label: "Camporog", mun_code: "083751" },
  { label: "Capinyahan", mun_code: "083751" },
  { label: "Casili-on", mun_code: "083751" },
  { label: "Catagbacan", mun_code: "083751" },
  { label: "Fatima (Pob.)", mun_code: "083751" },
  { label: "Hibulangan", mun_code: "083751" },
  { label: "Hinabuyan", mun_code: "083751" },
  { label: "Iligay", mun_code: "083751" },
  { label: "Jalas", mun_code: "083751" },
  { label: "Jordan", mun_code: "083751" },
  { label: "Libagong", mun_code: "083751" },
  { label: "New Balanac", mun_code: "083751" },
  { label: "Payao", mun_code: "083751" },
  { label: "Poblacion Norte", mun_code: "083751" },
  { label: "Poblacion Sur", mun_code: "083751" },
  { label: "Sambulawan", mun_code: "083751" },
  { label: "San Francisco", mun_code: "083751" },
  { label: "Silad", mun_code: "083751" },
  { label: "Sulpa", mun_code: "083751" },
  { label: "Tabunok", mun_code: "083751" },
  { label: "Tagbubunga", mun_code: "083751" },
  { label: "Tinghub", mun_code: "083751" },
  { label: "Bangcal", mun_code: "083751" },
  { label: "Canquiason", mun_code: "083751" },
  { label: "San Vicente", mun_code: "083751" },
  { label: "Santa Cruz", mun_code: "083751" },
  { label: "Suba", mun_code: "083751" },
  // { label: "Alejandro Village (Santiago)", mun_code: "084801" },
  // { label: "Bonifacio", mun_code: "084801" },
  // { label: "Cabacungan", mun_code: "084801" },
  // { label: "Calarayan", mun_code: "084801" },
  // { label: "Guin-arawayan", mun_code: "084801" },
  // { label: "Jubasan", mun_code: "084801" },
  // { label: "Kinabranan Zone I (Pob.)", mun_code: "084801" },
  // { label: "Kinaguitman", mun_code: "084801" },
  // { label: "Lagundi", mun_code: "084801" },
  // { label: "Lipata", mun_code: "084801" },
  // { label: "Londres", mun_code: "084801" },
  // { label: "Sabang Zone I (Pob.)", mun_code: "084801" },
  // { label: "Santa Rita", mun_code: "084801" },
  // { label: "Tasvilla", mun_code: "084801" },
  // { label: "Frederic", mun_code: "084801" },
  // { label: "Imelda", mun_code: "084801" },
  // { label: "Lo-oc", mun_code: "084801" },
  // { label: "Kinabranan Zone II (Pob.)", mun_code: "084801" },
  // { label: "Sabang Zone II (Pob.)", mun_code: "084801" },
  // { label: "Victoria", mun_code: "084801" },
  // { label: "Poblacion (Biri)", mun_code: "084802" },
  // { label: "MacArthur", mun_code: "084802" },
  // { label: "Kauswagan (Basud)", mun_code: "084802" },
  // { label: "Pio Del Pilar", mun_code: "084802" },
  // { label: "Progresso", mun_code: "084802" },
  // { label: "San Antonio", mun_code: "084802" },
  // { label: "San Pedro", mun_code: "084802" },
  // { label: "Acerida", mun_code: "084803" },
  // { label: "Arellano", mun_code: "084803" },
  // { label: "Balat-balud", mun_code: "084803" },
  // { label: "Dancalan", mun_code: "084803" },
  // { label: "E. Duran", mun_code: "084803" },
  // { label: "Gen. Lucban (Pob.)", mun_code: "084803" },
  // { label: "Jose Abad Santos", mun_code: "084803" },
  // { label: "Jose P. Laurel (Casulgan)", mun_code: "084803" },
  // { label: "Magsaysay (Doce)", mun_code: "084803" },
  // { label: "Calantiao (Pangobi-an)", mun_code: "084803" },
  // { label: "Quezon (Panicayan)", mun_code: "084803" },
  // { label: "Salvacion", mun_code: "084803" },
  // { label: "San Isidro", mun_code: "084803" },
  // { label: "San Juan (Pob.)", mun_code: "084803" },
  // { label: "Santa Clara (Pob.)", mun_code: "084803" },
  // { label: "Santander", mun_code: "084803" },
  // { label: "Somoroy", mun_code: "084803" },
  // { label: "Trojello", mun_code: "084803" },
  // { label: "Aguin", mun_code: "084804" },
  // { label: "Jubang", mun_code: "084804" },
  // { label: "Landusan", mun_code: "084804" },
  // { label: "Oson", mun_code: "084804" },
  // { label: "Poblacion Barangay 1", mun_code: "084804" },
  // { label: "Poblacion Barangay 2", mun_code: "084804" },
  // { label: "Poblacion Barangay 3", mun_code: "084804" },
  // { label: "Poblacion Barangay 4", mun_code: "084804" },
  // { label: "Poblacion Barangay 5", mun_code: "084804" },
  // { label: "Sagaosawan", mun_code: "084804" },
  // { label: "San Luis", mun_code: "084804" },
  // { label: "Sawang", mun_code: "084804" },
  // { label: "Aguinaldo", mun_code: "084805" },
  // { label: "Airport Village", mun_code: "084805" },
  // { label: "Baybay", mun_code: "084805" },
  // { label: "Bocsol", mun_code: "084805" },
  // { label: "Cabayhan", mun_code: "084805" },
  // { label: "Cag-abaca", mun_code: "084805" },
  // { label: "Cal-igang", mun_code: "084805" },
  // { label: "Cawayan", mun_code: "084805" },
  // { label: "Cervantes", mun_code: "084805" },
  // { label: "Cularima", mun_code: "084805" },
  // { label: "Daganas", mun_code: "084805" },
  // { label: "Galutan", mun_code: "084805" },
  // { label: "General Malvar", mun_code: "084805" },
  // { label: "Guba", mun_code: "084805" },
  // { label: "Gebalagnan (Hibalagnan)", mun_code: "084805" },
  // { label: "Gebulwangan", mun_code: "084805" },
  // { label: "Hinatad", mun_code: "084805" },
  // { label: "Imelda (Elimbo)", mun_code: "084805" },
  // { label: "Liberty", mun_code: "084805" },
  // { label: "Libjo (Binog)", mun_code: "084805" },
  // { label: "Mabini", mun_code: "084805" },
  // { label: "Macagtas", mun_code: "084805" },
  // { label: "Mckinley", mun_code: "084805" },
  // { label: "New Rizal", mun_code: "084805" },
  // { label: "Old Rizal", mun_code: "084805" },
  // { label: "Paticua", mun_code: "084805" },
  // { label: "Polangi", mun_code: "084805" },
  // { label: "Quezon", mun_code: "084805" },
  // { label: "Salvacion", mun_code: "084805" },
  // { label: "San Julian", mun_code: "084805" },
  // { label: "Somoge", mun_code: "084805" },
  // { label: "Tinowaran", mun_code: "084805" },
  // { label: "Trangue", mun_code: "084805" },
  // { label: "Washington", mun_code: "084805" },
  // { label: "UEP I", mun_code: "084805" },
  // { label: "UEP II", mun_code: "084805" },
  // { label: "UEP III", mun_code: "084805" },
  // { label: "Acacia (Pob.)", mun_code: "084805" },
  // { label: "Talisay (Pob.)", mun_code: "084805" },
  // { label: "Molave (Pob.)", mun_code: "084805" },
  // { label: "Yakal (Pob.)", mun_code: "084805" },
  // { label: "Ipil-ipil (Pob.)", mun_code: "084805" },
  // { label: "Jose Abad Santos (Pob.)", mun_code: "084805" },
  // { label: "Kasoy (Pob.)", mun_code: "084805" },
  // { label: "Lapu-lapu (Pob.)", mun_code: "084805" },
  // { label: "Santol (Pob.)", mun_code: "084805" },
  // { label: "Narra (Pob.)", mun_code: "084805" },
  // { label: "Calachuchi (Pob.)", mun_code: "084805" },
  // { label: "Sampaguita (Pob.)", mun_code: "084805" },
  // { label: "Mabolo (Pob.)", mun_code: "084805" },
  // { label: "Jose P. Rizal (Pob.)", mun_code: "084805" },
  // { label: "Bangkerohan", mun_code: "084805" },
  // { label: "Dalakit (Pob.)", mun_code: "084805" },
  // { label: "San Pascual", mun_code: "084805" },
  // { label: "Anongo", mun_code: "084806" },
  // { label: "D. Mercader (Bongog)", mun_code: "084806" },
  // { label: "Bonifacio", mun_code: "084806" },
  // { label: "Boring", mun_code: "084806" },
  // { label: "Cagbugna", mun_code: "084806" },
  // { label: "Cagmanaba", mun_code: "084806" },
  // { label: "Cagogobngan", mun_code: "084806" },
  // { label: "Calingnan", mun_code: "084806" },
  // { label: "Canuctan", mun_code: "084806" },
  // { label: "Guibwangan", mun_code: "084806" },
  // { label: "Hinagonoyan", mun_code: "084806" },
  // { label: "Hiparayan", mun_code: "084806" },
  // { label: "Hitapi-an", mun_code: "084806" },
  // { label: "Inoburan", mun_code: "084806" },
  // { label: "Irawahan", mun_code: "084806" },
  // { label: "Libon", mun_code: "084806" },
  // { label: "Claro M. Recto (Lobedico)", mun_code: "084806" },
  // { label: "Lenoyahan", mun_code: "084806" },
  // { label: "Magongon", mun_code: "084806" },
  // { label: "Magtuad", mun_code: "084806" },
  // { label: "Manering", mun_code: "084806" },
  // { label: "Nabulo", mun_code: "084806" },
  // { label: "Nagoocan", mun_code: "084806" },
  // { label: "Nahulid", mun_code: "084806" },
  // { label: "Opong", mun_code: "084806" },
  // { label: "Osang", mun_code: "084806" },
  // { label: "P. Rebadulla", mun_code: "084806" },
  // { label: "Roxas", mun_code: "084806" },
  // { label: "Sagudsuron", mun_code: "084806" },
  // { label: "San Antonio", mun_code: "084806" },
  // { label: "San Francisco", mun_code: "084806" },
  // { label: "San Jose (Hebobollao)", mun_code: "084806" },
  // { label: "San Vicente", mun_code: "084806" },
  // { label: "Santa Fe", mun_code: "084806" },
  // { label: "Sulitan", mun_code: "084806" },
  // { label: "Tangbo", mun_code: "084806" },
  // { label: "Tungodnon", mun_code: "084806" },
  // { label: "Vienna Maria", mun_code: "084806" },
  // { label: "Barangay 1 (Pob.)", mun_code: "084806" },
  // { label: "Barangay 2 (Pob.)", mun_code: "084806" },
  // { label: "Barangay 3 (Pob.)", mun_code: "084806" },
  // { label: "Barangay 4 (Pob.)", mun_code: "084806" },
  // { label: "Barangay 5 (Pob.)", mun_code: "084806" },
  // { label: "Barangay 6 (Pob.)", mun_code: "084806" },
  // { label: "Barangay 7 (Pob.)", mun_code: "084806" },
  // { label: "Barangay 8 (Pob.)", mun_code: "084806" },
  // { label: "Anito", mun_code: "084807" },
  // { label: "Bangon", mun_code: "084807" },
  // { label: "Bato", mun_code: "084807" },
  // { label: "Bonifacio", mun_code: "084807" },
  // { label: "Cabarasan", mun_code: "084807" },
  // { label: "Cadac-an (Calingnan)", mun_code: "084807" },
  // { label: "Cade-an", mun_code: "084807" },
  // { label: "Cagamutan del Norte", mun_code: "084807" },
  // { label: "Dao", mun_code: "084807" },
  // { label: "G. M. Osias", mun_code: "084807" },
  // { label: "Guibuangan", mun_code: "084807" },
  // { label: "Henogawe", mun_code: "084807" },
  // { label: "Lonoy", mun_code: "084807" },
  // { label: "Luneta", mun_code: "084807" },
  // { label: "Malidong", mun_code: "084807" },
  // { label: "Gamay Central (Pob.)", mun_code: "084807" },
  // { label: "Gamay Occidental I (Pob.)", mun_code: "084807" },
  // { label: "Gamay Oriental I (Pob.)", mun_code: "084807" },
  // { label: "Rizal", mun_code: "084807" },
  // { label: "San Antonio", mun_code: "084807" },
  // { label: "Baybay District (Pob.)", mun_code: "084807" },
  // { label: "Burabod (Pob.)", mun_code: "084807" },
  // { label: "Cagamutan del Sur", mun_code: "084807" },
  // { label: "Libertad (Pob.)", mun_code: "084807" },
  // { label: "Occidental II (Pob.)", mun_code: "084807" },
  // { label: "Oriental II (Pob.)", mun_code: "084807" },
  // { label: "Abaton", mun_code: "084808" },
  // { label: "Aguadahan", mun_code: "084808" },
  // { label: "Aroganga", mun_code: "084808" },
  // { label: "Atipolo", mun_code: "084808" },
  // { label: "Bawang", mun_code: "084808" },
  // { label: "Baybay (Pob.)", mun_code: "084808" },
  // { label: "Binatiklan", mun_code: "084808" },
  // { label: "Bobolosan", mun_code: "084808" },
  // { label: "Bongliw", mun_code: "084808" },
  // { label: "Burabud", mun_code: "084808" },
  // { label: "Cabadiangan", mun_code: "084808" },
  // { label: "Cabagngan", mun_code: "084808" },
  // { label: "Cabago-an", mun_code: "084808" },
  // { label: "Cabulaloan", mun_code: "084808" },
  // { label: "Cagaasan", mun_code: "084808" },
  // { label: "Cagdara-o", mun_code: "084808" },
  // { label: "Cahayagan", mun_code: "084808" },
  // { label: "Calomotan", mun_code: "084808" },
  // { label: "Candawid", mun_code: "084808" },
  // { label: "Cangcahipos", mun_code: "084808" },
  // { label: "Canyomanao", mun_code: "084808" },
  // { label: "Catigbian", mun_code: "084808" },
  // { label: "E. J. Dulay", mun_code: "084808" },
  // { label: "G. B. Tan", mun_code: "084808" },
  // { label: "Gibatangan", mun_code: "084808" },
  // { label: "Guilaoangi (Pob.)", mun_code: "084808" },
  // { label: "Inamlan", mun_code: "084808" },
  // { label: "La Perla", mun_code: "084808" },
  // { label: "Langob", mun_code: "084808" },
  // { label: "Lawaan", mun_code: "084808" },
  // { label: "Little Venice (Pob.)", mun_code: "084808" },
  // { label: "Magsaysay", mun_code: "084808" },
  // { label: "Marubay", mun_code: "084808" },
  // { label: "Mualbual", mun_code: "084808" },
  // { label: "Napotiocan (Salvacion)", mun_code: "084808" },
  // { label: "Oleras", mun_code: "084808" },
  // { label: "Palmera", mun_code: "084808" },
  // { label: "Pangdan", mun_code: "084808" },
  // { label: "Rawis", mun_code: "084808" },
  // { label: "Rombang", mun_code: "084808" },
  // { label: "San Antonio (Son-og)", mun_code: "084808" },
  // { label: "San Miguel Heights (Pob.)", mun_code: "084808" },
  // { label: "Sangcol", mun_code: "084808" },
  // { label: "Sibunot", mun_code: "084808" },
  // { label: "Simora", mun_code: "084808" },
  // { label: "Suba", mun_code: "084808" },
  // { label: "Tan-awan", mun_code: "084808" },
  // { label: "Tarusan", mun_code: "084808" },
  // { label: "Tinoblan", mun_code: "084808" },
  // { label: "Tumaguingting (Pob.)", mun_code: "084808" },
  // { label: "Vigo", mun_code: "084808" },
  // { label: "Yabyaban (San Vicente)", mun_code: "084808" },
  // { label: "Yapas", mun_code: "084808" },
  // { label: "Talisay", mun_code: "084808" },
  // { label: "Alang-alang", mun_code: "084809" },
  // { label: "Bagacay", mun_code: "084809" },
  // { label: "Cahagwayan", mun_code: "084809" },
  // { label: "Can Maria", mun_code: "084809" },
  // { label: "Can Omanio", mun_code: "084809" },
  // { label: "Imelda", mun_code: "084809" },
  // { label: "Lapinig Del Sur (Pob.)", mun_code: "084809" },
  // { label: "Lapinig Del Norte (Pob.)", mun_code: "084809" },
  // { label: "Lo-ok", mun_code: "084809" },
  // { label: "Mabini", mun_code: "084809" },
  // { label: "May-igot", mun_code: "084809" },
  // { label: "Palanas", mun_code: "084809" },
  // { label: "Pio Del Pilar", mun_code: "084809" },
  // { label: "Potong", mun_code: "084809" },
  // { label: "Potong Del Sur", mun_code: "084809" },
  // { label: "Balugo", mun_code: "084810" },
  // { label: "Bugay", mun_code: "084810" },
  // { label: "Bugtosan", mun_code: "084810" },
  // { label: "Bukid", mun_code: "084810" },
  // { label: "Bulao", mun_code: "084810" },
  // { label: "Caputoan", mun_code: "084810" },
  // { label: "Catoto-ogan", mun_code: "084810" },
  // { label: "Cuenco", mun_code: "084810" },
  // { label: "Dapdap", mun_code: "084810" },
  // { label: "Del Pilar", mun_code: "084810" },
  // { label: "Dolores", mun_code: "084810" },
  // { label: "Epaw", mun_code: "084810" },
  // { label: "Geguinta", mun_code: "084810" },
  // { label: "Geracdo", mun_code: "084810" },
  // { label: "Guyo", mun_code: "084810" },
  // { label: "H. Jolejole District (Pob.)", mun_code: "084810" },
  // { label: "Hangi", mun_code: "084810" },
  // { label: "Imelda", mun_code: "084810" },
  // { label: "L. Empon", mun_code: "084810" },
  // { label: "Lakandula", mun_code: "084810" },
  // { label: "Lumala-og", mun_code: "084810" },
  // { label: "Lourdes", mun_code: "084810" },
  // { label: "Mabini", mun_code: "084810" },
  // { label: "Macarthur", mun_code: "084810" },
  // { label: "Magsaysay", mun_code: "084810" },
  // { label: "Matelarag", mun_code: "084810" },
  // { label: "Paco", mun_code: "084810" },
  // { label: "Palanas", mun_code: "084810" },
  // { label: "Perez", mun_code: "084810" },
  // { label: "Poponton", mun_code: "084810" },
  // { label: "Quezon", mun_code: "084810" },
  // { label: "Quirino", mun_code: "084810" },
  // { label: "Quirino District (Pob.)", mun_code: "084810" },
  // { label: "Rebong", mun_code: "084810" },
  // { label: "Rizal", mun_code: "084810" },
  // { label: "Roxas", mun_code: "084810" },
  // { label: "Rufino", mun_code: "084810" },
  // { label: "Sag-od", mun_code: "084810" },
  // { label: "San Andres", mun_code: "084810" },
  // { label: "San Antonio", mun_code: "084810" },
  // { label: "San Fernando", mun_code: "084810" },
  // { label: "San Francisco", mun_code: "084810" },
  // { label: "San Isidro", mun_code: "084810" },
  // { label: "San Jorge", mun_code: "084810" },
  // { label: "San Jose", mun_code: "084810" },
  // { label: "San Miguel", mun_code: "084810" },
  // { label: "Santo Tomas", mun_code: "084810" },
  // { label: "Tagab-iran", mun_code: "084810" },
  // { label: "Tagan-ayan", mun_code: "084810" },
  // { label: "Taylor", mun_code: "084810" },
  // { label: "Victory", mun_code: "084810" },
  // { label: "H. Jolejole", mun_code: "084810" },
  // { label: "Balicuatro", mun_code: "084811" },
  // { label: "Bani", mun_code: "084811" },
  // { label: "Barobaybay", mun_code: "084811" },
  // { label: "Caburihan (Pob.)", mun_code: "084811" },
  // { label: "Caragas (Pob.)", mun_code: "084811" },
  // { label: "Cataogan (Pob.)", mun_code: "084811" },
  // { label: "Chansvilla", mun_code: "084811" },
  // { label: "Datag", mun_code: "084811" },
  // { label: "Enriqueta", mun_code: "084811" },
  // { label: "Libas", mun_code: "084811" },
  // { label: "Libertad", mun_code: "084811" },
  // { label: "Macarthur", mun_code: "084811" },
  // { label: "Magsaysay", mun_code: "084811" },
  // { label: "Maravilla", mun_code: "084811" },
  // { label: "Ocad (Pob.)", mun_code: "084811" },
  // { label: "Salvacion", mun_code: "084811" },
  // { label: "San Agustin", mun_code: "084811" },
  // { label: "San Isidro", mun_code: "084811" },
  // { label: "San Jose", mun_code: "084811" },
  // { label: "San Juan", mun_code: "084811" },
  // { label: "San Miguel", mun_code: "084811" },
  // { label: "To-og", mun_code: "084811" },
  // { label: "Urdaneta", mun_code: "084811" },
  // { label: "Villa", mun_code: "084811" },
  // { label: "Villahermosa", mun_code: "084811" },
  // { label: "Sabong-Tabok", mun_code: "084811" },
  // { label: "Burgos", mun_code: "084812" },
  // { label: "Jubasan", mun_code: "084812" },
  // { label: "Magsaysay", mun_code: "084812" },
  // { label: "Magtaon", mun_code: "084812" },
  // { label: "Del Norte (Pob.)", mun_code: "084812" },
  // { label: "Del Sur (Pob.)", mun_code: "084812" },
  // { label: "Quezon", mun_code: "084812" },
  // { label: "San Jose", mun_code: "084812" },
  // { label: "Siljagon", mun_code: "084812" },
  // { label: "Naparasan", mun_code: "084812" },
  // { label: "E. Laodenio", mun_code: "084812" },
  // { label: "Manaybanay", mun_code: "084812" },
  // { label: "Santa Potenciana (Pob.)", mun_code: "084812" },
  // { label: "Bagasbas", mun_code: "084813" },
  // { label: "Bugko", mun_code: "084813" },
  // { label: "Cablangan", mun_code: "084813" },
  // { label: "Cagmanaba", mun_code: "084813" },
  // { label: "Cahicsan", mun_code: "084813" },
  // { label: "Chitongco (Pob.)", mun_code: "084813" },
  // { label: "De Maria", mun_code: "084813" },
  // { label: "Eco (Pob.)", mun_code: "084813" },
  // { label: "Flormina", mun_code: "084813" },
  // { label: "Hinabangan", mun_code: "084813" },
  // { label: "Imelda", mun_code: "084813" },
  // { label: "La Trinidad", mun_code: "084813" },
  // { label: "Makiwalo", mun_code: "084813" },
  // { label: "Mirador", mun_code: "084813" },
  // { label: "Nenita", mun_code: "084813" },
  // { label: "Roxas", mun_code: "084813" },
  // { label: "San Agustin", mun_code: "084813" },
  // { label: "San Antonio", mun_code: "084813" },
  // { label: "San Isidro", mun_code: "084813" },
  // { label: "San Jose", mun_code: "084813" },
  // { label: "San Juan", mun_code: "084813" },
  // { label: "Santa Catalina", mun_code: "084813" },
  // { label: "Talolora", mun_code: "084813" },
  // { label: "Asum (Pob.)", mun_code: "084814" },
  // { label: "Bagacay", mun_code: "084814" },
  // { label: "Bangon", mun_code: "084814" },
  // { label: "Binay", mun_code: "084814" },
  // { label: "Cabariwan", mun_code: "084814" },
  // { label: "Cabatuan", mun_code: "084814" },
  // { label: "Campedico", mun_code: "084814" },
  // { label: "Capacujan", mun_code: "084814" },
  // { label: "Jangtud", mun_code: "084814" },
  // { label: "Laniwan (Pob.)", mun_code: "084814" },
  // { label: "Mabaras", mun_code: "084814" },
  // { label: "Magsaysay", mun_code: "084814" },
  // { label: "Manajao", mun_code: "084814" },
  // { label: "Mapno", mun_code: "084814" },
  // { label: "Maragano", mun_code: "084814" },
  // { label: "Matambag", mun_code: "084814" },
  // { label: "Monbon", mun_code: "084814" },
  // { label: "Nagbobtac", mun_code: "084814" },
  // { label: "Napo", mun_code: "084814" },
  // { label: "Natawo", mun_code: "084814" },
  // { label: "Nipa", mun_code: "084814" },
  // { label: "Pangpang", mun_code: "084814" },
  // { label: "Paysud", mun_code: "084814" },
  // { label: "Sangay", mun_code: "084814" },
  // { label: "Simora", mun_code: "084814" },
  // { label: "Sinalaran", mun_code: "084814" },
  // { label: "Sumoroy", mun_code: "084814" },
  // { label: "Talolora", mun_code: "084814" },
  // { label: "Tambangan (Pob.)", mun_code: "084814" },
  // { label: "Tinampo (Pob.)", mun_code: "084814" },
  // { label: "Benigno S. Aquino, Jr.", mun_code: "084814" },
  // { label: "Cababto-an", mun_code: "084815" },
  // { label: "Cabari-an", mun_code: "084815" },
  // { label: "Cagbigajo", mun_code: "084815" },
  // { label: "Canjumadal", mun_code: "084815" },
  // { label: "Camparanga", mun_code: "084815" },
  // { label: "Ge-adgawan", mun_code: "084815" },
  // { label: "Ginulgan", mun_code: "084815" },
  // { label: "Geparayan", mun_code: "084815" },
  // { label: "Igot", mun_code: "084815" },
  // { label: "Ynaguingayan", mun_code: "084815" },
  // { label: "Inanahawan", mun_code: "084815" },
  // { label: "Manahao", mun_code: "084815" },
  // { label: "Paninirongan", mun_code: "084815" },
  // { label: "Poblacion District 1", mun_code: "084815" },
  // { label: "Poblacion District 2", mun_code: "084815" },
  // { label: "Poblacion District 3", mun_code: "084815" },
  // { label: "Poblacion District 4", mun_code: "084815" },
  // { label: "Poblacion District 5", mun_code: "084815" },
  // { label: "Poblacion District 6", mun_code: "084815" },
  // { label: "Poblacion District 7", mun_code: "084815" },
  // { label: "Poblacion District 8", mun_code: "084815" },
  // { label: "San Ramon", mun_code: "084815" },
  // { label: "Senonogan", mun_code: "084815" },
  // { label: "Sixto T. Balanguit, Sr.", mun_code: "084815" },
  // { label: "Tula", mun_code: "084815" },
  // { label: "Aguada", mun_code: "084816" },
  // { label: "Buenavista", mun_code: "084816" },
  // { label: "Jamoog", mun_code: "084816" },
  // { label: "Ligaya", mun_code: "084816" },
  // { label: "Poblacion", mun_code: "084816" },
  // { label: "Salhag", mun_code: "084816" },
  // { label: "San Lorenzo", mun_code: "084816" },
  // { label: "Bantolinao", mun_code: "084816" },
  // { label: "Commonwealth", mun_code: "084816" },
  // { label: "Guindaulan", mun_code: "084816" },
  // { label: "Kailingan", mun_code: "084816" },
  // { label: "Burabod", mun_code: "084817" },
  // { label: "Dalupirit", mun_code: "084817" },
  // { label: "Manraya", mun_code: "084817" },
  // { label: "Pilar", mun_code: "084817" },
  // { label: "Ward I (Pob.)", mun_code: "084817" },
  // { label: "Rizal", mun_code: "084817" },
  // { label: "San Nicolas", mun_code: "084817" },
  // { label: "Vinisitahan", mun_code: "084817" },
  // { label: "Ward II (Pob.)", mun_code: "084817" },
  // { label: "Ward III (Pob.)", mun_code: "084817" },
  // { label: "Alegria", mun_code: "084818" },
  // { label: "Balite", mun_code: "084818" },
  // { label: "Buenavista", mun_code: "084818" },
  // { label: "Caglanipao", mun_code: "084818" },
  // { label: "Happy Valley", mun_code: "084818" },
  // { label: "Mabuhay", mun_code: "084818" },
  // { label: "Palanit", mun_code: "084818" },
  // { label: "Poblacion Norte", mun_code: "084818" },
  // { label: "Poblacion Sur", mun_code: "084818" },
  // { label: "Salvacion", mun_code: "084818" },
  // { label: "San Juan", mun_code: "084818" },
  // { label: "San Roque", mun_code: "084818" },
  // { label: "Seven Hills", mun_code: "084818" },
  // { label: "Veriato", mun_code: "084818" },
  // { label: "Aguadahan", mun_code: "084819" },
  // { label: "Bagong Sabang", mun_code: "084819" },
  // { label: "Balite", mun_code: "084819" },
  // { label: "Bonglas", mun_code: "084819" },
  // { label: "Da-o", mun_code: "084819" },
  // { label: "Gengarog", mun_code: "084819" },
  // { label: "Geratag", mun_code: "084819" },
  // { label: "Layuhan", mun_code: "084819" },
  // { label: "Mandugang", mun_code: "084819" },
  // { label: "P. Tingzon", mun_code: "084819" },
  // { label: "San Lorenzo", mun_code: "084819" },
  // { label: "Tubigdanao", mun_code: "084819" },
  // { label: "Barangay North (Pob.)", mun_code: "084819" },
  // { label: "Barangay South (Pob.)", mun_code: "084819" },
  // { label: "Barangay East (Pob.)", mun_code: "084819" },
  // { label: "Barangay West (Pob.)", mun_code: "084819" },
  // { label: "Balnasan", mun_code: "084820" },
  // { label: "Balud", mun_code: "084820" },
  // { label: "Bantayan", mun_code: "084820" },
  // { label: "Coroconog", mun_code: "084820" },
  // { label: "Dale", mun_code: "084820" },
  // { label: "Ginagdanan", mun_code: "084820" },
  // { label: "Lao-angan", mun_code: "084820" },
  // { label: "Lawaan", mun_code: "084820" },
  // { label: "Malobago", mun_code: "084820" },
  // { label: "Pagsang-an", mun_code: "084820" },
  // { label: "Zone 1 (Pob.)", mun_code: "084820" },
  // { label: "Zone 2 (Pob.)", mun_code: "084820" },
  // { label: "Zone 3 (Pob.)", mun_code: "084820" },
  // { label: "Zone 4 (Pob.)", mun_code: "084820" },
  // { label: "Zone 5 (Pob.)", mun_code: "084820" },
  // { label: "Zone 6 (Pob.)", mun_code: "084820" },
  // { label: "Maragat", mun_code: "084821" },
  // { label: "Mongol Bongol Pob. (Bgy.1)", mun_code: "084821" },
  // { label: "Sangputan", mun_code: "084821" },
  // { label: "Sila", mun_code: "084821" },
  // { label: "Tarnate", mun_code: "084821" },
  // { label: "Destacado Pob. (Bgy.2)", mun_code: "084821" },
  // { label: "Punta Pob. (Bgy.3)", mun_code: "084821" },
  // { label: "Balud", mun_code: "084822" },
  // { label: "Cababayogan", mun_code: "084822" },
  // { label: "Cabunga-an", mun_code: "084822" },
  // { label: "Cagda-o", mun_code: "084822" },
  // { label: "Caghilot", mun_code: "084822" },
  // { label: "Camanggaran", mun_code: "084822" },
  // { label: "Camaya-an", mun_code: "084822" },
  // { label: "Deit De Suba", mun_code: "084822" },
  // { label: "Deit De Turag", mun_code: "084822" },
  // { label: "Gebonawan", mun_code: "084822" },
  // { label: "Gebolwangan", mun_code: "084822" },
  // { label: "Gecbo-an", mun_code: "084822" },
  // { label: "Giguimitan", mun_code: "084822" },
  // { label: "Genagasan", mun_code: "084822" },
  // { label: "Geparayan De Turag", mun_code: "084822" },
  // { label: "Gusaran", mun_code: "084822" },
  // { label: "Imelda", mun_code: "084822" },
  // { label: "Montalban", mun_code: "084822" },
  // { label: "Suba (Pob.)", mun_code: "084822" },
  // { label: "San Isidro", mun_code: "084822" },
  // { label: "Senonogan de Tubang", mun_code: "084822" },
  // { label: "Tobgon", mun_code: "084822" },
  // { label: "Victory", mun_code: "084822" },
  // { label: "Poblacion I", mun_code: "084822" },
  // { label: "Poblacion II", mun_code: "084822" },
  // { label: "San Antonio", mun_code: "084822" },
  // { label: "Acedillo", mun_code: "084823" },
  // { label: "Buenasuerte", mun_code: "084823" },
  // { label: "Buenos Aires", mun_code: "084823" },
  // { label: "Colab-og", mun_code: "084823" },
  // { label: "Erenas", mun_code: "084823" },
  // { label: "Libertad", mun_code: "084823" },
  // { label: "Luisita", mun_code: "084823" },
  // { label: "Lungib", mun_code: "084823" },
  // { label: "Maxvilla", mun_code: "084823" },
  // { label: "Pasabuena", mun_code: "084823" },
  // { label: "Zone I (Pob.)", mun_code: "084823" },
  // { label: "Zone II (Pob.)", mun_code: "084823" },
  // { label: "Zone III (Pob.)", mun_code: "084823" },
  // { label: "San Lazaro", mun_code: "084823" },
  // { label: "San Miguel", mun_code: "084823" },
  // { label: "San Roman", mun_code: "084823" },
  // { label: "Bayho", mun_code: "084824" },
  // { label: "Bonifacio", mun_code: "084824" },
  // { label: "Cagamesarag", mun_code: "084824" },
  // { label: "Cag-aguingay", mun_code: "084824" },
  // { label: "Curry", mun_code: "084824" },
  // { label: "Gebonawan", mun_code: "084824" },
  // { label: "Gen. Luna", mun_code: "084824" },
  // { label: "Getigo", mun_code: "084824" },
  // { label: "Henaronagan", mun_code: "084824" },
  // { label: "Lope De Vega (Pob.)", mun_code: "084824" },
  // { label: "Lower Caynaga", mun_code: "084824" },
  // { label: "Maghipid", mun_code: "084824" },
  // { label: "Magsaysay", mun_code: "084824" },
  // { label: "Paguite", mun_code: "084824" },
  // { label: "Roxas", mun_code: "084824" },
  // { label: "Sampaguita", mun_code: "084824" },
  // { label: "San Francisco", mun_code: "084824" },
  // { label: "San Jose", mun_code: "084824" },
  // { label: "San Miguel", mun_code: "084824" },
  // { label: "Somoroy", mun_code: "084824" },
  // { label: "Upper Caynaga", mun_code: "084824" },
  // { label: "Bacjao", mun_code: "086001" },
  // { label: "Biasong I", mun_code: "086001" },
  // { label: "Costa Rica", mun_code: "086001" },
  // { label: "Guin-ansan", mun_code: "086001" },
  // { label: "Kerikite", mun_code: "086001" },
  // { label: "Lunang I (Look)", mun_code: "086001" },
  // { label: "Lunang II", mun_code: "086001" },
  // { label: "Malobago", mun_code: "086001" },
  // { label: "Marasbaras", mun_code: "086001" },
  // { label: "Panjobjoban I", mun_code: "086001" },
  // { label: "Poblacion", mun_code: "086001" },
  // { label: "Talahid", mun_code: "086001" },
  // { label: "Tonga-tonga", mun_code: "086001" },
  // { label: "Imelda (Badjang)", mun_code: "086001" },
  // { label: "Biasong II", mun_code: "086001" },
  // { label: "Costa Rica II", mun_code: "086001" },
  // { label: "Mabuhay", mun_code: "086001" },
  // { label: "Magsaysay", mun_code: "086001" },
  // { label: "Panjobjoban II", mun_code: "086001" },
  // { label: "San Isidro", mun_code: "086001" },
  // { label: "San Jose", mun_code: "086001" },
  // { label: "Veloso", mun_code: "086001" },
  // { label: "Amandayehan", mun_code: "086002" },
  // { label: "Anglit", mun_code: "086002" },
  // { label: "Bacubac", mun_code: "086002" },
  // { label: "Baloog", mun_code: "086002" },
  // { label: "Basiao", mun_code: "086002" },
  // { label: "Buenavista", mun_code: "086002" },
  // { label: "Burgos", mun_code: "086002" },
  // { label: "Cambayan", mun_code: "086002" },
  // { label: "Can-abay", mun_code: "086002" },
  // { label: "Cancaiyas", mun_code: "086002" },
  // { label: "Canmanila", mun_code: "086002" },
  // { label: "Catadman", mun_code: "086002" },
  // { label: "Cogon", mun_code: "086002" },
  // { label: "Dolongan", mun_code: "086002" },
  // { label: "Guintigui-an", mun_code: "086002" },
  // { label: "Guirang", mun_code: "086002" },
  // { label: "Balante", mun_code: "086002" },
  // { label: "Iba", mun_code: "086002" },
  // { label: "Inuntan", mun_code: "086002" },
  // { label: "Loog", mun_code: "086002" },
  // { label: "Mabini", mun_code: "086002" },
  // { label: "Magallanes", mun_code: "086002" },
  // { label: "Manlilinab", mun_code: "086002" },
  // { label: "Del Pilar", mun_code: "086002" },
  // { label: "May-it", mun_code: "086002" },
  // { label: "Mongabong", mun_code: "086002" },
  // { label: "New San Agustin", mun_code: "086002" },
  // { label: "Nouvelas Occidental", mun_code: "086002" },
  // { label: "San Fernando (Nouvelas Oriental)", mun_code: "086002" },
  // { label: "Old San Agustin", mun_code: "086002" },
  // { label: "Panugmonon", mun_code: "086002" },
  // { label: "Pelit", mun_code: "086002" },
  // { label: "Baybay (Pob.)", mun_code: "086002" },
  // { label: "Buscada (Pob.)", mun_code: "086002" },
  // { label: "Lawa-an (Pob.)", mun_code: "086002" },
  // { label: "Loyo (Pob.)", mun_code: "086002" },
  // { label: "Mercado (Pob.)", mun_code: "086002" },
  // { label: "Palaypay (Pob.)", mun_code: "086002" },
  // { label: "Sulod (Pob.)", mun_code: "086002" },
  // { label: "Roxas", mun_code: "086002" },
  // { label: "Salvacion", mun_code: "086002" },
  // { label: "San Antonio", mun_code: "086002" },
  // { label: "Sawa", mun_code: "086002" },
  // { label: "Serum", mun_code: "086002" },
  // { label: "Sugca", mun_code: "086002" },
  // { label: "Sugponon", mun_code: "086002" },
  // { label: "Tinaogan", mun_code: "086002" },
  // { label: "Tingib", mun_code: "086002" },
  // { label: "Villa Aurora", mun_code: "086002" },
  // { label: "Binongtu-an", mun_code: "086002" },
  // { label: "Bulao", mun_code: "086002" },
  // { label: "Acedillo", mun_code: "086003" },
  // { label: "Alibaba", mun_code: "086003" },
  // { label: "Amampacang", mun_code: "086003" },
  // { label: "Anislag", mun_code: "086003" },
  // { label: "Ba-ay", mun_code: "086003" },
  // { label: "Bagacay", mun_code: "086003" },
  // { label: "Baja", mun_code: "086003" },
  // { label: "Balud (Pob.)", mun_code: "086003" },
  // { label: "Bante", mun_code: "086003" },
  // { label: "Bantian", mun_code: "086003" },
  // { label: "Basud", mun_code: "086003" },
  // { label: "Bayo", mun_code: "086003" },
  // { label: "Begaho", mun_code: "086003" },
  // { label: "Binaliw", mun_code: "086003" },
  // { label: "Bugtong", mun_code: "086003" },
  // { label: "Buenavista", mun_code: "086003" },
  // { label: "Cabacungan", mun_code: "086003" },
  // { label: "Cabatuan", mun_code: "086003" },
  // { label: "Cabicahan", mun_code: "086003" },
  // { label: "Cabugawan", mun_code: "086003" },
  // { label: "Cacaransan", mun_code: "086003" },
  // { label: "Cag-olango", mun_code: "086003" },
  // { label: "Cag-anahaw", mun_code: "086003" },
  // { label: "Cagbanayacao", mun_code: "086003" },
  // { label: "Cagbayang", mun_code: "086003" },
  // { label: "Cagbilwang", mun_code: "086003" },
  // { label: "Cagboborac", mun_code: "086003" },
  // { label: "Caglanipao Sur", mun_code: "086003" },
  // { label: "Cagmanipes Norte", mun_code: "086003" },
  // { label: "Cag-anibong", mun_code: "086003" },
  // { label: "Cagnipa", mun_code: "086003" },
  // { label: "Cagsalaosao", mun_code: "086003" },
  // { label: "Cahumpan", mun_code: "086003" },
  // { label: "Calocnayan", mun_code: "086003" },
  // { label: "Cangomaod", mun_code: "086003" },
  // { label: "Canhumadac", mun_code: "086003" },
  // { label: "Capacuhan", mun_code: "086003" },
  // { label: "Capoocan", mun_code: "086003" },
  // { label: "Carayman", mun_code: "086003" },
  // { label: "Catabunan", mun_code: "086003" },
  // { label: "Caybago", mun_code: "086003" },
  // { label: "Central (Pob.)", mun_code: "086003" },
  // { label: "Cogon", mun_code: "086003" },
  // { label: "Dagum", mun_code: "086003" },
  // { label: "Dawo", mun_code: "086003" },
  // { label: "De Victoria", mun_code: "086003" },
  // { label: "Dinabongan", mun_code: "086003" },
  // { label: "Dinagan", mun_code: "086003" },
  // { label: "Dinawacan", mun_code: "086003" },
  // { label: "Esperanza", mun_code: "086003" },
  // { label: "Gadgaran", mun_code: "086003" },
  // { label: "Gasdo", mun_code: "086003" },
  // { label: "Helino", mun_code: "086003" },
  // { label: "Geraga-an", mun_code: "086003" },
  // { label: "Guin-on", mun_code: "086003" },
  // { label: "Guimbaoyan Norte", mun_code: "086003" },
  // { label: "Guimbaoyan Sur", mun_code: "086003" },
  // { label: "Hamorawon", mun_code: "086003" },
  // { label: "Hibabngan", mun_code: "086003" },
  // { label: "Hibatang", mun_code: "086003" },
  // { label: "Higasaan", mun_code: "086003" },
  // { label: "Himalandrog", mun_code: "086003" },
  // { label: "Jimautan", mun_code: "086003" },
  // { label: "Hugon Rosales", mun_code: "086003" },
  // { label: "Jacinto", mun_code: "086003" },
  // { label: "Aguit-itan (Pob.)", mun_code: "086003" },
  // { label: "Kilikili", mun_code: "086003" },
  // { label: "La Paz", mun_code: "086003" },
  // { label: "Langoyon", mun_code: "086003" },
  // { label: "Lapaan", mun_code: "086003" },
  // { label: "Libertad", mun_code: "086003" },
  // { label: "Limarayon", mun_code: "086003" },
  // { label: "Looc", mun_code: "086003" },
  // { label: "Longsob", mun_code: "086003" },
  // { label: "Lonoy", mun_code: "086003" },
  // { label: "Mabini I (Calbayog District)", mun_code: "086003" },
  // { label: "Mabini II (Oquendo District)", mun_code: "086003" },
  // { label: "Macatingog", mun_code: "086003" },
  // { label: "Mag-ubay", mun_code: "086003" },
  // { label: "Maguino-o", mun_code: "086003" },
  // { label: "Malaga", mun_code: "086003" },
  // { label: "Malajog", mun_code: "086003" },
  // { label: "Malayog", mun_code: "086003" },
  // { label: "Malopalo", mun_code: "086003" },
  // { label: "Marcatubig", mun_code: "086003" },
  // { label: "Mancol", mun_code: "086003" },
  // { label: "Mantaong (Oquendo District)", mun_code: "086003" },
  // { label: "Matobato", mun_code: "086003" },
  // { label: "Mawacat", mun_code: "086003" },
  // { label: "Maybog", mun_code: "086003" },
  // { label: "Maysalong", mun_code: "086003" },
  // { label: "Migara", mun_code: "086003" },
  // { label: "Nabang", mun_code: "086003" },
  // { label: "Naga", mun_code: "086003" },
  // { label: "Naguma", mun_code: "086003" },
  // { label: "Navarro", mun_code: "086003" },
  // { label: "Nijaga", mun_code: "086003" },
  // { label: "Oboob", mun_code: "086003" },
  // { label: "Obrero", mun_code: "086003" },
  // { label: "Olera", mun_code: "086003" },
  // { label: "Oquendo (Pob.)", mun_code: "086003" },
  // { label: "Palanas", mun_code: "086003" },
  // { label: "Palanogan", mun_code: "086003" },
  // { label: "Panlayahan", mun_code: "086003" },
  // { label: "Panonongan", mun_code: "086003" },
  // { label: "Panoypoy", mun_code: "086003" },
  // { label: "Patong", mun_code: "086003" },
  // { label: "Pilar", mun_code: "086003" },
  // { label: "Pinamorotan", mun_code: "086003" },
  // { label: "Quezon", mun_code: "086003" },
  // { label: "Rawis", mun_code: "086003" },
  // { label: "Rizal I (Calbayog District)", mun_code: "086003" },
  // { label: "Rizal II (Oquendo District)", mun_code: "086003" },
  // { label: "Roxas I (Calbayog District)", mun_code: "086003" },
  // { label: "Roxas II (Oquendo District)", mun_code: "086003" },
  // { label: "Saljag (Baut)", mun_code: "086003" },
  // { label: "Salvacion", mun_code: "086003" },
  // { label: "San Antonio", mun_code: "086003" },
  // { label: "San Isidro", mun_code: "086003" },
  // { label: "San Joaquin", mun_code: "086003" },
  // { label: "San Jose", mun_code: "086003" },
  // { label: "San Policarpio", mun_code: "086003" },
  // { label: "San Rufino", mun_code: "086003" },
  // { label: "Saputan", mun_code: "086003" },
  // { label: "Sigo", mun_code: "086003" },
  // { label: "Sinantan", mun_code: "086003" },
  // { label: "Sinidman Occidental", mun_code: "086003" },
  // { label: "Sinidman Oriental", mun_code: "086003" },
  // { label: "Tabawan", mun_code: "086003" },
  // { label: "Talahiban", mun_code: "086003" },
  // { label: "Tapa-e", mun_code: "086003" },
  // { label: "Tarabucan", mun_code: "086003" },
  // { label: "Tigbe", mun_code: "086003" },
  // { label: "Tinaplacan", mun_code: "086003" },
  // { label: "Tomaliguez", mun_code: "086003" },
  // { label: "Trinidad (Sabang)", mun_code: "086003" },
  // { label: "Victory", mun_code: "086003" },
  // { label: "Villahermosa", mun_code: "086003" },
  // { label: "Awang East (Pob.)", mun_code: "086003" },
  // { label: "Awang West (Pob.)", mun_code: "086003" },
  // { label: "Bagong Lipunan", mun_code: "086003" },
  // { label: "Bontay", mun_code: "086003" },
  // { label: "Kalilihan", mun_code: "086003" },
  // { label: "Carmen", mun_code: "086003" },
  // { label: "Danao I", mun_code: "086003" },
  // { label: "Danao II", mun_code: "086003" },
  // { label: "Gabay", mun_code: "086003" },
  // { label: "Pagbalican", mun_code: "086003" },
  // { label: "Payahan", mun_code: "086003" },
  // { label: "Tanval", mun_code: "086003" },
  // { label: "Tinambacan Norte", mun_code: "086003" },
  // { label: "Tinambacan Sur", mun_code: "086003" },
  // { label: "Cagmanipes Sur", mun_code: "086003" },
  // { label: "Manuel Barral, Sr.", mun_code: "086003" },
  // { label: "Antol", mun_code: "086004" },
  // { label: "Bacyaran", mun_code: "086004" },
  // { label: "Beri", mun_code: "086004" },
  // { label: "Barobaybay", mun_code: "086004" },
  // { label: "Binanggaran", mun_code: "086004" },
  // { label: "Borong", mun_code: "086004" },
  // { label: "Bulao", mun_code: "086004" },
  // { label: "Buluan", mun_code: "086004" },
  // { label: "Caamlongan", mun_code: "086004" },
  // { label: "Calayaan", mun_code: "086004" },
  // { label: "Calingonan", mun_code: "086004" },
  // { label: "Canbagtic", mun_code: "086004" },
  // { label: "Canticum", mun_code: "086004" },
  // { label: "Daligan", mun_code: "086004" },
  // { label: "Guinbanga", mun_code: "086004" },
  // { label: "Hubasan", mun_code: "086004" },
  // { label: "Literon", mun_code: "086004" },
  // { label: "Lubang", mun_code: "086004" },
  // { label: "Mahangcao", mun_code: "086004" },
  // { label: "Macaalan", mun_code: "086004" },
  // { label: "Malabal", mun_code: "086004" },
  // { label: "Minata", mun_code: "086004" },
  // { label: "Otoc", mun_code: "086004" },
  // { label: "Panayuran", mun_code: "086004" },
  // { label: "Pasigay", mun_code: "086004" },
  // { label: "Patong", mun_code: "086004" },
  // { label: "Barangay 1 (Pob.)", mun_code: "086004" },
  // { label: "Barangay 2 (Pob.)", mun_code: "086004" },
  // { label: "Barangay 3 (Pob.)", mun_code: "086004" },
  // { label: "Barangay 4 (Pob.)", mun_code: "086004" },
  // { label: "Barangay 5 (Pob.)", mun_code: "086004" },
  // { label: "Barangay 6 (Pob.)", mun_code: "086004" },
  // { label: "Barangay 7 (Pob.)", mun_code: "086004" },
  // { label: "Polangi", mun_code: "086004" },
  // { label: "Rawis", mun_code: "086004" },
  // { label: "San Ignacio", mun_code: "086004" },
  // { label: "San Mauricio", mun_code: "086004" },
  // { label: "Sinalangtan", mun_code: "086004" },
  // { label: "Timbangan", mun_code: "086004" },
  // { label: "Tinago", mun_code: "086004" },
  // { label: "Hindang", mun_code: "086004" },
  // { label: "Albalate", mun_code: "086005" },
  // { label: "Bagongon", mun_code: "086005" },
  // { label: "Bangon", mun_code: "086005" },
  // { label: "Basiao", mun_code: "086005" },
  // { label: "Buluan", mun_code: "086005" },
  // { label: "Bunuanan", mun_code: "086005" },
  // { label: "Cabugawan", mun_code: "086005" },
  // { label: "Cagudalo", mun_code: "086005" },
  // { label: "Cagusipan", mun_code: "086005" },
  // { label: "Cagutian", mun_code: "086005" },
  // { label: "Cagutsan", mun_code: "086005" },
  // { label: "Canhawan Gote", mun_code: "086005" },
  // { label: "Canlapwas (Pob.)", mun_code: "086005" },
  // { label: "Cawayan", mun_code: "086005" },
  // { label: "Cinco", mun_code: "086005" },
  // { label: "Darahuway Daco", mun_code: "086005" },
  // { label: "Darahuway Gote", mun_code: "086005" },
  // { label: "Estaka", mun_code: "086005" },
  // { label: "Guinsorongan", mun_code: "086005" },
  // { label: "Iguid", mun_code: "086005" },
  // { label: "Lagundi", mun_code: "086005" },
  // { label: "Libas", mun_code: "086005" },
  // { label: "Lobo", mun_code: "086005" },
  // { label: "Manguehay", mun_code: "086005" },
  // { label: "Maulong", mun_code: "086005" },
  // { label: "Mercedes", mun_code: "086005" },
  // { label: "Mombon", mun_code: "086005" },
  // { label: "New Mahayag", mun_code: "086005" },
  // { label: "Old Mahayag", mun_code: "086005" },
  // { label: "Palanyogon", mun_code: "086005" },
  // { label: "Pangdan", mun_code: "086005" },
  // { label: "Payao", mun_code: "086005" },
  // { label: "Poblacion 1 (Barangay 1)", mun_code: "086005" },
  // { label: "Poblacion 2 (Barangay 2)", mun_code: "086005" },
  // { label: "Poblacion 3 (Barangay 3)", mun_code: "086005" },
  // { label: "Poblacion 4 (Barangay 4)", mun_code: "086005" },
  // { label: "Poblacion 5 (Barangay 5)", mun_code: "086005" },
  // { label: "Poblacion 6 (Barangay 6)", mun_code: "086005" },
  // { label: "Poblacion 7 (Barangay 7)", mun_code: "086005" },
  // { label: "Poblacion 8 (Barangay 8)", mun_code: "086005" },
  // { label: "Poblacion 9 (Barangay 9)", mun_code: "086005" },
  // { label: "Poblacion 10 (Barangay 10)", mun_code: "086005" },
  // { label: "Poblacion 11 (Barangay 11)", mun_code: "086005" },
  // { label: "Poblacion 12 (Barangay 12)", mun_code: "086005" },
  // { label: "Poblacion 13 (Barangay 13)", mun_code: "086005" },
  // { label: "Pupua", mun_code: "086005" },
  // { label: "Guindaponan", mun_code: "086005" },
  // { label: "Rama", mun_code: "086005" },
  // { label: "San Andres", mun_code: "086005" },
  // { label: "San Pablo", mun_code: "086005" },
  // { label: "San Roque", mun_code: "086005" },
  // { label: "San Vicente", mun_code: "086005" },
  // { label: "Silanga", mun_code: "086005" },
  // { label: "Totoringon", mun_code: "086005" },
  // { label: "Ibol", mun_code: "086005" },
  // { label: "Socorro", mun_code: "086005" },
  // { label: "Arawane", mun_code: "086006" },
  // { label: "Astorga", mun_code: "086006" },
  // { label: "Bachao", mun_code: "086006" },
  // { label: "Baclayan", mun_code: "086006" },
  // { label: "Bagacay", mun_code: "086006" },
  // { label: "Bayog", mun_code: "086006" },
  // { label: "Birawan", mun_code: "086006" },
  // { label: "Betaug", mun_code: "086006" },
  // { label: "Bono-anon", mun_code: "086006" },
  // { label: "Buenavista", mun_code: "086006" },
  // { label: "Burgos", mun_code: "086006" },
  // { label: "Cabac", mun_code: "086006" },
  // { label: "Cabil-isan", mun_code: "086006" },
  // { label: "Cabiton-an", mun_code: "086006" },
  // { label: "Cabugao", mun_code: "086006" },
  // { label: "Calawan-an", mun_code: "086006" },
  // { label: "Cambuhay", mun_code: "086006" },
  // { label: "Candugue", mun_code: "086006" },
  // { label: "Canloloy", mun_code: "086006" },
  // { label: "Campelipa", mun_code: "086006" },
  // { label: "Cansaganay", mun_code: "086006" },
  // { label: "Poblacion 3 (Canti-il)", mun_code: "086006" },
  // { label: "Casab-ahan", mun_code: "086006" },
  // { label: "Guindapunan", mun_code: "086006" },
  // { label: "Guintampilan", mun_code: "086006" },
  // { label: "Iquiran", mun_code: "086006" },
  // { label: "Jacopon", mun_code: "086006" },
  // { label: "Losa", mun_code: "086006" },
  // { label: "Mabini", mun_code: "086006" },
  // { label: "Macalpe", mun_code: "086006" },
  // { label: "Mandoyucan", mun_code: "086006" },
  // { label: "Mongolbongol", mun_code: "086006" },
  // { label: "Marupangdan", mun_code: "086006" },
  // { label: "Mayabay", mun_code: "086006" },
  // { label: "Nipa", mun_code: "086006" },
  // { label: "Parasan", mun_code: "086006" },
  // { label: "Poblacion 1 (Hilaba)", mun_code: "086006" },
  // { label: "Poblacion 2 (Malingon)", mun_code: "086006" },
  // { label: "Pondang", mun_code: "086006" },
  // { label: "Poso", mun_code: "086006" },
  // { label: "Real", mun_code: "086006" },
  // { label: "Rizal", mun_code: "086006" },
  // { label: "San Antonio", mun_code: "086006" },
  // { label: "San Jose", mun_code: "086006" },
  // { label: "San Miguel", mun_code: "086006" },
  // { label: "San Roque", mun_code: "086006" },
  // { label: "Saugan", mun_code: "086006" },
  // { label: "So-ong", mun_code: "086006" },
  // { label: "Sua", mun_code: "086006" },
  // { label: "Talisay", mun_code: "086006" },
  // { label: "Tugas", mun_code: "086006" },
  // { label: "Ubo", mun_code: "086006" },
  // { label: "Valles-Bello", mun_code: "086006" },
  // { label: "Cagboboto", mun_code: "086006" },
  // { label: "Lucob-lucob", mun_code: "086006" },
  // { label: "San Vicente", mun_code: "086006" },
  // { label: "Sugod", mun_code: "086006" },
  // { label: "Yangta", mun_code: "086006" },
  // { label: "Balocawe", mun_code: "086007" },
  // { label: "Beslig", mun_code: "086007" },
  // { label: "Burabod I (Pob.)", mun_code: "086007" },
  // { label: "Burabod II (Pob.)", mun_code: "086007" },
  // { label: "Buao", mun_code: "086007" },
  // { label: "Bunyagan", mun_code: "086007" },
  // { label: "Calirocan", mun_code: "086007" },
  // { label: "Canhumawid", mun_code: "086007" },
  // { label: "Caparangasan", mun_code: "086007" },
  // { label: "Caranas", mun_code: "086007" },
  // { label: "Carmona", mun_code: "086007" },
  // { label: "Casab-ahan", mun_code: "086007" },
  // { label: "Casandig", mun_code: "086007" },
  // { label: "Caugbusan", mun_code: "086007" },
  // { label: "Concepcion", mun_code: "086007" },
  // { label: "Dumalo-ong (Pob.)", mun_code: "086007" },
  // { label: "Elcano", mun_code: "086007" },
  // { label: "Gerali", mun_code: "086007" },
  // { label: "Giaboc", mun_code: "086007" },
  // { label: "Hampton", mun_code: "086007" },
  // { label: "Hinayagan", mun_code: "086007" },
  // { label: "Hinugacan", mun_code: "086007" },
  // { label: "Jasminez", mun_code: "086007" },
  // { label: "Lungib", mun_code: "086007" },
  // { label: "Mabuhay", mun_code: "086007" },
  // { label: "Macugo", mun_code: "086007" },
  // { label: "Minda (Pob.)", mun_code: "086007" },
  // { label: "Nacube", mun_code: "086007" },
  // { label: "Nalihugan", mun_code: "086007" },
  // { label: "Napalisan", mun_code: "086007" },
  // { label: "Natimonan", mun_code: "086007" },
  // { label: "Ngoso", mun_code: "086007" },
  // { label: "Palambrag", mun_code: "086007" },
  // { label: "Palanas", mun_code: "086007" },
  // { label: "Pizarro", mun_code: "086007" },
  // { label: "Pologon", mun_code: "086007" },
  // { label: "Purog", mun_code: "086007" },
  // { label: "Rawis", mun_code: "086007" },
  // { label: "Rizal", mun_code: "086007" },
  // { label: "Samoyao", mun_code: "086007" },
  // { label: "San Agustin", mun_code: "086007" },
  // { label: "San Antonio", mun_code: "086007" },
  // { label: "San Enrique", mun_code: "086007" },
  // { label: "San Francisco", mun_code: "086007" },
  // { label: "San Isidro (Dao)", mun_code: "086007" },
  // { label: "San Jose", mun_code: "086007" },
  // { label: "San Miguel", mun_code: "086007" },
  // { label: "San Pelayo", mun_code: "086007" },
  // { label: "San Ramon", mun_code: "086007" },
  // { label: "Santa Elena", mun_code: "086007" },
  // { label: "Senibaran (Villa Leona)", mun_code: "086007" },
  // { label: "Tagnao", mun_code: "086007" },
  // { label: "Tambongan", mun_code: "086007" },
  // { label: "Tawiran", mun_code: "086007" },
  // { label: "Tigbawon", mun_code: "086007" },
  // { label: "Marcos", mun_code: "086007" },
  // { label: "Bangahon", mun_code: "086007" },
  // { label: "Adela Heights (Pob.)", mun_code: "086007" },
  // { label: "Arong", mun_code: "086007" },
  // { label: "Catorse De Agosto", mun_code: "086007" },
  // { label: "Diaz", mun_code: "086007" },
  // { label: "Gereganan", mun_code: "086007" },
  // { label: "Hetebac", mun_code: "086007" },
  // { label: "Himamaloto", mun_code: "086007" },
  // { label: "Hiparayan (Kinonongan)", mun_code: "086007" },
  // { label: "Malayog", mun_code: "086007" },
  // { label: "Sidmon", mun_code: "086007" },
  // { label: "Bagacay", mun_code: "086008" },
  // { label: "Binobucalan", mun_code: "086008" },
  // { label: "Bucalan", mun_code: "086008" },
  // { label: "Cabalagnan", mun_code: "086008" },
  // { label: "Canano", mun_code: "086008" },
  // { label: "Consolabao", mun_code: "086008" },
  // { label: "Concord", mun_code: "086008" },
  // { label: "Dalosdoson", mun_code: "086008" },
  // { label: "Lim-ao", mun_code: "086008" },
  // { label: "Poblacion 1 (Barangay 1)", mun_code: "086008" },
  // { label: "Poblacion 2 (Barangay 2)", mun_code: "086008" },
  // { label: "Rawis", mun_code: "086008" },
  // { label: "San Rafael", mun_code: "086008" },
  // { label: "Tabay", mun_code: "086008" },
  // { label: "Yabon", mun_code: "086008" },
  // { label: "Cabang", mun_code: "086008" },
  // { label: "Malihao", mun_code: "086008" },
  // { label: "San Jose", mun_code: "086008" },
  // { label: "Fatima", mun_code: "086008" },
  // { label: "Mugdo", mun_code: "086008" },
  // { label: "Bawang", mun_code: "086009" },
  // { label: "Bugho", mun_code: "086009" },
  // { label: "Camarobo-an", mun_code: "086009" },
  // { label: "Candayao", mun_code: "086009" },
  // { label: "Cantongtong", mun_code: "086009" },
  // { label: "Casapa", mun_code: "086009" },
  // { label: "Catalina", mun_code: "086009" },
  // { label: "Cristina", mun_code: "086009" },
  // { label: "Dogongan", mun_code: "086009" },
  // { label: "Garcia", mun_code: "086009" },
  // { label: "Hinaga", mun_code: "086009" },
  // { label: "Jia-an", mun_code: "086009" },
  // { label: "Jidanao", mun_code: "086009" },
  // { label: "Lulugayan", mun_code: "086009" },
  // { label: "Macabetas", mun_code: "086009" },
  // { label: "Malino", mun_code: "086009" },
  // { label: "Malobago (Villalinda)", mun_code: "086009" },
  // { label: "Mercedes", mun_code: "086009" },
  // { label: "Nagbac", mun_code: "086009" },
  // { label: "Parina", mun_code: "086009" },
  // { label: "Barangay No. 1 (Pob.)", mun_code: "086009" },
  // { label: "Barangay No. 2 (Pob.)", mun_code: "086009" },
  // { label: "Barangay No. 3 (Pob.)", mun_code: "086009" },
  // { label: "Barangay No. 4 (Pob.)", mun_code: "086009" },
  // { label: "Barangay No. 5 (Pob.)", mun_code: "086009" },
  // { label: "Barangay No. 6 (Pob.)", mun_code: "086009" },
  // { label: "Barangay No. 7 (Pob.)", mun_code: "086009" },
  // { label: "Barangay No. 8 (Pob.)", mun_code: "086009" },
  // { label: "Salvacion", mun_code: "086009" },
  // { label: "San Andres", mun_code: "086009" },
  // { label: "San Fernando", mun_code: "086009" },
  // { label: "San Miguel", mun_code: "086009" },
  // { label: "Tagbayaon", mun_code: "086009" },
  // { label: "Victory", mun_code: "086009" },
  // { label: "Amambucale", mun_code: "086010" },
  // { label: "Caluwayan", mun_code: "086010" },
  // { label: "Canyoyo", mun_code: "086010" },
  // { label: "Ferreras", mun_code: "086010" },
  // { label: "Legaspi", mun_code: "086010" },
  // { label: "Logero", mun_code: "086010" },
  // { label: "Pinalanga", mun_code: "086010" },
  // { label: "Pinamitinan (Pob.)", mun_code: "086010" },
  // { label: "Catato Pob. (Dist. I)", mun_code: "086010" },
  // { label: "San Roque", mun_code: "086010" },
  // { label: "Tagalag", mun_code: "086010" },
  // { label: "Tinabanan", mun_code: "086010" },
  // { label: "Amantillo", mun_code: "086010" },
  // { label: "Binukyahan", mun_code: "086010" },
  // { label: "Lipata", mun_code: "086010" },
  // { label: "Mabuhay", mun_code: "086010" },
  // { label: "Malobago", mun_code: "086010" },
  // { label: "Odoc", mun_code: "086010" },
  // { label: "Panan-awan", mun_code: "086010" },
  // { label: "Santa Rita", mun_code: "086010" },
  // { label: "Veloso", mun_code: "086010" },
  // { label: "Angyap", mun_code: "086011" },
  // { label: "Barruz (Barangay No. 1)", mun_code: "086011" },
  // { label: "Camonoan", mun_code: "086011" },
  // { label: "Carolina", mun_code: "086011" },
  // { label: "Deit", mun_code: "086011" },
  // { label: "Del Rosario", mun_code: "086011" },
  // { label: "Libertad", mun_code: "086011" },
  // { label: "Ligaya", mun_code: "086011" },
  // { label: "Mabuligon Pob. (Barangay No. 2)", mun_code: "086011" },
  // { label: "Maduroto Pob. (Barangay No. 3)", mun_code: "086011" },
  // { label: "Mahanud", mun_code: "086011" },
  // { label: "Mahayag", mun_code: "086011" },
  // { label: "Nagpapacao", mun_code: "086011" },
  // { label: "Rizal", mun_code: "086011" },
  // { label: "Salvacion", mun_code: "086011" },
  // { label: "San Isidro", mun_code: "086011" },
  // { label: "Santa Cruz", mun_code: "086011" },
  // { label: "Bag-otan", mun_code: "086011" },
  // { label: "Inubod", mun_code: "086011" },
  // { label: "San Roque (Mabuhay)", mun_code: "086011" },
  // { label: "Poblacion I", mun_code: "086012" },
  // { label: "Poblacion I-A", mun_code: "086012" },
  // { label: "Angyap", mun_code: "086012" },
  // { label: "Barayong", mun_code: "086012" },
  // { label: "Bayog", mun_code: "086012" },
  // { label: "Beri", mun_code: "086012" },
  // { label: "Bonga", mun_code: "086012" },
  // { label: "Calantawan", mun_code: "086012" },
  // { label: "Calapi", mun_code: "086012" },
  // { label: "Caluyahan", mun_code: "086012" },
  // { label: "Canvais", mun_code: "086012" },
  // { label: "Canatuan", mun_code: "086012" },
  // { label: "Candomacol", mun_code: "086012" },
  // { label: "Capaysagan", mun_code: "086012" },
  // { label: "Caranas", mun_code: "086012" },
  // { label: "Caulayanan", mun_code: "086012" },
  // { label: "Hinica-an", mun_code: "086012" },
  // { label: "Inalad", mun_code: "086012" },
  // { label: "Linonoban", mun_code: "086012" },
  // { label: "Malobago", mun_code: "086012" },
  // { label: "Malonoy", mun_code: "086012" },
  // { label: "Mararangsi", mun_code: "086012" },
  // { label: "Maypange", mun_code: "086012" },
  // { label: "New Minarog", mun_code: "086012" },
  // { label: "Oyandic", mun_code: "086012" },
  // { label: "Pamamasan", mun_code: "086012" },
  // { label: "San Andres", mun_code: "086012" },
  // { label: "Sarao", mun_code: "086012" },
  // { label: "Pusongan", mun_code: "086012" },
  // { label: "Bangon", mun_code: "086013" },
  // { label: "Barangay I (Pob.)", mun_code: "086013" },
  // { label: "Barangay II (Pob.)", mun_code: "086013" },
  // { label: "Botoc", mun_code: "086013" },
  // { label: "Bugho", mun_code: "086013" },
  // { label: "Calampong", mun_code: "086013" },
  // { label: "Canlobo", mun_code: "086013" },
  // { label: "Catigawan", mun_code: "086013" },
  // { label: "Dolores", mun_code: "086013" },
  // { label: "Lale", mun_code: "086013" },
  // { label: "Lawaan", mun_code: "086013" },
  // { label: "Laygayon", mun_code: "086013" },
  // { label: "Layo", mun_code: "086013" },
  // { label: "Loctob", mun_code: "086013" },
  // { label: "Madalunot", mun_code: "086013" },
  // { label: "Magdawat", mun_code: "086013" },
  // { label: "Mambog", mun_code: "086013" },
  // { label: "Manaing", mun_code: "086013" },
  // { label: "Obayan", mun_code: "086013" },
  // { label: "Pahug", mun_code: "086013" },
  // { label: "Parasanon", mun_code: "086013" },
  // { label: "Pelaon", mun_code: "086013" },
  // { label: "San Isidro", mun_code: "086013" },
  // { label: "Nabong", mun_code: "086013" },
  // { label: "Aguingayan", mun_code: "086014" },
  // { label: "Babaclayon", mun_code: "086014" },
  // { label: "Can-aponte", mun_code: "086014" },
  // { label: "Cataydongan", mun_code: "086014" },
  // { label: "Gusa", mun_code: "086014" },
  // { label: "Hagbay", mun_code: "086014" },
  // { label: "Hiduroma", mun_code: "086014" },
  // { label: "Hilumot", mun_code: "086014" },
  // { label: "Barangay 1 (Pob.)", mun_code: "086014" },
  // { label: "Barangay 2 (Pob.)", mun_code: "086014" },
  // { label: "Barangay 3 (Pob.)", mun_code: "086014" },
  // { label: "Barangay 4 (Pob.)", mun_code: "086014" },
  // { label: "San Nicolas", mun_code: "086014" },
  // { label: "Hibaca-an", mun_code: "086014" },
  // { label: "Poblacion Barangay 1", mun_code: "086015" },
  // { label: "Poblacion Barangay 2", mun_code: "086015" },
  // { label: "Poblacion Barangay 3", mun_code: "086015" },
  // { label: "Poblacion Barangay 4", mun_code: "086015" },
  // { label: "Balogo", mun_code: "086015" },
  // { label: "Bontod", mun_code: "086015" },
  // { label: "Camanhagay", mun_code: "086015" },
  // { label: "Campiyak", mun_code: "086015" },
  // { label: "Dolores", mun_code: "086015" },
  // { label: "Hita-asan I", mun_code: "086015" },
  // { label: "Inobongan", mun_code: "086015" },
  // { label: "Cabaywa", mun_code: "086015" },
  // { label: "Canduyucan", mun_code: "086015" },
  // { label: "Hita-asan II", mun_code: "086015" },
  // { label: "Agrupacion", mun_code: "086016" },
  // { label: "Arapison", mun_code: "086016" },
  // { label: "Avelino", mun_code: "086016" },
  // { label: "Bahay", mun_code: "086016" },
  // { label: "Balud", mun_code: "086016" },
  // { label: "Bana-ao", mun_code: "086016" },
  // { label: "Burabod", mun_code: "086016" },
  // { label: "Cautod (Pob.)", mun_code: "086016" },
  // { label: "Camperito", mun_code: "086016" },
  // { label: "Campeig", mun_code: "086016" },
  // { label: "Can-ipulan", mun_code: "086016" },
  // { label: "Canmoros", mun_code: "086016" },
  // { label: "Cinco", mun_code: "086016" },
  // { label: "Curry", mun_code: "086016" },
  // { label: "Gajo", mun_code: "086016" },
  // { label: "Hindang", mun_code: "086016" },
  // { label: "Ilo", mun_code: "086016" },
  // { label: "Imelda", mun_code: "086016" },
  // { label: "Inoraguiao", mun_code: "086016" },
  // { label: "Jolacao", mun_code: "086016" },
  // { label: "Lambao", mun_code: "086016" },
  // { label: "Mabuhay", mun_code: "086016" },
  // { label: "Mahayag", mun_code: "086016" },
  // { label: "Monbon (Pob.)", mun_code: "086016" },
  // { label: "Nabulo", mun_code: "086016" },
  // { label: "Napuro", mun_code: "086016" },
  // { label: "Palale", mun_code: "086016" },
  // { label: "Panabatan", mun_code: "086016" },
  // { label: "Panaruan", mun_code: "086016" },
  // { label: "Roxas", mun_code: "086016" },
  // { label: "Salvacion", mun_code: "086016" },
  // { label: "Solsogon", mun_code: "086016" },
  // { label: "Sundara", mun_code: "086016" },
  // { label: "Cagsumji", mun_code: "086016" },
  // { label: "Matayonas", mun_code: "086016" },
  // { label: "Napuro II", mun_code: "086016" },
  // { label: "Alegria", mun_code: "086017" },
  // { label: "Anibongan", mun_code: "086017" },
  // { label: "Aslum", mun_code: "086017" },
  // { label: "Bagolibas", mun_code: "086017" },
  // { label: "Binanalan", mun_code: "086017" },
  // { label: "Cabacungan", mun_code: "086017" },
  // { label: "Cabunga-an", mun_code: "086017" },
  // { label: "Camayse", mun_code: "086017" },
  // { label: "Cansadong", mun_code: "086017" },
  // { label: "Caticugan", mun_code: "086017" },
  // { label: "Dampigan", mun_code: "086017" },
  // { label: "Guinbalot-an", mun_code: "086017" },
  // { label: "Hinangudtan", mun_code: "086017" },
  // { label: "Igang-igang", mun_code: "086017" },
  // { label: "La Paz", mun_code: "086017" },
  // { label: "Lupig", mun_code: "086017" },
  // { label: "Magsaysay", mun_code: "086017" },
  // { label: "Maligaya", mun_code: "086017" },
  // { label: "New Manunca", mun_code: "086017" },
  // { label: "Old Manunca", mun_code: "086017" },
  // { label: "Pagsulhogon", mun_code: "086017" },
  // { label: "Salvacion", mun_code: "086017" },
  // { label: "San Eduardo", mun_code: "086017" },
  // { label: "San Isidro", mun_code: "086017" },
  // { label: "San Juan", mun_code: "086017" },
  // { label: "San Pascual (Crossing)", mun_code: "086017" },
  // { label: "San Pedro", mun_code: "086017" },
  // { label: "San Roque", mun_code: "086017" },
  // { label: "Santa Elena", mun_code: "086017" },
  // { label: "Tagacay", mun_code: "086017" },
  // { label: "Tominamos", mun_code: "086017" },
  // { label: "Tulay", mun_code: "086017" },
  // { label: "Union", mun_code: "086017" },
  // { label: "Bokinggan Pob. (Zone I)", mun_code: "086017" },
  // { label: "Bougainvilla Pob. (Zone II)", mun_code: "086017" },
  // { label: "Gumamela Pob. (Zone III)", mun_code: "086017" },
  // { label: "Rosal Pob. (Zone IV)", mun_code: "086017" },
  // { label: "Santan Pob. (Zone V)", mun_code: "086017" },
  // { label: "Balatguti", mun_code: "086018" },
  // { label: "Baras", mun_code: "086018" },
  // { label: "Basud (Pob.)", mun_code: "086018" },
  // { label: "Buenavista", mun_code: "086018" },
  // { label: "Cabunga-an", mun_code: "086018" },
  // { label: "Corocawayan", mun_code: "086018" },
  // { label: "Ilijan", mun_code: "086018" },
  // { label: "Ilo (Pob.)", mun_code: "086018" },
  // { label: "Lobelobe", mun_code: "086018" },
  // { label: "Pinanangnan", mun_code: "086018" },
  // { label: "Sevilla", mun_code: "086018" },
  // { label: "Takut", mun_code: "086018" },
  // { label: "Villahermosa", mun_code: "086018" },
  // { label: "Bo. Independencia", mun_code: "086019" },
  // { label: "Malaguining", mun_code: "086019" },
  // { label: "Mallorga", mun_code: "086019" },
  // { label: "Navatas Daku", mun_code: "086019" },
  // { label: "Navatas Guti", mun_code: "086019" },
  // { label: "Placer", mun_code: "086019" },
  // { label: "Poblacion Barangay 1", mun_code: "086019" },
  // { label: "Poblacion Barangay 2", mun_code: "086019" },
  // { label: "San Juan", mun_code: "086019" },
  // { label: "Tatabunan", mun_code: "086019" },
  // { label: "Victory", mun_code: "086019" },
  // { label: "Alcazar", mun_code: "086020" },
  // { label: "Awang", mun_code: "086020" },
  // { label: "Bahay", mun_code: "086020" },
  // { label: "Balonga-as", mun_code: "086020" },
  // { label: "Balugo", mun_code: "086020" },
  // { label: "Bangon Gote", mun_code: "086020" },
  // { label: "Baras", mun_code: "086020" },
  // { label: "Binalayan", mun_code: "086020" },
  // { label: "Bisitahan", mun_code: "086020" },
  // { label: "Bonga", mun_code: "086020" },
  // { label: "Cabunga-an", mun_code: "086020" },
  // { label: "Cagtutulo", mun_code: "086020" },
  // { label: "Cambatutay Nuevo", mun_code: "086020" },
  // { label: "Cambatutay Viejo", mun_code: "086020" },
  // { label: "Canunghan", mun_code: "086020" },
  // { label: "Catan-agan", mun_code: "086020" },
  // { label: "Dapdap", mun_code: "086020" },
  // { label: "Gallego", mun_code: "086020" },
  // { label: "Imelda Pob. (Posgo)", mun_code: "086020" },
  // { label: "Lucerdoni (Irong-irong)", mun_code: "086020" },
  // { label: "Lahong", mun_code: "086020" },
  // { label: "Libucan Dacu", mun_code: "086020" },
  // { label: "Libucan Gote", mun_code: "086020" },
  // { label: "Majacob", mun_code: "086020" },
  // { label: "Mancares", mun_code: "086020" },
  // { label: "Marabut", mun_code: "086020" },
  // { label: "Oeste - A", mun_code: "086020" },
  // { label: "Oeste - B", mun_code: "086020" },
  // { label: "Pajo", mun_code: "086020" },
  // { label: "Palencia", mun_code: "086020" },
  // { label: "Poblacion A", mun_code: "086020" },
  // { label: "Poblacion B", mun_code: "086020" },
  // { label: "Poblacion C", mun_code: "086020" },
  // { label: "Poblacion D", mun_code: "086020" },
  // { label: "Poblacion E", mun_code: "086020" },
  // { label: "San Vicente", mun_code: "086020" },
  // { label: "Santa Cruz", mun_code: "086020" },
  // { label: "Sugod", mun_code: "086020" },
  // { label: "Talinga", mun_code: "086020" },
  // { label: "Tigdaranao", mun_code: "086020" },
  // { label: "Tizon", mun_code: "086020" },
  // { label: "Banquil", mun_code: "086021" },
  // { label: "Bino-ongan", mun_code: "086021" },
  // { label: "Burabod", mun_code: "086021" },
  // { label: "Cambaguio", mun_code: "086021" },
  // { label: "Canmucat", mun_code: "086021" },
  // { label: "Villarosa Pob. (Campiatot)", mun_code: "086021" },
  // { label: "Conant", mun_code: "086021" },
  // { label: "Guintarcan", mun_code: "086021" },
  // { label: "Himyangan", mun_code: "086021" },
  // { label: "Igot", mun_code: "086021" },
  // { label: "Inarumbacan", mun_code: "086021" },
  // { label: "Inasudlan", mun_code: "086021" },
  // { label: "Lam-awan", mun_code: "086021" },
  // { label: "Lamingao", mun_code: "086021" },
  // { label: "Lawa-an", mun_code: "086021" },
  // { label: "Macopa", mun_code: "086021" },
  // { label: "Mahayag", mun_code: "086021" },
  // { label: "Malonoy", mun_code: "086021" },
  // { label: "Mercado (Pob.)", mun_code: "086021" },
  // { label: "Miramar (Pob.)", mun_code: "086021" },
  // { label: "Nagcaduha", mun_code: "086021" },
  // { label: "Pacao", mun_code: "086021" },
  // { label: "Pacoyoy", mun_code: "086021" },
  // { label: "Pangpang", mun_code: "086021" },
  // { label: "Plaridel", mun_code: "086021" },
  // { label: "Central (Pob.)", mun_code: "086021" },
  // { label: "Polangi", mun_code: "086021" },
  // { label: "San Andres", mun_code: "086021" },
  // { label: "San Fernando", mun_code: "086021" },
  // { label: "San Rafael (Buaya)", mun_code: "086021" },
  // { label: "San Roque", mun_code: "086021" },
  // { label: "Santa Rosa", mun_code: "086021" },
  // { label: "Soledad (Pob.)", mun_code: "086021" },
  // { label: "Tayud (Pob.)", mun_code: "086021" },
  // { label: "Tomabe", mun_code: "086021" },
  // { label: "Ulayan", mun_code: "086021" },
  // { label: "Patag", mun_code: "086021" },
  // { label: "Anagasi", mun_code: "086022" },
  // { label: "Apolonia", mun_code: "086022" },
  // { label: "Bagsa", mun_code: "086022" },
  // { label: "Balbagan", mun_code: "086022" },
  // { label: "Bato", mun_code: "086022" },
  // { label: "Buray (Binogho)", mun_code: "086022" },
  // { label: "Cantaguic", mun_code: "086022" },
  // { label: "Cantao-an", mun_code: "086022" },
  // { label: "Cantato (Canturab)", mun_code: "086022" },
  // { label: "Casandig I", mun_code: "086022" },
  // { label: "Concepcion", mun_code: "086022" },
  // { label: "Cawayan", mun_code: "086022" },
  // { label: "Lawaan I", mun_code: "086022" },
  // { label: "Lipata", mun_code: "086022" },
  // { label: "Lokilokon", mun_code: "086022" },
  // { label: "Mangcal", mun_code: "086022" },
  // { label: "Maylobe", mun_code: "086022" },
  // { label: "Minarog", mun_code: "086022" },
  // { label: "Nawi", mun_code: "086022" },
  // { label: "Pabanog", mun_code: "086022" },
  // { label: "Paco", mun_code: "086022" },
  // { label: "Pagsa-ogan", mun_code: "086022" },
  // { label: "Pagsanjan (Pagsan-an)", mun_code: "086022" },
  // { label: "Patag", mun_code: "086022" },
  // { label: "Pequit", mun_code: "086022" },
  // { label: "Poblacion 1", mun_code: "086022" },
  // { label: "Poblacion 2", mun_code: "086022" },
  // { label: "Poblacion 3", mun_code: "086022" },
  // { label: "Poblacion 4", mun_code: "086022" },
  // { label: "Poblacion 5", mun_code: "086022" },
  // { label: "Poblacion 6", mun_code: "086022" },
  // { label: "Salay", mun_code: "086022" },
  // { label: "San Isidro", mun_code: "086022" },
  // { label: "Sulopan", mun_code: "086022" },
  // { label: "Tabucan", mun_code: "086022" },
  // { label: "Tapul", mun_code: "086022" },
  // { label: "Tenani", mun_code: "086022" },
  // { label: "Tigbawon", mun_code: "086022" },
  // { label: "Tula", mun_code: "086022" },
  // { label: "Tutubigan", mun_code: "086022" },
  // { label: "Casandig II", mun_code: "086022" },
  // { label: "Lawaan II", mun_code: "086022" },
  // { label: "Alegria", mun_code: "086023" },
  // { label: "Arteche", mun_code: "086023" },
  // { label: "Bioso", mun_code: "086023" },
  // { label: "Boblaran", mun_code: "086023" },
  // { label: "Botaera", mun_code: "086023" },
  // { label: "Buntay", mun_code: "086023" },
  // { label: "Camayse", mun_code: "086023" },
  // { label: "Canwarak", mun_code: "086023" },
  // { label: "Ibarra", mun_code: "086023" },
  // { label: "Lumalantang", mun_code: "086023" },
  // { label: "Macalunod", mun_code: "086023" },
  // { label: "Maga-an", mun_code: "086023" },
  // { label: "Maputi", mun_code: "086023" },
  // { label: "Monbon", mun_code: "086023" },
  // { label: "Mualbual", mun_code: "086023" },
  // { label: "Pangdan", mun_code: "086023" },
  // { label: "Poro", mun_code: "086023" },
  // { label: "San Isidro", mun_code: "086023" },
  // { label: "Sugod", mun_code: "086023" },
  // { label: "Tinaugan", mun_code: "086023" },
  // { label: "Tubigan", mun_code: "086023" },
  // { label: "Poblacion 1 (Barangay 1)", mun_code: "086023" },
  // { label: "Poblacion 2 (Barangay 2)", mun_code: "086023" },
  // { label: "Marapilit", mun_code: "086023" },
  // { label: "Talib", mun_code: "086023" },
  // { label: "Baguiw", mun_code: "086024" },
  // { label: "Balocawe", mun_code: "086024" },
  // { label: "Guinbarucan", mun_code: "086024" },
  // { label: "Labangbaybay", mun_code: "086024" },
  // { label: "Luna", mun_code: "086024" },
  // { label: "Mataluto", mun_code: "086024" },
  // { label: "Nipa", mun_code: "086024" },
  // { label: "Pantalan", mun_code: "086024" },
  // { label: "Pulangbato", mun_code: "086024" },
  // { label: "San Vicente", mun_code: "086024" },
  // { label: "Sugod (Pob.)", mun_code: "086024" },
  // { label: "Suarez (Manlangit)", mun_code: "086024" },
  // { label: "San Jose (Pob.)", mun_code: "086024" },
  // { label: "Trinidad", mun_code: "086024" },
  // { label: "Aurora", mun_code: "086025" },
  // { label: "Blanca Aurora", mun_code: "086025" },
  // { label: "Buenavista I", mun_code: "086025" },
  // { label: "Bulao", mun_code: "086025" },
  // { label: "Bungliw", mun_code: "086025" },
  // { label: "Cogtoto-og", mun_code: "086025" },
  // { label: "Calundan", mun_code: "086025" },
  // { label: "Cantaguic", mun_code: "086025" },
  // { label: "Canyaki", mun_code: "086025" },
  // { label: "Erenas", mun_code: "086025" },
  // { label: "Guadalupe", mun_code: "086025" },
  // { label: "Hernandez", mun_code: "086025" },
  // { label: "Himay", mun_code: "086025" },
  // { label: "Janipon", mun_code: "086025" },
  // { label: "La Paz", mun_code: "086025" },
  // { label: "Libertad", mun_code: "086025" },
  // { label: "Lincoro", mun_code: "086025" },
  // { label: "Matalud", mun_code: "086025" },
  // { label: "Mobo-ob", mun_code: "086025" },
  // { label: "Quezon", mun_code: "086025" },
  // { label: "Ranera", mun_code: "086025" },
  // { label: "Rosalim", mun_code: "086025" },
  // { label: "San Isidro", mun_code: "086025" },
  // { label: "San Jorge I (Pob.)", mun_code: "086025" },
  // { label: "Sapinit", mun_code: "086025" },
  // { label: "Sinit-an", mun_code: "086025" },
  // { label: "Tomogbong", mun_code: "086025" },
  // { label: "Gayondato", mun_code: "086025" },
  // { label: "Puhagan", mun_code: "086025" },
  // { label: "Anquiana (Angkiana)", mun_code: "086025" },
  // { label: "Bay-ang", mun_code: "086025" },
  // { label: "Buenavista II", mun_code: "086025" },
  // { label: "Cabugao", mun_code: "086025" },
  // { label: "Cag-olo-olo", mun_code: "086025" },
  // { label: "Guindapunan", mun_code: "086025" },
  // { label: "Mabuhay", mun_code: "086025" },
  // { label: "Mancol (Pob.)", mun_code: "086025" },
  // { label: "Mombon", mun_code: "086025" },
  // { label: "Rawis", mun_code: "086025" },
  // { label: "San Jorge II (Pob.)", mun_code: "086025" },
  // { label: "San Juan", mun_code: "086025" },
  // { label: "Bangon", mun_code: "086026" },
  // { label: "Buenos Aires", mun_code: "086026" },
  // { label: "Calanyugan", mun_code: "086026" },
  // { label: "Caloloma", mun_code: "086026" },
  // { label: "Cambaye", mun_code: "086026" },
  // { label: "San Luis", mun_code: "086026" },
  // { label: "Villahermosa Occidental", mun_code: "086026" },
  // { label: "Canlapwas (Pob.)", mun_code: "086026" },
  // { label: "Libertad", mun_code: "086026" },
  // { label: "Viejo", mun_code: "086026" },
  // { label: "Villahermosa Oriental", mun_code: "086026" },
  // { label: "Amagusan", mun_code: "086401" },
  // { label: "Calintaan", mun_code: "086401" },
  // { label: "Canlabian", mun_code: "086401" },
  // { label: "Capacuhan", mun_code: "086401" },
  // { label: "Kagingkingan", mun_code: "086401" },
  // { label: "Lewing", mun_code: "086401" },
  // { label: "Lo-ok", mun_code: "086401" },
  // { label: "Mahalo", mun_code: "086401" },
  // { label: "Mainit", mun_code: "086401" },
  // { label: "Manigawong", mun_code: "086401" },
  // { label: "Poblacion", mun_code: "086401" },
  // { label: "San Vicente", mun_code: "086401" },
  // { label: "Tagup-on", mun_code: "086401" },
  // { label: "Cogon", mun_code: "086401" },
  // { label: "Banahao", mun_code: "086402" },
  // { label: "Baugo", mun_code: "086402" },
  // { label: "Beniton", mun_code: "086402" },
  // { label: "Buenavista", mun_code: "086402" },
  // { label: "Bunga", mun_code: "086402" },
  // { label: "Casao", mun_code: "086402" },
  // { label: "Catmon", mun_code: "086402" },
  // { label: "Catoogan", mun_code: "086402" },
  // { label: "Cawayanan", mun_code: "086402" },
  // { label: "Dao", mun_code: "086402" },
  // { label: "Divisoria", mun_code: "086402" },
  // { label: "Esperanza", mun_code: "086402" },
  // { label: "Guinsangaan", mun_code: "086402" },
  // { label: "Hibagwan", mun_code: "086402" },
  // { label: "Hilaan", mun_code: "086402" },
  // { label: "Himakilo", mun_code: "086402" },
  // { label: "Hitawos", mun_code: "086402" },
  // { label: "Lanao", mun_code: "086402" },
  // { label: "Lawgawan", mun_code: "086402" },
  // { label: "Mahayahay", mun_code: "086402" },
  // { label: "Malbago", mun_code: "086402" },
  // { label: "Mauylab", mun_code: "086402" },
  // { label: "Paku", mun_code: "086402" },
  // { label: "Pamahawan", mun_code: "086402" },
  // { label: "Pamigsian", mun_code: "086402" },
  // { label: "Pangi", mun_code: "086402" },
  // { label: "Poblacion", mun_code: "086402" },
  // { label: "Sampongon", mun_code: "086402" },
  // { label: "San Vicente", mun_code: "086402" },
  // { label: "Santa Cruz", mun_code: "086402" },
  // { label: "Taa", mun_code: "086402" },
  // { label: "Talisay", mun_code: "086402" },
  // { label: "Taytagan", mun_code: "086402" },
  // { label: "Tuburan", mun_code: "086402" },
  // { label: "Union", mun_code: "086402" },
  // { label: "Olisihan", mun_code: "086402" },
  // { label: "Anahao", mun_code: "086402" },
  // { label: "Pong-on", mun_code: "086402" },
  // { label: "San Ramon", mun_code: "086402" },
  // { label: "Ambacon", mun_code: "086403" },
  // { label: "Badiangon", mun_code: "086403" },
  // { label: "Bangcas A", mun_code: "086403" },
  // { label: "Bangcas B", mun_code: "086403" },
  // { label: "Biasong", mun_code: "086403" },
  // { label: "Bugho", mun_code: "086403" },
  // { label: "Calag-itan", mun_code: "086403" },
  // { label: "Calayugan", mun_code: "086403" },
  // { label: "Calinao", mun_code: "086403" },
  // { label: "Canipaan", mun_code: "086403" },
  // { label: "Catublian", mun_code: "086403" },
  // { label: "Ilaya", mun_code: "086403" },
  // { label: "Ingan", mun_code: "086403" },
  // { label: "Labrador", mun_code: "086403" },
  // { label: "Lumbog", mun_code: "086403" },
  // { label: "Manalog", mun_code: "086403" },
  // { label: "Manlico", mun_code: "086403" },
  // { label: "Matin-ao", mun_code: "086403" },
  // { label: "Nava", mun_code: "086403" },
  // { label: "Nueva Esperanza", mun_code: "086403" },
  // { label: "Otama", mun_code: "086403" },
  // { label: "Palongpong", mun_code: "086403" },
  // { label: "Panalaron", mun_code: "086403" },
  // { label: "Patong", mun_code: "086403" },
  // { label: "Poblacion", mun_code: "086403" },
  // { label: "Pondol", mun_code: "086403" },
  // { label: "Salog", mun_code: "086403" },
  // { label: "Salvacion", mun_code: "086403" },
  // { label: "San Pablo Island", mun_code: "086403" },
  // { label: "San Pedro Island", mun_code: "086403" },
  // { label: "Tahusan", mun_code: "086403" },
  // { label: "Talisay", mun_code: "086403" },
  // { label: "Tawog", mun_code: "086403" },
  // { label: "Toptop", mun_code: "086403" },
  // { label: "Tuburan", mun_code: "086403" },
  // { label: "Union", mun_code: "086403" },
  // { label: "Upper Bantawon", mun_code: "086403" },
  // { label: "Libas", mun_code: "086403" },
  // { label: "Amaga", mun_code: "086404" },
  // { label: "Ambao", mun_code: "086404" },
  // { label: "An-an", mun_code: "086404" },
  // { label: "Baculod", mun_code: "086404" },
  // { label: "Biasong", mun_code: "086404" },
  // { label: "Bugho", mun_code: "086404" },
  // { label: "Cabulisan", mun_code: "086404" },
  // { label: "District I (Pob.)", mun_code: "086404" },
  // { label: "District II (Pob.)", mun_code: "086404" },
  // { label: "District III (Pob.)", mun_code: "086404" },
  // { label: "Hubasan", mun_code: "086404" },
  // { label: "Cat-iwing", mun_code: "086404" },
  // { label: "Lungsodaan", mun_code: "086404" },
  // { label: "Navalita", mun_code: "086404" },
  // { label: "Plaridel", mun_code: "086404" },
  // { label: "Sabang", mun_code: "086404" },
  // { label: "Sagbok", mun_code: "086404" },
  // { label: "Biasong", mun_code: "086405" },
  // { label: "Bogasong", mun_code: "086405" },
  // { label: "Cawayan", mun_code: "086405" },
  // { label: "Gakat", mun_code: "086405" },
  // { label: "Jubas (Pob.)", mun_code: "086405" },
  // { label: "Magkasag", mun_code: "086405" },
  // { label: "Mayuga", mun_code: "086405" },
  // { label: "Nahaong", mun_code: "086405" },
  // { label: "Nahulid", mun_code: "086405" },
  // { label: "Otikon", mun_code: "086405" },
  // { label: "Pangi", mun_code: "086405" },
  // { label: "Punta", mun_code: "086405" },
  // { label: "Talisay (Pob.)", mun_code: "086405" },
  // { label: "Tigbao", mun_code: "086405" },
  // { label: "Amaga", mun_code: "086406" },
  // { label: "Anilao", mun_code: "086406" },
  // { label: "Bahay", mun_code: "086406" },
  // { label: "Cagbungalon", mun_code: "086406" },
  // { label: "Calian", mun_code: "086406" },
  // { label: "Caligangan", mun_code: "086406" },
  // { label: "Candayuman", mun_code: "086406" },
  // { label: "Estela", mun_code: "086406" },
  // { label: "Gud-an", mun_code: "086406" },
  // { label: "Guintoylan", mun_code: "086406" },
  // { label: "Himayangan", mun_code: "086406" },
  // { label: "Ilag", mun_code: "086406" },
  // { label: "Magaupas", mun_code: "086406" },
  // { label: "Malangsa", mun_code: "086406" },
  // { label: "Pres. Quezon (Maugoc)", mun_code: "086406" },
  // { label: "Molopolo", mun_code: "086406" },
  // { label: "Pandan", mun_code: "086406" },
  // { label: "Poblacion", mun_code: "086406" },
  // { label: "President Roxas", mun_code: "086406" },
  // { label: "San Isidro", mun_code: "086406" },
  // { label: "San Roque", mun_code: "086406" },
  // { label: "Tabugon", mun_code: "086406" },
  // { label: "Catig", mun_code: "086406" },
  // { label: "Fatima", mun_code: "086406" },
  // { label: "Abgao (Pob.)", mun_code: "086407" },
  // { label: "Asuncion", mun_code: "086407" },
  // { label: "Bactul II", mun_code: "086407" },
  // { label: "Bactul I", mun_code: "086407" },
  // { label: "Badiang", mun_code: "086407" },
  // { label: "Bagtican", mun_code: "086407" },
  // { label: "Basak", mun_code: "086407" },
  // { label: "Bato II", mun_code: "086407" },
  // { label: "Bato I", mun_code: "086407" },
  // { label: "Batuan", mun_code: "086407" },
  // { label: "Baugo", mun_code: "086407" },
  // { label: "Bilibol", mun_code: "086407" },
  // { label: "Bogo", mun_code: "086407" },
  // { label: "Cabadiangan", mun_code: "086407" },
  // { label: "Cabulihan", mun_code: "086407" },
  // { label: "Cagnituan", mun_code: "086407" },
  // { label: "Cambooc", mun_code: "086407" },
  // { label: "Cansirong", mun_code: "086407" },
  // { label: "Canturing", mun_code: "086407" },
  // { label: "Canyuom", mun_code: "086407" },
  // { label: "Dongon", mun_code: "086407" },
  // { label: "Gawisan", mun_code: "086407" },
  // { label: "Guadalupe", mun_code: "086407" },
  // { label: "Hanginan", mun_code: "086407" },
  // { label: "Hantag", mun_code: "086407" },
  // { label: "Hinapu Daku", mun_code: "086407" },
  // { label: "Hinapu Gamay", mun_code: "086407" },
  // { label: "Ibarra", mun_code: "086407" },
  // { label: "Isagani", mun_code: "086407" },
  // { label: "Laboon", mun_code: "086407" },
  // { label: "Lanao", mun_code: "086407" },
  // { label: "Libhu", mun_code: "086407" },
  // { label: "Lonoy", mun_code: "086407" },
  // { label: "Lunas", mun_code: "086407" },
  // { label: "Mahayahay", mun_code: "086407" },
  // { label: "Malapoc Norte", mun_code: "086407" },
  // { label: "Malapoc Sur", mun_code: "086407" },
  // { label: "Mambajao (Pob.)", mun_code: "086407" },
  // { label: "Manhilo", mun_code: "086407" },
  // { label: "Mantahan (Pob.)", mun_code: "086407" },
  // { label: "Maria Clara", mun_code: "086407" },
  // { label: "Matin-ao", mun_code: "086407" },
  // { label: "Nasaug", mun_code: "086407" },
  // { label: "Nati", mun_code: "086407" },
  // { label: "Nonok Norte", mun_code: "086407" },
  // { label: "Nonok Sur", mun_code: "086407" },
  // { label: "Panan-awan", mun_code: "086407" },
  // { label: "Pansaan", mun_code: "086407" },
  // { label: "Pinascohan", mun_code: "086407" },
  // { label: "Rizal", mun_code: "086407" },
  // { label: "San Isidro", mun_code: "086407" },
  // { label: "San Jose", mun_code: "086407" },
  // { label: "San Rafael", mun_code: "086407" },
  // { label: "Santa Cruz", mun_code: "086407" },
  // { label: "Santa Rosa", mun_code: "086407" },
  // { label: "Santo Rosario", mun_code: "086407" },
  // { label: "Soro-soro", mun_code: "086407" },
  // { label: "Tagnipa (Pob.)", mun_code: "086407" },
  // { label: "Tam-is", mun_code: "086407" },
  // { label: "Tawid", mun_code: "086407" },
  // { label: "Tigbawan", mun_code: "086407" },
  // { label: "Tomoy-tomoy", mun_code: "086407" },
  // { label: "Tunga-tunga (Pob.)", mun_code: "086407" },
  // { label: "Acasia", mun_code: "086407" },
  // { label: "Combado", mun_code: "086407" },
  // { label: "Libertad", mun_code: "086407" },
  // { label: "Lib-og", mun_code: "086407" },
  // { label: "Pasay", mun_code: "086407" },
  // { label: "San Agustin", mun_code: "086407" },
  // { label: "Aguinaldo", mun_code: "086408" },
  // { label: "Amparo", mun_code: "086408" },
  // { label: "Buscayan", mun_code: "086408" },
  // { label: "Cambaro", mun_code: "086408" },
  // { label: "Canlusay", mun_code: "086408" },
  // { label: "Flordeliz", mun_code: "086408" },
  // { label: "Ichon", mun_code: "086408" },
  // { label: "Ilihan", mun_code: "086408" },
  // { label: "Laray", mun_code: "086408" },
  // { label: "Lower Villa Jacinta", mun_code: "086408" },
  // { label: "Mabini", mun_code: "086408" },
  // { label: "Mohon", mun_code: "086408" },
  // { label: "Molopolo", mun_code: "086408" },
  // { label: "Rizal", mun_code: "086408" },
  // { label: "Salvador (Mangyang)", mun_code: "086408" },
  // { label: "San Isidro", mun_code: "086408" },
  // { label: "San Joaquin", mun_code: "086408" },
  // { label: "San Roque", mun_code: "086408" },
  // { label: "Sindangan", mun_code: "086408" },
  // { label: "Upper Villa Jacinta", mun_code: "086408" },
  // { label: "Asuncion", mun_code: "086408" },
  // { label: "Bagong Silang", mun_code: "086408" },
  // { label: "Danao", mun_code: "086408" },
  // { label: "Guadalupe", mun_code: "086408" },
  // { label: "San Vicente Poblacion", mun_code: "086408" },
  // { label: "San Vicente (Upper San Roque)", mun_code: "086408" },
  // { label: "Santa Cruz (Pob.)", mun_code: "086408" },
  // { label: "Santo Rosario (Pob.)", mun_code: "086408" },
  // { label: "Upper Ichon", mun_code: "086408" },
  // { label: "Abgao", mun_code: "086409" },
  // { label: "Aurora", mun_code: "086409" },
  // { label: "Benit", mun_code: "086409" },
  // { label: "Caaga", mun_code: "086409" },
  // { label: "Cabul-anonan (Pob.)", mun_code: "086409" },
  // { label: "Cadaruhan", mun_code: "086409" },
  // { label: "Candatag", mun_code: "086409" },
  // { label: "Cantamuac", mun_code: "086409" },
  // { label: "Caraatan", mun_code: "086409" },
  // { label: "Concepcion", mun_code: "086409" },
  // { label: "Guinabonan", mun_code: "086409" },
  // { label: "Iba", mun_code: "086409" },
  // { label: "Lambonao", mun_code: "086409" },
  // { label: "Maningning", mun_code: "086409" },
  // { label: "Maujo", mun_code: "086409" },
  // { label: "Pasil (Pob.)", mun_code: "086409" },
  // { label: "Sabang", mun_code: "086409" },
  // { label: "San Antonio (Pob.)", mun_code: "086409" },
  // { label: "San Jose", mun_code: "086409" },
  // { label: "San Roque", mun_code: "086409" },
  // { label: "San Vicente", mun_code: "086409" },
  // { label: "Sangahon", mun_code: "086409" },
  // { label: "Santa Cruz", mun_code: "086409" },
  // { label: "Taliwa (Pob.)", mun_code: "086409" },
  // { label: "Tigbawan I", mun_code: "086409" },
  // { label: "Tigbawan II", mun_code: "086409" },
  // { label: "Timba", mun_code: "086409" },
  // { label: "Asuncion", mun_code: "086409" },
  // { label: "Cadaruhan Sur", mun_code: "086409" },
  // { label: "Fatima", mun_code: "086409" },
  // { label: "Juangon", mun_code: "086409" },
  // { label: "Kauswagan", mun_code: "086409" },
  // { label: "Mahayahay", mun_code: "086409" },
  // { label: "New Katipunan", mun_code: "086409" },
  // { label: "Pancil", mun_code: "086409" },
  // { label: "San Isidro", mun_code: "086409" },
  // { label: "Buenavista", mun_code: "086410" },
  // { label: "Bunga", mun_code: "086410" },
  // { label: "Laca", mun_code: "086410" },
  // { label: "Lungsodaan", mun_code: "086410" },
  // { label: "Poblacion", mun_code: "086410" },
  // { label: "San Juan", mun_code: "086410" },
  // { label: "Santa Sofia", mun_code: "086410" },
  // { label: "Santo Rosario", mun_code: "086410" },
  // { label: "Cantutang", mun_code: "086410" },
  // { label: "Dinahugan", mun_code: "086410" },
  // { label: "Tangkaan", mun_code: "086410" },
  // { label: "Badiang", mun_code: "086411" },
  // { label: "Balongbalong", mun_code: "086411" },
  // { label: "Buenavista", mun_code: "086411" },
  // { label: "Bulawan", mun_code: "086411" },
  // { label: "Canlawis", mun_code: "086411" },
  // { label: "Catbawan", mun_code: "086411" },
  // { label: "Caubang", mun_code: "086411" },
  // { label: "Cogon", mun_code: "086411" },
  // { label: "Dan-an", mun_code: "086411" },
  // { label: "Lobo", mun_code: "086411" },
  // { label: "Mainit", mun_code: "086411" },
  // { label: "Manglit", mun_code: "086411" },
  // { label: "Nueva Estrella Sur", mun_code: "086411" },
  // { label: "Poblacion Ibabao", mun_code: "086411" },
  // { label: "Poblacion Ubos", mun_code: "086411" },
  // { label: "Ponod", mun_code: "086411" },
  // { label: "Son-ok I", mun_code: "086411" },
  // { label: "Tautag", mun_code: "086411" },
  // { label: "Nueva Estrella Norte", mun_code: "086411" },
  // { label: "Pociano D. Equipilag", mun_code: "086411" },
  // { label: "San Roque", mun_code: "086411" },
  // { label: "Santa Cruz", mun_code: "086411" },
  // { label: "Son-ok II", mun_code: "086411" },
  // { label: "Atuyan", mun_code: "086412" },
  // { label: "Ayahag", mun_code: "086412" },
  // { label: "Bantawon", mun_code: "086412" },
  // { label: "Bolodbolod", mun_code: "086412" },
  // { label: "Nueva Esperanza (Cabac-an)", mun_code: "086412" },
  // { label: "Cabagawan", mun_code: "086412" },
  // { label: "Carnaga", mun_code: "086412" },
  // { label: "Catmon", mun_code: "086412" },
  // { label: "Guinsaugon", mun_code: "086412" },
  // { label: "Himatagon (Pob.)", mun_code: "086412" },
  // { label: "Himbangan", mun_code: "086412" },
  // { label: "Himos-onan", mun_code: "086412" },
  // { label: "Hindag-an", mun_code: "086412" },
  // { label: "Kauswagan", mun_code: "086412" },
  // { label: "Libas", mun_code: "086412" },
  // { label: "Lipanto", mun_code: "086412" },
  // { label: "Magatas", mun_code: "086412" },
  // { label: "Magbagacay", mun_code: "086412" },
  // { label: "Mahayag", mun_code: "086412" },
  // { label: "Mahayahay", mun_code: "086412" },
  // { label: "Malibago", mun_code: "086412" },
  // { label: "Malinao", mun_code: "086412" },
  // { label: "Panian", mun_code: "086412" },
  // { label: "San Isidro", mun_code: "086412" },
  // { label: "Santa Cruz", mun_code: "086412" },
  // { label: "Sug-angon", mun_code: "086412" },
  // { label: "Tabontabon", mun_code: "086412" },
  // { label: "Tambis I", mun_code: "086412" },
  // { label: "Tambis II", mun_code: "086412" },
  // { label: "Hinabian", mun_code: "086412" },
  // { label: "Anislagon", mun_code: "086413" },
  // { label: "Bongbong", mun_code: "086413" },
  // { label: "Central (Pob.)", mun_code: "086413" },
  // { label: "Dakit (Pob.)", mun_code: "086413" },
  // { label: "Habay", mun_code: "086413" },
  // { label: "Marayag", mun_code: "086413" },
  // { label: "Napantao", mun_code: "086413" },
  // { label: "Pinamudlan", mun_code: "086413" },
  // { label: "Santa Paz Norte", mun_code: "086413" },
  // { label: "Santa Paz Sur", mun_code: "086413" },
  // { label: "Sudmon", mun_code: "086413" },
  // { label: "Tinaan", mun_code: "086413" },
  // { label: "Tuno", mun_code: "086413" },
  // { label: "Ubos (Pob.)", mun_code: "086413" },
  // { label: "Bongawisan", mun_code: "086413" },
  // { label: "Causi", mun_code: "086413" },
  // { label: "Gabi", mun_code: "086413" },
  // { label: "Cahayag", mun_code: "086413" },
  // { label: "Malico", mun_code: "086413" },
  // { label: "Pasanon", mun_code: "086413" },
  // { label: "Punta", mun_code: "086413" },
  // { label: "Santa Cruz", mun_code: "086413" },
  // { label: "Agay-ay", mun_code: "086414" },
  // { label: "Basak", mun_code: "086414" },
  // { label: "Bobon A", mun_code: "086414" },
  // { label: "Dayanog", mun_code: "086414" },
  // { label: "Santa Filomena", mun_code: "086414" },
  // { label: "Garrido", mun_code: "086414" },
  // { label: "Minoyho", mun_code: "086414" },
  // { label: "Pong-oy", mun_code: "086414" },
  // { label: "San Jose (Pob.)", mun_code: "086414" },
  // { label: "San Vicente", mun_code: "086414" },
  // { label: "Santa Cruz (Pob.)", mun_code: "086414" },
  // { label: "Somoje", mun_code: "086414" },
  // { label: "Sua", mun_code: "086414" },
  // { label: "Timba", mun_code: "086414" },
  // { label: "Osao", mun_code: "086414" },
  // { label: "San Roque", mun_code: "086414" },
  // { label: "Bobon B", mun_code: "086414" },
  // { label: "Benit", mun_code: "086415" },
  // { label: "Bitoon", mun_code: "086415" },
  // { label: "Cabutan", mun_code: "086415" },
  // { label: "Camang", mun_code: "086415" },
  // { label: "Esperanza", mun_code: "086415" },
  // { label: "Pinut-an", mun_code: "086415" },
  // { label: "Poblacion", mun_code: "086415" },
  // { label: "San Antonio (Alangalang)", mun_code: "086415" },
  // { label: "San Ramon", mun_code: "086415" },
  // { label: "Saub", mun_code: "086415" },
  // { label: "Timba", mun_code: "086415" },
  // { label: "Esperanza Dos", mun_code: "086415" },
  // { label: "Kinachawa", mun_code: "086415" },
  // { label: "Inolinan", mun_code: "086415" },
  // { label: "Looc", mun_code: "086415" },
  // { label: "Balagawan", mun_code: "086416" },
  // { label: "Catmon", mun_code: "086416" },
  // { label: "Pob. District I", mun_code: "086416" },
  // { label: "Pob. District II", mun_code: "086416" },
  // { label: "Hingatungan", mun_code: "086416" },
  // { label: "Katipunan", mun_code: "086416" },
  // { label: "Laguma", mun_code: "086416" },
  // { label: "Mercedes", mun_code: "086416" },
  // { label: "Puntana", mun_code: "086416" },
  // { label: "Salvacion", mun_code: "086416" },
  // { label: "Sap-ang", mun_code: "086416" },
  // { label: "Sudmon", mun_code: "086416" },
  // { label: "Tuba-on", mun_code: "086416" },
  // { label: "Tubod", mun_code: "086416" },
  // { label: "Imelda", mun_code: "086416" },
  // { label: "Benit", mun_code: "086417" },
  // { label: "Buac Daku", mun_code: "086417" },
  // { label: "Buac Gamay", mun_code: "086417" },
  // { label: "Cabadbaran", mun_code: "086417" },
  // { label: "Concepcion", mun_code: "086417" },
  // { label: "Consolacion", mun_code: "086417" },
  // { label: "Dagsa", mun_code: "086417" },
  // { label: "Hibod-hibod", mun_code: "086417" },
  // { label: "Hindangan", mun_code: "086417" },
  // { label: "Hipantag", mun_code: "086417" },
  // { label: "Javier", mun_code: "086417" },
  // { label: "Kahupian", mun_code: "086417" },
  // { label: "Kanangkaan", mun_code: "086417" },
  // { label: "Kauswagan", mun_code: "086417" },
  // { label: "La Purisima Concepcion", mun_code: "086417" },
  // { label: "Libas", mun_code: "086417" },
  // { label: "Lum-an", mun_code: "086417" },
  // { label: "Mabicay", mun_code: "086417" },
  // { label: "Mac", mun_code: "086417" },
  // { label: "Magatas", mun_code: "086417" },
  // { label: "Mahayahay", mun_code: "086417" },
  // { label: "Malinao", mun_code: "086417" },
  // { label: "Maria Plana", mun_code: "086417" },
  // { label: "Milagroso", mun_code: "086417" },
  // { label: "Pancho Villa", mun_code: "086417" },
  // { label: "Pandan", mun_code: "086417" },
  // { label: "Zone I (Pob.)", mun_code: "086417" },
  // { label: "Zone II (Pob.)", mun_code: "086417" },
  // { label: "Zone III (Pob.)", mun_code: "086417" },
  // { label: "Zone IV (Pob.)", mun_code: "086417" },
  // { label: "Zone V (Pob.)", mun_code: "086417" },
  // { label: "Rizal", mun_code: "086417" },
  // { label: "Salvacion", mun_code: "086417" },
  // { label: "San Francisco Mabuhay", mun_code: "086417" },
  // { label: "San Isidro", mun_code: "086417" },
  // { label: "San Jose", mun_code: "086417" },
  // { label: "San Juan (Agata)", mun_code: "086417" },
  // { label: "San Miguel", mun_code: "086417" },
  // { label: "San Pedro", mun_code: "086417" },
  // { label: "San Roque", mun_code: "086417" },
  // { label: "San Vicente", mun_code: "086417" },
  // { label: "Santa Maria", mun_code: "086417" },
  // { label: "Suba", mun_code: "086417" },
  // { label: "Tampoong", mun_code: "086417" },
  // { label: "Olisihan", mun_code: "086417" },
  // { label: "Anahawan", mun_code: "086418" },
  // { label: "Banday (Pob.)", mun_code: "086418" },
  // { label: "Bogo (Pob.)", mun_code: "086418" },
  // { label: "Cabascan", mun_code: "086418" },
  // { label: "Camansi", mun_code: "086418" },
  // { label: "Cambite (Pob.)", mun_code: "086418" },
  // { label: "Canlupao", mun_code: "086418" },
  // { label: "Carnaga", mun_code: "086418" },
  // { label: "Cawayan", mun_code: "086418" },
  // { label: "Hinagtikan", mun_code: "086418" },
  // { label: "Hinapo", mun_code: "086418" },
  // { label: "Hugpa", mun_code: "086418" },
  // { label: "Iniguihan Pob. (Salvacion)", mun_code: "086418" },
  // { label: "Looc", mun_code: "086418" },
  // { label: "Maanyag", mun_code: "086418" },
  // { label: "Maslog", mun_code: "086418" },
  // { label: "Ponong", mun_code: "086418" },
  // { label: "Rizal", mun_code: "086418" },
  // { label: "San Isidro", mun_code: "086418" },
  // { label: "San Miguel", mun_code: "086418" },
  // { label: "Tinago", mun_code: "086418" },
  // { label: "Biasong", mun_code: "086418" },
  // { label: "Higosoan", mun_code: "086418" },
  // { label: "Mag-ata", mun_code: "086418" },
  // { label: "San Antonio", mun_code: "086418" },
  // { label: "San Roque", mun_code: "086418" },
  // { label: "Luan", mun_code: "086418" },
  // { label: "Mapgap", mun_code: "086418" },
  // { label: "San Agustin", mun_code: "086418" },
  // { label: "Cabulihan", mun_code: "086419" },
  // { label: "Lugsongan", mun_code: "086419" },
  // { label: "Magallanes", mun_code: "086419" },
  // { label: "San Agustin", mun_code: "086419" },
  // { label: "San Bernardo", mun_code: "086419" },
  // { label: "Triana", mun_code: "086419" },
  // { label: "Caucab", mun_code: "087801" },
  // { label: "Iyosan", mun_code: "087801" },
  // { label: "Jamorawon", mun_code: "087801" },
  // { label: "Lo-ok", mun_code: "087801" },
  // { label: "Matanga", mun_code: "087801" },
  // { label: "Pili", mun_code: "087801" },
  // { label: "Poblacion", mun_code: "087801" },
  // { label: "Pulang Bato", mun_code: "087801" },
  // { label: "Salangi", mun_code: "087801" },
  // { label: "Sampao", mun_code: "087801" },
  // { label: "Tabunan", mun_code: "087801" },
  // { label: "Talahid", mun_code: "087801" },
  // { label: "Tamarindo", mun_code: "087801" },
  // { label: "Bato", mun_code: "087802" },
  // { label: "Burabod", mun_code: "087802" },
  // { label: "Busali", mun_code: "087802" },
  // { label: "Hugpa", mun_code: "087802" },
  // { label: "Julita", mun_code: "087802" },
  // { label: "Canila", mun_code: "087802" },
  // { label: "Pinangumhan", mun_code: "087802" },
  // { label: "San Isidro (Pob.)", mun_code: "087802" },
  // { label: "San Roque (Pob.)", mun_code: "087802" },
  // { label: "Sanggalang", mun_code: "087802" },
  // { label: "Villa Enage (Baras)", mun_code: "087802" },
  // { label: "Balaquid", mun_code: "087803" },
  // { label: "Baso", mun_code: "087803" },
  // { label: "Bunga", mun_code: "087803" },
  // { label: "Caanibongan", mun_code: "087803" },
  // { label: "Casiawan", mun_code: "087803" },
  // { label: "Esperanza (Pob.)", mun_code: "087803" },
  // { label: "Langgao", mun_code: "087803" },
  // { label: "Libertad", mun_code: "087803" },
  // { label: "Looc", mun_code: "087803" },
  // { label: "Magbangon (Pob.)", mun_code: "087803" },
  // { label: "Pawikan", mun_code: "087803" },
  // { label: "Salawad", mun_code: "087803" },
  // { label: "Talibong", mun_code: "087803" },
  // { label: "Alegria", mun_code: "087804" },
  // { label: "Asug", mun_code: "087804" },
  // { label: "Bari-is", mun_code: "087804" },
  // { label: "Binohangan", mun_code: "087804" },
  // { label: "Cabibihan", mun_code: "087804" },
  // { label: "Kawayanon", mun_code: "087804" },
  // { label: "Looc", mun_code: "087804" },
  // { label: "Manlabang", mun_code: "087804" },
  // { label: "Caulangohan (Marevil)", mun_code: "087804" },
  // { label: "Maurang", mun_code: "087804" },
  // { label: "Palanay (Pob.)", mun_code: "087804" },
  // { label: "Palengke (Pob.)", mun_code: "087804" },
  // { label: "Tomalistis", mun_code: "087804" },
  // { label: "Union", mun_code: "087804" },
  // { label: "Uson", mun_code: "087804" },
  // { label: "Victory (Pob.)", mun_code: "087804" },
  // { label: "Villa Vicenta (Mainit)", mun_code: "087804" },
  // { label: "Acaban", mun_code: "087805" },
  // { label: "Bacolod", mun_code: "087805" },
  // { label: "Binongtoan", mun_code: "087805" },
  // { label: "Bool Central (Pob.)", mun_code: "087805" },
  // { label: "Bool East (Pob.)", mun_code: "087805" },
  // { label: "Bool West (Pob.)", mun_code: "087805" },
  // { label: "Calipayan", mun_code: "087805" },
  // { label: "Guindapunan", mun_code: "087805" },
  // { label: "Habuhab", mun_code: "087805" },
  // { label: "Looc", mun_code: "087805" },
  // { label: "Marvel (Pob.)", mun_code: "087805" },
  // { label: "Patag", mun_code: "087805" },
  // { label: "Pinamihagan", mun_code: "087805" },
  // { label: "Culaba Central (Pob.)", mun_code: "087805" },
  // { label: "Salvacion", mun_code: "087805" },
  // { label: "San Roque", mun_code: "087805" },
  // { label: "Virginia (Pob.)", mun_code: "087805" },
  // { label: "Baganito", mun_code: "087806" },
  // { label: "Balacson", mun_code: "087806" },
  // { label: "Bilwang", mun_code: "087806" },
  // { label: "Bulalacao", mun_code: "087806" },
  // { label: "Burabod", mun_code: "087806" },
  // { label: "Inasuyan", mun_code: "087806" },
  // { label: "Kansanok", mun_code: "087806" },
  // { label: "Mada-o", mun_code: "087806" },
  // { label: "Mapuyo", mun_code: "087806" },
  // { label: "Masagaosao", mun_code: "087806" },
  // { label: "Masagongsong", mun_code: "087806" },
  // { label: "Poblacion", mun_code: "087806" },
  // { label: "Tabunan North", mun_code: "087806" },
  // { label: "Tubig Guinoo", mun_code: "087806" },
  // { label: "Tucdao", mun_code: "087806" },
  // { label: "Ungale", mun_code: "087806" },
  // { label: "Balite", mun_code: "087806" },
  // { label: "Buyo", mun_code: "087806" },
  // { label: "Villa Cornejo (Looc)", mun_code: "087806" },
  // { label: "San Lorenzo", mun_code: "087806" },
  // { label: "Agutay", mun_code: "087807" },
  // { label: "Banlas", mun_code: "087807" },
  // { label: "Bato", mun_code: "087807" },
  // { label: "Binalayan West", mun_code: "087807" },
  // { label: "Binalayan East", mun_code: "087807" },
  // { label: "Burabod", mun_code: "087807" },
  // { label: "Calbani", mun_code: "087807" },
  // { label: "Canduhao", mun_code: "087807" },
  // { label: "Casibang", mun_code: "087807" },
  // { label: "Danao", mun_code: "087807" },
  // { label: "Ol-og", mun_code: "087807" },
  // { label: "Binongto-an (Poblacion Norte)", mun_code: "087807" },
  // { label: "Ermita (Poblacion Sur)", mun_code: "087807" },
  // { label: "Trabugan", mun_code: "087807" },
  // { label: "Viga", mun_code: "087807" },
  // { label: "Agpangi", mun_code: "087808" },
  // { label: "Anislagan", mun_code: "087808" },
  // { label: "Atipolo", mun_code: "087808" },
  // { label: "Calumpang", mun_code: "087808" },
  // { label: "Caraycaray", mun_code: "087808" },
  // { label: "Catmon", mun_code: "087808" },
  // { label: "Haguikhikan", mun_code: "087808" },
  // { label: "Padre Inocentes Garcia (Pob.)", mun_code: "087808" },
  // { label: "Libertad", mun_code: "087808" },
  // { label: "Lico", mun_code: "087808" },
  // { label: "Lucsoon", mun_code: "087808" },
  // { label: "Mabini", mun_code: "087808" },
  // { label: "San Pablo", mun_code: "087808" },
  // { label: "Santissimo Rosario Pob. (Santo Rosa)", mun_code: "087808" },
  // { label: "Talustusan", mun_code: "087808" },
  // { label: "Villa Caneja", mun_code: "087808" },
  // { label: "Villa Consuelo", mun_code: "087808" },
  // { label: "Borac", mun_code: "087808" },
  // { label: "Cabungaan", mun_code: "087808" },
  // { label: "Imelda", mun_code: "087808" },
  // { label: "Larrazabal", mun_code: "087808" },
  // { label: "Libtong", mun_code: "087808" },
  // { label: "Padre Sergio Eamiguel", mun_code: "087808" },
  // { label: "Sabang", mun_code: "087808" },
  // { label: "Aliguay", mun_code: "097201" },
  // { label: "Antipolo", mun_code: "097201" },
  // { label: "Aseniero", mun_code: "097201" },
  // { label: "Ba-ao", mun_code: "097201" },
  // { label: "Banbanan", mun_code: "097201" },
  // { label: "Barcelona", mun_code: "097201" },
  // { label: "Baylimango", mun_code: "097201" },
  // { label: "Burgos", mun_code: "097201" },
  // { label: "Canlucani", mun_code: "097201" },
  // { label: "Carang", mun_code: "097201" },
  // { label: "Dampalan", mun_code: "097201" },
  // { label: "Daro", mun_code: "097201" },
  // { label: "Diwa-an", mun_code: "097201" },
  // { label: "Guimputlan", mun_code: "097201" },
  // { label: "Hilltop", mun_code: "097201" },
  // { label: "Ilaya", mun_code: "097201" },
  // { label: "Larayan", mun_code: "097201" },
  // { label: "Liyang", mun_code: "097201" },
  // { label: "Maria Cristina", mun_code: "097201" },
  // { label: "Maria Uray", mun_code: "097201" },
  // { label: "Masidlakon", mun_code: "097201" },
  // { label: "Napo", mun_code: "097201" },
  // { label: "Opao", mun_code: "097201" },
  // { label: "Oro", mun_code: "097201" },
  // { label: "Owaon", mun_code: "097201" },
  // { label: "Oyan", mun_code: "097201" },
  // { label: "Polo", mun_code: "097201" },
  // { label: "Potungan", mun_code: "097201" },
  // { label: "San Francisco", mun_code: "097201" },
  // { label: "San Nicolas", mun_code: "097201" },
  // { label: "San Pedro", mun_code: "097201" },
  // { label: "San Vicente", mun_code: "097201" },
  // { label: "Sicayab Bocana", mun_code: "097201" },
  // { label: "Sigayan", mun_code: "097201" },
  // { label: "Silinog", mun_code: "097201" },
  // { label: "Sinonoc", mun_code: "097201" },
  // { label: "Sulangon", mun_code: "097201" },
  // { label: "Tag-olo", mun_code: "097201" },
  // { label: "Taguilon", mun_code: "097201" },
  // { label: "Kauswagan (Talisay)", mun_code: "097201" },
  // { label: "Tamion", mun_code: "097201" },
  // { label: "Bagting (Pob.)", mun_code: "097201" },
  // { label: "Banonong (Pob.)", mun_code: "097201" },
  // { label: "Cawa-cawa (Pob.)", mun_code: "097201" },
  // { label: "Dawo (Pob.)", mun_code: "097201" },
  // { label: "Matagobtob Pob. (Talisay)", mun_code: "097201" },
  // { label: "Linabo (Pob.)", mun_code: "097201" },
  // { label: "Potol (Pob.)", mun_code: "097201" },
  // { label: "Santa Cruz (Pob.)", mun_code: "097201" },
  // { label: "Cogon", mun_code: "097202" },
  // { label: "Dicayas", mun_code: "097202" },
  // { label: "Diwan", mun_code: "097202" },
  // { label: "Galas", mun_code: "097202" },
  // { label: "Gulayon", mun_code: "097202" },
  // { label: "Lugdungan", mun_code: "097202" },
  // { label: "Minaog", mun_code: "097202" },
  // { label: "Olingan", mun_code: "097202" },
  // { label: "Estaca (Pob.)", mun_code: "097202" },
  // { label: "Biasong (Pob.)", mun_code: "097202" },
  // { label: "Barra (Pob.)", mun_code: "097202" },
  // { label: "Central (Pob.)", mun_code: "097202" },
  // { label: "Miputak (Pob.)", mun_code: "097202" },
  // { label: "Punta", mun_code: "097202" },
  // { label: "San Jose", mun_code: "097202" },
  // { label: "Sangkol", mun_code: "097202" },
  // { label: "Santa Filomena", mun_code: "097202" },
  // { label: "Sicayab", mun_code: "097202" },
  // { label: "Sinaman", mun_code: "097202" },
  // { label: "Turno", mun_code: "097202" },
  // { label: "Santa Isabel", mun_code: "097202" },
  // { label: "Balok", mun_code: "097203" },
  // { label: "Basagan", mun_code: "097203" },
  // { label: "Biniray", mun_code: "097203" },
  // { label: "Bulawan", mun_code: "097203" },
  // { label: "Daanglungsod", mun_code: "097203" },
  // { label: "Dabiak", mun_code: "097203" },
  // { label: "Dr. Jose Rizal (Lower Mias)", mun_code: "097203" },
  // { label: "Fimagas", mun_code: "097203" },
  // { label: "Malugas", mun_code: "097203" },
  // { label: "Malasay", mun_code: "097203" },
  // { label: "Matam", mun_code: "097203" },
  // { label: "Mias", mun_code: "097203" },
  // { label: "Miatan", mun_code: "097203" },
  // { label: "Nanginan", mun_code: "097203" },
  // { label: "Barangay Uno (Pob.)", mun_code: "097203" },
  // { label: "Barangay Dos (Pob.)", mun_code: "097203" },
  // { label: "San Antonio (Looy)", mun_code: "097203" },
  // { label: "Seres", mun_code: "097203" },
  // { label: "Seroan", mun_code: "097203" },
  // { label: "Singatong", mun_code: "097203" },
  // { label: "Sinuyak", mun_code: "097203" },
  // { label: "Sitog", mun_code: "097203" },
  // { label: "Tuburan", mun_code: "097203" },
  // { label: "Carupay", mun_code: "097203" },
  // { label: "Loyuran", mun_code: "097203" },
  // { label: "New Tambo", mun_code: "097203" },
  // { label: "Patik", mun_code: "097203" },
  // { label: "Sanao", mun_code: "097203" },
  // { label: "San Vicente", mun_code: "097203" },
  // { label: "El Paraiso", mun_code: "097204" },
  // { label: "La Union", mun_code: "097204" },
  // { label: "La Victoria", mun_code: "097204" },
  // { label: "Mauswagon", mun_code: "097204" },
  // { label: "Mercedes", mun_code: "097204" },
  // { label: "New Argao", mun_code: "097204" },
  // { label: "New Bataan", mun_code: "097204" },
  // { label: "New Carcar", mun_code: "097204" },
  // { label: "Poblacion", mun_code: "097204" },
  // { label: "San Jose", mun_code: "097204" },
  // { label: "Santa Catalina", mun_code: "097204" },
  // { label: "Santa Cruz", mun_code: "097204" },
  // { label: "Singaran", mun_code: "097204" },
  // { label: "Antonino (Pob.)", mun_code: "097205" },
  // { label: "Balas", mun_code: "097205" },
  // { label: "Bobongan", mun_code: "097205" },
  // { label: "Dansalan", mun_code: "097205" },
  // { label: "Gabu", mun_code: "097205" },
  // { label: "Immaculada", mun_code: "097205" },
  // { label: "Kipit", mun_code: "097205" },
  // { label: "La Union", mun_code: "097205" },
  // { label: "Lapatan", mun_code: "097205" },
  // { label: "Lawagan", mun_code: "097205" },
  // { label: "Lawigan", mun_code: "097205" },
  // { label: "Lopoc (Pob.)", mun_code: "097205" },
  // { label: "Malintuboan", mun_code: "097205" },
  // { label: "New Salvacion", mun_code: "097205" },
  // { label: "Osukan", mun_code: "097205" },
  // { label: "Patawag", mun_code: "097205" },
  // { label: "San Isidro", mun_code: "097205" },
  // { label: "Ubay", mun_code: "097205" },
  // { label: "Gil Sanchez", mun_code: "097205" },
  // { label: "Imelda", mun_code: "097205" },
  // { label: "Banigan", mun_code: "097206" },
  // { label: "Baybay (Pob.)", mun_code: "097206" },
  // { label: "Cabangcalan", mun_code: "097206" },
  // { label: "Candelaria", mun_code: "097206" },
  // { label: "Causwagan", mun_code: "097206" },
  // { label: "Communal", mun_code: "097206" },
  // { label: "Compra", mun_code: "097206" },
  // { label: "Fatima", mun_code: "097206" },
  // { label: "Goaw", mun_code: "097206" },
  // { label: "Goin", mun_code: "097206" },
  // { label: "Kayok", mun_code: "097206" },
  // { label: "Lamao", mun_code: "097206" },
  // { label: "La Libertad (Mawal)", mun_code: "097206" },
  // { label: "Panabang", mun_code: "097206" },
  // { label: "Patawag", mun_code: "097206" },
  // { label: "Punta", mun_code: "097206" },
  // { label: "San Isidro", mun_code: "097206" },
  // { label: "San Francisco", mun_code: "097206" },
  // { label: "San Miguel", mun_code: "097206" },
  // { label: "Santa Cruz", mun_code: "097206" },
  // { label: "Silucap", mun_code: "097206" },
  // { label: "Tapican", mun_code: "097206" },
  // { label: "Timan", mun_code: "097206" },
  // { label: "Villa M. Tejero", mun_code: "097206" },
  // { label: "Dela Paz", mun_code: "097206" },
  // { label: "El Paraiso", mun_code: "097206" },
  // { label: "Ganase", mun_code: "097206" },
  // { label: "Mabuhay", mun_code: "097206" },
  // { label: "Maigang", mun_code: "097206" },
  // { label: "Malila", mun_code: "097206" },
  // { label: "Mauswagon", mun_code: "097206" },
  // { label: "New Bethlehem", mun_code: "097206" },
  // { label: "Overview", mun_code: "097206" },
  // { label: "San Roque", mun_code: "097206" },
  // { label: "Villa Calixto Sudiacal", mun_code: "097206" },
  // { label: "Canaan", mun_code: "097206" },
  // { label: "Dipane", mun_code: "097207" },
  // { label: "Disakan", mun_code: "097207" },
  // { label: "Gupot", mun_code: "097207" },
  // { label: "Libuton", mun_code: "097207" },
  // { label: "Linay", mun_code: "097207" },
  // { label: "Lupasang", mun_code: "097207" },
  // { label: "Mate", mun_code: "097207" },
  // { label: "Poblacion", mun_code: "097207" },
  // { label: "Saluyong", mun_code: "097207" },
  // { label: "Serongan", mun_code: "097207" },
  // { label: "Villaramos", mun_code: "097207" },
  // { label: "Don Jose Aguirre", mun_code: "097207" },
  // { label: "Lingatongan", mun_code: "097207" },
  // { label: "Meses", mun_code: "097207" },
  // { label: "Palaranan", mun_code: "097207" },
  // { label: "Pangandao", mun_code: "097207" },
  // { label: "Patagan", mun_code: "097207" },
  // { label: "San Antonio", mun_code: "097207" },
  // { label: "Upper Disakan", mun_code: "097207" },
  // { label: "East Poblacion", mun_code: "097207" },
  // { label: "Punta Blanca", mun_code: "097207" },
  // { label: "Suisayan", mun_code: "097207" },
  // { label: "Alvenda", mun_code: "097208" },
  // { label: "Buenasuerte", mun_code: "097208" },
  // { label: "Diland", mun_code: "097208" },
  // { label: "Diolen", mun_code: "097208" },
  // { label: "Head Tipan", mun_code: "097208" },
  // { label: "New Casul", mun_code: "097208" },
  // { label: "Newland", mun_code: "097208" },
  // { label: "New Siquijor", mun_code: "097208" },
  // { label: "Paso Rio", mun_code: "097208" },
  // { label: "Poblacion", mun_code: "097208" },
  // { label: "San Miguel", mun_code: "097208" },
  // { label: "Tinglan", mun_code: "097208" },
  // { label: "Totongon", mun_code: "097208" },
  // { label: "Tubac", mun_code: "097208" },
  // { label: "Unidos", mun_code: "097208" },
  // { label: "Santo Tomas", mun_code: "097208" },
  // { label: "Adante", mun_code: "097209" },
  // { label: "Bacuyong", mun_code: "097209" },
  // { label: "Bagong Silang", mun_code: "097209" },
  // { label: "Calican", mun_code: "097209" },
  // { label: "Ubay (Daan Tipan)", mun_code: "097209" },
  // { label: "Del Pilar", mun_code: "097209" },
  // { label: "Dilawa", mun_code: "097209" },
  // { label: "Desin", mun_code: "097209" },
  // { label: "Dionum", mun_code: "097209" },
  // { label: "Lapu-lapu", mun_code: "097209" },
  // { label: "Lower Gumay", mun_code: "097209" },
  // { label: "Luzvilla", mun_code: "097209" },
  // { label: "Poblacion North", mun_code: "097209" },
  // { label: "Santa Fe", mun_code: "097209" },
  // { label: "Segabe", mun_code: "097209" },
  // { label: "Sikitan", mun_code: "097209" },
  // { label: "Silano", mun_code: "097209" },
  // { label: "Teresita", mun_code: "097209" },
  // { label: "Tinaytayan", mun_code: "097209" },
  // { label: "Upper Gumay", mun_code: "097209" },
  // { label: "Villarico", mun_code: "097209" },
  // { label: "Poblacion South", mun_code: "097209" },
  // { label: "Anastacio", mun_code: "097210" },
  // { label: "Bandera", mun_code: "097210" },
  // { label: "Bethlehem", mun_code: "097210" },
  // { label: "Dangi", mun_code: "097210" },
  // { label: "Dansullan", mun_code: "097210" },
  // { label: "De Venta Perla", mun_code: "097210" },
  // { label: "Guinles", mun_code: "097210" },
  // { label: "Isis", mun_code: "097210" },
  // { label: "Labrador (Prinda)", mun_code: "097210" },
  // { label: "Lapayanbaja", mun_code: "097210" },
  // { label: "Letapan", mun_code: "097210" },
  // { label: "Linabo", mun_code: "097210" },
  // { label: "Lingasad", mun_code: "097210" },
  // { label: "Macleodes", mun_code: "097210" },
  // { label: "Magangon", mun_code: "097210" },
  // { label: "Maligaya", mun_code: "097210" },
  // { label: "Milad", mun_code: "097210" },
  // { label: "New Lebangon", mun_code: "097210" },
  // { label: "New Sicayab", mun_code: "097210" },
  // { label: "Obay", mun_code: "097210" },
  // { label: "Pian", mun_code: "097210" },
  // { label: "Poblacion South", mun_code: "097210" },
  // { label: "San Antonio (Paetan)", mun_code: "097210" },
  // { label: "San Miguel (Loboc)", mun_code: "097210" },
  // { label: "San Pedro", mun_code: "097210" },
  // { label: "Silawe", mun_code: "097210" },
  // { label: "Sianib", mun_code: "097210" },
  // { label: "Villahermosa", mun_code: "097210" },
  // { label: "Poblacion North", mun_code: "097210" },
  // { label: "Balubo", mun_code: "097211" },
  // { label: "Canibongan", mun_code: "097211" },
  // { label: "Capase", mun_code: "097211" },
  // { label: "Denoman", mun_code: "097211" },
  // { label: "Dohinob", mun_code: "097211" },
  // { label: "Langatian", mun_code: "097211" },
  // { label: "Lipakan", mun_code: "097211" },
  // { label: "Marupay", mun_code: "097211" },
  // { label: "Moliton", mun_code: "097211" },
  // { label: "Nabilid", mun_code: "097211" },
  // { label: "Pangologon", mun_code: "097211" },
  // { label: "Sebod", mun_code: "097211" },
  // { label: "Tanayan", mun_code: "097211" },
  // { label: "Villahermoso", mun_code: "097211" },
  // { label: "Banbanan", mun_code: "097211" },
  // { label: "Cape", mun_code: "097211" },
  // { label: "Galokso", mun_code: "097211" },
  // { label: "Gubat", mun_code: "097211" },
  // { label: "Irasan", mun_code: "097211" },
  // { label: "Labakid", mun_code: "097211" },
  // { label: "Panampalay", mun_code: "097211" },
  // { label: "Piao", mun_code: "097211" },
  // { label: "Pongolan", mun_code: "097211" },
  // { label: "Salisig", mun_code: "097211" },
  // { label: "Sibatog", mun_code: "097211" },
  // { label: "Situbo", mun_code: "097211" },
  // { label: "Tantingon", mun_code: "097211" },
  // { label: "Upper Irasan", mun_code: "097211" },
  // { label: "Upper Minang", mun_code: "097211" },
  // { label: "Birayan", mun_code: "097212" },
  // { label: "Damasing", mun_code: "097212" },
  // { label: "La Esperanza", mun_code: "097212" },
  // { label: "Mabuhay", mun_code: "097212" },
  // { label: "Mabunao", mun_code: "097212" },
  // { label: "North Mapang", mun_code: "097212" },
  // { label: "Mitimos", mun_code: "097212" },
  // { label: "Nangca", mun_code: "097212" },
  // { label: "Nangcaan", mun_code: "097212" },
  // { label: "Napilan", mun_code: "097212" },
  // { label: "Nasipang", mun_code: "097212" },
  // { label: "New Dapitan", mun_code: "097212" },
  // { label: "Nilabo", mun_code: "097212" },
  // { label: "East Poblacion", mun_code: "097212" },
  // { label: "Rizalina", mun_code: "097212" },
  // { label: "San Roque", mun_code: "097212" },
  // { label: "Sebaca", mun_code: "097212" },
  // { label: "Sipaon", mun_code: "097212" },
  // { label: "Tolon", mun_code: "097212" },
  // { label: "Balubohan", mun_code: "097212" },
  // { label: "South Mapang", mun_code: "097212" },
  // { label: "West Poblacion", mun_code: "097212" },
  // { label: "Bacong", mun_code: "097213" },
  // { label: "Balakan", mun_code: "097213" },
  // { label: "Binoni", mun_code: "097213" },
  // { label: "Calucap", mun_code: "097213" },
  // { label: "Canawan", mun_code: "097213" },
  // { label: "Caracol", mun_code: "097213" },
  // { label: "Danao", mun_code: "097213" },
  // { label: "Dinoan", mun_code: "097213" },
  // { label: "Dipolod", mun_code: "097213" },
  // { label: "Fatima (Pogan)", mun_code: "097213" },
  // { label: "Liguac", mun_code: "097213" },
  // { label: "Lipakan", mun_code: "097213" },
  // { label: "Mucas", mun_code: "097213" },
  // { label: "Poblacion (Salug)", mun_code: "097213" },
  // { label: "Ramon Magsaysay", mun_code: "097213" },
  // { label: "Tambalang", mun_code: "097213" },
  // { label: "Tapalan", mun_code: "097213" },
  // { label: "Ipilan", mun_code: "097213" },
  // { label: "Lanawan", mun_code: "097213" },
  // { label: "Pukay", mun_code: "097213" },
  // { label: "Pacuhan", mun_code: "097213" },
  // { label: "Poblacion East", mun_code: "097213" },
  // { label: "Antonino", mun_code: "097214" },
  // { label: "Bagong Baguio", mun_code: "097214" },
  // { label: "Bagumbayan", mun_code: "097214" },
  // { label: "Biayon", mun_code: "097214" },
  // { label: "Buenavista", mun_code: "097214" },
  // { label: "Dampalan", mun_code: "097214" },
  // { label: "Danao", mun_code: "097214" },
  // { label: "Don Eleno", mun_code: "097214" },
  // { label: "Kauswagan", mun_code: "097214" },
  // { label: "Labiray", mun_code: "097214" },
  // { label: "Liwanag", mun_code: "097214" },
  // { label: "Mabuhay", mun_code: "097214" },
  // { label: "Macalibre", mun_code: "097214" },
  // { label: "Mahayahay", mun_code: "097214" },
  // { label: "Marapong", mun_code: "097214" },
  // { label: "Nazareth", mun_code: "097214" },
  // { label: "Nebo", mun_code: "097214" },
  // { label: "New Rizal", mun_code: "097214" },
  // { label: "New Tangub", mun_code: "097214" },
  // { label: "Nuevavista", mun_code: "097214" },
  // { label: "Pedagan", mun_code: "097214" },
  // { label: "Penacio", mun_code: "097214" },
  // { label: "Poblacion Alto", mun_code: "097214" },
  // { label: "Poblacion Bajo", mun_code: "097214" },
  // { label: "Princesa Lamaya", mun_code: "097214" },
  // { label: "Princesa Freshia", mun_code: "097214" },
  // { label: "San Antonio", mun_code: "097214" },
  // { label: "San Francisco", mun_code: "097214" },
  // { label: "San Isidro", mun_code: "097214" },
  // { label: "San Jose", mun_code: "097214" },
  // { label: "San Juan", mun_code: "097214" },
  // { label: "Sinaad", mun_code: "097214" },
  // { label: "Sinai", mun_code: "097214" },
  // { label: "Situbo", mun_code: "097214" },
  // { label: "Tinago", mun_code: "097214" },
  // { label: "Tinindugan", mun_code: "097214" },
  // { label: "Tuburan", mun_code: "097214" },
  // { label: "Venus", mun_code: "097214" },
  // { label: "Wilben", mun_code: "097214" },
  // { label: "Balok", mun_code: "097215" },
  // { label: "Datagan", mun_code: "097215" },
  // { label: "Denoyan", mun_code: "097215" },
  // { label: "Diongan", mun_code: "097215" },
  // { label: "Domogok", mun_code: "097215" },
  // { label: "Dumpilas", mun_code: "097215" },
  // { label: "Gonayen", mun_code: "097215" },
  // { label: "Guibo", mun_code: "097215" },
  // { label: "Gunyan", mun_code: "097215" },
  // { label: "Litolet", mun_code: "097215" },
  // { label: "Macasing", mun_code: "097215" },
  // { label: "Mangilay", mun_code: "097215" },
  // { label: "Moyo", mun_code: "097215" },
  // { label: "Pange", mun_code: "097215" },
  // { label: "Paranglumba (Pob.)", mun_code: "097215" },
  // { label: "Polayo", mun_code: "097215" },
  // { label: "Sayaw", mun_code: "097215" },
  // { label: "Seriac", mun_code: "097215" },
  // { label: "Siayan Proper (Pob.)", mun_code: "097215" },
  // { label: "Balunokan", mun_code: "097215" },
  // { label: "Suguilon", mun_code: "097215" },
  // { label: "Anongan", mun_code: "097216" },
  // { label: "Basak", mun_code: "097216" },
  // { label: "Cawit-cawit", mun_code: "097216" },
  // { label: "Dinulan", mun_code: "097216" },
  // { label: "Jatian", mun_code: "097216" },
  // { label: "Lakiki", mun_code: "097216" },
  // { label: "Lambagoan", mun_code: "097216" },
  // { label: "Limpapa", mun_code: "097216" },
  // { label: "Lingayon", mun_code: "097216" },
  // { label: "Lintangan", mun_code: "097216" },
  // { label: "Litawan", mun_code: "097216" },
  // { label: "Lunday", mun_code: "097216" },
  // { label: "Malayal", mun_code: "097216" },
  // { label: "Mantivo", mun_code: "097216" },
  // { label: "Panganuran", mun_code: "097216" },
  // { label: "Pangian", mun_code: "097216" },
  // { label: "Paniran", mun_code: "097216" },
  // { label: "Poblacion", mun_code: "097216" },
  // { label: "Puliran", mun_code: "097216" },
  // { label: "Bongalao", mun_code: "097216" },
  // { label: "Cabbunan", mun_code: "097216" },
  // { label: "Culaguan", mun_code: "097216" },
  // { label: "Cusipan", mun_code: "097216" },
  // { label: "Kamarangan", mun_code: "097216" },
  // { label: "Nala (Pob.)", mun_code: "097216" },
  // { label: "Pasilnahut", mun_code: "097216" },
  // { label: "Tangarak", mun_code: "097216" },
  // { label: "Bagacay", mun_code: "097217" },
  // { label: "Calilic", mun_code: "097217" },
  // { label: "Calube", mun_code: "097217" },
  // { label: "Kanim", mun_code: "097217" },
  // { label: "Delapa", mun_code: "097217" },
  // { label: "Libay", mun_code: "097217" },
  // { label: "Magsaysay", mun_code: "097217" },
  // { label: "Marapong", mun_code: "097217" },
  // { label: "Minlasag", mun_code: "097217" },
  // { label: "Oyan", mun_code: "097217" },
  // { label: "Panganuran", mun_code: "097217" },
  // { label: "Poblacion (Sibutad)", mun_code: "097217" },
  // { label: "Sawang", mun_code: "097217" },
  // { label: "Sibuloc", mun_code: "097217" },
  // { label: "Sinipay", mun_code: "097217" },
  // { label: "Sipaloc", mun_code: "097217" },
  // { label: "Bago", mun_code: "097218" },
  // { label: "Binuangan", mun_code: "097218" },
  // { label: "Bitoon", mun_code: "097218" },
  // { label: "Dicoyong", mun_code: "097218" },
  // { label: "Don Ricardo Macias (Dinobot)", mun_code: "097218" },
  // { label: "Dumalogdog", mun_code: "097218" },
  // { label: "Inuman", mun_code: "097218" },
  // { label: "La Concepcion", mun_code: "097218" },
  // { label: "Lagag", mun_code: "097218" },
  // { label: "Lapero", mun_code: "097218" },
  // { label: "Mandih", mun_code: "097218" },
  // { label: "Maras", mun_code: "097218" },
  // { label: "Mawal", mun_code: "097218" },
  // { label: "Misok", mun_code: "097218" },
  // { label: "Motibot", mun_code: "097218" },
  // { label: "Nato", mun_code: "097218" },
  // { label: "Pangalalan", mun_code: "097218" },
  // { label: "Piao", mun_code: "097218" },
  // { label: "Poblacion", mun_code: "097218" },
  // { label: "Siare", mun_code: "097218" },
  // { label: "Talinga", mun_code: "097218" },
  // { label: "Tinaplan", mun_code: "097218" },
  // { label: "Tigbao", mun_code: "097218" },
  // { label: "Titik", mun_code: "097218" },
  // { label: "Bato", mun_code: "097218" },
  // { label: "Bucana", mun_code: "097218" },
  // { label: "Caluan", mun_code: "097218" },
  // { label: "Calubian", mun_code: "097218" },
  // { label: "Calatunan", mun_code: "097218" },
  // { label: "Dagohoy", mun_code: "097218" },
  // { label: "Datagan", mun_code: "097218" },
  // { label: "Disud", mun_code: "097218" },
  // { label: "Gampis", mun_code: "097218" },
  // { label: "Goleo", mun_code: "097218" },
  // { label: "Imelda", mun_code: "097218" },
  // { label: "Joaquin Macias", mun_code: "097218" },
  // { label: "Labakid", mun_code: "097218" },
  // { label: "Santo Rosario", mun_code: "097218" },
  // { label: "Upper Nipaan", mun_code: "097218" },
  // { label: "Benigno Aquino Jr.", mun_code: "097218" },
  // { label: "Fatima", mun_code: "097218" },
  // { label: "Balok", mun_code: "097218" },
  // { label: "Bantayan", mun_code: "097218" },
  // { label: "Dapaon", mun_code: "097218" },
  // { label: "Datu Tangkilan", mun_code: "097218" },
  // { label: "La Roche San Miguel", mun_code: "097218" },
  // { label: "Lawis", mun_code: "097218" },
  // { label: "Magsaysay", mun_code: "097218" },
  // { label: "Nipaan", mun_code: "097218" },
  // { label: "Upper Inuman", mun_code: "097218" },
  // { label: "Balagunan", mun_code: "097219" },
  // { label: "Andres Micubo Jr. (Balili)", mun_code: "097219" },
  // { label: "Bucana", mun_code: "097219" },
  // { label: "Bulacan", mun_code: "097219" },
  // { label: "Dionisio Riconalla", mun_code: "097219" },
  // { label: "Candiz", mun_code: "097219" },
  // { label: "Jose P. Brillantes, Sr. (Old Lituban)", mun_code: "097219" },
  // { label: "Latabon", mun_code: "097219" },
  // { label: "Mateo Francisco", mun_code: "097219" },
  // { label: "Malipot", mun_code: "097219" },
  // { label: "New Lituban", mun_code: "097219" },
  // { label: "Pisawak", mun_code: "097219" },
  // { label: "Poblacion", mun_code: "097219" },
  // { label: "Santa Maria", mun_code: "097219" },
  // { label: "Suhaile Arabi", mun_code: "097219" },
  // { label: "Tibangao", mun_code: "097219" },
  // { label: "S. Cabral", mun_code: "097219" },
  // { label: "Datu Sailila", mun_code: "097219" },
  // { label: "Makiang", mun_code: "097219" },
  // { label: "Malambuhangin", mun_code: "097219" },
  // { label: "Manaol", mun_code: "097219" },
  // { label: "Matiag", mun_code: "097219" },
  // { label: "Pangian", mun_code: "097219" },
  // { label: "Siay", mun_code: "097219" },
  // { label: "Tabayo", mun_code: "097219" },
  // { label: "Tagaytay", mun_code: "097219" },
  // { label: "Balatakan", mun_code: "097220" },
  // { label: "Balonkan", mun_code: "097220" },
  // { label: "Balubuan", mun_code: "097220" },
  // { label: "Bitugan", mun_code: "097220" },
  // { label: "Bongon", mun_code: "097220" },
  // { label: "Catuyan", mun_code: "097220" },
  // { label: "Culasian", mun_code: "097220" },
  // { label: "Danganon", mun_code: "097220" },
  // { label: "Guban", mun_code: "097220" },
  // { label: "Lagundi", mun_code: "097220" },
  // { label: "Libucon", mun_code: "097220" },
  // { label: "Lubok", mun_code: "097220" },
  // { label: "Macuyon", mun_code: "097220" },
  // { label: "Minanga", mun_code: "097220" },
  // { label: "Motong", mun_code: "097220" },
  // { label: "Napulan", mun_code: "097220" },
  // { label: "Panabutan", mun_code: "097220" },
  // { label: "Piacan", mun_code: "097220" },
  // { label: "Pisa Puti", mun_code: "097220" },
  // { label: "Pisa Itom", mun_code: "097220" },
  // { label: "Saint Mary (Pob.)", mun_code: "097220" },
  // { label: "San Nicolas (Pob.)", mun_code: "097220" },
  // { label: "San Vicente (Pob.)", mun_code: "097220" },
  // { label: "Pugos", mun_code: "097220" },
  // { label: "Pula Bato", mun_code: "097220" },
  // { label: "Pulang Lupa", mun_code: "097220" },
  // { label: "San Roque (Pob.)", mun_code: "097220" },
  // { label: "Sipakit", mun_code: "097220" },
  // { label: "Sipawa", mun_code: "097220" },
  // { label: "Sirawai Proper (Pob.)", mun_code: "097220" },
  // { label: "Talabiga", mun_code: "097220" },
  // { label: "Tapanayan", mun_code: "097220" },
  // { label: "Cabong", mun_code: "097221" },
  // { label: "Galingon", mun_code: "097221" },
  // { label: "Lawaan", mun_code: "097221" },
  // { label: "Molos", mun_code: "097221" },
  // { label: "New Dapitan", mun_code: "097221" },
  // { label: "Situbo", mun_code: "097221" },
  // { label: "Poblacion (Tampilisan)", mun_code: "097221" },
  // { label: "Balacbaan", mun_code: "097221" },
  // { label: "Banbanan", mun_code: "097221" },
  // { label: "Barili", mun_code: "097221" },
  // { label: "Camul", mun_code: "097221" },
  // { label: "Farmington", mun_code: "097221" },
  // { label: "Lumbayao", mun_code: "097221" },
  // { label: "Malila-t", mun_code: "097221" },
  // { label: "Sandayong", mun_code: "097221" },
  // { label: "Tilubog", mun_code: "097221" },
  // { label: "Tininggaan", mun_code: "097221" },
  // { label: "Tubod", mun_code: "097221" },
  // { label: "Znac", mun_code: "097221" },
  // { label: "Dinasan", mun_code: "097222" },
  // { label: "Madalag", mun_code: "097222" },
  // { label: "Manawan", mun_code: "097222" },
  // { label: "Poblacion (Ponot)", mun_code: "097222" },
  // { label: "Siparok", mun_code: "097222" },
  // { label: "Tabon", mun_code: "097222" },
  // { label: "Tamil", mun_code: "097222" },
  // { label: "Balatakan", mun_code: "097222" },
  // { label: "Bitoon", mun_code: "097222" },
  // { label: "Ilihan", mun_code: "097222" },
  // { label: "Labakid", mun_code: "097222" },
  // { label: "Lipay", mun_code: "097222" },
  // { label: "Litalip", mun_code: "097222" },
  // { label: "Lopero", mun_code: "097222" },
  // { label: "Lumanping", mun_code: "097222" },
  // { label: "Marupay", mun_code: "097222" },
  // { label: "Sigamok", mun_code: "097222" },
  // { label: "Tamarok", mun_code: "097222" },
  // { label: "Banganon", mun_code: "097223" },
  // { label: "Cocob", mun_code: "097223" },
  // { label: "Poblacion (Gutalac)", mun_code: "097223" },
  // { label: "La Libertad", mun_code: "097223" },
  // { label: "Lux", mun_code: "097223" },
  // { label: "Panganuran", mun_code: "097223" },
  // { label: "Pitawe", mun_code: "097223" },
  // { label: "Canupong", mun_code: "097223" },
  // { label: "Mamawan", mun_code: "097223" },
  // { label: "Sibalic", mun_code: "097223" },
  // { label: "Tipan", mun_code: "097223" },
  // { label: "Bacong", mun_code: "097223" },
  // { label: "Bagong Silang", mun_code: "097223" },
  // { label: "Bayanihan", mun_code: "097223" },
  // { label: "Buenavista", mun_code: "097223" },
  // { label: "Datagan", mun_code: "097223" },
  // { label: "Imelda", mun_code: "097223" },
  // { label: "Loay", mun_code: "097223" },
  // { label: "Malian", mun_code: "097223" },
  // { label: "Map", mun_code: "097223" },
  // { label: "Matunoy", mun_code: "097223" },
  // { label: "New Dapitan", mun_code: "097223" },
  // { label: "Pitogo", mun_code: "097223" },
  // { label: "Salvador", mun_code: "097223" },
  // { label: "San Isidro", mun_code: "097223" },
  // { label: "San Juan", mun_code: "097223" },
  // { label: "San Roque", mun_code: "097223" },
  // { label: "San Vicente", mun_code: "097223" },
  // { label: "Sas", mun_code: "097223" },
  // { label: "Upper Gutalac", mun_code: "097223" },
  // { label: "Immaculada Concepcion", mun_code: "097223" },
  // { label: "Lower Lux", mun_code: "097223" },
  // { label: "Alegria", mun_code: "097224" },
  // { label: "Diangas", mun_code: "097224" },
  // { label: "Diculom", mun_code: "097224" },
  // { label: "Guimotan", mun_code: "097224" },
  // { label: "Kauswagan", mun_code: "097224" },
  // { label: "Kilalaban", mun_code: "097224" },
  // { label: "Linay", mun_code: "097224" },
  // { label: "Lumay", mun_code: "097224" },
  // { label: "Malinao", mun_code: "097224" },
  // { label: "Mamad", mun_code: "097224" },
  // { label: "Mamawan", mun_code: "097224" },
  // { label: "Milidan", mun_code: "097224" },
  // { label: "Nonoyan", mun_code: "097224" },
  // { label: "Poblacion", mun_code: "097224" },
  // { label: "San Jose", mun_code: "097224" },
  // { label: "Tamao", mun_code: "097224" },
  // { label: "Tan-awan", mun_code: "097224" },
  // { label: "Baluno", mun_code: "097225" },
  // { label: "Banuangan", mun_code: "097225" },
  // { label: "Bunawan", mun_code: "097225" },
  // { label: "Dilucot", mun_code: "097225" },
  // { label: "Dipopor", mun_code: "097225" },
  // { label: "Guisapong", mun_code: "097225" },
  // { label: "Limbonga (Limboangan)", mun_code: "097225" },
  // { label: "Lomogom", mun_code: "097225" },
  // { label: "Mauswagon", mun_code: "097225" },
  // { label: "Miampic", mun_code: "097225" },
  // { label: "Poblacion", mun_code: "097225" },
  // { label: "Raba", mun_code: "097225" },
  // { label: "Rambon", mun_code: "097225" },
  // { label: "San Pedro", mun_code: "097225" },
  // { label: "Sarawagan", mun_code: "097225" },
  // { label: "Sianan", mun_code: "097225" },
  // { label: "Sioran", mun_code: "097225" },
  // { label: "Bacungan (Pob.)", mun_code: "097226" },
  // { label: "Bogabongan", mun_code: "097226" },
  // { label: "Delusom", mun_code: "097226" },
  // { label: "Mangop", mun_code: "097226" },
  // { label: "Manil", mun_code: "097226" },
  // { label: "Mawal", mun_code: "097226" },
  // { label: "Midatag", mun_code: "097226" },
  // { label: "Nasibac", mun_code: "097226" },
  // { label: "Rizon", mun_code: "097226" },
  // { label: "Sipacong", mun_code: "097226" },
  // { label: "Santa Maria", mun_code: "097226" },
  // { label: "Talinga", mun_code: "097226" },
  // { label: "Tinaplan", mun_code: "097226" },
  // { label: "Tiniguiban", mun_code: "097226" },
  // { label: "Tinuyop", mun_code: "097226" },
  // { label: "Tiogan", mun_code: "097226" },
  // { label: "Titik", mun_code: "097226" },
  // { label: "Morob", mun_code: "097226" },
  // { label: "Batayan", mun_code: "097227" },
  // { label: "Botong", mun_code: "097227" },
  // { label: "Concepcion", mun_code: "097227" },
  // { label: "Daniel Maing (Dominolog)", mun_code: "097227" },
  // { label: "Fatima (Lacsutan)", mun_code: "097227" },
  // { label: "Gatas", mun_code: "097227" },
  // { label: "Kalawit (Pob.)", mun_code: "097227" },
  // { label: "Marcelo", mun_code: "097227" },
  // { label: "New Calamba", mun_code: "097227" },
  // { label: "Palalian", mun_code: "097227" },
  // { label: "Paraiso", mun_code: "097227" },
  // { label: "Pianon", mun_code: "097227" },
  // { label: "San Jose", mun_code: "097227" },
  // { label: "Tugop*", mun_code: "097227" },
  // { label: "Acad", mun_code: "097302" },
  // { label: "Alang-alang", mun_code: "097302" },
  // { label: "Alegria", mun_code: "097302" },
  // { label: "Anonang", mun_code: "097302" },
  // { label: "Bagong Mandaue", mun_code: "097302" },
  // { label: "Bagong Maslog", mun_code: "097302" },
  // { label: "Bagong Oslob", mun_code: "097302" },
  // { label: "Bagong Pitogo", mun_code: "097302" },
  // { label: "Baki", mun_code: "097302" },
  // { label: "Balas", mun_code: "097302" },
  // { label: "Balide", mun_code: "097302" },
  // { label: "Balintawak", mun_code: "097302" },
  // { label: "Bayabas", mun_code: "097302" },
  // { label: "Bemposa", mun_code: "097302" },
  // { label: "Cabilinan", mun_code: "097302" },
  // { label: "Campo Uno", mun_code: "097302" },
  // { label: "Ceboneg", mun_code: "097302" },
  // { label: "Commonwealth", mun_code: "097302" },
  // { label: "Gubaan", mun_code: "097302" },
  // { label: "Inasagan", mun_code: "097302" },
  // { label: "Inroad", mun_code: "097302" },
  // { label: "Kahayagan East (Katipunan)", mun_code: "097302" },
  // { label: "Kahayagan West", mun_code: "097302" },
  // { label: "Kauswagan", mun_code: "097302" },
  // { label: "La Victoria", mun_code: "097302" },
  // { label: "Lantungan", mun_code: "097302" },
  // { label: "Libertad", mun_code: "097302" },
  // { label: "Lintugop", mun_code: "097302" },
  // { label: "Lubid", mun_code: "097302" },
  // { label: "Maguikay", mun_code: "097302" },
  // { label: "Mahayahay", mun_code: "097302" },
  // { label: "Monte Alegre", mun_code: "097302" },
  // { label: "Montela", mun_code: "097302" },
  // { label: "Napo", mun_code: "097302" },
  // { label: "Panaghiusa", mun_code: "097302" },
  // { label: "Poblacion", mun_code: "097302" },
  // { label: "Resthouse", mun_code: "097302" },
  // { label: "Romarate", mun_code: "097302" },
  // { label: "San Jose", mun_code: "097302" },
  // { label: "San Juan", mun_code: "097302" },
  // { label: "Sapa Loboc", mun_code: "097302" },
  // { label: "Tagulalo", mun_code: "097302" },
  // { label: "La Paz (Tinibtiban)", mun_code: "097302" },
  // { label: "Waterfall", mun_code: "097302" },
  // { label: "Baking", mun_code: "097303" },
  // { label: "Balukbahan", mun_code: "097303" },
  // { label: "Balumbunan", mun_code: "097303" },
  // { label: "Bantal", mun_code: "097303" },
  // { label: "Bobuan", mun_code: "097303" },
  // { label: "Camp Blessing", mun_code: "097303" },
  // { label: "Canoayan", mun_code: "097303" },
  // { label: "Conacon", mun_code: "097303" },
  // { label: "Dagum", mun_code: "097303" },
  // { label: "Damit", mun_code: "097303" },
  // { label: "Datagan", mun_code: "097303" },
  // { label: "Depase", mun_code: "097303" },
  // { label: "Deporehan", mun_code: "097303" },
  // { label: "Depore", mun_code: "097303" },
  // { label: "Dimalinao", mun_code: "097303" },
  // { label: "Depili", mun_code: "097303" },
  // { label: "Kahayagan", mun_code: "097303" },
  // { label: "Kanipaan", mun_code: "097303" },
  // { label: "Lamare", mun_code: "097303" },
  // { label: "Liba", mun_code: "097303" },
  // { label: "Matin-ao", mun_code: "097303" },
  // { label: "Matun-og", mun_code: "097303" },
  // { label: "Poblacion", mun_code: "097303" },
  // { label: "Pulang Bato", mun_code: "097303" },
  // { label: "Salawagan", mun_code: "097303" },
  // { label: "Sigacad", mun_code: "097303" },
  // { label: "Supon", mun_code: "097303" },
  // { label: "Pangi (San Isidro)", mun_code: "097303" },
  // { label: "Bacayawan", mun_code: "097305" },
  // { label: "Baha", mun_code: "097305" },
  // { label: "Baluno", mun_code: "097305" },
  // { label: "Binuay", mun_code: "097305" },
  // { label: "Buburay", mun_code: "097305" },
  // { label: "Grap", mun_code: "097305" },
  // { label: "Kagawasan", mun_code: "097305" },
  // { label: "Lalab", mun_code: "097305" },
  // { label: "Libertad", mun_code: "097305" },
  // { label: "Mahayag", mun_code: "097305" },
  // { label: "Poblacion", mun_code: "097305" },
  // { label: "Saloagan", mun_code: "097305" },
  // { label: "Sugbay Uno", mun_code: "097305" },
  // { label: "Sumpot", mun_code: "097305" },
  // { label: "Tiniguangan", mun_code: "097305" },
  // { label: "Tinggabulong", mun_code: "097305" },
  // { label: "Tipangi", mun_code: "097305" },
  // { label: "Balanagan", mun_code: "097305" },
  // { label: "Josefina", mun_code: "097305" },
  // { label: "Magahis", mun_code: "097305" },
  // { label: "Mercedes", mun_code: "097305" },
  // { label: "San Roque", mun_code: "097305" },
  // { label: "Sumbato", mun_code: "097305" },
  // { label: "Upper Ludiong", mun_code: "097305" },
  // { label: "Bacawan", mun_code: "097306" },
  // { label: "Benuatan", mun_code: "097306" },
  // { label: "Beray", mun_code: "097306" },
  // { label: "Dongos", mun_code: "097306" },
  // { label: "Guinicolalay", mun_code: "097306" },
  // { label: "Kinacap", mun_code: "097306" },
  // { label: "Legarda 2", mun_code: "097306" },
  // { label: "Legarda 3", mun_code: "097306" },
  // { label: "Legarda 1", mun_code: "097306" },
  // { label: "Lucoban", mun_code: "097306" },
  // { label: "Lower Dimaya", mun_code: "097306" },
  // { label: "Ludiong", mun_code: "097306" },
  // { label: "East Migpulao", mun_code: "097306" },
  // { label: "West Migpulao", mun_code: "097306" },
  // { label: "Nangka", mun_code: "097306" },
  // { label: "Ignacio Garrata (New Mirapao)", mun_code: "097306" },
  // { label: "Old Mirapao", mun_code: "097306" },
  // { label: "Poblacion", mun_code: "097306" },
  // { label: "Proper Dimaya", mun_code: "097306" },
  // { label: "Sagacad", mun_code: "097306" },
  // { label: "Sambulawan", mun_code: "097306" },
  // { label: "San Isidro", mun_code: "097306" },
  // { label: "Sumpotan", mun_code: "097306" },
  // { label: "Songayan", mun_code: "097306" },
  // { label: "Tarakan", mun_code: "097306" },
  // { label: "Upper Dimaya", mun_code: "097306" },
  // { label: "Upper Sibul", mun_code: "097306" },
  // { label: "Pisa-an", mun_code: "097306" },
  // { label: "Don Jose", mun_code: "097306" },
  // { label: "Nian", mun_code: "097306" },
  // { label: "Bag-ong Misamis", mun_code: "097307" },
  // { label: "Baga", mun_code: "097307" },
  // { label: "Bag-ong Silao", mun_code: "097307" },
  // { label: "Baloboan", mun_code: "097307" },
  // { label: "Banta-ao", mun_code: "097307" },
  // { label: "Bibilik", mun_code: "097307" },
  // { label: "Calingayan", mun_code: "097307" },
  // { label: "Camalig", mun_code: "097307" },
  // { label: "Camanga", mun_code: "097307" },
  // { label: "Cuatro-cuatro", mun_code: "097307" },
  // { label: "Locuban", mun_code: "097307" },
  // { label: "Malasik", mun_code: "097307" },
  // { label: "Mama (San Juan)", mun_code: "097307" },
  // { label: "Matab-ang", mun_code: "097307" },
  // { label: "Mecolong", mun_code: "097307" },
  // { label: "Metokong", mun_code: "097307" },
  // { label: "Motosawa", mun_code: "097307" },
  // { label: "Pag-asa (Pob.)", mun_code: "097307" },
  // { label: "Paglaum (Pob.)", mun_code: "097307" },
  // { label: "Pantad", mun_code: "097307" },
  // { label: "Piniglibano", mun_code: "097307" },
  // { label: "Rebokon", mun_code: "097307" },
  // { label: "San Agustin", mun_code: "097307" },
  // { label: "Sibucao", mun_code: "097307" },
  // { label: "Sumadat", mun_code: "097307" },
  // { label: "Tikwas", mun_code: "097307" },
  // { label: "Tina", mun_code: "097307" },
  // { label: "Tubo-Pait", mun_code: "097307" },
  // { label: "Upper Dumalinao", mun_code: "097307" },
  // { label: "Anonang", mun_code: "097307" },
  // { label: "Bag-ong Valencia", mun_code: "097308" },
  // { label: "Bucayan", mun_code: "097308" },
  // { label: "Calumanggi", mun_code: "097308" },
  // { label: "Caridad", mun_code: "097308" },
  // { label: "Danlugan", mun_code: "097308" },
  // { label: "Datu Totocan", mun_code: "097308" },
  // { label: "Dilud", mun_code: "097308" },
  // { label: "Ditulan", mun_code: "097308" },
  // { label: "Dulian", mun_code: "097308" },
  // { label: "Dulop", mun_code: "097308" },
  // { label: "Guintananan", mun_code: "097308" },
  // { label: "Guitran", mun_code: "097308" },
  // { label: "Gumpingan", mun_code: "097308" },
  // { label: "La Fortuna", mun_code: "097308" },
  // { label: "Libertad", mun_code: "097308" },
  // { label: "Licabang", mun_code: "097308" },
  // { label: "Lipawan", mun_code: "097308" },
  // { label: "Lower Landing", mun_code: "097308" },
  // { label: "Lower Timonan", mun_code: "097308" },
  // { label: "Macasing", mun_code: "097308" },
  // { label: "Mahayahay", mun_code: "097308" },
  // { label: "Malagalad", mun_code: "097308" },
  // { label: "Manlabay", mun_code: "097308" },
  // { label: "Maralag", mun_code: "097308" },
  // { label: "Marangan", mun_code: "097308" },
  // { label: "New Basak", mun_code: "097308" },
  // { label: "Bagong Kauswagan", mun_code: "097308" },
  // { label: "Saad", mun_code: "097308" },
  // { label: "Salvador", mun_code: "097308" },
  // { label: "San Pablo (Pob.)", mun_code: "097308" },
  // { label: "San Pedro (Pob.)", mun_code: "097308" },
  // { label: "San Vicente", mun_code: "097308" },
  // { label: "Senote", mun_code: "097308" },
  // { label: "Sinonok", mun_code: "097308" },
  // { label: "Sunop", mun_code: "097308" },
  // { label: "Tagun", mun_code: "097308" },
  // { label: "Tamurayan", mun_code: "097308" },
  // { label: "Upper Landing", mun_code: "097308" },
  // { label: "Upper Timonan", mun_code: "097308" },
  // { label: "Bagong Silang", mun_code: "097308" },
  // { label: "Dapiwak", mun_code: "097308" },
  // { label: "Labangon", mun_code: "097308" },
  // { label: "San Juan", mun_code: "097308" },
  // { label: "Canibongan", mun_code: "097308" },
  // { label: "Bogayo", mun_code: "097311" },
  // { label: "Bolisong", mun_code: "097311" },
  // { label: "Bualan", mun_code: "097311" },
  // { label: "Boyugan East", mun_code: "097311" },
  // { label: "Boyugan West", mun_code: "097311" },
  // { label: "Diplo", mun_code: "097311" },
  // { label: "Gawil", mun_code: "097311" },
  // { label: "Gusom", mun_code: "097311" },
  // { label: "Kitaan Dagat", mun_code: "097311" },
  // { label: "Limamawan", mun_code: "097311" },
  // { label: "Mahayahay", mun_code: "097311" },
  // { label: "Pangi", mun_code: "097311" },
  // { label: "Poblacion", mun_code: "097311" },
  // { label: "Picanan", mun_code: "097311" },
  // { label: "Salagmanok", mun_code: "097311" },
  // { label: "Secade", mun_code: "097311" },
  // { label: "Suminalum", mun_code: "097311" },
  // { label: "Lantawan", mun_code: "097311" },
  // { label: "Bagalupa", mun_code: "097312" },
  // { label: "Balimbingan (West Luya)", mun_code: "097312" },
  // { label: "Binayan", mun_code: "097312" },
  // { label: "Bokong", mun_code: "097312" },
  // { label: "Bulanit", mun_code: "097312" },
  // { label: "Cogonan", mun_code: "097312" },
  // { label: "Dalapang", mun_code: "097312" },
  // { label: "Dipaya", mun_code: "097312" },
  // { label: "Langapod", mun_code: "097312" },
  // { label: "Lantian", mun_code: "097312" },
  // { label: "Lower Campo Islam (Pob.)", mun_code: "097312" },
  // { label: "Lower Pulacan", mun_code: "097312" },
  // { label: "New Labangan", mun_code: "097312" },
  // { label: "Noboran", mun_code: "097312" },
  // { label: "Old Labangan", mun_code: "097312" },
  // { label: "San Isidro", mun_code: "097312" },
  // { label: "Santa Cruz", mun_code: "097312" },
  // { label: "Tapodoc", mun_code: "097312" },
  // { label: "Tawagan Norte", mun_code: "097312" },
  // { label: "Upper Campo Islam (Pob.)", mun_code: "097312" },
  // { label: "Upper Pulacan", mun_code: "097312" },
  // { label: "Combo", mun_code: "097312" },
  // { label: "Dimasangca", mun_code: "097312" },
  // { label: "Lower Sang-an", mun_code: "097312" },
  // { label: "Upper Sang-an", mun_code: "097312" },
  // { label: "Bulawan", mun_code: "097313" },
  // { label: "Carpoc", mun_code: "097313" },
  // { label: "Danganan", mun_code: "097313" },
  // { label: "Dansal", mun_code: "097313" },
  // { label: "Dumara", mun_code: "097313" },
  // { label: "Linokmadalum", mun_code: "097313" },
  // { label: "Luanan", mun_code: "097313" },
  // { label: "Lubusan", mun_code: "097313" },
  // { label: "Mahalingeb", mun_code: "097313" },
  // { label: "Mandeg", mun_code: "097313" },
  // { label: "Maralag", mun_code: "097313" },
  // { label: "Maruing", mun_code: "097313" },
  // { label: "Molum", mun_code: "097313" },
  // { label: "Pampang", mun_code: "097313" },
  // { label: "Pantad", mun_code: "097313" },
  // { label: "Pingalay", mun_code: "097313" },
  // { label: "Salambuyan", mun_code: "097313" },
  // { label: "San Jose", mun_code: "097313" },
  // { label: "Sayog", mun_code: "097313" },
  // { label: "Tabon", mun_code: "097313" },
  // { label: "Talabab", mun_code: "097313" },
  // { label: "Tiguha", mun_code: "097313" },
  // { label: "Tininghalang", mun_code: "097313" },
  // { label: "Tipasan", mun_code: "097313" },
  // { label: "Tugaya", mun_code: "097313" },
  // { label: "Poblacion", mun_code: "097313" },
  // { label: "Bag-ong Balamban", mun_code: "097315" },
  // { label: "Bag-ong Dalaguete", mun_code: "097315" },
  // { label: "Marabanan (Balanan)", mun_code: "097315" },
  // { label: "Boniao", mun_code: "097315" },
  // { label: "Delusom", mun_code: "097315" },
  // { label: "Diwan", mun_code: "097315" },
  // { label: "Guripan", mun_code: "097315" },
  // { label: "Kaangayan", mun_code: "097315" },
  // { label: "Kabuhi", mun_code: "097315" },
  // { label: "Lourmah (Lower Mahayag)", mun_code: "097315" },
  // { label: "Lower Salug Daku", mun_code: "097315" },
  // { label: "Sicpao", mun_code: "097315" },
  // { label: "Malubo", mun_code: "097315" },
  // { label: "Manguiles", mun_code: "097315" },
  // { label: "Panagaan", mun_code: "097315" },
  // { label: "Paraiso", mun_code: "097315" },
  // { label: "Pedagan", mun_code: "097315" },
  // { label: "Poblacion (Upper Mahayag)", mun_code: "097315" },
  // { label: "Pugwan", mun_code: "097315" },
  // { label: "San Isidro", mun_code: "097315" },
  // { label: "San Jose", mun_code: "097315" },
  // { label: "San Vicente", mun_code: "097315" },
  // { label: "Santa Cruz", mun_code: "097315" },
  // { label: "Tuboran", mun_code: "097315" },
  // { label: "Tumapic", mun_code: "097315" },
  // { label: "Upper Salug Daku", mun_code: "097315" },
  // { label: "Tulan", mun_code: "097315" },
  // { label: "Balintawak", mun_code: "097317" },
  // { label: "Bularong", mun_code: "097317" },
  // { label: "Kalian", mun_code: "097317" },
  // { label: "Kolot", mun_code: "097317" },
  // { label: "Digon", mun_code: "097317" },
  // { label: "Guinimanan", mun_code: "097317" },
  // { label: "Igat Island", mun_code: "097317" },
  // { label: "Limamawan", mun_code: "097317" },
  // { label: "Limabatong", mun_code: "097317" },
  // { label: "Lumbog", mun_code: "097317" },
  // { label: "Poblacion", mun_code: "097317" },
  // { label: "Sagua", mun_code: "097317" },
  // { label: "Talanusa", mun_code: "097317" },
  // { label: "Tiguian", mun_code: "097317" },
  // { label: "Tulapoc", mun_code: "097317" },
  // { label: "Magahis", mun_code: "097317" },
  // { label: "Josefina", mun_code: "097317" },
  // { label: "Bacahan", mun_code: "097318" },
  // { label: "Bibilop", mun_code: "097318" },
  // { label: "Buloron", mun_code: "097318" },
  // { label: "Canipay Norte", mun_code: "097318" },
  // { label: "Canipay Sur", mun_code: "097318" },
  // { label: "Cumaron", mun_code: "097318" },
  // { label: "Golictop", mun_code: "097318" },
  // { label: "Kahayagan", mun_code: "097318" },
  // { label: "Lumpunid", mun_code: "097318" },
  // { label: "Matalang", mun_code: "097318" },
  // { label: "New Katipunan", mun_code: "097318" },
  // { label: "New Unidos", mun_code: "097318" },
  // { label: "Palili", mun_code: "097318" },
  // { label: "Piwan", mun_code: "097318" },
  // { label: "Poblacion A", mun_code: "097318" },
  // { label: "Poblacion B", mun_code: "097318" },
  // { label: "Sigapod", mun_code: "097318" },
  // { label: "Timbaboy", mun_code: "097318" },
  // { label: "Tulbong", mun_code: "097318" },
  // { label: "Tuluan", mun_code: "097318" },
  // { label: "Pisompongan", mun_code: "097318" },
  // { label: "Cabaloran", mun_code: "097318" },
  // { label: "Dakayakan", mun_code: "097318" },
  // { label: "Duelic", mun_code: "097318" },
  // { label: "Dumalinao", mun_code: "097318" },
  // { label: "Ecuan", mun_code: "097318" },
  // { label: "Guinabot", mun_code: "097318" },
  // { label: "Guitalos", mun_code: "097318" },
  // { label: "Licuro-an", mun_code: "097318" },
  // { label: "Pawan", mun_code: "097318" },
  // { label: "Balonai", mun_code: "097318" },
  // { label: "Guma", mun_code: "097318" },
  // { label: "Pili", mun_code: "097318" },
  // { label: "Alicia", mun_code: "097319" },
  // { label: "Ariosa", mun_code: "097319" },
  // { label: "Bagong Argao", mun_code: "097319" },
  // { label: "Bagong Gutlang", mun_code: "097319" },
  // { label: "Blancia", mun_code: "097319" },
  // { label: "Bogo Capalaran", mun_code: "097319" },
  // { label: "Dalaon", mun_code: "097319" },
  // { label: "Dipolo", mun_code: "097319" },
  // { label: "Dontulan", mun_code: "097319" },
  // { label: "Gonosan", mun_code: "097319" },
  // { label: "Culo", mun_code: "097319" },
  // { label: "Lower Dimorok", mun_code: "097319" },
  // { label: "Lower Dimalinao", mun_code: "097319" },
  // { label: "Mabuhay", mun_code: "097319" },
  // { label: "Madasigon (Pob.)", mun_code: "097319" },
  // { label: "Makuguihon (Pob.)", mun_code: "097319" },
  // { label: "Maloloy-on (Pob.)", mun_code: "097319" },
  // { label: "Miligan", mun_code: "097319" },
  // { label: "Parasan", mun_code: "097319" },
  // { label: "Rizal", mun_code: "097319" },
  // { label: "Santo Rosario", mun_code: "097319" },
  // { label: "Silangit", mun_code: "097319" },
  // { label: "Simata", mun_code: "097319" },
  // { label: "Sudlon", mun_code: "097319" },
  // { label: "Upper Dimorok", mun_code: "097319" },
  // { label: "Alegria", mun_code: "097322" },
  // { label: "Balangasan (Pob.)", mun_code: "097322" },
  // { label: "Balintawak", mun_code: "097322" },
  // { label: "Baloyboan", mun_code: "097322" },
  // { label: "Banale", mun_code: "097322" },
  // { label: "Bogo", mun_code: "097322" },
  // { label: "Bomba", mun_code: "097322" },
  // { label: "Buenavista", mun_code: "097322" },
  // { label: "Bulatok", mun_code: "097322" },
  // { label: "Bulawan", mun_code: "097322" },
  // { label: "Danlugan", mun_code: "097322" },
  // { label: "Dao", mun_code: "097322" },
  // { label: "Datagan", mun_code: "097322" },
  // { label: "Deborok", mun_code: "097322" },
  // { label: "Ditoray", mun_code: "097322" },
  // { label: "Gatas (Pob.)", mun_code: "097322" },
  // { label: "Gubac", mun_code: "097322" },
  // { label: "Gubang", mun_code: "097322" },
  // { label: "Kagawasan", mun_code: "097322" },
  // { label: "Kahayagan", mun_code: "097322" },
  // { label: "Kalasan", mun_code: "097322" },
  // { label: "La Suerte", mun_code: "097322" },
  // { label: "Lala", mun_code: "097322" },
  // { label: "Lapidian", mun_code: "097322" },
  // { label: "Lenienza", mun_code: "097322" },
  // { label: "Lizon Valley", mun_code: "097322" },
  // { label: "Lourdes", mun_code: "097322" },
  // { label: "Lower Sibatang", mun_code: "097322" },
  // { label: "Lumad", mun_code: "097322" },
  // { label: "Macasing", mun_code: "097322" },
  // { label: "Manga", mun_code: "097322" },
  // { label: "Muricay", mun_code: "097322" },
  // { label: "Napolan", mun_code: "097322" },
  // { label: "Palpalan", mun_code: "097322" },
  // { label: "Pedulonan", mun_code: "097322" },
  // { label: "Poloyagan", mun_code: "097322" },
  // { label: "San Francisco (Pob.)", mun_code: "097322" },
  // { label: "San Jose (Pob.)", mun_code: "097322" },
  // { label: "San Pedro (Pob.)", mun_code: "097322" },
  // { label: "Santa Lucia (Pob.)", mun_code: "097322" },
  // { label: "Santiago (Pob.)", mun_code: "097322" },
  // { label: "Tawagan Sur", mun_code: "097322" },
  // { label: "Tiguma", mun_code: "097322" },
  // { label: "Tuburan (Pob.)", mun_code: "097322" },
  // { label: "Tulawas", mun_code: "097322" },
  // { label: "Tulangan", mun_code: "097322" },
  // { label: "Upper Sibatang", mun_code: "097322" },
  // { label: "White Beach", mun_code: "097322" },
  // { label: "Kawit", mun_code: "097322" },
  // { label: "Lumbia", mun_code: "097322" },
  // { label: "Santa Maria", mun_code: "097322" },
  // { label: "Dampalan", mun_code: "097322" },
  // { label: "Dumagoc", mun_code: "097322" },
  // { label: "Bagong Opon", mun_code: "097323" },
  // { label: "Bambong Daku", mun_code: "097323" },
  // { label: "Bambong Diut", mun_code: "097323" },
  // { label: "Bobongan", mun_code: "097323" },
  // { label: "Campo IV", mun_code: "097323" },
  // { label: "Campo V", mun_code: "097323" },
  // { label: "Caniangan", mun_code: "097323" },
  // { label: "Dipalusan", mun_code: "097323" },
  // { label: "Eastern Bobongan", mun_code: "097323" },
  // { label: "Esperanza", mun_code: "097323" },
  // { label: "Gapasan", mun_code: "097323" },
  // { label: "Katipunan", mun_code: "097323" },
  // { label: "Kauswagan", mun_code: "097323" },
  // { label: "Lower Sambulawan", mun_code: "097323" },
  // { label: "Mabini", mun_code: "097323" },
  // { label: "Magsaysay", mun_code: "097323" },
  // { label: "Malating", mun_code: "097323" },
  // { label: "Paradise", mun_code: "097323" },
  // { label: "Pasingkalan", mun_code: "097323" },
  // { label: "Poblacion", mun_code: "097323" },
  // { label: "San Fernando", mun_code: "097323" },
  // { label: "Santo Rosario", mun_code: "097323" },
  // { label: "Sapa Anding", mun_code: "097323" },
  // { label: "Sinaguing", mun_code: "097323" },
  // { label: "Switch", mun_code: "097323" },
  // { label: "Upper Laperian", mun_code: "097323" },
  // { label: "Wakat", mun_code: "097323" },
  // { label: "Betinan", mun_code: "097324" },
  // { label: "Bulawan", mun_code: "097324" },
  // { label: "Calube", mun_code: "097324" },
  // { label: "Concepcion", mun_code: "097324" },
  // { label: "Dao-an", mun_code: "097324" },
  // { label: "Dumalian", mun_code: "097324" },
  // { label: "Fatima", mun_code: "097324" },
  // { label: "Langilan", mun_code: "097324" },
  // { label: "Lantawan", mun_code: "097324" },
  // { label: "Laperian", mun_code: "097324" },
  // { label: "Libuganan", mun_code: "097324" },
  // { label: "Limonan", mun_code: "097324" },
  // { label: "Mati", mun_code: "097324" },
  // { label: "Ocapan", mun_code: "097324" },
  // { label: "Poblacion", mun_code: "097324" },
  // { label: "San Isidro", mun_code: "097324" },
  // { label: "Sayog", mun_code: "097324" },
  // { label: "Tapian", mun_code: "097324" },
  // { label: "Bag-ong Misamis", mun_code: "097325" },
  // { label: "Bubual", mun_code: "097325" },
  // { label: "Buton", mun_code: "097325" },
  // { label: "Culasian", mun_code: "097325" },
  // { label: "Daplayan", mun_code: "097325" },
  // { label: "Kalilangan", mun_code: "097325" },
  // { label: "Kapamanok", mun_code: "097325" },
  // { label: "Kondum", mun_code: "097325" },
  // { label: "Lumbayao", mun_code: "097325" },
  // { label: "Mabuhay", mun_code: "097325" },
  // { label: "Marcos Village", mun_code: "097325" },
  // { label: "Miasin", mun_code: "097325" },
  // { label: "Molansong", mun_code: "097325" },
  // { label: "Pantad", mun_code: "097325" },
  // { label: "Pao", mun_code: "097325" },
  // { label: "Payag", mun_code: "097325" },
  // { label: "Poblacion (San Pablo)", mun_code: "097325" },
  // { label: "Pongapong", mun_code: "097325" },
  // { label: "Sagasan", mun_code: "097325" },
  // { label: "Sacbulan", mun_code: "097325" },
  // { label: "Senior", mun_code: "097325" },
  // { label: "Songgoy", mun_code: "097325" },
  // { label: "Tandubuay", mun_code: "097325" },
  // { label: "Ticala Island", mun_code: "097325" },
  // { label: "Taniapan", mun_code: "097325" },
  // { label: "Tubo-pait", mun_code: "097325" },
  // { label: "Villakapa", mun_code: "097325" },
  // { label: "San Juan", mun_code: "097325" },
  // { label: "Abong-abong", mun_code: "097327" },
  // { label: "Baganian", mun_code: "097327" },
  // { label: "Baya-baya", mun_code: "097327" },
  // { label: "Capisan", mun_code: "097327" },
  // { label: "Concepcion", mun_code: "097327" },
  // { label: "Culabay", mun_code: "097327" },
  // { label: "Lumbia", mun_code: "097327" },
  // { label: "Mabuhay", mun_code: "097327" },
  // { label: "Malim", mun_code: "097327" },
  // { label: "Manikaan", mun_code: "097327" },
  // { label: "New Oroquieta", mun_code: "097327" },
  // { label: "Poblacion", mun_code: "097327" },
  // { label: "San Francisco", mun_code: "097327" },
  // { label: "Tultolan", mun_code: "097327" },
  // { label: "Alang-alang", mun_code: "097328" },
  // { label: "Bag-ong Kauswagan", mun_code: "097328" },
  // { label: "Bag-ong Tabogon", mun_code: "097328" },
  // { label: "Balugo", mun_code: "097328" },
  // { label: "Fabian (Balucot)", mun_code: "097328" },
  // { label: "Cabgan", mun_code: "097328" },
  // { label: "Calolot", mun_code: "097328" },
  // { label: "Dimalinao", mun_code: "097328" },
  // { label: "Gabunon", mun_code: "097328" },
  // { label: "Happy Valley (Pob.)", mun_code: "097328" },
  // { label: "Libato", mun_code: "097328" },
  // { label: "Limamawan", mun_code: "097328" },
  // { label: "Lower Liasan", mun_code: "097328" },
  // { label: "Lower Lodiong (Pob.)", mun_code: "097328" },
  // { label: "Lower Tiparak", mun_code: "097328" },
  // { label: "Lower Usogan", mun_code: "097328" },
  // { label: "Maya-maya", mun_code: "097328" },
  // { label: "New Village (Pob.)", mun_code: "097328" },
  // { label: "Pelocoban", mun_code: "097328" },
  // { label: "Riverside (Pob.)", mun_code: "097328" },
  // { label: "Sagrada Familia", mun_code: "097328" },
  // { label: "San Jose", mun_code: "097328" },
  // { label: "Sumalig", mun_code: "097328" },
  // { label: "Tuluan", mun_code: "097328" },
  // { label: "Tungawan", mun_code: "097328" },
  // { label: "Upper Liason", mun_code: "097328" },
  // { label: "Upper Lodiong", mun_code: "097328" },
  // { label: "Upper Tiparak", mun_code: "097328" },
  // { label: "Angeles", mun_code: "097328" },
  // { label: "Kapalaran", mun_code: "097328" },
  // { label: "San Vicente", mun_code: "097328" },
  // { label: "Alindahaw", mun_code: "097330" },
  // { label: "Baclay", mun_code: "097330" },
  // { label: "Balimbingan", mun_code: "097330" },
  // { label: "Buenasuerte", mun_code: "097330" },
  // { label: "Camanga", mun_code: "097330" },
  // { label: "Curvada", mun_code: "097330" },
  // { label: "Laperian", mun_code: "097330" },
  // { label: "Libertad", mun_code: "097330" },
  // { label: "Lower Bayao", mun_code: "097330" },
  // { label: "Luy-a", mun_code: "097330" },
  // { label: "Manilan", mun_code: "097330" },
  // { label: "Manlayag", mun_code: "097330" },
  // { label: "Militar", mun_code: "097330" },
  // { label: "Navalan", mun_code: "097330" },
  // { label: "Panduma Senior", mun_code: "097330" },
  // { label: "Sambulawan", mun_code: "097330" },
  // { label: "San Antonio", mun_code: "097330" },
  // { label: "San Carlos (Pob.)", mun_code: "097330" },
  // { label: "Santo Rosario", mun_code: "097330" },
  // { label: "Sugod", mun_code: "097330" },
  // { label: "Tabuan", mun_code: "097330" },
  // { label: "Tagulo", mun_code: "097330" },
  // { label: "Tinotungan", mun_code: "097330" },
  // { label: "Upper Bayao", mun_code: "097330" },
  // { label: "Arena Blanco", mun_code: "097332" },
  // { label: "Ayala", mun_code: "097332" },
  // { label: "Baliwasan", mun_code: "097332" },
  // { label: "Baluno", mun_code: "097332" },
  // { label: "Boalan", mun_code: "097332" },
  // { label: "Bolong", mun_code: "097332" },
  // { label: "Buenavista", mun_code: "097332" },
  // { label: "Bunguiao", mun_code: "097332" },
  // { label: "Busay (Sacol Island)", mun_code: "097332" },
  // { label: "Cabaluay", mun_code: "097332" },
  // { label: "Cabatangan", mun_code: "097332" },
  // { label: "Cacao", mun_code: "097332" },
  // { label: "Calabasa", mun_code: "097332" },
  // { label: "Calarian", mun_code: "097332" },
  // { label: "Campo Islam", mun_code: "097332" },
  // { label: "Canelar", mun_code: "097332" },
  // { label: "Cawit", mun_code: "097332" },
  // { label: "Culianan", mun_code: "097332" },
  // { label: "Curuan", mun_code: "097332" },
  // { label: "Dita", mun_code: "097332" },
  // { label: "Divisoria", mun_code: "097332" },
  // { label: "Dulian (Upper Bunguiao)", mun_code: "097332" },
  // { label: "Dulian (Upper Pasonanca)", mun_code: "097332" },
  // { label: "Guisao", mun_code: "097332" },
  // { label: "Guiwan", mun_code: "097332" },
  // { label: "La Paz", mun_code: "097332" },
  // { label: "Labuan", mun_code: "097332" },
  // { label: "Lamisahan", mun_code: "097332" },
  // { label: "Landang Gua", mun_code: "097332" },
  // { label: "Landang Laum", mun_code: "097332" },
  // { label: "Lanzones", mun_code: "097332" },
  // { label: "Lapakan", mun_code: "097332" },
  // { label: "Latuan (Curuan)", mun_code: "097332" },
  // { label: "Limaong", mun_code: "097332" },
  // { label: "Limpapa", mun_code: "097332" },
  // { label: "Lubigan", mun_code: "097332" },
  // { label: "Lumayang", mun_code: "097332" },
  // { label: "Lumbangan", mun_code: "097332" },
  // { label: "Lunzuran", mun_code: "097332" },
  // { label: "Maasin", mun_code: "097332" },
  // { label: "Malagutay", mun_code: "097332" },
  // { label: "Mampang", mun_code: "097332" },
  // { label: "Manalipa", mun_code: "097332" },
  // { label: "Mangusu", mun_code: "097332" },
  // { label: "Manicahan", mun_code: "097332" },
  // { label: "Mariki", mun_code: "097332" },
  // { label: "Mercedes", mun_code: "097332" },
  // { label: "Muti", mun_code: "097332" },
  // { label: "Pamucutan", mun_code: "097332" },
  // { label: "Pangapuyan", mun_code: "097332" },
  // { label: "Panubigan", mun_code: "097332" },
  // { label: "Pasilmanta (Sacol Island)", mun_code: "097332" },
  // { label: "Pasonanca", mun_code: "097332" },
  // { label: "Patalon", mun_code: "097332" },
  // { label: "Barangay Zone I (Pob.)", mun_code: "097332" },
  // { label: "Barangay Zone II (Pob.)", mun_code: "097332" },
  // { label: "Barangay Zone III (Pob.)", mun_code: "097332" },
  // { label: "Barangay Zone IV (Pob.)", mun_code: "097332" },
  // { label: "Putik", mun_code: "097332" },
  // { label: "Quiniput", mun_code: "097332" },
  // { label: "Recodo", mun_code: "097332" },
  // { label: "Rio Hondo", mun_code: "097332" },
  // { label: "Salaan", mun_code: "097332" },
  // { label: "San Jose Cawa-cawa", mun_code: "097332" },
  // { label: "San Jose Gusu", mun_code: "097332" },
  // { label: "San Roque", mun_code: "097332" },
  // { label: "Sangali", mun_code: "097332" },
  // { label: "Santa Barbara", mun_code: "097332" },
  // { label: "Santa Catalina", mun_code: "097332" },
  // { label: "Santa Maria", mun_code: "097332" },
  // { label: "Sibulao (Caruan)", mun_code: "097332" },
  // { label: "Sinubung", mun_code: "097332" },
  // { label: "Sinunoc", mun_code: "097332" },
  // { label: "Tagasilay", mun_code: "097332" },
  // { label: "Taguiti", mun_code: "097332" },
  // { label: "Talabaan", mun_code: "097332" },
  // { label: "Talisayan", mun_code: "097332" },
  // { label: "Talon-talon", mun_code: "097332" },
  // { label: "Taluksangay", mun_code: "097332" },
  // { label: "Tetuan", mun_code: "097332" },
  // { label: "Tictapul", mun_code: "097332" },
  // { label: "Tigbalabag", mun_code: "097332" },
  // { label: "Tigtabon", mun_code: "097332" },
  // { label: "Tolosa", mun_code: "097332" },
  // { label: "Tugbungan", mun_code: "097332" },
  // { label: "Tulungatung", mun_code: "097332" },
  // { label: "Tumaga", mun_code: "097332" },
  // { label: "Tumalutab", mun_code: "097332" },
  // { label: "Tumitus", mun_code: "097332" },
  // { label: "Vitali", mun_code: "097332" },
  // { label: "Capisan", mun_code: "097332" },
  // { label: "Camino Nuevo", mun_code: "097332" },
  // { label: "Licomo", mun_code: "097332" },
  // { label: "Kasanyangan", mun_code: "097332" },
  // { label: "Pasobolong", mun_code: "097332" },
  // { label: "Victoria", mun_code: "097332" },
  // { label: "Zambowood", mun_code: "097332" },
  // { label: "Baking", mun_code: "097333" },
  // { label: "Bagong Kahayag", mun_code: "097333" },
  // { label: "Biswangan", mun_code: "097333" },
  // { label: "Bululawan", mun_code: "097333" },
  // { label: "Dagum", mun_code: "097333" },
  // { label: "Gasa", mun_code: "097333" },
  // { label: "Gatub", mun_code: "097333" },
  // { label: "Poblacion (Lakewood)", mun_code: "097333" },
  // { label: "Lukuan", mun_code: "097333" },
  // { label: "Matalang", mun_code: "097333" },
  // { label: "Sapang Pinoles", mun_code: "097333" },
  // { label: "Sebuguey", mun_code: "097333" },
  // { label: "Tiwales", mun_code: "097333" },
  // { label: "Tubod", mun_code: "097333" },
  // { label: "Bogo Calabat", mun_code: "097337" },
  // { label: "Dawa (Diwa)", mun_code: "097337" },
  // { label: "Ebarle", mun_code: "097337" },
  // { label: "Upper Bagong Tudela (Pob.)", mun_code: "097337" },
  // { label: "Leonardo", mun_code: "097337" },
  // { label: "Litapan", mun_code: "097337" },
  // { label: "Lower Bagong Tudela", mun_code: "097337" },
  // { label: "Mansanas", mun_code: "097337" },
  // { label: "Moradji", mun_code: "097337" },
  // { label: "Nopulan", mun_code: "097337" },
  // { label: "Sebukang", mun_code: "097337" },
  // { label: "Tagaytay Hill", mun_code: "097337" },
  // { label: "Gumahan (Pob.)", mun_code: "097337" },
  // { label: "Balabawan", mun_code: "097338" },
  // { label: "Balong-balong", mun_code: "097338" },
  // { label: "Colojo", mun_code: "097338" },
  // { label: "Liasan", mun_code: "097338" },
  // { label: "Liguac", mun_code: "097338" },
  // { label: "Limbayan", mun_code: "097338" },
  // { label: "Lower Paniki-an", mun_code: "097338" },
  // { label: "Matin-ao", mun_code: "097338" },
  // { label: "Panubigan", mun_code: "097338" },
  // { label: "Poblacion (Pitogo)", mun_code: "097338" },
  // { label: "Punta Flecha", mun_code: "097338" },
  // { label: "Sugbay Dos", mun_code: "097338" },
  // { label: "Tongao", mun_code: "097338" },
  // { label: "Upper Paniki-an", mun_code: "097338" },
  // { label: "San Isidro", mun_code: "097338" },
  // { label: "Bag-ong Baroy", mun_code: "097340" },
  // { label: "Bag-ong Oroquieta", mun_code: "097340" },
  // { label: "Barubuhan", mun_code: "097340" },
  // { label: "Bulanay", mun_code: "097340" },
  // { label: "Datagan", mun_code: "097340" },
  // { label: "Eastern Poblacion", mun_code: "097340" },
  // { label: "Lantawan", mun_code: "097340" },
  // { label: "Libertad", mun_code: "097340" },
  // { label: "Lumangoy", mun_code: "097340" },
  // { label: "New Carmen", mun_code: "097340" },
  // { label: "Picturan", mun_code: "097340" },
  // { label: "Poblacion", mun_code: "097340" },
  // { label: "Rizal", mun_code: "097340" },
  // { label: "San Miguel", mun_code: "097340" },
  // { label: "Sawa", mun_code: "097340" },
  // { label: "Tungawan", mun_code: "097340" },
  // { label: "Upper Sicpao", mun_code: "097340" },
  // { label: "Bui-os", mun_code: "097341" },
  // { label: "Cogon", mun_code: "097341" },
  // { label: "Danan", mun_code: "097341" },
  // { label: "Kabatan", mun_code: "097341" },
  // { label: "Kapatagan", mun_code: "097341" },
  // { label: "Limason", mun_code: "097341" },
  // { label: "Linoguayan", mun_code: "097341" },
  // { label: "Lumbal", mun_code: "097341" },
  // { label: "Lunib", mun_code: "097341" },
  // { label: "Maculay", mun_code: "097341" },
  // { label: "Maraya", mun_code: "097341" },
  // { label: "Sagucan", mun_code: "097341" },
  // { label: "Waling-waling", mun_code: "097341" },
  // { label: "Ambulon", mun_code: "097341" },
  // { label: "Bagong Oroquieta", mun_code: "097343" },
  // { label: "Baguitan", mun_code: "097343" },
  // { label: "Balongating", mun_code: "097343" },
  // { label: "Canunan", mun_code: "097343" },
  // { label: "Dacsol", mun_code: "097343" },
  // { label: "Dagohoy", mun_code: "097343" },
  // { label: "Dalapang", mun_code: "097343" },
  // { label: "Datagan", mun_code: "097343" },
  // { label: "Poblacion (Guipos)", mun_code: "097343" },
  // { label: "Guling", mun_code: "097343" },
  // { label: "Katipunan", mun_code: "097343" },
  // { label: "Lintum", mun_code: "097343" },
  // { label: "Litan", mun_code: "097343" },
  // { label: "Magting", mun_code: "097343" },
  // { label: "Regla", mun_code: "097343" },
  // { label: "Sikatuna", mun_code: "097343" },
  // { label: "Singclot", mun_code: "097343" },
  // { label: "Begong", mun_code: "097344" },
  // { label: "Busol", mun_code: "097344" },
  // { label: "Caluma", mun_code: "097344" },
  // { label: "Diana Countryside", mun_code: "097344" },
  // { label: "Guinlin", mun_code: "097344" },
  // { label: "Lacarayan", mun_code: "097344" },
  // { label: "Lacupayan", mun_code: "097344" },
  // { label: "Libayoy", mun_code: "097344" },
  // { label: "Limas", mun_code: "097344" },
  // { label: "Longmot", mun_code: "097344" },
  // { label: "Maragang", mun_code: "097344" },
  // { label: "Mate", mun_code: "097344" },
  // { label: "Nangan-nangan", mun_code: "097344" },
  // { label: "New Tuburan", mun_code: "097344" },
  // { label: "Nilo", mun_code: "097344" },
  // { label: "Tigbao", mun_code: "097344" },
  // { label: "Timolan", mun_code: "097344" },
  // { label: "Upper Nilo", mun_code: "097344" },
  // { label: "Alegria", mun_code: "098301" },
  // { label: "Milagrosa (Baluno)", mun_code: "098301" },
  // { label: "Bella", mun_code: "098301" },
  // { label: "Calades", mun_code: "098301" },
  // { label: "Dawa-dawa", mun_code: "098301" },
  // { label: "Gulayon", mun_code: "098301" },
  // { label: "Ilisan", mun_code: "098301" },
  // { label: "Kawayan", mun_code: "098301" },
  // { label: "Kauswagan", mun_code: "098301" },
  // { label: "La Paz", mun_code: "098301" },
  // { label: "Lambuyogan", mun_code: "098301" },
  // { label: "Lapirawan", mun_code: "098301" },
  // { label: "Litayon", mun_code: "098301" },
  // { label: "Lutiman", mun_code: "098301" },
  // { label: "Naga-naga", mun_code: "098301" },
  // { label: "Pandan-pandan", mun_code: "098301" },
  // { label: "Payongan", mun_code: "098301" },
  // { label: "Poblacion", mun_code: "098301" },
  // { label: "Santa Maria", mun_code: "098301" },
  // { label: "Talaptap", mun_code: "098301" },
  // { label: "Tampalan", mun_code: "098301" },
  // { label: "Tandiong Muslim", mun_code: "098301" },
  // { label: "Timbang-timbang", mun_code: "098301" },
  // { label: "Bagong Buhay", mun_code: "098301" },
  // { label: "Concepcion", mun_code: "098301" },
  // { label: "Kapatagan", mun_code: "098301" },
  // { label: "Basalem", mun_code: "098302" },
  // { label: "Bawang", mun_code: "098302" },
  // { label: "Bulaan", mun_code: "098302" },
  // { label: "Compostela", mun_code: "098302" },
  // { label: "Del Monte", mun_code: "098302" },
  // { label: "Guitom", mun_code: "098302" },
  // { label: "Guminta", mun_code: "098302" },
  // { label: "Labrador", mun_code: "098302" },
  // { label: "Lantawan", mun_code: "098302" },
  // { label: "Mabuhay", mun_code: "098302" },
  // { label: "Maganay", mun_code: "098302" },
  // { label: "Manlin", mun_code: "098302" },
  // { label: "Muyo", mun_code: "098302" },
  // { label: "Pamintayan", mun_code: "098302" },
  // { label: "Poblacion", mun_code: "098302" },
  // { label: "Talamimi", mun_code: "098302" },
  // { label: "Villacastor (Galit)", mun_code: "098302" },
  // { label: "Agutayan", mun_code: "098302" },
  // { label: "Bagong Borbon", mun_code: "098302" },
  // { label: "Bliss", mun_code: "098302" },
  // { label: "Danlugan", mun_code: "098302" },
  // { label: "Pling", mun_code: "098302" },
  // { label: "Pulog", mun_code: "098302" },
  // { label: "San Jose", mun_code: "098302" },
  // { label: "Talairan", mun_code: "098302" },
  // { label: "Datu Panas", mun_code: "098302" },
  // { label: "Guintuloan", mun_code: "098302" },
  // { label: "Balangao", mun_code: "098303" },
  // { label: "Pilar", mun_code: "098303" },
  // { label: "Poblacion (Diplahan)", mun_code: "098303" },
  // { label: "Ditay", mun_code: "098303" },
  // { label: "Gaulan", mun_code: "098303" },
  // { label: "Goling", mun_code: "098303" },
  // { label: "Guinoman", mun_code: "098303" },
  // { label: "Kauswagan", mun_code: "098303" },
  // { label: "Lindang", mun_code: "098303" },
  // { label: "Lobing", mun_code: "098303" },
  // { label: "Paradise", mun_code: "098303" },
  // { label: "Sampoli A", mun_code: "098303" },
  // { label: "Songcuya", mun_code: "098303" },
  // { label: "Santa Cruz", mun_code: "098303" },
  // { label: "Butong", mun_code: "098303" },
  // { label: "Sampoli B", mun_code: "098303" },
  // { label: "Luop", mun_code: "098303" },
  // { label: "Manangon", mun_code: "098303" },
  // { label: "Mejo", mun_code: "098303" },
  // { label: "Natan", mun_code: "098303" },
  // { label: "Tinongtongan", mun_code: "098303" },
  // { label: "Tuno", mun_code: "098303" },
  // { label: "Lower Baluran", mun_code: "098304" },
  // { label: "Baluyan", mun_code: "098304" },
  // { label: "Cana-an", mun_code: "098304" },
  // { label: "Dumpoc", mun_code: "098304" },
  // { label: "Gandiangan", mun_code: "098304" },
  // { label: "Israel (Balian Israel)", mun_code: "098304" },
  // { label: "La Victoria", mun_code: "098304" },
  // { label: "Lumpanac", mun_code: "098304" },
  // { label: "Little Baguio", mun_code: "098304" },
  // { label: "Lumbog", mun_code: "098304" },
  // { label: "Mali Little Baguio", mun_code: "098304" },
  // { label: "Poblacion (Santa Fe)", mun_code: "098304" },
  // { label: "San Jose", mun_code: "098304" },
  // { label: "Santa Barbara", mun_code: "098304" },
  // { label: "Balugo", mun_code: "098304" },
  // { label: "Balungisan", mun_code: "098304" },
  // { label: "Pulawan (Mt. View)", mun_code: "098304" },
  // { label: "Upper Baluran", mun_code: "098304" },
  // { label: "Bacalan", mun_code: "098305" },
  // { label: "Bangkerohan", mun_code: "098305" },
  // { label: "Bulu-an", mun_code: "098305" },
  // { label: "Don Andres", mun_code: "098305" },
  // { label: "Guituan", mun_code: "098305" },
  // { label: "Ipil Heights", mun_code: "098305" },
  // { label: "Labi", mun_code: "098305" },
  // { label: "Lower Ipil Heights", mun_code: "098305" },
  // { label: "Lower Taway", mun_code: "098305" },
  // { label: "Lumbia", mun_code: "098305" },
  // { label: "Magdaup", mun_code: "098305" },
  // { label: "Pangi", mun_code: "098305" },
  // { label: "Poblacion", mun_code: "098305" },
  // { label: "Sanito", mun_code: "098305" },
  // { label: "Suclema", mun_code: "098305" },
  // { label: "Taway", mun_code: "098305" },
  // { label: "Tenan", mun_code: "098305" },
  // { label: "Tiayon", mun_code: "098305" },
  // { label: "Timalang", mun_code: "098305" },
  // { label: "Tomitom", mun_code: "098305" },
  // { label: "Upper Pangi", mun_code: "098305" },
  // { label: "Veteran's Village (Ruiz)", mun_code: "098305" },
  // { label: "Makilas", mun_code: "098305" },
  // { label: "Caparan", mun_code: "098305" },
  // { label: "Domandan", mun_code: "098305" },
  // { label: "Logan", mun_code: "098305" },
  // { label: "Maasin", mun_code: "098305" },
  // { label: "Banker", mun_code: "098306" },
  // { label: "Bolo Batallion", mun_code: "098306" },
  // { label: "Buayan", mun_code: "098306" },
  // { label: "Cainglet", mun_code: "098306" },
  // { label: "Calapan", mun_code: "098306" },
  // { label: "Calubihan", mun_code: "098306" },
  // { label: "Concepcion (Balungis)", mun_code: "098306" },
  // { label: "Dipala", mun_code: "098306" },
  // { label: "Gacbusan", mun_code: "098306" },
  // { label: "Goodyear", mun_code: "098306" },
  // { label: "Lacnapan", mun_code: "098306" },
  // { label: "Little Baguio", mun_code: "098306" },
  // { label: "Lumbayao", mun_code: "098306" },
  // { label: "Timuay Danda (Mangahas)", mun_code: "098306" },
  // { label: "Nazareth", mun_code: "098306" },
  // { label: "Palinta", mun_code: "098306" },
  // { label: "Poblacion", mun_code: "098306" },
  // { label: "Riverside", mun_code: "098306" },
  // { label: "Sanghanan", mun_code: "098306" },
  // { label: "Santa Cruz", mun_code: "098306" },
  // { label: "Sayao", mun_code: "098306" },
  // { label: "Simbol", mun_code: "098306" },
  // { label: "Sininan", mun_code: "098306" },
  // { label: "Shiolan", mun_code: "098306" },
  // { label: "Tampilisan", mun_code: "098306" },
  // { label: "Tamin", mun_code: "098306" },
  // { label: "Tigbangagan", mun_code: "098306" },
  // { label: "Diampak", mun_code: "098306" },
  // { label: "Abunda", mun_code: "098307" },
  // { label: "Bagong Silang", mun_code: "098307" },
  // { label: "Bangkaw-bangkaw", mun_code: "098307" },
  // { label: "Caliran", mun_code: "098307" },
  // { label: "Catipan", mun_code: "098307" },
  // { label: "Tandu-Comot (Katipunan)", mun_code: "098307" },
  // { label: "Kauswagan", mun_code: "098307" },
  // { label: "Ligaya", mun_code: "098307" },
  // { label: "Looc-Barlak", mun_code: "098307" },
  // { label: "Malinao", mun_code: "098307" },
  // { label: "Pamansaan", mun_code: "098307" },
  // { label: "Pinalim (San Roque)", mun_code: "098307" },
  // { label: "Poblacion", mun_code: "098307" },
  // { label: "Punawan", mun_code: "098307" },
  // { label: "Sawa", mun_code: "098307" },
  // { label: "Sioton", mun_code: "098307" },
  // { label: "Taguisian", mun_code: "098307" },
  // { label: "Bacao", mun_code: "098308" },
  // { label: "Basak-bawang", mun_code: "098308" },
  // { label: "Camanga", mun_code: "098308" },
  // { label: "Candiis", mun_code: "098308" },
  // { label: "Catituan", mun_code: "098308" },
  // { label: "Dansulao", mun_code: "098308" },
  // { label: "Del Pilar", mun_code: "098308" },
  // { label: "Guilawa", mun_code: "098308" },
  // { label: "Kigay", mun_code: "098308" },
  // { label: "La Dicha", mun_code: "098308" },
  // { label: "Lipacan", mun_code: "098308" },
  // { label: "Logpond", mun_code: "098308" },
  // { label: "Mabini", mun_code: "098308" },
  // { label: "Malungon", mun_code: "098308" },
  // { label: "Mulom", mun_code: "098308" },
  // { label: "Overland", mun_code: "098308" },
  // { label: "Palalian", mun_code: "098308" },
  // { label: "Payag", mun_code: "098308" },
  // { label: "Poblacion", mun_code: "098308" },
  // { label: "Rebocon", mun_code: "098308" },
  // { label: "San Vicente", mun_code: "098308" },
  // { label: "Sinusayan", mun_code: "098308" },
  // { label: "Tackling", mun_code: "098308" },
  // { label: "Tigabon", mun_code: "098308" },
  // { label: "Bontong", mun_code: "098308" },
  // { label: "Aguinaldo", mun_code: "098309" },
  // { label: "Baga", mun_code: "098309" },
  // { label: "Baluno", mun_code: "098309" },
  // { label: "Cabong", mun_code: "098309" },
  // { label: "Gubawang", mun_code: "098309" },
  // { label: "Kaliantana", mun_code: "098309" },
  // { label: "La Paz", mun_code: "098309" },
  // { label: "Lower Sulitan", mun_code: "098309" },
  // { label: "Mamagon", mun_code: "098309" },
  // { label: "Marsolo", mun_code: "098309" },
  // { label: "Poblacion", mun_code: "098309" },
  // { label: "San Isidro", mun_code: "098309" },
  // { label: "Santa Clara", mun_code: "098309" },
  // { label: "Crossing Sta. Clara", mun_code: "098309" },
  // { label: "Sulo", mun_code: "098309" },
  // { label: "Tambanan", mun_code: "098309" },
  // { label: "Taytay Manubo", mun_code: "098309" },
  // { label: "Upper Sulitan", mun_code: "098309" },
  // { label: "Bangkaw-bangkaw", mun_code: "098309" },
  // { label: "Guintoloan", mun_code: "098309" },
  // { label: "Sandayong", mun_code: "098309" },
  // { label: "Tilubog", mun_code: "098309" },
  // { label: "Tipan", mun_code: "098309" },
  // { label: "Bateria", mun_code: "098310" },
  // { label: "Kahayagan", mun_code: "098310" },
  // { label: "Calais (Kalines)", mun_code: "098310" },
  // { label: "Esperanza", mun_code: "098310" },
  // { label: "Fama", mun_code: "098310" },
  // { label: "Galas", mun_code: "098310" },
  // { label: "Gandaan", mun_code: "098310" },
  // { label: "Looc Sapi", mun_code: "098310" },
  // { label: "Matim", mun_code: "098310" },
  // { label: "Noque", mun_code: "098310" },
  // { label: "Solar (Pob.)", mun_code: "098310" },
  // { label: "Pulo Laum", mun_code: "098310" },
  // { label: "Pulo Mabao", mun_code: "098310" },
  // { label: "San Isidro", mun_code: "098310" },
  // { label: "Santa Maria", mun_code: "098310" },
  // { label: "Tambanan", mun_code: "098310" },
  // { label: "Villacorte", mun_code: "098310" },
  // { label: "Villagonzalo", mun_code: "098310" },
  // { label: "San Jose", mun_code: "098310" },
  // { label: "Balian", mun_code: "098311" },
  // { label: "Balungisan", mun_code: "098311" },
  // { label: "Bulacan", mun_code: "098311" },
  // { label: "Bulawan", mun_code: "098311" },
  // { label: "Calape", mun_code: "098311" },
  // { label: "Dalama", mun_code: "098311" },
  // { label: "Fatima (Silal)", mun_code: "098311" },
  // { label: "Guintolan", mun_code: "098311" },
  // { label: "Katipunan", mun_code: "098311" },
  // { label: "Kima", mun_code: "098311" },
  // { label: "Kulasian", mun_code: "098311" },
  // { label: "Labatan", mun_code: "098311" },
  // { label: "Mountain View (Puluan)", mun_code: "098311" },
  // { label: "Nanan", mun_code: "098311" },
  // { label: "Poblacion (Payao)", mun_code: "098311" },
  // { label: "San Roque", mun_code: "098311" },
  // { label: "San Vicente (Binangonan)", mun_code: "098311" },
  // { label: "Mayabo (Santa Maria)", mun_code: "098311" },
  // { label: "Talaptap", mun_code: "098311" },
  // { label: "Balogo", mun_code: "098311" },
  // { label: "Binangonan", mun_code: "098311" },
  // { label: "Guiwan", mun_code: "098311" },
  // { label: "Kulisap", mun_code: "098311" },
  // { label: "La Fortuna", mun_code: "098311" },
  // { label: "San Isidro", mun_code: "098311" },
  // { label: "Silal", mun_code: "098311" },
  // { label: "Sumilong", mun_code: "098311" },
  // { label: "Upper Sumilong", mun_code: "098311" },
  // { label: "Balansag", mun_code: "098312" },
  // { label: "Calula", mun_code: "098312" },
  // { label: "Casacon", mun_code: "098312" },
  // { label: "Gango", mun_code: "098312" },
  // { label: "Katipunan", mun_code: "098312" },
  // { label: "Kulambugan", mun_code: "098312" },
  // { label: "Mabini", mun_code: "098312" },
  // { label: "Magsaysay", mun_code: "098312" },
  // { label: "New Antique", mun_code: "098312" },
  // { label: "New Sagay", mun_code: "098312" },
  // { label: "Pres. Roxas", mun_code: "098312" },
  // { label: "San Antonio", mun_code: "098312" },
  // { label: "San Fernandino", mun_code: "098312" },
  // { label: "San Jose", mun_code: "098312" },
  // { label: "Siawang", mun_code: "098312" },
  // { label: "Silingan", mun_code: "098312" },
  // { label: "Surabay", mun_code: "098312" },
  // { label: "Tilasan", mun_code: "098312" },
  // { label: "Tupilac", mun_code: "098312" },
  // { label: "Ali Alsree", mun_code: "098312" },
  // { label: "Don Perfecto", mun_code: "098312" },
  // { label: "Malubal", mun_code: "098312" },
  // { label: "Palmera", mun_code: "098312" },
  // { label: "Remedios", mun_code: "098312" },
  // { label: "Santo Rosario", mun_code: "098312" },
  // { label: "Taruc", mun_code: "098312" },
  // { label: "Balucanan", mun_code: "098313" },
  // { label: "Balagon", mun_code: "098313" },
  // { label: "Balingasan", mun_code: "098313" },
  // { label: "Batu", mun_code: "098313" },
  // { label: "Buyogan", mun_code: "098313" },
  // { label: "Camanga", mun_code: "098313" },
  // { label: "Coloran", mun_code: "098313" },
  // { label: "Kimos (Kima)", mun_code: "098313" },
  // { label: "Labasan", mun_code: "098313" },
  // { label: "Laih", mun_code: "098313" },
  // { label: "Magsaysay", mun_code: "098313" },
  // { label: "Mahayahay", mun_code: "098313" },
  // { label: "Maligaya", mun_code: "098313" },
  // { label: "Maniha", mun_code: "098313" },
  // { label: "Minsulao", mun_code: "098313" },
  // { label: "Mirangan", mun_code: "098313" },
  // { label: "Monching", mun_code: "098313" },
  // { label: "Paruk", mun_code: "098313" },
  // { label: "Poblacion", mun_code: "098313" },
  // { label: "Princesa Sumama", mun_code: "098313" },
  // { label: "Sibuguey", mun_code: "098313" },
  // { label: "Bagong Silang", mun_code: "098313" },
  // { label: "Bataan", mun_code: "098313" },
  // { label: "Lagting", mun_code: "098313" },
  // { label: "Logpond", mun_code: "098313" },
  // { label: "Salinding", mun_code: "098313" },
  // { label: "San Isidro", mun_code: "098313" },
  // { label: "Siloh", mun_code: "098313" },
  // { label: "Villagracia", mun_code: "098313" },
  // { label: "Aurora", mun_code: "098314" },
  // { label: "Baganipay", mun_code: "098314" },
  // { label: "Bolingan", mun_code: "098314" },
  // { label: "Bualan", mun_code: "098314" },
  // { label: "Cawilan", mun_code: "098314" },
  // { label: "Florida", mun_code: "098314" },
  // { label: "Kasigpitan", mun_code: "098314" },
  // { label: "Laparay", mun_code: "098314" },
  // { label: "Mahayahay", mun_code: "098314" },
  // { label: "Moalboal", mun_code: "098314" },
  // { label: "Sagay", mun_code: "098314" },
  // { label: "Samonte", mun_code: "098314" },
  // { label: "Poblacion (Talusan)", mun_code: "098314" },
  // { label: "Tuburan", mun_code: "098314" },
  // { label: "Achasol", mun_code: "098315" },
  // { label: "Bangco", mun_code: "098315" },
  // { label: "Camanga", mun_code: "098315" },
  // { label: "Culasian", mun_code: "098315" },
  // { label: "Dalangin", mun_code: "098315" },
  // { label: "Dalisay", mun_code: "098315" },
  // { label: "Gomotoc", mun_code: "098315" },
  // { label: "Imelda (Upper Camanga)", mun_code: "098315" },
  // { label: "Kipit", mun_code: "098315" },
  // { label: "Kitabog", mun_code: "098315" },
  // { label: "La Libertad", mun_code: "098315" },
  // { label: "Longilog", mun_code: "098315" },
  // { label: "Mabini", mun_code: "098315" },
  // { label: "Malagandis", mun_code: "098315" },
  // { label: "Mate", mun_code: "098315" },
  // { label: "Moalboal", mun_code: "098315" },
  // { label: "Namnama", mun_code: "098315" },
  // { label: "New Canaan", mun_code: "098315" },
  // { label: "Palomoc", mun_code: "098315" },
  // { label: "Poblacion (Titay)", mun_code: "098315" },
  // { label: "Pulidan", mun_code: "098315" },
  // { label: "San Antonio", mun_code: "098315" },
  // { label: "Santa Fe", mun_code: "098315" },
  // { label: "Supit", mun_code: "098315" },
  // { label: "Tugop", mun_code: "098315" },
  // { label: "Tugop Muslim", mun_code: "098315" },
  // { label: "Dalangin Muslim", mun_code: "098315" },
  // { label: "Poblacion Muslim", mun_code: "098315" },
  // { label: "Azusano", mun_code: "098315" },
  // { label: "San Isidro", mun_code: "098315" },
  // { label: "Baluran", mun_code: "098316" },
  // { label: "Cayamcam", mun_code: "098316" },
  // { label: "Langon", mun_code: "098316" },
  // { label: "Libertad (Pob.)", mun_code: "098316" },
  // { label: "Linguisan", mun_code: "098316" },
  // { label: "Looc-labuan", mun_code: "098316" },
  // { label: "Lower Tungawan", mun_code: "098316" },
  // { label: "Malungon", mun_code: "098316" },
  // { label: "San Pedro", mun_code: "098316" },
  // { label: "Tigbanuang", mun_code: "098316" },
  // { label: "Tigbucay", mun_code: "098316" },
  // { label: "Upper Tungawan", mun_code: "098316" },
  // { label: "San Vicente", mun_code: "098316" },
  // { label: "Batungan", mun_code: "098316" },
  // { label: "Loboc", mun_code: "098316" },
  // { label: "San Isidro", mun_code: "098316" },
  // { label: "Datu Tumanggong", mun_code: "098316" },
  // { label: "Sisay", mun_code: "098316" },
  // { label: "Gaycon", mun_code: "098316" },
  // { label: "Little Margos", mun_code: "098316" },
  // { label: "Taglibas", mun_code: "098316" },
  // { label: "Tigpalay", mun_code: "098316" },
  // { label: "Masao", mun_code: "098316" },
  // { label: "Timbabauan", mun_code: "098316" },
  // { label: "Aguada", mun_code: "099701" },
  // { label: "Balatanay", mun_code: "099701" },
  // { label: "Baluno", mun_code: "099701" },
  // { label: "Begang", mun_code: "099701" },
  // { label: "Binuangan", mun_code: "099701" },
  // { label: "Busay", mun_code: "099701" },
  // { label: "Cabunbata", mun_code: "099701" },
  // { label: "Calvario", mun_code: "099701" },
  // { label: "Carbon", mun_code: "099701" },
  // { label: "Diki", mun_code: "099701" },
  // { label: "Isabela Eastside (Pob.)", mun_code: "099701" },
  // { label: "Isabela Proper (Pob.)", mun_code: "099701" },
  // { label: "Dona Ramona T. Alano (Isabela Westsite)", mun_code: "099701" },
  // { label: "Kapatagan Grande", mun_code: "099701" },
  // { label: "Kaumpurnah Zone I", mun_code: "099701" },
  // { label: "Kaumpurnah Zone II", mun_code: "099701" },
  // { label: "Kaumpurnah Zone III", mun_code: "099701" },
  // { label: "Kumalarang", mun_code: "099701" },
  // { label: "La Piedad (Pob.)", mun_code: "099701" },
  // { label: "Lampinigan", mun_code: "099701" },
  // { label: "Lanote", mun_code: "099701" },
  // { label: "Lukbuton", mun_code: "099701" },
  // { label: "Lumbang", mun_code: "099701" },
  // { label: "Makiri", mun_code: "099701" },
  // { label: "Maligue (Lunot)", mun_code: "099701" },
  // { label: "Marang-marang", mun_code: "099701" },
  // { label: "Marketsite (Pob.)", mun_code: "099701" },
  // { label: "Menzi", mun_code: "099701" },
  // { label: "Panigayan", mun_code: "099701" },
  // { label: "Panunsulan", mun_code: "099701" },
  // { label: "Port Area (Pob.)", mun_code: "099701" },
  // { label: "Riverside", mun_code: "099701" },
  // { label: "San Rafael", mun_code: "099701" },
  // { label: "Santa Barbara", mun_code: "099701" },
  // { label: "Santa Cruz (Pob.)", mun_code: "099701" },
  // { label: "Seaside (Pob.)", mun_code: "099701" },
  // { label: "Sumagdang", mun_code: "099701" },
  // { label: "Sunrise Village (Pob.)", mun_code: "099701" },
  // { label: "Tabiawan", mun_code: "099701" },
  // { label: "Tabuk (Pob.)", mun_code: "099701" },
  // { label: "Timpul", mun_code: "099701" },
  // { label: "Kapayawan", mun_code: "099701" },
  // { label: "Masula", mun_code: "099701" },
  // { label: "Small Kapatagan", mun_code: "099701" },
  // { label: "Tampalan", mun_code: "099701" },
  // { label: "Balintad", mun_code: "101301" },
  // { label: "Buenavista", mun_code: "101301" },
  // { label: "Danatag", mun_code: "101301" },
  // { label: "Kalilangan", mun_code: "101301" },
  // { label: "Lacolac", mun_code: "101301" },
  // { label: "Langaon", mun_code: "101301" },
  // { label: "Liboran", mun_code: "101301" },
  // { label: "Lingating", mun_code: "101301" },
  // { label: "Mabuhay", mun_code: "101301" },
  // { label: "Mabunga", mun_code: "101301" },
  // { label: "Nicdao", mun_code: "101301" },
  // { label: "Imbatug (Pob.)", mun_code: "101301" },
  // { label: "Pualas", mun_code: "101301" },
  // { label: "Salimbalan", mun_code: "101301" },
  // { label: "San Vicente", mun_code: "101301" },
  // { label: "San Miguel", mun_code: "101301" },
  // { label: "Aludas", mun_code: "101302" },
  // { label: "Angga-an", mun_code: "101302" },
  // { label: "Tangkulan (Jose Rizal)", mun_code: "101302" },
  // { label: "Kinapat", mun_code: "101302" },
  // { label: "Kiraon", mun_code: "101302" },
  // { label: "Kitingting", mun_code: "101302" },
  // { label: "Lagandang", mun_code: "101302" },
  // { label: "Macapari", mun_code: "101302" },
  // { label: "Maican", mun_code: "101302" },
  // { label: "Migcawayan", mun_code: "101302" },
  // { label: "New Compostela", mun_code: "101302" },
  // { label: "Old Damulog", mun_code: "101302" },
  // { label: "Omonay", mun_code: "101302" },
  // { label: "Poblacion (New Damulog)", mun_code: "101302" },
  // { label: "Pocopoco", mun_code: "101302" },
  // { label: "Sampagar", mun_code: "101302" },
  // { label: "San Isidro", mun_code: "101302" },
  // { label: "Barongcot", mun_code: "101303" },
  // { label: "Bugwak", mun_code: "101303" },
  // { label: "Dolorosa", mun_code: "101303" },
  // { label: "Kapalaran", mun_code: "101303" },
  // { label: "Kianggat", mun_code: "101303" },
  // { label: "Lourdes", mun_code: "101303" },
  // { label: "Macarthur", mun_code: "101303" },
  // { label: "Miaray", mun_code: "101303" },
  // { label: "Migcuya", mun_code: "101303" },
  // { label: "New Visayas", mun_code: "101303" },
  // { label: "Poblacion", mun_code: "101303" },
  // { label: "Sagbayan", mun_code: "101303" },
  // { label: "San Vicente", mun_code: "101303" },
  // { label: "Cabadiangan", mun_code: "101304" },
  // { label: "Bocboc", mun_code: "101304" },
  // { label: "Buyot", mun_code: "101304" },
  // { label: "Calaocalao", mun_code: "101304" },
  // { label: "Don Carlos Norte", mun_code: "101304" },
  // { label: "Embayao", mun_code: "101304" },
  // { label: "Kalubihon", mun_code: "101304" },
  // { label: "Kasigkot", mun_code: "101304" },
  // { label: "Kawilihan", mun_code: "101304" },
  // { label: "Kiara", mun_code: "101304" },
  // { label: "Kibatang", mun_code: "101304" },
  // { label: "Mahayahay", mun_code: "101304" },
  // { label: "Manlamonay", mun_code: "101304" },
  // { label: "Maraymaray", mun_code: "101304" },
  // { label: "Mauswagon", mun_code: "101304" },
  // { label: "Minsalagan", mun_code: "101304" },
  // { label: "New Nongnongan (Masimag)", mun_code: "101304" },
  // { label: "New Visayas", mun_code: "101304" },
  // { label: "Old Nongnongan", mun_code: "101304" },
  // { label: "Pinamaloy", mun_code: "101304" },
  // { label: "Don Carlos Sur (Pob.)", mun_code: "101304" },
  // { label: "Pualas", mun_code: "101304" },
  // { label: "San Antonio East", mun_code: "101304" },
  // { label: "San Antonio West", mun_code: "101304" },
  // { label: "San Francisco", mun_code: "101304" },
  // { label: "San Nicolas (Banban)", mun_code: "101304" },
  // { label: "San Roque", mun_code: "101304" },
  // { label: "Sinangguyan", mun_code: "101304" },
  // { label: "Bismartz", mun_code: "101304" },
  // { label: "Bontongon", mun_code: "101305" },
  // { label: "Bulonay", mun_code: "101305" },
  // { label: "Capitan Bayong", mun_code: "101305" },
  // { label: "Cawayan", mun_code: "101305" },
  // { label: "Dumalaguing", mun_code: "101305" },
  // { label: "Guihean", mun_code: "101305" },
  // { label: "Hagpa", mun_code: "101305" },
  // { label: "Impalutao", mun_code: "101305" },
  // { label: "Kalabugao", mun_code: "101305" },
  // { label: "Kibenton", mun_code: "101305" },
  // { label: "La Fortuna", mun_code: "101305" },
  // { label: "Poblacion", mun_code: "101305" },
  // { label: "Sayawan", mun_code: "101305" },
  // { label: "Bagongbayan", mun_code: "101306" },
  // { label: "Bagor", mun_code: "101306" },
  // { label: "Balaoro", mun_code: "101306" },
  // { label: "Baroy", mun_code: "101306" },
  // { label: "Cabadiangan", mun_code: "101306" },
  // { label: "Husayan", mun_code: "101306" },
  // { label: "Kibalagon", mun_code: "101306" },
  // { label: "Mabuhay", mun_code: "101306" },
  // { label: "Malinao", mun_code: "101306" },
  // { label: "Matampay", mun_code: "101306" },
  // { label: "Sibonga", mun_code: "101306" },
  // { label: "Pay-as", mun_code: "101306" },
  // { label: "Pinamanguhan", mun_code: "101306" },
  // { label: "Poblacion", mun_code: "101306" },
  // { label: "Salvacion", mun_code: "101306" },
  // { label: "San Andres", mun_code: "101306" },
  // { label: "Kibogtok", mun_code: "101306" },
  // { label: "Bangbang", mun_code: "101307" },
  // { label: "Baborawon", mun_code: "101307" },
  // { label: "Canituan", mun_code: "101307" },
  // { label: "Kibaning", mun_code: "101307" },
  // { label: "Kinura", mun_code: "101307" },
  // { label: "Lampanusan", mun_code: "101307" },
  // { label: "Maca-opao", mun_code: "101307" },
  // { label: "Malinao", mun_code: "101307" },
  // { label: "Pamotolon (Pamotdon)", mun_code: "101307" },
  // { label: "Poblacion", mun_code: "101307" },
  // { label: "Public", mun_code: "101307" },
  // { label: "Ninoy Aquino", mun_code: "101307" },
  // { label: "San Vicente Ferrer", mun_code: "101307" },
  // { label: "West Poblacion", mun_code: "101307" },
  // { label: "Balintawak", mun_code: "101308" },
  // { label: "Cagawasan", mun_code: "101308" },
  // { label: "East Kibawe (Pob.)", mun_code: "101308" },
  // { label: "Gutapol", mun_code: "101308" },
  // { label: "Pinamula", mun_code: "101308" },
  // { label: "Kiorao", mun_code: "101308" },
  // { label: "Kisawa", mun_code: "101308" },
  // { label: "Labuagon", mun_code: "101308" },
  // { label: "Magsaysay", mun_code: "101308" },
  // { label: "Marapangi", mun_code: "101308" },
  // { label: "Natulongan", mun_code: "101308" },
  // { label: "New Kidapawan", mun_code: "101308" },
  // { label: "Old Kibawe", mun_code: "101308" },
  // { label: "Romagooc", mun_code: "101308" },
  // { label: "Sampaguita", mun_code: "101308" },
  // { label: "Sanipon", mun_code: "101308" },
  // { label: "Spring", mun_code: "101308" },
  // { label: "Talahiron", mun_code: "101308" },
  // { label: "Tumaras", mun_code: "101308" },
  // { label: "West Kibawe (Pob.)", mun_code: "101308" },
  // { label: "Bukang Liwayway", mun_code: "101308" },
  // { label: "Palma", mun_code: "101308" },
  // { label: "Balangigay", mun_code: "101309" },
  // { label: "Balukbukan", mun_code: "101309" },
  // { label: "Bershiba", mun_code: "101309" },
  // { label: "Bobong", mun_code: "101309" },
  // { label: "Bolocaon", mun_code: "101309" },
  // { label: "Cabalantian", mun_code: "101309" },
  // { label: "Calapaton", mun_code: "101309" },
  // { label: "Sinaysayan (Dalurong)", mun_code: "101309" },
  // { label: "Kahusayan", mun_code: "101309" },
  // { label: "Kalumihan", mun_code: "101309" },
  // { label: "Kauyonan", mun_code: "101309" },
  // { label: "Kimolong", mun_code: "101309" },
  // { label: "Kitaihon", mun_code: "101309" },
  // { label: "Kitobo", mun_code: "101309" },
  // { label: "Magsaysay", mun_code: "101309" },
  // { label: "Malobalo", mun_code: "101309" },
  // { label: "Metebagao", mun_code: "101309" },
  // { label: "Sagundanon", mun_code: "101309" },
  // { label: "Pagan", mun_code: "101309" },
  // { label: "Panganan", mun_code: "101309" },
  // { label: "Poblacion", mun_code: "101309" },
  // { label: "San Isidro", mun_code: "101309" },
  // { label: "San Lorenzo", mun_code: "101309" },
  // { label: "Santo Rosario", mun_code: "101309" },
  // { label: "Sinuda (Simod)", mun_code: "101309" },
  // { label: "Tandong", mun_code: "101309" },
  // { label: "Tawas", mun_code: "101309" },
  // { label: "White Kulaman", mun_code: "101309" },
  // { label: "Napalico", mun_code: "101309" },
  // { label: "Digongan", mun_code: "101309" },
  // { label: "Kiulom", mun_code: "101309" },
  // { label: "Binoongan", mun_code: "101309" },
  // { label: "Kipilas", mun_code: "101309" },
  // { label: "East Dalurong", mun_code: "101309" },
  // { label: "West Dalurong", mun_code: "101309" },
  // { label: "Alanib", mun_code: "101310" },
  // { label: "Baclayon", mun_code: "101310" },
  // { label: "Balila", mun_code: "101310" },
  // { label: "Bantuanon", mun_code: "101310" },
  // { label: "Basak", mun_code: "101310" },
  // { label: "Bugcaon", mun_code: "101310" },
  // { label: "Ka-atoan (Kaatuan)", mun_code: "101310" },
  // { label: "Capitan Juan", mun_code: "101310" },
  // { label: "Cawayan", mun_code: "101310" },
  // { label: "Kulasihan", mun_code: "101310" },
  // { label: "Kibangay", mun_code: "101310" },
  // { label: "Poblacion", mun_code: "101310" },
  // { label: "Songco", mun_code: "101310" },
  // { label: "Victory", mun_code: "101310" },
  // { label: "Capihan", mun_code: "101311" },
  // { label: "Crossing", mun_code: "101311" },
  // { label: "Gango", mun_code: "101311" },
  // { label: "Kiliog", mun_code: "101311" },
  // { label: "Kinawe", mun_code: "101311" },
  // { label: "Laturan", mun_code: "101311" },
  // { label: "Maambong", mun_code: "101311" },
  // { label: "Nangka", mun_code: "101311" },
  // { label: "Palabucan", mun_code: "101311" },
  // { label: "Poblacion", mun_code: "101311" },
  // { label: "Pongol", mun_code: "101311" },
  // { label: "San Jose", mun_code: "101311" },
  // { label: "Santa Fe", mun_code: "101311" },
  // { label: "Sil-ipon", mun_code: "101311" },
  // { label: "Aglayan", mun_code: "101312" },
  // { label: "Bangcud", mun_code: "101312" },
  // { label: "Busdi", mun_code: "101312" },
  // { label: "Cabangahan", mun_code: "101312" },
  // { label: "Caburacanan", mun_code: "101312" },
  // { label: "Canayan", mun_code: "101312" },
  // { label: "Capitan Angel", mun_code: "101312" },
  // { label: "Casisang", mun_code: "101312" },
  // { label: "Dalwangan", mun_code: "101312" },
  // { label: "Imbayao", mun_code: "101312" },
  // { label: "Indalaza", mun_code: "101312" },
  // { label: "Kalasungay", mun_code: "101312" },
  // { label: "Kabalabag", mun_code: "101312" },
  // { label: "Kulaman", mun_code: "101312" },
  // { label: "Laguitas", mun_code: "101312" },
  // { label: "Patpat (Lapu-lapu)", mun_code: "101312" },
  // { label: "Linabo", mun_code: "101312" },
  // { label: "Apo Macote", mun_code: "101312" },
  // { label: "Miglamin", mun_code: "101312" },
  // { label: "Magsaysay", mun_code: "101312" },
  // { label: "Maligaya", mun_code: "101312" },
  // { label: "Managok", mun_code: "101312" },
  // { label: "Manalog", mun_code: "101312" },
  // { label: "Mapayag", mun_code: "101312" },
  // { label: "Mapulo", mun_code: "101312" },
  // { label: "Barangay 1 (Pob.)", mun_code: "101312" },
  // { label: "Barangay 2 (Pob.)", mun_code: "101312" },
  // { label: "Barangay 3 (Pob.)", mun_code: "101312" },
  // { label: "Barangay 4 (Pob.)", mun_code: "101312" },
  // { label: "Barangay 5 (Pob.)", mun_code: "101312" },
  // { label: "Barangay 6 (Pob.)", mun_code: "101312" },
  // { label: "Barangay 7 (Pob.)", mun_code: "101312" },
  // { label: "Barangay 8 (Pob.)", mun_code: "101312" },
  // { label: "Barangay 9 (Pob.)", mun_code: "101312" },
  // { label: "Barangay 10 (Pob.)", mun_code: "101312" },
  // { label: "Barangay 11 (Pob.)", mun_code: "101312" },
  // { label: "Saint Peter", mun_code: "101312" },
  // { label: "San Jose", mun_code: "101312" },
  // { label: "San Martin", mun_code: "101312" },
  // { label: "Silae", mun_code: "101312" },
  // { label: "Simaya", mun_code: "101312" },
  // { label: "Sinanglanan", mun_code: "101312" },
  // { label: "Sumpong", mun_code: "101312" },
  // { label: "Violeta", mun_code: "101312" },
  // { label: "Zamboanguita", mun_code: "101312" },
  // { label: "Kalingking", mun_code: "101313" },
  // { label: "Kiabo", mun_code: "101313" },
  // { label: "Mindagat", mun_code: "101313" },
  // { label: "Omagling", mun_code: "101313" },
  // { label: "Patpat", mun_code: "101313" },
  // { label: "Poblacion", mun_code: "101313" },
  // { label: "Sampiano", mun_code: "101313" },
  // { label: "San Luis", mun_code: "101313" },
  // { label: "Santa Ines", mun_code: "101313" },
  // { label: "Silo-o", mun_code: "101313" },
  // { label: "Sumalsag", mun_code: "101313" },
  // { label: "Agusan Canyon", mun_code: "101314" },
  // { label: "Alae", mun_code: "101314" },
  // { label: "Dahilayan", mun_code: "101314" },
  // { label: "Dalirig", mun_code: "101314" },
  // { label: "Damilag", mun_code: "101314" },
  // { label: "Diclum", mun_code: "101314" },
  // { label: "Guilang-guilang", mun_code: "101314" },
  // { label: "Kalugmanan", mun_code: "101314" },
  // { label: "Lindaban", mun_code: "101314" },
  // { label: "Lingion", mun_code: "101314" },
  // { label: "Lunocan", mun_code: "101314" },
  // { label: "Maluko", mun_code: "101314" },
  // { label: "Mambatangan", mun_code: "101314" },
  // { label: "Mampayag", mun_code: "101314" },
  // { label: "Minsuro", mun_code: "101314" },
  // { label: "Mantibugao", mun_code: "101314" },
  // { label: "Tankulan (Pob.)", mun_code: "101314" },
  // { label: "San Miguel", mun_code: "101314" },
  // { label: "Sankanan", mun_code: "101314" },
  // { label: "Santiago", mun_code: "101314" },
  // { label: "Ticala", mun_code: "101314" },
  // { label: "Anahawon", mun_code: "101315" },
  // { label: "Base Camp", mun_code: "101315" },
  // { label: "Bayabason (Spring)", mun_code: "101315" },
  // { label: "Camp I", mun_code: "101315" },
  // { label: "Colambugan", mun_code: "101315" },
  // { label: "Dagumba-an", mun_code: "101315" },
  // { label: "Danggawan", mun_code: "101315" },
  // { label: "Dologon", mun_code: "101315" },
  // { label: "Kisanday", mun_code: "101315" },
  // { label: "Kuya", mun_code: "101315" },
  // { label: "La Roxas", mun_code: "101315" },
  // { label: "Panadtalan", mun_code: "101315" },
  // { label: "Panalsalan", mun_code: "101315" },
  // { label: "North Poblacion", mun_code: "101315" },
  // { label: "South Poblacion", mun_code: "101315" },
  // { label: "San Miguel", mun_code: "101315" },
  // { label: "San Roque", mun_code: "101315" },
  // { label: "Tubigon", mun_code: "101315" },
  // { label: "Bagongsilang", mun_code: "101315" },
  // { label: "Kiharong", mun_code: "101315" },
  // { label: "Adtuyon", mun_code: "101316" },
  // { label: "Bacusanon", mun_code: "101316" },
  // { label: "Bangahan", mun_code: "101316" },
  // { label: "Barandias", mun_code: "101316" },
  // { label: "Concepcion", mun_code: "101316" },
  // { label: "Gandingan", mun_code: "101316" },
  // { label: "Kimanait", mun_code: "101316" },
  // { label: "Kipadukan", mun_code: "101316" },
  // { label: "Langcataon", mun_code: "101316" },
  // { label: "Lantay", mun_code: "101316" },
  // { label: "Madaya", mun_code: "101316" },
  // { label: "Malipayon", mun_code: "101316" },
  // { label: "Mendis", mun_code: "101316" },
  // { label: "Nabaliwa", mun_code: "101316" },
  // { label: "New Eden", mun_code: "101316" },
  // { label: "Payad", mun_code: "101316" },
  // { label: "Pigtauranan", mun_code: "101316" },
  // { label: "Poblacion", mun_code: "101316" },
  // { label: "Portulin", mun_code: "101316" },
  // { label: "Butong", mun_code: "101317" },
  // { label: "Cebole", mun_code: "101317" },
  // { label: "Delapa", mun_code: "101317" },
  // { label: "Dumalama", mun_code: "101317" },
  // { label: "C-Handumanan", mun_code: "101317" },
  // { label: "Cawayan", mun_code: "101317" },
  // { label: "Kiburiao", mun_code: "101317" },
  // { label: "Kipaypayon", mun_code: "101317" },
  // { label: "Libertad", mun_code: "101317" },
  // { label: "Linabo", mun_code: "101317" },
  // { label: "Lipa", mun_code: "101317" },
  // { label: "Lumintao", mun_code: "101317" },
  // { label: "Magsaysay", mun_code: "101317" },
  // { label: "Mahayag", mun_code: "101317" },
  // { label: "Manuto", mun_code: "101317" },
  // { label: "Merangerang", mun_code: "101317" },
  // { label: "Mibantang", mun_code: "101317" },
  // { label: "Minongan", mun_code: "101317" },
  // { label: "Minsamongan", mun_code: "101317" },
  // { label: "Paitan", mun_code: "101317" },
  // { label: "Palacapao", mun_code: "101317" },
  // { label: "Pinilayan", mun_code: "101317" },
  // { label: "Poblacion (Kiokong)", mun_code: "101317" },
  // { label: "Puntian", mun_code: "101317" },
  // { label: "Salawagan", mun_code: "101317" },
  // { label: "San Isidro", mun_code: "101317" },
  // { label: "San Jose", mun_code: "101317" },
  // { label: "San Roque", mun_code: "101317" },
  // { label: "Santa Cruz", mun_code: "101317" },
  // { label: "Santa Filomena", mun_code: "101317" },
  // { label: "Minsalirak", mun_code: "101317" },
  // { label: "Bonacao", mun_code: "101318" },
  // { label: "Cabuling", mun_code: "101318" },
  // { label: "Kawayan", mun_code: "101318" },
  // { label: "Cayaga", mun_code: "101318" },
  // { label: "Dao", mun_code: "101318" },
  // { label: "Durian", mun_code: "101318" },
  // { label: "Iglugsad", mun_code: "101318" },
  // { label: "Kalagangan", mun_code: "101318" },
  // { label: "Kibongcog", mun_code: "101318" },
  // { label: "Little Baguio", mun_code: "101318" },
  // { label: "Nacabuklad", mun_code: "101318" },
  // { label: "Namnam", mun_code: "101318" },
  // { label: "Palacpacan", mun_code: "101318" },
  // { label: "Halapitan (Pob.)", mun_code: "101318" },
  // { label: "San Jose", mun_code: "101318" },
  // { label: "Santo Domingo", mun_code: "101318" },
  // { label: "Tugop", mun_code: "101318" },
  // { label: "Matupe", mun_code: "101318" },
  // { label: "Bulalang", mun_code: "101318" },
  // { label: "Candelaria", mun_code: "101318" },
  // { label: "Mabuhay", mun_code: "101318" },
  // { label: "Magkalungay", mun_code: "101318" },
  // { label: "Malayanan", mun_code: "101318" },
  // { label: "Sacramento Valley", mun_code: "101318" },
  // { label: "Kisolon", mun_code: "101319" },
  // { label: "Culasi", mun_code: "101319" },
  // { label: "Licoan", mun_code: "101319" },
  // { label: "Lupiagan", mun_code: "101319" },
  // { label: "Ocasion", mun_code: "101319" },
  // { label: "Puntian", mun_code: "101319" },
  // { label: "San Roque", mun_code: "101319" },
  // { label: "San Vicente", mun_code: "101319" },
  // { label: "Poblacion", mun_code: "101319" },
  // { label: "Vista Villa", mun_code: "101319" },
  // { label: "Basak", mun_code: "101320" },
  // { label: "Baylanan", mun_code: "101320" },
  // { label: "Cacaon", mun_code: "101320" },
  // { label: "Colawingon", mun_code: "101320" },
  // { label: "Cosina", mun_code: "101320" },
  // { label: "Dagumbaan", mun_code: "101320" },
  // { label: "Dagundalahon", mun_code: "101320" },
  // { label: "Dominorog", mun_code: "101320" },
  // { label: "Lapok", mun_code: "101320" },
  // { label: "Indulang", mun_code: "101320" },
  // { label: "Lantud", mun_code: "101320" },
  // { label: "Liguron", mun_code: "101320" },
  // { label: "Lingi-on", mun_code: "101320" },
  // { label: "Lirongan", mun_code: "101320" },
  // { label: "Miarayon", mun_code: "101320" },
  // { label: "Barangay 1 (Pob.)", mun_code: "101320" },
  // { label: "Barangay 2 (Pob.)", mun_code: "101320" },
  // { label: "Barangay 3 (Pob.)", mun_code: "101320" },
  // { label: "Barangay 4 (Pob.)", mun_code: "101320" },
  // { label: "Barangay 5 (Pob.)", mun_code: "101320" },
  // { label: "Sagaran", mun_code: "101320" },
  // { label: "Salucot", mun_code: "101320" },
  // { label: "San Antonio", mun_code: "101320" },
  // { label: "San Isidro", mun_code: "101320" },
  // { label: "San Miguel", mun_code: "101320" },
  // { label: "San Rafael", mun_code: "101320" },
  // { label: "Tagbak", mun_code: "101320" },
  // { label: "Tikalaan", mun_code: "101320" },
  // { label: "Bagontaas", mun_code: "101321" },
  // { label: "Banlag", mun_code: "101321" },
  // { label: "Barobo", mun_code: "101321" },
  // { label: "Batangan", mun_code: "101321" },
  // { label: "Catumbalon", mun_code: "101321" },
  // { label: "Colonia", mun_code: "101321" },
  // { label: "Concepcion", mun_code: "101321" },
  // { label: "Dagat-Kidavao", mun_code: "101321" },
  // { label: "Guinoyuran", mun_code: "101321" },
  // { label: "Kahapunan", mun_code: "101321" },
  // { label: "Laligan", mun_code: "101321" },
  // { label: "Lilingayon", mun_code: "101321" },
  // { label: "Lourdes", mun_code: "101321" },
  // { label: "Lumbayao", mun_code: "101321" },
  // { label: "Lumbo", mun_code: "101321" },
  // { label: "Lurogan", mun_code: "101321" },
  // { label: "Maapag", mun_code: "101321" },
  // { label: "Mabuhay", mun_code: "101321" },
  // { label: "Mailag", mun_code: "101321" },
  // { label: "Mt. Nebo", mun_code: "101321" },
  // { label: "Nabago", mun_code: "101321" },
  // { label: "Pinatilan", mun_code: "101321" },
  // { label: "Poblacion", mun_code: "101321" },
  // { label: "San Carlos", mun_code: "101321" },
  // { label: "San Isidro", mun_code: "101321" },
  // { label: "Sinabuagan", mun_code: "101321" },
  // { label: "Sinayawan", mun_code: "101321" },
  // { label: "Sugod", mun_code: "101321" },
  // { label: "Tongantongan", mun_code: "101321" },
  // { label: "Tugaya", mun_code: "101321" },
  // { label: "Vintar", mun_code: "101321" },
  // { label: "Cabulohan", mun_code: "101322" },
  // { label: "Canangaan", mun_code: "101322" },
  // { label: "Iba", mun_code: "101322" },
  // { label: "Imbatug", mun_code: "101322" },
  // { label: "Lambangan", mun_code: "101322" },
  // { label: "Mandaing", mun_code: "101322" },
  // { label: "Paradise", mun_code: "101322" },
  // { label: "Poblacion", mun_code: "101322" },
  // { label: "Anlogan", mun_code: "101322" },
  // { label: "Capinonan", mun_code: "101322" },
  // { label: "Dalacutan", mun_code: "101322" },
  // { label: "Freedom", mun_code: "101322" },
  // { label: "Mandahikan", mun_code: "101322" },
  // { label: "Mauswagon", mun_code: "101322" },
  // { label: "Jasaan", mun_code: "101322" },
  // { label: "Alga", mun_code: "101801" },
  // { label: "Bonbon", mun_code: "101801" },
  // { label: "Bura", mun_code: "101801" },
  // { label: "Catibac", mun_code: "101801" },
  // { label: "Compol", mun_code: "101801" },
  // { label: "Lawigan", mun_code: "101801" },
  // { label: "Liloan", mun_code: "101801" },
  // { label: "Looc", mun_code: "101801" },
  // { label: "Mainit", mun_code: "101801" },
  // { label: "Manduao", mun_code: "101801" },
  // { label: "Panghiawan", mun_code: "101801" },
  // { label: "Poblacion", mun_code: "101801" },
  // { label: "Tangaro", mun_code: "101801" },
  // { label: "Butay", mun_code: "101802" },
  // { label: "Cabuan", mun_code: "101802" },
  // { label: "Cantaan", mun_code: "101802" },
  // { label: "Liong", mun_code: "101802" },
  // { label: "Maac", mun_code: "101802" },
  // { label: "North Poblacion", mun_code: "101802" },
  // { label: "South Poblacion", mun_code: "101802" },
  // { label: "Benoni", mun_code: "101803" },
  // { label: "Binatubo (Binaliwan)", mun_code: "101803" },
  // { label: "Catohugan", mun_code: "101803" },
  // { label: "Hubangon", mun_code: "101803" },
  // { label: "Owakan", mun_code: "101803" },
  // { label: "Poblacion", mun_code: "101803" },
  // { label: "Puntod", mun_code: "101803" },
  // { label: "San Isidro", mun_code: "101803" },
  // { label: "San Jose", mun_code: "101803" },
  // { label: "San Miguel", mun_code: "101803" },
  // { label: "San Roque", mun_code: "101803" },
  // { label: "Tubod", mun_code: "101803" },
  // { label: "Agoho", mun_code: "101804" },
  // { label: "Anito", mun_code: "101804" },
  // { label: "Balbagon", mun_code: "101804" },
  // { label: "Baylao", mun_code: "101804" },
  // { label: "Benhaan", mun_code: "101804" },
  // { label: "Bug-ong", mun_code: "101804" },
  // { label: "Kuguita", mun_code: "101804" },
  // { label: "Magting", mun_code: "101804" },
  // { label: "Naasag", mun_code: "101804" },
  // { label: "Pandan", mun_code: "101804" },
  // { label: "Poblacion", mun_code: "101804" },
  // { label: "Soro-soro", mun_code: "101804" },
  // { label: "Tagdo", mun_code: "101804" },
  // { label: "Tupsan", mun_code: "101804" },
  // { label: "Yumbing", mun_code: "101804" },
  // { label: "Alangilan", mun_code: "101805" },
  // { label: "Bacnit", mun_code: "101805" },
  // { label: "Balite", mun_code: "101805" },
  // { label: "Bonbon", mun_code: "101805" },
  // { label: "Bugang", mun_code: "101805" },
  // { label: "Cuna", mun_code: "101805" },
  // { label: "Manuyog", mun_code: "101805" },
  // { label: "Mayana", mun_code: "101805" },
  // { label: "Poblacion", mun_code: "101805" },
  // { label: "Alegria", mun_code: "103501" },
  // { label: "Babalaya", mun_code: "103501" },
  // { label: "Babalayan Townsite", mun_code: "103501" },
  // { label: "Binuni", mun_code: "103501" },
  // { label: "Demologan", mun_code: "103501" },
  // { label: "Dimarao", mun_code: "103501" },
  // { label: "Esperanza", mun_code: "103501" },
  // { label: "Kahayag", mun_code: "103501" },
  // { label: "Liangan East", mun_code: "103501" },
  // { label: "Punod (Maliwanag)", mun_code: "103501" },
  // { label: "Mati", mun_code: "103501" },
  // { label: "Minaulon", mun_code: "103501" },
  // { label: "Pagayawan", mun_code: "103501" },
  // { label: "Poblacion Bacolod", mun_code: "103501" },
  // { label: "Rupagan", mun_code: "103501" },
  // { label: "Delabayan West", mun_code: "103501" },
  // { label: "Abaga", mun_code: "103502" },
  // { label: "Adapun-Ali (Dariat)", mun_code: "103502" },
  // { label: "Angandog (Bulao)", mun_code: "103502" },
  // { label: "Angayen (Balut)", mun_code: "103502" },
  // { label: "Bangko", mun_code: "103502" },
  // { label: "Batolacongan (Basagad)", mun_code: "103502" },
  // { label: "Buenavista", mun_code: "103502" },
  // { label: "Cadayonan", mun_code: "103502" },
  // { label: "Landa (Gadongan)", mun_code: "103502" },
  // { label: "Lumbac", mun_code: "103502" },
  // { label: "Mamaanun", mun_code: "103502" },
  // { label: "Maria-Cristina", mun_code: "103502" },
  // { label: "Matampay", mun_code: "103502" },
  // { label: "Nangka", mun_code: "103502" },
  // { label: "Pacalundo", mun_code: "103502" },
  // { label: "Poblacion East", mun_code: "103502" },
  // { label: "Poblacion West", mun_code: "103502" },
  // { label: "Sandor (Daduan)", mun_code: "103502" },
  // { label: "Sangcad (Cormatan )", mun_code: "103502" },
  // { label: "Sarip-Alawi (Payawan)", mun_code: "103502" },
  // { label: "Sigayan", mun_code: "103502" },
  // { label: "Andil", mun_code: "103503" },
  // { label: "Bagong Dawis", mun_code: "103503" },
  // { label: "Baroy Daku", mun_code: "103503" },
  // { label: "Bato", mun_code: "103503" },
  // { label: "Cabasagan", mun_code: "103503" },
  // { label: "Dalama", mun_code: "103503" },
  // { label: "Libertad", mun_code: "103503" },
  // { label: "Limwag", mun_code: "103503" },
  // { label: "Lindongan", mun_code: "103503" },
  // { label: "Maliwanag", mun_code: "103503" },
  // { label: "Manan-ao", mun_code: "103503" },
  // { label: "Pange", mun_code: "103503" },
  // { label: "Pindolonan", mun_code: "103503" },
  // { label: "Poblacion", mun_code: "103503" },
  // { label: "Princesa", mun_code: "103503" },
  // { label: "Rawan Point", mun_code: "103503" },
  // { label: "Riverside", mun_code: "103503" },
  // { label: "Sagadan (Sagadan Lower)", mun_code: "103503" },
  // { label: "Salong", mun_code: "103503" },
  // { label: "Tinubdan", mun_code: "103503" },
  // { label: "Sagadan Upper", mun_code: "103503" },
  // { label: "San Juan", mun_code: "103503" },
  // { label: "Village", mun_code: "103503" },
  // { label: "Abuno", mun_code: "103504" },
  // { label: "Bonbonon", mun_code: "103504" },
  // { label: "Bunawan", mun_code: "103504" },
  // { label: "Buru-un", mun_code: "103504" },
  // { label: "Dalipuga", mun_code: "103504" },
  // { label: "Digkilaan", mun_code: "103504" },
  // { label: "Hinaplanon", mun_code: "103504" },
  // { label: "Kabacsanan", mun_code: "103504" },
  // { label: "Kiwalan", mun_code: "103504" },
  // { label: "Mahayhay", mun_code: "103504" },
  // { label: "Mainit", mun_code: "103504" },
  // { label: "Mandulog", mun_code: "103504" },
  // { label: "Maria Cristina", mun_code: "103504" },
  // { label: "Palao", mun_code: "103504" },
  // { label: "Poblacion", mun_code: "103504" },
  // { label: "Puga-an", mun_code: "103504" },
  // { label: "Rogongon", mun_code: "103504" },
  // { label: "Santa Elena", mun_code: "103504" },
  // { label: "Santa Filomena", mun_code: "103504" },
  // { label: "Suarez", mun_code: "103504" },
  // { label: "Tambacan", mun_code: "103504" },
  // { label: "Saray-Tibanga", mun_code: "103504" },
  // { label: "Tipanoy", mun_code: "103504" },
  // { label: "Tominobo Proper", mun_code: "103504" },
  // { label: "Tominobo Upper", mun_code: "103504" },
  // { label: "Tubod", mun_code: "103504" },
  // { label: "Bagong Silang", mun_code: "103504" },
  // { label: "Del Carmen", mun_code: "103504" },
  // { label: "Dulag", mun_code: "103504" },
  // { label: "San Miguel", mun_code: "103504" },
  // { label: "Santiago", mun_code: "103504" },
  // { label: "Santo Rosario", mun_code: "103504" },
  // { label: "Tibanga", mun_code: "103504" },
  // { label: "Acmac", mun_code: "103504" },
  // { label: "Ditucalan", mun_code: "103504" },
  // { label: "Hindang", mun_code: "103504" },
  // { label: "Kalilangan", mun_code: "103504" },
  // { label: "Lanipao", mun_code: "103504" },
  // { label: "Luinab", mun_code: "103504" },
  // { label: "Panoroganan", mun_code: "103504" },
  // { label: "San Roque", mun_code: "103504" },
  // { label: "Ubaldo Laya", mun_code: "103504" },
  // { label: "Upper Hinaplanon", mun_code: "103504" },
  // { label: "Villa Verde", mun_code: "103504" },
  // { label: "Bagong Badian", mun_code: "103505" },
  // { label: "Bagong Silang", mun_code: "103505" },
  // { label: "Balili", mun_code: "103505" },
  // { label: "Bansarvil", mun_code: "103505" },
  // { label: "Belis", mun_code: "103505" },
  // { label: "Buenavista", mun_code: "103505" },
  // { label: "Butadon", mun_code: "103505" },
  // { label: "Cathedral Falls", mun_code: "103505" },
  // { label: "Concepcion", mun_code: "103505" },
  // { label: "Curvada", mun_code: "103505" },
  // { label: "De Asis", mun_code: "103505" },
  // { label: "Donggoan", mun_code: "103505" },
  // { label: "Durano", mun_code: "103505" },
  // { label: "Kahayagan", mun_code: "103505" },
  // { label: "Kidalos", mun_code: "103505" },
  // { label: "La Libertad", mun_code: "103505" },
  // { label: "Lapinig", mun_code: "103505" },
  // { label: "Mahayahay", mun_code: "103505" },
  // { label: "Malinas", mun_code: "103505" },
  // { label: "Maranding", mun_code: "103505" },
  // { label: "Margos", mun_code: "103505" },
  // { label: "Poblacion", mun_code: "103505" },
  // { label: "Pulang Yuta", mun_code: "103505" },
  // { label: "San Isidro", mun_code: "103505" },
  // { label: "San Vicente", mun_code: "103505" },
  // { label: "Santa Cruz", mun_code: "103505" },
  // { label: "Santo Tomas", mun_code: "103505" },
  // { label: "Suso", mun_code: "103505" },
  // { label: "Taguitic", mun_code: "103505" },
  // { label: "Tiacongan", mun_code: "103505" },
  // { label: "Tipolo", mun_code: "103505" },
  // { label: "Tulatulahan", mun_code: "103505" },
  // { label: "Waterfalls", mun_code: "103505" },
  // { label: "Bangaan", mun_code: "103506" },
  // { label: "Bangco", mun_code: "103506" },
  // { label: "Bansarvil II", mun_code: "103506" },
  // { label: "Bauyan", mun_code: "103506" },
  // { label: "Cabongbongan", mun_code: "103506" },
  // { label: "Calibao", mun_code: "103506" },
  // { label: "Calipapa", mun_code: "103506" },
  // { label: "Calube", mun_code: "103506" },
  // { label: "Campo Islam", mun_code: "103506" },
  // { label: "Capocao", mun_code: "103506" },
  // { label: "Dabliston", mun_code: "103506" },
  // { label: "Dangulaan", mun_code: "103506" },
  // { label: "Ditago", mun_code: "103506" },
  // { label: "Ilian", mun_code: "103506" },
  // { label: "Kauswagan", mun_code: "103506" },
  // { label: "Kirapan", mun_code: "103506" },
  // { label: "Koreo", mun_code: "103506" },
  // { label: "Lantawan", mun_code: "103506" },
  // { label: "Mabuhay", mun_code: "103506" },
  // { label: "Maguindanao", mun_code: "103506" },
  // { label: "Mahayahay", mun_code: "103506" },
  // { label: "Mamagum", mun_code: "103506" },
  // { label: "Pandanan", mun_code: "103506" },
  // { label: "Payong", mun_code: "103506" },
  // { label: "Piraka", mun_code: "103506" },
  // { label: "Pikalawag", mun_code: "103506" },
  // { label: "Pikinit", mun_code: "103506" },
  // { label: "Poblacion", mun_code: "103506" },
  // { label: "Ramain", mun_code: "103506" },
  // { label: "Rebucon", mun_code: "103506" },
  // { label: "Sigayan", mun_code: "103506" },
  // { label: "Sugod", mun_code: "103506" },
  // { label: "Tagulo", mun_code: "103506" },
  // { label: "Tantaon", mun_code: "103506" },
  // { label: "Topocon (Capocgo)", mun_code: "103506" },
  // { label: "Dalama", mun_code: "103506" },
  // { label: "Mina", mun_code: "103506" },
  // { label: "Bagumbayan (Pob.)", mun_code: "103507" },
  // { label: "Bara-ason", mun_code: "103507" },
  // { label: "Cayontor", mun_code: "103507" },
  // { label: "Delabayan", mun_code: "103507" },
  // { label: "Inudaran", mun_code: "103507" },
  // { label: "Kawit Occidental", mun_code: "103507" },
  // { label: "Kawit Oriental", mun_code: "103507" },
  // { label: "Libertad", mun_code: "103507" },
  // { label: "Paiton", mun_code: "103507" },
  // { label: "Poblacion", mun_code: "103507" },
  // { label: "Tacub", mun_code: "103507" },
  // { label: "Tingintingin", mun_code: "103507" },
  // { label: "Tugar", mun_code: "103507" },
  // { label: "Austin Heights", mun_code: "103508" },
  // { label: "Baybay", mun_code: "103508" },
  // { label: "Bubong", mun_code: "103508" },
  // { label: "Caromatan", mun_code: "103508" },
  // { label: "Inudaran", mun_code: "103508" },
  // { label: "Kulasihan", mun_code: "103508" },
  // { label: "Libertad", mun_code: "103508" },
  // { label: "Lumbac", mun_code: "103508" },
  // { label: "Manga", mun_code: "103508" },
  // { label: "Matampay", mun_code: "103508" },
  // { label: "Mukas", mun_code: "103508" },
  // { label: "Muntay", mun_code: "103508" },
  // { label: "Pagalungan", mun_code: "103508" },
  // { label: "Palao", mun_code: "103508" },
  // { label: "Pantaon", mun_code: "103508" },
  // { label: "Pantar", mun_code: "103508" },
  // { label: "Poblacion", mun_code: "103508" },
  // { label: "Rebucon", mun_code: "103508" },
  // { label: "Riverside", mun_code: "103508" },
  // { label: "San Roque", mun_code: "103508" },
  // { label: "Simbuco", mun_code: "103508" },
  // { label: "Small Banisilan", mun_code: "103508" },
  // { label: "Sucodan", mun_code: "103508" },
  // { label: "Tabigue", mun_code: "103508" },
  // { label: "Titunod", mun_code: "103508" },
  // { label: "Abaga", mun_code: "103509" },
  // { label: "Andil", mun_code: "103509" },
  // { label: "Matampay Bucana", mun_code: "103509" },
  // { label: "Darumawang Bucana", mun_code: "103509" },
  // { label: "Cabasagan", mun_code: "103509" },
  // { label: "Camalan", mun_code: "103509" },
  // { label: "Darumawang Ilaya", mun_code: "103509" },
  // { label: "El Salvador", mun_code: "103509" },
  // { label: "Gumagamot", mun_code: "103509" },
  // { label: "Lala Proper (Pob.)", mun_code: "103509" },
  // { label: "Lanipao", mun_code: "103509" },
  // { label: "Magpatao", mun_code: "103509" },
  // { label: "Maranding", mun_code: "103509" },
  // { label: "Matampay Ilaya", mun_code: "103509" },
  // { label: "Pacita", mun_code: "103509" },
  // { label: "Pendolonan", mun_code: "103509" },
  // { label: "Pinoyak", mun_code: "103509" },
  // { label: "Raw-an", mun_code: "103509" },
  // { label: "Rebe", mun_code: "103509" },
  // { label: "San Isidro Lower", mun_code: "103509" },
  // { label: "San Isidro Upper", mun_code: "103509" },
  // { label: "San Manuel", mun_code: "103509" },
  // { label: "Santa Cruz Lower", mun_code: "103509" },
  // { label: "Santa Cruz Upper", mun_code: "103509" },
  // { label: "Simpak", mun_code: "103509" },
  // { label: "Tenazas", mun_code: "103509" },
  // { label: "Tuna-an", mun_code: "103509" },
  // { label: "Busque", mun_code: "103510" },
  // { label: "Larapan", mun_code: "103510" },
  // { label: "Magoong", mun_code: "103510" },
  // { label: "Napo", mun_code: "103510" },
  // { label: "Poblacion", mun_code: "103510" },
  // { label: "Purakan", mun_code: "103510" },
  // { label: "Robocon", mun_code: "103510" },
  // { label: "Samburon", mun_code: "103510" },
  // { label: "Babasalon", mun_code: "103511" },
  // { label: "Baguiguicon", mun_code: "103511" },
  // { label: "Daan Campo", mun_code: "103511" },
  // { label: "Durianon", mun_code: "103511" },
  // { label: "Ilihan", mun_code: "103511" },
  // { label: "Lamigadato", mun_code: "103511" },
  // { label: "Lemoncret", mun_code: "103511" },
  // { label: "Lubo", mun_code: "103511" },
  // { label: "Lumbac", mun_code: "103511" },
  // { label: "Malabaogan", mun_code: "103511" },
  // { label: "Mapantao", mun_code: "103511" },
  // { label: "Olango", mun_code: "103511" },
  // { label: "Pangao", mun_code: "103511" },
  // { label: "Pelingkingan", mun_code: "103511" },
  // { label: "Lower Caningag (Perimbangan)", mun_code: "103511" },
  // { label: "Poblacion (Bago-A-Ingud)", mun_code: "103511" },
  // { label: "Rarab", mun_code: "103511" },
  // { label: "Somiorang", mun_code: "103511" },
  // { label: "Upper Caningag (Taguitingan)", mun_code: "103511" },
  // { label: "Talambo", mun_code: "103511" },
  // { label: "Tambacon", mun_code: "103511" },
  // { label: "Tawinian", mun_code: "103511" },
  // { label: "Tipaan", mun_code: "103511" },
  // { label: "Tombador", mun_code: "103511" },
  // { label: "Balagatasa", mun_code: "103512" },
  // { label: "Camp 1", mun_code: "103512" },
  // { label: "Claro M. Recto", mun_code: "103512" },
  // { label: "Inoma", mun_code: "103512" },
  // { label: "Labuay", mun_code: "103512" },
  // { label: "Liangan West", mun_code: "103512" },
  // { label: "Mahayahay", mun_code: "103512" },
  // { label: "Maliwanag", mun_code: "103512" },
  // { label: "Mentring", mun_code: "103512" },
  // { label: "Poblacion", mun_code: "103512" },
  // { label: "Santa Cruz", mun_code: "103512" },
  // { label: "Segapod", mun_code: "103512" },
  // { label: "Kulasihan (Villanueva)", mun_code: "103512" },
  // { label: "Bubong Radapan", mun_code: "103513" },
  // { label: "Bangco", mun_code: "103513" },
  // { label: "Batal", mun_code: "103513" },
  // { label: "Batangan", mun_code: "103513" },
  // { label: "Cadayonan", mun_code: "103513" },
  // { label: "Matampay", mun_code: "103513" },
  // { label: "Pangi", mun_code: "103513" },
  // { label: "Pasayanon", mun_code: "103513" },
  // { label: "Poblacion (Matungao)", mun_code: "103513" },
  // { label: "Puntod", mun_code: "103513" },
  // { label: "Santa Cruz", mun_code: "103513" },
  // { label: "Somiorang", mun_code: "103513" },
  // { label: "Bacayawan", mun_code: "103514" },
  // { label: "Balabacun", mun_code: "103514" },
  // { label: "Balintad", mun_code: "103514" },
  // { label: "Kadayonan", mun_code: "103514" },
  // { label: "Dalama", mun_code: "103514" },
  // { label: "Lindongan", mun_code: "103514" },
  // { label: "Lingco-an", mun_code: "103514" },
  // { label: "Lininding", mun_code: "103514" },
  // { label: "Lumba-Bayabao", mun_code: "103514" },
  // { label: "Madaya", mun_code: "103514" },
  // { label: "Maganding", mun_code: "103514" },
  // { label: "Matampay", mun_code: "103514" },
  // { label: "Old Poblacion", mun_code: "103514" },
  // { label: "North Cadulawan", mun_code: "103514" },
  // { label: "Panggao", mun_code: "103514" },
  // { label: "Pantao", mun_code: "103514" },
  // { label: "Pantao-A-Munai", mun_code: "103514" },
  // { label: "Pantaon", mun_code: "103514" },
  // { label: "Pindolonan", mun_code: "103514" },
  // { label: "Punong", mun_code: "103514" },
  // { label: "Ramain", mun_code: "103514" },
  // { label: "Sandigamunai", mun_code: "103514" },
  // { label: "Tagoranao", mun_code: "103514" },
  // { label: "Tambo", mun_code: "103514" },
  // { label: "Tamparan (Mandaya)", mun_code: "103514" },
  // { label: "Taporog", mun_code: "103514" },
  // { label: "Abaga", mun_code: "103515" },
  // { label: "Bangco", mun_code: "103515" },
  // { label: "Canibongan", mun_code: "103515" },
  // { label: "Karcum", mun_code: "103515" },
  // { label: "Dimayon", mun_code: "103515" },
  // { label: "Inayawan", mun_code: "103515" },
  // { label: "Kaludan", mun_code: "103515" },
  // { label: "Katubuan", mun_code: "103515" },
  // { label: "Cabasaran (Laya)", mun_code: "103515" },
  // { label: "Liangan", mun_code: "103515" },
  // { label: "Lupitan", mun_code: "103515" },
  // { label: "Mangan", mun_code: "103515" },
  // { label: "Malaig", mun_code: "103515" },
  // { label: "Masibay", mun_code: "103515" },
  // { label: "Poblacion (Nunungan Proper)", mun_code: "103515" },
  // { label: "Notongan", mun_code: "103515" },
  // { label: "Petadun", mun_code: "103515" },
  // { label: "Panganapan", mun_code: "103515" },
  // { label: "Pantar", mun_code: "103515" },
  // { label: "Paridi", mun_code: "103515" },
  // { label: "Rarab", mun_code: "103515" },
  // { label: "Raraban", mun_code: "103515" },
  // { label: "Rebucon", mun_code: "103515" },
  // { label: "Songgod", mun_code: "103515" },
  // { label: "Taraka", mun_code: "103515" },
  // { label: "Aloon", mun_code: "103516" },
  // { label: "Banday", mun_code: "103516" },
  // { label: "Bobonga Pantao Ragat", mun_code: "103516" },
  // { label: "Bobonga Radapan", mun_code: "103516" },
  // { label: "Cabasagan", mun_code: "103516" },
  // { label: "Calawe", mun_code: "103516" },
  // { label: "Culubun", mun_code: "103516" },
  // { label: "Dilimbayan", mun_code: "103516" },
  // { label: "Dimayon", mun_code: "103516" },
  // { label: "Poblacion East", mun_code: "103516" },
  // { label: "Lomidong", mun_code: "103516" },
  // { label: "Madaya", mun_code: "103516" },
  // { label: "Maliwanag", mun_code: "103516" },
  // { label: "Matampay", mun_code: "103516" },
  // { label: "Natangcopan", mun_code: "103516" },
  // { label: "Pansor", mun_code: "103516" },
  // { label: "Pantao Marug", mun_code: "103516" },
  // { label: "Tangcal", mun_code: "103516" },
  // { label: "Tongcopan", mun_code: "103516" },
  // { label: "Poblacion West", mun_code: "103516" },
  // { label: "Alowin", mun_code: "103517" },
  // { label: "Bubong-Dinaig", mun_code: "103517" },
  // { label: "Caromatan", mun_code: "103517" },
  // { label: "Daramba", mun_code: "103517" },
  // { label: "Dinaig", mun_code: "103517" },
  // { label: "Cabasaran", mun_code: "103517" },
  // { label: "Kablangan", mun_code: "103517" },
  // { label: "Cadayonan", mun_code: "103517" },
  // { label: "Linindingan", mun_code: "103517" },
  // { label: "Lumbatan", mun_code: "103517" },
  // { label: "Lupitan", mun_code: "103517" },
  // { label: "Madamba", mun_code: "103517" },
  // { label: "Madaya", mun_code: "103517" },
  // { label: "Maliwanag", mun_code: "103517" },
  // { label: "Nunang", mun_code: "103517" },
  // { label: "Nunungan", mun_code: "103517" },
  // { label: "Pantao Raya", mun_code: "103517" },
  // { label: "Pantaon", mun_code: "103517" },
  // { label: "Pened", mun_code: "103517" },
  // { label: "Piangamangaan", mun_code: "103517" },
  // { label: "Pendolonan", mun_code: "103517" },
  // { label: "Poblacion (Lumbacaingud)", mun_code: "103517" },
  // { label: "Sulo", mun_code: "103517" },
  // { label: "Tagoranao", mun_code: "103517" },
  // { label: "Tangclao", mun_code: "103517" },
  // { label: "Timbangalan", mun_code: "103517" },
  // { label: "Barandia", mun_code: "103518" },
  // { label: "Bulacon", mun_code: "103518" },
  // { label: "Buntong", mun_code: "103518" },
  // { label: "Calimodan", mun_code: "103518" },
  // { label: "Camp III", mun_code: "103518" },
  // { label: "Curva-Miagao", mun_code: "103518" },
  // { label: "Daligdigan", mun_code: "103518" },
  // { label: "Kilala", mun_code: "103518" },
  // { label: "Mabatao", mun_code: "103518" },
  // { label: "Madaya", mun_code: "103518" },
  // { label: "Mamaanon", mun_code: "103518" },
  // { label: "Mapantao", mun_code: "103518" },
  // { label: "Mindalano", mun_code: "103518" },
  // { label: "Padianan", mun_code: "103518" },
  // { label: "Pagalongan", mun_code: "103518" },
  // { label: "Pagayawan", mun_code: "103518" },
  // { label: "Panaliwad-on", mun_code: "103518" },
  // { label: "Pangantapan", mun_code: "103518" },
  // { label: "Pansor", mun_code: "103518" },
  // { label: "Patidon", mun_code: "103518" },
  // { label: "Pawak", mun_code: "103518" },
  // { label: "Poblacion", mun_code: "103518" },
  // { label: "Saumay", mun_code: "103518" },
  // { label: "Sudlon", mun_code: "103518" },
  // { label: "Inasagan", mun_code: "103518" },
  // { label: "Baning", mun_code: "103519" },
  // { label: "Buriasan (Pob.)", mun_code: "103519" },
  // { label: "Dansalan", mun_code: "103519" },
  // { label: "Gamal", mun_code: "103519" },
  // { label: "Inudaran I", mun_code: "103519" },
  // { label: "Inudaran II", mun_code: "103519" },
  // { label: "Karkum", mun_code: "103519" },
  // { label: "Katipunan", mun_code: "103519" },
  // { label: "Mabugnao", mun_code: "103519" },
  // { label: "Maito Salug", mun_code: "103519" },
  // { label: "Mala Salug", mun_code: "103519" },
  // { label: "Mama-anon", mun_code: "103519" },
  // { label: "Mapurog", mun_code: "103519" },
  // { label: "Pancilan", mun_code: "103519" },
  // { label: "Panoloon", mun_code: "103519" },
  // { label: "Pili", mun_code: "103519" },
  // { label: "Sapad", mun_code: "103519" },
  // { label: "Dalamas", mun_code: "103520" },
  // { label: "Darimbang", mun_code: "103520" },
  // { label: "Dimayon", mun_code: "103520" },
  // { label: "Inagongan", mun_code: "103520" },
  // { label: "Kiazar (Pob.)", mun_code: "103520" },
  // { label: "Malimbato", mun_code: "103520" },
  // { label: "Panalawan", mun_code: "103520" },
  // { label: "Small Banisilon", mun_code: "103521" },
  // { label: "Bayabao", mun_code: "103521" },
  // { label: "Berwar", mun_code: "103521" },
  // { label: "Big Banisilon", mun_code: "103521" },
  // { label: "Big Meladoc", mun_code: "103521" },
  // { label: "Bubong", mun_code: "103521" },
  // { label: "Lamaosa", mun_code: "103521" },
  // { label: "Linao", mun_code: "103521" },
  // { label: "Lindongan", mun_code: "103521" },
  // { label: "Lingco-an", mun_code: "103521" },
  // { label: "Small Meladoc", mun_code: "103521" },
  // { label: "Papan", mun_code: "103521" },
  // { label: "Pelingkingan", mun_code: "103521" },
  // { label: "Poblacion", mun_code: "103521" },
  // { label: "Poona Kapatagan", mun_code: "103521" },
  // { label: "Punod", mun_code: "103521" },
  // { label: "Somiorang", mun_code: "103521" },
  // { label: "Tangcal Proper", mun_code: "103521" },
  // { label: "Barakanas", mun_code: "103522" },
  // { label: "Baris (Lumangculob)", mun_code: "103522" },
  // { label: "Bualan", mun_code: "103522" },
  // { label: "Bulod", mun_code: "103522" },
  // { label: "Camp V", mun_code: "103522" },
  // { label: "Candis", mun_code: "103522" },
  // { label: "Caniogan", mun_code: "103522" },
  // { label: "Dalama", mun_code: "103522" },
  // { label: "Kakai Renabor", mun_code: "103522" },
  // { label: "Kalilangan", mun_code: "103522" },
  // { label: "Licapao", mun_code: "103522" },
  // { label: "Malingao", mun_code: "103522" },
  // { label: "Palao", mun_code: "103522" },
  // { label: "Patudan", mun_code: "103522" },
  // { label: "Pigcarangan", mun_code: "103522" },
  // { label: "Pinpin", mun_code: "103522" },
  // { label: "Poblacion", mun_code: "103522" },
  // { label: "Pualas", mun_code: "103522" },
  // { label: "San Antonio", mun_code: "103522" },
  // { label: "Taden", mun_code: "103522" },
  // { label: "Tangueguiron", mun_code: "103522" },
  // { label: "Taguranao", mun_code: "103522" },
  // { label: "Tubaran", mun_code: "103522" },
  // { label: "Bangcal", mun_code: "103523" },
  // { label: "Bubong Madaya", mun_code: "103523" },
  // { label: "Bowi", mun_code: "103523" },
  // { label: "Cabasaran", mun_code: "103523" },
  // { label: "Cadayonan", mun_code: "103523" },
  // { label: "Campong", mun_code: "103523" },
  // { label: "Dibarosan", mun_code: "103523" },
  // { label: "Kalanganan East", mun_code: "103523" },
  // { label: "Kalanganan Lower", mun_code: "103523" },
  // { label: "Kalilangan", mun_code: "103523" },
  // { label: "Pantao-Marug", mun_code: "103523" },
  // { label: "Pantao-Ranao", mun_code: "103523" },
  // { label: "Pantar East", mun_code: "103523" },
  // { label: "Poblacion", mun_code: "103523" },
  // { label: "Pitubo", mun_code: "103523" },
  // { label: "Poona-Punod", mun_code: "103523" },
  // { label: "Punod", mun_code: "103523" },
  // { label: "Sundiga-Punod", mun_code: "103523" },
  // { label: "Tawanan", mun_code: "103523" },
  // { label: "West Pantar", mun_code: "103523" },
  // { label: "Lumba-Punod", mun_code: "103523" },
  // { label: "Balintonga", mun_code: "104201" },
  // { label: "Banisilon", mun_code: "104201" },
  // { label: "Burgos", mun_code: "104201" },
  // { label: "Calube", mun_code: "104201" },
  // { label: "Caputol", mun_code: "104201" },
  // { label: "Casusan", mun_code: "104201" },
  // { label: "Conat", mun_code: "104201" },
  // { label: "Culpan", mun_code: "104201" },
  // { label: "Dalisay", mun_code: "104201" },
  // { label: "Dullan", mun_code: "104201" },
  // { label: "Ibabao", mun_code: "104201" },
  // { label: "Tubod (Juan Bacayo)", mun_code: "104201" },
  // { label: "Labo", mun_code: "104201" },
  // { label: "Lawa-an", mun_code: "104201" },
  // { label: "Lobogon", mun_code: "104201" },
  // { label: "Lumbayao", mun_code: "104201" },
  // { label: "Makawa", mun_code: "104201" },
  // { label: "Manamong", mun_code: "104201" },
  // { label: "Matipaz", mun_code: "104201" },
  // { label: "Maular", mun_code: "104201" },
  // { label: "Mitazan", mun_code: "104201" },
  // { label: "Mohon", mun_code: "104201" },
  // { label: "Monterico", mun_code: "104201" },
  // { label: "Nabuna", mun_code: "104201" },
  // { label: "Palayan", mun_code: "104201" },
  // { label: "Pelong", mun_code: "104201" },
  // { label: "Ospital (Pob.)", mun_code: "104201" },
  // { label: "Roxas", mun_code: "104201" },
  // { label: "San Pedro", mun_code: "104201" },
  // { label: "Santa Ana", mun_code: "104201" },
  // { label: "Sinampongan", mun_code: "104201" },
  // { label: "Taguanao", mun_code: "104201" },
  // { label: "Tawi-tawi", mun_code: "104201" },
  // { label: "Toril", mun_code: "104201" },
  // { label: "Tuburan", mun_code: "104201" },
  // { label: "Zamora", mun_code: "104201" },
  // { label: "Macubon (Sina-ad)", mun_code: "104201" },
  // { label: "Tugaya", mun_code: "104201" },
  // { label: "Del Pilar", mun_code: "104202" },
  // { label: "Landing", mun_code: "104202" },
  // { label: "Lumipac", mun_code: "104202" },
  // { label: "Lusot", mun_code: "104202" },
  // { label: "Mabini", mun_code: "104202" },
  // { label: "Magsaysay", mun_code: "104202" },
  // { label: "Misom", mun_code: "104202" },
  // { label: "Mitacas", mun_code: "104202" },
  // { label: "Naburos", mun_code: "104202" },
  // { label: "Northern Poblacion", mun_code: "104202" },
  // { label: "Punta Miray", mun_code: "104202" },
  // { label: "Punta Sulong", mun_code: "104202" },
  // { label: "Sinian", mun_code: "104202" },
  // { label: "Southern Poblacion", mun_code: "104202" },
  // { label: "Tugas", mun_code: "104202" },
  // { label: "Bag-ong Anonang", mun_code: "104203" },
  // { label: "Bagumbang", mun_code: "104203" },
  // { label: "Baybay", mun_code: "104203" },
  // { label: "Bolinsong", mun_code: "104203" },
  // { label: "Buenavista", mun_code: "104203" },
  // { label: "Buracan", mun_code: "104203" },
  // { label: "Calolot", mun_code: "104203" },
  // { label: "Dimalco", mun_code: "104203" },
  // { label: "Dullan", mun_code: "104203" },
  // { label: "Kanaokanao", mun_code: "104203" },
  // { label: "Liloan", mun_code: "104203" },
  // { label: "Linconan", mun_code: "104203" },
  // { label: "Lodiong", mun_code: "104203" },
  // { label: "Lower Usugan", mun_code: "104203" },
  // { label: "Mapurog (Migsale)", mun_code: "104203" },
  // { label: "Migpange", mun_code: "104203" },
  // { label: "Montol", mun_code: "104203" },
  // { label: "Pisa-an", mun_code: "104203" },
  // { label: "Poblacion (Centro)", mun_code: "104203" },
  // { label: "Remedios", mun_code: "104203" },
  // { label: "Rufino Lumapas", mun_code: "104203" },
  // { label: "Sibuyon", mun_code: "104203" },
  // { label: "Tangab", mun_code: "104203" },
  // { label: "Tiaman", mun_code: "104203" },
  // { label: "Tusik", mun_code: "104203" },
  // { label: "Upper Usogan", mun_code: "104203" },
  // { label: "Demetrio Fernan", mun_code: "104203" },
  // { label: "Digson", mun_code: "104203" },
  // { label: "Bonifacio", mun_code: "104204" },
  // { label: "Bunawan", mun_code: "104204" },
  // { label: "Calaran", mun_code: "104204" },
  // { label: "Dapacan Alto", mun_code: "104204" },
  // { label: "Dapacan Bajo", mun_code: "104204" },
  // { label: "Langub", mun_code: "104204" },
  // { label: "Libertad", mun_code: "104204" },
  // { label: "Magcamiguing", mun_code: "104204" },
  // { label: "Mamalad", mun_code: "104204" },
  // { label: "Mauswagon", mun_code: "104204" },
  // { label: "Northern Poblacion", mun_code: "104204" },
  // { label: "Salvador", mun_code: "104204" },
  // { label: "San Isidro (San Isidro-San Pedro)", mun_code: "104204" },
  // { label: "Siloy", mun_code: "104204" },
  // { label: "Singalat", mun_code: "104204" },
  // { label: "Solinog", mun_code: "104204" },
  // { label: "Southwestern Poblacion", mun_code: "104204" },
  // { label: "Sulipat", mun_code: "104204" },
  // { label: "Don Bernardo Nery Pob. (Tres de Mayo)", mun_code: "104204" },
  // { label: "Bernad", mun_code: "104205" },
  // { label: "Bito-on", mun_code: "104205" },
  // { label: "Cabunga-an", mun_code: "104205" },
  // { label: "Canibungan Daku", mun_code: "104205" },
  // { label: "Canibungan Putol", mun_code: "104205" },
  // { label: "Canipacan", mun_code: "104205" },
  // { label: "Dalingap", mun_code: "104205" },
  // { label: "Dela Paz", mun_code: "104205" },
  // { label: "Dolores", mun_code: "104205" },
  // { label: "Gata Daku", mun_code: "104205" },
  // { label: "Gata Diot", mun_code: "104205" },
  // { label: "Guba (Ozamis)", mun_code: "104205" },
  // { label: "Kinangay Norte", mun_code: "104205" },
  // { label: "Kinangay Sur", mun_code: "104205" },
  // { label: "Lapasan", mun_code: "104205" },
  // { label: "Lupagan", mun_code: "104205" },
  // { label: "Malibangcao", mun_code: "104205" },
  // { label: "Masabud", mun_code: "104205" },
  // { label: "Mialen", mun_code: "104205" },
  // { label: "Pan-ay", mun_code: "104205" },
  // { label: "Penacio", mun_code: "104205" },
  // { label: "Poblacion I", mun_code: "104205" },
  // { label: "Segatic Daku", mun_code: "104205" },
  // { label: "Segatic Diot", mun_code: "104205" },
  // { label: "Sebasi", mun_code: "104205" },
  // { label: "Tinacla-an", mun_code: "104205" },
  // { label: "Poblacion II", mun_code: "104205" },
  // { label: "Poblacion III", mun_code: "104205" },
  // { label: "Poblacion IV", mun_code: "104205" },
  // { label: "Bagong Nayon", mun_code: "104206" },
  // { label: "Capule", mun_code: "104206" },
  // { label: "New Casul", mun_code: "104206" },
  // { label: "Guiban", mun_code: "104206" },
  // { label: "Laya-an", mun_code: "104206" },
  // { label: "Lingatongan", mun_code: "104206" },
  // { label: "Maligubaan", mun_code: "104206" },
  // { label: "Mantukoy", mun_code: "104206" },
  // { label: "Marugang", mun_code: "104206" },
  // { label: "Poblacion", mun_code: "104206" },
  // { label: "Pogan", mun_code: "104206" },
  // { label: "Small Potongan", mun_code: "104206" },
  // { label: "Soso-on", mun_code: "104206" },
  // { label: "Upper Dapitan", mun_code: "104206" },
  // { label: "Upper Dioyo", mun_code: "104206" },
  // { label: "Upper Potongan", mun_code: "104206" },
  // { label: "Upper Salimpono", mun_code: "104206" },
  // { label: "Virayan", mun_code: "104206" },
  // { label: "Adorable", mun_code: "104207" },
  // { label: "Butuay", mun_code: "104207" },
  // { label: "Carmen", mun_code: "104207" },
  // { label: "Corrales", mun_code: "104207" },
  // { label: "Dicoloc", mun_code: "104207" },
  // { label: "Gata", mun_code: "104207" },
  // { label: "Guintomoyan", mun_code: "104207" },
  // { label: "Malibacsan", mun_code: "104207" },
  // { label: "Macabayao", mun_code: "104207" },
  // { label: "Matugas Alto", mun_code: "104207" },
  // { label: "Matugas Bajo", mun_code: "104207" },
  // { label: "Mialem", mun_code: "104207" },
  // { label: "Naga (Pob.)", mun_code: "104207" },
  // { label: "Palilan", mun_code: "104207" },
  // { label: "Nacional (Pob.)", mun_code: "104207" },
  // { label: "Rizal (Pob.)", mun_code: "104207" },
  // { label: "San Isidro", mun_code: "104207" },
  // { label: "Santa Cruz (Pob.)", mun_code: "104207" },
  // { label: "Sibaroc", mun_code: "104207" },
  // { label: "Sinara Alto", mun_code: "104207" },
  // { label: "Sinara Bajo", mun_code: "104207" },
  // { label: "Seti", mun_code: "104207" },
  // { label: "Tabo-o", mun_code: "104207" },
  // { label: "Taraka (Pob.)", mun_code: "104207" },
  // { label: "Alegria", mun_code: "104208" },
  // { label: "Bagong Silang", mun_code: "104208" },
  // { label: "Biasong", mun_code: "104208" },
  // { label: "Bonifacio", mun_code: "104208" },
  // { label: "Burgos", mun_code: "104208" },
  // { label: "Dalacon", mun_code: "104208" },
  // { label: "Dampalan", mun_code: "104208" },
  // { label: "Estante", mun_code: "104208" },
  // { label: "Jasa-an", mun_code: "104208" },
  // { label: "Katipa", mun_code: "104208" },
  // { label: "Luzaran", mun_code: "104208" },
  // { label: "Macalibre Alto", mun_code: "104208" },
  // { label: "Macalibre Bajo", mun_code: "104208" },
  // { label: "Mahayahay", mun_code: "104208" },
  // { label: "Manguehan", mun_code: "104208" },
  // { label: "Mansabay Bajo", mun_code: "104208" },
  // { label: "Molatuhan Alto", mun_code: "104208" },
  // { label: "Molatuhan Bajo", mun_code: "104208" },
  // { label: "Peniel", mun_code: "104208" },
  // { label: "Eastern Poblacion", mun_code: "104208" },
  // { label: "Puntod", mun_code: "104208" },
  // { label: "Rizal", mun_code: "104208" },
  // { label: "Sibugon", mun_code: "104208" },
  // { label: "Sibula", mun_code: "104208" },
  // { label: "Don Andres Soriano", mun_code: "104208" },
  // { label: "Mabas", mun_code: "104208" },
  // { label: "Mansabay Alto", mun_code: "104208" },
  // { label: "Western Poblacion", mun_code: "104208" },
  // { label: "Apil", mun_code: "104209" },
  // { label: "Binuangan", mun_code: "104209" },
  // { label: "Bolibol", mun_code: "104209" },
  // { label: "Buenavista", mun_code: "104209" },
  // { label: "Bunga", mun_code: "104209" },
  // { label: "Buntawan", mun_code: "104209" },
  // { label: "Burgos", mun_code: "104209" },
  // { label: "Canubay", mun_code: "104209" },
  // { label: "Clarin Settlement", mun_code: "104209" },
  // { label: "Dolipos Bajo", mun_code: "104209" },
  // { label: "Dolipos Alto", mun_code: "104209" },
  // { label: "Dulapo", mun_code: "104209" },
  // { label: "Dullan Norte", mun_code: "104209" },
  // { label: "Dullan Sur", mun_code: "104209" },
  // { label: "Lamac Lower", mun_code: "104209" },
  // { label: "Lamac Upper", mun_code: "104209" },
  // { label: "Langcangan Lower", mun_code: "104209" },
  // { label: "Langcangan Proper", mun_code: "104209" },
  // { label: "Langcangan Upper", mun_code: "104209" },
  // { label: "Layawan", mun_code: "104209" },
  // { label: "Loboc Lower", mun_code: "104209" },
  // { label: "Loboc Upper", mun_code: "104209" },
  // { label: "Rizal Lower", mun_code: "104209" },
  // { label: "Malindang", mun_code: "104209" },
  // { label: "Mialen", mun_code: "104209" },
  // { label: "Mobod", mun_code: "104209" },
  // { label: "Ciriaco C. Pastrano (Nilabo)", mun_code: "104209" },
  // { label: "Paypayan", mun_code: "104209" },
  // { label: "Pines", mun_code: "104209" },
  // { label: "Poblacion I", mun_code: "104209" },
  // { label: "Poblacion II", mun_code: "104209" },
  // { label: "San Vicente Alto", mun_code: "104209" },
  // { label: "San Vicente Bajo", mun_code: "104209" },
  // { label: "Sebucal", mun_code: "104209" },
  // { label: "Senote", mun_code: "104209" },
  // { label: "Taboc Norte", mun_code: "104209" },
  // { label: "Taboc Sur", mun_code: "104209" },
  // { label: "Talairon", mun_code: "104209" },
  // { label: "Talic", mun_code: "104209" },
  // { label: "Toliyok", mun_code: "104209" },
  // { label: "Tipan", mun_code: "104209" },
  // { label: "Tuyabang Alto", mun_code: "104209" },
  // { label: "Tuyabang Bajo", mun_code: "104209" },
  // { label: "Tuyabang Proper", mun_code: "104209" },
  // { label: "Rizal Upper", mun_code: "104209" },
  // { label: "Victoria", mun_code: "104209" },
  // { label: "Villaflor", mun_code: "104209" },
  // { label: "Aguada (Pob.)", mun_code: "104210" },
  // { label: "Banadero (Pob.)", mun_code: "104210" },
  // { label: "Bacolod", mun_code: "104210" },
  // { label: "Bagakay", mun_code: "104210" },
  // { label: "Balintawak", mun_code: "104210" },
  // { label: "Baybay Santa Cruz", mun_code: "104210" },
  // { label: "Baybay Triunfo", mun_code: "104210" },
  // { label: "Bongbong", mun_code: "104210" },
  // { label: "Calabayan", mun_code: "104210" },
  // { label: "Capucao C.", mun_code: "104210" },
  // { label: "Capucao P.", mun_code: "104210" },
  // { label: "Carangan", mun_code: "104210" },
  // { label: "Catadman-Manabay", mun_code: "104210" },
  // { label: "Cavinte", mun_code: "104210" },
  // { label: "Cogon", mun_code: "104210" },
  // { label: "Dalapang", mun_code: "104210" },
  // { label: "Diguan", mun_code: "104210" },
  // { label: "Dimaluna", mun_code: "104210" },
  // { label: "Embargo", mun_code: "104210" },
  // { label: "Gala", mun_code: "104210" },
  // { label: "Gango", mun_code: "104210" },
  // { label: "Gotokan Daku", mun_code: "104210" },
  // { label: "Gotokan Diot", mun_code: "104210" },
  // { label: "Guimad", mun_code: "104210" },
  // { label: "Guingona", mun_code: "104210" },
  // { label: "Kinuman Norte", mun_code: "104210" },
  // { label: "Kinuman Sur", mun_code: "104210" },
  // { label: "Labinay", mun_code: "104210" },
  // { label: "Labo", mun_code: "104210" },
  // { label: "Lam-an", mun_code: "104210" },
  // { label: "Liposong", mun_code: "104210" },
  // { label: "Litapan", mun_code: "104210" },
  // { label: "Malaubang", mun_code: "104210" },
  // { label: "Manaka", mun_code: "104210" },
  // { label: "Maningcol", mun_code: "104210" },
  // { label: "Mentering", mun_code: "104210" },
  // { label: "Carmen (Misamis Annex)", mun_code: "104210" },
  // { label: "Molicay", mun_code: "104210" },
  // { label: "Stimson Abordo (Montol)", mun_code: "104210" },
  // { label: "Pantaon", mun_code: "104210" },
  // { label: "Pulot", mun_code: "104210" },
  // { label: "San Antonio", mun_code: "104210" },
  // { label: "Baybay San Roque", mun_code: "104210" },
  // { label: "Sangay Daku", mun_code: "104210" },
  // { label: "Sangay Diot", mun_code: "104210" },
  // { label: "Sinuza", mun_code: "104210" },
  // { label: "Tabid", mun_code: "104210" },
  // { label: "Tinago", mun_code: "104210" },
  // { label: "Trigos", mun_code: "104210" },
  // { label: "50th District (Pob.)", mun_code: "104210" },
  // { label: "Baga", mun_code: "104211" },
  // { label: "Bangko", mun_code: "104211" },
  // { label: "Camanucan", mun_code: "104211" },
  // { label: "Dela Paz", mun_code: "104211" },
  // { label: "Lutao", mun_code: "104211" },
  // { label: "Magsaysay", mun_code: "104211" },
  // { label: "Map-an", mun_code: "104211" },
  // { label: "Mohon", mun_code: "104211" },
  // { label: "Poblacion", mun_code: "104211" },
  // { label: "Punta", mun_code: "104211" },
  // { label: "Salimpuno", mun_code: "104211" },
  // { label: "San Andres", mun_code: "104211" },
  // { label: "San Juan", mun_code: "104211" },
  // { label: "San Roque", mun_code: "104211" },
  // { label: "Sumasap", mun_code: "104211" },
  // { label: "Villalin", mun_code: "104211" },
  // { label: "Agunod", mun_code: "104212" },
  // { label: "Bato", mun_code: "104212" },
  // { label: "Buena Voluntad", mun_code: "104212" },
  // { label: "Calaca-an", mun_code: "104212" },
  // { label: "Cartagena Proper", mun_code: "104212" },
  // { label: "Catarman", mun_code: "104212" },
  // { label: "Cebulin", mun_code: "104212" },
  // { label: "Clarin", mun_code: "104212" },
  // { label: "Danao", mun_code: "104212" },
  // { label: "Deboloc", mun_code: "104212" },
  // { label: "Divisoria", mun_code: "104212" },
  // { label: "Eastern Looc", mun_code: "104212" },
  // { label: "Ilisan", mun_code: "104212" },
  // { label: "Katipunan", mun_code: "104212" },
  // { label: "Kauswagan", mun_code: "104212" },
  // { label: "Lao Proper", mun_code: "104212" },
  // { label: "Lao Santa Cruz", mun_code: "104212" },
  // { label: "Looc Proper", mun_code: "104212" },
  // { label: "Mamanga Daku", mun_code: "104212" },
  // { label: "Mamanga Gamay", mun_code: "104212" },
  // { label: "Mangidkid", mun_code: "104212" },
  // { label: "New Cartagena", mun_code: "104212" },
  // { label: "New Look", mun_code: "104212" },
  // { label: "Northern Poblacion", mun_code: "104212" },
  // { label: "Panalsalan", mun_code: "104212" },
  // { label: "Puntod", mun_code: "104212" },
  // { label: "Quirino", mun_code: "104212" },
  // { label: "Santa Cruz", mun_code: "104212" },
  // { label: "Southern Looc", mun_code: "104212" },
  // { label: "Southern Poblacion", mun_code: "104212" },
  // { label: "Tipolo", mun_code: "104212" },
  // { label: "Unidos", mun_code: "104212" },
  // { label: "Usocan", mun_code: "104212" },
  // { label: "Bautista", mun_code: "104213" },
  // { label: "Bitibut", mun_code: "104213" },
  // { label: "Boundary", mun_code: "104213" },
  // { label: "Caluya", mun_code: "104213" },
  // { label: "Capundag", mun_code: "104213" },
  // { label: "Casul", mun_code: "104213" },
  // { label: "Dasa", mun_code: "104213" },
  // { label: "Dioyo", mun_code: "104213" },
  // { label: "Guinabot", mun_code: "104213" },
  // { label: "Libertad", mun_code: "104213" },
  // { label: "Locus", mun_code: "104213" },
  // { label: "Manla", mun_code: "104213" },
  // { label: "Masubong", mun_code: "104213" },
  // { label: "Agapito Yap, Sr. (Napilan)", mun_code: "104213" },
  // { label: "Poblacion", mun_code: "104213" },
  // { label: "Salimpuno", mun_code: "104213" },
  // { label: "San Agustin", mun_code: "104213" },
  // { label: "Sinaad", mun_code: "104213" },
  // { label: "Sipac", mun_code: "104213" },
  // { label: "Sixto Velez, Sr.", mun_code: "104213" },
  // { label: "Upper Bautista", mun_code: "104213" },
  // { label: "Ventura", mun_code: "104213" },
  // { label: "Medallo", mun_code: "104213" },
  // { label: "Dalumpinas", mun_code: "104213" },
  // { label: "Disoy", mun_code: "104213" },
  // { label: "El Paraiso", mun_code: "104213" },
  // { label: "Macabibo", mun_code: "104213" },
  // { label: "Sapang Ama", mun_code: "104213" },
  // { label: "Cagay-anon", mun_code: "104214" },
  // { label: "Camanse", mun_code: "104214" },
  // { label: "Colupan Alto", mun_code: "104214" },
  // { label: "Colupan Bajo", mun_code: "104214" },
  // { label: "Dinas", mun_code: "104214" },
  // { label: "Estrella", mun_code: "104214" },
  // { label: "Katipunan", mun_code: "104214" },
  // { label: "Libertad Alto", mun_code: "104214" },
  // { label: "Libertad Bajo", mun_code: "104214" },
  // { label: "Poblacion", mun_code: "104214" },
  // { label: "San Isidro Alto", mun_code: "104214" },
  // { label: "San Isidro Bajo", mun_code: "104214" },
  // { label: "San Vicente", mun_code: "104214" },
  // { label: "Sinonoc", mun_code: "104214" },
  // { label: "San Lorenzo Ruiz (Sungan)", mun_code: "104214" },
  // { label: "Tipan", mun_code: "104214" },
  // { label: "Santa Maria (Baga)", mun_code: "104215" },
  // { label: "Balatacan", mun_code: "104215" },
  // { label: "Banglay", mun_code: "104215" },
  // { label: "Mantic", mun_code: "104215" },
  // { label: "Migcanaway", mun_code: "104215" },
  // { label: "Bintana", mun_code: "104215" },
  // { label: "Bocator", mun_code: "104215" },
  // { label: "Bongabong", mun_code: "104215" },
  // { label: "Caniangan", mun_code: "104215" },
  // { label: "Capalaran", mun_code: "104215" },
  // { label: "Catagan", mun_code: "104215" },
  // { label: "Barangay I - City Hall (Pob.)", mun_code: "104215" },
  // { label: "Barangay II - Marilou Annex (Pob.)", mun_code: "104215" },
  // { label: "Barangay IV - St. Michael (Pob.)", mun_code: "104215" },
  // { label: "Isidro D. Tan (Dimaloc-oc)", mun_code: "104215" },
  // { label: "Garang", mun_code: "104215" },
  // { label: "Guinabot", mun_code: "104215" },
  // { label: "Guinalaban", mun_code: "104215" },
  // { label: "Kauswagan", mun_code: "104215" },
  // { label: "Kimat", mun_code: "104215" },
  // { label: "Labuyo", mun_code: "104215" },
  // { label: "Lorenzo Tan", mun_code: "104215" },
  // { label: "Barangay VI - Lower Polao (Pob.)", mun_code: "104215" },
  // { label: "Lumban", mun_code: "104215" },
  // { label: "Maloro", mun_code: "104215" },
  // { label: "Barangay V - Malubog (Pob.)", mun_code: "104215" },
  // { label: "Manga", mun_code: "104215" },
  // { label: "Maquilao", mun_code: "104215" },
  // { label: "Barangay III- Market Kalubian (Pob.)", mun_code: "104215" },
  // { label: "Minsubong", mun_code: "104215" },
  // { label: "Owayan", mun_code: "104215" },
  // { label: "Paiton", mun_code: "104215" },
  // { label: "Panalsalan", mun_code: "104215" },
  // { label: "Pangabuan", mun_code: "104215" },
  // { label: "Prenza", mun_code: "104215" },
  // { label: "Salimpuno", mun_code: "104215" },
  // { label: "San Antonio", mun_code: "104215" },
  // { label: "San Apolinario", mun_code: "104215" },
  // { label: "San Vicente", mun_code: "104215" },
  // { label: "Santa Cruz", mun_code: "104215" },
  // { label: "Silangit", mun_code: "104215" },
  // { label: "Simasay", mun_code: "104215" },
  // { label: "Sumirap", mun_code: "104215" },
  // { label: "Taguite", mun_code: "104215" },
  // { label: "Tituron", mun_code: "104215" },
  // { label: "Barangay VII - Upper Polao (Pob.)", mun_code: "104215" },
  // { label: "Villaba", mun_code: "104215" },
  // { label: "Silanga", mun_code: "104215" },
  // { label: "Aquino (Marcos)", mun_code: "104215" },
  // { label: "Baluk", mun_code: "104215" },
  // { label: "Huyohoy", mun_code: "104215" },
  // { label: "Matugnaw", mun_code: "104215" },
  // { label: "Sicot", mun_code: "104215" },
  // { label: "Tugas", mun_code: "104215" },
  // { label: "Balon", mun_code: "104216" },
  // { label: "Barra", mun_code: "104216" },
  // { label: "Basirang", mun_code: "104216" },
  // { label: "Bongabong", mun_code: "104216" },
  // { label: "Buenavista", mun_code: "104216" },
  // { label: "Cabol-anonan", mun_code: "104216" },
  // { label: "Cahayag", mun_code: "104216" },
  // { label: "Camating", mun_code: "104216" },
  // { label: "Canibungan Proper", mun_code: "104216" },
  // { label: "Casilak San Agustin", mun_code: "104216" },
  // { label: "Centro Hulpa (Pob.)", mun_code: "104216" },
  // { label: "Centro Napu (Pob.)", mun_code: "104216" },
  // { label: "Centro Upper (Pob.)", mun_code: "104216" },
  // { label: "Calambutan Bajo", mun_code: "104216" },
  // { label: "Calambutan Settlement", mun_code: "104216" },
  // { label: "Duanguican", mun_code: "104216" },
  // { label: "Gala", mun_code: "104216" },
  // { label: "Gumbil", mun_code: "104216" },
  // { label: "Locso-on", mun_code: "104216" },
  // { label: "Maikay", mun_code: "104216" },
  // { label: "Maribojoc", mun_code: "104216" },
  // { label: "Mitugas", mun_code: "104216" },
  // { label: "Nailon", mun_code: "104216" },
  // { label: "Namut", mun_code: "104216" },
  // { label: "Napurog", mun_code: "104216" },
  // { label: "Pan-ay Diot", mun_code: "104216" },
  // { label: "San Nicolas", mun_code: "104216" },
  // { label: "Sebac", mun_code: "104216" },
  // { label: "Silongon", mun_code: "104216" },
  // { label: "Sinuza", mun_code: "104216" },
  // { label: "Taguima", mun_code: "104216" },
  // { label: "Tigdok", mun_code: "104216" },
  // { label: "Yahong", mun_code: "104216" },
  // { label: "Bagong Clarin", mun_code: "104217" },
  // { label: "Gandawan", mun_code: "104217" },
  // { label: "Lake Duminagat", mun_code: "104217" },
  // { label: "Lalud", mun_code: "104217" },
  // { label: "Lampasan", mun_code: "104217" },
  // { label: "Liboron", mun_code: "104217" },
  // { label: "Maramara", mun_code: "104217" },
  // { label: "Napangan", mun_code: "104217" },
  // { label: "Nueva Vista (Masawan)", mun_code: "104217" },
  // { label: "Petianan", mun_code: "104217" },
  // { label: "Tuno", mun_code: "104217" },
  // { label: "Baybay", mun_code: "104301" },
  // { label: "Benigwayan", mun_code: "104301" },
  // { label: "Calatcat", mun_code: "104301" },
  // { label: "Lagtang", mun_code: "104301" },
  // { label: "Lanao", mun_code: "104301" },
  // { label: "Loguilo", mun_code: "104301" },
  // { label: "Lourdes", mun_code: "104301" },
  // { label: "Lumbo", mun_code: "104301" },
  // { label: "Molocboloc", mun_code: "104301" },
  // { label: "Poblacion", mun_code: "104301" },
  // { label: "Sampatulog", mun_code: "104301" },
  // { label: "Sungay", mun_code: "104301" },
  // { label: "Talaba", mun_code: "104301" },
  // { label: "Taparak", mun_code: "104301" },
  // { label: "Tugasnon", mun_code: "104301" },
  // { label: "Tula", mun_code: "104301" },
  // { label: "Balagnan", mun_code: "104302" },
  // { label: "Baliwagan", mun_code: "104302" },
  // { label: "San Francisco", mun_code: "104302" },
  // { label: "Binitinan", mun_code: "104302" },
  // { label: "Blanco", mun_code: "104302" },
  // { label: "Calawag", mun_code: "104302" },
  // { label: "Camuayan", mun_code: "104302" },
  // { label: "Cogon", mun_code: "104302" },
  // { label: "Dansuli", mun_code: "104302" },
  // { label: "Dumarait", mun_code: "104302" },
  // { label: "Hermano", mun_code: "104302" },
  // { label: "Kibanban", mun_code: "104302" },
  // { label: "Linabu", mun_code: "104302" },
  // { label: "Linggangao", mun_code: "104302" },
  // { label: "Mambayaan", mun_code: "104302" },
  // { label: "Mandangoa", mun_code: "104302" },
  // { label: "Napaliran", mun_code: "104302" },
  // { label: "Barangay 1 (Pob.)", mun_code: "104302" },
  // { label: "Barangay 2 (Pob.)", mun_code: "104302" },
  // { label: "Barangay 3 (Pob.)", mun_code: "104302" },
  // { label: "Barangay 4 (Pob.)", mun_code: "104302" },
  // { label: "Barangay 5 (Pob.)", mun_code: "104302" },
  // { label: "Barangay 6 (Pob.)", mun_code: "104302" },
  // { label: "Quezon", mun_code: "104302" },
  // { label: "Rosario", mun_code: "104302" },
  // { label: "Samay", mun_code: "104302" },
  // { label: "San Isidro", mun_code: "104302" },
  // { label: "San Juan", mun_code: "104302" },
  // { label: "Talusan", mun_code: "104302" },
  // { label: "Waterfall", mun_code: "104302" },
  // { label: "Dahilig", mun_code: "104303" },
  // { label: "Baukbauk Pob. (G.E. Antonino)", mun_code: "104303" },
  // { label: "Kabangasan", mun_code: "104303" },
  // { label: "Kabulakan", mun_code: "104303" },
  // { label: "Kauswagan", mun_code: "104303" },
  // { label: "Lapinig (Pob.)", mun_code: "104303" },
  // { label: "Mantangale", mun_code: "104303" },
  // { label: "Mapua", mun_code: "104303" },
  // { label: "San Alonzo", mun_code: "104303" },
  // { label: "Dampias", mun_code: "104304" },
  // { label: "Kitamban", mun_code: "104304" },
  // { label: "Kitambis", mun_code: "104304" },
  // { label: "Mabini", mun_code: "104304" },
  // { label: "Mosangot", mun_code: "104304" },
  // { label: "Nabataan", mun_code: "104304" },
  // { label: "Poblacion", mun_code: "104304" },
  // { label: "Valdeconcha", mun_code: "104304" },
  // { label: "Agusan", mun_code: "104305" },
  // { label: "Baikingon", mun_code: "104305" },
  // { label: "Bulua", mun_code: "104305" },
  // { label: "Balubal", mun_code: "104305" },
  // { label: "Balulang", mun_code: "104305" },
  // { label: "Barangay 10 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 11 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 12 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 13 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 14 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 15 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 16 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 17 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 18 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 19 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 2 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 21 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 22 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 23 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 24 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 26 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 27 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 28 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 3 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 30 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 32 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 33 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 34 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 38 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 39 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 4 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 40 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 6 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 8 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 9 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 5 (Pob.)", mun_code: "104305" },
  // { label: "Bayabas", mun_code: "104305" },
  // { label: "Bayanga", mun_code: "104305" },
  // { label: "Besigan", mun_code: "104305" },
  // { label: "Bonbon", mun_code: "104305" },
  // { label: "Bugo", mun_code: "104305" },
  // { label: "Camaman-an", mun_code: "104305" },
  // { label: "Canito-an", mun_code: "104305" },
  // { label: "Carmen", mun_code: "104305" },
  // { label: "Consolacion", mun_code: "104305" },
  // { label: "Cugman", mun_code: "104305" },
  // { label: "Dansolihon", mun_code: "104305" },
  // { label: "F. S. Catanico", mun_code: "104305" },
  // { label: "Gusa", mun_code: "104305" },
  // { label: "Indahag", mun_code: "104305" },
  // { label: "Iponan", mun_code: "104305" },
  // { label: "Kauswagan", mun_code: "104305" },
  // { label: "Lapasan", mun_code: "104305" },
  // { label: "Lumbia", mun_code: "104305" },
  // { label: "Macabalan", mun_code: "104305" },
  // { label: "Macasandig", mun_code: "104305" },
  // { label: "Mambuaya", mun_code: "104305" },
  // { label: "Nazareth", mun_code: "104305" },
  // { label: "Pagalungan", mun_code: "104305" },
  // { label: "Pagatpat", mun_code: "104305" },
  // { label: "Patag", mun_code: "104305" },
  // { label: "Pigsag-an", mun_code: "104305" },
  // { label: "Puerto", mun_code: "104305" },
  // { label: "Puntod", mun_code: "104305" },
  // { label: "San Simon", mun_code: "104305" },
  // { label: "Tablon", mun_code: "104305" },
  // { label: "Taglimao", mun_code: "104305" },
  // { label: "Tagpangi", mun_code: "104305" },
  // { label: "Tignapoloan", mun_code: "104305" },
  // { label: "Tuburan", mun_code: "104305" },
  // { label: "Tumpagon", mun_code: "104305" },
  // { label: "Barangay 1 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 7 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 20 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 25 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 29 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 31 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 35 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 36 (Pob.)", mun_code: "104305" },
  // { label: "Barangay 37 (Pob.)", mun_code: "104305" },
  // { label: "Ani-e", mun_code: "104306" },
  // { label: "Aposkahoy", mun_code: "104306" },
  // { label: "Bulahan", mun_code: "104306" },
  // { label: "Cabacungan", mun_code: "104306" },
  // { label: "Pelaez (Don Gregorio Pelaez)", mun_code: "104306" },
  // { label: "Gumaod", mun_code: "104306" },
  // { label: "Hinaplanan", mun_code: "104306" },
  // { label: "Kalawitan", mun_code: "104306" },
  // { label: "Lanise", mun_code: "104306" },
  // { label: "Luna", mun_code: "104306" },
  // { label: "Madaguing", mun_code: "104306" },
  // { label: "Malagana", mun_code: "104306" },
  // { label: "Minalwang", mun_code: "104306" },
  // { label: "Mat-i", mun_code: "104306" },
  // { label: "Panampawan", mun_code: "104306" },
  // { label: "Pambugas", mun_code: "104306" },
  // { label: "Patrocenio", mun_code: "104306" },
  // { label: "Plaridel", mun_code: "104306" },
  // { label: "Poblacion", mun_code: "104306" },
  // { label: "Punong", mun_code: "104306" },
  // { label: "Rizal", mun_code: "104306" },
  // { label: "Santa Cruz", mun_code: "104306" },
  // { label: "Tamboboan", mun_code: "104306" },
  // { label: "Tipolohon", mun_code: "104306" },
  // { label: "Amoros", mun_code: "104307" },
  // { label: "Bolisong", mun_code: "104307" },
  // { label: "Bolobolo", mun_code: "104307" },
  // { label: "Calongonan", mun_code: "104307" },
  // { label: "Cogon", mun_code: "104307" },
  // { label: "Himaya", mun_code: "104307" },
  // { label: "Hinigdaan", mun_code: "104307" },
  // { label: "Kalabaylabay", mun_code: "104307" },
  // { label: "Molugan", mun_code: "104307" },
  // { label: "Poblacion", mun_code: "104307" },
  // { label: "Kibonbon", mun_code: "104307" },
  // { label: "Sambulawan", mun_code: "104307" },
  // { label: "Sinaloc", mun_code: "104307" },
  // { label: "Taytay", mun_code: "104307" },
  // { label: "Ulaliman", mun_code: "104307" },
  // { label: "Agay-ayan", mun_code: "104308" },
  // { label: "Alagatan", mun_code: "104308" },
  // { label: "Anakan", mun_code: "104308" },
  // { label: "Bagubad", mun_code: "104308" },
  // { label: "Bakidbakid", mun_code: "104308" },
  // { label: "Bal-ason", mun_code: "104308" },
  // { label: "Bantaawan", mun_code: "104308" },
  // { label: "Binakalan", mun_code: "104308" },
  // { label: "Capitulangan", mun_code: "104308" },
  // { label: "Daan-Lungsod", mun_code: "104308" },
  // { label: "Hindangon", mun_code: "104308" },
  // { label: "Kalagonoy", mun_code: "104308" },
  // { label: "Kibuging", mun_code: "104308" },
  // { label: "Kipuntos", mun_code: "104308" },
  // { label: "Lawaan", mun_code: "104308" },
  // { label: "Lawit", mun_code: "104308" },
  // { label: "Libertad", mun_code: "104308" },
  // { label: "Libon", mun_code: "104308" },
  // { label: "Lunao", mun_code: "104308" },
  // { label: "Lunotan", mun_code: "104308" },
  // { label: "Malibud", mun_code: "104308" },
  // { label: "Malinao", mun_code: "104308" },
  // { label: "Maribucao", mun_code: "104308" },
  // { label: "Mimbuntong", mun_code: "104308" },
  // { label: "Mimbalagon", mun_code: "104308" },
  // { label: "Mimbunga", mun_code: "104308" },
  // { label: "Minsapinit", mun_code: "104308" },
  // { label: "Murallon", mun_code: "104308" },
  // { label: "Odiongan", mun_code: "104308" },
  // { label: "Pangasihan", mun_code: "104308" },
  // { label: "Pigsaluhan", mun_code: "104308" },
  // { label: "Barangay 1 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 10 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 11 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 12 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 13 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 14 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 15 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 16 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 17 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 18-A (Pob.)", mun_code: "104308" },
  // { label: "Barangay 19 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 2 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 20 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 21 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 22-A (Pob.)", mun_code: "104308" },
  // { label: "Barangay 23 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 24 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 25 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 26 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 3 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 4 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 5 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 6 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 7 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 8 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 9 (Pob.)", mun_code: "104308" },
  // { label: "Punong", mun_code: "104308" },
  // { label: "Ricoro", mun_code: "104308" },
  // { label: "Samay", mun_code: "104308" },
  // { label: "San Juan", mun_code: "104308" },
  // { label: "San Luis", mun_code: "104308" },
  // { label: "San Miguel", mun_code: "104308" },
  // { label: "Santiago", mun_code: "104308" },
  // { label: "Talisay", mun_code: "104308" },
  // { label: "Talon", mun_code: "104308" },
  // { label: "Tinabalan", mun_code: "104308" },
  // { label: "Tinulongan", mun_code: "104308" },
  // { label: "Barangay 18 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 22 (Pob.)", mun_code: "104308" },
  // { label: "Barangay 24-A (Pob.)", mun_code: "104308" },
  // { label: "Dinawehan", mun_code: "104308" },
  // { label: "Eureka", mun_code: "104308" },
  // { label: "Kalipay", mun_code: "104308" },
  // { label: "Kamanikan", mun_code: "104308" },
  // { label: "Kianlagan", mun_code: "104308" },
  // { label: "San Jose", mun_code: "104308" },
  // { label: "Sangalan", mun_code: "104308" },
  // { label: "Tagpako", mun_code: "104308" },
  // { label: "Burnay", mun_code: "104309" },
  // { label: "Carlos P. Garcia", mun_code: "104309" },
  // { label: "Cogon", mun_code: "104309" },
  // { label: "Gregorio Pelaez (Lagutay)", mun_code: "104309" },
  // { label: "Kilangit", mun_code: "104309" },
  // { label: "Matangad", mun_code: "104309" },
  // { label: "Pangayawan", mun_code: "104309" },
  // { label: "Poblacion", mun_code: "104309" },
  // { label: "Quezon", mun_code: "104309" },
  // { label: "Tala-o", mun_code: "104309" },
  // { label: "Ulab", mun_code: "104309" },
  // { label: "Aluna", mun_code: "104310" },
  // { label: "Andales", mun_code: "104310" },
  // { label: "Apas", mun_code: "104310" },
  // { label: "Calacapan", mun_code: "104310" },
  // { label: "Gimangpang", mun_code: "104310" },
  // { label: "Jampason", mun_code: "104310" },
  // { label: "Kamelon", mun_code: "104310" },
  // { label: "Kanitoan", mun_code: "104310" },
  // { label: "Oguis", mun_code: "104310" },
  // { label: "Pagahan", mun_code: "104310" },
  // { label: "Poblacion", mun_code: "104310" },
  // { label: "Pontacon", mun_code: "104310" },
  // { label: "San Pedro", mun_code: "104310" },
  // { label: "Sinalac", mun_code: "104310" },
  // { label: "Tawantawan", mun_code: "104310" },
  // { label: "Tubigan", mun_code: "104310" },
  // { label: "Aplaya", mun_code: "104311" },
  // { label: "Bobontugan", mun_code: "104311" },
  // { label: "Corrales", mun_code: "104311" },
  // { label: "Danao", mun_code: "104311" },
  // { label: "Jampason", mun_code: "104311" },
  // { label: "Kimaya", mun_code: "104311" },
  // { label: "Lower Jasaan (Pob.)", mun_code: "104311" },
  // { label: "Luz Banzon", mun_code: "104311" },
  // { label: "Natubo", mun_code: "104311" },
  // { label: "San Antonio", mun_code: "104311" },
  // { label: "San Isidro", mun_code: "104311" },
  // { label: "San Nicolas", mun_code: "104311" },
  // { label: "Solana", mun_code: "104311" },
  // { label: "Upper Jasaan (Pob.)", mun_code: "104311" },
  // { label: "I. S. Cruz", mun_code: "104311" },
  // { label: "Beray", mun_code: "104312" },
  // { label: "Bolisong", mun_code: "104312" },
  // { label: "Buko", mun_code: "104312" },
  // { label: "Kalitian", mun_code: "104312" },
  // { label: "Calubo", mun_code: "104312" },
  // { label: "Campo", mun_code: "104312" },
  // { label: "Esperanza", mun_code: "104312" },
  // { label: "Kagumahan", mun_code: "104312" },
  // { label: "Kitotok", mun_code: "104312" },
  // { label: "Panabol", mun_code: "104312" },
  // { label: "Poblacion", mun_code: "104312" },
  // { label: "Salicapawan", mun_code: "104312" },
  // { label: "Salubsob", mun_code: "104312" },
  // { label: "Suarez", mun_code: "104312" },
  // { label: "Sumalag", mun_code: "104312" },
  // { label: "Banglay", mun_code: "104313" },
  // { label: "Dampil", mun_code: "104313" },
  // { label: "Gaston", mun_code: "104313" },
  // { label: "Kabulawan", mun_code: "104313" },
  // { label: "Kauswagan", mun_code: "104313" },
  // { label: "Lumbo", mun_code: "104313" },
  // { label: "Manaol", mun_code: "104313" },
  // { label: "Poblacion", mun_code: "104313" },
  // { label: "Tabok", mun_code: "104313" },
  // { label: "Umagos", mun_code: "104313" },
  // { label: "Aromahon", mun_code: "104314" },
  // { label: "Gasi", mun_code: "104314" },
  // { label: "Kibaghot", mun_code: "104314" },
  // { label: "Lapad", mun_code: "104314" },
  // { label: "Liberty", mun_code: "104314" },
  // { label: "Mauswagon", mun_code: "104314" },
  // { label: "Moog", mun_code: "104314" },
  // { label: "Poblacion", mun_code: "104314" },
  // { label: "Sambulawan", mun_code: "104314" },
  // { label: "Sinai", mun_code: "104314" },
  // { label: "Tubajon", mun_code: "104314" },
  // { label: "Dulong", mun_code: "104315" },
  // { label: "Gimaylan", mun_code: "104315" },
  // { label: "Kimalok", mun_code: "104315" },
  // { label: "Lubluban", mun_code: "104315" },
  // { label: "Poblacion", mun_code: "104315" },
  // { label: "Retablo", mun_code: "104315" },
  // { label: "Tangcub", mun_code: "104315" },
  // { label: "Taytayan", mun_code: "104315" },
  // { label: "Aya-aya", mun_code: "104316" },
  // { label: "Betahon", mun_code: "104316" },
  // { label: "Biga", mun_code: "104316" },
  // { label: "Calangahan", mun_code: "104316" },
  // { label: "Kaluknayan", mun_code: "104316" },
  // { label: "Lower Talacogon", mun_code: "104316" },
  // { label: "Poblacion", mun_code: "104316" },
  // { label: "Upper Talacogon", mun_code: "104316" },
  // { label: "Abunda", mun_code: "104317" },
  // { label: "Artadi", mun_code: "104317" },
  // { label: "Bonifacio Aquino", mun_code: "104317" },
  // { label: "Cabalawan", mun_code: "104317" },
  // { label: "Cabantian", mun_code: "104317" },
  // { label: "Cabubuhan", mun_code: "104317" },
  // { label: "Candiis", mun_code: "104317" },
  // { label: "Consuelo", mun_code: "104317" },
  // { label: "Damayuhan", mun_code: "104317" },
  // { label: "Gumabon", mun_code: "104317" },
  // { label: "Kauswagan", mun_code: "104317" },
  // { label: "Kibungsod", mun_code: "104317" },
  // { label: "Mahayahay", mun_code: "104317" },
  // { label: "Mindulao", mun_code: "104317" },
  // { label: "Pag-asa", mun_code: "104317" },
  // { label: "Poblacion", mun_code: "104317" },
  // { label: "San Isidro", mun_code: "104317" },
  // { label: "San Vicente", mun_code: "104317" },
  // { label: "Santa Cruz", mun_code: "104317" },
  // { label: "Tibon-tibon", mun_code: "104317" },
  // { label: "Tulang (Cadena de Amor)", mun_code: "104317" },
  // { label: "Villa Felipa", mun_code: "104317" },
  // { label: "Katipunan", mun_code: "104317" },
  // { label: "Tama", mun_code: "104317" },
  // { label: "Tinaan", mun_code: "104317" },
  // { label: "Argayoso", mun_code: "104318" },
  // { label: "Balintad", mun_code: "104318" },
  // { label: "Cabalantian", mun_code: "104318" },
  // { label: "Camanga", mun_code: "104318" },
  // { label: "Digkilaan", mun_code: "104318" },
  // { label: "Mahayahay", mun_code: "104318" },
  // { label: "Pagawan", mun_code: "104318" },
  // { label: "Paniangan", mun_code: "104318" },
  // { label: "Patag", mun_code: "104318" },
  // { label: "Poblacion", mun_code: "104318" },
  // { label: "Punta Silum", mun_code: "104318" },
  // { label: "Tuod", mun_code: "104318" },
  // { label: "Upper Malubog", mun_code: "104318" },
  // { label: "Bangbang", mun_code: "104319" },
  // { label: "Bulwa", mun_code: "104319" },
  // { label: "Cabug", mun_code: "104319" },
  // { label: "Dig-aguyan", mun_code: "104319" },
  // { label: "Duka", mun_code: "104319" },
  // { label: "Gasa", mun_code: "104319" },
  // { label: "Maanas", mun_code: "104319" },
  // { label: "Mananum Bag-o", mun_code: "104319" },
  // { label: "Mananum Daan", mun_code: "104319" },
  // { label: "North Poblacion", mun_code: "104319" },
  // { label: "Pahindong", mun_code: "104319" },
  // { label: "Portulin", mun_code: "104319" },
  // { label: "San Isidro", mun_code: "104319" },
  // { label: "San Jose", mun_code: "104319" },
  // { label: "San Roque", mun_code: "104319" },
  // { label: "San Vicente", mun_code: "104319" },
  // { label: "South Poblacion", mun_code: "104319" },
  // { label: "Tambagan", mun_code: "104319" },
  // { label: "Tup-on", mun_code: "104319" },
  // { label: "Don Pedro", mun_code: "104320" },
  // { label: "Linangkayan", mun_code: "104320" },
  // { label: "Lubilan", mun_code: "104320" },
  // { label: "Mapulog", mun_code: "104320" },
  // { label: "Maputi", mun_code: "104320" },
  // { label: "Mat-i", mun_code: "104320" },
  // { label: "Patag", mun_code: "104320" },
  // { label: "Poblacion", mun_code: "104320" },
  // { label: "Tagbalogo", mun_code: "104320" },
  // { label: "Tuboran", mun_code: "104320" },
  // { label: "Awang", mun_code: "104321" },
  // { label: "Bagocboc", mun_code: "104321" },
  // { label: "Barra", mun_code: "104321" },
  // { label: "Bonbon", mun_code: "104321" },
  // { label: "Cauyonan", mun_code: "104321" },
  // { label: "Igpit", mun_code: "104321" },
  // { label: "Limonda", mun_code: "104321" },
  // { label: "Luyongbonbon", mun_code: "104321" },
  // { label: "Malanang", mun_code: "104321" },
  // { label: "Nangcaon", mun_code: "104321" },
  // { label: "Patag", mun_code: "104321" },
  // { label: "Poblacion", mun_code: "104321" },
  // { label: "Taboc", mun_code: "104321" },
  // { label: "Tingalan", mun_code: "104321" },
  // { label: "Alipuaton", mun_code: "104322" },
  // { label: "Ampenican", mun_code: "104322" },
  // { label: "Bunal", mun_code: "104322" },
  // { label: "Dinagsaan", mun_code: "104322" },
  // { label: "Guinalaban", mun_code: "104322" },
  // { label: "Ili-ilihon", mun_code: "104322" },
  // { label: "Inobulan", mun_code: "104322" },
  // { label: "Looc", mun_code: "104322" },
  // { label: "Matampa", mun_code: "104322" },
  // { label: "Membuli", mun_code: "104322" },
  // { label: "Poblacion", mun_code: "104322" },
  // { label: "Salagsag", mun_code: "104322" },
  // { label: "Salay River I", mun_code: "104322" },
  // { label: "Salay River II", mun_code: "104322" },
  // { label: "Saray", mun_code: "104322" },
  // { label: "Tinagaan", mun_code: "104322" },
  // { label: "Yungod", mun_code: "104322" },
  // { label: "Casulog", mun_code: "104322" },
  // { label: "Alicomohan", mun_code: "104323" },
  // { label: "Ampianga", mun_code: "104323" },
  // { label: "Kaulayanan", mun_code: "104323" },
  // { label: "Kidampas", mun_code: "104323" },
  // { label: "Kiraging", mun_code: "104323" },
  // { label: "Mangga", mun_code: "104323" },
  // { label: "Mimbuahan", mun_code: "104323" },
  // { label: "Poblacion", mun_code: "104323" },
  // { label: "Santa Cruz (Mabini)", mun_code: "104323" },
  // { label: "Silad", mun_code: "104323" },
  // { label: "Baluarte", mun_code: "104324" },
  // { label: "Casinglot", mun_code: "104324" },
  // { label: "Gracia", mun_code: "104324" },
  // { label: "Mohon", mun_code: "104324" },
  // { label: "Natumolan", mun_code: "104324" },
  // { label: "Poblacion", mun_code: "104324" },
  // { label: "Rosario", mun_code: "104324" },
  // { label: "Santa Ana", mun_code: "104324" },
  // { label: "Santa Cruz", mun_code: "104324" },
  // { label: "Sugbongcogon", mun_code: "104324" },
  // { label: "Bugdang", mun_code: "104325" },
  // { label: "Calamcam", mun_code: "104325" },
  // { label: "Casibole", mun_code: "104325" },
  // { label: "Macopa", mun_code: "104325" },
  // { label: "Magkarila", mun_code: "104325" },
  // { label: "Mahayag", mun_code: "104325" },
  // { label: "Mandahilag", mun_code: "104325" },
  // { label: "Mintabon", mun_code: "104325" },
  // { label: "Pangpangon", mun_code: "104325" },
  // { label: "Poblacion", mun_code: "104325" },
  // { label: "Pook", mun_code: "104325" },
  // { label: "Punta Santiago", mun_code: "104325" },
  // { label: "Puting Balas", mun_code: "104325" },
  // { label: "San Jose", mun_code: "104325" },
  // { label: "Santa Ines", mun_code: "104325" },
  // { label: "Sibantang", mun_code: "104325" },
  // { label: "Sindangan", mun_code: "104325" },
  // { label: "Tagbocboc", mun_code: "104325" },
  // { label: "Balacanas", mun_code: "104326" },
  // { label: "Dayawan", mun_code: "104326" },
  // { label: "Katipunan", mun_code: "104326" },
  // { label: "Kimaya", mun_code: "104326" },
  // { label: "Poblacion 1", mun_code: "104326" },
  // { label: "San Martin", mun_code: "104326" },
  // { label: "Tambobong", mun_code: "104326" },
  // { label: "Imelda", mun_code: "104326" },
  // { label: "Looc", mun_code: "104326" },
  // { label: "Poblacion 2", mun_code: "104326" },
  // { label: "Poblacion 3", mun_code: "104326" },
  // { label: "Binancian", mun_code: "112301" },
  // { label: "Buan", mun_code: "112301" },
  // { label: "Buclad", mun_code: "112301" },
  // { label: "Cabaywa", mun_code: "112301" },
  // { label: "Camansa", mun_code: "112301" },
  // { label: "Camoning", mun_code: "112301" },
  // { label: "Canatan", mun_code: "112301" },
  // { label: "Concepcion", mun_code: "112301" },
  // { label: "Magatos", mun_code: "112301" },
  // { label: "Napungas", mun_code: "112301" },
  // { label: "New Bantayan", mun_code: "112301" },
  // { label: "New Santiago", mun_code: "112301" },
  // { label: "Pamacaun", mun_code: "112301" },
  // { label: "Cambanogoy (Pob.)", mun_code: "112301" },
  // { label: "Sagayen", mun_code: "112301" },
  // { label: "San Vicente", mun_code: "112301" },
  // { label: "Santa Filomena", mun_code: "112301" },
  // { label: "Sonlon", mun_code: "112301" },
  // { label: "New Loon", mun_code: "112301" },
  // { label: "Alejal", mun_code: "112303" },
  // { label: "Anibongan", mun_code: "112303" },
  // { label: "Asuncion (Cuatro-Cuatro)", mun_code: "112303" },
  // { label: "Cebulano", mun_code: "112303" },
  // { label: "Guadalupe", mun_code: "112303" },
  // { label: "Ising (Pob.)", mun_code: "112303" },
  // { label: "La Paz", mun_code: "112303" },
  // { label: "Mabaus", mun_code: "112303" },
  // { label: "Mabuhay", mun_code: "112303" },
  // { label: "Magsaysay", mun_code: "112303" },
  // { label: "Mangalcal", mun_code: "112303" },
  // { label: "Minda", mun_code: "112303" },
  // { label: "New Camiling", mun_code: "112303" },
  // { label: "San Isidro", mun_code: "112303" },
  // { label: "Tibulao", mun_code: "112303" },
  // { label: "Tubod", mun_code: "112303" },
  // { label: "Tuganay", mun_code: "112303" },
  // { label: "Salvacion", mun_code: "112303" },
  // { label: "Taba", mun_code: "112303" },
  // { label: "Semong", mun_code: "112305" },
  // { label: "Florida", mun_code: "112305" },
  // { label: "Gabuyan", mun_code: "112305" },
  // { label: "Gupitan", mun_code: "112305" },
  // { label: "Capungagan", mun_code: "112305" },
  // { label: "Katipunan", mun_code: "112305" },
  // { label: "Luna", mun_code: "112305" },
  // { label: "Mabantao", mun_code: "112305" },
  // { label: "Mamacao", mun_code: "112305" },
  // { label: "Pag-asa", mun_code: "112305" },
  // { label: "Maniki (Poblacion)", mun_code: "112305" },
  // { label: "Sampao", mun_code: "112305" },
  // { label: "Sua-on", mun_code: "112305" },
  // { label: "Tiburcia", mun_code: "112305" },
  // { label: "Cabidianan", mun_code: "112314" },
  // { label: "Carcor", mun_code: "112314" },
  // { label: "Del Monte", mun_code: "112314" },
  // { label: "Del Pilar", mun_code: "112314" },
  // { label: "El Salvador", mun_code: "112314" },
  // { label: "Limba-an", mun_code: "112314" },
  // { label: "Macgum", mun_code: "112314" },
  // { label: "Mambing", mun_code: "112314" },
  // { label: "Mesaoy", mun_code: "112314" },
  // { label: "New Bohol", mun_code: "112314" },
  // { label: "New Cortez", mun_code: "112314" },
  // { label: "New Sambog", mun_code: "112314" },
  // { label: "Patrocenio", mun_code: "112314" },
  // { label: "Poblacion", mun_code: "112314" },
  // { label: "San Roque", mun_code: "112314" },
  // { label: "Santa Cruz", mun_code: "112314" },
  // { label: "Santa Fe", mun_code: "112314" },
  // { label: "Suawon", mun_code: "112314" },
  // { label: "San Jose", mun_code: "112314" },
  // { label: "A. O. Floirendo", mun_code: "112315" },
  // { label: "Datu Abdul Dadia", mun_code: "112315" },
  // { label: "Buenavista", mun_code: "112315" },
  // { label: "Cacao", mun_code: "112315" },
  // { label: "Cagangohan", mun_code: "112315" },
  // { label: "Consolacion", mun_code: "112315" },
  // { label: "Dapco", mun_code: "112315" },
  // { label: "Gredu (Pob.)", mun_code: "112315" },
  // { label: "J.P. Laurel", mun_code: "112315" },
  // { label: "Kasilak", mun_code: "112315" },
  // { label: "Katipunan", mun_code: "112315" },
  // { label: "Katualan", mun_code: "112315" },
  // { label: "Kauswagan", mun_code: "112315" },
  // { label: "Kiotoy", mun_code: "112315" },
  // { label: "Little Panay", mun_code: "112315" },
  // { label: "Lower Panaga (Roxas)", mun_code: "112315" },
  // { label: "Mabunao", mun_code: "112315" },
  // { label: "Maduao", mun_code: "112315" },
  // { label: "Malativas", mun_code: "112315" },
  // { label: "Manay", mun_code: "112315" },
  // { label: "Nanyo", mun_code: "112315" },
  // { label: "New Malaga (Dalisay)", mun_code: "112315" },
  // { label: "New Malitbog", mun_code: "112315" },
  // { label: "New Pandan (Pob.)", mun_code: "112315" },
  // { label: "New Visayas", mun_code: "112315" },
  // { label: "Quezon", mun_code: "112315" },
  // { label: "Salvacion", mun_code: "112315" },
  // { label: "San Francisco (Pob.)", mun_code: "112315" },
  // { label: "San Nicolas", mun_code: "112315" },
  // { label: "San Roque", mun_code: "112315" },
  // { label: "San Vicente", mun_code: "112315" },
  // { label: "Santa Cruz", mun_code: "112315" },
  // { label: "Sindaton", mun_code: "112315" },
  // { label: "Southern Davao", mun_code: "112315" },
  // { label: "Tagpore", mun_code: "112315" },
  // { label: "Tibungol", mun_code: "112315" },
  // { label: "Upper Licanan", mun_code: "112315" },
  // { label: "Waterfall", mun_code: "112315" },
  // { label: "San Pedro", mun_code: "112315" },
  // { label: "Adecor", mun_code: "112317" },
  // { label: "Anonang", mun_code: "112317" },
  // { label: "Aumbay", mun_code: "112317" },
  // { label: "Aundanao", mun_code: "112317" },
  // { label: "Balet", mun_code: "112317" },
  // { label: "Bandera", mun_code: "112317" },
  // { label: "Caliclic (Dangca-an)", mun_code: "112317" },
  // { label: "Camudmud", mun_code: "112317" },
  // { label: "Catagman", mun_code: "112317" },
  // { label: "Cawag", mun_code: "112317" },
  // { label: "Cogon", mun_code: "112317" },
  // { label: "Cogon (Talicod)", mun_code: "112317" },
  // { label: "Dadatan", mun_code: "112317" },
  // { label: "Del Monte", mun_code: "112317" },
  // { label: "Guilon", mun_code: "112317" },
  // { label: "Kanaan", mun_code: "112317" },
  // { label: "Kinawitnon", mun_code: "112317" },
  // { label: "Libertad", mun_code: "112317" },
  // { label: "Libuak", mun_code: "112317" },
  // { label: "Licup", mun_code: "112317" },
  // { label: "Limao", mun_code: "112317" },
  // { label: "Linosutan", mun_code: "112317" },
  // { label: "Mambago-A", mun_code: "112317" },
  // { label: "Mambago-B", mun_code: "112317" },
  // { label: "Miranda (Pob.)", mun_code: "112317" },
  // { label: "Moncado (Pob.)", mun_code: "112317" },
  // { label: "Pangubatan (Talicod I)", mun_code: "112317" },
  // { label: "Poblacion (Kaputian)", mun_code: "112317" },
  // { label: "San Agustin", mun_code: "112317" },
  // { label: "San Antonio", mun_code: "112317" },
  // { label: "San Isidro (Babak)", mun_code: "112317" },
  // { label: "San Isidro (Kaputian)", mun_code: "112317" },
  // { label: "San Jose (San Lapuz)", mun_code: "112317" },
  // { label: "San Miguel (Magamono)", mun_code: "112317" },
  // { label: "San Remigio", mun_code: "112317" },
  // { label: "Santa Cruz (Talicod II)", mun_code: "112317" },
  // { label: "Sion (Zion)", mun_code: "112317" },
  // { label: "Tagbaobo", mun_code: "112317" },
  // { label: "Tagbay", mun_code: "112317" },
  // { label: "Tagbitan-ag", mun_code: "112317" },
  // { label: "Tagdaliao", mun_code: "112317" },
  // { label: "Tagpopongan", mun_code: "112317" },
  // { label: "Tambo", mun_code: "112317" },
  // { label: "Toril", mun_code: "112317" },
  // { label: "Balagunan", mun_code: "112318" },
  // { label: "Bobongon", mun_code: "112318" },
  // { label: "Esperanza", mun_code: "112318" },
  // { label: "Kimamon", mun_code: "112318" },
  // { label: "Kinamayan", mun_code: "112318" },
  // { label: "La Libertad", mun_code: "112318" },
  // { label: "Lungaog", mun_code: "112318" },
  // { label: "Magwawa", mun_code: "112318" },
  // { label: "New Katipunan", mun_code: "112318" },
  // { label: "Pantaron", mun_code: "112318" },
  // { label: "Tibal-og (Pob.)", mun_code: "112318" },
  // { label: "San Jose", mun_code: "112318" },
  // { label: "San Miguel", mun_code: "112318" },
  // { label: "Talomo", mun_code: "112318" },
  // { label: "Casig-Ang", mun_code: "112318" },
  // { label: "New Visayas", mun_code: "112318" },
  // { label: "Salvacion", mun_code: "112318" },
  // { label: "San Vicente", mun_code: "112318" },
  // { label: "Tulalian", mun_code: "112318" },
  // { label: "Apokon", mun_code: "112319" },
  // { label: "Bincungan", mun_code: "112319" },
  // { label: "Busaon", mun_code: "112319" },
  // { label: "Canocotan", mun_code: "112319" },
  // { label: "Cuambogan", mun_code: "112319" },
  // { label: "La Filipina", mun_code: "112319" },
  // { label: "Liboganon", mun_code: "112319" },
  // { label: "Madaum", mun_code: "112319" },
  // { label: "Magdum", mun_code: "112319" },
  // { label: "Mankilam", mun_code: "112319" },
  // { label: "New Balamban", mun_code: "112319" },
  // { label: "Nueva Fuerza", mun_code: "112319" },
  // { label: "Pagsabangan", mun_code: "112319" },
  // { label: "Pandapan", mun_code: "112319" },
  // { label: "Magugpo Poblacion", mun_code: "112319" },
  // { label: "San Agustin", mun_code: "112319" },
  // { label: "San Isidro", mun_code: "112319" },
  // { label: "San Miguel (Camp 4)", mun_code: "112319" },
  // { label: "Visayan Village", mun_code: "112319" },
  // { label: "Magugpo East", mun_code: "112319" },
  // { label: "Magugpo North", mun_code: "112319" },
  // { label: "Magugpo South", mun_code: "112319" },
  // { label: "Magugpo West", mun_code: "112319" },
  // { label: "Dagohoy", mun_code: "112322" },
  // { label: "Palma Gil", mun_code: "112322" },
  // { label: "Cabay-Angan", mun_code: "112323" },
  // { label: "Dujali", mun_code: "112323" },
  // { label: "Magupising", mun_code: "112323" },
  // { label: "New Casay", mun_code: "112323" },
  // { label: "Tanglaw", mun_code: "112323" },
  // { label: "Dacudao", mun_code: "112324" },
  // { label: "Datu Balong", mun_code: "112324" },
  // { label: "Igangon", mun_code: "112324" },
  // { label: "Kipalili", mun_code: "112324" },
  // { label: "Libuton", mun_code: "112324" },
  // { label: "Linao", mun_code: "112324" },
  // { label: "Mamangan", mun_code: "112324" },
  // { label: "Monte Dujali", mun_code: "112324" },
  // { label: "Pinamuno", mun_code: "112324" },
  // { label: "Sabangan", mun_code: "112324" },
  // { label: "San Miguel", mun_code: "112324" },
  // { label: "Sawata", mun_code: "112324" },
  // { label: "Alegre", mun_code: "112401" },
  // { label: "Alta Vista", mun_code: "112401" },
  // { label: "Anonang", mun_code: "112401" },
  // { label: "Bitaug", mun_code: "112401" },
  // { label: "Bonifacio", mun_code: "112401" },
  // { label: "Buenavista", mun_code: "112401" },
  // { label: "Darapuay", mun_code: "112401" },
  // { label: "Dolo", mun_code: "112401" },
  // { label: "Eman", mun_code: "112401" },
  // { label: "Kinuskusan", mun_code: "112401" },
  // { label: "Libertad", mun_code: "112401" },
  // { label: "Linawan", mun_code: "112401" },
  // { label: "Mabuhay", mun_code: "112401" },
  // { label: "Mabunga", mun_code: "112401" },
  // { label: "Managa", mun_code: "112401" },
  // { label: "Marber", mun_code: "112401" },
  // { label: "New Clarin (Miral)", mun_code: "112401" },
  // { label: "Poblacion", mun_code: "112401" },
  // { label: "Rizal", mun_code: "112401" },
  // { label: "Sibayan", mun_code: "112401" },
  // { label: "Tinongtongan", mun_code: "112401" },
  // { label: "Tubod", mun_code: "112401" },
  // { label: "Union", mun_code: "112401" },
  // { label: "Poblacion Dos", mun_code: "112401" },
  // { label: "Acacia", mun_code: "112402" },
  // { label: "Agdao", mun_code: "112402" },
  // { label: "Alambre", mun_code: "112402" },
  // { label: "Atan-Awe", mun_code: "112402" },
  // { label: "Bago Gallera", mun_code: "112402" },
  // { label: "Bago Oshiro", mun_code: "112402" },
  // { label: "Baguio (Pob.)", mun_code: "112402" },
  // { label: "Balengaeng", mun_code: "112402" },
  // { label: "Baliok", mun_code: "112402" },
  // { label: "Bangkas Heights", mun_code: "112402" },
  // { label: "Baracatan", mun_code: "112402" },
  // { label: "Bato", mun_code: "112402" },
  // { label: "Bayabas", mun_code: "112402" },
  // { label: "Biao Escuela", mun_code: "112402" },
  // { label: "Biao Guianga", mun_code: "112402" },
  // { label: "Biao Joaquin", mun_code: "112402" },
  // { label: "Binugao", mun_code: "112402" },
  // { label: "Bucana", mun_code: "112402" },
  // { label: "Buhangin (Pob.)", mun_code: "112402" },
  // { label: "Bunawan (Pob.)", mun_code: "112402" },
  // { label: "Cabantian", mun_code: "112402" },
  // { label: "Cadalian", mun_code: "112402" },
  // { label: "Calinan (Pob.)", mun_code: "112402" },
  // { label: "Callawa", mun_code: "112402" },
  // { label: "Camansi", mun_code: "112402" },
  // { label: "Carmen", mun_code: "112402" },
  // { label: "Catalunan Grande", mun_code: "112402" },
  // { label: "Catigan", mun_code: "112402" },
  // { label: "Cawayan", mun_code: "112402" },
  // { label: "Colosas", mun_code: "112402" },
  // { label: "Communal", mun_code: "112402" },
  // { label: "Crossing Bayabas", mun_code: "112402" },
  // { label: "Dacudao", mun_code: "112402" },
  // { label: "Dalag", mun_code: "112402" },
  // { label: "Dalagdag", mun_code: "112402" },
  // { label: "Daliao", mun_code: "112402" },
  // { label: "Daliaon Plantation", mun_code: "112402" },
  // { label: "Dominga", mun_code: "112402" },
  // { label: "Dumoy", mun_code: "112402" },
  // { label: "Eden", mun_code: "112402" },
  // { label: "Fatima (Benowang)", mun_code: "112402" },
  // { label: "Gatungan", mun_code: "112402" },
  // { label: "Gumalang", mun_code: "112402" },
  // { label: "Ilang", mun_code: "112402" },
  // { label: "Indangan", mun_code: "112402" },
  // { label: "Kilate", mun_code: "112402" },
  // { label: "Lacson", mun_code: "112402" },
  // { label: "Lamanan", mun_code: "112402" },
  // { label: "Lampianao", mun_code: "112402" },
  // { label: "Langub", mun_code: "112402" },
  // { label: "Alejandra Navarro (Lasang)", mun_code: "112402" },
  // { label: "Lizada", mun_code: "112402" },
  // { label: "Los Amigos", mun_code: "112402" },
  // { label: "Lubogan", mun_code: "112402" },
  // { label: "Lumiad", mun_code: "112402" },
  // { label: "Ma-a", mun_code: "112402" },
  // { label: "Mabuhay", mun_code: "112402" },
  // { label: "Magtuod", mun_code: "112402" },
  // { label: "Mahayag", mun_code: "112402" },
  // { label: "Malabog", mun_code: "112402" },
  // { label: "Malagos", mun_code: "112402" },
  // { label: "Malamba", mun_code: "112402" },
  // { label: "Manambulan", mun_code: "112402" },
  // { label: "Mandug", mun_code: "112402" },
  // { label: "Manuel Guianga", mun_code: "112402" },
  // { label: "Mapula", mun_code: "112402" },
  // { label: "Marapangi", mun_code: "112402" },
  // { label: "Marilog", mun_code: "112402" },
  // { label: "Matina Aplaya", mun_code: "112402" },
  // { label: "Matina Crossing", mun_code: "112402" },
  // { label: "Matina Pangi", mun_code: "112402" },
  // { label: "Matina Biao", mun_code: "112402" },
  // { label: "Mintal", mun_code: "112402" },
  // { label: "Mudiang", mun_code: "112402" },
  // { label: "Mulig", mun_code: "112402" },
  // { label: "New Carmen", mun_code: "112402" },
  // { label: "New Valencia", mun_code: "112402" },
  // { label: "Pampanga", mun_code: "112402" },
  // { label: "Panacan", mun_code: "112402" },
  // { label: "Panalum", mun_code: "112402" },
  // { label: "Pandaitan", mun_code: "112402" },
  // { label: "Pangyan", mun_code: "112402" },
  // { label: "Paquibato (Pob.)", mun_code: "112402" },
  // { label: "Paradise Embak", mun_code: "112402" },
  // { label: "Riverside", mun_code: "112402" },
  // { label: "Salapawan", mun_code: "112402" },
  // { label: "Salaysay", mun_code: "112402" },
  // { label: "San Isidro (Licanan)", mun_code: "112402" },
  // { label: "Sasa", mun_code: "112402" },
  // { label: "Sibulan", mun_code: "112402" },
  // { label: "Sirawan", mun_code: "112402" },
  // { label: "Sirib", mun_code: "112402" },
  // { label: "Suawan (Tuli)", mun_code: "112402" },
  // { label: "Subasta", mun_code: "112402" },
  // { label: "Sumimao", mun_code: "112402" },
  // { label: "Tacunan", mun_code: "112402" },
  // { label: "Tagakpan", mun_code: "112402" },
  // { label: "Tagluno", mun_code: "112402" },
  // { label: "Tagurano", mun_code: "112402" },
  // { label: "Talandang", mun_code: "112402" },
  // { label: "Talomo (Pob.)", mun_code: "112402" },
  // { label: "Talomo River", mun_code: "112402" },
  // { label: "Tamayong", mun_code: "112402" },
  // { label: "Tambobong", mun_code: "112402" },
  // { label: "Tamugan", mun_code: "112402" },
  // { label: "Tapak", mun_code: "112402" },
  // { label: "Tawan-tawan", mun_code: "112402" },
  // { label: "Tibuloy", mun_code: "112402" },
  // { label: "Tibungco", mun_code: "112402" },
  // { label: "Tigatto", mun_code: "112402" },
  // { label: "Toril (Pob.)", mun_code: "112402" },
  // { label: "Tugbok (Pob.)", mun_code: "112402" },
  // { label: "Tungakalan", mun_code: "112402" },
  // { label: "Ula", mun_code: "112402" },
  // { label: "Wangan", mun_code: "112402" },
  // { label: "Wines", mun_code: "112402" },
  // { label: "Barangay 1-A (Pob.)", mun_code: "112402" },
  // { label: "Barangay 2-A (Pob.)", mun_code: "112402" },
  // { label: "Barangay 3-A (Pob.)", mun_code: "112402" },
  // { label: "Barangay 4-A (Pob.)", mun_code: "112402" },
  // { label: "Barangay 5-A (Pob.)", mun_code: "112402" },
  // { label: "Barangay 6-A (Pob.)", mun_code: "112402" },
  // { label: "Barangay 7-A (Pob.)", mun_code: "112402" },
  // { label: "Barangay 8-A (Pob.)", mun_code: "112402" },
  // { label: "Barangay 9-A (Pob.)", mun_code: "112402" },
  // { label: "Barangay 10-A (Pob.)", mun_code: "112402" },
  // { label: "Barangay 11-B (Pob.)", mun_code: "112402" },
  // { label: "Barangay 12-B (Pob.)", mun_code: "112402" },
  // { label: "Barangay 13-B (Pob.)", mun_code: "112402" },
  // { label: "Barangay 14-B (Pob.)", mun_code: "112402" },
  // { label: "Barangay 15-B (Pob.)", mun_code: "112402" },
  // { label: "Barangay 16-B (Pob.)", mun_code: "112402" },
  // { label: "Barangay 17-B (Pob.)", mun_code: "112402" },
  // { label: "Barangay 18-B (Pob.)", mun_code: "112402" },
  // { label: "Barangay 19-B (Pob.)", mun_code: "112402" },
  // { label: "Barangay 20-B (Pob.)", mun_code: "112402" },
  // { label: "Barangay 21-C (Pob.)", mun_code: "112402" },
  // { label: "Barangay 22-C (Pob.)", mun_code: "112402" },
  // { label: "Barangay 23-C (Pob.)", mun_code: "112402" },
  // { label: "Barangay 24-C (Pob.)", mun_code: "112402" },
  // { label: "Barangay 25-C (Pob.)", mun_code: "112402" },
  // { label: "Barangay 26-C (Pob.)", mun_code: "112402" },
  // { label: "Barangay 27-C (Pob.)", mun_code: "112402" },
  // { label: "Barangay 28-C (Pob.)", mun_code: "112402" },
  // { label: "Barangay 29-C (Pob.)", mun_code: "112402" },
  // { label: "Barangay 30-C (Pob.)", mun_code: "112402" },
  // { label: "Barangay 31-D (Pob.)", mun_code: "112402" },
  // { label: "Barangay 32-D (Pob.)", mun_code: "112402" },
  // { label: "Barangay 33-D (Pob.)", mun_code: "112402" },
  // { label: "Barangay 34-D (Pob.)", mun_code: "112402" },
  // { label: "Barangay 35-D (Pob.)", mun_code: "112402" },
  // { label: "Barangay 36-D (Pob.)", mun_code: "112402" },
  // { label: "Barangay 37-D (Pob.)", mun_code: "112402" },
  // { label: "Barangay 38-D (Pob.)", mun_code: "112402" },
  // { label: "Barangay 39-D (Pob.)", mun_code: "112402" },
  // { label: "Barangay 40-D (Pob.)", mun_code: "112402" },
  // { label: "Angalan", mun_code: "112402" },
  // { label: "Baganihan", mun_code: "112402" },
  // { label: "Bago Aplaya", mun_code: "112402" },
  // { label: "Bantol", mun_code: "112402" },
  // { label: "Buda", mun_code: "112402" },
  // { label: "Centro (San Juan)", mun_code: "112402" },
  // { label: "Datu Salumay", mun_code: "112402" },
  // { label: "Gov. Paciano Bangoy", mun_code: "112402" },
  // { label: "Gov. Vicente Duterte", mun_code: "112402" },
  // { label: "Gumitan", mun_code: "112402" },
  // { label: "Inayangan", mun_code: "112402" },
  // { label: "Kap. Tomas Monteverde, Sr.", mun_code: "112402" },
  // { label: "Lapu-lapu", mun_code: "112402" },
  // { label: "Leon Garcia, Sr.", mun_code: "112402" },
  // { label: "Magsaysay", mun_code: "112402" },
  // { label: "Megkawayan", mun_code: "112402" },
  // { label: "Rafael Castillo", mun_code: "112402" },
  // { label: "Saloy", mun_code: "112402" },
  // { label: "San Antonio", mun_code: "112402" },
  // { label: "Ubalde", mun_code: "112402" },
  // { label: "Waan", mun_code: "112402" },
  // { label: "Wilfredo Aquino", mun_code: "112402" },
  // { label: "Alfonso Angliongto Sr.", mun_code: "112402" },
  // { label: "Vicente Hizon Sr.", mun_code: "112402" },
  // { label: "Aplaya", mun_code: "112403" },
  // { label: "Balabag", mun_code: "112403" },
  // { label: "San Jose (Balutakay)", mun_code: "112403" },
  // { label: "Binaton", mun_code: "112403" },
  // { label: "Cogon", mun_code: "112403" },
  // { label: "Colorado", mun_code: "112403" },
  // { label: "Dawis", mun_code: "112403" },
  // { label: "Dulangan", mun_code: "112403" },
  // { label: "Goma", mun_code: "112403" },
  // { label: "Igpit", mun_code: "112403" },
  // { label: "Kiagot", mun_code: "112403" },
  // { label: "Lungag", mun_code: "112403" },
  // { label: "Mahayahay", mun_code: "112403" },
  // { label: "Matti", mun_code: "112403" },
  // { label: "Kapatagan (Rizal)", mun_code: "112403" },
  // { label: "Ruparan", mun_code: "112403" },
  // { label: "San Agustin", mun_code: "112403" },
  // { label: "San Miguel (Odaca)", mun_code: "112403" },
  // { label: "San Roque", mun_code: "112403" },
  // { label: "Sinawilan", mun_code: "112403" },
  // { label: "Soong", mun_code: "112403" },
  // { label: "Tiguman", mun_code: "112403" },
  // { label: "Tres De Mayo", mun_code: "112403" },
  // { label: "Zone 1 (Pob.)", mun_code: "112403" },
  // { label: "Zone 2 (Pob.)", mun_code: "112403" },
  // { label: "Zone 3 (Pob.)", mun_code: "112403" },
  // { label: "Balutakay", mun_code: "112404" },
  // { label: "Clib", mun_code: "112404" },
  // { label: "Guihing Aplaya", mun_code: "112404" },
  // { label: "Guihing", mun_code: "112404" },
  // { label: "Hagonoy Crossing", mun_code: "112404" },
  // { label: "Kibuaya", mun_code: "112404" },
  // { label: "La Union", mun_code: "112404" },
  // { label: "Lanuro", mun_code: "112404" },
  // { label: "Lapulabao", mun_code: "112404" },
  // { label: "Leling", mun_code: "112404" },
  // { label: "Mahayahay", mun_code: "112404" },
  // { label: "Malabang Damsite", mun_code: "112404" },
  // { label: "Maliit Digos", mun_code: "112404" },
  // { label: "New Quezon", mun_code: "112404" },
  // { label: "Paligue", mun_code: "112404" },
  // { label: "Poblacion", mun_code: "112404" },
  // { label: "Sacub", mun_code: "112404" },
  // { label: "San Guillermo", mun_code: "112404" },
  // { label: "San Isidro", mun_code: "112404" },
  // { label: "Sinayawan", mun_code: "112404" },
  // { label: "Tologan", mun_code: "112404" },
  // { label: "Abnate", mun_code: "112406" },
  // { label: "Bagong Negros", mun_code: "112406" },
  // { label: "Bagong Silang", mun_code: "112406" },
  // { label: "Bagumbayan", mun_code: "112406" },
  // { label: "Balasiao", mun_code: "112406" },
  // { label: "Bonifacio", mun_code: "112406" },
  // { label: "Bunot", mun_code: "112406" },
  // { label: "Cogon-Bacaca", mun_code: "112406" },
  // { label: "Dapok", mun_code: "112406" },
  // { label: "Ihan", mun_code: "112406" },
  // { label: "Kibongbong", mun_code: "112406" },
  // { label: "Kimlawis", mun_code: "112406" },
  // { label: "Kisulan", mun_code: "112406" },
  // { label: "Lati-an", mun_code: "112406" },
  // { label: "Manual", mun_code: "112406" },
  // { label: "Maraga-a", mun_code: "112406" },
  // { label: "Molopolo", mun_code: "112406" },
  // { label: "New Sibonga", mun_code: "112406" },
  // { label: "Panaglib", mun_code: "112406" },
  // { label: "Pasig", mun_code: "112406" },
  // { label: "Poblacion", mun_code: "112406" },
  // { label: "Pocaleel", mun_code: "112406" },
  // { label: "San Isidro", mun_code: "112406" },
  // { label: "San Jose", mun_code: "112406" },
  // { label: "San Pedro", mun_code: "112406" },
  // { label: "Tacub", mun_code: "112406" },
  // { label: "Tacul", mun_code: "112406" },
  // { label: "Waterfall", mun_code: "112406" },
  // { label: "Bulol-Salo", mun_code: "112406" },
  // { label: "Bacungan", mun_code: "112407" },
  // { label: "Balnate", mun_code: "112407" },
  // { label: "Barayong", mun_code: "112407" },
  // { label: "Blocon", mun_code: "112407" },
  // { label: "Dalawinon", mun_code: "112407" },
  // { label: "Dalumay", mun_code: "112407" },
  // { label: "Glamang", mun_code: "112407" },
  // { label: "Kanapulo", mun_code: "112407" },
  // { label: "Kasuga", mun_code: "112407" },
  // { label: "Lower Bala", mun_code: "112407" },
  // { label: "Mabini", mun_code: "112407" },
  // { label: "Malawanit", mun_code: "112407" },
  // { label: "Malongon", mun_code: "112407" },
  // { label: "New Ilocos", mun_code: "112407" },
  // { label: "Poblacion", mun_code: "112407" },
  // { label: "San Isidro", mun_code: "112407" },
  // { label: "San Miguel", mun_code: "112407" },
  // { label: "Tacul", mun_code: "112407" },
  // { label: "Tagaytay", mun_code: "112407" },
  // { label: "Upper Bala", mun_code: "112407" },
  // { label: "Maibo", mun_code: "112407" },
  // { label: "New Opon", mun_code: "112407" },
  // { label: "Baybay", mun_code: "112408" },
  // { label: "Bolton", mun_code: "112408" },
  // { label: "Bulacan", mun_code: "112408" },
  // { label: "Caputian", mun_code: "112408" },
  // { label: "Ibo", mun_code: "112408" },
  // { label: "Kiblagon", mun_code: "112408" },
  // { label: "Lapu-Lapu (Lapla)", mun_code: "112408" },
  // { label: "Mabini", mun_code: "112408" },
  // { label: "New Baclayon", mun_code: "112408" },
  // { label: "Pitu", mun_code: "112408" },
  // { label: "Poblacion", mun_code: "112408" },
  // { label: "Tagansule", mun_code: "112408" },
  // { label: "Bagumbayan", mun_code: "112408" },
  // { label: "Rizal (Parame)", mun_code: "112408" },
  // { label: "San Isidro", mun_code: "112408" },
  // { label: "Asbang", mun_code: "112410" },
  // { label: "Asinan", mun_code: "112410" },
  // { label: "Bagumbayan", mun_code: "112410" },
  // { label: "Bangkal", mun_code: "112410" },
  // { label: "Buas", mun_code: "112410" },
  // { label: "Buri", mun_code: "112410" },
  // { label: "Camanchiles", mun_code: "112410" },
  // { label: "Ceboza", mun_code: "112410" },
  // { label: "Colonsabak", mun_code: "112410" },
  // { label: "Dongan-Pekong", mun_code: "112410" },
  // { label: "Kabasagan", mun_code: "112410" },
  // { label: "Kapok", mun_code: "112410" },
  // { label: "Kauswagan", mun_code: "112410" },
  // { label: "Kibao", mun_code: "112410" },
  // { label: "La Suerte", mun_code: "112410" },
  // { label: "Langa-an", mun_code: "112410" },
  // { label: "Lower Marber", mun_code: "112410" },
  // { label: "Cabligan (Managa)", mun_code: "112410" },
  // { label: "Manga", mun_code: "112410" },
  // { label: "New Katipunan", mun_code: "112410" },
  // { label: "New Murcia", mun_code: "112410" },
  // { label: "New Visayas", mun_code: "112410" },
  // { label: "Poblacion", mun_code: "112410" },
  // { label: "Saboy", mun_code: "112410" },
  // { label: "San Jose", mun_code: "112410" },
  // { label: "San Miguel", mun_code: "112410" },
  // { label: "San Vicente", mun_code: "112410" },
  // { label: "Saub", mun_code: "112410" },
  // { label: "Sinaragan", mun_code: "112410" },
  // { label: "Sinawilan", mun_code: "112410" },
  // { label: "Tamlangon", mun_code: "112410" },
  // { label: "Towak", mun_code: "112410" },
  // { label: "Tibongbong", mun_code: "112410" },
  // { label: "Almendras (Pob.)", mun_code: "112411" },
  // { label: "Harada Butai", mun_code: "112411" },
  // { label: "Lower Katipunan", mun_code: "112411" },
  // { label: "Lower Limonzo", mun_code: "112411" },
  // { label: "Lower Malinao", mun_code: "112411" },
  // { label: "N C Ordaneza District (Pob.)", mun_code: "112411" },
  // { label: "Northern Paligue", mun_code: "112411" },
  // { label: "Palili", mun_code: "112411" },
  // { label: "Piape", mun_code: "112411" },
  // { label: "Punta Piape", mun_code: "112411" },
  // { label: "Quirino District (Pob.)", mun_code: "112411" },
  // { label: "San Isidro", mun_code: "112411" },
  // { label: "Southern Paligue", mun_code: "112411" },
  // { label: "Tulogan", mun_code: "112411" },
  // { label: "Upper Limonzo", mun_code: "112411" },
  // { label: "Upper Malinao", mun_code: "112411" },
  // { label: "Astorga", mun_code: "112412" },
  // { label: "Bato", mun_code: "112412" },
  // { label: "Coronon", mun_code: "112412" },
  // { label: "Darong", mun_code: "112412" },
  // { label: "Inawayan", mun_code: "112412" },
  // { label: "Jose Rizal", mun_code: "112412" },
  // { label: "Matutungan", mun_code: "112412" },
  // { label: "Melilia", mun_code: "112412" },
  // { label: "Zone I (Pob.)", mun_code: "112412" },
  // { label: "Saliducon", mun_code: "112412" },
  // { label: "Sibulan", mun_code: "112412" },
  // { label: "Sinoron", mun_code: "112412" },
  // { label: "Tagabuli", mun_code: "112412" },
  // { label: "Tibolo", mun_code: "112412" },
  // { label: "Tuban", mun_code: "112412" },
  // { label: "Zone II (Pob.)", mun_code: "112412" },
  // { label: "Zone III (Pob.)", mun_code: "112412" },
  // { label: "Zone IV (Pob.)", mun_code: "112412" },
  // { label: "Balasinon", mun_code: "112414" },
  // { label: "Buguis", mun_code: "112414" },
  // { label: "Carre", mun_code: "112414" },
  // { label: "Clib", mun_code: "112414" },
  // { label: "Harada Butai", mun_code: "112414" },
  // { label: "Katipunan", mun_code: "112414" },
  // { label: "Kiblagon", mun_code: "112414" },
  // { label: "Labon", mun_code: "112414" },
  // { label: "Laperas", mun_code: "112414" },
  // { label: "Lapla", mun_code: "112414" },
  // { label: "Litos", mun_code: "112414" },
  // { label: "Luparan", mun_code: "112414" },
  // { label: "Mckinley", mun_code: "112414" },
  // { label: "New Cebu", mun_code: "112414" },
  // { label: "Palili", mun_code: "112414" },
  // { label: "Parame", mun_code: "112414" },
  // { label: "Poblacion", mun_code: "112414" },
  // { label: "Roxas", mun_code: "112414" },
  // { label: "Solongvale", mun_code: "112414" },
  // { label: "Tagolilong", mun_code: "112414" },
  // { label: "Tala-o", mun_code: "112414" },
  // { label: "Talas", mun_code: "112414" },
  // { label: "Tanwalang", mun_code: "112414" },
  // { label: "Waterfall", mun_code: "112414" },
  // { label: "Baculin", mun_code: "112501" },
  // { label: "Banao", mun_code: "112501" },
  // { label: "Batawan", mun_code: "112501" },
  // { label: "Batiano", mun_code: "112501" },
  // { label: "Binondo", mun_code: "112501" },
  // { label: "Bobonao", mun_code: "112501" },
  // { label: "Campawan", mun_code: "112501" },
  // { label: "Central (Pob.)", mun_code: "112501" },
  // { label: "Dapnan", mun_code: "112501" },
  // { label: "Kinablangan", mun_code: "112501" },
  // { label: "Lambajon", mun_code: "112501" },
  // { label: "Mahanub", mun_code: "112501" },
  // { label: "Mikit", mun_code: "112501" },
  // { label: "Salingcomot", mun_code: "112501" },
  // { label: "San Isidro", mun_code: "112501" },
  // { label: "San Victor", mun_code: "112501" },
  // { label: "Lucod", mun_code: "112501" },
  // { label: "Saoquegue", mun_code: "112501" },
  // { label: "Cabangcalan", mun_code: "112502" },
  // { label: "Caganganan", mun_code: "112502" },
  // { label: "Calubihan", mun_code: "112502" },
  // { label: "Causwagan", mun_code: "112502" },
  // { label: "Punta Linao", mun_code: "112502" },
  // { label: "Mahayag", mun_code: "112502" },
  // { label: "Maputi", mun_code: "112502" },
  // { label: "Mogbongcogon", mun_code: "112502" },
  // { label: "Panikian", mun_code: "112502" },
  // { label: "Pintatagan", mun_code: "112502" },
  // { label: "Piso Proper", mun_code: "112502" },
  // { label: "Poblacion", mun_code: "112502" },
  // { label: "San Vicente", mun_code: "112502" },
  // { label: "Rang-ay", mun_code: "112502" },
  // { label: "Cabasagan", mun_code: "112503" },
  // { label: "Caatihan", mun_code: "112503" },
  // { label: "Cawayanan", mun_code: "112503" },
  // { label: "Poblacion", mun_code: "112503" },
  // { label: "San Jose", mun_code: "112503" },
  // { label: "Sibajay", mun_code: "112503" },
  // { label: "Carmen", mun_code: "112503" },
  // { label: "Simulao", mun_code: "112503" },
  // { label: "Alvar", mun_code: "112504" },
  // { label: "Caningag", mun_code: "112504" },
  // { label: "Don Leon Balante", mun_code: "112504" },
  // { label: "Lamiawan", mun_code: "112504" },
  // { label: "Manorigao", mun_code: "112504" },
  // { label: "Mercedes", mun_code: "112504" },
  // { label: "Palma Gil", mun_code: "112504" },
  // { label: "Pichon", mun_code: "112504" },
  // { label: "Poblacion", mun_code: "112504" },
  // { label: "San Antonio", mun_code: "112504" },
  // { label: "San Jose", mun_code: "112504" },
  // { label: "San Luis", mun_code: "112504" },
  // { label: "San Miguel", mun_code: "112504" },
  // { label: "San Pedro", mun_code: "112504" },
  // { label: "Santa Fe", mun_code: "112504" },
  // { label: "Santiago", mun_code: "112504" },
  // { label: "Sobrecarey", mun_code: "112504" },
  // { label: "Abijod", mun_code: "112505" },
  // { label: "Alegria", mun_code: "112505" },
  // { label: "Aliwagwag", mun_code: "112505" },
  // { label: "Aragon", mun_code: "112505" },
  // { label: "Baybay", mun_code: "112505" },
  // { label: "Maglahus", mun_code: "112505" },
  // { label: "Mainit", mun_code: "112505" },
  // { label: "Malibago", mun_code: "112505" },
  // { label: "San Alfonso", mun_code: "112505" },
  // { label: "San Antonio", mun_code: "112505" },
  // { label: "San Miguel", mun_code: "112505" },
  // { label: "San Rafael", mun_code: "112505" },
  // { label: "San Vicente", mun_code: "112505" },
  // { label: "Santa Filomena", mun_code: "112505" },
  // { label: "Taytayan", mun_code: "112505" },
  // { label: "Poblacion", mun_code: "112505" },
  // { label: "Anitap", mun_code: "112506" },
  // { label: "Manuel Roxas", mun_code: "112506" },
  // { label: "Don Aurelio Chicote", mun_code: "112506" },
  // { label: "Lavigan", mun_code: "112506" },
  // { label: "Luzon", mun_code: "112506" },
  // { label: "Magdug", mun_code: "112506" },
  // { label: "Monserrat", mun_code: "112506" },
  // { label: "Nangan", mun_code: "112506" },
  // { label: "Oregon", mun_code: "112506" },
  // { label: "Poblacion", mun_code: "112506" },
  // { label: "Pundaguitan", mun_code: "112506" },
  // { label: "Surop", mun_code: "112506" },
  // { label: "Tagabebe", mun_code: "112506" },
  // { label: "Tamban", mun_code: "112506" },
  // { label: "Tandang Sora", mun_code: "112506" },
  // { label: "Tibanban", mun_code: "112506" },
  // { label: "Tiblawan", mun_code: "112506" },
  // { label: "Upper Tibanban", mun_code: "112506" },
  // { label: "Crispin Dela Cruz", mun_code: "112506" },
  // { label: "Bagumbayan", mun_code: "112507" },
  // { label: "Cabadiangan", mun_code: "112507" },
  // { label: "Calapagan", mun_code: "112507" },
  // { label: "Cocornon", mun_code: "112507" },
  // { label: "Corporacion", mun_code: "112507" },
  // { label: "Don Mariano Marcos", mun_code: "112507" },
  // { label: "Ilangay", mun_code: "112507" },
  // { label: "Langka", mun_code: "112507" },
  // { label: "Lantawan", mun_code: "112507" },
  // { label: "Limbahan", mun_code: "112507" },
  // { label: "Macangao", mun_code: "112507" },
  // { label: "Magsaysay", mun_code: "112507" },
  // { label: "Mahayahay", mun_code: "112507" },
  // { label: "Maragatas", mun_code: "112507" },
  // { label: "Marayag", mun_code: "112507" },
  // { label: "New Visayas", mun_code: "112507" },
  // { label: "Poblacion", mun_code: "112507" },
  // { label: "San Isidro", mun_code: "112507" },
  // { label: "San Jose", mun_code: "112507" },
  // { label: "Tagboa", mun_code: "112507" },
  // { label: "Tagugpo", mun_code: "112507" },
  // { label: "Capasnan", mun_code: "112508" },
  // { label: "Cayawan", mun_code: "112508" },
  // { label: "Central (Pob.)", mun_code: "112508" },
  // { label: "Concepcion", mun_code: "112508" },
  // { label: "Del Pilar", mun_code: "112508" },
  // { label: "Guza", mun_code: "112508" },
  // { label: "Holy Cross", mun_code: "112508" },
  // { label: "Mabini", mun_code: "112508" },
  // { label: "Manreza", mun_code: "112508" },
  // { label: "Old Macopa", mun_code: "112508" },
  // { label: "Rizal", mun_code: "112508" },
  // { label: "San Fermin", mun_code: "112508" },
  // { label: "San Ignacio", mun_code: "112508" },
  // { label: "San Isidro", mun_code: "112508" },
  // { label: "New Taokanga", mun_code: "112508" },
  // { label: "Zaragosa", mun_code: "112508" },
  // { label: "Lambog", mun_code: "112508" },
  // { label: "Badas", mun_code: "112509" },
  // { label: "Bobon", mun_code: "112509" },
  // { label: "Buso", mun_code: "112509" },
  // { label: "Cabuaya", mun_code: "112509" },
  // { label: "Central (Pob.)", mun_code: "112509" },
  // { label: "Culian", mun_code: "112509" },
  // { label: "Dahican", mun_code: "112509" },
  // { label: "Danao", mun_code: "112509" },
  // { label: "Dawan", mun_code: "112509" },
  // { label: "Don Enrique Lopez", mun_code: "112509" },
  // { label: "Don Martin Marundan", mun_code: "112509" },
  // { label: "Don Salvador Lopez, Sr.", mun_code: "112509" },
  // { label: "Langka", mun_code: "112509" },
  // { label: "Lawigan", mun_code: "112509" },
  // { label: "Libudon", mun_code: "112509" },
  // { label: "Luban", mun_code: "112509" },
  // { label: "Macambol", mun_code: "112509" },
  // { label: "Mamali", mun_code: "112509" },
  // { label: "Matiao", mun_code: "112509" },
  // { label: "Mayo", mun_code: "112509" },
  // { label: "Sainz", mun_code: "112509" },
  // { label: "Sanghay", mun_code: "112509" },
  // { label: "Tagabakid", mun_code: "112509" },
  // { label: "Tagbinonga", mun_code: "112509" },
  // { label: "Taguibo", mun_code: "112509" },
  // { label: "Tamisan", mun_code: "112509" },
  // { label: "Baon", mun_code: "112510" },
  // { label: "Bitaogan", mun_code: "112510" },
  // { label: "Cambaleon", mun_code: "112510" },
  // { label: "Dugmanon", mun_code: "112510" },
  // { label: "Iba", mun_code: "112510" },
  // { label: "La Union", mun_code: "112510" },
  // { label: "Lapu-lapu", mun_code: "112510" },
  // { label: "Maag", mun_code: "112510" },
  // { label: "Manikling", mun_code: "112510" },
  // { label: "Maputi", mun_code: "112510" },
  // { label: "Batobato (Pob.)", mun_code: "112510" },
  // { label: "San Miguel", mun_code: "112510" },
  // { label: "San Roque", mun_code: "112510" },
  // { label: "Santo Rosario", mun_code: "112510" },
  // { label: "Sudlon", mun_code: "112510" },
  // { label: "Talisay", mun_code: "112510" },
  // { label: "Cabagayan", mun_code: "112511" },
  // { label: "Central (Pob.)", mun_code: "112511" },
  // { label: "Dadong", mun_code: "112511" },
  // { label: "Jovellar", mun_code: "112511" },
  // { label: "Limot", mun_code: "112511" },
  // { label: "Lucatan", mun_code: "112511" },
  // { label: "Maganda", mun_code: "112511" },
  // { label: "Ompao", mun_code: "112511" },
  // { label: "Tomoaong", mun_code: "112511" },
  // { label: "Tubaon", mun_code: "112511" },
  // { label: "Bagongon", mun_code: "118201" },
  // { label: "Gabi", mun_code: "118201" },
  // { label: "Lagab", mun_code: "118201" },
  // { label: "Mangayon", mun_code: "118201" },
  // { label: "Mapaca", mun_code: "118201" },
  // { label: "Maparat", mun_code: "118201" },
  // { label: "New Alegria", mun_code: "118201" },
  // { label: "Ngan", mun_code: "118201" },
  // { label: "Panansalan", mun_code: "118201" },
  // { label: "Poblacion", mun_code: "118201" },
  // { label: "San Jose", mun_code: "118201" },
  // { label: "San Miguel", mun_code: "118201" },
  // { label: "Siocon", mun_code: "118201" },
  // { label: "Tamia", mun_code: "118201" },
  // { label: "Aurora", mun_code: "118201" },
  // { label: "Aguinaldo", mun_code: "118202" },
  // { label: "Banbanon", mun_code: "118202" },
  // { label: "Binasbas", mun_code: "118202" },
  // { label: "Cebulida", mun_code: "118202" },
  // { label: "Il Papa", mun_code: "118202" },
  // { label: "Kaligutan", mun_code: "118202" },
  // { label: "Kapatagan", mun_code: "118202" },
  // { label: "Kidawa", mun_code: "118202" },
  // { label: "Kilagding", mun_code: "118202" },
  // { label: "Kiokmay", mun_code: "118202" },
  // { label: "Langtud", mun_code: "118202" },
  // { label: "Longanapan", mun_code: "118202" },
  // { label: "Naga", mun_code: "118202" },
  // { label: "Laac (Pob.)", mun_code: "118202" },
  // { label: "San Antonio", mun_code: "118202" },
  // { label: "Amor Cruz", mun_code: "118202" },
  // { label: "Ampawid", mun_code: "118202" },
  // { label: "Andap", mun_code: "118202" },
  // { label: "Anitap", mun_code: "118202" },
  // { label: "Bagong Silang", mun_code: "118202" },
  // { label: "Belmonte", mun_code: "118202" },
  // { label: "Bullucan", mun_code: "118202" },
  // { label: "Concepcion", mun_code: "118202" },
  // { label: "Datu Ampunan", mun_code: "118202" },
  // { label: "Datu Davao", mun_code: "118202" },
  // { label: "El Katipunan", mun_code: "118202" },
  // { label: "Imelda", mun_code: "118202" },
  // { label: "Inacayan", mun_code: "118202" },
  // { label: "Mabuhay", mun_code: "118202" },
  // { label: "Macopa", mun_code: "118202" },
  // { label: "Malinao", mun_code: "118202" },
  // { label: "Mangloy", mun_code: "118202" },
  // { label: "Melale", mun_code: "118202" },
  // { label: "New Bethlehem", mun_code: "118202" },
  // { label: "Panamoren", mun_code: "118202" },
  // { label: "Sabud", mun_code: "118202" },
  // { label: "Santa Emilia", mun_code: "118202" },
  // { label: "Sisimon", mun_code: "118202" },
  // { label: "Cadunan", mun_code: "118203" },
  // { label: "Pindasan", mun_code: "118203" },
  // { label: "Cuambog (Pob.)", mun_code: "118203" },
  // { label: "Tagnanan (Mampising)", mun_code: "118203" },
  // { label: "Anitapan", mun_code: "118203" },
  // { label: "Cabuyuan", mun_code: "118203" },
  // { label: "Del Pilar", mun_code: "118203" },
  // { label: "Libodon", mun_code: "118203" },
  // { label: "Golden Valley (Maraut)", mun_code: "118203" },
  // { label: "Pangibiran", mun_code: "118203" },
  // { label: "San Antonio", mun_code: "118203" },
  // { label: "Anibongan", mun_code: "118204" },
  // { label: "Anislagan", mun_code: "118204" },
  // { label: "Binuangan", mun_code: "118204" },
  // { label: "Bucana", mun_code: "118204" },
  // { label: "Calabcab", mun_code: "118204" },
  // { label: "Concepcion", mun_code: "118204" },
  // { label: "Dumlan", mun_code: "118204" },
  // { label: "Elizalde (Somil)", mun_code: "118204" },
  // { label: "Pangi (Gaudencio Antonio)", mun_code: "118204" },
  // { label: "Gubatan", mun_code: "118204" },
  // { label: "Hijo", mun_code: "118204" },
  // { label: "Kinuban", mun_code: "118204" },
  // { label: "Langgam", mun_code: "118204" },
  // { label: "Lapu-lapu", mun_code: "118204" },
  // { label: "Libay-libay", mun_code: "118204" },
  // { label: "Limbo", mun_code: "118204" },
  // { label: "Lumatab", mun_code: "118204" },
  // { label: "Magangit", mun_code: "118204" },
  // { label: "Malamodao", mun_code: "118204" },
  // { label: "Manipongol", mun_code: "118204" },
  // { label: "Mapaang", mun_code: "118204" },
  // { label: "Masara", mun_code: "118204" },
  // { label: "New Asturias", mun_code: "118204" },
  // { label: "Panibasan", mun_code: "118204" },
  // { label: "Panoraon", mun_code: "118204" },
  // { label: "Poblacion", mun_code: "118204" },
  // { label: "San Juan", mun_code: "118204" },
  // { label: "San Roque", mun_code: "118204" },
  // { label: "Sangab", mun_code: "118204" },
  // { label: "Taglawig", mun_code: "118204" },
  // { label: "Mainit", mun_code: "118204" },
  // { label: "New Barili", mun_code: "118204" },
  // { label: "New Leyte", mun_code: "118204" },
  // { label: "New Visayas", mun_code: "118204" },
  // { label: "Panangan", mun_code: "118204" },
  // { label: "Tagbaros", mun_code: "118204" },
  // { label: "Teresa", mun_code: "118204" },
  // { label: "Bagong Silang", mun_code: "118205" },
  // { label: "Mapawa", mun_code: "118205" },
  // { label: "Maragusan (Pob.)", mun_code: "118205" },
  // { label: "New Albay", mun_code: "118205" },
  // { label: "Tupaz", mun_code: "118205" },
  // { label: "Bahi", mun_code: "118205" },
  // { label: "Cambagang", mun_code: "118205" },
  // { label: "Coronobe", mun_code: "118205" },
  // { label: "Katipunan", mun_code: "118205" },
  // { label: "Lahi", mun_code: "118205" },
  // { label: "Langgawisan", mun_code: "118205" },
  // { label: "Mabugnao", mun_code: "118205" },
  // { label: "Magcagong", mun_code: "118205" },
  // { label: "Mahayahay", mun_code: "118205" },
  // { label: "Mauswagon", mun_code: "118205" },
  // { label: "New Katipunan", mun_code: "118205" },
  // { label: "New Man-ay", mun_code: "118205" },
  // { label: "New Panay", mun_code: "118205" },
  // { label: "Paloc", mun_code: "118205" },
  // { label: "Pamintaran", mun_code: "118205" },
  // { label: "Parasanon", mun_code: "118205" },
  // { label: "Talian", mun_code: "118205" },
  // { label: "Tandik", mun_code: "118205" },
  // { label: "Tigbao", mun_code: "118205" },
  // { label: "Andili", mun_code: "118206" },
  // { label: "Bawani", mun_code: "118206" },
  // { label: "Concepcion", mun_code: "118206" },
  // { label: "Malinawon", mun_code: "118206" },
  // { label: "Nueva Visayas", mun_code: "118206" },
  // { label: "Nuevo Iloco", mun_code: "118206" },
  // { label: "Poblacion", mun_code: "118206" },
  // { label: "Salvacion", mun_code: "118206" },
  // { label: "Saosao", mun_code: "118206" },
  // { label: "Sawangan", mun_code: "118206" },
  // { label: "Tuboran", mun_code: "118206" },
  // { label: "Awao", mun_code: "118207" },
  // { label: "Babag", mun_code: "118207" },
  // { label: "Banlag", mun_code: "118207" },
  // { label: "Baylo", mun_code: "118207" },
  // { label: "Casoon", mun_code: "118207" },
  // { label: "Inambatan", mun_code: "118207" },
  // { label: "Haguimitan", mun_code: "118207" },
  // { label: "Macopa", mun_code: "118207" },
  // { label: "Mamunga", mun_code: "118207" },
  // { label: "Naboc", mun_code: "118207" },
  // { label: "Olaycon", mun_code: "118207" },
  // { label: "Pasian (Santa Filomena)", mun_code: "118207" },
  // { label: "Poblacion", mun_code: "118207" },
  // { label: "Rizal", mun_code: "118207" },
  // { label: "Salvacion", mun_code: "118207" },
  // { label: "San Isidro", mun_code: "118207" },
  // { label: "San Jose", mun_code: "118207" },
  // { label: "Tubo-tubo (New Del Monte)", mun_code: "118207" },
  // { label: "Upper Ulip", mun_code: "118207" },
  // { label: "Union", mun_code: "118207" },
  // { label: "Mount Diwata", mun_code: "118207" },
  // { label: "Banagbanag", mun_code: "118208" },
  // { label: "Banglasan", mun_code: "118208" },
  // { label: "Bankerohan Norte", mun_code: "118208" },
  // { label: "Bankerohan Sur", mun_code: "118208" },
  // { label: "Camansi", mun_code: "118208" },
  // { label: "Camantangan", mun_code: "118208" },
  // { label: "Concepcion", mun_code: "118208" },
  // { label: "Dauman", mun_code: "118208" },
  // { label: "Canidkid", mun_code: "118208" },
  // { label: "Lebanon", mun_code: "118208" },
  // { label: "Linoan", mun_code: "118208" },
  // { label: "Mayaon", mun_code: "118208" },
  // { label: "New Calape", mun_code: "118208" },
  // { label: "New Dalaguete", mun_code: "118208" },
  // { label: "New Cebulan (Sambayon)", mun_code: "118208" },
  // { label: "New Visayas", mun_code: "118208" },
  // { label: "Prosperidad", mun_code: "118208" },
  // { label: "San Jose (Pob.)", mun_code: "118208" },
  // { label: "San Vicente", mun_code: "118208" },
  // { label: "Tapia", mun_code: "118208" },
  // { label: "Anislagan", mun_code: "118209" },
  // { label: "Antiquera", mun_code: "118209" },
  // { label: "Basak", mun_code: "118209" },
  // { label: "Cabacungan", mun_code: "118209" },
  // { label: "Cabidianan", mun_code: "118209" },
  // { label: "Katipunan", mun_code: "118209" },
  // { label: "Libasan", mun_code: "118209" },
  // { label: "Linda", mun_code: "118209" },
  // { label: "Magading", mun_code: "118209" },
  // { label: "Magsaysay", mun_code: "118209" },
  // { label: "Mainit", mun_code: "118209" },
  // { label: "Manat", mun_code: "118209" },
  // { label: "Matilo", mun_code: "118209" },
  // { label: "Mipangi", mun_code: "118209" },
  // { label: "New Dauis", mun_code: "118209" },
  // { label: "New Sibonga", mun_code: "118209" },
  // { label: "Ogao", mun_code: "118209" },
  // { label: "Pangutosan", mun_code: "118209" },
  // { label: "Poblacion", mun_code: "118209" },
  // { label: "San Isidro", mun_code: "118209" },
  // { label: "San Roque", mun_code: "118209" },
  // { label: "San Vicente", mun_code: "118209" },
  // { label: "Santa Maria", mun_code: "118209" },
  // { label: "Sasa", mun_code: "118209" },
  // { label: "Tagnocon", mun_code: "118209" },
  // { label: "Bayabas", mun_code: "118209" },
  // { label: "Bukal", mun_code: "118209" },
  // { label: "Bantacan", mun_code: "118210" },
  // { label: "Batinao", mun_code: "118210" },
  // { label: "Camanlangan", mun_code: "118210" },
  // { label: "Cogonon", mun_code: "118210" },
  // { label: "Fatima", mun_code: "118210" },
  // { label: "Katipunan", mun_code: "118210" },
  // { label: "Magsaysay", mun_code: "118210" },
  // { label: "Magangit", mun_code: "118210" },
  // { label: "Pagsabangan", mun_code: "118210" },
  // { label: "Panag", mun_code: "118210" },
  // { label: "Cabinuangan (Pob.)", mun_code: "118210" },
  // { label: "San Roque", mun_code: "118210" },
  // { label: "Andap", mun_code: "118210" },
  // { label: "Kahayag", mun_code: "118210" },
  // { label: "Manurigao", mun_code: "118210" },
  // { label: "Tandawan", mun_code: "118210" },
  // { label: "Bongabong", mun_code: "118211" },
  // { label: "Bongbong", mun_code: "118211" },
  // { label: "P. Fuentes", mun_code: "118211" },
  // { label: "Kingking (Pob.)", mun_code: "118211" },
  // { label: "Magnaga", mun_code: "118211" },
  // { label: "Matiao", mun_code: "118211" },
  // { label: "Napnapan", mun_code: "118211" },
  // { label: "Tagdangua", mun_code: "118211" },
  // { label: "Tambongon", mun_code: "118211" },
  // { label: "Tibagon", mun_code: "118211" },
  // { label: "Las Arenas", mun_code: "118211" },
  // { label: "Araibo", mun_code: "118211" },
  // { label: "Tag-Ugpo", mun_code: "118211" },
  // { label: "Calian", mun_code: "118601" },
  // { label: "Kiobog", mun_code: "118601" },
  // { label: "North Lamidan", mun_code: "118601" },
  // { label: "Lawa (Pob.)", mun_code: "118601" },
  // { label: "Nueva Villa", mun_code: "118601" },
  // { label: "Talagutong (Pob.)", mun_code: "118601" },
  // { label: "Baluntaya", mun_code: "118601" },
  // { label: "Dalupan", mun_code: "118601" },
  // { label: "Kinanga", mun_code: "118601" },
  // { label: "Lanao", mun_code: "118601" },
  // { label: "Lapuan", mun_code: "118601" },
  // { label: "Linadasan", mun_code: "118601" },
  // { label: "Mabuhay", mun_code: "118601" },
  // { label: "South Lamidan", mun_code: "118601" },
  // { label: "West Lamidan", mun_code: "118601" },
  // { label: "Buguis", mun_code: "118602" },
  // { label: "Balangonan", mun_code: "118602" },
  // { label: "Bukid", mun_code: "118602" },
  // { label: "Butuan", mun_code: "118602" },
  // { label: "Butulan", mun_code: "118602" },
  // { label: "Caburan Big", mun_code: "118602" },
  // { label: "Caburan Small (Pob.)", mun_code: "118602" },
  // { label: "Camalian", mun_code: "118602" },
  // { label: "Carahayan", mun_code: "118602" },
  // { label: "Cayaponga", mun_code: "118602" },
  // { label: "Culaman", mun_code: "118602" },
  // { label: "Kalbay", mun_code: "118602" },
  // { label: "Kitayo", mun_code: "118602" },
  // { label: "Magulibas", mun_code: "118602" },
  // { label: "Malalan", mun_code: "118602" },
  // { label: "Mangile", mun_code: "118602" },
  // { label: "Marabutuan", mun_code: "118602" },
  // { label: "Meybio", mun_code: "118602" },
  // { label: "Molmol", mun_code: "118602" },
  // { label: "Nuing", mun_code: "118602" },
  // { label: "Patulang", mun_code: "118602" },
  // { label: "Quiapo", mun_code: "118602" },
  // { label: "San Isidro", mun_code: "118602" },
  // { label: "Sugal", mun_code: "118602" },
  // { label: "Tabayon", mun_code: "118602" },
  // { label: "Tanuman", mun_code: "118602" },
  // { label: "Bito", mun_code: "118603" },
  // { label: "Bolila", mun_code: "118603" },
  // { label: "Buhangin", mun_code: "118603" },
  // { label: "Culaman", mun_code: "118603" },
  // { label: "Datu Danwata", mun_code: "118603" },
  // { label: "Demoloc", mun_code: "118603" },
  // { label: "Felis", mun_code: "118603" },
  // { label: "Fishing Village (Fisherman's Village)", mun_code: "118603" },
  // { label: "Kibalatong", mun_code: "118603" },
  // { label: "Kidalapong", mun_code: "118603" },
  // { label: "Kilalag", mun_code: "118603" },
  // { label: "Kinangan", mun_code: "118603" },
  // { label: "Lacaron", mun_code: "118603" },
  // { label: "Lagumit", mun_code: "118603" },
  // { label: "Lais", mun_code: "118603" },
  // { label: "Little Baguio", mun_code: "118603" },
  // { label: "Macol", mun_code: "118603" },
  // { label: "Mana", mun_code: "118603" },
  // { label: "Manuel Peralta", mun_code: "118603" },
  // { label: "New Argao", mun_code: "118603" },
  // { label: "Pangian", mun_code: "118603" },
  // { label: "Pinalpalan", mun_code: "118603" },
  // { label: "Poblacion", mun_code: "118603" },
  // { label: "Sangay", mun_code: "118603" },
  // { label: "Talogoy", mun_code: "118603" },
  // { label: "Tical", mun_code: "118603" },
  // { label: "Ticulon", mun_code: "118603" },
  // { label: "Tingolo", mun_code: "118603" },
  // { label: "Tubalan", mun_code: "118603" },
  // { label: "Pangaleon", mun_code: "118603" },
  // { label: "Basiawan", mun_code: "118604" },
  // { label: "Buca", mun_code: "118604" },
  // { label: "Cadaatan", mun_code: "118604" },
  // { label: "Kidadan", mun_code: "118604" },
  // { label: "Kisulad", mun_code: "118604" },
  // { label: "Malalag Tubig", mun_code: "118604" },
  // { label: "Mamacao", mun_code: "118604" },
  // { label: "Ogpao", mun_code: "118604" },
  // { label: "Poblacion", mun_code: "118604" },
  // { label: "Pongpong", mun_code: "118604" },
  // { label: "San Agustin", mun_code: "118604" },
  // { label: "San Antonio", mun_code: "118604" },
  // { label: "San Isidro", mun_code: "118604" },
  // { label: "San Juan", mun_code: "118604" },
  // { label: "San Pedro", mun_code: "118604" },
  // { label: "San Roque", mun_code: "118604" },
  // { label: "Tanglad", mun_code: "118604" },
  // { label: "Santo Rosario", mun_code: "118604" },
  // { label: "Datu Daligasao", mun_code: "118604" },
  // { label: "Datu Intan", mun_code: "118604" },
  // { label: "Kinilidan", mun_code: "118604" },
  // { label: "Batuganding", mun_code: "118605" },
  // { label: "Konel", mun_code: "118605" },
  // { label: "Lipol", mun_code: "118605" },
  // { label: "Mabila (Pob.)", mun_code: "118605" },
  // { label: "Patuco (Sarangani Norte)", mun_code: "118605" },
  // { label: "Laker (Sarangani Sur)", mun_code: "118605" },
  // { label: "Tinina", mun_code: "118605" },
  // { label: "Camahual", mun_code: "118605" },
  // { label: "Camalig", mun_code: "118605" },
  // { label: "Gomtago", mun_code: "118605" },
  // { label: "Tagen", mun_code: "118605" },
  // { label: "Tucal", mun_code: "118605" },
  // { label: "Bao", mun_code: "124701" },
  // { label: "Barangiran", mun_code: "124701" },
  // { label: "Camansi", mun_code: "124701" },
  // { label: "Dado", mun_code: "124701" },
  // { label: "Guiling", mun_code: "124701" },
  // { label: "Kitacubong (Pob.)", mun_code: "124701" },
  // { label: "Macabasa", mun_code: "124701" },
  // { label: "Malitubog", mun_code: "124701" },
  // { label: "Mapurok", mun_code: "124701" },
  // { label: "Pacao", mun_code: "124701" },
  // { label: "Paruayan", mun_code: "124701" },
  // { label: "Pigcawaran", mun_code: "124701" },
  // { label: "Polayagan", mun_code: "124701" },
  // { label: "Rangayen", mun_code: "124701" },
  // { label: "Lower Dado", mun_code: "124701" },
  // { label: "Mirasol", mun_code: "124701" },
  // { label: "Raradangan", mun_code: "124701" },
  // { label: "Aroman", mun_code: "124702" },
  // { label: "Bentangan", mun_code: "124702" },
  // { label: "Cadiis", mun_code: "124702" },
  // { label: "General Luna", mun_code: "124702" },
  // { label: "Katanayanan", mun_code: "124702" },
  // { label: "Kib-Ayao", mun_code: "124702" },
  // { label: "Kibenes", mun_code: "124702" },
  // { label: "Kibugtongan", mun_code: "124702" },
  // { label: "Kilala", mun_code: "124702" },
  // { label: "Kimadzil", mun_code: "124702" },
  // { label: "Kitulaan", mun_code: "124702" },
  // { label: "Langogan", mun_code: "124702" },
  // { label: "Lanoon", mun_code: "124702" },
  // { label: "Liliongan", mun_code: "124702" },
  // { label: "Ugalingan (Lumayong)", mun_code: "124702" },
  // { label: "Macabenban", mun_code: "124702" },
  // { label: "Malapag", mun_code: "124702" },
  // { label: "Manarapan", mun_code: "124702" },
  // { label: "Manili", mun_code: "124702" },
  // { label: "Nasapian", mun_code: "124702" },
  // { label: "Palanggalan", mun_code: "124702" },
  // { label: "Pebpoloan", mun_code: "124702" },
  // { label: "Poblacion", mun_code: "124702" },
  // { label: "Ranzo", mun_code: "124702" },
  // { label: "Tacupan", mun_code: "124702" },
  // { label: "Tambad", mun_code: "124702" },
  // { label: "Tonganon", mun_code: "124702" },
  // { label: "Tupig", mun_code: "124702" },
  // { label: "Aringay", mun_code: "124703" },
  // { label: "Bangilan", mun_code: "124703" },
  // { label: "Bannawag", mun_code: "124703" },
  // { label: "Buluan", mun_code: "124703" },
  // { label: "Cuyapon", mun_code: "124703" },
  // { label: "Dagupan", mun_code: "124703" },
  // { label: "Katidtuan", mun_code: "124703" },
  // { label: "Kayaga", mun_code: "124703" },
  // { label: "Kilagasan", mun_code: "124703" },
  // { label: "Magatos", mun_code: "124703" },
  // { label: "Malamote", mun_code: "124703" },
  // { label: "Malanduague", mun_code: "124703" },
  // { label: "Nanga-an", mun_code: "124703" },
  // { label: "Osias", mun_code: "124703" },
  // { label: "Paatan Lower", mun_code: "124703" },
  // { label: "Paatan Upper", mun_code: "124703" },
  // { label: "Pedtad", mun_code: "124703" },
  // { label: "Pisan", mun_code: "124703" },
  // { label: "Poblacion", mun_code: "124703" },
  // { label: "Salapungan", mun_code: "124703" },
  // { label: "Sanggadong", mun_code: "124703" },
  // { label: "Simbuhay", mun_code: "124703" },
  // { label: "Simone", mun_code: "124703" },
  // { label: "Tamped", mun_code: "124703" },
  // { label: "Amas", mun_code: "124704" },
  // { label: "Amazion", mun_code: "124704" },
  // { label: "Balabag", mun_code: "124704" },
  // { label: "Balindog", mun_code: "124704" },
  // { label: "Benoligan", mun_code: "124704" },
  // { label: "Berada", mun_code: "124704" },
  // { label: "Gayola", mun_code: "124704" },
  // { label: "Ginatilan", mun_code: "124704" },
  // { label: "Ilomavis", mun_code: "124704" },
  // { label: "Indangan", mun_code: "124704" },
  // { label: "Junction", mun_code: "124704" },
  // { label: "Kalaisan", mun_code: "124704" },
  // { label: "Kalasuyan", mun_code: "124704" },
  // { label: "Katipunan", mun_code: "124704" },
  // { label: "Lanao", mun_code: "124704" },
  // { label: "Linangcob", mun_code: "124704" },
  // { label: "Luvimin", mun_code: "124704" },
  // { label: "Macabolig", mun_code: "124704" },
  // { label: "Malinan", mun_code: "124704" },
  // { label: "Manongol", mun_code: "124704" },
  // { label: "Marbel (Embac)", mun_code: "124704" },
  // { label: "Mateo", mun_code: "124704" },
  // { label: "Meochao", mun_code: "124704" },
  // { label: "Mua-an", mun_code: "124704" },
  // { label: "New Bohol", mun_code: "124704" },
  // { label: "Nuangan", mun_code: "124704" },
  // { label: "Onica", mun_code: "124704" },
  // { label: "Paco", mun_code: "124704" },
  // { label: "Patadon (Patadon East)", mun_code: "124704" },
  // { label: "Perez", mun_code: "124704" },
  // { label: "Poblacion", mun_code: "124704" },
  // { label: "San Isidro", mun_code: "124704" },
  // { label: "San Roque", mun_code: "124704" },
  // { label: "Sibawan", mun_code: "124704" },
  // { label: "Sikitan", mun_code: "124704" },
  // { label: "Singao", mun_code: "124704" },
  // { label: "Sudapin", mun_code: "124704" },
  // { label: "Sumbao", mun_code: "124704" },
  // { label: "Magsaysay", mun_code: "124704" },
  // { label: "Abaga", mun_code: "124705" },
  // { label: "Baguer", mun_code: "124705" },
  // { label: "Barongis", mun_code: "124705" },
  // { label: "Batiocan", mun_code: "124705" },
  // { label: "Cabaruyan", mun_code: "124705" },
  // { label: "Cabpangi", mun_code: "124705" },
  // { label: "Demapaco", mun_code: "124705" },
  // { label: "Grebona", mun_code: "124705" },
  // { label: "Gumaga", mun_code: "124705" },
  // { label: "Kapayawi", mun_code: "124705" },
  // { label: "Kiloyao", mun_code: "124705" },
  // { label: "Kitubod", mun_code: "124705" },
  // { label: "Malengen", mun_code: "124705" },
  // { label: "Montay", mun_code: "124705" },
  // { label: "Nica-an", mun_code: "124705" },
  // { label: "Palao", mun_code: "124705" },
  // { label: "Poblacion", mun_code: "124705" },
  // { label: "Sinapangan", mun_code: "124705" },
  // { label: "Sinawingan", mun_code: "124705" },
  // { label: "Ulamian", mun_code: "124705" },
  // { label: "Alibayon", mun_code: "124706" },
  // { label: "Bagumbayan", mun_code: "124706" },
  // { label: "Bangkal", mun_code: "124706" },
  // { label: "Bantac", mun_code: "124706" },
  // { label: "Basak", mun_code: "124706" },
  // { label: "Binay", mun_code: "124706" },
  // { label: "Bongolanon", mun_code: "124706" },
  // { label: "Datu Celo", mun_code: "124706" },
  // { label: "Del Pilar", mun_code: "124706" },
  // { label: "Doles", mun_code: "124706" },
  // { label: "Gubatan", mun_code: "124706" },
  // { label: "Ilian", mun_code: "124706" },
  // { label: "Inac", mun_code: "124706" },
  // { label: "Kamada", mun_code: "124706" },
  // { label: "Kauswagan", mun_code: "124706" },
  // { label: "Kisandal", mun_code: "124706" },
  // { label: "Magcaalam", mun_code: "124706" },
  // { label: "Mahongcog", mun_code: "124706" },
  // { label: "Manobo", mun_code: "124706" },
  // { label: "Noa", mun_code: "124706" },
  // { label: "Owas", mun_code: "124706" },
  // { label: "Pangao-an", mun_code: "124706" },
  // { label: "Poblacion", mun_code: "124706" },
  // { label: "Sallab", mun_code: "124706" },
  // { label: "Tagbac", mun_code: "124706" },
  // { label: "Temporan", mun_code: "124706" },
  // { label: "Amabel", mun_code: "124706" },
  // { label: "Balete", mun_code: "124706" },
  // { label: "Don Panaca", mun_code: "124706" },
  // { label: "Imamaling", mun_code: "124706" },
  // { label: "Kinarum", mun_code: "124706" },
  // { label: "Manobisa", mun_code: "124706" },
  // { label: "Batasan", mun_code: "124707" },
  // { label: "Bato", mun_code: "124707" },
  // { label: "Biangan", mun_code: "124707" },
  // { label: "Buena Vida", mun_code: "124707" },
  // { label: "Buhay", mun_code: "124707" },
  // { label: "Bulakanon", mun_code: "124707" },
  // { label: "Cabilao", mun_code: "124707" },
  // { label: "Concepcion", mun_code: "124707" },
  // { label: "Dagupan", mun_code: "124707" },
  // { label: "Garsika", mun_code: "124707" },
  // { label: "Guangan", mun_code: "124707" },
  // { label: "Indangan", mun_code: "124707" },
  // { label: "Jose Rizal", mun_code: "124707" },
  // { label: "Katipunan II", mun_code: "124707" },
  // { label: "Kawayanon", mun_code: "124707" },
  // { label: "Kisante", mun_code: "124707" },
  // { label: "Leboce", mun_code: "124707" },
  // { label: "Libertad", mun_code: "124707" },
  // { label: "Luayon", mun_code: "124707" },
  // { label: "Luna Norte", mun_code: "124707" },
  // { label: "Luna Sur", mun_code: "124707" },
  // { label: "Malabuan", mun_code: "124707" },
  // { label: "Malasila", mun_code: "124707" },
  // { label: "Malungon", mun_code: "124707" },
  // { label: "New Baguio", mun_code: "124707" },
  // { label: "New Bulatukan", mun_code: "124707" },
  // { label: "New Cebu", mun_code: "124707" },
  // { label: "Old Bulatukan", mun_code: "124707" },
  // { label: "Poblacion", mun_code: "124707" },
  // { label: "Rodero", mun_code: "124707" },
  // { label: "Saguing", mun_code: "124707" },
  // { label: "San Vicente", mun_code: "124707" },
  // { label: "Santa Felomina", mun_code: "124707" },
  // { label: "Sinkatulan", mun_code: "124707" },
  // { label: "Taluntalunan", mun_code: "124707" },
  // { label: "Villaflores", mun_code: "124707" },
  // { label: "New Israel", mun_code: "124707" },
  // { label: "New Alimodian", mun_code: "124708" },
  // { label: "Arakan", mun_code: "124708" },
  // { label: "Bangbang", mun_code: "124708" },
  // { label: "Bato", mun_code: "124708" },
  // { label: "Central Malamote", mun_code: "124708" },
  // { label: "Dalapitan", mun_code: "124708" },
  // { label: "Estado", mun_code: "124708" },
  // { label: "Ilian", mun_code: "124708" },
  // { label: "Kabulacan", mun_code: "124708" },
  // { label: "Kibia", mun_code: "124708" },
  // { label: "Kibudoc", mun_code: "124708" },
  // { label: "Kidama", mun_code: "124708" },
  // { label: "Kilada", mun_code: "124708" },
  // { label: "Lampayan", mun_code: "124708" },
  // { label: "Latagan", mun_code: "124708" },
  // { label: "Linao", mun_code: "124708" },
  // { label: "Lower Malamote", mun_code: "124708" },
  // { label: "Manubuan", mun_code: "124708" },
  // { label: "Manupal", mun_code: "124708" },
  // { label: "Marbel", mun_code: "124708" },
  // { label: "Minamaing", mun_code: "124708" },
  // { label: "Natutungan", mun_code: "124708" },
  // { label: "New Bugasong", mun_code: "124708" },
  // { label: "New Pandan", mun_code: "124708" },
  // { label: "Patadon West", mun_code: "124708" },
  // { label: "Poblacion", mun_code: "124708" },
  // { label: "Salvacion", mun_code: "124708" },
  // { label: "Santa Maria", mun_code: "124708" },
  // { label: "Sarayan", mun_code: "124708" },
  // { label: "Taculen", mun_code: "124708" },
  // { label: "Taguranao", mun_code: "124708" },
  // { label: "Tamped (Tampad)", mun_code: "124708" },
  // { label: "New Abra", mun_code: "124708" },
  // { label: "Pinamaton", mun_code: "124708" },
  // { label: "Agriculture", mun_code: "124709" },
  // { label: "Anonang", mun_code: "124709" },
  // { label: "Arizona", mun_code: "124709" },
  // { label: "Bagumba", mun_code: "124709" },
  // { label: "Baliki", mun_code: "124709" },
  // { label: "Bitoka", mun_code: "124709" },
  // { label: "Bual Norte", mun_code: "124709" },
  // { label: "Bual Sur", mun_code: "124709" },
  // { label: "Central Bulanan", mun_code: "124709" },
  // { label: "Damatulan", mun_code: "124709" },
  // { label: "Central Glad", mun_code: "124709" },
  // { label: "Ilbocean", mun_code: "124709" },
  // { label: "Kadigasan", mun_code: "124709" },
  // { label: "Kadingilan", mun_code: "124709" },
  // { label: "Kapinpilan", mun_code: "124709" },
  // { label: "Central Katingawan", mun_code: "124709" },
  // { label: "Kimagango", mun_code: "124709" },
  // { label: "Kudarangan", mun_code: "124709" },
  // { label: "Central Labas", mun_code: "124709" },
  // { label: "Lagumbingan", mun_code: "124709" },
  // { label: "Lomopog", mun_code: "124709" },
  // { label: "Lower Glad", mun_code: "124709" },
  // { label: "Lower Katingawan", mun_code: "124709" },
  // { label: "Macasendeg", mun_code: "124709" },
  // { label: "Malamote", mun_code: "124709" },
  // { label: "Malingao", mun_code: "124709" },
  // { label: "Milaya", mun_code: "124709" },
  // { label: "Mudseng", mun_code: "124709" },
  // { label: "Nabalawag", mun_code: "124709" },
  // { label: "Nalin", mun_code: "124709" },
  // { label: "Nes", mun_code: "124709" },
  // { label: "Olandang", mun_code: "124709" },
  // { label: "Patindeguen", mun_code: "124709" },
  // { label: "Barangay Poblacion 1", mun_code: "124709" },
  // { label: "Barangay Poblacion 2", mun_code: "124709" },
  // { label: "Barangay Poblacion 3", mun_code: "124709" },
  // { label: "Barangay Poblacion 4", mun_code: "124709" },
  // { label: "Barangay Poblacion 5", mun_code: "124709" },
  // { label: "Barangay Poblacion 6", mun_code: "124709" },
  // { label: "Barangay Poblacion 7", mun_code: "124709" },
  // { label: "Barangay Poblacion 8", mun_code: "124709" },
  // { label: "Palongoguen", mun_code: "124709" },
  // { label: "Rangaban", mun_code: "124709" },
  // { label: "Sadaan", mun_code: "124709" },
  // { label: "Salunayan", mun_code: "124709" },
  // { label: "Sambulawan", mun_code: "124709" },
  // { label: "San Isidro", mun_code: "124709" },
  // { label: "Santa Cruz", mun_code: "124709" },
  // { label: "Tugal", mun_code: "124709" },
  // { label: "Tumbras", mun_code: "124709" },
  // { label: "Bulanan Upper", mun_code: "124709" },
  // { label: "Upper Glad I", mun_code: "124709" },
  // { label: "Upper Glad II", mun_code: "124709" },
  // { label: "Upper Labas", mun_code: "124709" },
  // { label: "Villarica", mun_code: "124709" },
  // { label: "Kiwanan", mun_code: "124709" },
  // { label: "San Pedro", mun_code: "124709" },
  // { label: "Bagontapay", mun_code: "124710" },
  // { label: "Bialong", mun_code: "124710" },
  // { label: "Buayan", mun_code: "124710" },
  // { label: "Calunasan", mun_code: "124710" },
  // { label: "Dalipe", mun_code: "124710" },
  // { label: "Dagong", mun_code: "124710" },
  // { label: "Dungo-an", mun_code: "124710" },
  // { label: "Gaunan", mun_code: "124710" },
  // { label: "Inas", mun_code: "124710" },
  // { label: "Katipunan", mun_code: "124710" },
  // { label: "La Fortuna", mun_code: "124710" },
  // { label: "La Suerte", mun_code: "124710" },
  // { label: "Langkong", mun_code: "124710" },
  // { label: "Lepaga", mun_code: "124710" },
  // { label: "Liboo", mun_code: "124710" },
  // { label: "Lika", mun_code: "124710" },
  // { label: "Luz Village", mun_code: "124710" },
  // { label: "Magallon", mun_code: "124710" },
  // { label: "Malayan", mun_code: "124710" },
  // { label: "New Antique", mun_code: "124710" },
  // { label: "New Barbaza", mun_code: "124710" },
  // { label: "New Kalibo", mun_code: "124710" },
  // { label: "New Consolacion", mun_code: "124710" },
  // { label: "New Esperanza", mun_code: "124710" },
  // { label: "New Janiuay", mun_code: "124710" },
  // { label: "New Lawa-an", mun_code: "124710" },
  // { label: "New Rizal", mun_code: "124710" },
  // { label: "Nueva Vida", mun_code: "124710" },
  // { label: "Pag-asa", mun_code: "124710" },
  // { label: "Poblacion", mun_code: "124710" },
  // { label: "Pulang-lupa", mun_code: "124710" },
  // { label: "Sangat", mun_code: "124710" },
  // { label: "Tawantawan", mun_code: "124710" },
  // { label: "Tibao", mun_code: "124710" },
  // { label: "Ugpay", mun_code: "124710" },
  // { label: "Palma-Perez", mun_code: "124710" },
  // { label: "Poblacion B", mun_code: "124710" },
  // { label: "Anick (Upper Balogo)", mun_code: "124711" },
  // { label: "Upper Baguer (Baguer)", mun_code: "124711" },
  // { label: "Balacayon", mun_code: "124711" },
  // { label: "Balogo", mun_code: "124711" },
  // { label: "Banucagon", mun_code: "124711" },
  // { label: "Bulucaon", mun_code: "124711" },
  // { label: "Buluan", mun_code: "124711" },
  // { label: "Buricain", mun_code: "124711" },
  // { label: "Capayuran", mun_code: "124711" },
  // { label: "Central Panatan", mun_code: "124711" },
  // { label: "Datu Binasing", mun_code: "124711" },
  // { label: "Datu Mantil", mun_code: "124711" },
  // { label: "Kadingilan", mun_code: "124711" },
  // { label: "Kimarayang", mun_code: "124711" },
  // { label: "Libungan Torreta", mun_code: "124711" },
  // { label: "Lower Baguer", mun_code: "124711" },
  // { label: "Lower Pangangkalan", mun_code: "124711" },
  // { label: "Malagakit", mun_code: "124711" },
  // { label: "Maluao", mun_code: "124711" },
  // { label: "North Manuangan", mun_code: "124711" },
  // { label: "Matilac", mun_code: "124711" },
  // { label: "Midpapan I", mun_code: "124711" },
  // { label: "Mulok", mun_code: "124711" },
  // { label: "New Culasi", mun_code: "124711" },
  // { label: "New Igbaras", mun_code: "124711" },
  // { label: "New Panay", mun_code: "124711" },
  // { label: "Upper Pangangkalan", mun_code: "124711" },
  // { label: "Patot", mun_code: "124711" },
  // { label: "Payong-payong", mun_code: "124711" },
  // { label: "Poblacion I", mun_code: "124711" },
  // { label: "Poblacion II", mun_code: "124711" },
  // { label: "Poblacion III", mun_code: "124711" },
  // { label: "Presbitero", mun_code: "124711" },
  // { label: "Renibon", mun_code: "124711" },
  // { label: "Simsiman", mun_code: "124711" },
  // { label: "South Manuangan", mun_code: "124711" },
  // { label: "Tigbawan", mun_code: "124711" },
  // { label: "Tubon", mun_code: "124711" },
  // { label: "Midpapan II", mun_code: "124711" },
  // { label: "Cabpangi", mun_code: "124711" },
  // { label: "Bagoaingud (Bagoinged)", mun_code: "124712" },
  // { label: "Balabak", mun_code: "124712" },
  // { label: "Balatican", mun_code: "124712" },
  // { label: "Balong", mun_code: "124712" },
  // { label: "Balungis", mun_code: "124712" },
  // { label: "Barungis", mun_code: "124712" },
  // { label: "Batulawan", mun_code: "124712" },
  // { label: "Bualan", mun_code: "124712" },
  // { label: "Buliok", mun_code: "124712" },
  // { label: "Bulod", mun_code: "124712" },
  // { label: "Bulol", mun_code: "124712" },
  // { label: "Calawag", mun_code: "124712" },
  // { label: "Dalingaoen (Lalingaon)", mun_code: "124712" },
  // { label: "Damalasak", mun_code: "124712" },
  // { label: "Fort Pikit", mun_code: "124712" },
  // { label: "Ginatilan", mun_code: "124712" },
  // { label: "Gligli", mun_code: "124712" },
  // { label: "Gokoton (Gokotan)", mun_code: "124712" },
  // { label: "Inug-ug", mun_code: "124712" },
  // { label: "Kabasalan", mun_code: "124712" },
  // { label: "Kalacacan", mun_code: "124712" },
  // { label: "Katilacan", mun_code: "124712" },
  // { label: "Kolambog", mun_code: "124712" },
  // { label: "Ladtingan", mun_code: "124712" },
  // { label: "Lagunde", mun_code: "124712" },
  // { label: "Langayen", mun_code: "124712" },
  // { label: "Macabual", mun_code: "124712" },
  // { label: "Macasendeg", mun_code: "124712" },
  // { label: "Manaulanan", mun_code: "124712" },
  // { label: "Nabundas", mun_code: "124712" },
  // { label: "Nalapaan", mun_code: "124712" },
  // { label: "Nunguan", mun_code: "124712" },
  // { label: "Paidu Pulangi", mun_code: "124712" },
  // { label: "Panicupan", mun_code: "124712" },
  // { label: "Poblacion", mun_code: "124712" },
  // { label: "Punol", mun_code: "124712" },
  // { label: "Rajah Muda", mun_code: "124712" },
  // { label: "Silik", mun_code: "124712" },
  // { label: "Takipan", mun_code: "124712" },
  // { label: "Talitay", mun_code: "124712" },
  // { label: "Tinutulan", mun_code: "124712" },
  // { label: "Pamalian", mun_code: "124712" },
  // { label: "Alegria", mun_code: "124713" },
  // { label: "Bato-bato", mun_code: "124713" },
  // { label: "Del Carmen", mun_code: "124713" },
  // { label: "F. Cajelo (New Maasin)", mun_code: "124713" },
  // { label: "Idaoman", mun_code: "124713" },
  // { label: "Ilustre", mun_code: "124713" },
  // { label: "Kamarahan", mun_code: "124713" },
  // { label: "Camasi", mun_code: "124713" },
  // { label: "Kisupaan", mun_code: "124713" },
  // { label: "La Esperanza", mun_code: "124713" },
  // { label: "Labu-o", mun_code: "124713" },
  // { label: "Lamalama", mun_code: "124713" },
  // { label: "Lomonay", mun_code: "124713" },
  // { label: "New Cebu", mun_code: "124713" },
  // { label: "Poblacion", mun_code: "124713" },
  // { label: "Sagcungan", mun_code: "124713" },
  // { label: "Salat", mun_code: "124713" },
  // { label: "Sarayan", mun_code: "124713" },
  // { label: "Tuael", mun_code: "124713" },
  // { label: "Greenhill", mun_code: "124713" },
  // { label: "Cabangbangan", mun_code: "124713" },
  // { label: "Datu Indang", mun_code: "124713" },
  // { label: "Datu Sandongan", mun_code: "124713" },
  // { label: "Kimaruhing", mun_code: "124713" },
  // { label: "Mabuhay", mun_code: "124713" },
  // { label: "Bagumbayan", mun_code: "124714" },
  // { label: "Banayal", mun_code: "124714" },
  // { label: "Batang", mun_code: "124714" },
  // { label: "Bituan", mun_code: "124714" },
  // { label: "Bual", mun_code: "124714" },
  // { label: "Daig", mun_code: "124714" },
  // { label: "Damawato", mun_code: "124714" },
  // { label: "Dungos", mun_code: "124714" },
  // { label: "Kanibong", mun_code: "124714" },
  // { label: "La Esperanza", mun_code: "124714" },
  // { label: "Lampagang", mun_code: "124714" },
  // { label: "Bunawan", mun_code: "124714" },
  // { label: "Magbok", mun_code: "124714" },
  // { label: "Maybula", mun_code: "124714" },
  // { label: "Minapan", mun_code: "124714" },
  // { label: "New Caridad", mun_code: "124714" },
  // { label: "New Culasi", mun_code: "124714" },
  // { label: "New Panay", mun_code: "124714" },
  // { label: "Paraiso", mun_code: "124714" },
  // { label: "Poblacion", mun_code: "124714" },
  // { label: "Popoyon", mun_code: "124714" },
  // { label: "Sibsib", mun_code: "124714" },
  // { label: "Tambac", mun_code: "124714" },
  // { label: "Tuburan", mun_code: "124714" },
  // { label: "F. Cajelo", mun_code: "124714" },
  // { label: "Bacong", mun_code: "124714" },
  // { label: "Galidan", mun_code: "124714" },
  // { label: "Genoveva Baynosa", mun_code: "124714" },
  // { label: "Nabundasan", mun_code: "124714" },
  // { label: "Camutan", mun_code: "124715" },
  // { label: "Canaan", mun_code: "124715" },
  // { label: "Dolores", mun_code: "124715" },
  // { label: "Kiyaab", mun_code: "124715" },
  // { label: "Luhong", mun_code: "124715" },
  // { label: "Magsaysay", mun_code: "124715" },
  // { label: "Malangag", mun_code: "124715" },
  // { label: "Malatad", mun_code: "124715" },
  // { label: "Malire", mun_code: "124715" },
  // { label: "New Pontevedra", mun_code: "124715" },
  // { label: "Poblacion", mun_code: "124715" },
  // { label: "B. Cadungon", mun_code: "124715" },
  // { label: "Datu Agod", mun_code: "124715" },
  // { label: "Banisilan Poblacion", mun_code: "124716" },
  // { label: "Busaon", mun_code: "124716" },
  // { label: "Capayangan", mun_code: "124716" },
  // { label: "Carugmanan", mun_code: "124716" },
  // { label: "Gastay", mun_code: "124716" },
  // { label: "Kalawaig", mun_code: "124716" },
  // { label: "Kiaring", mun_code: "124716" },
  // { label: "Malagap", mun_code: "124716" },
  // { label: "Malinao", mun_code: "124716" },
  // { label: "Miguel Macasarte", mun_code: "124716" },
  // { label: "Pantar", mun_code: "124716" },
  // { label: "Paradise", mun_code: "124716" },
  // { label: "Pinamulaan", mun_code: "124716" },
  // { label: "Poblacion II", mun_code: "124716" },
  // { label: "Puting-bato", mun_code: "124716" },
  // { label: "Solama", mun_code: "124716" },
  // { label: "Thailand", mun_code: "124716" },
  // { label: "Tinimbacan", mun_code: "124716" },
  // { label: "Tumbao-Camalig", mun_code: "124716" },
  // { label: "Wadya", mun_code: "124716" },
  // { label: "Bagolibas", mun_code: "124717" },
  // { label: "Cawilihan", mun_code: "124717" },
  // { label: "Dualing", mun_code: "124717" },
  // { label: "Dunguan", mun_code: "124717" },
  // { label: "Katalicanan", mun_code: "124717" },
  // { label: "Lawili", mun_code: "124717" },
  // { label: "Lower Mingading", mun_code: "124717" },
  // { label: "Luanan", mun_code: "124717" },
  // { label: "Malapang", mun_code: "124717" },
  // { label: "New Leon", mun_code: "124717" },
  // { label: "New Panay", mun_code: "124717" },
  // { label: "Pagangan", mun_code: "124717" },
  // { label: "Palacat", mun_code: "124717" },
  // { label: "Pentil", mun_code: "124717" },
  // { label: "San Mateo", mun_code: "124717" },
  // { label: "Santa Cruz", mun_code: "124717" },
  // { label: "Tapodoc", mun_code: "124717" },
  // { label: "Tomado", mun_code: "124717" },
  // { label: "Upper Mingading", mun_code: "124717" },
  // { label: "Allab", mun_code: "124718" },
  // { label: "Anapolon", mun_code: "124718" },
  // { label: "Badiangon", mun_code: "124718" },
  // { label: "Binoongan", mun_code: "124718" },
  // { label: "Dallag", mun_code: "124718" },
  // { label: "Datu Ladayon", mun_code: "124718" },
  // { label: "Datu Matangkil", mun_code: "124718" },
  // { label: "Doroluman", mun_code: "124718" },
  // { label: "Gambodes", mun_code: "124718" },
  // { label: "Ganatan", mun_code: "124718" },
  // { label: "Greenfield", mun_code: "124718" },
  // { label: "Kabalantian", mun_code: "124718" },
  // { label: "Katipunan", mun_code: "124718" },
  // { label: "Kinawayan", mun_code: "124718" },
  // { label: "Kulaman Valley", mun_code: "124718" },
  // { label: "Lanao Kuran", mun_code: "124718" },
  // { label: "Libertad", mun_code: "124718" },
  // { label: "Makalangot", mun_code: "124718" },
  // { label: "Malibatuan", mun_code: "124718" },
  // { label: "Maria Caridad", mun_code: "124718" },
  // { label: "Meocan", mun_code: "124718" },
  // { label: "Naje", mun_code: "124718" },
  // { label: "Napalico", mun_code: "124718" },
  // { label: "Salasang", mun_code: "124718" },
  // { label: "San Miguel", mun_code: "124718" },
  // { label: "Sumalili", mun_code: "124718" },
  // { label: "Tumanding", mun_code: "124718" },
  // { label: "Benitez (Pob.)", mun_code: "126302" },
  // { label: "Cabudian", mun_code: "126302" },
  // { label: "Cabuling", mun_code: "126302" },
  // { label: "Cinco (Barrio 5)", mun_code: "126302" },
  // { label: "Derilon", mun_code: "126302" },
  // { label: "El Nonok", mun_code: "126302" },
  // { label: "Improgo Village (Pob.)", mun_code: "126302" },
  // { label: "Kusan (Barrio 8)", mun_code: "126302" },
  // { label: "Lam-Apos", mun_code: "126302" },
  // { label: "Lamba", mun_code: "126302" },
  // { label: "Lambingi", mun_code: "126302" },
  // { label: "Lampari", mun_code: "126302" },
  // { label: "Liwanay (Barrio 1)", mun_code: "126302" },
  // { label: "Malaya (Barrio 9)", mun_code: "126302" },
  // { label: "Punong Grande (Barrio 2)", mun_code: "126302" },
  // { label: "Rang-ay (Barrio 4)", mun_code: "126302" },
  // { label: "Reyes (Pob.)", mun_code: "126302" },
  // { label: "Rizal (Barrio 3)", mun_code: "126302" },
  // { label: "Rizal Poblacion", mun_code: "126302" },
  // { label: "San Jose (Barrio 7)", mun_code: "126302" },
  // { label: "San Vicente (Barrio 6)", mun_code: "126302" },
  // { label: "Yangco Poblacion", mun_code: "126302" },
  // { label: "Baluan", mun_code: "126303" },
  // { label: "Buayan", mun_code: "126303" },
  // { label: "Bula", mun_code: "126303" },
  // { label: "Conel", mun_code: "126303" },
  // { label: "Dadiangas East (Pob.)", mun_code: "126303" },
  // { label: "Katangawan", mun_code: "126303" },
  // { label: "Lagao (1st & 3rd)", mun_code: "126303" },
  // { label: "Labangal", mun_code: "126303" },
  // { label: "Ligaya", mun_code: "126303" },
  // { label: "Mabuhay", mun_code: "126303" },
  // { label: "San Isidro (Lagao 2nd)", mun_code: "126303" },
  // { label: "San Jose", mun_code: "126303" },
  // { label: "Sinawal", mun_code: "126303" },
  // { label: "Tambler", mun_code: "126303" },
  // { label: "Tinagacan", mun_code: "126303" },
  // { label: "Apopong", mun_code: "126303" },
  // { label: "Siguel", mun_code: "126303" },
  // { label: "Upper Labay", mun_code: "126303" },
  // { label: "Batomelong", mun_code: "126303" },
  // { label: "Calumpang", mun_code: "126303" },
  // { label: "City Heights", mun_code: "126303" },
  // { label: "Dadiangas North", mun_code: "126303" },
  // { label: "Dadiangas South", mun_code: "126303" },
  // { label: "Dadiangas West", mun_code: "126303" },
  // { label: "Fatima", mun_code: "126303" },
  // { label: "Olympog", mun_code: "126303" },
  // { label: "Assumption (Bulol)", mun_code: "126306" },
  // { label: "Cacub", mun_code: "126306" },
  // { label: "Caloocan", mun_code: "126306" },
  // { label: "Carpenter Hill", mun_code: "126306" },
  // { label: "Concepcion (Bo. 6)", mun_code: "126306" },
  // { label: "Esperanza", mun_code: "126306" },
  // { label: "General Paulino Santos (Bo. 1)", mun_code: "126306" },
  // { label: "Mabini", mun_code: "126306" },
  // { label: "Magsaysay", mun_code: "126306" },
  // { label: "Mambucal", mun_code: "126306" },
  // { label: "Morales", mun_code: "126306" },
  // { label: "Namnama", mun_code: "126306" },
  // { label: "New Pangasinan (Bo. 4)", mun_code: "126306" },
  // { label: "Paraiso", mun_code: "126306" },
  // { label: "Zone I (Pob.)", mun_code: "126306" },
  // { label: "Zone II (Pob.)", mun_code: "126306" },
  // { label: "Zone III (Pob.)", mun_code: "126306" },
  // { label: "Zone IV (Pob.)", mun_code: "126306" },
  // { label: "Rotonda", mun_code: "126306" },
  // { label: "San Isidro", mun_code: "126306" },
  // { label: "San Jose (Bo. 5)", mun_code: "126306" },
  // { label: "San Roque", mun_code: "126306" },
  // { label: "Santa Cruz", mun_code: "126306" },
  // { label: "Sarabia (Bo. 8)", mun_code: "126306" },
  // { label: "Zulueta (Bo. 7)", mun_code: "126306" },
  // { label: "Dumaguil", mun_code: "126311" },
  // { label: "Esperanza", mun_code: "126311" },
  // { label: "Kibid", mun_code: "126311" },
  // { label: "Lapuz", mun_code: "126311" },
  // { label: "Liberty", mun_code: "126311" },
  // { label: "Lopez Jaena", mun_code: "126311" },
  // { label: "Matapol", mun_code: "126311" },
  // { label: "Poblacion", mun_code: "126311" },
  // { label: "Puti", mun_code: "126311" },
  // { label: "San Jose", mun_code: "126311" },
  // { label: "San Miguel", mun_code: "126311" },
  // { label: "Simsiman", mun_code: "126311" },
  // { label: "Tinago", mun_code: "126311" },
  // { label: "Benigno Aquino, Jr.", mun_code: "126311" },
  // { label: "Bentung", mun_code: "126312" },
  // { label: "Crossing Palkan", mun_code: "126312" },
  // { label: "Glamang", mun_code: "126312" },
  // { label: "Kinilis", mun_code: "126312" },
  // { label: "Klinan 6", mun_code: "126312" },
  // { label: "Koronadal Proper", mun_code: "126312" },
  // { label: "Lam-Caliaf", mun_code: "126312" },
  // { label: "Landan", mun_code: "126312" },
  // { label: "Lumakil", mun_code: "126312" },
  // { label: "Maligo", mun_code: "126312" },
  // { label: "Palkan", mun_code: "126312" },
  // { label: "Poblacion", mun_code: "126312" },
  // { label: "Polo", mun_code: "126312" },
  // { label: "Magsaysay", mun_code: "126312" },
  // { label: "Rubber", mun_code: "126312" },
  // { label: "Silway 7", mun_code: "126312" },
  // { label: "Silway 8", mun_code: "126312" },
  // { label: "Sulit", mun_code: "126312" },
  // { label: "Sumbakil", mun_code: "126312" },
  // { label: "Upper Klinan", mun_code: "126312" },
  // { label: "Lapu", mun_code: "126312" },
  // { label: "Cannery Site", mun_code: "126312" },
  // { label: "Pagalungan", mun_code: "126312" },
  // { label: "Buenavista", mun_code: "126313" },
  // { label: "Centrala", mun_code: "126313" },
  // { label: "Colongulo", mun_code: "126313" },
  // { label: "Dajay", mun_code: "126313" },
  // { label: "Duengas", mun_code: "126313" },
  // { label: "Canahay (Godwino)", mun_code: "126313" },
  // { label: "Lambontong", mun_code: "126313" },
  // { label: "Lamian", mun_code: "126313" },
  // { label: "Lamsugod", mun_code: "126313" },
  // { label: "Libertad (Pob.)", mun_code: "126313" },
  // { label: "Little Baguio", mun_code: "126313" },
  // { label: "Moloy", mun_code: "126313" },
  // { label: "Naci (Doce)", mun_code: "126313" },
  // { label: "Talahik", mun_code: "126313" },
  // { label: "Tubiala", mun_code: "126313" },
  // { label: "Upper Sepaka", mun_code: "126313" },
  // { label: "Veterans", mun_code: "126313" },
  // { label: "Albagan", mun_code: "126314" },
  // { label: "Kipalbig", mun_code: "126314" },
  // { label: "Lambayong", mun_code: "126314" },
  // { label: "Liberty", mun_code: "126314" },
  // { label: "Maltana", mun_code: "126314" },
  // { label: "Poblacion", mun_code: "126314" },
  // { label: "Tablu", mun_code: "126314" },
  // { label: "Buto", mun_code: "126314" },
  // { label: "Lampitak", mun_code: "126314" },
  // { label: "Palo", mun_code: "126314" },
  // { label: "Pula-bato", mun_code: "126314" },
  // { label: "Danlag", mun_code: "126314" },
  // { label: "San Isidro", mun_code: "126314" },
  // { label: "Santa Cruz", mun_code: "126314" },
  // { label: "Bukay Pait", mun_code: "126315" },
  // { label: "Cabuling", mun_code: "126315" },
  // { label: "Dumadalig", mun_code: "126315" },
  // { label: "Libas", mun_code: "126315" },
  // { label: "Magon", mun_code: "126315" },
  // { label: "Maibo", mun_code: "126315" },
  // { label: "Mangilala", mun_code: "126315" },
  // { label: "New Iloilo", mun_code: "126315" },
  // { label: "New Lambunao", mun_code: "126315" },
  // { label: "Poblacion", mun_code: "126315" },
  // { label: "San Felipe", mun_code: "126315" },
  // { label: "New Cuyapo", mun_code: "126315" },
  // { label: "Tinongcop", mun_code: "126315" },
  // { label: "Basag", mun_code: "126316" },
  // { label: "Edwards (Pob.)", mun_code: "126316" },
  // { label: "Kematu", mun_code: "126316" },
  // { label: "Laconon", mun_code: "126316" },
  // { label: "Lamsalome", mun_code: "126316" },
  // { label: "New Dumangas", mun_code: "126316" },
  // { label: "Sinolon", mun_code: "126316" },
  // { label: "Lambangan", mun_code: "126316" },
  // { label: "Maan", mun_code: "126316" },
  // { label: "Afus", mun_code: "126316" },
  // { label: "Lambuling", mun_code: "126316" },
  // { label: "Lamhako", mun_code: "126316" },
  // { label: "Poblacion", mun_code: "126316" },
  // { label: "Talcon", mun_code: "126316" },
  // { label: "Talufo", mun_code: "126316" },
  // { label: "Tudok", mun_code: "126316" },
  // { label: "Aflek", mun_code: "126316" },
  // { label: "Datal Bob", mun_code: "126316" },
  // { label: "Desawo", mun_code: "126316" },
  // { label: "Dlanag", mun_code: "126316" },
  // { label: "Lemsnolon", mun_code: "126316" },
  // { label: "Malugong", mun_code: "126316" },
  // { label: "Mongocayo", mun_code: "126316" },
  // { label: "Salacafe", mun_code: "126316" },
  // { label: "T'bolok", mun_code: "126316" },
  // { label: "Acmonan", mun_code: "126317" },
  // { label: "Bololmala", mun_code: "126317" },
  // { label: "Bunao", mun_code: "126317" },
  // { label: "Cebuano", mun_code: "126317" },
  // { label: "Crossing Rubber", mun_code: "126317" },
  // { label: "Kablon", mun_code: "126317" },
  // { label: "Kalkam", mun_code: "126317" },
  // { label: "Linan", mun_code: "126317" },
  // { label: "Lunen", mun_code: "126317" },
  // { label: "Miasong", mun_code: "126317" },
  // { label: "Palian", mun_code: "126317" },
  // { label: "Poblacion", mun_code: "126317" },
  // { label: "Polonuling", mun_code: "126317" },
  // { label: "Simbo", mun_code: "126317" },
  // { label: "Tubeng", mun_code: "126317" },
  // { label: "Ambalgan", mun_code: "126318" },
  // { label: "Guinsang-an", mun_code: "126318" },
  // { label: "Katipunan", mun_code: "126318" },
  // { label: "Manuel Roxas", mun_code: "126318" },
  // { label: "Panay", mun_code: "126318" },
  // { label: "Poblacion", mun_code: "126318" },
  // { label: "San Isidro", mun_code: "126318" },
  // { label: "San Vicente", mun_code: "126318" },
  // { label: "Teresita", mun_code: "126318" },
  // { label: "Sajaneba", mun_code: "126318" },
  // { label: "Bacdulong", mun_code: "126319" },
  // { label: "Denlag", mun_code: "126319" },
  // { label: "Halilan", mun_code: "126319" },
  // { label: "Hanoon", mun_code: "126319" },
  // { label: "Klubi", mun_code: "126319" },
  // { label: "Lake Lahit", mun_code: "126319" },
  // { label: "Lamcade", mun_code: "126319" },
  // { label: "Lamdalag", mun_code: "126319" },
  // { label: "Lamfugon", mun_code: "126319" },
  // { label: "Lamlahak", mun_code: "126319" },
  // { label: "Lower Maculan", mun_code: "126319" },
  // { label: "Luhib", mun_code: "126319" },
  // { label: "Ned", mun_code: "126319" },
  // { label: "Poblacion", mun_code: "126319" },
  // { label: "Siluton", mun_code: "126319" },
  // { label: "Talisay", mun_code: "126319" },
  // { label: "Takunel", mun_code: "126319" },
  // { label: "Upper Maculan", mun_code: "126319" },
  // { label: "Tasiman", mun_code: "126319" },
  // { label: "Bai Sarifinang", mun_code: "126501" },
  // { label: "Biwang", mun_code: "126501" },
  // { label: "Busok", mun_code: "126501" },
  // { label: "Daguma", mun_code: "126501" },
  // { label: "Kapaya", mun_code: "126501" },
  // { label: "Kinayao", mun_code: "126501" },
  // { label: "Masiag", mun_code: "126501" },
  // { label: "Poblacion", mun_code: "126501" },
  // { label: "South Sepaka", mun_code: "126501" },
  // { label: "Tuka", mun_code: "126501" },
  // { label: "Chua", mun_code: "126501" },
  // { label: "Daluga", mun_code: "126501" },
  // { label: "Kabulanan", mun_code: "126501" },
  // { label: "Kanulay", mun_code: "126501" },
  // { label: "Monteverde", mun_code: "126501" },
  // { label: "Sumilil", mun_code: "126501" },
  // { label: "Titulok", mun_code: "126501" },
  // { label: "Sison", mun_code: "126501" },
  // { label: "Bantangan (Lasak)", mun_code: "126502" },
  // { label: "Datablao", mun_code: "126502" },
  // { label: "Eday", mun_code: "126502" },
  // { label: "Elbebe", mun_code: "126502" },
  // { label: "Libertad", mun_code: "126502" },
  // { label: "Lomoyon", mun_code: "126502" },
  // { label: "Makat (Sumali Pas)", mun_code: "126502" },
  // { label: "Maligaya", mun_code: "126502" },
  // { label: "Mayo", mun_code: "126502" },
  // { label: "Natividad", mun_code: "126502" },
  // { label: "Poblacion", mun_code: "126502" },
  // { label: "Polomolok", mun_code: "126502" },
  // { label: "Sinapulan", mun_code: "126502" },
  // { label: "Sucob", mun_code: "126502" },
  // { label: "Telafas", mun_code: "126502" },
  // { label: "Lasak", mun_code: "126502" },
  // { label: "Ala", mun_code: "126503" },
  // { label: "Daladap", mun_code: "126503" },
  // { label: "Dukay", mun_code: "126503" },
  // { label: "Guiamalia", mun_code: "126503" },
  // { label: "Ilian", mun_code: "126503" },
  // { label: "Kangkong", mun_code: "126503" },
  // { label: "Margues", mun_code: "126503" },
  // { label: "New Panay", mun_code: "126503" },
  // { label: "Numo", mun_code: "126503" },
  // { label: "Pamantingan", mun_code: "126503" },
  // { label: "Poblacion", mun_code: "126503" },
  // { label: "Sagasa", mun_code: "126503" },
  // { label: "Salabaca", mun_code: "126503" },
  // { label: "Villamor", mun_code: "126503" },
  // { label: "Laguinding", mun_code: "126503" },
  // { label: "Magsaysay", mun_code: "126503" },
  // { label: "Paitan", mun_code: "126503" },
  // { label: "Saliao", mun_code: "126503" },
  // { label: "Salumping", mun_code: "126503" },
  // { label: "Bambad", mun_code: "126504" },
  // { label: "Bual", mun_code: "126504" },
  // { label: "D'Lotilla", mun_code: "126504" },
  // { label: "Dansuli", mun_code: "126504" },
  // { label: "Impao", mun_code: "126504" },
  // { label: "Kalawag I (Pob.)", mun_code: "126504" },
  // { label: "Kalawag II (Pob.)", mun_code: "126504" },
  // { label: "Kalawag III (Pob.)", mun_code: "126504" },
  // { label: "Kenram", mun_code: "126504" },
  // { label: "Kudanding", mun_code: "126504" },
  // { label: "Kolambog", mun_code: "126504" },
  // { label: "Lagandang", mun_code: "126504" },
  // { label: "Laguilayan", mun_code: "126504" },
  // { label: "Mapantig", mun_code: "126504" },
  // { label: "New Pangasinan", mun_code: "126504" },
  // { label: "Sampao", mun_code: "126504" },
  // { label: "Tayugo", mun_code: "126504" },
  // { label: "Bantogon (Santa Clara)", mun_code: "126505" },
  // { label: "Cadiz", mun_code: "126505" },
  // { label: "Dumangas Nuevo", mun_code: "126505" },
  // { label: "Hinalaan", mun_code: "126505" },
  // { label: "Limulan", mun_code: "126505" },
  // { label: "Obial", mun_code: "126505" },
  // { label: "Paril", mun_code: "126505" },
  // { label: "Poblacion", mun_code: "126505" },
  // { label: "Sangay", mun_code: "126505" },
  // { label: "Santa Maria", mun_code: "126505" },
  // { label: "Datu Ito Andong", mun_code: "126505" },
  // { label: "Datu Wasay", mun_code: "126505" },
  // { label: "Nalilidan", mun_code: "126505" },
  // { label: "Sabanal", mun_code: "126505" },
  // { label: "Pag-asa", mun_code: "126505" },
  // { label: "Barurao", mun_code: "126506" },
  // { label: "Basak", mun_code: "126506" },
  // { label: "Bululawan", mun_code: "126506" },
  // { label: "Capilan", mun_code: "126506" },
  // { label: "Christiannuevo", mun_code: "126506" },
  // { label: "Datu Karon", mun_code: "126506" },
  // { label: "Kalamongog", mun_code: "126506" },
  // { label: "Keytodac", mun_code: "126506" },
  // { label: "Kinodalan", mun_code: "126506" },
  // { label: "New Calinog", mun_code: "126506" },
  // { label: "Nuling", mun_code: "126506" },
  // { label: "Pansud", mun_code: "126506" },
  // { label: "Pasandalan", mun_code: "126506" },
  // { label: "Poblacion", mun_code: "126506" },
  // { label: "Poloy-poloy", mun_code: "126506" },
  // { label: "Purikay", mun_code: "126506" },
  // { label: "Ragandang", mun_code: "126506" },
  // { label: "Salaman", mun_code: "126506" },
  // { label: "Salangsang", mun_code: "126506" },
  // { label: "Taguisa", mun_code: "126506" },
  // { label: "Tibpuan", mun_code: "126506" },
  // { label: "Tran", mun_code: "126506" },
  // { label: "Villamonte", mun_code: "126506" },
  // { label: "Barurao II", mun_code: "126506" },
  // { label: "Aurelio F. Freires (Poblacion II)", mun_code: "126506" },
  // { label: "Poblacion III", mun_code: "126506" },
  // { label: "Bolebok", mun_code: "126506" },
  // { label: "Antong", mun_code: "126507" },
  // { label: "Bayasong", mun_code: "126507" },
  // { label: "Blingkong", mun_code: "126507" },
  // { label: "Lutayan Proper", mun_code: "126507" },
  // { label: "Maindang", mun_code: "126507" },
  // { label: "Mamali", mun_code: "126507" },
  // { label: "Manili", mun_code: "126507" },
  // { label: "Sampao", mun_code: "126507" },
  // { label: "Sisiman", mun_code: "126507" },
  // { label: "Tamnag (Pob.)", mun_code: "126507" },
  // { label: "Palavilla", mun_code: "126507" },
  // { label: "Caridad (Cuyapon)", mun_code: "126508" },
  // { label: "Didtaras", mun_code: "126508" },
  // { label: "Gansing (Bilumen)", mun_code: "126508" },
  // { label: "Kabulakan", mun_code: "126508" },
  // { label: "Kapingkong", mun_code: "126508" },
  // { label: "Katitisan", mun_code: "126508" },
  // { label: "Lagao", mun_code: "126508" },
  // { label: "Lilit", mun_code: "126508" },
  // { label: "Madanding", mun_code: "126508" },
  // { label: "Maligaya", mun_code: "126508" },
  // { label: "Mamali", mun_code: "126508" },
  // { label: "Matiompong", mun_code: "126508" },
  // { label: "Midtapok", mun_code: "126508" },
  // { label: "New Cebu", mun_code: "126508" },
  // { label: "Palumbi", mun_code: "126508" },
  // { label: "Pidtiguian", mun_code: "126508" },
  // { label: "Pimbalayan", mun_code: "126508" },
  // { label: "Pinguiaman", mun_code: "126508" },
  // { label: "Poblacion (Lambayong)", mun_code: "126508" },
  // { label: "Sadsalan", mun_code: "126508" },
  // { label: "Seneben", mun_code: "126508" },
  // { label: "Sigayan", mun_code: "126508" },
  // { label: "Tambak", mun_code: "126508" },
  // { label: "Tinumigues", mun_code: "126508" },
  // { label: "Tumiao (Tinaga)", mun_code: "126508" },
  // { label: "Udtong", mun_code: "126508" },
  // { label: "Akol", mun_code: "126509" },
  // { label: "Badiangon", mun_code: "126509" },
  // { label: "Baliango", mun_code: "126509" },
  // { label: "Baranayan", mun_code: "126509" },
  // { label: "Barongis", mun_code: "126509" },
  // { label: "Batang-baglas", mun_code: "126509" },
  // { label: "Butril", mun_code: "126509" },
  // { label: "Domolol", mun_code: "126509" },
  // { label: "Kabuling", mun_code: "126509" },
  // { label: "Kalibuhan", mun_code: "126509" },
  // { label: "Kanipaan", mun_code: "126509" },
  // { label: "Kisek", mun_code: "126509" },
  // { label: "Kidayan", mun_code: "126509" },
  // { label: "Kiponget", mun_code: "126509" },
  // { label: "Kulong-kulong", mun_code: "126509" },
  // { label: "Kraan", mun_code: "126509" },
  // { label: "Langali", mun_code: "126509" },
  // { label: "Libua", mun_code: "126509" },
  // { label: "Lumitan", mun_code: "126509" },
  // { label: "Maganao", mun_code: "126509" },
  // { label: "Maguid", mun_code: "126509" },
  // { label: "Malatuneng (Malatunol)", mun_code: "126509" },
  // { label: "Malisbong", mun_code: "126509" },
  // { label: "Milbuk", mun_code: "126509" },
  // { label: "Molon", mun_code: "126509" },
  // { label: "Namat Masla", mun_code: "126509" },
  // { label: "Napnapon", mun_code: "126509" },
  // { label: "Poblacion", mun_code: "126509" },
  // { label: "San Roque", mun_code: "126509" },
  // { label: "Colobe (Tagadtal)", mun_code: "126509" },
  // { label: "Tibuhol (East Badiangon)", mun_code: "126509" },
  // { label: "Wal", mun_code: "126509" },
  // { label: "Bambanen", mun_code: "126509" },
  // { label: "Lopoken (Lepolon)", mun_code: "126509" },
  // { label: "Mina", mun_code: "126509" },
  // { label: "Medol", mun_code: "126509" },
  // { label: "Wasag", mun_code: "126509" },
  // { label: "Balwan (Bulan)", mun_code: "126509" },
  // { label: "Ligao", mun_code: "126509" },
  // { label: "Datu Maguiales", mun_code: "126509" },
  // { label: "Bagumbayan", mun_code: "126510" },
  // { label: "Bannawag", mun_code: "126510" },
  // { label: "Bayawa", mun_code: "126510" },
  // { label: "Estrella", mun_code: "126510" },
  // { label: "Kalanawe I", mun_code: "126510" },
  // { label: "Kalanawe II", mun_code: "126510" },
  // { label: "Katico", mun_code: "126510" },
  // { label: "Malingon", mun_code: "126510" },
  // { label: "Mangalen", mun_code: "126510" },
  // { label: "C. Mangilala", mun_code: "126510" },
  // { label: "Pedtubo", mun_code: "126510" },
  // { label: "Poblacion (Sambulawan)", mun_code: "126510" },
  // { label: "Romualdez", mun_code: "126510" },
  // { label: "San Jose", mun_code: "126510" },
  // { label: "Sinakulay", mun_code: "126510" },
  // { label: "Suben", mun_code: "126510" },
  // { label: "Tinaungan", mun_code: "126510" },
  // { label: "Tual (Liguasan)", mun_code: "126510" },
  // { label: "San Pedro (Tuato)", mun_code: "126510" },
  // { label: "Baras", mun_code: "126511" },
  // { label: "Buenaflor", mun_code: "126511" },
  // { label: "Calean", mun_code: "126511" },
  // { label: "Carmen", mun_code: "126511" },
  // { label: "D'Ledesma", mun_code: "126511" },
  // { label: "Gansing", mun_code: "126511" },
  // { label: "Kalandagan", mun_code: "126511" },
  // { label: "Lower Katungal", mun_code: "126511" },
  // { label: "New Isabela", mun_code: "126511" },
  // { label: "New Lagao", mun_code: "126511" },
  // { label: "New Passi", mun_code: "126511" },
  // { label: "Poblacion", mun_code: "126511" },
  // { label: "Rajah Nuda", mun_code: "126511" },
  // { label: "San Antonio", mun_code: "126511" },
  // { label: "San Emmanuel", mun_code: "126511" },
  // { label: "San Pablo", mun_code: "126511" },
  // { label: "Upper Katungal", mun_code: "126511" },
  // { label: "Tina", mun_code: "126511" },
  // { label: "San Rafael", mun_code: "126511" },
  // { label: "Lancheta", mun_code: "126511" },
  // { label: "Banali", mun_code: "126512" },
  // { label: "Basag", mun_code: "126512" },
  // { label: "Buenaflores", mun_code: "126512" },
  // { label: "Bugso", mun_code: "126512" },
  // { label: "Buklod", mun_code: "126512" },
  // { label: "Gapok", mun_code: "126512" },
  // { label: "Kadi", mun_code: "126512" },
  // { label: "Kapatagan", mun_code: "126512" },
  // { label: "Kiadsam", mun_code: "126512" },
  // { label: "Kuden", mun_code: "126512" },
  // { label: "Kulaman", mun_code: "126512" },
  // { label: "Lagubang", mun_code: "126512" },
  // { label: "Langgal", mun_code: "126512" },
  // { label: "Limuhay", mun_code: "126512" },
  // { label: "Malegdeg", mun_code: "126512" },
  // { label: "Midtungok", mun_code: "126512" },
  // { label: "Nati", mun_code: "126512" },
  // { label: "Sewod", mun_code: "126512" },
  // { label: "Tacupis", mun_code: "126512" },
  // { label: "Tinalon", mun_code: "126512" },
  // { label: "Alegria", mun_code: "128001" },
  // { label: "Bagacay", mun_code: "128001" },
  // { label: "Baluntay", mun_code: "128001" },
  // { label: "Datal Anggas", mun_code: "128001" },
  // { label: "Domolok", mun_code: "128001" },
  // { label: "Kawas", mun_code: "128001" },
  // { label: "Maribulan", mun_code: "128001" },
  // { label: "Pag-Asa", mun_code: "128001" },
  // { label: "Paraiso", mun_code: "128001" },
  // { label: "Poblacion (Alabel)", mun_code: "128001" },
  // { label: "Spring", mun_code: "128001" },
  // { label: "Tokawal", mun_code: "128001" },
  // { label: "Ladol", mun_code: "128001" },
  // { label: "Baliton", mun_code: "128002" },
  // { label: "Batotuling", mun_code: "128002" },
  // { label: "Batulaki", mun_code: "128002" },
  // { label: "Big Margus", mun_code: "128002" },
  // { label: "Burias", mun_code: "128002" },
  // { label: "Cablalan", mun_code: "128002" },
  // { label: "Calabanit", mun_code: "128002" },
  // { label: "Calpidong", mun_code: "128002" },
  // { label: "Congan", mun_code: "128002" },
  // { label: "Cross", mun_code: "128002" },
  // { label: "Datalbukay", mun_code: "128002" },
  // { label: "E. Alegado", mun_code: "128002" },
  // { label: "Glan Padidu", mun_code: "128002" },
  // { label: "Gumasa", mun_code: "128002" },
  // { label: "Ilaya", mun_code: "128002" },
  // { label: "Kaltuad", mun_code: "128002" },
  // { label: "Kapatan", mun_code: "128002" },
  // { label: "Lago", mun_code: "128002" },
  // { label: "Laguimit", mun_code: "128002" },
  // { label: "Mudan", mun_code: "128002" },
  // { label: "New Aklan", mun_code: "128002" },
  // { label: "Pangyan", mun_code: "128002" },
  // { label: "Poblacion", mun_code: "128002" },
  // { label: "Rio Del Pilar", mun_code: "128002" },
  // { label: "San Jose", mun_code: "128002" },
  // { label: "San Vicente", mun_code: "128002" },
  // { label: "Small Margus", mun_code: "128002" },
  // { label: "Sufatubo", mun_code: "128002" },
  // { label: "Taluya", mun_code: "128002" },
  // { label: "Tango", mun_code: "128002" },
  // { label: "Tapon", mun_code: "128002" },
  // { label: "Badtasan", mun_code: "128003" },
  // { label: "Datu Dani", mun_code: "128003" },
  // { label: "Gasi", mun_code: "128003" },
  // { label: "Kapate", mun_code: "128003" },
  // { label: "Katubao", mun_code: "128003" },
  // { label: "Kayupo", mun_code: "128003" },
  // { label: "Kling (Lumit)", mun_code: "128003" },
  // { label: "Lagundi", mun_code: "128003" },
  // { label: "Lebe", mun_code: "128003" },
  // { label: "Lomuyon", mun_code: "128003" },
  // { label: "Luma", mun_code: "128003" },
  // { label: "Maligang", mun_code: "128003" },
  // { label: "Nalus", mun_code: "128003" },
  // { label: "Poblacion", mun_code: "128003" },
  // { label: "Salakit", mun_code: "128003" },
  // { label: "Suli", mun_code: "128003" },
  // { label: "Tablao", mun_code: "128003" },
  // { label: "Tamadang", mun_code: "128003" },
  // { label: "Tambilil", mun_code: "128003" },
  // { label: "Amsipit", mun_code: "128004" },
  // { label: "Bales", mun_code: "128004" },
  // { label: "Colon", mun_code: "128004" },
  // { label: "Daliao", mun_code: "128004" },
  // { label: "Kabatiol", mun_code: "128004" },
  // { label: "Kablacan", mun_code: "128004" },
  // { label: "Kamanga", mun_code: "128004" },
  // { label: "Kanalo", mun_code: "128004" },
  // { label: "Lumasal", mun_code: "128004" },
  // { label: "Lumatil", mun_code: "128004" },
  // { label: "Malbang", mun_code: "128004" },
  // { label: "Nomoh", mun_code: "128004" },
  // { label: "Pananag", mun_code: "128004" },
  // { label: "Poblacion (Maasim)", mun_code: "128004" },
  // { label: "Seven Hills", mun_code: "128004" },
  // { label: "Tinoto", mun_code: "128004" },
  // { label: "Bati-an", mun_code: "128005" },
  // { label: "Kalaneg", mun_code: "128005" },
  // { label: "Kalaong", mun_code: "128005" },
  // { label: "Kiambing", mun_code: "128005" },
  // { label: "Kiayap", mun_code: "128005" },
  // { label: "Mabay", mun_code: "128005" },
  // { label: "Maguling", mun_code: "128005" },
  // { label: "Malalag (Pob.)", mun_code: "128005" },
  // { label: "Mindupok", mun_code: "128005" },
  // { label: "New La Union", mun_code: "128005" },
  // { label: "Old Poblacion (Maitum)", mun_code: "128005" },
  // { label: "Pangi (Linao)", mun_code: "128005" },
  // { label: "Pinol", mun_code: "128005" },
  // { label: "Sison (Edenton)", mun_code: "128005" },
  // { label: "Ticulab", mun_code: "128005" },
  // { label: "Tuanadatu", mun_code: "128005" },
  // { label: "Upo (Lanao)", mun_code: "128005" },
  // { label: "Wali (Kambuhan)", mun_code: "128005" },
  // { label: "Zion", mun_code: "128005" },
  // { label: "Daan Suyan", mun_code: "128006" },
  // { label: "Kihan", mun_code: "128006" },
  // { label: "Kinam", mun_code: "128006" },
  // { label: "Libi", mun_code: "128006" },
  // { label: "Lun Masla", mun_code: "128006" },
  // { label: "Lun Padidu", mun_code: "128006" },
  // { label: "Patag", mun_code: "128006" },
  // { label: "Poblacion (Malapatan)", mun_code: "128006" },
  // { label: "Sapu Masla", mun_code: "128006" },
  // { label: "Sapu Padidu", mun_code: "128006" },
  // { label: "Tuyan", mun_code: "128006" },
  // { label: "Upper Suyan", mun_code: "128006" },
  // { label: "Alkikan", mun_code: "128007" },
  // { label: "Ampon", mun_code: "128007" },
  // { label: "Atlae", mun_code: "128007" },
  // { label: "Banahaw", mun_code: "128007" },
  // { label: "Banate", mun_code: "128007" },
  // { label: "B'Laan", mun_code: "128007" },
  // { label: "Datal Batong", mun_code: "128007" },
  // { label: "Datal Bila", mun_code: "128007" },
  // { label: "Datal Tampal", mun_code: "128007" },
  // { label: "J.P. Laurel", mun_code: "128007" },
  // { label: "Kawayan", mun_code: "128007" },
  // { label: "Kibala", mun_code: "128007" },
  // { label: "Kiblat", mun_code: "128007" },
  // { label: "Kinabalan", mun_code: "128007" },
  // { label: "Lower Mainit", mun_code: "128007" },
  // { label: "Lutay", mun_code: "128007" },
  // { label: "Malabod", mun_code: "128007" },
  // { label: "Malalag Cogon", mun_code: "128007" },
  // { label: "Malandag", mun_code: "128007" },
  // { label: "Malungon Gamay", mun_code: "128007" },
  // { label: "Nagpan", mun_code: "128007" },
  // { label: "Panamin", mun_code: "128007" },
  // { label: "Poblacion", mun_code: "128007" },
  // { label: "San Juan", mun_code: "128007" },
  // { label: "San Miguel", mun_code: "128007" },
  // { label: "San Roque", mun_code: "128007" },
  // { label: "Talus", mun_code: "128007" },
  // { label: "Tamban", mun_code: "128007" },
  // { label: "Upper Biangan", mun_code: "128007" },
  // { label: "Upper Lumabat", mun_code: "128007" },
  // { label: "Upper Mainit", mun_code: "128007" },
  // { label: "Bagua", mun_code: "129804" },
  // { label: "Kalanganan", mun_code: "129804" },
  // { label: "Poblacion", mun_code: "129804" },
  // { label: "Rosary Heights", mun_code: "129804" },
  // { label: "Tamontaka", mun_code: "129804" },
  // { label: "Bagua I", mun_code: "129804" },
  // { label: "Bagua II", mun_code: "129804" },
  // { label: "Bagua III", mun_code: "129804" },
  // { label: "Kalanganan I", mun_code: "129804" },
  // { label: "Kalanganan II", mun_code: "129804" },
  // { label: "Poblacion I", mun_code: "129804" },
  // { label: "Poblacion II", mun_code: "129804" },
  // { label: "Poblacion III", mun_code: "129804" },
  // { label: "Poblacion IV", mun_code: "129804" },
  // { label: "Poblacion V", mun_code: "129804" },
  // { label: "Poblacion VI", mun_code: "129804" },
  // { label: "Poblacion VII", mun_code: "129804" },
  // { label: "Poblacion VIII", mun_code: "129804" },
  // { label: "Poblacion IX", mun_code: "129804" },
  // { label: "Rosary Heights I", mun_code: "129804" },
  // { label: "Rosary Heights II", mun_code: "129804" },
  // { label: "Rosary Heights III", mun_code: "129804" },
  // { label: "Rosary Heights IV", mun_code: "129804" },
  // { label: "Rosary Heights V", mun_code: "129804" },
  // { label: "Rosary Heights VI", mun_code: "129804" },
  // { label: "Rosary Heights VII", mun_code: "129804" },
  // { label: "Rosary Heights VIII", mun_code: "129804" },
  // { label: "Rosary Heights IX", mun_code: "129804" },
  // { label: "Rosary Heights X", mun_code: "129804" },
  // { label: "Rosary Heights XI", mun_code: "129804" },
  // { label: "Rosary Heights XII", mun_code: "129804" },
  // { label: "Rosary Heights XIII", mun_code: "129804" },
  // { label: "Tamontaka I", mun_code: "129804" },
  // { label: "Tamontaka II", mun_code: "129804" },
  // { label: "Tamontaka III", mun_code: "129804" },
  // { label: "Tamontaka IV", mun_code: "129804" },
  // { label: "Tamontaka V", mun_code: "129804" },
  // { label: "Barangay 1", mun_code: "133901" },
  // { label: "Barangay 2", mun_code: "133901" },
  // { label: "Barangay 3", mun_code: "133901" },
  // { label: "Barangay 4", mun_code: "133901" },
  // { label: "Barangay 5", mun_code: "133901" },
  // { label: "Barangay 6", mun_code: "133901" },
  // { label: "Barangay 7", mun_code: "133901" },
  // { label: "Barangay 8", mun_code: "133901" },
  // { label: "Barangay 9", mun_code: "133901" },
  // { label: "Barangay 10", mun_code: "133901" },
  // { label: "Barangay 11", mun_code: "133901" },
  // { label: "Barangay 12", mun_code: "133901" },
  // { label: "Barangay 13", mun_code: "133901" },
  // { label: "Barangay 14", mun_code: "133901" },
  // { label: "Barangay 15", mun_code: "133901" },
  // { label: "Barangay 16", mun_code: "133901" },
  // { label: "Barangay 17", mun_code: "133901" },
  // { label: "Barangay 18", mun_code: "133901" },
  // { label: "Barangay 19", mun_code: "133901" },
  // { label: "Barangay 20", mun_code: "133901" },
  // { label: "Barangay 25", mun_code: "133901" },
  // { label: "Barangay 26", mun_code: "133901" },
  // { label: "Barangay 28", mun_code: "133901" },
  // { label: "Barangay 29", mun_code: "133901" },
  // { label: "Barangay 30", mun_code: "133901" },
  // { label: "Barangay 31", mun_code: "133901" },
  // { label: "Barangay 32", mun_code: "133901" },
  // { label: "Barangay 33", mun_code: "133901" },
  // { label: "Barangay 34", mun_code: "133901" },
  // { label: "Barangay 35", mun_code: "133901" },
  // { label: "Barangay 36", mun_code: "133901" },
  // { label: "Barangay 37", mun_code: "133901" },
  // { label: "Barangay 38", mun_code: "133901" },
  // { label: "Barangay 39", mun_code: "133901" },
  // { label: "Barangay 41", mun_code: "133901" },
  // { label: "Barangay 42", mun_code: "133901" },
  // { label: "Barangay 43", mun_code: "133901" },
  // { label: "Barangay 44", mun_code: "133901" },
  // { label: "Barangay 45", mun_code: "133901" },
  // { label: "Barangay 46", mun_code: "133901" },
  // { label: "Barangay 47", mun_code: "133901" },
  // { label: "Barangay 48", mun_code: "133901" },
  // { label: "Barangay 49", mun_code: "133901" },
  // { label: "Barangay 50", mun_code: "133901" },
  // { label: "Barangay 51", mun_code: "133901" },
  // { label: "Barangay 52", mun_code: "133901" },
  // { label: "Barangay 53", mun_code: "133901" },
  // { label: "Barangay 54", mun_code: "133901" },
  // { label: "Barangay 55", mun_code: "133901" },
  // { label: "Barangay 56", mun_code: "133901" },
  // { label: "Barangay 57", mun_code: "133901" },
  // { label: "Barangay 58", mun_code: "133901" },
  // { label: "Barangay 59", mun_code: "133901" },
  // { label: "Barangay 60", mun_code: "133901" },
  // { label: "Barangay 61", mun_code: "133901" },
  // { label: "Barangay 62", mun_code: "133901" },
  // { label: "Barangay 63", mun_code: "133901" },
  // { label: "Barangay 64", mun_code: "133901" },
  // { label: "Barangay 65", mun_code: "133901" },
  // { label: "Barangay 66", mun_code: "133901" },
  // { label: "Barangay 67", mun_code: "133901" },
  // { label: "Barangay 68", mun_code: "133901" },
  // { label: "Barangay 69", mun_code: "133901" },
  // { label: "Barangay 70", mun_code: "133901" },
  // { label: "Barangay 71", mun_code: "133901" },
  // { label: "Barangay 72", mun_code: "133901" },
  // { label: "Barangay 73", mun_code: "133901" },
  // { label: "Barangay 74", mun_code: "133901" },
  // { label: "Barangay 75", mun_code: "133901" },
  // { label: "Barangay 76", mun_code: "133901" },
  // { label: "Barangay 77", mun_code: "133901" },
  // { label: "Barangay 78", mun_code: "133901" },
  // { label: "Barangay 79", mun_code: "133901" },
  // { label: "Barangay 80", mun_code: "133901" },
  // { label: "Barangay 81", mun_code: "133901" },
  // { label: "Barangay 82", mun_code: "133901" },
  // { label: "Barangay 83", mun_code: "133901" },
  // { label: "Barangay 84", mun_code: "133901" },
  // { label: "Barangay 85", mun_code: "133901" },
  // { label: "Barangay 86", mun_code: "133901" },
  // { label: "Barangay 87", mun_code: "133901" },
  // { label: "Barangay 88", mun_code: "133901" },
  // { label: "Barangay 89", mun_code: "133901" },
  // { label: "Barangay 90", mun_code: "133901" },
  // { label: "Barangay 91", mun_code: "133901" },
  // { label: "Barangay 92", mun_code: "133901" },
  // { label: "Barangay 93", mun_code: "133901" },
  // { label: "Barangay 94", mun_code: "133901" },
  // { label: "Barangay 95", mun_code: "133901" },
  // { label: "Barangay 96", mun_code: "133901" },
  // { label: "Barangay 97", mun_code: "133901" },
  // { label: "Barangay 98", mun_code: "133901" },
  // { label: "Barangay 99", mun_code: "133901" },
  // { label: "Barangay 100", mun_code: "133901" },
  // { label: "Barangay 101", mun_code: "133901" },
  // { label: "Barangay 102", mun_code: "133901" },
  // { label: "Barangay 103", mun_code: "133901" },
  // { label: "Barangay 104", mun_code: "133901" },
  // { label: "Barangay 105", mun_code: "133901" },
  // { label: "Barangay 106", mun_code: "133901" },
  // { label: "Barangay 107", mun_code: "133901" },
  // { label: "Barangay 108", mun_code: "133901" },
  // { label: "Barangay 109", mun_code: "133901" },
  // { label: "Barangay 110", mun_code: "133901" },
  // { label: "Barangay 111", mun_code: "133901" },
  // { label: "Barangay 112", mun_code: "133901" },
  // { label: "Barangay 116", mun_code: "133901" },
  // { label: "Barangay 117", mun_code: "133901" },
  // { label: "Barangay 118", mun_code: "133901" },
  // { label: "Barangay 119", mun_code: "133901" },
  // { label: "Barangay 120", mun_code: "133901" },
  // { label: "Barangay 121", mun_code: "133901" },
  // { label: "Barangay 122", mun_code: "133901" },
  // { label: "Barangay 123", mun_code: "133901" },
  // { label: "Barangay 124", mun_code: "133901" },
  // { label: "Barangay 125", mun_code: "133901" },
  // { label: "Barangay 126", mun_code: "133901" },
  // { label: "Barangay 127", mun_code: "133901" },
  // { label: "Barangay 128", mun_code: "133901" },
  // { label: "Barangay 129", mun_code: "133901" },
  // { label: "Barangay 130", mun_code: "133901" },
  // { label: "Barangay 131", mun_code: "133901" },
  // { label: "Barangay 132", mun_code: "133901" },
  // { label: "Barangay 133", mun_code: "133901" },
  // { label: "Barangay 134", mun_code: "133901" },
  // { label: "Barangay 135", mun_code: "133901" },
  // { label: "Barangay 136", mun_code: "133901" },
  // { label: "Barangay 137", mun_code: "133901" },
  // { label: "Barangay 138", mun_code: "133901" },
  // { label: "Barangay 139", mun_code: "133901" },
  // { label: "Barangay 140", mun_code: "133901" },
  // { label: "Barangay 141", mun_code: "133901" },
  // { label: "Barangay 142", mun_code: "133901" },
  // { label: "Barangay 143", mun_code: "133901" },
  // { label: "Barangay 144", mun_code: "133901" },
  // { label: "Barangay 145", mun_code: "133901" },
  // { label: "Barangay 146", mun_code: "133901" },
  // { label: "Barangay 147", mun_code: "133901" },
  // { label: "Barangay 148", mun_code: "133901" },
  // { label: "Barangay 149", mun_code: "133901" },
  // { label: "Barangay 150", mun_code: "133901" },
  // { label: "Barangay 151", mun_code: "133901" },
  // { label: "Barangay 152", mun_code: "133901" },
  // { label: "Barangay 153", mun_code: "133901" },
  // { label: "Barangay 154", mun_code: "133901" },
  // { label: "Barangay 155", mun_code: "133901" },
  // { label: "Barangay 156", mun_code: "133901" },
  // { label: "Barangay 157", mun_code: "133901" },
  // { label: "Barangay 158", mun_code: "133901" },
  // { label: "Barangay 159", mun_code: "133901" },
  // { label: "Barangay 160", mun_code: "133901" },
  // { label: "Barangay 161", mun_code: "133901" },
  // { label: "Barangay 162", mun_code: "133901" },
  // { label: "Barangay 163", mun_code: "133901" },
  // { label: "Barangay 164", mun_code: "133901" },
  // { label: "Barangay 165", mun_code: "133901" },
  // { label: "Barangay 166", mun_code: "133901" },
  // { label: "Barangay 167", mun_code: "133901" },
  // { label: "Barangay 168", mun_code: "133901" },
  // { label: "Barangay 169", mun_code: "133901" },
  // { label: "Barangay 170", mun_code: "133901" },
  // { label: "Barangay 171", mun_code: "133901" },
  // { label: "Barangay 172", mun_code: "133901" },
  // { label: "Barangay 173", mun_code: "133901" },
  // { label: "Barangay 174", mun_code: "133901" },
  // { label: "Barangay 175", mun_code: "133901" },
  // { label: "Barangay 176", mun_code: "133901" },
  // { label: "Barangay 177", mun_code: "133901" },
  // { label: "Barangay 178", mun_code: "133901" },
  // { label: "Barangay 179", mun_code: "133901" },
  // { label: "Barangay 180", mun_code: "133901" },
  // { label: "Barangay 181", mun_code: "133901" },
  // { label: "Barangay 182", mun_code: "133901" },
  // { label: "Barangay 183", mun_code: "133901" },
  // { label: "Barangay 184", mun_code: "133901" },
  // { label: "Barangay 185", mun_code: "133901" },
  // { label: "Barangay 186", mun_code: "133901" },
  // { label: "Barangay 187", mun_code: "133901" },
  // { label: "Barangay 188", mun_code: "133901" },
  // { label: "Barangay 189", mun_code: "133901" },
  // { label: "Barangay 190", mun_code: "133901" },
  // { label: "Barangay 191", mun_code: "133901" },
  // { label: "Barangay 192", mun_code: "133901" },
  // { label: "Barangay 193", mun_code: "133901" },
  // { label: "Barangay 194", mun_code: "133901" },
  // { label: "Barangay 195", mun_code: "133901" },
  // { label: "Barangay 196", mun_code: "133901" },
  // { label: "Barangay 197", mun_code: "133901" },
  // { label: "Barangay 198", mun_code: "133901" },
  // { label: "Barangay 199", mun_code: "133901" },
  // { label: "Barangay 200", mun_code: "133901" },
  // { label: "Barangay 201", mun_code: "133901" },
  // { label: "Barangay 202", mun_code: "133901" },
  // { label: "Barangay 202-A", mun_code: "133901" },
  // { label: "Barangay 203", mun_code: "133901" },
  // { label: "Barangay 204", mun_code: "133901" },
  // { label: "Barangay 205", mun_code: "133901" },
  // { label: "Barangay 206", mun_code: "133901" },
  // { label: "Barangay 207", mun_code: "133901" },
  // { label: "Barangay 208", mun_code: "133901" },
  // { label: "Barangay 209", mun_code: "133901" },
  // { label: "Barangay 210", mun_code: "133901" },
  // { label: "Barangay 211", mun_code: "133901" },
  // { label: "Barangay 212", mun_code: "133901" },
  // { label: "Barangay 213", mun_code: "133901" },
  // { label: "Barangay 214", mun_code: "133901" },
  // { label: "Barangay 215", mun_code: "133901" },
  // { label: "Barangay 216", mun_code: "133901" },
  // { label: "Barangay 217", mun_code: "133901" },
  // { label: "Barangay 218", mun_code: "133901" },
  // { label: "Barangay 219", mun_code: "133901" },
  // { label: "Barangay 220", mun_code: "133901" },
  // { label: "Barangay 221", mun_code: "133901" },
  // { label: "Barangay 222", mun_code: "133901" },
  // { label: "Barangay 223", mun_code: "133901" },
  // { label: "Barangay 224", mun_code: "133901" },
  // { label: "Barangay 225", mun_code: "133901" },
  // { label: "Barangay 226", mun_code: "133901" },
  // { label: "Barangay 227", mun_code: "133901" },
  // { label: "Barangay 228", mun_code: "133901" },
  // { label: "Barangay 229", mun_code: "133901" },
  // { label: "Barangay 230", mun_code: "133901" },
  // { label: "Barangay 231", mun_code: "133901" },
  // { label: "Barangay 232", mun_code: "133901" },
  // { label: "Barangay 233", mun_code: "133901" },
  // { label: "Barangay 234", mun_code: "133901" },
  // { label: "Barangay 235", mun_code: "133901" },
  // { label: "Barangay 236", mun_code: "133901" },
  // { label: "Barangay 237", mun_code: "133901" },
  // { label: "Barangay 238", mun_code: "133901" },
  // { label: "Barangay 239", mun_code: "133901" },
  // { label: "Barangay 240", mun_code: "133901" },
  // { label: "Barangay 241", mun_code: "133901" },
  // { label: "Barangay 242", mun_code: "133901" },
  // { label: "Barangay 243", mun_code: "133901" },
  // { label: "Barangay 244", mun_code: "133901" },
  // { label: "Barangay 245", mun_code: "133901" },
  // { label: "Barangay 246", mun_code: "133901" },
  // { label: "Barangay 247", mun_code: "133901" },
  // { label: "Barangay 248", mun_code: "133901" },
  // { label: "Barangay 249", mun_code: "133901" },
  // { label: "Barangay 250", mun_code: "133901" },
  // { label: "Barangay 251", mun_code: "133901" },
  // { label: "Barangay 252", mun_code: "133901" },
  // { label: "Barangay 253", mun_code: "133901" },
  // { label: "Barangay 254", mun_code: "133901" },
  // { label: "Barangay 255", mun_code: "133901" },
  // { label: "Barangay 256", mun_code: "133901" },
  // { label: "Barangay 257", mun_code: "133901" },
  // { label: "Barangay 258", mun_code: "133901" },
  // { label: "Barangay 259", mun_code: "133901" },
  // { label: "Barangay 260", mun_code: "133901" },
  // { label: "Barangay 261", mun_code: "133901" },
  // { label: "Barangay 262", mun_code: "133901" },
  // { label: "Barangay 263", mun_code: "133901" },
  // { label: "Barangay 264", mun_code: "133901" },
  // { label: "Barangay 265", mun_code: "133901" },
  // { label: "Barangay 266", mun_code: "133901" },
  // { label: "Barangay 267", mun_code: "133901" },
  // { label: "Barangay 287", mun_code: "133902" },
  // { label: "Barangay 288", mun_code: "133902" },
  // { label: "Barangay 289", mun_code: "133902" },
  // { label: "Barangay 290", mun_code: "133902" },
  // { label: "Barangay 291", mun_code: "133902" },
  // { label: "Barangay 292", mun_code: "133902" },
  // { label: "Barangay 293", mun_code: "133902" },
  // { label: "Barangay 294", mun_code: "133902" },
  // { label: "Barangay 295", mun_code: "133902" },
  // { label: "Barangay 296", mun_code: "133902" },
  // { label: "Barangay 383", mun_code: "133903" },
  // { label: "Barangay 384", mun_code: "133903" },
  // { label: "Barangay 385", mun_code: "133903" },
  // { label: "Barangay 386", mun_code: "133903" },
  // { label: "Barangay 387", mun_code: "133903" },
  // { label: "Barangay 388", mun_code: "133903" },
  // { label: "Barangay 389", mun_code: "133903" },
  // { label: "Barangay 390", mun_code: "133903" },
  // { label: "Barangay 391", mun_code: "133903" },
  // { label: "Barangay 392", mun_code: "133903" },
  // { label: "Barangay 393", mun_code: "133903" },
  // { label: "Barangay 394", mun_code: "133903" },
  // { label: "Barangay 306", mun_code: "133903" },
  // { label: "Barangay 307", mun_code: "133903" },
  // { label: "Barangay 308", mun_code: "133903" },
  // { label: "Barangay 309", mun_code: "133903" },
  // { label: "Barangay 268", mun_code: "133904" },
  // { label: "Barangay 269", mun_code: "133904" },
  // { label: "Barangay 270", mun_code: "133904" },
  // { label: "Barangay 271", mun_code: "133904" },
  // { label: "Barangay 272", mun_code: "133904" },
  // { label: "Barangay 273", mun_code: "133904" },
  // { label: "Barangay 274", mun_code: "133904" },
  // { label: "Barangay 275", mun_code: "133904" },
  // { label: "Barangay 276", mun_code: "133904" },
  // { label: "Barangay 281", mun_code: "133904" },
  // { label: "Barangay 282", mun_code: "133904" },
  // { label: "Barangay 283", mun_code: "133904" },
  // { label: "Barangay 284", mun_code: "133904" },
  // { label: "Barangay 285", mun_code: "133904" },
  // { label: "Barangay 286", mun_code: "133904" },
  // { label: "Barangay 297", mun_code: "133905" },
  // { label: "Barangay 298", mun_code: "133905" },
  // { label: "Barangay 299", mun_code: "133905" },
  // { label: "Barangay 300", mun_code: "133905" },
  // { label: "Barangay 301", mun_code: "133905" },
  // { label: "Barangay 302", mun_code: "133905" },
  // { label: "Barangay 303", mun_code: "133905" },
  // { label: "Barangay 304", mun_code: "133905" },
  // { label: "Barangay 305", mun_code: "133905" },
  // { label: "Barangay 310", mun_code: "133905" },
  // { label: "Barangay 311", mun_code: "133905" },
  // { label: "Barangay 312", mun_code: "133905" },
  // { label: "Barangay 313", mun_code: "133905" },
  // { label: "Barangay 314", mun_code: "133905" },
  // { label: "Barangay 315", mun_code: "133905" },
  // { label: "Barangay 316", mun_code: "133905" },
  // { label: "Barangay 317", mun_code: "133905" },
  // { label: "Barangay 318", mun_code: "133905" },
  // { label: "Barangay 319", mun_code: "133905" },
  // { label: "Barangay 320", mun_code: "133905" },
  // { label: "Barangay 321", mun_code: "133905" },
  // { label: "Barangay 322", mun_code: "133905" },
  // { label: "Barangay 323", mun_code: "133905" },
  // { label: "Barangay 324", mun_code: "133905" },
  // { label: "Barangay 325", mun_code: "133905" },
  // { label: "Barangay 326", mun_code: "133905" },
  // { label: "Barangay 327", mun_code: "133905" },
  // { label: "Barangay 328", mun_code: "133905" },
  // { label: "Barangay 329", mun_code: "133905" },
  // { label: "Barangay 330", mun_code: "133905" },
  // { label: "Barangay 331", mun_code: "133905" },
  // { label: "Barangay 332", mun_code: "133905" },
  // { label: "Barangay 333", mun_code: "133905" },
  // { label: "Barangay 334", mun_code: "133905" },
  // { label: "Barangay 335", mun_code: "133905" },
  // { label: "Barangay 336", mun_code: "133905" },
  // { label: "Barangay 337", mun_code: "133905" },
  // { label: "Barangay 338", mun_code: "133905" },
  // { label: "Barangay 339", mun_code: "133905" },
  // { label: "Barangay 340", mun_code: "133905" },
  // { label: "Barangay 341", mun_code: "133905" },
  // { label: "Barangay 342", mun_code: "133905" },
  // { label: "Barangay 343", mun_code: "133905" },
  // { label: "Barangay 344", mun_code: "133905" },
  // { label: "Barangay 345", mun_code: "133905" },
  // { label: "Barangay 346", mun_code: "133905" },
  // { label: "Barangay 347", mun_code: "133905" },
  // { label: "Barangay 348", mun_code: "133905" },
  // { label: "Barangay 349", mun_code: "133905" },
  // { label: "Barangay 350", mun_code: "133905" },
  // { label: "Barangay 351", mun_code: "133905" },
  // { label: "Barangay 352", mun_code: "133905" },
  // { label: "Barangay 353", mun_code: "133905" },
  // { label: "Barangay 354", mun_code: "133905" },
  // { label: "Barangay 355", mun_code: "133905" },
  // { label: "Barangay 356", mun_code: "133905" },
  // { label: "Barangay 357", mun_code: "133905" },
  // { label: "Barangay 358", mun_code: "133905" },
  // { label: "Barangay 359", mun_code: "133905" },
  // { label: "Barangay 360", mun_code: "133905" },
  // { label: "Barangay 361", mun_code: "133905" },
  // { label: "Barangay 362", mun_code: "133905" },
  // { label: "Barangay 363", mun_code: "133905" },
  // { label: "Barangay 364", mun_code: "133905" },
  // { label: "Barangay 365", mun_code: "133905" },
  // { label: "Barangay 366", mun_code: "133905" },
  // { label: "Barangay 367", mun_code: "133905" },
  // { label: "Barangay 368", mun_code: "133905" },
  // { label: "Barangay 369", mun_code: "133905" },
  // { label: "Barangay 370", mun_code: "133905" },
  // { label: "Barangay 371", mun_code: "133905" },
  // { label: "Barangay 372", mun_code: "133905" },
  // { label: "Barangay 373", mun_code: "133905" },
  // { label: "Barangay 374", mun_code: "133905" },
  // { label: "Barangay 375", mun_code: "133905" },
  // { label: "Barangay 376", mun_code: "133905" },
  // { label: "Barangay 377", mun_code: "133905" },
  // { label: "Barangay 378", mun_code: "133905" },
  // { label: "Barangay 379", mun_code: "133905" },
  // { label: "Barangay 380", mun_code: "133905" },
  // { label: "Barangay 381", mun_code: "133905" },
  // { label: "Barangay 382", mun_code: "133905" },
  // { label: "Barangay 395", mun_code: "133906" },
  // { label: "Barangay 396", mun_code: "133906" },
  // { label: "Barangay 397", mun_code: "133906" },
  // { label: "Barangay 398", mun_code: "133906" },
  // { label: "Barangay 399", mun_code: "133906" },
  // { label: "Barangay 400", mun_code: "133906" },
  // { label: "Barangay 401", mun_code: "133906" },
  // { label: "Barangay 402", mun_code: "133906" },
  // { label: "Barangay 403", mun_code: "133906" },
  // { label: "Barangay 404", mun_code: "133906" },
  // { label: "Barangay 405", mun_code: "133906" },
  // { label: "Barangay 406", mun_code: "133906" },
  // { label: "Barangay 407", mun_code: "133906" },
  // { label: "Barangay 408", mun_code: "133906" },
  // { label: "Barangay 409", mun_code: "133906" },
  // { label: "Barangay 410", mun_code: "133906" },
  // { label: "Barangay 411", mun_code: "133906" },
  // { label: "Barangay 412", mun_code: "133906" },
  // { label: "Barangay 413", mun_code: "133906" },
  // { label: "Barangay 414", mun_code: "133906" },
  // { label: "Barangay 415", mun_code: "133906" },
  // { label: "Barangay 416", mun_code: "133906" },
  // { label: "Barangay 417", mun_code: "133906" },
  // { label: "Barangay 418", mun_code: "133906" },
  // { label: "Barangay 419", mun_code: "133906" },
  // { label: "Barangay 420", mun_code: "133906" },
  // { label: "Barangay 421", mun_code: "133906" },
  // { label: "Barangay 422", mun_code: "133906" },
  // { label: "Barangay 423", mun_code: "133906" },
  // { label: "Barangay 424", mun_code: "133906" },
  // { label: "Barangay 425", mun_code: "133906" },
  // { label: "Barangay 426", mun_code: "133906" },
  // { label: "Barangay 427", mun_code: "133906" },
  // { label: "Barangay 428", mun_code: "133906" },
  // { label: "Barangay 429", mun_code: "133906" },
  // { label: "Barangay 430", mun_code: "133906" },
  // { label: "Barangay 431", mun_code: "133906" },
  // { label: "Barangay 432", mun_code: "133906" },
  // { label: "Barangay 433", mun_code: "133906" },
  // { label: "Barangay 434", mun_code: "133906" },
  // { label: "Barangay 435", mun_code: "133906" },
  // { label: "Barangay 436", mun_code: "133906" },
  // { label: "Barangay 437", mun_code: "133906" },
  // { label: "Barangay 438", mun_code: "133906" },
  // { label: "Barangay 439", mun_code: "133906" },
  // { label: "Barangay 440", mun_code: "133906" },
  // { label: "Barangay 441", mun_code: "133906" },
  // { label: "Barangay 442", mun_code: "133906" },
  // { label: "Barangay 443", mun_code: "133906" },
  // { label: "Barangay 444", mun_code: "133906" },
  // { label: "Barangay 445", mun_code: "133906" },
  // { label: "Barangay 446", mun_code: "133906" },
  // { label: "Barangay 447", mun_code: "133906" },
  // { label: "Barangay 448", mun_code: "133906" },
  // { label: "Barangay 449", mun_code: "133906" },
  // { label: "Barangay 450", mun_code: "133906" },
  // { label: "Barangay 451", mun_code: "133906" },
  // { label: "Barangay 452", mun_code: "133906" },
  // { label: "Barangay 453", mun_code: "133906" },
  // { label: "Barangay 454", mun_code: "133906" },
  // { label: "Barangay 455", mun_code: "133906" },
  // { label: "Barangay 456", mun_code: "133906" },
  // { label: "Barangay 457", mun_code: "133906" },
  // { label: "Barangay 458", mun_code: "133906" },
  // { label: "Barangay 459", mun_code: "133906" },
  // { label: "Barangay 460", mun_code: "133906" },
  // { label: "Barangay 461", mun_code: "133906" },
  // { label: "Barangay 462", mun_code: "133906" },
  // { label: "Barangay 463", mun_code: "133906" },
  // { label: "Barangay 464", mun_code: "133906" },
  // { label: "Barangay 465", mun_code: "133906" },
  // { label: "Barangay 466", mun_code: "133906" },
  // { label: "Barangay 467", mun_code: "133906" },
  // { label: "Barangay 468", mun_code: "133906" },
  // { label: "Barangay 469", mun_code: "133906" },
  // { label: "Barangay 470", mun_code: "133906" },
  // { label: "Barangay 471", mun_code: "133906" },
  // { label: "Barangay 472", mun_code: "133906" },
  // { label: "Barangay 473", mun_code: "133906" },
  // { label: "Barangay 474", mun_code: "133906" },
  // { label: "Barangay 475", mun_code: "133906" },
  // { label: "Barangay 476", mun_code: "133906" },
  // { label: "Barangay 477", mun_code: "133906" },
  // { label: "Barangay 478", mun_code: "133906" },
  // { label: "Barangay 479", mun_code: "133906" },
  // { label: "Barangay 480", mun_code: "133906" },
  // { label: "Barangay 481", mun_code: "133906" },
  // { label: "Barangay 482", mun_code: "133906" },
  // { label: "Barangay 483", mun_code: "133906" },
  // { label: "Barangay 484", mun_code: "133906" },
  // { label: "Barangay 485", mun_code: "133906" },
  // { label: "Barangay 486", mun_code: "133906" },
  // { label: "Barangay 487", mun_code: "133906" },
  // { label: "Barangay 488", mun_code: "133906" },
  // { label: "Barangay 489", mun_code: "133906" },
  // { label: "Barangay 490", mun_code: "133906" },
  // { label: "Barangay 491", mun_code: "133906" },
  // { label: "Barangay 492", mun_code: "133906" },
  // { label: "Barangay 493", mun_code: "133906" },
  // { label: "Barangay 494", mun_code: "133906" },
  // { label: "Barangay 495", mun_code: "133906" },
  // { label: "Barangay 496", mun_code: "133906" },
  // { label: "Barangay 497", mun_code: "133906" },
  // { label: "Barangay 498", mun_code: "133906" },
  // { label: "Barangay 499", mun_code: "133906" },
  // { label: "Barangay 500", mun_code: "133906" },
  // { label: "Barangay 501", mun_code: "133906" },
  // { label: "Barangay 502", mun_code: "133906" },
  // { label: "Barangay 503", mun_code: "133906" },
  // { label: "Barangay 504", mun_code: "133906" },
  // { label: "Barangay 505", mun_code: "133906" },
  // { label: "Barangay 506", mun_code: "133906" },
  // { label: "Barangay 507", mun_code: "133906" },
  // { label: "Barangay 508", mun_code: "133906" },
  // { label: "Barangay 509", mun_code: "133906" },
  // { label: "Barangay 510", mun_code: "133906" },
  // { label: "Barangay 511", mun_code: "133906" },
  // { label: "Barangay 512", mun_code: "133906" },
  // { label: "Barangay 513", mun_code: "133906" },
  // { label: "Barangay 514", mun_code: "133906" },
  // { label: "Barangay 515", mun_code: "133906" },
  // { label: "Barangay 516", mun_code: "133906" },
  // { label: "Barangay 517", mun_code: "133906" },
  // { label: "Barangay 518", mun_code: "133906" },
  // { label: "Barangay 519", mun_code: "133906" },
  // { label: "Barangay 520", mun_code: "133906" },
  // { label: "Barangay 521", mun_code: "133906" },
  // { label: "Barangay 522", mun_code: "133906" },
  // { label: "Barangay 523", mun_code: "133906" },
  // { label: "Barangay 524", mun_code: "133906" },
  // { label: "Barangay 525", mun_code: "133906" },
  // { label: "Barangay 526", mun_code: "133906" },
  // { label: "Barangay 527", mun_code: "133906" },
  // { label: "Barangay 528", mun_code: "133906" },
  // { label: "Barangay 529", mun_code: "133906" },
  // { label: "Barangay 530", mun_code: "133906" },
  // { label: "Barangay 531", mun_code: "133906" },
  // { label: "Barangay 532", mun_code: "133906" },
  // { label: "Barangay 533", mun_code: "133906" },
  // { label: "Barangay 534", mun_code: "133906" },
  // { label: "Barangay 535", mun_code: "133906" },
  // { label: "Barangay 536", mun_code: "133906" },
  // { label: "Barangay 537", mun_code: "133906" },
  // { label: "Barangay 538", mun_code: "133906" },
  // { label: "Barangay 539", mun_code: "133906" },
  // { label: "Barangay 540", mun_code: "133906" },
  // { label: "Barangay 541", mun_code: "133906" },
  // { label: "Barangay 542", mun_code: "133906" },
  // { label: "Barangay 543", mun_code: "133906" },
  // { label: "Barangay 544", mun_code: "133906" },
  // { label: "Barangay 545", mun_code: "133906" },
  // { label: "Barangay 546", mun_code: "133906" },
  // { label: "Barangay 547", mun_code: "133906" },
  // { label: "Barangay 548", mun_code: "133906" },
  // { label: "Barangay 549", mun_code: "133906" },
  // { label: "Barangay 550", mun_code: "133906" },
  // { label: "Barangay 551", mun_code: "133906" },
  // { label: "Barangay 552", mun_code: "133906" },
  // { label: "Barangay 553", mun_code: "133906" },
  // { label: "Barangay 554", mun_code: "133906" },
  // { label: "Barangay 555", mun_code: "133906" },
  // { label: "Barangay 556", mun_code: "133906" },
  // { label: "Barangay 557", mun_code: "133906" },
  // { label: "Barangay 558", mun_code: "133906" },
  // { label: "Barangay 559", mun_code: "133906" },
  // { label: "Barangay 560", mun_code: "133906" },
  // { label: "Barangay 561", mun_code: "133906" },
  // { label: "Barangay 562", mun_code: "133906" },
  // { label: "Barangay 563", mun_code: "133906" },
  // { label: "Barangay 564", mun_code: "133906" },
  // { label: "Barangay 565", mun_code: "133906" },
  // { label: "Barangay 566", mun_code: "133906" },
  // { label: "Barangay 567", mun_code: "133906" },
  // { label: "Barangay 568", mun_code: "133906" },
  // { label: "Barangay 569", mun_code: "133906" },
  // { label: "Barangay 570", mun_code: "133906" },
  // { label: "Barangay 571", mun_code: "133906" },
  // { label: "Barangay 572", mun_code: "133906" },
  // { label: "Barangay 573", mun_code: "133906" },
  // { label: "Barangay 574", mun_code: "133906" },
  // { label: "Barangay 575", mun_code: "133906" },
  // { label: "Barangay 576", mun_code: "133906" },
  // { label: "Barangay 577", mun_code: "133906" },
  // { label: "Barangay 578", mun_code: "133906" },
  // { label: "Barangay 579", mun_code: "133906" },
  // { label: "Barangay 580", mun_code: "133906" },
  // { label: "Barangay 581", mun_code: "133906" },
  // { label: "Barangay 582", mun_code: "133906" },
  // { label: "Barangay 583", mun_code: "133906" },
  // { label: "Barangay 584", mun_code: "133906" },
  // { label: "Barangay 585", mun_code: "133906" },
  // { label: "Barangay 586", mun_code: "133906" },
  // { label: "Barangay 587", mun_code: "133906" },
  // { label: "Barangay 587-A", mun_code: "133906" },
  // { label: "Barangay 588", mun_code: "133906" },
  // { label: "Barangay 589", mun_code: "133906" },
  // { label: "Barangay 590", mun_code: "133906" },
  // { label: "Barangay 591", mun_code: "133906" },
  // { label: "Barangay 592", mun_code: "133906" },
  // { label: "Barangay 593", mun_code: "133906" },
  // { label: "Barangay 594", mun_code: "133906" },
  // { label: "Barangay 595", mun_code: "133906" },
  // { label: "Barangay 596", mun_code: "133906" },
  // { label: "Barangay 597", mun_code: "133906" },
  // { label: "Barangay 598", mun_code: "133906" },
  // { label: "Barangay 599", mun_code: "133906" },
  // { label: "Barangay 600", mun_code: "133906" },
  // { label: "Barangay 601", mun_code: "133906" },
  // { label: "Barangay 602", mun_code: "133906" },
  // { label: "Barangay 603", mun_code: "133906" },
  // { label: "Barangay 604", mun_code: "133906" },
  // { label: "Barangay 605", mun_code: "133906" },
  // { label: "Barangay 606", mun_code: "133906" },
  // { label: "Barangay 607", mun_code: "133906" },
  // { label: "Barangay 608", mun_code: "133906" },
  // { label: "Barangay 609", mun_code: "133906" },
  // { label: "Barangay 610", mun_code: "133906" },
  // { label: "Barangay 611", mun_code: "133906" },
  // { label: "Barangay 612", mun_code: "133906" },
  // { label: "Barangay 613", mun_code: "133906" },
  // { label: "Barangay 614", mun_code: "133906" },
  // { label: "Barangay 615", mun_code: "133906" },
  // { label: "Barangay 616", mun_code: "133906" },
  // { label: "Barangay 617", mun_code: "133906" },
  // { label: "Barangay 618", mun_code: "133906" },
  // { label: "Barangay 619", mun_code: "133906" },
  // { label: "Barangay 620", mun_code: "133906" },
  // { label: "Barangay 621", mun_code: "133906" },
  // { label: "Barangay 622", mun_code: "133906" },
  // { label: "Barangay 623", mun_code: "133906" },
  // { label: "Barangay 624", mun_code: "133906" },
  // { label: "Barangay 625", mun_code: "133906" },
  // { label: "Barangay 626", mun_code: "133906" },
  // { label: "Barangay 627", mun_code: "133906" },
  // { label: "Barangay 628", mun_code: "133906" },
  // { label: "Barangay 629", mun_code: "133906" },
  // { label: "Barangay 630", mun_code: "133906" },
  // { label: "Barangay 631", mun_code: "133906" },
  // { label: "Barangay 632", mun_code: "133906" },
  // { label: "Barangay 633", mun_code: "133906" },
  // { label: "Barangay 634", mun_code: "133906" },
  // { label: "Barangay 635", mun_code: "133906" },
  // { label: "Barangay 636", mun_code: "133906" },
  // { label: "Barangay 637", mun_code: "133907" },
  // { label: "Barangay 638", mun_code: "133907" },
  // { label: "Barangay 639", mun_code: "133907" },
  // { label: "Barangay 640", mun_code: "133907" },
  // { label: "Barangay 641", mun_code: "133907" },
  // { label: "Barangay 642", mun_code: "133907" },
  // { label: "Barangay 643", mun_code: "133907" },
  // { label: "Barangay 644", mun_code: "133907" },
  // { label: "Barangay 645", mun_code: "133907" },
  // { label: "Barangay 646", mun_code: "133907" },
  // { label: "Barangay 647", mun_code: "133907" },
  // { label: "Barangay 648", mun_code: "133907" },
  // { label: "Barangay 659", mun_code: "133908" },
  // { label: "Barangay 659-A", mun_code: "133908" },
  // { label: "Barangay 660", mun_code: "133908" },
  // { label: "Barangay 660-A", mun_code: "133908" },
  // { label: "Barangay 661", mun_code: "133908" },
  // { label: "Barangay 666", mun_code: "133908" },
  // { label: "Barangay 667", mun_code: "133908" },
  // { label: "Barangay 668", mun_code: "133908" },
  // { label: "Barangay 669", mun_code: "133908" },
  // { label: "Barangay 670", mun_code: "133908" },
  // { label: "Barangay 663", mun_code: "133908" },
  // { label: "Barangay 663-A", mun_code: "133908" },
  // { label: "Barangay 664", mun_code: "133908" },
  // { label: "Barangay 654", mun_code: "133909" },
  // { label: "Barangay 655", mun_code: "133909" },
  // { label: "Barangay 656", mun_code: "133909" },
  // { label: "Barangay 657", mun_code: "133909" },
  // { label: "Barangay 658", mun_code: "133909" },
  // { label: "Barangay 689", mun_code: "133910" },
  // { label: "Barangay 690", mun_code: "133910" },
  // { label: "Barangay 691", mun_code: "133910" },
  // { label: "Barangay 692", mun_code: "133910" },
  // { label: "Barangay 693", mun_code: "133910" },
  // { label: "Barangay 694", mun_code: "133910" },
  // { label: "Barangay 695", mun_code: "133910" },
  // { label: "Barangay 696", mun_code: "133910" },
  // { label: "Barangay 697", mun_code: "133910" },
  // { label: "Barangay 698", mun_code: "133910" },
  // { label: "Barangay 699", mun_code: "133910" },
  // { label: "Barangay 700", mun_code: "133910" },
  // { label: "Barangay 701", mun_code: "133910" },
  // { label: "Barangay 702", mun_code: "133910" },
  // { label: "Barangay 703", mun_code: "133910" },
  // { label: "Barangay 704", mun_code: "133910" },
  // { label: "Barangay 705", mun_code: "133910" },
  // { label: "Barangay 706", mun_code: "133910" },
  // { label: "Barangay 707", mun_code: "133910" },
  // { label: "Barangay 708", mun_code: "133910" },
  // { label: "Barangay 709", mun_code: "133910" },
  // { label: "Barangay 710", mun_code: "133910" },
  // { label: "Barangay 711", mun_code: "133910" },
  // { label: "Barangay 712", mun_code: "133910" },
  // { label: "Barangay 713", mun_code: "133910" },
  // { label: "Barangay 714", mun_code: "133910" },
  // { label: "Barangay 715", mun_code: "133910" },
  // { label: "Barangay 716", mun_code: "133910" },
  // { label: "Barangay 717", mun_code: "133910" },
  // { label: "Barangay 718", mun_code: "133910" },
  // { label: "Barangay 719", mun_code: "133910" },
  // { label: "Barangay 720", mun_code: "133910" },
  // { label: "Barangay 721", mun_code: "133910" },
  // { label: "Barangay 722", mun_code: "133910" },
  // { label: "Barangay 723", mun_code: "133910" },
  // { label: "Barangay 724", mun_code: "133910" },
  // { label: "Barangay 725", mun_code: "133910" },
  // { label: "Barangay 726", mun_code: "133910" },
  // { label: "Barangay 727", mun_code: "133910" },
  // { label: "Barangay 728", mun_code: "133910" },
  // { label: "Barangay 729", mun_code: "133910" },
  // { label: "Barangay 730", mun_code: "133910" },
  // { label: "Barangay 731", mun_code: "133910" },
  // { label: "Barangay 732", mun_code: "133910" },
  // { label: "Barangay 733", mun_code: "133910" },
  // { label: "Barangay 738", mun_code: "133910" },
  // { label: "Barangay 739", mun_code: "133910" },
  // { label: "Barangay 740", mun_code: "133910" },
  // { label: "Barangay 741", mun_code: "133910" },
  // { label: "Barangay 742", mun_code: "133910" },
  // { label: "Barangay 743", mun_code: "133910" },
  // { label: "Barangay 744", mun_code: "133910" },
  // { label: "Barangay 688", mun_code: "133910" },
  // { label: "Barangay 735", mun_code: "133910" },
  // { label: "Barangay 736", mun_code: "133910" },
  // { label: "Barangay 737", mun_code: "133910" },
  // { label: "Barangay 734", mun_code: "133910" },
  // { label: "Barangay 662", mun_code: "133911" },
  // { label: "Barangay 664-A", mun_code: "133911" },
  // { label: "Barangay 671", mun_code: "133911" },
  // { label: "Barangay 672", mun_code: "133911" },
  // { label: "Barangay 673", mun_code: "133911" },
  // { label: "Barangay 674", mun_code: "133911" },
  // { label: "Barangay 675", mun_code: "133911" },
  // { label: "Barangay 676", mun_code: "133911" },
  // { label: "Barangay 677", mun_code: "133911" },
  // { label: "Barangay 678", mun_code: "133911" },
  // { label: "Barangay 679", mun_code: "133911" },
  // { label: "Barangay 680", mun_code: "133911" },
  // { label: "Barangay 681", mun_code: "133911" },
  // { label: "Barangay 682", mun_code: "133911" },
  // { label: "Barangay 683", mun_code: "133911" },
  // { label: "Barangay 684", mun_code: "133911" },
  // { label: "Barangay 685", mun_code: "133911" },
  // { label: "Barangay 809", mun_code: "133911" },
  // { label: "Barangay 810", mun_code: "133911" },
  // { label: "Barangay 811", mun_code: "133911" },
  // { label: "Barangay 812", mun_code: "133911" },
  // { label: "Barangay 813", mun_code: "133911" },
  // { label: "Barangay 814", mun_code: "133911" },
  // { label: "Barangay 815", mun_code: "133911" },
  // { label: "Barangay 816", mun_code: "133911" },
  // { label: "Barangay 817", mun_code: "133911" },
  // { label: "Barangay 818", mun_code: "133911" },
  // { label: "Barangay 819", mun_code: "133911" },
  // { label: "Barangay 820", mun_code: "133911" },
  // { label: "Barangay 821", mun_code: "133911" },
  // { label: "Barangay 822", mun_code: "133911" },
  // { label: "Barangay 823", mun_code: "133911" },
  // { label: "Barangay 824", mun_code: "133911" },
  // { label: "Barangay 825", mun_code: "133911" },
  // { label: "Barangay 826", mun_code: "133911" },
  // { label: "Barangay 827", mun_code: "133911" },
  // { label: "Barangay 828", mun_code: "133911" },
  // { label: "Barangay 829", mun_code: "133911" },
  // { label: "Barangay 830", mun_code: "133911" },
  // { label: "Barangay 831", mun_code: "133911" },
  // { label: "Barangay 832", mun_code: "133911" },
  // { label: "Barangay 686", mun_code: "133911" },
  // { label: "Barangay 687", mun_code: "133911" },
  // { label: "Barangay 833", mun_code: "133912" },
  // { label: "Barangay 834", mun_code: "133912" },
  // { label: "Barangay 835", mun_code: "133912" },
  // { label: "Barangay 836", mun_code: "133912" },
  // { label: "Barangay 837", mun_code: "133912" },
  // { label: "Barangay 838", mun_code: "133912" },
  // { label: "Barangay 839", mun_code: "133912" },
  // { label: "Barangay 840", mun_code: "133912" },
  // { label: "Barangay 841", mun_code: "133912" },
  // { label: "Barangay 842", mun_code: "133912" },
  // { label: "Barangay 843", mun_code: "133912" },
  // { label: "Barangay 844", mun_code: "133912" },
  // { label: "Barangay 845", mun_code: "133912" },
  // { label: "Barangay 846", mun_code: "133912" },
  // { label: "Barangay 847", mun_code: "133912" },
  // { label: "Barangay 848", mun_code: "133912" },
  // { label: "Barangay 849", mun_code: "133912" },
  // { label: "Barangay 850", mun_code: "133912" },
  // { label: "Barangay 851", mun_code: "133912" },
  // { label: "Barangay 852", mun_code: "133912" },
  // { label: "Barangay 853", mun_code: "133912" },
  // { label: "Barangay 855", mun_code: "133912" },
  // { label: "Barangay 856", mun_code: "133912" },
  // { label: "Barangay 857", mun_code: "133912" },
  // { label: "Barangay 858", mun_code: "133912" },
  // { label: "Barangay 859", mun_code: "133912" },
  // { label: "Barangay 860", mun_code: "133912" },
  // { label: "Barangay 861", mun_code: "133912" },
  // { label: "Barangay 862", mun_code: "133912" },
  // { label: "Barangay 863", mun_code: "133912" },
  // { label: "Barangay 864", mun_code: "133912" },
  // { label: "Barangay 865", mun_code: "133912" },
  // { label: "Barangay 867", mun_code: "133912" },
  // { label: "Barangay 868", mun_code: "133912" },
  // { label: "Barangay 870", mun_code: "133912" },
  // { label: "Barangay 871", mun_code: "133912" },
  // { label: "Barangay 872", mun_code: "133912" },
  // { label: "Barangay 869", mun_code: "133912" },
  // { label: "Barangay 649", mun_code: "133913" },
  // { label: "Barangay 650", mun_code: "133913" },
  // { label: "Barangay 651", mun_code: "133913" },
  // { label: "Barangay 652", mun_code: "133913" },
  // { label: "Barangay 653", mun_code: "133913" },
  // { label: "Barangay 745", mun_code: "133914" },
  // { label: "Barangay 746", mun_code: "133914" },
  // { label: "Barangay 747", mun_code: "133914" },
  // { label: "Barangay 748", mun_code: "133914" },
  // { label: "Barangay 749", mun_code: "133914" },
  // { label: "Barangay 750", mun_code: "133914" },
  // { label: "Barangay 751", mun_code: "133914" },
  // { label: "Barangay 752", mun_code: "133914" },
  // { label: "Barangay 753", mun_code: "133914" },
  // { label: "Barangay 755", mun_code: "133914" },
  // { label: "Barangay 756", mun_code: "133914" },
  // { label: "Barangay 757", mun_code: "133914" },
  // { label: "Barangay 758", mun_code: "133914" },
  // { label: "Barangay 759", mun_code: "133914" },
  // { label: "Barangay 760", mun_code: "133914" },
  // { label: "Barangay 761", mun_code: "133914" },
  // { label: "Barangay 762", mun_code: "133914" },
  // { label: "Barangay 763", mun_code: "133914" },
  // { label: "Barangay 764", mun_code: "133914" },
  // { label: "Barangay 765", mun_code: "133914" },
  // { label: "Barangay 766", mun_code: "133914" },
  // { label: "Barangay 767", mun_code: "133914" },
  // { label: "Barangay 768", mun_code: "133914" },
  // { label: "Barangay 769", mun_code: "133914" },
  // { label: "Barangay 770", mun_code: "133914" },
  // { label: "Barangay 771", mun_code: "133914" },
  // { label: "Barangay 772", mun_code: "133914" },
  // { label: "Barangay 773", mun_code: "133914" },
  // { label: "Barangay 774", mun_code: "133914" },
  // { label: "Barangay 775", mun_code: "133914" },
  // { label: "Barangay 776", mun_code: "133914" },
  // { label: "Barangay 777", mun_code: "133914" },
  // { label: "Barangay 778", mun_code: "133914" },
  // { label: "Barangay 779", mun_code: "133914" },
  // { label: "Barangay 780", mun_code: "133914" },
  // { label: "Barangay 781", mun_code: "133914" },
  // { label: "Barangay 782", mun_code: "133914" },
  // { label: "Barangay 783", mun_code: "133914" },
  // { label: "Barangay 784", mun_code: "133914" },
  // { label: "Barangay 785", mun_code: "133914" },
  // { label: "Barangay 786", mun_code: "133914" },
  // { label: "Barangay 787", mun_code: "133914" },
  // { label: "Barangay 788", mun_code: "133914" },
  // { label: "Barangay 789", mun_code: "133914" },
  // { label: "Barangay 790", mun_code: "133914" },
  // { label: "Barangay 791", mun_code: "133914" },
  // { label: "Barangay 792", mun_code: "133914" },
  // { label: "Barangay 793", mun_code: "133914" },
  // { label: "Barangay 794", mun_code: "133914" },
  // { label: "Barangay 795", mun_code: "133914" },
  // { label: "Barangay 796", mun_code: "133914" },
  // { label: "Barangay 797", mun_code: "133914" },
  // { label: "Barangay 798", mun_code: "133914" },
  // { label: "Barangay 799", mun_code: "133914" },
  // { label: "Barangay 800", mun_code: "133914" },
  // { label: "Barangay 801", mun_code: "133914" },
  // { label: "Barangay 802", mun_code: "133914" },
  // { label: "Barangay 803", mun_code: "133914" },
  // { label: "Barangay 804", mun_code: "133914" },
  // { label: "Barangay 805", mun_code: "133914" },
  // { label: "Barangay 806", mun_code: "133914" },
  // { label: "Barangay 807", mun_code: "133914" },
  // { label: "Barangay 866", mun_code: "133914" },
  // { label: "Barangay 873", mun_code: "133914" },
  // { label: "Barangay 874", mun_code: "133914" },
  // { label: "Barangay 875", mun_code: "133914" },
  // { label: "Barangay 876", mun_code: "133914" },
  // { label: "Barangay 877", mun_code: "133914" },
  // { label: "Barangay 878", mun_code: "133914" },
  // { label: "Barangay 879", mun_code: "133914" },
  // { label: "Barangay 880", mun_code: "133914" },
  // { label: "Barangay 881", mun_code: "133914" },
  // { label: "Barangay 882", mun_code: "133914" },
  // { label: "Barangay 883", mun_code: "133914" },
  // { label: "Barangay 884", mun_code: "133914" },
  // { label: "Barangay 885", mun_code: "133914" },
  // { label: "Barangay 886", mun_code: "133914" },
  // { label: "Barangay 887", mun_code: "133914" },
  // { label: "Barangay 888", mun_code: "133914" },
  // { label: "Barangay 889", mun_code: "133914" },
  // { label: "Barangay 890", mun_code: "133914" },
  // { label: "Barangay 891", mun_code: "133914" },
  // { label: "Barangay 892", mun_code: "133914" },
  // { label: "Barangay 893", mun_code: "133914" },
  // { label: "Barangay 894", mun_code: "133914" },
  // { label: "Barangay 895", mun_code: "133914" },
  // { label: "Barangay 896", mun_code: "133914" },
  // { label: "Barangay 897", mun_code: "133914" },
  // { label: "Barangay 898", mun_code: "133914" },
  // { label: "Barangay 899", mun_code: "133914" },
  // { label: "Barangay 900", mun_code: "133914" },
  // { label: "Barangay 901", mun_code: "133914" },
  // { label: "Barangay 902", mun_code: "133914" },
  // { label: "Barangay 903", mun_code: "133914" },
  // { label: "Barangay 904", mun_code: "133914" },
  // { label: "Barangay 905", mun_code: "133914" },
  // { label: "Barangay 754", mun_code: "133914" },
  // { label: "Barangay 808", mun_code: "133914" },
  // { label: "Barangay 818-A", mun_code: "133914" },
  // { label: "Addition Hills", mun_code: "137401" },
  // { label: "Bagong Silang", mun_code: "137401" },
  // { label: "Barangka Drive", mun_code: "137401" },
  // { label: "Barangka Ibaba", mun_code: "137401" },
  // { label: "Barangka Ilaya", mun_code: "137401" },
  // { label: "Barangka Itaas", mun_code: "137401" },
  // { label: "Burol", mun_code: "137401" },
  // { label: "Buayang Bato", mun_code: "137401" },
  // { label: "Daang Bakal", mun_code: "137401" },
  // { label: "Hagdang Bato Itaas", mun_code: "137401" },
  // { label: "Hagdang Bato Libis", mun_code: "137401" },
  // { label: "Harapin Ang Bukas", mun_code: "137401" },
  // { label: "Highway Hills", mun_code: "137401" },
  // { label: "Hulo", mun_code: "137401" },
  // { label: "Mabini-J. Rizal", mun_code: "137401" },
  // { label: "Malamig", mun_code: "137401" },
  // { label: "Mauway", mun_code: "137401" },
  // { label: "Namayan", mun_code: "137401" },
  // { label: "Pag-asa", mun_code: "137401" },
  // { label: "Plainview", mun_code: "137401" },
  // { label: "Pleasant Hills", mun_code: "137401" },
  // { label: "Poblacion", mun_code: "137401" },
  // { label: "San Jose", mun_code: "137401" },
  // { label: "Vergara", mun_code: "137401" },
  // { label: "Wack-wack Greenhills", mun_code: "137401" },
  // { label: "Barangka", mun_code: "137402" },
  // { label: "Calumpang", mun_code: "137402" },
  // { label: "Concepcion Uno", mun_code: "137402" },
  // { label: "Malanday", mun_code: "137402" },
  // { label: "Nangka", mun_code: "137402" },
  // { label: "Parang", mun_code: "137402" },
  // { label: "San Roque", mun_code: "137402" },
  // { label: "Santa Elena (Pob.)", mun_code: "137402" },
  // { label: "Concepcion Dos", mun_code: "137402" },
  // { label: "Marikina Heights (Concepcion)", mun_code: "137402" },
  // { label: "Industrial Valley", mun_code: "137402" },
  // { label: "Fortune", mun_code: "137402" },
  // { label: "Tumana", mun_code: "137402" },
  // { label: "Bagong Ilog", mun_code: "137403" },
  // { label: "Bagong Katipunan", mun_code: "137403" },
  // { label: "Bambang", mun_code: "137403" },
  // { label: "Buting", mun_code: "137403" },
  // { label: "Caniogan", mun_code: "137403" },
  // { label: "Dela Paz", mun_code: "137403" },
  // { label: "Kalawaan", mun_code: "137403" },
  // { label: "Kapasigan", mun_code: "137403" },
  // { label: "Kapitolyo", mun_code: "137403" },
  // { label: "Malinao", mun_code: "137403" },
  // { label: "Manggahan", mun_code: "137403" },
  // { label: "Maybunga", mun_code: "137403" },
  // { label: "Oranbo", mun_code: "137403" },
  // { label: "Palatiw", mun_code: "137403" },
  // { label: "Pinagbuhatan", mun_code: "137403" },
  // { label: "Pineda", mun_code: "137403" },
  // { label: "Rosario", mun_code: "137403" },
  // { label: "Sagad", mun_code: "137403" },
  // { label: "San Antonio", mun_code: "137403" },
  // { label: "San Joaquin", mun_code: "137403" },
  // { label: "San Jose", mun_code: "137403" },
  // { label: "San Miguel", mun_code: "137403" },
  // { label: "San Nicolas (Pob.)", mun_code: "137403" },
  // { label: "Santa Cruz", mun_code: "137403" },
  // { label: "Santa Rosa", mun_code: "137403" },
  // { label: "Santo Tomas", mun_code: "137403" },
  // { label: "Santolan", mun_code: "137403" },
  // { label: "Sumilang", mun_code: "137403" },
  // { label: "Ugong", mun_code: "137403" },
  // { label: "Santa Lucia", mun_code: "137403" },
  // { label: "Alicia", mun_code: "137404" },
  // { label: "Amihan", mun_code: "137404" },
  // { label: "Apolonio Samson", mun_code: "137404" },
  // { label: "Aurora", mun_code: "137404" },
  // { label: "Baesa", mun_code: "137404" },
  // { label: "Bagbag", mun_code: "137404" },
  // { label: "Bagumbuhay", mun_code: "137404" },
  // { label: "Bagong Lipunan Ng Crame", mun_code: "137404" },
  // { label: "Bagong Pag-asa", mun_code: "137404" },
  // { label: "Bagong Silangan", mun_code: "137404" },
  // { label: "Bagumbayan", mun_code: "137404" },
  // { label: "Bahay Toro", mun_code: "137404" },
  // { label: "Balingasa", mun_code: "137404" },
  // { label: "Bayanihan", mun_code: "137404" },
  // { label: "Blue Ridge A", mun_code: "137404" },
  // { label: "Blue Ridge B", mun_code: "137404" },
  // { label: "Botocan", mun_code: "137404" },
  // { label: "Bungad", mun_code: "137404" },
  // { label: "Camp Aguinaldo", mun_code: "137404" },
  // { label: "Central", mun_code: "137404" },
  // { label: "Claro", mun_code: "137404" },
  // { label: "Commonwealth", mun_code: "137404" },
  // { label: "New Era (Constitution Hills)", mun_code: "137404" },
  // { label: "Kristong Hari", mun_code: "137404" },
  // { label: "Culiat", mun_code: "137404" },
  // { label: "Damar", mun_code: "137404" },
  // { label: "Damayan", mun_code: "137404" },
  // { label: "Damayang Lagi", mun_code: "137404" },
  // { label: "Del Monte", mun_code: "137404" },
  // { label: "Dioquino Zobel", mun_code: "137404" },
  // { label: "Don Manuel", mun_code: "137404" },
  // { label: "Duyan-duyan", mun_code: "137404" },
  // { label: "E. Rodriguez", mun_code: "137404" },
  // { label: "East Kamias", mun_code: "137404" },
  // { label: "Escopa I", mun_code: "137404" },
  // { label: "Escopa II", mun_code: "137404" },
  // { label: "Escopa III", mun_code: "137404" },
  // { label: "Escopa IV", mun_code: "137404" },
  // { label: "Fairview", mun_code: "137404" },
  // { label: "N.S. Amoranto (Gintong Silahis)", mun_code: "137404" },
  // { label: "Gulod", mun_code: "137404" },
  // { label: "Horseshoe", mun_code: "137404" },
  // { label: "Immaculate Concepcion", mun_code: "137404" },
  // { label: "Kaligayahan", mun_code: "137404" },
  // { label: "Kalusugan", mun_code: "137404" },
  // { label: "Kamuning", mun_code: "137404" },
  // { label: "Katipunan", mun_code: "137404" },
  // { label: "Kaunlaran", mun_code: "137404" },
  // { label: "Krus Na Ligas", mun_code: "137404" },
  // { label: "Laging Handa", mun_code: "137404" },
  // { label: "Libis", mun_code: "137404" },
  // { label: "Lourdes", mun_code: "137404" },
  // { label: "Loyola Heights", mun_code: "137404" },
  // { label: "Maharlika", mun_code: "137404" },
  // { label: "Malaya", mun_code: "137404" },
  // { label: "Manresa", mun_code: "137404" },
  // { label: "Mangga", mun_code: "137404" },
  // { label: "Mariana", mun_code: "137404" },
  // { label: "Mariblo", mun_code: "137404" },
  // { label: "Marilag", mun_code: "137404" },
  // { label: "Masagana", mun_code: "137404" },
  // { label: "Masambong", mun_code: "137404" },
  // { label: "Santo Domingo (Matalahib)", mun_code: "137404" },
  // { label: "Matandang Balara", mun_code: "137404" },
  // { label: "Milagrosa", mun_code: "137404" },
  // { label: "Nagkaisang Nayon", mun_code: "137404" },
  // { label: "Nayong Kanluran", mun_code: "137404" },
  // { label: "Novaliches Proper", mun_code: "137404" },
  // { label: "Obrero", mun_code: "137404" },
  // { label: "Old Capitol Site", mun_code: "137404" },
  // { label: "Paang Bundok", mun_code: "137404" },
  // { label: "Pag-ibig Sa Nayon", mun_code: "137404" },
  // { label: "Paligsahan", mun_code: "137404" },
  // { label: "Paltok", mun_code: "137404" },
  // { label: "Pansol", mun_code: "137404" },
  // { label: "Paraiso", mun_code: "137404" },
  // { label: "Pasong Putik Proper (Pasong Putik)", mun_code: "137404" },
  // { label: "Pasong Tamo", mun_code: "137404" },
  // { label: "Phil-Am", mun_code: "137404" },
  // { label: "Pinyahan", mun_code: "137404" },
  // { label: "Pinagkaisahan", mun_code: "137404" },
  // { label: "Project 6", mun_code: "137404" },
  // { label: "Quirino 2-A", mun_code: "137404" },
  // { label: "Quirino 2-B", mun_code: "137404" },
  // { label: "Quirino 2-C", mun_code: "137404" },
  // { label: "Quirino 3-A", mun_code: "137404" },
  // { label: "Ramon Magsaysay", mun_code: "137404" },
  // { label: "Roxas", mun_code: "137404" },
  // { label: "Sacred Heart", mun_code: "137404" },
  // { label: "Saint Ignatius", mun_code: "137404" },
  // { label: "Saint Peter", mun_code: "137404" },
  // { label: "Salvacion", mun_code: "137404" },
  // { label: "San Agustin", mun_code: "137404" },
  // { label: "San Antonio", mun_code: "137404" },
  // { label: "San Bartolome", mun_code: "137404" },
  // { label: "San Isidro", mun_code: "137404" },
  // { label: "San Isidro Labrador", mun_code: "137404" },
  // { label: "San Jose", mun_code: "137404" },
  // { label: "San Martin De Porres", mun_code: "137404" },
  // { label: "San Roque", mun_code: "137404" },
  // { label: "San Vicente", mun_code: "137404" },
  // { label: "Santa Cruz", mun_code: "137404" },
  // { label: "Santa Lucia", mun_code: "137404" },
  // { label: "Santa Monica", mun_code: "137404" },
  // { label: "Santa Teresita", mun_code: "137404" },
  // { label: "Santo Cristo", mun_code: "137404" },
  // { label: "Santol", mun_code: "137404" },
  // { label: "Sauyo", mun_code: "137404" },
  // { label: "Sienna", mun_code: "137404" },
  // { label: "Sikatuna Village", mun_code: "137404" },
  // { label: "Silangan", mun_code: "137404" },
  // { label: "Socorro", mun_code: "137404" },
  // { label: "South Triangle", mun_code: "137404" },
  // { label: "Tagumpay", mun_code: "137404" },
  // { label: "Talayan", mun_code: "137404" },
  // { label: "Talipapa", mun_code: "137404" },
  // { label: "Tandang Sora", mun_code: "137404" },
  // { label: "Tatalon", mun_code: "137404" },
  // { label: "Teachers Village East", mun_code: "137404" },
  // { label: "Teachers Village West", mun_code: "137404" },
  // { label: "U.P. Campus", mun_code: "137404" },
  // { label: "U.P. Village", mun_code: "137404" },
  // { label: "Ugong Norte", mun_code: "137404" },
  // { label: "Unang Sigaw", mun_code: "137404" },
  // { label: "Valencia", mun_code: "137404" },
  // { label: "Vasra", mun_code: "137404" },
  // { label: "Veterans Village", mun_code: "137404" },
  // { label: "Villa Maria Clara", mun_code: "137404" },
  // { label: "West Kamias", mun_code: "137404" },
  // { label: "West Triangle", mun_code: "137404" },
  // { label: "White Plains", mun_code: "137404" },
  // { label: "Balong Bato", mun_code: "137404" },
  // { label: "Capri", mun_code: "137404" },
  // { label: "Sangandaan", mun_code: "137404" },
  // { label: "Payatas", mun_code: "137404" },
  // { label: "Batasan Hills", mun_code: "137404" },
  // { label: "Holy Spirit", mun_code: "137404" },
  // { label: "Greater Lagro", mun_code: "137404" },
  // { label: "North Fairview", mun_code: "137404" },
  // { label: "Addition Hills", mun_code: "137405" },
  // { label: "Balong-Bato", mun_code: "137405" },
  // { label: "Batis", mun_code: "137405" },
  // { label: "Corazon De Jesus", mun_code: "137405" },
  // { label: "Halo-halo (St. Joseph)", mun_code: "137405" },
  // { label: "Isabelita", mun_code: "137405" },
  // { label: "Kabayanan", mun_code: "137405" },
  // { label: "Little Baguio", mun_code: "137405" },
  // { label: "Maytunas", mun_code: "137405" },
  // { label: "Onse", mun_code: "137405" },
  // { label: "Pedro Cruz", mun_code: "137405" },
  // { label: "Progreso", mun_code: "137405" },
  // { label: "Rivera", mun_code: "137405" },
  // { label: "Salapan", mun_code: "137405" },
  // { label: "San Perfecto", mun_code: "137405" },
  // { label: "Santa Lucia", mun_code: "137405" },
  // { label: "Tibagan", mun_code: "137405" },
  // { label: "West Crame", mun_code: "137405" },
  // { label: "Greenhills", mun_code: "137405" },
  // { label: "Barangay 1", mun_code: "137501" },
  // { label: "Barangay 2", mun_code: "137501" },
  // { label: "Barangay 3", mun_code: "137501" },
  // { label: "Barangay 4", mun_code: "137501" },
  // { label: "Barangay 5", mun_code: "137501" },
  // { label: "Barangay 6", mun_code: "137501" },
  // { label: "Barangay 7", mun_code: "137501" },
  // { label: "Barangay 8", mun_code: "137501" },
  // { label: "Barangay 9", mun_code: "137501" },
  // { label: "Barangay 10", mun_code: "137501" },
  // { label: "Barangay 11", mun_code: "137501" },
  // { label: "Barangay 12", mun_code: "137501" },
  // { label: "Barangay 13", mun_code: "137501" },
  // { label: "Barangay 14", mun_code: "137501" },
  // { label: "Barangay 15", mun_code: "137501" },
  // { label: "Barangay 16", mun_code: "137501" },
  // { label: "Barangay 17", mun_code: "137501" },
  // { label: "Barangay 18", mun_code: "137501" },
  // { label: "Barangay 19", mun_code: "137501" },
  // { label: "Barangay 20", mun_code: "137501" },
  // { label: "Barangay 21", mun_code: "137501" },
  // { label: "Barangay 22", mun_code: "137501" },
  // { label: "Barangay 23", mun_code: "137501" },
  // { label: "Barangay 24", mun_code: "137501" },
  // { label: "Barangay 25", mun_code: "137501" },
  // { label: "Barangay 26", mun_code: "137501" },
  // { label: "Barangay 27", mun_code: "137501" },
  // { label: "Barangay 28", mun_code: "137501" },
  // { label: "Barangay 29", mun_code: "137501" },
  // { label: "Barangay 30", mun_code: "137501" },
  // { label: "Barangay 31", mun_code: "137501" },
  // { label: "Barangay 32", mun_code: "137501" },
  // { label: "Barangay 33", mun_code: "137501" },
  // { label: "Barangay 34", mun_code: "137501" },
  // { label: "Barangay 35", mun_code: "137501" },
  // { label: "Barangay 36", mun_code: "137501" },
  // { label: "Barangay 37", mun_code: "137501" },
  // { label: "Barangay 38", mun_code: "137501" },
  // { label: "Barangay 39", mun_code: "137501" },
  // { label: "Barangay 40", mun_code: "137501" },
  // { label: "Barangay 41", mun_code: "137501" },
  // { label: "Barangay 42", mun_code: "137501" },
  // { label: "Barangay 43", mun_code: "137501" },
  // { label: "Barangay 44", mun_code: "137501" },
  // { label: "Barangay 45", mun_code: "137501" },
  // { label: "Barangay 46", mun_code: "137501" },
  // { label: "Barangay 47", mun_code: "137501" },
  // { label: "Barangay 48", mun_code: "137501" },
  // { label: "Barangay 49", mun_code: "137501" },
  // { label: "Barangay 50", mun_code: "137501" },
  // { label: "Barangay 51", mun_code: "137501" },
  // { label: "Barangay 52", mun_code: "137501" },
  // { label: "Barangay 53", mun_code: "137501" },
  // { label: "Barangay 54", mun_code: "137501" },
  // { label: "Barangay 55", mun_code: "137501" },
  // { label: "Barangay 56", mun_code: "137501" },
  // { label: "Barangay 57", mun_code: "137501" },
  // { label: "Barangay 58", mun_code: "137501" },
  // { label: "Barangay 59", mun_code: "137501" },
  // { label: "Barangay 60", mun_code: "137501" },
  // { label: "Barangay 61", mun_code: "137501" },
  // { label: "Barangay 62", mun_code: "137501" },
  // { label: "Barangay 63", mun_code: "137501" },
  // { label: "Barangay 64", mun_code: "137501" },
  // { label: "Barangay 65", mun_code: "137501" },
  // { label: "Barangay 66", mun_code: "137501" },
  // { label: "Barangay 67", mun_code: "137501" },
  // { label: "Barangay 68", mun_code: "137501" },
  // { label: "Barangay 69", mun_code: "137501" },
  // { label: "Barangay 70", mun_code: "137501" },
  // { label: "Barangay 71", mun_code: "137501" },
  // { label: "Barangay 72", mun_code: "137501" },
  // { label: "Barangay 73", mun_code: "137501" },
  // { label: "Barangay 74", mun_code: "137501" },
  // { label: "Barangay 75", mun_code: "137501" },
  // { label: "Barangay 76", mun_code: "137501" },
  // { label: "Barangay 77", mun_code: "137501" },
  // { label: "Barangay 78", mun_code: "137501" },
  // { label: "Barangay 79", mun_code: "137501" },
  // { label: "Barangay 80", mun_code: "137501" },
  // { label: "Barangay 81", mun_code: "137501" },
  // { label: "Barangay 82", mun_code: "137501" },
  // { label: "Barangay 83", mun_code: "137501" },
  // { label: "Barangay 84", mun_code: "137501" },
  // { label: "Barangay 85", mun_code: "137501" },
  // { label: "Barangay 86", mun_code: "137501" },
  // { label: "Barangay 87", mun_code: "137501" },
  // { label: "Barangay 88", mun_code: "137501" },
  // { label: "Barangay 89", mun_code: "137501" },
  // { label: "Barangay 90", mun_code: "137501" },
  // { label: "Barangay 91", mun_code: "137501" },
  // { label: "Barangay 92", mun_code: "137501" },
  // { label: "Barangay 93", mun_code: "137501" },
  // { label: "Barangay 94", mun_code: "137501" },
  // { label: "Barangay 95", mun_code: "137501" },
  // { label: "Barangay 96", mun_code: "137501" },
  // { label: "Barangay 97", mun_code: "137501" },
  // { label: "Barangay 98", mun_code: "137501" },
  // { label: "Barangay 99", mun_code: "137501" },
  // { label: "Barangay 100", mun_code: "137501" },
  // { label: "Barangay 101", mun_code: "137501" },
  // { label: "Barangay 102", mun_code: "137501" },
  // { label: "Barangay 103", mun_code: "137501" },
  // { label: "Barangay 104", mun_code: "137501" },
  // { label: "Barangay 105", mun_code: "137501" },
  // { label: "Barangay 106", mun_code: "137501" },
  // { label: "Barangay 107", mun_code: "137501" },
  // { label: "Barangay 108", mun_code: "137501" },
  // { label: "Barangay 109", mun_code: "137501" },
  // { label: "Barangay 110", mun_code: "137501" },
  // { label: "Barangay 111", mun_code: "137501" },
  // { label: "Barangay 112", mun_code: "137501" },
  // { label: "Barangay 113", mun_code: "137501" },
  // { label: "Barangay 114", mun_code: "137501" },
  // { label: "Barangay 115", mun_code: "137501" },
  // { label: "Barangay 116", mun_code: "137501" },
  // { label: "Barangay 117", mun_code: "137501" },
  // { label: "Barangay 118", mun_code: "137501" },
  // { label: "Barangay 119", mun_code: "137501" },
  // { label: "Barangay 120", mun_code: "137501" },
  // { label: "Barangay 121", mun_code: "137501" },
  // { label: "Barangay 122", mun_code: "137501" },
  // { label: "Barangay 123", mun_code: "137501" },
  // { label: "Barangay 124", mun_code: "137501" },
  // { label: "Barangay 125", mun_code: "137501" },
  // { label: "Barangay 126", mun_code: "137501" },
  // { label: "Barangay 127", mun_code: "137501" },
  // { label: "Barangay 128", mun_code: "137501" },
  // { label: "Barangay 129", mun_code: "137501" },
  // { label: "Barangay 130", mun_code: "137501" },
  // { label: "Barangay 131", mun_code: "137501" },
  // { label: "Barangay 132", mun_code: "137501" },
  // { label: "Barangay 133", mun_code: "137501" },
  // { label: "Barangay 134", mun_code: "137501" },
  // { label: "Barangay 135", mun_code: "137501" },
  // { label: "Barangay 136", mun_code: "137501" },
  // { label: "Barangay 137", mun_code: "137501" },
  // { label: "Barangay 138", mun_code: "137501" },
  // { label: "Barangay 139", mun_code: "137501" },
  // { label: "Barangay 140", mun_code: "137501" },
  // { label: "Barangay 141", mun_code: "137501" },
  // { label: "Barangay 142", mun_code: "137501" },
  // { label: "Barangay 143", mun_code: "137501" },
  // { label: "Barangay 144", mun_code: "137501" },
  // { label: "Barangay 145", mun_code: "137501" },
  // { label: "Barangay 146", mun_code: "137501" },
  // { label: "Barangay 147", mun_code: "137501" },
  // { label: "Barangay 148", mun_code: "137501" },
  // { label: "Barangay 149", mun_code: "137501" },
  // { label: "Barangay 150", mun_code: "137501" },
  // { label: "Barangay 151", mun_code: "137501" },
  // { label: "Barangay 152", mun_code: "137501" },
  // { label: "Barangay 153", mun_code: "137501" },
  // { label: "Barangay 154", mun_code: "137501" },
  // { label: "Barangay 155", mun_code: "137501" },
  // { label: "Barangay 156", mun_code: "137501" },
  // { label: "Barangay 157", mun_code: "137501" },
  // { label: "Barangay 158", mun_code: "137501" },
  // { label: "Barangay 159", mun_code: "137501" },
  // { label: "Barangay 160", mun_code: "137501" },
  // { label: "Barangay 161", mun_code: "137501" },
  // { label: "Barangay 162", mun_code: "137501" },
  // { label: "Barangay 163", mun_code: "137501" },
  // { label: "Barangay 164", mun_code: "137501" },
  // { label: "Barangay 165", mun_code: "137501" },
  // { label: "Barangay 166", mun_code: "137501" },
  // { label: "Barangay 167", mun_code: "137501" },
  // { label: "Barangay 168", mun_code: "137501" },
  // { label: "Barangay 169", mun_code: "137501" },
  // { label: "Barangay 170", mun_code: "137501" },
  // { label: "Barangay 171", mun_code: "137501" },
  // { label: "Barangay 172", mun_code: "137501" },
  // { label: "Barangay 173", mun_code: "137501" },
  // { label: "Barangay 174", mun_code: "137501" },
  // { label: "Barangay 175", mun_code: "137501" },
  // { label: "Barangay 176", mun_code: "137501" },
  // { label: "Barangay 177", mun_code: "137501" },
  // { label: "Barangay 178", mun_code: "137501" },
  // { label: "Barangay 179", mun_code: "137501" },
  // { label: "Barangay 180", mun_code: "137501" },
  // { label: "Barangay 181", mun_code: "137501" },
  // { label: "Barangay 182", mun_code: "137501" },
  // { label: "Barangay 183", mun_code: "137501" },
  // { label: "Barangay 184", mun_code: "137501" },
  // { label: "Barangay 185", mun_code: "137501" },
  // { label: "Barangay 186", mun_code: "137501" },
  // { label: "Barangay 187", mun_code: "137501" },
  // { label: "Barangay 188", mun_code: "137501" },
  // { label: "Acacia", mun_code: "137502" },
  // { label: "Baritan", mun_code: "137502" },
  // { label: "Bayan-bayanan", mun_code: "137502" },
  // { label: "Catmon", mun_code: "137502" },
  // { label: "Concepcion", mun_code: "137502" },
  // { label: "Dampalit", mun_code: "137502" },
  // { label: "Flores", mun_code: "137502" },
  // { label: "Hulong Duhat", mun_code: "137502" },
  // { label: "Ibaba", mun_code: "137502" },
  // { label: "Longos", mun_code: "137502" },
  // { label: "Maysilo", mun_code: "137502" },
  // { label: "Muzon", mun_code: "137502" },
  // { label: "Niugan", mun_code: "137502" },
  // { label: "Panghulo", mun_code: "137502" },
  // { label: "Potrero", mun_code: "137502" },
  // { label: "San Agustin", mun_code: "137502" },
  // { label: "Santolan", mun_code: "137502" },
  // { label: "Tinajeros", mun_code: "137502" },
  // { label: "Tonsuya", mun_code: "137502" },
  // { label: "Tugatog", mun_code: "137502" },
  // { label: "Sipac-Almacen", mun_code: "137503" },
  // { label: "Bagumbayan North", mun_code: "137503" },
  // { label: "Bagumbayan South", mun_code: "137503" },
  // { label: "Bangculasi", mun_code: "137503" },
  // { label: "Daanghari", mun_code: "137503" },
  // { label: "Navotas East", mun_code: "137503" },
  // { label: "Navotas West", mun_code: "137503" },
  // { label: "North Bay Blvd., North", mun_code: "137503" },
  // { label: "North Bay Blvd., South", mun_code: "137503" },
  // { label: "San Jose (Pob.)", mun_code: "137503" },
  // { label: "San Rafael Village", mun_code: "137503" },
  // { label: "San Roque", mun_code: "137503" },
  // { label: "Tangos", mun_code: "137503" },
  // { label: "Tanza", mun_code: "137503" },
  // { label: "Arkong Bato", mun_code: "137504" },
  // { label: "Bagbaguin", mun_code: "137504" },
  // { label: "Balangkas", mun_code: "137504" },
  // { label: "Parada", mun_code: "137504" },
  // { label: "Bignay", mun_code: "137504" },
  // { label: "Bisig", mun_code: "137504" },
  // { label: "Canumay West (Canumay)", mun_code: "137504" },
  // { label: "Karuhatan", mun_code: "137504" },
  // { label: "Coloong", mun_code: "137504" },
  // { label: "Dalandanan", mun_code: "137504" },
  // { label: "Hen. T. De Leon", mun_code: "137504" },
  // { label: "Isla", mun_code: "137504" },
  // { label: "Lawang Bato", mun_code: "137504" },
  // { label: "Lingunan", mun_code: "137504" },
  // { label: "Mabolo", mun_code: "137504" },
  // { label: "Malanday", mun_code: "137504" },
  // { label: "Malinta", mun_code: "137504" },
  // { label: "Mapulang Lupa", mun_code: "137504" },
  // { label: "Marulas", mun_code: "137504" },
  // { label: "Maysan", mun_code: "137504" },
  // { label: "Palasan", mun_code: "137504" },
  // { label: "Pariancillo Villa", mun_code: "137504" },
  // { label: "Paso De Blas", mun_code: "137504" },
  // { label: "Pasolo", mun_code: "137504" },
  // { label: "Poblacion", mun_code: "137504" },
  // { label: "Pulo", mun_code: "137504" },
  // { label: "Punturin", mun_code: "137504" },
  // { label: "Rincon", mun_code: "137504" },
  // { label: "Tagalag", mun_code: "137504" },
  // { label: "Ugong", mun_code: "137504" },
  // { label: "Viente Reales", mun_code: "137504" },
  // { label: "Wawang Pulo", mun_code: "137504" },
  // { label: "Canumay East (Canumay)", mun_code: "137504" },
  // { label: "Almanza Uno", mun_code: "137601" },
  // { label: "Daniel Fajardo", mun_code: "137601" },
  // { label: "Elias Aldana", mun_code: "137601" },
  // { label: "Ilaya", mun_code: "137601" },
  // { label: "Manuyo Uno", mun_code: "137601" },
  // { label: "Pamplona Uno", mun_code: "137601" },
  // { label: "Pulang Lupa Uno", mun_code: "137601" },
  // { label: "Talon Uno", mun_code: "137601" },
  // { label: "Zapote", mun_code: "137601" },
  // { label: "Almanza Dos", mun_code: "137601" },
  // { label: "B. F. International Village", mun_code: "137601" },
  // { label: "Manuyo Dos", mun_code: "137601" },
  // { label: "Pamplona Dos", mun_code: "137601" },
  // { label: "Pamplona Tres", mun_code: "137601" },
  // { label: "Pilar", mun_code: "137601" },
  // { label: "Pulang Lupa Dos", mun_code: "137601" },
  // { label: "Talon Dos", mun_code: "137601" },
  // { label: "Talon Tres", mun_code: "137601" },
  // { label: "Talon Kuatro", mun_code: "137601" },
  // { label: "Talon Singko", mun_code: "137601" },
  // { label: "Bangkal", mun_code: "137602" },
  // { label: "Bel-Air", mun_code: "137602" },
  // { label: "Cembo", mun_code: "137602" },
  // { label: "Comembo", mun_code: "137602" },
  // { label: "Carmona", mun_code: "137602" },
  // { label: "East Rembo", mun_code: "137602" },
  // { label: "Forbes Park", mun_code: "137602" },
  // { label: "Guadalupe Nuevo", mun_code: "137602" },
  // { label: "Guadalupe Viejo", mun_code: "137602" },
  // { label: "Kasilawan", mun_code: "137602" },
  // { label: "La Paz", mun_code: "137602" },
  // { label: "Magallanes", mun_code: "137602" },
  // { label: "Olympia", mun_code: "137602" },
  // { label: "Palanan", mun_code: "137602" },
  // { label: "Pembo", mun_code: "137602" },
  // { label: "Pinagkaisahan", mun_code: "137602" },
  // { label: "Pio Del Pilar", mun_code: "137602" },
  // { label: "Pitogo", mun_code: "137602" },
  // { label: "Poblacion", mun_code: "137602" },
  // { label: "Post Proper Northside", mun_code: "137602" },
  // { label: "Post Proper Southside", mun_code: "137602" },
  // { label: "San Antonio", mun_code: "137602" },
  // { label: "San Isidro", mun_code: "137602" },
  // { label: "San Lorenzo", mun_code: "137602" },
  // { label: "Santa Cruz", mun_code: "137602" },
  // { label: "Singkamas", mun_code: "137602" },
  // { label: "South Cembo", mun_code: "137602" },
  // { label: "Tejeros", mun_code: "137602" },
  // { label: "Urdaneta", mun_code: "137602" },
  // { label: "Valenzuela", mun_code: "137602" },
  // { label: "West Rembo", mun_code: "137602" },
  // { label: "Rizal", mun_code: "137602" },
  // { label: "Alabang", mun_code: "137603" },
  // { label: "Bayanan", mun_code: "137603" },
  // { label: "Buli", mun_code: "137603" },
  // { label: "Cupang", mun_code: "137603" },
  // { label: "Poblacion", mun_code: "137603" },
  // { label: "Putatan", mun_code: "137603" },
  // { label: "Sucat", mun_code: "137603" },
  // { label: "Tunasan", mun_code: "137603" },
  // { label: "New Alabang Village", mun_code: "137603" },
  // { label: "Baclaran", mun_code: "137604" },
  // { label: "Don Galo", mun_code: "137604" },
  // { label: "La Huerta", mun_code: "137604" },
  // { label: "San Dionisio", mun_code: "137604" },
  // { label: "Tambo", mun_code: "137604" },
  // { label: "B. F. Homes", mun_code: "137604" },
  // { label: "Don Bosco", mun_code: "137604" },
  // { label: "Marcelo Green Village", mun_code: "137604" },
  // { label: "Merville", mun_code: "137604" },
  // { label: "Moonwalk", mun_code: "137604" },
  // { label: "San Antonio", mun_code: "137604" },
  // { label: "San Isidro", mun_code: "137604" },
  // { label: "San Martin De Porres", mun_code: "137604" },
  // { label: "Sun Valley", mun_code: "137604" },
  // { label: "Vitalez", mun_code: "137604" },
  // { label: "Barangay 1", mun_code: "137605" },
  // { label: "Barangay 2", mun_code: "137605" },
  // { label: "Barangay 3", mun_code: "137605" },
  // { label: "Barangay 4", mun_code: "137605" },
  // { label: "Barangay 5", mun_code: "137605" },
  // { label: "Barangay 6", mun_code: "137605" },
  // { label: "Barangay 7", mun_code: "137605" },
  // { label: "Barangay 8", mun_code: "137605" },
  // { label: "Barangay 9", mun_code: "137605" },
  // { label: "Barangay 10", mun_code: "137605" },
  // { label: "Barangay 11", mun_code: "137605" },
  // { label: "Barangay 12", mun_code: "137605" },
  // { label: "Barangay 13", mun_code: "137605" },
  // { label: "Barangay 14", mun_code: "137605" },
  // { label: "Barangay 15", mun_code: "137605" },
  // { label: "Barangay 16", mun_code: "137605" },
  // { label: "Barangay 17", mun_code: "137605" },
  // { label: "Barangay 18", mun_code: "137605" },
  // { label: "Barangay 19", mun_code: "137605" },
  // { label: "Barangay 20", mun_code: "137605" },
  // { label: "Barangay 21", mun_code: "137605" },
  // { label: "Barangay 22", mun_code: "137605" },
  // { label: "Barangay 23", mun_code: "137605" },
  // { label: "Barangay 24", mun_code: "137605" },
  // { label: "Barangay 25", mun_code: "137605" },
  // { label: "Barangay 26", mun_code: "137605" },
  // { label: "Barangay 27", mun_code: "137605" },
  // { label: "Barangay 28", mun_code: "137605" },
  // { label: "Barangay 29", mun_code: "137605" },
  // { label: "Barangay 30", mun_code: "137605" },
  // { label: "Barangay 31", mun_code: "137605" },
  // { label: "Barangay 32", mun_code: "137605" },
  // { label: "Barangay 33", mun_code: "137605" },
  // { label: "Barangay 34", mun_code: "137605" },
  // { label: "Barangay 35", mun_code: "137605" },
  // { label: "Barangay 36", mun_code: "137605" },
  // { label: "Barangay 37", mun_code: "137605" },
  // { label: "Barangay 38", mun_code: "137605" },
  // { label: "Barangay 39", mun_code: "137605" },
  // { label: "Barangay 40", mun_code: "137605" },
  // { label: "Barangay 41", mun_code: "137605" },
  // { label: "Barangay 42", mun_code: "137605" },
  // { label: "Barangay 43", mun_code: "137605" },
  // { label: "Barangay 44", mun_code: "137605" },
  // { label: "Barangay 45", mun_code: "137605" },
  // { label: "Barangay 46", mun_code: "137605" },
  // { label: "Barangay 47", mun_code: "137605" },
  // { label: "Barangay 48", mun_code: "137605" },
  // { label: "Barangay 49", mun_code: "137605" },
  // { label: "Barangay 50", mun_code: "137605" },
  // { label: "Barangay 51", mun_code: "137605" },
  // { label: "Barangay 52", mun_code: "137605" },
  // { label: "Barangay 53", mun_code: "137605" },
  // { label: "Barangay 54", mun_code: "137605" },
  // { label: "Barangay 55", mun_code: "137605" },
  // { label: "Barangay 56", mun_code: "137605" },
  // { label: "Barangay 57", mun_code: "137605" },
  // { label: "Barangay 58", mun_code: "137605" },
  // { label: "Barangay 59", mun_code: "137605" },
  // { label: "Barangay 60", mun_code: "137605" },
  // { label: "Barangay 61", mun_code: "137605" },
  // { label: "Barangay 62", mun_code: "137605" },
  // { label: "Barangay 63", mun_code: "137605" },
  // { label: "Barangay 64", mun_code: "137605" },
  // { label: "Barangay 65", mun_code: "137605" },
  // { label: "Barangay 66", mun_code: "137605" },
  // { label: "Barangay 67", mun_code: "137605" },
  // { label: "Barangay 68", mun_code: "137605" },
  // { label: "Barangay 69", mun_code: "137605" },
  // { label: "Barangay 70", mun_code: "137605" },
  // { label: "Barangay 71", mun_code: "137605" },
  // { label: "Barangay 72", mun_code: "137605" },
  // { label: "Barangay 73", mun_code: "137605" },
  // { label: "Barangay 74", mun_code: "137605" },
  // { label: "Barangay 75", mun_code: "137605" },
  // { label: "Barangay 76", mun_code: "137605" },
  // { label: "Barangay 77", mun_code: "137605" },
  // { label: "Barangay 78", mun_code: "137605" },
  // { label: "Barangay 79", mun_code: "137605" },
  // { label: "Barangay 80", mun_code: "137605" },
  // { label: "Barangay 81", mun_code: "137605" },
  // { label: "Barangay 82", mun_code: "137605" },
  // { label: "Barangay 83", mun_code: "137605" },
  // { label: "Barangay 84", mun_code: "137605" },
  // { label: "Barangay 85", mun_code: "137605" },
  // { label: "Barangay 86", mun_code: "137605" },
  // { label: "Barangay 87", mun_code: "137605" },
  // { label: "Barangay 88", mun_code: "137605" },
  // { label: "Barangay 89", mun_code: "137605" },
  // { label: "Barangay 90", mun_code: "137605" },
  // { label: "Barangay 91", mun_code: "137605" },
  // { label: "Barangay 92", mun_code: "137605" },
  // { label: "Barangay 93", mun_code: "137605" },
  // { label: "Barangay 94", mun_code: "137605" },
  // { label: "Barangay 95", mun_code: "137605" },
  // { label: "Barangay 96", mun_code: "137605" },
  // { label: "Barangay 97", mun_code: "137605" },
  // { label: "Barangay 98", mun_code: "137605" },
  // { label: "Barangay 99", mun_code: "137605" },
  // { label: "Barangay 100", mun_code: "137605" },
  // { label: "Barangay 101", mun_code: "137605" },
  // { label: "Barangay 102", mun_code: "137605" },
  // { label: "Barangay 103", mun_code: "137605" },
  // { label: "Barangay 104", mun_code: "137605" },
  // { label: "Barangay 105", mun_code: "137605" },
  // { label: "Barangay 106", mun_code: "137605" },
  // { label: "Barangay 107", mun_code: "137605" },
  // { label: "Barangay 108", mun_code: "137605" },
  // { label: "Barangay 109", mun_code: "137605" },
  // { label: "Barangay 110", mun_code: "137605" },
  // { label: "Barangay 111", mun_code: "137605" },
  // { label: "Barangay 112", mun_code: "137605" },
  // { label: "Barangay 113", mun_code: "137605" },
  // { label: "Barangay 114", mun_code: "137605" },
  // { label: "Barangay 115", mun_code: "137605" },
  // { label: "Barangay 116", mun_code: "137605" },
  // { label: "Barangay 117", mun_code: "137605" },
  // { label: "Barangay 118", mun_code: "137605" },
  // { label: "Barangay 119", mun_code: "137605" },
  // { label: "Barangay 120", mun_code: "137605" },
  // { label: "Barangay 121", mun_code: "137605" },
  // { label: "Barangay 122", mun_code: "137605" },
  // { label: "Barangay 123", mun_code: "137605" },
  // { label: "Barangay 124", mun_code: "137605" },
  // { label: "Barangay 125", mun_code: "137605" },
  // { label: "Barangay 126", mun_code: "137605" },
  // { label: "Barangay 127", mun_code: "137605" },
  // { label: "Barangay 128", mun_code: "137605" },
  // { label: "Barangay 129", mun_code: "137605" },
  // { label: "Barangay 130", mun_code: "137605" },
  // { label: "Barangay 131", mun_code: "137605" },
  // { label: "Barangay 132", mun_code: "137605" },
  // { label: "Barangay 133", mun_code: "137605" },
  // { label: "Barangay 134", mun_code: "137605" },
  // { label: "Barangay 135", mun_code: "137605" },
  // { label: "Barangay 136", mun_code: "137605" },
  // { label: "Barangay 137", mun_code: "137605" },
  // { label: "Barangay 138", mun_code: "137605" },
  // { label: "Barangay 139", mun_code: "137605" },
  // { label: "Barangay 140", mun_code: "137605" },
  // { label: "Barangay 141", mun_code: "137605" },
  // { label: "Barangay 142", mun_code: "137605" },
  // { label: "Barangay 143", mun_code: "137605" },
  // { label: "Barangay 144", mun_code: "137605" },
  // { label: "Barangay 145", mun_code: "137605" },
  // { label: "Barangay 146", mun_code: "137605" },
  // { label: "Barangay 147", mun_code: "137605" },
  // { label: "Barangay 148", mun_code: "137605" },
  // { label: "Barangay 149", mun_code: "137605" },
  // { label: "Barangay 150", mun_code: "137605" },
  // { label: "Barangay 151", mun_code: "137605" },
  // { label: "Barangay 152", mun_code: "137605" },
  // { label: "Barangay 153", mun_code: "137605" },
  // { label: "Barangay 154", mun_code: "137605" },
  // { label: "Barangay 155", mun_code: "137605" },
  // { label: "Barangay 156", mun_code: "137605" },
  // { label: "Barangay 157", mun_code: "137605" },
  // { label: "Barangay 158", mun_code: "137605" },
  // { label: "Barangay 159", mun_code: "137605" },
  // { label: "Barangay 160", mun_code: "137605" },
  // { label: "Barangay 161", mun_code: "137605" },
  // { label: "Barangay 162", mun_code: "137605" },
  // { label: "Barangay 163", mun_code: "137605" },
  // { label: "Barangay 164", mun_code: "137605" },
  // { label: "Barangay 165", mun_code: "137605" },
  // { label: "Barangay 166", mun_code: "137605" },
  // { label: "Barangay 167", mun_code: "137605" },
  // { label: "Barangay 168", mun_code: "137605" },
  // { label: "Barangay 169", mun_code: "137605" },
  // { label: "Barangay 170", mun_code: "137605" },
  // { label: "Barangay 171", mun_code: "137605" },
  // { label: "Barangay 172", mun_code: "137605" },
  // { label: "Barangay 173", mun_code: "137605" },
  // { label: "Barangay 174", mun_code: "137605" },
  // { label: "Barangay 175", mun_code: "137605" },
  // { label: "Barangay 176", mun_code: "137605" },
  // { label: "Barangay 177", mun_code: "137605" },
  // { label: "Barangay 178", mun_code: "137605" },
  // { label: "Barangay 179", mun_code: "137605" },
  // { label: "Barangay 180", mun_code: "137605" },
  // { label: "Barangay 181", mun_code: "137605" },
  // { label: "Barangay 182", mun_code: "137605" },
  // { label: "Barangay 183", mun_code: "137605" },
  // { label: "Barangay 184", mun_code: "137605" },
  // { label: "Barangay 185", mun_code: "137605" },
  // { label: "Barangay 186", mun_code: "137605" },
  // { label: "Barangay 187", mun_code: "137605" },
  // { label: "Barangay 188", mun_code: "137605" },
  // { label: "Barangay 189", mun_code: "137605" },
  // { label: "Barangay 190", mun_code: "137605" },
  // { label: "Barangay 191", mun_code: "137605" },
  // { label: "Barangay 192", mun_code: "137605" },
  // { label: "Barangay 193", mun_code: "137605" },
  // { label: "Barangay 194", mun_code: "137605" },
  // { label: "Barangay 195", mun_code: "137605" },
  // { label: "Barangay 196", mun_code: "137605" },
  // { label: "Barangay 197", mun_code: "137605" },
  // { label: "Barangay 198", mun_code: "137605" },
  // { label: "Barangay 199", mun_code: "137605" },
  // { label: "Barangay 200", mun_code: "137605" },
  // { label: "Barangay 201", mun_code: "137605" },
  // { label: "Aguho", mun_code: "137606" },
  // { label: "Magtanggol", mun_code: "137606" },
  // { label: "Martires Del 96", mun_code: "137606" },
  // { label: "Poblacion", mun_code: "137606" },
  // { label: "San Pedro", mun_code: "137606" },
  // { label: "San Roque", mun_code: "137606" },
  // { label: "Santa Ana", mun_code: "137606" },
  // { label: "Santo Rosario-Kanluran", mun_code: "137606" },
  // { label: "Santo Rosario-Silangan", mun_code: "137606" },
  // { label: "Tabacalera", mun_code: "137606" },
  // { label: "Tanyag (Bagong Tanyag)", mun_code: "137607" },
  // { label: "Bagumbayan", mun_code: "137607" },
  // { label: "Bambang", mun_code: "137607" },
  // { label: "Calzada", mun_code: "137607" },
  // { label: "Hagonoy", mun_code: "137607" },
  // { label: "Ibayo-Tipas", mun_code: "137607" },
  // { label: "Ligid-Tipas", mun_code: "137607" },
  // { label: "Lower Bicutan", mun_code: "137607" },
  // { label: "Maharlika Village", mun_code: "137607" },
  // { label: "Napindan", mun_code: "137607" },
  // { label: "Palingon", mun_code: "137607" },
  // { label: "Santa Ana", mun_code: "137607" },
  // { label: "Central Signal Village (Signal Village)", mun_code: "137607" },
  // { label: "Tuktukan", mun_code: "137607" },
  // { label: "Upper Bicutan", mun_code: "137607" },
  // { label: "Ususan", mun_code: "137607" },
  // { label: "Wawa", mun_code: "137607" },
  // { label: "Western Bicutan", mun_code: "137607" },
  // { label: "Central Bicutan", mun_code: "137607" },
  // { label: "Fort Bonifacio", mun_code: "137607" },
  // { label: "Katuparan", mun_code: "137607" },
  // { label: "New Lower Bicutan", mun_code: "137607" },
  // { label: "North Daang Hari", mun_code: "137607" },
  // { label: "North Signal Village", mun_code: "137607" },
  // { label: "Pinagsama", mun_code: "137607" },
  // { label: "San Miguel", mun_code: "137607" },
  // { label: "South Daang Hari", mun_code: "137607" },
  // { label: "South Signal Village", mun_code: "137607" },
  // { label: "Agtangao", mun_code: "140101" },
  // { label: "Angad", mun_code: "140101" },
  // { label: "Bangbangar", mun_code: "140101" },
  // { label: "Cabuloan", mun_code: "140101" },
  // { label: "Calaba", mun_code: "140101" },
  // { label: "Tablac (Calot)", mun_code: "140101" },
  // { label: "Cosili West (Buaya)", mun_code: "140101" },
  // { label: "Cosili East (Proper)", mun_code: "140101" },
  // { label: "Dangdangla", mun_code: "140101" },
  // { label: "Lingtan", mun_code: "140101" },
  // { label: "Lipcan", mun_code: "140101" },
  // { label: "Lubong", mun_code: "140101" },
  // { label: "Macarcarmay", mun_code: "140101" },
  // { label: "Maoay", mun_code: "140101" },
  // { label: "Macray", mun_code: "140101" },
  // { label: "Malita", mun_code: "140101" },
  // { label: "Palao", mun_code: "140101" },
  // { label: "Patucannay", mun_code: "140101" },
  // { label: "Sagap", mun_code: "140101" },
  // { label: "San Antonio", mun_code: "140101" },
  // { label: "Santa Rosa", mun_code: "140101" },
  // { label: "Sao-atan", mun_code: "140101" },
  // { label: "Sappaac", mun_code: "140101" },
  // { label: "Zone 2 Pob. (Consiliman)", mun_code: "140101" },
  // { label: "Zone 3 Pob. (Lalaud)", mun_code: "140101" },
  // { label: "Zone 4 Pob. (Town Proper)", mun_code: "140101" },
  // { label: "Zone 5 Pob. (Bo. Barikir)", mun_code: "140101" },
  // { label: "Zone 6 Pob. (Sinapangan)", mun_code: "140101" },
  // { label: "Zone 7 Pob. (Baliling)", mun_code: "140101" },
  // { label: "Zone 1 Pob. (Nalasin)", mun_code: "140101" },
  // { label: "Amti", mun_code: "140102" },
  // { label: "Bao-yan", mun_code: "140102" },
  // { label: "Danac East", mun_code: "140102" },
  // { label: "Dao-angan", mun_code: "140102" },
  // { label: "Dumagas", mun_code: "140102" },
  // { label: "Kilong-Olao", mun_code: "140102" },
  // { label: "Poblacion (Boliney)", mun_code: "140102" },
  // { label: "Danac West", mun_code: "140102" },
  // { label: "Abang", mun_code: "140103" },
  // { label: "Bangbangcag", mun_code: "140103" },
  // { label: "Bangcagan", mun_code: "140103" },
  // { label: "Banglolao", mun_code: "140103" },
  // { label: "Bugbog", mun_code: "140103" },
  // { label: "Calao", mun_code: "140103" },
  // { label: "Dugong", mun_code: "140103" },
  // { label: "Labon", mun_code: "140103" },
  // { label: "Layugan", mun_code: "140103" },
  // { label: "Madalipay", mun_code: "140103" },
  // { label: "Pagala", mun_code: "140103" },
  // { label: "Palaquio", mun_code: "140103" },
  // { label: "Pakiling", mun_code: "140103" },
  // { label: "Patoc", mun_code: "140103" },
  // { label: "North Poblacion", mun_code: "140103" },
  // { label: "South Poblacion", mun_code: "140103" },
  // { label: "Quimloong", mun_code: "140103" },
  // { label: "Salnec", mun_code: "140103" },
  // { label: "San Miguel", mun_code: "140103" },
  // { label: "Siblong", mun_code: "140103" },
  // { label: "Tabiog", mun_code: "140103" },
  // { label: "Ducligan", mun_code: "140104" },
  // { label: "Labaan", mun_code: "140104" },
  // { label: "Lingay", mun_code: "140104" },
  // { label: "Lamao (Pob.)", mun_code: "140104" },
  // { label: "Ableg", mun_code: "140105" },
  // { label: "Cabaruyan", mun_code: "140105" },
  // { label: "Pikek", mun_code: "140105" },
  // { label: "Tui (Pob.)", mun_code: "140105" },
  // { label: "Abaquid", mun_code: "140106" },
  // { label: "Cabaruan", mun_code: "140106" },
  // { label: "Caupasan (Pob.)", mun_code: "140106" },
  // { label: "Danglas", mun_code: "140106" },
  // { label: "Nagaparan", mun_code: "140106" },
  // { label: "Padangitan", mun_code: "140106" },
  // { label: "Pangal", mun_code: "140106" },
  // { label: "Bayaan", mun_code: "140107" },
  // { label: "Cabaroan", mun_code: "140107" },
  // { label: "Calumbaya", mun_code: "140107" },
  // { label: "Cardona", mun_code: "140107" },
  // { label: "Isit", mun_code: "140107" },
  // { label: "Kimmalaba", mun_code: "140107" },
  // { label: "Libtec", mun_code: "140107" },
  // { label: "Lub-lubba", mun_code: "140107" },
  // { label: "Mudiit", mun_code: "140107" },
  // { label: "Namit-ingan", mun_code: "140107" },
  // { label: "Pacac", mun_code: "140107" },
  // { label: "Poblacion", mun_code: "140107" },
  // { label: "Salucag", mun_code: "140107" },
  // { label: "Talogtog", mun_code: "140107" },
  // { label: "Taping", mun_code: "140107" },
  // { label: "Benben (Bonbon)", mun_code: "140108" },
  // { label: "Bulbulala", mun_code: "140108" },
  // { label: "Buli", mun_code: "140108" },
  // { label: "Canan (Gapan)", mun_code: "140108" },
  // { label: "Liguis", mun_code: "140108" },
  // { label: "Malabbaga", mun_code: "140108" },
  // { label: "Mudeng", mun_code: "140108" },
  // { label: "Pidipid", mun_code: "140108" },
  // { label: "Poblacion", mun_code: "140108" },
  // { label: "San Gregorio", mun_code: "140108" },
  // { label: "Toon", mun_code: "140108" },
  // { label: "Udangan", mun_code: "140108" },
  // { label: "Bacag", mun_code: "140109" },
  // { label: "Buneg", mun_code: "140109" },
  // { label: "Guinguinabang", mun_code: "140109" },
  // { label: "Lan-ag", mun_code: "140109" },
  // { label: "Pacoc", mun_code: "140109" },
  // { label: "Poblacion (Talampac)", mun_code: "140109" },
  // { label: "Aguet", mun_code: "140110" },
  // { label: "Bacooc", mun_code: "140110" },
  // { label: "Balais", mun_code: "140110" },
  // { label: "Cayapa", mun_code: "140110" },
  // { label: "Dalaguisen", mun_code: "140110" },
  // { label: "Laang", mun_code: "140110" },
  // { label: "Lagben", mun_code: "140110" },
  // { label: "Laguiben", mun_code: "140110" },
  // { label: "Nagtipulan", mun_code: "140110" },
  // { label: "Nagtupacan", mun_code: "140110" },
  // { label: "Paganao", mun_code: "140110" },
  // { label: "Pawa", mun_code: "140110" },
  // { label: "Poblacion", mun_code: "140110" },
  // { label: "Presentar", mun_code: "140110" },
  // { label: "San Isidro", mun_code: "140110" },
  // { label: "Tagodtod", mun_code: "140110" },
  // { label: "Taping", mun_code: "140110" },
  // { label: "Ba-i", mun_code: "140111" },
  // { label: "Collago", mun_code: "140111" },
  // { label: "Pang-ot", mun_code: "140111" },
  // { label: "Poblacion", mun_code: "140111" },
  // { label: "Pulot", mun_code: "140111" },
  // { label: "Baac", mun_code: "140112" },
  // { label: "Dalayap (Nalaas)", mun_code: "140112" },
  // { label: "Mabungtot", mun_code: "140112" },
  // { label: "Malapaao", mun_code: "140112" },
  // { label: "Poblacion", mun_code: "140112" },
  // { label: "Quillat", mun_code: "140112" },
  // { label: "Bonglo (Patagui)", mun_code: "140113" },
  // { label: "Bulbulala", mun_code: "140113" },
  // { label: "Cawayan", mun_code: "140113" },
  // { label: "Domenglay", mun_code: "140113" },
  // { label: "Lenneng", mun_code: "140113" },
  // { label: "Mapisla", mun_code: "140113" },
  // { label: "Mogao", mun_code: "140113" },
  // { label: "Nalbuan", mun_code: "140113" },
  // { label: "Poblacion", mun_code: "140113" },
  // { label: "Subagan", mun_code: "140113" },
  // { label: "Tumalip", mun_code: "140113" },
  // { label: "Ampalioc", mun_code: "140114" },
  // { label: "Barit", mun_code: "140114" },
  // { label: "Gayaman", mun_code: "140114" },
  // { label: "Lul-luno", mun_code: "140114" },
  // { label: "Luzong", mun_code: "140114" },
  // { label: "Nagbukel-Tuquipa", mun_code: "140114" },
  // { label: "Poblacion", mun_code: "140114" },
  // { label: "Sabnangan", mun_code: "140114" },
  // { label: "Bayabas", mun_code: "140115" },
  // { label: "Binasaran", mun_code: "140115" },
  // { label: "Buanao", mun_code: "140115" },
  // { label: "Dulao", mun_code: "140115" },
  // { label: "Duldulao", mun_code: "140115" },
  // { label: "Gacab", mun_code: "140115" },
  // { label: "Lat-ey", mun_code: "140115" },
  // { label: "Malibcong (Pob.)", mun_code: "140115" },
  // { label: "Mataragan", mun_code: "140115" },
  // { label: "Pacgued", mun_code: "140115" },
  // { label: "Taripan", mun_code: "140115" },
  // { label: "Umnap", mun_code: "140115" },
  // { label: "Catacdegan Viejo", mun_code: "140116" },
  // { label: "Luzong", mun_code: "140116" },
  // { label: "Ayyeng (Pob.)", mun_code: "140116" },
  // { label: "San Jose Norte", mun_code: "140116" },
  // { label: "San Jose Sur", mun_code: "140116" },
  // { label: "San Juan Norte", mun_code: "140116" },
  // { label: "San Juan Sur", mun_code: "140116" },
  // { label: "San Ramon East", mun_code: "140116" },
  // { label: "San Ramon West", mun_code: "140116" },
  // { label: "Santo Tomas", mun_code: "140116" },
  // { label: "Catacdegan Nuevo", mun_code: "140116" },
  // { label: "Dumayco", mun_code: "140117" },
  // { label: "Lusuac", mun_code: "140117" },
  // { label: "Namarabar", mun_code: "140117" },
  // { label: "Patiao", mun_code: "140117" },
  // { label: "Malamsit (Pau-Malamsit)", mun_code: "140117" },
  // { label: "Poblacion", mun_code: "140117" },
  // { label: "Riang (Tiang)", mun_code: "140117" },
  // { label: "Santa Rosa", mun_code: "140117" },
  // { label: "Tattawa", mun_code: "140117" },
  // { label: "Alinaya", mun_code: "140118" },
  // { label: "Arab", mun_code: "140118" },
  // { label: "Garreta", mun_code: "140118" },
  // { label: "Immuli", mun_code: "140118" },
  // { label: "Laskig", mun_code: "140118" },
  // { label: "Naguirayan", mun_code: "140118" },
  // { label: "Monggoc", mun_code: "140118" },
  // { label: "Pamutic", mun_code: "140118" },
  // { label: "Pangtud", mun_code: "140118" },
  // { label: "Poblacion East", mun_code: "140118" },
  // { label: "Poblacion West", mun_code: "140118" },
  // { label: "San Diego", mun_code: "140118" },
  // { label: "Sulbec", mun_code: "140118" },
  // { label: "Suyo (Malidong)", mun_code: "140118" },
  // { label: "Yuyeng", mun_code: "140118" },
  // { label: "Bolbolo", mun_code: "140119" },
  // { label: "Brookside", mun_code: "140119" },
  // { label: "Ocup", mun_code: "140119" },
  // { label: "Dalit", mun_code: "140119" },
  // { label: "Dintan", mun_code: "140119" },
  // { label: "Gapang", mun_code: "140119" },
  // { label: "Kinabiti", mun_code: "140119" },
  // { label: "Maliplipit", mun_code: "140119" },
  // { label: "Nagcanasan", mun_code: "140119" },
  // { label: "Nanangduan", mun_code: "140119" },
  // { label: "Narnara", mun_code: "140119" },
  // { label: "Pang-ot", mun_code: "140119" },
  // { label: "Patad", mun_code: "140119" },
  // { label: "Poblacion", mun_code: "140119" },
  // { label: "San Juan East", mun_code: "140119" },
  // { label: "San Juan West", mun_code: "140119" },
  // { label: "South Balioag", mun_code: "140119" },
  // { label: "Tikitik", mun_code: "140119" },
  // { label: "Villavieja", mun_code: "140119" },
  // { label: "Bazar", mun_code: "140120" },
  // { label: "Bilabila", mun_code: "140120" },
  // { label: "Gangal (Pob.)", mun_code: "140120" },
  // { label: "Maguyepyep", mun_code: "140120" },
  // { label: "Naguilian", mun_code: "140120" },
  // { label: "Saccaang", mun_code: "140120" },
  // { label: "Sallapadan", mun_code: "140120" },
  // { label: "Subusob", mun_code: "140120" },
  // { label: "Ud-udiao", mun_code: "140120" },
  // { label: "Cabayogan", mun_code: "140121" },
  // { label: "Dalimag", mun_code: "140121" },
  // { label: "Langbaban", mun_code: "140121" },
  // { label: "Manayday", mun_code: "140121" },
  // { label: "Pantoc", mun_code: "140121" },
  // { label: "Poblacion", mun_code: "140121" },
  // { label: "Sabtan-olo", mun_code: "140121" },
  // { label: "San Marcial", mun_code: "140121" },
  // { label: "Tangbao", mun_code: "140121" },
  // { label: "Abualan", mun_code: "140122" },
  // { label: "Ba-ug", mun_code: "140122" },
  // { label: "Badas", mun_code: "140122" },
  // { label: "Cabcaborao", mun_code: "140122" },
  // { label: "Colabaoan", mun_code: "140122" },
  // { label: "Culiong", mun_code: "140122" },
  // { label: "Daoidao", mun_code: "140122" },
  // { label: "Guimba", mun_code: "140122" },
  // { label: "Lam-ag", mun_code: "140122" },
  // { label: "Lumobang", mun_code: "140122" },
  // { label: "Nangobongan", mun_code: "140122" },
  // { label: "Pattaoig", mun_code: "140122" },
  // { label: "Poblacion North", mun_code: "140122" },
  // { label: "Poblacion South", mun_code: "140122" },
  // { label: "Quidaoen", mun_code: "140122" },
  // { label: "Sabangan", mun_code: "140122" },
  // { label: "Silet", mun_code: "140122" },
  // { label: "Supi-il", mun_code: "140122" },
  // { label: "Tagaytay", mun_code: "140122" },
  // { label: "Labaan", mun_code: "140123" },
  // { label: "Palang", mun_code: "140123" },
  // { label: "Pantoc", mun_code: "140123" },
  // { label: "Poblacion", mun_code: "140123" },
  // { label: "Tangadan", mun_code: "140123" },
  // { label: "Villa Mercedes", mun_code: "140123" },
  // { label: "Bagalay", mun_code: "140124" },
  // { label: "Basbasa", mun_code: "140124" },
  // { label: "Budac", mun_code: "140124" },
  // { label: "Bumagcat", mun_code: "140124" },
  // { label: "Cabaroan", mun_code: "140124" },
  // { label: "Deet", mun_code: "140124" },
  // { label: "Gaddani", mun_code: "140124" },
  // { label: "Patucannay", mun_code: "140124" },
  // { label: "Pias", mun_code: "140124" },
  // { label: "Poblacion", mun_code: "140124" },
  // { label: "Velasco", mun_code: "140124" },
  // { label: "Poblacion (Agsimao)", mun_code: "140125" },
  // { label: "Alaoa", mun_code: "140125" },
  // { label: "Anayan", mun_code: "140125" },
  // { label: "Apao", mun_code: "140125" },
  // { label: "Belaat", mun_code: "140125" },
  // { label: "Caganayan", mun_code: "140125" },
  // { label: "Cogon", mun_code: "140125" },
  // { label: "Lanec", mun_code: "140125" },
  // { label: "Lapat-Balantay", mun_code: "140125" },
  // { label: "Naglibacan", mun_code: "140125" },
  // { label: "Alangtin", mun_code: "140126" },
  // { label: "Amtuagan", mun_code: "140126" },
  // { label: "Dilong", mun_code: "140126" },
  // { label: "Kili", mun_code: "140126" },
  // { label: "Poblacion (Mayabo)", mun_code: "140126" },
  // { label: "Supo", mun_code: "140126" },
  // { label: "Tiempo", mun_code: "140126" },
  // { label: "Tubtuba", mun_code: "140126" },
  // { label: "Wayangan", mun_code: "140126" },
  // { label: "Tabacda", mun_code: "140126" },
  // { label: "Ap-apaya", mun_code: "140127" },
  // { label: "Bol-lilising", mun_code: "140127" },
  // { label: "Cal-lao", mun_code: "140127" },
  // { label: "Lap-lapog", mun_code: "140127" },
  // { label: "Lumaba", mun_code: "140127" },
  // { label: "Poblacion", mun_code: "140127" },
  // { label: "Tamac", mun_code: "140127" },
  // { label: "Tuquib", mun_code: "140127" },
  // { label: "Abiang", mun_code: "141101" },
  // { label: "Caliking", mun_code: "141101" },
  // { label: "Cattubo", mun_code: "141101" },
  // { label: "Naguey", mun_code: "141101" },
  // { label: "Paoay", mun_code: "141101" },
  // { label: "Pasdong", mun_code: "141101" },
  // { label: "Poblacion", mun_code: "141101" },
  // { label: "Topdac", mun_code: "141101" },
  // { label: "Apugan-Loakan", mun_code: "141102" },
  // { label: "Asin Road", mun_code: "141102" },
  // { label: "Atok Trail", mun_code: "141102" },
  // { label: "Bakakeng Central", mun_code: "141102" },
  // { label: "Bakakeng North", mun_code: "141102" },
  // { label: "Happy Hollow", mun_code: "141102" },
  // { label: "Balsigan", mun_code: "141102" },
  // { label: "Bayan Park West (Bayan Park)", mun_code: "141102" },
  // { label: "Bayan Park East", mun_code: "141102" },
  // { label: "Brookspoint", mun_code: "141102" },
  // { label: "Brookside", mun_code: "141102" },
  // { label: "Cabinet Hill-Teacher's Camp", mun_code: "141102" },
  // { label: "Camp Allen", mun_code: "141102" },
  // { label: "Camp 7", mun_code: "141102" },
  // { label: "Camp 8", mun_code: "141102" },
  // { label: "Campo Filipino", mun_code: "141102" },
  // { label: "City Camp Central", mun_code: "141102" },
  // { label: "City Camp Proper", mun_code: "141102" },
  // { label: "Country Club Village", mun_code: "141102" },
  // { label: "Cresencia Village", mun_code: "141102" },
  // { label: "Dagsian, Upper", mun_code: "141102" },
  // { label: "DPS Area", mun_code: "141102" },
  // { label: "Dizon Subdivision", mun_code: "141102" },
  // { label: "Quirino Hill, East", mun_code: "141102" },
  // { label: "Engineers' Hill", mun_code: "141102" },
  // { label: "Fairview Village", mun_code: "141102" },
  // { label: "Fort del Pilar", mun_code: "141102" },
  // { label: "General Luna, Upper", mun_code: "141102" },
  // { label: "General Luna, Lower", mun_code: "141102" },
  // { label: "Gibraltar", mun_code: "141102" },
  // { label: "Greenwater Village", mun_code: "141102" },
  // { label: "Guisad Central", mun_code: "141102" },
  // { label: "Guisad Sorong", mun_code: "141102" },
  // { label: "Hillside", mun_code: "141102" },
  // { label: "Holy Ghost Extension", mun_code: "141102" },
  // { label: "Holy Ghost Proper", mun_code: "141102" },
  // { label: "Imelda Village", mun_code: "141102" },
  // { label: "Irisan", mun_code: "141102" },
  // { label: "Kayang Extension", mun_code: "141102" },
  // { label: "Kias", mun_code: "141102" },
  // { label: "Kagitingan", mun_code: "141102" },
  // { label: "Loakan Proper", mun_code: "141102" },
  // { label: "Lopez Jaena", mun_code: "141102" },
  // { label: "Lourdes Subdivision Extension", mun_code: "141102" },
  // { label: "Dagsian, Lower", mun_code: "141102" },
  // { label: "Lourdes Subdivision, Lower", mun_code: "141102" },
  // { label: "Quirino Hill, Lower", mun_code: "141102" },
  // {
  //   label: "General Emilio F. Aguinaldo (Quirino-Magsaysay, Lower)",
  //   mun_code: "141102",
  // },
  // { label: "Lualhati", mun_code: "141102" },
  // { label: "Lucnab", mun_code: "141102" },
  // { label: "Magsaysay, Lower", mun_code: "141102" },
  // { label: "Magsaysay Private Road", mun_code: "141102" },
  // { label: "Aurora Hill Proper (Malvar-Sgt. Floresca)", mun_code: "141102" },
  // { label: "Bal-Marcoville (Marcoville)", mun_code: "141102" },
  // { label: "Quirino Hill, Middle", mun_code: "141102" },
  // { label: "Military Cut-off", mun_code: "141102" },
  // { label: "Mines View Park", mun_code: "141102" },
  // { label: "Modern Site, East", mun_code: "141102" },
  // { label: "Modern Site, West", mun_code: "141102" },
  // { label: "New Lucban", mun_code: "141102" },
  // { label: "Aurora Hill, North Central", mun_code: "141102" },
  // { label: "Sanitary Camp, North", mun_code: "141102" },
  // { label: "Outlook Drive", mun_code: "141102" },
  // { label: "Pacdal", mun_code: "141102" },
  // { label: "Pinget", mun_code: "141102" },
  // { label: "Pinsao Pilot Project", mun_code: "141102" },
  // { label: "Pinsao Proper", mun_code: "141102" },
  // { label: "Poliwes", mun_code: "141102" },
  // { label: "Pucsusan", mun_code: "141102" },
  // { label: "MRR-Queen Of Peace", mun_code: "141102" },
  // { label: "Rock Quarry, Lower", mun_code: "141102" },
  // { label: "Salud Mitra", mun_code: "141102" },
  // { label: "San Antonio Village", mun_code: "141102" },
  // { label: "San Luis Village", mun_code: "141102" },
  // { label: "San Roque Village", mun_code: "141102" },
  // { label: "San Vicente", mun_code: "141102" },
  // { label: "Santa Escolastica", mun_code: "141102" },
  // { label: "Santo Rosario", mun_code: "141102" },
  // { label: "Santo Tomas School Area", mun_code: "141102" },
  // { label: "Santo Tomas Proper", mun_code: "141102" },
  // { label: "Scout Barrio", mun_code: "141102" },
  // { label: "Session Road Area", mun_code: "141102" },
  // { label: "Sanitary Camp, South", mun_code: "141102" },
  // { label: "Saint Joseph Village", mun_code: "141102" },
  // { label: "Teodora Alonzo", mun_code: "141102" },
  // { label: "Trancoville", mun_code: "141102" },
  // { label: "Rock Quarry, Upper", mun_code: "141102" },
  // { label: "Victoria Village", mun_code: "141102" },
  // { label: "Quirino Hill, West", mun_code: "141102" },
  // { label: "Andres Bonifacio (Lower Bokawkan)", mun_code: "141102" },
  // { label: "Legarda-Burnham-Kisad", mun_code: "141102" },
  // { label: "Imelda R. Marcos (La Salle)", mun_code: "141102" },
  // { label: "Lourdes Subdivision, Proper", mun_code: "141102" },
  // { label: "Quirino-Magsaysay, Upper (Upper QM)", mun_code: "141102" },
  // { label: "A. Bonifacio-Caguioa-Rimando (ABCR)", mun_code: "141102" },
  // { label: "Ambiong", mun_code: "141102" },
  // { label: "Aurora Hill, South Central", mun_code: "141102" },
  // {
  //   label: "Abanao-Zandueta-Kayong-Chugum-Otek (AZKCO)",
  //   mun_code: "141102",
  // },
  // { label: "Bagong Lipunan (Market Area)", mun_code: "141102" },
  // { label: "BGH Compound", mun_code: "141102" },
  // { label: "Bayan Park Village", mun_code: "141102" },
  // { label: "Camdas Subdivision", mun_code: "141102" },
  // { label: "Dominican Hill-Mirador", mun_code: "141102" },
  // { label: "Alfonso Tabora", mun_code: "141102" },
  // { label: "Dontogan", mun_code: "141102" },
  // { label: "Ferdinand (Happy Homes-Campo Sioco)", mun_code: "141102" },
  // { label: "Happy Homes (Happy Homes-Lucban)", mun_code: "141102" },
  // { label: "Harrison-Claudio Carantes", mun_code: "141102" },
  // { label: "Honeymoon (Honeymoon-Holy Ghost)", mun_code: "141102" },
  // { label: "Kabayanihan", mun_code: "141102" },
  // { label: "Kayang-Hilltop", mun_code: "141102" },
  // { label: "Gabriela Silang", mun_code: "141102" },
  // { label: "Liwanag-Loakan", mun_code: "141102" },
  // {
  //   label: "Malcolm Square-Perfecto (Jose Abad Santos)",
  //   mun_code: "141102",
  // },
  // { label: "Manuel A. Roxas", mun_code: "141102" },
  // { label: "Padre Burgos", mun_code: "141102" },
  // { label: "Quezon Hill, Upper", mun_code: "141102" },
  // { label: "Rock Quarry, Middle", mun_code: "141102" },
  // { label: "Phil-Am", mun_code: "141102" },
  // { label: "Quezon Hill Proper", mun_code: "141102" },
  // {
  //   label: "Middle Quezon Hill Subdivision(Quezon Hill Middle)",
  //   mun_code: "141102",
  // },
  // { label: "Rizal Monument Area", mun_code: "141102" },
  // { label: "SLU-SVP Housing Village", mun_code: "141102" },
  // { label: "South Drive", mun_code: "141102" },
  // { label: "Magsaysay, Upper", mun_code: "141102" },
  // { label: "Market Subdivision, Upper", mun_code: "141102" },
  // { label: "Padre Zamora", mun_code: "141102" },
  // { label: "Ampusongan", mun_code: "141103" },
  // { label: "Bagu", mun_code: "141103" },
  // { label: "Dalipey", mun_code: "141103" },
  // { label: "Gambang", mun_code: "141103" },
  // { label: "Kayapa", mun_code: "141103" },
  // { label: "Poblacion (Central)", mun_code: "141103" },
  // { label: "Sinacbat", mun_code: "141103" },
  // { label: "Ambuclao", mun_code: "141104" },
  // { label: "Bila", mun_code: "141104" },
  // { label: "Bobok-Bisal", mun_code: "141104" },
  // { label: "Daclan", mun_code: "141104" },
  // { label: "Ekip", mun_code: "141104" },
  // { label: "Karao", mun_code: "141104" },
  // { label: "Nawal", mun_code: "141104" },
  // { label: "Pito", mun_code: "141104" },
  // { label: "Poblacion", mun_code: "141104" },
  // { label: "Tikey", mun_code: "141104" },
  // { label: "Abatan", mun_code: "141105" },
  // { label: "Amgaleyguey", mun_code: "141105" },
  // { label: "Amlimay", mun_code: "141105" },
  // { label: "Baculongan Norte", mun_code: "141105" },
  // { label: "Bangao", mun_code: "141105" },
  // { label: "Buyacaoan", mun_code: "141105" },
  // { label: "Calamagan", mun_code: "141105" },
  // { label: "Catlubong", mun_code: "141105" },
  // { label: "Loo", mun_code: "141105" },
  // { label: "Natubleng", mun_code: "141105" },
  // { label: "Poblacion (Central)", mun_code: "141105" },
  // { label: "Baculongan Sur", mun_code: "141105" },
  // { label: "Lengaoan", mun_code: "141105" },
  // { label: "Sebang", mun_code: "141105" },
  // { label: "Ampucao", mun_code: "141106" },
  // { label: "Dalupirip", mun_code: "141106" },
  // { label: "Gumatdang", mun_code: "141106" },
  // { label: "Loacan", mun_code: "141106" },
  // { label: "Poblacion (Central)", mun_code: "141106" },
  // { label: "Tinongdan", mun_code: "141106" },
  // { label: "Tuding", mun_code: "141106" },
  // { label: "Ucab", mun_code: "141106" },
  // { label: "Virac", mun_code: "141106" },
  // { label: "Adaoay", mun_code: "141107" },
  // { label: "Anchukey", mun_code: "141107" },
  // { label: "Ballay", mun_code: "141107" },
  // { label: "Bashoy", mun_code: "141107" },
  // { label: "Batan", mun_code: "141107" },
  // { label: "Duacan", mun_code: "141107" },
  // { label: "Eddet", mun_code: "141107" },
  // { label: "Gusaran", mun_code: "141107" },
  // { label: "Kabayan Barrio", mun_code: "141107" },
  // { label: "Lusod", mun_code: "141107" },
  // { label: "Pacso", mun_code: "141107" },
  // { label: "Poblacion (Central)", mun_code: "141107" },
  // { label: "Tawangan", mun_code: "141107" },
  // { label: "Balakbak", mun_code: "141108" },
  // { label: "Beleng-Belis", mun_code: "141108" },
  // { label: "Boklaoan", mun_code: "141108" },
  // { label: "Cayapes", mun_code: "141108" },
  // { label: "Cuba", mun_code: "141108" },
  // { label: "Datakan", mun_code: "141108" },
  // { label: "Gadang", mun_code: "141108" },
  // { label: "Gasweling", mun_code: "141108" },
  // { label: "Labueg", mun_code: "141108" },
  // { label: "Paykek", mun_code: "141108" },
  // { label: "Poblacion Central", mun_code: "141108" },
  // { label: "Pudong", mun_code: "141108" },
  // { label: "Pongayan", mun_code: "141108" },
  // { label: "Sagubo", mun_code: "141108" },
  // { label: "Taba-ao", mun_code: "141108" },
  // { label: "Badeo", mun_code: "141109" },
  // { label: "Lubo", mun_code: "141109" },
  // { label: "Madaymen", mun_code: "141109" },
  // { label: "Palina", mun_code: "141109" },
  // { label: "Poblacion", mun_code: "141109" },
  // { label: "Sagpat", mun_code: "141109" },
  // { label: "Tacadang", mun_code: "141109" },
  // { label: "Alapang", mun_code: "141110" },
  // { label: "Alno", mun_code: "141110" },
  // { label: "Ambiong", mun_code: "141110" },
  // { label: "Bahong", mun_code: "141110" },
  // { label: "Balili", mun_code: "141110" },
  // { label: "Beckel", mun_code: "141110" },
  // { label: "Bineng", mun_code: "141110" },
  // { label: "Betag", mun_code: "141110" },
  // { label: "Cruz", mun_code: "141110" },
  // { label: "Lubas", mun_code: "141110" },
  // { label: "Pico", mun_code: "141110" },
  // { label: "Poblacion", mun_code: "141110" },
  // { label: "Puguis", mun_code: "141110" },
  // { label: "Shilan", mun_code: "141110" },
  // { label: "Tawang", mun_code: "141110" },
  // { label: "Wangal", mun_code: "141110" },
  // { label: "Balili", mun_code: "141111" },
  // { label: "Bedbed", mun_code: "141111" },
  // { label: "Bulalacao", mun_code: "141111" },
  // { label: "Cabiten", mun_code: "141111" },
  // { label: "Colalo", mun_code: "141111" },
  // { label: "Guinaoang", mun_code: "141111" },
  // { label: "Paco", mun_code: "141111" },
  // { label: "Palasaan", mun_code: "141111" },
  // { label: "Poblacion", mun_code: "141111" },
  // { label: "Sapid", mun_code: "141111" },
  // { label: "Tabio", mun_code: "141111" },
  // { label: "Taneg", mun_code: "141111" },
  // { label: "Bagong", mun_code: "141112" },
  // { label: "Balluay", mun_code: "141112" },
  // { label: "Banangan", mun_code: "141112" },
  // { label: "Banengbeng", mun_code: "141112" },
  // { label: "Bayabas", mun_code: "141112" },
  // { label: "Kamog", mun_code: "141112" },
  // { label: "Pappa", mun_code: "141112" },
  // { label: "Poblacion", mun_code: "141112" },
  // { label: "Ansagan", mun_code: "141113" },
  // { label: "Camp One", mun_code: "141113" },
  // { label: "Camp 3", mun_code: "141113" },
  // { label: "Camp 4", mun_code: "141113" },
  // { label: "Nangalisan", mun_code: "141113" },
  // { label: "Poblacion", mun_code: "141113" },
  // { label: "San Pascual", mun_code: "141113" },
  // { label: "Tabaan Norte", mun_code: "141113" },
  // { label: "Tabaan Sur", mun_code: "141113" },
  // { label: "Tadiangan", mun_code: "141113" },
  // { label: "Taloy Norte", mun_code: "141113" },
  // { label: "Taloy Sur", mun_code: "141113" },
  // { label: "Twin Peaks", mun_code: "141113" },
  // { label: "Ambassador", mun_code: "141114" },
  // { label: "Ambongdolan", mun_code: "141114" },
  // { label: "Ba-ayan", mun_code: "141114" },
  // { label: "Basil", mun_code: "141114" },
  // { label: "Daclan", mun_code: "141114" },
  // { label: "Caponga (Pob.)", mun_code: "141114" },
  // { label: "Tublay Central", mun_code: "141114" },
  // { label: "Tuel", mun_code: "141114" },
  // { label: "Amganad", mun_code: "142701" },
  // { label: "Anaba", mun_code: "142701" },
  // { label: "Bangaan", mun_code: "142701" },
  // { label: "Batad", mun_code: "142701" },
  // { label: "Bocos", mun_code: "142701" },
  // { label: "Banao", mun_code: "142701" },
  // { label: "Cambulo", mun_code: "142701" },
  // { label: "Ducligan", mun_code: "142701" },
  // { label: "Gohang", mun_code: "142701" },
  // { label: "Kinakin", mun_code: "142701" },
  // { label: "Poblacion", mun_code: "142701" },
  // { label: "Poitan", mun_code: "142701" },
  // { label: "San Fernando", mun_code: "142701" },
  // { label: "Balawis", mun_code: "142701" },
  // { label: "Ohaj", mun_code: "142701" },
  // { label: "Tam-an", mun_code: "142701" },
  // { label: "View Point", mun_code: "142701" },
  // { label: "Pula", mun_code: "142701" },
  // { label: "Abatan", mun_code: "142702" },
  // { label: "Bangbang", mun_code: "142702" },
  // { label: "Maggok", mun_code: "142702" },
  // { label: "Poblacion", mun_code: "142702" },
  // { label: "Bokiawan", mun_code: "142702" },
  // { label: "Hapao", mun_code: "142702" },
  // { label: "Lubo-ong", mun_code: "142702" },
  // { label: "Nungulunan", mun_code: "142702" },
  // { label: "Ba-ang", mun_code: "142702" },
  // { label: "Ambabag", mun_code: "142703" },
  // { label: "Baguinge", mun_code: "142703" },
  // { label: "Bokiawan", mun_code: "142703" },
  // { label: "Dalligan", mun_code: "142703" },
  // { label: "Duit", mun_code: "142703" },
  // { label: "Hucab", mun_code: "142703" },
  // { label: "Julongan", mun_code: "142703" },
  // { label: "Lingay", mun_code: "142703" },
  // { label: "Mungayang", mun_code: "142703" },
  // { label: "Nagacadan", mun_code: "142703" },
  // { label: "Pindongan", mun_code: "142703" },
  // { label: "Poblacion", mun_code: "142703" },
  // { label: "Tuplac", mun_code: "142703" },
  // { label: "Bolog", mun_code: "142703" },
  // { label: "Abinuan", mun_code: "142704" },
  // { label: "Banga", mun_code: "142704" },
  // { label: "Boliwong", mun_code: "142704" },
  // { label: "Burnay", mun_code: "142704" },
  // { label: "Buyabuyan", mun_code: "142704" },
  // { label: "Caba", mun_code: "142704" },
  // { label: "Cudog", mun_code: "142704" },
  // { label: "Dulao", mun_code: "142704" },
  // { label: "Jucbong", mun_code: "142704" },
  // { label: "Luta", mun_code: "142704" },
  // { label: "Montabiong", mun_code: "142704" },
  // { label: "Olilicon", mun_code: "142704" },
  // { label: "Poblacion South", mun_code: "142704" },
  // { label: "Ponghal", mun_code: "142704" },
  // { label: "Pullaan", mun_code: "142704" },
  // { label: "Tungngod", mun_code: "142704" },
  // { label: "Tupaya", mun_code: "142704" },
  // { label: "Poblacion East", mun_code: "142704" },
  // { label: "Poblacion North", mun_code: "142704" },
  // { label: "Poblacion West", mun_code: "142704" },
  // { label: "Ambasa", mun_code: "142705" },
  // { label: "Hapid", mun_code: "142705" },
  // { label: "Lawig", mun_code: "142705" },
  // { label: "Lucban", mun_code: "142705" },
  // { label: "Mabatobato (Lamut)", mun_code: "142705" },
  // { label: "Magulon", mun_code: "142705" },
  // { label: "Nayon", mun_code: "142705" },
  // { label: "Panopdopan", mun_code: "142705" },
  // { label: "Payawan", mun_code: "142705" },
  // { label: "Pieza", mun_code: "142705" },
  // { label: "Poblacion East", mun_code: "142705" },
  // { label: "Pugol (Ifugao Reservation)", mun_code: "142705" },
  // { label: "Salamague", mun_code: "142705" },
  // { label: "Bimpal", mun_code: "142705" },
  // { label: "Holowon", mun_code: "142705" },
  // { label: "Poblacion West", mun_code: "142705" },
  // { label: "Sanafe", mun_code: "142705" },
  // { label: "Umilag", mun_code: "142705" },
  // { label: "Aduyongan", mun_code: "142706" },
  // { label: "Alimit", mun_code: "142706" },
  // { label: "Ayangan", mun_code: "142706" },
  // { label: "Balangbang", mun_code: "142706" },
  // { label: "Banao", mun_code: "142706" },
  // { label: "Banhal", mun_code: "142706" },
  // { label: "Bongan", mun_code: "142706" },
  // { label: "Buninan", mun_code: "142706" },
  // { label: "Chaya", mun_code: "142706" },
  // { label: "Chumang", mun_code: "142706" },
  // { label: "Guinihon", mun_code: "142706" },
  // { label: "Inwaloy", mun_code: "142706" },
  // { label: "Langayan", mun_code: "142706" },
  // { label: "Liwo", mun_code: "142706" },
  // { label: "Maga", mun_code: "142706" },
  // { label: "Magulon", mun_code: "142706" },
  // { label: "Mapawoy", mun_code: "142706" },
  // { label: "Mayoyao Proper", mun_code: "142706" },
  // { label: "Mongol", mun_code: "142706" },
  // { label: "Nalbu", mun_code: "142706" },
  // { label: "Nattum", mun_code: "142706" },
  // { label: "Palaad", mun_code: "142706" },
  // { label: "Poblacion", mun_code: "142706" },
  // { label: "Talboc", mun_code: "142706" },
  // { label: "Tulaed", mun_code: "142706" },
  // { label: "Bato-Alatbang", mun_code: "142706" },
  // { label: "Epeng", mun_code: "142706" },
  // { label: "Bangar", mun_code: "142707" },
  // { label: "Busilac", mun_code: "142707" },
  // { label: "Calimag", mun_code: "142707" },
  // { label: "Calupaan", mun_code: "142707" },
  // { label: "Caragasan", mun_code: "142707" },
  // { label: "Dolowog", mun_code: "142707" },
  // { label: "Kiling", mun_code: "142707" },
  // { label: "Namnama", mun_code: "142707" },
  // { label: "Namillangan", mun_code: "142707" },
  // { label: "Pinto", mun_code: "142707" },
  // { label: "Poblacion", mun_code: "142707" },
  // { label: "San Jose", mun_code: "142707" },
  // { label: "San Juan", mun_code: "142707" },
  // { label: "San Marcos", mun_code: "142707" },
  // { label: "San Quintin", mun_code: "142707" },
  // { label: "Santa Maria", mun_code: "142707" },
  // { label: "Santo Domingo (Cabicalan)", mun_code: "142707" },
  // { label: "Little Tadian", mun_code: "142707" },
  // { label: "Ngileb", mun_code: "142707" },
  // { label: "Laya", mun_code: "142707" },
  // { label: "Awayan", mun_code: "142708" },
  // { label: "Bunhian", mun_code: "142708" },
  // { label: "Butac", mun_code: "142708" },
  // { label: "Chalalo", mun_code: "142708" },
  // { label: "Damag", mun_code: "142708" },
  // { label: "Galonogon", mun_code: "142708" },
  // { label: "Halag", mun_code: "142708" },
  // { label: "Itab", mun_code: "142708" },
  // { label: "Jacmal", mun_code: "142708" },
  // { label: "Majlong", mun_code: "142708" },
  // { label: "Mongayang", mun_code: "142708" },
  // { label: "Posnaan", mun_code: "142708" },
  // { label: "Ta-ang", mun_code: "142708" },
  // { label: "Talite", mun_code: "142708" },
  // { label: "Ubao", mun_code: "142708" },
  // { label: "Buwag", mun_code: "142708" },
  // { label: "Anao", mun_code: "142709" },
  // { label: "Bangtinon", mun_code: "142709" },
  // { label: "Bitu", mun_code: "142709" },
  // { label: "Cababuyan", mun_code: "142709" },
  // { label: "Mompolia", mun_code: "142709" },
  // { label: "Namulditan", mun_code: "142709" },
  // { label: "O-ong", mun_code: "142709" },
  // { label: "Piwong", mun_code: "142709" },
  // { label: "Poblacion (Hingyon)", mun_code: "142709" },
  // { label: "Ubuag", mun_code: "142709" },
  // { label: "Umalbong", mun_code: "142709" },
  // { label: "Northern Cababuyan", mun_code: "142709" },
  // { label: "Ahin", mun_code: "142710" },
  // { label: "Ap-apid", mun_code: "142710" },
  // { label: "Binablayan", mun_code: "142710" },
  // { label: "Danggo", mun_code: "142710" },
  // { label: "Eheb", mun_code: "142710" },
  // { label: "Gumhang", mun_code: "142710" },
  // { label: "Impugong", mun_code: "142710" },
  // { label: "Luhong", mun_code: "142710" },
  // { label: "Tinoc", mun_code: "142710" },
  // { label: "Tukucan", mun_code: "142710" },
  // { label: "Tulludan", mun_code: "142710" },
  // { label: "Wangwang", mun_code: "142710" },
  // { label: "Amduntog", mun_code: "142711" },
  // { label: "Antipolo", mun_code: "142711" },
  // { label: "Camandag", mun_code: "142711" },
  // { label: "Cawayan", mun_code: "142711" },
  // { label: "Hallap", mun_code: "142711" },
  // { label: "Namal", mun_code: "142711" },
  // { label: "Nungawa", mun_code: "142711" },
  // { label: "Panubtuban", mun_code: "142711" },
  // { label: "Pula", mun_code: "142711" },
  // { label: "Ababa-an", mun_code: "143201" },
  // { label: "Balantoy", mun_code: "143201" },
  // { label: "Balbalan Proper", mun_code: "143201" },
  // { label: "Balbalasang", mun_code: "143201" },
  // { label: "Buaya", mun_code: "143201" },
  // { label: "Dao-angan", mun_code: "143201" },
  // { label: "Gawa-an", mun_code: "143201" },
  // { label: "Mabaca", mun_code: "143201" },
  // { label: "Maling (Kabugao)", mun_code: "143201" },
  // { label: "Pantikian", mun_code: "143201" },
  // { label: "Poswoy", mun_code: "143201" },
  // { label: "Poblacion (Salegseg)", mun_code: "143201" },
  // { label: "Talalang", mun_code: "143201" },
  // { label: "Tawang", mun_code: "143201" },
  // { label: "Dangoy", mun_code: "143206" },
  // { label: "Mabilong", mun_code: "143206" },
  // { label: "Mabongtot", mun_code: "143206" },
  // { label: "Poblacion", mun_code: "143206" },
  // { label: "Tanglag", mun_code: "143206" },
  // { label: "Lower Uma", mun_code: "143206" },
  // { label: "Upper Uma", mun_code: "143206" },
  // { label: "Antonio Canao", mun_code: "143206" },
  // { label: "Uma del Norte (Western Luna Uma)", mun_code: "143206" },
  // { label: "Ableg", mun_code: "143208" },
  // { label: "Balatoc", mun_code: "143208" },
  // { label: "Balinciagao Norte", mun_code: "143208" },
  // { label: "Cagaluan", mun_code: "143208" },
  // { label: "Colayo", mun_code: "143208" },
  // { label: "Dalupa", mun_code: "143208" },
  // { label: "Dangtalan", mun_code: "143208" },
  // { label: "Galdang (Casaloan)", mun_code: "143208" },
  // { label: "Guina-ang (Pob.)", mun_code: "143208" },
  // { label: "Magsilay", mun_code: "143208" },
  // { label: "Malucsad", mun_code: "143208" },
  // { label: "Pugong", mun_code: "143208" },
  // { label: "Balenciagao Sur", mun_code: "143208" },
  // { label: "Bagtayan", mun_code: "143208" },
  // { label: "Aciga", mun_code: "143209" },
  // { label: "Allaguia", mun_code: "143209" },
  // { label: "Ammacian", mun_code: "143209" },
  // { label: "Apatan", mun_code: "143209" },
  // { label: "Ba-ay", mun_code: "143209" },
  // { label: "Ballayangon", mun_code: "143209" },
  // { label: "Bayao", mun_code: "143209" },
  // { label: "Wagud", mun_code: "143209" },
  // { label: "Camalog", mun_code: "143209" },
  // { label: "Katabbogan", mun_code: "143209" },
  // { label: "Dugpa", mun_code: "143209" },
  // { label: "Cawagayan", mun_code: "143209" },
  // { label: "Asibanglan", mun_code: "143209" },
  // { label: "Limos", mun_code: "143209" },
  // { label: "Magaogao", mun_code: "143209" },
  // { label: "Malagnat", mun_code: "143209" },
  // { label: "Mapaco", mun_code: "143209" },
  // { label: "Pakawit", mun_code: "143209" },
  // { label: "Pinukpuk Junction", mun_code: "143209" },
  // { label: "Socbot", mun_code: "143209" },
  // { label: "Taga (Pob.)", mun_code: "143209" },
  // { label: "Pinococ", mun_code: "143209" },
  // { label: "Taggay", mun_code: "143209" },
  // { label: "Babalag East (Pob.)", mun_code: "143211" },
  // { label: "Calaocan", mun_code: "143211" },
  // { label: "Kinama", mun_code: "143211" },
  // { label: "Liwan East", mun_code: "143211" },
  // { label: "Liwan West", mun_code: "143211" },
  // { label: "Macutay", mun_code: "143211" },
  // { label: "San Pascual", mun_code: "143211" },
  // { label: "San Quintin", mun_code: "143211" },
  // { label: "Santor", mun_code: "143211" },
  // { label: "Babalag West (Pob.)", mun_code: "143211" },
  // { label: "Bulbol", mun_code: "143211" },
  // { label: "Romualdez", mun_code: "143211" },
  // { label: "San Francisco", mun_code: "143211" },
  // { label: "San Pedro", mun_code: "143211" },
  // { label: "Agbannawag", mun_code: "143213" },
  // { label: "Amlao", mun_code: "143213" },
  // { label: "Appas", mun_code: "143213" },
  // { label: "Bagumbayan", mun_code: "143213" },
  // { label: "Balawag", mun_code: "143213" },
  // { label: "Balong", mun_code: "143213" },
  // { label: "Bantay", mun_code: "143213" },
  // { label: "Bulanao", mun_code: "143213" },
  // { label: "Cabaritan", mun_code: "143213" },
  // { label: "Cabaruan", mun_code: "143213" },
  // { label: "Calaccad", mun_code: "143213" },
  // { label: "Calanan", mun_code: "143213" },
  // { label: "Dilag", mun_code: "143213" },
  // { label: "Dupag", mun_code: "143213" },
  // { label: "Gobgob", mun_code: "143213" },
  // { label: "Guilayon", mun_code: "143213" },
  // { label: "Lanna", mun_code: "143213" },
  // { label: "Laya East", mun_code: "143213" },
  // { label: "Laya West", mun_code: "143213" },
  // { label: "Lucog", mun_code: "143213" },
  // { label: "Magnao", mun_code: "143213" },
  // { label: "Magsaysay", mun_code: "143213" },
  // { label: "Malalao", mun_code: "143213" },
  // { label: "Masablang", mun_code: "143213" },
  // { label: "Nambaran", mun_code: "143213" },
  // { label: "Nambucayan", mun_code: "143213" },
  // { label: "Naneng", mun_code: "143213" },
  // { label: "Dagupan Centro (Pob.)", mun_code: "143213" },
  // { label: "San Juan", mun_code: "143213" },
  // { label: "Suyang", mun_code: "143213" },
  // { label: "Tuga", mun_code: "143213" },
  // { label: "Bado Dangwa", mun_code: "143213" },
  // { label: "Bulo", mun_code: "143213" },
  // { label: "Casigayan", mun_code: "143213" },
  // { label: "Cudal", mun_code: "143213" },
  // { label: "Dagupan Weste", mun_code: "143213" },
  // { label: "Lacnog", mun_code: "143213" },
  // { label: "Malin-awa", mun_code: "143213" },
  // { label: "New Tanglag", mun_code: "143213" },
  // { label: "San Julian", mun_code: "143213" },
  // { label: "Bulanao Norte", mun_code: "143213" },
  // { label: "Ipil", mun_code: "143213" },
  // { label: "Anggacan", mun_code: "143214" },
  // { label: "Babbanoy", mun_code: "143214" },
  // { label: "Dacalan", mun_code: "143214" },
  // { label: "Gaang", mun_code: "143214" },
  // { label: "Lower Mangali", mun_code: "143214" },
  // { label: "Lower Taloctoc", mun_code: "143214" },
  // { label: "Lower Lubo", mun_code: "143214" },
  // { label: "Upper Lubo", mun_code: "143214" },
  // { label: "Mabaca", mun_code: "143214" },
  // { label: "Pangol", mun_code: "143214" },
  // { label: "Poblacion", mun_code: "143214" },
  // { label: "Upper Taloctoc", mun_code: "143214" },
  // { label: "Anggacan Sur", mun_code: "143214" },
  // { label: "Dupligan", mun_code: "143214" },
  // { label: "Lay-asan", mun_code: "143214" },
  // { label: "Mangali Centro", mun_code: "143214" },
  // { label: "Ambato Legleg", mun_code: "143215" },
  // { label: "Bangad Centro", mun_code: "143215" },
  // { label: "Basao", mun_code: "143215" },
  // { label: "Belong Manubal", mun_code: "143215" },
  // { label: "Butbut (Butbut-Ngibat)", mun_code: "143215" },
  // { label: "Bugnay", mun_code: "143215" },
  // { label: "Buscalan (Buscalan-Locong)", mun_code: "143215" },
  // { label: "Dananao", mun_code: "143215" },
  // { label: "Loccong", mun_code: "143215" },
  // { label: "Luplupa", mun_code: "143215" },
  // { label: "Mallango", mun_code: "143215" },
  // { label: "Poblacion", mun_code: "143215" },
  // { label: "Sumadel 1", mun_code: "143215" },
  // { label: "Sumadel 2", mun_code: "143215" },
  // { label: "Tulgao East", mun_code: "143215" },
  // { label: "Tulgao West", mun_code: "143215" },
  // { label: "Upper Bangad", mun_code: "143215" },
  // { label: "Ngibat", mun_code: "143215" },
  // { label: "Old Tinglayan", mun_code: "143215" },
  // { label: "Lower Bangad", mun_code: "143215" },
  // { label: "Chupac", mun_code: "144401" },
  // { label: "Fiangtin", mun_code: "144401" },
  // { label: "Kaleo", mun_code: "144401" },
  // { label: "Latang", mun_code: "144401" },
  // { label: "Lias Kanluran", mun_code: "144401" },
  // { label: "Lingoy", mun_code: "144401" },
  // { label: "Lunas", mun_code: "144401" },
  // { label: "Macalana", mun_code: "144401" },
  // { label: "Ogoog", mun_code: "144401" },
  // { label: "Gawana (Pob.)", mun_code: "144401" },
  // { label: "Lias Silangan", mun_code: "144401" },
  // { label: "Abatan", mun_code: "144402" },
  // { label: "Bagnen Oriente", mun_code: "144402" },
  // { label: "Bagnen Proper", mun_code: "144402" },
  // { label: "Balintaugan", mun_code: "144402" },
  // { label: "Banao", mun_code: "144402" },
  // { label: "Bila (Bua)", mun_code: "144402" },
  // { label: "Guinzadan Central", mun_code: "144402" },
  // { label: "Guinzadan Norte", mun_code: "144402" },
  // { label: "Guinzadan Sur", mun_code: "144402" },
  // { label: "Lagawa", mun_code: "144402" },
  // { label: "Leseb", mun_code: "144402" },
  // { label: "Mabaay", mun_code: "144402" },
  // { label: "Mayag", mun_code: "144402" },
  // { label: "Monamon Norte", mun_code: "144402" },
  // { label: "Monamon Sur", mun_code: "144402" },
  // { label: "Mount Data", mun_code: "144402" },
  // { label: "Otucan Norte", mun_code: "144402" },
  // { label: "Otucan Sur", mun_code: "144402" },
  // { label: "Poblacion (Bauko)", mun_code: "144402" },
  // { label: "Sadsadan", mun_code: "144402" },
  // { label: "Sinto", mun_code: "144402" },
  // { label: "Tapapan", mun_code: "144402" },
  // { label: "Agawa", mun_code: "144403" },
  // { label: "Ambaguio", mun_code: "144403" },
  // { label: "Banguitan", mun_code: "144403" },
  // { label: "Besao East (Besao Proper)", mun_code: "144403" },
  // { label: "Besao West", mun_code: "144403" },
  // { label: "Catengan", mun_code: "144403" },
  // { label: "Gueday", mun_code: "144403" },
  // { label: "Lacmaan", mun_code: "144403" },
  // { label: "Laylaya", mun_code: "144403" },
  // { label: "Padangan", mun_code: "144403" },
  // { label: "Payeo", mun_code: "144403" },
  // { label: "Suquib", mun_code: "144403" },
  // { label: "Tamboan", mun_code: "144403" },
  // { label: "Kin-iway (Pob.)", mun_code: "144403" },
  // { label: "Alab Proper", mun_code: "144404" },
  // { label: "Alab Oriente", mun_code: "144404" },
  // { label: "Balili", mun_code: "144404" },
  // { label: "Bayyo", mun_code: "144404" },
  // { label: "Bontoc Ili", mun_code: "144404" },
  // { label: "Caneo", mun_code: "144404" },
  // { label: "Dalican", mun_code: "144404" },
  // { label: "Gonogon", mun_code: "144404" },
  // { label: "Guinaang", mun_code: "144404" },
  // { label: "Mainit", mun_code: "144404" },
  // { label: "Maligcong", mun_code: "144404" },
  // { label: "Samoki", mun_code: "144404" },
  // { label: "Talubin", mun_code: "144404" },
  // { label: "Tocucan", mun_code: "144404" },
  // { label: "Poblacion (Bontoc)", mun_code: "144404" },
  // { label: "Calutit", mun_code: "144404" },
  // { label: "Alunogan", mun_code: "144405" },
  // { label: "Balangao", mun_code: "144405" },
  // { label: "Banao", mun_code: "144405" },
  // { label: "Banawal", mun_code: "144405" },
  // { label: "Butac", mun_code: "144405" },
  // { label: "Maducayan", mun_code: "144405" },
  // { label: "Poblacion", mun_code: "144405" },
  // { label: "Saliok", mun_code: "144405" },
  // { label: "Santa Isabel", mun_code: "144405" },
  // { label: "Tonglayan", mun_code: "144405" },
  // { label: "Pudo", mun_code: "144405" },
  // { label: "Anonat", mun_code: "144406" },
  // { label: "Bacarni", mun_code: "144406" },
  // { label: "Bananao", mun_code: "144406" },
  // { label: "Bantay", mun_code: "144406" },
  // { label: "Butigue", mun_code: "144406" },
  // { label: "Bunot", mun_code: "144406" },
  // { label: "Buringal", mun_code: "144406" },
  // { label: "Palitod", mun_code: "144406" },
  // { label: "Poblacion", mun_code: "144406" },
  // { label: "Bao-angan", mun_code: "144407" },
  // { label: "Bun-ayan", mun_code: "144407" },
  // { label: "Busa", mun_code: "144407" },
  // { label: "Camatagan", mun_code: "144407" },
  // { label: "Capinitan", mun_code: "144407" },
  // { label: "Data", mun_code: "144407" },
  // { label: "Gayang", mun_code: "144407" },
  // { label: "Lagan", mun_code: "144407" },
  // { label: "Losad", mun_code: "144407" },
  // { label: "Namatec", mun_code: "144407" },
  // { label: "Napua", mun_code: "144407" },
  // { label: "Pingad", mun_code: "144407" },
  // { label: "Poblacion", mun_code: "144407" },
  // { label: "Supang", mun_code: "144407" },
  // { label: "Tambingan", mun_code: "144407" },
  // { label: "Anabel", mun_code: "144408" },
  // { label: "Belwang", mun_code: "144408" },
  // { label: "Betwagan", mun_code: "144408" },
  // { label: "Bekigan", mun_code: "144408" },
  // { label: "Poblacion", mun_code: "144408" },
  // { label: "Sacasacan", mun_code: "144408" },
  // { label: "Saclit", mun_code: "144408" },
  // { label: "Demang", mun_code: "144408" },
  // { label: "Aguid", mun_code: "144409" },
  // { label: "Tetepan Sur", mun_code: "144409" },
  // { label: "Ambasing", mun_code: "144409" },
  // { label: "Angkeling", mun_code: "144409" },
  // { label: "Antadao", mun_code: "144409" },
  // { label: "Balugan", mun_code: "144409" },
  // { label: "Bangaan", mun_code: "144409" },
  // { label: "Dagdag (Pob.)", mun_code: "144409" },
  // { label: "Demang (Pob.)", mun_code: "144409" },
  // { label: "Fidelisan", mun_code: "144409" },
  // { label: "Kilong", mun_code: "144409" },
  // { label: "Madongo", mun_code: "144409" },
  // { label: "Poblacion (Patay)", mun_code: "144409" },
  // { label: "Pide", mun_code: "144409" },
  // { label: "Nacagang", mun_code: "144409" },
  // { label: "Suyo", mun_code: "144409" },
  // { label: "Taccong", mun_code: "144409" },
  // { label: "Tanulong", mun_code: "144409" },
  // { label: "Tetepan Norte", mun_code: "144409" },
  // { label: "Balaoa", mun_code: "144410" },
  // { label: "Banaao", mun_code: "144410" },
  // { label: "Bantey", mun_code: "144410" },
  // { label: "Batayan", mun_code: "144410" },
  // { label: "Bunga", mun_code: "144410" },
  // { label: "Cadad-anan", mun_code: "144410" },
  // { label: "Cagubatan", mun_code: "144410" },
  // { label: "Duagan", mun_code: "144410" },
  // { label: "Dacudac", mun_code: "144410" },
  // { label: "Kayan East", mun_code: "144410" },
  // { label: "Lenga", mun_code: "144410" },
  // { label: "Lubon", mun_code: "144410" },
  // { label: "Mabalite", mun_code: "144410" },
  // { label: "Masla", mun_code: "144410" },
  // { label: "Pandayan", mun_code: "144410" },
  // { label: "Poblacion", mun_code: "144410" },
  // { label: "Sumadel", mun_code: "144410" },
  // { label: "Tue", mun_code: "144410" },
  // { label: "Kayan West", mun_code: "144410" },
  // { label: "Butao", mun_code: "148101" },
  // { label: "Cadaclan", mun_code: "148101" },
  // { label: "Langnao", mun_code: "148101" },
  // { label: "Lubong", mun_code: "148101" },
  // { label: "Naguilian", mun_code: "148101" },
  // { label: "Namaltugan", mun_code: "148101" },
  // { label: "Poblacion", mun_code: "148101" },
  // { label: "Sabangan", mun_code: "148101" },
  // { label: "Santa Filomena", mun_code: "148101" },
  // { label: "Tubongan", mun_code: "148101" },
  // { label: "Tanglagan", mun_code: "148101" },
  // { label: "Tubang", mun_code: "148101" },
  // { label: "Don Roque Ablan Sr.", mun_code: "148101" },
  // { label: "Eleazar", mun_code: "148101" },
  // { label: "Eva Puzon", mun_code: "148101" },
  // { label: "Kabugawan", mun_code: "148101" },
  // { label: "Macalino", mun_code: "148101" },
  // { label: "Santa Elena", mun_code: "148101" },
  // { label: "Allangigan", mun_code: "148102" },
  // { label: "Buluan", mun_code: "148102" },
  // { label: "Caglayan (New Pob.)", mun_code: "148102" },
  // { label: "Calafug", mun_code: "148102" },
  // { label: "Cupis", mun_code: "148102" },
  // { label: "Daga", mun_code: "148102" },
  // { label: "Guinamgaman", mun_code: "148102" },
  // { label: "Karikitan", mun_code: "148102" },
  // { label: "Katablangan", mun_code: "148102" },
  // { label: "Malama", mun_code: "148102" },
  // { label: "Manag", mun_code: "148102" },
  // { label: "Nabuangan", mun_code: "148102" },
  // { label: "Paddaoan", mun_code: "148102" },
  // { label: "Puguin", mun_code: "148102" },
  // { label: "Ripang (Old Pob.)", mun_code: "148102" },
  // { label: "Sacpil", mun_code: "148102" },
  // { label: "Talifugo", mun_code: "148102" },
  // { label: "Banban", mun_code: "148102" },
  // { label: "Guinaang", mun_code: "148102" },
  // { label: "Ili", mun_code: "148102" },
  // { label: "Mawegui", mun_code: "148102" },
  // { label: "Allig", mun_code: "148103" },
  // { label: "Anninipan", mun_code: "148103" },
  // { label: "Atok", mun_code: "148103" },
  // { label: "Bagutong", mun_code: "148103" },
  // { label: "Balasi", mun_code: "148103" },
  // { label: "Balluyan", mun_code: "148103" },
  // { label: "Malayugan", mun_code: "148103" },
  // { label: "Malubibit Norte", mun_code: "148103" },
  // { label: "Poblacion East", mun_code: "148103" },
  // { label: "Tamalunog", mun_code: "148103" },
  // { label: "Mallig", mun_code: "148103" },
  // { label: "Malubibit Sur", mun_code: "148103" },
  // { label: "Poblacion West", mun_code: "148103" },
  // { label: "San Jose", mun_code: "148103" },
  // { label: "Santa Maria", mun_code: "148103" },
  // { label: "Upper Atok (Coliman)", mun_code: "148103" },
  // { label: "Badduat", mun_code: "148104" },
  // { label: "Baliwanan", mun_code: "148104" },
  // { label: "Bulu", mun_code: "148104" },
  // { label: "Dagara", mun_code: "148104" },
  // { label: "Dibagat", mun_code: "148104" },
  // { label: "Cabetayan", mun_code: "148104" },
  // { label: "Karagawan", mun_code: "148104" },
  // { label: "Kumao", mun_code: "148104" },
  // { label: "Laco", mun_code: "148104" },
  // { label: "Lenneng (Liyyeng)", mun_code: "148104" },
  // { label: "Lucab", mun_code: "148104" },
  // { label: "Luttuacan", mun_code: "148104" },
  // { label: "Madatag", mun_code: "148104" },
  // { label: "Madduang", mun_code: "148104" },
  // { label: "Magabta", mun_code: "148104" },
  // { label: "Maragat", mun_code: "148104" },
  // { label: "Musimut", mun_code: "148104" },
  // { label: "Nagbabalayan", mun_code: "148104" },
  // { label: "Poblacion", mun_code: "148104" },
  // { label: "Tuyangan", mun_code: "148104" },
  // { label: "Waga", mun_code: "148104" },
  // { label: "Bacsay", mun_code: "148105" },
  // { label: "Capagaypayan", mun_code: "148105" },
  // { label: "Dagupan", mun_code: "148105" },
  // { label: "Lappa", mun_code: "148105" },
  // { label: "Marag", mun_code: "148105" },
  // { label: "Poblacion", mun_code: "148105" },
  // { label: "Quirino", mun_code: "148105" },
  // { label: "Salvacion", mun_code: "148105" },
  // { label: "San Francisco", mun_code: "148105" },
  // { label: "San Isidro Norte", mun_code: "148105" },
  // { label: "San Sebastian", mun_code: "148105" },
  // { label: "Santa Lina", mun_code: "148105" },
  // { label: "Tumog", mun_code: "148105" },
  // { label: "Zumigui", mun_code: "148105" },
  // { label: "Cagandungan", mun_code: "148105" },
  // { label: "Calabigan", mun_code: "148105" },
  // { label: "Cangisitan", mun_code: "148105" },
  // { label: "Luyon", mun_code: "148105" },
  // { label: "San Gregorio", mun_code: "148105" },
  // { label: "San Isidro Sur", mun_code: "148105" },
  // { label: "Shalom", mun_code: "148105" },
  // { label: "Turod", mun_code: "148105" },
  // { label: "Aga", mun_code: "148106" },
  // { label: "Alem", mun_code: "148106" },
  // { label: "Cabatacan", mun_code: "148106" },
  // { label: "Cacalaggan", mun_code: "148106" },
  // { label: "Capannikian", mun_code: "148106" },
  // { label: "Lower Maton", mun_code: "148106" },
  // { label: "Malibang", mun_code: "148106" },
  // { label: "Mataguisi", mun_code: "148106" },
  // { label: "Poblacion", mun_code: "148106" },
  // { label: "San Antonio (Pugo)", mun_code: "148106" },
  // { label: "Swan", mun_code: "148106" },
  // { label: "Upper Maton", mun_code: "148106" },
  // { label: "Amado", mun_code: "148106" },
  // { label: "Aurora", mun_code: "148106" },
  // { label: "Emilia", mun_code: "148106" },
  // { label: "Imelda", mun_code: "148106" },
  // { label: "Lt. Bilag", mun_code: "148106" },
  // { label: "Lydia", mun_code: "148106" },
  // { label: "San Jose", mun_code: "148106" },
  // { label: "San Luis", mun_code: "148106" },
  // { label: "San Mariano", mun_code: "148106" },
  // { label: "Barocboc", mun_code: "148107" },
  // { label: "Consuelo", mun_code: "148107" },
  // { label: "Imelda (Sipa Annex)", mun_code: "148107" },
  // { label: "Malekkeg", mun_code: "148107" },
  // { label: "Marcela (Pob.)", mun_code: "148107" },
  // { label: "Nueva", mun_code: "148107" },
  // { label: "Panay", mun_code: "148107" },
  // { label: "San Antonio", mun_code: "148107" },
  // { label: "Sipa Proper", mun_code: "148107" },
  // { label: "Emiliana", mun_code: "148107" },
  // { label: "San Carlos", mun_code: "148107" },
  // { label: "San Juan", mun_code: "148107" },
  // { label: "San Mariano", mun_code: "148107" },
  // { label: "Arco", mun_code: "150702" },
  // { label: "Ba-as", mun_code: "150702" },
  // { label: "Baimbing", mun_code: "150702" },
  // { label: "Balagtasan", mun_code: "150702" },
  // { label: "Balas", mun_code: "150702" },
  // { label: "Balobo", mun_code: "150702" },
  // { label: "Bato", mun_code: "150702" },
  // { label: "Boheyakan", mun_code: "150702" },
  // { label: "Buahan", mun_code: "150702" },
  // { label: "Boheibu", mun_code: "150702" },
  // { label: "Bohesapa", mun_code: "150702" },
  // { label: "Bulingan", mun_code: "150702" },
  // { label: "Cabobo", mun_code: "150702" },
  // { label: "Campo Uno", mun_code: "150702" },
  // { label: "Colonia", mun_code: "150702" },
  // { label: "Calugusan", mun_code: "150702" },
  // { label: "Kulay Bato", mun_code: "150702" },
  // { label: "Limo-ok", mun_code: "150702" },
  // { label: "Lo-ok", mun_code: "150702" },
  // { label: "Lumuton", mun_code: "150702" },
  // { label: "Luksumbang", mun_code: "150702" },
  // { label: "Malo-ong Canal", mun_code: "150702" },
  // { label: "Malo-ong San Jose", mun_code: "150702" },
  // { label: "Parangbasak", mun_code: "150702" },
  // { label: "Santa Clara", mun_code: "150702" },
  // { label: "Tandong Ahas", mun_code: "150702" },
  // { label: "Tumakid", mun_code: "150702" },
  // { label: "Ubit", mun_code: "150702" },
  // { label: "Bohebessey", mun_code: "150702" },
  // { label: "Baungos", mun_code: "150702" },
  // { label: "Danit-Puntocan", mun_code: "150702" },
  // { label: "Sabong", mun_code: "150702" },
  // { label: "Sengal", mun_code: "150702" },
  // { label: "Ulame", mun_code: "150702" },
  // { label: "Bohenange", mun_code: "150702" },
  // { label: "Boheyawas", mun_code: "150702" },
  // { label: "Bulanting", mun_code: "150702" },
  // { label: "Lebbuh", mun_code: "150702" },
  // { label: "Maganda", mun_code: "150702" },
  // { label: "Malakas", mun_code: "150702" },
  // { label: "Maligaya", mun_code: "150702" },
  // { label: "Malinis (Pob.)", mun_code: "150702" },
  // { label: "Matatag", mun_code: "150702" },
  // { label: "Matibay", mun_code: "150702" },
  // { label: "Simbangon", mun_code: "150702" },
  // { label: "Atong-atong", mun_code: "150703" },
  // { label: "Baungis", mun_code: "150703" },
  // { label: "Bulanza", mun_code: "150703" },
  // { label: "Lantawan Proper (Pob.)", mun_code: "150703" },
  // { label: "Lower Manggas", mun_code: "150703" },
  // { label: "Matarling", mun_code: "150703" },
  // { label: "Matikang", mun_code: "150703" },
  // { label: "Tairan", mun_code: "150703" },
  // { label: "Upper Manggas", mun_code: "150703" },
  // { label: "Bagbagon", mun_code: "150703" },
  // { label: "Bulan-bulan", mun_code: "150703" },
  // { label: "Suba-an (Pangasahan)", mun_code: "150703" },
  // { label: "Calugusan", mun_code: "150703" },
  // { label: "Canibungan", mun_code: "150703" },
  // { label: "Landugan", mun_code: "150703" },
  // { label: "Lawila", mun_code: "150703" },
  // { label: "Lawi-lawi", mun_code: "150703" },
  // { label: "Pamucalin", mun_code: "150703" },
  // { label: "Switch Yakal", mun_code: "150703" },
  // { label: "Paniongan", mun_code: "150703" },
  // { label: "Luuk-Maluha", mun_code: "150703" },
  // { label: "Calayan", mun_code: "150703" },
  // { label: "Parian-Baunoh", mun_code: "150703" },
  // { label: "Abong-Abong", mun_code: "150704" },
  // { label: "Batungal", mun_code: "150704" },
  // { label: "Calang Canas", mun_code: "150704" },
  // { label: "Guanan North (Zone I)", mun_code: "150704" },
  // { label: "Guanan South (Zone II)", mun_code: "150704" },
  // { label: "Limbubong", mun_code: "150704" },
  // { label: "Mahayahay Lower (Zone I)", mun_code: "150704" },
  // { label: "Muslim Area", mun_code: "150704" },
  // { label: "Port Holland Zone I Pob. (Upper)", mun_code: "150704" },
  // { label: "Port Holland Zone II Pob. (Shipyard Main)", mun_code: "150704" },
  // {
  //   label: "Port Holland Zone III Pob. (Samal Village)",
  //   mun_code: "150704",
  // },
  // { label: "Port Holland Zone IV (Lower)", mun_code: "150704" },
  // { label: "Townsite (Pob.)", mun_code: "150704" },
  // { label: "Taberlongan", mun_code: "150704" },
  // { label: "Fuente Maluso", mun_code: "150704" },
  // { label: "Tamuk", mun_code: "150704" },
  // { label: "Tubigan", mun_code: "150704" },
  // { label: "Mahayahay Upper (Zone II)", mun_code: "150704" },
  // { label: "Port Holland Zone V (Shipyard Tabuk)", mun_code: "150704" },
  // { label: "Upper Garlayan", mun_code: "150704" },
  // { label: "Bacung", mun_code: "150705" },
  // { label: "Benembengan Lower", mun_code: "150705" },
  // { label: "Buli-buli", mun_code: "150705" },
  // { label: "Cabcaban", mun_code: "150705" },
  // { label: "Guiong", mun_code: "150705" },
  // { label: "Manaul", mun_code: "150705" },
  // { label: "Mangal (Pob.)", mun_code: "150705" },
  // { label: "Sumisip Central", mun_code: "150705" },
  // { label: "Tumahubong", mun_code: "150705" },
  // { label: "Libug", mun_code: "150705" },
  // { label: "Tongsengal", mun_code: "150705" },
  // { label: "Baiwas", mun_code: "150705" },
  // { label: "Basak", mun_code: "150705" },
  // { label: "Cabengbeng Lower", mun_code: "150705" },
  // { label: "Cabengbeng Upper", mun_code: "150705" },
  // { label: "Luuk-Bait", mun_code: "150705" },
  // { label: "Mahatalang", mun_code: "150705" },
  // { label: "Benembengan Upper", mun_code: "150705" },
  // { label: "Bohe-languyan", mun_code: "150705" },
  // { label: "Ettub-ettub", mun_code: "150705" },
  // { label: "Kaum-Air", mun_code: "150705" },
  // { label: "Limbocandis", mun_code: "150705" },
  // { label: "Lukketon", mun_code: "150705" },
  // { label: "Marang", mun_code: "150705" },
  // { label: "Mebak", mun_code: "150705" },
  // { label: "Sahaya Bohe Bato", mun_code: "150705" },
  // { label: "Sapah Bulak", mun_code: "150705" },
  // { label: "Tikus", mun_code: "150705" },
  // { label: "Kaumpamatsakem", mun_code: "150705" },
  // { label: "Badja", mun_code: "150706" },
  // { label: "Bohebaca", mun_code: "150706" },
  // { label: "Bohelebung", mun_code: "150706" },
  // { label: "Lagayas", mun_code: "150706" },
  // { label: "Limbo-Upas", mun_code: "150706" },
  // { label: "Tipo-tipo Proper (Pob.)", mun_code: "150706" },
  // { label: "Baguindan", mun_code: "150706" },
  // { label: "Banah", mun_code: "150706" },
  // { label: "Bohe-Tambak", mun_code: "150706" },
  // { label: "Silangkum", mun_code: "150706" },
  // { label: "Bangcuang", mun_code: "150706" },
  // { label: "Lahi-lahi", mun_code: "150707" },
  // { label: "Sinulatan", mun_code: "150707" },
  // { label: "Bohetambis", mun_code: "150707" },
  // { label: "Duga-a", mun_code: "150707" },
  // { label: "Mahawid", mun_code: "150707" },
  // { label: "Lower Sinangkapan", mun_code: "150707" },
  // { label: "Tablas Usew", mun_code: "150707" },
  // { label: "Calut", mun_code: "150707" },
  // { label: "Katipunan", mun_code: "150707" },
  // { label: "Lower Tablas", mun_code: "150707" },
  // { label: "Caddayan", mun_code: "150708" },
  // { label: "Linongan", mun_code: "150708" },
  // { label: "Lower Bato-bato", mun_code: "150708" },
  // { label: "Mangalut", mun_code: "150708" },
  // { label: "Manguso", mun_code: "150708" },
  // { label: "Paguengan", mun_code: "150708" },
  // { label: "Semut", mun_code: "150708" },
  // { label: "Upper Bato-bato", mun_code: "150708" },
  // { label: "Upper Sinangkapan", mun_code: "150708" },
  // { label: "Apil-apil", mun_code: "150709" },
  // { label: "Bato-bato", mun_code: "150709" },
  // { label: "Bohe-Piang", mun_code: "150709" },
  // { label: "Bucalao", mun_code: "150709" },
  // { label: "Cambug", mun_code: "150709" },
  // { label: "Danapah", mun_code: "150709" },
  // { label: "Guinanta", mun_code: "150709" },
  // { label: "Kailih", mun_code: "150709" },
  // { label: "Kinukutan", mun_code: "150709" },
  // { label: "Kuhon", mun_code: "150709" },
  // { label: "Kuhon Lennuh", mun_code: "150709" },
  // { label: "Linuan", mun_code: "150709" },
  // { label: "Lookbisaya (Kaulungan Island)", mun_code: "150709" },
  // { label: "Macalang", mun_code: "150709" },
  // { label: "Magcawa", mun_code: "150709" },
  // { label: "Sangkahan (Kaulungan Island)", mun_code: "150709" },
  // { label: "Basakan", mun_code: "150710" },
  // { label: "Buton", mun_code: "150710" },
  // { label: "Candiis", mun_code: "150710" },
  // { label: "Langil", mun_code: "150710" },
  // { label: "Langong", mun_code: "150710" },
  // { label: "Languyan", mun_code: "150710" },
  // { label: "Pintasan", mun_code: "150710" },
  // { label: "Seronggon", mun_code: "150710" },
  // { label: "Sibago", mun_code: "150710" },
  // { label: "Sulutan Matangal", mun_code: "150710" },
  // { label: "Tuburan Proper (Pob.)", mun_code: "150710" },
  // { label: "Amaloy", mun_code: "150711" },
  // { label: "Bohe-Pahuh", mun_code: "150711" },
  // { label: "Bohe-Suyak", mun_code: "150711" },
  // { label: "Cabangalan", mun_code: "150711" },
  // { label: "Danit", mun_code: "150711" },
  // { label: "Kamamburingan", mun_code: "150711" },
  // { label: "Matata", mun_code: "150711" },
  // { label: "Materling", mun_code: "150711" },
  // { label: "Pipil", mun_code: "150711" },
  // { label: "Sungkayut", mun_code: "150711" },
  // { label: "Tongbato", mun_code: "150711" },
  // { label: "Ulitan", mun_code: "150711" },
  // { label: "Baluk-baluk", mun_code: "150712" },
  // { label: "Dasalan", mun_code: "150712" },
  // { label: "Lubukan", mun_code: "150712" },
  // { label: "Luukbongsod", mun_code: "150712" },
  // { label: "Mananggal", mun_code: "150712" },
  // { label: "Palahangan", mun_code: "150712" },
  // { label: "Panducan", mun_code: "150712" },
  // { label: "Sangbay Big", mun_code: "150712" },
  // { label: "Sangbay Small", mun_code: "150712" },
  // { label: "Tausan", mun_code: "150712" },
  // { label: "Babag (Babuan Island)", mun_code: "150713" },
  // { label: "Balanting", mun_code: "150713" },
  // { label: "Boloh-boloh", mun_code: "150713" },
  // { label: "Bukut-Umus", mun_code: "150713" },
  // { label: "Kaumpurnah", mun_code: "150713" },
  // { label: "Lanawan", mun_code: "150713" },
  // { label: "Pisak-pisak", mun_code: "150713" },
  // { label: "Saluping", mun_code: "150713" },
  // { label: "Suligan (Babuan Island)", mun_code: "150713" },
  // { label: "Sulloh (Tapiantana)", mun_code: "150713" },
  // { label: "Tambulig Buton", mun_code: "150713" },
  // { label: "Tong-Umus", mun_code: "150713" },
  // { label: "Ampao", mun_code: "153601" },
  // { label: "Bagoaingud", mun_code: "153601" },
  // { label: "Balut", mun_code: "153601" },
  // { label: "Barua", mun_code: "153601" },
  // { label: "Buadiawani", mun_code: "153601" },
  // { label: "Bubong", mun_code: "153601" },
  // { label: "Dilabayan", mun_code: "153601" },
  // { label: "Dipatuan", mun_code: "153601" },
  // { label: "Daramoyod", mun_code: "153601" },
  // { label: "Gandamato", mun_code: "153601" },
  // { label: "Gurain", mun_code: "153601" },
  // { label: "Ilian", mun_code: "153601" },
  // { label: "Lama", mun_code: "153601" },
  // { label: "Liawao", mun_code: "153601" },
  // { label: "Lumbaca-Ingud", mun_code: "153601" },
  // { label: "Madanding", mun_code: "153601" },
  // { label: "Orong", mun_code: "153601" },
  // { label: "Pindolonan", mun_code: "153601" },
  // { label: "Poblacion I", mun_code: "153601" },
  // { label: "Poblacion II", mun_code: "153601" },
  // { label: "Raya", mun_code: "153601" },
  // { label: "Rorowan", mun_code: "153601" },
  // { label: "Sugod", mun_code: "153601" },
  // { label: "Tambo", mun_code: "153601" },
  // { label: "Tuka I", mun_code: "153601" },
  // { label: "Tuka II", mun_code: "153601" },
  // { label: "Banago", mun_code: "153602" },
  // { label: "Barorao", mun_code: "153602" },
  // { label: "Batuan", mun_code: "153602" },
  // { label: "Budas", mun_code: "153602" },
  // { label: "Calilangan", mun_code: "153602" },
  // { label: "Igabay", mun_code: "153602" },
  // { label: "Lalabuan", mun_code: "153602" },
  // { label: "Magulalung Occidental", mun_code: "153602" },
  // { label: "Magulalung Oriental", mun_code: "153602" },
  // { label: "Molimoc", mun_code: "153602" },
  // { label: "Narra", mun_code: "153602" },
  // { label: "Plasan", mun_code: "153602" },
  // { label: "Purakan", mun_code: "153602" },
  // { label: "Buisan (Bengabeng)", mun_code: "153602" },
  // { label: "Buenavista", mun_code: "153602" },
  // { label: "Lorenzo", mun_code: "153602" },
  // { label: "Lower Itil", mun_code: "153602" },
  // { label: "Macao", mun_code: "153602" },
  // { label: "Poblacion (Balabagan)", mun_code: "153602" },
  // { label: "Upper Itil", mun_code: "153602" },
  // { label: "Bagoaingud", mun_code: "153602" },
  // { label: "Ilian", mun_code: "153602" },
  // { label: "Lumbac", mun_code: "153602" },
  // { label: "Matampay", mun_code: "153602" },
  // { label: "Matanog", mun_code: "153602" },
  // { label: "Pindolonan", mun_code: "153602" },
  // { label: "Tataya", mun_code: "153602" },
  // { label: "Abaga", mun_code: "153603" },
  // { label: "Poblacion (Balindong)", mun_code: "153603" },
  // { label: "Pantaragoo", mun_code: "153603" },
  // { label: "Bantoga Wato", mun_code: "153603" },
  // { label: "Barit", mun_code: "153603" },
  // { label: "Bubong", mun_code: "153603" },
  // { label: "Bubong Cadapaan", mun_code: "153603" },
  // { label: "Borakis", mun_code: "153603" },
  // { label: "Bualan", mun_code: "153603" },
  // { label: "Cadapaan", mun_code: "153603" },
  // { label: "Cadayonan", mun_code: "153603" },
  // { label: "Kaluntay", mun_code: "153603" },
  // { label: "Dadayag", mun_code: "153603" },
  // { label: "Dado", mun_code: "153603" },
  // { label: "Dibarusan", mun_code: "153603" },
  // { label: "Dilausan", mun_code: "153603" },
  // { label: "Dimarao", mun_code: "153603" },
  // { label: "Ingud", mun_code: "153603" },
  // { label: "Lalabuan", mun_code: "153603" },
  // { label: "Lilod", mun_code: "153603" },
  // { label: "Lumbayao", mun_code: "153603" },
  // { label: "Limbo", mun_code: "153603" },
  // { label: "Lumbac Lalan", mun_code: "153603" },
  // { label: "Lumbac Wato", mun_code: "153603" },
  // { label: "Magarang", mun_code: "153603" },
  // { label: "Nusa Lumba Ranao", mun_code: "153603" },
  // { label: "Padila", mun_code: "153603" },
  // { label: "Pagayawan", mun_code: "153603" },
  // { label: "Paigoay", mun_code: "153603" },
  // { label: "Raya", mun_code: "153603" },
  // { label: "Salipongan", mun_code: "153603" },
  // { label: "Talub", mun_code: "153603" },
  // { label: "Tomarompong", mun_code: "153603" },
  // { label: "Tantua Raya", mun_code: "153603" },
  // { label: "Tuka Bubong", mun_code: "153603" },
  // { label: "Bolinsong", mun_code: "153603" },
  // { label: "Lati", mun_code: "153603" },
  // { label: "Malaig", mun_code: "153603" },
  // { label: "Bagoaingud", mun_code: "153604" },
  // { label: "Bairan (Pob.)", mun_code: "153604" },
  // { label: "Bandingun", mun_code: "153604" },
  // { label: "Biabi", mun_code: "153604" },
  // { label: "Bialaan", mun_code: "153604" },
  // { label: "Cadayonan", mun_code: "153604" },
  // { label: "Lumbac Cadayonan", mun_code: "153604" },
  // { label: "Lalapung Central", mun_code: "153604" },
  // { label: "Condaraan Pob. (Condaraan Dimadap)", mun_code: "153604" },
  // { label: "Cormatan", mun_code: "153604" },
  // { label: "Ilian", mun_code: "153604" },
  // { label: "Lalapung Proper (Pob.)", mun_code: "153604" },
  // { label: "Bubong Lilod", mun_code: "153604" },
  // { label: "Linao", mun_code: "153604" },
  // { label: "Linuk (Pob.)", mun_code: "153604" },
  // { label: "Liong", mun_code: "153604" },
  // { label: "Lumbac", mun_code: "153604" },
  // { label: "Mimbalawag", mun_code: "153604" },
  // { label: "Maliwanag", mun_code: "153604" },
  // { label: "Mapantao", mun_code: "153604" },
  // { label: "Cadingilan Occidental", mun_code: "153604" },
  // { label: "Cadingilan Oriental", mun_code: "153604" },
  // { label: "Palao", mun_code: "153604" },
  // { label: "Pama-an", mun_code: "153604" },
  // { label: "Pamacotan", mun_code: "153604" },
  // { label: "Pantar", mun_code: "153604" },
  // { label: "Parao", mun_code: "153604" },
  // { label: "Patong", mun_code: "153604" },
  // { label: "Porotan", mun_code: "153604" },
  // { label: "Rantian", mun_code: "153604" },
  // { label: "Raya Cadayonan", mun_code: "153604" },
  // { label: "Rinabor (Pob.)", mun_code: "153604" },
  // { label: "Sapa", mun_code: "153604" },
  // { label: "Samporna (Pob.)", mun_code: "153604" },
  // { label: "Silid", mun_code: "153604" },
  // { label: "Sugod", mun_code: "153604" },
  // { label: "Sultan Pandapatan", mun_code: "153604" },
  // { label: "Sumbag (Pob.)", mun_code: "153604" },
  // { label: "Tagoranao", mun_code: "153604" },
  // { label: "Tangcal", mun_code: "153604" },
  // { label: "Tangcal Proper (Pob.)", mun_code: "153604" },
  // { label: "Tuca (Pob.)", mun_code: "153604" },
  // { label: "Tomarompong", mun_code: "153604" },
  // { label: "Tomongcal Ligi", mun_code: "153604" },
  // { label: "Torogan", mun_code: "153604" },
  // { label: "Lalapung Upper", mun_code: "153604" },
  // { label: "Gandamato", mun_code: "153604" },
  // { label: "Bubong Raya", mun_code: "153604" },
  // { label: "Poblacion (Bayang)", mun_code: "153604" },
  // { label: "Badak", mun_code: "153605" },
  // { label: "Baguiangun", mun_code: "153605" },
  // { label: "Balut Maito", mun_code: "153605" },
  // { label: "Basak", mun_code: "153605" },
  // { label: "Bubong", mun_code: "153605" },
  // { label: "Bubonga-Ranao", mun_code: "153605" },
  // { label: "Dansalan Dacsula", mun_code: "153605" },
  // { label: "Ingud", mun_code: "153605" },
  // { label: "Kialilidan", mun_code: "153605" },
  // { label: "Lumbac", mun_code: "153605" },
  // { label: "Macaguiling", mun_code: "153605" },
  // { label: "Madaya", mun_code: "153605" },
  // { label: "Magonaya", mun_code: "153605" },
  // { label: "Maindig", mun_code: "153605" },
  // { label: "Masolun", mun_code: "153605" },
  // { label: "Olama", mun_code: "153605" },
  // { label: "Pagalamatan (Pob.)", mun_code: "153605" },
  // { label: "Pantar", mun_code: "153605" },
  // { label: "Picalilangan", mun_code: "153605" },
  // { label: "Picotaan", mun_code: "153605" },
  // { label: "Pindolonan", mun_code: "153605" },
  // { label: "Poblacion", mun_code: "153605" },
  // { label: "Soldaroro", mun_code: "153605" },
  // { label: "Tambac", mun_code: "153605" },
  // { label: "Timbangan", mun_code: "153605" },
  // { label: "Tuca", mun_code: "153605" },
  // { label: "Bagoaingud", mun_code: "153606" },
  // { label: "Bansayan", mun_code: "153606" },
  // { label: "Basingan", mun_code: "153606" },
  // { label: "Batangan", mun_code: "153606" },
  // { label: "Bualan", mun_code: "153606" },
  // { label: "Poblacion (Bubong)", mun_code: "153606" },
  // { label: "Bubonga Didagun", mun_code: "153606" },
  // { label: "Carigongan", mun_code: "153606" },
  // { label: "Bacolod", mun_code: "153606" },
  // { label: "Dilabayan", mun_code: "153606" },
  // { label: "Dimapatoy", mun_code: "153606" },
  // { label: "Dimayon Proper", mun_code: "153606" },
  // { label: "Diolangan", mun_code: "153606" },
  // { label: "Guiguikun", mun_code: "153606" },
  // { label: "Dibarosan", mun_code: "153606" },
  // { label: "Madanding", mun_code: "153606" },
  // { label: "Malungun", mun_code: "153606" },
  // { label: "Masorot", mun_code: "153606" },
  // { label: "Matampay Dimarao", mun_code: "153606" },
  // { label: "Miabalawag", mun_code: "153606" },
  // { label: "Montiaan", mun_code: "153606" },
  // { label: "Pagayawan", mun_code: "153606" },
  // { label: "Palao", mun_code: "153606" },
  // { label: "Panalawan", mun_code: "153606" },
  // { label: "Pantar", mun_code: "153606" },
  // { label: "Pendogoan", mun_code: "153606" },
  // { label: "Polayagan", mun_code: "153606" },
  // { label: "Ramain Bubong", mun_code: "153606" },
  // { label: "Rogero", mun_code: "153606" },
  // { label: "Salipongan", mun_code: "153606" },
  // { label: "Sunggod", mun_code: "153606" },
  // { label: "Taboro", mun_code: "153606" },
  // { label: "Dalaon", mun_code: "153606" },
  // { label: "Dimayon", mun_code: "153606" },
  // { label: "Pindolonan", mun_code: "153606" },
  // { label: "Punud", mun_code: "153606" },
  // { label: "Butig Proper", mun_code: "153607" },
  // { label: "Cabasaran", mun_code: "153607" },
  // { label: "Coloyan Tambo", mun_code: "153607" },
  // { label: "Dilimbayan", mun_code: "153607" },
  // { label: "Dolangan", mun_code: "153607" },
  // { label: "Pindolonan", mun_code: "153607" },
  // { label: "Bayabao Poblacion", mun_code: "153607" },
  // { label: "Poktan", mun_code: "153607" },
  // { label: "Ragayan", mun_code: "153607" },
  // { label: "Raya", mun_code: "153607" },
  // { label: "Samer", mun_code: "153607" },
  // { label: "Sandab Madaya", mun_code: "153607" },
  // { label: "Sundig", mun_code: "153607" },
  // { label: "Tiowi", mun_code: "153607" },
  // { label: "Timbab", mun_code: "153607" },
  // { label: "Malungun", mun_code: "153607" },
  // { label: "Bagoaingud", mun_code: "153609" },
  // { label: "Balintad", mun_code: "153609" },
  // { label: "Barit", mun_code: "153609" },
  // { label: "Bato Batoray", mun_code: "153609" },
  // { label: "Campong a Raya", mun_code: "153609" },
  // { label: "Gadongan", mun_code: "153609" },
  // { label: "Gui", mun_code: "153609" },
  // { label: "Linuk", mun_code: "153609" },
  // { label: "Lumbac", mun_code: "153609" },
  // { label: "Macabao", mun_code: "153609" },
  // { label: "Macaguiling", mun_code: "153609" },
  // { label: "Pagalongan", mun_code: "153609" },
  // { label: "Panggawalupa", mun_code: "153609" },
  // { label: "Pantaon A", mun_code: "153609" },
  // { label: "Para-aba", mun_code: "153609" },
  // { label: "Pindolonan", mun_code: "153609" },
  // { label: "Poblacion", mun_code: "153609" },
  // { label: "Baya", mun_code: "153609" },
  // { label: "Sogod Madaya", mun_code: "153609" },
  // { label: "Tabuan", mun_code: "153609" },
  // { label: "Taganonok", mun_code: "153609" },
  // { label: "Taliogon", mun_code: "153609" },
  // { label: "Masolun", mun_code: "153609" },
  // { label: "Lumbacaingud", mun_code: "153609" },
  // { label: "Sekun Matampay", mun_code: "153609" },
  // { label: "Dapaan", mun_code: "153609" },
  // { label: "Balintad A", mun_code: "153609" },
  // { label: "Barorao", mun_code: "153609" },
  // { label: "Campong Sabela", mun_code: "153609" },
  // { label: "Pangadapun", mun_code: "153609" },
  // { label: "Pantaon", mun_code: "153609" },
  // { label: "Pamalian", mun_code: "153609" },
  // { label: "Cormatan", mun_code: "153610" },
  // { label: "Dimagaling (Dimagalin Proper)", mun_code: "153610" },
  // { label: "Dimunda", mun_code: "153610" },
  // { label: "Doronan", mun_code: "153610" },
  // { label: "Poblacion (Kapai Proper)", mun_code: "153610" },
  // { label: "Malna Proper", mun_code: "153610" },
  // { label: "Pagalongan", mun_code: "153610" },
  // { label: "Parao", mun_code: "153610" },
  // { label: "Kasayanan", mun_code: "153610" },
  // { label: "Kasayanan West", mun_code: "153610" },
  // { label: "Dilabayan", mun_code: "153610" },
  // { label: "Dilimbayan", mun_code: "153610" },
  // { label: "Kibolos", mun_code: "153610" },
  // { label: "Kining", mun_code: "153610" },
  // { label: "Pindolonan", mun_code: "153610" },
  // { label: "Babayog", mun_code: "153610" },
  // { label: "Gadongan", mun_code: "153610" },
  // { label: "Lidasan", mun_code: "153610" },
  // { label: "Macadar", mun_code: "153610" },
  // { label: "Pantaon", mun_code: "153610" },
  // { label: "Bacolod I", mun_code: "153611" },
  // { label: "Bacolod II", mun_code: "153611" },
  // { label: "Bantayao", mun_code: "153611" },
  // { label: "Barit", mun_code: "153611" },
  // { label: "Baugan", mun_code: "153611" },
  // { label: "Buad Lumbac", mun_code: "153611" },
  // { label: "Cabasaran", mun_code: "153611" },
  // { label: "Calilangan", mun_code: "153611" },
  // { label: "Carandangan-Mipaga", mun_code: "153611" },
  // { label: "Cormatan Langban", mun_code: "153611" },
  // { label: "Dialongana", mun_code: "153611" },
  // { label: "Dilindongan-Cadayonan", mun_code: "153611" },
  // { label: "Gadongan", mun_code: "153611" },
  // { label: "Galawan", mun_code: "153611" },
  // { label: "Gambai", mun_code: "153611" },
  // { label: "Kasola", mun_code: "153611" },
  // { label: "Lalangitun", mun_code: "153611" },
  // { label: "Lama", mun_code: "153611" },
  // { label: "Lindongan Dialongana", mun_code: "153611" },
  // { label: "Lobo Basara", mun_code: "153611" },
  // { label: "Lumbac Bacayawan", mun_code: "153611" },
  // { label: "Macaguiling", mun_code: "153611" },
  // { label: "Mapantao", mun_code: "153611" },
  // { label: "Mapoling", mun_code: "153611" },
  // { label: "Pagayawan", mun_code: "153611" },
  // { label: "Maribo (Pob.)", mun_code: "153611" },
  // { label: "Posudaragat", mun_code: "153611" },
  // { label: "Rumayas", mun_code: "153611" },
  // { label: "Sabala Bantayao", mun_code: "153611" },
  // { label: "Salaman", mun_code: "153611" },
  // { label: "Salolodun Berwar", mun_code: "153611" },
  // { label: "Sarigidan Madiar", mun_code: "153611" },
  // { label: "Sunggod", mun_code: "153611" },
  // { label: "Taluan", mun_code: "153611" },
  // { label: "Tamlang", mun_code: "153611" },
  // { label: "Tongcopan", mun_code: "153611" },
  // { label: "Turogan", mun_code: "153611" },
  // { label: "Minaring Diladigan", mun_code: "153611" },
  // { label: "Alog", mun_code: "153612" },
  // { label: "Basayungun", mun_code: "153612" },
  // { label: "Buad", mun_code: "153612" },
  // { label: "Budi", mun_code: "153612" },
  // { label: "Dago-ok", mun_code: "153612" },
  // { label: "Dalama", mun_code: "153612" },
  // { label: "Dalipuga", mun_code: "153612" },
  // { label: "Lalapung", mun_code: "153612" },
  // { label: "Lumbac", mun_code: "153612" },
  // { label: "Lumbac Bacayawan", mun_code: "153612" },
  // { label: "Lunay", mun_code: "153612" },
  // { label: "Macadar", mun_code: "153612" },
  // { label: "Madaya", mun_code: "153612" },
  // { label: "Minanga", mun_code: "153612" },
  // { label: "Pantar", mun_code: "153612" },
  // { label: "Picotaan", mun_code: "153612" },
  // { label: "Poblacion (Lumbatan)", mun_code: "153612" },
  // { label: "Tambac", mun_code: "153612" },
  // { label: "Bubong Macadar", mun_code: "153612" },
  // { label: "Penaring", mun_code: "153612" },
  // { label: "Ligue", mun_code: "153612" },
  // { label: "Abaga", mun_code: "153613" },
  // { label: "Bagoaingud", mun_code: "153613" },
  // { label: "Basak", mun_code: "153613" },
  // { label: "Bato", mun_code: "153613" },
  // { label: "Bubong", mun_code: "153613" },
  // { label: "Kormatan", mun_code: "153613" },
  // { label: "Dandamun", mun_code: "153613" },
  // { label: "Diampaca", mun_code: "153613" },
  // { label: "Dibarosan", mun_code: "153613" },
  // { label: "Delausan", mun_code: "153613" },
  // { label: "Gadongan", mun_code: "153613" },
  // { label: "Gurain", mun_code: "153613" },
  // { label: "Cabasaran", mun_code: "153613" },
  // { label: "Cadayonan", mun_code: "153613" },
  // { label: "Liangan I", mun_code: "153613" },
  // { label: "Lilitun", mun_code: "153613" },
  // { label: "Linao", mun_code: "153613" },
  // { label: "Linuk", mun_code: "153613" },
  // { label: "Madaya", mun_code: "153613" },
  // { label: "Pagayawan", mun_code: "153613" },
  // { label: "Poblacion", mun_code: "153613" },
  // { label: "Punud", mun_code: "153613" },
  // { label: "Raya", mun_code: "153613" },
  // { label: "Riray", mun_code: "153613" },
  // { label: "Sabanding", mun_code: "153613" },
  // { label: "Salongabanding", mun_code: "153613" },
  // { label: "Sugod", mun_code: "153613" },
  // { label: "Tamporong", mun_code: "153613" },
  // { label: "Tongantongan", mun_code: "153613" },
  // { label: "Udangun", mun_code: "153613" },
  // { label: "Liangan", mun_code: "153613" },
  // { label: "Lumbac", mun_code: "153613" },
  // { label: "Paridi-Kalimodan", mun_code: "153613" },
  // { label: "Racotan", mun_code: "153613" },
  // { label: "Bacayawan", mun_code: "153613" },
  // { label: "Padian Torogan I", mun_code: "153613" },
  // { label: "Sogod Kaloy", mun_code: "153613" },
  // { label: "Balintad", mun_code: "153614" },
  // { label: "Balagunun", mun_code: "153614" },
  // { label: "Bawang", mun_code: "153614" },
  // { label: "Biabe", mun_code: "153614" },
  // { label: "Bubong Uyaan", mun_code: "153614" },
  // { label: "Cabasaran", mun_code: "153614" },
  // { label: "Dibarusan", mun_code: "153614" },
  // { label: "Lakitan", mun_code: "153614" },
  // { label: "Liangan", mun_code: "153614" },
  // { label: "Linuk", mun_code: "153614" },
  // { label: "Lumbaca Ingud", mun_code: "153614" },
  // { label: "Palao", mun_code: "153614" },
  // { label: "Pantaon", mun_code: "153614" },
  // { label: "Pantar", mun_code: "153614" },
  // { label: "Madamba", mun_code: "153614" },
  // { label: "Punud", mun_code: "153614" },
  // { label: "Tubaran", mun_code: "153614" },
  // { label: "Tambo", mun_code: "153614" },
  // { label: "Tuca", mun_code: "153614" },
  // { label: "Uyaan Proper (Pob.)", mun_code: "153614" },
  // { label: "Tulay", mun_code: "153614" },
  // { label: "Ilian", mun_code: "153614" },
  // { label: "Pagayonan", mun_code: "153614" },
  // { label: "Pangadapan", mun_code: "153614" },
  // { label: "Bacayawan", mun_code: "153615" },
  // { label: "Badak Lumao", mun_code: "153615" },
  // { label: "Bagoaingud", mun_code: "153615" },
  // { label: "Boniga", mun_code: "153615" },
  // { label: "BPS Village", mun_code: "153615" },
  // { label: "Betayan", mun_code: "153615" },
  // { label: "Campo Muslim", mun_code: "153615" },
  // { label: "China Town (Pob.)", mun_code: "153615" },
  // { label: "Corahab", mun_code: "153615" },
  // { label: "Diamaro", mun_code: "153615" },
  // { label: "Inandayan", mun_code: "153615" },
  // { label: "Cabasaran (South)", mun_code: "153615" },
  // { label: "Calumbog", mun_code: "153615" },
  // { label: "Lamin", mun_code: "153615" },
  // { label: "Mable", mun_code: "153615" },
  // { label: "Mananayo", mun_code: "153615" },
  // { label: "Manggahan", mun_code: "153615" },
  // { label: "Masao", mun_code: "153615" },
  // { label: "Montay", mun_code: "153615" },
  // { label: "Pasir", mun_code: "153615" },
  // { label: "Rebocun", mun_code: "153615" },
  // { label: "Sarang", mun_code: "153615" },
  // { label: "Tacub", mun_code: "153615" },
  // { label: "Tambara", mun_code: "153615" },
  // { label: "Tiongcop", mun_code: "153615" },
  // { label: "Tuboc", mun_code: "153615" },
  // { label: "Matampay", mun_code: "153615" },
  // { label: "Calibagat", mun_code: "153615" },
  // { label: "Jose Abad Santos", mun_code: "153615" },
  // { label: "Macuranding", mun_code: "153615" },
  // { label: "Matalin", mun_code: "153615" },
  // { label: "Matling", mun_code: "153615" },
  // { label: "Pialot", mun_code: "153615" },
  // { label: "Sumbagarogong", mun_code: "153615" },
  // { label: "Banday", mun_code: "153615" },
  // { label: "Bunk House", mun_code: "153615" },
  // { label: "Madaya", mun_code: "153615" },
  // { label: "Bacayawan", mun_code: "153616" },
  // { label: "Cawayan Bacolod", mun_code: "153616" },
  // { label: "Bacong", mun_code: "153616" },
  // { label: "Camalig Bandara Ingud", mun_code: "153616" },
  // { label: "Camalig Bubong", mun_code: "153616" },
  // { label: "Camalig (Pob.)", mun_code: "153616" },
  // { label: "Inudaran Campong", mun_code: "153616" },
  // { label: "Cawayan", mun_code: "153616" },
  // { label: "Daanaingud", mun_code: "153616" },
  // { label: "Cawayan Kalaw", mun_code: "153616" },
  // { label: "Kialdan", mun_code: "153616" },
  // { label: "Lumbac Kialdan", mun_code: "153616" },
  // { label: "Cawayan Linuk", mun_code: "153616" },
  // { label: "Lubo", mun_code: "153616" },
  // { label: "Inudaran Lumbac", mun_code: "153616" },
  // { label: "Mantapoli", mun_code: "153616" },
  // { label: "Matampay", mun_code: "153616" },
  // { label: "Maul", mun_code: "153616" },
  // { label: "Nataron", mun_code: "153616" },
  // { label: "Pagalongan Bacayawan", mun_code: "153616" },
  // { label: "Pataimas", mun_code: "153616" },
  // { label: "Poona Marantao", mun_code: "153616" },
  // { label: "Punud Proper", mun_code: "153616" },
  // { label: "Tacub", mun_code: "153616" },
  // { label: "Maul Ilian", mun_code: "153616" },
  // { label: "Palao", mun_code: "153616" },
  // { label: "Banga-Pantar", mun_code: "153616" },
  // { label: "Batal-Punud", mun_code: "153616" },
  // { label: "Bubong Madanding (Bubong)", mun_code: "153616" },
  // { label: "Ilian", mun_code: "153616" },
  // { label: "Inudaran Loway", mun_code: "153616" },
  // { label: "Maul Lumbaca Ingud", mun_code: "153616" },
  // { label: "Poblacion", mun_code: "153616" },
  // { label: "Tuca Kialdan", mun_code: "153616" },
  // { label: "Ambolong", mun_code: "153617" },
  // { label: "Bacolod Chico Proper", mun_code: "153617" },
  // { label: "Banga", mun_code: "153617" },
  // { label: "Bangco", mun_code: "153617" },
  // { label: "Banggolo Poblacion", mun_code: "153617" },
  // { label: "Bangon", mun_code: "153617" },
  // { label: "Beyaba-Damag", mun_code: "153617" },
  // { label: "Bito Buadi Itowa", mun_code: "153617" },
  // { label: "Bito Buadi Parba", mun_code: "153617" },
  // { label: "Bubonga Pagalamatan", mun_code: "153617" },
  // { label: "Bubonga Lilod Madaya", mun_code: "153617" },
  // { label: "Boganga", mun_code: "153617" },
  // { label: "Boto Ambolong", mun_code: "153617" },
  // { label: "Bubonga Cadayonan", mun_code: "153617" },
  // { label: "Bubong Lumbac", mun_code: "153617" },
  // { label: "Bubonga Marawi", mun_code: "153617" },
  // { label: "Bubonga Punod", mun_code: "153617" },
  // { label: "Cabasaran", mun_code: "153617" },
  // { label: "Cabingan", mun_code: "153617" },
  // { label: "Cadayonan", mun_code: "153617" },
  // { label: "Cadayonan I", mun_code: "153617" },
  // { label: "Calocan East", mun_code: "153617" },
  // { label: "Calocan West", mun_code: "153617" },
  // { label: "Kormatan Matampay", mun_code: "153617" },
  // { label: "Daguduban", mun_code: "153617" },
  // { label: "Dansalan", mun_code: "153617" },
  // { label: "Datu Sa Dansalan", mun_code: "153617" },
  // { label: "Dayawan", mun_code: "153617" },
  // { label: "Dimaluna", mun_code: "153617" },
  // { label: "Dulay", mun_code: "153617" },
  // { label: "Dulay West", mun_code: "153617" },
  // { label: "East Basak", mun_code: "153617" },
  // { label: "Emie Punud", mun_code: "153617" },
  // { label: "Fort", mun_code: "153617" },
  // { label: "Gadongan", mun_code: "153617" },
  // { label: "Buadi Sacayo (Green)", mun_code: "153617" },
  // { label: "Guimba (Lilod Proper)", mun_code: "153617" },
  // { label: "Kapantaran", mun_code: "153617" },
  // { label: "Kilala", mun_code: "153617" },
  // { label: "Lilod Madaya (Pob.)", mun_code: "153617" },
  // { label: "Lilod Saduc", mun_code: "153617" },
  // { label: "Lomidong", mun_code: "153617" },
  // { label: "Lumbaca Madaya (Pob.)", mun_code: "153617" },
  // { label: "Lumbac Marinaut", mun_code: "153617" },
  // { label: "Lumbaca Toros", mun_code: "153617" },
  // { label: "Malimono", mun_code: "153617" },
  // { label: "Basak Malutlut", mun_code: "153617" },
  // { label: "Gadongan Mapantao", mun_code: "153617" },
  // { label: "Amito Marantao", mun_code: "153617" },
  // { label: "Marinaut East", mun_code: "153617" },
  // { label: "Marinaut West", mun_code: "153617" },
  // { label: "Matampay", mun_code: "153617" },
  // { label: "Pantaon (Langcaf)", mun_code: "153617" },
  // { label: "Mipaga Proper", mun_code: "153617" },
  // { label: "Moncado Colony", mun_code: "153617" },
  // { label: "Moncado Kadingilan", mun_code: "153617" },
  // { label: "Moriatao Loksadato", mun_code: "153617" },
  // { label: "Datu Naga", mun_code: "153617" },
  // { label: "Navarro (Datu Saber)", mun_code: "153617" },
  // { label: "Olawa Ambolong", mun_code: "153617" },
  // { label: "Pagalamatan Gambai", mun_code: "153617" },
  // { label: "Pagayawan", mun_code: "153617" },
  // { label: "Panggao Saduc", mun_code: "153617" },
  // { label: "Papandayan", mun_code: "153617" },
  // { label: "Paridi", mun_code: "153617" },
  // { label: "Patani", mun_code: "153617" },
  // { label: "Pindolonan", mun_code: "153617" },
  // { label: "Poona Marantao", mun_code: "153617" },
  // { label: "Pugaan", mun_code: "153617" },
  // { label: "Rapasun MSU", mun_code: "153617" },
  // { label: "Raya Madaya I", mun_code: "153617" },
  // { label: "Raya Madaya II", mun_code: "153617" },
  // { label: "Raya Saduc", mun_code: "153617" },
  // { label: "Rorogagus Proper", mun_code: "153617" },
  // { label: "Rorogagus East", mun_code: "153617" },
  // { label: "Sabala Manao", mun_code: "153617" },
  // { label: "Sabala Manao Proper", mun_code: "153617" },
  // { label: "Saduc Proper", mun_code: "153617" },
  // { label: "Sagonsongan", mun_code: "153617" },
  // { label: "Sangcay Dansalan", mun_code: "153617" },
  // { label: "Somiorang", mun_code: "153617" },
  // { label: "South Madaya Proper", mun_code: "153617" },
  // { label: "Sugod Proper", mun_code: "153617" },
  // { label: "Tampilong", mun_code: "153617" },
  // { label: "Timbangalan", mun_code: "153617" },
  // { label: "Tuca Ambolong", mun_code: "153617" },
  // { label: "Tolali", mun_code: "153617" },
  // { label: "Toros", mun_code: "153617" },
  // { label: "Tuca", mun_code: "153617" },
  // { label: "Tuca Marinaut", mun_code: "153617" },
  // { label: "Tongantongan-Tuca Timbangalan", mun_code: "153617" },
  // { label: "Wawalayan Calocan", mun_code: "153617" },
  // { label: "Wawalayan Marinaut", mun_code: "153617" },
  // { label: "Marawi Poblacion", mun_code: "153617" },
  // { label: "Norhaya Village", mun_code: "153617" },
  // { label: "Papandayan Caniogan", mun_code: "153617" },
  // { label: "Abdullah Buisan", mun_code: "153618" },
  // { label: "Caramian Alim Raya", mun_code: "153618" },
  // { label: "Alip Lalabuan", mun_code: "153618" },
  // { label: "Alumpang Paino Mimbalay", mun_code: "153618" },
  // { label: "Mai Ditimbang Balindong", mun_code: "153618" },
  // { label: "Mai Sindaoloan Dansalan", mun_code: "153618" },
  // { label: "Lakadun", mun_code: "153618" },
  // { label: "Maranat Bontalis", mun_code: "153618" },
  // { label: "Dalog Balut", mun_code: "153618" },
  // { label: "Gindolongan Alabat", mun_code: "153618" },
  // { label: "Gondarangin Asa Adigao", mun_code: "153618" },
  // { label: "Sawir", mun_code: "153618" },
  // { label: "Moriatao-Bai Labay", mun_code: "153618" },
  // { label: "Laila Lumbac Bacon", mun_code: "153618" },
  // { label: "Lanco Dimapatoy", mun_code: "153618" },
  // { label: "Talub Langi", mun_code: "153618" },
  // { label: "Lomigis Sucod", mun_code: "153618" },
  // { label: "Macabangan Imbala", mun_code: "153618" },
  // { label: "Macadaag Talaguian", mun_code: "153618" },
  // { label: "Macalupang Lumbac Caramian", mun_code: "153618" },
  // { label: "Magayo Bagoaingud", mun_code: "153618" },
  // { label: "Macompara Apa Mimbalay", mun_code: "153618" },
  // { label: "Manalocon Talub", mun_code: "153618" },
  // { label: "Putad Marandang Buisan", mun_code: "153618" },
  // { label: "Matao Araza", mun_code: "153618" },
  // { label: "Mocamad Tangul", mun_code: "153618" },
  // { label: "Pantao", mun_code: "153618" },
  // { label: "Sambowang Atawa", mun_code: "153618" },
  // { label: "Tamboro Cormatan", mun_code: "153618" },
  // { label: "Towanao Arangga", mun_code: "153618" },
  // { label: "Tomambiling Lumbaca Ingud", mun_code: "153618" },
  // { label: "Unda Dayawan", mun_code: "153618" },
  // { label: "Buadi Amloy", mun_code: "153618" },
  // { label: "Kalilangan", mun_code: "153618" },
  // { label: "Lumbaca Ingud", mun_code: "153618" },
  // { label: "Bangon", mun_code: "153619" },
  // { label: "Bubonga Guilopa", mun_code: "153619" },
  // { label: "Buadi-Abala", mun_code: "153619" },
  // { label: "Buadi-Bayawa", mun_code: "153619" },
  // { label: "Buadi-Insuba", mun_code: "153619" },
  // { label: "Bubong", mun_code: "153619" },
  // { label: "Cabasaran", mun_code: "153619" },
  // { label: "Cairatan", mun_code: "153619" },
  // { label: "Cormatan", mun_code: "153619" },
  // { label: "Poblacion (Dado)", mun_code: "153619" },
  // { label: "Dalama", mun_code: "153619" },
  // { label: "Dansalan", mun_code: "153619" },
  // { label: "Dimarao", mun_code: "153619" },
  // { label: "Guilopa", mun_code: "153619" },
  // { label: "Ilian", mun_code: "153619" },
  // { label: "Kitambugun", mun_code: "153619" },
  // { label: "Lama (Bagoaingud)", mun_code: "153619" },
  // { label: "Lilod", mun_code: "153619" },
  // { label: "Lilod Raybalai", mun_code: "153619" },
  // { label: "Lumbaca Ingud", mun_code: "153619" },
  // { label: "Lumbac (Lumbac Bubong)", mun_code: "153619" },
  // { label: "Madaya", mun_code: "153619" },
  // { label: "Pindolonan", mun_code: "153619" },
  // { label: "Salipongan", mun_code: "153619" },
  // { label: "Sugan", mun_code: "153619" },
  // { label: "Bagoaingud", mun_code: "153619" },
  // { label: "Ayong", mun_code: "153620" },
  // { label: "Bandara Ingud", mun_code: "153620" },
  // { label: "Bangon (Pob.)", mun_code: "153620" },
  // { label: "Biala-an", mun_code: "153620" },
  // { label: "Diampaca", mun_code: "153620" },
  // { label: "Guiarong", mun_code: "153620" },
  // { label: "Ilian", mun_code: "153620" },
  // { label: "Madang", mun_code: "153620" },
  // { label: "Mapantao", mun_code: "153620" },
  // { label: "Ngingir (Kabasaran)", mun_code: "153620" },
  // { label: "Padas", mun_code: "153620" },
  // { label: "Paigoay", mun_code: "153620" },
  // { label: "Pinalangca", mun_code: "153620" },
  // { label: "Poblacion (Lumbac)", mun_code: "153620" },
  // { label: "Rangiran", mun_code: "153620" },
  // { label: "Rubokun", mun_code: "153620" },
  // { label: "Linindingan", mun_code: "153620" },
  // { label: "Kalaludan", mun_code: "153620" },
  // { label: "Aposong", mun_code: "153621" },
  // { label: "Bagoaingud", mun_code: "153621" },
  // { label: "Bangco (Pob.)", mun_code: "153621" },
  // { label: "Bansayan", mun_code: "153621" },
  // { label: "Basak", mun_code: "153621" },
  // { label: "Bobo", mun_code: "153621" },
  // { label: "Bubong Tawa-an", mun_code: "153621" },
  // { label: "Bubonga Mamaanun", mun_code: "153621" },
  // { label: "Bualan", mun_code: "153621" },
  // { label: "Bubong Ilian", mun_code: "153621" },
  // { label: "Gacap", mun_code: "153621" },
  // { label: "Katumbacan", mun_code: "153621" },
  // { label: "Ilian Poblacion", mun_code: "153621" },
  // { label: "Kalanganan", mun_code: "153621" },
  // { label: "Lininding", mun_code: "153621" },
  // { label: "Lumbaca Mamaan", mun_code: "153621" },
  // { label: "Mamaanun", mun_code: "153621" },
  // { label: "Mentring", mun_code: "153621" },
  // { label: "Olango", mun_code: "153621" },
  // { label: "Palacat", mun_code: "153621" },
  // { label: "Palao", mun_code: "153621" },
  // { label: "Paling", mun_code: "153621" },
  // { label: "Pantaon", mun_code: "153621" },
  // { label: "Pantar", mun_code: "153621" },
  // { label: "Paridi", mun_code: "153621" },
  // { label: "Pindolonan", mun_code: "153621" },
  // { label: "Radapan", mun_code: "153621" },
  // { label: "Radapan Poblacion", mun_code: "153621" },
  // { label: "Sapingit", mun_code: "153621" },
  // { label: "Talao", mun_code: "153621" },
  // { label: "Tambo", mun_code: "153621" },
  // { label: "Tapocan", mun_code: "153621" },
  // { label: "Taporug", mun_code: "153621" },
  // { label: "Tawaan", mun_code: "153621" },
  // { label: "Udalo", mun_code: "153621" },
  // { label: "Rantian", mun_code: "153621" },
  // { label: "Ilian", mun_code: "153621" },
  // { label: "Ataragadong", mun_code: "153622" },
  // { label: "Bangon", mun_code: "153622" },
  // { label: "Bansayan", mun_code: "153622" },
  // { label: "Bubong-Dimunda", mun_code: "153622" },
  // { label: "Bugaran", mun_code: "153622" },
  // { label: "Bualan", mun_code: "153622" },
  // { label: "Cadayonan", mun_code: "153622" },
  // { label: "Calilangan Dicala", mun_code: "153622" },
  // { label: "Calupaan", mun_code: "153622" },
  // { label: "Dimayon", mun_code: "153622" },
  // { label: "Dilausan", mun_code: "153622" },
  // { label: "Dongcoan", mun_code: "153622" },
  // { label: "Gadongan", mun_code: "153622" },
  // { label: "Poblacion (Gata Proper)", mun_code: "153622" },
  // { label: "Liangan", mun_code: "153622" },
  // { label: "Lumbac", mun_code: "153622" },
  // { label: "Lumbaca Ingud", mun_code: "153622" },
  // { label: "Madanding", mun_code: "153622" },
  // { label: "Pantao", mun_code: "153622" },
  // { label: "Punud", mun_code: "153622" },
  // { label: "Ragayan", mun_code: "153622" },
  // { label: "Rogan Cairan", mun_code: "153622" },
  // { label: "Talaguian", mun_code: "153622" },
  // { label: "Rogan Tandiong Dimayon", mun_code: "153622" },
  // { label: "Taporog", mun_code: "153622" },
  // { label: "Badak", mun_code: "153623" },
  // { label: "Bantayan", mun_code: "153623" },
  // { label: "Basagad", mun_code: "153623" },
  // { label: "Bolinsong", mun_code: "153623" },
  // { label: "Boring", mun_code: "153623" },
  // { label: "Bualan", mun_code: "153623" },
  // { label: "Danugan", mun_code: "153623" },
  // { label: "Dapao", mun_code: "153623" },
  // { label: "Diamla", mun_code: "153623" },
  // { label: "Gadongan", mun_code: "153623" },
  // { label: "Ingud", mun_code: "153623" },
  // { label: "Linuk", mun_code: "153623" },
  // { label: "Lumbac", mun_code: "153623" },
  // { label: "Maligo", mun_code: "153623" },
  // { label: "Masao", mun_code: "153623" },
  // { label: "Notong", mun_code: "153623" },
  // { label: "Porug", mun_code: "153623" },
  // { label: "Romagondong", mun_code: "153623" },
  // { label: "Tambo (Pob.)", mun_code: "153623" },
  // { label: "Tamlang", mun_code: "153623" },
  // { label: "Tuka", mun_code: "153623" },
  // { label: "Tomarompong", mun_code: "153623" },
  // { label: "Yaran", mun_code: "153623" },
  // { label: "Bagoaingud", mun_code: "153624" },
  // { label: "Barimbingan", mun_code: "153624" },
  // { label: "Bayabao", mun_code: "153624" },
  // { label: "Buadi Babai", mun_code: "153624" },
  // { label: "Buadi Alao", mun_code: "153624" },
  // { label: "Buadi Oloc", mun_code: "153624" },
  // { label: "Pagalongan Buadiadingan", mun_code: "153624" },
  // { label: "Dado", mun_code: "153624" },
  // { label: "Dangimprampiai", mun_code: "153624" },
  // { label: "Darimbang", mun_code: "153624" },
  // { label: "Dilausan", mun_code: "153624" },
  // { label: "Ditsaan", mun_code: "153624" },
  // { label: "Gadongan", mun_code: "153624" },
  // { label: "Pagalongan Ginaopan", mun_code: "153624" },
  // { label: "Baclayan Lilod", mun_code: "153624" },
  // { label: "Linamon", mun_code: "153624" },
  // { label: "Lumbatan Ramain", mun_code: "153624" },
  // { label: "Buayaan Madanding", mun_code: "153624" },
  // { label: "Maindig Ditsaan", mun_code: "153624" },
  // { label: "Mandara", mun_code: "153624" },
  // { label: "Maranao Timber (Dalama)", mun_code: "153624" },
  // { label: "Pagalongan Proper", mun_code: "153624" },
  // { label: "Polo", mun_code: "153624" },
  // { label: "Ramain Poblacion", mun_code: "153624" },
  // { label: "Ramain Proper", mun_code: "153624" },
  // { label: "Baclayan Raya", mun_code: "153624" },
  // { label: "Buayaan Raya", mun_code: "153624" },
  // { label: "Rantian", mun_code: "153624" },
  // { label: "Sundiga Bayabao", mun_code: "153624" },
  // { label: "Talub", mun_code: "153624" },
  // { label: "Buayaan Lilod", mun_code: "153624" },
  // { label: "Bubong Dangiprampiai", mun_code: "153624" },
  // { label: "Pagalongan Masioon", mun_code: "153624" },
  // { label: "Sultan Pangadapun", mun_code: "153624" },
  // { label: "Upper Pugaan", mun_code: "153624" },
  // { label: "Alinun", mun_code: "153625" },
  // { label: "Bagoaingud", mun_code: "153625" },
  // { label: "Batangan", mun_code: "153625" },
  // { label: "Cadayon", mun_code: "153625" },
  // { label: "Cadingilan", mun_code: "153625" },
  // { label: "Lumbac Toros", mun_code: "153625" },
  // { label: "Comonal", mun_code: "153625" },
  // { label: "Dilausan", mun_code: "153625" },
  // { label: "Gadongan", mun_code: "153625" },
  // { label: "Linao", mun_code: "153625" },
  // { label: "Limogao", mun_code: "153625" },
  // { label: "Lumbayanague", mun_code: "153625" },
  // { label: "Basak Maito", mun_code: "153625" },
  // { label: "Maliwanag", mun_code: "153625" },
  // { label: "Mapantao", mun_code: "153625" },
  // { label: "Mipaga", mun_code: "153625" },
  // { label: "Natangcopan", mun_code: "153625" },
  // { label: "Pagalamatan", mun_code: "153625" },
  // { label: "Pamacotan", mun_code: "153625" },
  // { label: "Panggao", mun_code: "153625" },
  // { label: "Pantao Raya", mun_code: "153625" },
  // { label: "Pantaon", mun_code: "153625" },
  // { label: "Patpangkat", mun_code: "153625" },
  // { label: "Pawak", mun_code: "153625" },
  // { label: "Dilimbayan", mun_code: "153625" },
  // { label: "Pindolonan", mun_code: "153625" },
  // { label: "Poblacion", mun_code: "153625" },
  // { label: "Salocad", mun_code: "153625" },
  // { label: "Sungcod", mun_code: "153625" },
  // { label: "Bubong", mun_code: "153625" },
  // { label: "Bocalan", mun_code: "153626" },
  // { label: "Bangon", mun_code: "153626" },
  // { label: "Cabasaran", mun_code: "153626" },
  // { label: "Dilausan", mun_code: "153626" },
  // { label: "Lalabuan", mun_code: "153626" },
  // { label: "Lilod Tamparan", mun_code: "153626" },
  // { label: "Lindongan", mun_code: "153626" },
  // { label: "Linuk", mun_code: "153626" },
  // { label: "Occidental Linuk", mun_code: "153626" },
  // { label: "Linuk Oriental", mun_code: "153626" },
  // { label: "Lumbaca Ingud", mun_code: "153626" },
  // { label: "Lumbacaingud South", mun_code: "153626" },
  // { label: "Lumbaca Lilod", mun_code: "153626" },
  // { label: "Balutmadiar", mun_code: "153626" },
  // { label: "Mala-abangon", mun_code: "153626" },
  // { label: "Maliwanag", mun_code: "153626" },
  // { label: "Maidan Linuk", mun_code: "153626" },
  // { label: "Miondas", mun_code: "153626" },
  // { label: "New Lumbacaingud", mun_code: "153626" },
  // { label: "Pimbago-Pagalongan", mun_code: "153626" },
  // { label: "Pagayawan", mun_code: "153626" },
  // { label: "Picarabawan", mun_code: "153626" },
  // { label: "Poblacion I", mun_code: "153626" },
  // { label: "Poblacion II", mun_code: "153626" },
  // { label: "Poblacion III", mun_code: "153626" },
  // { label: "Poblacion IV", mun_code: "153626" },
  // { label: "Raya Niondas", mun_code: "153626" },
  // { label: "Raya Buadi Barao", mun_code: "153626" },
  // { label: "Raya Tamparan", mun_code: "153626" },
  // { label: "Salongabanding", mun_code: "153626" },
  // { label: "Saminunggay", mun_code: "153626" },
  // { label: "Talub", mun_code: "153626" },
  // { label: "Tatayawan North", mun_code: "153626" },
  // { label: "Tatayawan South", mun_code: "153626" },
  // { label: "Tubok", mun_code: "153626" },
  // { label: "Beruar", mun_code: "153626" },
  // { label: "Dasomalong", mun_code: "153626" },
  // { label: "Ginaopan", mun_code: "153626" },
  // { label: "Lumbac", mun_code: "153626" },
  // { label: "Minanga", mun_code: "153626" },
  // { label: "Lilod Tubok", mun_code: "153626" },
  // { label: "Mariatao Datu", mun_code: "153626" },
  // { label: "Pagalamatan Linuk", mun_code: "153626" },
  // { label: "Pindolonan Mariatao Sarip", mun_code: "153626" },
  // { label: "Bandera Buisan", mun_code: "153627" },
  // { label: "Boriongan", mun_code: "153627" },
  // { label: "Borowa", mun_code: "153627" },
  // { label: "Buadi Dingun", mun_code: "153627" },
  // { label: "Buadi Amao", mun_code: "153627" },
  // { label: "Buadi Amunta", mun_code: "153627" },
  // { label: "Buadi Arorao", mun_code: "153627" },
  // { label: "Buadi Atopa", mun_code: "153627" },
  // { label: "Buadi Dayomangga", mun_code: "153627" },
  // { label: "Buadi Ongcalo", mun_code: "153627" },
  // { label: "Bucalan", mun_code: "153627" },
  // { label: "Cadayonan Bagumbayan", mun_code: "153627" },
  // { label: "Caramat", mun_code: "153627" },
  // { label: "Carandangan Calopaan", mun_code: "153627" },
  // { label: "Datu Ma-as", mun_code: "153627" },
  // { label: "Dilabayan", mun_code: "153627" },
  // { label: "Dimayon", mun_code: "153627" },
  // { label: "Gapao Balindong", mun_code: "153627" },
  // { label: "Ilian", mun_code: "153627" },
  // { label: "Lumasa", mun_code: "153627" },
  // { label: "Lumbac Bagoaingud", mun_code: "153627" },
  // { label: "Lumbac Bubong Maindang", mun_code: "153627" },
  // { label: "Lumbac Pitakus", mun_code: "153627" },
  // { label: "Malungun", mun_code: "153627" },
  // { label: "Maruhom", mun_code: "153627" },
  // { label: "Masolun", mun_code: "153627" },
  // { label: "Moriatao Loksa Datu", mun_code: "153627" },
  // { label: "Pagalamatan", mun_code: "153627" },
  // { label: "Pindolonan", mun_code: "153627" },
  // { label: "Pitakus", mun_code: "153627" },
  // { label: "Ririk", mun_code: "153627" },
  // { label: "Salipongan", mun_code: "153627" },
  // { label: "Lumasa Proper (Salvador Concha)", mun_code: "153627" },
  // { label: "Sambolawan", mun_code: "153627" },
  // { label: "Samporna Salamatollah", mun_code: "153627" },
  // { label: "Somiorang Bandingun", mun_code: "153627" },
  // { label: "Sigayan Proper", mun_code: "153627" },
  // { label: "Sunggod", mun_code: "153627" },
  // { label: "Sunding", mun_code: "153627" },
  // { label: "Supangan", mun_code: "153627" },
  // { label: "Tupa-an Buadiatupa", mun_code: "153627" },
  // { label: "Buadi Amunud", mun_code: "153627" },
  // { label: "Mangayao", mun_code: "153627" },
  // { label: "Alog", mun_code: "153628" },
  // { label: "Beta", mun_code: "153628" },
  // { label: "Poblacion (Buribid)", mun_code: "153628" },
  // { label: "Campo", mun_code: "153628" },
  // { label: "Datumanong", mun_code: "153628" },
  // { label: "Dinaigan", mun_code: "153628" },
  // { label: "Guiarong", mun_code: "153628" },
  // { label: "Mindamudag", mun_code: "153628" },
  // { label: "Paigoay-Pimbataan", mun_code: "153628" },
  // { label: "Polo", mun_code: "153628" },
  // { label: "Riantaran", mun_code: "153628" },
  // { label: "Tangcal", mun_code: "153628" },
  // { label: "Tubaran Proper", mun_code: "153628" },
  // { label: "Wago", mun_code: "153628" },
  // { label: "Bagiangun", mun_code: "153628" },
  // { label: "Gadongan", mun_code: "153628" },
  // { label: "Gaput", mun_code: "153628" },
  // { label: "Madaya", mun_code: "153628" },
  // { label: "Malaganding", mun_code: "153628" },
  // { label: "Metadicop", mun_code: "153628" },
  // { label: "Pagalamatan", mun_code: "153628" },
  // { label: "Bagoaingud", mun_code: "153629" },
  // { label: "Bubong", mun_code: "153629" },
  // { label: "Buadi Alawang", mun_code: "153629" },
  // { label: "Buadi Dico", mun_code: "153629" },
  // { label: "Campong Talao", mun_code: "153629" },
  // { label: "Cayagan", mun_code: "153629" },
  // { label: "Dandanun", mun_code: "153629" },
  // { label: "Dilimbayan", mun_code: "153629" },
  // { label: "Gurain", mun_code: "153629" },
  // { label: "Poblacion (Ingud)", mun_code: "153629" },
  // { label: "Lumbac", mun_code: "153629" },
  // { label: "Maidan", mun_code: "153629" },
  // { label: "Mapantao", mun_code: "153629" },
  // { label: "Pagalamatan", mun_code: "153629" },
  // { label: "Pandiaranao", mun_code: "153629" },
  // { label: "Pindolonan I", mun_code: "153629" },
  // { label: "Pindolonan II", mun_code: "153629" },
  // { label: "Putad", mun_code: "153629" },
  // { label: "Raya", mun_code: "153629" },
  // { label: "Sugod I", mun_code: "153629" },
  // { label: "Sugod Mawatan", mun_code: "153629" },
  // { label: "Sumbaga Rogong", mun_code: "153629" },
  // { label: "Tangcal", mun_code: "153629" },
  // { label: "Amoyong", mun_code: "153630" },
  // { label: "Balatin", mun_code: "153630" },
  // { label: "Banga", mun_code: "153630" },
  // { label: "Buntongun", mun_code: "153630" },
  // { label: "Bo-ot", mun_code: "153630" },
  // { label: "Cebuano Group", mun_code: "153630" },
  // { label: "Christian Village", mun_code: "153630" },
  // { label: "Eastern Wao", mun_code: "153630" },
  // { label: "Extension Poblacion", mun_code: "153630" },
  // { label: "Gata", mun_code: "153630" },
  // { label: "Kabatangan", mun_code: "153630" },
  // { label: "Kadingilan", mun_code: "153630" },
  // { label: "Katutungan (Pob.)", mun_code: "153630" },
  // { label: "Kilikili East", mun_code: "153630" },
  // { label: "Kilikili West", mun_code: "153630" },
  // { label: "Malaigang", mun_code: "153630" },
  // { label: "Manila Group", mun_code: "153630" },
  // { label: "Milaya", mun_code: "153630" },
  // { label: "Mimbuaya", mun_code: "153630" },
  // { label: "Muslim Village", mun_code: "153630" },
  // { label: "Pagalongan", mun_code: "153630" },
  // { label: "Panang", mun_code: "153630" },
  // { label: "Park Area (Pob.)", mun_code: "153630" },
  // { label: "Pilintangan", mun_code: "153630" },
  // { label: "Serran Village", mun_code: "153630" },
  // { label: "Western Wao (Pob.)", mun_code: "153630" },
  // { label: "Balut", mun_code: "153631" },
  // { label: "Bagumbayan", mun_code: "153631" },
  // { label: "Bitayan", mun_code: "153631" },
  // { label: "Bolawan", mun_code: "153631" },
  // { label: "Bonga", mun_code: "153631" },
  // { label: "Cabasaran", mun_code: "153631" },
  // { label: "Cahera", mun_code: "153631" },
  // { label: "Cairantang", mun_code: "153631" },
  // { label: "Canibongan", mun_code: "153631" },
  // { label: "Diragun", mun_code: "153631" },
  // { label: "Mantailoco", mun_code: "153631" },
  // { label: "Mapantao", mun_code: "153631" },
  // { label: "Marogong East", mun_code: "153631" },
  // { label: "Marogong Proper (Pob.)", mun_code: "153631" },
  // { label: "Mayaman", mun_code: "153631" },
  // { label: "Pabrica", mun_code: "153631" },
  // { label: "Paigoay Coda", mun_code: "153631" },
  // { label: "Pasayanan", mun_code: "153631" },
  // { label: "Piangologan", mun_code: "153631" },
  // { label: "Puracan", mun_code: "153631" },
  // { label: "Romagondong", mun_code: "153631" },
  // { label: "Sarang", mun_code: "153631" },
  // { label: "Cadayonan", mun_code: "153631" },
  // { label: "Calumbog", mun_code: "153631" },
  // { label: "Bubonga Ranao", mun_code: "153632" },
  // { label: "Calalaoan (Pob.)", mun_code: "153632" },
  // { label: "Gas", mun_code: "153632" },
  // { label: "Inudaran", mun_code: "153632" },
  // { label: "Inoma", mun_code: "153632" },
  // { label: "Luguna", mun_code: "153632" },
  // { label: "Mimbalawag", mun_code: "153632" },
  // { label: "Ngingir", mun_code: "153632" },
  // { label: "Pagalongan", mun_code: "153632" },
  // { label: "Panggawalupa", mun_code: "153632" },
  // { label: "Pantaon", mun_code: "153632" },
  // { label: "Piksan", mun_code: "153632" },
  // { label: "Pindolonan", mun_code: "153632" },
  // { label: "Punud", mun_code: "153632" },
  // { label: "Tagoranao", mun_code: "153632" },
  // { label: "Taliboboka", mun_code: "153632" },
  // { label: "Tambac", mun_code: "153632" },
  // { label: "Bacolod", mun_code: "153633" },
  // { label: "Bangon", mun_code: "153633" },
  // { label: "Buadiposo Lilod", mun_code: "153633" },
  // { label: "Buadiposo Proper", mun_code: "153633" },
  // { label: "Bubong", mun_code: "153633" },
  // { label: "Buntong Proper", mun_code: "153633" },
  // { label: "Cadayonan", mun_code: "153633" },
  // { label: "Dansalan", mun_code: "153633" },
  // { label: "Gata", mun_code: "153633" },
  // { label: "Kalakala", mun_code: "153633" },
  // { label: "Katogonan", mun_code: "153633" },
  // { label: "Lumbac", mun_code: "153633" },
  // { label: "Lumbatan Manacab", mun_code: "153633" },
  // { label: "Lumbatan Pataingud", mun_code: "153633" },
  // { label: "Manacab (Pob.)", mun_code: "153633" },
  // { label: "Minanga (Buntong)", mun_code: "153633" },
  // { label: "Paling", mun_code: "153633" },
  // { label: "Pindolonan", mun_code: "153633" },
  // { label: "Pualas", mun_code: "153633" },
  // { label: "Buadiposo Raya", mun_code: "153633" },
  // { label: "Sapot", mun_code: "153633" },
  // { label: "Tangcal", mun_code: "153633" },
  // { label: "Tarik", mun_code: "153633" },
  // { label: "Tuka", mun_code: "153633" },
  // { label: "Bangon Proper", mun_code: "153633" },
  // { label: "Raya Buntong (Buntong East)", mun_code: "153633" },
  // { label: "Lunduban (Ragondingan)", mun_code: "153633" },
  // { label: "Ragondingan East", mun_code: "153633" },
  // { label: "Ragondingan Proper", mun_code: "153633" },
  // { label: "Ragondingan West", mun_code: "153633" },
  // { label: "Boto Ragondingan", mun_code: "153633" },
  // { label: "Datu Tambara", mun_code: "153633" },
  // { label: "Dirisan", mun_code: "153633" },
  // { label: "Agagan", mun_code: "153634" },
  // { label: "Balagunun", mun_code: "153634" },
  // { label: "Bolao", mun_code: "153634" },
  // { label: "Balawag", mun_code: "153634" },
  // { label: "Balintao", mun_code: "153634" },
  // { label: "Borocot", mun_code: "153634" },
  // { label: "Bato-bato", mun_code: "153634" },
  // { label: "Borrowa", mun_code: "153634" },
  // { label: "Buadiangkay", mun_code: "153634" },
  // { label: "Bubong Bayabao", mun_code: "153634" },
  // { label: "Botud", mun_code: "153634" },
  // { label: "Camalig", mun_code: "153634" },
  // { label: "Cambong", mun_code: "153634" },
  // { label: "Dilausan (Pob.)", mun_code: "153634" },
  // { label: "Dilimbayan", mun_code: "153634" },
  // { label: "Ilalag", mun_code: "153634" },
  // { label: "Kianodan", mun_code: "153634" },
  // { label: "Lumbac", mun_code: "153634" },
  // { label: "Madanding", mun_code: "153634" },
  // { label: "Madaya", mun_code: "153634" },
  // { label: "Maguing Proper", mun_code: "153634" },
  // { label: "Malungun", mun_code: "153634" },
  // { label: "Pagalongan", mun_code: "153634" },
  // { label: "Panayangan", mun_code: "153634" },
  // { label: "Pilimoknan", mun_code: "153634" },
  // { label: "Ragayan (Rungayan)", mun_code: "153634" },
  // { label: "Lilod Maguing", mun_code: "153634" },
  // { label: "Bubong", mun_code: "153634" },
  // { label: "Lilod Borocot", mun_code: "153634" },
  // { label: "Malungun Borocot", mun_code: "153634" },
  // { label: "Malungun Pagalongan", mun_code: "153634" },
  // { label: "Sabala Dilausan", mun_code: "153634" },
  // { label: "Lumbac-Dimarao", mun_code: "153634" },
  // { label: "Pindolonan", mun_code: "153634" },
  // { label: "Bara-as", mun_code: "153635" },
  // { label: "Biasong", mun_code: "153635" },
  // { label: "Bulangos", mun_code: "153635" },
  // { label: "Durian", mun_code: "153635" },
  // { label: "Ilian", mun_code: "153635" },
  // { label: "Liangan (Pob.)", mun_code: "153635" },
  // { label: "Maganding", mun_code: "153635" },
  // { label: "Maladi", mun_code: "153635" },
  // { label: "Mapantao", mun_code: "153635" },
  // { label: "Micalubo", mun_code: "153635" },
  // { label: "Pindolonan", mun_code: "153635" },
  // { label: "Punong", mun_code: "153635" },
  // { label: "Ramitan", mun_code: "153635" },
  // { label: "Torogan", mun_code: "153635" },
  // { label: "Tual", mun_code: "153635" },
  // { label: "Tuca", mun_code: "153635" },
  // { label: "Ubanoban", mun_code: "153635" },
  // { label: "Anas", mun_code: "153635" },
  // { label: "Mimbalawag", mun_code: "153635" },
  // { label: "Bagoaingud", mun_code: "153636" },
  // { label: "Balaigay", mun_code: "153636" },
  // { label: "Bualan", mun_code: "153636" },
  // { label: "Cadingilan", mun_code: "153636" },
  // { label: "Casalayan", mun_code: "153636" },
  // { label: "Dala (Dalama)", mun_code: "153636" },
  // { label: "Dilimbayan", mun_code: "153636" },
  // { label: "Cabuntungan", mun_code: "153636" },
  // { label: "Lamin", mun_code: "153636" },
  // { label: "Diromoyod", mun_code: "153636" },
  // { label: "Kabasaran (Pob.)", mun_code: "153636" },
  // { label: "Nanagun", mun_code: "153636" },
  // { label: "Mapantao-Balangas", mun_code: "153636" },
  // { label: "Miniros", mun_code: "153636" },
  // { label: "Pantaon", mun_code: "153636" },
  // { label: "Pindolonan", mun_code: "153636" },
  // { label: "Pitatanglan", mun_code: "153636" },
  // { label: "Poctan", mun_code: "153636" },
  // { label: "Singcara", mun_code: "153636" },
  // { label: "Wago", mun_code: "153636" },
  // { label: "Cadayonan", mun_code: "153636" },
  // { label: "Cadingilan A", mun_code: "153636" },
  // { label: "Poblacion (Apartfort)", mun_code: "153637" },
  // { label: "Bagumbayan", mun_code: "153637" },
  // { label: "Bandara-Ingud", mun_code: "153637" },
  // { label: "Comara", mun_code: "153637" },
  // { label: "Frankfort", mun_code: "153637" },
  // { label: "Lambanogan", mun_code: "153637" },
  // { label: "Lico", mun_code: "153637" },
  // { label: "Mansilano", mun_code: "153637" },
  // { label: "Natangcopan", mun_code: "153637" },
  // { label: "Pagonayan", mun_code: "153637" },
  // { label: "Pagalamatan", mun_code: "153637" },
  // { label: "Piagma", mun_code: "153637" },
  // { label: "Punud", mun_code: "153637" },
  // { label: "Ranao-Baning", mun_code: "153637" },
  // { label: "Salam", mun_code: "153637" },
  // { label: "Sagua-an", mun_code: "153637" },
  // { label: "Sumugot", mun_code: "153637" },
  // { label: "Bantalan", mun_code: "153638" },
  // { label: "Bayog", mun_code: "153638" },
  // { label: "Cadayonan", mun_code: "153638" },
  // { label: "Dagonalan", mun_code: "153638" },
  // { label: "Dimalama", mun_code: "153638" },
  // { label: "Gayakay", mun_code: "153638" },
  // { label: "Inodaran", mun_code: "153638" },
  // { label: "Kalilangan", mun_code: "153638" },
  // { label: "Kianibong", mun_code: "153638" },
  // { label: "Kingan", mun_code: "153638" },
  // { label: "Kitaon", mun_code: "153638" },
  // { label: "Bagoaingud", mun_code: "153638" },
  // { label: "Malinao", mun_code: "153638" },
  // { label: "Malingon", mun_code: "153638" },
  // { label: "Mama-an Pagalongan", mun_code: "153638" },
  // { label: "Marawi", mun_code: "153638" },
  // { label: "Maimbaguiang", mun_code: "153638" },
  // { label: "Sigayan", mun_code: "153638" },
  // { label: "Tagoloan Poblacion", mun_code: "153638" },
  // { label: "Bakikis", mun_code: "153639" },
  // { label: "Barao", mun_code: "153639" },
  // { label: "Bongabong", mun_code: "153639" },
  // { label: "Daguan", mun_code: "153639" },
  // { label: "Inudaran", mun_code: "153639" },
  // { label: "Kabaniakawan", mun_code: "153639" },
  // { label: "Kapatagan", mun_code: "153639" },
  // { label: "Lusain", mun_code: "153639" },
  // { label: "Matimos", mun_code: "153639" },
  // { label: "Minimao", mun_code: "153639" },
  // { label: "Pinantao", mun_code: "153639" },
  // { label: "Salaman", mun_code: "153639" },
  // { label: "Sigayan", mun_code: "153639" },
  // { label: "Tabuan", mun_code: "153639" },
  // { label: "Upper Igabay", mun_code: "153639" },
  // { label: "Bacayawan", mun_code: "153640" },
  // { label: "Buta (Sumalindao)", mun_code: "153640" },
  // { label: "Dinganun Guilopa (Dingunun)", mun_code: "153640" },
  // { label: "Lumbac", mun_code: "153640" },
  // { label: "Malalis", mun_code: "153640" },
  // { label: "Pagalongan", mun_code: "153640" },
  // { label: "Tagoranao", mun_code: "153640" },
  // { label: "Bangon (Dilausan)", mun_code: "153641" },
  // { label: "Beta", mun_code: "153641" },
  // { label: "Calalon", mun_code: "153641" },
  // { label: "Calipapa", mun_code: "153641" },
  // { label: "Dilausan", mun_code: "153641" },
  // { label: "Dimapaok", mun_code: "153641" },
  // { label: "Lumbac Dilausan", mun_code: "153641" },
  // { label: "Oriental Beta", mun_code: "153641" },
  // { label: "Tringun", mun_code: "153641" },
  // { label: "Dicalongan (Pob.)", mun_code: "153801" },
  // { label: "Kakal", mun_code: "153801" },
  // { label: "Kamasi", mun_code: "153801" },
  // { label: "Kapinpilan", mun_code: "153801" },
  // { label: "Kauran", mun_code: "153801" },
  // { label: "Malatimon", mun_code: "153801" },
  // { label: "Matagabong", mun_code: "153801" },
  // { label: "Saniag", mun_code: "153801" },
  // { label: "Tomicor", mun_code: "153801" },
  // { label: "Tubak", mun_code: "153801" },
  // { label: "Salman", mun_code: "153801" },
  // { label: "Ampuan", mun_code: "153802" },
  // { label: "Aratuc", mun_code: "153802" },
  // { label: "Cabayuan", mun_code: "153802" },
  // { label: "Calaan (Pob.)", mun_code: "153802" },
  // { label: "Karim", mun_code: "153802" },
  // { label: "Dinganen", mun_code: "153802" },
  // { label: "Edcor (Gallego Edcor)", mun_code: "153802" },
  // { label: "Kulimpang", mun_code: "153802" },
  // { label: "Mataya", mun_code: "153802" },
  // { label: "Minabay", mun_code: "153802" },
  // { label: "Nuyo", mun_code: "153802" },
  // { label: "Oring", mun_code: "153802" },
  // { label: "Pantawan", mun_code: "153802" },
  // { label: "Piers", mun_code: "153802" },
  // { label: "Rumidas", mun_code: "153802" },
  // { label: "Digal", mun_code: "153803" },
  // { label: "Lower Siling", mun_code: "153803" },
  // { label: "Maslabeng", mun_code: "153803" },
  // { label: "Poblacion", mun_code: "153803" },
  // { label: "Popol", mun_code: "153803" },
  // { label: "Talitay", mun_code: "153803" },
  // { label: "Upper Siling", mun_code: "153803" },
  // { label: "Alip (Pob.)", mun_code: "153805" },
  // { label: "Damawato", mun_code: "153805" },
  // { label: "Katil", mun_code: "153805" },
  // { label: "Malala", mun_code: "153805" },
  // { label: "Mangadeg", mun_code: "153805" },
  // { label: "Manindolo", mun_code: "153805" },
  // { label: "Puya", mun_code: "153805" },
  // { label: "Sepaka", mun_code: "153805" },
  // { label: "Lomoyon", mun_code: "153805" },
  // { label: "Kalumenga (Kalumanga)", mun_code: "153805" },
  // { label: "Palao sa Buto", mun_code: "153805" },
  // { label: "Damalusay", mun_code: "153805" },
  // { label: "Bonawan", mun_code: "153805" },
  // { label: "Bulod", mun_code: "153805" },
  // { label: "Datang", mun_code: "153805" },
  // { label: "Elbebe", mun_code: "153805" },
  // { label: "Lipao", mun_code: "153805" },
  // { label: "Madidis", mun_code: "153805" },
  // { label: "Makat", mun_code: "153805" },
  // { label: "Mao", mun_code: "153805" },
  // { label: "Napok", mun_code: "153805" },
  // { label: "Poblacion", mun_code: "153805" },
  // { label: "Salendab", mun_code: "153805" },
  // { label: "Alonganan", mun_code: "153806" },
  // { label: "Ambadao", mun_code: "153806" },
  // { label: "Balanakan", mun_code: "153806" },
  // { label: "Balong", mun_code: "153806" },
  // { label: "Buayan", mun_code: "153806" },
  // { label: "Dado", mun_code: "153806" },
  // { label: "Damabalas", mun_code: "153806" },
  // { label: "Duaminanga", mun_code: "153806" },
  // { label: "Kalipapa", mun_code: "153806" },
  // { label: "Liong", mun_code: "153806" },
  // { label: "Magaslong", mun_code: "153806" },
  // { label: "Masigay", mun_code: "153806" },
  // { label: "Montay", mun_code: "153806" },
  // { label: "Poblacion (Dulawan)", mun_code: "153806" },
  // { label: "Reina Regente", mun_code: "153806" },
  // { label: "Kanguan", mun_code: "153806" },
  // { label: "Ambolodto", mun_code: "153807" },
  // { label: "Awang", mun_code: "153807" },
  // { label: "Badak", mun_code: "153807" },
  // { label: "Bagoenged", mun_code: "153807" },
  // { label: "Baka", mun_code: "153807" },
  // { label: "Benolen", mun_code: "153807" },
  // { label: "Bitu", mun_code: "153807" },
  // { label: "Bongued", mun_code: "153807" },
  // { label: "Bugawas", mun_code: "153807" },
  // { label: "Capiton", mun_code: "153807" },
  // { label: "Dados", mun_code: "153807" },
  // { label: "Dalican Poblacion", mun_code: "153807" },
  // { label: "Dinaig Proper", mun_code: "153807" },
  // { label: "Dulangan", mun_code: "153807" },
  // { label: "Kakar", mun_code: "153807" },
  // { label: "Kenebeka", mun_code: "153807" },
  // { label: "Kurintem", mun_code: "153807" },
  // { label: "Kusiong", mun_code: "153807" },
  // { label: "Labungan", mun_code: "153807" },
  // { label: "Linek", mun_code: "153807" },
  // { label: "Makir", mun_code: "153807" },
  // { label: "Margues", mun_code: "153807" },
  // { label: "Nekitan", mun_code: "153807" },
  // { label: "Mompong", mun_code: "153807" },
  // { label: "Sapalan", mun_code: "153807" },
  // { label: "Semba", mun_code: "153807" },
  // { label: "Sibuto", mun_code: "153807" },
  // { label: "Sifaren (Sifaran)", mun_code: "153807" },
  // { label: "Tambak", mun_code: "153807" },
  // { label: "Tamontaka", mun_code: "153807" },
  // { label: "Tanuel", mun_code: "153807" },
  // { label: "Tapian", mun_code: "153807" },
  // { label: "Taviran", mun_code: "153807" },
  // { label: "Tenonggos", mun_code: "153807" },
  // { label: "Bagong", mun_code: "153808" },
  // { label: "Bialong", mun_code: "153808" },
  // { label: "Kuloy", mun_code: "153808" },
  // { label: "Labu-labu", mun_code: "153808" },
  // { label: "Lapok (Lepok)", mun_code: "153808" },
  // { label: "Malingao", mun_code: "153808" },
  // { label: "Poblacion", mun_code: "153808" },
  // { label: "Satan", mun_code: "153808" },
  // { label: "Tapikan", mun_code: "153808" },
  // { label: "Timbangan", mun_code: "153808" },
  // { label: "Tina", mun_code: "153808" },
  // { label: "Poblacion I", mun_code: "153808" },
  // { label: "Poblacion II", mun_code: "153808" },
  // { label: "Bayanga Norte", mun_code: "153809" },
  // { label: "Bayanga Sur", mun_code: "153809" },
  // { label: "Bugasan Norte", mun_code: "153809" },
  // { label: "Bugasan Sur (Pob.)", mun_code: "153809" },
  // { label: "Kidama", mun_code: "153809" },
  // { label: "Sapad", mun_code: "153809" },
  // { label: "Langco", mun_code: "153809" },
  // { label: "Langkong", mun_code: "153809" },
  // { label: "Bagoenged", mun_code: "153810" },
  // { label: "Buliok", mun_code: "153810" },
  // { label: "Damalasak", mun_code: "153810" },
  // { label: "Galakit", mun_code: "153810" },
  // { label: "Inug-ug", mun_code: "153810" },
  // { label: "Kalbugan", mun_code: "153810" },
  // { label: "Kilangan", mun_code: "153810" },
  // { label: "Kudal", mun_code: "153810" },
  // { label: "Layog", mun_code: "153810" },
  // { label: "Linandangan", mun_code: "153810" },
  // { label: "Poblacion", mun_code: "153810" },
  // { label: "Dalgan", mun_code: "153810" },
  // { label: "Gadungan", mun_code: "153811" },
  // { label: "Gumagadong Calawag", mun_code: "153811" },
  // { label: "Guiday T. Biruar", mun_code: "153811" },
  // { label: "Landasan (Sarmiento)", mun_code: "153811" },
  // { label: "Limbayan", mun_code: "153811" },
  // { label: "Bongo Island (Litayen)", mun_code: "153811" },
  // { label: "Magsaysay", mun_code: "153811" },
  // { label: "Making", mun_code: "153811" },
  // { label: "Nituan", mun_code: "153811" },
  // { label: "Orandang", mun_code: "153811" },
  // { label: "Pinantao", mun_code: "153811" },
  // { label: "Poblacion", mun_code: "153811" },
  // { label: "Polloc", mun_code: "153811" },
  // { label: "Tagudtongan", mun_code: "153811" },
  // { label: "Tuca-Maror", mun_code: "153811" },
  // { label: "Manion", mun_code: "153811" },
  // { label: "Macasandag", mun_code: "153811" },
  // { label: "Cotongan", mun_code: "153811" },
  // { label: "Poblacion II", mun_code: "153811" },
  // { label: "Samberen", mun_code: "153811" },
  // { label: "Kabuan", mun_code: "153811" },
  // { label: "Campo Islam", mun_code: "153811" },
  // { label: "Datu Macarimbang Biruar", mun_code: "153811" },
  // { label: "Gadunganpedpandaran", mun_code: "153811" },
  // { label: "Moro Point", mun_code: "153811" },
  // { label: "Alamada", mun_code: "153812" },
  // { label: "Banatin", mun_code: "153812" },
  // { label: "Banubo", mun_code: "153812" },
  // { label: "Bulalo", mun_code: "153812" },
  // { label: "Bulibod", mun_code: "153812" },
  // { label: "Calsada", mun_code: "153812" },
  // { label: "Crossing Simuay", mun_code: "153812" },
  // { label: "Dalumangcob (Pob.)", mun_code: "153812" },
  // { label: "Darapanan", mun_code: "153812" },
  // { label: "Gang", mun_code: "153812" },
  // { label: "Inawan", mun_code: "153812" },
  // { label: "Kabuntalan", mun_code: "153812" },
  // { label: "Kakar", mun_code: "153812" },
  // { label: "Kapimpilan", mun_code: "153812" },
  // { label: "Katidtuan", mun_code: "153812" },
  // { label: "Katuli", mun_code: "153812" },
  // { label: "Ladia", mun_code: "153812" },
  // { label: "Limbo", mun_code: "153812" },
  // { label: "Maidapa", mun_code: "153812" },
  // { label: "Makaguiling", mun_code: "153812" },
  // { label: "Katamlangan (Matampay)", mun_code: "153812" },
  // { label: "Matengen", mun_code: "153812" },
  // { label: "Mulaug", mun_code: "153812" },
  // { label: "Nalinan", mun_code: "153812" },
  // { label: "Nekitan", mun_code: "153812" },
  // { label: "Olas", mun_code: "153812" },
  // { label: "Panatan", mun_code: "153812" },
  // { label: "Pigcalagan", mun_code: "153812" },
  // { label: "Pigkelegan (Ibotegen)", mun_code: "153812" },
  // { label: "Pinaring", mun_code: "153812" },
  // { label: "Pingping", mun_code: "153812" },
  // { label: "Raguisi", mun_code: "153812" },
  // { label: "Rebuken", mun_code: "153812" },
  // { label: "Salimbao", mun_code: "153812" },
  // { label: "Sambolawan", mun_code: "153812" },
  // { label: "Senditan", mun_code: "153812" },
  // { label: "Ungap", mun_code: "153812" },
  // { label: "Damaniog", mun_code: "153812" },
  // { label: "Nara", mun_code: "153812" },
  // { label: "Angkayamat", mun_code: "153813" },
  // { label: "Barurao", mun_code: "153813" },
  // { label: "Bulod", mun_code: "153813" },
  // { label: "Darampua", mun_code: "153813" },
  // { label: "Gadungan", mun_code: "153813" },
  // { label: "Kulambog", mun_code: "153813" },
  // { label: "Langgapanan", mun_code: "153813" },
  // { label: "Masulot", mun_code: "153813" },
  // { label: "Papakan", mun_code: "153813" },
  // { label: "Tugal", mun_code: "153813" },
  // { label: "Tukanakuden", mun_code: "153813" },
  // { label: "Paldong", mun_code: "153813" },
  // { label: "Bagumbayan", mun_code: "153814" },
  // { label: "Dadtumog (Dadtumeg)", mun_code: "153814" },
  // { label: "Gambar", mun_code: "153814" },
  // { label: "Ganta", mun_code: "153814" },
  // { label: "Katidtuan", mun_code: "153814" },
  // { label: "Langeban", mun_code: "153814" },
  // { label: "Liong", mun_code: "153814" },
  // { label: "Maitong", mun_code: "153814" },
  // { label: "Matilak", mun_code: "153814" },
  // { label: "Pagalungan", mun_code: "153814" },
  // { label: "Payan", mun_code: "153814" },
  // { label: "Pened", mun_code: "153814" },
  // { label: "Pedtad", mun_code: "153814" },
  // { label: "Poblacion", mun_code: "153814" },
  // { label: "Upper Taviran", mun_code: "153814" },
  // { label: "Buterin", mun_code: "153814" },
  // { label: "Lower Taviran", mun_code: "153814" },
  // { label: "Borongotan", mun_code: "153815" },
  // { label: "Bayabas", mun_code: "153815" },
  // { label: "Blensong", mun_code: "153815" },
  // { label: "Bugabungan", mun_code: "153815" },
  // { label: "Bungcog", mun_code: "153815" },
  // { label: "Darugao", mun_code: "153815" },
  // { label: "Ganasi", mun_code: "153815" },
  // { label: "Kabakaba", mun_code: "153815" },
  // { label: "Kibleg", mun_code: "153815" },
  // { label: "Kibucay", mun_code: "153815" },
  // { label: "Kiga", mun_code: "153815" },
  // { label: "Kinitan (Kinitaan)", mun_code: "153815" },
  // { label: "Mirab", mun_code: "153815" },
  // { label: "Nangi", mun_code: "153815" },
  // { label: "Nuro Poblacion", mun_code: "153815" },
  // { label: "Bantek", mun_code: "153815" },
  // { label: "Ranao Pilayan", mun_code: "153815" },
  // { label: "Rempes", mun_code: "153815" },
  // { label: "Renede", mun_code: "153815" },
  // { label: "Renti", mun_code: "153815" },
  // { label: "Rifao", mun_code: "153815" },
  // { label: "Sefegefen", mun_code: "153815" },
  // { label: "Tinungkaan", mun_code: "153815" },
  // { label: "Boboguiron", mun_code: "153816" },
  // { label: "Damablac", mun_code: "153816" },
  // { label: "Fugotan", mun_code: "153816" },
  // { label: "Fukol", mun_code: "153816" },
  // { label: "Katibpuan", mun_code: "153816" },
  // { label: "Kedati", mun_code: "153816" },
  // { label: "Lanting", mun_code: "153816" },
  // { label: "Linamunan", mun_code: "153816" },
  // { label: "Marader", mun_code: "153816" },
  // { label: "Binangga North", mun_code: "153816" },
  // { label: "Binangga South", mun_code: "153816" },
  // { label: "Talayan", mun_code: "153816" },
  // { label: "Tamar", mun_code: "153816" },
  // { label: "Tambunan I", mun_code: "153816" },
  // { label: "Timbaluan", mun_code: "153816" },
  // { label: "Kuya", mun_code: "153817" },
  // { label: "Biarong", mun_code: "153817" },
  // { label: "Bongo", mun_code: "153817" },
  // { label: "Itaw", mun_code: "153817" },
  // { label: "Kigan", mun_code: "153817" },
  // { label: "Lamud", mun_code: "153817" },
  // { label: "Looy", mun_code: "153817" },
  // { label: "Pandan", mun_code: "153817" },
  // { label: "Pilar", mun_code: "153817" },
  // { label: "Romangaob (Pob.)", mun_code: "153817" },
  // { label: "San Jose", mun_code: "153817" },
  // { label: "Barira (Pob.)", mun_code: "153818" },
  // { label: "Bualan", mun_code: "153818" },
  // { label: "Gadung", mun_code: "153818" },
  // { label: "Liong", mun_code: "153818" },
  // { label: "Lipa", mun_code: "153818" },
  // { label: "Lipawan", mun_code: "153818" },
  // { label: "Marang", mun_code: "153818" },
  // { label: "Nabalawag", mun_code: "153818" },
  // { label: "Rominimbang", mun_code: "153818" },
  // { label: "Togaig", mun_code: "153818" },
  // { label: "Minabay", mun_code: "153818" },
  // { label: "Korosoyan", mun_code: "153818" },
  // { label: "Lamin", mun_code: "153818" },
  // { label: "Panggao", mun_code: "153818" },
  // { label: "Badak", mun_code: "153819" },
  // { label: "Bulod", mun_code: "153819" },
  // { label: "Kaladturan", mun_code: "153819" },
  // { label: "Kulasi", mun_code: "153819" },
  // { label: "Lao-lao", mun_code: "153819" },
  // { label: "Lasangan", mun_code: "153819" },
  // { label: "Lower Idtig", mun_code: "153819" },
  // { label: "Lumabao", mun_code: "153819" },
  // { label: "Makainis", mun_code: "153819" },
  // { label: "Midconding", mun_code: "153819" },
  // { label: "Midpandacan", mun_code: "153819" },
  // { label: "Panosolen", mun_code: "153819" },
  // { label: "Ramcor", mun_code: "153819" },
  // { label: "Tonggol", mun_code: "153819" },
  // { label: "Pidtiguian", mun_code: "153819" },
  // { label: "Quipolot", mun_code: "153819" },
  // { label: "Sadangin", mun_code: "153819" },
  // { label: "Sumakubay", mun_code: "153819" },
  // { label: "Upper Lasangan", mun_code: "153819" },
  // { label: "Bagumbong", mun_code: "153820" },
  // { label: "Dabenayan", mun_code: "153820" },
  // { label: "Daladap", mun_code: "153820" },
  // { label: "Dasikil", mun_code: "153820" },
  // { label: "Liab", mun_code: "153820" },
  // { label: "Libutan", mun_code: "153820" },
  // { label: "Lusay", mun_code: "153820" },
  // { label: "Mamasapano", mun_code: "153820" },
  // { label: "Manongkaling", mun_code: "153820" },
  // { label: "Pidsandawan", mun_code: "153820" },
  // { label: "Pimbalakan", mun_code: "153820" },
  // { label: "Sapakan", mun_code: "153820" },
  // { label: "Tuka", mun_code: "153820" },
  // { label: "Tukanalipao", mun_code: "153820" },
  // { label: "Bintan (Bentan)", mun_code: "153821" },
  // { label: "Gadungan", mun_code: "153821" },
  // { label: "Kiladap", mun_code: "153821" },
  // { label: "Kilalan", mun_code: "153821" },
  // { label: "Kuden", mun_code: "153821" },
  // { label: "Makadayon", mun_code: "153821" },
  // { label: "Manggay", mun_code: "153821" },
  // { label: "Pageda", mun_code: "153821" },
  // { label: "Talitay", mun_code: "153821" },
  // { label: "Balatungkayo (Batungkayo)", mun_code: "153822" },
  // { label: "Bulit", mun_code: "153822" },
  // { label: "Bulod", mun_code: "153822" },
  // { label: "Dungguan", mun_code: "153822" },
  // { label: "Limbalud", mun_code: "153822" },
  // { label: "Maridagao", mun_code: "153822" },
  // { label: "Nabundas", mun_code: "153822" },
  // { label: "Pagagawan", mun_code: "153822" },
  // { label: "Talapas", mun_code: "153822" },
  // { label: "Talitay", mun_code: "153822" },
  // { label: "Tunggol", mun_code: "153822" },
  // { label: "Damakling", mun_code: "153823" },
  // { label: "Damalusay", mun_code: "153823" },
  // { label: "Paglat", mun_code: "153823" },
  // { label: "Upper Idtig", mun_code: "153823" },
  // { label: "Campo", mun_code: "153823" },
  // { label: "Kakal", mun_code: "153823" },
  // { label: "Salam", mun_code: "153823" },
  // { label: "Tual", mun_code: "153823" },
  // { label: "Balut", mun_code: "153824" },
  // { label: "Boliok", mun_code: "153824" },
  // { label: "Bungabong", mun_code: "153824" },
  // { label: "Dagurongan", mun_code: "153824" },
  // { label: "Kirkir", mun_code: "153824" },
  // { label: "Macabico (Macabiso)", mun_code: "153824" },
  // { label: "Namuken", mun_code: "153824" },
  // { label: "Simuay/Seashore", mun_code: "153824" },
  // { label: "Solon", mun_code: "153824" },
  // { label: "Tambo", mun_code: "153824" },
  // { label: "Tapayan", mun_code: "153824" },
  // { label: "Tariken", mun_code: "153824" },
  // { label: "Tuka", mun_code: "153824" },
  // { label: "Ahan", mun_code: "153825" },
  // { label: "Bagan", mun_code: "153825" },
  // { label: "Datalpandan", mun_code: "153825" },
  // { label: "Kalumamis", mun_code: "153825" },
  // { label: "Kateman", mun_code: "153825" },
  // { label: "Lambayao", mun_code: "153825" },
  // { label: "Macasampen", mun_code: "153825" },
  // { label: "Muslim", mun_code: "153825" },
  // { label: "Muti", mun_code: "153825" },
  // { label: "Sampao", mun_code: "153825" },
  // { label: "Tambunan II", mun_code: "153825" },
  // { label: "Dapiawan", mun_code: "153826" },
  // { label: "Elian", mun_code: "153826" },
  // { label: "Gawang", mun_code: "153826" },
  // { label: "Kabengi", mun_code: "153826" },
  // { label: "Kitango", mun_code: "153826" },
  // { label: "Kitapok", mun_code: "153826" },
  // { label: "Madia", mun_code: "153826" },
  // { label: "Salbu", mun_code: "153826" },
  // { label: "Bulayan", mun_code: "153827" },
  // { label: "Iganagampong", mun_code: "153827" },
  // { label: "Macalag", mun_code: "153827" },
  // { label: "Maitumaig", mun_code: "153827" },
  // { label: "Malangog", mun_code: "153827" },
  // { label: "Meta", mun_code: "153827" },
  // { label: "Panangeti", mun_code: "153827" },
  // { label: "Tuntungan", mun_code: "153827" },
  // { label: "Banaba", mun_code: "153828" },
  // { label: "Dimampao", mun_code: "153828" },
  // { label: "Guinibon", mun_code: "153828" },
  // { label: "Kaya-kaya", mun_code: "153828" },
  // { label: "Maganoy", mun_code: "153828" },
  // { label: "Mao", mun_code: "153828" },
  // { label: "Maranding", mun_code: "153828" },
  // { label: "Sugadol", mun_code: "153828" },
  // { label: "Talisawa", mun_code: "153828" },
  // { label: "Tukanolocong (Tukanologong)", mun_code: "153828" },
  // { label: "Baital", mun_code: "153829" },
  // { label: "Bakat", mun_code: "153829" },
  // { label: "Dapantis", mun_code: "153829" },
  // { label: "Gaunan", mun_code: "153829" },
  // { label: "Malibpolok", mun_code: "153829" },
  // { label: "Mileb", mun_code: "153829" },
  // { label: "Panadtaban", mun_code: "153829" },
  // { label: "Pidsandawan", mun_code: "153829" },
  // { label: "Sampao", mun_code: "153829" },
  // { label: "Sapakan (Pob.)", mun_code: "153829" },
  // { label: "Tabungao", mun_code: "153829" },
  // { label: "Kinimi", mun_code: "153830" },
  // { label: "Laguitan", mun_code: "153830" },
  // { label: "Lapaken", mun_code: "153830" },
  // { label: "Matuber", mun_code: "153830" },
  // { label: "Meti", mun_code: "153830" },
  // { label: "Nalkan", mun_code: "153830" },
  // { label: "Penansaran", mun_code: "153830" },
  // { label: "Resa", mun_code: "153830" },
  // { label: "Sedem", mun_code: "153830" },
  // { label: "Sinipak", mun_code: "153830" },
  // { label: "Tambak", mun_code: "153830" },
  // { label: "Tubuan", mun_code: "153830" },
  // { label: "Pura", mun_code: "153830" },
  // { label: "Adaon", mun_code: "153831" },
  // { label: "Brar", mun_code: "153831" },
  // { label: "Mapayag", mun_code: "153831" },
  // { label: "Midtimbang (Pob.)", mun_code: "153831" },
  // { label: "Nunangan (Nunangen)", mun_code: "153831" },
  // { label: "Tugal", mun_code: "153831" },
  // { label: "Tulunan", mun_code: "153831" },
  // { label: "Daladagan", mun_code: "153832" },
  // { label: "Kalian", mun_code: "153832" },
  // { label: "Luayan", mun_code: "153832" },
  // { label: "Paitan", mun_code: "153832" },
  // { label: "Panapan", mun_code: "153832" },
  // { label: "Tenok", mun_code: "153832" },
  // { label: "Tinambulan", mun_code: "153832" },
  // { label: "Tumbao", mun_code: "153832" },
  // { label: "Kabuling", mun_code: "153833" },
  // { label: "Kayaga", mun_code: "153833" },
  // { label: "Kayupo (Cuyapo)", mun_code: "153833" },
  // { label: "Lepak", mun_code: "153833" },
  // { label: "Lower Dilag", mun_code: "153833" },
  // { label: "Malangit", mun_code: "153833" },
  // { label: "Pandag", mun_code: "153833" },
  // { label: "Upper Dilag", mun_code: "153833" },
  // { label: "Balong", mun_code: "153834" },
  // { label: "Damatog", mun_code: "153834" },
  // { label: "Gayonga", mun_code: "153834" },
  // { label: "Guiawa", mun_code: "153834" },
  // { label: "Indatuan", mun_code: "153834" },
  // { label: "Kapimpilan", mun_code: "153834" },
  // { label: "Libungan", mun_code: "153834" },
  // { label: "Montay", mun_code: "153834" },
  // { label: "Paulino Labio", mun_code: "153834" },
  // { label: "Sabaken", mun_code: "153834" },
  // { label: "Tumaguinting", mun_code: "153834" },
  // { label: "Kubentong", mun_code: "153835" },
  // { label: "Labu-labu I", mun_code: "153835" },
  // { label: "Labu-labu II", mun_code: "153835" },
  // { label: "Limpongo", mun_code: "153835" },
  // { label: "Sayap", mun_code: "153835" },
  // { label: "Taib", mun_code: "153835" },
  // { label: "Talibadok", mun_code: "153835" },
  // { label: "Tuayan", mun_code: "153835" },
  // { label: "Tuayan I", mun_code: "153835" },
  // { label: "Macalag", mun_code: "153835" },
  // { label: "Tuntungan", mun_code: "153835" },
  // { label: "Alonganan", mun_code: "153836" },
  // { label: "Andavit", mun_code: "153836" },
  // { label: "Balanakan", mun_code: "153836" },
  // { label: "Buayan", mun_code: "153836" },
  // { label: "Butilen", mun_code: "153836" },
  // { label: "Dado", mun_code: "153836" },
  // { label: "Damabalas", mun_code: "153836" },
  // { label: "Duaminanga", mun_code: "153836" },
  // { label: "Kalipapa", mun_code: "153836" },
  // { label: "Liong", mun_code: "153836" },
  // { label: "Magaslong", mun_code: "153836" },
  // { label: "Masigay", mun_code: "153836" },
  // { label: "Pagatin", mun_code: "153836" },
  // { label: "Pandi", mun_code: "153836" },
  // { label: "Penditen", mun_code: "153836" },
  // { label: "Sambulawan", mun_code: "153836" },
  // { label: "Tee", mun_code: "153836" },
  // { label: "Bakat", mun_code: "153837" },
  // { label: "Dale-Bong", mun_code: "153837" },
  // { label: "Dasawao", mun_code: "153837" },
  // { label: "Datu Bakal", mun_code: "153837" },
  // { label: "Datu Kilay", mun_code: "153837" },
  // { label: "Duguengen", mun_code: "153837" },
  // { label: "Ganta", mun_code: "153837" },
  // { label: "Inaladan", mun_code: "153837" },
  // { label: "Linantangan", mun_code: "153837" },
  // { label: "Nabundas", mun_code: "153837" },
  // { label: "Pagatin", mun_code: "153837" },
  // { label: "Pamalian", mun_code: "153837" },
  // { label: "Pikeg", mun_code: "153837" },
  // { label: "Pusao", mun_code: "153837" },
  // { label: "Libutan", mun_code: "153837" },
  // { label: "Pagatin (Pagatin I)", mun_code: "153837" },
  // { label: "Adjid", mun_code: "156601" },
  // { label: "Bangalan", mun_code: "156601" },
  // { label: "Bato-bato", mun_code: "156601" },
  // { label: "Buanza", mun_code: "156601" },
  // { label: "Bud-Taran", mun_code: "156601" },
  // { label: "Bunut", mun_code: "156601" },
  // { label: "Jati-Tunggal", mun_code: "156601" },
  // { label: "Kabbon Maas", mun_code: "156601" },
  // { label: "Kagay", mun_code: "156601" },
  // { label: "Kajatian", mun_code: "156601" },
  // { label: "Kan Islam", mun_code: "156601" },
  // { label: "Kandang Tukay", mun_code: "156601" },
  // { label: "Karawan", mun_code: "156601" },
  // { label: "Katian", mun_code: "156601" },
  // { label: "Kuppong", mun_code: "156601" },
  // { label: "Lambayong", mun_code: "156601" },
  // { label: "Langpas", mun_code: "156601" },
  // { label: "Licup", mun_code: "156601" },
  // { label: "Malimbaya", mun_code: "156601" },
  // { label: "Manggis", mun_code: "156601" },
  // { label: "Manilop", mun_code: "156601" },
  // { label: "Paligue", mun_code: "156601" },
  // { label: "Panabuan", mun_code: "156601" },
  // { label: "Pasil", mun_code: "156601" },
  // { label: "Poblacion (Indanan)", mun_code: "156601" },
  // { label: "Sapah Malaum", mun_code: "156601" },
  // { label: "Panglima Misuari (Sasak)", mun_code: "156601" },
  // { label: "Sionogan", mun_code: "156601" },
  // { label: "Tagbak", mun_code: "156601" },
  // { label: "Timbangan", mun_code: "156601" },
  // { label: "Tubig Dakulah", mun_code: "156601" },
  // { label: "Tubig Parang", mun_code: "156601" },
  // { label: "Tumantangis", mun_code: "156601" },
  // { label: "Sawaki", mun_code: "156601" },
  // { label: "Alat", mun_code: "156602" },
  // { label: "Asturias", mun_code: "156602" },
  // { label: "Bus-bus", mun_code: "156602" },
  // { label: "Chinese Pier", mun_code: "156602" },
  // { label: "San Raymundo", mun_code: "156602" },
  // { label: "Takut-takut", mun_code: "156602" },
  // { label: "Tulay", mun_code: "156602" },
  // { label: "Walled City (Pob.)", mun_code: "156602" },
  // { label: "Kambing", mun_code: "156603" },
  // { label: "Kanlagay", mun_code: "156603" },
  // { label: "Pang", mun_code: "156603" },
  // { label: "Pangdan Pangdan", mun_code: "156603" },
  // { label: "Pitogo", mun_code: "156603" },
  // { label: "Karungdong (Pob.)", mun_code: "156603" },
  // { label: "Tunggol", mun_code: "156603" },
  // { label: "Masjid Bayle", mun_code: "156603" },
  // { label: "Masjid Punjungan", mun_code: "156603" },
  // { label: "Bual", mun_code: "156604" },
  // { label: "Kan-Bulak", mun_code: "156604" },
  // { label: "Kan-Mindus", mun_code: "156604" },
  // { label: "Lambago", mun_code: "156604" },
  // { label: "Lianutan", mun_code: "156604" },
  // { label: "Lingah", mun_code: "156604" },
  // { label: "Mananti", mun_code: "156604" },
  // { label: "Tubig-Puti (Pob.)", mun_code: "156604" },
  // { label: "Tandu-Bato", mun_code: "156604" },
  // { label: "Tulayan Island", mun_code: "156604" },
  // { label: "Guimbaun", mun_code: "156604" },
  // { label: "Niog-niog", mun_code: "156604" },
  // { label: "Anak Jati", mun_code: "156605" },
  // { label: "Bato Ugis", mun_code: "156605" },
  // { label: "Bualo Lahi", mun_code: "156605" },
  // { label: "Bualo Lipid", mun_code: "156605" },
  // { label: "Bulabog", mun_code: "156605" },
  // { label: "Ratag Limbon", mun_code: "156605" },
  // { label: "Duhol Kabbon", mun_code: "156605" },
  // { label: "Gulangan", mun_code: "156605" },
  // { label: "Ipil", mun_code: "156605" },
  // { label: "Kandang", mun_code: "156605" },
  // { label: "Kapok-Punggol", mun_code: "156605" },
  // { label: "Kulasi", mun_code: "156605" },
  // { label: "Labah", mun_code: "156605" },
  // { label: "Lagasan Asibih", mun_code: "156605" },
  // { label: "Lantong", mun_code: "156605" },
  // { label: "Laud Kulasi", mun_code: "156605" },
  // { label: "Laum Maimbung", mun_code: "156605" },
  // { label: "Lunggang", mun_code: "156605" },
  // { label: "Lower Tambaking", mun_code: "156605" },
  // { label: "Lapa", mun_code: "156605" },
  // { label: "Matatal", mun_code: "156605" },
  // { label: "Patao", mun_code: "156605" },
  // { label: "Poblacion (Maimbung)", mun_code: "156605" },
  // { label: "Tabu-Bato", mun_code: "156605" },
  // { label: "Tandu Patong", mun_code: "156605" },
  // { label: "Tubig-Samin", mun_code: "156605" },
  // { label: "Upper Tambaking", mun_code: "156605" },
  // { label: "Bubuan", mun_code: "156606" },
  // { label: "Kabuukan", mun_code: "156606" },
  // { label: "Pag-asinan", mun_code: "156606" },
  // { label: "Bangas (Pob.)", mun_code: "156606" },
  // { label: "Teomabal", mun_code: "156606" },
  // { label: "Asin", mun_code: "156607" },
  // { label: "Bakud", mun_code: "156607" },
  // { label: "Bangday", mun_code: "156607" },
  // { label: "Baunoh", mun_code: "156607" },
  // { label: "Bitanag", mun_code: "156607" },
  // { label: "Bud Seit", mun_code: "156607" },
  // { label: "Bulangsi", mun_code: "156607" },
  // { label: "Datag", mun_code: "156607" },
  // { label: "Kamalig", mun_code: "156607" },
  // { label: "Kan Ukol", mun_code: "156607" },
  // { label: "Kan Asaali", mun_code: "156607" },
  // { label: "Kan-Dayok", mun_code: "156607" },
  // { label: "Kan-Sipat", mun_code: "156607" },
  // { label: "Kawasan", mun_code: "156607" },
  // { label: "Kulay-kulay", mun_code: "156607" },
  // { label: "Lakit", mun_code: "156607" },
  // { label: "Lunggang", mun_code: "156607" },
  // { label: "Parang", mun_code: "156607" },
  // { label: "Lower Patibulan", mun_code: "156607" },
  // { label: "Upper Patibulan", mun_code: "156607" },
  // { label: "Pugad Manaul", mun_code: "156607" },
  // { label: "Puhagan", mun_code: "156607" },
  // { label: "Seit Higad", mun_code: "156607" },
  // { label: "Seit Lake (Pob.)", mun_code: "156607" },
  // { label: "Su-uh", mun_code: "156607" },
  // { label: "Tabu Manuk", mun_code: "156607" },
  // { label: "Tandu-tandu", mun_code: "156607" },
  // { label: "Tayungan", mun_code: "156607" },
  // { label: "Tinah", mun_code: "156607" },
  // { label: "Tubig Gantang", mun_code: "156607" },
  // { label: "Tubig Jati", mun_code: "156607" },
  // { label: "Alu Bunah", mun_code: "156608" },
  // { label: "Bangkilay", mun_code: "156608" },
  // { label: "Kawitan", mun_code: "156608" },
  // { label: "Kehi Niog", mun_code: "156608" },
  // { label: "Lantong Babag", mun_code: "156608" },
  // { label: "Lumah Dapdap", mun_code: "156608" },
  // { label: "Pandan Niog", mun_code: "156608" },
  // { label: "Panducan", mun_code: "156608" },
  // { label: "Panitikan", mun_code: "156608" },
  // { label: "Patutol", mun_code: "156608" },
  // { label: "Simbahan (Pob.)", mun_code: "156608" },
  // { label: "Se-ipang", mun_code: "156608" },
  // { label: "Suang Bunah", mun_code: "156608" },
  // { label: "Tonggasang", mun_code: "156608" },
  // { label: "Tubig Nonok", mun_code: "156608" },
  // { label: "Tubig Sallang", mun_code: "156608" },
  // { label: "Kaha", mun_code: "156609" },
  // { label: "Alu Pangkoh", mun_code: "156609" },
  // { label: "Bagsak", mun_code: "156609" },
  // { label: "Bawisan", mun_code: "156609" },
  // { label: "Biid", mun_code: "156609" },
  // { label: "Bukid", mun_code: "156609" },
  // { label: "Buli Bawang", mun_code: "156609" },
  // { label: "Buton", mun_code: "156609" },
  // { label: "Buton Mahablo", mun_code: "156609" },
  // { label: "Danapa", mun_code: "156609" },
  // { label: "Duyan Kabao", mun_code: "156609" },
  // { label: "Gimba Lagasan", mun_code: "156609" },
  // { label: "Kahoy Sinah", mun_code: "156609" },
  // { label: "Kanaway", mun_code: "156609" },
  // { label: "Kutah Sairap", mun_code: "156609" },
  // { label: "Lagasan Higad", mun_code: "156609" },
  // { label: "Laum Buwahan", mun_code: "156609" },
  // { label: "Laum Suwah", mun_code: "156609" },
  // { label: "Alu Layag-Layag", mun_code: "156609" },
  // { label: "Liang", mun_code: "156609" },
  // { label: "Linuho", mun_code: "156609" },
  // { label: "Lipunos", mun_code: "156609" },
  // { label: "Lungan Gitong", mun_code: "156609" },
  // { label: "Lumbaan Mahaba", mun_code: "156609" },
  // { label: "Lupa Abu", mun_code: "156609" },
  // { label: "Nonokan", mun_code: "156609" },
  // { label: "Paugan", mun_code: "156609" },
  // { label: "Payuhan", mun_code: "156609" },
  // { label: "Piyahan", mun_code: "156609" },
  // { label: "Poblacion (Parang)", mun_code: "156609" },
  // { label: "Saldang", mun_code: "156609" },
  // { label: "Sampunay", mun_code: "156609" },
  // { label: "Silangkan", mun_code: "156609" },
  // { label: "Taingting", mun_code: "156609" },
  // { label: "Tikong", mun_code: "156609" },
  // { label: "Tukay", mun_code: "156609" },
  // { label: "Tumangas", mun_code: "156609" },
  // { label: "Wanni Piyanjihan", mun_code: "156609" },
  // { label: "Lanao Dakula", mun_code: "156609" },
  // { label: "Lower Sampunay", mun_code: "156609" },
  // { label: "Andalan", mun_code: "156610" },
  // { label: "Daungdong", mun_code: "156610" },
  // { label: "Kamawi", mun_code: "156610" },
  // { label: "Kanjarang", mun_code: "156610" },
  // { label: "Kayawan (Pob.)", mun_code: "156610" },
  // { label: "Kiput", mun_code: "156610" },
  // { label: "Likud", mun_code: "156610" },
  // { label: "Luuk-tulay", mun_code: "156610" },
  // { label: "Niog-niog", mun_code: "156610" },
  // { label: "Patian", mun_code: "156610" },
  // { label: "Pisak-pisak", mun_code: "156610" },
  // { label: "Saimbangon", mun_code: "156610" },
  // { label: "Sangkap", mun_code: "156610" },
  // { label: "Timuddas", mun_code: "156610" },
  // { label: "Anuling", mun_code: "156611" },
  // { label: "Bakong", mun_code: "156611" },
  // { label: "Bangkal", mun_code: "156611" },
  // { label: "Bonbon", mun_code: "156611" },
  // { label: "Buhanginan (Darayan)", mun_code: "156611" },
  // { label: "Bungkaung", mun_code: "156611" },
  // { label: "Danag", mun_code: "156611" },
  // { label: "Igasan", mun_code: "156611" },
  // { label: "Kabbon Takas", mun_code: "156611" },
  // { label: "Kadday Mampallam", mun_code: "156611" },
  // { label: "Kan Ague", mun_code: "156611" },
  // { label: "Kaunayan", mun_code: "156611" },
  // { label: "Langhub", mun_code: "156611" },
  // { label: "Latih", mun_code: "156611" },
  // { label: "Liang", mun_code: "156611" },
  // { label: "Maligay", mun_code: "156611" },
  // { label: "Mauboh", mun_code: "156611" },
  // { label: "Pangdanon", mun_code: "156611" },
  // { label: "Panglayahan", mun_code: "156611" },
  // { label: "Pansul", mun_code: "156611" },
  // { label: "Patikul Higad", mun_code: "156611" },
  // { label: "Sandah", mun_code: "156611" },
  // { label: "Taglibi (Pob.)", mun_code: "156611" },
  // { label: "Tandu-Bagua", mun_code: "156611" },
  // { label: "Tanum", mun_code: "156611" },
  // { label: "Taung", mun_code: "156611" },
  // { label: "Timpok", mun_code: "156611" },
  // { label: "Tugas", mun_code: "156611" },
  // { label: "Umangay", mun_code: "156611" },
  // { label: "Gandasuli", mun_code: "156611" },
  // { label: "Bakud", mun_code: "156612" },
  // { label: "Buan", mun_code: "156612" },
  // { label: "Bulansing Tara", mun_code: "156612" },
  // { label: "Bulihkullul", mun_code: "156612" },
  // { label: "Campo Islam", mun_code: "156612" },
  // { label: "Poblacion (Campo Baro)", mun_code: "156612" },
  // { label: "Duggo", mun_code: "156612" },
  // { label: "Duhol Tara", mun_code: "156612" },
  // { label: "East Kungtad", mun_code: "156612" },
  // { label: "East Sisangat", mun_code: "156612" },
  // { label: "Ipil", mun_code: "156612" },
  // { label: "Jambangan", mun_code: "156612" },
  // { label: "Kabubu", mun_code: "156612" },
  // { label: "Kong-Kong Laminusa", mun_code: "156612" },
  // { label: "Kud-kud", mun_code: "156612" },
  // { label: "Kungtad West", mun_code: "156612" },
  // { label: "Luuk Laminusa", mun_code: "156612" },
  // { label: "Latung", mun_code: "156612" },
  // { label: "Luuk Tara", mun_code: "156612" },
  // { label: "Manta", mun_code: "156612" },
  // { label: "Minapan", mun_code: "156612" },
  // { label: "Nipa-nipa", mun_code: "156612" },
  // { label: "North Laud", mun_code: "156612" },
  // { label: "North Manta", mun_code: "156612" },
  // { label: "North Musu Laud", mun_code: "156612" },
  // { label: "North Silumpak", mun_code: "156612" },
  // { label: "Punungan", mun_code: "156612" },
  // { label: "Pislong", mun_code: "156612" },
  // { label: "Ratag", mun_code: "156612" },
  // { label: "Sablay", mun_code: "156612" },
  // { label: "Sarukot", mun_code: "156612" },
  // { label: "Siburi", mun_code: "156612" },
  // { label: "Singko", mun_code: "156612" },
  // { label: "Siolakan", mun_code: "156612" },
  // { label: "Siundoh", mun_code: "156612" },
  // { label: "Siowing", mun_code: "156612" },
  // { label: "Sipanding", mun_code: "156612" },
  // { label: "Sisangat", mun_code: "156612" },
  // { label: "South Musu Laud", mun_code: "156612" },
  // { label: "South Silumpak", mun_code: "156612" },
  // { label: "Southwestern Bulikullul", mun_code: "156612" },
  // { label: "Subah Buaya", mun_code: "156612" },
  // { label: "Tampakan Laminusa", mun_code: "156612" },
  // { label: "Tengah Laminusa", mun_code: "156612" },
  // { label: "Tong Laminusa", mun_code: "156612" },
  // { label: "Tong-tong", mun_code: "156612" },
  // { label: "Tonglabah", mun_code: "156612" },
  // { label: "Tubig Kutah", mun_code: "156612" },
  // { label: "Tulling", mun_code: "156612" },
  // { label: "Puukan Laminusa", mun_code: "156612" },
  // { label: "Andalan", mun_code: "156613" },
  // { label: "Bagsak", mun_code: "156613" },
  // { label: "Bandang", mun_code: "156613" },
  // { label: "Bilaan (Pob.)", mun_code: "156613" },
  // { label: "Bud Bunga", mun_code: "156613" },
  // { label: "Buntod", mun_code: "156613" },
  // { label: "Buroh", mun_code: "156613" },
  // { label: "Dalih", mun_code: "156613" },
  // { label: "Gata", mun_code: "156613" },
  // { label: "Kabatuhan Tiis", mun_code: "156613" },
  // { label: "Kabungkol", mun_code: "156613" },
  // { label: "Kagay", mun_code: "156613" },
  // { label: "Kahawa", mun_code: "156613" },
  // { label: "Kandaga", mun_code: "156613" },
  // { label: "Kanlibot", mun_code: "156613" },
  // { label: "Kiutaan", mun_code: "156613" },
  // { label: "Kuhaw", mun_code: "156613" },
  // { label: "Kulamboh", mun_code: "156613" },
  // { label: "Kuttong", mun_code: "156613" },
  // { label: "Lagtoh", mun_code: "156613" },
  // { label: "Lambanah", mun_code: "156613" },
  // { label: "Liban", mun_code: "156613" },
  // { label: "Liu-Bud Pantao", mun_code: "156613" },
  // { label: "Lower Binuang", mun_code: "156613" },
  // { label: "Lower Kamuntayan", mun_code: "156613" },
  // { label: "Lower Laus", mun_code: "156613" },
  // { label: "Lower Sinumaan", mun_code: "156613" },
  // { label: "Lower Talipao", mun_code: "156613" },
  // { label: "Lumbayao", mun_code: "156613" },
  // { label: "Lumping Pigih Daho", mun_code: "156613" },
  // { label: "Lungkiaban", mun_code: "156613" },
  // { label: "Mabahay", mun_code: "156613" },
  // { label: "Mahala", mun_code: "156613" },
  // { label: "Mampallam", mun_code: "156613" },
  // { label: "Marsada", mun_code: "156613" },
  // { label: "Mauboh", mun_code: "156613" },
  // { label: "Mungit-mungit", mun_code: "156613" },
  // { label: "Niog-Sangahan", mun_code: "156613" },
  // { label: "Pantao", mun_code: "156613" },
  // { label: "Samak", mun_code: "156613" },
  // { label: "Talipao Proper", mun_code: "156613" },
  // { label: "Tampakan", mun_code: "156613" },
  // { label: "Tiis", mun_code: "156613" },
  // { label: "Tinggah", mun_code: "156613" },
  // { label: "Tubod", mun_code: "156613" },
  // { label: "Tuyang", mun_code: "156613" },
  // { label: "Upper Kamuntayan", mun_code: "156613" },
  // { label: "Upper Laus", mun_code: "156613" },
  // { label: "Upper Sinumaan", mun_code: "156613" },
  // { label: "Upper Talipao", mun_code: "156613" },
  // { label: "Kabatuhan Bilaan", mun_code: "156613" },
  // { label: "Upper Binuang", mun_code: "156613" },
  // { label: "Banting", mun_code: "156614" },
  // { label: "Hawan", mun_code: "156614" },
  // { label: "Alu-Kabingaan", mun_code: "156614" },
  // { label: "Kalang (Pob.)", mun_code: "156614" },
  // { label: "Kamaunggi", mun_code: "156614" },
  // { label: "Kanmangon", mun_code: "156614" },
  // { label: "Kanaway", mun_code: "156614" },
  // { label: "Kaumpang", mun_code: "156614" },
  // { label: "Pagatpat", mun_code: "156614" },
  // { label: "Pangdan", mun_code: "156614" },
  // { label: "Puok", mun_code: "156614" },
  // { label: "Sayli", mun_code: "156614" },
  // { label: "Sumambat", mun_code: "156614" },
  // { label: "Tangkapaan", mun_code: "156614" },
  // { label: "Tulakan", mun_code: "156614" },
  // { label: "Bakkaan", mun_code: "156615" },
  // { label: "Bangalaw", mun_code: "156615" },
  // { label: "Danao", mun_code: "156615" },
  // { label: "Dungon", mun_code: "156615" },
  // { label: "Kahikukuk", mun_code: "156615" },
  // { label: "Luuk (Pob.)", mun_code: "156615" },
  // { label: "North Paarol", mun_code: "156615" },
  // { label: "Sigumbal", mun_code: "156615" },
  // { label: "South Paarol", mun_code: "156615" },
  // { label: "Tabialan", mun_code: "156615" },
  // { label: "Tainga-Bakkao", mun_code: "156615" },
  // { label: "Tambun-bun", mun_code: "156615" },
  // { label: "Tinutungan", mun_code: "156615" },
  // { label: "Tattalan", mun_code: "156615" },
  // { label: "Gagguil", mun_code: "156616" },
  // { label: "Gata-gata", mun_code: "156616" },
  // { label: "Kamih-Pungud", mun_code: "156616" },
  // { label: "Lihbug Kabaw", mun_code: "156616" },
  // { label: "Lubuk-lubuk", mun_code: "156616" },
  // { label: "Pandakan", mun_code: "156616" },
  // { label: "Punay (Pob.)", mun_code: "156616" },
  // { label: "Tiptipon", mun_code: "156616" },
  // { label: "Jinggan", mun_code: "156616" },
  // { label: "Likbah", mun_code: "156616" },
  // { label: "Marsada", mun_code: "156616" },
  // { label: "Paiksa", mun_code: "156616" },
  // { label: "Alu Bus-Bus", mun_code: "156617" },
  // { label: "Alu-Duyong", mun_code: "156617" },
  // { label: "Bas Lugus", mun_code: "156617" },
  // { label: "Gapas Rugasan", mun_code: "156617" },
  // { label: "Gapas Tubig Tuwak", mun_code: "156617" },
  // { label: "Huwit-huwit Proper", mun_code: "156617" },
  // { label: "Huwit-huwit Bas Nonok", mun_code: "156617" },
  // { label: "Kutah Parang", mun_code: "156617" },
  // { label: "Laha", mun_code: "156617" },
  // { label: "Larap", mun_code: "156617" },
  // { label: "Lugus Proper", mun_code: "156617" },
  // { label: "Mangkallay", mun_code: "156617" },
  // { label: "Mantan", mun_code: "156617" },
  // { label: "Pait", mun_code: "156617" },
  // { label: "Parian Kayawan", mun_code: "156617" },
  // { label: "Sibul", mun_code: "156617" },
  // { label: "Tingkangan", mun_code: "156617" },
  // { label: "Baligtang", mun_code: "156618" },
  // { label: "Bud Sibaud", mun_code: "156618" },
  // { label: "Hambilan", mun_code: "156618" },
  // { label: "Kabbon", mun_code: "156618" },
  // { label: "Lahi", mun_code: "156618" },
  // { label: "Lapak", mun_code: "156618" },
  // { label: "Malanta", mun_code: "156618" },
  // { label: "Mamanok", mun_code: "156618" },
  // { label: "North Manubul", mun_code: "156618" },
  // { label: "Parian Dakula", mun_code: "156618" },
  // { label: "Sibaud Proper", mun_code: "156618" },
  // { label: "Siganggang", mun_code: "156618" },
  // { label: "South Manubul", mun_code: "156618" },
  // { label: "Suba-suba", mun_code: "156618" },
  // { label: "Tenga Manubul", mun_code: "156618" },
  // { label: "Laud Sibaud", mun_code: "156618" },
  // { label: "Andalan", mun_code: "156619" },
  // { label: "Angilan", mun_code: "156619" },
  // { label: "Capual Island", mun_code: "156619" },
  // { label: "Huwit-huwit", mun_code: "156619" },
  // { label: "Lahing-Lahing", mun_code: "156619" },
  // { label: "Niangkaan", mun_code: "156619" },
  // { label: "Sucuban", mun_code: "156619" },
  // { label: "Tangkuan", mun_code: "156619" },
  // { label: "Balimbing Proper", mun_code: "157001" },
  // { label: "Batu-batu (Pob.)", mun_code: "157001" },
  // { label: "Buan", mun_code: "157001" },
  // { label: "Dungon", mun_code: "157001" },
  // { label: "Luuk Buntal", mun_code: "157001" },
  // { label: "Parangan", mun_code: "157001" },
  // { label: "Tabunan", mun_code: "157001" },
  // { label: "Tungbangkaw", mun_code: "157001" },
  // { label: "Bauno Garing", mun_code: "157001" },
  // { label: "Belatan Halu", mun_code: "157001" },
  // { label: "Karaha", mun_code: "157001" },
  // { label: "Kulape", mun_code: "157001" },
  // { label: "Liyaburan", mun_code: "157001" },
  // { label: "Magsaggaw", mun_code: "157001" },
  // { label: "Malacca", mun_code: "157001" },
  // { label: "Sumangday", mun_code: "157001" },
  // { label: "Tundon", mun_code: "157001" },
  // { label: "Ipil", mun_code: "157002" },
  // { label: "Kamagong", mun_code: "157002" },
  // { label: "Karungdong", mun_code: "157002" },
  // { label: "Lakit Lakit", mun_code: "157002" },
  // { label: "Lamion", mun_code: "157002" },
  // { label: "Lapid Lapid", mun_code: "157002" },
  // { label: "Lato Lato", mun_code: "157002" },
  // { label: "Luuk Pandan", mun_code: "157002" },
  // { label: "Luuk Tulay", mun_code: "157002" },
  // { label: "Malassa", mun_code: "157002" },
  // { label: "Mandulan", mun_code: "157002" },
  // { label: "Masantong", mun_code: "157002" },
  // { label: "Montay Montay", mun_code: "157002" },
  // { label: "Pababag", mun_code: "157002" },
  // { label: "Pagasinan", mun_code: "157002" },
  // { label: "Pahut", mun_code: "157002" },
  // { label: "Pakias", mun_code: "157002" },
  // { label: "Paniongan", mun_code: "157002" },
  // { label: "Pasiagan", mun_code: "157002" },
  // { label: "Bongao Poblacion", mun_code: "157002" },
  // { label: "Sanga-sanga", mun_code: "157002" },
  // { label: "Silubog", mun_code: "157002" },
  // { label: "Simandagit", mun_code: "157002" },
  // { label: "Sumangat", mun_code: "157002" },
  // { label: "Tarawakan", mun_code: "157002" },
  // { label: "Tongsinah", mun_code: "157002" },
  // { label: "Tubig Basag", mun_code: "157002" },
  // { label: "Ungus-ungus", mun_code: "157002" },
  // { label: "Lagasan", mun_code: "157002" },
  // { label: "Nalil", mun_code: "157002" },
  // { label: "Pagatpat", mun_code: "157002" },
  // { label: "Pag-asa", mun_code: "157002" },
  // { label: "Tubig Tanah", mun_code: "157002" },
  // { label: "Tubig-Boh", mun_code: "157002" },
  // { label: "Tubig-Mampallam", mun_code: "157002" },
  // { label: "Boki", mun_code: "157003" },
  // { label: "Duhul Batu", mun_code: "157003" },
  // { label: "Kompang", mun_code: "157003" },
  // { label: "Lupa Pula (Pob.)", mun_code: "157003" },
  // { label: "Guppah", mun_code: "157003" },
  // { label: "Mahalo", mun_code: "157003" },
  // { label: "Pawan", mun_code: "157003" },
  // { label: "Sikub", mun_code: "157003" },
  // { label: "Tabulian", mun_code: "157003" },
  // { label: "Tanduan", mun_code: "157003" },
  // { label: "Umus Mataha", mun_code: "157003" },
  // { label: "Erok-erok", mun_code: "157003" },
  // { label: "Liyubud", mun_code: "157003" },
  // { label: "Lubbak Parang", mun_code: "157003" },
  // { label: "Sapa", mun_code: "157003" },
  // { label: "Bakong", mun_code: "157004" },
  // { label: "Manuk Mangkaw", mun_code: "157004" },
  // { label: "Mongkay", mun_code: "157004" },
  // { label: "Tampakan (Pob.)", mun_code: "157004" },
  // { label: "Tonggosong", mun_code: "157004" },
  // { label: "Tubig Indangan", mun_code: "157004" },
  // { label: "Ubol", mun_code: "157004" },
  // { label: "Doh-Tong", mun_code: "157004" },
  // { label: "Luuk Datan", mun_code: "157004" },
  // { label: "Maruwa", mun_code: "157004" },
  // { label: "Pagasinan", mun_code: "157004" },
  // { label: "Panglima Mastul", mun_code: "157004" },
  // { label: "Sukah-Bulan", mun_code: "157004" },
  // { label: "Timundon", mun_code: "157004" },
  // { label: "Bagid*", mun_code: "157004" },
  // { label: "South Larap (Larap)", mun_code: "157005" },
  // { label: "Sitangkai Poblacion", mun_code: "157005" },
  // { label: "Tongmageng", mun_code: "157005" },
  // { label: "Tongusong", mun_code: "157005" },
  // { label: "Datu Baguinda Putih", mun_code: "157005" },
  // { label: "Imam Sapie", mun_code: "157005" },
  // { label: "North Larap", mun_code: "157005" },
  // { label: "Panglima Alari", mun_code: "157005" },
  // { label: "Sipangkot", mun_code: "157005" },
  // { label: "Babagan", mun_code: "157006" },
  // { label: "Bengkol", mun_code: "157006" },
  // { label: "Bintawlan", mun_code: "157006" },
  // { label: "Bohe", mun_code: "157006" },
  // { label: "Bubuan", mun_code: "157006" },
  // { label: "Bunay Bunay Tong", mun_code: "157006" },
  // { label: "Bunay Bunay Lookan", mun_code: "157006" },
  // { label: "Bunay Bunay Center", mun_code: "157006" },
  // { label: "Lahad Dampong", mun_code: "157006" },
  // { label: "East Talisay", mun_code: "157006" },
  // { label: "Nunuk", mun_code: "157006" },
  // { label: "Laitan", mun_code: "157006" },
  // { label: "Lambi-lambian", mun_code: "157006" },
  // { label: "Laud", mun_code: "157006" },
  // { label: "Likud Tabawan", mun_code: "157006" },
  // { label: "Nusa-nusa", mun_code: "157006" },
  // { label: "Nusa", mun_code: "157006" },
  // { label: "Pampang", mun_code: "157006" },
  // { label: "Putat", mun_code: "157006" },
  // { label: "Sollogan", mun_code: "157006" },
  // { label: "Talisay", mun_code: "157006" },
  // { label: "Tampakan Dampong", mun_code: "157006" },
  // { label: "Tinda-tindahan", mun_code: "157006" },
  // { label: "Tong Tampakan", mun_code: "157006" },
  // { label: "Tubig Dayang Center", mun_code: "157006" },
  // { label: "Tubig Dayang Riverside", mun_code: "157006" },
  // { label: "Tubig Dayang", mun_code: "157006" },
  // { label: "Tukkai", mun_code: "157006" },
  // { label: "Unas-unas", mun_code: "157006" },
  // { label: "Likud Dampong", mun_code: "157006" },
  // { label: "Tangngah", mun_code: "157006" },
  // { label: "Baliungan", mun_code: "157007" },
  // { label: "Kakoong", mun_code: "157007" },
  // { label: "Kepeng", mun_code: "157007" },
  // { label: "Lahay-lahay", mun_code: "157007" },
  // { label: "Naungan", mun_code: "157007" },
  // { label: "Sallangan", mun_code: "157007" },
  // { label: "Sapa", mun_code: "157007" },
  // { label: "Silantup", mun_code: "157007" },
  // { label: "Tapian", mun_code: "157007" },
  // { label: "Tongbangkaw", mun_code: "157007" },
  // { label: "Tangngah (Tangngah Ungus Matata)", mun_code: "157007" },
  // { label: "Ballak", mun_code: "157007" },
  // { label: "Butun", mun_code: "157007" },
  // { label: "Himbah", mun_code: "157007" },
  // { label: "Kalang-kalang", mun_code: "157007" },
  // { label: "Salamat", mun_code: "157007" },
  // { label: "Sibakloon", mun_code: "157007" },
  // { label: "Tandubato", mun_code: "157007" },
  // { label: "Tapian Sukah", mun_code: "157007" },
  // { label: "Taruk", mun_code: "157007" },
  // { label: "Taganak Poblacion", mun_code: "157008" },
  // { label: "Likud Bakkao", mun_code: "157008" },
  // { label: "Bakong", mun_code: "157009" },
  // { label: "Bas-bas Proper", mun_code: "157009" },
  // { label: "Basnunuk", mun_code: "157009" },
  // { label: "Darussalam", mun_code: "157009" },
  // { label: "Languyan Proper (Pob.)", mun_code: "157009" },
  // { label: "Maraning", mun_code: "157009" },
  // { label: "Simalak", mun_code: "157009" },
  // { label: "Tuhog-tuhog", mun_code: "157009" },
  // { label: "Tumahubong", mun_code: "157009" },
  // { label: "Tumbagaan", mun_code: "157009" },
  // { label: "Parang Pantay", mun_code: "157009" },
  // { label: "Adnin", mun_code: "157009" },
  // { label: "Bakaw-bakaw", mun_code: "157009" },
  // { label: "BasLikud", mun_code: "157009" },
  // { label: "Jakarta (Lookan Latuan)", mun_code: "157009" },
  // { label: "Kalupag", mun_code: "157009" },
  // { label: "Kiniktal", mun_code: "157009" },
  // { label: "Marang-marang", mun_code: "157009" },
  // { label: "Sikullis", mun_code: "157009" },
  // { label: "Tubig Dakula", mun_code: "157009" },
  // { label: "Baldatal Islam", mun_code: "157010" },
  // { label: "Lookan Banaran", mun_code: "157010" },
  // { label: "Tonggusong Banaran", mun_code: "157010" },
  // { label: "Butun", mun_code: "157010" },
  // { label: "Dalo-dalo", mun_code: "157010" },
  // { label: "Palate Gadjaminah", mun_code: "157010" },
  // { label: "Kohec", mun_code: "157010" },
  // { label: "Latuan (Suasang)", mun_code: "157010" },
  // { label: "Lakit-lakit", mun_code: "157010" },
  // { label: "Tangngah (Lalung Sikubong)", mun_code: "157010" },
  // { label: "Tabunan Likud Sikubong", mun_code: "157010" },
  // { label: "Malanta", mun_code: "157010" },
  // { label: "Mantabuan Tabunan", mun_code: "157010" },
  // { label: "Sapa-sapa (Pob.)", mun_code: "157010" },
  // { label: "Tapian Bohe North", mun_code: "157010" },
  // { label: "Look Natuh", mun_code: "157010" },
  // { label: "Lookan Latuan", mun_code: "157010" },
  // { label: "Nunuk Likud Sikubong", mun_code: "157010" },
  // { label: "Pamasan", mun_code: "157010" },
  // { label: "Sapaat", mun_code: "157010" },
  // { label: "Sukah-sukah", mun_code: "157010" },
  // { label: "Tapian Bohe South", mun_code: "157010" },
  // { label: "Tup-tup Banaran", mun_code: "157010" },
  // { label: "Ambulong Sapal", mun_code: "157011" },
  // { label: "Datu Amilhamja Jaafar", mun_code: "157011" },
  // { label: "Hadji Imam Bidin", mun_code: "157011" },
  // { label: "Hadji Mohtar Sulayman", mun_code: "157011" },
  // { label: "Hadji Taha", mun_code: "157011" },
  // { label: "Imam Hadji Mohammad", mun_code: "157011" },
  // { label: "Ligayan", mun_code: "157011" },
  // { label: "Nunukan", mun_code: "157011" },
  // { label: "Sheik Makdum", mun_code: "157011" },
  // { label: "Sibutu (Pob.)", mun_code: "157011" },
  // { label: "Talisay", mun_code: "157011" },
  // { label: "Tandu Banak", mun_code: "157011" },
  // { label: "Taungoh", mun_code: "157011" },
  // { label: "Tongehat", mun_code: "157011" },
  // { label: "Tongsibalo", mun_code: "157011" },
  // { label: "Ungus-ungus", mun_code: "157011" },
  // { label: "Abilan", mun_code: "160201" },
  // { label: "Agong-ong", mun_code: "160201" },
  // { label: "Alubijid", mun_code: "160201" },
  // { label: "Guinabsan", mun_code: "160201" },
  // { label: "Macalang", mun_code: "160201" },
  // { label: "Malapong", mun_code: "160201" },
  // { label: "Malpoc", mun_code: "160201" },
  // { label: "Manapa", mun_code: "160201" },
  // { label: "Matabao", mun_code: "160201" },
  // { label: "Poblacion 1", mun_code: "160201" },
  // { label: "Poblacion 2", mun_code: "160201" },
  // { label: "Poblacion 3", mun_code: "160201" },
  // { label: "Poblacion 4", mun_code: "160201" },
  // { label: "Poblacion 5", mun_code: "160201" },
  // { label: "Poblacion 6", mun_code: "160201" },
  // { label: "Poblacion 7", mun_code: "160201" },
  // { label: "Poblacion 8", mun_code: "160201" },
  // { label: "Poblacion 9", mun_code: "160201" },
  // { label: "Poblacion 10", mun_code: "160201" },
  // { label: "Rizal", mun_code: "160201" },
  // { label: "Sacol", mun_code: "160201" },
  // { label: "Sangay", mun_code: "160201" },
  // { label: "Talo-ao", mun_code: "160201" },
  // { label: "Lower Olave", mun_code: "160201" },
  // { label: "Simbalan", mun_code: "160201" },
  // { label: "Agao Pob. (Bgy. 3)", mun_code: "160202" },
  // { label: "Ambago", mun_code: "160202" },
  // { label: "Amparo", mun_code: "160202" },
  // { label: "Ampayon", mun_code: "160202" },
  // { label: "Anticala", mun_code: "160202" },
  // { label: "Antongalon", mun_code: "160202" },
  // { label: "Aupagan", mun_code: "160202" },
  // { label: "Baan KM 3", mun_code: "160202" },
  // { label: "Babag", mun_code: "160202" },
  // { label: "Bading Pob. (Bgy. 22)", mun_code: "160202" },
  // { label: "Bancasi", mun_code: "160202" },
  // { label: "Banza", mun_code: "160202" },
  // { label: "Baobaoan", mun_code: "160202" },
  // { label: "Basag", mun_code: "160202" },
  // { label: "Bayanihan Pob. (Bgy. 27)", mun_code: "160202" },
  // { label: "Bilay", mun_code: "160202" },
  // { label: "Bit-os", mun_code: "160202" },
  // { label: "Bitan-agan", mun_code: "160202" },
  // { label: "Bobon", mun_code: "160202" },
  // { label: "Bonbon", mun_code: "160202" },
  // { label: "Bugabus", mun_code: "160202" },
  // { label: "Buhangin Pob. (Bgy. 19)", mun_code: "160202" },
  // { label: "Cabcabon", mun_code: "160202" },
  // { label: "Camayahan", mun_code: "160202" },
  // { label: "Baan Riverside Pob. (Bgy. 20)", mun_code: "160202" },
  // { label: "Dankias", mun_code: "160202" },
  // { label: "Imadejas Pob. (Bgy. 24)", mun_code: "160202" },
  // { label: "Diego Silang Pob. (Bgy. 6)", mun_code: "160202" },
  // { label: "Doongan", mun_code: "160202" },
  // { label: "Dumalagan", mun_code: "160202" },
  // { label: "Golden Ribbon Pob. (Bgy. 2)", mun_code: "160202" },
  // { label: "Dagohoy Pob. (Bgy. 7)", mun_code: "160202" },
  // { label: "Jose Rizal Pob. (Bgy. 25)", mun_code: "160202" },
  // { label: "Holy Redeemer Pob. (Bgy. 23)", mun_code: "160202" },
  // { label: "Humabon Pob. (Bgy. 11)", mun_code: "160202" },
  // { label: "Kinamlutan", mun_code: "160202" },
  // { label: "Lapu-lapu Pob. (Bgy. 8)", mun_code: "160202" },
  // { label: "Lemon", mun_code: "160202" },
  // { label: "Leon Kilat Pob. (Bgy. 13)", mun_code: "160202" },
  // { label: "Libertad", mun_code: "160202" },
  // { label: "Limaha Pob. (Bgy. 14)", mun_code: "160202" },
  // { label: "Los Angeles", mun_code: "160202" },
  // { label: "Lumbocan", mun_code: "160202" },
  // { label: "Maguinda", mun_code: "160202" },
  // { label: "Mahay", mun_code: "160202" },
  // { label: "Mahogany Pob. (Bgy. 21)", mun_code: "160202" },
  // { label: "Maibu", mun_code: "160202" },
  // { label: "Mandamo", mun_code: "160202" },
  // { label: "Manila de Bugabus", mun_code: "160202" },
  // { label: "Maon Pob. (Bgy. 1)", mun_code: "160202" },
  // { label: "Masao", mun_code: "160202" },
  // { label: "Maug", mun_code: "160202" },
  // { label: "Port Poyohon Pob. (Bgy. 17 - New Asia)", mun_code: "160202" },
  // { label: "New Society Village Pob. (Bgy. 26)", mun_code: "160202" },
  // { label: "Ong Yiu Pob. (Bgy. 16)", mun_code: "160202" },
  // { label: "Pianing", mun_code: "160202" },
  // { label: "Pinamanculan", mun_code: "160202" },
  // { label: "Rajah Soliman Pob. (Bgy. 4)", mun_code: "160202" },
  // { label: "San Ignacio Pob. (Bgy. 15)", mun_code: "160202" },
  // { label: "San Mateo", mun_code: "160202" },
  // { label: "San Vicente", mun_code: "160202" },
  // { label: "Sikatuna Pob. (Bgy. 10)", mun_code: "160202" },
  // { label: "Silongan Pob. (Bgy. 5)", mun_code: "160202" },
  // { label: "Sumilihon", mun_code: "160202" },
  // { label: "Tagabaca", mun_code: "160202" },
  // { label: "Taguibo", mun_code: "160202" },
  // { label: "Taligaman", mun_code: "160202" },
  // { label: "Tandang Sora Pob. (Bgy. 12)", mun_code: "160202" },
  // { label: "Tiniwisan", mun_code: "160202" },
  // { label: "Tungao", mun_code: "160202" },
  // { label: "Urduja Pob. (Bgy. 9)", mun_code: "160202" },
  // { label: "Villa Kananga", mun_code: "160202" },
  // { label: "Obrero Pob. (Bgy. 18)", mun_code: "160202" },
  // { label: "Bugsukan", mun_code: "160202" },
  // { label: "De Oro", mun_code: "160202" },
  // { label: "Dulag", mun_code: "160202" },
  // { label: "Florida", mun_code: "160202" },
  // { label: "Nong-nong", mun_code: "160202" },
  // { label: "Pagatpatan", mun_code: "160202" },
  // { label: "Pangabugan", mun_code: "160202" },
  // { label: "Salvacion", mun_code: "160202" },
  // { label: "Sumile", mun_code: "160202" },
  // { label: "Don Francisco", mun_code: "160202" },
  // { label: "Pigdaulan", mun_code: "160202" },
  // { label: "Antonio Luna", mun_code: "160203" },
  // { label: "Bay-ang", mun_code: "160203" },
  // { label: "Bayabas", mun_code: "160203" },
  // { label: "Caasinan", mun_code: "160203" },
  // { label: "Cabinet", mun_code: "160203" },
  // { label: "Calamba", mun_code: "160203" },
  // { label: "Calibunan", mun_code: "160203" },
  // { label: "Comagascas", mun_code: "160203" },
  // { label: "Concepcion", mun_code: "160203" },
  // { label: "Del Pilar", mun_code: "160203" },
  // { label: "Katugasan", mun_code: "160203" },
  // { label: "Kauswagan", mun_code: "160203" },
  // { label: "La Union", mun_code: "160203" },
  // { label: "Mabini", mun_code: "160203" },
  // { label: "Poblacion 1", mun_code: "160203" },
  // { label: "Poblacion 10", mun_code: "160203" },
  // { label: "Poblacion 11", mun_code: "160203" },
  // { label: "Poblacion 12", mun_code: "160203" },
  // { label: "Poblacion 2", mun_code: "160203" },
  // { label: "Poblacion 3", mun_code: "160203" },
  // { label: "Poblacion 4", mun_code: "160203" },
  // { label: "Poblacion 5", mun_code: "160203" },
  // { label: "Poblacion 6", mun_code: "160203" },
  // { label: "Poblacion 7", mun_code: "160203" },
  // { label: "Poblacion 8", mun_code: "160203" },
  // { label: "Poblacion 9", mun_code: "160203" },
  // { label: "Puting Bato", mun_code: "160203" },
  // { label: "Sanghan", mun_code: "160203" },
  // { label: "Soriano", mun_code: "160203" },
  // { label: "Tolosa", mun_code: "160203" },
  // { label: "Mahaba", mun_code: "160203" },
  // { label: "Cahayagan", mun_code: "160204" },
  // { label: "Gosoon", mun_code: "160204" },
  // { label: "Manoligao", mun_code: "160204" },
  // { label: "Poblacion (Carmen)", mun_code: "160204" },
  // { label: "Rojales", mun_code: "160204" },
  // { label: "San Agustin", mun_code: "160204" },
  // { label: "Tagcatong", mun_code: "160204" },
  // { label: "Vinapor", mun_code: "160204" },
  // { label: "Baleguian", mun_code: "160205" },
  // { label: "Bangonay", mun_code: "160205" },
  // { label: "A. Beltran (Camalig)", mun_code: "160205" },
  // { label: "Bunga", mun_code: "160205" },
  // { label: "Colorado", mun_code: "160205" },
  // { label: "Cuyago", mun_code: "160205" },
  // { label: "Libas", mun_code: "160205" },
  // { label: "Magdagooc", mun_code: "160205" },
  // { label: "Magsaysay", mun_code: "160205" },
  // { label: "Maraiging", mun_code: "160205" },
  // { label: "Poblacion (Jabonga)", mun_code: "160205" },
  // { label: "San Jose", mun_code: "160205" },
  // { label: "San Pablo", mun_code: "160205" },
  // { label: "San Vicente", mun_code: "160205" },
  // { label: "Bangayan", mun_code: "160206" },
  // { label: "Canaway", mun_code: "160206" },
  // { label: "Hinimbangan", mun_code: "160206" },
  // { label: "Jaliobong", mun_code: "160206" },
  // { label: "Mahayahay", mun_code: "160206" },
  // { label: "Poblacion", mun_code: "160206" },
  // { label: "San Isidro", mun_code: "160206" },
  // { label: "San Roque", mun_code: "160206" },
  // { label: "Sangay", mun_code: "160206" },
  // { label: "Crossing", mun_code: "160206" },
  // { label: "Songkoy", mun_code: "160206" },
  // { label: "Ambacon", mun_code: "160207" },
  // { label: "Bonifacio", mun_code: "160207" },
  // { label: "Consorcia", mun_code: "160207" },
  // { label: "Katipunan", mun_code: "160207" },
  // { label: "Lingayao", mun_code: "160207" },
  // { label: "Malicato", mun_code: "160207" },
  // { label: "Maningalao", mun_code: "160207" },
  // { label: "Marcos Calo", mun_code: "160207" },
  // { label: "Mat-i", mun_code: "160207" },
  // { label: "Pinana-an", mun_code: "160207" },
  // { label: "Poblacion", mun_code: "160207" },
  // { label: "San Isidro", mun_code: "160207" },
  // { label: "Tinucoran", mun_code: "160207" },
  // { label: "Balungagan", mun_code: "160207" },
  // { label: "Eduardo G. Montilla (Camboayon)", mun_code: "160207" },
  // { label: "Durian", mun_code: "160207" },
  // { label: "Ibuan", mun_code: "160207" },
  // { label: "Rosario", mun_code: "160207" },
  // { label: "San Roque", mun_code: "160207" },
  // { label: "Casiklan", mun_code: "160207" },
  // { label: "Buhang", mun_code: "160208" },
  // { label: "Caloc-an", mun_code: "160208" },
  // { label: "Guiasan", mun_code: "160208" },
  // { label: "Poblacion", mun_code: "160208" },
  // { label: "Taod-oy", mun_code: "160208" },
  // { label: "Marcos", mun_code: "160208" },
  // { label: "Santo Rosario", mun_code: "160208" },
  // { label: "Aclan", mun_code: "160209" },
  // { label: "Amontay", mun_code: "160209" },
  // { label: "Ata-atahon", mun_code: "160209" },
  // { label: "Camagong", mun_code: "160209" },
  // { label: "Cubi-cubi", mun_code: "160209" },
  // { label: "Culit", mun_code: "160209" },
  // { label: "Jaguimitan", mun_code: "160209" },
  // { label: "Kinabjangan", mun_code: "160209" },
  // { label: "Barangay 1 (Pob.)", mun_code: "160209" },
  // { label: "Barangay 2 (Pob.)", mun_code: "160209" },
  // { label: "Barangay 3 (Pob.)", mun_code: "160209" },
  // { label: "Barangay 4 (Pob.)", mun_code: "160209" },
  // { label: "Barangay 5 (Pob.)", mun_code: "160209" },
  // { label: "Barangay 6 (Pob.)", mun_code: "160209" },
  // { label: "Barangay 7 (Pob.)", mun_code: "160209" },
  // { label: "Punta", mun_code: "160209" },
  // { label: "Santa Ana", mun_code: "160209" },
  // { label: "Talisay", mun_code: "160209" },
  // { label: "Triangulo", mun_code: "160209" },
  // { label: "Curva", mun_code: "160210" },
  // { label: "Jagupit", mun_code: "160210" },
  // { label: "La Paz", mun_code: "160210" },
  // { label: "Poblacion I", mun_code: "160210" },
  // { label: "San Isidro", mun_code: "160210" },
  // { label: "Tagbuyacan", mun_code: "160210" },
  // { label: "Estanislao Morgado", mun_code: "160210" },
  // { label: "Poblacion II", mun_code: "160210" },
  // { label: "Pangaylan-IP", mun_code: "160210" },
  // { label: "Binuangan", mun_code: "160211" },
  // { label: "Cabayawa", mun_code: "160211" },
  // { label: "La Fraternidad", mun_code: "160211" },
  // { label: "Lawigan", mun_code: "160211" },
  // { label: "Poblacion 1", mun_code: "160211" },
  // { label: "Poblacion 2", mun_code: "160211" },
  // { label: "Santa Ana", mun_code: "160211" },
  // { label: "Tagmamarkay", mun_code: "160211" },
  // { label: "Tagpangahoy", mun_code: "160211" },
  // { label: "Tinigbasan", mun_code: "160211" },
  // { label: "Victory", mun_code: "160211" },
  // { label: "Poblacion I (Agay)", mun_code: "160212" },
  // { label: "Balangbalang", mun_code: "160212" },
  // { label: "Basilisa", mun_code: "160212" },
  // { label: "Humilog", mun_code: "160212" },
  // { label: "Panaytayon", mun_code: "160212" },
  // { label: "San Antonio", mun_code: "160212" },
  // { label: "Tagbongabong", mun_code: "160212" },
  // { label: "Poblacion II", mun_code: "160212" },
  // { label: "Calaitan", mun_code: "160301" },
  // { label: "Charito", mun_code: "160301" },
  // { label: "Fili", mun_code: "160301" },
  // { label: "Hamogaway", mun_code: "160301" },
  // { label: "Katipunan", mun_code: "160301" },
  // { label: "Mabuhay", mun_code: "160301" },
  // { label: "Marcelina", mun_code: "160301" },
  // { label: "Maygatasan", mun_code: "160301" },
  // { label: "Noli", mun_code: "160301" },
  // { label: "Panaytay", mun_code: "160301" },
  // { label: "Poblacion", mun_code: "160301" },
  // { label: "Sagmone", mun_code: "160301" },
  // { label: "Saguma", mun_code: "160301" },
  // { label: "Salvacion", mun_code: "160301" },
  // { label: "San Isidro", mun_code: "160301" },
  // { label: "Santa Irene", mun_code: "160301" },
  // { label: "Taglatawan", mun_code: "160301" },
  // { label: "Verdu", mun_code: "160301" },
  // { label: "Wawa", mun_code: "160301" },
  // { label: "Berseba", mun_code: "160301" },
  // { label: "Bucac", mun_code: "160301" },
  // { label: "Cagbas", mun_code: "160301" },
  // { label: "Canayugan", mun_code: "160301" },
  // { label: "Claro Cortez", mun_code: "160301" },
  // { label: "Gamao", mun_code: "160301" },
  // { label: "Getsemane", mun_code: "160301" },
  // { label: "Grace Estate", mun_code: "160301" },
  // { label: "Magkiangkang", mun_code: "160301" },
  // { label: "Mahayag", mun_code: "160301" },
  // { label: "Montivesta", mun_code: "160301" },
  // { label: "Mt. Ararat", mun_code: "160301" },
  // { label: "Mt. Carmel", mun_code: "160301" },
  // { label: "Mt. Olive", mun_code: "160301" },
  // { label: "New Salem", mun_code: "160301" },
  // { label: "Pinagalaan", mun_code: "160301" },
  // { label: "San Agustin", mun_code: "160301" },
  // { label: "San Juan", mun_code: "160301" },
  // { label: "Santa Teresita", mun_code: "160301" },
  // { label: "Taglibas", mun_code: "160301" },
  // { label: "Tagubay", mun_code: "160301" },
  // { label: "Villa Undayon", mun_code: "160301" },
  // { label: "Bunawan Brook", mun_code: "160302" },
  // { label: "Consuelo", mun_code: "160302" },
  // { label: "Libertad", mun_code: "160302" },
  // { label: "Mambalili", mun_code: "160302" },
  // { label: "Poblacion", mun_code: "160302" },
  // { label: "San Andres", mun_code: "160302" },
  // { label: "San Marcos", mun_code: "160302" },
  // { label: "Imelda", mun_code: "160302" },
  // { label: "Nueva Era", mun_code: "160302" },
  // { label: "San Teodoro", mun_code: "160302" },
  // { label: "Anolingan", mun_code: "160303" },
  // { label: "Bakingking", mun_code: "160303" },
  // { label: "Bentahon", mun_code: "160303" },
  // { label: "Bunaguit", mun_code: "160303" },
  // { label: "Catmonon", mun_code: "160303" },
  // { label: "Concordia", mun_code: "160303" },
  // { label: "Dakutan", mun_code: "160303" },
  // { label: "Duangan", mun_code: "160303" },
  // { label: "Mac-Arthur", mun_code: "160303" },
  // { label: "Guadalupe", mun_code: "160303" },
  // { label: "Hawilian", mun_code: "160303" },
  // { label: "Labao", mun_code: "160303" },
  // { label: "Maasin", mun_code: "160303" },
  // { label: "Mahagcot", mun_code: "160303" },
  // { label: "Milagros", mun_code: "160303" },
  // { label: "Nato", mun_code: "160303" },
  // { label: "Oro", mun_code: "160303" },
  // { label: "Poblacion", mun_code: "160303" },
  // { label: "Remedios", mun_code: "160303" },
  // { label: "Salug", mun_code: "160303" },
  // { label: "San Toribio", mun_code: "160303" },
  // { label: "Santa Fe", mun_code: "160303" },
  // { label: "Segunda", mun_code: "160303" },
  // { label: "Tagabase", mun_code: "160303" },
  // { label: "Taganahaw", mun_code: "160303" },
  // { label: "Tagbalili", mun_code: "160303" },
  // { label: "Tahina", mun_code: "160303" },
  // { label: "Tandang Sora", mun_code: "160303" },
  // { label: "Agsabu", mun_code: "160303" },
  // { label: "Aguinaldo", mun_code: "160303" },
  // { label: "Balubo", mun_code: "160303" },
  // { label: "Cebulan", mun_code: "160303" },
  // { label: "Crossing Luna", mun_code: "160303" },
  // { label: "Cubo", mun_code: "160303" },
  // { label: "Guibonon", mun_code: "160303" },
  // { label: "Kalabuan", mun_code: "160303" },
  // { label: "Kinamaybay", mun_code: "160303" },
  // { label: "Langag", mun_code: "160303" },
  // { label: "Maliwanag", mun_code: "160303" },
  // { label: "New Gingoog", mun_code: "160303" },
  // { label: "Odiong", mun_code: "160303" },
  // { label: "Piglawigan", mun_code: "160303" },
  // { label: "San Isidro", mun_code: "160303" },
  // { label: "San Jose", mun_code: "160303" },
  // { label: "San Vicente", mun_code: "160303" },
  // { label: "Sinakungan", mun_code: "160303" },
  // { label: "Valentina", mun_code: "160303" },
  // { label: "Bataan", mun_code: "160304" },
  // { label: "Comota", mun_code: "160304" },
  // { label: "Halapitan", mun_code: "160304" },
  // { label: "Langasian", mun_code: "160304" },
  // { label: "Poblacion", mun_code: "160304" },
  // { label: "Sagunto", mun_code: "160304" },
  // { label: "Villa Paz", mun_code: "160304" },
  // { label: "Angeles", mun_code: "160304" },
  // { label: "Kasapa II", mun_code: "160304" },
  // { label: "Lydia", mun_code: "160304" },
  // { label: "Panagangan", mun_code: "160304" },
  // { label: "Sabang Adgawan", mun_code: "160304" },
  // { label: "San Patricio", mun_code: "160304" },
  // { label: "Valentina", mun_code: "160304" },
  // { label: "Binucayan", mun_code: "160305" },
  // { label: "Johnson", mun_code: "160305" },
  // { label: "Magaud", mun_code: "160305" },
  // { label: "Nueva Gracia", mun_code: "160305" },
  // { label: "Poblacion", mun_code: "160305" },
  // { label: "San Isidro", mun_code: "160305" },
  // { label: "San Mariano", mun_code: "160305" },
  // { label: "San Vicente", mun_code: "160305" },
  // { label: "Santa Teresa", mun_code: "160305" },
  // { label: "Santo Tomas", mun_code: "160305" },
  // { label: "Violanta", mun_code: "160305" },
  // { label: "Waloe", mun_code: "160305" },
  // { label: "Kasapa", mun_code: "160305" },
  // { label: "Katipunan", mun_code: "160305" },
  // { label: "Kauswagan", mun_code: "160305" },
  // { label: "Sabud", mun_code: "160305" },
  // { label: "Aurora", mun_code: "160306" },
  // { label: "Awa", mun_code: "160306" },
  // { label: "Azpetia", mun_code: "160306" },
  // { label: "Poblacion (Bahbah)", mun_code: "160306" },
  // { label: "La Caridad", mun_code: "160306" },
  // { label: "La Suerte", mun_code: "160306" },
  // { label: "La Union", mun_code: "160306" },
  // { label: "Las Navas", mun_code: "160306" },
  // { label: "Libertad", mun_code: "160306" },
  // { label: "Los Arcos", mun_code: "160306" },
  // { label: "Lucena", mun_code: "160306" },
  // { label: "Mabuhay", mun_code: "160306" },
  // { label: "Magsaysay", mun_code: "160306" },
  // { label: "Mapaga", mun_code: "160306" },
  // { label: "New Maug", mun_code: "160306" },
  // { label: "Napo", mun_code: "160306" },
  // { label: "Patin-ay", mun_code: "160306" },
  // { label: "Salimbogaon", mun_code: "160306" },
  // { label: "Salvacion", mun_code: "160306" },
  // { label: "San Joaquin", mun_code: "160306" },
  // { label: "San Jose", mun_code: "160306" },
  // { label: "San Lorenzo", mun_code: "160306" },
  // { label: "San Martin", mun_code: "160306" },
  // { label: "San Pedro", mun_code: "160306" },
  // { label: "San Rafael", mun_code: "160306" },
  // { label: "San Salvador", mun_code: "160306" },
  // { label: "San Vicente", mun_code: "160306" },
  // { label: "Santa Irene", mun_code: "160306" },
  // { label: "Santa Maria", mun_code: "160306" },
  // { label: "La Perian", mun_code: "160306" },
  // { label: "La Purisima", mun_code: "160306" },
  // { label: "San Roque", mun_code: "160306" },
  // { label: "Bayugan 3", mun_code: "160307" },
  // { label: "Cabantao", mun_code: "160307" },
  // { label: "Cabawan", mun_code: "160307" },
  // { label: "Marfil", mun_code: "160307" },
  // { label: "Novele", mun_code: "160307" },
  // { label: "Poblacion", mun_code: "160307" },
  // { label: "Santa Cruz", mun_code: "160307" },
  // { label: "Tagbayagan", mun_code: "160307" },
  // { label: "Wasi-an", mun_code: "160307" },
  // { label: "Libuac", mun_code: "160307" },
  // { label: "Maligaya", mun_code: "160307" },
  // { label: "Alegria", mun_code: "160308" },
  // { label: "Bayugan 2", mun_code: "160308" },
  // { label: "Borbon", mun_code: "160308" },
  // { label: "Caimpugan", mun_code: "160308" },
  // { label: "Ebro", mun_code: "160308" },
  // { label: "Hubang", mun_code: "160308" },
  // { label: "Lapinigan", mun_code: "160308" },
  // { label: "Lucac", mun_code: "160308" },
  // { label: "Mate", mun_code: "160308" },
  // { label: "New Visayas", mun_code: "160308" },
  // { label: "Pasta", mun_code: "160308" },
  // { label: "Pisa-an", mun_code: "160308" },
  // { label: "Barangay 1 (Pob.)", mun_code: "160308" },
  // { label: "Barangay 2 (Pob.)", mun_code: "160308" },
  // { label: "Barangay 3 (Pob.)", mun_code: "160308" },
  // { label: "Barangay 4 (Pob.)", mun_code: "160308" },
  // { label: "Barangay 5 (Pob.)", mun_code: "160308" },
  // { label: "Rizal", mun_code: "160308" },
  // { label: "San Isidro", mun_code: "160308" },
  // { label: "Santa Ana", mun_code: "160308" },
  // { label: "Tagapua", mun_code: "160308" },
  // { label: "Bitan-agan", mun_code: "160308" },
  // { label: "Buenasuerte", mun_code: "160308" },
  // { label: "Das-agan", mun_code: "160308" },
  // { label: "Karaus", mun_code: "160308" },
  // { label: "Ladgadan", mun_code: "160308" },
  // { label: "Ormaca", mun_code: "160308" },
  // { label: "Anislagan", mun_code: "160309" },
  // { label: "Baylo", mun_code: "160309" },
  // { label: "Coalicion", mun_code: "160309" },
  // { label: "Culi", mun_code: "160309" },
  // { label: "Nuevo Trabajo", mun_code: "160309" },
  // { label: "Poblacion", mun_code: "160309" },
  // { label: "Santa Ines", mun_code: "160309" },
  // { label: "Balit", mun_code: "160309" },
  // { label: "Binicalan", mun_code: "160309" },
  // { label: "Cecilia", mun_code: "160309" },
  // { label: "Dimasalang", mun_code: "160309" },
  // { label: "Don Alejandro", mun_code: "160309" },
  // { label: "Don Pedro", mun_code: "160309" },
  // { label: "Mahagsay", mun_code: "160309" },
  // { label: "Mahapag", mun_code: "160309" },
  // { label: "Mahayahay", mun_code: "160309" },
  // { label: "Muritula", mun_code: "160309" },
  // { label: "Policarpo", mun_code: "160309" },
  // { label: "San Isidro", mun_code: "160309" },
  // { label: "San Pedro", mun_code: "160309" },
  // { label: "Santa Rita", mun_code: "160309" },
  // { label: "Santiago", mun_code: "160309" },
  // { label: "Wegguam", mun_code: "160309" },
  // { label: "Angas", mun_code: "160310" },
  // { label: "Aurora", mun_code: "160310" },
  // { label: "Awao", mun_code: "160310" },
  // { label: "Tapaz", mun_code: "160310" },
  // { label: "Patrocinio", mun_code: "160310" },
  // { label: "Poblacion", mun_code: "160310" },
  // { label: "San Jose", mun_code: "160310" },
  // { label: "Santa Isabel", mun_code: "160310" },
  // { label: "Sayon", mun_code: "160310" },
  // { label: "Concepcion", mun_code: "160310" },
  // { label: "Pag-asa", mun_code: "160310" },
  // { label: "BuenaGracia", mun_code: "160311" },
  // { label: "Causwagan", mun_code: "160311" },
  // { label: "Culi", mun_code: "160311" },
  // { label: "Del Monte", mun_code: "160311" },
  // { label: "Desamparados", mun_code: "160311" },
  // { label: "Labnig", mun_code: "160311" },
  // { label: "Sabang Gibung", mun_code: "160311" },
  // { label: "San Agustin (Pob.)", mun_code: "160311" },
  // { label: "San Isidro (Pob.)", mun_code: "160311" },
  // { label: "San Nicolas (Pob.)", mun_code: "160311" },
  // { label: "Zamora", mun_code: "160311" },
  // { label: "Zillovia", mun_code: "160311" },
  // { label: "La Flora", mun_code: "160311" },
  // { label: "Maharlika", mun_code: "160311" },
  // { label: "Marbon", mun_code: "160311" },
  // { label: "Batucan", mun_code: "160311" },
  // { label: "Basa", mun_code: "160312" },
  // { label: "Cuevas", mun_code: "160312" },
  // { label: "Kapatungan", mun_code: "160312" },
  // { label: "Langkila-an", mun_code: "160312" },
  // { label: "New Visayas", mun_code: "160312" },
  // { label: "Poblacion", mun_code: "160312" },
  // { label: "Pulang-lupa", mun_code: "160312" },
  // { label: "Salvacion", mun_code: "160312" },
  // { label: "San Ignacio", mun_code: "160312" },
  // { label: "San Isidro", mun_code: "160312" },
  // { label: "San Roque", mun_code: "160312" },
  // { label: "Santa Maria", mun_code: "160312" },
  // { label: "Tudela", mun_code: "160312" },
  // { label: "Cebolin", mun_code: "160312" },
  // { label: "Manat", mun_code: "160312" },
  // { label: "Pangyan", mun_code: "160312" },
  // { label: "Binongan", mun_code: "160313" },
  // { label: "Del Monte", mun_code: "160313" },
  // { label: "Don Mateo", mun_code: "160313" },
  // { label: "La Fortuna", mun_code: "160313" },
  // { label: "Limot", mun_code: "160313" },
  // { label: "Magsaysay", mun_code: "160313" },
  // { label: "Masayan", mun_code: "160313" },
  // { label: "Poblacion", mun_code: "160313" },
  // { label: "Sampaguita", mun_code: "160313" },
  // { label: "San Gabriel", mun_code: "160313" },
  // { label: "Santa Emelia", mun_code: "160313" },
  // { label: "Sinobong", mun_code: "160313" },
  // { label: "Anitap", mun_code: "160313" },
  // { label: "Bacay II", mun_code: "160313" },
  // { label: "Caigangan", mun_code: "160313" },
  // { label: "Candiis", mun_code: "160313" },
  // { label: "Katipunan", mun_code: "160313" },
  // { label: "Santa Cruz", mun_code: "160313" },
  // { label: "Sawagan", mun_code: "160313" },
  // { label: "Sisimon", mun_code: "160313" },
  // { label: "Afga", mun_code: "160314" },
  // { label: "Anahawan", mun_code: "160314" },
  // { label: "Banagbanag", mun_code: "160314" },
  // { label: "Del Rosario", mun_code: "160314" },
  // { label: "El Rio", mun_code: "160314" },
  // { label: "Ilihan", mun_code: "160314" },
  // { label: "Kauswagan", mun_code: "160314" },
  // { label: "Kioya", mun_code: "160314" },
  // { label: "Magkalape", mun_code: "160314" },
  // { label: "Magsaysay", mun_code: "160314" },
  // { label: "Mahayahay", mun_code: "160314" },
  // { label: "New Tubigon", mun_code: "160314" },
  // { label: "Padiay", mun_code: "160314" },
  // { label: "Perez", mun_code: "160314" },
  // { label: "Poblacion", mun_code: "160314" },
  // { label: "San Isidro", mun_code: "160314" },
  // { label: "San Vicente", mun_code: "160314" },
  // { label: "Santa Cruz", mun_code: "160314" },
  // { label: "Santa Maria", mun_code: "160314" },
  // { label: "Sinai", mun_code: "160314" },
  // { label: "Tabon-tabon", mun_code: "160314" },
  // { label: "Tag-uyango", mun_code: "160314" },
  // { label: "Villangit", mun_code: "160314" },
  // { label: "Kolambugan", mun_code: "160314" },
  // { label: "Poblacion (Alegria)", mun_code: "166701" },
  // { label: "Alipao", mun_code: "166701" },
  // { label: "Budlingin", mun_code: "166701" },
  // { label: "Camp Eduard (Geotina)", mun_code: "166701" },
  // { label: "Ombong", mun_code: "166701" },
  // { label: "Pongtud", mun_code: "166701" },
  // { label: "San Pedro", mun_code: "166701" },
  // { label: "Ferlda", mun_code: "166701" },
  // { label: "Julio Ouano (Pob.)", mun_code: "166701" },
  // { label: "San Juan", mun_code: "166701" },
  // { label: "Anahaw", mun_code: "166701" },
  // { label: "Gamuton", mun_code: "166701" },
  // { label: "Cabugao", mun_code: "166702" },
  // { label: "Cambuayon", mun_code: "166702" },
  // { label: "Campo", mun_code: "166702" },
  // { label: "Dugsangon", mun_code: "166702" },
  // { label: "Pautao", mun_code: "166702" },
  // { label: "Payapag", mun_code: "166702" },
  // { label: "Poblacion", mun_code: "166702" },
  // { label: "Pungtod", mun_code: "166702" },
  // { label: "Santo Rosario", mun_code: "166702" },
  // { label: "Baybay", mun_code: "166704" },
  // { label: "Bitaug", mun_code: "166704" },
  // { label: "Poblacion 1", mun_code: "166704" },
  // { label: "Poblacion 2", mun_code: "166704" },
  // { label: "San Mateo", mun_code: "166704" },
  // { label: "Matin-ao", mun_code: "166704" },
  // { label: "Cabugo", mun_code: "166706" },
  // { label: "Cagdianao", mun_code: "166706" },
  // { label: "Daywan", mun_code: "166706" },
  // { label: "Hayanggabon", mun_code: "166706" },
  // { label: "Ladgaron (Pob.)", mun_code: "166706" },
  // { label: "Lapinigan", mun_code: "166706" },
  // { label: "Magallanes", mun_code: "166706" },
  // { label: "Panatao", mun_code: "166706" },
  // { label: "Tayaga (Pob. East)", mun_code: "166706" },
  // { label: "Bagakay (Pob. West)", mun_code: "166706" },
  // { label: "Sapa", mun_code: "166706" },
  // { label: "Taganito", mun_code: "166706" },
  // { label: "Urbiztondo", mun_code: "166706" },
  // { label: "Wangke", mun_code: "166706" },
  // { label: "Bagakay", mun_code: "166707" },
  // { label: "Barangay 1 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 13 (Pob.)", mun_code: "166707" },
  // { label: "Buenavista", mun_code: "166707" },
  // { label: "Cabawa", mun_code: "166707" },
  // { label: "Cambas-ac", mun_code: "166707" },
  // { label: "Consolacion", mun_code: "166707" },
  // { label: "Corregidor", mun_code: "166707" },
  // { label: "Dagohoy", mun_code: "166707" },
  // { label: "Don Paulino", mun_code: "166707" },
  // { label: "Jubang", mun_code: "166707" },
  // { label: "Montserrat", mun_code: "166707" },
  // { label: "Barangay 10 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 11 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 12 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 2 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 3 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 4 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 5 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 6 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 7 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 8 (Pob.)", mun_code: "166707" },
  // { label: "Barangay 9 (Pob.)", mun_code: "166707" },
  // { label: "San Carlos", mun_code: "166707" },
  // { label: "San Miguel", mun_code: "166707" },
  // { label: "Santa Fe", mun_code: "166707" },
  // { label: "Union", mun_code: "166707" },
  // { label: "Santa Felomina", mun_code: "166707" },
  // { label: "Bagakay (Alburo)", mun_code: "166708" },
  // { label: "Antipolo", mun_code: "166708" },
  // { label: "Bitoon", mun_code: "166708" },
  // { label: "Cabugao", mun_code: "166708" },
  // { label: "Cancohoy", mun_code: "166708" },
  // { label: "Caub", mun_code: "166708" },
  // { label: "Del Carmen (Pob.)", mun_code: "166708" },
  // { label: "Domoyog", mun_code: "166708" },
  // { label: "Esperanza", mun_code: "166708" },
  // { label: "Jamoyaon", mun_code: "166708" },
  // { label: "Katipunan", mun_code: "166708" },
  // { label: "Lobogon", mun_code: "166708" },
  // { label: "Mabuhay", mun_code: "166708" },
  // { label: "Mahayahay", mun_code: "166708" },
  // { label: "Quezon", mun_code: "166708" },
  // { label: "San Fernando", mun_code: "166708" },
  // { label: "San Jose (Pob.)", mun_code: "166708" },
  // { label: "Sayak", mun_code: "166708" },
  // { label: "Tuboran", mun_code: "166708" },
  // { label: "Halian", mun_code: "166708" },
  // { label: "Anajawan", mun_code: "166710" },
  // { label: "Cabitoonan", mun_code: "166710" },
  // { label: "Catangnan", mun_code: "166710" },
  // { label: "Consuelo", mun_code: "166710" },
  // { label: "Corazon", mun_code: "166710" },
  // { label: "Daku", mun_code: "166710" },
  // { label: "Poblacion I (Purok I)", mun_code: "166710" },
  // { label: "Poblacion II (Purok II)", mun_code: "166710" },
  // { label: "Poblacion III (Purok III)", mun_code: "166710" },
  // { label: "Poblacion IV (Purok IV)", mun_code: "166710" },
  // { label: "Poblacion V (Purok V)", mun_code: "166710" },
  // { label: "La Januza", mun_code: "166710" },
  // { label: "Libertad", mun_code: "166710" },
  // { label: "Magsaysay", mun_code: "166710" },
  // { label: "Malinao", mun_code: "166710" },
  // { label: "Santa Cruz", mun_code: "166710" },
  // { label: "Santa Fe", mun_code: "166710" },
  // { label: "Suyangan", mun_code: "166710" },
  // { label: "Tawin-tawin", mun_code: "166710" },
  // { label: "Alambique (Pob.)", mun_code: "166711" },
  // { label: "Anibongan", mun_code: "166711" },
  // { label: "Camam-onan", mun_code: "166711" },
  // { label: "Cam-boayon", mun_code: "166711" },
  // { label: "Ipil (Pob.)", mun_code: "166711" },
  // { label: "Lahi", mun_code: "166711" },
  // { label: "Mahanub", mun_code: "166711" },
  // { label: "Poniente", mun_code: "166711" },
  // { label: "San Antonio (Bonot)", mun_code: "166711" },
  // { label: "San Isidro", mun_code: "166711" },
  // { label: "Sico-sico", mun_code: "166711" },
  // { label: "Villaflor", mun_code: "166711" },
  // { label: "Villafranca", mun_code: "166711" },
  // { label: "Binga", mun_code: "166714" },
  // { label: "Bobona-on", mun_code: "166714" },
  // { label: "Cantugas", mun_code: "166714" },
  // { label: "Dayano", mun_code: "166714" },
  // { label: "Mabini", mun_code: "166714" },
  // { label: "Magpayang", mun_code: "166714" },
  // { label: "Magsaysay (Pob.)", mun_code: "166714" },
  // { label: "Mansayao", mun_code: "166714" },
  // { label: "Marayag", mun_code: "166714" },
  // { label: "Matin-ao", mun_code: "166714" },
  // { label: "Paco", mun_code: "166714" },
  // { label: "Quezon (Pob.)", mun_code: "166714" },
  // { label: "Roxas", mun_code: "166714" },
  // { label: "San Francisco", mun_code: "166714" },
  // { label: "San Isidro", mun_code: "166714" },
  // { label: "San Jose", mun_code: "166714" },
  // { label: "Siana", mun_code: "166714" },
  // { label: "Silop", mun_code: "166714" },
  // { label: "Tagbuyawan", mun_code: "166714" },
  // { label: "Tapi-an", mun_code: "166714" },
  // { label: "Tolingon", mun_code: "166714" },
  // { label: "Doro (Binocaran)", mun_code: "166715" },
  // { label: "Bunyasan", mun_code: "166715" },
  // { label: "Cantapoy", mun_code: "166715" },
  // { label: "Cagtinae", mun_code: "166715" },
  // { label: "Cayawan", mun_code: "166715" },
  // { label: "Hanagdong", mun_code: "166715" },
  // { label: "Karihatag", mun_code: "166715" },
  // { label: "Masgad", mun_code: "166715" },
  // { label: "Pili", mun_code: "166715" },
  // { label: "San Isidro (Pob.)", mun_code: "166715" },
  // { label: "Tinago", mun_code: "166715" },
  // { label: "Cansayong", mun_code: "166715" },
  // { label: "Can-aga", mun_code: "166715" },
  // { label: "Villariza", mun_code: "166715" },
  // { label: "Caridad", mun_code: "166716" },
  // { label: "Katipunan", mun_code: "166716" },
  // { label: "Maasin", mun_code: "166716" },
  // { label: "Mabini", mun_code: "166716" },
  // { label: "Mabuhay", mun_code: "166716" },
  // { label: "Salvacion", mun_code: "166716" },
  // { label: "San Roque", mun_code: "166716" },
  // { label: "Asinan (Pob.)", mun_code: "166716" },
  // { label: "Centro (Pob.)", mun_code: "166716" },
  // { label: "Pilaring (Pob.)", mun_code: "166716" },
  // { label: "Punta (Pob.)", mun_code: "166716" },
  // { label: "Consolacion", mun_code: "166716" },
  // { label: "Datu", mun_code: "166716" },
  // { label: "Dayaohay", mun_code: "166716" },
  // { label: "Jaboy", mun_code: "166716" },
  // { label: "Amoslog", mun_code: "166717" },
  // { label: "Anislagan", mun_code: "166717" },
  // { label: "Bad-as", mun_code: "166717" },
  // { label: "Boyongan", mun_code: "166717" },
  // { label: "Bugas-bugas", mun_code: "166717" },
  // { label: "Central (Pob.)", mun_code: "166717" },
  // { label: "Ellaperal (Nonok)", mun_code: "166717" },
  // { label: "Ipil (Pob.)", mun_code: "166717" },
  // { label: "Lakandula", mun_code: "166717" },
  // { label: "Mabini", mun_code: "166717" },
  // { label: "Macalaya", mun_code: "166717" },
  // { label: "Magsaysay (Pob.)", mun_code: "166717" },
  // { label: "Magupange", mun_code: "166717" },
  // { label: "Pananay-an", mun_code: "166717" },
  // { label: "Panhutongan", mun_code: "166717" },
  // { label: "San Isidro", mun_code: "166717" },
  // { label: "Santa Cruz", mun_code: "166717" },
  // { label: "Suyoc", mun_code: "166717" },
  // { label: "Tagbongabong", mun_code: "166717" },
  // { label: "Sani-sani", mun_code: "166717" },
  // { label: "Bongdo", mun_code: "166718" },
  // { label: "Maribojoc", mun_code: "166718" },
  // { label: "Nuevo Campo", mun_code: "166718" },
  // { label: "San Juan", mun_code: "166718" },
  // { label: "Santa Cruz (Pob.)", mun_code: "166718" },
  // { label: "Talisay (Pob.)", mun_code: "166718" },
  // { label: "Amontay", mun_code: "166719" },
  // { label: "Balite", mun_code: "166719" },
  // { label: "Banbanon", mun_code: "166719" },
  // { label: "Diaz", mun_code: "166719" },
  // { label: "Honrado", mun_code: "166719" },
  // { label: "Jubgan", mun_code: "166719" },
  // { label: "Linongganan", mun_code: "166719" },
  // { label: "Macopa", mun_code: "166719" },
  // { label: "Magtangale", mun_code: "166719" },
  // { label: "Oslao", mun_code: "166719" },
  // { label: "Poblacion", mun_code: "166719" },
  // { label: "Buhing Calipay", mun_code: "166720" },
  // { label: "Del Carmen (Pob.)", mun_code: "166720" },
  // { label: "Del Pilar", mun_code: "166720" },
  // { label: "Macapagal", mun_code: "166720" },
  // { label: "Pacifico", mun_code: "166720" },
  // { label: "Pelaez", mun_code: "166720" },
  // { label: "Roxas", mun_code: "166720" },
  // { label: "San Miguel", mun_code: "166720" },
  // { label: "Santa Paz", mun_code: "166720" },
  // { label: "Tambacan", mun_code: "166720" },
  // { label: "Tigasao", mun_code: "166720" },
  // { label: "Abad Santos", mun_code: "166721" },
  // { label: "Alegria", mun_code: "166721" },
  // { label: "T. Arlan (Pob.)", mun_code: "166721" },
  // { label: "Bailan", mun_code: "166721" },
  // { label: "Garcia", mun_code: "166721" },
  // { label: "Libertad", mun_code: "166721" },
  // { label: "Mabini", mun_code: "166721" },
  // { label: "Mabuhay (Pob.)", mun_code: "166721" },
  // { label: "Magsaysay", mun_code: "166721" },
  // { label: "Rizal", mun_code: "166721" },
  // { label: "Tangbo", mun_code: "166721" },
  // { label: "Biyabid", mun_code: "166722" },
  // { label: "Gacepan", mun_code: "166722" },
  // { label: "Ima", mun_code: "166722" },
  // { label: "Lower Patag", mun_code: "166722" },
  // { label: "Mabuhay", mun_code: "166722" },
  // { label: "Mayag", mun_code: "166722" },
  // { label: "Poblacion (San Pedro)", mun_code: "166722" },
  // { label: "San Isidro", mun_code: "166722" },
  // { label: "San Pablo", mun_code: "166722" },
  // { label: "Tagbayani", mun_code: "166722" },
  // { label: "Tinogpahan", mun_code: "166722" },
  // { label: "Upper Patag", mun_code: "166722" },
  // { label: "Del Pilar", mun_code: "166723" },
  // { label: "Helene", mun_code: "166723" },
  // { label: "Honrado", mun_code: "166723" },
  // { label: "Navarro (Pob.)", mun_code: "166723" },
  // { label: "Nueva Estrella", mun_code: "166723" },
  // { label: "Pamosaingan", mun_code: "166723" },
  // { label: "Rizal (Pob.)", mun_code: "166723" },
  // { label: "Salog", mun_code: "166723" },
  // { label: "San Roque", mun_code: "166723" },
  // { label: "Santa Cruz", mun_code: "166723" },
  // { label: "Sering", mun_code: "166723" },
  // { label: "Songkoy", mun_code: "166723" },
  // { label: "Sudlon", mun_code: "166723" },
  // { label: "Albino Taruc", mun_code: "166723" },
  // { label: "Alang-alang", mun_code: "166724" },
  // { label: "Alegria", mun_code: "166724" },
  // { label: "Anomar", mun_code: "166724" },
  // { label: "Aurora", mun_code: "166724" },
  // { label: "Serna (Bad-asay)", mun_code: "166724" },
  // { label: "Balibayon", mun_code: "166724" },
  // { label: "Baybay", mun_code: "166724" },
  // { label: "Bilabid", mun_code: "166724" },
  // { label: "Bitaugan", mun_code: "166724" },
  // { label: "Bonifacio", mun_code: "166724" },
  // { label: "Buenavista", mun_code: "166724" },
  // { label: "Cabongbongan", mun_code: "166724" },
  // { label: "Cagniog", mun_code: "166724" },
  // { label: "Cagutsan", mun_code: "166724" },
  // { label: "Cantiasay", mun_code: "166724" },
  // { label: "Capalayan", mun_code: "166724" },
  // { label: "Catadman", mun_code: "166724" },
  // { label: "Danao", mun_code: "166724" },
  // { label: "Danawan", mun_code: "166724" },
  // { label: "Day-asan", mun_code: "166724" },
  // { label: "Ipil", mun_code: "166724" },
  // { label: "Libuac", mun_code: "166724" },
  // { label: "Lipata", mun_code: "166724" },
  // { label: "Lisondra", mun_code: "166724" },
  // { label: "Luna", mun_code: "166724" },
  // { label: "Mabini", mun_code: "166724" },
  // { label: "Mabua", mun_code: "166724" },
  // { label: "Manyagao", mun_code: "166724" },
  // { label: "Mapawa", mun_code: "166724" },
  // { label: "Mat-i", mun_code: "166724" },
  // { label: "Nabago", mun_code: "166724" },
  // { label: "Nonoc", mun_code: "166724" },
  // { label: "Poctoy", mun_code: "166724" },
  // { label: "Punta Bilar", mun_code: "166724" },
  // { label: "Quezon", mun_code: "166724" },
  // { label: "Rizal", mun_code: "166724" },
  // { label: "Sabang", mun_code: "166724" },
  // { label: "San Isidro", mun_code: "166724" },
  // { label: "San Jose", mun_code: "166724" },
  // { label: "San Juan", mun_code: "166724" },
  // { label: "San Pedro (Hanigad)", mun_code: "166724" },
  // { label: "San Roque", mun_code: "166724" },
  // { label: "Sidlakan", mun_code: "166724" },
  // { label: "Silop", mun_code: "166724" },
  // { label: "Sugbay", mun_code: "166724" },
  // { label: "Sukailang", mun_code: "166724" },
  // { label: "Taft (Pob.)", mun_code: "166724" },
  // { label: "Talisay", mun_code: "166724" },
  // { label: "Togbongon", mun_code: "166724" },
  // { label: "Trinidad", mun_code: "166724" },
  // { label: "Orok", mun_code: "166724" },
  // { label: "Washington (Pob.)", mun_code: "166724" },
  // { label: "Zaragoza", mun_code: "166724" },
  // { label: "Canlanipa", mun_code: "166724" },
  // { label: "Aurora (Pob.)", mun_code: "166725" },
  // { label: "Azucena (Pob.)", mun_code: "166725" },
  // { label: "Banban", mun_code: "166725" },
  // { label: "Cawilan", mun_code: "166725" },
  // { label: "Fabio", mun_code: "166725" },
  // { label: "Himamaug", mun_code: "166725" },
  // { label: "Laurel", mun_code: "166725" },
  // { label: "Lower Libas", mun_code: "166725" },
  // { label: "Opong", mun_code: "166725" },
  // { label: "Sampaguita (Pob.)", mun_code: "166725" },
  // { label: "Talavera", mun_code: "166725" },
  // { label: "Union", mun_code: "166725" },
  // { label: "Upper Libas", mun_code: "166725" },
  // { label: "Patino", mun_code: "166725" },
  // { label: "Capayahan", mun_code: "166727" },
  // { label: "Cawilan", mun_code: "166727" },
  // { label: "Del Rosario", mun_code: "166727" },
  // { label: "Marga", mun_code: "166727" },
  // { label: "Motorpool", mun_code: "166727" },
  // { label: "Poblacion (Tubod)", mun_code: "166727" },
  // { label: "San Isidro", mun_code: "166727" },
  // { label: "Timamana", mun_code: "166727" },
  // { label: "San Pablo", mun_code: "166727" },
  // { label: "Amaga", mun_code: "166801" },
  // { label: "Bahi", mun_code: "166801" },
  // { label: "Cabacungan", mun_code: "166801" },
  // { label: "Cambagang", mun_code: "166801" },
  // { label: "Causwagan", mun_code: "166801" },
  // { label: "Dapdap", mun_code: "166801" },
  // { label: "Dughan", mun_code: "166801" },
  // { label: "Gamut", mun_code: "166801" },
  // { label: "Javier", mun_code: "166801" },
  // { label: "Kinayan", mun_code: "166801" },
  // { label: "Mamis", mun_code: "166801" },
  // { label: "Poblacion", mun_code: "166801" },
  // { label: "Rizal", mun_code: "166801" },
  // { label: "San Jose", mun_code: "166801" },
  // { label: "San Vicente", mun_code: "166801" },
  // { label: "Sua", mun_code: "166801" },
  // { label: "Sudlon", mun_code: "166801" },
  // { label: "Unidad", mun_code: "166801" },
  // { label: "Wakat", mun_code: "166801" },
  // { label: "San Roque", mun_code: "166801" },
  // { label: "Tambis", mun_code: "166801" },
  // { label: "Amag", mun_code: "166802" },
  // { label: "Balete (Pob.)", mun_code: "166802" },
  // { label: "Cabugo", mun_code: "166802" },
  // { label: "Cagbaoto", mun_code: "166802" },
  // { label: "La Paz", mun_code: "166802" },
  // { label: "Magobawok", mun_code: "166802" },
  // { label: "Panaosawon", mun_code: "166802" },
  // { label: "Bucto", mun_code: "166803" },
  // { label: "Burboanan", mun_code: "166803" },
  // { label: "San Roque (Cadanglasan)", mun_code: "166803" },
  // { label: "Caguyao", mun_code: "166803" },
  // { label: "Coleto", mun_code: "166803" },
  // { label: "Labisma", mun_code: "166803" },
  // { label: "Lawigan", mun_code: "166803" },
  // { label: "Mangagoy", mun_code: "166803" },
  // { label: "Mone", mun_code: "166803" },
  // { label: "Pamaypayan", mun_code: "166803" },
  // { label: "Poblacion", mun_code: "166803" },
  // { label: "San Antonio", mun_code: "166803" },
  // { label: "San Fernando", mun_code: "166803" },
  // { label: "San Isidro (Bagnan)", mun_code: "166803" },
  // { label: "San Jose", mun_code: "166803" },
  // { label: "San Vicente", mun_code: "166803" },
  // { label: "Santa Cruz", mun_code: "166803" },
  // { label: "Sibaroy", mun_code: "166803" },
  // { label: "Tabon", mun_code: "166803" },
  // { label: "Tumanan", mun_code: "166803" },
  // { label: "Pamanlinan", mun_code: "166803" },
  // { label: "Kahayag", mun_code: "166803" },
  // { label: "Maharlika", mun_code: "166803" },
  // { label: "Comawas", mun_code: "166803" },
  // { label: "Aras-Asan", mun_code: "166804" },
  // { label: "Bacolod", mun_code: "166804" },
  // { label: "Bitaugan East", mun_code: "166804" },
  // { label: "Bitaugan West", mun_code: "166804" },
  // { label: "Tawagan", mun_code: "166804" },
  // { label: "Lactudan", mun_code: "166804" },
  // { label: "Mat-e", mun_code: "166804" },
  // { label: "La Purisima (Palhe)", mun_code: "166804" },
  // { label: "Poblacion", mun_code: "166804" },
  // { label: "Unidad", mun_code: "166804" },
  // { label: "Tubo-tubo", mun_code: "166804" },
  // { label: "Bugsukan", mun_code: "166805" },
  // { label: "Buntalid", mun_code: "166805" },
  // { label: "Cabangahan", mun_code: "166805" },
  // { label: "Cabas-an", mun_code: "166805" },
  // { label: "Calagdaan", mun_code: "166805" },
  // { label: "Consuelo", mun_code: "166805" },
  // { label: "General Island", mun_code: "166805" },
  // { label: "Lininti-an (Pob.)", mun_code: "166805" },
  // { label: "Magasang", mun_code: "166805" },
  // { label: "Magosilom (Pob.)", mun_code: "166805" },
  // { label: "Pag-Antayan", mun_code: "166805" },
  // { label: "Palasao", mun_code: "166805" },
  // { label: "Parang", mun_code: "166805" },
  // { label: "Tapi", mun_code: "166805" },
  // { label: "Tigabong", mun_code: "166805" },
  // { label: "Lobo", mun_code: "166805" },
  // { label: "San Pedro", mun_code: "166805" },
  // { label: "Antao", mun_code: "166806" },
  // { label: "Cancavan", mun_code: "166806" },
  // { label: "Carmen (Pob.)", mun_code: "166806" },
  // { label: "Esperanza", mun_code: "166806" },
  // { label: "Puyat", mun_code: "166806" },
  // { label: "San Vicente", mun_code: "166806" },
  // { label: "Santa Cruz", mun_code: "166806" },
  // { label: "Hinapoyan", mun_code: "166806" },
  // { label: "Adlay", mun_code: "166807" },
  // { label: "Babuyan", mun_code: "166807" },
  // { label: "Bacolod", mun_code: "166807" },
  // { label: "Baybay (Pob.)", mun_code: "166807" },
  // { label: "Bon-ot", mun_code: "166807" },
  // { label: "Caglayag", mun_code: "166807" },
  // { label: "Dahican", mun_code: "166807" },
  // { label: "Doyos (Pob.)", mun_code: "166807" },
  // { label: "Embarcadero (Pob.)", mun_code: "166807" },
  // { label: "Gamuton", mun_code: "166807" },
  // { label: "Panikian", mun_code: "166807" },
  // { label: "Pantukan", mun_code: "166807" },
  // { label: "Saca (Pob.)", mun_code: "166807" },
  // { label: "Tag-Anito", mun_code: "166807" },
  // { label: "Balibadon", mun_code: "166808" },
  // { label: "Burgos", mun_code: "166808" },
  // { label: "Capandan", mun_code: "166808" },
  // { label: "Mabahin", mun_code: "166808" },
  // { label: "Madrelino", mun_code: "166808" },
  // { label: "Manlico", mun_code: "166808" },
  // { label: "Matho", mun_code: "166808" },
  // { label: "Poblacion", mun_code: "166808" },
  // { label: "Tag-Anongan", mun_code: "166808" },
  // { label: "Tigao", mun_code: "166808" },
  // { label: "Tuboran", mun_code: "166808" },
  // { label: "Uba", mun_code: "166808" },
  // { label: "Baculin", mun_code: "166809" },
  // { label: "Bigaan", mun_code: "166809" },
  // { label: "Cambatong", mun_code: "166809" },
  // { label: "Campa", mun_code: "166809" },
  // { label: "Dugmanon", mun_code: "166809" },
  // { label: "Harip", mun_code: "166809" },
  // { label: "La Casa (Pob.)", mun_code: "166809" },
  // { label: "Loyola", mun_code: "166809" },
  // { label: "Maligaya", mun_code: "166809" },
  // { label: "Pagtigni-an (Bitoon)", mun_code: "166809" },
  // { label: "Pocto", mun_code: "166809" },
  // { label: "Port Lamon", mun_code: "166809" },
  // { label: "Roxas", mun_code: "166809" },
  // { label: "San Juan", mun_code: "166809" },
  // { label: "Sasa", mun_code: "166809" },
  // { label: "Tagasaka", mun_code: "166809" },
  // { label: "Talisay", mun_code: "166809" },
  // { label: "Tarusan", mun_code: "166809" },
  // { label: "Tidman", mun_code: "166809" },
  // { label: "Tiwi", mun_code: "166809" },
  // { label: "Benigno Aquino (Zone I Pob.)", mun_code: "166809" },
  // { label: "Zone II (Pob.)", mun_code: "166809" },
  // { label: "Zone III Maharlika (Pob.)", mun_code: "166809" },
  // { label: "Tagbobonga", mun_code: "166809" },
  // { label: "Agsam", mun_code: "166810" },
  // { label: "Bocawe", mun_code: "166810" },
  // { label: "Bunga", mun_code: "166810" },
  // { label: "Gamuton", mun_code: "166810" },
  // { label: "Habag", mun_code: "166810" },
  // { label: "Mampi", mun_code: "166810" },
  // { label: "Nurcia", mun_code: "166810" },
  // { label: "Sibahay", mun_code: "166810" },
  // { label: "Zone I (Pob.)", mun_code: "166810" },
  // { label: "Pakwan", mun_code: "166810" },
  // { label: "Zone II (Pob.)", mun_code: "166810" },
  // { label: "Zone III (Pob.)", mun_code: "166810" },
  // { label: "Zone IV (Pob.)", mun_code: "166810" },
  // { label: "Anibongan", mun_code: "166811" },
  // { label: "Banahao", mun_code: "166811" },
  // { label: "Ban-as", mun_code: "166811" },
  // { label: "Baucawe", mun_code: "166811" },
  // { label: "Diatagon", mun_code: "166811" },
  // { label: "Ganayon", mun_code: "166811" },
  // { label: "Liatimco", mun_code: "166811" },
  // { label: "Manyayay", mun_code: "166811" },
  // { label: "Payasan", mun_code: "166811" },
  // { label: "Poblacion", mun_code: "166811" },
  // { label: "Saint Christine", mun_code: "166811" },
  // { label: "San Isidro", mun_code: "166811" },
  // { label: "San Pedro", mun_code: "166811" },
  // { label: "Anibongan", mun_code: "166812" },
  // { label: "Barcelona", mun_code: "166812" },
  // { label: "Bongan", mun_code: "166812" },
  // { label: "Bogak", mun_code: "166812" },
  // { label: "Handamayan", mun_code: "166812" },
  // { label: "Mahayahay", mun_code: "166812" },
  // { label: "Mandus", mun_code: "166812" },
  // { label: "Mansa-ilao", mun_code: "166812" },
  // { label: "Pagtila-an", mun_code: "166812" },
  // { label: "Palo Alto", mun_code: "166812" },
  // { label: "Poblacion", mun_code: "166812" },
  // { label: "Rajah Cabungso-an", mun_code: "166812" },
  // { label: "Sabang", mun_code: "166812" },
  // { label: "Salvacion", mun_code: "166812" },
  // { label: "San Roque", mun_code: "166812" },
  // { label: "Tagpoporan", mun_code: "166812" },
  // { label: "Union", mun_code: "166812" },
  // { label: "Valencia", mun_code: "166812" },
  // { label: "Bagsac", mun_code: "166813" },
  // { label: "Bayogo", mun_code: "166813" },
  // { label: "Magsaysay", mun_code: "166813" },
  // { label: "Manga", mun_code: "166813" },
  // { label: "Panayogon", mun_code: "166813" },
  // { label: "Patong Patong", mun_code: "166813" },
  // { label: "Quirino (Pob.)", mun_code: "166813" },
  // { label: "San Antonio", mun_code: "166813" },
  // { label: "San Juan", mun_code: "166813" },
  // { label: "San Roque", mun_code: "166813" },
  // { label: "San Vicente", mun_code: "166813" },
  // { label: "Songkit", mun_code: "166813" },
  // { label: "Union", mun_code: "166813" },
  // { label: "Linibonan", mun_code: "166813" },
  // { label: "Alegria", mun_code: "166814" },
  // { label: "Amontay", mun_code: "166814" },
  // { label: "Antipolo", mun_code: "166814" },
  // { label: "Arorogan", mun_code: "166814" },
  // { label: "Bayan", mun_code: "166814" },
  // { label: "Mahaba", mun_code: "166814" },
  // { label: "Mararag", mun_code: "166814" },
  // { label: "Poblacion", mun_code: "166814" },
  // { label: "San Antonio", mun_code: "166814" },
  // { label: "San Isidro", mun_code: "166814" },
  // { label: "San Pedro", mun_code: "166814" },
  // { label: "Santa Cruz", mun_code: "166814" },
  // { label: "Bretania", mun_code: "166815" },
  // { label: "Buatong", mun_code: "166815" },
  // { label: "Buhisan", mun_code: "166815" },
  // { label: "Gata", mun_code: "166815" },
  // { label: "Hornasan", mun_code: "166815" },
  // { label: "Janipaan", mun_code: "166815" },
  // { label: "Kauswagan", mun_code: "166815" },
  // { label: "Oteiza", mun_code: "166815" },
  // { label: "Poblacion", mun_code: "166815" },
  // { label: "Pong-on", mun_code: "166815" },
  // { label: "Pongtod", mun_code: "166815" },
  // { label: "Salvacion", mun_code: "166815" },
  // { label: "Bagyang", mun_code: "166816" },
  // { label: "Baras", mun_code: "166816" },
  // { label: "Bitaugan", mun_code: "166816" },
  // { label: "Bolhoon", mun_code: "166816" },
  // { label: "Calatngan", mun_code: "166816" },
  // { label: "Carromata", mun_code: "166816" },
  // { label: "Castillo", mun_code: "166816" },
  // { label: "Libas Gua", mun_code: "166816" },
  // { label: "Libas Sud", mun_code: "166816" },
  // { label: "Magroyong", mun_code: "166816" },
  // { label: "Mahayag (Maitum)", mun_code: "166816" },
  // { label: "Patong", mun_code: "166816" },
  // { label: "Poblacion", mun_code: "166816" },
  // { label: "Sagbayan", mun_code: "166816" },
  // { label: "San Roque", mun_code: "166816" },
  // { label: "Siagao", mun_code: "166816" },
  // { label: "Umalag", mun_code: "166816" },
  // { label: "Tina", mun_code: "166816" },
  // { label: "Batunan", mun_code: "166817" },
  // { label: "Carpenito", mun_code: "166817" },
  // { label: "Kahayagan", mun_code: "166817" },
  // { label: "Lago", mun_code: "166817" },
  // { label: "Maglambing", mun_code: "166817" },
  // { label: "Maglatab", mun_code: "166817" },
  // { label: "Magsaysay", mun_code: "166817" },
  // { label: "Malixi", mun_code: "166817" },
  // { label: "Manambia", mun_code: "166817" },
  // { label: "Poblacion", mun_code: "166817" },
  // { label: "Quezon", mun_code: "166817" },
  // { label: "San Vicente", mun_code: "166817" },
  // { label: "Santa Cruz", mun_code: "166817" },
  // { label: "Santa Fe", mun_code: "166817" },
  // { label: "Santa Juana", mun_code: "166817" },
  // { label: "Santa Maria", mun_code: "166817" },
  // { label: "Sayon", mun_code: "166817" },
  // { label: "Soriano", mun_code: "166817" },
  // { label: "Tagongon", mun_code: "166817" },
  // { label: "Trinidad", mun_code: "166817" },
  // { label: "Ugoban", mun_code: "166817" },
  // { label: "Villaverde", mun_code: "166817" },
  // { label: "Hinagdanan", mun_code: "166817" },
  // { label: "Alba", mun_code: "166818" },
  // { label: "Anahao Bag-o", mun_code: "166818" },
  // { label: "Anahao Daan", mun_code: "166818" },
  // { label: "Badong", mun_code: "166818" },
  // { label: "Bajao", mun_code: "166818" },
  // { label: "Bangsud", mun_code: "166818" },
  // { label: "Cagdapao", mun_code: "166818" },
  // { label: "Camagong", mun_code: "166818" },
  // { label: "Caras-an", mun_code: "166818" },
  // { label: "Cayale", mun_code: "166818" },
  // { label: "Dayo-an", mun_code: "166818" },
  // { label: "Gamut", mun_code: "166818" },
  // { label: "Jubang", mun_code: "166818" },
  // { label: "Kinabigtasan", mun_code: "166818" },
  // { label: "Layog", mun_code: "166818" },
  // { label: "Lindoy", mun_code: "166818" },
  // { label: "Mercedes", mun_code: "166818" },
  // { label: "Purisima (Pob.)", mun_code: "166818" },
  // { label: "Sumo-sumo", mun_code: "166818" },
  // { label: "Umbay", mun_code: "166818" },
  // { label: "Unaban", mun_code: "166818" },
  // { label: "Unidos", mun_code: "166818" },
  // { label: "Victoria", mun_code: "166818" },
  // { label: "Cabangahan", mun_code: "166818" },
  // { label: "Awasian", mun_code: "166819" },
  // { label: "Bagong Lungsod (Pob.)", mun_code: "166819" },
  // { label: "Bioto", mun_code: "166819" },
  // { label: "Buenavista", mun_code: "166819" },
  // { label: "Bongtod Pob. (East West)", mun_code: "166819" },
  // { label: "Dagocdoc (Pob.)", mun_code: "166819" },
  // { label: "Mabua", mun_code: "166819" },
  // { label: "Mabuhay", mun_code: "166819" },
  // { label: "Maitum", mun_code: "166819" },
  // { label: "Maticdum", mun_code: "166819" },
  // { label: "Pandanon", mun_code: "166819" },
  // { label: "Pangi", mun_code: "166819" },
  // { label: "Quezon", mun_code: "166819" },
  // { label: "Rosario", mun_code: "166819" },
  // { label: "Salvacion", mun_code: "166819" },
  // { label: "San Agustin Norte", mun_code: "166819" },
  // { label: "San Agustin Sur", mun_code: "166819" },
  // { label: "San Antonio", mun_code: "166819" },
  // { label: "San Isidro", mun_code: "166819" },
  // { label: "San Jose", mun_code: "166819" },
  // { label: "Telaje", mun_code: "166819" },
  // { label: "Catadman", mun_code: "168501" },
  // { label: "Columbus", mun_code: "168501" },
  // { label: "Coring", mun_code: "168501" },
  // { label: "Cortes", mun_code: "168501" },
  // { label: "Ferdinand", mun_code: "168501" },
  // { label: "Geotina", mun_code: "168501" },
  // { label: "Imee (Bactasan)", mun_code: "168501" },
  // { label: "Melgar", mun_code: "168501" },
  // { label: "Montag", mun_code: "168501" },
  // { label: "Navarro", mun_code: "168501" },
  // { label: "Poblacion", mun_code: "168501" },
  // { label: "Puerto Princesa", mun_code: "168501" },
  // { label: "Rita Glenda", mun_code: "168501" },
  // { label: "Roxas", mun_code: "168501" },
  // { label: "Sering", mun_code: "168501" },
  // { label: "Tag-abaca", mun_code: "168501" },
  // { label: "Benglen", mun_code: "168501" },
  // { label: "Diegas", mun_code: "168501" },
  // { label: "Edera", mun_code: "168501" },
  // { label: "New Nazareth", mun_code: "168501" },
  // { label: "Roma", mun_code: "168501" },
  // { label: "Santa Monica", mun_code: "168501" },
  // { label: "Sombrado", mun_code: "168501" },
  // { label: "Villa Ecleo", mun_code: "168501" },
  // { label: "Villa Pantinople", mun_code: "168501" },
  // { label: "Boa", mun_code: "168502" },
  // { label: "Cabunga-an", mun_code: "168502" },
  // { label: "Del Pilar", mun_code: "168502" },
  // { label: "Laguna", mun_code: "168502" },
  // { label: "Legaspi", mun_code: "168502" },
  // { label: "Ma-atas", mun_code: "168502" },
  // { label: "Nueva Estrella", mun_code: "168502" },
  // { label: "Poblacion", mun_code: "168502" },
  // { label: "San Jose", mun_code: "168502" },
  // { label: "Santa Rita", mun_code: "168502" },
  // { label: "Tigbao", mun_code: "168502" },
  // { label: "Valencia", mun_code: "168502" },
  // { label: "Mabini (Borja)", mun_code: "168502" },
  // { label: "R. Ecleo, Sr.", mun_code: "168502" },
  // { label: "Cab-ilan", mun_code: "168503" },
  // { label: "Cabayawan", mun_code: "168503" },
  // { label: "Escolta (Pob.)", mun_code: "168503" },
  // { label: "Gomez", mun_code: "168503" },
  // { label: "Magsaysay", mun_code: "168503" },
  // { label: "Mauswagon (Pob.)", mun_code: "168503" },
  // { label: "White Beach (Pob.)", mun_code: "168503" },
  // { label: "Bagumbayan", mun_code: "168503" },
  // { label: "New Mabuhay", mun_code: "168503" },
  // { label: "Wadas", mun_code: "168503" },
  // { label: "Cayetano", mun_code: "168503" },
  // { label: "Justiniana Edera", mun_code: "168503" },
  // { label: "Albor (Pob.)", mun_code: "168504" },
  // { label: "Arellano", mun_code: "168504" },
  // { label: "Bayanihan", mun_code: "168504" },
  // { label: "Garcia", mun_code: "168504" },
  // { label: "General Aguinaldo (Bolod-bolod)", mun_code: "168504" },
  // { label: "Kanihaan", mun_code: "168504" },
  // { label: "Magsaysay", mun_code: "168504" },
  // { label: "Plaridel", mun_code: "168504" },
  // { label: "Quezon", mun_code: "168504" },
  // { label: "San Antonio (Pob.)", mun_code: "168504" },
  // { label: "San Jose", mun_code: "168504" },
  // { label: "Llamera", mun_code: "168504" },
  // { label: "Rosita", mun_code: "168504" },
  // { label: "Carmen (Pob.)", mun_code: "168505" },
  // { label: "Esperanza", mun_code: "168505" },
  // { label: "Ferdinand", mun_code: "168505" },
  // { label: "Helene", mun_code: "168505" },
  // { label: "Liberty", mun_code: "168505" },
  // { label: "Magsaysay", mun_code: "168505" },
  // { label: "Panamaon", mun_code: "168505" },
  // { label: "San Juan (Pob.)", mun_code: "168505" },
  // { label: "Santa Cruz (Pob.)", mun_code: "168505" },
  // { label: "Santiago (Pob.)", mun_code: "168505" },
  // { label: "Aurelio", mun_code: "168506" },
  // { label: "Cuarinta", mun_code: "168506" },
  // { label: "Don Ruben Ecleo (Baltazar)", mun_code: "168506" },
  // { label: "Jacquez", mun_code: "168506" },
  // { label: "Justiniana Edera", mun_code: "168506" },
  // { label: "Luna", mun_code: "168506" },
  // { label: "Mahayahay", mun_code: "168506" },
  // { label: "Matingbe", mun_code: "168506" },
  // { label: "San Jose (Pob.)", mun_code: "168506" },
  // { label: "San Juan", mun_code: "168506" },
  // { label: "Santa Cruz", mun_code: "168506" },
  // { label: "Wilson", mun_code: "168506" },
  // { label: "Imelda", mun_code: "168507" },
  // { label: "Mabini", mun_code: "168507" },
  // { label: "Malinao", mun_code: "168507" },
  // { label: "Navarro", mun_code: "168507" },
  // { label: "Diaz (Romualdez)", mun_code: "168507" },
  // { label: "Roxas", mun_code: "168507" },
  // { label: "San Roque (Pob.)", mun_code: "168507" },
  // { label: "San Vicente (Pob.)", mun_code: "168507" },
  // { label: "Santa Cruz (Pob.)", mun_code: "168507" },
];

// Reference:
// https://github.com/ejcubillas/phil-reg-prov-mun-brgy/tree/master/src/json
export { regions, provinces, municipalities, barangays };
