// /////////////////////////////////////////////////////////////////////////////
// IMPORTS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

import "../../assets/scss/Forms.css";

// Styling /////////////////////////////////////////////////////////////////////

import { Nav } from "react-bootstrap";
import SaveIcon from "@mui/icons-material/Save";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MyLocationIcon from "@mui/icons-material/MyLocation";

// React ///////////////////////////////////////////////////////////////////////

import { useEffect, useState } from "react";
import { LoaderFunctionArgs } from "react-router-dom";
import { NavLink, redirect, useNavigate } from "react-router-dom";

// Arvouz Library //////////////////////////////////////////////////////////////

import { Card, HBar, VBar } from "../../custom/afx/ABox";
import { SET, TGetValidation } from "../../custom/afx/AType";
import { Checks, Notify, Text, backup } from "../../custom/afx/AFX";
import { Pick, Press, Field, INotifyPublic } from "../../custom/afx/AFX";
import { GET, TGetSeverity, TSetValidation } from "../../custom/afx/AType";
import { Day, Mod, IModPublic, TModButton, Popup } from "../../custom/afx/AFX";

// Address Library /////////////////////////////////////////////////////////////

import { Address, Province } from "../../custom/RAddress";
import { Municipality, Barangay } from "../../custom/RAddress";
import { provinces, municipalities, barangays } from "../../custom/Philippines";

// Geolocation Library /////////////////////////////////////////////////////////

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { GeoLocationPane } from "../../app/Settings";

// Custom //////////////////////////////////////////////////////////////////////

import WebCam from "../../custom/Camera";
import { Route } from "../../app/Routes";
import { FORMS } from "../../app/Settings";
import { TitleBar } from "../encode/EncodePage";
import { AppSettings, DATA_STATUS } from "../../app/Settings";
import { fxdMachine, fxdMachineBackup } from "../../app/Storage";
import { fxdGen, fxdProfile, fxdUser } from "../../app/Storage";

// Others //////////////////////////////////////////////////////////////////////

import saveAs from "file-saver";
import { Alert } from "@mui/material";
import { BrowserQRCodeReader } from "@zxing/library";
import { AUTH_PROVIDER } from "../../hooks/auth/auth";

// /////////////////////////////////////////////////////////////////////////////
// STRINGS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

import { strPrf } from "../profile/ProfilePage";

export var strMac = {
  bscInfo: {
    title: "Basic Information",
    resName: {
      label: "Name of Respondent",
      placeholder: "First Name MI. Last Name",
    },
    qrcode: {
      label: "Scan Machine QR Code",
      placeholder: "Click to start scanning",
    },
    qrcodevid: {
      label: "QRCode Camera",
      placeholder: "Select Camera",
    },
    qrReset: "Reset",
    front: {
      label: "Capture Front View",
      placeholder: "Click to start capture",
    },
    side: {
      label: "Capture Side View",
      placeholder: "Click to start capture",
    },
    plate: {
      label: "Capture Specification Plate",
      placeholder: "Click to start capture",
    },
    location: {
      label: "Location",
      placeholder: "Select the location",
    },
    latitude: {
      label: "Latitude",
      placeholder: "0.000000",
    },
    longitude: {
      label: "Longitude",
      placeholder: "0.000000",
    },
    accuracy: {
      label: "Accuracy",
      placeholder: "0.000000",
    },
    during: {
      label: "Location during survey",
      placeholder: "Choose an option",
      options: [
        { label: "Garage" },
        { label: "Field" },
        { label: "Transloading Zone" },
      ],
    },
    garage: "Garage Location",
    province: {
      label: "Province",
      placeholder: "Select Province",
    },
    municipality: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    barangay: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
    coordinates: {
      label: "Accuracy",
      placeholder: "0.000000, 0.000000",
    },
  },
  macInfo: {
    title: "Machine Specifications",
    type: {
      label: "Machine Type",
      placeholder: "Select Machine Type",
      // ! The array is statically linked as constant
      options: [
        { label: "Two-wheel Tractor", key: 0 },
        { label: "Four-wheel Tractor", key: 1 },
        { label: "Cane Grab Loader", key: 2 },
        { label: "Sugarcane Harvester (Self-Propelled)", key: 3 },
        { label: "Water Pump", key: 4 },
        { label: "Infield Hauler", key: 5 },
        { label: "Rain Gun", key: 6 },
        { label: "Not Applicable", key: 7 },
      ],
    },
    brand: { label: "Brand", placeholder: "Select Machine Brand" },
    model: { label: "Model", placeholder: "Select Machine Model" },
    rPower: {
      label: "Rated Power",
      placeholder: "0.00",
      unit: "hp",
    },
    sArea: {
      label: "Total Service Area",
      placeholder: "0.00",
      unit: "ha",
    },
    // TODO Possible duplicate
    oDayTitle: "Average operating hours per day",
    oDay: {
      label: "Average operating hours per day",
      placeholder: "0.0",
      unit: "hrs/day",
    },
    oSeason: {
      label: "Average operating days per season",
      placeholder: "0",
      unit: "days/season",
    },
  },
  cane: {
    title: "CANE GRAB LOADER",
    lCap: {
      val: {
        label: "Loading Capacity",
        placeholder: "0.0",
      },
      unit: {
        label: "Unit",
        placeholder: "Choose",
        options: [
          { label: "tons/load" },
          { label: "tons/day" },
          { label: "kg/load" },
          { label: "kg/day" },
        ],
      },
      load: {
        label: "Load per day",
        placeholder: "0",
        unit: "load/day",
      },
    },
  },
  harvester: {
    title: "HARVESTER",
    eArea: {
      label: "Effective area accomplished",
      placeholder: "0.00",
      unit: "ha",
    },
    time: {
      label: "Time used in working the effective area",
      placeholder: "0.0",
      unit: "hrs",
    },
    fCap: {
      label: "Field Capacity",
      placeholder: "0.0",
      unit: "ha/hr",
    },
    yield: {
      label: "Sugarcane Yield",
      placeholder: "0.0",
      unit: "tons canes/ha",
    },
  },
  wPump: {
    title: "WATER PUMP",
    rgun: "RAIN GUN",
    sSpecs: "Pump Specifications",
    type: {
      label: "Type of Tube Wells",
      placeholder: "Choose an option",
      options: [{ label: "Deep Well" }, { label: "Shallow Well" }],
      options2: [
        { label: "Shallow tubewell (0 to 20 ft)" },
        { label: "Deep well (more than 20 ft)" },
        { label: "Open Source (River, Lake, Canal, Reservoir)" },
      ],
    },
    brand: {
      label: "Brand",
      placeholder: "Select Brand",
    },
    model: {
      label: "Model",
      placeholder: "Select Model",
    },
    size: {
      label: "Size of Pump",
      placeholder: "0.00",
      unit: "in",
    },
    sPrime: "Prime Mover Specifications",
    source: {
      label: "Source of Power",
      placeholder: "Choose source of power",
      options: [
        { label: "Engine" },
        { label: "Solar-Powered Electric Motor" },
        { label: "Tractor PTO" },
      ],
    },
    pBrand: {
      label: "Brand",
      placeholder: "Select Brand",
    },
    pModel: {
      label: "Model",
      placeholder: "Select Model",
    },
    pRPower: {
      label: "Rated Power",
      placeholder: "0.00",
      unit: "hp",
    },
    sOutput: "Water Pump Output",
    discharge: {
      label: "Discharge Capacity",
      placeholder: "0.0",
      unitval: {
        label: "Unit",
        placeholder: "Choose",
      },
      options: [
        { label: "cubic meter / sec" },
        { label: "gallon / sec" },
        { label: "liters / sec" },
      ],
    },
    sArea: {
      label: "Total Service Area",
      placeholder: "0.00",
      unit: "ha",
    },
    // TODO Possible duplicate
    oDayTitle: "Average operating hours per day",
    oDay: {
      label: "Average operating hours per day",
      placeholder: "0.0",
      unit: "hrs/day",
    },
    oSeason: {
      label: "Average operating days per season",
      placeholder: "0",
      unit: "days/season",
    },
  },
  hauler: {
    title: "INFIELD HAULER",
    lCap: {
      val: {
        label: "Loading Capacity",
        placeholder: "0.0",
      },
      unit: {
        label: "Unit",
        placeholder: "Choose",
      },
      load: {
        label: "Load per day",
        placeholder: "0",
        unit: "load/day",
      },
    },
  },
  yes: {
    rate: {
      label: "Consumption Rate",
      placeholder: "0.00",
      unit: "₱",
      unitval: {
        label: "Unit",
        placeholder: "Choose",
      },
    },
    fuel: {
      label: "Fuel Consumption",
      placeholder: "0.00",
      unit: "L/ha",
    },
    avail: {
      label: "Machine Availability",
      placeholder: "Select an option",
    },
    mill: {
      label: "Mill District",
      placeholder: "Select Mill District",
    },
    province: {
      label: "Province",
      placeholder: "Select Province",
    },
    municipality: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    present: {
      label: "Present Condition",
      placeholder: "Select Condition",
    },
    operator: "Number of operators for this machine only",
    ops: {
      label: "Total",
      placeholder: "0",
      unit: "operators",
    },
    exp: "Years of experience",
    exp1: {
      label: "0 - 2 years",
      placeholder: "0",
      unit: "operators",
    },
    exp2: {
      label: "More than 2 years",
      placeholder: "0",
      unit: "operators",
    },
    with: {
      label: "WITH formal training",
      placeholder: "0",
    },
    without: {
      label: "WITHOUT formal training",
      placeholder: "0",
    },
  },
  perf: {
    title: "MACHINE PERFORMANCE",
    used: {
      label: "Hours Used",
      placeholder: "0.0",
      unit: "hrs",
    },
    breakdowns: {
      label: "Number of Breakdowns",
      placeholder: "0",
      unit: "times",
    },
    peak: {
      label: "Peak month of machine usage during operation",
      start: {
        label: "Start",
        placeholder: "Select",
      },
      end: {
        label: "End",
        placeholder: "Select",
      },
      months: {
        label: "Months",
        placeholder: "0",
        unit: "months",
      },
    },
  },
  maint: {
    title: "MAINTENANCE SERVICES",
    options: [
      { label: "Once a year" },
      { label: "Twice a year" },
      { label: "Never" },
    ],
    aFilter: {
      label: "Change Air Filter",
      placeholder: "Select Option",
    },
    eOil: {
      label: "Change Engine Oil",
      placeholder: "Select Option",
    },
    oFilter: {
      label: "Change Engine Oil Filter",
      placeholder: "Select Option",
    },
    hOil: {
      label: "Change Hydraulic Oil",
      placeholder: "Select Option",
    },
    hOFil: {
      label: "Change Hydraulic Oil Filter",
      placeholder: "Select Option",
    },
    tire: {
      label: "Change Tire or Crawler",
      placeholder: "Select Option",
    },
  },
  supplier: {
    title: "Service Provider",
    options: [
      { label: "Local mechanic" },
      { label: "In-house mechanic" },
      { label: "Supplier" },
    ],
    sName: {
      label: "Service Provider",
      placeholder: "Pick service provider",
      "helper-ex":
        "Use comma ',' then press enter to separate names (Example: RBG Tire Central, JDC Repair Shop)",
    },
    address: {
      label: "Address",
      placeholder: "Input Address",
    },
    contact: {
      label: "Contact No.",
      placeholder: "Input Contact No.",
    },
    sProv: {
      label: "Province",
      placeholder: "Select Province",
    },
    sMun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    sBrgy: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
    sTimeRes: {
      label: "Time to respond to concerns",
      placeholder: "Select Option",
      options: [
        { label: "Responds within 24 hours" },
        { label: "Responds after 1-2 days" },
        { label: "Responds after 3 or more days" },
        { label: "Does not respond" },
      ],
    },
    sPartsAvail: {
      label: "Parts Availability",
      placeholder: "Select Option",
      options: [
        { label: "Available always / within 24 hours" },
        { label: "Available after 1-2 days" },
        { label: "Available after 3 or more days" },
        { label: "Not available" },
      ],
    },
  },
  othCrops: {
    title: "",
    question: {
      label: "Is this machine used for other crops?",
      placeholder: "Choose an option",
      options: [
        { label: "Yes", key: 0 },
        { label: "No", key: 1 },
      ],
    },
    sArea: {
      label: "Service Area",
      blank: "",
      rice: {
        text: "Rice",
        label: "",
        placeholder: "0.00",
        unit: "ha",
      },
      corn: {
        text: "Corn",
        label: "",
        placeholder: "0.00",
        unit: "ha",
      },
      othersName: {
        label: "Others, specify crop",
        placeholder: "Crop name",
      },
      othersVal: {
        label: "",
        placeholder: "0.00",
        unit: "ha",
      },
    },
  },
  notes: {
    label: "Survey Notes",
    placeholder: "Enter some notes here",
  },
  message: "Enter some notes here",
  save: "Save Data",
  load: "load",
  notif: {
    noProfile: {
      title: "No Profile Found",
      message:
        "You need to create a profile first before you can proceed with the machine survey.",
      button: "Create Profile",
    },
    qrError: {
      title: "QR Code Error",
      message: "The QR code is not valid. Please scan a valid QR code.",
      center: "Close",
    },
    geoloc: {
      init: {
        title: "Initializing Geolocation",
        message:
          "You can proceed with the survey while waiting for the location.",
        button: "Okay",
      },
      initError: {
        title: "Geolocation Error",
        message:
          "There was an error initializing the geolocation. Your browser doesn't support geolocation feature!",
        button: "Exit",
      },
      success: {
        title: "Geolocation Found",
        button: "Close",
        lat: "Your coordinate is: Lat: ",
        lng: " Long: ",
        acc: " Accuracy: ",
      },
    },
    ope: {
      ok: "OK",
      ope02: {
        title: "Operator count does not match",
        message:
          "The number of machine operator for this machine only should match the sum of operators with 0 - 2 years of experience and those with more than 2 years of experience.",
      },
      with: {
        title: "Operator count does not match",
        message:
          "The number of machine operator for this machine only should match the sum of operators with formal training and without formal training.",
      },
      only: {
        title: "Operator Count Error",
        message:
          "Specify the total number of operators that use this machine above.",
      },
    },
    backup: {
      title: "Unsaved Machine Exists",
      message:
        "Would you like to continue with the unsaved machine? This may take a while.",
      center: "No",
      right: "Yes",
    },
    accuracy: {
      init: "Initializing . . .",
      high: "High Accuracy",
      low: "Low Accuracy",
      search: "Searching for location . . .",
    },
    save: {
      title: "Data Saved",
      message: "The machine data has been saved successfully.",
      center: "Close",
    },
    missing: "Kindly fill out the missing fields above.",
    saveIni: "Initializing profile for saving . . .",
  },
  // Public. Used by Implement Page
  usage: {
    title: "Ownership Details",
    oob: {
      label: "Name of owner, organization or business",
      placeholder: "Input Name",
    },
    class: {
      label: "Owner Classification",
      placeholder: "Choose an option",
      options: [
        { label: "Private Individual or Business" },
        { label: "Organization or Association" },
        { label: "Local Government Unit LGU" },
      ],
    },
    usage: {
      label: "Machine Usage",
      placeholder: "Select usage",
      options: [
        { label: "Private Use" },
        { label: "For rental to members only" },
        { label: "For rental to customers" },
      ],
    },
    mode: {
      label: "Mode of Aquisition",
      placeholder: "Select mode of aquisition",
      // ! The array is statically linked as constant
      options: [
        { label: "Purchased" },
        { label: "Loan" },
        { label: "Grant" },
        { label: "Donation" },
      ],
    },
    agency: {
      label: "Name of Agency",
      placeholder: "Name of Agency",
    },
    acqYear: { label: "Month and Year Acquired" },
    cond: {
      label: "Condition when acquired",
      placeholder: "Select condition",
      // ! The array is statically linked as constant
      options: [
        { label: "Brand new" },
        { label: "Second hand" },
        { label: "Reconditioned" },
        { label: "Data to be acquired" },
      ],
    },
    pCond: {
      label: "Present condition",
      placeholder: "Select condition",
      options: [
        { label: "Functional and used" },
        { label: "Functional but unused" },
        { label: "Non-Functional" },
        { label: "Data to be acquired" },
      ],
    },
    contact: {
      title: "Contact Information if Service Provider",
      same: "Same as owner's information",
      consent:
        "I hereby consent to the inclusion of my information in the M3DAS directory and understand that it may be accessible to the public for mapping purposes.",
    },
    contact1: {
      label: "Business Contact No. 1",
      placeholder: "Mobile or Telephone",
    },
    contact2: {
      label: "Business Contact No. 2",
      placeholder: "Mobile or Telephone",
    },
    spProv: {
      label: "Province",
      placeholder: "Select Province",
    },
    spMun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    spBrgy: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
  },
  unused: {
    uuYear: { label: "Month and year this became unused" },
    subtitle: "Why is this unused?",
    options: [
      { label: "Newly Awarded", key: 0 },
      {
        label: "This is not suitable to the condition of the area",
        key: 1,
      },
      { label: "No one is skilled enough to operate this", key: 2 },
      { label: "Custom hiring rate for this is too high", key: 3 },
      { label: "This was not turned over to the beneficiaries", key: 4 },
    ],
    uuOthers: {
      label: "Others, please specify",
      placeholder: "Input reason",
    },
  },
  unserv: {
    usYear: { label: "Month and year this became unserviceable" },
    subtitle: "Why is this unserviceable?",
    options: [
      { label: "For repair", key: 0 },
      { label: "Too old unrepairable", key: 1 },
      { label: "Obsolete", key: 2 },
    ],
    usOthers: {
      label: "Others, please specify",
      placeholder: "Input reason",
    },
  },
  rent: {
    title: "Renting Details",
    question: {
      label: "For service rental?",
      placeholder: "Choose an option",
      options: [
        { label: "Yes", key: 0 },
        { label: "No", key: 1 },
        { label: "Data to be acquired", key: 2 },
      ],
    },
    fOper: {
      label: "Operation",
      placeholder: "Select operation",
      options: [
        { label: "Land Preparation" },
        { label: "Planting" },
        { label: "Ratoon Management" },
        { label: "Harvesting" },
        { label: "Hauling" },
      ],
    },
    fORate: {
      label: "Service Rate",
      placeholder: "0.00",
      unit: "₱",
      unitval: {
        label: "Unit",
        placeholder: "Choose",
      },
      options: [
        { label: "ha" },
        { label: "hr" },
        { label: "day" },
        { label: "per operation" },
        { label: "tractor only" },
        { label: "implement only" },
        { label: "tractor and implement" },
      ],
    },
    fOFuel: {
      label: "Fuel Consumption",
      placeholder: "0.00",
      unit: "L/ha",
    },
    ope: {
      first: "First Operation",
      second: "Second Operation",
      third: "Third Operation",
    },
  },
};
//////////////////////////////////////////////////////////////////////////
// CONSTANTS AND VARIABLE //////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// Machine Types ///////////////////////////////////////////////////////////////
const tTrc2 = strMac.macInfo.type.options[0];
const tTrc4 = strMac.macInfo.type.options[1];
const tCane = strMac.macInfo.type.options[2];
const tHrvt = strMac.macInfo.type.options[3];
const tPump = strMac.macInfo.type.options[4];
const tHaul = strMac.macInfo.type.options[5];
const tRGun = strMac.macInfo.type.options[6];
const NA = strMac.macInfo.type.options[7];

// General use /////////////////////////////////////////////////////////////////

const MCBKUP = fxdMachineBackup;
var UNUSED_BACKUP: string = "";
var UNSERV_BACKUP: string = "";
var CONSENT_BACKUP: string = "";
var RICE_BACKUP: string = "";
var CORN_BACKUP: string = "";

var QRCODE_BACKUP: string = "";
var ACC_BACKUP: string = "";
var LAT_BACKUP: string = "";
var LNG_BACKUP: string = "";
var IMG_FRONT_BACKUP: string = "";
var IMG_SIDE_BACKUP: string = "";
var IMG_PLATE_BACKUP: string = "";

// /////////////////////////////////////////////////////////////////////////////
// PRELOADER ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export async function machineLoader({ request }: LoaderFunctionArgs) {
  localStorage.setItem(fxdGen.editing, FORMS.machine);

  if (!AUTH_PROVIDER.isAuthenticated) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }

  UNUSED_BACKUP = localStorage.getItem(MCBKUP.unused) ?? "";
  UNSERV_BACKUP = localStorage.getItem(MCBKUP.unserv) ?? "";
  CONSENT_BACKUP = localStorage.getItem(MCBKUP.consent) ?? "";
  RICE_BACKUP = localStorage.getItem(MCBKUP.oRice) ?? "";
  CORN_BACKUP = localStorage.getItem(MCBKUP.oCorn) ?? "";

  QRCODE_BACKUP = localStorage.getItem(MCBKUP.qrCode) ?? "";
  ACC_BACKUP = localStorage.getItem(MCBKUP.acc) ?? "";
  LAT_BACKUP = localStorage.getItem(MCBKUP.lat) ?? "";
  LNG_BACKUP = localStorage.getItem(MCBKUP.lng) ?? "";
  IMG_FRONT_BACKUP = localStorage.getItem(MCBKUP.imageFront) ?? "";
  IMG_SIDE_BACKUP = localStorage.getItem(MCBKUP.imageSide) ?? "";
  IMG_PLATE_BACKUP = localStorage.getItem(MCBKUP.imagePlate) ?? "";

  if (localStorage.getItem(fxdProfile.profiles) == null) {
    Popup({
      icon: "error",
      title: strMac.notif.noProfile.title,
      text: strMac.notif.noProfile.message,
      confirmButtonText: strMac.notif.noProfile.button,
    });
    return redirect(Route.profile);
  } else {
    return null;
  }
}

// /////////////////////////////////////////////////////////////////////////////
// THE MACHINE PAGE ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export default function MachinePage() {
  // Navigation ////////////////////////////////////////////////////////////////

  const GOTO = useNavigate();

  // ///////////////////////////////////////////////////////////////////////////
  // STATES ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Basic Information States //////////////////////////////////////////////////

  const [iResName, setIResName] = useState("");
  const [eResName, setEResName] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iQRCode, setIQRCode] = useState("");
  const [vQRCode, setVQRCode] = useState(false);
  const [eQRCode, setEQRCode] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iImgFront, setIImgFront] = useState("");
  const [vImgFront, setVImgFront] = useState(false);
  const [eImgFront, setEImgFront] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iImgSide, setIImgSide] = useState("");
  const [vImgSide, setVImgSide] = useState(false);
  const [eImgSide, setEImgSide] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iImgPlate, setIImgPlate] = useState("");
  const [vImgPlate, setVImgPlate] = useState(false);
  const [eImgPlate, setEImgPlate] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [coor, setCoor] = useState({
    lat: undefined,
    lng: undefined,
    acc: undefined,
    accs: "",
  });
  const [vCoor, setVCoor] = useState(false);

  const [iLat, setILat] = useState("");
  const [eLat, setELat] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iLng, setILng] = useState("");
  const [eLng, setELng] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iAcc, setIAcc] = useState("");
  const [eAcc, setEAcc] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iLoc, setILoc] = useState("");
  const [eLoc, setELoc] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iProv, setIProv] = useState("");
  const [eProv, setEProv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iMun, setIMun] = useState("");
  const [eMun, setEMun] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iBrgy, setIBrgy] = useState("");
  const [eBrgy, setEBrgy] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iMacType, setIMacType] = useState("");
  const [vMacTypeSel, setVMacTypeSel] = useState(false);
  const [eMacType, setEMacType] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Machine Specification States //////////////////////////////////////////////

  const [iBrand, setIBrand] = useState("");
  const [eBrand, setEBrand] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iModel, setIModel] = useState("");
  const [eModel, setEModel] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iRPower, setIRPower] = useState("");
  const [eRPower, setERPower] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.power },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iSArea, setISArea] = useState("");
  const [eSArea, setESArea] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 99999 },
  } as TGetValidation);

  const [iODay, setIODay] = useState("");
  const [eODay, setEODay] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.value },
    range: { min: 0, max: 24 },
  } as TGetValidation);

  const [iOSeason, setIOSeason] = useState("");
  const [eOSeason, setEOSeason] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.value },
    range: { min: 0, max: 366 },
  } as TGetValidation);

  const [iLCap, setILCap] = useState("");
  const [eLCap, setELCap] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.capacity },
    range: { min: 0, max: 99999 },
  } as TGetValidation);

  const [iLCapUnit, setILCapUnit] = useState("");
  const [eLCapUnit, setELCapUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iLCapLoad, setILCapLoad] = useState("");
  const [eLCapLoad, setELCapLoad] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.load },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iEffArea, setIEffArea] = useState("");
  const [eEffArea, setEEffArea] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iTime, setITime] = useState("");
  const [eTime, setETime] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.time },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  // ! This is auto compute
  //   const [iFCap, setIFCap] = useState("");

  const [iYield, setIYield] = useState("");
  const [eYield, setEYield] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.yield },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  // Water Pump States /////////////////////////////////////////////////////////

  const [iPType, setIPType] = useState("");
  const [ePType, setEPType] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPBrand, setIPBrand] = useState("");
  const [ePBrand, setEPBrand] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPModel, setIPModel] = useState("");
  const [ePModel, setEPModel] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPSize, setIPSize] = useState("");
  const [ePSize, setEPSize] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.size },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iPSource, setIPSource] = useState("");
  const [ePSource, setEPSource] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPSBrand, setIPSBrand] = useState("");
  const [ePSBrand, setEPSBrand] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPSModel, setIPSModel] = useState("");
  const [ePSModel, setEPSModel] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPSRPower, setIPSRPower] = useState("");
  const [ePSRPower, setEPSRPower] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.power },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iPDischarge, setIPDischarge] = useState("");
  const [ePDischarge, setEPDischarge] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.discharge },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iPDisUnit, setIPDisUnit] = useState("");
  const [ePDisUnit, setEPDisUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPSArea, setIPSArea] = useState("");
  const [ePSArea, setEPSArea] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 99999 },
  } as TGetValidation);

  const [iPODay, setIPODay] = useState("");
  const [ePODay, setEPODay] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.value },
    range: { min: 0, max: 12 },
  } as TGetValidation);

  const [iPOSeason, setIPOSeason] = useState("");
  const [ePOSeason, setEPOSeason] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.value },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  // Ownership Details States //////////////////////////////////////////////////

  const [iOOB, setIOOB] = useState("");
  const [eOOB, setEOOB] = useState({
    required: false,
    condition: "name",
    message: { label: strPrf.validation.propname },
  } as TGetValidation);

  const [iClass, setIClass] = useState("");
  const [eClass, setEClass] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iUsage, setIUsage] = useState("");
  const [eUsage, setEUsage] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iMode, setIMode] = useState("");
  const [vMode, setVMode] = useState(false);
  const [eMode, setEMode] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iAgency, setIAgency] = useState("");
  const [eAgency, setEAgency] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iAcqYear, setIAcqYear] = useState("");
  const [eAcqYear, setEAcqYear] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    range: { min: 1949, max: 2024 },
  } as TGetValidation);

  const [iCond, setICond] = useState("");
  const [eCond, setECond] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPCond, setIPCond] = useState("");
  const [ePCond, setEPCond] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iUUYear, setIUUYear] = useState("");
  const [eUUYear, setEUUYear] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    range: { min: 1949, max: 2024 },
  } as TGetValidation);

  const [iUnused, setIUnused] = useState("");
  const [eUnused, setEUnused] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iUUOthers, setIUUOthers] = useState("");
  const [eUUOthers, setEUUOthers] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iUSYear, setIUSYear] = useState("");
  const [eUSYear, setEUSYear] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    range: { min: 1949, max: 2024 },
  } as TGetValidation);

  const [iUnserv, setIUnserv] = useState("");
  const [eUnserv, setEUnserv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iUSOthers, setIUSOthers] = useState("");
  const [eUSOthers, setEUSOthers] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iContact1, setIContact1] = useState("");
  const [eContact1, setEContact1] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.number },
    length: { min: 10, max: 11 },
  } as TGetValidation);

  const [iContact2, setIContact2] = useState("");
  const [eContact2, setEContact2] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.number },
    length: { min: 10, max: 11 },
  } as TGetValidation);

  const [iSPProv, setISPProv] = useState("");
  const [eSPProv, setESPProv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSPMun, setISPMun] = useState("");
  const [eSPMun, setESPMun] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSPBrgy, setISPBrgy] = useState("");
  const [eSPBrgy, setESPBrgy] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iConsent, setIConsent] = useState("");
  const [eConsent, setEConsent] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Renting Detail States /////////////////////////////////////////////////////

  const [iRental, setIRental] = useState("");
  const [eRental, setERental] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iFOper, setIFOper] = useState("");
  const [eFOper, setEFOper] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iFORate, setIFORate] = useState("");
  const [eFORate, setEFORate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    range: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iFORateUnit, setIFORateUnit] = useState("");
  const [eFORateUnit, setEFORateUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iFOFuel, setIFOFuel] = useState("");
  const [eFOFuel, setEFOFuel] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.fuel },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iSOper, setISOper] = useState("");
  const [eSOper, setESOper] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSORate, setISORate] = useState("");
  const [eSORate, setESORate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    range: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iSORateUnit, setISORateUnit] = useState("");
  const [eSORateUnit, setESORateUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSOFuel, setISOFuel] = useState("");
  const [eSOFuel, setESOFuel] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.fuel },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iTOper, setITOper] = useState("");
  const [eTOper, setETOper] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iTORate, setITORate] = useState("");
  const [eTORate, setETORate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    range: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iTORateUnit, setITORateUnit] = useState("");
  const [eTORateUnit, setETORateUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iTOFuel, setITOFuel] = useState("");
  const [eTOFuel, setETOFuel] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.fuel },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iOps, setIOps] = useState("");
  const [eOps, setEOps] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.operator },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iOpe02, setIOpe02] = useState("");
  const [eOpe02, setEOpe02] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.operator },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iOpe2Abv, setIOpe2Abv] = useState("");
  const [eOpe2Abv, setEOpe2Abv] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.operator },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iOpeWith, setIOpeWith] = useState("");
  const [eOpeWith, setEOpeWith] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.operator },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iOpeWithout, setIOpeWithout] = useState("");
  const [eOpeWithout, setEOpeWithout] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.operator },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  // Machine Performance States ////////////////////////////////////////////////

  const [iUsed, setIUsed] = useState("");
  const [eUsed, setEUsed] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.hour },
    range: { min: 0, max: 999999 },
  } as TGetValidation);

  const [iBreakdowns, setIBreakdowns] = useState("");
  const [eBreakdowns, setEBreakdowns] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.breakdown },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  // Maintenance Service States ////////////////////////////////////////////////

  const [iAFilter, setIAFilter] = useState("");
  const [eAFilter, setEAFilter] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iEOil, setIEOil] = useState("");
  const [eEOil, setEEOil] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOFilter, setIOFilter] = useState("");
  const [eOFilter, setEOFilter] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iHOil, setIHOil] = useState("");
  const [eHOil, setEHOil] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iHOFil, setIHOFil] = useState("");
  const [eHOFil, setEHOFil] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iTire, setITire] = useState("");
  const [eTire, setETire] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSName, setISName] = useState("");
  const [eSName, setESName] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSProv, setISProv] = useState("");
  const [eSProv, setESProv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSMun, setISMun] = useState("");
  const [eSMun, setESMun] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSBrgy, setISBrgy] = useState("");
  const [eSBrgy, setESBrgy] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSTimeRes, setISTimeRes] = useState("");
  const [eSTimeRes, setESTimeRes] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSPartsAvail, setISPartsAvail] = useState("");
  const [eSPartsAvail, setESPartsAvail] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Other Crops States ////////////////////////////////////////////////////////

  const [iOthCrp, setIOthCrp] = useState("");
  const [vOthCrp, setVOthCrp] = useState(false);
  const [eOthCrp, setEOthCrp] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iORice, setIORice] = useState("");
  const [eORice, setEORice] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iORSArea, setIORSArea] = useState("");
  const [eORSArea, setEORSArea] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iOCorn, setIOCorn] = useState("");
  const [eOCorn, setEOCorn] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOCSArea, setIOCSArea] = useState("");
  const [eOCSArea, setEOCSArea] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iOOth, setIOOth] = useState("");
  const [eOOth, setEOOth] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOOArea, setIOOArea] = useState("");
  const [eOOArea, setEOOArea] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  // Notes States //////////////////////////////////////////////////////////////

  const [iNotes, setINotes] = useState("");
  const [eNotes, setENotes] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // ///////////////////////////////////////////////////////////////////////////
  // CONSTANTS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Address ///////////////////////////////////////////////////////////////////

  const adMac: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  const adPvd: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  const adYes: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  const adAts: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  // Questions /////////////////////////////////////////////////////////////////

  type QUESTION = {
    id: number;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    errorMessage: TGetValidation;
    setErrorMessage: React.Dispatch<React.SetStateAction<TGetValidation>>;
    backup: string;
    label: string;
  };

  const QUESTIONS: QUESTION[] = [
    // Basic Information
    {
      id: 1,
      value: iResName,
      setValue: setIResName,
      errorMessage: eResName,
      setErrorMessage: setEResName,
      backup: MCBKUP.resName,
      label: strMac.bscInfo.resName.label,
    },
    {
      id: 2,
      value: iQRCode,
      setValue: setIQRCode,
      errorMessage: eQRCode,
      setErrorMessage: setEQRCode,
      backup: MCBKUP.qrCode,
      label: strMac.bscInfo.qrcode.label,
    },
    {
      id: 3,
      value: iImgFront,
      setValue: setIImgFront,
      errorMessage: eImgFront,
      setErrorMessage: setEImgFront,
      backup: MCBKUP.imageFront,
      label: strMac.bscInfo.front.label,
    },
    {
      id: 4,
      value: iImgSide,
      setValue: setIImgSide,
      errorMessage: eImgSide,
      setErrorMessage: setEImgSide,
      backup: MCBKUP.imageSide,
      label: strMac.bscInfo.side.label,
    },
    {
      id: 5,
      value: iImgPlate,
      setValue: setIImgPlate,
      errorMessage: eImgPlate,
      setErrorMessage: setEImgPlate,
      backup: MCBKUP.imagePlate,
      label: strMac.bscInfo.plate.label,
    },
    {
      id: 6,
      value: iLat,
      setValue: setILat,
      errorMessage: eLat,
      setErrorMessage: setELat,
      backup: MCBKUP.lat,
      label: strMac.bscInfo.latitude.label,
    },
    {
      id: 7,
      value: iLng,
      setValue: setILng,
      errorMessage: eLng,
      setErrorMessage: setELng,
      backup: MCBKUP.lng,
      label: strMac.bscInfo.longitude.label,
    },
    {
      id: 8,
      value: iAcc,
      setValue: setIAcc,
      errorMessage: eAcc,
      setErrorMessage: setEAcc,
      backup: MCBKUP.acc,
      label: strMac.bscInfo.accuracy.label,
    },
    {
      id: 9,
      value: iLoc,
      setValue: setILoc,
      errorMessage: eLoc,
      setErrorMessage: setELoc,
      backup: MCBKUP.loc,
      label: strMac.bscInfo.location.label,
    },
    {
      id: 10,
      value: iProv,
      setValue: setIProv,
      errorMessage: eProv,
      setErrorMessage: setEProv,
      backup: MCBKUP.prov,
      label: strMac.bscInfo.province.label,
    },
    {
      id: 11,
      value: iMun,
      setValue: setIMun,
      errorMessage: eMun,
      setErrorMessage: setEMun,
      backup: MCBKUP.mun,
      label: strMac.bscInfo.municipality.label,
    },
    {
      id: 12,
      value: iBrgy,
      setValue: setIBrgy,
      errorMessage: eBrgy,
      setErrorMessage: setEBrgy,
      backup: MCBKUP.brgy,
      label: strMac.bscInfo.barangay.label,
    },
    {
      id: 13,
      value: iMacType,
      setValue: setIMacType,
      errorMessage: eMacType,
      setErrorMessage: setEMacType,
      backup: MCBKUP.macType,
      label: strMac.macInfo.type.label,
    },
    // Machine Specifications
    {
      id: 14,
      value: iBrand,
      setValue: setIBrand,
      errorMessage: eBrand,
      setErrorMessage: setEBrand,
      backup: MCBKUP.brand,
      label: strMac.macInfo.brand.label,
    },
    {
      id: 15,
      value: iModel,
      setValue: setIModel,
      errorMessage: eModel,
      setErrorMessage: setEModel,
      backup: MCBKUP.model,
      label: strMac.macInfo.model.label,
    },
    {
      id: 16,
      value: iRPower,
      setValue: setIRPower,
      errorMessage: eRPower,
      setErrorMessage: setERPower,
      backup: MCBKUP.rPower,
      label: strMac.macInfo.rPower.label,
    },
    {
      id: 17,
      value: iSArea,
      setValue: setISArea,
      errorMessage: eSArea,
      setErrorMessage: setESArea,
      backup: MCBKUP.sArea,
      label: strMac.macInfo.sArea.label,
    },
    {
      id: 18,
      value: iODay,
      setValue: setIODay,
      errorMessage: eODay,
      setErrorMessage: setEODay,
      backup: MCBKUP.oDay,
      label: strMac.macInfo.oDay.label,
    },
    {
      id: 19,
      value: iOSeason,
      setValue: setIOSeason,
      errorMessage: eOSeason,
      setErrorMessage: setEOSeason,
      backup: MCBKUP.oSeason,
      label: strMac.macInfo.oSeason.label,
    },
    {
      id: 20,
      value: iLCap,
      setValue: setILCap,
      errorMessage: eLCap,
      setErrorMessage: setELCap,
      backup: MCBKUP.lCap,
      label: strMac.cane.lCap.val.label,
    },
    {
      id: 21,
      value: iLCapUnit,
      setValue: setILCapUnit,
      errorMessage: eLCapUnit,
      setErrorMessage: setELCapUnit,
      backup: MCBKUP.lCapUnit,
      label: strMac.cane.lCap.unit.label,
    },
    {
      id: 22,
      value: iLCapLoad,
      setValue: setILCapLoad,
      errorMessage: eLCapLoad,
      setErrorMessage: setELCapLoad,
      backup: MCBKUP.lCapLoad,
      label: strMac.cane.lCap.load.label,
    },
    {
      id: 23,
      value: iEffArea,
      setValue: setIEffArea,
      errorMessage: eEffArea,
      setErrorMessage: setEEffArea,
      backup: MCBKUP.effArea,
      label: strMac.harvester.eArea.label,
    },
    {
      id: 24,
      value: iTime,
      setValue: setITime,
      errorMessage: eTime,
      setErrorMessage: setETime,
      backup: MCBKUP.time,
      label: strMac.harvester.time.label,
    },
    {
      id: 25,
      value: iYield,
      setValue: setIYield,
      errorMessage: eYield,
      setErrorMessage: setEYield,
      backup: MCBKUP.yield,
      label: strMac.harvester.yield.label,
    },
    {
      id: 26,
      value: iPType,
      setValue: setIPType,
      errorMessage: ePType,
      setErrorMessage: setEPType,
      backup: MCBKUP.pType,
      label: strMac.wPump.type.label,
    },
    {
      id: 27,
      value: iPBrand,
      setValue: setIPBrand,
      errorMessage: ePBrand,
      setErrorMessage: setEPBrand,
      backup: MCBKUP.pBrand,
      label: strMac.wPump.brand.label,
    },
    {
      id: 28,
      value: iPModel,
      setValue: setIPModel,
      errorMessage: ePModel,
      setErrorMessage: setEPModel,
      backup: MCBKUP.pModel,
      label: strMac.wPump.model.label,
    },
    {
      id: 29,
      value: iPSize,
      setValue: setIPSize,
      errorMessage: ePSize,
      setErrorMessage: setEPSize,
      backup: MCBKUP.pSize,
      label: strMac.wPump.size.label,
    },
    {
      id: 30,
      value: iPSource,
      setValue: setIPSource,
      errorMessage: ePSource,
      setErrorMessage: setEPSource,
      backup: MCBKUP.pSource,
      label: strMac.wPump.source.label,
    },
    {
      id: 31,
      value: iPSBrand,
      setValue: setIPSBrand,
      errorMessage: ePSBrand,
      setErrorMessage: setEPSBrand,
      backup: MCBKUP.pSBrand,
      label: strMac.wPump.pBrand.label,
    },
    {
      id: 32,
      value: iPSModel,
      setValue: setIPSModel,
      errorMessage: ePSModel,
      setErrorMessage: setEPSModel,
      backup: MCBKUP.pSModel,
      label: strMac.wPump.pModel.label,
    },
    {
      id: 33,
      value: iPSRPower,
      setValue: setIPSRPower,
      errorMessage: ePSRPower,
      setErrorMessage: setEPSRPower,
      backup: MCBKUP.pSRPower,
      label: strMac.wPump.pRPower.label,
    },
    {
      id: 34,
      value: iPDischarge,
      setValue: setIPDischarge,
      errorMessage: ePDischarge,
      setErrorMessage: setEPDischarge,
      backup: MCBKUP.pDischarge,
      label: strMac.wPump.discharge.label,
    },
    {
      id: 35,
      value: iPDisUnit,
      setValue: setIPDisUnit,
      errorMessage: ePDisUnit,
      setErrorMessage: setEPDisUnit,
      backup: MCBKUP.pDisUnit,
      label: strMac.wPump.discharge.unitval.label,
    },
    {
      id: 36,
      value: iPSArea,
      setValue: setIPSArea,
      errorMessage: ePSArea,
      setErrorMessage: setEPSArea,
      backup: MCBKUP.pSArea,
      label: strMac.wPump.sArea.label,
    },
    {
      id: 37,
      value: iPODay,
      setValue: setIPODay,
      errorMessage: ePODay,
      setErrorMessage: setEPODay,
      backup: MCBKUP.pODay,
      label: strMac.wPump.oDay.label,
    },
    {
      id: 38,
      value: iPOSeason,
      setValue: setIPOSeason,
      errorMessage: ePOSeason,
      setErrorMessage: setEPOSeason,
      backup: MCBKUP.pOSeason,
      label: strMac.wPump.oSeason.label,
    },
    // Ownership Details
    {
      id: 39,
      value: iOOB,
      setValue: setIOOB,
      errorMessage: eOOB,
      setErrorMessage: setEOOB,
      backup: MCBKUP.oob,
      label: strMac.usage.oob.label,
    },
    {
      id: 40,
      value: iClass,
      setValue: setIClass,
      errorMessage: eClass,
      setErrorMessage: setEClass,
      backup: MCBKUP.class,
      label: strMac.usage.class.label,
    },
    {
      id: 41,
      value: iUsage,
      setValue: setIUsage,
      errorMessage: eUsage,
      setErrorMessage: setEUsage,
      backup: MCBKUP.usage,
      label: strMac.usage.usage.label,
    },
    {
      id: 42,
      value: iMode,
      setValue: setIMode,
      errorMessage: eMode,
      setErrorMessage: setEMode,
      backup: MCBKUP.mode,
      label: strMac.usage.mode.label,
    },
    {
      id: 43,
      value: iAgency,
      setValue: setIAgency,
      errorMessage: eAgency,
      setErrorMessage: setEAgency,
      backup: MCBKUP.agency,
      label: strMac.usage.agency.label,
    },
    {
      id: 44,
      value: iAcqYear,
      setValue: setIAcqYear,
      errorMessage: eAcqYear,
      setErrorMessage: setEAcqYear,
      backup: MCBKUP.acqYear,
      label: strMac.usage.acqYear.label,
    },
    {
      id: 45,
      value: iCond,
      setValue: setICond,
      errorMessage: eCond,
      setErrorMessage: setECond,
      backup: MCBKUP.cond,
      label: strMac.usage.cond.label,
    },
    {
      id: 46,
      value: iPCond,
      setValue: setIPCond,
      errorMessage: ePCond,
      setErrorMessage: setEPCond,
      backup: MCBKUP.pCond,
      label: strMac.usage.pCond.label,
    },
    //
    {
      id: 47,
      value: iUUYear,
      setValue: setIUUYear,
      errorMessage: eUUYear,
      setErrorMessage: setEUUYear,
      backup: MCBKUP.uuYear,
      label: strMac.unused.uuYear.label,
    },
    {
      id: 48,
      value: iUnused,
      setValue: setIUnused,
      errorMessage: eUnused,
      setErrorMessage: setEUnused,
      backup: MCBKUP.unused,
      label: strMac.unused.subtitle,
    },
    {
      id: 49,
      value: iUUOthers,
      setValue: setIUUOthers,
      errorMessage: eUUOthers,
      setErrorMessage: setEUUOthers,
      backup: MCBKUP.uuOthers,
      label: strMac.unused.subtitle + " " + strMac.unused.uuOthers.label,
    },
    {
      id: 50,
      value: iUSYear,
      setValue: setIUSYear,
      errorMessage: eUSYear,
      setErrorMessage: setEUSYear,
      backup: MCBKUP.usYear,
      label: strMac.unserv.usYear.label,
    },
    {
      id: 51,
      value: iUnserv,
      setValue: setIUnserv,
      errorMessage: eUnserv,
      setErrorMessage: setEUnserv,
      backup: MCBKUP.unserv,
      label: strMac.unserv.subtitle,
    },
    {
      id: 52,
      value: iUSOthers,
      setValue: setIUSOthers,
      errorMessage: eUSOthers,
      setErrorMessage: setEUSOthers,
      backup: MCBKUP.usOthers,
      label: strMac.unserv.subtitle + " " + strMac.unserv.usOthers.label,
    },
    //
    {
      id: 53,
      value: iContact1,
      setValue: setIContact1,
      errorMessage: eContact1,
      setErrorMessage: setEContact1,
      backup: MCBKUP.contact1,
      label: strMac.usage.contact1.label,
    },
    {
      id: 54,
      value: iContact2,
      setValue: setIContact2,
      errorMessage: eContact2,
      setErrorMessage: setEContact2,
      backup: MCBKUP.contact2,
      label: strMac.usage.contact2.label,
    },
    {
      id: 55,
      value: iSPProv,
      setValue: setISPProv,
      errorMessage: eSPProv,
      setErrorMessage: setESPProv,
      backup: MCBKUP.spProv,
      label: strMac.usage.spProv.label,
    },
    {
      id: 56,
      value: iSPMun,
      setValue: setISPMun,
      errorMessage: eSPMun,
      setErrorMessage: setESPMun,
      backup: MCBKUP.spMun,
      label: strMac.usage.spMun.label,
    },
    {
      id: 57,
      value: iSPBrgy,
      setValue: setISPBrgy,
      errorMessage: eSPBrgy,
      setErrorMessage: setESPBrgy,
      backup: MCBKUP.spBrgy,
      label: strMac.usage.spBrgy.label,
    },
    {
      id: 58,
      value: iConsent,
      setValue: setIConsent,
      errorMessage: eConsent,
      setErrorMessage: setEConsent,
      backup: MCBKUP.consent,
      label: "",
    },
    // Renting Details
    {
      id: 59,
      value: iRental,
      setValue: setIRental,
      errorMessage: eRental,
      setErrorMessage: setERental,
      backup: MCBKUP.rental,
      label: strMac.rent.question.label,
    },
    {
      id: 60,
      value: iFOper,
      setValue: setIFOper,
      errorMessage: eFOper,
      setErrorMessage: setEFOper,
      backup: MCBKUP.fOper,
      label: strMac.rent.ope.first,
    },
    {
      id: 61,
      value: iFORate,
      setValue: setIFORate,
      errorMessage: eFORate,
      setErrorMessage: setEFORate,
      backup: MCBKUP.fORate,
      label: strMac.rent.ope.first + " " + strMac.rent.fORate.label,
    },
    {
      id: 62,
      value: iFORateUnit,
      setValue: setIFORateUnit,
      errorMessage: eFORateUnit,
      setErrorMessage: setEFORateUnit,
      backup: MCBKUP.fORateUnit,
      label:
        strMac.rent.ope.first +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    {
      id: 63,
      value: iFOFuel,
      setValue: setIFOFuel,
      errorMessage: eFOFuel,
      setErrorMessage: setEFOFuel,
      backup: MCBKUP.fOFuel,
      label: strMac.rent.ope.first + " " + strMac.rent.fOFuel.label,
    },
    {
      id: 64,
      value: iSOper,
      setValue: setISOper,
      errorMessage: eSOper,
      setErrorMessage: setESOper,
      backup: MCBKUP.sOper,
      label: strMac.rent.ope.second,
    },
    {
      id: 65,
      value: iSORate,
      setValue: setISORate,
      errorMessage: eSORate,
      setErrorMessage: setESORate,
      backup: MCBKUP.sORate,
      label: strMac.rent.ope.second + " " + strMac.rent.fORate.label,
    },
    {
      id: 66,
      value: iSORateUnit,
      setValue: setISORateUnit,
      errorMessage: eSORateUnit,
      setErrorMessage: setESORateUnit,
      backup: MCBKUP.sORateUnit,
      label:
        strMac.rent.ope.second +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    {
      id: 67,
      value: iSOFuel,
      setValue: setISOFuel,
      errorMessage: eSOFuel,
      setErrorMessage: setESOFuel,
      backup: MCBKUP.sOFuel,
      label: strMac.rent.ope.second + " " + strMac.rent.fOFuel.label,
    },
    {
      id: 68,
      value: iTOper,
      setValue: setITOper,
      errorMessage: eTOper,
      setErrorMessage: setETOper,
      backup: MCBKUP.tOper,
      label: strMac.rent.ope.third,
    },
    {
      id: 69,
      value: iTORate,
      setValue: setITORate,
      errorMessage: eTORate,
      setErrorMessage: setETORate,
      backup: MCBKUP.tORate,
      label: strMac.rent.ope.third + " " + strMac.rent.fORate.label,
    },
    {
      id: 70,
      value: iTORateUnit,
      setValue: setITORateUnit,
      errorMessage: eTORateUnit,
      setErrorMessage: setETORateUnit,
      backup: MCBKUP.tORateUnit,
      label:
        strMac.rent.ope.third +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    {
      id: 71,
      value: iTOFuel,
      setValue: setITOFuel,
      errorMessage: eTOFuel,
      setErrorMessage: setETOFuel,
      backup: MCBKUP.tOFuel,
      label: strMac.rent.ope.third + " " + strMac.rent.fOFuel.label,
    },
    //
    {
      id: 72,
      value: iOps,
      setValue: setIOps,
      errorMessage: eOps,
      setErrorMessage: setEOps,
      backup: MCBKUP.ops,
      label: strMac.yes.operator,
    },
    {
      id: 73,
      value: iOpe02,
      setValue: setIOpe02,
      errorMessage: eOpe02,
      setErrorMessage: setEOpe02,
      backup: MCBKUP.ope02,
      label: strMac.yes.operator,
    },
    {
      id: 74,
      value: iOpe2Abv,
      setValue: setIOpe2Abv,
      errorMessage: eOpe2Abv,
      setErrorMessage: setEOpe2Abv,
      backup: MCBKUP.ope2Abv,
      label: strMac.yes.operator,
    },
    {
      id: 75,
      value: iOpeWith,
      setValue: setIOpeWith,
      errorMessage: eOpeWith,
      setErrorMessage: setEOpeWith,
      backup: MCBKUP.opeWith,
      label: strMac.yes.operator,
    },
    {
      id: 76,
      value: iOpeWithout,
      setValue: setIOpeWithout,
      errorMessage: eOpeWithout,
      setErrorMessage: setEOpeWithout,
      backup: MCBKUP.opeWithout,
      label: strMac.yes.operator,
    },
    // Machine Performance
    {
      id: 77,
      value: iUsed,
      setValue: setIUsed,
      errorMessage: eUsed,
      setErrorMessage: setEUsed,
      backup: MCBKUP.used,
      label: strMac.perf.used.label,
    },
    {
      id: 78,
      value: iBreakdowns,
      setValue: setIBreakdowns,
      errorMessage: eBreakdowns,
      setErrorMessage: setEBreakdowns,
      backup: MCBKUP.breakdowns,
      label: strMac.perf.breakdowns.label,
    },
    // Maintenance Service
    {
      id: 79,
      value: iAFilter,
      setValue: setIAFilter,
      errorMessage: eAFilter,
      setErrorMessage: setEAFilter,
      backup: MCBKUP.aFilter,
      label: strMac.maint.aFilter.label,
    },
    {
      id: 80,
      value: iEOil,
      setValue: setIEOil,
      errorMessage: eEOil,
      setErrorMessage: setEEOil,
      backup: MCBKUP.eOil,
      label: strMac.maint.eOil.label,
    },
    {
      id: 81,
      value: iOFilter,
      setValue: setIOFilter,
      errorMessage: eOFilter,
      setErrorMessage: setEOFilter,
      backup: MCBKUP.oFilter,
      label: strMac.maint.oFilter.label,
    },
    {
      id: 82,
      value: iHOil,
      setValue: setIHOil,
      errorMessage: eHOil,
      setErrorMessage: setEHOil,
      backup: MCBKUP.hOil,
      label: strMac.maint.hOil.label,
    },
    {
      id: 83,
      value: iHOFil,
      setValue: setIHOFil,
      errorMessage: eHOFil,
      setErrorMessage: setEHOFil,
      backup: MCBKUP.hOFil,
      label: strMac.maint.hOFil.label,
    },
    {
      id: 84,
      value: iTire,
      setValue: setITire,
      errorMessage: eTire,
      setErrorMessage: setETire,
      backup: MCBKUP.tire,
      label: strMac.maint.tire.label,
    },
    //
    {
      id: 85,
      value: iSName,
      setValue: setISName,
      errorMessage: eSName,
      setErrorMessage: setESName,
      backup: MCBKUP.sName,
      label: strMac.supplier.sName.label,
    },
    {
      id: 86,
      value: iSProv,
      setValue: setISProv,
      errorMessage: eSProv,
      setErrorMessage: setESProv,
      backup: MCBKUP.sProv,
      label: strMac.supplier.sProv.label,
    },
    {
      id: 87,
      value: iSMun,
      setValue: setISMun,
      errorMessage: eSMun,
      setErrorMessage: setESMun,
      backup: MCBKUP.sMun,
      label: strMac.supplier.sMun.label,
    },
    {
      id: 88,
      value: iSBrgy,
      setValue: setISBrgy,
      errorMessage: eSBrgy,
      setErrorMessage: setESBrgy,
      backup: MCBKUP.sBrgy,
      label: strMac.supplier.sBrgy.label,
    },
    {
      id: 89,
      value: iSTimeRes,
      setValue: setISTimeRes,
      errorMessage: eSTimeRes,
      setErrorMessage: setESTimeRes,
      backup: MCBKUP.sTimeRes,
      label: strMac.supplier.sTimeRes.label,
    },
    {
      id: 90,
      value: iSPartsAvail,
      setValue: setISPartsAvail,
      errorMessage: eSPartsAvail,
      setErrorMessage: setESPartsAvail,
      backup: MCBKUP.sPartsAvail,
      label: strMac.supplier.sPartsAvail.label,
    },
    // Other Crops
    {
      id: 91,
      value: iOthCrp,
      setValue: setIOthCrp,
      errorMessage: eOthCrp,
      setErrorMessage: setEOthCrp,
      backup: MCBKUP.othCrp,
      label: strMac.othCrops.question.label,
    },
    {
      id: 92,
      value: iORice,
      setValue: setIORice,
      errorMessage: eORice,
      setErrorMessage: setEORice,
      backup: MCBKUP.oRice,
      label: "",
    },
    {
      id: 93,
      value: iORSArea,
      setValue: setIORSArea,
      errorMessage: eORSArea,
      setErrorMessage: setEORSArea,
      backup: MCBKUP.oRSArea,
      label:
        strMac.othCrops.sArea.rice.label + " " + strMac.othCrops.sArea.label,
    },
    {
      id: 94,
      value: iOCorn,
      setValue: setIOCorn,
      errorMessage: eOCorn,
      setErrorMessage: setEOCorn,
      backup: MCBKUP.oCorn,
      label: "",
    },
    {
      id: 95,
      value: iOCSArea,
      setValue: setIOCSArea,
      errorMessage: eOCSArea,
      setErrorMessage: setEOCSArea,
      backup: MCBKUP.oCSArea,
      label:
        strMac.othCrops.sArea.corn.label + " " + strMac.othCrops.sArea.label,
    },
    {
      id: 96,
      value: iOOth,
      setValue: setIOOth,
      errorMessage: eOOth,
      setErrorMessage: setEOOth,
      backup: MCBKUP.oOth,
      label: "",
    },
    {
      id: 97,
      value: iOOArea,
      setValue: setIOOArea,
      errorMessage: eOOArea,
      setErrorMessage: setEOOArea,
      backup: MCBKUP.oOArea,
      label: strMac.othCrops.sArea.label,
    },
    {
      id: 98,
      value: iNotes,
      setValue: setINotes,
      errorMessage: eNotes,
      setErrorMessage: setENotes,
      backup: MCBKUP.notes,
      label: strMac.notes.label,
    },
  ];

  // ///////////////////////////////////////////////////////////////////////////
  // VISIBILITY ////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Machine based visibility //////////////////////////////////////////////////

  // Common Specs Card
  const [vCSpecs, setVCSpecs] = useState(false);
  // WaterPump Specs Card
  const [vPSpecs, setVPSpecs] = useState(false);
  // Specifics
  const [vCnHlr, setVCnHlr] = useState(false);
  const [vHrv, setVHrv] = useState(false);
  const [vRent, setVRent] = useState(false);
  const [vOCrops, setVOCrops] = useState(false);

  useEffect(() => {
    var macType = iMacType ? (iMacType as any).label : iMacType;
    macType ? setVMacTypeSel(true) : setVMacTypeSel(false);

    const T = true;
    const F = false;

    switch (macType) {
      case tTrc2.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: F, hrv: F, rent: T, othCrops: T });
        break;
      case tTrc4.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: F, hrv: F, rent: T, othCrops: T });
        break;
      case tCane.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: T, hrv: F, rent: T, othCrops: F });
        break;
      case tHrvt.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: F, hrv: T, rent: T, othCrops: F });
        break;
      case tPump.label:
        views({ cSpecs: F, pSpecs: T, cnHlr: F, hrv: F, rent: T, othCrops: F });
        break;
      case tHaul.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: T, hrv: F, rent: T, othCrops: F });
        break;
      case tRGun.label:
        views({ cSpecs: F, pSpecs: T, cnHlr: F, hrv: F, rent: T, othCrops: F });
        break;
      default: {
        views({ cSpecs: T, pSpecs: F, cnHlr: T, hrv: F, rent: T, othCrops: F });
        break;
      }
    }
  }, [iMacType]);

  function views(p: {
    cSpecs: boolean;
    pSpecs: boolean;
    cnHlr: boolean;
    hrv: boolean;
    rent: boolean;
    othCrops: boolean;
  }) {
    setVCSpecs(p.cSpecs);
    setVPSpecs(p.pSpecs);
    setVCnHlr(p.cnHlr);
    setVHrv(p.hrv);
    setVRent(p.rent);
    setVOCrops(p.othCrops);
  }

  // Present condition /////////////////////////////////////////////////////////

  const [vUsed, setVUsed] = useState(false);
  const [vUnUsd, setVUnUsd] = useState(false);

  useEffect(() => {
    var pcond = iPCond ? (iPCond as any).label : iPCond;
    setVUsed(false);
    setVUnUsd(false);

    if (pcond == strMac.usage.pCond.options[1].label) {
      setVUsed(true);
    } else if (pcond == strMac.usage.pCond.options[2].label) {
      setVUnUsd(true);
    }
  }, [iPCond]);

  // Other crops ///////////////////////////////////////////////////////////////

  useEffect(() => {
    var othCrp = iOthCrp ? (iOthCrp as any).label : iOthCrp;
    othCrp == strMac.othCrops.question.options[0].label
      ? setVOthCrp(true)
      : setVOthCrp(false);
  }, [iOthCrp]);

  // Checks ////////////////////////////////////////////////////////////////////

  // Case unused ///////////////////////////////////////////////////////////////
  const cUnused = [
    useState(false),
    useState(false),
    useState(false),
    useState(false),
    useState(false),
  ];

  useEffect(() => {
    if (localStorage.getItem(fxdGen.editing) == FORMS.machine) {
      var unused = "";
      for (let i = 0; i < cUnused.length; i++)
        if (cUnused[i][GET]) unused += strMac.unused.options[i].label + ", ";
      unused.trimEnd();
      if (unused.length > 0) unused = unused.substring(0, unused.length - 2);
      setIUnused(unused);
      backup(unused, MCBKUP.unused);
    }
  }, [cUnused]);

  // Case unserviceable ////////////////////////////////////////////////////////
  const cUnserviceable = [useState(false), useState(false), useState(false)];

  useEffect(() => {
    if (localStorage.getItem(fxdGen.editing) == FORMS.machine) {
      var unserv = "";
      for (let i = 0; i < cUnserviceable.length; i++)
        if (cUnserviceable[i][GET])
          unserv += strMac.unserv.options[i].label + ", ";
      unserv.trimEnd();
      if (unserv.length > 0) unserv = unserv.substring(0, unserv.length - 2);
      setIUnserv(unserv);
      backup(unserv, MCBKUP.unserv);
    }
  }, [cUnserviceable]);

  // Consent statement /////////////////////////////////////////////////////////
  const cConsent = [useState(false)];

  useEffect(() => {
    if (localStorage.getItem(fxdGen.editing) == FORMS.machine) {
      setIConsent(String(cConsent[0][GET]));
      backup(String(cConsent[0][GET]), MCBKUP.consent);
    }
  }, [cConsent]);

  // Rice and Corn /////////////////////////////////////////////////////////////
  const cRice = [useState(false)];

  useEffect(() => {
    if (localStorage.getItem(fxdGen.editing) == FORMS.machine) {
      setIORice(String(cRice[0][GET]));
      backup(String(cRice[0][GET]), MCBKUP.oRice);
    }
  }, [cRice]);

  const cCorn = [useState(false)];

  useEffect(() => {
    if (localStorage.getItem(fxdGen.editing) == FORMS.machine) {
      setIOCorn(String(cCorn[0][GET]));
      backup(String(cCorn[0][GET]), MCBKUP.oCorn);
    }
  }, [cCorn]);

  // Disabled //////////////////////////////////////////////////////////////////

  const [disQRBtn, setDisQRBtn] = useState(false);
  const [disCamBtnFront, setDisCamBtnFront] = useState(false);
  const [disCamBtnSide, setDisCamBtnSide] = useState(false);
  const [disCamBtnPlate, setDisCamBtnPlate] = useState(false);

  useEffect(() => {
    if (vQRCode) {
      setVImgFront(false);
      setVImgSide(false);
      setVImgPlate(false);

      setDisQRBtn(false);
      setDisCamBtnFront(true);
      setDisCamBtnSide(true);
      setDisCamBtnPlate(true);
    } else if (vImgFront) {
      setVQRCode(false);
      setVImgSide(false);
      setVImgPlate(false);

      setDisQRBtn(true);
      setDisCamBtnFront(false);
      setDisCamBtnSide(true);
      setDisCamBtnPlate(true);
    } else if (vImgSide) {
      setVQRCode(false);
      setVImgFront(false);
      setVImgPlate(false);

      setDisQRBtn(true);
      setDisCamBtnFront(true);
      setDisCamBtnSide(false);
      setDisCamBtnPlate(true);
    } else if (vImgPlate) {
      setVQRCode(false);
      setVImgFront(false);
      setVImgSide(false);

      setDisQRBtn(true);
      setDisCamBtnFront(true);
      setDisCamBtnSide(true);
      setDisCamBtnPlate(false);
    } else {
      setDisQRBtn(false);
      setDisCamBtnFront(false);
      setDisCamBtnSide(false);
      setDisCamBtnPlate(false);
    }
  }, [vQRCode, vImgFront, vImgSide, vImgPlate]);

  // ///////////////////////////////////////////////////////////////////////////
  // FUNCTIONS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Backup operations /////////////////////////////////////////////////////////

  function deleteMachineBackups() {
    Object.values(MCBKUP).forEach((val, index) => localStorage.removeItem(val));
  }

  function recoverMachineBackups() {
    // Recover all field and pick type questions
    for (let i = 0; i < QUESTIONS.length; i++) {
      if (typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue(recover(QUESTIONS[i].backup));
      }
    }

    // Code for to restore the checkboxes
    for (let i = 0; i < cUnused.length; i++) {
      cUnused[i][SET](UNUSED_BACKUP.includes(strMac.unused.options[i].label));
    }
    UNUSED_BACKUP = "";

    for (let i = 0; i < cUnserviceable.length; i++) {
      cUnserviceable[i][SET](
        UNSERV_BACKUP.includes(strMac.unserv.options[i].label)
      );
    }
    UNSERV_BACKUP = "";

    console.log("Info. Restoring machine checks.");
    cConsent[0][SET](CONSENT_BACKUP == "true");
    CONSENT_BACKUP = "";

    cRice[0][SET](RICE_BACKUP == "true");
    RICE_BACKUP = "";

    cCorn[0][SET](CORN_BACKUP == "true");
    CORN_BACKUP = "";

    setIQRCode(recover(MCBKUP.qrCode));
    QRCODE_BACKUP = "";

    setIImgFront(recover(MCBKUP.imageFront));
    IMG_FRONT_BACKUP = "";

    setIImgSide(recover(MCBKUP.imageSide));
    IMG_SIDE_BACKUP = "";

    setIImgPlate(recover(MCBKUP.imagePlate));
    IMG_PLATE_BACKUP = "";

    setIAcc(recover(MCBKUP.acc));
    ACC_BACKUP = "";

    setILat(recover(MCBKUP.lat));
    LAT_BACKUP = "";

    setILng(recover(MCBKUP.lng));
    LNG_BACKUP = "";
  }

  function recover(key: string) {
    var val = localStorage.getItem(key) || "";
    if (localStorage.getItem(key) == null) {
      return "";
    } else if (val.charAt(0) == "{") {
      return JSON.parse(localStorage.getItem(key) ?? "");
    } else {
      return val;
    }
  }

  function clearFields() {
    for (let i = 0; i < QUESTIONS.length; i++) {
      if (typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue("");
      }
    }
  }

  // Show restore backup notice ///////////////////////////////////////////////

  var doesBackupExist = false;

  Object.values(MCBKUP).forEach((val, index) => {
    if (localStorage.getItem(val) != null) doesBackupExist = true;
  });

  const [inBackup, setinBackup] = useState(doesBackupExist);

  // QRCode ////////////////////////////////////////////////////////////////////

  const [qrDevice, setQRDevice] = useState("");
  const [qrVideos, setQRVideos] = useState([]);
  const [qrCameras, setQRCameras] = useState<
    { label: string; value: string }[]
  >([]);

  const qrReader = new BrowserQRCodeReader();

  useEffect(() => {
    setQRCameras(
      qrVideos.map((element: any) => ({
        label: element.label,
        value: element.deviceId,
      }))
    );
  }, [qrVideos]);

  function setupDevices(videos: any) {
    const sourceSelect = document.getElementById("sourceSelect");
    setQRDevice(videos[0].deviceId);
    if (videos.length >= 1) setQRVideos(videos);
  }

  function resetQR() {
    qrReader.reset();
    setIQRCode("");
  }

  function againQR() {
    qrReader
      .getVideoInputDevices()
      .then((qrVideo) => {
        setupDevices(qrVideo);
      })
      .catch((err) => {});
    decodeOnce(qrReader, qrDevice);
  }

  function decodeOnce(codeReader: any, selectedDeviceId: any) {
    codeReader
      .decodeFromInputVideoDevice(selectedDeviceId, "video")
      .then((result: any) => {
        let res = result!.getText();
        res = res.substring(res.lastIndexOf("/") + 1);
        if (
          res.charAt(6) === "M" &&
          res.length === 12 &&
          res.charAt(0) === "R"
        ) {
          setIQRCode(res);
        } else {
          showPopup({
            title: strMac.notif.qrError.title,
            content: strMac.notif.qrError.message,
            actionCenter: {
              text: strMac.notif.qrError.center,
              color: "error",
            },
          });
          setTimeout(() => {
            resetQR();
            againQR();
          }, 5000);
        }
      })
      .catch((err: any) => {});
  }

  useEffect(() => {
    if (iQRCode) {
      var qrCode = (iQRCode as any).label ?? iQRCode;
      if (qrCode.length > 10) {
        setVQRCode(false);
      }
    }
  }, [iQRCode]);

  // Geolocation ///////////////////////////////////////////////////////////////

  var gLat: any;
  var gLong: any;
  var gAcc: any;

  function loadMapMachine() {
    Popup({
      icon: "info",
      title: strMac.notif.geoloc.init.title,
      text: strMac.notif.geoloc.init.message,
      confirmButtonText: strMac.notif.geoloc.init.button,
    });
    var map = L.map("map").setView([14.16255, 121.248497], 6);

    var osm = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    osm.addTo(map);

    var refreshMap: any;
    if (!navigator.geolocation) {
      Popup({
        icon: "error",
        title: strMac.notif.geoloc.initError.title,
        text: strMac.notif.geoloc.initError.message,
        confirmButtonText: strMac.notif.geoloc.initError.button,
      });
    } else {
      refreshMap = setInterval(() => {
        if (!isNaN(gLat) && !isNaN(gLong) && !isNaN(gAcc)) {
          setCoor({
            lat: gLat,
            lng: gLong,
            acc: gAcc,
            accs: gAcc,
          });
        }

        var dev =
          AppSettings.SERVER == "https://127.0.0.1:4433" &&
          !isNaN(gLat) &&
          !isNaN(gLong) &&
          !isNaN(gAcc);

        if (gAcc <= AppSettings.MIN_ACCURACY || dev) {
          clearInterval(refreshMap);
          Popup({
            icon: "success",
            title: strMac.notif.geoloc.success.title,
            text:
              strMac.notif.geoloc.success.lat +
              gLat +
              strMac.notif.geoloc.success.lng +
              gLong +
              strMac.notif.geoloc.success.acc +
              gAcc,
            confirmButtonText: strMac.notif.geoloc.success.button,
          });
          setCoor({
            lat: gLat,
            lng: gLong,
            acc: gAcc,
            accs: gAcc,
          });
        } else {
          navigator.geolocation.getCurrentPosition(getPosition);
        }
      }, 3000);
    }

    var marker: any, circle: any;

    function getPosition(position: any) {
      var lat = position.coords.latitude;
      var long = position.coords.longitude;
      var accuracy = position.coords.accuracy / 3;
      gLat = lat;
      gLong = long;
      gAcc = accuracy;

      if (marker) {
        map.removeLayer(marker);
      }

      if (circle) {
        map.removeLayer(circle);
      }

      marker = L.marker([lat, long]);
      circle = L.circle([lat, long], { radius: accuracy });

      var featureGroup = L.featureGroup([circle]).addTo(map);

      map.fitBounds(featureGroup.getBounds());
    }
  }

  // Brand and Model ///////////////////////////////////////////////////////////

  const [oBrands, setOBrands] = useState(oOBrands);
  const [oModels, setOModels] = useState(oOModels);

  const [dBrand, setDBrand] = useState(true);
  const [dModel, setDModel] = useState(true);

  const [kBrand, setKBrand] = useState(0);
  const [kModel, setKModel] = useState(10000);

  useEffect(() => {
    var macType = iMacType ? (iMacType as any).label : iMacType;
    setOBrands(oOBrands.filter((o) => o.type.label == macType));

    if (macType == "") {
      setKBrand(kBrand + 1);
      setKModel(kModel + 1);
      setDBrand(true);
      setDModel(true);
    } else {
      setDBrand(false);
    }
  }, [iMacType]);

  useEffect(() => {
    var brand = iBrand ? (iBrand as any).label : iBrand;
    setOModels(oOModels.filter((o) => o.brand == brand));

    if (brand == "") {
      setKModel(kModel + 1);
      setDModel(true);
    } else {
      setDModel(false);
    }
  }, [iBrand]);

  // Mode of Acquisition /////////////////////////////////////////////////////////
  useEffect(() => {
    var mod = iMode ? (iMode as any).label : iMode;
    if (mod != strMac.usage.mode.options[0]) {
      setVMode(true);
    } else {
      setVMode(false);
    }
  }, [iMode]);

  // Coordinate separation ////////////////////////////////////////////////////
  useEffect(() => {
    if (
      coor.lat != undefined &&
      coor.lng != undefined &&
      coor.acc != undefined
    ) {
      setILat(coor.lat + "");
      setILng(coor.lng + "");
      setIAcc(coor.acc + "");
    }
  }, [coor]);

  // Notice for number of operators ////////////////////////////////////////////
  useEffect(() => {
    if (iOpe02 && iOpe2Abv) {
      if (isNaN(parseInt(iOps))) {
        requireNumberOfOperators();
      } else if (parseInt(iOpe02) + parseInt(iOpe2Abv) != parseInt(iOps)) {
        showPopup({
          title: strMac.notif.ope.ope02.title,
          content: strMac.notif.ope.ope02.message,
          actionCenter: { text: strMac.notif.ope.ok },
        });
      }
    }
  }, [iOpe02, iOpe2Abv]);

  function requireNumberOfOperators() {
    showPopup({
      title: strMac.notif.ope.only.title,
      content: strMac.notif.ope.only.message,
      actionCenter: { text: strMac.notif.ope.ok },
    });
  }

  useEffect(() => {
    if (iOpeWith && iOpeWithout) {
      if (isNaN(parseInt(iOps))) {
        requireNumberOfOperators();
      } else if (parseInt(iOpeWith) + parseInt(iOpeWithout) != parseInt(iOps)) {
        showPopup({
          title: strMac.notif.ope.with.title,
          content: strMac.notif.ope.with.message,
          actionCenter: { text: strMac.notif.ope.ok },
        });
      }
    }
  }, [iOpeWith, iOpeWithout]);

  // Load Profile //////////////////////////////////////////////////////////////

  const profiles = localStorage.getItem(fxdProfile.profiles);
  let oUsernames: { label: string }[] = [];
  if (profiles == null) {
    Popup({
      icon: "error",
      title: strMac.notif.noProfile.title,
      text: strMac.notif.noProfile.message,
      confirmButtonText: strMac.notif.noProfile.button,
      confirmButtonColor: "btn btn-danger",
    });
  } else {
    const profs = JSON.parse(profiles);
    for (let i = 0; i < profs.length; i++) {
      oUsernames.push({ label: profs[i].resName });
    }
  }

  useEffect(() => {
    if (localStorage.getItem(fxdGen.editing) == FORMS.machine) {
      if (iQRCode != "") localStorage.setItem(MCBKUP.qrCode, iQRCode);
      if (iAcc != "") localStorage.setItem(MCBKUP.acc, iAcc);
      if (iLat != "") localStorage.setItem(MCBKUP.lat, iLat);
      if (iLng != "") localStorage.setItem(MCBKUP.lng, iLng);
      if (iImgFront != "") localStorage.setItem(MCBKUP.imageFront, iImgFront);
      if (iImgSide != "") localStorage.setItem(MCBKUP.imageSide, iImgSide);
      if (iImgPlate != "") localStorage.setItem(MCBKUP.imagePlate, iImgPlate);
    }
  }, [iQRCode, iAcc, iLat, iLng, iImgFront, iImgSide, iImgPlate]);

  // DYNAMIC NOTIFICATION STATES AND FUNCTIONS /////////////////////////////////

  const [popTitle, setPopTitle] = useState("");
  const [popContent, setPopContent] = useState("");
  const [popShow, setPopShow] = useState(false);
  const [popCentered, setPopCentered] = useState(true);
  const [popActLeft, setPopActLeft] = useState<TModButton>();
  const [popActCenter, setPopActCenter] = useState<TModButton>({
    text: "",
  });
  const [popActRight, setPopActRight] = useState<TModButton>();

  function showPopup(props: IModPublic) {
    setPopTitle("");
    setPopContent("");
    setPopCentered(true);
    setPopActLeft({ text: "" });
    setPopActCenter({ text: "" });
    setPopActRight({ text: "" });

    setPopTitle(props.title);
    setPopContent(props.content);
    if (props.centered) setPopCentered(props.centered);
    if (props.actionLeft) setPopActLeft(props.actionLeft);
    if (props.actionCenter) setPopActCenter(props.actionCenter);
    if (props.actionRight) setPopActRight(props.actionRight);
    setPopShow(true);
  }

  const [ntfMessage, setNtfMessage] = useState("");
  const [ntfShow, setNtfShow] = useState(false);
  const [ntfDuration, setNtfDuration] = useState<number | undefined>(5000);
  const [ntfSeverity, setNtfSeverity] = useState<TGetSeverity>("info");

  function showNotification(props: INotifyPublic) {
    setNtfMessage("");
    setNtfSeverity("info");
    setNtfDuration(5000);
    setNtfMessage(props.message);
    setNtfSeverity(props.severity);
    if (props.duration) setNtfDuration(props.duration);
    setNtfShow(true);
  }

  // ///////////////////////////////////////////////////////////////////////////

  return (
    <VBar style="main-bg-form form-design">
      {/* DYNAMIC NOTIFICATION COMPONENT */}
      <Mod
        title={popTitle}
        content={popContent}
        show={[popShow, setPopShow]}
        centered={popCentered}
        actionLeft={popActLeft}
        actionCenter={popActCenter}
        actionRight={popActRight}
      />
      <Notify
        message={ntfMessage}
        show={[ntfShow, setNtfShow]}
        severity={ntfSeverity}
        duration={ntfDuration}
      />
      <Mod
        show={[inBackup, setinBackup]}
        title={strMac.notif.backup.title}
        content={strMac.notif.backup.message}
        actionCenter={{
          text: strMac.notif.backup.center,
          variant: "text",
          action: () => {
            deleteMachineBackups();
          },
        }}
        actionRight={{
          text: strMac.notif.backup.right,
          action: () => {
            recoverMachineBackups();
          },
        }}
      />
      <VBar style="v-bar">
        <TitleBar />
        <Card title={strMac.bscInfo.title} titleStyle="title">
          <Pick
            text={strMac.bscInfo.resName}
            options={oUsernames}
            value={[iResName, setIResName]}
            validation={eResName}
            backup={MCBKUP.resName}
          />
          <HBar>
            <Press
              disabled={disQRBtn}
              position="left"
              icon={<QrCode2Icon />}
              onClick={() => {
                setVQRCode(!vQRCode);
                resetQR();
                againQR();
              }}
            ></Press>
            <Field
              disabled
              position="right"
              text={strMac.bscInfo.qrcode}
              value={[iQRCode, setIQRCode]}
              validation={eQRCode}
              backup={MCBKUP.qrCode}
            />
          </HBar>
          <VBar force in={vQRCode} style="spacer">
            <Pick
              text={strMac.bscInfo.qrcodevid}
              options={qrCameras}
              onInputChange={(e, val: any) => {
                resetQR();
                let v = qrCameras.find((o) => o.label == val)?.value;
                if (v != undefined) {
                  setQRDevice(v);
                  resetQR();
                  decodeOnce(qrReader, v);
                }
              }}
              value={[qrCameras.find((o) => o.value == qrDevice)?.label, null]}
              validation={null}
              backup=""
            />
            <video id="video" />
          </VBar>
          <HBar>
            <Press
              disabled={disCamBtnFront}
              position="left"
              icon={<CameraAltIcon />}
              onClick={() => {
                setVImgFront(!vImgFront);
              }}
            ></Press>
            <Field
              disabled
              position="right"
              text={strMac.bscInfo.front}
              value={[iImgFront, setIImgFront]}
              validation={eImgFront}
              backup={MCBKUP.imageFront}
            />
          </HBar>
          <VBar force in={vImgFront} style="spacer">
            <WebCam setImage={setIImgFront} />
          </VBar>

          <HBar>
            <Press
              disabled={disCamBtnSide}
              position="left"
              icon={<CameraAltIcon />}
              onClick={() => {
                setVImgSide(!vImgSide);
              }}
            ></Press>
            <Field
              disabled
              position="right"
              text={strMac.bscInfo.side}
              value={[iImgSide, setIImgSide]}
              validation={eImgSide}
              backup={MCBKUP.imageSide}
            />
          </HBar>
          <VBar force in={vImgSide} style="spacer">
            <WebCam setImage={setIImgSide} />
          </VBar>
          <HBar>
            <Press
              disabled={disCamBtnPlate}
              position="left"
              icon={<CameraAltIcon />}
              onClick={() => {
                setVImgPlate(!vImgPlate);
              }}
            ></Press>
            <Field
              disabled
              position="right"
              text={strMac.bscInfo.plate}
              value={[iImgPlate, setIImgPlate]}
              validation={eImgPlate}
              backup={MCBKUP.imagePlate}
            />
          </HBar>
          <VBar force in={vImgPlate} style="spacer">
            <WebCam setImage={setIImgPlate} />
          </VBar>
          <HBar>
            <Press
              position="left"
              icon={<MyLocationIcon />}
              onClick={() => {
                setVCoor(true);
                loadMapMachine();
              }}
              disabled={vCoor}
            ></Press>
            <Field
              disabled
              position="right"
              text={strMac.bscInfo.coordinates}
              value={[iAcc, setIAcc]}
              validation={eAcc}
              backup={MCBKUP.acc}
            />
          </HBar>
          <VBar in={vCoor} style="spacer">
            <Field
              disabled
              text={strMac.bscInfo.latitude}
              value={[iLat, setILat]}
              validation={eLat}
              backup={MCBKUP.lat}
            />
            <Field
              disabled
              text={strMac.bscInfo.longitude}
              value={[iLng, setILng]}
              validation={eLng}
              backup={MCBKUP.lng}
            />
            <Alert
              severity={
                coor.acc == undefined
                  ? "error"
                  : !/[^0-9]./.test(String(coor.acc)) && coor.acc <= 5
                  ? "success"
                  : !/[^0-9]./.test(String(coor.acc)) && coor.acc <= 8
                  ? "warning"
                  : "error"
              }
              sx={{ width: "100%" }}
            >
              {coor.acc == undefined
                ? strMac.notif.accuracy.init
                : coor.acc <= 5
                ? strMac.notif.accuracy.high
                : coor.acc <= 8
                ? strMac.notif.accuracy.low
                : strMac.notif.accuracy.search}
            </Alert>
          </VBar>
          <GeoLocationPane />
          <Pick
            text={strMac.bscInfo.during}
            options={strMac.bscInfo.during.options}
            value={[iLoc, setILoc]}
            validation={eLoc}
            backup={MCBKUP.loc}
          />
          <hr />
          <Text text={strMac.bscInfo.garage} />
          <Province
            text={strMac.bscInfo.province}
            address={adPvd}
            value={[iProv, setIProv]}
            validation={eProv}
            backup={MCBKUP.prov}
          />
          <Municipality
            text={strMac.bscInfo.municipality}
            address={adPvd}
            value={[iMun, setIMun]}
            validation={eMun}
            backup={MCBKUP.mun}
          />
          <Barangay
            text={strMac.bscInfo.barangay}
            address={adPvd}
            value={[iBrgy, setIBrgy]}
            validation={eBrgy}
            backup={MCBKUP.brgy}
          />
          <hr />
          <Pick
            value={[iMacType, setIMacType]}
            text={strMac.macInfo.type}
            options={strMac.macInfo.type.options}
            validation={eMacType}
            backup={MCBKUP.macType}
          />
        </Card>
        <Card
          in={vCSpecs && vMacTypeSel}
          title={strMac.macInfo.title}
          titleStyle="title"
        >
          <Pick
            free
            disabled={dBrand}
            text={strMac.macInfo.brand}
            options={oBrands}
            value={[iBrand, setIBrand]}
            validation={eBrand}
            backup={MCBKUP.brand}
          />
          <Pick
            free
            disabled={dModel}
            text={strMac.macInfo.model}
            options={oModels}
            value={[iModel, setIModel]}
            validation={eModel}
            backup={MCBKUP.model}
          />
          <HBar gap={10}>
            <Field
              text={strMac.macInfo.rPower}
              value={[iRPower, setIRPower]}
              validation={eRPower}
              backup={MCBKUP.rPower}
            />
            <Field
              text={strMac.macInfo.sArea}
              value={[iSArea, setISArea]}
              validation={eSArea}
              backup={MCBKUP.sArea}
            />
          </HBar>
          <Field
            text={strMac.macInfo.oDay}
            value={[iODay, setIODay]}
            validation={eODay}
            backup={MCBKUP.oDay}
          />
          <Field
            text={strMac.macInfo.oSeason}
            value={[iOSeason, setIOSeason]}
            validation={eOSeason}
            backup={MCBKUP.oSeason}
          />
          <HBar in={vCnHlr}>
            <Field
              position="left"
              text={strMac.cane.lCap.val}
              value={[iLCap, setILCap]}
              validation={eLCap}
              backup={MCBKUP.lCap}
            />
            <Pick
              // free
              position="center"
              text={strMac.cane.lCap.unit}
              options={strMac.cane.lCap.unit.options}
              value={[iLCapUnit, setILCapUnit]}
              validation={eLCapUnit}
              backup={MCBKUP.lCapUnit}
            />
            <Field
              disabled={!String((iLCapUnit as any).label).includes(strMac.load)}
              position="right"
              text={strMac.cane.lCap.load}
              value={[iLCapLoad, setILCapLoad]}
              validation={eLCapLoad}
              backup={MCBKUP.lCapLoad}
            />
          </HBar>
          <VBar in={vHrv} style="spacer">
            <Field
              text={strMac.harvester.eArea}
              value={[iEffArea, setIEffArea]}
              validation={eEffArea}
              backup={MCBKUP.effArea}
            />
            <Field
              text={strMac.harvester.time}
              value={[iTime, setITime]}
              validation={eTime}
              backup={MCBKUP.time}
            />
            <Field
              text={strMac.harvester.yield}
              value={[iYield, setIYield]}
              validation={eYield}
              backup={MCBKUP.yield}
            />
          </VBar>
        </Card>
        <Card
          in={vPSpecs && vMacTypeSel}
          title={
            iMacType && (iMacType as any).label == tRGun.label
              ? strMac.wPump.rgun
              : strMac.wPump.title
          }
          titleStyle="title"
        >
          <Text text={strMac.wPump.sSpecs} />
          <Pick
            free
            text={strMac.wPump.type}
            options={strMac.wPump.type.options2}
            value={[iPType, setIPType]}
            validation={ePType}
            backup={MCBKUP.pType}
          />
          <Pick
            free
            options={[]}
            text={strMac.macInfo.brand}
            value={[iPBrand, setIPBrand]}
            validation={ePBrand}
            backup={MCBKUP.pBrand}
          />
          <Pick
            free
            options={[]}
            text={strMac.macInfo.model}
            value={[iPModel, setIPModel]}
            validation={ePModel}
            backup={MCBKUP.pModel}
          />
          <Field
            text={strMac.wPump.size}
            value={[iPSize, setIPSize]}
            validation={ePSize}
            backup={MCBKUP.pSize}
          />
          <Text text={strMac.wPump.sPrime} />
          <Pick
            text={strMac.wPump.source}
            options={strMac.wPump.source.options}
            value={[iPSource, setIPSource]}
            validation={ePSource}
            backup={MCBKUP.pSource}
          />
          <Pick
            free
            options={[]}
            text={strMac.wPump.pBrand}
            value={[iPSBrand, setIPSBrand]}
            validation={ePSBrand}
            backup={MCBKUP.pSBrand}
          />
          <Pick
            free
            options={[]}
            text={strMac.wPump.pModel}
            value={[iPSModel, setIPSModel]}
            validation={ePSModel}
            backup={MCBKUP.pSModel}
          />
          <Field
            text={strMac.wPump.pRPower}
            value={[iPSRPower, setIPSRPower]}
            validation={ePSRPower}
            backup={MCBKUP.pSRPower}
          />
          <Text text={strMac.wPump.sOutput} />
          <HBar>
            <Field
              position="left"
              text={strMac.wPump.discharge}
              value={[iPDischarge, setIPDischarge]}
              validation={ePDischarge}
              backup={MCBKUP.pDischarge}
            />
            <Pick
              free
              options={strMac.wPump.discharge.options}
              position="right"
              text={strMac.wPump.discharge.unitval}
              value={[iPDisUnit, setIPDisUnit]}
              validation={ePDisUnit}
              backup={MCBKUP.pDisUnit}
            />
          </HBar>
          <Field
            text={strMac.wPump.sArea}
            value={[iPSArea, setIPSArea]}
            validation={ePSArea}
            backup={MCBKUP.pSArea}
          />
          <Field
            text={strMac.wPump.oDay}
            value={[iPODay, setIPODay]}
            validation={ePODay}
            backup={MCBKUP.pODay}
          />
          <Field
            text={strMac.wPump.oSeason}
            value={[iPOSeason, setIPOSeason]}
            validation={ePOSeason}
            backup={MCBKUP.pOSeason}
          />
        </Card>
        <Card in={vMacTypeSel} title={strMac.usage.title} titleStyle="title">
          <Field
            text={strMac.usage.oob}
            value={[iOOB, setIOOB]}
            validation={eOOB}
            backup={MCBKUP.oob}
          />
          <Pick
            text={strMac.usage.class}
            options={strMac.usage.class.options}
            value={[iClass, setIClass]}
            validation={eClass}
            backup={MCBKUP.class}
          />
          <Pick
            text={strMac.usage.usage}
            options={strMac.usage.usage.options}
            value={[iUsage, setIUsage]}
            validation={eUsage}
            backup={MCBKUP.usage}
          />
          <Pick
            text={strMac.usage.mode}
            options={strMac.usage.mode.options}
            value={[iMode, setIMode]}
            validation={eMode}
            backup={MCBKUP.mode}
          />
          <HBar in={vMode}>
            <Field
              text={strMac.usage.agency}
              value={[iAgency, setIAgency]}
              validation={eAgency}
              backup={MCBKUP.agency}
            />
          </HBar>
          <Day
            text={strMac.usage.acqYear}
            views={["month", "year"]}
            listen={setIAcqYear}
            validation={eAcqYear}
            backup={MCBKUP.acqYear}
          />
          <Pick
            text={strMac.usage.cond}
            options={strMac.usage.cond.options}
            value={[iCond, setICond]}
            validation={eCond}
            backup={MCBKUP.cond}
          />
          <Pick
            text={strMac.usage.pCond}
            value={[iPCond, setIPCond]}
            options={strMac.usage.pCond.options}
            validation={ePCond}
            backup={MCBKUP.pCond}
          />
          <VBar in={vUsed} id="unused" style="spacer">
            <Day
              text={strMac.unused.uuYear}
              views={["month", "year"]}
              listen={setIUUYear}
              validation={eUUYear}
              backup={MCBKUP.uuYear}
            />
            <Text text={strMac.unused.subtitle} />
            <Checks
              row={false}
              options={strMac.unused.options}
              listen={setIUnused}
              checks={cUnused}
            />
            <Field
              text={strMac.unused.uuOthers}
              value={[iUUOthers, setIUUOthers]}
              validation={eUUOthers}
              backup={MCBKUP.uuOthers}
            />
          </VBar>
          <VBar in={vUnUsd} id="unserviceable" style="spacer">
            <Day
              text={strMac.unserv.usYear}
              views={["month", "year"]}
              listen={setIUSYear}
              validation={eUSYear}
              backup={MCBKUP.usYear}
            />
            <Text text={strMac.unserv.subtitle} />
            <Checks
              row={false}
              options={strMac.unserv.options}
              listen={setIUnserv}
              checks={cUnserviceable}
            />
            <Field
              text={strMac.unserv.usOthers}
              value={[iUSOthers, setIUSOthers]}
              validation={eUSOthers}
              backup={MCBKUP.usOthers}
            />
          </VBar>
          <hr />
          <Text text={strMac.usage.contact.title} />
          <HBar>
            <Field
              width={50}
              position="left"
              text={strMac.usage.contact1}
              value={[iContact1, setIContact1]}
              validation={eContact1}
              backup={MCBKUP.contact1}
            />
            <Field
              width={50}
              position="right"
              text={strMac.usage.contact2}
              value={[iContact2, setIContact2]}
              validation={eContact2}
              backup={MCBKUP.contact2}
            />
          </HBar>
          <Province
            text={strMac.usage.spProv}
            address={adPvd}
            value={[iSPProv, setISPProv]}
            validation={eSPProv}
            backup={MCBKUP.spProv}
          />
          <Municipality
            text={strMac.usage.spMun}
            address={adPvd}
            value={[iSPMun, setISPMun]}
            validation={eSPMun}
            backup={MCBKUP.spMun}
          />
          <Barangay
            text={strMac.usage.spBrgy}
            address={adPvd}
            value={[iSPBrgy, setISPBrgy]}
            validation={eSPBrgy}
            backup={MCBKUP.spBrgy}
          />
          <Checks
            options={[{ label: strMac.usage.contact.consent, key: 0 }]}
            checks={cConsent}
          />
        </Card>
        <Card
          in={vRent && vMacTypeSel}
          title={strMac.rent.title}
          titleStyle="title"
        >
          <Pick
            text={strMac.rent.question}
            value={[iRental, setIRental]}
            options={strMac.rent.question.options}
            validation={eRental}
            backup={MCBKUP.rental}
          />
          <Text text={strMac.rent.ope.first} />
          <Pick
            text={strMac.rent.fOper}
            options={strMac.rent.fOper.options}
            value={[iFOper, setIFOper]}
            validation={eFOper}
            backup={MCBKUP.fOper}
          />
          <HBar>
            <Field
              unitPosition="start"
              position="left"
              text={strMac.rent.fORate}
              value={[iFORate, setIFORate]}
              validation={eFORate}
              backup={MCBKUP.fORate}
            />
            <Pick
              position="right"
              text={strMac.rent.fORate.unitval}
              options={strMac.rent.fORate.options}
              value={[iFORateUnit, setIFORateUnit]}
              validation={eFORateUnit}
              backup={MCBKUP.fORateUnit}
            />
          </HBar>
          <Field
            text={strMac.rent.fOFuel}
            value={[iFOFuel, setIFOFuel]}
            validation={eFOFuel}
            backup={MCBKUP.fOFuel}
          />
          <Text text={strMac.rent.ope.second} />
          <Pick
            text={strMac.rent.fOper}
            options={strMac.rent.fOper.options}
            value={[iSOper, setISOper]}
            validation={eSOper}
            backup={MCBKUP.sOper}
          />
          <HBar>
            <Field
              unitPosition="start"
              position="left"
              text={strMac.rent.fORate}
              value={[iSORate, setISORate]}
              validation={eSORate}
              backup={MCBKUP.sORate}
            />
            <Pick
              free
              position="right"
              text={strMac.rent.fORate.unitval}
              options={strMac.rent.fORate.options}
              value={[iSORateUnit, setISORateUnit]}
              validation={eSORateUnit}
              backup={MCBKUP.sORateUnit}
            />
          </HBar>
          <Field
            text={strMac.rent.fOFuel}
            value={[iSOFuel, setISOFuel]}
            validation={eSOFuel}
            backup={MCBKUP.sOFuel}
          />
          <Text text={strMac.rent.ope.third} />
          <Pick
            text={strMac.rent.fOper}
            options={strMac.rent.fOper.options}
            value={[iTOper, setITOper]}
            validation={eTOper}
            backup={MCBKUP.tOper}
          />
          <HBar>
            <Field
              unitPosition="start"
              position="left"
              text={strMac.rent.fORate}
              value={[iTORate, setITORate]}
              validation={eTORate}
              backup={MCBKUP.tORate}
            />
            <Pick
              free
              position="right"
              text={strMac.rent.fORate.unitval}
              options={strMac.rent.fORate.options}
              value={[iTORateUnit, setITORateUnit]}
              validation={eTORateUnit}
              backup={MCBKUP.tORateUnit}
            />
          </HBar>
          <Field
            text={strMac.rent.fOFuel}
            value={[iTOFuel, setITOFuel]}
            validation={eTOFuel}
            backup={MCBKUP.tOFuel}
          />
          <hr />
          <VBar id="yes" style="spacer">
            <Text text={strMac.yes.operator} />
            <Field
              text={strMac.yes.ops}
              value={[iOps, setIOps]}
              validation={eOps}
              backup={MCBKUP.ops}
            />
            <Text text={strMac.yes.exp} />
            <HBar gap={10}>
              <Field
                text={strMac.yes.exp1}
                value={[iOpe02, setIOpe02]}
                validation={eOpe02}
                backup={MCBKUP.ope02}
              />
              <Field
                text={strMac.yes.exp2}
                value={[iOpe2Abv, setIOpe2Abv]}
                validation={eOpe2Abv}
                backup={MCBKUP.ope2Abv}
              />
            </HBar>
            <HBar gap={10}>
              <Field
                text={strMac.yes.with}
                value={[iOpeWith, setIOpeWith]}
                validation={eOpeWith}
                backup={MCBKUP.opeWith}
              />
              <Field
                text={strMac.yes.without}
                value={[iOpeWithout, setIOpeWithout]}
                validation={eOpeWithout}
                backup={MCBKUP.opeWithout}
              />
            </HBar>
          </VBar>
        </Card>
        <Card in={vMacTypeSel} title={strMac.perf.title} titleStyle="title">
          <HBar in={!vPSpecs}>
            <Field
              text={strMac.perf.used}
              value={[iUsed, setIUsed]}
              validation={eUsed}
              backup={MCBKUP.used}
            />
          </HBar>
          <HBar>
            <Field
              text={strMac.perf.breakdowns}
              value={[iBreakdowns, setIBreakdowns]}
              validation={eBreakdowns}
              backup={MCBKUP.breakdowns}
            />
          </HBar>
        </Card>
        <Card in={vMacTypeSel} title={""} titleStyle="title">
          <VBar in={vCSpecs} style="spacer">
            <Text text={strMac.maint.title} className="title" />
            <Pick
              text={strMac.maint.aFilter}
              options={strMac.maint.options}
              value={[iAFilter, setIAFilter]}
              validation={eAFilter}
              backup={MCBKUP.aFilter}
            />
            <Pick
              text={strMac.maint.eOil}
              options={strMac.maint.options}
              value={[iEOil, setIEOil]}
              validation={eEOil}
              backup={MCBKUP.eOil}
            />
            <Pick
              text={strMac.maint.oFilter}
              options={strMac.maint.options}
              value={[iOFilter, setIOFilter]}
              validation={eOFilter}
              backup={MCBKUP.oFilter}
            />
            <Pick
              text={strMac.maint.hOil}
              options={strMac.maint.options}
              value={[iHOil, setIHOil]}
              validation={eHOil}
              backup={MCBKUP.hOil}
            />
            <Pick
              text={strMac.maint.hOFil}
              options={strMac.maint.options}
              value={[iHOFil, setIHOFil]}
              validation={eHOFil}
              backup={MCBKUP.hOFil}
            />
            <Pick
              text={strMac.maint.tire}
              options={strMac.maint.options}
              value={[iTire, setITire]}
              validation={eTire}
              backup={MCBKUP.tire}
            />
            <hr />
          </VBar>
          <Text text={strMac.supplier.title} />
          <Pick
            text={strMac.supplier.sName}
            options={strMac.supplier.options}
            value={[iSName, setISName]}
            validation={eSName}
            backup={MCBKUP.sName}
          />
          <VBar style="spacer indent">
            <Province
              text={strMac.supplier.sProv}
              address={adAts}
              value={[iSProv, setISProv]}
              validation={eSProv}
              backup={MCBKUP.sProv}
            />
            <Municipality
              text={strMac.supplier.sMun}
              address={adAts}
              value={[iSMun, setISMun]}
              validation={eSMun}
              backup={MCBKUP.sMun}
            />
            <Barangay
              text={strMac.supplier.sBrgy}
              address={adAts}
              value={[iSBrgy, setISBrgy]}
              validation={eSBrgy}
              backup={MCBKUP.sBrgy}
            />
          </VBar>
          <Pick
            text={strMac.supplier.sTimeRes}
            options={strMac.supplier.sTimeRes.options}
            value={[iSTimeRes, setISTimeRes]}
            validation={eSTimeRes}
            backup={MCBKUP.sTimeRes}
          />
          <Pick
            text={strMac.supplier.sPartsAvail}
            options={strMac.supplier.sPartsAvail.options}
            value={[iSPartsAvail, setISPartsAvail]}
            validation={eSPartsAvail}
            backup={MCBKUP.sPartsAvail}
          />
        </Card>
        <Card in={vOCrops} title={strMac.othCrops.title} titleStyle="title">
          <Pick
            text={strMac.othCrops.question}
            options={strMac.othCrops.question.options}
            value={[iOthCrp, setIOthCrp]}
            validation={eOthCrp}
            backup={MCBKUP.othCrp}
          />
          <VBar in={vOthCrp} style="spacer">
            <HBar>
              <Text text={strMac.othCrops.sArea.label} />
            </HBar>
            <HBar gap={10}>
              <Checks
                width={50}
                options={[{ label: strMac.othCrops.sArea.rice.text, key: 0 }]}
                checks={cRice}
              />
              <HBar in={cRice[0][GET]}>
                <Field
                  open
                  text={strMac.othCrops.sArea.rice}
                  value={[iORSArea, setIORSArea]}
                  validation={eORSArea}
                  backup={MCBKUP.oRSArea}
                />
              </HBar>
            </HBar>
            <HBar gap={10}>
              <Checks
                width={50}
                options={[{ label: strMac.othCrops.sArea.corn.text, key: 0 }]}
                checks={cCorn}
              />
              <HBar in={cCorn[0][GET]}>
                <Field
                  open
                  text={strMac.othCrops.sArea.corn}
                  value={[iOCSArea, setIOCSArea]}
                  validation={eOCSArea}
                  backup={MCBKUP.oCSArea}
                />
              </HBar>
            </HBar>
            <HBar gap={10}>
              <Field
                width={50}
                text={strMac.othCrops.sArea.othersName}
                value={[iOOth, setIOOth]}
                validation={eOOth}
                backup={MCBKUP.oOth}
              />
              <HBar in={iOOth.length > 0}>
                <Field
                  open
                  text={strMac.othCrops.sArea.othersVal}
                  value={[iOOArea, setIOOArea]}
                  validation={eOOArea}
                  backup={MCBKUP.oOArea}
                />
              </HBar>
            </HBar>
          </VBar>
        </Card>
        <Card>
          <Field
            multiline
            rows={7}
            text={strMac.notes}
            value={[iNotes, setINotes]}
            validation={eNotes}
            backup={MCBKUP.notes}
          />
        </Card>
        <Press
          label={strMac.save}
          color="primary"
          icon={<SaveIcon />}
          onClick={async () => {
            if (saveEncodedMachine()) {
              // setTimeout(() => {
              //   navigate(Route.machine);
              // }, 6000);
            }
          }}
          height={50}
        />
        <div className="btn-group" role="group">
          <button className="btn btn-primary" type="button">
            <Nav.Link key={Route.encode} as={NavLink} to={Route.encode} end>
              Go to Encode
            </Nav.Link>
          </button>
          <button className="btn btn-primary" type="button">
            <Nav.Link
              key={Route.implement}
              as={NavLink}
              to={Route.implement}
              end
            >
              Go to Implement
            </Nav.Link>
          </button>
        </div>
      </VBar>
    </VBar>
  );

  function saveEncodedMachine() {
    // Variables ///////////////////////////////////////////////////////////////
    var proceedToSave = true;
    var now = Date.now().toString();
    var enumerator = localStorage.getItem(fxdUser.name);

    // Constants ///////////////////////////////////////////////////////////////

    const VGET = 0;
    const EGET = 1;
    const ESET = 2;

    // Add required fields /////////////////////////////////////////////////////

    var required = [
      [iResName, eResName, setEResName],
      [iQRCode, eQRCode, setEQRCode],
      [iImgFront, eImgFront, setEImgFront],
      [iImgSide, eImgSide, setEImgSide],
      // [iImgPlate, eImgPlate, setEImgPlate],
      [iAcc, eAcc, setEAcc],
      [iLat, eLat, setELat],
      [iLng, eLng, setELng],
      [iLoc, eLoc, setELoc],
      [iProv, eProv, setEProv],
      [iMun, eMun, setEMun],
      [iBrgy, eBrgy, setEBrgy],
      [iMacType, eMacType, setEMacType],
    ];

    // // /////////////////////////////////////////////////////////////////////////

    if (vCSpecs && vMacTypeSel) {
      required.push([iBrand, eBrand, setEBrand]);
      // required.push([iModel, eModel, setEModel]);
      required.push([iRPower, eRPower, setERPower]);
      required.push([iSArea, eSArea, setESArea]);
      required.push([iODay, eODay, setEODay]);
      required.push([iOSeason, eOSeason, setEOSeason]);

      if (vCnHlr) {
        required.push([iLCap, eLCap, setELCap]);
        required.push([iLCapUnit, eLCapUnit, setELCapUnit]);
        // required.push([iLCapLoad, eLCapLoad, setELCapLoad]);
      }

      if (vHrv) {
        required.push([iEffArea, eEffArea, setEEffArea]);
        required.push([iTime, eTime, setETime]);
        required.push([iYield, eYield, setEYield]);
      }
    }

    // // /////////////////////////////////////////////////////////////////////////

    if (vPSpecs && vMacTypeSel) {
      required.push([iPType, ePType, setEPType]);
      required.push([iPBrand, ePBrand, setEPBrand]);
      required.push([iPModel, ePModel, setEPModel]);
      required.push([iPSize, ePSize, setEPSize]);
      required.push([iPSource, ePSource, setEPSource]);
      required.push([iPSBrand, ePSBrand, setEPSBrand]);
      required.push([iPSModel, ePSModel, setEPSModel]);
      required.push([iPSRPower, ePSRPower, setEPSRPower]);
      required.push([iPDischarge, ePDischarge, setEPDischarge]);
      required.push([iPDisUnit, ePDisUnit, setEPDisUnit]);
      required.push([iPSArea, ePSArea, setEPSArea]);
      required.push([iPODay, ePODay, setEPODay]);
      required.push([iPOSeason, ePOSeason, setEPOSeason]);
    }

    if (vMacTypeSel) {
      required.push([iOOB, eOOB, setEOOB]);
      required.push([iClass, eClass, setEClass]);
      required.push([iUsage, eUsage, setEUsage]);
      required.push([iMode, eMode, setEMode]);

      if (vMode) {
        required.push([iAgency, eAgency, setEAgency]);
      }

      required.push([iAcqYear, eAcqYear, setEAcqYear]);
      required.push([iCond, eCond, setECond]);
      required.push([iPCond, ePCond, setEPCond]);

      if (vUsed) {
        required.push([iUUYear, eUUYear, setEUUYear]);
        required.push([iUnused, eUnused, setEUnused]);
        // TODO
        // required.push([iUUOthers, eUUOthers, setEUUOthers]);
      }

      if (vUnUsd) {
        required.push([iUSYear, eUSYear, setEUSYear]);
        required.push([iUnserv, eUnserv, setEUnserv]);
        // TODO
        // required.push([iUSOthers, eUSOthers, setEUSOthers]);
      }

      // required.push([iContact1, eContact1, setEContact1]);
      // required.push([iContact2, eContact2, setEContact2]);
      // required.push([iSPProv, eSPProv, setESPProv]);
      // required.push([iSPMun, eSPMun, setESPMun]);
      // required.push([iSPBrgy, eSPBrgy, setESPBrgy]);
      // required.push([iConsent, eConsent, setEConsent]);
    }

    if (vRent && vMacTypeSel) {
      if (
        iRental &&
        (iRental as any).label == strMac.rent.question.options[0].label
      ) {
        required.push([iFOper, eFOper, setEFOper]);
        required.push([iFORate, eFORate, setEFORate]);
        required.push([iFORateUnit, eFORateUnit, setEFORateUnit]);
        required.push([iFOFuel, eFOFuel, setEFOFuel]);

        // required.push([iSOper, eSOper, setESOper]);
        // required.push([iSORate, eSORate, setESORate]);
        // required.push([iSORateUnit, eSORateUnit, setESORateUnit]);
        // required.push([iSOFuel, eSOFuel, setESOFuel]);

        // required.push([iTOper, eTOper, setETOper]);
        // required.push([iTORate, eTORate, setETORate]);
        // required.push([iTORateUnit, eTORateUnit, setETORateUnit]);
        // required.push([iTOFuel, eTOFuel, setETOFuel]);
      }

      required.push([iOps, eOps, setEOps]);
      required.push([iOpe02, eOpe02, setEOpe02]);
      required.push([iOpe2Abv, eOpe2Abv, setEOpe2Abv]);
      required.push([iOpeWith, eOpeWith, setEOpeWith]);
      required.push([iOpeWithout, eOpeWithout, setEOpeWithout]);
    }

    if (vMacTypeSel) {
      if (!vPSpecs) required.push([iUsed, eUsed, setEUsed]);

      required.push([iBreakdowns, eBreakdowns, setEBreakdowns]);

      if (vCSpecs) {
        required.push([iAFilter, eAFilter, setEAFilter]);
        required.push([iEOil, eEOil, setEEOil]);
        required.push([iOFilter, eOFilter, setEOFilter]);
        required.push([iHOil, eHOil, setEHOil]);
        required.push([iHOFil, eHOFil, setEHOFil]);
        required.push([iTire, eTire, setETire]);
      }

      required.push([iSName, eSName, setESName]);
      required.push([iSProv, eSProv, setESProv]);
      required.push([iSMun, eSMun, setESMun]);
      required.push([iSBrgy, eSBrgy, setESBrgy]);
      required.push([iSTimeRes, eSTimeRes, setESTimeRes]);
      required.push([iSPartsAvail, eSPartsAvail, setESPartsAvail]);

      if (vOCrops) {
        if (cRice[0][GET]) {
          required.push([iORSArea, eORSArea, setEORSArea]);
        }

        if (cCorn[0][GET]) {
          required.push([iOCSArea, eOCSArea, setEOCSArea]);
        }

        if (iOOth.length > 0) {
          required.push([iOOArea, eOOArea, setEOOArea]);
        }
      }
    }

    // required.push([iNotes, eNotes, setENotes]);

    // Setting required fields /////////////////////////////////////////////////
    for (let i = 0; i < required.length; i++) {
      var valAsText =
        required[i][VGET] != null
          ? (required[i][VGET] as any).label
          : required[i][VGET];

      if (!valAsText) {
        var require = {
          required: true,
          condition: (required[i][EGET] as TGetValidation).condition,
          message: (required[i][EGET] as TGetValidation).message,
          range: (required[i][EGET] as TGetValidation).range,
          length: (required[i][EGET] as TGetValidation).length,
        } as TGetValidation;
        (required[i][ESET] as TSetValidation)(require);
      }
    }

    // Check if all required fields are filled /////////////////////////////////
    for (let i = 0; i < required.length; i++) {
      var valAsText = (required[i][VGET] as any).label ?? required[i][VGET];

      if (valAsText == "") {
        proceedToSave = false;
        break;
      }
    }

    if (parseFloat(iAcc) > AppSettings.MIN_ACCURACY) {
      showNotification({
        message:
          "Accuracy is too low. Kindly wait for the accuracy to meet atleast 8 meters.",
        severity: "error",
      });
      proceedToSave = false;
    }

    // Browser compatibility test //////////////////////////////////////////////
    // This might show in rare case only
    if (typeof JSON == "undefined") {
      showNotification({
        message: strPrf.notif.browserError,
        severity: "error",
      });
    } else if (!proceedToSave) {
      showNotification({
        message: strPrf.notif.missing,
        severity: "error",
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      showNotification({
        message: strPrf.notif.saveIni,
        severity: "success",
      });
    }

    // Prepare save the data ///////////////////////////////////////////////////
    if (proceedToSave) {
      let data = {
        // Basic Information
        resName: iResName,
        qrCode: iQRCode,
        imageFront: iImgFront,
        imageSide: iImgSide,
        imagePlate: iImgPlate,
        acc: iAcc,
        lat: iLat,
        lng: iLng,
        loc: iLoc,
        prov: iProv,
        mun: iMun,
        brgy: iBrgy,
        macType: iMacType,
        // Machine Specifications
        brand: iBrand,
        model: iModel,
        rPower: iRPower,
        sArea: iSArea,
        oDay: iODay,
        oSeason: iOSeason,
        lCap: iLCap,
        lCapUnit: iLCapUnit,
        lCapLoad: iLCapLoad,
        effArea: iEffArea,
        time: iTime,
        yield: iYield,
        // Water Pump
        pType: iPType,
        pBrand: iPBrand,
        pModel: iPModel,
        pSize: iPSize,
        pSource: iPSource,
        pSBrand: iPSBrand,
        pSModel: iPSModel,
        pSRPower: iPSRPower,
        pDischarge: iPDischarge,
        pDisUnit: iPDisUnit,
        pSArea: iPSArea,
        pODay: iPODay,
        pOSeason: iPOSeason,
        // Ownership Details
        oob: iOOB,
        class: iClass,
        usage: iUsage,
        mode: iMode,
        agency: iAgency,
        acqYear: iAcqYear,
        cond: iCond,
        pCond: iPCond,
        //
        uuYear: iUUYear,
        unused: iUnused,
        uuOthers: iUUOthers,
        usYear: iUSYear,
        unserv: iUnserv,
        usOthers: iUSOthers,
        //
        contact1: iContact1,
        contact2: iContact2,
        spProv: iSPProv,
        spMun: iSPMun,
        spBrgy: iSPBrgy,
        consent: iConsent,
        // Renting Details
        rental: iRental,
        fOper: iFOper,
        fORate: iFORate,
        fORateUnit: iFORateUnit,
        fOFuel: iFOFuel,
        sOper: iSOper,
        sORate: iSORate,
        sORateUnit: iSORateUnit,
        sOFuel: iSOFuel,
        tOper: iTOper,
        tORate: iTORate,
        tORateUnit: iTORateUnit,
        tOFuel: iTOFuel,
        //
        ops: iOps,
        ope02: iOpe02,
        ope2Abv: iOpe2Abv,
        opeWith: iOpeWith,
        opeWithout: iOpeWithout,
        // Machine Performance
        used: iUsed,
        breakdowns: iBreakdowns,
        // Maintenance Service
        aFilter: iAFilter,
        eOil: iEOil,
        oFilter: iOFilter,
        hOil: iHOil,
        hOFil: iHOFil,
        tire: iTire,
        //
        sName: iSName,
        sProv: iSProv,
        sMun: iSMun,
        sBrgy: iSBrgy,
        sTimeRes: iSTimeRes,
        sPartsAvail: iSPartsAvail,
        // Other Crops
        othCrp: iOthCrp,
        oRice: iORice,
        oRSArea: iORSArea,
        oCorn: iOCorn,
        oCSArea: iOCSArea,
        oOth: iOOth,
        oOArea: iOOArea,
        notes: iNotes,
        // Metadata
        uname: enumerator,
        date: now,
        status: DATA_STATUS.local,
      } as MachineData;

      localStorage.setItem(fxdGen.editing, FORMS.null);
      let filename = iQRCode + ".zip";
      var fileToSave = new Blob([JSON.stringify(data, undefined, 2)], {
        type: "application/json",
      });
      saveAs(fileToSave, filename);
      let machines = localStorage.getItem(fxdMachine.machines);
      if (machines != null) {
        const newMachines = data;
        const machs = JSON.parse(machines) as Array<any>;
        machs.push(newMachines);
        localStorage.setItem(fxdMachine.machines, JSON.stringify(machs));
      } else {
        localStorage.setItem(fxdMachine.machines, JSON.stringify([data]));
      }

      clearFields();
      deleteMachineBackups();
      showPopup({
        title: strMac.notif.save.title,
        content: strMac.notif.save.message,
        actionCenter: {
          text: strMac.notif.save.center,
          action: () => {
            localStorage.removeItem(MCBKUP.unused);
            localStorage.removeItem(MCBKUP.unserv);
            localStorage.removeItem(MCBKUP.consent);
            localStorage.removeItem(MCBKUP.oRice);
            localStorage.removeItem(MCBKUP.oCorn);

            localStorage.removeItem(MCBKUP.qrCode);
            localStorage.removeItem(MCBKUP.acc);
            localStorage.removeItem(MCBKUP.lat);
            localStorage.removeItem(MCBKUP.lng);
            localStorage.removeItem(MCBKUP.imageFront);
            localStorage.removeItem(MCBKUP.imageSide);
            localStorage.removeItem(MCBKUP.imagePlate);
          },
        },
      });
    }

    return proceedToSave;
  }
}

// /////////////////////////////////////////////////////////////////////////////
// MACHINE FORMAT //////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface MachineData {
  resName: string;
  qrCode: string;
  imageFront: string;
  imageSide: string;
  imagePlate: string;
  lat: string;
  lng: string;
  acc: string;
  prov: string;
  mun: string;
  brgy: string;
  macType: string;
  brand: string;
  model: string;
  rPower: string;
  sArea: string;
  oDay: string;
  oSeason: string;
  lCap: string;
  lCapUnit: string;
  lCapLoad: string;
  effArea: string;
  time: string;
  // fCap: string;
  yield: string;
  pType: string;
  pBrand: string;
  pModel: string;
  pSize: string;
  pSource: string;
  pSBrand: string;
  pSModel: string;
  pSRPower: string;
  pDischarge: string;
  pDisUnit: string;
  pSArea: string;
  pODay: string;
  pOSeason: string;
  oob: string;
  class: string;
  usage: string;
  mode: string;
  agency: string;
  acqYear: string;
  cond: string;
  pCond: string;
  uuYear: string;
  unused: string;
  uuOthers: string;
  usYear: string;
  unserv: string;
  usOthers: string;
  contact1: string;
  contact2: string;
  spProv: string;
  spMun: string;
  spBrgy: string;
  consent: string;
  rental: string;
  fOper: string;
  fORate: string;
  fORateUnit: string;
  fOFuel: string;
  sOper: string;
  sORate: string;
  sORateUnit: string;
  sOFuel: string;
  tOper: string;
  tORate: string;
  tORateUnit: string;
  tOFuel: string;
  // conRate: string;
  // conUnit: string;
  // conFuel: string;
  // avail: string;
  // mill: string;
  // rProv: string;
  // rMun: string;
  // presCon: string;
  ops: string;
  ope02: string;
  ope2Abv: string;
  opeWith: string;
  opeWithout: string;
  used: string;
  breakdowns: string;
  aFilter: string;
  eOil: string;
  oFilter: string;
  hOil: string;
  hOFil: string;
  tire: string;
  sName: string;
  sProv: string;
  sMun: string;
  sBrgy: string;
  sTimeRes: string;
  sPartsAvail: string;
  othCrp: string;
  oRice: string;
  oRSArea: string;
  oCorn: string;
  oCSArea: string;
  oOth: string;
  oOArea: string;
  notes: string;
  uname: string;
  date: string;
  status: string;
}

// /////////////////////////////////////////////////////////////////////////////
// BRANDS AND MODELS ///////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// Brands and Models ///////////////////////////////////////////////////////////

const oBTrc2Brand1 = "ACT";
const oBTrc2Brand2 = "AIMS";
const oBTrc2Brand3 = "APT";
const oBTrc2Brand4 = "BOWA";
const oBTrc2Brand5 = "BUFFALO";
const oBTrc2Brand6 = "D.U.A";
const oBTrc2Brand7 = "FARM MASTER";
const oBTrc2Brand8 = "KAPITAN";
const oBTrc2Brand9 = "KASAMA HARABAS";
const oBTrc2Brand10 = "KATO";
const oBTrc2Brand11 = "KELLY";
const oBTrc2Brand12 = "KULIGLIG";
const oBTrc2Brand13 = "LONG FOONG";
const oBTrc2Brand14 = "MITSUBOMAR";
const oBTrc2Brand15 = "NICHINO";
const oBTrc2Brand16 = "SUMO PLUS";
const oBTrc2Brand17 = "SUPER";
const oBTrc2Brand18 = "TIBAY KULIGLIG";
const oBTrc2Brand19 = "TRIPLE J";
const oBTrc2Brand20 = "WEST WIND";
const oBTrc2Brand21 = "Not Applicable";

const oBTrc4Brand1 = "AGRINDO";
const oBTrc4Brand2 = "BRANSON";
const oBTrc4Brand3 = "CASE IH";
const oBTrc4Brand4 = "DEUTZ-FAHR";
const oBTrc4Brand5 = "EUROTRAC";
const oBTrc4Brand6 = "FARM ROVER";
const oBTrc4Brand7 = "FIAT";
const oBTrc4Brand8 = "FORD";
const oBTrc4Brand9 = "FORDSON";
const oBTrc4Brand10 = "INTERNATIONAL HARVESTER IH";
const oBTrc4Brand11 = "ISEKI";
const oBTrc4Brand12 = "ITMCO";
const oBTrc4Brand13 = "JOHN DEERE";
const oBTrc4Brand14 = "KAMOL";
const oBTrc4Brand15 = "KIOTI";
const oBTrc4Brand16 = "KUBOTA";
const oBTrc4Brand17 = "LANDINI";
const oBTrc4Brand18 = "LEYLAND";
const oBTrc4Brand19 = "LOVOL";
const oBTrc4Brand20 = "LS";
const oBTrc4Brand21 = "MAHINDRA";
const oBTrc4Brand22 = "MASSEY FERGUSON";
const oBTrc4Brand23 = "MC CORMICK";
const oBTrc4Brand24 = "NEW HOLLAND";
const oBTrc4Brand25 = "PREET";
const oBTrc4Brand26 = "SHUHE";
const oBTrc4Brand27 = "SAME";
const oBTrc4Brand28 = "SONALIKA";
const oBTrc4Brand29 = "VALTRA";
const oBTrc4Brand30 = "WEITAI";
const oBTrc4Brand31 = "YANMAR";
const oBTrc4Brand32 = "Not Applicable";

const oBCaneBrand1 = "JING GONG III";
const oBCaneBrand2 = "APACHE";
const oBCaneBrand3 = "Not Applicable";

const oBHrvtBrand1 = "CAMECO";
const oBHrvtBrand2 = "CASE IH";
const oBHrvtBrand3 = "CATERPILLAR";
const oBHrvtBrand4 = "ESMECH";
const oBHrvtBrand5 = "FORD";
const oBHrvtBrand6 = "JOHN DEERE";
const oBHrvtBrand7 = "JING GONG";
const oBHrvtBrand8 = "KUBOTA";
const oBHrvtBrand9 = "SHAKTIMAN";
const oBHrvtBrand10 = "TOFT";
const oBHrvtBrand11 = "WORLD";
const oBHrvtBrand12 = "YANMAR ";
const oBHrvtBrand13 = "SMKY";
const oBHrvtBrand14 = "Not Applicable";

const oBPumpBrand1 = "KATO";
const oBPumpBrand2 = "MAXIPRO";
const oBPumpBrand3 = "INNOVA";
const oBPumpBrand4 = "KENBO";
const oBPumpBrand5 = "GAUDI";
const oBPumpBrand6 = "HINOKI";
const oBPumpBrand7 = "TARO";
const oBPumpBrand8 = "ROBIN";
const oBPumpBrand9 = "MIYATA";
const oBPumpBrand10 = "EAGLE";
const oBPumpBrand11 = "KITACO";
const oBPumpBrand12 = "SAKAI";
const oBPumpBrand13 = "EXTREME";
const oBPumpBrand14 = "TAY";
const oBPumpBrand15 = "BLUMAX";
const oBPumpBrand16 = "KAMA";
const oBPumpBrand17 = "SOLAR PUMP";
const oBPumpBrand18 = "YAMADA";
const oBPumpBrand19 = "SUPER KASAMA";
const oBPumpBrand20 = "COMET";
const oBPumpBrand21 = "GRUNDFOS";
const oBPumpBrand22 = "OKAMA";
const oBPumpBrand23 = "YAMAHA";
const oBPumpBrand24 = "KAIAO";
const oBPumpBrand25 = "HONDA";
const oBPumpBrand26 = "TSURUMI";
const oBPumpBrand27 = "LAUNTOP";
const oBPumpBrand28 = "MOTORSTAR";
const oBPumpBrand29 = "KAITO";
const oBPumpBrand30 = "NITTOO";
const oBPumpBrand31 = "PLATINUM";
const oBPumpBrand32 = "LORENTZ";
const oBPumpBrand33 = "BRIGGS AND STRATTON";
const oBPumpBrand34 = "HAKATA";
const oBPumpBrand35 = "SUMO";
const oBPumpBrand36 = "VIKYNO";
const oBPumpBrand37 = "ISHII";
const oBPumpBrand38 = "TOKOYAMA";
const oBPumpBrand39 = "BALLENA";
const oBPumpBrand40 = "BLACK HAWK";
const oBPumpBrand41 = "WEIMA";
const oBPumpBrand42 = "Not Applicable";

// Brands //////////////////////////////////////////////////////////////////////
const oOBrands = [
  { label: oBTrc2Brand1, type: tTrc2 },
  { label: oBTrc2Brand2, type: tTrc2 },
  { label: oBTrc2Brand3, type: tTrc2 },
  { label: oBTrc2Brand4, type: tTrc2 },
  { label: oBTrc2Brand5, type: tTrc2 },
  { label: oBTrc2Brand6, type: tTrc2 },
  { label: oBTrc2Brand7, type: tTrc2 },
  { label: oBTrc2Brand8, type: tTrc2 },
  { label: oBTrc2Brand9, type: tTrc2 },
  { label: oBTrc2Brand10, type: tTrc2 },
  { label: oBTrc2Brand11, type: tTrc2 },
  { label: oBTrc2Brand12, type: tTrc2 },
  { label: oBTrc2Brand13, type: tTrc2 },
  { label: oBTrc2Brand14, type: tTrc2 },
  { label: oBTrc2Brand15, type: tTrc2 },
  { label: oBTrc2Brand16, type: tTrc2 },
  { label: oBTrc2Brand17, type: tTrc2 },
  { label: oBTrc2Brand18, type: tTrc2 },
  { label: oBTrc2Brand19, type: tTrc2 },
  { label: oBTrc2Brand20, type: tTrc2 },
  { label: oBTrc2Brand21, type: tTrc2 },

  { label: oBTrc4Brand1, type: tTrc4 },
  { label: oBTrc4Brand2, type: tTrc4 },
  { label: oBTrc4Brand3, type: tTrc4 },
  { label: oBTrc4Brand4, type: tTrc4 },
  { label: oBTrc4Brand5, type: tTrc4 },
  { label: oBTrc4Brand6, type: tTrc4 },
  { label: oBTrc4Brand7, type: tTrc4 },
  { label: oBTrc4Brand8, type: tTrc4 },
  { label: oBTrc4Brand9, type: tTrc4 },
  { label: oBTrc4Brand10, type: tTrc4 },
  { label: oBTrc4Brand11, type: tTrc4 },
  { label: oBTrc4Brand12, type: tTrc4 },
  { label: oBTrc4Brand13, type: tTrc4 },
  { label: oBTrc4Brand14, type: tTrc4 },
  { label: oBTrc4Brand15, type: tTrc4 },
  { label: oBTrc4Brand16, type: tTrc4 },
  { label: oBTrc4Brand17, type: tTrc4 },
  { label: oBTrc4Brand18, type: tTrc4 },
  { label: oBTrc4Brand19, type: tTrc4 },
  { label: oBTrc4Brand20, type: tTrc4 },
  { label: oBTrc4Brand21, type: tTrc4 },
  { label: oBTrc4Brand22, type: tTrc4 },
  { label: oBTrc4Brand23, type: tTrc4 },
  { label: oBTrc4Brand24, type: tTrc4 },
  { label: oBTrc4Brand25, type: tTrc4 },
  { label: oBTrc4Brand26, type: tTrc4 },
  { label: oBTrc4Brand27, type: tTrc4 },
  { label: oBTrc4Brand28, type: tTrc4 },
  { label: oBTrc4Brand29, type: tTrc4 },
  { label: oBTrc4Brand30, type: tTrc4 },
  { label: oBTrc4Brand31, type: tTrc4 },
  { label: oBTrc4Brand32, type: tTrc4 },

  { label: oBCaneBrand1, type: tCane },
  { label: oBCaneBrand2, type: tCane },
  { label: oBCaneBrand3, type: tCane },

  { label: oBHrvtBrand1, type: tHrvt },
  { label: oBHrvtBrand2, type: tHrvt },
  { label: oBHrvtBrand3, type: tHrvt },
  { label: oBHrvtBrand4, type: tHrvt },
  { label: oBHrvtBrand5, type: tHrvt },
  { label: oBHrvtBrand6, type: tHrvt },
  { label: oBHrvtBrand7, type: tHrvt },
  { label: oBHrvtBrand8, type: tHrvt },
  { label: oBHrvtBrand9, type: tHrvt },
  { label: oBHrvtBrand10, type: tHrvt },
  { label: oBHrvtBrand11, type: tHrvt },
  { label: oBHrvtBrand12, type: tHrvt },
  { label: oBHrvtBrand13, type: tHrvt },
  { label: oBHrvtBrand14, type: tHrvt },

  { label: oBPumpBrand1, type: tPump },
  { label: oBPumpBrand2, type: tPump },
  { label: oBPumpBrand3, type: tPump },
  { label: oBPumpBrand4, type: tPump },
  { label: oBPumpBrand5, type: tPump },
  { label: oBPumpBrand6, type: tPump },
  { label: oBPumpBrand7, type: tPump },
  { label: oBPumpBrand8, type: tPump },
  { label: oBPumpBrand9, type: tPump },
  { label: oBPumpBrand10, type: tPump },
  { label: oBPumpBrand11, type: tPump },
  { label: oBPumpBrand12, type: tPump },
  { label: oBPumpBrand13, type: tPump },
  { label: oBPumpBrand14, type: tPump },
  { label: oBPumpBrand15, type: tPump },
  { label: oBPumpBrand16, type: tPump },
  { label: oBPumpBrand17, type: tPump },
  { label: oBPumpBrand18, type: tPump },
  { label: oBPumpBrand19, type: tPump },
  { label: oBPumpBrand20, type: tPump },
  { label: oBPumpBrand21, type: tPump },
  { label: oBPumpBrand22, type: tPump },
  { label: oBPumpBrand23, type: tPump },
  { label: oBPumpBrand24, type: tPump },
  { label: oBPumpBrand25, type: tPump },
  { label: oBPumpBrand26, type: tPump },
  { label: oBPumpBrand27, type: tPump },
  { label: oBPumpBrand28, type: tPump },
  { label: oBPumpBrand29, type: tPump },
  { label: oBPumpBrand30, type: tPump },
  { label: oBPumpBrand31, type: tPump },
  { label: oBPumpBrand32, type: tPump },
  { label: oBPumpBrand33, type: tPump },
  { label: oBPumpBrand34, type: tPump },
  { label: oBPumpBrand35, type: tPump },
  { label: oBPumpBrand36, type: tPump },
  { label: oBPumpBrand37, type: tPump },
  { label: oBPumpBrand38, type: tPump },
  { label: oBPumpBrand39, type: tPump },
  { label: oBPumpBrand40, type: tPump },
  { label: oBPumpBrand41, type: tPump },
  { label: oBPumpBrand42, type: tPump },
];

// Models //////////////////////////////////////////////////////////////////////
const oOModels = [
  { label: "Isabelo - Diesel", brand: oBTrc2Brand1 },
  { label: "Isabelo - Gasoline", brand: oBTrc2Brand1 },
  { label: "Mega Isabelo", brand: oBTrc2Brand1 },
  { label: "Supermasa - Diesel-AC", brand: oBTrc2Brand1 },
  { label: "Supermasa - Diesel-WC", brand: oBTrc2Brand1 },
  { label: "Supermasa - Gasoline", brand: oBTrc2Brand1 },
  { label: "Masa - Diesel-AC", brand: oBTrc2Brand1 },
  { label: "Masa - Diesel-WC", brand: oBTrc2Brand1 },
  { label: "Masa - Gasoline", brand: oBTrc2Brand1 },
  { label: "No model specified", brand: oBTrc2Brand2 },
  { label: "WR652", brand: oBTrc2Brand3 },
  { label: "WR653", brand: oBTrc2Brand3 },
  { label: "MT-921G", brand: oBTrc2Brand4 },
  { label: "BHT-001", brand: oBTrc2Brand5 },
  { label: "No model specified", brand: oBTrc2Brand6 },
  { label: "FMHT-60C", brand: oBTrc2Brand7 },
  { label: "1001", brand: oBTrc2Brand8 },
  { label: "No model specified", brand: oBTrc2Brand9 },
  { label: "KPT86-B", brand: oBTrc2Brand10 },
  { label: "KHT", brand: oBTrc2Brand11 },
  { label: "SK-106 - Gasoline", brand: oBTrc2Brand12 },
  { label: "SK-106 - Diesel", brand: oBTrc2Brand12 },
  { label: "WR801", brand: oBTrc2Brand13 },
  { label: "WR652", brand: oBTrc2Brand13 },
  { label: "No model specified", brand: oBTrc2Brand14 },
  { label: "IE-102", brand: oBTrc2Brand15 },
  { label: "SPHT-60", brand: oBTrc2Brand16 },
  { label: "SFT01", brand: oBTrc2Brand17 },
  { label: "TK-01", brand: oBTrc2Brand18 },
  { label: "No model specified", brand: oBTrc2Brand19 },
  { label: "STA-201", brand: oBTrc2Brand20 },

  { label: "TA3504", brand: oBTrc4Brand1 },
  { label: "TA3704", brand: oBTrc4Brand1 },
  { label: "TA3304", brand: oBTrc4Brand1 },
  { label: "6225R", brand: oBTrc4Brand2 },
  { label: "5825R", brand: oBTrc4Brand2 },
  { label: "5025R", brand: oBTrc4Brand2 },
  { label: "F50R", brand: oBTrc4Brand2 },
  { label: "F47R", brand: oBTrc4Brand2 },
  { label: "F42R", brand: oBTrc4Brand2 },
  { label: "F36R", brand: oBTrc4Brand2 },
  { label: "5520R", brand: oBTrc4Brand2 },
  { label: "4820R", brand: oBTrc4Brand2 },
  { label: "3620R", brand: oBTrc4Brand2 },
  { label: "FARMALL 100JX", brand: oBTrc4Brand3 },
  { label: "FARMALL 90JX", brand: oBTrc4Brand3 },
  { label: "JX55T", brand: oBTrc4Brand3 },
  { label: "JX75T", brand: oBTrc4Brand3 },
  { label: "FARMALL 110A", brand: oBTrc4Brand3 },
  { label: "FARMALL 120A", brand: oBTrc4Brand3 },
  { label: "FARMALL 125A", brand: oBTrc4Brand3 },
  { label: "JX95", brand: oBTrc4Brand3 },
  { label: "AGROFARM TB415", brand: oBTrc4Brand4 },
  { label: "AGROFARM TB425", brand: oBTrc4Brand4 },
  { label: "AGROFARM G85", brand: oBTrc4Brand4 },
  { label: "AGROFARM G100", brand: oBTrc4Brand4 },
  { label: "AGROFARM G115", brand: oBTrc4Brand4 },
  { label: "AGROFARM C75", brand: oBTrc4Brand4 },
  { label: "AGROFARM C80", brand: oBTrc4Brand4 },
  { label: "AGROFARM C85", brand: oBTrc4Brand4 },
  { label: "AGROFARM C90", brand: oBTrc4Brand4 },
  { label: "AGROFARM C95", brand: oBTrc4Brand4 },
  { label: "AGROFARM C100", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 100", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 110", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 115", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 1130", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 150", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 170", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 3035E", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 3040E", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 3042E", brand: oBTrc4Brand4 },
  { label: "AGROLUX 80", brand: oBTrc4Brand4 },
  { label: "AGROLUX 85", brand: oBTrc4Brand4 },
  { label: "AGROLUX 90", brand: oBTrc4Brand4 },
  { label: "AGROLUX 95", brand: oBTrc4Brand4 },
  { label: "AGROLUX 100", brand: oBTrc4Brand4 },
  { label: "AGROLUX 310", brand: oBTrc4Brand4 },
  { label: "AGROLUX 320", brand: oBTrc4Brand4 },
  { label: "AGROLUX 410", brand: oBTrc4Brand4 },
  { label: "YTO MF504", brand: oBTrc4Brand5 },
  { label: "YTO MF554", brand: oBTrc4Brand5 },
  { label: "YTO MF500", brand: oBTrc4Brand5 },
  { label: "YTO MF550", brand: oBTrc4Brand5 },
  { label: "YTO X454", brand: oBTrc4Brand5 },
  { label: "YTO X904", brand: oBTrc4Brand5 },
  { label: "YTO X1204", brand: oBTrc4Brand5 },
  { label: "YTO 1604", brand: oBTrc4Brand5 },
  { label: "FR4135", brand: oBTrc4Brand6 },
  { label: "FR4145", brand: oBTrc4Brand6 },
  { label: "500", brand: oBTrc4Brand7 },
  { label: "540", brand: oBTrc4Brand7 },
  { label: "550", brand: oBTrc4Brand7 },
  { label: "600", brand: oBTrc4Brand7 },
  { label: "640", brand: oBTrc4Brand7 },
  { label: "TD  90", brand: oBTrc4Brand8 },
  { label: "TW 5", brand: oBTrc4Brand8 },
  { label: "TW 10", brand: oBTrc4Brand8 },
  { label: "TW 15", brand: oBTrc4Brand8 },
  { label: "TW 20", brand: oBTrc4Brand8 },
  { label: "TW 25", brand: oBTrc4Brand8 },
  { label: "TW 30", brand: oBTrc4Brand8 },
  { label: "TW 35", brand: oBTrc4Brand8 },
  { label: "COUNTY 754", brand: oBTrc4Brand8 },
  { label: "1164", brand: oBTrc4Brand8 },
  { label: "1660", brand: oBTrc4Brand8 },
  { label: "3930", brand: oBTrc4Brand8 },
  { label: "4000", brand: oBTrc4Brand8 },
  { label: "5000", brand: oBTrc4Brand8 },
  { label: "5600", brand: oBTrc4Brand8 },
  { label: "6000", brand: oBTrc4Brand8 },
  { label: "6610", brand: oBTrc4Brand8 },
  { label: "6640", brand: oBTrc4Brand8 },
  { label: "6600", brand: oBTrc4Brand8 },
  { label: "7000", brand: oBTrc4Brand8 },
  { label: "7210", brand: oBTrc4Brand8 },
  { label: "7610", brand: oBTrc4Brand8 },
  { label: "7710", brand: oBTrc4Brand8 },
  { label: "7810", brand: oBTrc4Brand8 },
  { label: "8100", brand: oBTrc4Brand8 },
  { label: "8210", brand: oBTrc4Brand8 },
  { label: "8700", brand: oBTrc4Brand8 },
  { label: "9210", brand: oBTrc4Brand8 },
  { label: "ALL AROUND 25HP", brand: oBTrc4Brand9 },
  { label: "DEXTA 32HP", brand: oBTrc4Brand9 },
  { label: "FARM MAJOR 42HP", brand: oBTrc4Brand9 },
  { label: "FORDSON F 20HP", brand: oBTrc4Brand9 },
  { label: "FORDSON N 26HP", brand: oBTrc4Brand9 },
  { label: "MAJOR 27HP", brand: oBTrc4Brand9 },
  { label: "MAJOR 45HP", brand: oBTrc4Brand9 },
  { label: "NEW MAJOR 38HP", brand: oBTrc4Brand9 },
  { label: "POWER MAJOR 52HP", brand: oBTrc4Brand9 },
  { label: "SUPER DEXTA 45HP", brand: oBTrc4Brand9 },
  { label: "SUPER MAJOR 54HP", brand: oBTrc4Brand9 },
  { label: "824", brand: oBTrc4Brand10 },
  { label: "T9000", brand: oBTrc4Brand11 },
  { label: "SX65", brand: oBTrc4Brand11 },
  { label: "SX75", brand: oBTrc4Brand11 },
  { label: "SX95", brand: oBTrc4Brand11 },
  { label: "T10510", brand: oBTrc4Brand11 },
  { label: "T10520", brand: oBTrc4Brand11 },
  { label: "TS1610", brand: oBTrc4Brand11 },
  { label: "TS1700", brand: oBTrc4Brand11 },
  { label: "TL3700", brand: oBTrc4Brand11 },
  { label: "ITM 240", brand: oBTrc4Brand12 },
  { label: "ITM 285", brand: oBTrc4Brand12 },
  { label: "ITM 299", brand: oBTrc4Brand12 },
  { label: "ITM 399", brand: oBTrc4Brand12 },
  { label: "ITM 475", brand: oBTrc4Brand12 },
  { label: "ITM 485", brand: oBTrc4Brand12 },
  { label: "ITM 800", brand: oBTrc4Brand12 },
  { label: "ITM 950", brand: oBTrc4Brand12 },
  { label: "ITM 1050", brand: oBTrc4Brand12 },
  { label: "JD5045D", brand: oBTrc4Brand13 },
  { label: "JD504SE", brand: oBTrc4Brand13 },
  { label: "JD5715", brand: oBTrc4Brand13 },
  { label: "JD6603", brand: oBTrc4Brand13 },
  { label: "JD6605", brand: oBTrc4Brand13 },
  { label: "JD6615", brand: oBTrc4Brand13 },
  { label: "JD6525", brand: oBTrc4Brand13 },
  { label: "JD6110B", brand: oBTrc4Brand13 },
  { label: "JD2140", brand: oBTrc4Brand13 },
  { label: "JD 804", brand: oBTrc4Brand13 },
  { label: "6125M", brand: oBTrc4Brand13 },
  { label: "3036E", brand: oBTrc4Brand13 },
  { label: "6630", brand: oBTrc4Brand13 },
  { label: "5400", brand: oBTrc4Brand13 },
  { label: "5525", brand: oBTrc4Brand13 },
  { label: "6615", brand: oBTrc4Brand13 },
  { label: "6425", brand: oBTrc4Brand13 },
  { label: "6510", brand: oBTrc4Brand13 },
  { label: "6215", brand: oBTrc4Brand13 },
  { label: "6420", brand: oBTrc4Brand13 },
  { label: "5400", brand: oBTrc4Brand13 },
  { label: "6410", brand: oBTrc4Brand13 },
  { label: "120", brand: oBTrc4Brand13 },
  { label: "1640", brand: oBTrc4Brand13 },
  { label: "3140", brand: oBTrc4Brand13 },
  { label: "8606", brand: oBTrc4Brand13 },
  { label: "3120", brand: oBTrc4Brand13 },
  { label: "3520", brand: oBTrc4Brand13 },
  { label: "2130", brand: oBTrc4Brand13 },
  { label: "5705", brand: oBTrc4Brand13 },
  { label: "5095", brand: oBTrc4Brand14 },
  { label: "5077", brand: oBTrc4Brand14 },
  { label: "CK22", brand: oBTrc4Brand15 },
  { label: "DS4110-HS", brand: oBTrc4Brand15 },
  { label: "DS4510-HS", brand: oBTrc4Brand15 },
  { label: "DS3510", brand: oBTrc4Brand15 },
  { label: "DK901", brand: oBTrc4Brand15 },
  { label: "M95400", brand: oBTrc4Brand16 },
  { label: "M1085", brand: oBTrc4Brand16 },
  { label: "M7040", brand: oBTrc4Brand16 },
  { label: "M9000", brand: oBTrc4Brand16 },
  { label: "L3500", brand: oBTrc4Brand16 },
  { label: "L3608", brand: oBTrc4Brand16 },
  { label: "B2140S", brand: oBTrc4Brand16 },
  { label: "B2420D", brand: oBTrc4Brand16 },
  { label: "B2440S", brand: oBTrc4Brand16 },
  { label: "B6000", brand: oBTrc4Brand16 },
  { label: "L140", brand: oBTrc4Brand16 },
  { label: "L1501", brand: oBTrc4Brand16 },
  { label: "L1801", brand: oBTrc4Brand16 },
  { label: "B1820", brand: oBTrc4Brand16 },
  { label: "L2002", brand: oBTrc4Brand16 },
  { label: "L2201", brand: oBTrc4Brand16 },
  { label: "L2202", brand: oBTrc4Brand16 },
  { label: "L4018", brand: oBTrc4Brand16 },
  { label: "L4740", brand: oBTrc4Brand16 },
  { label: "L5018", brand: oBTrc4Brand16 },
  { label: "L2402", brand: oBTrc4Brand16 },
  { label: "L1500", brand: oBTrc4Brand16 },
  { label: "MISTRAL 40", brand: oBTrc4Brand17 },
  { label: "MISTRAL 45", brand: oBTrc4Brand17 },
  { label: "MISTRAL 50", brand: oBTrc4Brand17 },
  { label: "LANDFORCE 115", brand: oBTrc4Brand17 },
  { label: "LANDFORCE 125", brand: oBTrc4Brand17 },
  { label: "DT 8860", brand: oBTrc4Brand17 },
  { label: "GLOBALFARM 90", brand: oBTrc4Brand17 },
  { label: "GLOBALFARM 100", brand: oBTrc4Brand17 },
  { label: "LANDPOWER 115", brand: oBTrc4Brand17 },
  { label: "LANDPOWER 130", brand: oBTrc4Brand17 },
  { label: "LANDPOWER 160", brand: oBTrc4Brand17 },
  { label: "245", brand: oBTrc4Brand18 },
  { label: "255", brand: oBTrc4Brand18 },
  { label: "270", brand: oBTrc4Brand18 },
  { label: "272", brand: oBTrc4Brand18 },
  { label: "279", brand: oBTrc4Brand18 },
  { label: "TB 504", brand: oBTrc4Brand19 },
  { label: "TD 904", brand: oBTrc4Brand19 },
  { label: "TB 454", brand: oBTrc4Brand19 },
  { label: "LK-354", brand: oBTrc4Brand19 },
  { label: "LK-454", brand: oBTrc4Brand19 },
  { label: "LK-904", brand: oBTrc4Brand19 },
  { label: "XU6158", brand: oBTrc4Brand20 },
  { label: "XU6168", brand: oBTrc4Brand20 },
  { label: "MT573", brand: oBTrc4Brand20 },
  { label: "XP8084", brand: oBTrc4Brand20 },
  { label: "XP8101", brand: oBTrc4Brand20 },
  { label: "9500", brand: oBTrc4Brand21 },
  { label: "MF445 XTRA", brand: oBTrc4Brand22 },
  { label: "MF455 XTRA", brand: oBTrc4Brand22 },
  { label: "MF460 XTRA", brand: oBTrc4Brand22 },
  { label: "MF470 XTRA", brand: oBTrc4Brand22 },
  { label: "MF 390", brand: oBTrc4Brand22 },
  { label: "MF 7614", brand: oBTrc4Brand22 },
  { label: "MF 7615", brand: oBTrc4Brand22 },
  { label: "MF 4708", brand: oBTrc4Brand22 },
  { label: "MF 5709", brand: oBTrc4Brand22 },
  { label: "MF 5710", brand: oBTrc4Brand22 },
  { label: "MF 6712", brand: oBTrc4Brand22 },
  { label: "MF 6713", brand: oBTrc4Brand22 },
  { label: "MF 2515", brand: oBTrc4Brand22 },
  { label: "MF 2615", brand: oBTrc4Brand22 },
  { label: "MF 290T", brand: oBTrc4Brand22 },
  { label: "1552", brand: oBTrc4Brand22 },
  { label: "165", brand: oBTrc4Brand22 },
  { label: "185", brand: oBTrc4Brand22 },
  { label: "399", brand: oBTrc4Brand22 },
  { label: "440", brand: oBTrc4Brand22 },
  { label: "454", brand: oBTrc4Brand22 },
  { label: "460", brand: oBTrc4Brand22 },
  { label: "590", brand: oBTrc4Brand22 },
  { label: "595", brand: oBTrc4Brand22 },
  { label: "599", brand: oBTrc4Brand22 },
  { label: "B100 MAX.CM 50", brand: oBTrc4Brand23 },
  { label: "C95 MAX", brand: oBTrc4Brand23 },
  { label: "C100MAX", brand: oBTrc4Brand23 },
  { label: "C110MAX", brand: oBTrc4Brand23 },
  { label: "T110MAX", brand: oBTrc4Brand23 },
  { label: "TC28R", brand: oBTrc4Brand24 },
  { label: "TC38R", brand: oBTrc4Brand24 },
  { label: "TC48R", brand: oBTrc4Brand24 },
  { label: "TDS.75", brand: oBTrc4Brand24 },
  { label: "TDS.90", brand: oBTrc4Brand24 },
  { label: "TDS.100", brand: oBTrc4Brand24 },
  { label: "TDS.110", brand: oBTrc4Brand24 },
  { label: "TD 80D", brand: oBTrc4Brand24 },
  { label: "TD 90D", brand: oBTrc4Brand24 },
  { label: "TT3.35", brand: oBTrc4Brand24 },
  { label: "TT3.50", brand: oBTrc4Brand24 },
  { label: "TT35", brand: oBTrc4Brand24 },
  { label: "TT55", brand: oBTrc4Brand24 },
  { label: "TT75", brand: oBTrc4Brand24 },
  { label: "TS 6030", brand: oBTrc4Brand24 },
  { label: "TS6140", brand: oBTrc4Brand24 },
  { label: "TS6.120", brand: oBTrc4Brand24 },
  { label: "TS6.125", brand: oBTrc4Brand24 },
  { label: "TS6.110", brand: oBTrc4Brand24 },
  { label: "TS 90", brand: oBTrc4Brand24 },
  { label: "TS 110", brand: oBTrc4Brand24 },
  { label: "TS 115", brand: oBTrc4Brand24 },
  { label: "TS 120", brand: oBTrc4Brand24 },
  { label: "TS 125", brand: oBTrc4Brand24 },
  { label: "TS 135", brand: oBTrc4Brand24 },
  { label: "6610", brand: oBTrc4Brand24 },
  { label: "6640", brand: oBTrc4Brand24 },
  { label: "8260", brand: oBTrc4Brand24 },
  { label: "9010", brand: oBTrc4Brand24 },
  { label: "9049", brand: oBTrc4Brand25 },
  { label: "6549", brand: oBTrc4Brand25 },
  { label: "6049", brand: oBTrc4Brand25 },
  { label: "SH404", brand: oBTrc4Brand26 },
  { label: "EXPLORER 95", brand: oBTrc4Brand27 },
  { label: "EXPLORER 100", brand: oBTrc4Brand27 },
  { label: "EXPLORER 105", brand: oBTrc4Brand27 },
  { label: "EXPLORER 115", brand: oBTrc4Brand27 },
  { label: "EXPLORER 125", brand: oBTrc4Brand27 },
  { label: "EXPLORER II 60", brand: oBTrc4Brand27 },
  { label: "EXPLORER II 70", brand: oBTrc4Brand27 },
  { label: "EXPLORER II 80", brand: oBTrc4Brand27 },
  { label: "EXPLORER II 90", brand: oBTrc4Brand27 },
  { label: "WORLDTRAC 90RX", brand: oBTrc4Brand28 },
  { label: "DI-740 III", brand: oBTrc4Brand28 },
  { label: "DI-730 III", brand: oBTrc4Brand28 },
  { label: "DI=60", brand: oBTrc4Brand28 },
  { label: "DI-60RX", brand: oBTrc4Brand28 },
  { label: "DI-745 III", brand: oBTrc4Brand28 },
  { label: "DI-745 RX III", brand: oBTrc4Brand28 },
  { label: "DI-750", brand: oBTrc4Brand28 },
  { label: "DI-26", brand: oBTrc4Brand28 },
  { label: "DI-35", brand: oBTrc4Brand28 },
  { label: "DI-42", brand: oBTrc4Brand28 },
  { label: "DI-47", brand: oBTrc4Brand28 },
  { label: "DI-50", brand: oBTrc4Brand28 },
  { label: "DI-55", brand: oBTrc4Brand28 },
  { label: "DI 750 III", brand: oBTrc4Brand28 },
  { label: "WORLDTRAC 75 RX", brand: oBTrc4Brand28 },
  { label: "WORLDTRAC 60 RX", brand: oBTrc4Brand28 },
  { label: "RX 35", brand: oBTrc4Brand28 },
  { label: "RX 42", brand: oBTrc4Brand28 },
  { label: "RX 47", brand: oBTrc4Brand28 },
  { label: "RX 50", brand: oBTrc4Brand28 },
  { label: "RX 60", brand: oBTrc4Brand28 },
  { label: "RX 750 III DLX", brand: oBTrc4Brand28 },
  { label: "A950", brand: oBTrc4Brand29 },
  { label: "TS354", brand: oBTrc4Brand30 },
  { label: "TS404", brand: oBTrc4Brand30 },
  { label: "TS454", brand: oBTrc4Brand30 },
  { label: "TS504", brand: oBTrc4Brand30 },
  { label: "T854", brand: oBTrc4Brand30 },
  { label: "TT904", brand: oBTrc4Brand30 },
  { label: "TT1004", brand: oBTrc4Brand30 },
  { label: "TT1104", brand: oBTrc4Brand30 },
  { label: "TT1204", brand: oBTrc4Brand30 },
  { label: "EF393T", brand: oBTrc4Brand31 },
  { label: "EF514T", brand: oBTrc4Brand31 },
  { label: "EF725T", brand: oBTrc4Brand31 },
  { label: "YM351A", brand: oBTrc4Brand31 },
  { label: "YM357A", brand: oBTrc4Brand31 },

  { label: "JG90Z", brand: oBCaneBrand1 },
  { label: "ASZ-7600", brand: oBCaneBrand2 },

  { label: "3510", brand: oBHrvtBrand1 },
  { label: "A4000", brand: oBHrvtBrand2 },
  { label: "A7000", brand: oBHrvtBrand2 },
  { label: "A7700", brand: oBHrvtBrand2 },
  { label: "A8000", brand: oBHrvtBrand2 },
  { label: "A8800", brand: oBHrvtBrand2 },
  { label: "A8010", brand: oBHrvtBrand2 },
  { label: "A8810", brand: oBHrvtBrand2 },
  { label: "No model specified", brand: oBHrvtBrand3 },
  { label: "7000", brand: oBHrvtBrand4 },
  { label: "No model specified", brand: oBHrvtBrand5 },
  { label: "CH 570", brand: oBHrvtBrand6 },
  { label: "CH 330", brand: oBHrvtBrand6 },
  { label: "3520", brand: oBHrvtBrand6 },
  { label: "JG 4GL-1", brand: oBHrvtBrand7 },
  { label: "SH95", brand: oBHrvtBrand8 },
  { label: "3737", brand: oBHrvtBrand9 },
  { label: "4000", brand: oBHrvtBrand10 },
  { label: "6000", brand: oBHrvtBrand10 },
  { label: "4GQ-1", brand: oBHrvtBrand11 },
  { label: "HS2000", brand: oBHrvtBrand12 },
  { label: "SM200", brand: oBHrvtBrand13 },
  { label: "SM200 GIANT", brand: oBHrvtBrand13 },

  { label: "KC-40", brand: oBPumpBrand1 },
  { label: "KCS40-E", brand: oBPumpBrand1 },
  { label: "KDP100", brand: oBPumpBrand1 },
  { label: "KC40", brand: oBPumpBrand1 },
  { label: "KSP40", brand: oBPumpBrand1 },
  { label: "KSP30", brand: oBPumpBrand1 },
  { label: "KC30 3X3", brand: oBPumpBrand1 },
  { label: "KDP50 2X2", brand: oBPumpBrand1 },
  { label: "KT50", brand: oBPumpBrand1 },
  { label: "CP-200L", brand: oBPumpBrand2 },
  { label: "NVC-15", brand: oBPumpBrand3 },
  { label: "NVC-22", brand: oBPumpBrand3 },
  { label: "KBP20", brand: oBPumpBrand4 },
  { label: "KBP30", brand: oBPumpBrand4 },
  { label: "GDW-9295-6", brand: oBPumpBrand5 },
  { label: "HP80D", brand: oBPumpBrand6 },
  { label: "HP50D", brand: oBPumpBrand6 },
  { label: "4X4 MS", brand: oBPumpBrand7 },
  { label: "4X4 PT", brand: oBPumpBrand7 },
  { label: "3X3 MS", brand: oBPumpBrand7 },
  { label: "3X3 PT", brand: oBPumpBrand7 },
  { label: "2X2 MS", brand: oBPumpBrand7 },
  { label: "2X2 PT", brand: oBPumpBrand7 },
  { label: "RPX206", brand: oBPumpBrand8 },
  { label: "RP205", brand: oBPumpBrand8 },
  { label: "RP203", brand: oBPumpBrand8 },
  { label: "PTG207", brand: oBPumpBrand8 },
  { label: "RP305", brand: oBPumpBrand8 },
  { label: "RP306", brand: oBPumpBrand8 },
  { label: "PTG305", brand: oBPumpBrand8 },
  { label: "NS100", brand: oBPumpBrand9 },
  { label: "NS80", brand: oBPumpBrand9 },
  { label: "NS50", brand: oBPumpBrand9 },
  { label: "RBP-305", brand: oBPumpBrand9 },
  { label: "NS100", brand: oBPumpBrand10 },
  { label: "EC100", brand: oBPumpBrand10 },
  { label: "NS-80", brand: oBPumpBrand10 },
  { label: "EC80", brand: oBPumpBrand10 },
  { label: "NS-50", brand: oBPumpBrand10 },
  { label: "EC50", brand: oBPumpBrand10 },
  { label: "TS-100", brand: oBPumpBrand11 },
  { label: "4X4 PACKING TYPE", brand: oBPumpBrand11 },
  { label: "4X4 MS", brand: oBPumpBrand11 },
  { label: "NS100", brand: oBPumpBrand11 },
  { label: "3X3 MS", brand: oBPumpBrand11 },
  { label: "NS80", brand: oBPumpBrand12 },
  { label: "2X2 MECHANICAL TYPE", brand: oBPumpBrand12 },
  { label: "3X3 PACKING STYLE", brand: oBPumpBrand12 },
  { label: "4X4 MECHANICAL SEAL", brand: oBPumpBrand12 },
  { label: "4X4 PACKING TYPE", brand: oBPumpBrand13 },
  { label: "NS100", brand: oBPumpBrand14 },
  { label: "TS100", brand: oBPumpBrand14 },
  { label: "BXD-100-P", brand: oBPumpBrand15 },
  { label: "BXD-80P", brand: oBPumpBrand15 },
  { label: "KDP20", brand: oBPumpBrand16 },
  { label: "KDP30", brand: oBPumpBrand16 },
  { label: "KDP40", brand: oBPumpBrand16 },
  { label: "4PSP2-3", brand: oBPumpBrand17 },
  { label: "DPW50", brand: oBPumpBrand18 },
  { label: "DHP50", brand: oBPumpBrand18 },
  { label: "DPW80", brand: oBPumpBrand18 },
  { label: "DHP80", brand: oBPumpBrand18 },
  { label: "DPW-100", brand: oBPumpBrand18 },
  { label: "KDP40", brand: oBPumpBrand19 },
  { label: "KDP30", brand: oBPumpBrand19 },
  { label: "KDP20", brand: oBPumpBrand19 },
  { label: "TCM-4B", brand: oBPumpBrand20 },
  { label: "TCM-4A", brand: oBPumpBrand20 },
  { label: "TCM 4H", brand: oBPumpBrand20 },
  { label: "TDCV-4", brand: oBPumpBrand20 },
  { label: "CRIF15-01", brand: oBPumpBrand21 },
  { label: "SQF 2.2-2", brand: oBPumpBrand21 },
  { label: "KDP3", brand: oBPumpBrand22 },
  { label: "YP30G", brand: oBPumpBrand23 },
  { label: "KDP40", brand: oBPumpBrand24 },
  { label: "KDP20", brand: oBPumpBrand24 },
  { label: "KDP30", brand: oBPumpBrand24 },
  { label: "WB30XH", brand: oBPumpBrand25 },
  { label: "WB20XH", brand: oBPumpBrand25 },
  { label: "TE5-80R", brand: oBPumpBrand26 },
  { label: "TE5-50R", brand: oBPumpBrand26 },
  { label: "LTP50C", brand: oBPumpBrand27 },
  { label: "LTP80C", brand: oBPumpBrand27 },
  { label: "MS50 2X2", brand: oBPumpBrand28 },
  { label: "MS50 3X3", brand: oBPumpBrand28 },
  { label: "LXT100", brand: oBPumpBrand29 },
  { label: "LXT80", brand: oBPumpBrand29 },
  { label: "TS100", brand: oBPumpBrand30 },
  { label: "NS100", brand: oBPumpBrand30 },
  { label: "PT5.5 HP", brand: oBPumpBrand31 },
  { label: "PT6.5 HP", brand: oBPumpBrand31 },
  { label: "PS 1200HR-14", brand: oBPumpBrand32 },
  { label: "WP3-65", brand: oBPumpBrand33 },
  { label: "D50KB", brand: oBPumpBrand34 },
  { label: "D80KB", brand: oBPumpBrand34 },
  { label: "D100KB", brand: oBPumpBrand34 },
  { label: "SD100M", brand: oBPumpBrand35 },
  { label: "DT53", brand: oBPumpBrand36 },
  { label: "IW-407", brand: oBPumpBrand37 },
  { label: "WP30C", brand: oBPumpBrand38 },
  { label: "WP-30", brand: oBPumpBrand39 },
  { label: "WP20X", brand: oBPumpBrand40 },
  { label: "CGZ100-30", brand: oBPumpBrand41 },
  { label: "KCS-40", brand: oBPumpBrand1 },
];
