// /////////////////////////////////////////////////////////////////////////////
// IMPORTS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

import "../../assets/scss/Forms.css";

// Styling /////////////////////////////////////////////////////////////////////

import { Nav } from "react-bootstrap";
import SaveIcon from "@mui/icons-material/Save";

// React ///////////////////////////////////////////////////////////////////////

import { useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import { LoaderFunctionArgs } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";

// Arvouz Library //////////////////////////////////////////////////////////////

import { GET, SET, TGetSeverity } from "../../custom/afx/AType";
import { Card, HBar, VBar } from "../../custom/afx/ABox";
import {
  optMonths,
  IModPublic,
  IModButtonPublic,
  TModButton,
} from "../../custom/afx/AFX";
import { Notify, INotifyPublic } from "../../custom/afx/AFX";
import { Pick, Press, Field, Checks } from "../../custom/afx/AFX";
import { Mod, PiChecks, Text, backup } from "../../custom/afx/AFX";
import { TGetValidation, TSetValidation } from "../../custom/afx/AType";

// Address Library /////////////////////////////////////////////////////////////

import { Address, Province } from "../../custom/RAddress";
import { Municipality, Barangay } from "../../custom/RAddress";
import { provinces, municipalities, barangays } from "../../custom/Philippines";

// Custom //////////////////////////////////////////////////////////////////////

import { Route } from "../../app/Routes";
import { TitleBar } from "../encode/EncodePage";
import ConsentStatement from "./PrivacyConsent";
import { DATA_STATUS, FORMS } from "../../app/Settings";
import { fxdGen, fxdProfileBackup } from "../../app/Storage";
import { fxdProfile, fxdUser } from "../../app/Storage";

// Pages ///////////////////////////////////////////////////////////////////////

import { strMac } from "../machine/MachinePage";
import { strImp } from "../implement/ImplementPage";

// Save ////////////////////////////////////////////////////////////////////////

import { saveAs } from "file-saver";
import { AUTH_PROVIDER } from "../../hooks/auth/auth";

// /////////////////////////////////////////////////////////////////////////////
// STRINGS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export var strPrf = {
  consent: {
    title: "Privacy Agreement",
    agree: "Agree",
  },
  recover: {
    message:
      "Would you like to continue with the unsaved profile? This may take a while.",
    title: "Unsaved Profile Exists",
    no: "No",
    yes: "Yes",
    recovering: "Recovering . . .",
  },
  edit: {
    title: "Edit Profile",
    message: "Would you like to edit the profile?",
    no: "No",
    yes: "Yes",
  },
  res: {
    title: "RESPONDENT",
    name: {
      label: "Complete Name",
      placeholder: "First Name MI. Last Name",
    },
    age: {
      label: "Age",
      placeholder: "0 (In years)",
    },
    sex: {
      label: "Sex",
      placeholder: "Choose an option",
      options: [
        {
          label: "Male",
        },
        {
          label: "Female",
        },
      ],
    },
    educ: {
      label: "Educational Attainment",
      placeholder: "Choose an option",
      options: [
        {
          label: "Primary (Elementary)",
        },
        {
          label: "Secondary (High School)",
        },
        {
          label: "Tertiary (College)",
        },
        {
          label: "Post-Graduate",
        },
        {
          label: "Diploma",
        },
        {
          label: "Vocational Technical",
        },
        {
          label: "Not Applicable",
        },
      ],
    },
    cont1: {
      label: "Contact No. 1",
      placeholder: "Mobile or Telephone",
    },
    cont2: {
      label: "Contact No. 2",
      placeholder: "Mobile or Telephone",
    },
    prov: {
      label: "Province",
      placeholder: "Select Province",
    },
    mun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    brgy: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
    profile: {
      label: "Profile of Respondent",
      placeholder: "Select role",
      options: [
        {
          label: "Owner",
        },
        {
          label: "Representative",
        },
      ],
    },
    scope: {
      label: "Scope of Ownership",
      options: [
        {
          label: "Farm",
          key: "farm",
        },
        {
          label: "Processing Plant",
          key: "plant",
        },
        {
          label: "Garage",
          key: "garage",
        },
        {
          label: "Transloading Zone",
          key: "zone",
        },
      ],
    },
    pos: {
      label: "Position / Title",
      placeholder: "Select Position / Title",
      options: [
        {
          label: "Operator of Machine",
        },
        {
          label: "Manager of Transloading Zone",
        },
        {
          label: "Farm Manager",
        },
        {
          label: "Officer in Charge",
        },
        {
          label: "Representative",
        },
      ],
    },
  },
  owner: {
    title: "OWNER OR ORGANIZATION",
    name: {
      label: "Complete Name",
      placeholder: "First Name MI. Last Name",
    },
    class: {
      label: "Owner Classification",
      placeholder: "Choose an option",
      options: [
        {
          label: "Private Individual / Business",
        },
        {
          label: "Organization / Association",
        },
        {
          label: "Local Government Unit (LGU)",
        },
      ],
    },
    cont1: {
      label: "Contact No. 1",
      placeholder: "Mobile or Telephone",
    },
    cont2: {
      label: "Contact No. 2",
      placeholder: "Mobile or Telephone",
    },
    prov: {
      label: "Province",
      placeholder: "Select Province",
    },
    mun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    brgy: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
  },
  farm: {
    title: "FARM",
    accre: {
      label: "Is farm accredited by SRA?",
      placeholder: "Choose an option",
      options: [
        {
          label: "Yes",
        },
        {
          label: "No",
        },
      ],
    },
    area: {
      label: "Sugarcane farm area, ha",
      placeholder: "0.0",
    },
    tobe: [
      {
        label: "Data to be acquired",
      },
    ],
    mach: {
      label: "Most frequently rented machine",
      placeholder: "Select a machine",
    },
    impl: {
      label: "Most frequently rented implement",
      placeholder: "Select a implement",
    },
    mngt: {
      label: "Type of Farm Management",
      placeholder: "Select a management practice",
      options: [
        { label: "Individual Management" },
        { label: "Collective Management" },
      ],
    },
    varieties: "Varieties of Sugarcane",
    pltd: {
      label: "Planted Variety",
      placeholder: "Choose a variety",
      // TODO See component declaration
      "helper-X": "Max of 3 variety, press enter to separate",
    },
    pref: {
      label: "Preferred Variety",
      placeholder: "Choose a variety",
    },
    planting: "Planting Season",
    start: {
      label: "Start",
      placeholder: "Choose a month",
    },
    end: {
      label: "End",
      placeholder: "Choose a month",
    },
    harvesting: "Harvesting Season",
    number: "Number of months",
    moPlanting: {
      label: "Planting",
      placeholder: "0",
      unit: "months",
    },
    moHarvesting: {
      label: "Harvesting",
      placeholder: "0",
      unit: "months",
    },
    aYld: {
      label: "Ave. yield of planted canes",
      field: {
        label: "Yield in Planted, tons/ha",
        placeholder: "0.00",
      },
    },
    mRtn: {
      label: "Number of months to harvest ratooned crops",
      field: {
        label: "Months",
        placeholder: "0",
      },
    },
    aRtn: {
      label: "Ave. yield of ratooned canes",
      field: {
        label: "Yield in Ratooned, tons/ha",
        placeholder: "0.00",
      },
    },
    thr: {
      label: "Maximum number of Ratooning",
      field: {
        label: "Insert frequency",
        placeholder: "0",
      },
    },
    sMl: {
      label: "At what sugar mills do you sell your harvested canes?",
      field: {
        label: "Sugar Mills",
        placeholder: "Select sugar mills",
      },
      options: [
        { label: "UNIVERSAL ROBINA CORP. (URCCARSUMCO) Region II" },
        { label: "CENTRAL AZUCARERA DE TARLAC, INC. Region III" },
        { label: "CENTRAL AZUCARERA DE BAIS INC. Region II" },
        { label: "BISCOM INC. Region VI" },
        { label: "FIRST FARMERS HOLDING CORPORATION Region VI" },
        { label: "HAWAIIAN-PHILIPPINE COMPANY Region VI" },
        {
          label:
            "HDJ BAYAWAN AGRI-VENTURE CORPORATION -(HDJ-BAYAWAN) Region VII",
        },
        { label: "UNIVERSAL ROBINA CORP. (URC-LA CARLOTA) Region VI" },
        { label: "LOPEZ SUGAR CORPORATION Region VI" },
        { label: "UNIVERSAL ROBINA CORP. (URC-URSUMCO) Region VII" },
        { label: "SAGAY CENTRAL INC. Region VI" },
        { label: "UNIVERAL ROBINA CORP. (URC-SONEDCO) Region VI" },
        {
          label: "HDJ BAYAWAN AGRI-VENTURE CORPORATION (HDJ-TOLONG) Region VII",
        },
        { label: "VICTORIAS MILLING COMPANY, INC. Region VI" },
        {
          label:
            "ORGANIC PRODUCERS IN THE ISLAND OF NEGROS MULTI-PURPOSE COOPERATIVE (OPTION-MPC) Region VI",
        },
        { label: "HIDECO SUGAR MILLING CO., INC. Region VIII" },
        { label: "BUSCO SUGAR MILLING CO., INC. Region X" },
        { label: "COTABATO SUGAR CENTRAL COMPANY, INC. Region XII" },
        { label: "CRYSTAL SUGAR CO., INC. Region X" },
        { label: "DAVAO SUGAR CENTRAL CO., INC. Region XI" },
      ],
    },
  },
  note: {
    label: "Survey Notes",
    placeholder: "Enter some notes here",
  },
  save: "Save Encoded Profile",
  saveNotif: {
    title: "Save Profile",
    content: "Profile saved locally.",
    actionCenter: "Close",
  },
  notif: {
    browserError: "Upgrade your browser.",
    mun: "Please wait a few seconds after selecting municipality.",
    missing: "Kindly fill out the missing fields above.",
    saveIni: "Initializing for saving . . .",
  },
  validation: {
    name: "Please indicate complete name.",
    duplicate: "Respondent's name already exists.",
    range: "Please input a valid age.",
    contact: "Please input a valid contact number.",
    area: "Please input a valid area",
    yield: "Please input a valid yield.",
    month: "Please input valid months.",
    number: "Please input a valid number.",
    // Machine
    power: "Please input a valid power rating",
    value: "Please input a valid value",
    capacity: "Please input a valid capacity",
    load: "Please input a valid load",
    time: "Please input a valid time",
    size: "Please input a valid size",
    discharge: "Please input a valid discharge",
    propname: "Please input a valid proper name",
    year: "Please input a valid year",
    rate: "Please input a valid rate",
    fuel: "Please input a valid fuel consumption",
    operator: "Please input a valid number of operator",
    hour: "Please input a valid number of hours",
    breakdown: "Please input a valid number of breakdown",
    // Implement
    bottoms: "Please input a valid number of bottoms",
    disk: "Please input a valid number of disk size",
    distance: "Please input a valid distance",
    wcut: "Please input a valid width of cut",
    rows: "Please input a valid number of rows",
    drows: "Please input a valid distance between rows",
    nozzle: "Please input a valid number of nozzle",
    dnozzle: "Please input a valid distance between nozzle",
    outlet: "Please input a valid number of outlet",
    doutlet: "Please input a valid distance between outlet",
    passes: "Please input a valid number of passes",
  },
};

// /////////////////////////////////////////////////////////////////////////////
// CONSTANTS AND VARIABLES /////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// General use /////////////////////////////////////////////////////////////////

const PFBKUP = fxdProfileBackup;
var IRESSCP_BACKUP: string = "";

// /////////////////////////////////////////////////////////////////////////////
// PRELOADER ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function ProfileLoader({ request }: LoaderFunctionArgs) {
  localStorage.setItem(fxdGen.editing, FORMS.profile);

  if (!AUTH_PROVIDER.isAuthenticated) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }

  IRESSCP_BACKUP = localStorage.getItem(PFBKUP.resScope) ?? "";
  return null;
}

// /////////////////////////////////////////////////////////////////////////////
// THE PROFILE PAGE ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export default function ProfilePage() {
  // Navigation ////////////////////////////////////////////////////////////////

  const GOTO = useNavigate();

  // ///////////////////////////////////////////////////////////////////////////
  // STATES ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Respondent States /////////////////////////////////////////////////////////

  const [iResName, setIResName] = useState("");
  const [eResName, setEResName] = useState({
    required: false,
    condition: "duplicate",
    message: {
      label: strPrf.validation.name,
      duplicate: strPrf.validation.duplicate,
    },
  } as TGetValidation);

  const [iResAge, setIResAge] = useState("");
  const [eResAge, setEResAge] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.range },
    range: { min: 15, max: 150 },
  } as TGetValidation);

  const [iResSex, setIResSex] = useState("");
  const [eResSex, setEResSex] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iResEduc, setIResEduc] = useState("");
  const [eResEduc, setEResEduc] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iResCont1, setIResCont1] = useState("");
  const [eResCont1, setEResCont1] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
    length: { min: 10, max: 11 },
  } as TGetValidation);

  const [iResCont2, setIResCont2] = useState("");
  const [eResCont2, setEResCont2] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
    length: { min: 10, max: 11 },
  } as TGetValidation);

  const [iResProv, setIResProv] = useState("");
  const [eResProv, setEResProv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iResMun, setIResMun] = useState("");
  const [eResMun, setEResMun] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iResBrgy, setIResBrgy] = useState("");
  const [eResBrgy, setEResBrgy] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Role States ///////////////////////////////////////////////////////////////

  const [iResProf, setIResProf] = useState("");
  const [eResProf, setEResProf] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iResScp, setIResScp] = useState("");
  const [eResScp, setEResScp] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iResPos, setIResPos] = useState("");
  const [eResPos, setEResPos] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Owner States //////////////////////////////////////////////////////////////

  const [iOwName, setIOwName] = useState("");
  const [eOwName, setEOwName] = useState({
    required: false,
    condition: "name",
    message: {
      label: strPrf.validation.name,
    },
  } as TGetValidation);

  const [iOwClass, setIOwClass] = useState("");
  const [eOwClass, setEOwClass] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOwCont1, setIOwCont1] = useState("");
  const [eOwCont1, setEOwCont1] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
    length: { min: 10, max: 11 },
  } as TGetValidation);

  const [iOwCont2, setIOwCont2] = useState("");
  const [eOwCont2, setEOwCont2] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
    length: { min: 10, max: 11 },
  } as TGetValidation);

  const [iOwProv, setIOwProv] = useState("");
  const [eOwProv, setEOwProv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOwMun, setIOwMun] = useState("");
  const [eOwMun, setEOwMun] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOwBrgy, setIOwBrgy] = useState("");
  const [eOwBrgy, setEOwBrgy] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Farm States ///////////////////////////////////////////////////////////////

  const [iAccre, setIAccre] = useState("");
  const [eAccre, setEAccre] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iArea, setIArea] = useState("");
  const [eArea, setEArea] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 1, max: 99999 },
  } as TGetValidation);

  const [iMach, setIMach] = useState("");
  const [eMach, setEMach] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iImpl, setIImpl] = useState("");
  const [eImpl, setEImpl] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iMngt, setIMngt] = useState("");
  const [eMngt, setEMngt] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iVarPltd, setIVarPltd] = useState("");
  const [eVarPltd, setEVarPltd] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iVarPref, setIVarPref] = useState("");
  const [eVarPref, setEVarPref] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iStartPltng, setIStartPltng] = useState("");
  const [eStartPltng, setEStartPltng] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iEndPltng, setIEndPltng] = useState("");
  const [eEndPltng, setEEndPltng] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iStartHarv, setIStartHarv] = useState("");
  const [eStartHarv, setEStartHarv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iEndHarv, setIEndHarv] = useState("");
  const [eEndHarv, setEEndHarv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iTtlPltng, setITtlPltng] = useState("");
  const [eTtlPltng, setETtlPltng] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iTtlHarv, setITtlHarv] = useState("");
  const [eTtlHarv, setETtlHarv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iAvgYld, setIAvgYld] = useState("");
  const [eAvgYld, setEAvgYld] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.yield },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iMoRato, setIMoRato] = useState("");
  const [eMoRato, setEMoRato] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.month },
    range: { min: 0, max: 12 },
  } as TGetValidation);

  const [iAvgRato, setIAvgRato] = useState("");
  const [eAvgRato, setEAvgRato] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.yield },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iThresh, setIThresh] = useState("");
  const [eThresh, setEThresh] = useState({
    required: false,
    condition: "none",
    message: { label: strPrf.validation.number },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iSgrMill, setISgrMill] = useState("");
  const [eSgrMill, setESgrMill] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Notes States //////////////////////////////////////////////////////////////

  const [iNotes, setINotes] = useState("");
  const [eNotes, setENotes] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // ///////////////////////////////////////////////////////////////////////////
  // VISIBILITY ////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Farm checkbox states /////////////////////////////////////////////////////
  var _owner = strPrf.res.profile.options[0].label;
  var _representative = strPrf.res.profile.options[1].label;

  const farmIndex = 0;
  const farmStates = [
    useState(false), // 0 Farm
    useState(false), // 1 Plant
    useState(false), // 2 Garage
    useState(false), // 3 Zone
  ];

  // Update the respondent's scope text value //////////////////////////////////
  useEffect(() => {
    if (localStorage.getItem(fxdGen.editing) == FORMS.profile) {
      var scope = "";
      for (let i = 0; i < farmStates.length; i++)
        if (farmStates[i][GET])
          scope += strPrf.res.scope.options[i].label + ", ";
      scope.trimEnd();
      if (scope.length > 0) scope = scope.substring(0, scope.length - 2);
      setIResScp(scope);
      backup(iResScp, PFBKUP.resScope);
    }
  }, [farmStates]);

  // Respondent's role based views /////////////////////////////////////////////

  const [vScopes, setVScopes] = useState(false);
  const [vRole, setVRole] = useState(false);
  const [vOwCard, setVOwCard] = useState(false);
  const [vFarmCard, setVFarmCard] = useState(false);

  useEffect(() => {
    function updateResProf() {
      if (iResProf) {
        var resProfText = (iResProf as any).label ?? null;

        if (resProfText == _owner) {
          assistViews({ scope: true, role: false, owner: false });
        } else if (resProfText == _representative) {
          assistViews({ scope: false, role: true, owner: true });
        }
      } else {
        assistViews({ scope: false, role: false, owner: false });
        setVFarmCard(false);
      }
    }
    updateResProf();
  }, [iResProf]);

  function assistViews(props: {
    scope: boolean;
    role: boolean;
    owner: boolean;
  }) {
    setVScopes(props.scope);
    setVRole(props.role);
    setVOwCard(props.owner);
  }

  useEffect(() => {
    if (iResProf) {
      farmStates[farmIndex][0] || (iResProf as any).label == _representative
        ? setVFarmCard(true)
        : setVFarmCard(false);
    }
  }, [farmStates[0]]);

  // ///////////////////////////////////////////////////////////////////////////
  // USEEFFECTS ////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Total months of planting //////////////////////////////////////////////////
  useEffect(() => {
    if (iStartPltng && iEndPltng) {
      var start = (iStartPltng as any).intgr;
      var end = (iEndPltng as any).intgr;
      var pltng = start > end ? 12 - start + end : end - start;
      if (pltng < 13) setITtlPltng(Math.abs(pltng).toString());
    }
  }, [iStartPltng, iEndPltng]);

  // Total months of harvesting ////////////////////////////////////////////////
  useEffect(() => {
    if (iStartHarv && iEndHarv) {
      var start = (iStartHarv as any).intgr;
      var end = (iEndHarv as any).intgr;
      var harv = start > end ? 12 - start + end : end - start;
      if (harv < 13) setITtlHarv(Math.abs(harv).toString());
    }
  }, [iStartHarv, iEndHarv]);

  // Backup the total months of planting ///////////////////////////////////////
  useEffect(() => {
    backup(iTtlPltng, PFBKUP.tPltng);
  }, [iTtlPltng]);

  // Backup the total months of harvesting /////////////////////////////////////
  useEffect(() => {
    backup(iTtlHarv, PFBKUP.tHarv);
  }, [iTtlHarv]);

  // ///////////////////////////////////////////////////////////////////////////
  // CONSTANTS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Address ///////////////////////////////////////////////////////////////////

  const adRes: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  const adOwn: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  // Questions /////////////////////////////////////////////////////////////////

  type QUESTION = {
    id: number;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    errorMessage: TGetValidation;
    setErrorMessage: React.Dispatch<React.SetStateAction<TGetValidation>>;
    backup: string;
    label: string;
  };

  const QUESTIONS: QUESTION[] = [
    // Respondent Questions ////////////////////////////////////////////////////
    {
      id: 1,
      value: iResName,
      setValue: setIResName,
      errorMessage: eResName,
      setErrorMessage: setEResName,
      backup: PFBKUP.resName,
      label: strPrf.res.name.label,
    },
    {
      id: 2,
      value: iResAge,
      setValue: setIResAge,
      errorMessage: eResAge,
      setErrorMessage: setEResAge,
      backup: PFBKUP.resAge,
      label: strPrf.res.age.label,
    },
    {
      id: 3,
      value: iResSex,
      setValue: setIResSex,
      errorMessage: eResSex,
      setErrorMessage: setEResSex,
      backup: PFBKUP.resSex,
      label: strPrf.res.sex.label,
    },
    {
      id: 4,
      value: iResEduc,
      setValue: setIResEduc,
      errorMessage: eResEduc,
      setErrorMessage: setEResEduc,
      backup: PFBKUP.resEduc,
      label: strPrf.res.educ.label,
    },
    {
      id: 5,
      value: iResCont1,
      setValue: setIResCont1,
      errorMessage: eResCont1,
      setErrorMessage: setEResCont1,
      backup: PFBKUP.resCont1,
      label: strPrf.res.cont1.label,
    },
    {
      id: 6,
      value: iResCont2,
      setValue: setIResCont2,
      errorMessage: eResCont2,
      setErrorMessage: setEResCont2,
      backup: PFBKUP.resCont2,
      label: strPrf.res.cont2.label,
    },
    {
      id: 7,
      value: iResProv,
      setValue: setIResProv,
      errorMessage: eResProv,
      setErrorMessage: setEResProv,
      backup: PFBKUP.resProv,
      label: strPrf.res.prov.label,
    },
    {
      id: 8,
      value: iResMun,
      setValue: setIResMun,
      errorMessage: eResMun,
      setErrorMessage: setEResMun,
      backup: PFBKUP.resMun,
      label: strPrf.res.mun.label,
    },
    {
      id: 9,
      value: iResBrgy,
      setValue: setIResBrgy,
      errorMessage: eResBrgy,
      setErrorMessage: setEResBrgy,
      backup: PFBKUP.resBrgy,
      label: strPrf.res.brgy.label,
    },
    // Role Question //////////////////////////////////////////////////////////
    {
      id: 10,
      value: iResProf,
      setValue: setIResProf,
      errorMessage: eResProf,
      setErrorMessage: setEResProf,
      backup: PFBKUP.resProf,
      label: strPrf.res.profile.label,
    },
    {
      id: 11,
      value: iResScp,
      setValue: setIResScp,
      errorMessage: eResScp,
      setErrorMessage: setEResScp,
      backup: PFBKUP.resScope,
      label: strPrf.res.scope.label,
    },
    {
      id: 12,
      value: iResPos,
      setValue: setIResPos,
      errorMessage: eResPos,
      setErrorMessage: setEResPos,
      backup: PFBKUP.resPos,
      label: strPrf.res.pos.label,
    },
    // Owner Questions /////////////////////////////////////////////////////////
    {
      id: 13,
      value: iOwName,
      setValue: setIOwName,
      errorMessage: eOwName,
      setErrorMessage: setEOwName,
      backup: PFBKUP.owName,
      label: strPrf.owner.name.label,
    },
    {
      id: 14,
      value: iOwClass,
      setValue: setIOwClass,
      errorMessage: eOwClass,
      setErrorMessage: setEOwClass,
      backup: PFBKUP.owClass,
      label: strPrf.owner.class.label,
    },
    {
      id: 15,
      value: iOwCont1,
      setValue: setIOwCont1,
      errorMessage: eOwCont1,
      setErrorMessage: setEOwCont1,
      backup: PFBKUP.owCont1,
      label: strPrf.owner.cont1.label,
    },
    {
      id: 16,
      value: iOwCont2,
      setValue: setIOwCont2,
      errorMessage: eOwCont2,
      setErrorMessage: setEOwCont2,
      backup: PFBKUP.owCont2,
      label: strPrf.owner.cont2.label,
    },
    {
      id: 17,
      value: iOwProv,
      setValue: setIOwProv,
      errorMessage: eOwProv,
      setErrorMessage: setEOwProv,
      backup: PFBKUP.owProv,
      label: strPrf.owner.prov.label,
    },
    {
      id: 18,
      value: iOwMun,
      setValue: setIOwMun,
      errorMessage: eOwMun,
      setErrorMessage: setEOwMun,
      backup: PFBKUP.owMun,
      label: strPrf.owner.mun.label,
    },
    {
      id: 19,
      value: iOwBrgy,
      setValue: setIOwBrgy,
      errorMessage: eOwBrgy,
      setErrorMessage: setEOwBrgy,
      backup: PFBKUP.owBrgy,
      label: strPrf.owner.brgy.label,
    },
    // Farm Questions //////////////////////////////////////////////////////////
    {
      id: 20,
      value: iAccre,
      setValue: setIAccre,
      errorMessage: eAccre,
      setErrorMessage: setEAccre,
      backup: PFBKUP.accre,
      label: strPrf.farm.accre.label,
    },
    {
      id: 21,
      value: iArea,
      setValue: setIArea,
      errorMessage: eArea,
      setErrorMessage: setEArea,
      backup: PFBKUP.area,
      label: strPrf.farm.area.label,
    },
    {
      id: 22,
      value: iMach,
      setValue: setIMach,
      errorMessage: eMach,
      setErrorMessage: setEMach,
      backup: PFBKUP.mach,
      label: strPrf.farm.mach.label,
    },
    {
      id: 23,
      value: iImpl,
      setValue: setIImpl,
      errorMessage: eImpl,
      setErrorMessage: setEImpl,
      backup: PFBKUP.impl,
      label: strPrf.farm.impl.label,
    },
    {
      id: 24,
      value: iMngt,
      setValue: setIMngt,
      errorMessage: eMngt,
      setErrorMessage: setEMngt,
      backup: PFBKUP.mngt,
      label: strPrf.farm.mngt.label,
    },
    {
      id: 25,
      value: iVarPltd,
      setValue: setIVarPltd,
      errorMessage: eVarPltd,
      setErrorMessage: setEVarPltd,
      backup: PFBKUP.varPltd,
      label: strPrf.farm.pltd.label,
    },
    {
      id: 26,
      value: iVarPref,
      setValue: setIVarPref,
      errorMessage: eVarPref,
      setErrorMessage: setEVarPref,
      backup: PFBKUP.varPref,
      label: strPrf.farm.pref.label,
    },
    {
      id: 27,
      value: iStartPltng,
      setValue: setIStartPltng,
      errorMessage: eStartPltng,
      setErrorMessage: setEStartPltng,
      backup: PFBKUP.strPltng,
      label: strPrf.farm.planting + " " + strPrf.farm.start.label,
    },
    {
      id: 28,
      value: iEndPltng,
      setValue: setIEndPltng,
      errorMessage: eEndPltng,
      setErrorMessage: setEEndPltng,
      backup: PFBKUP.endPltng,
      label: strPrf.farm.planting + " " + strPrf.farm.end.label,
    },
    {
      id: 29,
      value: iStartHarv,
      setValue: setIStartHarv,
      errorMessage: eStartHarv,
      setErrorMessage: setEStartHarv,
      backup: PFBKUP.startHarv,
      label: strPrf.farm.harvesting + " " + strPrf.farm.start.label,
    },
    {
      id: 30,
      value: iEndHarv,
      setValue: setIEndHarv,
      errorMessage: eEndHarv,
      setErrorMessage: setEEndHarv,
      backup: PFBKUP.endHarv,
      label: strPrf.farm.harvesting + " " + strPrf.farm.end.label,
    },
    {
      id: 31,
      value: iTtlPltng,
      setValue: setITtlPltng,
      errorMessage: eTtlPltng,
      setErrorMessage: setETtlPltng,
      backup: PFBKUP.tPltng,
      label: strPrf.farm.planting + " " + strPrf.farm.number,
    },
    {
      id: 32,
      value: iTtlHarv,
      setValue: setITtlHarv,
      errorMessage: eTtlHarv,
      setErrorMessage: setETtlHarv,
      backup: PFBKUP.tHarv,
      label: strPrf.farm.harvesting + " " + strPrf.farm.number,
    },
    {
      id: 33,
      value: iAvgYld,
      setValue: setIAvgYld,
      errorMessage: eAvgYld,
      setErrorMessage: setEAvgYld,
      backup: PFBKUP.avgYld,
      label: strPrf.farm.aYld.label,
    },
    {
      id: 34,
      value: iMoRato,
      setValue: setIMoRato,
      errorMessage: eMoRato,
      setErrorMessage: setEMoRato,
      backup: PFBKUP.moRato,
      label: strPrf.farm.mRtn.label,
    },
    {
      id: 35,
      value: iAvgRato,
      setValue: setIAvgRato,
      errorMessage: eAvgRato,
      setErrorMessage: setEAvgRato,
      backup: PFBKUP.avgRato,
      label: strPrf.farm.aRtn.label,
    },
    {
      id: 36,
      value: iThresh,
      setValue: setIThresh,
      errorMessage: eThresh,
      setErrorMessage: setEThresh,
      backup: PFBKUP.thresh,
      label: strPrf.farm.thr.label,
    },
    {
      id: 37,
      value: iSgrMill,
      setValue: setISgrMill,
      errorMessage: eSgrMill,
      setErrorMessage: setESgrMill,
      backup: PFBKUP.sgrMill,
      label: strPrf.farm.sMl.label,
    },
    // Notes Questions /////////////////////////////////////////////////////////
    {
      id: 38,
      value: iNotes,
      setValue: setINotes,
      errorMessage: eNotes,
      setErrorMessage: setENotes,
      backup: PFBKUP.notes,
      label: strPrf.note.label,
    },
  ];

  // ///////////////////////////////////////////////////////////////////////////
  // FUNCTIONS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Backup operations /////////////////////////////////////////////////////////

  function deleteProfileBackups() {
    Object.values(PFBKUP).forEach((val, index) => localStorage.removeItem(val));
  }

  function recoverProfileBackups() {
    // Recover all field and pick type questions
    for (let i = 0; i < QUESTIONS.length; i++) {
      if (typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue(recover(QUESTIONS[i].backup));
      }
    }

    // Code for to restore the checkboxes
    for (let i = 0; i < farmStates.length; i++) {
      farmStates[i][SET](
        IRESSCP_BACKUP.includes(strPrf.res.scope.options[i].label)
      );
    }
    IRESSCP_BACKUP = "";
  }

  function recover(key: string) {
    var val = localStorage.getItem(key) || "";
    if (localStorage.getItem(key) == null) {
      return "";
    } else if (val.charAt(0) == "{") {
      return JSON.parse(localStorage.getItem(key) ?? "");
    } else {
      return val;
    }
  }

  function clearFields() {
    for (let i = 0; i < QUESTIONS.length; i++) {
      if (typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue("");
      }
    }
  }

  // Toggle between privacy consent and restore backup notice //////////////////

  var profBackupExists = false;

  Object.values(PFBKUP).forEach((val, index) => {
    if (localStorage.getItem(val) !== null) profBackupExists = true;
  });

  const [consentShow, setConsentShow] = useState(!profBackupExists);
  const [backupShow, setBackupShow] = useState(
    profBackupExists && localStorage.getItem(fxdGen.editing) == FORMS.profile
  );
  const [editShow, setEditShow] = useState(
    localStorage.getItem(fxdGen.editing) == FORMS.profile ? true : false
  );

  const [backupMessage, setBackupMessage] = useState(strPrf.recover.message);

  // Dynamic Notification Call /////////////////////////////////////////////////

  const [popTitle, setPopTitle] = useState("");
  const [popContent, setPopContent] = useState("");
  const [popShow, setPopShow] = useState(false);
  const [popCentered, setPopCentered] = useState(true);
  const [popActLeft, setPopActLeft] = useState<TModButton>();
  const [popActCenter, setPopActCenter] = useState<TModButton>({
    text: "",
  });
  const [popActRight, setPopActRight] = useState<TModButton>();

  function showPopup(props: IModPublic) {
    setPopTitle("");
    setPopContent("");
    setPopCentered(true);
    setPopActLeft({ text: "" });
    setPopActCenter({ text: "" });
    setPopActRight({ text: "" });

    setPopTitle(props.title);
    setPopContent(props.content);
    if (props.centered) setPopCentered(props.centered);
    if (props.actionLeft) setPopActLeft(props.actionLeft);
    if (props.actionCenter) setPopActCenter(props.actionCenter);
    if (props.actionRight) setPopActRight(props.actionRight);
    setPopShow(true);
  }

  const [ntfMessage, setNtfMessage] = useState("");
  const [ntfShow, setNtfShow] = useState(false);
  const [ntfDuration, setNtfDuration] = useState<number | undefined>(5000);
  const [ntfSeverity, setNtfSeverity] = useState<TGetSeverity>("info");

  function showNotification(props: INotifyPublic) {
    setNtfMessage("");
    setNtfSeverity("info");
    setNtfDuration(5000);

    setNtfMessage(props.message);
    setNtfSeverity(props.severity);
    if (props.duration) setNtfDuration(props.duration);
    setNtfShow(true);
  }

  // ///////////////////////////////////////////////////////////////////////////
  // LAYOUT ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  return (
    <VBar style="main-bg-form form-design">
      {/* Dynamic Notification Component */}
      <Mod
        title={popTitle}
        content={popContent}
        show={[popShow, setPopShow]}
        centered={popCentered}
        actionLeft={popActLeft}
        actionCenter={popActCenter}
        actionRight={popActRight}
      />
      <Notify
        message={ntfMessage}
        show={[ntfShow, setNtfShow]}
        severity={ntfSeverity}
        duration={ntfDuration}
      />
      {/* Privacy Consent Form */}
      <Mod
        show={[consentShow, setConsentShow]}
        title={strPrf.consent.title}
        content={ConsentStatement()}
        actionCenter={{
          text: strPrf.consent.agree,
          color: "primary",
        }}
      />
      {/* Recover Backup Notice */}
      <Mod
        show={[backupShow, setBackupShow]}
        title={strPrf.recover.title}
        content={backupMessage}
        actionCenter={{
          text: strPrf.recover.no,
          variant: "text",
          action: () => {
            deleteProfileBackups();
            setConsentShow(true);
          },
        }}
        actionRight={{
          text: strPrf.recover.yes,
          action: () => {
            setBackupMessage(strPrf.recover.recovering);
            recoverProfileBackups();
          },
        }}
      />
      {/* Edit Notice */}
      <Mod
        show={[editShow, setEditShow]}
        title={strPrf.edit.title}
        content={strPrf.edit.message}
        actionCenter={{
          text: strPrf.recover.no,
          variant: "text",
          action: () => {
            deleteProfileBackups();
            GOTO(Route.encode);
          },
        }}
        actionRight={{
          text: strPrf.recover.yes,
          action: () => {
            recoverProfileBackups();
            // if (localStorage.getItem(stgProfile.edit) == "true" ? true : false)
            //   localStorage.removeItem(stgProfile.edit);
            // window.location.reload();
          },
        }}
      />
      <VBar style="v-bar">
        {/* Title Bar */}
        <TitleBar />
        {/* Respondent Card */}
        <Card title={strPrf.res.title} titleStyle="title">
          <Field
            text={strPrf.res.name}
            value={[iResName, setIResName]}
            validation={eResName}
            backup={PFBKUP.resName}
          />
          <Field
            text={strPrf.res.age}
            value={[iResAge, setIResAge]}
            validation={eResAge}
            backup={PFBKUP.resAge}
          />
          <Pick
            text={strPrf.res.sex}
            options={strPrf.res.sex.options}
            value={[iResSex, setIResSex]}
            validation={eResSex}
            backup={PFBKUP.resSex}
          />
          <Pick
            text={strPrf.res.educ}
            options={strPrf.res.educ.options}
            value={[iResEduc, setIResEduc]}
            validation={eResEduc}
            backup={PFBKUP.resEduc}
          />
          <HBar>
            <Field
              position="left"
              text={strPrf.res.cont1}
              value={[iResCont1, setIResCont1]}
              validation={eResCont1}
              backup={PFBKUP.resCont1}
            />
            <Field
              position="right"
              text={strPrf.res.cont2}
              value={[iResCont2, setIResCont2]}
              validation={eResCont2}
              backup={PFBKUP.resCont2}
            />
          </HBar>
          <Province
            text={strPrf.res.prov}
            address={adRes}
            value={[iResProv, setIResProv]}
            validation={eResProv}
            backup={PFBKUP.resProv}
          />
          <Municipality
            text={strPrf.res.mun}
            address={adRes}
            value={[iResMun, setIResMun]}
            validation={eResMun}
            backup={PFBKUP.resMun}
            onFocus={() => {
              showNotification({
                message: strPrf.notif.mun,
                severity: "warning",
              });
            }}
          />
          <Barangay
            text={strPrf.res.brgy}
            address={adRes}
            value={[iResBrgy, setIResBrgy]}
            validation={eResBrgy}
            backup={PFBKUP.resBrgy}
          />
        </Card>
        {/* Respondent Card Additional */}
        <Card>
          <Pick
            text={strPrf.res.profile}
            options={strPrf.res.profile.options}
            value={[iResProf, setIResProf]}
            validation={eResProf}
            backup={PFBKUP.resProf}
          />
          <VBar in={vScopes} style="spacer">
            <Text text={strPrf.res.scope.label} />
            <Checks
              row={false}
              options={strPrf.res.scope.options}
              checks={farmStates}
            />
          </VBar>
          <VBar in={vRole} style="spacer">
            <Pick
              free
              text={strPrf.res.pos}
              options={strPrf.res.pos.options}
              value={[iResPos, setIResPos]}
              validation={eResPos}
              backup={PFBKUP.resPos}
            />
          </VBar>
        </Card>
        {/* Owner Card */}
        <Card in={vOwCard} title={strPrf.owner.title} titleStyle="title">
          <Field
            text={strPrf.owner.name}
            value={[iOwName, setIOwName]}
            validation={eOwName}
            backup={PFBKUP.owName}
          />
          <Pick
            text={strPrf.owner.class}
            options={strPrf.owner.class.options}
            value={[iOwClass, setIOwClass]}
            validation={eOwClass}
            backup={PFBKUP.owClass}
          />
          <HBar>
            <Field
              position="left"
              text={strPrf.owner.cont1}
              value={[iOwCont1, setIOwCont1]}
              validation={eOwCont1}
              backup={PFBKUP.owCont1}
            />
            <Field
              position="right"
              text={strPrf.owner.cont2}
              value={[iOwCont2, setIOwCont2]}
              validation={eOwCont2}
              backup={PFBKUP.owCont2}
            />
          </HBar>
          <Province
            text={strPrf.owner.prov}
            address={adOwn}
            value={[iOwProv, setIOwProv]}
            validation={eOwProv}
            backup={PFBKUP.owProv}
          />
          <Municipality
            text={strPrf.owner.mun}
            address={adOwn}
            value={[iOwMun, setIOwMun]}
            validation={eOwMun}
            backup={PFBKUP.owMun}
            onFocus={() => {
              showNotification({
                message: strPrf.notif.mun,
                severity: "warning",
              });
            }}
          />
          <Barangay
            text={strPrf.owner.brgy}
            address={adOwn}
            value={[iOwBrgy, setIOwBrgy]}
            validation={eOwBrgy}
            backup={PFBKUP.owBrgy}
          />
        </Card>
        {/* Farm */}
        <Card in={vFarmCard} title={strPrf.farm.title} titleStyle="title">
          <Pick
            text={strPrf.farm.accre}
            options={strPrf.farm.accre.options}
            value={[iAccre, setIAccre]}
            validation={eAccre}
            backup={PFBKUP.accre}
          />
          <Pick
            free
            text={strPrf.farm.area}
            options={strPrf.farm.tobe}
            value={[iArea, setIArea]}
            validation={eArea}
            backup={PFBKUP.area}
          />
          <Pick
            text={strPrf.farm.mach}
            options={strMac.macInfo.type.options}
            value={[iMach, setIMach]}
            validation={eMach}
            backup={PFBKUP.mach}
          />
          <Pick
            text={strPrf.farm.impl}
            options={strImp.basicInfo.type.options}
            value={[iImpl, setIImpl]}
            group={(g) => g.type}
            validation={eImpl}
            backup={PFBKUP.impl}
          />
          <Pick
            text={strPrf.farm.mngt}
            options={strPrf.farm.mngt.options}
            value={[iMngt, setIMngt]}
            validation={eMngt}
            backup={PFBKUP.mngt}
          />
          <Text text={strPrf.farm.varieties} />
          <Pick
            free
            // TODO Make it multiple
            text={strPrf.farm.pltd}
            options={optVarities}
            value={[iVarPltd, setIVarPltd]}
            validation={eVarPltd}
            backup={PFBKUP.varPltd}
          />
          <Pick
            free
            text={strPrf.farm.pref}
            options={optVarities}
            value={[iVarPref, setIVarPref]}
            validation={eVarPref}
            backup={PFBKUP.varPref}
          />
          <Text text={strPrf.farm.planting} />
          <HBar gap={10}>
            <Pick
              text={strPrf.farm.start}
              options={optMonths}
              value={[iStartPltng, setIStartPltng]}
              validation={eStartPltng}
              backup={PFBKUP.strPltng}
            />
            <Pick
              text={strPrf.farm.end}
              options={optMonths}
              value={[iEndPltng, setIEndPltng]}
              validation={eEndPltng}
              backup={PFBKUP.endPltng}
            />
          </HBar>
          <Text text={strPrf.farm.harvesting} />
          <HBar gap={10}>
            <Pick
              text={strPrf.farm.start}
              options={optMonths}
              value={[iStartHarv, setIStartHarv]}
              validation={eStartHarv}
              backup={PFBKUP.startHarv}
            />
            <Pick
              text={strPrf.farm.end}
              options={optMonths}
              value={[iEndHarv, setIEndHarv]}
              validation={eEndHarv}
              backup={PFBKUP.endHarv}
            />
          </HBar>
          <Text text={strPrf.farm.number} />
          <HBar gap={10}>
            <Field
              disabled
              open
              text={strPrf.farm.moPlanting}
              value={[iTtlPltng, setITtlPltng]}
              validation={eTtlPltng}
              backup={PFBKUP.tPltng}
            />
            <Field
              disabled
              open
              text={strPrf.farm.moHarvesting}
              value={[iTtlHarv, setITtlHarv]}
              validation={eTtlHarv}
              backup={PFBKUP.tHarv}
            />
          </HBar>
          <Text text={strPrf.farm.aYld.label} />
          <Pick
            free
            text={strPrf.farm.aYld.field}
            options={strPrf.farm.tobe}
            value={[iAvgYld, setIAvgYld]}
            validation={eAvgYld}
            backup={PFBKUP.avgYld}
          />
          <Text text={strPrf.farm.mRtn.label} />
          <Pick
            free
            text={strPrf.farm.mRtn.field}
            options={strPrf.farm.tobe}
            value={[iMoRato, setIMoRato]}
            validation={eMoRato}
            backup={PFBKUP.moRato}
          />
          <Text text={strPrf.farm.aRtn.label} />
          <Pick
            free
            text={strPrf.farm.aRtn.field}
            options={strPrf.farm.tobe}
            value={[iAvgRato, setIAvgRato]}
            validation={eAvgRato}
            backup={PFBKUP.avgRato}
          />
          <Text text={strPrf.farm.thr.label} />
          <Pick
            free
            text={strPrf.farm.thr.field}
            options={strPrf.farm.tobe}
            value={[iThresh, setIThresh]}
            validation={eThresh}
            backup={PFBKUP.thresh}
          />
          <Text text={strPrf.farm.sMl.label} />
          <PiChecks
            text={strPrf.farm.sMl.field}
            options={strPrf.farm.sMl.options}
            value={[iSgrMill, setISgrMill]}
            validation={eSgrMill}
            backup={PFBKUP.sgrMill}
          />
        </Card>
        {/* COMMENT CARD */}
        <Card>
          <Field
            multiline
            rows={7}
            text={strPrf.note}
            value={[iNotes, setINotes]}
            validation={eNotes}
            backup={PFBKUP.notes}
          />
        </Card>
        {/* Save Button */}
        <Press
          label={strPrf.save}
          color="primary"
          width={100}
          height={50}
          icon={<SaveIcon />}
          onClick={async () => {
            saveEncodedProfile();
          }}
        />
        {/*
        // TODO Improve this button group layouts
        */}
        <div className="btn-group" role="group">
          <button className="btn btn-primary" type="button">
            <Nav.Link key={Route.encode} as={NavLink} to={Route.encode} end>
              Go to Encode
            </Nav.Link>
          </button>
          <button className="btn btn-primary" type="button">
            <Nav.Link key={Route.machine} as={NavLink} to={Route.machine} end>
              Go to Machine
            </Nav.Link>
          </button>
        </div>
      </VBar>
    </VBar>
  );

  // ///////////////////////////////////////////////////////////////////////////
  // SAVE ENCODED PROFILE //////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  async function saveEncodedProfile() {
    // Variables ///////////////////////////////////////////////////////////////
    var proceedToSave = true;
    var now = Date.now().toString();
    var enumerator = localStorage.getItem(fxdUser.name);

    // Constants ///////////////////////////////////////////////////////////////
    const VGET = 0;
    const EGET = 1;
    const ESET = 2;

    // Add required fields /////////////////////////////////////////////////////
    var required = [
      // Respondent
      [iResName, eResName, setEResName],
      [iResAge, eResAge, setEResAge],
      [iResSex, eResSex, setEResSex],
      [iResEduc, eResEduc, setEResEduc],
      [iResCont1, eResCont1, setEResCont1],
      // [iResCont2, eResCont2, setEResCont2],
      [iResProv, eResProv, setEResProv],
      [iResMun, eResMun, setEResMun],
      [iResBrgy, eResBrgy, setEResBrgy],
      [iResProf, eResProf, setEResProf],
    ];

    // if (vScopes) {
    // }

    if (vRole) {
      required.push([iResPos, eResPos, setEResPos]);
    }

    if (vOwCard) {
      required.push([iOwName, eOwName, setEOwName]);
      required.push([iOwClass, eOwClass, setEOwClass]);
      required.push([iOwCont1, eOwCont1, setEOwCont1]);
      // required.push([iOwCont2, eOwCont2, setEOwCont2]);
      required.push([iOwProv, eOwProv, setEOwProv]);
      required.push([iOwMun, eOwMun, setEOwMun]);
      required.push([iOwBrgy, eOwBrgy, setEOwBrgy]);
    }

    if (vFarmCard) {
      required.push([iAccre, eAccre, setEAccre]);
      required.push([iArea, eArea, setEArea]);
      required.push([iMach, eMach, setEMach]);
      required.push([iImpl, eImpl, setEImpl]);
      required.push([iMngt, eMngt, setEMngt]);
      required.push([iVarPltd, eVarPltd, setEVarPltd]);
      required.push([iVarPref, eVarPref, setEVarPref]);
      required.push([iStartPltng, eStartPltng, setEStartPltng]);
      required.push([iEndPltng, eEndPltng, setEEndPltng]);
      required.push([iStartHarv, eStartHarv, setEStartHarv]);
      required.push([iEndHarv, eEndHarv, setEEndHarv]);
      // required.push([iTtlPltng, eTtlPltng, setETtlPltng]);
      // required.push([iTtlHarv, eTtlHarv, setETtlHarv]);
      // required.push([iAvgYld, eAvgYld, setEAvgYld]);
      // required.push([iMoRato, eMoRato, setEMoRato]);
      // required.push([iAvgRato, eAvgRato, setEAvgRato]);
      // required.push([iThresh, eThresh, setEThresh]);
      required.push([iSgrMill, eSgrMill, setESgrMill]);
    }

    // Setting required fields /////////////////////////////////////////////////
    for (let i = 0; i < required.length; i++) {
      var valAsText =
        required[i][VGET] != null
          ? (required[i][VGET] as any).label
          : required[i][VGET];

      if (!valAsText) {
        var require = {
          required: true,
          condition: (required[i][EGET] as TGetValidation).condition,
          message: (required[i][EGET] as TGetValidation).message,
          range: (required[i][EGET] as TGetValidation).range,
          length: (required[i][EGET] as TGetValidation).length,
        } as TGetValidation;
        (required[i][ESET] as TSetValidation)(require);
      }
    }

    // Check if all required fields are filled /////////////////////////////////
    for (let i = 0; i < required.length; i++) {
      var valAsText = (required[i][VGET] as any).label ?? required[i][VGET];

      if (valAsText == "") {
        proceedToSave = false;
        break;
      }
    }

    // Browser compatibility test //////////////////////////////////////////////
    // This might show in rare case only
    if (typeof JSON == "undefined") {
      showNotification({
        message: strPrf.notif.browserError,
        severity: "error",
      });
    } else if (!proceedToSave) {
      showNotification({
        message: strPrf.notif.missing,
        severity: "error",
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      showNotification({
        message: strPrf.notif.saveIni,
        severity: "success",
      });
    }

    // Prepare save the data ///////////////////////////////////////////////////
    if (proceedToSave) {
      let data = {
        // Respondent
        resName: iResName,
        resAge: iResAge,
        resSex: iResSex,
        resEduc: iResEduc,
        resCont1: iResCont1,
        resCont2: iResCont2,
        resProv: iResProv,
        resMun: iResMun,
        resBrgy: iResBrgy,
        // Profile of respondent
        resProf: iResProf,
        resScope: iResScp,
        resPos: iResPos,
        // Owner or Business
        owName: iOwName,
        owClass: iOwClass,
        owCont1: iOwCont1,
        owCont2: iOwCont2,
        owProv: iOwProv,
        owMun: iOwMun,
        owBrgy: iOwBrgy,
        // Farm
        accre: iAccre,
        area: iArea,
        mach: iMach,
        impl: iImpl,
        mngt: iMngt,
        varPltd: iVarPltd,
        varPref: iVarPref,
        startPltng: iStartPltng,
        endPltng: iEndPltng,
        startHarv: iStartHarv,
        endHarv: iEndHarv,
        ttlPltng: iTtlPltng,
        ttlHarv: iTtlHarv,
        avgYld: iAvgYld,
        moRato: iMoRato,
        avgRato: iAvgRato,
        thresh: iThresh,
        sgrMill: iSgrMill,
        notes: iNotes,
        // Metadata
        uname: enumerator,
        encoded: now,
        status: DATA_STATUS.local,
      } as ProfileData;

      localStorage.setItem(fxdGen.editing, FORMS.null);
      let fileName = iResName.replaceAll(".", "").replaceAll(" ", "") + ".zip";
      var fileToSave = new Blob([JSON.stringify(data, undefined, 2)], {
        type: "application/json",
      });
      saveAs(fileToSave, fileName);
      let profiles = localStorage.getItem(fxdProfile.profiles);
      if (profiles != null) {
        const newProfile = data;
        const profs = JSON.parse(profiles) as Array<any>;
        profs.push(newProfile);
        localStorage.setItem(fxdProfile.profiles, JSON.stringify(profs));
      } else {
        localStorage.setItem(fxdProfile.profiles, JSON.stringify([data]));
      }

      clearFields();
      deleteProfileBackups();
      showPopup({
        title: strPrf.saveNotif.title,
        content: strPrf.saveNotif.content,
        actionCenter: {
          text: strPrf.saveNotif.actionCenter,
          color: "success",
          action: () => {
            GOTO(Route.machine);
          },
        },
      });
    }

    return proceedToSave;
  }
}

// /////////////////////////////////////////////////////////////////////////////
// OPTIONS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const optVarities = [
  { label: "PHL 91-1091" },
  { label: "PHL 92-0051" },
  { label: "PHL 92-0577" },
  { label: "PHL 92-0751" },
  { label: "PHL 93-1601" },
  { label: "PHL 93-2349" },
  { label: "PHL 93-3155S" },
  { label: "PHL 93-3727" },
  { label: "PHL 93-3849" },
  { label: "PHL 94-0913" },
  { label: "PHL 97-0693" },
  { label: "PHL 97-1123" },
  { label: "PHL 97-1793" },
  { label: "PHL 97-2041" },
  { label: "PHL 7544" },
  { label: "VMC 84-524" },
  { label: "VMC 85-524" },
  { label: "PSR 01-105" },
  { label: "PHIL 93-236-3301" },
  { label: "PHIL 8477" },
  { label: "PHIL 90-0343" },
  { label: "PHIL 92-3-0023" },
  { label: "PHIL 88-626-1691" },
  { label: "PHIL 91-110-0807" },
  { label: "Q 102" },
  { label: "PHIL 84-438-5799" },
  { label: "PHIL 81-341-3415" },
  { label: "PHIL 89-660-1511" },
  { label: "Laredo 52-604" },
  { label: "PHIL 80-4-0213" },
  { label: "PHIL 89-127-0815" },
  { label: "PHIL 8709" },
  { label: "PHIL 90-85-0343" },
  { label: "PHIL 8013" },
  { label: "PHIL79-0019" },
  { label: "PHIL 64-2227" },
  { label: "CO 467" },
  { label: "CP 50-28" },
  { label: "PHIL 80-2287" },
  { label: "PHIL 86-1119" },
  { label: "PHIL 81-3415" },
  { label: "PHIL 79-1915" },
  { label: "PHIL 79-3032" },
  { label: "PHIL 2006-2289" },
];

// /////////////////////////////////////////////////////////////////////////////
// PROFILE FORMAT //////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface ProfileData {
  // Respondent
  resName: string;
  resAge: string;
  resSex: string;
  resEduc: string;
  resCont1: string;
  resCont2: string;
  resProv: string;
  resMun: string;
  resBrgy: string;
  // Profile of the respondent
  resProf: string;
  resScope: string;
  resPos: string;
  // Owner or business
  owName: string;
  owClass: string;
  owCont1: string;
  owCont2: string;
  owProv: string;
  owMun: string;
  owBrgy: string;
  // Farm
  accre: string;
  area: string;
  mach: string;
  impl: string;
  mngt: string;
  varPltd: string;
  varPref: string;
  startPltng: string;
  endPltng: string;
  startHarv: string;
  endHarv: string;
  ttlPltng: string;
  ttlHarv: string;
  avgYld: string;
  moRato: string;
  avgRato: string;
  thresh: string;
  sgrMill: string;
  // Notes
  notes: string;
  // Metadata
  uname: string;
  encoded: string;
  status: string;
}
