// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: June 19, 2023
// Last:

// TODO Remove unnecessary comments.
// TODO Remove unnecessary keys.

// /////////////////////////////////////////////////////////////////////////////
// FUNCTIONS ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function readValue(key: string, data: any): any {
  return data[key] ?? data;
}

export function readLabel(value: any): string {
  return readValue("label", value);
}

// Local Storage ///////////////////////////////////////////////////////////////
// FX for fixed

export function fxdRead(key: string): string | null {
  return localStorage.getItem(key);
}

export function fxdWrite(key: string, value: string): void {
  localStorage.setItem(key, value);
}

export function fxdDelete(key: string): void {
  localStorage.removeItem(key);
}

export function fxdEqual(key: string, value: string | null): boolean {
  return localStorage.getItem(key) === value;
}

export function fxdIsNull(values: string | string[]): boolean {
  if (typeof values === "string") return fxdRead(values) === null;
  for (const value of values) {
    if (fxdRead(value) === null) return true;
  }
  return false;
}

// Session Storage /////////////////////////////////////////////////////////////
// TM for temporary

export function tmpRead(key: string): string | null {
  return sessionStorage.getItem(key);
}

export function tmpWrite(key: string, value: string): void {
  sessionStorage.setItem(key, value);
}

export function tmpDelete(key: string): void {
  sessionStorage.removeItem(key);
}

export function tmpEqual(key: string, value: string | null): boolean {
  return sessionStorage.getItem(key) === value;
}

export function tmpIsNull(values: string | string[]): boolean {
  if (typeof values === "string") return tmpRead(values) === null;
  for (const value of values) {
    if (tmpRead(value) === null) return true;
  }
  return false;
}

// /////////////////////////////////////////////////////////////////////////////
// LOCAL STORAGE ///////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// User Account ////////////////////////////////////////////////////////////////

export enum fxdUser {
  name = "m3uname", // Usermame
  pass = "m3upass", // Password
  auth = "m3uauth", // Authentication
  mail = "m3umail", // Email
  lsNm = "m3ultnm", // Last Name
  frNm = "m3ufrnm", // First Name
  offc = "m3uoffc", // Office
  role = "m3urole", // Role
  dbcd = "m3udtbs", // Database Code
}

// General /////////////////////////////////////////////////////////////////////

export enum fxdGen {
  editing = "m3edit",
  backup = "m3backup",
}

// Profile /////////////////////////////////////////////////////////////////////

export enum fxdProfile {
  profiles = "m3profiles",
}

export enum fxdProfileBackup {
  resName = "m3BPResName", // * E: OK  | B: OK
  resAge = "m3BPResAge", // * E: OK  | B: OK
  resSex = "m3BPResSex", // * E: OK  | B: OK
  resEduc = "m3BPResEduc", // * E: OK  | B: OK
  resCont1 = "m3BPResCont1", // * E: OK  | B: OK
  resCont2 = "m3BPResCont2", // * E: OK  | B: OK
  resProv = "m3BPResProv", // * E: OK  | B: OK
  resMun = "m3BPResMun", // * E: OK  | B: OK
  resBrgy = "m3BPResBrgy", // * E: OK  | B: OK
  resProf = "m3BPResProf", // TODO Checks
  resScope = "m3BPResScope" /* AKA oOwnshp */, // TODO Checks
  resPos = "m3BPResPos", // * E: NOVAL  | B: OK
  owName = "m3BPOwName", // * E: OK  | B: OK
  owClass = "m3BPOwClass", // * E: OK  | B: OK
  owCont1 = "m3BPOwCont1", // * E: OK  | B: OK
  owCont2 = "m3BPOwCont2", // * E: OK  | B: OK
  owProv = "m3BPOwProv", // * E: OK  | B: OK
  owMun = "m3BPOwMun", // * E: OK  | B: OK
  owBrgy = "m3BPOwBrgy", // * E: OK  | B: OK
  accre = "m3BPAccre", // * E: OK  | B: OK
  area = "m3BPArea", // * E: OK  | B: OK
  mach = "m3BPMach", // * E: OK  | B: OK
  impl = "m3BPImpl", // * E: OK  | B: OK
  mngt = "m3BPMngt", // * E: OK  | B: OK
  varPltd = "m3BPVarPltd", // * E: OK  | B: OK
  varPref = "m3BPVarPref", // * E: OK  | B: OK
  strPltng = "m3BPStrPltng", // * E: OK  | B: OK
  endPltng = "m3BPEndPltng", // * E: OK  | B: OK
  startHarv = "m3BPStartHarv", // * E: OK  | B: OK
  endHarv = "m3BPEndHarv", // * E: OK  | B: OK
  tPltng = "m3BPTPltng", // * E: OK  | B: OK
  tHarv = "m3BPTHarv", // * E: OK  | B: OK
  avgYld = "m3BPAvgYld", // * E: OK  | B: OK
  moRato = "m3BPMoRato", // * E: OK  | B: OK
  avgRato = "m3BPAvgRato", // * E: OK  | B: OK
  thresh = "m3BPThresh", // * E: OK  | B: OK
  sgrMill = "m3BPSgrMill", // * E: OK  | B: OK
  notes = "m3BPNotes", // * E: NOVAL  | B: OK
  //
  uname = "m3BPUName", // TODO this
  date = "m3BPDate", // TODO this
  databs = "m3BPDB", // TODO this
}

// Machine /////////////////////////////////////////////////////////////////////

export enum fxdMachine {
  machines = "m3machines",
}

export enum fxdMachineBackup {
  resName = "m3BMResName", // * E: OK  | B: OK
  qrCode = "m3BMQRCode", // ! No error state | B: OK
  imageFront = "m3BMImageFront", // ! No error state | B: OK
  imageSide = "m3BMImageSide", // ! No error state | B: OK
  imagePlate = "m3BMImagePlate", // ! No error state | B: OK
  lat = "m3BMLat", // ! No error state | B: OK
  lng = "m3BMLng", // ! No error state | B: OK
  acc = "m3BMAcc", // ! No error state | B: OK
  loc = "m3BMLoc", // ! No error state | B: OK
  prov = "m3BMProv", // ! No error state | B: OK
  mun = "m3BMMun", // ! No error state | B: OK
  brgy = "m3BMBrgy", // ! No error state | B: OK
  macType = "m3BMMacType", // ! No error state | B: OK
  brand = "m3BMBrand", // ! No error state | B: OK
  model = "m3BMModel", // ! No error state | B: OK
  rPower = "m3BMRPower", // * E: OK  | B: OK
  sArea = "m3BMSArea", // * E: OK  | B: OK
  oDay = "m3BMODay", // * E: OK  | B: OK
  oSeason = "m3BMOSeason", // * E: OK  | B: OK
  lCap = "m3BMLCap", // * E: OK  | B: OK
  lCapUnit = "m3BMLCapUnit", // * E: OK  | B: OK
  lCapLoad = "m3BMLCapLoad", // * E: OK  | B: OK
  effArea = "m3BMEffArea", // * E: OK  | B: OK
  time = "m3BMTime", // * E: OK  | B: OK
  yield = "m3BMYield", // * E: OK  | B: OK
  pType = "m3BMPType", // ! No error state | B: OK
  pBrand = "m3BMPBrand", // ! No error state | B: OK
  pModel = "m3BMPModel", // ! No error state | B: OK
  pSize = "m3BMPSize", // * E: OK  | B: OK
  pSource = "m3BMPSource", // ! No error state | B: OK
  pSBrand = "m3BMPSBrand", // ! No error state | B: OK
  pSModel = "m3BMPSModel", // ! No error state | B: OK
  pSRPower = "m3BMPSRPower", // * E: OK  | B: OK
  pDischarge = "m3BMPDischarge", // * E: OK  | B: OK
  pDisUnit = "m3BMPDisUnit", // ! No error state | B: OK
  pSArea = "m3BMPSArea", // * E: OK  | B: OK
  pODay = "m3BMPODay", // * E: OK  | B: OK
  pOSeason = "m3BMPOSeason", // * E: OK  | B: OK
  oob = "m3BMOOB", // * E: OK  | B: OK
  class = "m3BMClass", // ! No error state | B: OK
  usage = "m3BMUsage", // ! No error state | B: OK
  mode = "m3BMMode", // ! No error state | B: OK
  agency = "m3BMAgency", // ! No error state | B: OK
  acqYear = "m3BMAcqYear", // * E: OK  | B: OK
  cond = "m3BMCond", // ! No error state | B: OK
  pCond = "m3BMPCond", // ! No error state | B: NOTSAVE 1614
  // Line 1614
  uuYear = "m3BMUUYear", // * E: OK  | B: OK
  unused = "m3BMUnused", // TODO Checks
  uuOthers = "m3BMUUOthers", // ! No error state | B: OK
  usYear = "m3BMUSYear", // ! No error state | B: OK
  unserv = "m3BMUnserv", // TODO Checks
  usOthers = "m3BMUSOthers", // ! No error state | B: OK
  contact1 = "m3BMContact1", // * E: OK  | B: OK
  contact2 = "m3BMContact2", // * E: OK  | B: OK
  spProv = "m3BMSPProv", // ! No error state | B: OK
  spMun = "m3BMSPMun", // ! No error state | B: OK
  spBrgy = "m3BMSPBrgy", // ! No error state | B: OK
  consent = "m3BMConsent", // TODO Checks
  rental = "m3BMRental", // TODO Checks
  fOper = "m3BMFOper", // ! No error state | B: OK
  fORate = "m3BMFORate", // * E: OK  | B: OK
  fORateUnit = "m3BMFORateUnit", // ! No error state | B: OK
  fOFuel = "m3BMFOFuel", // * E: OK  | B: OK
  sOper = "m3BMSOper", // ! No error state | B: OK
  sORate = "m3BMSORate", // * E: OK  | B: OK
  sORateUnit = "m3BMSORateUnit", // ! No error state | B: OK
  sOFuel = "m3BMSOFuel", // * E: OK  | B: OK
  tOper = "m3BMTOper", // ! No error state | B: OK
  tORate = "m3BMTORate", // * E: OK  | B: OK
  tORateUnit = "m3BMTORateUnit", // ! No error state | B: OK
  tOFuel = "m3BMTOFuel", // * E: OK  | B: OK
  ops = "m3BMOps", // * E: OK  | B: OK
  ope02 = "m3BMOpe02", // * E: OK  | B: OK
  ope2Abv = "m3BMOpe2Abv", // * E: OK  | B: OK
  opeWith = "m3BMOpeWith", // * E: OK  | B: OK
  opeWithout = "m3BMOpeWithout", // * E: OK  | B: OK
  used = "m3BMUsed", // * E: OK  | B: OK
  breakdowns = "m3BMBreakdowns", // * E: OK  | B: OK
  peakStart = "m3BMPeakStart", // ! No error state | B: OK
  peakEnd = "m3BMPeakEnd", // ! No error state | B: OK
  peakMonths = "m3BMPeakMonths", // ! Question removed
  aFilter = "m3BMAFilter", // ! No error state | B: OK
  eOil = "m3BMEOil", // ! No error state | B: OK
  oFilter = "m3BMOFilter", // ! No error state | B: OK
  hOil = "m3BMHOil", // ! No error state | B: OK
  hOFil = "m3BMHOFil", // ! No error state | B: OK
  tire = "m3BMTire", // ! No error state | B: OK
  sName = "m3BMSName", // ! No error state | B: OK
  sProv = "m3BMSPProv", // ! No error state | B: OK
  sMun = "m3BMSMun", // ! No error state | B: OK
  sBrgy = "m3BMSBrgy", // ! No error state | B: OK
  sTimeRes = "m3BMSTimeRes", // ! No error state | B: OK
  sPartsAvail = "m3BMSPartsAvail", // ! No error state | B: OK
  othCrp = "m3BMOthCrp", // TODO Checks
  oRice = "m3BMORice", // TODO Checks
  oRSArea = "m3BMORSArea", // ! No error state | B: OK
  oCorn = "m3BMOCorn", // TODO Checks
  oCSArea = "m3BMOCSArea", // ! No error state | B: OK
  oOth = "m3BMOOth", // ! No error state | B: OK
  oOArea = "m3BMOOArea", // ! No error state | B: OK
  notes = "m3BMNotes", // ! No error state | B: OK
  //
  uname = "m3BMUName", // TODO this
  date = "m3BMDate", // TODO this
  databs = "m3BMDB", // TODO this
}

// Implement ///////////////////////////////////////////////////////////////////

export enum fxdImplement {
  implements = "m3implements",
}

export enum fxdImplementBackup {
  resName = "m3BIResName", // ! No error state | B: OK
  machine = "m3BIMachine", // ! No error state | B: OK
  qrCode = "m3BIQRCode", // ! No error state | B: OK
  front = "m3BIFront", // ! No error state | B: OK
  side = "m3BISide", // ! No error state | B: OK
  plate = "m3BIPlate", // ! No error state | B: OK
  lat = "m3BILat", // ! No error state | B: OK
  lng = "m3BILng", // ! No error state | B: OK
  acc = "m3BIAcc", // ! No error state | B: OK
  loc = "m3BILoc", // ! No error state | B: OK
  prov = "m3BIProv", // ! No error state | B: OK
  mun = "m3BIMun", // ! No error state | B: OK
  brgy = "m3BIBrgy", // ! No error state | B: OK
  pri = "m3BIPri", // ! No error state | B: OK
  sec = "m3BISec", // ! No error state | B: OK
  ter = "m3BITer", // ! No error state | B: OK
  type = "m3BIType", // ! No error state | B: OK
  brand = "m3BIBrand", // ! No error state | B: OK
  model = "m3BIModel", // ! No error state | B: OK
  botA = "m3BIBotA", // * E: OK  | B: OK
  botB = "m3BIBotB", // * E: OK  | B: OK
  szDisk = "m3BISzDisk", // * E: OK  | B: OK
  botC = "m3BIBotC", // * E: OK  | B: OK
  dRowsA = "m3BIDRowsA", // * E: OK  | B: OK
  wCut = "m3BIWCut", // * E: OK  | B: OK
  lCap = "m3BILCap", // * E: OK  | B: OK
  lCapUnit = "m3BILCapUnit", // ! No error state | B: OK
  noRowsA = "m3BINoRowsA", // * E: OK  | B: OK
  dRowB = "m3BIDRowB", // * E: OK  | B: OK
  noz = "m3BINoz", // * E: OK  | B: OK
  dNoz = "m3BIDNoz", // * E: OK  | B: OK
  tkCapA = "m3BITkCapA", // * E: OK  | B: OK
  noOut = "m3BINoOut", // * E: OK  | B: OK
  dOut = "m3BIDOut", // * E: OK  | B: OK
  tkCapB = "m3BITkCapB", // * E: OK  | B: OK
  tkCapUnit = "m3BITkCapUnit", // ! No error state | B: OK
  tpPlntr = "m3BITpPlntr", // TODO Checks
  noRowsB = "m3BINoRowsB", // * E: OK  | B: OK
  grbType = "m3BIGrbType", // TODO Checks
  sameAsOwner = "m3BISameAsOwner", // ! Mukang naligaw to
  oob = "m3BIOob", // * E: OK  | B: OK
  oClass = "m3BIOClass", // ! No error state | B: OK
  oUsage = "m3BIOUsage", // ! No error state | B: OK
  oMode = "m3BIOMode", // ! No error state | B: OK
  oAgency = "m3BIOAgency", // ! No error state | B: OK
  oAYear = "m3BIOAYear", // ! No error state | B: OK
  oACond = "m3BIOACond", // ! No error state | B: OK
  presCon = "m3BIPresCon", // ! No error state | B: OK
  uUYear = "m3BIUUYear", // TODO Checks
  unused = "m3BIUnused", // TODO Checks
  uUOthers = "m3BIUUOthers", // ! No error state | B: OK
  uSYear = "m3BIUSYear", // ! No error state | B: OK
  unserv = "m3BIUnserv", // TODO Checks
  uSOthers = "m3BIUSOthers", // ! No error state | B: OK
  rental = "m3BIRental", // TODO Checks
  rFrtOpe = "m3BIRFrtOpe", // ! No error state | B: OK
  rFrtRate = "m3BIRFrtRate", // * E: OK  | B: OK
  rFrtUnit = "m3BIRFrtUnit", // ! No error state | B: OK
  rFrtFuel = "m3BIRFrtFuel", // * E: OK  | B: OK
  rScdOpe = "m3BIRScdOpe", // ! No error state | B: OK
  rScdRate = "m3BIRScdRate", // * E: OK  | B: OK
  rScdUnit = "m3BIRScdUnit", // ! No error state | B: OK
  rScdFuel = "m3BIRScdFuel", // * E: OK  | B: OK
  rTrdOpe = "m3BITrdOpe", // ! No error state | B: OK
  rTrdRate = "m3BITrdRate", // * E: OK  | B: OK
  rTrdUnit = "m3BITrdUnit", // ! No error state | B: OK
  rTrdFuel = "m3BITrdFuel", // * E: OK  | B: OK
  pEffArea = "m3BIPEffArea", // * E: OK  | B: OK
  pEffATime = "m3BIPEffATime", // * E: OK  | B: OK
  pFCap = "m3BIPFCap", // ! Question removed
  pPasses = "m3BIPPasses", // * E: OK  | B: OK
  pGrFerWght = "m3BIPGrFerWght", // * E: OK  | B: OK
  pGrFerUnit = "m3BIPGrFerUnit", // ! No error state | B: OK
  pFerAppEffA = "m3BIPFerAppEffA", // * E: OK  | B: OK
  pFerDelRate = "m3BIPFerDelRate", // * E: OK  | B: OK
  pSgrYield = "m3BIPSgrYield", // * E: OK  | B: OK
  pLCap = "m3BIPLCap", // * E: OK  | B: OK
  pLCapUnit = "m3BIPLCapUnit", // ! No error state | B: OK
  pBrkDwns = "m3BIPBrkDwns", // * E: OK  | B: OK
  pComBrk = "m3BIPComBrk", // ! No error state | B: OK
  pMaint = "m3BIPMaint", // ! No error state | B: OK
  pStart = "m3BIPStart", // ! No error state | B: OK
  pEnd = "m3BIPEnd", // ! No error state | B: OK
  pMonths = "m3BIPMonths", // ! No error state | B: OK
  supplier = "m3BISupplier", // ! No error state | B: OK
  sProv = "m3BISPProv", // ! No error state | B: OK
  sMun = "m3BISMun", // ! No error state | B: OK
  sBrgy = "m3BISBrgy", // ! No error state | B: OK
  sTime = "m3BISTime", // ! No error state | B: OK
  sAvail = "m3BISAvail", // ! No error state | B: OK
  notes = "m3BINotes", // ! No error state | B: OK
  //
  uname = "m3BIUName", // TODO this
  date = "m3BIDate", // TODO this
  databs = "m3IPDB", // TODO this
}

// /////////////////////////////////////////////////////////////////////////////
// SESSION STORAGE /////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// General /////////////////////////////////////////////////////////////////////

export enum tmpGen {
  notified = "notified",
}
