// /////////////////////////////////////////////////////////////////////////////
// Arvouz Types ////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

import { SweetAlertIcon } from "sweetalert2";

// Constants ///////////////////////////////////////////////////////////////////

/** Used for useState array. */
export const GET = 0;

/** Used for useState array. */
export const SET = 1;

// /////////////////////////////////////////////////////////////////////////////
// useState Types //////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// SetStateAction Shortener ////////////////////////////////////////////////////

type SSA<T> = React.Dispatch<React.SetStateAction<T>>;

// Any /////////////////////////////////////////////////////////////////////////

export type TGetAny = any;
export type TSetAny = SSA<TGetAny>;
export type AnyState = [TGetAny, TSetAny | null];
export let SetAny = (a: TGetAny) => [a, null] as AnyState;

// String //////////////////////////////////////////////////////////////////////

export type TGetString = string;
export type TSetString = SSA<TGetString | undefined>;
export type StringState = [TGetString, TSetString | null];
export let SetString = (s: TGetString) => [s, null] as StringState;

// Boolean /////////////////////////////////////////////////////////////////////

export type TGetBoolean = boolean;
export type TSetBoolean = SSA<TGetBoolean>;
export type BooleanState = [TGetBoolean, TSetBoolean | null];
export let SetBoolean = (b: TGetBoolean) => [b, null] as BooleanState;

// Number //////////////////////////////////////////////////////////////////////

export type TGetNumber = number;
export type TSetNumber = SSA<TGetNumber>;
export type NumberState = [TGetNumber, TSetNumber | null];
export let SetNumber = (n: TGetNumber) => [n, null] as NumberState;

// Bigint //////////////////////////////////////////////////////////////////////

export type TGetBigint = bigint;
export type TSetBigint = SSA<TGetBigint>;
export type BigintState = [TGetBigint, TSetBigint | null];
export let SetBigint = (bi: TGetBigint) => [bi, null] as BigintState;

// /////////////////////////////////////////////////////////////////////////////
// Other Types /////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// Options /////////////////////////////////////////////////////////////////////

export type TGetOptions = { label: StringState }[];
export type TSetOptions = SSA<TGetOptions>;
export type OptionsState = [TGetOptions, TSetOptions | null];
export let SetOptions = (o: TGetOptions) => [o, null] as OptionsState;

// Position ////////////////////////////////////////////////////////////////////

export type TGetPosition = "left" | "center" | "right";
export type TSetPosition = SSA<TGetPosition>;
export type PositionState = [TGetPosition, TSetPosition | null];
export let SetPosition = (p: TGetPosition) => [p, null] as PositionState;

// Bootstrap Color /////////////////////////////////////////////////////////////

export type TGetBTColor =
  | "primary"
  | "secondary"
  | "info"
  | "warning"
  | "danger"
  | "success"
  | "light"
  | "dark"
  | "muted"
  | undefined;
export type TSetBTColor = SSA<TGetBTColor>;
export type BTColorState = [TGetBTColor, TSetBTColor | null];
export let SetBTColor = (p: TGetBTColor) => [p, null] as BTColorState;

// Material UI Color ///////////////////////////////////////////////////////////

export type TGetMUIColor =
  | "primary"
  | "secondary"
  | "info"
  | "warning"
  | "error"
  | "success"
  | "inherit"
  | undefined;
export type TSetMUIColor = SSA<TGetMUIColor>;
export type MUIColorState = [TGetMUIColor, TSetMUIColor | null];
export let SetMUIColor = (p: TGetMUIColor) => [p, null] as MUIColorState;

// Pop /////////////////////////////////////////////////////////////////////////

export type TGetPop =
  | SweetAlertIcon
  | "primary"
  | "secondary"
  | "light"
  | "dark";
export type TSetPop = SSA<TGetPop>;
export type PopState = [TGetPop, TSetPop | null];
export let SetPop = (p: TGetPop) => [p, null] as PopState;

// Severity ////////////////////////////////////////////////////////////////////

export type TGetSeverity = "success" | "info" | "warning" | "error";
export type TSetSeverity = SSA<TGetSeverity>;
export type SeverityState = [TGetSeverity, TSetSeverity | null];
export let SetSeverity = (p: TGetSeverity) => [p, null] as SeverityState;

// Variant /////////////////////////////////////////////////////////////////////

export type TGetVariant = "filled" | "outlined" | "standard";
export type TSetVariant = SSA<TGetVariant>;
export type VariantState = [TGetVariant, TSetVariant | null];
export let SetVariant = (p: TGetVariant) => [p, null] as VariantState;

// Button Variant //////////////////////////////////////////////////////////////

export type TGetVariantButton = "text" | "outlined" | "contained";
export type TSetVariantButton = SSA<TGetVariant>;
export type VariantButtonState = [TGetVariant, TSetVariant | null];
export let SetVariantButton = (p: TGetVariant) => [p, null] as VariantState;

// Validations /////////////////////////////////////////////////////////////////

export type TGetValidationResults = { status: boolean; message: string } | null;

export type TGetValidationConditions =
  | "none"
  | "name"
  | "duplicate"
  | "range"
  | "contact"
  | "year";

export type TGetValidation = {
  required: boolean;
  condition?: TGetValidationConditions;
  message?: { label: string; duplicate?: string };
  range?: { min: number; max: number };
  length?: { min: number; max: number };
};

export type TSetValidation = SSA<TGetValidation>;

// Text ////////////////////////////////////////////////////////////////////////

export type TGetText = {
  label: StringState;
  placeholder: StringState;
  helperText?: StringState;
};
export type TSetText = SSA<TGetText>;
export type TextState = [TGetText, TSetText | null];
export let SetText = (t: TGetText) => [t, null] as TextState;

// Render Input ////////////////////////////////////////////////////////////////

export type TGetRenderInput = (
  props: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLLIElement> &
    React.LiHTMLAttributes<HTMLLIElement>,
  option: {
    label:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | null
      | undefined;
  },
  { selected }: any
) => JSX.Element;
