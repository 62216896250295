import { useEffect, useState } from "react";
import { provinces, municipalities, barangays } from "./Philippines";
import { IError, Pick } from "./afx/AFX";
import { TSetAny, SET, GET, AnyState, TGetValidation } from "./afx/AType";

// /////////////////////////////////////////////////////////////////////////////
// createAddress ///////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// export function createAddress(key: number) {
//   const [prov, setProv] = useState(
//     provinces.sort((a, b) => a.label.localeCompare(b.label))
//   );
//   const [muns, setMuns] = useState(
//     municipalities.sort((a, b) => a.label.localeCompare(b.label))
//   );
//   const [brgys, setBrgys] = useState(
//     barangays.sort((a, b) => a.label.localeCompare(b.label))
//   );

//   return {
//     province: {
//       options: [prov, setProv],
//       disabled: useState(true),
//     },
//     municipality: {
//       options: [muns, setMuns],
//       disabled: useState(true),
//     },
//     barangay: {
//       options: [brgys, setBrgys],
//       disabled: useState(true),
//       key: useState(key),
//     },
//   } as Address;
// }

// /////////////////////////////////////////////////////////////////////////////
// TYPES AND CONSTANTS /////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const provs = provinces;
const muns = municipalities;
const brgys = barangays;

type BooleanST = React.Dispatch<React.SetStateAction<boolean>>;
type provsT = React.Dispatch<React.SetStateAction<typeof provs>>;
type MunicipalityST = React.Dispatch<React.SetStateAction<typeof muns>>;
type BarangayST = React.Dispatch<React.SetStateAction<typeof brgys>>;

// /////////////////////////////////////////////////////////////////////////////
// ADDRESS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface Address {
  province: IProvs;
  municipality: IMuns;
  barangay: IBrgys;
}

interface IProvs {
  options: [typeof provs, provsT];
  disabled: [boolean, BooleanST];
}

interface IMuns {
  options: [typeof muns, MunicipalityST];
  disabled: [boolean, BooleanST];
}

interface IBrgys {
  options: [typeof brgys, BarangayST];
  disabled: [boolean, BooleanST];
  key: [any, TSetAny];
}

interface GenProps {
  text: { label: string; placeholder: string };
  address: Address;
  value: AnyState;
  backup: string;
  validation: TGetValidation;
  onFocus?: (e: any) => void;
}

// /////////////////////////////////////////////////////////////////////////////
// PROVINCE ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function Province(props: GenProps & IError) {
  const province = props.address.province;
  const municipality = props.address.municipality;
  const setMunOptions = municipality.options[SET];
  const setMunDisabled = municipality.disabled[SET];
  const options = province.options[GET].map((option) => ({
    label: option.label,
  }));

  return (
    <Pick
      value={props.value}
      text={props.text}
      options={options}
      onInputChange={(e, value) => {
        if (setMunOptions != null || setMunOptions != undefined) {
          setMunOptions!(
            muns.filter(
              (mun) =>
                mun.prov_code ==
                provs
                  .filter((prov) => prov.label == value)
                  .map((prov) => prov.prov_code)[0]
            )
          );
        }

        if (setMunDisabled != null || setMunDisabled != undefined) {
          setMunDisabled!(
            value == null || value == undefined || value == "" ? true : false
          );
        }
      }}
      color={props.color}
      error={props.error}
      backup={props.backup}
      validation={props.validation}
      onFocus={props.onFocus}
    />
  );
}

// /////////////////////////////////////////////////////////////////////////////
// MUNICIPALITY ////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function Municipality(props: GenProps & IError) {
  const [key, setKey] = useState(0);
  const municipality = props.address.municipality;
  const barangay = props.address.barangay;
  const disabled = municipality.disabled[GET];
  const setBrgyOptions = barangay.options[SET];
  const setBrgyDisabled = barangay.disabled[SET];
  const setBarangayKey = barangay.key[SET];
  const options = municipality.options[GET].map((option) => ({
    label: option.label,
  }));

  useEffect(() => {
    if (disabled != null && disabled == true) {
      setBrgyDisabled!(true);
      setBarangayKey!(Math.random());
      setKey(key + 1);
    }
  }, [disabled]);

  return (
    <Pick
      value={props.value}
      key={key}
      disabled={disabled}
      text={props.text}
      options={options}
      onInputChange={(e, value) => {
        if (setBrgyOptions != null || setBrgyOptions != undefined)
          setBrgyOptions!(
            brgys.filter(
              (brgy) =>
                brgy.mun_code ==
                muns
                  .filter((mun) => mun.label == value)
                  .map((mun) => mun.mun_code)[0]
            )
          );

        if (setBrgyDisabled != null || setBrgyDisabled != undefined)
          setBrgyDisabled!(
            value == null || value == undefined || value == "" ? true : false
          );

        if (value == "") setBarangayKey!(Math.random());
      }}
      color={props.color}
      error={props.error}
      backup={props.backup}
      validation={props.validation}
      onFocus={props.onFocus}
    />
  );
}

// /////////////////////////////////////////////////////////////////////////////
// BARANGAY ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function Barangay(props: GenProps & IError) {
  const barangay = props.address.barangay;
  const disabled = barangay.disabled[GET];
  const key = barangay.key[GET];
  const options = barangay.options[GET].map((option) => ({
    label: option.label,
  }));

  return (
    <Pick
      value={props.value}
      key={key}
      disabled={disabled}
      text={props.text}
      options={options}
      color={props.color}
      error={props.error}
      backup={props.backup}
      validation={props.validation}
      onFocus={props.onFocus}
    />
  );
}
