// // /////////////////////////////////////////////////////////////////////////////
// // Arvouz Layout Components ////////////////////////////////////////////////////
// // /////////////////////////////////////////////////////////////////////////////

// // Import React and Arvouz Functions ///////////////////////////////////////////

// import { ReactNode } from "react";
// import { assistWidth, assistCollapse } from "./AFunc";

// // /////////////////////////////////////////////////////////////////////////////
// // Collapsible HBar Component //////////////////////////////////////////////////
// // /////////////////////////////////////////////////////////////////////////////

// export function HBar(props: {
//   id?: string;
//   class?: string;
//   children?: ReactNode;
//   gap?: number;
//   show?: boolean;
//   force?: boolean;
// }) {
//   const BAR = (
//     <div
//       id={props.id}
//       className={props.class}
//       style={{ display: "flex", gap: props.gap }}
//     >
//       {props.children}
//     </div>
//   );

//   return props.show != null
//     ? assistCollapse(BAR, props.show, props.force)
//     : BAR;
// }

// // /////////////////////////////////////////////////////////////////////////////
// // Collapsible VBar Component //////////////////////////////////////////////////
// // /////////////////////////////////////////////////////////////////////////////

// export function VBar(props: {
//   id?: string;
//   class?: string;
//   children?: ReactNode;
//   width?: number;
//   show?: boolean;
//   force?: boolean;
// }) {
//   const BAR = (
//     <div
//       id={props.id}
//       className={props.class}
//       style={{
//         width: assistWidth({ width: props.width }),
//         minWidth: assistWidth({ width: props.width }),
//       }}
//     >
//       {props.children}
//     </div>
//   );

//   return props.show != null
//     ? assistCollapse(BAR, props.show, props.force)
//     : BAR;
// }

// // /////////////////////////////////////////////////////////////////////////////
// // Collapsible Card Component //////////////////////////////////////////////////
// // /////////////////////////////////////////////////////////////////////////////

// export function Card(props: {
//   id?: string;
//   class?: string;
//   children?: ReactNode;
//   title?: string;
//   titleID?: string;
//   titleStyle?: string;
//   show?: boolean;
//   force?: boolean;
// }) {
//   const TITLE = props.title ? (
//     <p id={props.titleID} className={props.titleStyle}>
//       {props.title}
//     </p>
//   ) : null;

//   const CARD = (
//     <div id={props.id} className={props.class + " card"}>
//       <div className="card-body">
//         {TITLE}
//         {props.children}
//       </div>
//     </div>
//   );

//   return props.show != null
//     ? assistCollapse(CARD, props.show, props.force)
//     : CARD;
// }

import { ReactNode } from "react";
import { Collapse } from "@mui/material";
import { assistWidth } from "./AFunc";
import { AppSettings } from "../../app/Settings";

// /////////////////////////////////////////////////////////////////////////////

function collapseAssist(child: ReactNode, tranIN: boolean, force?: boolean) {
  return (
    <Collapse in={checkShow(tranIN, force)} timeout="auto" unmountOnExit>
      {child}
    </Collapse>
  );
}

export function checkShow(showThis: boolean, force?: boolean) {
  // If Not showAll return ShowThis value else return true
  return force
    ? showThis
    : AppSettings.showAll
    ? true
    : showThis
    ? true
    : false;
}

// /////////////////////////////////////////////////////////////////////////////

export function HBar(props: {
  id?: string;
  style?: string;
  gap?: number;
  children?: ReactNode;
  /** Transition IN */
  in?: boolean;
  force?: boolean;
}) {
  const bar = (
    <div
      id={props.id}
      className={props.style}
      style={{ display: "flex", gap: props.gap }}
    >
      {props.children}
    </div>
  );

  return props.in != null ? collapseAssist(bar, props.in, props.force) : bar;
}

export function VBar(props: {
  w?: number;
  id?: string;
  style?: string;
  children?: ReactNode;
  /** Transition IN */
  in?: boolean;
  force?: boolean;
}) {
  const bar = (
    <div
      id={props.id}
      className={props.style}
      style={{
        width: assistWidth({ width: props.w }),
        minWidth: assistWidth({ width: props.w }),
      }}
    >
      {props.children}
    </div>
  );

  return props.in != null ? collapseAssist(bar, props.in, props.force) : bar;
}

export function Card(props: {
  id?: string;
  style?: string;
  children?: ReactNode;
  title?: string;
  titleID?: string;
  titleStyle?: string;
  /** Transition IN */
  in?: boolean;
  force?: boolean;
}) {
  const t = props.title ? (
    <p id={props.titleID} className={props.titleStyle}>
      {props.title}
    </p>
  ) : null;

  const d = (
    <div id={props.id} className={props.style + " card"}>
      <div className="card-body">
        {t}
        {props.children}
      </div>
    </div>
  );

  return props.in != null ? collapseAssist(d, props.in, props.force) : d;
}
