// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: April 01, 2024
// Last Clean: May 09, 2024

// /////////////////////////////////////////////////////////////////////////////

import axios from "axios";
import { sha512 } from "crypto-hash";
import { Toast } from "../../custom/afx/AFX";
import { UserCredentials } from "../../app/Server";
import { LogCount, LogUser } from "../../app/Server";
import { fxdDelete, fxdEqual } from "../../app/Storage";
import { fxdRead, fxdUser, fxdWrite } from "../../app/Storage";
import TX from "../../pages/app/App_en_US.json";
import { LOG } from "../../app/Settings";

// /////////////////////////////////////////////////////////////////////////////
// Authentication Provider /////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

interface AuthProvider {
  isAuthenticated: boolean;
  username: null | string;
  signin(username: string, password: string): Promise<void>;
  signout(): Promise<void>;
}

export const AUTH_PROVIDER: AuthProvider = {
  isAuthenticated: fxdEqual(fxdUser.auth, "true"),
  username: fxdRead(fxdUser.name) ?? "",

  async signin(username: string, password: string) {
    let auth = false;
    let name = "";
    let pass = await sha512(password);

    await axios
      .get(UserCredentials(username))
      .then(async (res) => {
        if (res.data.Password == pass) {
          auth = true;
          name = username;
          fxdWrite(fxdUser.auth, "true");
          fxdWrite(fxdUser.name, res.data.Username);
          fxdWrite(fxdUser.pass, res.data.Password);
          fxdWrite(fxdUser.mail, res.data.Email);
          fxdWrite(fxdUser.lsNm, res.data.LastName);
          fxdWrite(fxdUser.frNm, res.data.FirstName);
          fxdWrite(fxdUser.offc, res.data.Office);
          fxdWrite(fxdUser.role, res.data.Role);
          fxdWrite(fxdUser.dbcd, res.data.Database);

          const uname = res.data.Username;
          if (uname != null && uname.length > 0) {
            await axios.get(LogCount(uname)).then(async (count) => {
              var log = {
                Username: res.data.Username,
                Name: res.data.FirstName + " " + res.data.LastName,
                Field01: count.data[0].ct + 1,
                Field02: LOG.loginCode,
                Field03: LOG.login,
              };

              await axios.post(LogUser, log as any);
            });
          }
        } else {
          Toast({
            type: "error",
            title: TX.auth.err.title,
            text: TX.auth.err.textPass,
          });
        }
      })
      .catch((err) => {
        Toast({
          type: "error",
          title: TX.auth.err.title,
          text: err.message,
        });
      });

    AUTH_PROVIDER.isAuthenticated = auth;
    AUTH_PROVIDER.username = name;
  },

  async signout() {
    await new Promise((r) => setTimeout(r, 500));
    AUTH_PROVIDER.isAuthenticated = false;
    AUTH_PROVIDER.username = "";

    const uname = fxdRead(fxdUser.name);
    if (uname != null && uname.length > 0) {
      await axios.get(LogCount(uname)).then(async (count) => {
        var log = {
          Username: fxdRead(fxdUser.name),
          Name: fxdRead(fxdUser.frNm) + " " + fxdRead(fxdUser.lsNm),
          Field01: count.data[0].ct + 1,
          Field02: LOG.logoutCode,
          Field03: LOG.logout,
        };

        await axios.post(LogUser, log as any);
      });
    }

    fxdDelete(fxdUser.auth);
    fxdDelete(fxdUser.name);
    fxdDelete(fxdUser.pass);
    fxdDelete(fxdUser.mail);
    fxdDelete(fxdUser.lsNm);
    fxdDelete(fxdUser.frNm);
    fxdDelete(fxdUser.offc);
    fxdDelete(fxdUser.role);
    fxdDelete(fxdUser.dbcd);
  },
};
