// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: June 19, 2023
// Last Clean:

// /////////////////////////////////////////////////////////////////////////////

import "./App.css";

import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Container } from "react-bootstrap";

import TX from "./App_en_US.json";
import { ROUTES, Route } from "../../app/Routes";
import { fxdWrite, fxdGen } from "../../app/Storage";
import { AUTH_PROVIDER } from "../../hooks/auth/auth";
import { AppSettings, FORMS } from "../../app/Settings";
import addSplashScreen from "../splash/SplashScreen";

import { CSSTransition } from "react-transition-group";
import { SwitchTransition } from "react-transition-group";

import { redirect, useRouteLoaderData } from "react-router-dom";
import { useFetcher, useLocation, useOutlet } from "react-router-dom";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// /////////////////////////////////////////////////////////////////////////////
// Application /////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export default addSplashScreen(
  () => (
    <RouterProvider
      router={createBrowserRouter([
        {
          id: AppSettings.ROOT_ID,
          path: Route.home,
          element: <Layout />,
          children: ROUTES.map((childRoute) => ({
            index: childRoute.path === Route.home,
            path: childRoute.path === Route.home ? undefined : childRoute.path,
            loader: childRoute.loader ?? undefined,
            action: childRoute.action ?? undefined,
            element: childRoute.element,
          })),
          loader() {
            return { user: AUTH_PROVIDER.username };
          },
          errorElement: ErrorPage(),
        },
        {
          path: Route.logout,
          async action() {
            await AUTH_PROVIDER.signout();
            return redirect(Route.home);
          },
        },
      ])}
      fallbackElement={<p>{TX.app.init}</p>}
    />
  ),
  window.location.pathname === Route.home ? 3000 : 1000
);

// /////////////////////////////////////////////////////////////////////////////
// Application Layout //////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

function Layout() {
  const CURRENT_OUTLET = useOutlet();
  const PATH = useLocation().pathname;
  const ROUTE = ROUTES.find((route) => route.path === PATH);
  const { nodeRef } = ROUTE ?? {};

  const EREF = nodeRef as React.RefObject<HTMLElement>;
  const DIVREF = nodeRef as React.RefObject<HTMLDivElement>;

  const NotEditing = !(
    PATH == Route.profile ||
    PATH == Route.machine ||
    PATH == Route.implement
  );

  if (NotEditing) fxdWrite(fxdGen.editing, FORMS.null);

  return (
    <Container id="page-transition">
      <SwitchTransition>
        <CSSTransition
          unmountOnExit
          timeout={300}
          classNames="page"
          key={PATH}
          nodeRef={EREF}
        >
          {(state) => (
            <div className="page" ref={DIVREF}>
              {CURRENT_OUTLET}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </Container>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// Authentication Status ///////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function AuthStatusButton(props: { border?: "center" | "right" }) {
  let FETCHER = useFetcher();
  type User = { user: string | null };
  let { user } = useRouteLoaderData(AppSettings.ROOT_ID) as User;
  var str = TX.app.auth;

  if (!user) return <p>{str.notLoggedIn}</p>;

  let isLoggingOut = FETCHER.formData != null;

  var borderval = "btn-logout-center";
  if (props.border === "right") borderval = "btn-logout-right";

  return (
    <FETCHER.Form method="post" action={Route.logout}>
      <button
        type="submit"
        disabled={isLoggingOut}
        className={"btn btn-secondary " + borderval}
      >
        {isLoggingOut ? str.signingOut : str.signOut}
      </button>
    </FETCHER.Form>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// Page Not Found //////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface ErrorPageProps {
  title: string;
  heading: string;
  message: string;
  button: string;
}

export function ErrorPage(props?: ErrorPageProps) {
  const str = TX.app.pagenotfound;

  // https://magic.reactjs.net/htmltojsx.htm
  return (
    <div>
      <div>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{props?.title ?? str.title}</title>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "      body {" +
              "        font-family: Arial, sans-serif;" +
              "        background-color: #f9f9f9;" +
              "        text-align: center;" +
              "        padding: 50px;" +
              "        display: flex;" +
              "        flex-direction: column;" +
              "        align-items: center;" +
              "        align-content: center;" +
              "        gap: 20px;" +
              "      }" +
              "" +
              "      h1 {" +
              "        color: #333;" +
              "      }" +
              "" +
              "      p {" +
              "        color: #777;" +
              "        max-width: 100%;" +
              "      }" +
              "" +
              "      button {" +
              "        color: #090909;" +
              "        padding: 0.7em 1.7em;" +
              "        border-radius: 0.5em;" +
              "        background: #e8e8e8;" +
              "        cursor: pointer;" +
              "        border: 1px solid #e8e8e8;" +
              "        transition: all 0.3s;" +
              "        box-shadow: 6px 6px 12px #c5c5c5, " +
              "                    -6px -6px 12px #ffffff;" +
              "      }" +
              "" +
              "      button:active {" +
              "        color: #333;" +
              "        box-shadow: inset 4px 4px 12px #c5c5c5, " +
              "                    inset -4px -4px 12px #ffffff;" +
              "      }" +
              "    ",
          }}
        />
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h1>{props?.heading ?? str.heading}</h1>
          <p>{props?.message ?? str.message}</p>
          {/* <br /> */}
          <button>
            <a
              href={Route.home}
              style={{ textDecoration: "none", color: "#333" }}
            >
              {props?.button ?? str.button}
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}
