import Webcam from "react-webcam";
import { useRef, useState, useCallback } from "react";
import "../assets/scss/Forms.css";
import { VBar, HBar } from "./afx/ABox.tsx";
import { Press } from "./afx/AFX.tsx";

export default function WebCam({ setImage }) {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setImage(imageSrc);
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";

  const videoConstraints = {
    facingMode: FACING_MODE_USER,
  };

  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

  const handleClick = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  return (
    <VBar className="spacer">
      {imgSrc ? (
        <img src={imgSrc} alt="webcam" />
      ) : (
        <Webcam
          id="webcam"
          audio={false}
          ref={webcamRef}
          // width={640}
          // height={960}
          screenshotFormat="image/jpeg"
          screenshotQuality={0.8}
          videoConstraints={{
            ...videoConstraints,
            facingMode,
          }}
        />
      )}
      <HBar>
        {imgSrc ? (
          <Press
            width={50}
            position="left"
            onClick={retake}
            label="Retake"
          ></Press>
        ) : (
          <Press
            width={50}
            position="left"
            onClick={capture}
            label="Capture"
          ></Press>
        )}
        <Press
          width={50}
          position="right"
          color="secondary"
          onClick={handleClick}
          label="Switch"
        ></Press>
      </HBar>
    </VBar>
  );
}
