// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: June 19, 2023
// Last Clean:

// /////////////////////////////////////////////////////////////////////////////

import { Popup } from "../../custom/afx/AFX";
import TX from "./DevelopersCode.json";
import {
  enlistedImplementsFromThisProfile,
  enlistedMachinesFromThisProfile,
} from "./EncodePage";

// /////////////////////////////////////////////////////////////////////////////

export function ManageDevelopersCode(count: number) {
  count++;
  if (count > 7) {
    Popup({
      icon: "info",
      input: "text",
      title: TX.activate.title,
      text: TX.activate.text,
      confirmButtonText: TX.activate.confirm,
      allowOutsideClick: true,
    }).then((code) => {
      if (code.isConfirmed) {
        DevelopersCode(code.value);
      }
    });
  }
  return count;
}

export function DevelopersCode(code: any) {
  if (String(code).toUpperCase() === "RICHMOND") {
    Popup({
      type: "success",
      title: TX.code + code,
      text: TX.RICHMOND.text,
      confirmButtonText: TX.RICHMOND.confirm,
    });
    // } else if (String(code).toUpperCase().includes("COUNT_")) {
    //   var name = code.split("_")[1];
    //   var mach = enlistedMachinesFromThisProfile(name)?.length ?? 0;
    //   var imp = enlistedImplementsFromThisProfile(name)?.length ?? 0;
    //   Popup({
    //     type: "success",
    //     title: TX.code + code,
    //     text: "Machines: " + mach + "\nImplements: " + imp,
    //     confirmButtonText: "Okay",
    //   });
  } else {
    Popup({
      type: "error",
      title: TX.code + code,
      text: TX.error.text,
      confirmButtonText: TX.error.confirm,
    });
  }
}
