// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: June 19, 2023
// Last:

// /////////////////////////////////////////////////////////////////////////////

import { createRef } from "react";
import { AxiosError } from "axios";
import { redirect } from "react-router-dom";
import { LoaderFunctionArgs } from "react-router-dom";

import { fxdRead } from "./Storage";
import TX from "../pages/app/App_en_US.json";
import { AUTH_PROVIDER } from "../hooks/auth/auth";

import HomePage from "../pages/home/HomePage";
import LoginPage from "../pages/login/LoginPage";
import WebMapPage from "../pages/webmap/WebMapPage";
import EncodePage from "../pages/encode/EncodePage";
import UploadPage from "../pages/upload/UploadPage";
import VerifyPage from "../pages/verify/VerifyPage";

import ProfilePage from "../pages/profile/ProfilePage";
import { ProfileLoader } from "../pages/profile/ProfilePage";
import MachinePage from "../pages/machine/MachinePage";
import { machineLoader } from "../pages/machine/MachinePage";
import ImplementPage from "../pages/implement/ImplementPage";
import { implementLoader } from "../pages/implement/ImplementPage";

// /////////////////////////////////////////////////////////////////////////////
// Application Routes //////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export enum Route {
  // Homepage
  home = "/",

  // Mainpage: WebMap
  map = "/map",

  // Mainpage: Encode
  encode = "/encode",

  // Subpage: Forms
  profile = "/encode/profile",
  machine = "/encode/machine",
  implement = "/encode/implement",

  // Mainpage: Upload
  upload = "/upload",

  // Mainpage: Verify
  verify = "/verify",

  // Authentication Pages
  login = "/login",
  logout = "/logout",
  signup = "/signup",

  // Miscellaneous Page
  geoLoc = "/geoLoc",
}

export const ROUTES = [
  {
    path: Route.home,
    name: "Home",
    element: <HomePage />,
    nodeRef: createRef(),
    loader: protectedLoader,
  },
  {
    path: Route.map,
    name: "Web Map",
    element: <WebMapPage />,
    nodeRef: createRef(),
  },
  {
    path: Route.encode,
    name: "Encode",
    element: <EncodePage />,
    nodeRef: createRef(),
    loader: protectedLoader,
  },
  {
    path: Route.upload,
    name: "Upload",
    element: <UploadPage />,
    nodeRef: createRef(),
    loader: protectedLoader,
  },
  {
    path: Route.verify,
    name: "Verify",
    element: <VerifyPage />,
    nodeRef: createRef(),
    loader: protectedLoader,
  },
  {
    path: Route.profile,
    name: "Profile",
    element: <ProfilePage />,
    nodeRef: createRef(),
    loader: ProfileLoader,
  },
  {
    path: Route.machine,
    name: "Machine",
    element: <MachinePage />,
    nodeRef: createRef(),
    loader: machineLoader,
  },
  {
    path: Route.implement,
    name: "Implement",
    element: <ImplementPage />,
    nodeRef: createRef(),
    loader: implementLoader,
  },
  // ! Delete if left unused.
  // {
  //   path: Route.geoLoc,
  //   name: "Geo Location",
  //   element: <GeoLocation />,
  //   nodeRef: createRef(),
  //   loader: geoLocation,
  // },
  {
    path: Route.login,
    name: "Login",
    element: <LoginPage />,
    nodeRef: createRef(),
    action: loginAction,
    loader: loginLoader,
  },
];

// /////////////////////////////////////////////////////////////////////////////
// LOADERS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

async function loginLoader() {
  return AUTH_PROVIDER.isAuthenticated ? redirect(Route.home) : null;
}

function protectedLoader({ request }: LoaderFunctionArgs) {
  if (!AUTH_PROVIDER.isAuthenticated) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  return null;
}

// ! Delete if left unused.
// TODO Check if this works and if it is used.
// function geoLocation({ request }: LoaderFunctionArgs) {
//   loadMap();
//   return null;
// }

// ! Delete if left unused.
// TODO Check if this works and if it is used.
// function loadMap() {
//   // Map initialization
//   var map = L.map("map").setView([14.0860746, 100.608406], 6);

//   // osm layer
//   var osm = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
//     attribution:
//       '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//   });
//   osm.addTo(map);

//   if (!navigator.geolocation) {
//     console.log("Your browser doesn't support geolocation feature!");
//   } else {
//     setInterval(() => {
//       navigator.geolocation.getCurrentPosition(getPosition);
//     }, 5000);
//   }

//   var marker: any;
//   var circle: any;

//   function getPosition(position: any) {
//     // console.log(position)
//     var lat = position.coords.latitude;
//     var long = position.coords.longitude;
//     var accuracy = position.coords.accuracy;

//     if (marker) {
//       map.removeLayer(marker);
//     }

//     if (circle) {
//       map.removeLayer(circle);
//     }

//     marker = L.marker([lat, long]);
//     circle = L.circle([lat, long], { radius: accuracy });

//     var featureGroup = L.featureGroup([marker, circle]).addTo(map);

//     map.fitBounds(featureGroup.getBounds());

//     console.log(
//       "Your coordinate is: Lat: " +
//         lat +
//         " Long: " +
//         long +
//         " Accuracy: " +
//         accuracy
//     );
//   }
// }

// /////////////////////////////////////////////////////////////////////////////
// ACTIONS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// TODO Not sure if this work as expected
async function loginAction({ request }: LoaderFunctionArgs) {
  let formData = await request.formData();
  let username = formData.get("username") as string | null;
  let password = formData.get("password") as string;

  if (!username || !password) {
    return {
      error: TX.routes.err.cred,
    };
  }

  try {
    let uname = fxdRead("m3uname");
    let upass = fxdRead("m3upass");

    uname && uname.length > 0 && upass && upass.length > 0
      ? await AUTH_PROVIDER.signin(uname as string, upass as string)
      : await AUTH_PROVIDER.signin(username, password);
  } catch (error: AxiosError | any) {
    if (error instanceof AxiosError) {
      return { error: error.message };
    } else {
      return { error: TX.routes.err.failed };
    }
  }

  let redirectTo = formData.get("redirectTo") as string | null;
  return redirect(redirectTo || Route.home);
}

// /////////////////////////////////////////////////////////////////////////////
