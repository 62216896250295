// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: June 19, 2023
// Last Clean: May 01, 2024

// /////////////////////////////////////////////////////////////////////////////

import React from "react";
import ReactDOM from "react-dom/client";

import Application from "./pages/app/App";
import { AppSettings } from "./app/Settings";

import { ThemeProvider } from "@mui/material/styles";
import { Helmet, HelmetProvider } from "react-helmet-async";

// /////////////////////////////////////////////////////////////////////////////
// The Web Application /////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const ID = document.getElementById(AppSettings.ROOT_ID);
const VERSION = AppSettings.TITLE_VERSION;
const THEME = AppSettings.MUI_THEME;

ReactDOM.createRoot(ID!).render(
  <React.StrictMode>
    {/* Customizes the application title in tab bar. */}
    <HelmetProvider>
      <Helmet>
        <title>{VERSION}</title>
      </Helmet>
    </HelmetProvider>

    {/* Provides the theme for MUI components. */}
    <ThemeProvider theme={THEME}>
      <Application />
    </ThemeProvider>
  </React.StrictMode>
);

// /////////////////////////////////////////////////////////////////////////////

// TODO Auto resize windows zoom based on screen size.
// https://medium.com/@josephat94/building-a-simple-react-hook-to-detect-screen-
// size-404a867fa2d2
// https://blog.logrocket.com/adding-zoom-pan-pinch-react-web-apps/
// https://codesandbox.io/p/sandbox/react-zoom-pan-pinch-example-20hhkn?file=%2F
// src%2FApp.js&from-embed=
// const useScreenSize = () => {
//   const [screenSize, setScreenSize] = useState({
//     width: window.innerWidth,
//     height: window.innerHeight,
//   });

//   useEffect(() => {
//     const handleResize = () => {
//       setScreenSize({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       });
//     };

//     window.addEventListener("resize", handleResize);

//     // Clean up the event listener when the component unmounts
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return screenSize;
// };
