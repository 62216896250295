// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: June 19, 2023
// Last Clean:

// /////////////////////////////////////////////////////////////////////////////

import { AppSettings } from "./Settings";

// /////////////////////////////////////////////////////////////////////////////

export const APPVERSION = AppSettings.SERVER + "/read/settings/key0/version";
export const NOTIFICATION = AppSettings.SERVER + "/notif";

export const UserCredentials = (uname: string) =>
  AppSettings.SERVER + "/ucred/" + uname;

export const ReadProfile = (dbcd: string, name: string) =>
  AppSettings.SERVER + "/read/data_" + dbcd + '_profile/resName/"' + name + '"';
export const UploadProfile = (dbcd: string) =>
  AppSettings.SERVER + "/upprofile/" + dbcd;

export const ReadMachine = (dbcd: string, qr: string) =>
  AppSettings.SERVER + "/read/data_" + dbcd + '_machine/qrCode/"' + qr + '"';
export const UploadMachine = (dbcd: string) =>
  AppSettings.SERVER + "/upmachine/" + dbcd;
export function UpdateMachineImage(dbcd: string, column: string, data: string) {
  return (
    AppSettings.SERVER +
    "/editl/data_" +
    dbcd +
    "_machine/" +
    column +
    "/" +
    data
  );
}

// https://127.0.0.1:4433/editl/data_test_machine/id/1

export const ReadImplement = (dbcd: string, qr: string) =>
  AppSettings.SERVER + "/read/data_" + dbcd + '_implement/qrCode/"' + qr + '"';
export const UploadImplement = (dbcd: string) =>
  AppSettings.SERVER + "/upimplement/" + dbcd;
export function UpdateImplementImage(
  dbcd: string,
  column: string,
  data: string
) {
  return (
    AppSettings.SERVER +
    "/editl/data_" +
    dbcd +
    "_implement/" +
    column +
    "/" +
    data
  );
}

export const LogUser = AppSettings.SERVER + "/insert/" + "users_log";

export const LogCount = (username: string) =>
  AppSettings.SERVER + "/count/users_log/Username/" + username;
