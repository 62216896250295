import React from "react";
import { fxdProfile } from "../app/Storage";
import { TGetValidationResults } from "./afx/AType";

const TOBEACQUIRED = "Data to be acquired";

/**
 *
 */
type SetterFunction = React.Dispatch<
  React.SetStateAction<{ status: boolean; msg: string }>
>;

/**
 *
 */
export const resetval = (setter: SetterFunction) => {
  setter({ status: false, msg: "" });
};

/**
 *
 */
export function nameValidation(props: {
  /**
   * If the value is an object, it must have a label property.
   * If the value is a string, it must be the label.
   */
  value: any;
  message: { label: string; duplicate?: string };
  checkIfItAlreadyExists?: boolean;
}): TGetValidationResults {
  if (props.value !== null) {
    props.value = props.value.label ?? props.value;

    var testIsEmpty = props.value.length == 0;

    var testIsOneWord = props.value.indexOf(" ") == -1;

    var testIsDuplicate;

    if (props.checkIfItAlreadyExists) {
      testIsDuplicate =
        localStorage.getItem(fxdProfile.profiles) !== null
          ? JSON.parse(localStorage.getItem(fxdProfile.profiles)!).find(
              (item: any) => {
                return item.resName.toLowerCase() == props.value.toLowerCase();
              }
            )
          : false;
    }

    if (String(props.value) !== TOBEACQUIRED) {
      if (!testIsEmpty) {
        if (testIsOneWord) {
          return { status: true, message: props.message.label };
        } else if (testIsDuplicate && props.checkIfItAlreadyExists) {
          return {
            status: true,
            message: props.message.duplicate ?? "Already exists.",
          };
        }
      }
    }
    return { status: false, message: "" };
  }
  return null;
}

export const completeNameVal = (setter: SetterFunction, iRes: any) => {};

/**
 *
 */
export const NameValNoDatabase = (setter: SetterFunction, iRes: any) => {};

export function numberRangeValidation(props: {
  value: any;
  message: { label: string };
  range: { min: number; max: number };
}): TGetValidationResults {
  if (props.value !== null) {
    props.value = props.value.label ?? props.value;

    var testIsEmpty = props.value.length === 0;

    var numIRes = parseInt(props.value);
    var testIsNotNumber = /[^\d.]/.test(props.value);

    if (String(props.value) !== TOBEACQUIRED) {
      if (!testIsEmpty) {
        if (
          testIsNotNumber ||
          numIRes < props.range.min ||
          numIRes > props.range.max
        ) {
          return { status: true, message: props.message.label };
        }
      }
    }
    return { status: false, message: "" };
  }
  return null;
}

/**
 *
 */
export const rangeVal = (
  setter: SetterFunction,
  iRes: any,
  customErrorMessage?: string,
  lowValue?: number,
  highValue?: number
) => {};

export function contactNumberValidation(props: {
  value: any;
  message: { label: string };
  length: { min: number; max: number };
}): TGetValidationResults {
  if (props.value !== null) {
    props.value = props.value.label ?? props.value;

    var testIsEmpty = props.value.length === 0;

    var testIsNotNumber = /[^0-9]./.test(props.value);

    if (String(props.value) !== TOBEACQUIRED) {
      if (!testIsEmpty) {
        if (
          testIsNotNumber ||
          props.value.length < props.length.min ||
          props.value.length > props.length.max
        ) {
          return { status: true, message: props.message.label };
        } else if (props.value.length === props.length.max) {
          if (props.value.substring(0, 2) !== "09") {
            return { status: true, message: props.message.label };
          }
        }
      }
    }
    return { status: false, message: "" };
  }
  return null;
}

/**
 *
 */
export function yearValidation(props: {
  value: any;
  message: { label: string };
  range: { min: number; max: number };
}): TGetValidationResults {
  if (props.value !== null) {
    var valYear = props.value.$y;

    if (valYear > props.range.max || valYear < props.range.min) {
      return { status: true, message: props.message.label };
    } else {
      return { status: false, message: "" };
    }
  }
  return null;
}

/**
 *
 */
export const yearVal = (
  setter: SetterFunction,
  iRes: any,
  customErrorMessage?: string,
  lowValue?: number,
  highValue?: number
) => {
  if (iRes !== null) {
    //console.log(iRes);
    var iResYear = iRes.$y;
    var testIsEmpty = iResYear === "NaN";

    //console.log(iResYear);
    //var numIRes = parseInt(iResYear);

    var testIsNotNumberMsg = String(customErrorMessage);

    if (testIsEmpty || iRes.lenght === 0) {
      setter({ status: false, msg: "" });
      //setter({ status: true, msg: "here" });
    } else if (iResYear > highValue! || iResYear < lowValue!) {
      setter({ status: true, msg: testIsNotNumberMsg });
    } else {
      setter({ status: false, msg: "" });
    }
  }
};
