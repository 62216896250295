import {
  useLocation,
  useNavigation,
  useActionData,
  Form,
  NavLink,
} from "react-router-dom";
import { Route } from "../../app/Routes";
import "./LoginPage.css";

import { Nav } from "react-bootstrap";
import { Mod, Notify } from "../../custom/afx/AFX";
import { useState } from "react";

const t = {
  title: "Log In",
  subtitle: "Log in with your account.",
  logging: "Logging in...",
  login: "Login",
  account: "Doesn't have an account? ",
  signup: "Sign Up",
  check: "Check database connection: ",
  here: "Status",
};

export default function LoginPage() {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || Route.home;

  let navigation = useNavigation();
  let isLoggingIn = navigation.formData?.get("username") != null;

  let actionData = useActionData() as { error: string } | undefined;

  const [notify, setNotify] = useState(false);
  const [mod, setMod] = useState(true);

  return (
    <div id="login-page" className="main-bg-animated">
      <Notify
        message="Contact https://to.m3das.com/dev to create an account."
        show={[notify, setNotify]}
        severity="warning"
      />
      <div className="form-box">
        <Form method="post" className="form" replace>
          <input type="hidden" name="redirectTo" value={from} />
          <span className="title">{t.title}</span>
          <span className="subtitle">{t.subtitle}</span>
          <div className="form-container">
            <input
              type="text"
              className="input"
              placeholder="Username"
              name="username"
            />
            {/* <input type="email" className="input" placeholder="Email" /> */}
            <input
              type="password"
              className="input"
              placeholder="Password"
              name="password"
            />
          </div>
          <button type="submit" disabled={isLoggingIn}>
            {isLoggingIn ? t.logging : t.login}
          </button>
          {actionData && actionData.error ? (
            <p style={{ color: "red" }}>{actionData.error}</p>
          ) : null}
          {actionData?.error == "Network Error" ? (
            <Mod
              title="Connect to Database"
              content={
                <p>
                  Try connecting to the database. <br />
                  <a
                    href="https://to.m3das.com/connecttodatabase"
                    target="_blank”"
                  >
                    Open Connection Guide
                  </a>
                  .
                </p>
              }
              show={[mod, setMod]}
              actionCenter={{
                text: "Connect",
                color: "primary",
                action: () => {
                  window.open("https://202.92.144.27:4433/", "_blank");
                  window.location.reload();
                },
              }}
            />
          ) : null}
        </Form>
        <div className="form-section">
          {/* <p className="custom">
            {t.account}
            <Nav.Link
              key={Route.signup}
              as={NavLink}
              to={Route.login}
              onClick={() => setNotify(true)}
              end
            >
              {t.signup}
            </Nav.Link>
          </p> */}
          <p className="custom">
            {t.check}
            <a
              href="https://202.92.144.27:4433/"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {t.here}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
