export default function ConsentStatement() {
  return (
    <>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <b>Privacy Agreement</b>
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        Thank you for choosing to participate in our survey. Your participation
        is essential to help us gather valuable information for the purpose of
        research. Before you begin, we want to ensure that you understand our
        data collection and usage practices. Please read this consent form
        carefully and let us know if you have any questions or concerns.
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        By participating in this survey, you are providing your consent for us
        to collect and process your personal data. We take your privacy
        seriously and are committed to ensuring the security and confidentiality
        of your data.
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <strong>What personal information are we collecting?</strong>
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        We only collect the minimum personal information such as name, address,
        and contact information for the purpose of validation and tracking of
        agricultural machineries. This survey will only be asking for limited
        sensitive information such as age and educational attainment for the
        purpose of profiling the respondents of the survey and will not be
        disclosed without the respondent’s consent for the purpose of security
        and safety. Any data acquired ensures that these are securely connected
        to the M3DAS server through SSL encryption.
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <strong>
          How do we plan to use the responses and who are authorized to access
          them?
        </strong>
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        This survey is conducted solely for the purpose of research involving
        mechanization resource mapping, monitoring, and data analysis which will
        be utilized for mechanization planning, implementation, and policy data
        generation of government departments and LGUs. We will not sell or share
        your personal data with third parties for marketing purposes.The only
        authorized entities who have access with the data provided are the
        Center for Agri-Fisheries and Biosystems Mechniation (BIOMECH),
        University of the Philippines Los Banos (UPLB), and the Department of
        Science and Technology - Philippine Council for Agriculture, Aquatic and
        Natural Resources Research and Development (DOST-PCAARRD). Any other
        affiliates or concerned organizations will need to submit a written
        letter of consent from authorized entities before any access to
        anonymized data, except for the processed data enclosed in the public
        web maps, can be given.
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        We will retain your data in the system for a period necessary to fulfill
        the purposes outlined in this consent form or as required by applicable
        laws and regulations.
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <strong>How can respondents access their responses?</strong>
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        All data collected from the respondents will be analyzed through the
        M3DAS system. The mechanization data from the respondents including but
        not limited to machine horsepower (hp) rating, geolocation, ownership
        classification, usage, and functionality of machines will be processed
        to produce web maps that will be accessible to the general public
        through the M3DAS website (https://www.m3das.com).
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <strong>Privacy Consent&nbsp;</strong>
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        By proceeding with the survey, you confirm that you have read and
        understood the terms of this privacy consent form. You voluntarily
        consent to the collection, processing, and usage of your personal data
        as described herein.
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        You may withdraw your consent at any time by opting to discontinue
        answering the survey. However, withdrawal of consent will not affect the
        lawfulness of processing based on consent before its withdrawal.
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        If you do not agree to the terms of this consent form, please do not
        proceed with the survey.
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <strong>Contact Us</strong>
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        Your feedback is greatly appreciated and will contribute to the
        improvement of the M3DAS system. If you have any questions or concerns,
        please do not hesitate to contact us through the following channels:
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        Address
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        M3DAS project, Center for Agri-Fisheries and Biosystems Mechanization
        (BIOMECH), College of Engineering and Agro-Industrial Technology,
        University of the Philippines Los Banos (UPLB), College, Laguna,
        Philippines 4030.
      </p>

      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginLeft: "2.54cm",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        E-mail
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <a href="mailto:contact@m3das.com">
          <span style={{ color: "rgb(17, 85, 204)" }}>contact@m3das.com</span>
        </a>
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <a href="mailto:biomech.m3das@gmail.com">
          <span style={{ color: "rgb(17, 85, 204)" }}>
            biomech.m3das@gmail.com
          </span>
        </a>
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginLeft: "2.54cm",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        Facebook
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <a
          href="https://www.facebook.com/profile.php?id=61555521344650&mibextid=DlZzXU"
          target="_blank"
        >
          <span style={{ color: "rgb(17, 85, 204)" }}>M3DAS Project</span>
        </a>
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        Telephone
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        (049) 536 3606
      </p>
      <p
        style={{
          lineHeight: "115%",
          background: "transparent",
          marginBottom: "0cm",
          textAlign: "justify",
        }}
      >
        <br />
      </p>
    </>
  );
}
