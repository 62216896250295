// Stylesheet //////////////////////////////////////////////////////////////////

import "../../assets/scss/Forms.css";

// React ///////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";

// Material UI /////////////////////////////////////////////////////////////////

import { Radio, Checkbox, Button } from "@mui/material";
import { DateValidationError } from "@mui/x-date-pickers";
import { TextField, InputAdornment } from "@mui/material";
import { Autocomplete, Snackbar, Alert } from "@mui/material";
import { PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { FormGroup, FormControlLabel, RadioGroup } from "@mui/material";

// MUI Date Picker additional imports //////////////////////////////////////////

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// MUI Icons ///////////////////////////////////////////////////////////////////

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

// React Bootstrap /////////////////////////////////////////////////////////////

import { Modal } from "react-bootstrap";

// Arvouz Library //////////////////////////////////////////////////////////////

import { assistWidth, assistBorderRadius } from "./AFunc";
import { AnyState, BooleanState, GET, SET } from "./AType";
import { TGetMUIColor, TGetPop, TGetPosition } from "./AType";
import { TGetRenderInput, TGetSeverity, TGetValidation } from "./AType";
import { TGetVariant, TGetVariantButton } from "./AType";
import { TSetAny, TSetBoolean, TSetString } from "./AType";

// Validations /////////////////////////////////////////////////////////////////

import { numberRangeValidation, yearValidation } from "../Validations";
import { contactNumberValidation, nameValidation } from "../Validations";

// Others //////////////////////////////////////////////////////////////////////

import dayjs from "dayjs";
import { AppSettings } from "../../app/Settings";
import Swal, { SweetAlertPosition } from "sweetalert2";
import { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";

// /////////////////////////////////////////////////////////////////////////////

export function backup(val: any, localStorageforBackup: string) {
  if (localStorageforBackup) {
    localStorage.setItem(localStorageforBackup, val);
    if (val == "") localStorage.removeItem(localStorageforBackup);
  }
}

// /////////////////////////////////////////////////////////////////////////////
// CONSTANTS ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const optMonths = [
  { label: "January", intgr: 1 },
  { label: "February", intgr: 2 },
  { label: "March", intgr: 3 },
  { label: "April", intgr: 4 },
  { label: "May", intgr: 5 },
  { label: "June", intgr: 6 },
  { label: "July", intgr: 7 },
  { label: "August", intgr: 8 },
  { label: "September", intgr: 9 },
  { label: "October", intgr: 10 },
  { label: "November", intgr: 11 },
  { label: "December", intgr: 12 },
];

// /////////////////////////////////////////////////////////////////////////////
// Common Interface ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface IError {
  /**  */
  color?: TGetSeverity;
  /**  */
  error?: { status: boolean; msg: string };
}

// /////////////////////////////////////////////////////////////////////////////
// PRIVATE FUNCTIONS ///////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

function _validate(props: {
  validation: TGetValidation;
  val: any;
  setStatus: TSetBoolean;
  setText: TSetString;
}) {
  // Validation
  if (props.validation && props.validation?.message) {
    var result;

    if (props.validation.required && props.val) {
      result = { status: false, message: "" };
    }

    if (props.validation.condition == "name") {
      result = nameValidation({
        value: props.val,
        message: props.validation?.message,
      });
    } else if (props.validation.condition == "duplicate") {
      result = nameValidation({
        value: props.val,
        message: props.validation?.message,
        checkIfItAlreadyExists: true,
      });
    }

    if (props.validation.condition == "range") {
      if (props.validation?.range) {
        result = numberRangeValidation({
          value: props.val,
          message: props.validation?.message,
          range: props.validation?.range,
        });
      }
    }

    if (props.validation.condition == "contact") {
      if (props.validation?.length) {
        result = contactNumberValidation({
          value: props.val,
          message: props.validation?.message,
          length: props.validation?.length,
        });
      }
    }

    if (props.validation.condition == "year") {
      if (props.validation?.range) {
        result = yearValidation({
          value: props.val,
          message: props.validation?.message,
          range: props.validation?.range,
        });
      }
    }

    if (result) {
      props.setStatus(result?.status);
      props.setText(result?.message);
    }
  }
}

// /////////////////////////////////////////////////////////////////////////////
// PICK AND PICHECKS ///////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

interface IPickProps {
  /** The label, placeholder, and helper text of this component. */
  text: { label: string; placeholder: string; helper?: string };
  /** An array of options to show and pick from. */
  options: { label: any }[];
  /** If true, users are allowed to type their custom input. */
  free?: boolean;
  // TODO Support multiple pick feature in the future
  /** Toggle between single input and multiple inputs. */
  // multiple?: boolean;
  /** The value of this component. */
  value: AnyState;
  /**  */
  valueAsText?: string;
  /**  */
  onChange?: (event?: any, value?: any) => void;
  /**  */
  onInputChange?: (event?: any, value?: any) => void;
  /**  */
  group?: (option: any) => string;
  /**  */
  id?: string;
  /**  */
  className?: string;
  /**  */
  textfieldID?: string;
  /**  */
  textfieldClassName?: string;
  /**  */
  width?: number;
  /** Border Radius */
  borderRadius?: number;
  /** The position of this component in a row. */
  position?: TGetPosition;
  /**  */
  disabled?: boolean;
  /** The render design of the option list. */
  _renderOption?: TGetRenderInput;
  /** If true, the dropdown list will not close when an option is selected. */
  _disableCloseOnSelect?: boolean;
  /**  */
  validation: TGetValidation | null;
  /** The LocalStorage value to save the backup to on input change. */
  backup: string;
  /**  */
  onFocus?: (event?: any, value?: any) => void;
}

export function Pick(props: IPickProps & IError) {
  // ///////////////////////////////////////////////////////////////////////////

  function backup(val: any) {
    if (props.backup == null || props.backup == "") return;

    if (val == null || val == "") {
      localStorage.removeItem(props.backup);
    } else if (Array.isArray(val)) {
      var newVal: any[] = [];
      val.forEach((v: any) => {
        newVal.push(v.label ? v : { label: v });
      });
      localStorage.setItem(props.backup, JSON.stringify(newVal));
    } else {
      localStorage.setItem(
        props.backup,
        JSON.stringify(val.label ? val : { label: val })
      );
    }
  }

  // const recover =
  //   props.backup && localStorage.getItem(props.backup)
  //     ? JSON.parse(localStorage.getItem(props.backup)!)
  //     : null;

  // ///////////////////////////////////////////////////////////////////////////

  function onChangeMerged(event: any, val: any) {
    props.onChange?.(event, val);
    props.value[SET]?.(val);
    backup(val);

    if (props.validation) {
      _validate({
        validation: props.validation,
        val: val,
        setStatus: setErrorStatus,
        setText: setHelperText,
      });
    }
  }

  function onInputChangeMerged(event: any, val: any) {
    props.onInputChange?.(event, val);
    // if ((props.multiple && event.code == "Enter") || !props.multiple) {
    if (props.free) {
      props.value[SET]?.(val);
      backup(val);
    }
    // }

    if (props.validation) {
      _validate({
        validation: props.validation,
        val: val,
        setStatus: setErrorStatus,
        setText: setHelperText,
      });
    }
  }

  // Handle Error Status ///////////////////////////////////////////////////////

  const [helperText, setHelperText] = useState(props.text.helper);
  const [errorStatus, setErrorStatus] = useState(false);

  useEffect(() => {
    if (props.error?.status) {
      setErrorStatus(true);
      setHelperText(props.error?.msg);
    } else {
      setErrorStatus(false);
      setHelperText(props.text.helper);
    }
  }, [props.error?.status]);

  // ///////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (props.validation?.required && !props.value[GET]) {
      setErrorStatus(true);
      setHelperText("This field is required");
    }
  }, [props.validation]);

  // ///////////////////////////////////////////////////////////////////////////

  return (
    <Autocomplete
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          label={props.text.label}
          placeholder={props.text.placeholder}
          helperText={helperText}
          id={props.textfieldID}
          className={props.textfieldClassName}
          color={props.color}
          error={errorStatus}
          // value={restore}
        />
      )}
      options={props.options}
      freeSolo={props.free}
      // multiple={props.multiple}
      onChange={onChangeMerged}
      // TODO Restore for multiple
      // value={recover ?? props.value}
      value={props.value[GET]}
      onInputChange={onInputChangeMerged}
      groupBy={props.group}
      id={props.id}
      className={props.className}
      style={{
        width: assistWidth({ width: props.width }),
        minWidth: assistWidth({ width: props.width }),
      }}
      sx={{
        "& fieldset": {
          borderRadius: assistBorderRadius({
            borderRadius: props.borderRadius,
            horPosition: props.position,
          }),
        },
      }}
      disabled={props.disabled}
      renderOption={props._renderOption}
      disableCloseOnSelect={props._disableCloseOnSelect}
      onFocus={props.onFocus}
    />
  );
}

export function PiChecks(props: IPickProps & IError) {
  return (
    <Pick
      {...props}
      // multiple
      _disableCloseOnSelect
      _renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
    />
  );
}

// /////////////////////////////////////////////////////////////////////////////
// FIELD ///////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

interface IFieldProps {
  /** The label, placeholder, unit, and helper text of this component. */
  text: { label: string; placeholder: string; unit?: string; helper?: string };
  /** Position of the unit text, AKA Adornment.*/
  unitPosition?: "start" | "end";
  /** If true, the dropdown list is ALWAYS open. */
  open?: boolean;
  /** The value of this component. */
  value: AnyState;
  /**  */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**  */
  id?: string;
  /**  */
  className?: string;
  /**  */
  width?: number;
  /** Border Radius */
  borderRadius?: number;
  /** The position of this component in a row. */
  position?: TGetPosition;
  /**  */
  disabled?: boolean;
  /**  */
  number?: boolean;
  /**  */
  multiline?: boolean;
  /**  */
  rows?: number;
  /** */
  validation: TGetValidation;
  /** The LocalStorage value to save the backup to on input change. */
  backup: string;
}

export function Field(props: IFieldProps & IError) {
  // ///////////////////////////////////////////////////////////////////////////

  const [focus, setFocus] = useState(props.open || false);

  // const recover: any = props.backup ? localStorage.getItem(props.backup) : null;

  // Handle Error Status ///////////////////////////////////////////////////////

  const [helperText, setHelperText] = useState(props.text.helper);
  const [errorStatus, setErrorStatus] = useState(props.error?.status ?? false);

  useEffect(() => {
    if (props.error?.status) {
      setErrorStatus(true);
      setHelperText(props.error?.msg);
    } else {
      setErrorStatus(false);
      setHelperText(props.text.helper);
    }
  }, [props.error?.status]);

  // ///////////////////////////////////////////////////////////////////////////

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.onChange?.(e);
    const val = e.target.value;
    props.value[SET]?.(val);
    backup(val, props.backup);

    if (props.validation) {
      _validate({
        validation: props.validation,
        val: val,
        setStatus: setErrorStatus,
        setText: setHelperText,
      });
    }
  }

  // ///////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (props.validation?.required && !props.value[GET]) {
      setErrorStatus(true);
      setHelperText("This field is required");
    }
  }, [props.validation]);

  // ///////////////////////////////////////////////////////////////////////////

  return (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      label={props.text.label}
      placeholder={props.text.placeholder}
      InputProps={{
        endAdornment: props.unitPosition == null && focus && (
          <InputAdornment position="end">{props.text.unit}</InputAdornment>
        ),
        startAdornment: props.unitPosition == "start" && focus && (
          <InputAdornment position="start">{props.text.unit}</InputAdornment>
        ),
        style: {
          borderRadius: assistBorderRadius({
            borderRadius: props.borderRadius,
            horPosition: props.position,
          }),
        },
      }}
      helperText={helperText}
      InputLabelProps={{ shrink: props.open }}
      // value={recover ?? props.value}
      value={props.value[GET]}
      onChange={handleOnChange}
      id={props.id}
      className={props.className}
      style={{
        width: assistWidth({ width: props.width }),
        minWidth: assistWidth({ width: props.width }),
      }}
      disabled={props.disabled}
      type={props.number ? "number" : "text"}
      multiline={props.multiline}
      rows={props.rows}
      color={props.color}
      error={errorStatus}
      onFocus={() => setFocus(true)}
      onBlur={(e) => setFocus(e.target.value != "")}
    />
  );
}

// /////////////////////////////////////////////////////////////////////////////
// CHECKS //////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

type TChecks = "radio" | null;
type TCheckOptions = { label: string; key: any; value?: any }[];

interface IFormLabel {
  /**  */
  label: string;
  /**  */
  value?: string;
  /**  */
  type?: TChecks;
  /**  */
  checks?: BooleanState[];
  /**  */
  index?: number;
}

function FormLabel(props: IFormLabel) {
  function handleOnChange() {
    const currentVal = props.checks?.[props.index!][GET];
    props.checks?.[props.index!][SET]?.(!currentVal);
  }

  return (
    <FormControlLabel
      label={props.label}
      value={props.value == null ? props.label : props.value}
      control={
        props.type == "radio" ? (
          <Radio />
        ) : props.checks != null ? (
          <Checkbox
            checked={props.checks?.[props.index!][GET]}
            onChange={handleOnChange}
          />
        ) : (
          <Checkbox />
        )
      }
    />
  );
}

interface ChecksProps {
  /** If true, the options will be displayed in a row. */
  row?: boolean;
  /** Set the type to radio, else checkbox. */
  type?: TChecks;
  /** The available options to check from. */
  options: TCheckOptions;
  /** Width */
  width?: number;
  /** A useState set value to save into. */
  listen?: TSetAny;
  /** The value of this component. */
  value?: any; // TODO Get its value
  /**  */
  checks?: BooleanState[];
}

export function Checks(props: ChecksProps) {
  // ///////////////////////////////////////////////////////////////////////////
  const row = props.row ?? true;

  const style = {
    width: assistWidth({ width: props.width }),
    minWidth: assistWidth({ width: props.width }),
  };

  // ///////////////////////////////////////////////////////////////////////////

  function generateOptions(lprops: { options: TCheckOptions; type?: TChecks }) {
    return lprops.options.map((option, index) => (
      <FormLabel
        label={option.label}
        value={option.value ?? option.label}
        type={lprops.type}
        key={option.key}
        checks={props.checks}
        index={index}
      />
    ));
  }

  const options = generateOptions({ options: props.options, type: props.type });

  // ///////////////////////////////////////////////////////////////////////////

  const [value, setValue] = useState("");

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    props.listen?.(val);
    setValue(val);
  }

  // ///////////////////////////////////////////////////////////////////////////

  return props.type == "radio" ? (
    <RadioGroup row={row} style={style} value={value} onChange={handleOnChange}>
      {options}
    </RadioGroup>
  ) : (
    <FormGroup row={row} style={style}>
      {options}
    </FormGroup>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// TEXT ////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

interface TextProps {
  /**  */
  text: string;
  /**  */
  id?: string;
  /**  */
  className?: string;
  /**  */
  width?: number;
}

export function Text(props: TextProps) {
  return (
    <p
      id={props.id}
      className={props.className}
      style={{
        width: assistWidth({ width: props.width }),
        minWidth: assistWidth({ width: props.width }),
      }}
    >
      {props.text}
    </p>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// DATE PICKER /////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

interface IDay {
  /** The label, placeholder, and helper text of this component. */
  text: { label: string; placeholder?: string; helper?: string };
  /**  */
  views: ("day" | "month" | "year")[];
  /**  */
  id?: string;
  /**  */
  classname?: string;
  /** A useState set value to save into. */
  listen?: TSetAny;
  /** The value of this component. */
  value?: any;
  /**  */
  backup: string;
  /**  */
  validation?: TGetValidation;
}

export function Day(props: IDay & IError) {
  // Handle Error Status ///////////////////////////////////////////////////////

  const [helperText, setHelperText] = useState(props.text.helper);
  const [errorStatus, setErrorStatus] = useState(false);

  useEffect(() => {
    if (props.error?.status) {
      setErrorStatus(true);
      setHelperText(props.error?.msg);
    } else {
      setErrorStatus(false);
      setHelperText(props.text.helper);
    }
  }, [props.error?.status]);

  // ///////////////////////////////////////////////////////////////////////////

  const recover =
    props.backup != null && localStorage.getItem(props.backup) != null
      ? dayjs(localStorage.getItem(props.backup))
      : null;

  const [value, setValue] = useState(recover);

  function handleOnChange(
    newDate: unknown,
    context: PickerChangeHandlerContext<DateValidationError>
  ) {
    props.listen?.(newDate);
    // setValue(String(newDate));
    backup(newDate, props.backup);

    // if (props.validation) {
    //   _validate({
    //     validation: props.validation,
    //     val: value,
    //     setStatus: setErrorStatus,
    //     setText: setHelperText,
    //   });
    // }
  }

  // ///////////////////////////////////////////////////////////////////////////

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        // label={props.text}
        views={props.views}
        slotProps={{
          textField: {
            fullWidth: true,
            id: props.id,
            className: props.classname,
            size: "small",
            variant: "outlined",
            label: props.text.label,
            placeholder: props.text.placeholder,
            helperText: helperText,
            color: props.color,
            error: errorStatus,
          },
        }}
        onChange={handleOnChange}
        value={value}
      />
    </LocalizationProvider>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// BUTTON //////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

interface IButtonProps {
  /**  */
  label?: string;
  /**  */
  variant?: TGetVariantButton;
  /**  */
  icon?: JSX.Element;
  /**  */
  onClick?: () => void;
  /**  */
  color?: TGetMUIColor;
  /**  */
  width?: number;
  /**  */
  height?: number;
  /**  */
  borderRadius?: number;
  /**  */
  position?: TGetPosition;
  /**  */
  disabled?: boolean;
  /**  */
  fullWidth?: boolean;
}

export function Press(props: IButtonProps) {
  return (
    <Button
      fullWidth={props.fullWidth}
      variant={props.variant ?? "contained"}
      startIcon={props.icon}
      onClick={props.onClick}
      color={props.color}
      style={{
        width: assistWidth({ width: props.width }),
        minWidth: assistWidth({ width: props.width }),
        height: props.height,
      }}
      sx={{
        boxShadow: 0,
        borderRadius: assistBorderRadius({
          borderRadius: props.borderRadius,
          horPosition: props.position,
        }),
      }}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// Notify //////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface INotifyPublic {
  /**  */
  message: string;
  /**  */
  show?: BooleanState;
  /**  */
  duration?: number;
  /**  */
  severity: TGetSeverity;
  /**  */
  variant?: TGetVariant;
}

interface INotify {
  /**  */
  message: string;
  /**  */
  show: BooleanState;
  /**  */
  duration?: number;
  /**  */
  severity?: TGetSeverity;
  /**  */
  variant?: TGetVariant;
}

export function Notify(props: INotify) {
  const handleOnClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    props.show[SET]?.(false);
  };

  return (
    <Snackbar
      open={props.show[GET]}
      autoHideDuration={props.duration ?? 5000}
      onClose={handleOnClose}
    >
      <Alert
        onClose={handleOnClose}
        severity={props.severity ?? "success"}
        variant={props.variant ?? "filled"}
        sx={{ width: "100%" }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// Modal ///////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export type TModButton = IModButtonPublic | JSX.Element;

export interface IModButtonPublic {
  text: string;
  action?: () => void;
  variant?: TGetVariantButton;
  color?: TGetMUIColor;
}

export interface IModPublic {
  /**  */
  title: string;
  /**  */
  content: any;
  /**  */
  centered?: boolean;
  /**  */
  actionLeft?:
    | {
        text: string;
        action?: () => void;
        variant?: TGetVariantButton;
        color?: TGetMUIColor;
      }
    | JSX.Element;
  /**  */
  actionCenter:
    | {
        text: string;
        action?: () => void;
        variant?: TGetVariantButton;
        color?: TGetMUIColor;
      }
    | JSX.Element;
  /**  */
  actionRight?:
    | {
        text: string;
        action?: () => void;
        variant?: TGetVariantButton;
        color?: TGetMUIColor;
      }
    | JSX.Element;
}

interface IMod {
  /**  */
  title: string;
  /**  */
  content: any;
  /**  */
  show: BooleanState;
  /**  */
  centered?: boolean;
  /**  */
  actionLeft?:
    | {
        text: string;
        action?: () => void;
        variant?: TGetVariantButton;
        color?: TGetMUIColor;
      }
    | JSX.Element;
  /**  */
  actionCenter:
    | {
        text: string;
        action?: () => void;
        variant?: TGetVariantButton;
        color?: TGetMUIColor;
      }
    | JSX.Element;
  /**  */
  actionRight?:
    | {
        text: string;
        action?: () => void;
        variant?: TGetVariantButton;
        color?: TGetMUIColor;
      }
    | JSX.Element;
}

export function Mod(props: IMod) {
  const runLeftAction = () => {
    Promise.all([props.show[SET]?.(false)]).then((val) => {
      if (
        typeof props.actionLeft === "object" &&
        props.actionLeft !== null &&
        "action" in props.actionLeft
      ) {
        props.actionLeft.action?.();
      }
    });
  };

  const runCenterAction = () => {
    Promise.all([props.show[SET]?.(false)]).then((val) => {
      if (
        typeof props.actionCenter === "object" &&
        props.actionCenter !== null &&
        "action" in props.actionCenter
      ) {
        props.show[SET]?.(false);
        props.actionCenter.action?.();
      }
    });
  };

  const runRightAction = () => {
    Promise.all([props.show[SET]?.(false)]).then((val) => {
      if (
        typeof props.actionRight === "object" &&
        props.actionRight !== null &&
        "action" in props.actionRight
      ) {
        props.show[SET]?.(false);
        props.actionRight.action?.();
      }
    });
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={props.show[GET]}
      centered={props.centered ?? true}
      onHide={() => props.show[SET]?.(false)}
      // TODO Modal className for background color
    >
      <Modal.Header closeButton={false} className="border-0">
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof props.content === "string" ? (
          <p>{props.content}</p>
        ) : (
          props.content
        )}
      </Modal.Body>
      {props.actionLeft || props.actionCenter || props.actionRight ? (
        <Modal.Footer className="border-0">
          {React.isValidElement(props.actionLeft) ? props.actionLeft : null}
          {props.actionLeft &&
          "text" in props.actionLeft &&
          props.actionLeft?.text != "" &&
          typeof props.actionLeft === "object" ? (
            // &&
            // "color" in props.actionLeft &&
            // "variant" in props.actionLeft
            <Press
              label={props.actionLeft?.text ?? "Close"}
              color={props.actionLeft?.color ?? "inherit"}
              variant={props.actionLeft?.variant}
              fullWidth={false}
              onClick={runLeftAction}
            ></Press>
          ) : null}
          {React.isValidElement(props.actionCenter) ? props.actionCenter : null}
          {props.actionCenter &&
          "text" in props.actionCenter &&
          props.actionCenter?.text != "" &&
          typeof props.actionCenter === "object" ? (
            // &&
            // "color" in props.actionCenter &&
            // "variant" in props.actionCenter
            <Press
              label={props.actionCenter?.text}
              color={props.actionCenter?.color ?? "inherit"}
              variant={props.actionCenter?.variant}
              fullWidth={false}
              onClick={runCenterAction}
            ></Press>
          ) : null}
          {React.isValidElement(props.actionRight) ? props.actionRight : null}
          {props.actionRight &&
          "text" in props.actionRight &&
          props.actionRight?.text != "" &&
          typeof props.actionRight === "object" ? (
            // &&
            // "color" in props.actionRight &&
            // "variant" in props.actionRight
            <Press
              label={props.actionRight?.text}
              color={props.actionRight?.color ?? "inherit"}
              variant={props.actionRight?.variant}
              fullWidth={false}
              onClick={runRightAction}
            ></Press>
          ) : null}
        </Modal.Footer>
      ) : null}
    </Modal>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// RANGE ///////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// interface RangeProps {
//   def: number;
//   step: number;
//   min: number;
//   max: number;
//   width?: string;
//   marks?: boolean;
//   sVal?: SetAnyType;
// }

// export function Range(props: RangeProps) {
//   const [val, setVal] = useState(props.def);

//   return (
//     <Slider
//       valueLabelDisplay="auto"
//       defaultValue={val}
//       step={props.step}
//       min={props.min}
//       max={props.max}
//       marks={props.marks}
//       style={{ width: props.width ? props.width : "100%" }}
//       onChange={(e, v) => setVal(v as number)}
//     />
//   );
// }

// /////////////////////////////////////////////////////////////////////////////
// POPUP ///////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function Toast(props: SweetAlertOptions & { type?: TGetPop }) {
  var position: SweetAlertPosition =
    window.innerWidth < AppSettings.WIDTHBREAKPOINT ? "bottom" : "top-right";

  const Toast = Swal.mixin({
    toast: true,
    position: position,
    showConfirmButton: false,
    timer: props.timer ?? 7000,
    icon: props.icon ?? getIcon(props.type),
    iconColor: props.iconColor ?? "white",
    timerProgressBar: props.timerProgressBar ?? true,
    customClass: {
      popup: getBackgroundColor(props.type),
    },
    didOpen: (toast: any) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  return Toast.fire(props);
}

export function Popup(props: SweetAlertOptions & { type?: TGetPop }) {
  props.type = props.icon ? getStyleByIcon(props.icon) : props.type;
  var confirmBtn = getStyle(props.type ?? "primary");

  const toast = Swal.mixin({
    buttonsStyling: false,
    allowEscapeKey: props.allowEscapeKey ?? false,
    allowOutsideClick: props.allowOutsideClick ?? false,
    customClass: {
      confirmButton: props.confirmButtonColor ?? confirmBtn,
      cancelButton: props.cancelButtonColor ?? "btn btn-white",
      denyButton: props.denyButtonColor ?? "btn btn-white",
    },
  });

  return toast.fire(props);
}

function getIcon(type?: TGetPop): SweetAlertIcon | undefined {
  switch (type) {
    case "info":
      return "info";
    case "warning":
      return "warning";
    case "error":
      return "error";
    case "success":
      return "success";
    case "question":
      return "question";
    default:
      return undefined;
  }
}

function getBackgroundColor(type?: TGetPop) {
  switch (type) {
    case "primary":
      return "bg-primary text-light";
    case "secondary":
      return "bg-secondary text-light";
    case "info":
      return "bg-info text-light";
    case "warning":
      return "bg-warning text-light";
    case "error":
      return "bg-danger text-light";
    case "success":
      return "bg-success text-light";
    case "question":
      return "bg-info text-light";
    case "light":
      return "bg-light text-dark";
    case "dark":
      return "bg-dark text-light";
    default:
      return "bg-primary text-light";
  }
}

function getStyleByIcon(icon: string) {
  switch (icon) {
    case "info":
      return "info";
    case "warning":
      return "warning";
    case "error":
      return "error";
    case "success":
      return "success";
    case "question":
      return "info";
  }
}

function getStyle(type: TGetPop) {
  switch (type) {
    case "primary":
      return "btn btn-primary";
    case "secondary":
      return "btn btn-secondary";
    case "info":
      return "btn btn-info";
    case "warning":
      return "btn btn-warning";
    case "error":
      return "btn btn-danger";
    case "success":
      return "btn btn-success";
    case "question":
      return "btn btn-info";
    case "light":
      return "btn btn-light";
    case "dark":
      return "btn btn-dark";
    default:
      return "btn btn-primary";
  }
}
