// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: April 01, 2024
// Last Clean: April 01, 2024

// /////////////////////////////////////////////////////////////////////////////

import "./SplashScreen.css";
import { Component } from "react";

import upLogo from "../../assets/img/icon/partners/up.png";
import m3dasLogo from "../../assets/img/icon/m3das/logo512.png";
import biomechLogo from "../../assets/img/icon/partners/biomech.png";
import pcaarrdLogo from "../../assets/img/icon/partners/pcaarrd.png";
import sraLogo from "../../assets/img/icon/partners/sra.png";

// /////////////////////////////////////////////////////////////////////////////
// Logo Bar ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

/**
 * @param {*} barID The styling ID of the division bar
 * @param {*} logoClass The styling class for the logos
 * @returns A division bar with logos
 */
export function LogoBar(barID, logoClass) {
  return (
    <div id={barID}>
      <img className={logoClass} alt="up-logo" src={upLogo} />
      <img className={logoClass} alt="pcaarrd-logo" src={pcaarrdLogo} />
      <img className={logoClass} alt="sra-logo" src={sraLogo} />
      <img className={logoClass} alt="biomech-logo" src={biomechLogo} />
      <img className={logoClass} alt="m3das-logo" src={m3dasLogo} />
    </div>
  );
}

/**
 * @returns The splash screen component
 */
function SplashScreen() {
  return (
    <div id="splash-screen" className="main-bg-animated">
      {LogoBar("logo-bar", "splash-icon")}
    </div>
  );
}

/**
 * @param {*} WrappedComponent The component to show after the splash screen
 * @returns The component with the splash screen
 */
export default function addSplashScreen(WrappedComponent, ms) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    async componentDidMount() {
      setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, ms);
    }

    render() {
      if (this.state.loading) return SplashScreen();
      return <WrappedComponent {...this.props} />;
    }
  };
}
