// /////////////////////////////////////////////////////////////////////////////
// IMPORTS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

import "../../assets/scss/Forms.css";

// Styling /////////////////////////////////////////////////////////////////////

import { Nav } from "react-bootstrap";
import SaveIcon from "@mui/icons-material/Save";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MyLocationIcon from "@mui/icons-material/MyLocation";

// React ///////////////////////////////////////////////////////////////////////

import { useEffect, useState } from "react";
import { LoaderFunctionArgs } from "react-router-dom";
import { NavLink, redirect, useNavigate } from "react-router-dom";

// Arvouz Library //////////////////////////////////////////////////////////////

import { GET, SET } from "../../custom/afx/AType";
import { TGetSeverity } from "../../custom/afx/AType";
import { Card, HBar, VBar } from "../../custom/afx/ABox";
import {
  Pick,
  Press,
  Field,
  IModButtonPublic,
  TModButton,
  Popup,
} from "../../custom/afx/AFX";
import { Day, Mod, IModPublic } from "../../custom/afx/AFX";
import { Checks, Text, backup } from "../../custom/afx/AFX";
import { Notify, INotifyPublic } from "../../custom/afx/AFX";
import { TGetValidation, TSetValidation } from "../../custom/afx/AType";

// Address Library /////////////////////////////////////////////////////////////

import { Address, Province } from "../../custom/RAddress";
import { Municipality, Barangay } from "../../custom/RAddress";
import { provinces, municipalities, barangays } from "../../custom/Philippines";

// Geolocation Library /////////////////////////////////////////////////////////

import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Custom //////////////////////////////////////////////////////////////////////

import WebCam from "../../custom/Camera";
import { Route } from "../../app/Routes";
import { TitleBar } from "../encode/EncodePage";
import { fxdImplement } from "../../app/Storage";
import { fxdImplementBackup } from "../../app/Storage";
import { fxdProfile, fxdMachine, fxdUser } from "../../app/Storage";

// Others //////////////////////////////////////////////////////////////////////

import saveAs from "file-saver";
import { Alert } from "@mui/material";
import { AppSettings, DATA_STATUS, GeoLocationPane } from "../../app/Settings";
import { BrowserQRCodeReader } from "@zxing/library";
import { AUTH_PROVIDER } from "../../hooks/auth/auth";

// /////////////////////////////////////////////////////////////////////////////
// STRINGS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

import { strPrf } from "../profile/ProfilePage";
import { strMac } from "../machine/MachinePage";

const tMolBrdPlow = "Moldboard Plow";
const tDiscPlow = "Disc Plow";
const tDiscHarrow = "Disc Harrow";
const tFurrower = "Furrower";
const tSubsoiler = "Subsoiler";
const tChiselPlow = "Chisel Plow";
const tPowerHarrow = "Power Harrow";
const tSpkTthHarrow = "Spike-tooth Harrow";
const tRotTiller = "Rotary Tiller";
const tDitcher = "Ditcher";
const tDozer = "Dozer";
const tExcavator = "Excavator";
const tMechPlanter = "Mechanical Planter";
const tFldCltvtr = "Field Cultivator";
const tRtnManager = "Ratoon Manager/Stubble shaver";
const tTrashRake = "Trash Rake";
const tMulcher = "Mulcher";
const tItrRowCltvtr = "Inter-row Cultivator";
const tCutAwayCltvtr = "Cut-away Cultivator";
const tTrashInc = "Trash Incorporator";
const tGranFertAppl = "Granular Fertilizer Applicator";
const tFertApplCutAway = "Fertilizer Applicator w/ Cut-away";
const tWeeder = "Weeder";
const tBoomSprayer = "Boom Sprayer";
const tMechHarvester = "Mechanical Harvester";
const tCaneGrabLoader = "Cane Grab Loader";
const tTrailer = "Trailer";
const tNa = "Not Applicable";

export var strImp = {
  basicInfo: {
    title: "Basic Information",
    resName: {
      label: "Respondent's name",
      placeholder: "First Name MI. Last Name",
    },
    machine: {
      label: "Machine using the implement",
      placeholder: "Choose a machine",
    },
    type: {
      label: "Type of Implement",
      placeholder: "Choose an option",
      options: [
        // Land Preparation
        { label: tMolBrdPlow, type: "Land Preparation" },
        { label: tDiscPlow, type: "Land Preparation" },
        { label: tDiscHarrow, type: "Land Preparation" },
        { label: tFurrower, type: "Land Preparation" },
        { label: tSubsoiler, type: "Land Preparation" },
        { label: tChiselPlow, type: "Land Preparation" },
        { label: tPowerHarrow, type: "Land Preparation" },
        { label: tSpkTthHarrow, type: "Land Preparation" },
        { label: tRotTiller, type: "Land Preparation" },
        { label: tDitcher, type: "Land Preparation" },
        { label: tDozer, type: "Land Preparation" },
        { label: tExcavator, type: "Land Preparation" },
        { label: tNa, type: "Not Applicable" },
        // Planting
        { label: tMechPlanter, type: "Planting" },
        // Crop/Ratoon Management
        { label: tFldCltvtr, type: "Ratoon Management" },
        { label: tRtnManager, type: "Ratoon Management" },
        { label: tTrashRake, type: "Ratoon Management" },
        { label: tMulcher, type: "Ratoon Management" },
        { label: tItrRowCltvtr, type: "Ratoon Management" },
        { label: tCutAwayCltvtr, type: "Ratoon Management" },
        { label: tTrashInc, type: "Ratoon Management" },
        { label: tGranFertAppl, type: "Ratoon Management" },
        { label: tFertApplCutAway, type: "Ratoon Management" },
        { label: tWeeder, type: "Ratoon Management" },
        { label: tBoomSprayer, type: "Ratoon Management" },
        // Harvesting
        { label: tMechHarvester, type: "Harvesting" },
        // Hauling
        { label: tCaneGrabLoader, type: "Hauling" },
        { label: tTrailer, type: "Hauling" },
      ],
      // o: {},
    },
    qrCode: {
      label: "Scan Implement QR Code",
      placeholder: "Click to start scanning",
    },
    qrcodevid: {
      label: "QRCode Camera",
      placeholder: "Select Camera",
    },
    front: {
      label: "Capture Front View",
      placeholder: "Click to start capture",
    },
    side: {
      label: "Capture Side View",
      placeholder: "Click to start capture",
    },
    plate: {
      label: "Capture Specification Plate",
      placeholder: "Click to start capture",
    },
    loc: {
      label: "Location",
      placeholder: "Select the location",
    },
    lat: {
      label: "Latitude",
      placeholder: "0.000000",
    },
    lng: {
      label: "Longitude",
      placeholder: "0.000000",
    },
    acc: {
      label: "Accuracy",
      placeholder: "0.000000",
    },
    prov: {
      label: "Province",
      placeholder: "Select Province",
    },
    mun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    brgy: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
    coordinates: {
      label: "Coordinates",
      placeholder: "0.000000, 0.000000",
    },
  },
  operations: {
    title: "OPERATIONS",
    pri: {
      label: "Primary Operation",
      placeholder: "Select Operation",
    },
    sec: {
      label: "Secondary Operation",
      placeholder: "Select Operation",
    },
    ter: {
      label: "Tertiary Operation",
      placeholder: "Select Operation",
    },
  },
  specifications: {
    title: "SPECIFICATIONS",
    brand: {
      label: "Brand",
      placeholder: "Select Brand",
    },
    model: {
      label: "Model",
      placeholder: "Select Model",
    },
    bottoms: {
      label: "Number of Bottoms",
      placeholder: "0",
      unit: "bottoms",
    },
    disk: {
      label: "Size of disk",
      placeholder: "0.00",
      unit: "in",
    },
    distance: {
      label: "Distance between rows",
      placeholder: "0.00",
      unit: "cm",
    },
    type1: {
      text: {
        label: "Type of planter",
        placeholder: "Choose an option",
      },
      options: [{ label: "Billet" }, { label: "Whole stalk" }],
      // o: {
      //   billet: "Billet",
      //   stalk: "Whole stalk",
      // },
    },
    rows: {
      label: "Number of rows",
      placeholder: "0",
    },
    noz: {
      label: "Number of nozzles",
      placeholder: "0",
    },
    dNoz: {
      label: "Distance between nozzles",
      placeholder: "0",
      unit: "cm",
    },
    tkCap: {
      label: "Tank Capacity",
      placeholder: "0",
      unit: "Liters",
    },
    out: {
      label: "Number of outlets",
      placeholder: "0",
    },
    dOut: {
      label: "Distance between outlets",
      placeholder: "0",
      unit: "cm",
    },
    tkCap2: {
      label: "Tank Capacity",
      placeholder: "0.00",
      unitval: {
        label: "Unit",
        placeholder: "Choose",
        options: [{ label: "Liters / Day" }, { label: "Gallons / Day" }],
      },
    },
    depth: {
      label: "Depth of cut",
      placeholder: "0.00",
      unit: "cm",
    },
    type2: {
      text: {
        label: "Type",
        placeholder: "Choose an option",
      },
      options: [{ label: "Digger" }, { label: "Grapple" }],
      // o: {
      //   digger: "Digger",
      //   grapple: "Log Grapple",
      // },
    },
    loading: {
      label: "Loading Capacity",
      placeholder: "0.00",
      unitval: {
        label: "Unit",
        placeholder: "Choose",
        options: [{ label: "Kg / Day" }, { label: "Tons / Day" }],
      },
    },
    teeth: {
      label: "Number of teeth",
      placeholder: "0",
      unit: "teeth",
    },
    width: {
      label: "Width of cut",
      placeholder: "0.00",
      unit: "cm",
    },
  },
  capacity: {
    title: "FIELD CAPACITY",
    eArea: {
      label: "Effective area accomplished",
      placeholder: "0.00",
      unit: "ha",
    },
    time: {
      text: "Time used in working the effective area",
      label: "",
      placeholder: "0.0",
      unit: "hrs",
    },
    fCap: {
      label: "Field Capacity",
      placeholder: "0.0",
      unit: "ha/hr",
    },
    nPasses: {
      label: "Number of passes",
      placeholder: "0",
    },
    yield: {
      label: "Sugarcane Yield",
      placeholder: "0.0",
      unit: "tons canes/ha",
    },
  },
  delivery: {
    title: "DELIVERY RATE",
    fert: {
      label: "Granular fertilizer weight",
      placeholder: "0.00",
      unitval: {
        label: "Unit",
        placeholder: "Choose",
      },
    },
    eArea: {
      label: "Effective area accomplished",
      placeholder: "0.00",
      unit: "ha",
    },
    rate: {
      label: "Delivery Rate",
      placeholder: "0.00",
      unit: "kg/ha ??",
    },
  },
  performance: {
    title: "IMPLEMENT PERFORMANCE",
    breakdowns: {
      label: "Number of Breakdowns",
      placeholder: "0",
      unit: "times",
    },
    pComBrk: {
      label: "Common Breakdowns",
      placeholder: "Select Breakdowns",
    },
    pMaint: {
      label: "Maintenance Done",
      placeholder: "Select Maintenance",
    },
  },
  supplier: {
    title: "Service Provider",
    options: [
      { label: "Local mechanic" },
      { label: "In-house mechanic" },
      { label: "Supplier" },
    ],
    name: {
      label: "Service Provider",
      placeholder: "Pick service provider",
      "helper-ex":
        "Use comma ',' then press enter to separate names (Example: RBG Tire Central, JDC Repair Shop)",
      options: [
        { label: "Local mechanic" },
        { label: "In-house mechanic" },
        { label: "Supplier" },
      ],
    },
    address: {
      label: "Address",
      placeholder: "Input Address",
    },
    contact: {
      label: "Contact No.",
      placeholder: "Input Contact No.",
    },
    prov: {
      label: "Province",
      placeholder: "Select Province",
    },
    mun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    brgy: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
    sTime: {
      label: "Time to respond to concerns",
      placeholder: "Select Option",
      options: [
        { label: "Responds within 24 hours" },
        { label: "Responds after 1-2 days" },
        { label: "Responds after 3 or more days" },
        { label: "Does not respond" },
      ],
    },
    sAvail: {
      label: "Parts Availability",
      placeholder: "Select Option",
    },
  },
  usage: {
    title: "",
    usage: {
      label: "Implement Usage",
      placeholder: "Select usage",
    },
    mode: {
      label: "Mode of Aquisition",
      placeholder: "Select mode of aquisition",
    },
    agency: {
      label: "Name of Agency",
      placeholder: "Name of Agency",
    },
    oob: {
      label: "Name of owner, organization or business",
      placeholder: "Input Name",
    },
    year: "Year Acquired",
    condition: {
      label: "Condition when acquired",
      placeholder: "Select condition",
    },
    present: {
      label: "Present condition",
      placeholder: "Select condition",
    },
    contact: {
      title: "Contact Information of Service Provider",
      same: "Same as owner's information",
      consent: "Insert Consent Statement",
    },
    contact1: {
      label: "Contact No. 1",
      placeholder: "Mobile or Telephone",
    },
    contact2: {
      label: "Contact No. 2",
      placeholder: "Mobile or Telephone",
    },
    province: {
      label: "Province",
      placeholder: "Select Province",
    },
    municipality: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    barangay: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
  },
  perf: {
    title: "Tractor-Implement Performance",
    eArea: {
      label: "Effective Area",
      placeholder: "0.00",
      unit: "ha",
    },
    time: {
      label: "Time to finish eff. area",
      placeholder: "0.0",
      unit: "hrs",
    },
    cap: {
      label: "Field Capacity",
      placeholder: "0.0",
      unit: "ha/hr",
    },
    passes: {
      label: "Number of passes",
      placeholder: "0",
    },
    breakd: {
      label: "Number of Breakdowns",
      placeholder: "0",
      unit: "times",
    },
    combreak: {
      label: "Common Breakdowns",
      placeholder: "Select Breakdowns",
      options: [
        { label: "Broken tire" },
        { label: "Damaged parts" },
        { label: "Clogged nozzles" },
        { label: "Leakage" },
        { label: "Not applicable" },
      ],
    },
    maint: {
      label: "Maintenance Done",
      placeholder: "Select Maintenance",
      options: [
        { label: "Cleaning of parts" },
        { label: "Oil application" },
        { label: "Repaint" },
        { label: "Bolt adjustments" },
        { label: "Not applicable" },
      ],
    },
    start: {
      label: "Start",
      placeholder: "Select",
    },
    end: {
      label: "End",
      placeholder: "Select",
    },
    months: {
      label: "Months",
      placeholder: "0",
      unit: "months",
    },
    gran: {
      label: "Granular fertilizer weight",
      placeholder: "0.00",
      unitval: {
        label: "Unit",
        placeholder: "Choose",
        options: [{ label: "Kg / Day" }, { label: "Tons / Day" }],
      },
    },
    fertapp: {
      label: "Fertilizer Application Eff. Area",
      placeholder: "0.00",
      unit: "ha",
    },
    fertdel: {
      label: "Fertilizer Delivery Rate",
      placeholder: "0.00",
      unit: "unit/ha",
    },
    sugar: {
      label: "Sugarcane Yield",
      placeholder: "0.00",
      unit: "tons/ha",
    },
    lcap: {
      label: "Loading Capacity",
      placeholder: "0.00",
      unitval: {
        label: "Unit",
        placeholder: "Choose",
        options: [{ label: "Tons / Day" }, { label: "Kg / Day" }],
      },
    },
  },
  note: {
    label: "Survey Notes",
    placeholder: "Enter some notes here",
  },
  message: "Enter some notes here",
  save: "Save Data",
  upload: "Upload Data",
  notif: {
    save: {
      title: "Save Implement",
      message: "Implement has been saved successfully",
      center: "Close",
    },
    missing: "Kindly fill out the missing fields above.",
    saveIni: "Initializing profile for saving . . .",
    noMachine: {
      title: "No Machine Found",
      message:
        "You need to create a profile and a machine before you can proceed with the implement survey.",
      button: "Create Machine",
    },
  },
};

// /////////////////////////////////////////////////////////////////////////////
// CONSTANTS AND VARIABLES /////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// General use /////////////////////////////////////////////////////////////////

const IMBKUP = fxdImplementBackup;
var UNUSED_BACKUP: string = "";
var UNSERV_BACKUP: string = "";

var QRCODE_BACKUP: string = "";
var ACC_BACKUP: string = "";
var LAT_BACKUP: string = "";
var LNG_BACKUP: string = "";
var IMG_FRONT_BACKUP: string = "";
var IMG_SIDE_BACKUP: string = "";
var IMG_PLATE_BACKUP: string = "";

// ! Move it
function deleteImplementBackups() {
  UNUSED_BACKUP = localStorage.getItem(IMBKUP.unused) ?? "";
  UNSERV_BACKUP = localStorage.getItem(IMBKUP.unserv) ?? "";

  QRCODE_BACKUP = localStorage.getItem(IMBKUP.qrCode) ?? "";
  ACC_BACKUP = localStorage.getItem(IMBKUP.acc) ?? "";
  LAT_BACKUP = localStorage.getItem(IMBKUP.lat) ?? "";
  LNG_BACKUP = localStorage.getItem(IMBKUP.lng) ?? "";
  IMG_FRONT_BACKUP = localStorage.getItem(IMBKUP.front) ?? "";
  IMG_SIDE_BACKUP = localStorage.getItem(IMBKUP.side) ?? "";
  IMG_PLATE_BACKUP = localStorage.getItem(IMBKUP.plate) ?? "";

  Object.values(IMBKUP).forEach((val, index) => {
    localStorage.removeItem(val);
  });
}

// /////////////////////////////////////////////////////////////////////////////
// PRELOADER ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function implementLoader({ request }: LoaderFunctionArgs) {
  if (!AUTH_PROVIDER.isAuthenticated) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }

  if (localStorage.getItem(fxdMachine.machines) == null) {
    Popup({
      icon: "error",
      title: strImp.notif.noMachine.title,
      text: strImp.notif.noMachine.message,
      confirmButtonText: strImp.notif.noMachine.button,
    });
    setTimeout(() => {
      return redirect(Route.machine);
    }, 1000);
  }

  return null;
}

export default function ImplementPage() {
  // Navigation ////////////////////////////////////////////////////////////////

  const GOTO = useNavigate();

  // ///////////////////////////////////////////////////////////////////////////
  // STATE /////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Basic Information States //////////////////////////////////////////////////

  const [iResName, setIResName] = useState("");
  const [eResName, setEResName] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iMachine, setIMachine] = useState("");
  const [eMachine, setEMachine] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iQRCode, setIQRCode] = useState("");
  const [eQRCode, setEQRCode] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iImgFront, setIImgFront] = useState("");
  const [vImgFront, setVImgFront] = useState(false);
  const [eImgFront, setEImgFront] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iImgSide, setIImgSide] = useState("");
  const [vImgSide, setVImgSide] = useState(false);
  const [eImgSide, setEImgSide] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iImgPlate, setIImgPlate] = useState("");
  const [vImgPlate, setVImgPlate] = useState(false);
  const [eImgPlate, setEImgPlate] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [coor, setCoor] = useState({
    lat: undefined,
    lng: undefined,
    acc: undefined,
    accs: "",
  });
  const [vCoor, setVCoor] = useState(false);

  const [iLat, setILat] = useState("");
  const [eLat, setELat] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iLng, setILng] = useState("");
  const [eLng, setELng] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iAcc, setIAcc] = useState("");
  const [eAcc, setEAcc] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iLoc, setILoc] = useState("");
  const [eLoc, setELoc] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iProv, setIProv] = useState("");
  const [eProv, setEProv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iMun, setIMun] = useState("");
  const [eMun, setEMun] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iBrgy, setIBrgy] = useState("");
  const [eBrgy, setEBrgy] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Operations States /////////////////////////////////////////////////////////

  const [iPri, setIPri] = useState("");
  const [ePri, setEPri] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSec, setISec] = useState("");
  const [eSec, setESec] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iTer, setITer] = useState("");
  const [eTer, setETer] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Specifications States /////////////////////////////////////////////////////

  const [iType, setIType] = useState("");
  const [eType, setEType] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iBrand, setIBrand] = useState("");
  const [eBrand, setEBrand] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iModel, setIModel] = useState("");
  const [eModel, setEModel] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Custom Specifications States //////////////////////////////////////////////

  const [iBotA, setIBotA] = useState("");
  const [eBotA, setEBotA] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.bottoms },
    length: { min: 0, max: 99 },
  } as TGetValidation);

  const [iBotB, setIBotB] = useState("");
  const [eBotB, setEBotB] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.bottoms },
    length: { min: 0, max: 99 },
  } as TGetValidation);

  const [iSzDisk, setISzDisk] = useState("");
  const [eSzDisk, setESzDisk] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.disk },
    length: { min: 0, max: 99 },
  } as TGetValidation);

  const [iBotC, setIBotC] = useState("");
  const [eBotC, setEBotC] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.bottoms },
    length: { min: 0, max: 99 },
  } as TGetValidation);

  const [iDRowsA, setIDRowsA] = useState("");
  const [eDRowsA, setEDRowsA] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.distance },
    length: { min: 0, max: 999 },
  } as TGetValidation);

  const [iWCut, setIWCut] = useState("");
  const [eWCut, setEWCut] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.wcut },
    length: { min: 0, max: 999 },
  } as TGetValidation);

  const [iLCap, setILCap] = useState("");
  const [eLCap, setELCap] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.capacity },
    length: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iLCapUnit, setILCapUnit] = useState("");
  const [eLCapUnit, setELCapUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iNoRowsA, setINoRowsA] = useState("");
  const [eNoRowsA, setENoRowsA] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rows },
    length: { min: 0, max: 99 },
  } as TGetValidation);

  const [iDRowB, setIDRowB] = useState("");
  const [eDRowB, setEDRowB] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.drows },
    length: { min: 0, max: 999 },
  } as TGetValidation);

  const [iNoz, setINoz] = useState("");
  const [eNoz, setENoz] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.nozzle },
    length: { min: 0, max: 999 },
  } as TGetValidation);

  const [iDNoz, setIDNoz] = useState("");
  const [eDNoz, setEDNoz] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.dnozzle },
    length: { min: 0, max: 999 },
  } as TGetValidation);

  const [iTkCapA, setITkCapA] = useState("");
  const [eTkCapA, setETkCapA] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.capacity },
    length: { min: 0, max: 999 },
  } as TGetValidation);

  const [iNoOut, setINoOut] = useState("");
  const [eNoOut, setENoOut] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.outlet },
    length: { min: 0, max: 99 },
  } as TGetValidation);

  const [iDOut, setIDOut] = useState("");
  const [eDOut, setEDOut] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.doutlet },
    length: { min: 0, max: 999 },
  } as TGetValidation);

  const [iTkCapB, setITkCapB] = useState("");
  const [eTkCapB, setETkCapB] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.capacity },
    length: { min: 0, max: 999 },
  } as TGetValidation);

  const [iTkCapUnit, setITkCapUnit] = useState("");
  const [eTkCapUnit, setETkCapUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iTpPlntr, setITpPlntr] = useState("");
  const [eTpPlntr, setETpPlntr] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iNoRowsB, setINoRowsB] = useState("");
  const [eNoRowsB, setENoRowsB] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rows },
    length: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iGrbType, setIGrbType] = useState("");
  const [eGrbType, setEGrbType] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Ownership States //////////////////////////////////////////////////////////

  const [iOob, setIOob] = useState("");
  const [eOob, setEOob] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOClass, setIOClass] = useState("");
  const [eOClass, setEOClass] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOUsage, setIOUsage] = useState("");
  const [eOUsage, setEOUsage] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOMode, setIOMode] = useState("");
  const [vOMode, setVOMode] = useState(false);
  const [eOMode, setEOMode] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOAgency, setIOAgency] = useState("");
  const [eOAgency, setEOAgency] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iOAYear, setIOAYear] = useState("");
  const [eOAYear, setEOAYear] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    length: { min: 1949, max: 2026 },
  } as TGetValidation);

  const [iOACond, setIOACond] = useState("");
  const [eOACond, setEOACond] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPresCon, setIPresCon] = useState("");
  const [ePresCon, setEPresCon] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iUUYear, setIUUYear] = useState("");
  const [eUUYear, setEUUYear] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    length: { min: 1949, max: 2026 },
  } as TGetValidation);

  const [iUnused, setIUnused] = useState("");
  const [eUnused, setEUnused] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iUUOthers, setIUUOthers] = useState("");
  const [eUUOthers, setEUUOthers] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iUSYear, setIUSYear] = useState("");
  const [eUSYear, setEUSYear] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    length: { min: 1949, max: 2026 },
  } as TGetValidation);

  const [iUnserv, setIUnserv] = useState("");
  const [eUnserv, setEUnserv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iUSOthers, setIUSOthers] = useState("");
  const [eUSOthers, setEUSOthers] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Renting States ////////////////////////////////////////////////////////////

  const [iRental, setIRental] = useState("");
  const [eRental, setERental] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iRFrtOpe, setIRFrtOpe] = useState("");
  const [eRFrtOpe, setERFrtOpe] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iRFrtRate, setIRFrtRate] = useState("");
  const [eRFrtRate, setERFrtRate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    length: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iRFrtUnit, setIRFrtUnit] = useState("");
  const [eRFrtUnit, setERFrtUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // const [iRFrtFuel, setIRFrtFuel] = useState("");
  // const [eRFrtFuel, setERFrtFuel] = useState({
  //   required: false,
  //   condition: "none",
  //   message: { label: "" },
  // } as TGetValidation);

  const [iRScdOpe, setIRScdOpe] = useState("");
  const [eRScdOpe, setERScdOpe] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iRScdRate, setIRScdRate] = useState("");
  const [eRScdRate, setERScdRate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    length: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iRScdUnit, setIRScdUnit] = useState("");
  const [eRScdUnit, setERScdUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // const [iRScdFuel, setIRScdFuel] = useState("");
  // const [eRScdFuel, setERScdFuel] = useState({
  //   required: false,
  //   condition: "none",
  //   message: { label: "" },
  // } as TGetValidation);

  const [iRTrdOpe, setIRTrdOpe] = useState("");
  const [eRTrdOpe, setERTrdOpe] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iRTrdRate, setIRTrdRate] = useState("");
  const [eRTrdRate, setERTrdRate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    length: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iRTrdUnit, setIRTrdUnit] = useState("");
  const [eRTrdUnit, setERTrdUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // const [iRTrdFuel, setIRTrdFuel] = useState("");
  // const [eRTrdFuel, setERTrdFuel] = useState({
  //   required: false,
  //   condition: "none",
  //   message: { label: "" },
  // } as TGetValidation);

  // Performance States ////////////////////////////////////////////////////////

  const [iPEffArea, setIPEffArea] = useState("");
  const [ePEffArea, setEPEffArea] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    length: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iPEffATime, setIPEffATime] = useState("");
  const [ePEffATime, setEPEffATime] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.time },
    length: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iPPasses, setIPPasses] = useState("");
  const [ePPasses, setEPPasses] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.passes },
    length: { min: 0, max: 10 },
  } as TGetValidation);

  const [iPGrFerWght, setIPGrFerWght] = useState("");
  const [ePGrFerWght, setEPGrFerWght] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    length: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iPGrFerUnit, setIPGrFerUnit] = useState("");
  const [ePGrFerUnit, setEPGrFerUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPFerAppEffA, setIPFerAppEffA] = useState("");
  const [ePFerAppEffA, setEPFerAppEffA] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    length: { min: 0, max: 99 },
  } as TGetValidation);

  const [iPFerDelRate, setIPFerDelRate] = useState("");
  const [ePFerDelRate, setEPFerDelRate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    length: { min: 0, max: 99 },
  } as TGetValidation);

  const [iPSgrYield, setIPSgrYield] = useState("");
  const [ePSgrYield, setEPSgrYield] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.yield },
    length: { min: 0, max: 99 },
  } as TGetValidation);

  const [iPLCap, setIPLCap] = useState("");
  const [ePLCap, setEPLCap] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.capacity },
    length: { min: 0, max: 999 },
  } as TGetValidation);

  const [iPLCapUnit, setIPLCapUnit] = useState("");
  const [ePLCapUnit, setEPLCapUnit] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPBrkDwns, setIPBrkDwns] = useState("");
  const [ePBrkDwns, setEPBrkDwns] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.breakdown },
    length: { min: 0, max: 999 },
  } as TGetValidation);

  const [iPComBrk, setIPComBrk] = useState("");
  const [ePComBrk, setEPComBrk] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iPMaint, setIPMaint] = useState("");
  const [ePMaint, setEPMaint] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // Service Provider States ///////////////////////////////////////////////////

  const [iSupplier, setISupplier] = useState("");
  const [eSupplier, setESupplier] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSProv, setISProv] = useState("");
  const [eSProv, setESProv] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSMun, setISMun] = useState("");
  const [eSMun, setESMun] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSBrgy, setISBrgy] = useState("");
  const [eSBrgy, setESBrgy] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSTime, setISTime] = useState("");
  const [eSTime, setESTime] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iSAvail, setISAvail] = useState("");
  const [eSAvail, setESAvail] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  const [iNotes, setINotes] = useState("");
  const [eNotes, setENotes] = useState({
    required: false,
    condition: "none",
    message: { label: "" },
  } as TGetValidation);

  // ///////////////////////////////////////////////////////////////////////////
  // CONSTANTS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Address ///////////////////////////////////////////////////////////////////

  const implement: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  const aftersales: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  const provider: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  const adPvd: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  const adYes: Address = {
    province: {
      options: useState(
        provinces.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    municipality: {
      options: useState(
        municipalities.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
    },
    barangay: {
      options: useState(
        barangays.sort((a, b) => a.label.localeCompare(b.label))
      ),
      disabled: useState(true),
      key: useState(0),
    },
  };

  // Questions /////////////////////////////////////////////////////////////////

  type QUESTION = {
    id: number;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    errorMessage: TGetValidation;
    setErrorMessage: React.Dispatch<React.SetStateAction<TGetValidation>>;
    backup: string;
    label: string;
  };

  const QUESTIONS: QUESTION[] = [
    {
      id: 1,
      value: iResName,
      setValue: setIResName,
      errorMessage: eResName,
      setErrorMessage: setEResName,
      backup: IMBKUP.resName,
      label: strImp.basicInfo.resName.label,
    },
    {
      id: 2,
      value: iMachine,
      setValue: setIMachine,
      errorMessage: eMachine,
      setErrorMessage: setEMachine,
      backup: IMBKUP.machine,
      label: strImp.basicInfo.machine.label,
    },
    {
      id: 3,
      value: iQRCode,
      setValue: setIQRCode,
      errorMessage: eQRCode,
      setErrorMessage: setEQRCode,
      backup: IMBKUP.qrCode,
      label: strImp.basicInfo.qrCode.label,
    },
    {
      id: 4,
      value: iImgFront,
      setValue: setIImgFront,
      errorMessage: eImgFront,
      setErrorMessage: setEImgFront,
      backup: IMBKUP.front,
      label: strImp.basicInfo.front.label,
    },
    {
      id: 5,
      value: iImgSide,
      setValue: setIImgSide,
      errorMessage: eImgSide,
      setErrorMessage: setEImgSide,
      backup: IMBKUP.side,
      label: strImp.basicInfo.side.label,
    },
    {
      id: 6,
      value: iImgPlate,
      setValue: setIImgPlate,
      errorMessage: eImgPlate,
      setErrorMessage: setEImgPlate,
      backup: IMBKUP.plate,
      label: strImp.basicInfo.plate.label,
    },
    {
      id: 7,
      value: iLat,
      setValue: setILat,
      errorMessage: eLat,
      setErrorMessage: setELat,
      backup: IMBKUP.lat,
      label: strImp.basicInfo.lat.label,
    },
    {
      id: 8,
      value: iLng,
      setValue: setILng,
      errorMessage: eLng,
      setErrorMessage: setELng,
      backup: IMBKUP.lng,
      label: strImp.basicInfo.lng.label,
    },
    {
      id: 9,
      value: iAcc,
      setValue: setIAcc,
      errorMessage: eAcc,
      setErrorMessage: setEAcc,
      backup: IMBKUP.acc,
      label: strImp.basicInfo.acc.label,
    },
    {
      id: 10,
      value: iLoc,
      setValue: setILoc,
      errorMessage: eLoc,
      setErrorMessage: setELoc,
      backup: IMBKUP.loc,
      label: strImp.basicInfo.loc.label,
    },
    {
      id: 11,
      value: iProv,
      setValue: setIProv,
      errorMessage: eProv,
      setErrorMessage: setEProv,
      backup: IMBKUP.prov,
      label: strImp.basicInfo.prov.label,
    },
    {
      id: 12,
      value: iMun,
      setValue: setIMun,
      errorMessage: eMun,
      setErrorMessage: setEMun,
      backup: IMBKUP.mun,
      label: strImp.basicInfo.mun.label,
    },
    {
      id: 13,
      value: iBrgy,
      setValue: setIBrgy,
      errorMessage: eBrgy,
      setErrorMessage: setEBrgy,
      backup: IMBKUP.brgy,
      label: strImp.basicInfo.brgy.label,
    },
    //
    {
      id: 14,
      value: iPri,
      setValue: setIPri,
      errorMessage: ePri,
      setErrorMessage: setEPri,
      backup: IMBKUP.pri,
      label: strImp.operations.pri.label,
    },
    {
      id: 15,
      value: iSec,
      setValue: setISec,
      errorMessage: eSec,
      setErrorMessage: setESec,
      backup: IMBKUP.sec,
      label: strImp.operations.sec.label,
    },
    {
      id: 16,
      value: iTer,
      setValue: setITer,
      errorMessage: eTer,
      setErrorMessage: setETer,
      backup: IMBKUP.ter,
      label: strImp.operations.ter.label,
    },
    //
    {
      id: 17,
      value: iType,
      setValue: setIType,
      errorMessage: eType,
      setErrorMessage: setEType,
      backup: IMBKUP.type,
      label: strImp.basicInfo.type.label,
    },
    {
      id: 18,
      value: iBrand,
      setValue: setIBrand,
      errorMessage: eBrand,
      setErrorMessage: setEBrand,
      backup: IMBKUP.brand,
      label: strImp.specifications.brand.label,
    },
    {
      id: 19,
      value: iModel,
      setValue: setIModel,
      errorMessage: eModel,
      setErrorMessage: setEModel,
      backup: IMBKUP.model,
      label: strImp.specifications.model.label,
    },
    //
    {
      id: 20,
      value: iBotA,
      setValue: setIBotA,
      errorMessage: eBotA,
      setErrorMessage: setEBotA,
      backup: IMBKUP.botA,
      label: strImp.specifications.bottoms.label,
    },
    {
      id: 21,
      value: iBotB,
      setValue: setIBotB,
      errorMessage: eBotB,
      setErrorMessage: setEBotB,
      backup: IMBKUP.botB,
      label: strImp.specifications.bottoms.label,
    },
    {
      id: 22,
      value: iSzDisk,
      setValue: setISzDisk,
      errorMessage: eSzDisk,
      setErrorMessage: setESzDisk,
      backup: IMBKUP.szDisk,
      label: strImp.specifications.disk.label,
    },
    {
      id: 23,
      value: iBotC,
      setValue: setIBotC,
      errorMessage: eBotC,
      setErrorMessage: setEBotC,
      backup: IMBKUP.botC,
      label: strImp.specifications.bottoms.label,
    },
    {
      id: 24,
      value: iDRowsA,
      setValue: setIDRowsA,
      errorMessage: eDRowsA,
      setErrorMessage: setEDRowsA,
      backup: IMBKUP.dRowsA,
      label: strImp.specifications.distance.label,
    },
    {
      id: 25,
      value: iWCut,
      setValue: setIWCut,
      errorMessage: eWCut,
      setErrorMessage: setEWCut,
      backup: IMBKUP.wCut,
      label: strImp.specifications.width.label,
    },
    {
      id: 26,
      value: iLCap,
      setValue: setILCap,
      errorMessage: eLCap,
      setErrorMessage: setELCap,
      backup: IMBKUP.lCap,
      label: strImp.perf.lcap.label,
    },
    {
      id: 27,
      value: iLCapUnit,
      setValue: setILCapUnit,
      errorMessage: eLCapUnit,
      setErrorMessage: setELCapUnit,
      backup: IMBKUP.lCapUnit,
      label: strImp.perf.lcap.label + " " + strImp.perf.lcap.unitval.label,
    },
    {
      id: 28,
      value: iNoRowsA,
      setValue: setINoRowsA,
      errorMessage: eNoRowsA,
      setErrorMessage: setENoRowsA,
      backup: IMBKUP.noRowsA,
      label: strImp.specifications.rows.label,
    },
    {
      id: 29,
      value: iDRowB,
      setValue: setIDRowB,
      errorMessage: eDRowB,
      setErrorMessage: setEDRowB,
      backup: IMBKUP.dRowB,
      label: strImp.specifications.distance.label,
    },
    {
      id: 30,
      value: iNoz,
      setValue: setINoz,
      errorMessage: eNoz,
      setErrorMessage: setENoz,
      backup: IMBKUP.noz,
      label: strImp.specifications.noz.label,
    },
    {
      id: 31,
      value: iDNoz,
      setValue: setIDNoz,
      errorMessage: eDNoz,
      setErrorMessage: setEDNoz,
      backup: IMBKUP.dNoz,
      label: strImp.specifications.dNoz.label,
    },
    {
      id: 32,
      value: iTkCapA,
      setValue: setITkCapA,
      errorMessage: eTkCapA,
      setErrorMessage: setETkCapA,
      backup: IMBKUP.tkCapA,
      label: strImp.specifications.tkCap.label,
    },
    {
      id: 33,
      value: iNoOut,
      setValue: setINoOut,
      errorMessage: eNoOut,
      setErrorMessage: setENoOut,
      backup: IMBKUP.noOut,
      label: strImp.specifications.out.label,
    },
    {
      id: 34,
      value: iDOut,
      setValue: setIDOut,
      errorMessage: eDOut,
      setErrorMessage: setEDOut,
      backup: IMBKUP.dOut,
      label: strImp.specifications.dOut.label,
    },
    {
      id: 35,
      value: iTkCapB,
      setValue: setITkCapB,
      errorMessage: eTkCapB,
      setErrorMessage: setETkCapB,
      backup: IMBKUP.tkCapB,
      label: strImp.specifications.tkCap2.label,
    },
    {
      id: 36,
      value: iTkCapUnit,
      setValue: setITkCapUnit,
      errorMessage: eTkCapUnit,
      setErrorMessage: setETkCapUnit,
      backup: IMBKUP.tkCapUnit,
      label:
        strImp.specifications.tkCap2.label +
        " " +
        strImp.specifications.tkCap2.unitval.label,
    },
    {
      id: 37,
      value: iTpPlntr,
      setValue: setITpPlntr,
      errorMessage: eTpPlntr,
      setErrorMessage: setETpPlntr,
      backup: IMBKUP.tpPlntr,
      label: strImp.specifications.type1.text.label,
    },
    {
      id: 38,
      value: iNoRowsB,
      setValue: setINoRowsB,
      errorMessage: eNoRowsB,
      setErrorMessage: setENoRowsB,
      backup: IMBKUP.noRowsB,
      label: strImp.specifications.rows.label,
    },
    {
      id: 39,
      value: iGrbType,
      setValue: setIGrbType,
      errorMessage: eGrbType,
      setErrorMessage: setEGrbType,
      backup: IMBKUP.grbType,
      label: strImp.specifications.type2.text.label,
    },
    //
    {
      id: 40,
      value: iOob,
      setValue: setIOob,
      errorMessage: eOob,
      setErrorMessage: setEOob,
      backup: IMBKUP.oob,
      label: strMac.usage.oob.label,
    },
    {
      id: 41,
      value: iOClass,
      setValue: setIOClass,
      errorMessage: eOClass,
      setErrorMessage: setEOClass,
      backup: IMBKUP.oClass,
      label: strMac.usage.class.label,
    },
    {
      id: 42,
      value: iOUsage,
      setValue: setIOUsage,
      errorMessage: eOUsage,
      setErrorMessage: setEOUsage,
      backup: IMBKUP.oUsage,
      label: strMac.usage.usage.label,
    },
    {
      id: 43,
      value: iOMode,
      setValue: setIOMode,
      errorMessage: eOMode,
      setErrorMessage: setEOMode,
      backup: IMBKUP.oMode,
      label: strMac.usage.mode.label,
    },
    {
      id: 44,
      value: iOAgency,
      setValue: setIOAgency,
      errorMessage: eOAgency,
      setErrorMessage: setEOAgency,
      backup: IMBKUP.oAgency,
      label: strMac.usage.agency.label,
    },
    {
      id: 45,
      value: iOAYear,
      setValue: setIOAYear,
      errorMessage: eOAYear,
      setErrorMessage: setEOAYear,
      backup: IMBKUP.oAYear,
      label: strMac.usage.acqYear.label,
    },
    {
      id: 46,
      value: iOACond,
      setValue: setIOACond,
      errorMessage: eOACond,
      setErrorMessage: setEOACond,
      backup: IMBKUP.oACond,
      label: strMac.usage.cond.label,
    },
    {
      id: 47,
      value: iPresCon,
      setValue: setIPresCon,
      errorMessage: ePresCon,
      setErrorMessage: setEPresCon,
      backup: IMBKUP.presCon,
      label: strMac.usage.pCond.label,
    },
    {
      id: 48,
      value: iUUYear,
      setValue: setIUUYear,
      errorMessage: eUUYear,
      setErrorMessage: setEUUYear,
      backup: IMBKUP.uUYear,
      label: strMac.unused.uuYear.label,
    },
    {
      id: 49,
      value: iUnused,
      setValue: setIUnused,
      errorMessage: eUnused,
      setErrorMessage: setEUnused,
      backup: IMBKUP.unused,
      label: strMac.unused.subtitle,
    },
    {
      id: 50,
      value: iUUOthers,
      setValue: setIUUOthers,
      errorMessage: eUUOthers,
      setErrorMessage: setEUUOthers,
      backup: IMBKUP.uUOthers,
      label: strMac.unused.subtitle + " " + strMac.unused.uuOthers.label,
    },
    {
      id: 51,
      value: iUSYear,
      setValue: setIUSYear,
      errorMessage: eUSYear,
      setErrorMessage: setEUSYear,
      backup: IMBKUP.uSYear,
      label: strMac.unserv.usYear.label,
    },
    {
      id: 52,
      value: iUnserv,
      setValue: setIUnserv,
      errorMessage: eUnserv,
      setErrorMessage: setEUnserv,
      backup: IMBKUP.unserv,
      label: strMac.unserv.subtitle,
    },
    {
      id: 53,
      value: iUSOthers,
      setValue: setIUSOthers,
      errorMessage: eUSOthers,
      setErrorMessage: setEUSOthers,
      backup: IMBKUP.uSOthers,
      label: strMac.unserv.subtitle + " " + strMac.unserv.usOthers.label,
    },
    //
    {
      id: 54,
      value: iRental,
      setValue: setIRental,
      errorMessage: eRental,
      setErrorMessage: setERental,
      backup: IMBKUP.rental,
      label: strMac.rent.question.label,
    },
    {
      id: 55,
      value: iRFrtOpe,
      setValue: setIRFrtOpe,
      errorMessage: eRFrtOpe,
      setErrorMessage: setERFrtOpe,
      backup: IMBKUP.rFrtOpe,
      label: strMac.rent.ope.first,
    },
    {
      id: 56,
      value: iRFrtRate,
      setValue: setIRFrtRate,
      errorMessage: eRFrtRate,
      setErrorMessage: setERFrtRate,
      backup: IMBKUP.rFrtRate,
      label: strMac.rent.ope.first + " " + strMac.rent.fORate.label,
    },
    {
      id: 57,
      value: iRFrtUnit,
      setValue: setIRFrtUnit,
      errorMessage: eRFrtUnit,
      setErrorMessage: setERFrtUnit,
      backup: IMBKUP.rFrtUnit,
      label:
        strMac.rent.ope.first +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    // {
    // value: iRFrtFuel,
    // setValue: setIRFrtFuel,
    // errorMessage: eRFrtFuel,
    // setErrorMessage: setERFrtFuel,
    // backup: IMBKUP.rFrtFuel,
    // },
    {
      id: 58,
      value: iRScdOpe,
      setValue: setIRScdOpe,
      errorMessage: eRScdOpe,
      setErrorMessage: setERScdOpe,
      backup: IMBKUP.rScdOpe,
      label: strMac.rent.ope.second,
    },
    {
      id: 59,
      value: iRScdRate,
      setValue: setIRScdRate,
      errorMessage: eRScdRate,
      setErrorMessage: setERScdRate,
      backup: IMBKUP.rScdRate,
      label: strMac.rent.ope.second + " " + strMac.rent.fORate.label,
    },
    {
      id: 60,
      value: iRScdUnit,
      setValue: setIRScdUnit,
      errorMessage: eRScdUnit,
      setErrorMessage: setERScdUnit,
      backup: IMBKUP.rScdUnit,
      label:
        strMac.rent.ope.second +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    // {
    // value: iRScdFuel,
    // setValue: setIRScdFuel,
    // errorMessage: eRScdFuel,
    // setErrorMessage: setERScdFuel,
    // backup: IMBKUP.rScdFuel,
    // },
    {
      id: 61,
      value: iRTrdOpe,
      setValue: setIRTrdOpe,
      errorMessage: eRTrdOpe,
      setErrorMessage: setERTrdOpe,
      backup: IMBKUP.rTrdOpe,
      label: strMac.rent.ope.second + " " + strMac.rent.fOFuel.label,
    },
    {
      id: 62,
      value: iRTrdRate,
      setValue: setIRTrdRate,
      errorMessage: eRTrdRate,
      setErrorMessage: setERTrdRate,
      backup: IMBKUP.rTrdRate,
      label: strMac.rent.ope.third,
    },
    {
      id: 63,
      value: iRTrdUnit,
      setValue: setIRTrdUnit,
      errorMessage: eRTrdUnit,
      setErrorMessage: setERTrdUnit,
      backup: IMBKUP.rTrdUnit,
      label: strMac.rent.ope.third + " " + strMac.rent.fORate.label,
    },
    // {
    //   value: iRTrdFuel,
    //   setValue: setIRTrdFuel,
    //   errorMessage: eRTrdFuel,
    //   setErrorMessage: setERTrdFuel,
    //   backup: IMBKUP.rTrdFuel,
    // },
    //
    {
      id: 64,
      value: iPEffArea,
      setValue: setIPEffArea,
      errorMessage: ePEffArea,
      setErrorMessage: setEPEffArea,
      backup: IMBKUP.pEffArea,
      label: strImp.capacity.eArea.label,
    },
    {
      id: 65,
      value: iPEffATime,
      setValue: setIPEffATime,
      errorMessage: ePEffATime,
      setErrorMessage: setEPEffATime,
      backup: IMBKUP.pEffATime,
      label: strImp.capacity.time.label,
    },
    {
      id: 66,
      value: iPPasses,
      setValue: setIPPasses,
      errorMessage: ePPasses,
      setErrorMessage: setEPPasses,
      backup: IMBKUP.pPasses,
      label: strImp.capacity.nPasses.label,
    },
    {
      id: 67,
      value: iPGrFerWght,
      setValue: setIPGrFerWght,
      errorMessage: ePGrFerWght,
      setErrorMessage: setEPGrFerWght,
      backup: IMBKUP.pGrFerWght,
      label: strImp.delivery.fert.label,
    },
    {
      id: 68,
      value: iPGrFerUnit,
      setValue: setIPGrFerUnit,
      errorMessage: ePGrFerUnit,
      setErrorMessage: setEPGrFerUnit,
      backup: IMBKUP.pGrFerUnit,
      label:
        strImp.delivery.fert.label + " " + strImp.delivery.fert.unitval.label,
    },
    {
      id: 69,
      value: iPFerAppEffA,
      setValue: setIPFerAppEffA,
      errorMessage: ePFerAppEffA,
      setErrorMessage: setEPFerAppEffA,
      backup: IMBKUP.pFerAppEffA,
      label: strImp.delivery.eArea.label,
    },
    {
      id: 70,
      value: iPFerDelRate,
      setValue: setIPFerDelRate,
      errorMessage: ePFerDelRate,
      setErrorMessage: setEPFerDelRate,
      backup: IMBKUP.pFerDelRate,
      label: strImp.delivery.rate.label,
    },
    {
      id: 71,
      value: iPSgrYield,
      setValue: setIPSgrYield,
      errorMessage: ePSgrYield,
      setErrorMessage: setEPSgrYield,
      backup: IMBKUP.pSgrYield,
      label: strImp.capacity.yield.label,
    },
    {
      id: 72,
      value: iPLCap,
      setValue: setIPLCap,
      errorMessage: ePLCap,
      setErrorMessage: setEPLCap,
      backup: IMBKUP.pLCap,
      label: strImp.perf.lcap.label,
    },
    {
      id: 73,
      value: iPLCapUnit,
      setValue: setIPLCapUnit,
      errorMessage: ePLCapUnit,
      setErrorMessage: setEPLCapUnit,
      backup: IMBKUP.pLCapUnit,
      label: strImp.perf.lcap.label + " " + strImp.perf.lcap.unitval.label,
    },
    {
      id: 74,
      value: iPBrkDwns,
      setValue: setIPBrkDwns,
      errorMessage: ePBrkDwns,
      setErrorMessage: setEPBrkDwns,
      backup: IMBKUP.pBrkDwns,
      label: strImp.performance.breakdowns.label,
    },
    {
      id: 75,
      value: iPComBrk,
      setValue: setIPComBrk,
      errorMessage: ePComBrk,
      setErrorMessage: setEPComBrk,
      backup: IMBKUP.pComBrk,
      label: strImp.performance.pComBrk.label,
    },
    {
      id: 76,
      value: iPMaint,
      setValue: setIPMaint,
      errorMessage: ePMaint,
      setErrorMessage: setEPMaint,
      backup: IMBKUP.pMaint,
      label: strImp.performance.pMaint.label,
    },
    //
    {
      id: 77,
      value: iSupplier,
      setValue: setISupplier,
      errorMessage: eSupplier,
      setErrorMessage: setESupplier,
      backup: IMBKUP.supplier,
      label: strImp.supplier.name.label,
    },
    {
      id: 78,
      value: iSProv,
      setValue: setISProv,
      errorMessage: eSProv,
      setErrorMessage: setESProv,
      backup: IMBKUP.sProv,
      label: strImp.supplier.prov.label,
    },
    {
      id: 79,
      value: iSMun,
      setValue: setISMun,
      errorMessage: eSMun,
      setErrorMessage: setESMun,
      backup: IMBKUP.sMun,
      label: strImp.supplier.mun.label,
    },
    {
      id: 80,
      value: iSBrgy,
      setValue: setISBrgy,
      errorMessage: eSBrgy,
      setErrorMessage: setESBrgy,
      backup: IMBKUP.sBrgy,
      label: strImp.supplier.brgy.label,
    },
    {
      id: 81,
      value: iSTime,
      setValue: setISTime,
      errorMessage: eSTime,
      setErrorMessage: setESTime,
      backup: IMBKUP.sTime,
      label: strImp.supplier.sTime.label,
    },
    {
      id: 82,
      value: iSAvail,
      setValue: setISAvail,
      errorMessage: eSAvail,
      setErrorMessage: setESAvail,
      backup: IMBKUP.sAvail,
      label: strImp.supplier.sAvail.label,
    },
    //
    {
      id: 83,
      value: iNotes,
      setValue: setINotes,
      errorMessage: eNotes,
      setErrorMessage: setENotes,
      backup: IMBKUP.notes,
      label: strImp.note.label,
    },
  ];
  // ///////////////////////////////////////////////////////////////////////////

  const [qrDevice, setQRDevice] = useState("");
  const [qrVideos, setQRVideos] = useState([]);
  const [qrCameras, setQRCameras] = useState<
    { label: string; value: string }[]
  >([]);
  const [vQRCode, setVQRCode] = useState(false);

  const qrReader = new BrowserQRCodeReader();

  useEffect(() => {
    setQRCameras(
      qrVideos.map((element: any) => ({
        label: element.label,
        value: element.deviceId,
      }))
    );
  }, [qrVideos]);

  function setupDevices(videos: any) {
    const sourceSelect = document.getElementById("sourceSelect");
    setQRDevice(videos[0].deviceId);
    if (videos.length >= 1) setQRVideos(videos);
  }

  function resetQR() {
    qrReader.reset();
    setIQRCode("");
  }

  function againQR() {
    qrReader
      .getVideoInputDevices()
      .then((qrVideo) => {
        setupDevices(qrVideo);
      })
      .catch((err) => {});
    decodeOnce(qrReader, qrDevice);
  }

  function decodeOnce(codeReader: any, selectedDeviceId: any) {
    codeReader
      .decodeFromInputVideoDevice(selectedDeviceId, "video")
      .then((result: any) => {
        let res = result!.getText();
        res = res.substring(res.lastIndexOf("/") + 1);
        if (
          res.charAt(6) === "I" &&
          res.length === 12 &&
          res.charAt(0) === "R"
        ) {
          setIQRCode(res);
        } else {
          showPopup({
            title: "QR Error",
            content:
              "Use a valid QR Code. Resetting QR Scanner. Wait a few seconds.",
            actionCenter: { text: "Close", color: "error" },
          });
          setTimeout(() => {
            resetQR();
            againQR();
          }, 5000);
        }
      })
      .catch((err: any) => {});
  }

  useEffect(() => {
    if (iQRCode) {
      var qrCode = (iQRCode as any).label ?? iQRCode;
      if (qrCode.length > 10) {
        setVQRCode(false);
      }
    }
  }, [iQRCode]);

  //   // ///////////////////////////////////////////////////////////////////////////

  const [disQRBtn, setDisQRBtn] = useState(false);
  const [disCamBtnFront, setDisCamBtnFront] = useState(false);
  const [disCamBtnSide, setDisCamBtnSide] = useState(false);
  const [disCamBtnPlate, setDisCamBtnPlate] = useState(false);

  useEffect(() => {
    if (vQRCode) {
      setVImgFront(false);
      setVImgSide(false);
      setVImgPlate(false);

      setDisQRBtn(false);
      setDisCamBtnFront(true);
      setDisCamBtnSide(true);
      setDisCamBtnPlate(true);
    } else if (vImgFront) {
      setVQRCode(false);
      setVImgSide(false);
      setVImgPlate(false);

      setDisQRBtn(true);
      setDisCamBtnFront(false);
      setDisCamBtnSide(true);
      setDisCamBtnPlate(true);
    } else if (vImgSide) {
      setVQRCode(false);
      setVImgFront(false);
      setVImgPlate(false);

      setDisQRBtn(true);
      setDisCamBtnFront(true);
      setDisCamBtnSide(false);
      setDisCamBtnPlate(true);
    } else if (vImgPlate) {
      setVQRCode(false);
      setVImgFront(false);
      setVImgSide(false);

      setDisQRBtn(true);
      setDisCamBtnFront(true);
      setDisCamBtnSide(true);
      setDisCamBtnPlate(false);
    } else {
      setDisQRBtn(false);
      setDisCamBtnFront(false);
      setDisCamBtnSide(false);
      setDisCamBtnPlate(false);
    }
  }, [vQRCode, vImgFront, vImgSide, vImgPlate]);

  // Coordinate separation ////////////////////////////////////////////////////
  useEffect(() => {
    if (
      coor.lat != undefined &&
      coor.lng != undefined &&
      coor.acc != undefined
    ) {
      setILat(coor.lat + "");
      setILng(coor.lng + "");
      setIAcc(coor.acc + "");
    }
  }, [coor]);

  // const [oFrt, setOFrt] = useState(strMac.rent.operation.options);
  // const [oScd, setOScd] = useState(strMac.rent.operation.options);
  // const [oTrd, setOTrd] = useState(strMac.rent.operation.options);

  // Disabled
  // const [dFrt, setDFrt] = useState(false);
  // const [dScd, setDScd] = useState(true);
  // const [dTrd, setDTrd] = useState(true);

  // Keys
  // const [kFrt, setKFrt] = useState(0);
  // const [kScd, setKScd] = useState(1000);
  // const [kTrd, setKTrd] = useState(2000);

  // ! Seemingly would not work
  // function oICFrt(e: any, val: any) {
  //   if (val == null || val == undefined || val == "") {
  //     disOpe({ frt: false, scd: true, trd: true });
  //     setKScd(kScd + 1);
  //     setKTrd(kTrd + 1);
  //     setOFrt(strMac.rent.operation.options);
  //     setOScd(strMac.rent.operation.options);
  //     setOTrd(strMac.rent.operation.options);
  //   } else {
  //     disOpe({ frt: true, scd: false, trd: true });

  //     if (iSec && (iSec as any).label != "") {
  //       setKScd(kScd + 1);
  //       setKTrd(kTrd + 1);
  //     }

  //     setOScd(strMac.rent.operation.options.filter((o) => o.label != val));
  //     setOTrd(strMac.rent.operation.options.filter((o) => o.label != val));
  //   }
  // }

  // ! Seemingly would not work
  // function oICScd(e: any, val: any) {
  //   if (iPri) {
  //     if (val == null || val == undefined || (val == "" && !dScd)) {
  //       disOpe({ frt: true, scd: false, trd: true });
  //     } else {
  //       disOpe({ frt: true, scd: true, trd: false });
  //       setOTrd(
  //         strMac.rent.operation.options
  //           .filter((o) => o.label != val)
  //           .filter((o) => o.label != (iPri as any).label)
  //       );
  //     }
  //   }
  // }

  // function oICTrd(e: any, val: any) {
  //   if (val == null || val == undefined || (val == "" && !dTrd)) {
  //     disOpe({ frt: true, scd: true, trd: false });
  //   } else {
  //     disOpe({ frt: false, scd: true, trd: true });
  //   }
  // }

  // function disOpe(p: { frt: boolean; scd: boolean; trd: boolean }) {
  //   setDFrt(p.frt);
  //   setDScd(p.scd);
  //   setDTrd(p.trd);
  // }

  useEffect(() => {
    var mode = iOMode ? (iOMode as any).label : iOMode;
    if (mode != strMac.usage.mode.options[0]) {
      setVOMode(true);
    } else {
      setVOMode(false);
    }
  }, [iOMode]);

  const [vUsed, setVUsed] = useState(false);
  const [vUnUsd, setVUnUsd] = useState(false);

  useEffect(() => {
    var prescon = iPresCon ? (iPresCon as any).label : iPresCon;
    setVUsed(false);
    setVUnUsd(false);

    if (prescon == strMac.usage.pCond.options[1].label) {
      setVUsed(true);
    } else if (prescon == strMac.usage.pCond.options[2].label) {
      setVUnUsd(true);
    }
  }, [iPresCon]);

  // ///////////////////////////////////////////////////////////////////////////

  //   // ///////////////////////////////////////////////////////////////////////////

  //   // ///////////////////////////////////////////////////////////////////////////

  const [vTypeGrp, setVTypeGrp] = useState(0);
  const [v01, setV01] = useState(false);
  const [v02, setV02] = useState(false);
  const [v03, setV03] = useState(false);
  const [v04, setV04] = useState(false);
  const [v05, setV05] = useState(false);
  const [v06, setV06] = useState(false);
  const [v07, setV07] = useState(false);
  const [v08, setV08] = useState(false);
  const [v09, setV09] = useState(false);
  const [v10, setV10] = useState(false);
  const [v11, setV11] = useState(false);

  function hideSpecsCard() {
    setV01(false);
    setV02(false);
    setV03(false);
    setV04(false);
    setV05(false);
    setV06(false);
    setV07(false);
    setV08(false);
    setV09(false);
    setV10(false);
    setV11(false);
  }

  function oICType(e: any, val: any) {
    if (val == tMolBrdPlow || val == tSubsoiler || val == tChiselPlow) {
      setVTypeGrp(1);
      hideSpecsCard();
      setV01(true);
    } else if (val == tDiscPlow || val == tDiscHarrow) {
      setVTypeGrp(2);
      hideSpecsCard();
      setV02(true);
    } else if (
      val == tFurrower ||
      val == tItrRowCltvtr ||
      val == tCutAwayCltvtr ||
      val == tTrashInc ||
      val == tDitcher
    ) {
      setVTypeGrp(3);
      hideSpecsCard();
      setV03(true);
    } else if (
      val == tPowerHarrow ||
      val == tSpkTthHarrow ||
      val == tFldCltvtr ||
      val == tRtnManager ||
      val == tTrashRake ||
      val == tRotTiller ||
      val == tMulcher ||
      val == tDozer
    ) {
      setVTypeGrp(4);
      hideSpecsCard();
      setV04(true);
    } else if (val == tTrailer || val == tExcavator) {
      setVTypeGrp(5);
      hideSpecsCard();
      setV05(true);
    } else if (val == tWeeder) {
      setVTypeGrp(6);
      hideSpecsCard();
      setV06(true);
    } else if (val == tBoomSprayer) {
      setVTypeGrp(7);
      hideSpecsCard();
      setV07(true);
    } else if (val == tGranFertAppl || val == tFertApplCutAway) {
      setVTypeGrp(8);
      hideSpecsCard();
      setV08(true);
    } else if (val == tMechPlanter) {
      setVTypeGrp(9);
      hideSpecsCard();
      setV09(true);
    } else if (val == tMechHarvester) {
      setVTypeGrp(10);
      hideSpecsCard();
      setV10(true);
    } else if (val == tCaneGrabLoader) {
      setVTypeGrp(11);
      hideSpecsCard();
      setV11(true);
    } else {
      setVTypeGrp(0);
      hideSpecsCard();
    }
  }

  // ///////////////////////////////////////////////////////////////////////////

  const profiles = localStorage.getItem(fxdProfile.profiles);
  let oUsernames: { label: string }[] = [];
  if (profiles == null) {
    Popup({
      icon: "error",
      title: strMac.notif.noProfile.title,
      text: strMac.notif.noProfile.message,
      confirmButtonText: strMac.notif.noProfile.button,
    });
  } else {
    const profs = JSON.parse(profiles);
    for (let i = 0; i < profs.length; i++) {
      oUsernames.push({ label: profs[i].resName });
    }
  }

  const machines = localStorage.getItem(fxdMachine.machines);
  let oMachines: { label: string }[] = [];
  if (machines == null) {
    Popup({
      icon: "error",
      title: strImp.notif.noMachine.title,
      text: strImp.notif.noMachine.message,
      confirmButtonText: strImp.notif.noMachine.button,
    });
  } else {
    const machs = JSON.parse(machines);
    for (let i = 0; i < machs.length; i++) {
      oMachines.push({
        label:
          machs[i].qrCode +
          " - " +
          machs[i].macType.label +
          " - " +
          machs[i].resName.label,
      });
    }
  }

  // Checks ////////////////////////////////////////////////////////////////////

  // Case unused ///////////////////////////////////////////////////////////////
  const cUnused = [
    useState(false),
    useState(false),
    useState(false),
    useState(false),
    useState(false),
  ];

  useEffect(() => {
    var unused = "";
    for (let i = 0; i < cUnused.length; i++)
      if (cUnused[i][GET]) unused += strMac.unused.options[i].label + ", ";
    unused.trimEnd();
    if (unused.length > 0) unused = unused.substring(0, unused.length - 2);
    setIUnused(unused);
    backup(unused, IMBKUP.unused);
  }, [cUnused]);

  // Case unserviceable ////////////////////////////////////////////////////////
  const cUnserv = [useState(false), useState(false), useState(false)];

  useEffect(() => {
    var unserv = "";
    for (let i = 0; i < cUnserv.length; i++)
      if (cUnserv[i][GET]) unserv += strMac.unserv.options[i].label + ", ";
    unserv.trimEnd();
    if (unserv.length > 0) unserv = unserv.substring(0, unserv.length - 2);
    setIUnserv(unserv);
    backup(unserv, IMBKUP.unserv);
  }, [cUnserv]);

  // ///////////////////////////////////////////////////////////////////////////

  var gLat: any;
  var gLong: any;
  var gAcc: any;

  function loadMapImplement() {
    Popup({
      icon: "info",
      title: strMac.notif.geoloc.init.title,
      text: strMac.notif.geoloc.init.message,
      confirmButtonText: strMac.notif.geoloc.init.button,
    });
    var map = L.map("map").setView([14.16255, 121.248497], 6);

    var osm = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    osm.addTo(map);

    var refreshMap: any;
    if (!navigator.geolocation) {
      Popup({
        icon: "error",
        title: strMac.notif.geoloc.initError.title,
        text: strMac.notif.geoloc.initError.message,
        confirmButtonText: strMac.notif.geoloc.initError.button,
      });
    } else {
      refreshMap = setInterval(() => {
        if (!isNaN(gLat) && !isNaN(gLong) && !isNaN(gAcc)) {
          setCoor({
            lat: gLat,
            lng: gLong,
            acc: gAcc,
            accs: gAcc,
          });
        }

        var dev =
          AppSettings.SERVER == "https://127.0.0.1:4433" &&
          !isNaN(gLat) &&
          !isNaN(gLong) &&
          !isNaN(gAcc);

        if (gAcc <= AppSettings.MIN_ACCURACY || dev) {
          clearInterval(refreshMap);
          Popup({
            icon: "success",
            title: strMac.notif.geoloc.success.title,
            text:
              strMac.notif.geoloc.success.lat +
              gLat +
              strMac.notif.geoloc.success.lng +
              gLong +
              strMac.notif.geoloc.success.acc +
              gAcc,
            confirmButtonText: strMac.notif.geoloc.success.button,
          });
          setCoor({
            lat: gLat,
            lng: gLong,
            acc: gAcc,
            accs: gAcc,
          });
        } else {
          navigator.geolocation.getCurrentPosition(getPosition);
        }
      }, 3000);
    }

    var marker: any, circle: any;

    function getPosition(position: any) {
      var lat = position.coords.latitude;
      var long = position.coords.longitude;
      var accuracy = position.coords.accuracy / 3;
      gLat = lat;
      gLong = long;
      gAcc = accuracy;

      if (marker) {
        map.removeLayer(marker);
      }

      if (circle) {
        map.removeLayer(circle);
      }

      marker = L.marker([lat, long]);
      circle = L.circle([lat, long], { radius: accuracy });

      var featureGroup = L.featureGroup([circle]).addTo(map);

      map.fitBounds(featureGroup.getBounds());
    }
  }

  // ///////////////////////////////////////////////////////////////////////////
  // FUNCTIONS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Backup operations /////////////////////////////////////////////////////////

  function deleteMachineBackups() {
    Object.values(IMBKUP).forEach((val, index) => localStorage.removeItem(val));
  }

  function recoverImplementBackups() {
    // Recover all field and pick type questions
    for (let i = 0; i < QUESTIONS.length; i++) {
      if (typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue(recover(QUESTIONS[i].backup));
      }
    }

    // Code for to restore the checkboxes
    for (let i = 0; i < cUnused.length; i++) {
      cUnused[i][SET](UNUSED_BACKUP.includes(strMac.unused.options[i].label));
    }
    UNUSED_BACKUP = "";

    for (let i = 0; i < cUnserv.length; i++) {
      cUnserv[i][SET](UNSERV_BACKUP.includes(strMac.unserv.options[i].label));
    }
    UNSERV_BACKUP = "";

    setIQRCode(recover(IMBKUP.qrCode));
    QRCODE_BACKUP = "";

    setIImgFront(recover(IMBKUP.front));
    IMG_FRONT_BACKUP = "";

    setIImgSide(recover(IMBKUP.side));
    IMG_SIDE_BACKUP = "";

    setIImgPlate(recover(IMBKUP.plate));
    IMG_PLATE_BACKUP = "";

    setIAcc(recover(IMBKUP.acc));
    ACC_BACKUP = "";

    setILat(recover(IMBKUP.lat));
    LAT_BACKUP = "";

    setILng(recover(IMBKUP.lng));
    LNG_BACKUP = "";
  }

  function recover(key: string) {
    var val = localStorage.getItem(key) || "";
    if (localStorage.getItem(key) == null) {
      return "";
    } else if (val.charAt(0) == "{") {
      return JSON.parse(localStorage.getItem(key) ?? "");
    } else {
      return val;
    }
  }

  function clearFields() {
    for (let i = 0; i < QUESTIONS.length; i++) {
      if (typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue("");
      }
    }
  }

  // Show restore backup notice ///////////////////////////////////////////////

  var doesBackupExist = false;
  Object.values(IMBKUP).forEach((val, index) => {
    if (localStorage.getItem(val) != null) {
      doesBackupExist = true;
    }
  });

  const [inBackup, setinBackup] = useState(doesBackupExist);

  useEffect(() => {
    if (iQRCode != "") localStorage.setItem(IMBKUP.qrCode, iQRCode);
    if (iAcc != "") localStorage.setItem(IMBKUP.acc, iAcc);
    if (iLat != "") localStorage.setItem(IMBKUP.lat, iLat);
    if (iLng != "") localStorage.setItem(IMBKUP.lng, iLng);
    if (iImgFront != "") localStorage.setItem(IMBKUP.front, iImgFront);
    if (iImgSide != "") localStorage.setItem(IMBKUP.side, iImgSide);
    if (iImgPlate != "") localStorage.setItem(IMBKUP.plate, iImgPlate);
  }, [iQRCode, iAcc, iLat, iLng, iImgFront, iImgSide, iImgPlate]);

  // DYNAMIC NOTIFICATION STATES AND FUNCTIONS /////////////////////////////////

  const [popTitle, setPopTitle] = useState("");
  const [popContent, setPopContent] = useState("");
  const [popShow, setPopShow] = useState(false);
  const [popCentered, setPopCentered] = useState(true);
  const [popActLeft, setPopActLeft] = useState<TModButton>();
  const [popActCenter, setPopActCenter] = useState<TModButton>({ text: "" });
  const [popActRight, setPopActRight] = useState<TModButton>();

  function showPopup(props: IModPublic) {
    setPopTitle("");
    setPopContent("");
    setPopCentered(true);
    setPopActLeft({ text: "" });
    setPopActCenter({ text: "" });
    setPopActRight({ text: "" });

    setPopTitle(props.title);
    setPopContent(props.content);
    if (props.centered) setPopCentered(props.centered);
    if (props.actionLeft) setPopActLeft(props.actionLeft);
    if (props.actionCenter) setPopActCenter(props.actionCenter);
    if (props.actionRight) setPopActRight(props.actionRight);
    setPopShow(true);
  }

  const [ntfMessage, setNtfMessage] = useState("");
  const [ntfShow, setNtfShow] = useState(false);
  const [ntfDuration, setNtfDuration] = useState<number | undefined>(5000);
  const [ntfSeverity, setNtfSeverity] = useState<TGetSeverity>("info");

  function showNotification(props: INotifyPublic) {
    setNtfMessage("");
    setNtfSeverity("info");
    setNtfDuration(5000);
    setNtfMessage(props.message);
    setNtfSeverity(props.severity);
    if (props.duration) setNtfDuration(props.duration);
    setNtfShow(true);
  }

  // ///////////////////////////////////////////////////////////////////////////

  return (
    <VBar style="main-bg-form form-design">
      {/* DYNAMIC NOTIFICATION COMPONENT */}
      <Mod
        title={popTitle}
        content={popContent}
        show={[popShow, setPopShow]}
        centered={popCentered}
        actionLeft={popActLeft}
        actionCenter={popActCenter}
        actionRight={popActRight}
      />
      <Notify
        message={ntfMessage}
        show={[ntfShow, setNtfShow]}
        severity={ntfSeverity}
        duration={ntfDuration}
      />
      <Mod
        show={[inBackup, setinBackup]}
        title="Unsaved Machine Exists"
        content="Would you like to continue with the unsaved implement? This may take a while."
        actionCenter={{
          text: "No",
          variant: "text",
          action: () => {
            deleteImplementBackups();
          },
        }}
        actionRight={{ text: "Yes", action: () => recoverImplementBackups() }}
      />
      <VBar style="v-bar">
        <TitleBar />
        <Card title={strImp.basicInfo.title} titleStyle="title">
          <Pick
            text={strImp.basicInfo.resName}
            options={oUsernames}
            value={[iResName, setIResName]}
            validation={eResName}
            backup={IMBKUP.resName}
          />
          <Pick
            text={strImp.basicInfo.machine}
            options={oMachines}
            value={[iMachine, setIMachine]}
            validation={eMachine}
            backup={IMBKUP.machine}
          />
          <HBar>
            <Press
              disabled={disQRBtn}
              position="left"
              icon={<QrCode2Icon />}
              onClick={() => {
                setVQRCode(!vQRCode);
                resetQR();
                againQR();
              }}
            ></Press>
            <Field
              disabled
              position="right"
              text={strImp.basicInfo.qrCode}
              value={[iQRCode, setIQRCode]}
              validation={eQRCode}
              backup={IMBKUP.qrCode}
            />
          </HBar>
          <VBar force in={vQRCode} style="spacer">
            <Pick
              text={strImp.basicInfo.qrcodevid}
              options={qrCameras}
              onInputChange={(e, val: any) => {
                resetQR();
                let v = qrCameras.find((o) => o.label == val)?.value;
                if (v != undefined) {
                  setQRDevice(v);
                  resetQR();
                  decodeOnce(qrReader, v);
                }
              }}
              value={[qrCameras.find((o) => o.value == qrDevice)?.label, null]}
              validation={null}
              backup=""
            />
            <video id="video" />
          </VBar>
          <HBar>
            <Press
              disabled={disCamBtnFront}
              position="left"
              icon={<CameraAltIcon />}
              onClick={() => {
                setVImgFront(!vImgFront);
              }}
            ></Press>
            <Field
              disabled
              position="right"
              text={strImp.basicInfo.front}
              value={[iImgFront, setIImgFront]}
              validation={eImgFront}
              backup={IMBKUP.front}
            />
          </HBar>
          <VBar force in={vImgFront} style="spacer">
            <WebCam setImage={setIImgFront} />
          </VBar>

          <HBar>
            <Press
              disabled={disCamBtnSide}
              position="left"
              icon={<CameraAltIcon />}
              onClick={() => {
                setVImgSide(!vImgSide);
              }}
            ></Press>
            <Field
              disabled
              position="right"
              text={strImp.basicInfo.side}
              value={[iImgSide, setIImgSide]}
              validation={eImgSide}
              backup={IMBKUP.side}
            />
          </HBar>
          <VBar force in={vImgSide} style="spacer">
            <WebCam setImage={setIImgSide} />
          </VBar>
          <HBar>
            <Press
              disabled={disCamBtnPlate}
              position="left"
              icon={<CameraAltIcon />}
              onClick={() => {
                setVImgPlate(!vImgPlate);
              }}
            ></Press>
            <Field
              disabled
              position="right"
              text={strImp.basicInfo.plate}
              value={[iImgPlate, setIImgPlate]}
              validation={eImgPlate}
              backup={IMBKUP.plate}
            />
          </HBar>
          <VBar force in={vImgPlate} style="spacer">
            <WebCam setImage={setIImgPlate} />
          </VBar>
          <HBar>
            <Press
              position="left"
              icon={<MyLocationIcon />}
              onClick={() => {
                // setLocation();
                setVCoor(true);
                loadMapImplement();
              }}
              disabled={vCoor}
            ></Press>
            <Field
              disabled
              position="right"
              text={strImp.basicInfo.coordinates}
              value={[iAcc, setIAcc]}
              validation={eAcc}
              backup={IMBKUP.acc}
            />
          </HBar>
          <VBar in={vCoor} style="spacer">
            <Field
              disabled
              text={strImp.basicInfo.lat}
              value={[iLat, setILat]}
              validation={eLat}
              backup={IMBKUP.lat}
            />
            <Field
              disabled
              text={strImp.basicInfo.lng}
              value={[iLng, setILng]}
              validation={eLng}
              backup={IMBKUP.lng}
            />
            <Alert
              severity={
                coor.acc == undefined
                  ? "error"
                  : !/[^0-9]./.test(String(coor.acc)) && coor.acc <= 5
                  ? "success"
                  : !/[^0-9]./.test(String(coor.acc)) && coor.acc <= 8
                  ? "warning"
                  : "error"
              }
              sx={{ width: "100%" }}
            >
              {coor.acc == undefined
                ? strMac.notif.accuracy.init
                : coor.acc <= 5
                ? strMac.notif.accuracy.high
                : coor.acc <= 8
                ? strMac.notif.accuracy.low
                : strMac.notif.accuracy.search}
            </Alert>
          </VBar>
          <GeoLocationPane />
          <Pick
            text={strMac.bscInfo.during}
            options={strMac.bscInfo.during.options}
            value={[iLoc, setILoc]}
            validation={eLoc}
            backup={IMBKUP.loc}
          />
          <hr />
          <Text text={strMac.bscInfo.garage} />
          <Province
            text={strImp.basicInfo.prov}
            address={implement}
            value={[iProv, setIProv]}
            validation={eProv}
            backup={IMBKUP.prov}
          />
          <Municipality
            text={strImp.basicInfo.mun}
            address={implement}
            value={[iMun, setIMun]}
            validation={eMun}
            backup={IMBKUP.mun}
          />
          <Barangay
            text={strImp.basicInfo.brgy}
            address={implement}
            value={[iBrgy, setIBrgy]}
            validation={eBrgy}
            backup={IMBKUP.brgy}
          />
        </Card>
        <Card title={strImp.specifications.title} titleStyle="title">
          <Pick
            group={(o) => o.type}
            text={strImp.basicInfo.type}
            options={strImp.basicInfo.type.options}
            onInputChange={oICType}
            value={[iType, setIType]}
            validation={eType}
            backup={IMBKUP.type}
          />
          <Pick
            free
            text={strImp.specifications.brand}
            options={[]}
            value={[iBrand, setIBrand]}
            validation={eBrand}
            backup={IMBKUP.brand}
          />
          <Pick
            free
            text={strImp.specifications.model}
            options={[]}
            value={[iModel, setIModel]}
            validation={eModel}
            backup={IMBKUP.model}
          />
          <HBar in={v01}>
            <Field
              text={strImp.specifications.bottoms}
              value={[iBotA, setIBotA]}
              validation={eBotA}
              backup={IMBKUP.botA}
            />
          </HBar>
          <HBar in={v02} gap={10}>
            <Field
              text={strImp.specifications.bottoms}
              value={[iBotB, setIBotB]}
              validation={eBotB}
              backup={IMBKUP.botB}
            />
            <Field
              text={strImp.specifications.disk}
              value={[iSzDisk, setISzDisk]}
              validation={eSzDisk}
              backup={IMBKUP.szDisk}
            />
          </HBar>
          <HBar in={v03} gap={10}>
            <Field
              text={strImp.specifications.bottoms}
              value={[iBotC, setIBotC]}
              validation={eBotC}
              backup={IMBKUP.botC}
            />
            <Field
              text={strImp.specifications.distance}
              value={[iDRowsA, setIDRowsA]}
              validation={eDRowsA}
              backup={IMBKUP.dRowsA}
            />
          </HBar>
          <HBar in={v04 || v10}>
            <Field
              text={strImp.specifications.width}
              value={[iWCut, setIWCut]}
              validation={eWCut}
              backup={IMBKUP.wCut}
            />
          </HBar>
          <HBar in={v05}>
            <Field
              position="left"
              text={strImp.specifications.loading}
              value={[iLCap, setILCap]}
              validation={eLCap}
              backup={IMBKUP.lCap}
            />
            <Pick
              //free
              position="right"
              text={strImp.specifications.loading.unitval}
              options={strImp.specifications.loading.unitval.options}
              value={[iLCapUnit, setILCapUnit]}
              validation={eLCapUnit}
              backup={IMBKUP.lCapUnit}
            />
          </HBar>
          <HBar in={v06} gap={10}>
            <Field
              text={strImp.specifications.rows}
              value={[iNoRowsA, setINoRowsA]}
              validation={eNoRowsA}
              backup={IMBKUP.noRowsA}
            />
            <Field
              text={strImp.specifications.distance}
              value={[iDRowB, setIDRowB]}
              validation={eDRowB}
              backup={IMBKUP.dRowB}
            />
          </HBar>
          <VBar in={v07} style="spacer">
            <HBar gap={10}>
              <Field
                text={strImp.specifications.noz}
                value={[iNoz, setINoz]}
                validation={eNoz}
                backup={IMBKUP.noz}
              />
              <Field
                text={strImp.specifications.dNoz}
                value={[iDNoz, setIDNoz]}
                validation={eDNoz}
                backup={IMBKUP.dNoz}
              />
            </HBar>
            <Field
              text={strImp.specifications.tkCap}
              value={[iTkCapA, setITkCapA]}
              validation={eTkCapA}
              backup={IMBKUP.tkCapA}
            />
          </VBar>
          <VBar in={v08} style="spacer">
            <HBar gap={10}>
              <Field
                text={strImp.specifications.out}
                value={[iNoOut, setINoOut]}
                validation={eNoOut}
                backup={IMBKUP.noOut}
              />
              <Field
                text={strImp.specifications.dOut}
                value={[iDOut, setIDOut]}
                validation={eDOut}
                backup={IMBKUP.dOut}
              />
            </HBar>
            <HBar>
              <Field
                position="left"
                text={strImp.specifications.tkCap2}
                value={[iTkCapB, setITkCapB]}
                validation={eTkCapB}
                backup={IMBKUP.tkCapB}
              />
              <Pick
                //free
                position="right"
                text={strImp.specifications.tkCap2.unitval}
                options={strImp.specifications.tkCap2.unitval.options}
                value={[iTkCapUnit, setITkCapUnit]}
                validation={eTkCapUnit}
                backup={IMBKUP.tkCapUnit}
              />
            </HBar>
          </VBar>
          <VBar in={v09} style="spacer">
            <Pick
              text={strImp.specifications.type1.text}
              options={strImp.specifications.type1.options}
              value={[iTpPlntr, setITpPlntr]}
              validation={eTpPlntr}
              backup={IMBKUP.tpPlntr}
            />
            <Field
              text={strImp.specifications.rows}
              value={[iNoRowsB, setINoRowsB]}
              validation={eNoRowsB}
              backup={IMBKUP.noRowsB}
            />
          </VBar>
          <VBar in={v11} style="spacer">
            <Pick
              text={strImp.specifications.type2.text}
              options={strImp.specifications.type2.options}
              value={[iGrbType, setIGrbType]}
              validation={eGrbType}
              backup={IMBKUP.grbType}
            />
          </VBar>
        </Card>
        <Card title={strImp.operations.title} titleStyle="title">
          <Pick
            value={[iPri, setIPri]}
            // disabled={dFrt}
            // key={kFrt}
            text={strImp.operations.pri}
            options={strMac.rent.fOper.options}
            // onInputChange={oICFrt}
            validation={ePri}
            backup={IMBKUP.pri}
          />
          <Pick
            value={[iSec, setISec]}
            // disabled={dScd}
            // key={kScd}
            text={strImp.operations.sec}
            options={strMac.rent.fOper.options}
            // onInputChange={oICScd}
            validation={eSec}
            backup={IMBKUP.sec}
          />
          <Pick
            value={[iTer, setITer]}
            // disabled={dTrd}
            // key={kTrd}
            text={strImp.operations.ter}
            options={strMac.rent.fOper.options}
            // onInputChange={oICTrd}
            validation={eTer}
            backup={IMBKUP.ter}
          />
        </Card>
        <Card title={strMac.usage.title} titleStyle="title">
          <Field
            text={strMac.usage.oob}
            value={[iOob, setIOob]}
            validation={eOob}
            backup={IMBKUP.oob}
          />
          <Pick
            text={strMac.usage.class}
            options={strMac.usage.class.options}
            value={[iOClass, setIOClass]}
            validation={eOClass}
            backup={IMBKUP.oClass}
          />
          <Pick
            text={strMac.usage.usage}
            options={strMac.usage.usage.options}
            value={[iOUsage, setIOUsage]}
            validation={eOUsage}
            backup={IMBKUP.oUsage}
          />
          <Pick
            text={strMac.usage.mode}
            options={strMac.usage.mode.options}
            value={[iOMode, setIOMode]}
            validation={eOMode}
            backup={IMBKUP.oMode}
          />
          <HBar in={vOMode}>
            <Field
              text={strMac.usage.agency}
              value={[iOAgency, setIOAgency]}
              validation={eOAgency}
              backup={IMBKUP.oAgency}
            />
          </HBar>
          <Day
            text={strMac.usage.acqYear}
            views={["month", "year"]}
            listen={setIOAYear}
            validation={eOAYear}
            backup={IMBKUP.oAYear}
          />
          <Pick
            text={strMac.usage.cond}
            options={strMac.usage.cond.options}
            value={[iOACond, setIOACond]}
            validation={eOACond}
            backup={IMBKUP.oACond}
          />
          <Pick
            value={[iPresCon, setIPresCon]}
            text={strMac.usage.pCond}
            options={strMac.usage.pCond.options}
            validation={ePresCon}
            backup={IMBKUP.presCon}
          />
          <VBar in={vUsed} id="unused" style="spacer">
            <Day
              text={strMac.unused.uuYear}
              views={["month", "year"]}
              listen={setIUUYear}
              validation={eUUYear}
              backup={IMBKUP.uUYear}
            />
            <Text text={strMac.unused.subtitle} />
            <Checks options={strMac.unused.options} checks={cUnused} />
            <Field
              text={strMac.unused.uuOthers}
              value={[iUUOthers, setIUUOthers]}
              validation={eUUOthers}
              backup={IMBKUP.uUOthers}
            />
          </VBar>
          <VBar in={vUnUsd} id="unserviceable" style="spacer">
            <Day
              text={strMac.unserv.usYear}
              views={["month", "year"]}
              listen={setIUSYear}
              validation={eUSYear}
              backup={IMBKUP.uSYear}
            />
            <Text text={strMac.unserv.subtitle} />
            <Checks
              row={false}
              options={strMac.unserv.options}
              checks={cUnserv}
            />
            <Field
              text={strMac.unserv.usOthers}
              value={[iUSOthers, setIUSOthers]}
              validation={eUSOthers}
              backup={IMBKUP.uSOthers}
            />
          </VBar>
        </Card>
        <Card title={strMac.rent.title} titleStyle="title">
          <Pick
            text={strMac.rent.question}
            value={[iRental, setIRental]}
            options={strMac.rent.question.options}
            validation={eRental}
            backup={IMBKUP.rental}
          />
          <Text text={strMac.rent.ope.first} />
          <Pick
            free
            text={strMac.rent.fOper}
            options={strMac.rent.fOper.options}
            value={[iRFrtOpe, setIRFrtOpe]}
            validation={eRFrtOpe}
            backup={IMBKUP.rFrtOpe}
          />
          <HBar>
            <Field
              unitPosition="start"
              position="left"
              text={strMac.rent.fORate}
              value={[iRFrtRate, setIRFrtRate]}
              validation={eRFrtRate}
              backup={IMBKUP.rFrtRate}
            />
            <Pick
              free
              position="right"
              text={strMac.rent.fORate.unitval}
              options={strMac.rent.fORate.options}
              value={[iRFrtUnit, setIRFrtUnit]}
              validation={eRFrtUnit}
              backup={IMBKUP.rFrtUnit}
            />
          </HBar>
          {/*<Field
            text={strMac.rent.fuel}
            value={[iRFrtFuel, setIRFrtFuel]}
            validation={eRFrtFuel}
            backup={IMBKUP.rFrtFuel}
              />*/}
          <Text text={strMac.rent.ope.second} />
          <Pick
            free
            text={strMac.rent.fOper}
            options={strMac.rent.fOper.options}
            value={[iRScdOpe, setIRScdOpe]}
            validation={eRScdOpe}
            backup={IMBKUP.rScdOpe}
          />
          <HBar>
            <Field
              unitPosition="start"
              position="left"
              text={strMac.rent.fORate}
              value={[iRScdRate, setIRScdRate]}
              validation={eRScdRate}
              backup={IMBKUP.rScdRate}
            />
            <Pick
              free
              position="right"
              text={strMac.rent.fORate.unitval}
              options={strMac.rent.fORate.options}
              value={[iRScdUnit, setIRScdUnit]}
              validation={eRScdUnit}
              backup={IMBKUP.rScdUnit}
            />
          </HBar>
          {/*<Field
            text={strMac.rent.fuel}
            value={[iRScdFuel, setIRScdFuel]}
            validation={eRScdFuel}
            backup={IMBKUP.rScdFuel}
          />*/}
          <Text text={strMac.rent.ope.third} />
          <Pick
            free
            text={strMac.rent.fOper}
            options={strMac.rent.fOper.options}
            value={[iRTrdOpe, setIRTrdOpe]}
            validation={eRTrdOpe}
            backup={IMBKUP.rTrdOpe}
          />
          <HBar>
            <Field
              unitPosition="start"
              position="left"
              text={strMac.rent.fORate}
              value={[iRTrdRate, setIRTrdRate]}
              validation={eRTrdRate}
              backup={IMBKUP.rTrdRate}
            />
            <Pick
              free
              position="right"
              text={strMac.rent.fORate.unitval}
              options={strMac.rent.fORate.options}
              value={[iRTrdUnit, setIRTrdUnit]}
              validation={eRTrdUnit}
              backup={IMBKUP.rTrdUnit}
            />
          </HBar>
          {/*<Field
            text={strMac.rent.fuel}
            value={[iRTrdFuel, setIRTrdFuel]}
            validation={eRTrdFuel}
            backup={IMBKUP.rTrdFuel}
            />*/}
        </Card>
        <Card title={strImp.perf.title} titleStyle="title">
          <VBar
            in={v01 || v02 || v03 || v04 || v06 || v07 || v08 || v09}
            style="spacer"
          >
            <HBar gap={10}>
              <Field
                text={strImp.perf.eArea}
                value={[iPEffArea, setIPEffArea]}
                validation={ePEffArea}
                backup={IMBKUP.pEffArea}
              />
              <Field
                text={strImp.perf.time}
                value={[iPEffATime, setIPEffATime]}
                validation={ePEffATime}
                backup={IMBKUP.pEffATime}
              />
            </HBar>
            <HBar in={v01 || v02 || v03 || v04 || v06}>
              <Field
                text={strImp.perf.passes}
                value={[iPPasses, setIPPasses]}
                validation={ePPasses}
                backup={IMBKUP.pPasses}
              />
            </HBar>
          </VBar>
          <VBar in={v08} style="spacer">
            <HBar>
              <Field
                position="left"
                text={strImp.perf.gran}
                value={[iPGrFerWght, setIPGrFerWght]}
                validation={ePGrFerWght}
                backup={IMBKUP.pGrFerWght}
              />
              <Pick
                //free
                position="right"
                text={strImp.perf.gran.unitval}
                options={strImp.perf.gran.unitval.options}
                value={[iPGrFerUnit, setIPGrFerUnit]}
                validation={ePGrFerUnit}
                backup={IMBKUP.pGrFerUnit}
              />
            </HBar>
            <Field
              text={strImp.perf.fertapp}
              value={[iPFerAppEffA, setIPFerAppEffA]}
              validation={ePFerAppEffA}
              backup={IMBKUP.pFerAppEffA}
            />
            <Field
              text={strImp.perf.fertdel}
              value={[iPFerDelRate, setIPFerDelRate]}
              validation={ePFerDelRate}
              backup={IMBKUP.pFerDelRate}
            />
          </VBar>
          <VBar in={v10} style="spacer">
            <Field
              text={strImp.perf.sugar}
              value={[iPSgrYield, setIPSgrYield]}
              validation={ePSgrYield}
              backup={IMBKUP.pSgrYield}
            />
          </VBar>
          <VBar in={v11} style="spacer">
            <HBar>
              <Field
                position="left"
                text={strImp.perf.lcap}
                value={[iPLCap, setIPLCap]}
                validation={ePLCap}
                backup={IMBKUP.lCap}
              />
              <Pick
                //free
                position="right"
                text={strImp.perf.lcap.unitval}
                options={strImp.perf.lcap.unitval.options}
                value={[iPLCapUnit, setIPLCapUnit]}
                validation={ePLCapUnit}
                backup={IMBKUP.lCapUnit}
              />
            </HBar>
          </VBar>
          <Field
            text={strImp.perf.breakd}
            value={[iPBrkDwns, setIPBrkDwns]}
            validation={ePBrkDwns}
            backup={IMBKUP.pBrkDwns}
          />
          <Pick
            text={strImp.perf.combreak}
            value={[iPComBrk, setIPComBrk]}
            options={strImp.perf.combreak.options}
            validation={ePComBrk}
            backup={IMBKUP.pComBrk}
          />
          <Pick
            free
            text={strImp.perf.maint}
            value={[iPMaint, setIPMaint]}
            options={strImp.perf.maint.options}
            validation={ePMaint}
            backup={IMBKUP.pMaint}
          />
        </Card>
        <Card title={strImp.supplier.title} titleStyle="title">
          <Text text={strImp.supplier.title} />
          <Pick
            text={strImp.supplier.name}
            options={strImp.supplier.name.options}
            value={[iSupplier, setISupplier]}
            validation={eSupplier}
            backup={IMBKUP.supplier}
          />
          <VBar style="spacer indent">
            <Province
              text={strImp.supplier.prov}
              address={aftersales}
              value={[iSProv, setISProv]}
              validation={eSProv}
              backup={IMBKUP.sProv}
            />
            <Municipality
              text={strImp.supplier.mun}
              address={aftersales}
              value={[iSMun, setISMun]}
              validation={eSMun}
              backup={IMBKUP.sMun}
            />
            <Barangay
              text={strImp.supplier.brgy}
              address={aftersales}
              value={[iSBrgy, setISBrgy]}
              validation={eSBrgy}
              backup={IMBKUP.sBrgy}
            />
          </VBar>
          <Pick
            text={strImp.supplier.sTime}
            options={strImp.supplier.sTime.options}
            value={[iSTime, setISTime]}
            validation={eSTime}
            backup={IMBKUP.sTime}
          />
          <Pick
            text={strImp.supplier.sAvail}
            options={strMac.supplier.sPartsAvail.options}
            value={[iSAvail, setISAvail]}
            validation={eSAvail}
            backup={IMBKUP.sAvail}
          />
        </Card>

        <Card>
          <Field
            multiline
            rows={7}
            text={strImp.note}
            value={[iNotes, setINotes]}
            validation={eNotes}
            backup={IMBKUP.notes}
          />
        </Card>
        <Press
          label={strImp.save}
          color="secondary"
          icon={<SaveIcon />}
          onClick={() => {
            if (saveEncodedImplement()) {
              // setTimeout(() => {
              //   navigate(Route.encode);
              // }, 6000);
            }
          }}
          height={50}
        />
        <div className="btn-group" role="group">
          <button className="btn btn-primary" type="button">
            <Nav.Link key={Route.encode} as={NavLink} to={Route.encode} end>
              Go to Encode
            </Nav.Link>
          </button>
          <button className="btn btn-primary" type="button">
            <Nav.Link key={Route.machine} as={NavLink} to={Route.machine} end>
              Go to Machine
            </Nav.Link>
          </button>
        </div>
      </VBar>
    </VBar>
  );

  function saveEncodedImplement() {
    // Variables ///////////////////////////////////////////////////////////////
    var proceedToSave = true;
    var now = Date.now().toString();
    var enumerator = localStorage.getItem(fxdUser.name);

    // Constants ///////////////////////////////////////////////////////////////
    const VGET = 0;
    const EGET = 1;
    const ESET = 2;

    // Add required fields /////////////////////////////////////////////////////
    var required = [
      [iResName, eResName, setEResName],
      [iMachine, eMachine, setEMachine],
      [iQRCode, eQRCode, setEQRCode],
      [iImgFront, eImgFront, setEImgFront],
      [iImgSide, eImgSide, setEImgSide],
      // [iImgPlate, eImgPlate, setEImgPlate],
      [iAcc, eAcc, setEAcc],
      [iLat, eLat, setELat],
      [iLng, eLng, setELng],
      [iLoc, eLoc, setELoc],
      [iProv, eProv, setEProv],
      [iMun, eMun, setEMun],
      [iBrgy, eBrgy, setEBrgy],
    ];

    required.push([iPri, ePri, setEPri]);
    // required.push([iSec, eSec, setESec]);
    // required.push([iTer, eTer, setETer]);

    required.push([iType, eType, setEType]);
    // required.push([iBrand, eBrand, setEBrand]);
    // required.push([iModel, eModel, setEModel]);

    if (v01) {
      required.push([iBotA, eBotA, setEBotA]);
    }

    if (v02) {
      required.push([iBotB, eBotB, setEBotB]);
      required.push([iSzDisk, eSzDisk, setESzDisk]);
    }

    if (v03) {
      required.push([iBotC, eBotC, setEBotC]);
      required.push([iDRowsA, eDRowsA, setEDRowsA]);
    }

    if (v04 || v10) {
      required.push([iWCut, eWCut, setEWCut]);
    }

    if (v05) {
      required.push([iLCap, eLCap, setELCap]);
      required.push([iLCapUnit, eLCapUnit, setELCapUnit]);
    }

    if (v06) {
      required.push([iNoRowsA, eNoRowsA, setENoRowsA]);
      required.push([iDRowB, eDRowB, setEDRowB]);
    }

    if (v07) {
      required.push([iNoz, eNoz, setENoz]);
      required.push([iDNoz, eDNoz, setEDNoz]);
      required.push([iTkCapA, eTkCapA, setETkCapA]);
    }

    if (v08) {
      required.push([iNoOut, eNoOut, setENoOut]);
      required.push([iDOut, eDOut, setEDOut]);
      required.push([iTkCapB, eTkCapB, setETkCapB]);
      required.push([iTkCapUnit, eTkCapUnit, setETkCapUnit]);
    }

    if (v09) {
      required.push([iTpPlntr, eTpPlntr, setETpPlntr]);
      required.push([iNoRowsB, eNoRowsB, setENoRowsB]);
    }
    if (v11) {
      required.push([iGrbType, eGrbType, setEGrbType]);
    }

    required.push([iOob, eOob, setEOob]);
    required.push([iOClass, eOClass, setEOClass]);
    required.push([iOUsage, eOUsage, setEOUsage]);
    required.push([iOMode, eOMode, setEOMode]);
    required.push([iOAgency, eOAgency, setEOAgency]);
    required.push([iOAYear, eOAYear, setEOAYear]);
    required.push([iOACond, eOACond, setEOACond]);
    required.push([iPresCon, ePresCon, setEPresCon]);

    if (vUsed) {
      required.push([iUUYear, eUUYear, setEUUYear]);
      required.push([iUnused, eUnused, setEUnused]);
      // TODO
      // required.push([iUUOthers, eUUOthers, setEUUOthers]);
    } else if (vUnUsd) {
      required.push([iUSYear, eUSYear, setEUSYear]);
      required.push([iUnserv, eUnserv, setEUnserv]);
      // TODO
      // required.push([iUSOthers, eUSOthers, setEUSOthers]);
    }

    required.push([iRental, eRental, setERental]);

    if (
      iRental &&
      (iRental as any).label == strMac.rent.question.options[0].label
    ) {
      required.push([iRFrtOpe, eRFrtOpe, setERFrtOpe]);
      required.push([iRFrtRate, eRFrtRate, setERFrtRate]);
      required.push([iRFrtUnit, eRFrtUnit, setERFrtUnit]);
      // required.push([iRFrtFuel, eRFrtFuel, setERFrtFuel]);
      // required.push([iRScdOpe, eRScdOpe, setERScdOpe]);
      // required.push([iRScdRate, eRScdRate, setERScdRate]);
      // required.push([iRScdUnit, eRScdUnit, setERScdUnit]);
      // required.push([iRScdFuel, eRScdFuel, setERScdFuel]);
      // required.push([iRTrdOpe, eRTrdOpe, setERTrdOpe]);
      // required.push([iRTrdRate, eRTrdRate, setERTrdRate]);
      // required.push([iRTrdUnit, eRTrdUnit, setERTrdUnit]);
      // required.push([iRTrdFuel, eRTrdFuel, setERTrdFuel]);
    }

    if (v01 || v02 || v03 || v04 || v06 || v07 || v08 || v09) {
      required.push([iPEffArea, ePEffArea, setEPEffArea]);
      required.push([iPEffATime, ePEffATime, setEPEffATime]);

      if (v01 || v02 || v03 || v04 || v06) {
        required.push([iPPasses, ePPasses, setEPPasses]);
      }
    }

    if (v08) {
      required.push([iPGrFerWght, ePGrFerWght, setEPGrFerWght]);
      required.push([iPGrFerUnit, ePGrFerUnit, setEPGrFerUnit]);
      required.push([iPFerAppEffA, ePFerAppEffA, setEPFerAppEffA]);
      required.push([iPFerDelRate, ePFerDelRate, setEPFerDelRate]);
    }
    if (v10) {
      required.push([iPSgrYield, ePSgrYield, setEPSgrYield]);
    }
    if (v11) {
      required.push([iPLCap, ePLCap, setEPLCap]);
      required.push([iPLCapUnit, ePLCapUnit, setEPLCapUnit]);
    }
    required.push([iPBrkDwns, ePBrkDwns, setEPBrkDwns]);
    if (Number(iPBrkDwns) > 0) {
      required.push([iPComBrk, ePComBrk, setEPComBrk]);
    }
    required.push([iPMaint, ePMaint, setEPMaint]);

    required.push([iSupplier, eSupplier, setESupplier]);
    required.push([iSProv, eSProv, setESProv]);
    required.push([iSMun, eSMun, setESMun]);
    required.push([iSBrgy, eSBrgy, setESBrgy]);
    required.push([iSTime, eSTime, setESTime]);
    required.push([iSAvail, eSAvail, setESAvail]);

    // required.push([iNotes, eNotes, setENotes]);

    // Setting required fields /////////////////////////////////////////////////
    for (let i = 0; i < required.length; i++) {
      var valAsText =
        required[i][VGET] != null
          ? (required[i][VGET] as any).label
          : required[i][VGET];

      if (!valAsText) {
        var require = {
          required: true,
          condition: (required[i][EGET] as TGetValidation).condition,
          message: (required[i][EGET] as TGetValidation).message,
          range: (required[i][EGET] as TGetValidation).range,
          length: (required[i][EGET] as TGetValidation).length,
        } as TGetValidation;
        (required[i][ESET] as TSetValidation)(require);
      }
    }

    // Check if all required fields are filled /////////////////////////////////
    for (let i = 0; i < required.length; i++) {
      var valAsText = (required[i][VGET] as any).label ?? required[i][VGET];

      if (valAsText == "") {
        proceedToSave = false;
        break;
      }
    }

    if (parseFloat(iAcc) > AppSettings.MIN_ACCURACY) {
      showNotification({
        message:
          "Accuracy is too low. Kindly wait for the accuracy to meet atleast 8 meters.",
        severity: "error",
      });
      proceedToSave = false;
    }

    // Browser compatibility test //////////////////////////////////////////////
    // This might show in rare case only
    if (typeof JSON == "undefined") {
      showNotification({
        message: strPrf.notif.browserError,
        severity: "error",
      });
    } else if (!proceedToSave) {
      showNotification({
        message: strPrf.notif.missing,
        severity: "error",
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      showNotification({
        message: strPrf.notif.saveIni,
        severity: "success",
      });
    }

    // Prepare save the data ///////////////////////////////////////////////////
    if (proceedToSave) {
      let data = {
        //
        resName: iResName,
        machine: iMachine,
        qrCode: iQRCode,
        front: iImgFront,
        side: iImgSide,
        plate: iImgPlate,
        acc: iAcc,
        lat: iLat,
        lng: iLng,
        loc: iLoc,
        prov: iProv,
        mun: iMun,
        brgy: iBrgy,
        //
        pri: iPri,
        sec: iSec,
        ter: iTer,
        //
        type: iType,
        brand: iBrand,
        model: iModel,
        //
        botA: iBotA,
        botB: iBotB,
        szDisk: iSzDisk,
        botC: iBotC,
        dRowsA: iDRowsA,
        wCut: iWCut,
        lCap: iLCap,
        lCapUnit: iLCapUnit,
        noRowsA: iNoRowsA,
        dRowB: iDRowB,
        noz: iNoz,
        dNoz: iDNoz,
        tkCapA: iTkCapA,
        noOut: iNoOut,
        dOut: iDOut,
        tkCapB: iTkCapB,
        tkCapUnit: iTkCapUnit,
        tpPlntr: iTpPlntr,
        noRowsB: iNoRowsB,
        grbType: iGrbType,
        //
        oob: iOob,
        oClass: iOClass,
        oUsage: iOUsage,
        oMode: iOMode,
        oAgency: iOAgency,
        oAYear: iOAYear,
        oACond: iOACond,
        presCon: iPresCon,
        uUYear: iUUYear,
        unused: iUnused,
        uUOthers: iUUOthers,
        uSYear: iUSYear,
        unserv: iUnserv,
        uSOthers: iUSOthers,
        //
        rental: iRental,
        rFrtOpe: iRFrtOpe,
        rFrtRate: iRFrtRate,
        rFrtUnit: iRFrtUnit,
        // All fuel fields are not included in the data
        // As per Ate Jen March 05, 2024
        rFrtFuel: "Removed",
        rScdOpe: iRScdOpe,
        rScdRate: iRScdRate,
        rScdUnit: iRScdUnit,
        rScdFuel: "Removed",
        rTrdOpe: iRTrdOpe,
        rTrdRate: iRTrdRate,
        rTrdUnit: iRTrdUnit,
        rTrdFuel: "Removed",
        //
        pEffArea: iPEffArea,
        pEffATime: iPEffATime,
        pPasses: iPPasses,
        pGrFerWght: iPGrFerWght,
        pGrFerUnit: iPGrFerUnit,
        pFerAppEffA: iPFerAppEffA,
        pFerDelRate: iPFerDelRate,
        pSgrYield: iPSgrYield,
        pLCap: iPLCap,
        pLCapUnit: iPLCapUnit,
        pBrkDwns: iPBrkDwns,
        pComBrk: iPComBrk,
        pMaint: iPMaint,
        //
        supplier: iSupplier,
        sProv: iSProv,
        sMun: iSMun,
        sBrgy: iSBrgy,
        sTime: iSTime,
        sAvail: iSAvail,
        //
        notes: iNotes,
        //
        uname: enumerator,
        date: now,
        status: DATA_STATUS.local,
      } as ImplementData;

      let filename = iQRCode + ".zip";
      var fileToSave = new Blob([JSON.stringify(data, undefined, 2)], {
        type: "application/json",
      });
      saveAs(fileToSave, filename);
      let implementss = localStorage.getItem(fxdImplement.implements);
      if (implementss != null) {
        const newImplements = data;
        const iplmts = JSON.parse(implementss) as Array<any>;
        iplmts.push(newImplements);
        localStorage.setItem(fxdImplement.implements, JSON.stringify(iplmts));
      } else {
        localStorage.setItem(fxdImplement.implements, JSON.stringify([data]));
      }

      clearFields();
      deleteImplementBackups();
      showPopup({
        title: strImp.notif.save.title,
        content: strImp.notif.save.message,
        actionCenter: {
          text: strImp.notif.save.center,
          action: () => {
            localStorage.removeItem(IMBKUP.unused);
            localStorage.removeItem(IMBKUP.unserv);

            localStorage.removeItem(IMBKUP.qrCode);
            localStorage.removeItem(IMBKUP.acc);
            localStorage.removeItem(IMBKUP.lat);
            localStorage.removeItem(IMBKUP.lng);
            localStorage.removeItem(IMBKUP.front);
            localStorage.removeItem(IMBKUP.side);
            localStorage.removeItem(IMBKUP.plate);
          },
        },
      });
    }

    return proceedToSave;
  }
}

// const oType1 = [
//   { label: strImp.specifications.type1.o.billet, key: 0 },
//   { label: strImp.specifications.type1.o.stalk, key: 1 },
// // ];

// const oType2 = [
//   { label: strImp.specifications.type2.o.digger, key: 0 },
//   { label: strImp.specifications.type2.o.grapple, key: 1 },
// ];
// export const oOpe = [
//   { label: "Land Preparation" },
//   { label: "Planting" },
//   { label: "Ratoon Management" },
//   { label: "Harvesting" },
//   { label: "Hauling" },
// ];
// export const oLCapUnit = [{ label: "Tons / Day" }, { label: "Kg / Day" }];
// export const oTkCapUnit = [
//   { label: "Liters / Day" },
//   { label: "Gallons / Day" },
// ];
// export const oPGrFerUnit = [
//   { label: "Kilograms / Day" },
//   { label: "Tons / Day" },
// ];

// /////////////////////////////////////////////////////////////////////////////
// IMPLEMENT FORMAT ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface ImplementData {
  resName: string;
  machine: string;
  qrCode: string;
  front: string;
  side: string;
  plate: string;
  lat: string;
  lng: string;
  acc: string;
  prov: string;
  mun: string;
  brgy: string;
  pri: string;
  sec: string;
  ter: string;
  type: string;
  brand: string;
  model: string;
  botA: string;
  botB: string;
  szDisk: string;
  botC: string;
  dRowsA: string;
  wCut: string;
  lCap: string;
  lCapUnit: string;
  noRowsA: string;
  dRowB: string;
  noz: string;
  dNoz: string;
  tkCapA: string;
  noOut: string;
  dOut: string;
  tkCapB: string;
  tkCapUnit: string;
  tpPlntr: string;
  noRowsB: string;
  grbType: string;
  oob: string;
  oClass: string;
  oUsage: string;
  oMode: string;
  oAgency: string;
  oAYear: string;
  // oAMonth: iOAMonth,
  oACond: string;
  presCon: string;
  uUYear: string;
  // uUMonth: iUUMonth,
  unused: string;
  uUOthers: string;
  uSYear: string;
  // uSMonth: iUSMonth,
  unserv: string;
  uSOthers: string;
  rental: string;
  rFrtOpe: string;
  rFrtRate: string;
  rFrtUnit: string;
  rFrtFuel: string;
  rScdOpe: string;
  rScdRate: string;
  rScdUnit: string;
  rScdFuel: string;
  rTrdOpe: string;
  rTrdRate: string;
  rTrdUnit: string;
  rTrdFuel: string;
  pEffArea: string;
  pEffATime: string;
  pPasses: string;
  pGrFerWght: string;
  pGrFerUnit: string;
  pFerAppEffA: string;
  pFerDelRate: string;
  pSgrYield: string;
  pLCap: string;
  pLCapUnit: string;
  pBrkDwns: string;
  pComBrk: string;
  pMaint: string;
  supplier: string;
  sProv: string;
  sMun: string;
  sBrgy: string;
  sTime: string;
  sAvail: string;
  notes: string;
  uname: string; // just added Mar 06, 2024 01:11 AM
  date: string;
  status: string;
}
