export default function VerifyPage() {
  return (
    <div
      className="d-md-flex justify-content-md-center align-items-md-center main-bg"
      style={{ width: "100vw", height: "100vh" }}
    >
      <h1 style={{ color: "white" }}>Welcome to Verify!</h1>
    </div>
  );
}
